import BookmarkCheckbox from "components/BookmarkCheckbox/BookmarkCheckbox"
import FavoriteCheckbox from "components/FavoriteCheckbox"
import InteractionsButton from "components/InteractionsMenu/InteractionsButton/InteractionsButton"
import MobileWrapper from "components/MobileWrapper/MobileWrapper"
import NudgeButton from "components/NudgeButton/NudgeButton"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import UserAvatar from "components/UserAvatar/UserAvatar"
import useToggleFavorite from "graphql/favorites/useToggleFavorite"
import useUserFavorites from "graphql/favorites/useUserFavorites"
import useAddUserSubscription from "graphql/subscriptions/useAddUserSubscription"
import useRemoveUserSubscription from "graphql/subscriptions/useRemoveUserSubscription"
import useUserSubscriptions from "graphql/subscriptions/useUserSubscriptions"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { PropsWithChildren, useCallback } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { useTeamsAppContext } from "TeamsApp/TeamsAppContext"
import { Flags, UserBase, UTCSlot, ViewModeType } from "types"
import { Button, colors, L12, mediaQueries, P14, P16, transitions } from "ui"
import { Bookmark, NavArrowRight } from "ui/icons"
import getUserDisplayName from "utils/getUserDisplayName"

import UserSlots from "../UserSlots/UserSlots"

const DesktopItemLayout = styled.div<{ depth: number }>`
  display: grid;
  grid-template-columns: 24px auto auto 1fr;
  align-items: center;
  width: 100%;
  min-height: 40px;
  background: hsla(${colors.blackReal}, 0);
  cursor: pointer;
  padding: 0 8px 0 32px;

  &.cursor-default {
    cursor: default;
  }

  &:hover .show-hover {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  ${({ depth }) => `padding-left: calc(32px + ${depth * 24}px);`}

  > p {
    padding-left: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    > em {
      font-style: normal;
      color: ${colors.grey1};
      padding-left: 6px;
    }
  }

  &:hover {
    background: hsla(${colors.blackReal}, 0.04);
  }

  @media ${mediaQueries.isNarrowScreen} {
    padding: 0 8px 0 16px;

    &.pad-start {
      padding-left: 32px;
    }
  }

  transition: ${transitions.generic};
`

const ActionsDesktopLayout = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: auto;

  svg {
    width: 16px;
    height: 16px;
  }

  .show-hover {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: ${transitions.fade};
  }

  @media ${mediaQueries.isNarrowScreen} {
    gap: 2px;
  }
`

const ToggleShowTeamContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 8px;
`

const ToggleShowTeam = styled(NavArrowRight)`
  width: 20px;
  height: 20px;
  stroke: ${colors.grey1};

  &.rotated {
    rotate: 90deg;
  }

  transition: rotate ease-in-out 250ms;
`

const FavoriteBtn = styled(Button)`
  .favorite-checkbox-icon {
    svg {
      height: 24px;
      width: 24px;
      stroke-width: 1.5px;
      stroke: ${colors.purple};
    }
  }
`

function ActionWrapper({
  isMobile,
  children,
}: PropsWithChildren<{
  isMobile: boolean
}>) {
  if (isMobile)
    return <div onClick={(e) => e.stopPropagation()}>{children}</div>

  return (
    <ActionsDesktopLayout onClick={(e) => e.stopPropagation()}>
      {children}
    </ActionsDesktopLayout>
  )
}

interface ActionsProps {
  user: UserBase
  slots: UTCSlot[]
  isMe: boolean
  isTeamsApp: boolean
  isMobile: boolean
}

function Actions({ user, slots, isMe, isTeamsApp, isMobile }: ActionsProps) {
  const t = useTranslate()

  const [anchorMenu, setAnchorMenu] = React.useState<any>(null)

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget)
    event.stopPropagation()
  }
  const closeMenu = () => {
    setAnchorMenu(null)
  }

  // SUBSCRIPTIONS
  const { subscriptions } = useUserSubscriptions()
  const { addUserSubscription } = useAddUserSubscription()
  const { removeUserSubscription } = useRemoveUserSubscription()
  const checkedSubscription =
    subscriptions.find((s) => s.id === user.id) !== undefined

  const onChangeSubscription = () => {
    if (checkedSubscription) return removeUserSubscription(user.id)
    return addUserSubscription(user.id)
  }

  // FAVORITES
  const { favorites } = useUserFavorites()
  const toggleFavorite = useToggleFavorite()

  const checkedFavorite =
    favorites.users.find((f) => f.itemId == user.id) !== undefined

  if (isTeamsApp)
    return (
      <ActionWrapper isMobile={isMobile}>
        <NudgeButton user={user} slots={slots} itemId={user.id} />
      </ActionWrapper>
    )
  if (isMe) return <></>

  if (isMobile)
    return (
      <ActionWrapper isMobile>
        <InteractionsButton toggleMenu={toggleMenu} />
        <MobileWrapper open={anchorMenu !== null} onClose={closeMenu}>
          <NudgeButton user={user} slots={slots} itemId={user.id} />
          <FavoriteBtn
            secondary
            icon={<FavoriteCheckbox type="user" itemId={user.id} />}
            onClick={() => {
              toggleFavorite(user.id, "user")
              closeMenu()
            }}
          >
            {checkedFavorite
              ? t("remove from favorites")
              : t("add to favorites")}
          </FavoriteBtn>
          <Button
            secondary
            icon={
              <Bookmark
                stroke={checkedSubscription ? colors.success : colors.purple}
                fill={checkedSubscription ? colors.success : undefined}
              />
            }
            onClick={() => {
              onChangeSubscription()
              closeMenu()
            }}
          >
            {checkedSubscription ? t("disable alerts") : t("activate alerts")}
          </Button>

          <Button ghost onClick={closeMenu}>
            {t("Cancel")}
          </Button>
        </MobileWrapper>
      </ActionWrapper>
    )

  return (
    <ActionWrapper isMobile={false}>
      <BookmarkCheckbox className="show-hover" id={user.id} type="user" />
      <FavoriteCheckbox className="show-hover" itemId={user.id} type="user" />
      <NudgeButton user={user} slots={slots} itemId={user.id} />
    </ActionWrapper>
  )
}

function UserName({
  user,
  companyFlags,
  isMe,
  isMobile,
  viewMode,
}: {
  user: UserBase
  companyFlags: Flags
  isMe: boolean
  isMobile: boolean
  viewMode: ViewModeType
}) {
  const t = useTranslate()

  if (isMe) {
    if (isMobile)
      return (
        <L12>
          {getUserDisplayName(user, companyFlags)} <em>{t("you")}</em>
        </L12>
      )
    return (
      <P16>
        {getUserDisplayName(user, companyFlags)} <em>{t("you")}</em>
      </P16>
    )
  }
  if (viewMode === "DAY")
    return <P14>{getUserDisplayName(user, companyFlags)}</P14>
  if (isMobile) return <L12>{getUserDisplayName(user, companyFlags)}</L12>
  return <P16>{getUserDisplayName(user, companyFlags)} </P16>
}

interface PropsType {
  user: UserBase
  slots: UTCSlot[]
  isMe: boolean
  companyFlags: Flags
  showTeam: boolean
  "data-component"?: string
  depth: number
  focusedSlot: UTCSlot | undefined
  setFocusedSlot: (slot: UTCSlot | undefined) => void
}

export default function UserPlanningItem({
  user,
  slots,
  isMe,
  companyFlags,
  showTeam,
  "data-component": dataComponent,
  depth,
  focusedSlot,
  setFocusedSlot,
}: PropsType) {
  const history = useHistory()
  const { isMobile } = useMediaQueries()
  const { isTeamsApp, microsoftTeams } = useTeamsAppContext()
  const { viewMode, setTeamVisibility } = usePlanningContext()
  const path = `/user/${user.id}`

  const isTeamFriendly = location.pathname === "/planning/group/myteam"
  const canShowTeam = !isMobile && user.isManager && isTeamFriendly

  const handleClick = useCallback(() => {
    if (isMe) return
    if (isTeamsApp) {
      if (user) {
        microsoftTeams.executeDeepLink(
          `https://teams.microsoft.com/l/chat/0/0?users=${user.email}`
        )
      }
    } else {
      if (path) history.push(path)
    }
  }, [isTeamsApp, user, microsoftTeams, history, path, isMe])

  if (isMobile && viewMode === "DAY")
    return (
      <span>
        <UserAvatar user={user} size={18} />
        <UserName
          user={user}
          isMe={isMe}
          companyFlags={companyFlags}
          isMobile={isMobile}
          viewMode={viewMode}
        />
        <UserSlots
          slots={slots}
          focusedSlot={focusedSlot}
          viewMode={viewMode}
          setFocusedSlot={setFocusedSlot}
          isMe={isMe}
        />
        <Actions
          user={user}
          slots={slots}
          isMe={isMe}
          isTeamsApp={isTeamsApp}
          isMobile={isMobile}
        />
      </span>
    )

  if (isMobile)
    return (
      <span onClick={handleClick}>
        <UserAvatar user={user} size={18} />
        <UserName
          user={user}
          isMe={isMe}
          companyFlags={companyFlags}
          isMobile={isMobile}
          viewMode={viewMode}
        />
        <Actions
          user={user}
          slots={slots}
          isMe={isMe}
          isTeamsApp={isTeamsApp}
          isMobile={isMobile}
        />
      </span>
    )

  return (
    <DesktopItemLayout
      data-component={dataComponent}
      className={isMe ? "pointer-default" : "pointer-cursor"}
      role="link"
      depth={depth}
      onClick={handleClick}
    >
      <UserAvatar user={user} />
      <UserName
        user={user}
        isMe={isMe}
        companyFlags={companyFlags}
        isMobile={isMobile}
        viewMode={viewMode}
      />
      {canShowTeam ? (
        <ToggleShowTeamContainer>
          <ToggleShowTeam
            className={showTeam ? "rotated" : ""}
            onClick={(e) => {
              e.stopPropagation()
              setTeamVisibility(user.id, !showTeam)
            }}
          />
        </ToggleShowTeamContainer>
      ) : (
        <span />
      )}
      <Actions
        user={user}
        slots={slots}
        isMe={isMe}
        isTeamsApp={isTeamsApp}
        isMobile={isMobile}
      />
    </DesktopItemLayout>
  )
}
