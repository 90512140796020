import useCompanyIntegrations from "graphql/integrations/useCompanyIntegrations"
import React from "react"
import styled from "styled-components"
import { useTeamsAppContext } from "TeamsApp/TeamsAppContext"
import { IntegrationEvent } from "types"
import { colors, LU14, P16, P16Bold, PopperMenu, transitions } from "ui"
import { Celebration, Google, MeetingRoom, Microsoft } from "ui/icons"
import { isSingleDayEvent } from "utils/events"

const List = styled.ul`
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  border-radius: 8px;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.06), 0px 2px 2px rgba(0, 0, 0, 0.03);
`

const Item = styled.li`
  display: grid;
  grid-template-columns: auto 1fr auto;
  padding: 10px 16px;
  gap: 16px;
  border-bottom: solid 1px ${colors.grey3};
  min-height: 59px;
  cursor: pointer;

  &:last-child {
    border: none;
  }

  > div:first-child {
    // dates
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  > div:nth-child(2) {
    // title
    display: flex;
    flex-direction: column;
    gap: 6px;
    max-width: 20ch;

    p {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  svg:last-child {
    width: 20px;
    height: 20px;
  }

  &.no-pointer {
    cursor: default;
    pointer-events: none;
  }
  &:hover {
    background: ${colors.grey4};
  }

  transition: ${transitions.fade};
`

interface ChildrenPropsType {
  event: IntegrationEvent
}

function DateSection({ event }: ChildrenPropsType) {
  if (!isSingleDayEvent(event)) {
    return (
      <div>
        <LU14 color="purple">{event.from.format("DD/MM")}</LU14>
        <LU14 color="purple">{event.to.format("DD/MM")}</LU14>
      </div>
    )
  }

  if (event.isAllDay)
    return (
      <div>
        <LU14 color="purple">{event.from.format("DD/MM")}</LU14>
      </div>
    )

  return (
    <div>
      <LU14 color="purple">{event.from.format("HH:MM")}</LU14>
      <LU14 color="purple">{event.to.format("HH:MM")}</LU14>
    </div>
  )
}

function Title({ event }: ChildrenPropsType) {
  if (event.roomId || event.location !== "")
    return (
      <div>
        <P16Bold>{event.name}</P16Bold>
        <P16 color="grey1">{event.location}</P16>
      </div>
    )

  return (
    <div>
      <P16Bold>{event.name}</P16Bold>
    </div>
  )
}

function Pictogram({ event }: ChildrenPropsType) {
  const { companyIntegrations } = useCompanyIntegrations()
  if (event.initiator === "offishall-meeting-rooms")
    return <MeetingRoom stroke={colors.purple} />
  if (event.initiator === "offishall-events")
    return <Celebration fill={colors.purple} />
  if (companyIntegrations.find(({ name }) => name === "google-events"))
    return <Google />
  if (companyIntegrations.find(({ name }) => name === "microsoft-outlook"))
    return <Microsoft />
  return <></>
}

interface PropsType {
  open: boolean
  anchorEl: HTMLElement | null
  events: IntegrationEvent[]
  onLeave: () => void
  onSelectEvent: (event: IntegrationEvent) => void
}

export default function EventsOfTheDayPopup({
  open,
  anchorEl,
  events,
  onLeave,
  onSelectEvent,
}: PropsType) {
  const { isTeamsApp } = useTeamsAppContext()

  if (events.length === 0 || !open) return <></>

  return (
    <PopperMenu
      open={open}
      onClose={onLeave}
      anchorEl={anchorEl}
      style={{
        zIndex: 1300,
      }}
      onMouseLeave={onLeave}
      disablePortal
      hideBackground
      placement="bottom"
    >
      <List>
        {events.map((event) => (
          <Item
            key={`popup-event-item-${event.id}`}
            className={isTeamsApp ? "no-pointer" : undefined}
            onClick={(e) => {
              e.stopPropagation()
              if (isTeamsApp) return
              onSelectEvent(event)
            }}
          >
            <DateSection event={event} />
            <Title event={event} />
            <Pictogram event={event} />
          </Item>
        ))}
      </List>
    </PopperMenu>
  )
}
