import { gql } from "@apollo/client"
import { LocationFragment } from "graphql/locations/fragments/locationFragment"

export const TeamDayFragment = gql`
  ${LocationFragment}
  fragment TeamDayFragment on TeamDay {
    id
    date
    userIds
    managerId
    location {
      ...LocationFragment
    }
  }
`
