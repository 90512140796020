import useTranslate from "intl/useTranslate"
import EmailInputText from "pages/AdminPage/UsersPage/NewEmployees/EmailInputText/EmailInputText"
import React, { useRef, useState } from "react"
import styled from "styled-components"
import { colors, P16, transitions } from "ui"
import { Trash } from "ui/icons"

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 16px 0;

  > fieldset:first-child {
    // email input
    padding: 0 16px;
  }
`

const EmailsList = styled.ul`
  display: flex;
  padding: 16px 0;
  gap: 8px;
  flex-wrap: wrap;
`

const EmailItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: ${colors.grey4}00;
  padding: 4px 16px;

  &:hover {
    background: ${colors.grey4};
  }

  > svg:last-child {
    // trash icon
    stroke: ${colors.grey2};
    cursor: pointer;
    transition: inherit;

    &:hover {
      stroke: ${colors.redAlert};
    }
  }

  transition: ${transitions.fade};
`

interface PropsType {
  emails: string[]
  onAddEmail: (email: string) => void
}

export default function EmailsPicker({ emails, onAddEmail }: PropsType) {
  const t = useTranslate()
  const [emailInput, setEmailInput] = useState<{
    email: string
    error?: boolean
  }>({
    email: "",
    error: undefined,
  })
  const [mutatedList, setMutatedList] = useState<string[]>([...emails])
  const [formError, setFormError] = useState<string | undefined>(undefined)
  const userEmailInputRef = useRef<HTMLInputElement>(null)

  const disableAddBtn = emailInput.error ?? false

  return (
    <Form onSubmit={(e) => e.preventDefault()}>
      <EmailInputText
        label={t("Email")}
        inputRef={userEmailInputRef}
        value={emailInput.email}
        initialValue={emailInput.email}
        placeholder="someone@something.com"
        autoFocus
        onChange={(email, error) => setEmailInput({ email, error })}
        externalError={formError}
        resetExternalError={() => setFormError(undefined)}
        onAddEmail={() => {
          if (emailInput.email === "" || disableAddBtn) return

          const duplicated = mutatedList.find(
            (email) => email === emailInput.email
          )
          if (duplicated) {
            userEmailInputRef.current?.blur()
            return setFormError(t("email already present"))
          }

          setMutatedList((prev) => [
            ...prev.filter((email) => email !== emailInput.email),
            emailInput.email,
          ])
          setFormError(undefined)
          setEmailInput({ email: "", error: undefined })
          onAddEmail(emailInput.email)
        }}
      />

      <EmailsList>
        {mutatedList.map((email, index) => (
          <EmailItem key={index}>
            <P16>{email}</P16>
            <Trash
              onClick={() =>
                setMutatedList((prev) => [...prev.filter((e) => e !== email)])
              }
            />
          </EmailItem>
        ))}
      </EmailsList>
    </Form>
  )
}
