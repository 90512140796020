import useTranslate from "intl/useTranslate"
import React from "react"
import { UpsertEventInput } from "types"
import { colors, FieldsetRow, P14Bold, Toggle } from "ui"

interface PropsType {
  inputs: UpsertEventInput
  setInputs: (inputs: UpsertEventInput) => void
}

export default function Privacy({ inputs, setInputs }: PropsType) {
  const t = useTranslate()

  return (
    <FieldsetRow style={{ width: "100%", justifyContent: "space-between" }}>
      <P14Bold style={{ marginBottom: 0, color: colors.grey1 }}>
        {t("event privacy")}
      </P14Bold>
      <Toggle
        checked={inputs.isPrivate}
        onChange={() => setInputs({ ...inputs, isPrivate: !inputs.isPrivate })}
      />
    </FieldsetRow>
  )
}
