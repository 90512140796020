import "./PacmanLike.css"

import useCompanyFlag from "hooks/useCompanyFlag"
import usePacman from "hooks/usePacman"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { mediaQueries } from "ui"

const PacmanLikeLayout = styled.div`
  position: fixed;
  width: 40px;
  height: 40px;

  // absolute positioning
  // --pacman-x sets when parent container has coordinates
  // when dragging --pacman-x is dynamically updated
  top: var(--pacman-y);
  left: calc(var(--pacman-x) - 16px);
  z-index: 3000;
  visibility: var(--pacman-visibility);
  pointer-events: none;
  // pading needed to fine tuned position
  // since parent container most probably has padding
  // pacman then needs to be centered with padded content
  padding: 4px 0 0 4px;

  img {
    width: 40px;
    height: 40px;
    // flips it towards direction
    -webkit-transform: scaleX(var(--pacman-direction));
    transform: scaleX(var(--pacman-direction));
  }

  @media ${mediaQueries.isMobile} {
    visibility: "visible";
    ${() => {
      if (location.pathname.startsWith("/user/")) {
        return "padding: 4px 0 0 0;"
      }
      return "padding: 4px 0 0 0;"
    }}
  }
`

const CarambarLayout = styled(PacmanLikeLayout)`
  width: 100px;
  height: 100px;

  img {
    width: 100px;
    height: 100px;
    translate: 0 -60px;
  }
`

export default function PacmanLike() {
  const animatedGif = useCompanyFlag("customAnimatedGif")
  const [error, setError] = useState<string | undefined>(undefined)
  const { reset, position } = usePacman()

  const Layout =
    animatedGif === "carambar-cropped.gif" ? CarambarLayout : PacmanLikeLayout

  useEffect(() => {
    return () => {
      reset()
    }
  }, [reset, position])

  if (!error && animatedGif)
    return (
      <Layout>
        <img
          src={`/static/img/pacman_like/${animatedGif}`}
          onError={() => {
            setError(`image source not found: ${animatedGif}`)
          }}
        />
      </Layout>
    )

  return (
    <Layout>
      <img src="/static/img/pacman_like/pacman.gif" />
    </Layout>
  )
}
