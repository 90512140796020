import { LocationIconFromId } from "components/LocationIcon"
import useTranslate from "intl/useTranslate"
import React from "react"
import { UserFavorites } from "types"
import { Group as GroupIcon, Lifebelt, User as UserIcon } from "ui/icons"
import sortByKey from "utils/sortByKey"

import { MenuList } from "../components/MenuList"
import { MenuTitle } from "../components/MenuTitle"
import FavoriteUsersMenuItem from "../FavoriteUsersMenuItem/FavoriteUsersMenuItem"
import MenuListItem from "../MenuListItem/MenuListItem"

interface PropsType {
  favorites: UserFavorites
}
export default function MenuFavorites({ favorites }: PropsType) {
  const t = useTranslate()

  const favoritesToDisplay = [...favorites.groups, ...favorites.locations].map(
    (favorite) => {
      let icon = null
      const iconStrokeWidth = location.pathname.includes(`${favorite.itemId}`)
        ? 2
        : 1.5
      if (favorite.type === "user")
        icon = <UserIcon strokeWidth={iconStrokeWidth} />
      else if (favorite.type === "group")
        icon = <GroupIcon strokeWidth={iconStrokeWidth} />
      else if (favorite.type === "location")
        icon = (
          <LocationIconFromId
            locationId={favorite.itemId}
            strokeWidth={iconStrokeWidth}
          />
        )
      else icon = <Lifebelt />
      return {
        ...favorite,
        icon,
      }
    }
  )

  return (
    <MenuList>
      <MenuTitle path="/favorites">{t("Favorites")}</MenuTitle>
      <FavoriteUsersMenuItem path="/planning/favorites" favorites={favorites} />

      {sortByKey(favoritesToDisplay, "itemName").map((favorite) => (
        <MenuListItem
          key={`my-favorite-${favorite.id}`}
          icon={favorite.icon}
          name={favorite.itemName}
          path={`/planning/${favorite.type}/${favorite.itemId}`}
        />
      ))}
    </MenuList>
  )
}
