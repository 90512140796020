import { Checkbox, Link as MuiLink } from "@mui/material"
import MobileNavBack from "components/Layout/PageLayout/PageHeader/PageTitle/MobileNavBack/MobileNavBack"
import PasswordInput from "components/PasswordInput"
import { getYupPassword } from "components/PasswordInput"
import { Formik } from "formik"
import useSetPassword from "graphql/users/useSetPassword"
import useWindowSize from "hooks/useWindowResize"
import useTranslate from "intl/useTranslate"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useHistory } from "react-router-dom"
import { Transition } from "react-transition-group"
import styled from "styled-components/macro"
import { Button, colors, Grid, P14, P16 } from "ui"
import { Cancel, CheckOff, CheckOn } from "ui/icons"
import { loginWithToken } from "utils/firebase"
import * as Yup from "yup"

import {
  Content,
  Footer,
  Header,
  MessageContainer,
  RightContainer,
  Subtitle,
  Title,
} from "./components/Layout"
import StepIndicator from "./components/StepIndicator"

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 18px;

  .input {
    display: flex;
    flex-direction: column;
  }
  .terms-of-service {
    display: flex;
    flex-direction: column;
    padding: 9px 0;
  }
`

const ConfirmationMessage = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  p {
    margin-left: 12px;
  }
`

const duration = 600

const defaultStyleSmallPopUp = {
  transition: `right ${duration}ms ease-in-out 400ms`,
  position: "absolute",
  top: "48%",
  width: "30%",
}
const defaultStyleMainPopUp = {
  transition: `right ${duration}ms ease-in-out 300ms`,
  position: "absolute",
  top: "25%",
}

const transitionStylesSmallPopUp: any = {
  entering: { right: 200 },
  entered: { right: 200 },
  exiting: { right: -300 },
  exited: { right: -300 },
}

const transitionStylesMainPop: any = {
  entering: { right: -110 },
  entered: { right: -110 },
  exiting: { right: -500 },
  exited: { right: -500 },
}

export default function OnboardingPassword() {
  const history = useHistory()
  const t = useTranslate()
  const intl = useIntl()
  const { setPassword, loading, error } = useSetPassword()
  const [, maxHeight] = useWindowSize()

  const [inProp, setInProp] = useState(false)

  useEffect(() => {
    setTimeout(() => setInProp(true), 1)
  }, [])

  return (
    <Formik
      initialValues={{
        password: "",
        confirmPassword: "",
        termsOfService: false,
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        password: getYupPassword(false, t),
        confirmPassword: Yup.string()
          .required(t("Confirm password is required"))
          .when("password", {
            is: (val: any) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
              [Yup.ref("password"), null],
              t("Both passwords need to be the same")
            ),
          }),
        termsOfService: Yup.boolean().oneOf(
          [true],
          t("You must accept terms of service")
        ),
      })}
      validateOnChange
      validateOnMount
      onSubmit={async ({ password }) => {
        const token = sessionStorage.getItem("signupToken")
        if (!token) {
          throw new Error("No token found")
        }
        const newToken = await setPassword(password, token)
        if (newToken) {
          sessionStorage.removeItem("signupToken")
          loginWithToken(newToken).then(() => {
            history.push("/onboarding/user/congrats")
          })
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <Grid container direction="row">
          <Grid
            item
            xs={12}
            md={8}
            xl={9}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Header>
              <MobileNavBack />
            </Header>
            <Content maxHeight={maxHeight}>
              <MessageContainer>
                <Title>{t("user-onboarding-password-title")}</Title>
                <Subtitle>{t("user-onboarding-password-p-1")}</Subtitle>
                <Subtitle>{t("user-onboarding-password-p-2")}</Subtitle>
              </MessageContainer>
              <>
                <Form>
                  {(errors.submit || error) && (
                    <ConfirmationMessage>
                      <Cancel />
                      <P16>{errors.submit || error?.message}</P16>
                    </ConfirmationMessage>
                  )}
                  <div className="input">
                    <PasswordInput
                      name="password"
                      fullWidth
                      isFormik
                      useB4BPasswords={false}
                      newPassword={true}
                      placeholder={t("Password")}
                      value={values.password}
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                      onBlur={handleBlur}
                      handleChange={handleChange}
                      variant="outlined"
                      size="small"
                    />
                  </div>
                  <div className="input">
                    <PasswordInput
                      name="confirmPassword"
                      fullWidth
                      isFormik
                      useB4BPasswords={false}
                      newPassword={true}
                      placeholder={t("Confirm Password")}
                      value={values.confirmPassword}
                      error={Boolean(
                        touched.confirmPassword && errors.confirmPassword
                      )}
                      helperText={
                        touched.confirmPassword && errors.confirmPassword
                      }
                      onBlur={handleBlur}
                      handleChange={handleChange}
                      variant="outlined"
                      size="small"
                    />
                  </div>
                </Form>
                <div className="terms-of-service">
                  <Grid container direction="row" alignItems="center">
                    <Checkbox
                      name="termsOfService"
                      value={values.termsOfService}
                      onChange={(e) => {
                        handleChange(e)
                      }}
                      style={{ color: colors.black, padding: "0 9px 0 0" }}
                      icon={<CheckOff />}
                      checkedIcon={<CheckOn />}
                    />
                    {intl.locale === "fr" && (
                      <P14>
                        J&apos;accepte les
                        <MuiLink
                          href="https://offishall.io/conditions-generales-dutilisation-du-compte"
                          target="_blank"
                        >
                          &nbsp;conditions d&apos;utilisation
                        </MuiLink>
                      </P14>
                    )}
                    {intl.locale === "en" && (
                      <P14>
                        I accept Offishall&apos;s
                        <MuiLink
                          href="https://en.offishall.io/conditions-generales-dutilisation-du-compte"
                          target="_blank"
                        >
                          &nbsp;terms of service
                        </MuiLink>
                      </P14>
                    )}
                  </Grid>
                </div>
              </>
            </Content>
            <Footer>
              <StepIndicator />

              <Button
                disabled={
                  loading ||
                  errors.password !== undefined ||
                  errors.confirmPassword !== undefined ||
                  errors.termsOfService !== undefined
                }
                onClick={handleSubmit}
              >
                {t("continue")}
              </Button>
            </Footer>
          </Grid>

          <RightContainer item xs={0} md={4} xl={3}>
            <Transition in={inProp} timeout={duration}>
              {(state) => (
                <div
                  style={{
                    ...defaultStyleMainPopUp,
                    ...transitionStylesMainPop[state],
                  }}
                >
                  <img
                    alt="Planning page"
                    src="/static/img/onboarding/pop_up_1_step1.svg"
                  />
                </div>
              )}
            </Transition>
            <Transition in={inProp} timeout={duration}>
              {(state) => (
                <div
                  style={{
                    ...defaultStyleSmallPopUp,
                    ...transitionStylesSmallPopUp[state],
                  }}
                >
                  <img
                    alt="Define slot value"
                    src="/static/img/onboarding/pop_up_step1.svg"
                  />
                </div>
              )}
            </Transition>
          </RightContainer>
        </Grid>
      )}
    </Formik>
  )
}
