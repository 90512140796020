import useProtectedRoute from "./useProtectedRoute"

export default function useResetPassword(): (
  email: string,
  locale?: string
) => void {
  const { callRoute } = useProtectedRoute(
    "resetPassword",
    "/auth/user/reset-password",
    "POST"
  )
  return (email: string, locale?: string) => {
    callRoute(locale ? { email, locale } : { email })
  }
}
