import { Dialog as MuiDialog, PaperProps } from "@mui/material"
import useMediaQueries from "hooks/useMediaQueries"
import React from "react"
import styled from "styled-components"
import { colors, P16 } from "ui"
import { Cancel } from "ui/icons"

import mediaQueries from "../mediaQueries"

const Layout = styled.div`
  overflow-y: hidden;
  overflow-x: hidden;
  display: grid;
  grid-template-rows: min-content auto min-content;
  min-height: 100%;

  > :nth-child(2) {
    overflow-y: scroll;
  }

  @media ${mediaQueries.isMobile} {
    &.layout--fullscreen {
      max-height: unset;
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
`

interface FooterProps {
  leftButton?: JSX.Element
  rightButton?: JSX.Element
}

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;
  background: ${colors.grey4};

  svg {
    stroke: ${colors.grey1};

    &:hover {
      stroke: ${colors.black};
      cursor: pointer;
    }
  }

  @media ${mediaQueries.isMobile} {
    &.header--bigger {
      padding: 16px 24px;
    }
  }
`
const Content = styled.div`
  overflow: scroll;
`

const Footer = styled.div<FooterProps>`
  display: flex;
  flex-direction: row;
  gap: 8px;
  bottom: 0px;
  width: 100%;
  background-color: ${colors.white};

  > *:first-child {
    ${({ leftButton, rightButton }) => {
      if (leftButton && rightButton) return "margin-right: auto;"
      return "margin-left: auto;"
    }}
  }
  border-top: 1px solid ${colors.grey3};
  padding: 15px;

  @media ${mediaQueries.isMobile} {
    padding: 8px;
    margin-top: auto;
  }
`

interface PropsType {
  open: boolean
  width?: string
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false
  children: React.ReactNode
  title?: string
  leftButton?: JSX.Element
  rightButton?: JSX.Element
  stopEventPropagation?: boolean
  fullscreen?: boolean
  forceToMaxHeight?: boolean
  transparentBackdrop?: boolean
  onClose?: () => void
}

export function Dialog({
  open,
  width,
  maxWidth,
  children,
  onClose,
  leftButton,
  rightButton,
  title,
  stopEventPropagation,
  fullscreen,
  forceToMaxHeight,
  transparentBackdrop,
}: PropsType) {
  const { isMobile } = useMediaQueries()

  const getLayoutClassName = () => {
    const classList = []
    if (fullscreen) classList.push("layout--fullscreen")
    return classList.length > 0 ? classList.join(" ") : ""
  }

  const getDialogPaperProps = (): PaperProps => {
    if (fullscreen && isMobile)
      return {
        style: {
          margin: 0,
          width: "100vw",
          position: "absolute",
          inset:
            "env(safe-area-inset-top, 0) 0 env(safe-area-inset-bottom, 0) 0",
          borderRadius: "0px",

          maxHeight:
            "calc(100vh - env(safe-area-inset-top, 0) - env(safe-area-inset-bottom, 0))",
          maxWidth: "100vw",
          boxShadow: "none",
        },
      }

    if (isMobile)
      return {
        style: {
          width: 400,
          borderRadius: "8px",
          boxShadow:
            "0px 2px 16px rgba(0, 0, 0, 0.24), 0px 2px 2px rgba(0, 0, 0, 0.12)",
        },
      }

    return {
      style: {
        width: width ? width : "100%",
        borderRadius: "8px",
        boxShadow:
          "0px 2px 16px rgba(0, 0, 0, 0.24), 0px 2px 2px rgba(0, 0, 0, 0.12)",
        minHeight: forceToMaxHeight
          ? "calc(95vh - env(safe-area-inset-top, 0) - env(safe-area-inset-bottom, 0))"
          : "unset",
        height: forceToMaxHeight
          ? "calc(95vh - env(safe-area-inset-top, 0) - env(safe-area-inset-bottom, 0))"
          : "unset",
        maxHeight:
          "calc(95vh - env(safe-area-inset-top, 0) - env(safe-area-inset-bottom, 0))",
      },
    }
  }

  return (
    <MuiDialog
      open={open}
      onClose={(e: Event) => {
        e.stopPropagation()
        if (onClose) {
          onClose()
        }
      }}
      PaperProps={getDialogPaperProps()}
      hideBackdrop={fullscreen}
      BackdropProps={
        transparentBackdrop
          ? {
              style: {
                opacity: "0",
              },
            }
          : undefined
      }
      maxWidth={maxWidth ?? undefined}
    >
      <Layout
        className={getLayoutClassName()}
        onClick={(e) => {
          if (stopEventPropagation) e.stopPropagation()
        }}
      >
        {title && (
          <Header className={fullscreen ? "header--bigger" : ""}>
            <P16>{title}</P16>
            {onClose && (
              <Cancel onClick={onClose} style={{ color: colors.black }} />
            )}
          </Header>
        )}
        <Content>{children}</Content>
        {(leftButton || rightButton) && (
          <Footer leftButton={leftButton} rightButton={rightButton}>
            {leftButton}
            {rightButton}
          </Footer>
        )}
      </Layout>
    </MuiDialog>
  )
}
