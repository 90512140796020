import useAllGroups from "graphql/groups/useAllGroups"
import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import React from "react"
import { GroupSimple } from "types"
import { Group as GroupIcon } from "ui/icons"
import sortByKey from "utils/sortByKey"

import CreateNewGroup from "../components/CreateNewGroup"
import { MenuList } from "../components/MenuList"
import { MenuTitle } from "../components/MenuTitle"
import MenuListItem from "../MenuListItem/MenuListItem"
import ViewMoreGroups from "./ViewMoreGroups/ViewMoreGroups"

interface PropsType {
  myGroups: GroupSimple[]
  myTeam: GroupSimple | undefined
  myManagerTeam: GroupSimple | undefined
  everyoneGroup: GroupSimple | undefined
  hideEveryoneGroup: boolean
}

export default function MenuGroups({
  myGroups,
  myTeam,
  myManagerTeam,
  everyoneGroup,
  hideEveryoneGroup,
}: PropsType) {
  const t = useTranslate()

  const { user: me } = useUser()
  const { groups: allGroups } = useAllGroups()

  const everyoneGroupLast = me?.company.flags.openEveryoneGroup === false

  const groupsToDisplay = myGroups.filter(
    (group) =>
      group.id !== "everyone" &&
      group.id !== "myteam" &&
      group.id !== "mymanagerteam"
  )

  const notMyGroups = allGroups.filter(
    (group) => !myGroups.find((g) => g.id === group.id)
  )

  const showEveryoneGroup =
    everyoneGroup && !hideEveryoneGroup && (myTeam || myManagerTeam)

  const showCreateGroupButton = notMyGroups.length === 0

  return (
    <MenuList>
      <MenuTitle path="/groups">{t("Groups")}</MenuTitle>

      {showEveryoneGroup && !everyoneGroupLast && (
        <MenuListItem
          icon={<GroupIcon />}
          name={everyoneGroup.name}
          path={`/planning/group/${everyoneGroup.id}`}
        />
      )}

      {sortByKey(groupsToDisplay).map((group) => {
        return (
          <MenuListItem
            key={`my-group-${group.id}`}
            icon={<GroupIcon />}
            name={group.name}
            path={`/planning/group/${group.id}`}
          />
        )
      })}

      {showEveryoneGroup && everyoneGroupLast && (
        <MenuListItem
          icon={<GroupIcon />}
          name={everyoneGroup.name}
          path={`/planning/group/${everyoneGroup.id}`}
        />
      )}
      {showCreateGroupButton ? (
        <CreateNewGroup />
      ) : (
        <ViewMoreGroups groups={notMyGroups} />
      )}
    </MenuList>
  )
}
