import Drawer from "@mui/material/Drawer"
import useMediaQueries from "hooks/useMediaQueries"
import useWindowSize from "hooks/useWindowResize"
import React from "react"
import styled from "styled-components"
import { P16 } from "ui"
import colors from "ui/colors"
import { Cancel } from "ui/icons"
import mediaQueries from "ui/mediaQueries"
import getSafeAvailableHeightCSSProperties from "utils/getSafeAvailableHeightCSSProperties"

const Layout = styled.div<{ availableHeight: number }>`
  display: grid;
  grid-template-rows: auto 1fr auto;
  flex-direction: column;
  min-width: 500px;
  overflow: hidden;
  height: 100vh;
  max-height: 100vh;
  ${({ availableHeight }) =>
    getSafeAvailableHeightCSSProperties(availableHeight)}

  > div:nth-child(2) {
    overflow-y: scroll;
  }
  @media ${mediaQueries.isMobile} {
    min-width: 75vw;
  }
`

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 24px;
  border-bottom: 1px solid ${colors.grey3};
  svg {
    stroke: ${colors.grey2};
    &:hover {
      cursor: pointer;
      stroke: ${colors.grey1};
    }
  }
  @media ${mediaQueries.isMobile} {
    padding: 16px;
    gap: 16px;
    justify-content: flex-start;
  }
`

const Content = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 14px;

  @media ${mediaQueries.isMobile} {
    padding: 8px;
  }
`

interface FooterProps {
  leftButton?: JSX.Element
  rightButton?: JSX.Element
}

const Footer = styled.div<FooterProps>`
  display: flex;
  gap: 8px;
  padding: 16px 24px;
  width: 100%;
  background: ${colors.white};

  > *:first-child {
    ${({ leftButton, rightButton }) => {
      if (leftButton && rightButton) return "margin-right: auto;"
      return "margin-left: auto;"
    }}
  }
  border-top: 1px solid ${colors.grey3};

  @media ${mediaQueries.isMobile} {
    padding: 8px;
    margin-top: auto;
  }
`

const MobileActionsFooter = styled.footer`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px;
  border-top: solid 1px ${colors.grey3};

  @media ${mediaQueries.isMobile} {
    > button {
      width: 100%;
    }
  }
`

interface PropsType {
  leftButton?: JSX.Element
  rightButton?: JSX.Element
  children: React.ReactElement
  open: boolean
  title?: string
  contentStyle?: React.CSSProperties
  onClose: () => void
}

export function AdminPopup({
  leftButton,
  rightButton,
  children,
  open,
  contentStyle,
  title,
  onClose,
}: PropsType) {
  const { isMobile } = useMediaQueries()
  const [, availableHeight] = useWindowSize()

  const mobilePaperStyle: React.CSSProperties = {
    inset: "env(safe-area-inset-top, 0) 0 env(safe-area-inset-bottom, 0) 0",
    borderRadius: "0px",
    maxHeight:
      "calc(100vh - env(safe-area-inset-top, 0) - env(safe-area-inset-bottom, 0))",
    boxShadow: "none",
  }

  const desktopPaperStyle: React.CSSProperties = {
    borderRadius: `16px 0px 0px 16px`,
  }

  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={onClose}
      style={{ maxWidth: "100vw" }}
      BackdropProps={{ style: { maxWidth: "100vw" } }}
      PaperProps={{
        style: isMobile ? mobilePaperStyle : desktopPaperStyle,
      }}
      hideBackdrop={isMobile}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <Layout availableHeight={availableHeight}>
        <Header>
          {isMobile && (
            <Cancel
              onClick={() => {
                onClose()
              }}
              style={{ color: colors.black }}
            />
          )}
          <P16>{title}</P16>
          {!isMobile && (
            <Cancel
              onClick={() => {
                onClose()
              }}
              style={{ color: colors.black }}
            />
          )}
        </Header>

        <Content style={contentStyle}>{children}</Content>

        {(leftButton || rightButton) &&
          (isMobile ? (
            <MobileActionsFooter>
              {leftButton}
              {rightButton}
            </MobileActionsFooter>
          ) : (
            <Footer leftButton={leftButton} rightButton={rightButton}>
              {leftButton}
              {rightButton}
            </Footer>
          ))}
      </Layout>
    </Drawer>
  )
}
