import { useApolloClient } from "@apollo/client"
import useLink from "hooks/useLink"
import React, { useEffect, useState } from "react"
import { logout } from "utils/firebase"

import LoaderOrErrorComponent from "./LoaderOrErrorComponent"

export default function Logout() {
  const [loading, setLoading] = useState(true)
  const client = useApolloClient()
  const goToHome = useLink("/")
  useEffect(() => {
    logout()
      .then(() => {
        sessionStorage.clear()
        client.clearStore()
        client.resetStore()
        setTimeout(() => {
          setLoading(false)
          goToHome()
        }, 1200)
      })
      .catch((error) => {
        console.error(error)
        setTimeout(() => {
          setLoading(false)
          goToHome()
        }, 1200)
      })
  }, [client, goToHome])
  return <LoaderOrErrorComponent loading={loading} error={null} />
}
