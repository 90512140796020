import { gql, useMutation } from "@apollo/client"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import { QUERY as LOCATIONS_AT_QUERY } from "graphql/locations/useLocationsAt"
import { QUERY as USERS_AT_QUERY } from "graphql/users/useUsersAt"
import useCustomErrorToast from "hooks/useCustomErrorToast"
import UTCDate from "utils/UTCDate"

const DELETE_BULK_SLOT_RULE_MUTATION = gql`
  mutation deleteBulkSlotRule($id: ID!) {
    deleteBulkSlotRule(id: $id)
  }
`

export default function useDeleteBulkSlotRule() {
  const {
    usersAtQuery: { from, to, userIds, locationIds, groupIds },
  } = usePlanningContext()

  const refetchVariables = {
    from: `${(from || new UTCDate()).getTime()}`,
    to: `${(to || new UTCDate()).getTime()}`,
    userIds: userIds && userIds.length > 0 ? userIds : undefined,
    locationIds,
    groupIds,
  }

  const { addToastError } = useCustomErrorToast()
  const [deleteRule] = useMutation<{
    deleteBulkSlotRule: string
  }>(DELETE_BULK_SLOT_RULE_MUTATION, {
    update(cache, { data }) {
      const { deleteBulkSlotRule: id } = data || {}
      if (!id) return

      const normalizedId = cache.identify({
        id,
        __typename: "BulkSlotRule",
      })
      cache.evict({ id: normalizedId })
    },
    refetchQueries: [
      {
        query: USERS_AT_QUERY,
        fetchPolicy: "network-only",
        variables: refetchVariables,
      },
      {
        query: LOCATIONS_AT_QUERY,
        fetchPolicy: "network-only",
        variables: refetchVariables,
      },
      "getBulkSlotRules",
    ],
  })
  return (id: string) => {
    return deleteRule({
      variables: {
        id,
      },
    }).catch(({ message }) => {
      addToastError({ message })
    })
  }
}
