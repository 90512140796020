import UTCDatePicker from "components/UTCDatePicker/UTCDatePicker"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { colors, mediaQueries, P14 } from "ui"
import { Calendar } from "ui/icons"
import { Label } from "ui/Typography"
import UTCDate, { days, months } from "utils/UTCDate"

const DisplayDate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  height: 40px;
  background: ${colors.white};
  border: 1px solid ${colors.grey2};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  &:hover {
    background: linear-gradient(0deg, ${colors.white}, ${colors.white}),
      ${colors.grey4};
    border: 2px solid ${colors.green};
    cursor: pointer;
    color: ${colors.black};
    padding: 9px;
  }
  p {
    color: ${colors.black};
  }
  .calendar {
    color: ${colors.grey2};
    height: 24px;
    width: 24px;
    margin-top: -2px;

    @media ${mediaQueries.isTouchable} {
      height: 22px;
      width: 22px;
    }
  }
`

interface PropsType {
  label: string
  onSelect: (date?: UTCDate) => void
  defaultDate?: UTCDate
  minDate?: UTCDate
  maxDate?: UTCDate
}

export default function TextfieldDatePicker({
  label,
  onSelect,
  defaultDate,
  minDate,
  maxDate,
}: PropsType) {
  const t = useTranslate()

  const today = new UTCDate()
  today.setUTCHours(0, 0, 0, 0)

  const [date, setDate] = useState<UTCDate>(defaultDate ? defaultDate : today)

  const [isOpen, setIsOpen] = useState(false)

  const handleClose = () => {
    setIsOpen(false)
  }

  const onChange = (date?: UTCDate) => {
    if (!date) return
    setDate(date)
    onSelect(date)
    setIsOpen(false)
  }

  const dateToDisplay = date && [
    days[date.getUTCDay()],
    date.getUTCDate(),
    months[date.getUTCMonth()],
    date.getUTCFullYear(),
  ]

  return (
    <>
      <Label>{label}</Label>
      <DisplayDate onClick={() => setIsOpen(true)}>
        <P14>
          {dateToDisplay
            ? dateToDisplay
                .map((d) => {
                  if (typeof d === "string") return t(d)
                  return d
                })
                .join(" ")
            : t("select a date")}
        </P14>

        <Calendar className="calendar" />
      </DisplayDate>
      {isOpen && (
        <UTCDatePicker
          open
          defaultDate={date}
          onSelect={onChange}
          minDate={minDate}
          maxDate={maxDate}
          onClose={handleClose}
        />
      )}
    </>
  )
}
