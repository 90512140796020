import FloorPlanProps from "components/FloorPlanComponents/FloorPlanProps"
import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import useUserFavorites from "graphql/favorites/useUserFavorites"
import useLocationsTree from "graphql/locations/useLocationsTree"
import React from "react"
import styled from "styled-components"
import LocationTree from "utils/LocationTree"

import CategoryItem from "./CategoryItem"
import LocationItem from "./LocationItem"

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px 8px;
`

interface PropsType {
  withActions?: boolean
  showFavoriteCheckbox?: boolean
  favoritesFilter?:
    | "favorites-only"
    | "favorites-excluded"
    | "favorites-included"
  showArchivedLocations?: boolean
  externalLocationsArray?: LocationTree[]
  floorPlan?: FloorPlanProps
  selectedZone?: LocationTree
  setSelectedZone?: (zone: LocationTree) => void
}

export default function LocationsList({
  withActions,
  showFavoriteCheckbox,
  favoritesFilter,
  externalLocationsArray,
  showArchivedLocations = false,
  floorPlan,
  selectedZone,
  setSelectedZone,
}: PropsType) {
  const { error, loading, locations } = useLocationsTree(!showArchivedLocations)

  const { favorites } = useUserFavorites()

  const favoriteLocations = favorites.locations
    .map((favorite) => LocationTree.getLocationNode(locations, favorite.itemId))
    .filter((l) => l !== undefined) as LocationTree[]

  const notFavoriteLocations = locations
    .filter(
      (location) =>
        !favoriteLocations.find((favorite) => favorite.id === location.id)
    )
    .filter((l) => l !== undefined) as LocationTree[]

  if (error || loading)
    return <LoaderOrErrorComponent loading={loading} error={error} />

  const getLocationsToDisplay = () => {
    if (externalLocationsArray) return externalLocationsArray
    if (
      favoritesFilter === undefined ||
      favoritesFilter === "favorites-included"
    )
      return locations
    if (favoritesFilter === "favorites-excluded") return notFavoriteLocations
    if (favoritesFilter === "favorites-only") return favoriteLocations
    return locations
  }

  return (
    <>
      <li>
        <List>
          {getLocationsToDisplay()
            .filter((l) => !l.isCategory)
            .map((location) => (
              <li key={`root-location-${location.id}`}>
                <LocationItem
                  location={location}
                  withActions={withActions}
                  showFavoriteCheckbox={showFavoriteCheckbox}
                  floorPlan={floorPlan}
                />
              </li>
            ))}
        </List>
      </li>
      <li>
        <ul>
          {getLocationsToDisplay()
            .filter((l) => l.isCategory)
            .map((location) => (
              <li key={`root-category-${location.id}`}>
                <CategoryItem
                  category={location}
                  withActions={withActions}
                  showFavoriteCheckbox={showFavoriteCheckbox}
                  floorPlan={floorPlan}
                  selectedZone={selectedZone}
                  setSelectedZone={setSelectedZone}
                />
              </li>
            ))}
        </ul>
      </li>
    </>
  )
}
