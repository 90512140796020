import uniqBy from "lodash.uniqby"
import { UserWithTeam } from "types"

export default class UserTree {
  id: string
  email: string
  name: string
  role: string
  teamName: string
  isManager: boolean
  photo: string
  manager: UserWithTeam | null
  team: UserTree[]
  hierarchy: UserTree[]
  isCollaborator: () => boolean

  constructor(
    rawEntry: UserWithTeam,
    parent: UserTree | null,
    allRawEntries: UserWithTeam[]
  ) {
    this.manager = parent || null
    this.hierarchy = parent ? [...parent.hierarchy, parent] : []

    this.id = rawEntry.id
    this.name = rawEntry.name
    this.email = rawEntry.email
    this.role = rawEntry.role
    this.teamName = rawEntry.teamName
    this.isManager = rawEntry.isManager
    this.photo = rawEntry.photo

    if (rawEntry.team.length > 0) {
      // make children

      this.team = allRawEntries
        .filter((entry) => entry.manager?.id === this.id)
        .sort((one, other) => {
          return other.team.length - one.team.length
        })
        .map((entry) => {
          const user = new UserTree(entry, this, allRawEntries)
          this.hierarchy = parent ? [...parent.hierarchy, parent] : []

          return user
        })
    } else {
      this.team = []
    }
    this.isCollaborator = () => this.team.length === 0
  }

  // Returns an array, because this tree can have several roots
  static make(rawEntries: UserWithTeam[]): UserTree[] {
    const roots = rawEntries
      .filter((entry) => entry.manager === null)
      .sort((one, other) => {
        return other.team.length - one.team.length
      })
      .map((entry) => {
        return new UserTree(entry, null, rawEntries)
      })
    return roots
  }

  static getUserTreeManagers(userTree: UserTree[], managers: UserTree[] = []) {
    for (const user of userTree) {
      if (user.isManager) managers.push({ ...user })
      for (const teamMember of user.team) {
        if (teamMember.isManager) managers.push({ ...teamMember })
        UserTree.getUserTreeManagers(teamMember.team, managers)
      }
    }

    return uniqBy(managers, "id")
  }

  static getUserTreeCollaborators(
    userTree: UserTree[],
    collaborators: UserTree[] = []
  ) {
    for (const user of userTree) {
      if (user.isCollaborator()) collaborators.push({ ...user })
      for (const teamMember of user.team) {
        if (teamMember.isCollaborator()) collaborators.push({ ...teamMember })
        UserTree.getUserTreeCollaborators(teamMember.team, collaborators)
      }
    }

    return uniqBy(collaborators, "id")
  }

  static findUserById(roots: UserTree[], id: string): UserTree | undefined {
    for (const root of roots) {
      if (root.id === id) return { ...root }

      for (const child of root.team) {
        if (child.id === id) return child
        const f = UserTree.findUserById(child.team, id)
        if (f) return f
      }
    }
  }

  static findUserByEmail(
    roots: UserTree[],
    email: string
  ): UserTree | undefined {
    for (const root of roots) {
      if (root.email === email) return { ...root }

      for (const child of root.team) {
        if (child.email === email) return child
        const f = UserTree.findUserByEmail(child.team, email)
        if (f) return f
      }
    }
  }

  static getManagers(roots: UserTree[], userId: string): UserWithTeam[] {
    const user = UserTree.findUserById(roots, userId)
    if (!user || user.manager === null) {
      return []
    }

    return [user.manager, ...UserTree.getManagers(roots, user.manager.id)]
  }

  static isManagedBy(
    userTree: UserTree[],
    managerId?: string,
    userId?: string
  ): boolean {
    if (!managerId || !userId) return false
    const manager = UserTree.findUserById(userTree, managerId)
    if (!manager) return false
    for (const user of manager.team) {
      if (user.id === userId) return true
      if (UserTree.isManagedBy(userTree, user.id, userId)) return true
    }
    return false
  }
}
