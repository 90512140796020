import { ApolloError, gql, useMutation } from "@apollo/client"
import { MY_GROUPS_SIMPLE_QUERY } from "graphql/groups/useMyGroupsSimple"
import { GET_USER_QUERY } from "graphql/users/useUser"
import { useState } from "react"

import { UserFragment } from "./fragments/userFragment"

const MUTATION = gql`
  ${UserFragment}
  mutation setTeamName($teamName: String!) {
    setTeamName(teamName: $teamName) {
      ...UserFragment
    }
  }
`

export default function useSetTeamName() {
  const [mutate] = useMutation(MUTATION)
  const [error, setError] = useState<ApolloError | null>(null)
  const [loading, setLoading] = useState(false)
  const setTeamName = async (teamName: string) => {
    setLoading(true)
    try {
      await mutate({
        variables: { teamName },
        refetchQueries: [
          {
            query: GET_USER_QUERY,
            variables: { user: null },
          },
          {
            query: MY_GROUPS_SIMPLE_QUERY,
          },
        ],
      })
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }
  return {
    loading,
    error,
    setTeamName,
  }
}
