import HomeDialog from "components/HomeDialog/HomeDialog"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { useEffect } from "react"
import styled from "styled-components"
import { colors, L12, mediaQueries } from "ui"
import { Bubbles } from "ui/icons"
import LocalDate from "utils/LocalDate"

import { useHomeContext } from "../HomeDialog/components/HomeContextProvider"

const Button = styled.button`
  background: ${colors.purple};
  border: none;
  border-radius: 100%;
  padding: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  height: 56px;
  width: 56px;
  position: absolute;
  bottom: 28px;
  pointer-events: all;
  z-index: 1;
  svg {
    stroke: none;
    height: 24px;
    width: 24px;
  }

  @media ${mediaQueries.isDesktop} {
    position: fixed;

    right: 40px;
    bottom: 100px;
  }
`

export default function HomeButton() {
  const t = useTranslate()
  const { showHomeDialog, openHomeDialog } = useHomeContext()
  const { isDesktop } = useMediaQueries()

  useEffect(() => {
    // check for auto Home mount
    // if never seen on the current day open it
    const now = new LocalDate()

    const todayMorning = new LocalDate("DAY")
    todayMorning.shift("HOUR", 7)

    const lastTimeHomeWasDisplayed = Number(
      localStorage.getItem("lastTimeHomeWasDisplayed")
    )
    if (
      lastTimeHomeWasDisplayed < todayMorning.getTime() &&
      now.getTime() > todayMorning.getTime()
    ) {
      showHomeDialog()
    }
    // eslint-disable-next-line
  }, [])

  if (isDesktop && !location.pathname.includes("/planning")) return <></>

  return (
    <>
      <Button
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          localStorage.removeItem("lastTimeHomeWasDisplayed")
          showHomeDialog()
        }}
      >
        <Bubbles />
        <L12 color="white">{t("Reception")}</L12>
      </Button>
      {openHomeDialog && <HomeDialog />}
    </>
  )
}
