import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { SearchResult } from "types"
import { ChipButton, colors, RemovableChipButton } from "ui"

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

interface PropsType {
  usersToBeAdded: SearchResult[]
  onClick: (user: SearchResult) => void
}

export function AddedUsersList({ usersToBeAdded, onClick }: PropsType) {
  const t = useTranslate()
  const { user: me } = useUser()
  return (
    <Container>
      {usersToBeAdded.map((user) => {
        if (user.id === me?.id)
          return (
            <ChipButton key={user.id} active>
              <strong
                style={{
                  fontWeight: 400,
                  fontStyle: "normal",
                }}
              >
                {user.name}{" "}
              </strong>
              <em
                style={{
                  color: colors.grey1,
                  fontWeight: 400,
                  fontStyle: "normal",
                  paddingLeft: "6px",
                }}
              >
                {t("you")}
              </em>
            </ChipButton>
          )
        return (
          <RemovableChipButton
            key={user.id}
            active
            onRemove={() => onClick(user)}
          >
            {user.name}
          </RemovableChipButton>
        )
      })}
    </Container>
  )
}
