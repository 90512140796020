import styled from "styled-components/macro"
import { colors, mediaQueries } from "ui"

interface ButtonProps {
  language: string
}

export const ConnectionButton = styled.button<ButtonProps>`
  padding: 12px 8px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid ${colors.grey2};
  border-radius: 4px;
  height: 40px;
  margin-bottom: 8px;

  ${({ language }) => {
    return `width: ${language === "fr" ? "240px" : "200px"};`
  }}

  @media ${mediaQueries.isMobile} {
    ${({ language }) => {
      return `width: ${language === "fr" ? "240px" : "200px"};`
    }}
  }

  p {
    font-family: Roboto, "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;

    color: ${colors.grey1};
    text-align: center;
    margin-left: 18px;
  }
`
