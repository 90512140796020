import { ApolloError, gql, useQuery } from "@apollo/client"

export const QUERY = gql`
  query checkCompanyIntegration($name: String!) {
    checkCompanyIntegration(name: $name)
  }
`

export default function useCheckCompanyIntegration(
  name?: string,
  skip?: boolean
): {
  loading: boolean
  error?: ApolloError
  result?: string
} {
  const { loading, error, data } = useQuery<{
    checkCompanyIntegration?: string
  }>(QUERY, {
    variables: { name },
    skip: !name || skip,
    fetchPolicy: "network-only",
  })
  return {
    result: data?.checkCompanyIntegration || "",
    loading,
    error,
  }
}
