import { ApolloError, gql, useMutation } from "@apollo/client"
import { GET_USER_QUERY } from "graphql/users/useUser"
import { useState } from "react"
import { Flags } from "types"

const MUTATION = gql`
  mutation setAllowedUserFlags($flags: JSON!) {
    setAllowedUserFlags(flags: $flags)
  }
`

export default function useSetAllowedUserFlags() {
  const [mutate] = useMutation(MUTATION)
  const [error, setError] = useState<ApolloError | null>(null)
  const [loading, setLoading] = useState(false)
  const setAllowedUserFlags = async (flags: Flags) => {
    setLoading(true)
    try {
      await mutate({
        variables: { flags },
        refetchQueries: [
          {
            query: GET_USER_QUERY,
            variables: { user: null },
          },
        ],
      })
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }
  return {
    loading,
    error,
    setAllowedUserFlags,
  }
}
