import useLastTimeAtTheOffice from "graphql/teamDays/useLastTimeAtTheOffice"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { UserBase } from "types"
import { colors, Grid, mediaQueries, P14Bold, Table, TH, TR } from "ui"

import OneMember from "./OneMember/OneMember"

interface ListProps {
  headerheight: number | undefined
}

const List = styled(Grid)<ListProps>`
  border-right: 1px solid ${colors.grey3};
  border-top: 1px solid ${colors.grey3};
  padding-left: 32px;
  overflow: auto;
  ${({ headerheight }) => `height: calc(100vh - ${headerheight}px);`}
  ${({ headerheight }) => `max-height: calc(100vh - ${headerheight}px);`}

  @media ${mediaQueries.isMobile} {
    border: none;
    padding: 0;
  }
`

interface PropsType {
  users: UserBase[]
  headerHeight: number | undefined
}

export default function MembersList({ users, headerHeight }: PropsType) {
  const t = useTranslate()

  const usersId = users.map((el) => el.id)
  const { lastTimes, loading } = useLastTimeAtTheOffice({ userIds: usersId })

  const getLastTime = (userId: string) => {
    return lastTimes.find((lastTime) => lastTime.userId === userId)
  }

  return (
    <List item xs={12} md={8} headerheight={headerHeight}>
      <Table>
        <thead>
          <TR>
            <TH>
              <P14Bold>{t("Name")}</P14Bold>
            </TH>
            <TH>
              <P14Bold>{t("last meeting")}</P14Bold>
            </TH>
            <TH>
              <P14Bold>{t("last attendance")}</P14Bold>
            </TH>
          </TR>
        </thead>
        <tbody>
          {users.map((user) => (
            <OneMember
              loading={loading}
              key={user.id}
              user={user}
              lastTime={getLastTime(user.id)}
            />
          ))}
        </tbody>
      </Table>
    </List>
  )
}
