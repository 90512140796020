import "./index.css"
import "./ui/reset.css"

import { ApolloProvider } from "@apollo/client"
import DateFnsUtils from "@date-io/date-fns"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles"
import { StylesProvider } from "@mui/styles"
import AddToastProvider from "components/NotificationContextProvider/NotificationContextProvider"
import SignInWithSAMLIdpFlow from "PublicApp/SignInWithSAMLIdpFlow"
import React, { useEffect } from "react"
import ReactDOM from "react-dom"
import { Helmet } from "react-helmet"
import { IntlProvider } from "react-intl"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { ThemeProvider } from "styled-components/macro"
import { initMobileApp } from "utils/mobileApp"

import App from "./App/App"
import Logout from "./components/Logout"
import useTranslation from "./intl/useTranslation"
import PublicApp from "./PublicApp/PublicApp"
import reportWebVitals from "./reportWebVitals"
import TeamsAppLoader from "./TeamsApp/TeamsAppLoader"
import theme from "./theme"
import client from "./utils/apollo"
import { useAuth } from "./utils/firebase"

function Main() {
  const { authUser: user, loading } = useAuth()
  const [translation] = useTranslation()

  useEffect(() => {
    if (!loading && user !== undefined && user?.emailVerified !== false) {
      const el = document.querySelector(".loader-container")
      if (el) {
        el.remove()
      }
    }
  }, [loading, user])

  if (loading || user === undefined || user?.emailVerified === false) {
    return <div />
  }
  return (
    <>
      <Helmet
        htmlAttributes={{ lang: translation.id }}
        titleTemplate="%s | Offishall"
        defaultTitle="Offishall"
      />
      <StylesProvider injectFirst>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
              <IntlProvider
                messages={translation.messages}
                locale={translation.id}
                defaultLocale="en"
              >
                <AddToastProvider>
                  <Switch>
                    <Route path="/logout" exact>
                      <Logout />
                    </Route>
                    <Route path="/teams">
                      <TeamsAppLoader />
                    </Route>
                    <Route path="/signin/saml/idpflow">
                      <SignInWithSAMLIdpFlow />
                    </Route>
                    <Route path="/">
                      {user === null ? <PublicApp /> : <App />}
                    </Route>
                  </Switch>
                </AddToastProvider>
              </IntlProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </>
  )
}

ReactDOM.render(
  //<React.StrictMode>
  //  <ThemeProvider theme={theme}>
  <ApolloProvider client={client}>
    <Router>
      <Main />
    </Router>
  </ApolloProvider>,
  // </ThemeProvider>,
  //</React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

initMobileApp()
