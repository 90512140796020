import "./style.css"

import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components/macro"
import { Equipment } from "types"
import { colors, mediaQueries, P14Bold, P16Bold } from "ui"
import { AddUser } from "ui/icons"
import { getServiceIcon } from "utils/getServiceIcons"

import { EditableSlotTooltip } from "./EditableSlotTootlip/EditableSlotTootlip"

interface SlotEntryPropsType {
  value: string | null
  hoverInfo: string
}

const SlotEntry = styled.div<SlotEntryPropsType>`
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover .hover-info {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }

  .hover-info {
    position: absolute;
    display: flex;
    visibility: hidden;
    opacity: 0;
    transform: scale(0.95);

    &--with-transitions {
      transition-property: opacity scale;
      transition-delay: var(--toggle-active-location-in);
      transition-duration: var(--toggle-active-location-dt);
      transition-timing-function: ease-in-out;
    }

    pointer-events: none;
    background: ${colors.grey3};
    color: ${colors.black};
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    border-radius: 4px;
    top: 56px;
    z-index: 1500;

    &:after {
      ${({ hoverInfo }) => `content: "${hoverInfo}";`}
      text-align: center;
      padding: 4px 8px;
    }
  }
`

const SlotContentDiv = styled.div`
  margin: auto;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.32);
`

const NullSlot = styled.div`
  margin: auto;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: solid 4px currentColor;

  @media ${mediaQueries.isMobile} {
    width: 16px;
    height: 16px;
    border: solid 3px currentColor;
  }
`

const SlotContentDivWithEquipment = styled(EditableSlotTooltip)`
  margin: auto;
  width: 24px;
  height: 24px;
  border-radius: 100vh;
  background: rgba(255, 255, 255, 0.32);

  border: 2px solid rgba(255, 255, 255, 0.32);

  .equipment-div,
  p {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      stroke: ${colors.black};
      fill: ${colors.black};
      width: 16px;
      height: 16px;
      margin-top: 2px; // TO BE CHECKED
    }
  }

  .guests-div,
  p {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      stroke: ${colors.black};
      stroke-width: 2px;
      fill: transparent;
      width: 15px;
      height: 15px;
      margin-left: 2px;
      margin-top: 3px; // TO BE CHECKED

      @media ${mediaQueries.isMobile} {
        width: 14px;
        height: 14px;
        margin-top: 1px;
      }
    }
  }

  @media ${mediaQueries.isMobile} {
    width: 20px;
    height: 20px;

    .equipment-div {
      svg {
        width: 14px;
        height: 14px;
        margin-top: 1px; // TO BE CHECKED
      }
    }
    > p {
      margin-top: -1px;
    }
  }
`

interface SlotContentPropsType {
  value: string | null
  equipments: Equipment[]
  nbGuests: number
}

function SlotContent({ value, equipments, nbGuests }: SlotContentPropsType) {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()
  const tooltipContent = `${t("You booked")}: ${equipments
    .map((e) => e.name)
    .join(", ")}. ${
    nbGuests > 0
      ? `\n ${nbGuests} ${
          nbGuests > 1 ? t("Guests").toLowerCase() : t("Guest").toLowerCase()
        } `
      : ""
  }`

  if (!value || value === "null") return <NullSlot className="null-slot" />
  if (nbGuests > 0 && equipments.length === 0)
    return (
      <SlotContentDivWithEquipment
        className="slot-content"
        title={`${nbGuests} ${
          nbGuests > 1 ? t("Guests").toLowerCase() : t("Guest").toLowerCase()
        }`}
      >
        <div className="guests-div">
          <AddUser />
        </div>
      </SlotContentDivWithEquipment>
    )
  if (equipments.length > 0 && nbGuests > 0)
    return (
      <SlotContentDivWithEquipment
        className="slot-content"
        title={tooltipContent}
      >
        {isMobile ? (
          <P14Bold>{equipments.length + 1}</P14Bold>
        ) : (
          <P16Bold>{equipments.length + 1}</P16Bold>
        )}
      </SlotContentDivWithEquipment>
    )
  if (equipments.length > 1)
    return (
      <SlotContentDivWithEquipment
        className="slot-content"
        title={tooltipContent}
      >
        {isMobile ? (
          <P14Bold>{equipments.length}</P14Bold>
        ) : (
          <P16Bold>{equipments.length}</P16Bold>
        )}
      </SlotContentDivWithEquipment>
    )
  if (equipments.length === 1)
    return (
      <SlotContentDivWithEquipment
        className="slot-content"
        title={tooltipContent}
      >
        <div className="equipment-div">
          {getServiceIcon(equipments[0].icon).icon}
        </div>
      </SlotContentDivWithEquipment>
    )
  return <SlotContentDiv className="slot-content" />
}

interface PropsType extends SlotEntryPropsType {
  equipments: Equipment[]
  nbGuests: number
  isFirst: boolean
  isLast: boolean
  isSelected: boolean
  isActive: boolean
  showHoverInfo: boolean
  onMouseEnter: () => void
  onMouseLeave: () => void
}

export default function EditableUserSlotEntry({
  value,
  equipments,
  nbGuests,
  isSelected,
  isFirst,
  isLast,
  hoverInfo,
  isActive,
  showHoverInfo,
  onMouseEnter,
  onMouseLeave,
}: PropsType) {
  if (isSelected)
    return (
      <>
        <SlotEntry
          value={value}
          hoverInfo={hoverInfo}
          className={`
      cuse

      ${value ? `cuse--${value}` : `cuse--null`}
      cuse--selected
      ${
        isFirst
          ? isLast
            ? "cuse--selected--solo"
            : "cuse--selected--first"
          : ""
      }
      ${
        isLast
          ? isFirst
            ? "cuse--selected--solo"
            : "cuse--selected--last"
          : ""
      }
      ${!isFirst && !isLast ? "cuse--selected--between" : ""}
      `.trim()}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <SlotContent
            value={value}
            equipments={equipments}
            nbGuests={nbGuests}
          />
        </SlotEntry>
      </>
    )

  return (
    <>
      <SlotEntry
        value={value}
        hoverInfo={hoverInfo}
        className={`
      cuse
      ${isFirst ? (isLast ? "cuse--solo" : "cuse--first") : ""}
      ${isLast ? (isFirst ? "cuse--solo" : "cuse--last") : ""}
      ${value ? `cuse--${value}` : `cuse--null`}
      ${showHoverInfo ? (isActive ? "" : "cuse--not-active") : ""}
      `.trim()}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <SlotContent
          value={value}
          equipments={equipments}
          nbGuests={nbGuests}
        />
        {showHoverInfo && (
          <div className="hover-info hover-info--with-transitions" />
        )}
      </SlotEntry>
    </>
  )
}
