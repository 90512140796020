import SlotsEditorCategoryItem from "components/Collapses/LocationsCollapse/SlotsEditorCategoryItem"
import useEquipments from "graphql/equipments/useEquipments"
import useEquipmentsAvailability from "graphql/equipments/useEquipmentsAvailability"
import useLocationsTree from "graphql/locations/useLocationsTree"
import useOccupations from "graphql/slots/useOccupations"
import useTranslate from "intl/useTranslate"
import SlotsEditorLocation from "pages/PlanningPage/components/SlotsEditor/SlotsEditorContent/SlotsEditorLocation"
import React from "react"
import styled from "styled-components"
import { LocationSuggestion, WorkingMode } from "types"
import { L12, LU12, mediaQueries } from "ui"
import LocationTree from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

const Header = styled.header`
  display: flex;
  justify-content: space-between;

  @media ${mediaQueries.isMobile} {
    flex-direction: column;
  }
`
interface PropsType {
  from: UTCDate
  to: UTCDate
  locationId: string
  groupId: string | undefined
  suggestion: LocationSuggestion
  groupName?: string
  guestIds: string[]
  saveWorkingMode: (workingMode: WorkingMode) => void
}

export default function Suggestion({
  from,
  to,
  locationId,
  groupId,
  suggestion,
  groupName,
  guestIds,
  saveWorkingMode,
}: PropsType) {
  const t = useTranslate()

  const { locations } = useLocationsTree()
  const location = LocationTree.getLocationNode(locations, locationId)

  const { occupations } = useOccupations(
    from.getTime(),
    to.getTime(),
    "cache-first"
  )

  const { equipments } = useEquipments()

  const { equipmentsAvailability } = useEquipmentsAvailability({ from, to })

  const getGroupString = () => {
    if (groupId === "myteam") return t("of your team")
    if (groupId === "mymanagerteam") return t("of your manager team")
    if (groupId === "favorites") return t("of your favorites")
    if (groupName) return groupName
    return t("of the selected group")
  }

  const defineSuggestion = () => {
    if (suggestion.groupId) {
      if (suggestion.reason.includes("people")) {
        if (suggestion.value === 1)
          return t("locationSuggestion::max people in group singular", {
            nbMembers: `${suggestion.value}`,
            selectedGroup: getGroupString(),
          })
        else
          return t(suggestion.reason, {
            nbMembers: `${suggestion.value}`,
            selectedGroup: getGroupString(),
          })
      }
    } else if (locationId && !groupId) return t(suggestion.reason)
    else return ""
  }

  if (!location) return <></>
  return (
    <>
      <Header>
        <LU12>{t("suggestions")}</LU12>
        <L12 color="grey1">{defineSuggestion()}</L12>
      </Header>
      <div>
        {location.isCategory ? (
          <SlotsEditorCategoryItem
            category={location}
            companyLocations={locations}
            occupations={occupations}
            equipments={equipments}
            equipmentsAvailability={equipmentsAvailability}
            guestIds={guestIds}
            saveWorkingMode={saveWorkingMode}
            showFavoriteCheckbox
          />
        ) : (
          <SlotsEditorLocation
            isSelected={false}
            location={location}
            companyLocations={locations}
            occupations={occupations}
            equipments={equipments}
            equipmentsAvailability={equipmentsAvailability}
            guestIds={guestIds}
            showBreadCrumbs
            showFavoriteCheckbox
            saveWorkingMode={saveWorkingMode}
          />
        )}
      </div>
    </>
  )
}
