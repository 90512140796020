import useIndexedDays from "hooks/useIndexedDays"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { IndexedDay } from "types"
import { ChipButton, colors, FieldsetRow, P14Bold, Toggle } from "ui"

const List = styled.ul<{ disabled: boolean }>`
  display: flex;
  gap: 8px;
  ${({ disabled }) => (disabled ? `cursor: not-allowed;` : "")}
  li button {
    ${({ disabled }) =>
      disabled
        ? `
    pointer-events: none;
    color: ${colors.grey2};
    `
        : ""}
    p {
      color: inherit;
    }
  }
`

interface PropsType {
  selectedDays: IndexedDay[]
  disabled: boolean
  forceDisabled: boolean
  setDisabled: (checked: boolean) => void
  onUpdate: (pickablindexedDayeDays: IndexedDay) => void
}

export default function DaysSelection({
  selectedDays,
  disabled,
  forceDisabled,
  setDisabled,
  onUpdate,
}: PropsType) {
  const t = useTranslate()
  const indexedDays = useIndexedDays(true)

  return (
    <>
      <FieldsetRow style={{ justifyContent: "space-between" }}>
        <P14Bold>{t("hybrid-rule-label--restricted-days")}</P14Bold>
        <Toggle
          checked={!disabled}
          onChange={(checked) => {
            setDisabled(!checked)
          }}
          disabled={forceDisabled}
        />
      </FieldsetRow>
      {!disabled && (
        <FieldsetRow>
          <List disabled={disabled}>
            {indexedDays.map(({ name, index }) => {
              const active =
                selectedDays.find((d) => d.index === index) === undefined

              return (
                <li
                  key={`pickable-day-${name}`}
                  onClick={() => {
                    if (disabled) return
                    onUpdate({ name, index })
                  }}
                >
                  <ChipButton
                    subject={active ? "home" : undefined}
                    active={active}
                  >
                    {t(name)}
                  </ChipButton>
                </li>
              )
            })}
          </List>
        </FieldsetRow>
      )}
    </>
  )
}
