import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import usePlanningUserSlots from "graphql/slots/usePlanningUserSlots"
import useUser from "graphql/users/useUser"
import useUserFromId from "graphql/users/useUserFromId"
import usePlanningProgressFromTo from "hooks/usePlanningProgressFromTo"
import useTranslate from "intl/useTranslate"
import React from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import { User, UTCSlot } from "types"
import { colors, H4, L12, mediaQueries } from "ui"
import getUserDisplayName from "utils/getUserDisplayName"

import HybridRuleStatusTooltip from "../../../../components/HybridRuleStatus/HybridRuleStatus"
import OneDayEditableSlots from "../../components/EditableUserSlots/OneDayEditableSlots/OneDayEditableSlots"
import GaugeIcon from "./GaugeIcon/GaugeIcon"
import TypicalWeekButton from "./TypicalWeekButton/TypicalWeekButton"

const PlanningProgressContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0px;
  width: 100%;
  height: 88px;
  padding: 0 16px;

  border-right: solid 1px ${colors.grey3};

  @media ${mediaQueries.isNarrowScreen} {
    padding: 0 16px;
    gap: 8px;

    h4 {
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media ${mediaQueries.isMobile} {
    height: 72px;
    border-bottom: 1px solid ${colors.grey3};
    padding: 0 0 0 6px;
  }
  &:after {
    display: none;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media ${mediaQueries.isTouchable} {
    margin-right: auto;
    width: fit-content;
  }
`

const StyledL12 = styled(L12)`
  @media ${mediaQueries.isTouchable} {
    color: ${colors.grey1};
  }
`

interface PropsType {
  selectedUser: User
  nullSlots: UTCSlot[]
  loading: boolean
}

export function PlanningProgress({
  selectedUser,
  nullSlots,
  loading,
}: PropsType) {
  const t = useTranslate()

  const { from, to, userShowWeekends, openDrawer, setOpenDrawer, viewMode } =
    usePlanningContext()

  return (
    <PlanningProgressContainer
      onClick={() => {
        if (openDrawer !== null) setOpenDrawer(null)
      }}
    >
      <GaugeIcon
        weekSlotsLength={userShowWeekends ? 14 : 10}
        nullSlots={nullSlots}
        loading={loading}
      />
      <TextContainer>
        <H4>{getUserDisplayName(selectedUser, selectedUser.company.flags)}</H4>
        <div
          style={{
            display: "flex",
            gap: "8px",
          }}
        >
          <HybridRuleStatusTooltip
            selectedUser={selectedUser}
            from={from}
            to={to}
          />
          <StyledL12>
            {t(`days remain unfilled`, {
              days: `${
                userShowWeekends
                  ? Math.ceil(0.5 * (14 - nullSlots.length))
                  : Math.ceil(0.5 * (10 - nullSlots.length))
              }/${userShowWeekends ? 7 : 5}`,
            })}
          </StyledL12>
        </div>
      </TextContainer>
      {viewMode === "DAY" && (
        <OneDayEditableSlots from={from} to={to} userToMutate={selectedUser} />
      )}
      <TypicalWeekButton selectedUser={selectedUser} />
    </PlanningProgressContainer>
  )
}

function getNullSlots(slots: UTCSlot[], userShowWeekends: boolean) {
  return userShowWeekends
    ? slots.filter((slot) => slot.value === null || slot.value === "null")
    : slots
        .filter((slot) => slot.value === null || slot.value === "null")
        .filter(
          (slot) =>
            !(slot.date.getUTCDay() === 0 || slot.date.getUTCDay() === 6)
        )
}

function ComponentWrapper({ selectedUser }: { selectedUser: User }) {
  const { userShowWeekends } = usePlanningContext()

  const { from, to } = usePlanningProgressFromTo()

  const { slots, loading } = usePlanningUserSlots({
    userId: selectedUser.id,
    from,
    to,
  })

  return (
    <PlanningProgress
      selectedUser={selectedUser}
      nullSlots={getNullSlots(slots, userShowWeekends)}
      loading={loading}
    />
  )
}

export default function PlanningProgressRouter() {
  const { user: me } = useUser()
  const { userId } = useParams<{ userId?: string }>()
  const { user: selectedUser } = useUserFromId(userId ?? "")
  const { viewMode } = usePlanningContext()

  if (viewMode === "MONTH") return <></>

  if (selectedUser) return <ComponentWrapper selectedUser={selectedUser} />

  if (me)
    return (
      <>
        <ComponentWrapper selectedUser={me} />
      </>
    )

  return <></>
}
