import { DocumentNode, gql, useMutation } from "@apollo/client"

import { LocationFragment } from "./fragments/locationFragment"

const ADD_CATEGORY_MUTATION = gql`
  ${LocationFragment}
  mutation addCategory($name: String!, $parentId: ID) {
    addCategory(name: $name, parentId: $parentId) {
      ...LocationFragment
    }
  }
`

const UPDATE_CATEGORY_MUTATION = gql`
  ${LocationFragment}
  mutation updateCategory($id: ID!, $name: String!, $parentId: ID) {
    updateCategory(id: $id, name: $name, parentId: $parentId) {
      ...LocationFragment
    }
  }
`

const REMOVE_CATEGORY_MUTATION = gql`
  ${LocationFragment}
  mutation removeCategory($id: ID!) {
    removeCategory(id: $id) {
      ...LocationFragment
    }
  }
`

function useLocationMutation(mutation: DocumentNode, key: string) {
  const [mutate] = useMutation(mutation, {
    update(cache, { data }) {
      cache.modify({
        fields: {
          locations() {
            return data[key]
          },
        },
      })
    },
  })
  return mutate
}

export default function useCategoryMutations() {
  const mutateAdd = useLocationMutation(ADD_CATEGORY_MUTATION, "addCategory")
  const addCategory = async (name: string, parentId: string | null) => {
    try {
      await mutateAdd({ variables: { name, parentId } })
    } catch (e) {
      console.error(e.message)
    }
  }

  const mutateUpdate = useLocationMutation(
    UPDATE_CATEGORY_MUTATION,
    "updateCategory"
  )
  const updateCategory = async (
    id: string,
    name: string,
    parentId: string | null
  ) => {
    try {
      await mutateUpdate({ variables: { id, name, parentId } })
    } catch (e) {
      console.error(e.message)
    }
  }

  const mutateRemove = useLocationMutation(
    REMOVE_CATEGORY_MUTATION,
    "removeCategory"
  )
  const removeCategory = async (id: string) => {
    try {
      await mutateRemove({ variables: { id } })
    } catch (e) {
      console.error(e.message)
    }
  }

  return {
    addCategory,
    updateCategory,
    removeCategory,
  }
}
