import useLocationsTree from "graphql/locations/useLocationsTree"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { colors, H4, P16 } from "ui"
import LocationTree from "utils/LocationTree"

const List = styled.ul`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 16px;
  padding: 16px;
  overflow-y: scroll;
`

const Item = styled.li`
  display: flex;
  flex-direction: column;
  width: 280px;
  height: min-content;
  border-radius: 16px;
  box-shadow: ${colors.boxShadow};
  cursor: pointer;

  > img {
    height: 184px;
    border: none;
    object-fit: cover;
    border-radius: 16px 16px 0 0;
  }
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  border-top: solid 1px ${colors.grey3};
  padding: 8px 0;
  gap: 10px;
`

const Counter = styled.div`
  display: flex;
  border: solid 1px ${colors.grey3};
  justify-content: center;
  border-radius: 100vh;
  width: min-content;
  white-space: nowrap;
  padding: 2px 8px;
`

interface PropsType {
  setSelectedLocation: (location?: LocationTree | undefined) => void
}

interface TileListPropsType extends PropsType {
  locations: LocationTree[]
}

interface TileItemPropsType extends PropsType {
  location: LocationTree
}

function TileItem({ location, setSelectedLocation }: TileItemPropsType) {
  const t = useTranslate()

  const tileSource = location.floorPlanImageUrl

  if (tileSource === null) return <></>

  return (
    <Item
      onClick={() => {
        setSelectedLocation(location)
      }}
    >
      <img src={tileSource ?? ""} />
      <Footer>
        <H4>{location.name}</H4>
        <Counter>
          <P16>
            {location.bookable} {t("seats")}
          </P16>
        </Counter>
      </Footer>
    </Item>
  )
}

function TileList({ locations, setSelectedLocation }: TileListPropsType) {
  return (
    <>
      {locations.map((l) => {
        const key = `loc-tile-${l.id}`
        if (!l.isCategory)
          return (
            <TileItem
              key={key}
              location={l}
              setSelectedLocation={setSelectedLocation}
            />
          )
        return (
          <React.Fragment key={key}>
            <TileItem location={l} setSelectedLocation={setSelectedLocation} />
            <TileList
              locations={l.children}
              setSelectedLocation={setSelectedLocation}
            />
          </React.Fragment>
        )
      })}
    </>
  )
}

export default function FloorPlanTiles({ setSelectedLocation }: PropsType) {
  const { locations } = useLocationsTree()

  return (
    <List>
      <TileList
        locations={locations}
        setSelectedLocation={setSelectedLocation}
      />
    </List>
  )
}
