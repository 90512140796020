import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components/macro"
import { colors, ExternalLink, P14, P14Bold, P16Bold } from "ui"
import { ArrowRight } from "ui/icons"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid ${colors.grey3};
  border-radius: 4px;
  background-color: white;
  padding: 16px;
  width: 100%;
  gap: 4px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    svg {
      height: 24px;
      width: 24px;
      stroke-width: 2px;
      overflow: visible;
    }
  }
`

const StyledLink = styled(ExternalLink)`
  color: ${colors.purple};
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  height: 40px;
  p {
    color: ${colors.purple};
  }
`

interface PropsType {
  icon: JSX.Element
  title: string
  subtitle: string
}

function BigButton({ icon, title, subtitle }: PropsType) {
  const t = useTranslate()

  return (
    <Container>
      <div className="header">
        {icon}
        <StyledLink href="https://www.offishall.io/care-notre-offre">
          <P14Bold>{t("Read more")}</P14Bold> <ArrowRight />
        </StyledLink>
      </div>
      <P16Bold>{title}</P16Bold>
      <P14>{subtitle}</P14>
    </Container>
  )
}
export default BigButton
