import NudgeModal from "components/NudgeModal/NudgeModal"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useNudge from "graphql/notifications/useNudge"
import useUser from "graphql/users/useUser"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { useTeamsAppContext } from "TeamsApp/TeamsAppContext"
import { UserBase, UTCSlot } from "types"
import { Button, colors } from "ui"
import { Megaphone } from "ui/icons"

const Nudge = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 4px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }

  svg {
    height: 16px;
    width: 16px;
    color: ${colors.grey2};

    &:active {
      color: ${colors.grey1};
    }
  }
`

interface PropsType {
  className?: string
  itemId: string
  user: UserBase
  slots: UTCSlot[]
  closeMenu?: () => void
}

export default function NudgeButton({
  className,
  itemId,
  user,
  slots,
  closeMenu,
}: PropsType) {
  const { user: me } = useUser()
  const { viewMode } = usePlanningContext()
  const { isTeamsApp } = useTeamsAppContext()
  const { isMobile } = useMediaQueries()
  const t = useTranslate()

  const isComplete = !slots.find((s) => s.value === null || s.value === "null")

  const isMe = me && me.id === itemId

  const [displayNudgeModal, setDisplayNudgeModal] = useState<boolean>(false)

  const canShowNudge =
    !isMe &&
    !isComplete &&
    (!isTeamsApp || (isTeamsApp && me?.company.flags.enabledTeamsNudge)) &&
    viewMode !== "MONTH"

  const nudge = useNudge()

  if (isMobile && canShowNudge) {
    if (isTeamsApp) return <></>
    return (
      <Button
        onClick={() => {
          nudge(user.id)
          if (closeMenu) closeMenu()
        }}
      >
        {t("send reminder message")}
      </Button>
    )
  }

  return (
    <>
      {canShowNudge && (
        <>
          <Nudge
            className={className}
            onClick={(e) => {
              e.stopPropagation()
              setDisplayNudgeModal(true)
            }}
          >
            <Megaphone />
          </Nudge>
          <NudgeModal
            open={displayNudgeModal}
            onClose={() => setDisplayNudgeModal(false)}
            user={user}
          />
        </>
      )}
    </>
  )
}
