import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useMediaQueries from "hooks/useMediaQueries"
import React from "react"
import styled from "styled-components"
import { colors, mediaQueries } from "ui"
import UTCDate from "utils/UTCDate"

import {
  NextMonthButton,
  PreviousMonthButton,
} from "./NavButtons/MonthNavButtons"
import TodayButton from "./NavButtons/TodayButton"
import { NextWeekButton, PreviousWeekButton } from "./NavButtons/WeekNavButtons"
import SelectedPeriodName from "./SelectedPeriodName/SelectedPeriodName"
import WeekNumberTitle from "./WeekNumberTitle/WeekNumberTitle"

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 32px;

  border-right: solid 1px ${colors.grey3};

  @media ${mediaQueries.isNarrowScreen} {
    padding: 0 12px;
  }

  @media ${mediaQueries.isMobile} {
    padding: 0 8px;
    width: 100%;
    border-right: none;
  }
`

const WeekModeLayout = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  @media ${mediaQueries.isNarrowScreen} {
    width: 100%;
    gap: 4px;
    justify-content: space-between;
  }

  @media ${mediaQueries.isMobile} {
    width: 100%;
    height: 40px;
    justify-content: space-between;
  }
`

const MonthModeLayout = styled.div`
  display: grid;
  grid-template-columns: auto auto 1fr auto auto;
  align-items: center;
  gap: 8px;
  width: 100%;
`

interface PropsType {
  selectedDateIsPast: boolean
  selectedDateIsFuture: boolean
}

function WeekModeContent({
  selectedDateIsPast,
  selectedDateIsFuture,
}: PropsType) {
  const { isMobile, isNarrowScreen } = useMediaQueries()

  if (isMobile)
    return (
      <WeekModeLayout>
        <PreviousWeekButton />
        {selectedDateIsFuture && <TodayButton />}
        <SelectedPeriodName />
        <WeekNumberTitle />
        {selectedDateIsPast && <TodayButton />}
        <NextWeekButton />
      </WeekModeLayout>
    )

  if (isNarrowScreen)
    return (
      <WeekModeLayout>
        <SelectedPeriodName />
        <PreviousWeekButton />
        {selectedDateIsFuture && <TodayButton />}
        <WeekNumberTitle />
        {selectedDateIsPast && <TodayButton />}
        <NextWeekButton />
      </WeekModeLayout>
    )

  return (
    <>
      <SelectedPeriodName />
      <WeekModeLayout>
        <PreviousWeekButton />
        {selectedDateIsFuture && <TodayButton />}
        <WeekNumberTitle />
        {selectedDateIsPast && <TodayButton />}
        <NextWeekButton />
      </WeekModeLayout>
    </>
  )
}

function MonthModeContent() {
  const { isMobile, isNarrowScreen } = useMediaQueries()

  if (isMobile)
    return (
      <MonthModeLayout>
        <PreviousMonthButton />
        <PreviousWeekButton />
        <SelectedPeriodName />
        <NextWeekButton />
        <NextMonthButton />
      </MonthModeLayout>
    )

  if (isNarrowScreen)
    return (
      <MonthModeLayout>
        <PreviousMonthButton />
        <PreviousWeekButton />
        <SelectedPeriodName />
        <NextWeekButton />
        <NextMonthButton />
      </MonthModeLayout>
    )

  return (
    <MonthModeLayout>
      <PreviousMonthButton />
      <PreviousWeekButton />
      <SelectedPeriodName />
      <NextWeekButton />
      <NextMonthButton />
    </MonthModeLayout>
  )
}

export default function PlanningNavigation() {
  const { selectedDate, viewMode, openDrawer, setOpenDrawer } =
    usePlanningContext()

  const now = new UTCDate("DAY")
  const selectedDateIsPast = selectedDate.getTime() <= now.getTime()
  const selectedDateIsFuture = selectedDate.getTime() >= now.getTime()

  if (viewMode === "MONTH")
    return (
      <Wrapper
        onClick={() => {
          if (openDrawer !== null) setOpenDrawer(null)
        }}
      >
        <MonthModeContent />
      </Wrapper>
    )

  return (
    <Wrapper
      onClick={() => {
        if (openDrawer !== null) setOpenDrawer(null)
      }}
    >
      <WeekModeContent
        selectedDateIsPast={selectedDateIsPast}
        selectedDateIsFuture={selectedDateIsFuture}
      />
    </Wrapper>
  )
}
