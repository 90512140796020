import useUserFromId from "graphql/users/useUserFromId"
import { useUserHybridRuleScore } from "hooks/useHybridRuleScore"
import useTranslate from "intl/useTranslate"
import React from "react"
import { Flags, HybridRule } from "types"
import { colors, P14, TD, TR } from "ui"
import getUserDisplayName from "utils/getUserDisplayName"
import UTCDate from "utils/UTCDate"

import ActionsMenu from "./ActionsMenu/ActionsMenu"

interface UserRowPropsType {
  rule: HybridRule
  selectedDate: UTCDate
  userId: string
  companyFlags: Flags
  options?: {
    showUserRuleType?: boolean
  }
}

export default function UserHybridRuleScoreRow({
  userId,
  rule,
  selectedDate,
  companyFlags,
  options: { showUserRuleType } = { showUserRuleType: false },
}: UserRowPropsType) {
  const t = useTranslate()
  const { score, loading } = useUserHybridRuleScore(selectedDate, rule, userId)
  const { user } = useUserFromId(userId)
  const homeDays = score ? Math.abs(rule.maxDays - score.homeDayDelta) : 0

  if (!score || loading || !user) return <></>

  const isWeekDetailReady = false

  return (
    <TR>
      <TD displayBorder>
        <P14>
          {getUserDisplayName(user, companyFlags)}
          {user.isManager && (
            <>
              {" "}
              <span style={{ color: colors.grey2 }}>{t("manager")}</span>
            </>
          )}
        </P14>
      </TD>
      {showUserRuleType && (
        <TD displayBorder>
          <P14>{rule.name}</P14>
        </TD>
      )}
      <TD displayBorder>
        <P14>{t(rule.period)}</P14>
      </TD>
      <TD displayBorder>
        <P14>
          {rule.maxDays} {t("days")}
        </P14>
      </TD>
      <TD displayBorder>
        <P14 color={homeDays > rule.maxDays ? "redAlert" : "black"}>
          {homeDays} {t("days")}
        </P14>
      </TD>
      {isWeekDetailReady && (
        <>
          <TD displayBorder>TODO, week details</TD>
          <TD displayBorder>
            <ActionsMenu />
          </TD>
        </>
      )}
    </TR>
  )
}
