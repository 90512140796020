import useTranslate from "intl/useTranslate"
import useTranslation from "intl/useTranslation"
import React from "react"
import styled from "styled-components"
import { Button, Dialog, P14 } from "ui"

import updateApp from "./updateApp"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 400px;
`

export default function MandatoryUpdatePopup() {
  const t = useTranslate()
  const [translation] = useTranslation()
  return (
    <Dialog
      open={true}
      title={t("mandatory update title")}
      rightButton={
        <Button onClick={updateApp}>{t("mandatory update button")}</Button>
      }
    >
      <Container>
        <img
          src={
            translation.id === "fr"
              ? "/static/img/onboarding/onboarding.gif"
              : "/static/img/onboarding/onboarding-en.gif"
          }
          style={{ height: 200, width: 360 }}
        />
        <P14>{t("mandatory update description")}</P14>
      </Container>
    </Dialog>
  )
}
