import MobileBottomNavigation from "components/MobileBottomNavigation/MobileBottomNavigation"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import UsersPlanningList from "pages/PlanningPage/components/UsersPlanningList/UsersPlanningList"
import React, { useState } from "react"
import styled from "styled-components"
import { Button, colors, P16Bold } from "ui"

import PlanningProgressRouter from "../../../components/PlanningProgress/PlanningProgress"
import PlanningHeadersRouter from "../PlanningPageHeader/PlanningHeadersRouter/PlanningHeadersRouter"
import WeeklyDays from "./components/WeeklyDays/WeeklyDays"

const Layout = styled.div`
  display: grid;
  grid-auto-rows: auto auto auto 1fr auto;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
`

const Body = styled.div`
  overflow-y: scroll;
`

const ViewMoreButton = styled(Button)`
  margin: 8px 0 8px 16px;
`

const WeekendContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${colors.grey4};
  gap: 32px;
  height: 100%;
  width: 100%;
  p {
    text-align: center;
    max-width: 80%;
  }
`

const GROUP_LIST_LIMIT = 20
const GROUP_LIST_INCREMENT = 10

export default function DailyView() {
  const { users, selectedDate, setViewMode } = usePlanningContext()
  const t = useTranslate()
  const { user: me } = useUser()

  const onClose = () => {
    setViewMode("WEEK")
  }

  const [limit, setLimit] = useState(GROUP_LIST_LIMIT)

  if (!me) return null
  return (
    <Layout>
      <WeeklyDays onClose={onClose} />
      <PlanningProgressRouter />
      <PlanningHeadersRouter usersFromRouter={users} />
      <Body>
        {selectedDate.isWeekend() ? (
          <WeekendContainer>
            <img src="/static/img/weekend_illu.svg" />
            <P16Bold color="grey1">{t("weekend planning text")}</P16Bold>
          </WeekendContainer>
        ) : (
          <>
            <UsersPlanningList users={users} limit={limit} />
            {limit < users.length && (
              <ViewMoreButton
                ghost
                onClick={() => setLimit(limit + GROUP_LIST_INCREMENT)}
              >
                {t("view more")}
              </ViewMoreButton>
            )}
          </>
        )}
      </Body>
      <MobileBottomNavigation />
    </Layout>
  )
}
