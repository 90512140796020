import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import useTranslate from "intl/useTranslate"
import { DropzoneDialog } from "material-ui-dropzone"
import React, { useState } from "react"
import styled from "styled-components"
import { Button, colors, Grid, P14, P14Bold, P16 } from "ui"
import { Edit, MultiplePages } from "ui/icons"

const File = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;

  .file-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    padding: 13px 10px;
    width: 200px;
    height: 50px;
    background: ${colors.grey4};
    border-radius: 8px;
  }

  .error-text {
    color: ${colors.redAlert};
    text-align: center;
  }
`

const Container = styled.div`
  width: 523px;
`

const RadioButtonsContainer = styled(FormControl)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const SeparatorTitle = styled(P14Bold)`
  color: ${colors.grey1};
  padding-bottom: 8px;
`

const Footer = styled.div`
  display: flex;
  width: 100%;
  border-top: 1px solid ${colors.grey3};
  padding-top: 22px;
  margin-top: 12px;

  > button {
    margin-left: auto;
  }
`

const RadioStyling = {
  color: colors.purple,
  "&.Mui-checked": {
    color: colors.purple,
  },
}

interface PropsType {
  fileName: string
  separator: string
  editSeparator: (separator: string) => void
  error: string
  onNext: () => void
  handleFile: (files: File[] | undefined | null) => void
}

export function CSVSeparator({
  fileName,
  separator,
  editSeparator,
  error,
  onNext,
  handleFile,
}: PropsType) {
  const t = useTranslate()

  const [openDialog, setOpenDialog] = useState<boolean>(false)

  return (
    <Container>
      {fileName !== "" && (
        <File>
          <div className="file-name">
            <MultiplePages />
            <P16>{fileName}</P16>
          </div>
          <Button ghost icon={<Edit />} onClick={() => setOpenDialog(true)}>
            {t("change my file")}
          </Button>
          <DropzoneDialog
            open={openDialog}
            acceptedFiles={["text/csv"]}
            filesLimit={1}
            dropzoneText={t("drop a csv file or browse your documents")}
            onSave={(files) => {
              handleFile(files)
              setOpenDialog(false)
            }}
            onClose={() => setOpenDialog(false)}
            showPreviewsInDropzone={false}
          />
          <P14 className="error-text">{error}</P14>
        </File>
      )}
      {error === "" && (
        <Grid item xs={12}>
          <RadioButtonsContainer>
            <SeparatorTitle>{t("choose separator")}</SeparatorTitle>
            <RadioGroup
              row
              value={separator}
              onChange={() => editSeparator(separator === "," ? ";" : ",")}
            >
              <FormControlLabel
                value=","
                label=","
                control={<Radio sx={{ ...RadioStyling }} />}
              />
              <FormControlLabel
                value=";"
                label=";"
                control={<Radio sx={{ ...RadioStyling }} />}
              />
            </RadioGroup>
          </RadioButtonsContainer>
        </Grid>
      )}
      <Footer>
        <Button disabled={error !== ""} onClick={onNext}>
          {t("Next")}
        </Button>
      </Footer>
    </Container>
  )
}
