import styled from "styled-components"
import { colors } from "ui"

interface PropsType {
  row?: boolean
  spaceBetween?: boolean
}

export const Fieldset = styled.fieldset<PropsType>`
  // reset default style
  padding: 0;
  margin: 0;
  border: none;

  display: flex;
  ${({ row }) => (row ? "" : "flex-direction: column;")}
  ${({ spaceBetween }) =>
    spaceBetween ? "justify-content: space-between;" : ""}
  gap: 4px;
  ${({ row }) => (row ? "align-items: center;" : "")}

  p {
    color: ${colors.grey1};
    font-weight: 600;
  }

  *:last-child {
    ${({ row }) => (row ? "margin-left: auto;" : "")}
  }
`
