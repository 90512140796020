import styled from "styled-components"
import { colors, transitions } from "ui"

export const CustomButton = styled.button`
  display: flex;
  gap: 16px;
  padding: 5px 16px;
  align-items: center;
  background: ${colors.grey3}00;
  border: solid 1px ${colors.grey3};
  border-radius: 4px;
  width: max-content;
  font-size: 14px;
  font-weight: 800;
  color: ${colors.purple};
  cursor: pointer;

  &.centered {
    margin: 0 auto;
  }

  &:hover:not(:disabled) {
    background: ${colors.grey3};
  }

  &:disabled {
    color: ${colors.grey2};
    cursor: not-allowed;
    svg {
      opacity: 0.5;
    }
  }

  transition: ${transitions.fade};
`
