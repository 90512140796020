import useDefaultHybridRule from "graphql/hybridPolicy/useDefaultHybridRule"
import useGroupHybridRule from "graphql/hybridPolicy/useGroupHybridRule"
import useManagerHybridRule from "graphql/hybridPolicy/useManagerHybridRule"
import useUserHybridRule from "graphql/hybridPolicy/useUserHybridRule"
import { HybridRule } from "types"
import UTCDate from "utils/UTCDate"

export function getRuleFromTo(
  rule: Omit<HybridRule, "name" | "id">,
  date: UTCDate
) {
  if (rule.period === "week") {
    const from = new UTCDate(date, "WEEK-MONDAY")
    const to = new UTCDate(from)
    to.shift("WEEK", 1)
    return { from, to }
  }

  const from = new UTCDate(date, "MONTH")
  const to = new UTCDate(from)
  to.shift("MONTH", 1)
  return { from, to }
}

export function useUserHybridRuleScore(
  date: UTCDate,
  rule: HybridRule,
  userId: string
) {
  const { from, to } = getRuleFromTo(rule, date)
  const { userHybridRule, loading, error } = useUserHybridRule(userId, from, to)

  const selectedWeekScore = userHybridRule?.weeksScores
    ? userHybridRule.weeksScores.find(
        ({ weekIndex }) => weekIndex === from.getWeekNumber()
      )
    : undefined
  const selectedMonthScore = userHybridRule?.monthsScores
    ? userHybridRule.monthsScores.find(
        ({ monthIndex }) => monthIndex === from.getMonth()
      )
    : undefined

  return {
    score: rule.period === "week" ? selectedWeekScore : selectedMonthScore,
    loading,
    error,
  }
}

export function useGroupHybridRuleScore(
  date: UTCDate,
  rule: HybridRule,
  groupId: string
) {
  const { from, to } = getRuleFromTo(rule, date)
  const { groupHybridRule, loading, error } = useGroupHybridRule(
    groupId,
    from,
    to
  )

  const selectedWeekScore = groupHybridRule?.weeksScores
    ? groupHybridRule.weeksScores.find(
        ({ weekIndex }) => weekIndex === from.getWeekNumber()
      )
    : undefined
  const selectedMonthScore = groupHybridRule?.monthsScores
    ? groupHybridRule.monthsScores.find(
        ({ monthIndex }) => monthIndex === from.getMonth()
      )
    : undefined

  return {
    score: rule.period === "week" ? selectedWeekScore : selectedMonthScore,
    loading,
    error,
  }
}

export function useManagerHybridRuleScore(
  date: UTCDate,
  rule: HybridRule,
  managerId: string
) {
  const { from, to } = getRuleFromTo(rule, date)
  const { managerHybridRule, loading, error } = useManagerHybridRule(
    managerId,
    from,
    to
  )

  const selectedWeekScore = managerHybridRule?.weeksScores
    ? managerHybridRule.weeksScores.find(
        ({ weekIndex }) => weekIndex === from.getWeekNumber()
      )
    : undefined
  const selectedMonthScore = managerHybridRule?.monthsScores
    ? managerHybridRule.monthsScores.find(
        ({ monthIndex }) => monthIndex === from.getMonth()
      )
    : undefined

  return {
    score: rule.period === "week" ? selectedWeekScore : selectedMonthScore,
    loading,
    error,
  }
}

export function useDefaultHybridRuleScore(date: UTCDate, rule: HybridRule) {
  const { defaultHybridRule, loading, error } = useDefaultHybridRule(
    getRuleFromTo(rule, date)
  )
  return { score: defaultHybridRule, loading, error }
}
