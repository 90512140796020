import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { Group as GroupType } from "types"
import { colors, P14 } from "ui"
import { ArrowDownFill, ArrowRightFill, Plus } from "ui/icons"
import sortByKey from "utils/sortByKey"

import MenuListItem from "../../MenuListItem/MenuListItem"

const Container = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  width: 100%;
  height: 25px;
  background-color: transparent;
  p {
    color: ${colors.grey1};
  }

  svg {
    height: 16px;
    width: 16px;
    color: ${colors.grey1};
    fill: ${colors.grey1};
    margin-right: 8px;
    margin-left: 17px;
    stroke-width: 2px;
  }
`

interface PropsType {
  groups: GroupType[]
}

export default function ViewMoreGroups({ groups }: PropsType) {
  const t = useTranslate()
  const [showAll, setShowAll] = useState<boolean>(false)

  return (
    <>
      <Container onClick={() => setShowAll((oldValue) => !oldValue)}>
        {showAll ? <ArrowDownFill /> : <ArrowRightFill />}
        <P14>{showAll ? t("view less") : t("simple view more")}</P14>
      </Container>
      {showAll && (
        <ul>
          {sortByKey(groups).map((group) => {
            return (
              <MenuListItem
                key={`my-group-${group.id}`}
                icon={<Plus />}
                name={group.name}
                groupId={group.id}
                notActive
                path={`/planning/group/${group.id}`}
              />
            )
          })}
        </ul>
      )}
    </>
  )
}
