import styled from "styled-components"
import { colors, mediaQueries } from "ui"

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  thead tr th {
    position: sticky;
    top: 0;
  }

  > tbody {
    max-height: calc(100% - 100px);
    overflow-y: scroll;

    @media ${mediaQueries.isMobile} {
      max-height: 100%;
    }
  }
`

export const TR = styled.tr`
  & :first-child {
    box-shadow: none;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
`

interface THProps {
  displayBorder?: boolean
}

export const TH = styled.th<THProps>`
  padding: 8px 0 8px 8px;
  text-align: left;
  width: min-content;
  background: ${colors.grey4};
  z-index: 1;

  ${({ displayBorder }) => {
    if (displayBorder)
      return `box-shadow: inset 1px 0px ${colors.grey3}, 0 0px ${colors.grey3};`
    else return `box-shadow: none;`
  }}

  p {
    color: ${colors.grey1};
  }

  @media ${mediaQueries.isMobile} {
    position: -webkit-sticky;
    position: sticky;
    padding: 8px;
  }
`

interface TDProps {
  displayBorder?: boolean
  isClickable?: boolean
}

export const TD = styled.td<TDProps>`
  text-align: left;
  padding: 8px;

  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.black};

  ${({ displayBorder }) => {
    if (displayBorder) return `border-bottom: 1px solid ${colors.grey3};`
    else return `border-bottom: none;`
  }}

  ${({ isClickable }) => {
    if (isClickable) return `cursor: pointer;`
    else return `cursor: auto;`
  }}

  > svg {
    color: ${colors.grey2};
    height: 24px;
    width: 24px;
  }
`
