import DeleteButtonWithConfirmation from "components/ButtonsWithConfirmation/DeleteButtonWithConfirmation/DeleteButtonWithConfirmation"
import useDeleteCompanyFile from "graphql/company/useDeleteCompanyFile"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { CompanyFile } from "types"
import { colors, L12, P16 } from "ui"

const Layout = styled.li`
  display: grid;
  grid-template-columns: 1fr min-content;
  padding: 16px 0;
  border-bottom: solid 1px ${colors.grey3};

  .file-item-title {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .file-item-actions {
    align-self: center;
    display: flex;
    padding: 0 16px;
    svg {
      stroke: ${colors.grey2};
      cursor: pointer;
    }
  }

  &:first-child {
    border-top: solid 1px ${colors.grey3};
  }
`

interface PropsType {
  file: CompanyFile
}

export default function FileItem({ file }: PropsType) {
  const t = useTranslate()
  const deleteCompanyFile = useDeleteCompanyFile()

  return (
    <Layout>
      <div className="file-item-title">
        <P16>{file.name}</P16>
        <L12 style={{ color: colors.grey1 }}>{file.rawName}</L12>
      </div>
      <div className="file-item-actions">
        <DeleteButtonWithConfirmation
          onConfirm={() => deleteCompanyFile(file.id)}
          dialogTitle={t("company_file--confirm_deletion_title")}
        >
          <P16>{t("company_file--confirm_deletion_message")}</P16>
          <div className="file-item-title">
            <P16>{file.name}</P16>
            <L12 style={{ color: colors.grey1 }}>{file.rawName}</L12>
          </div>
        </DeleteButtonWithConfirmation>
      </div>
    </Layout>
  )
}
