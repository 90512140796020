import UpdateManagerDialog from "components/UpdateManagerDialog/UpdateManagerDialog"
import useCompanyFlag from "hooks/useCompanyFlag"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components/macro"
import { User } from "types"
import { colors, P14, P16Bold } from "ui"
import { Lock, Mail, User as UserIcon, VerifiedUser } from "ui/icons"
import getUserDisplayName from "utils/getUserDisplayName"

import MeAvatarPicker from "../../../pages/CurrentUserSettingsPage/MeAvatarPicker/MeAvatarPicker"
import EditionBtn from "./EditionBtn"
import { Elem } from "./ListElem"
import NameEditionDialog from "./NameEditionDialog/NameEditionDialog"
import PasswordEditionDialog from "./PasswordEditionDialog/PasswordEditionDialog"

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Title = styled(P16Bold)`
  margin-bottom: 24px;
`

const CirclesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
`

const CirclePassword = styled.div`
  height: 5px;
  width: 5px;
  border-radius: 5px;
  background-color: ${colors.purple};
`

interface PropsType {
  user: User
}

function PersonalInformations({ user }: PropsType) {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()

  const [openPhotoDialog, setOpenPhotoDialog] = useState(false)
  const bypassDisplayName = useCompanyFlag("bypassDisplayName", false)
  const [openUserNameDialog, setOpenUserNameDialog] = useState(false)
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false)
  const [openManagerDialog, setOpenManagerDialog] = useState<boolean>(false)

  if (!user) return <></>
  return (
    <Content>
      {!isMobile && <Title>{t("user-setting-group-personal")}</Title>}{" "}
      <Elem>
        <MeAvatarPicker
          me={user}
          open={openPhotoDialog}
          onClose={() => setOpenPhotoDialog(false)}
        />
        <EditionBtn onClick={() => setOpenPhotoDialog(true)} />
      </Elem>
      <Elem>
        <UserIcon />
        <P14>{getUserDisplayName(user, user.company.flags)}</P14>
        {!bypassDisplayName && (
          <>
            <EditionBtn onClick={() => setOpenUserNameDialog(true)} />
            <NameEditionDialog
              user={user}
              open={openUserNameDialog}
              onClose={() => setOpenUserNameDialog(false)}
            />
          </>
        )}
      </Elem>
      <Elem>
        <Mail />
        <P14>{user?.email}</P14>
      </Elem>
      <Elem>
        <Lock style={{ marginLeft: 5, marginTop: 5 }} />
        <CirclesContainer>
          {Array.from(Array(10), (e, i) => {
            return <CirclePassword key={i} />
          })}
        </CirclesContainer>
        <EditionBtn onClick={() => setOpenPasswordDialog(true)} />
        <PasswordEditionDialog
          open={openPasswordDialog}
          onClose={() => setOpenPasswordDialog(false)}
        />
      </Elem>
      <Elem>
        <VerifiedUser />
        {user.manager ? (
          <>
            <P14>{t("Manager")} :</P14>
            <P14>{getUserDisplayName(user.manager, user.company.flags)}</P14>
          </>
        ) : (
          <P14>{t("no manager")}</P14>
        )}
        <EditionBtn onClick={() => setOpenManagerDialog(true)} />
      </Elem>
      <UpdateManagerDialog
        open={openManagerDialog}
        onClose={() => setOpenManagerDialog(false)}
        selectedUser={user}
      />
    </Content>
  )
}
export default PersonalInformations
