import { useEffect, useState } from "react"
import { getToken } from "utils/firebase"

export default function useToken() {
  const [token, setToken] = useState<string | null>(null)
  useEffect(() => {
    getToken().then((token) => setToken(token))
  }, [])
  return token
}
