import { ApolloError, gql, useQuery } from "@apollo/client"
import { LocationsAtInput } from "types"
import LocationTree from "utils/LocationTree"

import useLocationsTree from "./useLocationsTree"

export const QUERY = gql`
  query locationsAt(
    $from: String!
    $to: String!
    $userIds: [String]
    $locationIds: [String]
    $groupIds: [String]
  ) {
    locationsAt(
      from: $from
      to: $to
      userIds: $userIds
      locationIds: $locationIds
      groupIds: $groupIds
    )
  }
`

let _lastFetchedLocations: LocationTree[] = []

export default function useLocationsAt(
  { from, to, userIds, locationIds, groupIds }: LocationsAtInput,
  skip = false
): {
  loading: boolean
  error?: ApolloError
  locations: LocationTree[]
} {
  const { locations: tree } = useLocationsTree()
  const { loading, error, data } = useQuery<{ locationsAt: string[] }>(QUERY, {
    skip,
    variables: {
      from: `${from.getTime()}`,
      to: `${to.getTime()}`,
      userIds: userIds && userIds.length > 0 ? userIds : undefined,
      locationIds,
      groupIds,
    },
  })

  const ids = data?.locationsAt || []

  const locations = ids
    .map((id) => LocationTree.getLocationNode(tree, id))
    .filter((l): l is LocationTree => l !== undefined)

  if (!skip) {
    _lastFetchedLocations = locations
  }

  return {
    locations: skip ? _lastFetchedLocations : locations,
    loading,
    error,
  }
}
