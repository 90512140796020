import useOccupancyRateOverTime from "graphql/stats/useOccupancyRateOverTime"
import React, { useEffect, useState } from "react"
import LocationTree from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

interface PropsType {
  locations: LocationTree[]
  from: UTCDate
  to: UTCDate
  includeWeekends: boolean
  occupancyPerDay: {
    date: string
    value: number
    booked: number
    total: number
  }[]
  updateOccupancyPerDate: (
    occupancyPerDate: {
      date: string
      value: number
      booked: number
      total: number
    }[]
  ) => void
  bypassLocationIds?: boolean
}

export default function OccupancyRatePerDayBar({
  locations,
  from,
  to,
  includeWeekends,
  updateOccupancyPerDate,
  bypassLocationIds = false,
}: PropsType) {
  const { occupancyRateOverTime, loading, error } = useOccupancyRateOverTime({
    locationIds: locations.map((g) => g.id),
    from,
    to,
    includeWeekends,
    perDay: true,
    bypassLocationIds,
  })

  const [previousLocations, setPreviousLocations] = useState<
    LocationTree[] | undefined
  >(undefined)

  const [previousTimeFrame, setPreviousTimeFrame] = useState<
    { from: UTCDate; to: UTCDate; includeWeekends: boolean } | undefined
  >(undefined)

  useEffect(() => {
    if (error || loading) return

    const doesLocationsMatch = (a: LocationTree[], b: LocationTree[]) => {
      if (a.length !== b.length) return false
      for (const locationFromA of a) {
        if (!b.find((locationFromB) => locationFromB.id === locationFromA.id))
          return false
      }
      return true
    }

    const timeFrameHasChanged =
      previousTimeFrame?.from.getTime() !== from.getTime() ||
      previousTimeFrame?.to.getTime() !== to.getTime() ||
      previousTimeFrame?.includeWeekends !== includeWeekends

    const locationsHaveChanged =
      previousLocations === undefined ||
      !doesLocationsMatch(locations, previousLocations)

    if (locationsHaveChanged || timeFrameHasChanged) {
      // update parent dataset with this data if:
      // * this data does not exist in parent dataset yet
      // * the sample has changed: size or at least one item
      // * parent time frame has changed
      updateOccupancyPerDate(occupancyRateOverTime)
      setPreviousLocations(locations)
      setPreviousTimeFrame({ from, to, includeWeekends })
    }
  }, [
    occupancyRateOverTime,
    loading,
    error,
    locations,
    from,
    to,
    includeWeekends,
    updateOccupancyPerDate,
    previousLocations,
    previousTimeFrame,
  ])

  return <></>
}
