import SearchBarForm from "components/Search/SearchBarForm/SearchBarForm"
import useTranslate from "intl/useTranslate"
import React, { useEffect, useState } from "react"
import { GroupSimple } from "types"
import { Search } from "ui/icons"

interface PropsType {
  groups: GroupSimple[]
  onSubmit: (results: GroupSimple[]) => void
  onNewResults: (results: GroupSimple[]) => void
}

export default function GroupsSearchComponent({
  groups,
  onSubmit,
  onNewResults,
}: PropsType) {
  const t = useTranslate()

  const [searchString, setSearchString] = useState("")
  const [newResults, setNewResults] = useState<GroupSimple[]>([])
  const results: GroupSimple[] = groups.filter((group) =>
    group.name.toLowerCase().includes(searchString.toLowerCase())
  )

  const resetForm = () => {
    setSearchString("")
    setNewResults([])
    onNewResults([])
  }

  useEffect(() => {
    if (results.length === newResults.length) {
      for (const result of results) {
        if (!newResults.find((nr) => nr.id === result.id)) {
          setNewResults(results)
          onNewResults(results)
          return
        }
      }
    } else {
      setNewResults(results)
      onNewResults(results)
    }
  }, [results, newResults, searchString, onNewResults])

  return (
    <SearchBarForm
      searchString={searchString}
      handleChange={(str) => setSearchString(str)}
      resetForm={resetForm}
      icon={<Search />}
      placeholder={t("search groups")}
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit(results)
        resetForm()
      }}
      autoFocus
    />
  )
}
