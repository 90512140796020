import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { NumberField, TextField } from "ui"

import { OnboardingLocation } from "../OnboardingTypes"

const Container = styled.div`
  max-width: 100%;
  height: 140px;

  .textfield {
    margin-bottom: 16px;
  }
`

interface PropsType {
  locations: any
  setLocations: (locations: any) => void
}

export default function DefineWorkspace({
  locations,
  setLocations,
}: PropsType) {
  const t = useTranslate()

  return (
    <Container>
      <div className="textfield">
        <TextField
          label={t("workspace name")}
          placeholder={t("workspace name placeholder")}
          handleChange={(name) => {
            setLocations((prev: OnboardingLocation[]) =>
              prev.map((e: OnboardingLocation, idx: number) =>
                idx === 0 ? { ...e, name } : e
              )
            )
          }}
        />
      </div>

      <NumberField
        label={t("Full capacity")}
        value={locations[0].capacity}
        handleChange={(capacity) => {
          setLocations((prev: OnboardingLocation[]) =>
            prev.map((e: OnboardingLocation, idx: number) =>
              idx === 0 ? { ...e, capacity } : e
            )
          )
        }}
      />
    </Container>
  )
}
