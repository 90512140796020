import UpdateManagerSearchResults from "components/Search/ResultsList/UpdateManagerSearchResults/UpdateManagerSearchResults"
import SearchBarForm from "components/Search/SearchBarForm/SearchBarForm"
import useSearchUsersByName from "graphql/search/useSearchUsersByName"
import useTranslate from "intl/useTranslate"
import React, { useRef, useState } from "react"
import styled from "styled-components"
import { SearchResult } from "types"
import { Grid, mediaQueries } from "ui"
import { Search as SearchIcon } from "ui/icons"
import isIphone from "utils/isIphone"

const SearchContainer = styled(Grid)`
  display: flex;
  width: 100%;
  height: 40px;

  @media ${mediaQueries.isMobile} {
    padding: 0;
    height: auto;
  }
`

interface PropsType {
  onSelect: (user: SearchResult) => void
}

function UserSearch({ onSelect }: PropsType) {
  const t = useTranslate()

  const containerRef = useRef<HTMLDivElement>(null)
  const searchInputRef = useRef<HTMLFormElement>(null)

  const [searchString, setSearchString] = useState("")
  const { results } = useSearchUsersByName(searchString)

  const handelSelect = (user: SearchResult) => {
    onSelect(user)
    resetForm()
  }

  const resetForm = () => {
    setSearchString("")
  }

  return (
    <SearchContainer
      item
      ref={containerRef}
      onKeyDown={(event) => {
        if (event.key === "Escape") return resetForm()
      }}
    >
      <SearchBarForm
        ref={searchInputRef}
        icon={isIphone ? undefined : <SearchIcon />}
        placeholder={t("enter a name or an email address")}
        handleChange={(str) => {
          setSearchString(str)
        }}
        searchString={searchString}
        resetForm={resetForm}
      />
      <UpdateManagerSearchResults
        anchorEl={searchInputRef.current}
        results={results}
        searchString={searchString}
        onSelect={handelSelect}
        onClick={() => {
          searchInputRef.current?.focus()
        }}
        handleReset={resetForm}
      />
    </SearchContainer>
  )
}

export default UserSearch
