import { gql } from "@apollo/client"
import { IntegrationEventFragment } from "graphql/events/fragments/integrationEventFragments"

export const MeetingRoomFragment = gql`
  fragment MeetingRoomFragment on MeetingRoom {
    integration
    id
    name
    description
    capacity
    flags
    initiator
  }
`

export const MeetingRoomWithEventsFragment = gql`
  ${IntegrationEventFragment}
  fragment MeetingRoomWithEventsFragment on MeetingRoomWithEvents {
    integration
    id
    name
    description
    capacity
    flags
    initiator
    events {
      ...IntegrationEventFragment
    }
  }
`
