import Loader from "components/Loader/Loader"
import useCompanyIntegrations from "graphql/integrations/useCompanyIntegrations"
import useUserIntegrations from "graphql/integrations/useUserIntegrations"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { colors, LU12, mediaQueries, P16Bold } from "ui"

import CompanyWideIntegrations from "./CompanyWideIntegrations/CompanyWideIntegrations"
import UserIntegrations from "./UserIntegrations/UserIntegrations"

const List = styled.ul`
  margin-top: 24px;

  @media ${mediaQueries.isMobile} {
    margin-top: 0px;
  }
  .header {
    background-color: ${colors.grey4};
    height: 24px;
    display: flex;
    padding: 4px 8px;
    justify-content: space-between;
    align-items: center;
  }

  .item {
    min-height: 56px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid ${colors.grey3};
    padding: 16px 8px;
    width: 100%;

    button.btn-small {
      white-space: nowrap;
      border: 1px solid ${colors.grey3};
      background-color: ${colors.white};
      color: ${colors.purple};
      border-radius: 4px;
      padding: 4px 8px;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;

      &:hover {
        background: rgba(0, 0, 0, 0.04);
      }
    }

    .top-part {
      display: flex;
      flex-direction: row;
      align-items: center;
      svg {
        margin-right: 16px;
      }
      .title {
        margin-right: 32px;
        white-space: nowrap;
      }
      .status {
        margin-left: auto;
      }
    }
    .description {
      display: flex;
      gap: 8px;
      width: 100%;
      margin-top: 12px;
    }

    .bottom-part {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-left: 42px;
      margin-top: 8px;
    }
  }
`

export default function Integrations() {
  const { userIntegrations, loading: loadingUserIntegratons } =
    useUserIntegrations()
  const { companyIntegrations, loading: loadingCompanyIntegrations } =
    useCompanyIntegrations()
  const t = useTranslate()
  const { isMobile } = useMediaQueries()

  if (loadingUserIntegratons || loadingCompanyIntegrations)
    return (
      <>
        <div>
          {!isMobile && <P16Bold>{t("Integrations")}</P16Bold>}
          <List>
            <li className="header">
              <LU12>{t("connect to")}</LU12>
              <LU12>{t("Status")}</LU12>
            </li>
          </List>
        </div>
        <Loader />
      </>
    )

  return (
    <div>
      {!isMobile && <P16Bold>{t("Integrations")}</P16Bold>}
      <List>
        <li className="header">
          <LU12>{t("connect to")}</LU12>
          <LU12>{t("Status")}</LU12>
        </li>
        <CompanyWideIntegrations companyIntegrations={companyIntegrations} />
        <UserIntegrations userIntegrations={userIntegrations} />
      </List>
    </div>
  )
}
