import { PlanningDrawer } from "components/PlanningDrawer/PlanningDrawer"
import useTranslate from "intl/useTranslate"
import CalendarDays from "pages/PlanningPage/components/CalendarDays/CalendarDays"
import PlanningNavigation from "pages/PlanningPage/components/PlanningNavigation/PlanningNavigation"
import React from "react"
import styled from "styled-components"
import { colors, UnstyledButton } from "ui"
import LocationTree from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

import FloorPlan from "./FloorPlan/FloorPlan"
import FloorPlanLocationsList from "./FloorPlanLocationsList/FloorPlanLocationsList"
import LocationOccupations from "./LocationOccupations/LocationOccupations"

const CloseButton = styled(UnstyledButton)`
  border: solid 1px ${colors.grey3};
  border-radius: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  color: ${colors.grey1};
`

const Layout = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto 1fr;
  overflow-y: hidden;
  height: 100%;
  min-height: 100%;
  max-height: 100%;

  > div:nth-child(1) {
    padding: 8px;
    overflow-y: hidden;
  }
  > div:nth-child(2) {
    // locations list
    overflow-y: hidden;
    border-right: none;
  }
  > div:nth-child(3) {
    // floor plan + occupations
    padding: 8px 0;

    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`

interface PropsType {
  from: UTCDate
  to: UTCDate
  locations: LocationTree[]
  selectedLocation: LocationTree | undefined
  selectedFloorPlan: LocationTree | undefined
  setSelectedLocation: (location: LocationTree | undefined) => void
  onClose: () => void
}

export default function MobileUserFloorPlanDrawer({
  from,
  to,
  locations,
  selectedLocation,
  setSelectedLocation,
  selectedFloorPlan,
  onClose,
}: PropsType) {
  const t = useTranslate()
  return (
    <PlanningDrawer open onClose={onClose}>
      <Layout>
        <div>
          <CloseButton onClick={onClose}>{t("Close")}</CloseButton>
          <PlanningNavigation />
          <CalendarDays />
          <LocationOccupations location={selectedLocation} />
        </div>
        <div>
          <FloorPlanLocationsList
            from={from}
            to={to}
            locations={locations}
            selectedLocation={selectedLocation}
            selectedFloorPlan={selectedFloorPlan}
            maxHeight={0}
            onSelect={setSelectedLocation}
          />
        </div>
        <div>
          <FloorPlan
            from={from}
            to={to}
            locations={locations}
            selectedLocation={selectedLocation}
            maxHeight={window.innerHeight - 150}
            setSelectedLocation={setSelectedLocation}
          />
        </div>
      </Layout>
    </PlanningDrawer>
  )
}
