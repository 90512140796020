import LocalDate from "utils/LocalDate"

export function getTimeTableHours(minHour: LocalDate, maxHour: LocalDate) {
  const hours: LocalDate[] = []
  for (
    let cursor = new LocalDate(minHour);
    cursor.getTime() <= maxHour.getTime();
    cursor.shift("HOUR", 1)
  ) {
    hours.push(new LocalDate(cursor))
  }
  return hours
}
