import useMediaQueries from "hooks/useMediaQueries"
import OccupationList from "pages/PlanningPage/components/PlanningHeadersRouter/OccupationList/OccupationList"
import React from "react"
import LocationTree from "utils/LocationTree"

interface PropsType {
  location: LocationTree | undefined
}

export default function LocationOccupations({ location }: PropsType) {
  const { isMobile } = useMediaQueries()
  if (!location && isMobile) return <div />
  if (!location) return <></>
  return <OccupationList location={location} variant="purple" />
}
