import { Tooltip as MuiTooltip } from "@mui/material"
import React, { MouseEvent, useState } from "react"
import styled from "styled-components"
import { colors } from "ui"
import { Info } from "ui/icons"

const StyledInfo = styled.div`
  display: flex;
  svg {
    height: 14px;
    width: 14px;
    aspect-ratio: 1;
  }
`

interface PropsType {
  text: string
  disabled?: boolean
}

export function InfoTooltip({ text, disabled }: PropsType) {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

  const handlePopoverOpen = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <MuiTooltip
        title={text}
        disableHoverListener={disabled}
        placement="top"
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: colors.grey3,
              color: colors.black,
              fontSize: "14px",
              lineHeight: "18px",
              fontWeight: "normal",
              top: "4px",
              whiteSpace: "pre-line",
              "& .MuiTooltip-arrow": {
                color: colors.grey3,
              },
            },
          },
        }}
        enterDelay={500}
        open={open}
      >
        <StyledInfo
          onMouseEnter={(e) => {
            handlePopoverOpen(e)
          }}
          onMouseLeave={() => {
            handlePopoverClose()
          }}
        >
          <Info />
        </StyledInfo>
      </MuiTooltip>
    </>
  )
}
