import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { colors, mediaQueries } from "ui"
import { ArrowRight } from "ui/icons"

const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 6px;
  padding: 8px 16px 8px 24px;
  border-radius: 10rem;
  color: ${colors.black};
  transition: all 0.4s;
  overflow: hidden;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  border: none;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  z-index: 2;
  background-color: ${colors.white};
  min-height: 40px;
  margin: 5% auto;
  width: fit-content;
  @media ${mediaQueries.isDesktop} {
    bottom: 10px;
  }
  @media ${mediaQueries.isMobile} {
    position: fixed;
    bottom: 10px;
    left: 0;
    right: 0;
  }
`
interface PropsType {
  onClick: () => void
}

export default function ValidationButton({ onClick }: PropsType) {
  const t = useTranslate()

  return (
    <Button onClick={onClick}>
      {t("go to the planning")} <ArrowRight />
    </Button>
  )
}
