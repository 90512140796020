import useUsersAt from "graphql/users/useUsersAt"
import useUserFlag from "hooks/useUserFlag"
import React, { useContext, useEffect, useRef, useState } from "react"
import { UserBase, UsersAtFilter, UsersAtInput, ViewModeType } from "types"
import LocalDate from "utils/LocalDate"
import LocationTree from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

import { computePlanningFromTo } from "./utils"

export interface ActiveLocation {
  location: LocationTree | null
  persist: boolean
  triggeredFrom: "search" | "active-locations-list" | "menu" | "planning"
}

interface PlanningContextType {
  // Group ID
  groupId?: string
  setGroupId: (groupId?: string) => void

  // Location ID
  locationId?: string
  setLocationId: (locationId?: string) => void

  // User IDS
  userIds?: string[]
  setUserIds: (userIds?: string[]) => void

  // Active locations
  activeLocation: ActiveLocation
  setActiveLocation: (activeLocation: ActiveLocation) => void

  // Selected Date
  selectedDate: UTCDate
  setSelectedDate: (selectedDate: UTCDate) => void

  setNextWeek: () => void
  setPreviousWeek: () => void
  setNextMonth: () => void
  setPreviousMonth: () => void
  setOnToday: () => void
  setWeek: (date: UTCDate) => void
  from: UTCDate
  to: UTCDate
  clipToWholeMonth: boolean

  // User Show Weekends Boolean
  userShowWeekends: boolean

  usersAtQuery: UsersAtInput

  users: UserBase[]

  openDrawer: "SLOTS_EDITOR" | "FLOOR_PLAN" | null
  setOpenDrawer: (openDrawer: "SLOTS_EDITOR" | "FLOOR_PLAN" | null) => void

  // User (me) picks a specific week day
  // which will replace from/to used in usersAtQuery
  focusedDate?: UTCDate
  setFocusedDate: (date?: UTCDate) => void

  usersAtFilters: UsersAtFilter[]
  setUsersAtFilters: (filters: UsersAtFilter[]) => void

  showHiddenActiveLocations: boolean
  setShowHiddenActiveLocations: (bool: boolean) => void

  viewMode: ViewModeType
  setViewMode: (mode: ViewModeType) => void

  loading: boolean

  isTypicalWeekOpen: boolean
  setIsTypicalWeekOpen: (bool: boolean) => void

  visibleTeams: string[]
  setVisibleTeams: (teamIds: string[]) => void
  setTeamVisibility: (teamId: string, visible: boolean) => void
}

const emptyFunc = () => {
  //
}

const initialPlanningContext: PlanningContextType = {
  // main filters
  groupId: undefined,
  locationId: undefined,
  userIds: undefined,
  setGroupId: emptyFunc,
  setLocationId: emptyFunc,
  setUserIds: emptyFunc,

  selectedDate: new UTCDate("WEEK-MONDAY"),
  setSelectedDate: emptyFunc,
  from: new UTCDate("WEEK-MONDAY"),
  to: new UTCDate("WEEK-MONDAY", "NEXT-SATURDAY"),
  clipToWholeMonth: true,
  userShowWeekends: false,
  usersAtQuery: {
    from: new UTCDate("WEEK-MONDAY"),
    to: new UTCDate("WEEK-MONDAY", "NEXT-SATURDAY"),
    filters: [],
  },
  setNextWeek: emptyFunc,
  setPreviousWeek: emptyFunc,
  setNextMonth: emptyFunc,
  setPreviousMonth: emptyFunc,
  setOnToday: emptyFunc,
  setWeek: emptyFunc,
  setActiveLocation: emptyFunc,
  activeLocation: {
    location: null,
    persist: false,
    triggeredFrom: "search",
  },

  users: [],

  openDrawer: null,
  setOpenDrawer: emptyFunc,

  focusedDate: undefined,
  setFocusedDate: emptyFunc,

  usersAtFilters: [],
  setUsersAtFilters: emptyFunc,

  showHiddenActiveLocations: false,
  setShowHiddenActiveLocations: emptyFunc,

  viewMode: "WEEK",
  setViewMode: emptyFunc,

  loading: true,

  isTypicalWeekOpen: false,
  setIsTypicalWeekOpen: emptyFunc,

  visibleTeams: [],
  setVisibleTeams: emptyFunc,
  setTeamVisibility: emptyFunc,
}

const PlanningContext = React.createContext(initialPlanningContext)

interface PropsType {
  children: React.ReactNode
}

export default function PlanningContextProvider({ children }: PropsType) {
  // main filters
  const [groupId, _setGroupId] = useState<string | undefined>(undefined)
  const [locationId, _setLocationId] = useState<string | undefined>(undefined)
  const [userIds, _setUserIds] = useState<string[] | undefined>(undefined)

  // active location
  const [activeLocation, setActiveLocation] = useState<ActiveLocation>({
    location: null,
    persist: false,
    triggeredFrom: "search",
  })

  const [viewMode, updateViewMode] = useState<ViewModeType>("WEEK")
  const setViewMode = (mode: ViewModeType) => {
    previousViewMode.current = viewMode
    updateViewMode(mode)
    if (mode === "MONTH") setSelectedDate(new UTCDate(selectedDate, "MONTH"))
    if (mode === "DAY") setSelectedDate(new UTCDate("DAY"))
    else setOnToday()
  }
  const previousViewMode = useRef<ViewModeType>("WEEK")
  const clipToWholeMonth = previousViewMode.current === "WEEK"

  const [openDrawer, setOpenDrawer] = useState<
    "SLOTS_EDITOR" | "FLOOR_PLAN" | null
  >(null)
  const [isTypicalWeekOpen, setIsTypicalWeekOpen] = useState(false)

  const today = new LocalDate()
  const todayIsWeekendDay = today.getDay() === 0 || today.getDay() === 6

  const [selectedDate, setSelectedDate] = useState<UTCDate>(
    todayIsWeekendDay ? new UTCDate("NEXT-MONDAY") : new UTCDate("WEEK-MONDAY")
  )
  const userShowWeekends = useUserFlag("userShowWeekends") ?? false
  const [from, setFrom] = useState(
    computePlanningFromTo(selectedDate, viewMode, userShowWeekends).from
  )
  const [to, setTo] = useState(
    computePlanningFromTo(selectedDate, viewMode, userShowWeekends).to
  )
  const [visibleTeams, setVisibleTeams] = useState<string[]>([])
  const setTeamVisibility = (teamId: string, visible: boolean) => {
    const filteredTeams = visibleTeams.filter((id) => id !== teamId)
    if (!visible) return setVisibleTeams(filteredTeams)
    setVisibleTeams([...filteredTeams, teamId])
  }

  const clearActiveLocationSelection = () => {
    setActiveLocation({
      location: null,
      persist: false,
      triggeredFrom: "search",
    })
  }
  const clearFocusedDateSelection = () => {
    setFocusedDate(undefined)
  }

  const setOnToday = () => {
    clearActiveLocationSelection()
    clearFocusedDateSelection()
    setSelectedDate(new UTCDate("WEEK-MONDAY"))
  }
  const setNextWeek = () => {
    clearActiveLocationSelection()
    clearFocusedDateSelection()
    setSelectedDate(new UTCDate(selectedDate, "NEXT-MONDAY"))

    // Naive approach to manage clipToWholeMonth
    if (viewMode === "MONTH") previousViewMode.current = "MONTH"
  }
  const setPreviousWeek = () => {
    clearActiveLocationSelection()
    clearFocusedDateSelection()
    const timeFrameStart = new UTCDate(selectedDate, "NEXT-MONDAY")
    timeFrameStart.shift("WEEK", -2)
    setSelectedDate(timeFrameStart)

    // Naive approach to manage clipToWholeMonth
    if (viewMode === "MONTH") previousViewMode.current = "MONTH"
  }
  const setNextMonth = () => {
    clearActiveLocationSelection()
    clearFocusedDateSelection()
    const target = new UTCDate(selectedDate, "MONTH")
    target.shift("MONTH", 1)
    setSelectedDate(target)
    if (viewMode === "MONTH") previousViewMode.current = "WEEK"
  }
  const setPreviousMonth = () => {
    clearActiveLocationSelection()
    clearFocusedDateSelection()
    const target = new UTCDate(selectedDate, "MONTH")
    target.shift("MONTH", -1)
    setSelectedDate(target)

    // Naive approach to manage clipToWholeMonth
    if (viewMode === "MONTH") previousViewMode.current = "WEEK"
  }
  const setWeek = (date: UTCDate) => {
    clearActiveLocationSelection()
    clearFocusedDateSelection()

    // Naive approach to manage clipToWholeMonth
    setSelectedDate(new UTCDate(date, "WEEK-MONDAY"))
  }

  const [usersAtFilters, setUsersAtFilters] = useState<UsersAtFilter[]>([])

  const [usersAtQuery, setUsersAtQuery] = useState<UsersAtInput>({
    from: new UTCDate("WEEK-MONDAY"),
    to: new UTCDate("WEEK-MONDAY", "NEXT-SATURDAY"),
    filters: usersAtFilters,
  })

  const setGroupId = (groupId?: string) => {
    _setGroupId(groupId)
    _setUserIds(undefined)
    _setLocationId(undefined)
    setUsersAtQuery({
      groupIds: groupId ? [groupId] : undefined,
      filters: usersAtFilters,
    })
  }

  const setLocationId = (locationId?: string) => {
    _setGroupId(undefined)
    _setUserIds(undefined)
    _setLocationId(locationId)
    setUsersAtQuery({
      from,
      to,
      locationIds: locationId ? [locationId] : undefined,
      filters: usersAtFilters,
    })
  }

  const setUserIds = (userIds?: string[]) => {
    _setGroupId(undefined)
    _setUserIds(userIds)
    _setLocationId(undefined)
    setUsersAtQuery({
      userIds,
      filters: usersAtFilters,
    })
  }

  const [focusedDate, setFocusedDate] = useState<UTCDate | undefined>(undefined)
  const [showHiddenActiveLocations, setShowHiddenActiveLocations] =
    useState<boolean>(false)

  const { users, loading } = useUsersAt(usersAtQuery)

  // Main Effect Loop
  // Refetch usersAt when from/to/focusedDate time states change
  // or when groupId, locationId, userIds, activeLocation change
  useEffect(() => {
    let usersAtFrom: UTCDate | undefined = undefined
    let usersAtTo: UTCDate | undefined = undefined
    if (focusedDate !== undefined) {
      usersAtFrom = new UTCDate(focusedDate)
      usersAtTo = new UTCDate(usersAtFrom, "NEXT-DAY")
    } else {
      usersAtFrom = new UTCDate(from)
      usersAtTo = new UTCDate(to)
    }
    const getLocationIds = () => {
      const selectedActiveLocationId =
        activeLocation.persist && activeLocation.location
          ? activeLocation.location.id
          : undefined
      if (locationId) {
        return selectedActiveLocationId
          ? Array.from(new Set([selectedActiveLocationId, locationId]))
          : [locationId]
      }
      return selectedActiveLocationId ? [selectedActiveLocationId] : undefined
    }
    setUsersAtQuery({
      from: usersAtFrom,
      to: usersAtTo,
      groupIds: groupId ? [groupId] : undefined,
      locationIds: getLocationIds(),
      userIds,
      filters: usersAtFilters,
    })
  }, [
    from,
    to,
    groupId,
    locationId,
    userIds,
    activeLocation,
    focusedDate,
    usersAtFilters,
  ])

  useEffect(() => {
    if (isTypicalWeekOpen) return
    // when switching from week -> month
    // we want to display a whole month
    // but when already in month view
    // we want to be able to translate the vieww +/- 1 week
    // in this case clipToWholeMonth os set to false
    const { from, to } = computePlanningFromTo(
      selectedDate,
      viewMode,
      userShowWeekends,
      clipToWholeMonth
    )

    setFrom(from)
    setTo(to)
  }, [
    viewMode,
    selectedDate,
    userShowWeekends,
    isTypicalWeekOpen,
    clipToWholeMonth,
  ])

  const value: PlanningContextType = {
    groupId,
    locationId,
    userIds,
    setGroupId,
    setLocationId,
    setUserIds,
    activeLocation,
    setActiveLocation,
    selectedDate,
    setSelectedDate,
    from,
    to,
    userShowWeekends,
    clipToWholeMonth,
    usersAtQuery,
    setNextWeek,
    setNextMonth,
    setOnToday,
    setWeek,
    setPreviousWeek,
    setPreviousMonth,
    users,
    openDrawer,
    setOpenDrawer,
    focusedDate,
    setFocusedDate,
    usersAtFilters,
    setUsersAtFilters,
    showHiddenActiveLocations,
    setShowHiddenActiveLocations,
    viewMode,
    setViewMode,
    loading,
    isTypicalWeekOpen,
    setIsTypicalWeekOpen,
    visibleTeams,
    setVisibleTeams,
    setTeamVisibility,
  }

  return (
    <PlanningContext.Provider value={value}>
      {children}
    </PlanningContext.Provider>
  )
}

export function usePlanningContext() {
  return useContext(PlanningContext)
}
