import { UTCSlot } from "types"

import clearElementClasslist from "./clearElementClassList"

function getCurrentUserSlotClassList({
  isFirst,
  isLast,
  value,
}: {
  isFirst?: boolean
  isLast?: boolean
  value: string | null
}) {
  const classList = [
    "cuse",
    "cuse--no-transition",
    value ? `cuse--${value}` : "cuse--null",
  ]
  isFirst && isLast ? classList.push("cuse--solo") : ""
  isFirst && !isLast ? classList.push("cuse--first") : ""
  !isFirst && isLast ? classList.push("cuse--last") : ""
  !isFirst && !isLast ? classList.push("cuse--between") : ""

  // empty strings are invalid classes
  return [...classList.filter((c) => c !== "")]
}

export default function updateCurrentUserSlotsClassList(
  element: HTMLElement,
  currentSlots: UTCSlot[]
) {
  Array.from(element.children).forEach((child, i) => {
    if (currentSlots[i]) {
      clearElementClasslist(child.children[0])

      child.children[0].classList.add(
        ...getCurrentUserSlotClassList({ ...currentSlots[i] })
      )
    }
  })
}
