import Loader from "components/Loader/Loader"
import useCompanyHybridRules from "graphql/hybridPolicy/useCompanyHybridRules"
import useTranslate from "intl/useTranslate"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import {
  DefaultHybridRule,
  GroupHybridRule,
  ManagerHybridRule,
  UserHybridRule,
} from "types"
import { colors, H4, L12GreyBold, P16Bold } from "ui"

import HybridRulesBrowserButton from "./HybridRulesBrowserButton/HybridRulesBrowserButton"
import GlobalScoreChart from "./ScoreChart/ScoreChart"
import DefaultScoreNullComponent from "./ScoreChart/ScoreNullComponents/DefaultScoreNullComponent"
import GroupScoreNullComponent from "./ScoreChart/ScoreNullComponents/GroupScoreNullComponent"
import ManagerScoreNullComponent from "./ScoreChart/ScoreNullComponents/ManagerScoreNullComponent"
import UserScoreNullComponent from "./ScoreChart/ScoreNullComponents/UserScoreNullComponent"
import {
  isGroupHybridRule,
  isManagerHybridRule,
  isUserHybridRule,
  LOADED_SCORE_EVENT_KEY,
  LoadedScoreEventDetail,
  LoadingScore,
} from "./types"

const Body = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${colors.grey4};
  width: 100%;
  border-radius: 16px;
  padding: 24px;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 24px;
  }
  .content {
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;

    canvas {
      width: 112px;
      max-width: 112px;
    }

    .second-title {
      margin-top: 16px;
    }
  }
`

interface PropsType {
  concerned: number
  compliant: number
}

function CompanyScoreCard({ concerned, compliant }: PropsType) {
  const t = useTranslate()

  const concernedLength = concerned
  const notCompliantsLength = concernedLength - compliant

  return (
    <Body>
      <div className="header">
        <H4>{t("Compliance with hybrid rules")}</H4>
        <HybridRulesBrowserButton title={t("view scores")} />
      </div>

      <div className="content">
        <GlobalScoreChart
          concerned={concernedLength}
          notCompliant={notCompliantsLength}
        />
        <div>
          <L12GreyBold>{t("respected")}</L12GreyBold>
          <P16Bold color="purple">
            {concernedLength - notCompliantsLength} {t("Collaborators")}
          </P16Bold>
          <L12GreyBold className="second-title">
            {t("not respected")}
          </L12GreyBold>
          <P16Bold color="redAlert">
            {notCompliantsLength} {t("Collaborators")}
          </P16Bold>
        </div>
      </div>
    </Body>
  )
}

export default function Wrapper() {
  const { companyHybridRules, loading } = useCompanyHybridRules()

  const allCompanyRules: LoadingScore<
    DefaultHybridRule | GroupHybridRule | UserHybridRule | ManagerHybridRule
  >[] = companyHybridRules.default
    ? [
        { rule: companyHybridRules.default, loading: true },
        ...companyHybridRules.groups.map((rule) => ({ rule, loading: true })),
        ...companyHybridRules.users.map((rule) => ({ rule, loading: true })),
        ...companyHybridRules.managers.map((rule) => ({ rule, loading: true })),
      ]
    : []

  const [allScoresLoaded, setAllScoresLoaded] = useState(false)
  const loadedScores = useRef<LoadedScoreEventDetail[]>([])

  // when child has loaded its score:
  // * let's add it to the stack if not already included
  // * IF score is added to the stack trigger "loadedscore" event

  const dispatchLoadedEvent = (detail: LoadedScoreEventDetail) => {
    document.dispatchEvent(
      new CustomEvent<LoadedScoreEventDetail>(LOADED_SCORE_EVENT_KEY, {
        detail,
      })
    )
  }
  const addScoreAsLoaded = (detail: LoadedScoreEventDetail) => {
    const ruleId = detail.id.split("-")[0]
    const foundScore = loadedScores.current.find(({ id }) => id === ruleId)
    if (!foundScore) {
      loadedScores.current.push({ ...detail, id: ruleId })
      dispatchLoadedEvent({ ...detail, id: ruleId })
    } else {
      // score already exists has it changed ?
      if (
        detail.compliantUserIds !== foundScore.compliantUserIds ||
        detail.concernedUserIds !== foundScore.concernedUserIds
      )
        loadedScores.current = [
          ...loadedScores.current.filter(({ id }) => id !== ruleId),
          { ...detail, id: ruleId },
        ]
    }
  }

  // company score is the concatenation of all company rules score:
  // 1. since each rule can has period: week | month
  //    the score is computed on current week for week rule
  //    and current month for month rule
  // 2. each rule has a compliant and concerned ids array
  // 3. companyScore.compliant is the sum of every rule.compliant ids
  // 4. companyScore.concerned is the sum of every rule.compliant ids
  //    a. we should have concerned === company.users.length
  const companyScore = loadedScores.current.reduce(
    (acc, curr) => [
      acc[0] + curr.compliantUserIds.length,
      acc[1] + curr.concernedUserIds.length,
    ],
    [0, 0]
  )

  // scores have to be fetch one by one
  // this could take some time
  // let's listen to a custom event and decide when everything is loaded
  // AND let's add a timeout to hide loader after 30s if:
  //     * for whatever reason no more rules to load
  //     * BUT allCompanyRules.length > allLoaded.length
  useEffect(() => {
    setTimeout(() => {
      setAllScoresLoaded(true)
    }, 30000)

    const allScoresLoaded = (() => {
      if (loadedScores.current.length === allCompanyRules.length)
        setAllScoresLoaded(true)
    }) as EventListener
    document.addEventListener(LOADED_SCORE_EVENT_KEY, allScoresLoaded, false)

    return () => {
      document.removeEventListener(LOADED_SCORE_EVENT_KEY, allScoresLoaded)
      loadedScores.current = []
      setAllScoresLoaded(false)
    }
  }, [allCompanyRules.length])

  if (!companyHybridRules.default) return <></>

  return (
    <>
      {allCompanyRules.map((companyRule, n) => {
        const key = `score-${n}-${companyRule.rule.id}`
        if (companyRule.rule.id === companyHybridRules.default?.id)
          return (
            <DefaultScoreNullComponent
              key={key}
              score={companyRule}
              addScoreAsLoaded={addScoreAsLoaded}
            />
          )

        if (isGroupHybridRule(companyRule.rule))
          return (
            <GroupScoreNullComponent
              key={key}
              score={{ ...companyRule, rule: companyRule.rule }}
              addScoreAsLoaded={addScoreAsLoaded}
            />
          )

        if (isUserHybridRule(companyRule.rule))
          return (
            <UserScoreNullComponent
              key={key}
              score={{ ...companyRule, rule: companyRule.rule }}
              addScoreAsLoaded={addScoreAsLoaded}
            />
          )

        if (isManagerHybridRule(companyRule.rule))
          return (
            <ManagerScoreNullComponent
              key={key}
              score={{ ...companyRule, rule: companyRule.rule }}
              addScoreAsLoaded={addScoreAsLoaded}
            />
          )
      })}
      {(!allScoresLoaded || loading) && (
        <Body>
          <Loader />
        </Body>
      )}
      {allScoresLoaded ? (
        <CompanyScoreCard
          compliant={companyScore[0]}
          concerned={companyScore[1]}
        />
      ) : (
        <div />
      )}
    </>
  )
}
