import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import useAllGroups from "graphql/groups/useAllGroups"
import useMyGroupsSimple from "graphql/groups/useMyGroupsSimple"
import uniqBy from "lodash.uniqby"
import React, { useState } from "react"
import styled from "styled-components"
import { GroupSimple, UserBase } from "types"
import { Toggle } from "ui"
import LocationTree from "utils/LocationTree"
import UTCDate, { UTCDateClampType } from "utils/UTCDate"

import DatePicker from "../components/DatePicker"
import { DataLayout, Layout, SampleList } from "../components/Layout"
import SamplePicker from "../components/SamplePicker/SamplePicker"
import WorkingModeChart from "./WorkingModeChart/WorkingModeChart"

const ChartLayout = styled.div`
  display: flex;
  flex-direction: column;

  > div > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 16px 0;
  }
  canvas {
    max-width: 250px;
  }

  > :last-child {
    margin-left: auto;
  }

  @media (max-width: 1080px) {
    > div > div {
      margin: 0 auto;
    }

    canvas {
      max-width: 200px;
    }
  }
`

interface PropsType {
  from: UTCDate
  to: UTCDate
  selectedPeriod: UTCDateClampType | "personalized"
  enableWeekends: boolean
  pageHeaderHeight: { header?: number; tabsHeader?: number }
  editSelectedPeriod: (string: UTCDateClampType | "personalized") => void
  editFromDate: (date: UTCDate) => void
  editToDate: (date: UTCDate) => void
  editEnableWeekends: (bool: boolean) => void
}

export default function WorkingMode({
  from,
  to,
  selectedPeriod,
  enableWeekends,
  pageHeaderHeight,
  editEnableWeekends,
  editSelectedPeriod,
  editFromDate,
  editToDate,
}: PropsType) {
  const alreadyOccupiedHeight =
    pageHeaderHeight.header !== undefined &&
    pageHeaderHeight.tabsHeader !== undefined
      ? pageHeaderHeight.header + pageHeaderHeight.tabsHeader
      : undefined

  const { groups: myGroupsSimple, loading, error } = useMyGroupsSimple()
  const {
    groups: allGroups,
    loading: loadingAllGroups,
    error: errorAllGroups,
  } = useAllGroups()

  const [selectedGroups, setSelectedGroups] = useState<GroupSimple[]>([])
  const [selectedUsers, setSelectedUsers] = useState<UserBase[]>([])

  const [showTotal, setShowTotal] = useState(false)

  const isTotalValid = () => {
    if (selectedUsers.length < 2 && selectedGroups.length === 0) return false
    if (selectedGroups.length < 2 && selectedUsers.length === 0) return false
    if (selectedUsers.length < 1 && selectedGroups.length < 1) return false
    return true
  }

  // when total become invalid: not enough item selected
  // then hide total toggle and bar in chart
  if (!isTotalValid() && showTotal) setShowTotal(false)

  if (loading || loadingAllGroups || error || errorAllGroups)
    return (
      <LoaderOrErrorComponent
        loading={loading || loadingAllGroups}
        error={error || errorAllGroups}
      />
    )

  return (
    <Layout>
      <SampleList alreadyOccupiedHeight={alreadyOccupiedHeight}>
        <SamplePicker
          data={{
            users: [],
            groups: uniqBy([...myGroupsSimple, ...allGroups], "id"),
            locations: [],
          }}
          onChange={(sample: {
            users: UserBase[]
            groups: GroupSimple[]
            locations: LocationTree[]
          }) => {
            setSelectedGroups(sample.groups)
            setSelectedUsers(sample.users)
          }}
        />
      </SampleList>
      <DataLayout>
        <div>
          <DatePicker
            from={from}
            to={to}
            selectedPeriod={selectedPeriod}
            enableWeekends={enableWeekends}
            editEnableWeekends={editEnableWeekends}
            editSelectedPeriod={editSelectedPeriod}
            editFromDate={editFromDate}
            editToDate={editToDate}
          />
        </div>
        <ChartLayout>
          <WorkingModeChart
            users={selectedUsers}
            groups={selectedGroups}
            from={from}
            to={to}
            includeWeekends={enableWeekends}
            showTotal={showTotal && isTotalValid()}
          />
          {isTotalValid() && (
            <Toggle
              checked={showTotal && isTotalValid()}
              onChange={setShowTotal}
              label={"Total"}
              labelToTheLeft
              disabled={
                selectedGroups.length === 0 && selectedUsers.length === 0
              }
            />
          )}
        </ChartLayout>
      </DataLayout>
    </Layout>
  )
}
