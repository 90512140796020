import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { useTeamsAppContext } from "TeamsApp/TeamsAppContext"
import { EditionTimeFrame } from "types"
import { colors, IconButton, mediaQueries } from "ui"
import { Cancel, Settings } from "ui/icons"
import UTCDate from "utils/UTCDate"

import FromToPicker from "./FromToPicker/FromToPicker"
import SettingsActions from "./SettingsActions/SettingsActions"

const Layout = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 24px 16px 16px 16px;
  gap: 16px;
  border-bottom: 1px solid ${colors.grey3};

  @media ${mediaQueries.isMobile} {
    flex-direction: column;
    gap: 4px;
    border-bottom: 1px solid ${colors.grey3};
    padding: 4px 8px 8px 8px;
    width: 100vw;
  }
`

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 40px;
  aspect-ratio: 1;
  border: none;
  border-radius: 4px;
  background: ${colors.grey4}00;

  &:hover {
    background: ${colors.grey4};
  }
  svg {
    stroke: ${colors.purple};

    @media ${mediaQueries.isMobile} {
      stroke: ${colors.grey1};
    }
  }
`

const ReinitButton = styled.button`
  padding: 4px 16px;
  background: none;
  border-radius: 100vh;
  border: solid 1px ${colors.grey3};
  color: ${colors.grey1};

  &.MONTH {
    margin: 0 auto;
    width: 256px;
  }

  &:hover {
    background: ${colors.grey4};
    border-color: ${colors.red};
    color: ${colors.red};
  }

  @media ${mediaQueries.isMobile} {
    width: 100%;
  }

  transition-property: color background-color;
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;
`

const MobileShowActionsButton = styled(IconButton)`
  margin-left: auto;

  &.btn--active {
    background: ${colors.purple}14;
    svg {
      stroke: ${colors.purple};
    }
  }

  svg {
    stroke: ${colors.purple};
    color: ${colors.purple};
    width: 24px;
    aspect-ratio: 1;
  }
`

const ButtonsLayout = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  > svg {
    width: 20px;
  }

  @media ${mediaQueries.isMobile} {
    display: flex;
    width: 100%;
    gap: 4px;

    > svg {
      width: 14px;
    }
  }
`

interface PropsType {
  from: UTCDate
  to: UTCDate
  setEditionTimeFrame: React.Dispatch<React.SetStateAction<EditionTimeFrame>>
  inviteGuestsMessage: string
  guestsEmails: string[]
  updateGuestsEmails: (emails: string[]) => void
  updateInviteGuestsMessage: (description: string) => void
  onClearSlots: () => void
}

export default function SlotsSettings({
  from,
  to,
  setEditionTimeFrame,
  guestsEmails,
  inviteGuestsMessage,
  updateGuestsEmails,
  updateInviteGuestsMessage,
  onClearSlots,
}: PropsType) {
  const t = useTranslate()
  const { openDrawer, setOpenDrawer, viewMode } = usePlanningContext()
  const bookingMaxDate = new UTCDate("DAY")
  bookingMaxDate.increment("YEAR")
  const { isMobile } = useMediaQueries()
  const { isTeamsApp } = useTeamsAppContext()

  const [hideActions, setHideActions] = useState(true)
  const showActions =
    viewMode !== "MONTH" && (!hideActions || !isMobile) && !isTeamsApp

  return (
    <Layout>
      <ButtonsLayout>
        <CloseButton
          onClick={() => {
            if (openDrawer !== null) setOpenDrawer(null)
            setEditionTimeFrame({})
          }}
        >
          <Cancel />
        </CloseButton>
        {viewMode !== "MONTH" && (
          <FromToPicker
            from={from}
            to={to}
            setEditionTimeFrame={setEditionTimeFrame}
          />
        )}
        {isMobile && (
          <MobileShowActionsButton
            ghost
            icon={<Settings />}
            onClick={() => {
              setHideActions((prev) => !prev)
            }}
          />
        )}
      </ButtonsLayout>
      {!isMobile && (
        <ReinitButton className={viewMode} onClick={onClearSlots}>
          {t("Reinitialize")}
        </ReinitButton>
      )}
      {showActions && (
        <SettingsActions
          from={from}
          guestsEmails={guestsEmails}
          inviteGuestsMessage={inviteGuestsMessage}
          updateGuestsEmails={updateGuestsEmails}
          updateInviteGuestsMessage={updateInviteGuestsMessage}
          setEditionTimeFrame={setEditionTimeFrame}
        />
      )}
    </Layout>
  )
}
