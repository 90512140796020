import useUser from "graphql/users/useUser"
import UTCDate from "utils/UTCDate"

export default function useBookingMinDate() {
  const { user: me } = useUser()
  const shouldBlockPastSlots =
    Boolean(me?.company.flags.blockPastSlots) &&
    !location.pathname.startsWith("/typical-week")

  if (!shouldBlockPastSlots) return undefined
  return new UTCDate("WEEK-MONDAY")
}
