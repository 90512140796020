import useAddUserSubscription from "graphql/subscriptions/useAddUserSubscription"
import useRemoveUserSubscription from "graphql/subscriptions/useRemoveUserSubscription"
import useUserSubscriptions from "graphql/subscriptions/useUserSubscriptions"
import useUser from "graphql/users/useUser"
import React from "react"
import styled from "styled-components"
import { colors } from "ui"
import { Bookmark } from "ui/icons"

const Layout = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const Input = styled.input`
  position: absolute;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
`

interface SimplePropsType {
  className?: string
  checked: boolean
  onChange: () => void
}

function Checkbox({ className, checked, onChange }: SimplePropsType) {
  return (
    <Layout className={className}>
      <Input
        type="checkbox"
        checked={checked}
        onChange={(e) => {
          e.stopPropagation()
          onChange()
        }}
      />
      <Bookmark
        fill={checked ? colors.success : undefined}
        stroke={checked ? colors.success : colors.grey1}
      />
    </Layout>
  )
}

function UserBaseBookmarkCheckbox({
  id,
  className,
}: {
  id: string
  className?: string
}) {
  const { subscriptions } = useUserSubscriptions()
  const { addUserSubscription } = useAddUserSubscription()
  const { removeUserSubscription } = useRemoveUserSubscription()
  const checked = subscriptions.find((s) => s.id === id) !== undefined

  const onChange = () => {
    if (checked) return removeUserSubscription(id)
    return addUserSubscription(id)
  }
  return (
    <Checkbox className={className} checked={checked} onChange={onChange} />
  )
}

interface PropsType {
  type: "user"
  id: string
  className?: string
}

export default function BookmarkCheckbox({ type, id, className }: PropsType) {
  const { user: me } = useUser()
  if (me?.id === id) return <></>
  if (type === "user")
    return <UserBaseBookmarkCheckbox id={id} className={className} />
  return <></>
}
