import useOccupancyRate from "graphql/stats/useOccupancyRate"
import React, { useEffect, useState } from "react"
import LocationTree from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

interface Propstype {
  location: LocationTree
  from: UTCDate
  to: UTCDate
  includeWeekends: boolean
  occupancies: {
    location: LocationTree
    occupancyRate: { value: number; booked: number; total: number }
  }[]

  updateData: (
    location: LocationTree,
    occupancyRate: { value: number; booked: number; total: number },
    remove?: boolean
  ) => void
}

export default function OccupancyBar({
  location,
  from,
  to,
  includeWeekends,
  occupancies,
  updateData,
}: Propstype) {
  const { occupancyRate, loading, error } = useOccupancyRate({
    from,
    to,
    locationIds: [location.id],
    includeWeekends,
  })

  const [previousTimeFrame, setPreviousTimeFrame] = useState<
    { from: UTCDate; to: UTCDate; includeWeekends: boolean } | undefined
  >(undefined)

  useEffect(() => {
    if (loading || error) return

    const timeFrameHasChanged =
      previousTimeFrame?.from.getTime() !== from.getTime() ||
      previousTimeFrame?.to.getTime() !== to.getTime() ||
      previousTimeFrame?.includeWeekends !== includeWeekends

    const prevOccupancy = occupancies.find((o) => o.location.id === location.id)
    if (prevOccupancy === undefined || timeFrameHasChanged) {
      // update parent dataset with this data if:
      // * this data does not exist in parent dataset yet
      // * parent time frame has changed
      updateData(location, occupancyRate)
      setPreviousTimeFrame({ from, to, includeWeekends })
    }
  }, [
    loading,
    error,
    location,
    occupancies,
    occupancyRate,
    updateData,
    from,
    to,
    includeWeekends,
    previousTimeFrame,
  ])

  useEffect(() => {
    return () => updateData(location, occupancyRate, true)
    // eslint-disable-next-line
  }, [])

  return <></>
}
