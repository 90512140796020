import styled from "styled-components/macro"
import { getColor } from "ui/colors"

export const H1 = styled.h1`
  font-family: Sora;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 56px;
  color: ${(props) => getColor(props.color)};
`

export const H1Plus = styled.h1`
  font-family: Sora;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 80px;
  color: ${(props) => getColor(props.color)};
`

export const H1Light = styled.h1`
  font-family: Sora;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 56px;
  color: ${(props) => getColor(props.color)};
`

export const H2 = styled.h2`
  font-family: Sora;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 32px;
  color: ${(props) => getColor(props.color)};
`

export const H3 = styled.h3`
  font-family: Sora;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: ${(props) => getColor(props.color)};
`

export const H3Light = styled.h3`
  font-family: Sora;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  color: ${(props) => getColor(props.color)};
`

export const H4 = styled.h4`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: ${(props) => getColor(props.color)};
`

export const H4Light = styled.h4`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: ${(props) => getColor(props.color)};
`

export const P16Bold = styled.p`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => getColor(props.color)};
`

export const P16 = styled.p`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => getColor(props.color)};
`

export const P14Bold = styled.p`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${(props) => getColor(props.color)};
`

export const P14 = styled.p`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: ${(props) => getColor(props.color)};
`

export const L12 = styled.p`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => getColor(props.color)};
`

export const L12GreyBold = styled.p`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: ${getColor("grey1")};
`

export const LU12 = styled.p`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${(props) => getColor(props.color)};
`

export const LU14 = styled.p`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${(props) => getColor(props.color)};
`

export const Label = styled.label`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: ${getColor("grey1")};
  margin-bottom: 8px;

  &.required::after {
    content: "*";
  }
`
