import { gql, useMutation } from "@apollo/client"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import { QUERY as LOCATIONS_AT_QUERY } from "graphql/locations/useLocationsAt"
import { CustomErrorFragment } from "graphql/misc/customErrorFragment"
import { QUERY as USERS_AT_QUERY } from "graphql/users/useUsersAt"
import useCustomErrorToast from "hooks/useCustomErrorToast"
import { CustomError } from "types"
import UTCDate from "utils/UTCDate"

import { SlotFragment } from "../slots/fragments/slotFragment"
import { BulkSlotRuleFragment } from "./fragment/bulkSlotRuleFragment"

const ADD_BULK_SLOT_RULE_MUTATION = gql`
  ${SlotFragment}
  ${BulkSlotRuleFragment}
  ${CustomErrorFragment}
  mutation addBulkSlotRule(
    $from: String!
    $to: String
    $status: String!
    $label: String
    $locationId: ID
    $title: String
    $applyCompanyWide: Boolean
    $applyToUsers: [ID]
    $applyToTeams: [ID]
    $applyToGroups: [ID]
  ) {
    addBulkSlotRule(
      from: $from
      to: $to
      status: $status
      label: $label
      locationId: $locationId
      title: $title
      applyCompanyWide: $applyCompanyWide
      applyToUsers: $applyToUsers
      applyToTeams: $applyToTeams
      applyToGroups: $applyToGroups
    ) {
      slots {
        ...SlotFragment
      }
      rule {
        ...BulkSlotRuleFragment
      }
      errors {
        ...CustomErrorFragment
      }
    }
  }
`

export default function useAddBulkSlotRule() {
  const {
    usersAtQuery: { from, to, userIds, locationIds, groupIds },
  } = usePlanningContext()

  const refetchVariables = {
    from: `${(from || new UTCDate()).getTime()}`,
    to: `${(to || new UTCDate()).getTime()}`,
    userIds: userIds && userIds.length > 0 ? userIds : undefined,
    locationIds,
    groupIds,
  }

  const { addToastError } = useCustomErrorToast()
  const [addBulkSlotRule] = useMutation<{
    addBulkSlotRule: {
      slots: any
      errors: CustomError[]
      rule: any
    }
  }>(ADD_BULK_SLOT_RULE_MUTATION, {
    update(cache, { data }) {
      if (data?.addBulkSlotRule) {
        const { addBulkSlotRule } = data
        const { slots: slotsFromQuery, rule, errors } = addBulkSlotRule
        cache.modify({
          fields: {
            slots(existing = []) {
              const slots = existing.filter(
                (es: any) =>
                  es.__ref !==
                  `${slotsFromQuery.__typename}:${slotsFromQuery.id}`
              )
              const newRefs = slotsFromQuery.map((slot: any) =>
                cache.writeFragment({
                  data: slot,
                  fragment: SlotFragment,
                })
              )
              return [...slots, ...newRefs]
            },
            bulkSlotRules() {
              cache.writeFragment({
                data: rule,
                fragment: BulkSlotRuleFragment,
              })
            },
          },
        })
        if (errors.length > 0) {
          addToastError(errors[0])
        }
      }
    },
    refetchQueries: [
      {
        query: USERS_AT_QUERY,
        fetchPolicy: "network-only",
        variables: refetchVariables,
      },
      {
        query: LOCATIONS_AT_QUERY,
        fetchPolicy: "network-only",
        variables: refetchVariables,
      },
    ],
  })
  return (
    from: UTCDate,
    to: UTCDate | null,
    status: string,
    label: string | null,
    locationId: string | null,
    title: string | null,
    who: {
      everyone: boolean
      users?: string[]
      teams?: string[]
      groups?: string[]
    }
  ) => {
    return addBulkSlotRule({
      variables: {
        from: from.getTime().toString(),
        to: to?.getTime().toString(),
        status,
        label,
        locationId,
        title,
        applyCompanyWide: who.everyone,
        applyToUsers: who.users,
        applyToTeams: who.teams,
        applyToGroups: who.groups,
      },
    }).catch(({ message }) => {
      addToastError({ message })
    })
  }
}
