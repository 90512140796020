import { gql, useMutation } from "@apollo/client"

const SEND_NEW_REGISTRATION_EMAIL_MUTATION = gql`
  mutation sendNewRegistrationEmail($userId: ID) {
    sendNewRegistrationEmail(userId: $userId)
  }
`

export default function useSendNewRegistrationEmail(): {
  sendNewRegistrationEmail: (userId?: string) => void
} {
  const [mutate] = useMutation(SEND_NEW_REGISTRATION_EMAIL_MUTATION)
  return {
    sendNewRegistrationEmail: (userId?: string) =>
      mutate({ variables: { userId } }),
  }
}
