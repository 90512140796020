import styled from "styled-components"
import { colors, Grid } from "ui"

export const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  padding: 50px 70px;
  height: 150px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  height: calc(100vh - 300px);
  padding: 50px 70px;
`

export const Title = styled.h2`
  font-family: "Sora";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 56px;
  color: ${colors.purple};
  margin-bottom: 30px;
`

export const RightContainer = styled(Grid)`
  background-color: ${colors.purple};
  height: 100%;
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
`

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 50px 70px;
  height: 150px;
`
