import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useBookingMinDate from "hooks/useBookingMinDate"
import React, { PointerEvent, useRef, useState } from "react"
import { EditionTimeFrame } from "types"
import UTCDate from "utils/UTCDate"

import SlotsEditor from "../../SlotsEditor/SlotsEditor"
import MonthDay from "./MonthDay/MonthDay"

export default function MonthDays() {
  const minBookingDate = useBookingMinDate()
  const { from, to, userShowWeekends, setOpenDrawer } = usePlanningContext()
  const cursor = useRef<{
    from?: number
    to?: number
    isDragged: boolean
    direction: number
  }>({ isDragged: false, direction: 0 })

  const days = []
  for (
    const day = new UTCDate(from);
    day.getTime() < to.getTime();
    day.shift("HALF-DAY", 1)
  ) {
    days.push(new UTCDate(day))
  }
  const filteredDays = days.filter((d) => {
    if (d.isMorning()) {
      if (!userShowWeekends && d.getDay() === 0) {
        return false
      }
      return true
    }
    return false
  })

  const listRef = useRef<HTMLUListElement | null>(null)
  const selectedChildrenIds = useRef<number[]>([])
  const selectedTimestamps = useRef<number[]>([])
  const updateAndSortSelectedTimestamps = (timestamp: number) => {
    if (selectedTimestamps.current.find((ts) => ts === timestamp)) return
    selectedTimestamps.current.push(timestamp)
    selectedTimestamps.current.sort((a, b) => a - b)
  }
  const [editionTimeFrame, setEditionTimeFrame] = useState<EditionTimeFrame>({
    from: undefined,
    to: undefined,
  })

  const resetSelection = () => {
    selectedTimestamps.current = []
    selectedChildrenIds.current = []
    mutateChildren(clearChildSelectionClasses)
    setEditionTimeFrame({
      from: undefined,
      to: undefined,
    })
  }

  const clearChildSelectionClasses = (child: Element) => {
    child.classList.remove("is-first")
    child.classList.remove("is-last")
    child.classList.remove("is-selected")
  }

  const computeChildClasses = (child: Element, n: number) => {
    if (selectedChildrenIds.current.find((id) => id === n)) {
      if (selectedTimestamps.current.length > 0) {
        const timestamp = child.getAttribute("data-timestamp")
        if (!timestamp) return
        const isFirst = selectedTimestamps.current[0] === Number(timestamp)
        const isLast =
          selectedTimestamps.current[selectedTimestamps.current.length - 1] ===
          Number(timestamp)
        if (isFirst) child.classList.add("is-first")
        if (isLast) child.classList.add("is-last")
      }
      child.classList.add("is-selected")
    }
  }

  const mutateChildren = (func: (child: Element, n: number) => void) => {
    if (listRef.current) {
      const children = Array.from(listRef.current.children)
      children.forEach(func)
    }
  }

  const getChildTimeStamp = (child: Element, from: number, to: number) => {
    const { x, width } = child.getBoundingClientRect()

    if (x + width >= from && x <= to)
      return child.getAttribute("data-timestamp")
  }

  const getChildAtPosition = (x: number) => {
    if (!listRef.current) return

    const children = Array.from(listRef.current.children)
    for (const child of children) {
      const timestamp = getChildTimeStamp(child, x, x)
      if (timestamp) {
        return { child, timestamp }
      }
    }
    return {}
  }

  const update = (e: PointerEvent<HTMLUListElement>) => {
    if (!cursor.current.isDragged || !listRef.current) return

    // update to at current pointer position
    cursor.current.to = e.clientX

    if (cursor.current.from === undefined || cursor.current.to === undefined)
      return

    cursor.current.direction = cursor.current.from <= cursor.current.to ? 1 : -1

    // flip from/to so that from < to
    const from =
      cursor.current.from <= cursor.current.to
        ? cursor.current.from
        : cursor.current.to
    const to =
      cursor.current.to >= cursor.current.from
        ? cursor.current.to
        : cursor.current.from

    // reset to nothing selected
    selectedTimestamps.current = []
    selectedChildrenIds.current = []

    // does child is included into ongoing selection
    const children = Array.from(listRef.current.children)
    children.forEach((child, n) => {
      const timestamp = getChildTimeStamp(child, from, to)
      if (timestamp) {
        updateAndSortSelectedTimestamps(Number(timestamp))
        selectedChildrenIds.current.push(n)
      }
    })

    // mutating child elements regarding their selected state
    mutateChildren(clearChildSelectionClasses)
    mutateChildren(computeChildClasses)
  }

  return (
    <>
      <ul
        ref={listRef}
        draggable={false}
        onDragStart={(e) => {
          // prevent ghost image when dragging
          e.preventDefault()
          cursor.current.isDragged = true
          selectedTimestamps.current = []
        }}
        onPointerMove={(e) => {
          if (!cursor.current.isDragged) return
          if (minBookingDate) {
            // can I drag to select in the past ?
            const child = getChildAtPosition(e.clientX)
            if (
              new UTCDate(Number(child?.timestamp)).getTime() <=
              minBookingDate.getTime()
            )
              return
          }
          update(e)
        }}
        onPointerDown={(e) => {
          cursor.current = { from: e.clientX, isDragged: true, direction: 0 }
        }}
        onPointerUp={() => {
          cursor.current = { ...cursor.current, isDragged: false }

          if (selectedTimestamps.current.length === 0) return

          setEditionTimeFrame({
            from: new UTCDate(
              Number(selectedTimestamps.current[0]),
              "DAY" // enforce selection starts at AM
            ),
            to: new UTCDate(
              Number(
                selectedTimestamps.current[
                  selectedTimestamps.current.length - 1
                ]
              ),
              "NEXT-HALF-DAY" // enforce selection ends at PM
            ),
          })
          setOpenDrawer("SLOTS_EDITOR")
        }}
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(${filteredDays.length}, 1fr)`,
          padding: "0 12px 0 10px",
        }}
      >
        {filteredDays.map((day, n) => {
          return (
            <MonthDay
              key={`month-day-${n}-${day.getTime()}`}
              day={day}
              minBookingDate={minBookingDate}
            />
          )
        })}
      </ul>
      {selectedTimestamps.current.length > 0 && (
        <SlotsEditor
          editionTimeFrame={editionTimeFrame}
          setEditionTimeFrame={resetSelection}
        />
      )}
    </>
  )
}
