import Dialog from "@mui/material/Dialog"
import useUserEvents from "graphql/events/useUserEvents"
import useUser from "graphql/users/useUser"
import useMediaQueries from "hooks/useMediaQueries"
import useWindowSize from "hooks/useWindowResize"
import useTranslate from "intl/useTranslate"
import React, { useEffect } from "react"
import styled from "styled-components"
import { H2, H4, mediaQueries } from "ui"
import { Cancel } from "ui/icons"
import filterEventsOfTheDay from "utils/events"
import getUserDisplayName from "utils/getUserDisplayName"
import LocalDate from "utils/LocalDate"
import UTCDate from "utils/UTCDate"

import BubblesList from "./components/Bubbles/BubblesList"
import EventItem from "./components/EventItem"
import { useHomeContext } from "./components/HomeContextProvider"
import { Content } from "./components/Layout"
import ValidationButton from "./components/ValidationButton"
import WorkingPlace from "./components/WorkingPlace/WorkingPlace"

const PopUp = styled(Dialog)`
  background-color: transparent;
  & .MuiDialog-paper {
    border-radius: 32px;
    @media ${mediaQueries.isMobile} {
      display: flex;
      margin-top: auto;
      background-color: transparent;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      height: 100%;
    }
    @media ${mediaQueries.isDesktop} {
      overflow: hidden;
    }
  }
`

export default function HomeDialog() {
  const t = useTranslate()
  const { user: me } = useUser()
  const { userEvents } = useUserEvents({
    from: new LocalDate("DAY"),
    to: new LocalDate("NEXT-DAY"),
  })
  const { isMobile } = useMediaQueries()
  const [, windowHeight] = useWindowSize()
  const { openHomeDialog, onPlanningLinkClick, onCancelHome } = useHomeContext()

  useEffect(() => {
    // when home un mounted
    // persist last time home has been displayed in local storage
    return () => {
      localStorage.setItem(
        "lastTimeHomeWasDisplayed",
        new Date().getTime().toString()
      )
    }
  }, [])

  const AM = new UTCDate("DAY")
  const PM = new UTCDate("DAY")
  PM.increment("HALF-DAY")
  const dayAfter = new UTCDate("DAY")
  dayAfter.increment("DAY")

  const eventsOfTheDay = userEvents.filter(
    filterEventsOfTheDay(new LocalDate("DAY"))
  )

  const isEventsDisplayed = eventsOfTheDay.length > 0

  if (!me || !openHomeDialog) return <></>

  return (
    <PopUp
      open
      onClose={onCancelHome}
      maxWidth={isMobile ? undefined : "md"}
      fullScreen={isMobile}
    >
      <Content
        windowHeight={windowHeight}
        isEventsDisplayed={isEventsDisplayed}
      >
        <div className="close-btn" onClick={onCancelHome}>
          <Cancel />
        </div>

        <div className="header">
          <H2>{t("hello")}</H2>
          <H2>{getUserDisplayName(me, me.company.flags)},</H2>
          <WorkingPlace />
        </div>
        <div className="content">
          <BubblesList AM={AM} PM={PM} />

          {eventsOfTheDay.length > 0 && (
            <div className="events">
              <H4 className="section-title">
                {eventsOfTheDay.length > 1
                  ? t("events of the day")
                  : t("event of the day")}
              </H4>
              <div className="events-list">
                {eventsOfTheDay.map((event) => (
                  <EventItem
                    key={event.id}
                    event={event}
                    onClose={onCancelHome}
                  />
                ))}
              </div>
            </div>
          )}
        </div>

        <ValidationButton onClick={onPlanningLinkClick} />
      </Content>
    </PopUp>
  )
}
