import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function Bookmark({
  stroke,
  fill,
  strokeWidth,
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      strokeWidth={strokeWidth ?? 1.5}
      stroke={stroke ?? "currentColor"}
      {...rest}
    >
      <path
        d="M5 21V5a2 2 0 012-2h10a2 2 0 012 2v16l-5.918-3.805a2 2 0 00-2.164 0L5 21z"
        fill={fill ?? "transparent"}
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </SvgIcon>
  )
}
