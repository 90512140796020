import useTranslate from "intl/useTranslate"
import React from "react"
import { P14 } from "ui"

interface PropsType {
  value: string | null | undefined
  date: string
}

export const TodayLocation = ({ value, date }: PropsType) => {
  const t = useTranslate()
  if (value === "office")
    return (
      <P14>
        {t("Today the")} <strong>{date}</strong> {t("you are here")}
      </P14>
    )
  else if (value === "home")
    return (
      <P14>
        {t("Today the")} <strong>{date}</strong> {t("you are in")}
      </P14>
    )
  else if (value === "off")
    return (
      <P14>
        {t("Today the")} <strong>{date}</strong> {t("you are")}
      </P14>
    )
  else if (value === "else")
    return (
      <P14>
        {t("Today the")} <strong>{date}</strong> {t("you are here")}
      </P14>
    )
  else
    return (
      <P14>
        {t("Today the")} <strong>{date}</strong> {t("you are here")}
      </P14>
    )
}
