import { LocationIconFromId } from "components/LocationIcon"
import { UserAvatarFromId } from "components/UserAvatar/UserAvatar"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { SearchResult } from "types"
import { colors, IconText } from "ui"
import { Group as GroupIcon } from "ui/icons"
import LocationTree from "utils/LocationTree"

const ResultGroupContainer = styled.li`
  width: 100%;
  padding-bottom: 6px;
  .results-group {
    &__title {
      padding-left: 16px;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: ${colors.grey2};
    }

    &__item {
      padding: 0 16px;
      margin: 8px 0;
      color: ${colors.black};

      &:hover,
      &:focus {
        background: hsla(${colors.blackHSL}, 0.04);
        cursor: pointer;
      }
    }
  }
`

const UserNameAndEmail = styled.span`
  display: flex;
  justify-content: space-between;
  width: 100%;

  em {
    color: ${colors.grey1};
    font-style: normal;
    font-size: 0.85em;
  }
`

interface PropsType {
  results: SearchResult[]
  showEmail?: boolean
  onSelect?: (input: SearchResult) => void
}

export function UserResultsGroup({ results, showEmail, onSelect }: PropsType) {
  const t = useTranslate()

  const handleClick = (user: SearchResult) => {
    if (onSelect) onSelect(user)
  }

  return (
    <ResultGroupContainer className="results-group">
      <h6 className="results-group__title">{t("Collaborators")}</h6>
      <ul className="results-group__list">
        {results.map((user) => (
          <li
            key={user.id}
            className="results-group__item"
            onClick={() => handleClick(user)}
          >
            <IconText fullWidth icon={<UserAvatarFromId id={user.id} />}>
              <UserNameAndEmail>
                <span>{user.name}</span>
                {showEmail && <em>{user.email}</em>}
              </UserNameAndEmail>
            </IconText>
          </li>
        ))}
      </ul>
    </ResultGroupContainer>
  )
}

export function GroupResultsGroup({ results, onSelect }: PropsType) {
  const t = useTranslate()

  const handleClick = (group: SearchResult) => {
    if (onSelect) onSelect(group)
  }

  return (
    <ResultGroupContainer className="results-group">
      <h6 className="results-group__title">{t("Groups")}</h6>
      <ul className="results-group__list">
        {results.map((group) => (
          <li
            key={group.id}
            className="results-group__item"
            onClick={() => handleClick(group)}
          >
            <IconText icon={<GroupIcon />}>{group.name}</IconText>
          </li>
        ))}
      </ul>
    </ResultGroupContainer>
  )
}

interface LocationResultsGroupPropsTytpe extends PropsType {
  locationsTree: LocationTree[]
}

export function LocationResultsGroup({
  results,
  locationsTree,
  onSelect,
}: LocationResultsGroupPropsTytpe) {
  const t = useTranslate()

  const handleClick = (location: SearchResult) => {
    if (onSelect) onSelect(location)
  }

  return (
    <ResultGroupContainer className="results-group">
      <h6 className="results-group__title">{t("working spaces")}</h6>
      <ul className="results-group__list">
        {results.map((location) => {
          const locationTree = LocationTree.getLocationNode(
            locationsTree,
            location.id
          )
          if (locationTree) {
            return (
              <li
                key={location.id}
                className="results-group__item"
                onClick={() => handleClick(location)}
              >
                <IconText
                  icon={<LocationIconFromId locationId={location.id} />}
                >
                  {[...locationTree.breadCrumbs, location.name].join(" / ")}
                </IconText>
              </li>
            )
          }
          return (
            <li
              key={location.id}
              className="results-group__item"
              onClick={() => handleClick(location)}
            >
              <IconText icon={<LocationIconFromId locationId={location.id} />}>
                {location.name}
              </IconText>
            </li>
          )
        })}
      </ul>
    </ResultGroupContainer>
  )
}
