import { ApolloError, gql, useQuery } from "@apollo/client"
import { Group } from "types"

import { GroupFragment } from "./fragments/groupFragment"

const ALL_GROUPS_QUERY = gql`
  ${GroupFragment}
  query getAllGroups {
    allGroups {
      ...GroupFragment
    }
  }
`

export default function useAllGroups(): {
  loading: boolean
  error?: ApolloError
  groups: Group[]
} {
  const { loading, error, data } = useQuery(ALL_GROUPS_QUERY)
  return { groups: data?.allGroups || [], loading, error }
}
