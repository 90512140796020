import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useUser from "graphql/users/useUser"
import React from "react"
import { Route, Switch, useParams } from "react-router-dom"
import { UserBase } from "types"

import PlanningRowLayout from "../PlanningRowLayout/PlanningRowLayout"
import FavoritesPlanningHeader from "./PlanningHeaders/FavoritesPlanningHeader/FavoritesPlanningHeader"
import GroupPlanningHeader from "./PlanningHeaders/GroupPlanningHeader/GroupPlanningHeader"
import LocationPlanningHeader from "./PlanningHeaders/LocationPlanningHeader/LocationPlanningHeader"
import TeamsPlanningHeader from "./PlanningHeaders/TeamsPlanningHeader/TeamsPlanningHeader"

interface PropsType {
  users: UserBase[]
}

export default function PlanningHeadersRouter({ users }: PropsType) {
  const { user: me } = useUser()

  const { locationId, groupId } =
    useParams<{ locationId?: string; groupId?: string }>()

  const { from, to, usersAtFilters } = usePlanningContext()

  const noActiveFilters = Object.keys(usersAtFilters).length === 0

  if (!me) return <></>

  return (
    <Switch>
      <Route path="/planning/location/:locationId">
        <PlanningRowLayout>
          <LocationPlanningHeader
            locationId={locationId ?? ""}
            firstWeekDay={from}
            lastWeekDay={to}
            users={users}
          />
        </PlanningRowLayout>
      </Route>
      <Route path="/planning/group/:groupId">
        <PlanningRowLayout>
          <GroupPlanningHeader
            groupId={groupId ?? ""}
            firstWeekday={from}
            lastWeekday={to}
            users={users}
            noActiveFilters={noActiveFilters}
          />
        </PlanningRowLayout>
      </Route>
      <Route path="/planning/favorites">
        <PlanningRowLayout>
          <FavoritesPlanningHeader
            firstWeekday={from}
            lastWeekDay={to}
            users={users}
            noActiveFilters={noActiveFilters}
          />
        </PlanningRowLayout>
      </Route>
      <Route path="/teams/planning">
        <PlanningRowLayout>
          <TeamsPlanningHeader
            firstWeekday={from}
            lastWeekday={to}
            users={users}
            noActiveFilters={noActiveFilters}
          />
        </PlanningRowLayout>
      </Route>
    </Switch>
  )
}
