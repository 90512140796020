import useCompletionRate from "graphql/stats/useCompletionRate"
import React, { useEffect, useState } from "react"
import { UserBase } from "types"
import UTCDate from "utils/UTCDate"

interface PropsType {
  user: UserBase
  from: UTCDate
  to: UTCDate

  includeWeekends: boolean
  usersCompletionRate: {
    user: UserBase
    completionRate: number
  }[]
  updateUsersCompletionRate: (
    completionRate: {
      user: UserBase
      completionRate: number
    },
    remove?: boolean
  ) => void
}

export default function UserCompletionRateBar({
  from,
  to,

  includeWeekends,
  user,
  usersCompletionRate,
  updateUsersCompletionRate,
}: PropsType) {
  const { completionRate, loading, error } = useCompletionRate({
    from,
    to,
    userIds: [user.id],
    groupIds: [],
    includeWeekends,
  })

  const [previousTimeFrame, setPreviousTimeFrame] = useState<
    { from: UTCDate; to: UTCDate; includeWeekends: boolean } | undefined
  >(undefined)

  useEffect(() => {
    if (loading || error) return
    const timeFrameHasChanged =
      previousTimeFrame?.from.getTime() !== from.getTime() ||
      previousTimeFrame?.to.getTime() !== to.getTime() ||
      previousTimeFrame?.includeWeekends !== includeWeekends

    const previousCompletionRate = usersCompletionRate.find(
      (ucr) => ucr.user.id === user.id
    )

    if (previousCompletionRate === undefined || timeFrameHasChanged) {
      // update parent dataset with this data if:
      // * this data does not exist in parent dataset yet
      // * parent time frame has changed
      updateUsersCompletionRate({ user, completionRate })
      setPreviousTimeFrame({ from, to, includeWeekends })
    }
  }, [
    loading,
    error,
    user,
    completionRate,
    usersCompletionRate,
    updateUsersCompletionRate,
    from,
    to,
    includeWeekends,
    previousTimeFrame,
  ])

  useEffect(() => {
    return () => updateUsersCompletionRate({ user, completionRate }, true)
    // eslint-disable-next-line
  }, [])

  return <></>
}
