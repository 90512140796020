import React from "react"
import styled from "styled-components"
import { colors, mediaQueries } from "ui"
import { MidlineHorizontalEllispsis } from "ui/icons"

const IconButton = styled.button`
  height: 24px;
  width: 24px;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${mediaQueries.isDesktop} {
    &:hover {
      background: rgba(0, 0, 0, 0.04);
    }

    &:focus {
      background: rgba(0, 0, 0, 0.12);
    }
  }

  svg {
    width: 20px;
    height: 20px;
    color: ${colors.grey1};
  }
`

interface PropsType {
  toggleMenu: (event: React.SyntheticEvent) => void
}

export default function InteractionsButton({ toggleMenu }: PropsType) {
  return (
    <>
      <IconButton className="visible-action" onClick={toggleMenu}>
        <MidlineHorizontalEllispsis />
      </IconButton>
    </>
  )
}
