import { gql, useMutation } from "@apollo/client"
import { Group } from "types"

import { GroupFragment } from "./fragments/groupFragment"
import updateGroupInCache from "./updateGroupInCache"

const CREATE_GROUP_MUTATION = gql`
  ${GroupFragment}
  mutation createGroupSimple($name: String!, $userIds: [ID]!) {
    createGroupSimple(name: $name, userIds: $userIds) {
      ...GroupFragment
    }
  }
`

export default function useCreateGroup() {
  const [mutate] = useMutation(CREATE_GROUP_MUTATION, {
    update: (cache, { data }) =>
      updateGroupInCache({ cache, data, mutationName: "createGroupSimple" }),
  })
  const createGroup = async (name: string, userIds: string[]) => {
    try {
      const { data } = await mutate({ variables: { name, userIds } })
      if (data?.createGroupSimple) {
        return data?.createGroupSimple as Group
      }
      return null
    } catch (e) {
      console.error(e.message)
    }
  }

  return createGroup
}
