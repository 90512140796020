import SlotsEditorLocationsList from "components/Collapses/LocationsCollapse/SlotsEditorLocationsList"
import useLocationsTree from "graphql/locations/useLocationsTree"
import useMediaQueries from "hooks/useMediaQueries"
import { DesktopFloorPlanContent } from "pages/PlanningPage/components/PlanningHeadersRouter/PlanningHeaders/LocationPlanningHeader/UserFloorPlanDrawer/DesktopFloorPlanDrawer"
import React, { useCallback } from "react"
import { SlotOccupationForLocation, User, WorkingMode } from "types"
import LocationTree, { findOldestParentWithFloorPlan } from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

import MobileFloorPlanContent from "../MobileFloorPlanContent/MobileFloorPlanContent"

interface PropsType {
  from: UTCDate
  to: UTCDate
  selectedUser: User
  guestIds: string[]
  occupations: SlotOccupationForLocation[]
  floorPlan:
    | {
        selectedFloorPlan?: LocationTree | undefined
        selectedVisualArea?: LocationTree | undefined
        editing: boolean
      }
    | undefined
  setFloorPlan: React.Dispatch<
    React.SetStateAction<
      | {
          selectedFloorPlan?: LocationTree | undefined
          selectedVisualArea?: LocationTree | undefined
          editing: boolean
        }
      | undefined
    >
  >
  saveWorkingMode: (workingMode: WorkingMode) => void | Promise<void>
}

function getBaseLocations(
  roots: LocationTree[],
  locationFromUrl: LocationTree | undefined
) {
  if (!locationFromUrl) return []

  const oldestParentWithFloorPlan = findOldestParentWithFloorPlan(
    roots,
    locationFromUrl
  )
  return [oldestParentWithFloorPlan || locationFromUrl]
}

export default function SlotsEditorLocationsListRouter({
  from,
  to,
  selectedUser,
  guestIds,
  floorPlan,
  setFloorPlan,
  saveWorkingMode,
  occupations,
}: PropsType) {
  const { isMobile } = useMediaQueries()
  const { locations: companyLocations } = useLocationsTree()

  const locations = floorPlan?.selectedVisualArea
    ? getBaseLocations(companyLocations, floorPlan?.selectedVisualArea)
    : []

  const updateSelectedFloorPlan = (floorPlan?: LocationTree) => {
    setFloorPlan((prev) => ({
      ...prev,
      selectedFloorPlan: floorPlan,
      editing: true,
    }))
  }
  const updateSelectedVisualArea = useCallback(
    (locationTree?: LocationTree) => {
      setFloorPlan((prev) => ({
        ...prev,
        selectedVisualArea: locationTree,
        editing: true,
      }))
    },
    [setFloorPlan]
  )

  const showFloorPlan = floorPlan?.editing

  if (showFloorPlan && !isMobile)
    return (
      <DesktopFloorPlanContent
        from={from}
        to={to}
        locations={locations}
        selectedLocation={floorPlan?.selectedVisualArea}
        selectedFloorPlan={undefined}
        setSelectedLocation={updateSelectedVisualArea}
        saveWorkingMode={saveWorkingMode}
        onClose={() => {
          updateSelectedVisualArea(undefined)
          setFloorPlan({ editing: false })
        }}
      />
    )

  if (showFloorPlan)
    return (
      <MobileFloorPlanContent
        from={from}
        to={to}
        locations={locations}
        selectedLocation={floorPlan?.selectedVisualArea}
        selectedFloorPlan={undefined}
        setSelectedLocation={updateSelectedVisualArea}
        saveWorkingMode={saveWorkingMode}
        onClose={() => {
          updateSelectedVisualArea(undefined)
          setFloorPlan({ editing: false })
        }}
      />
    )

  return (
    <SlotsEditorLocationsList
      from={from}
      to={to}
      selectedUser={selectedUser}
      guestIds={guestIds}
      occupations={occupations}
      floorPlan={{
        ...floorPlan,
        updateSelectedFloorPlan,
        updateSelectedVisualArea,
      }}
      saveWorkingMode={saveWorkingMode}
    />
  )
}
