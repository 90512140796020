import PageHeader from "components/Layout/PageLayout/PageHeader/PageHeader"
import BurgerButton from "components/Layout/PageLayout/PageHeader/PageTitle/BurgerButton/BurgerButton"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import usePlanningUserSlots from "graphql/slots/usePlanningUserSlots"
import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { UTCSlot } from "types"
import { colors, L12, P16Bold } from "ui"
import { Search as SearchIcon } from "ui/icons"
import getUserDisplayName from "utils/getUserDisplayName"

import SearchDialog from "./SearchDialog/SearchDialog"

const Layout = styled(PageHeader)`
  padding: 9px 24px;
  height: 56px; // cannot reach the 56px with padding so enforce it
`

const Action = styled.div`
  margin-left: auto;
  display: flex;

  svg {
    stroke: ${colors.grey1};
  }
`

interface StyledL12Props {
  color: string
}

const StyledL12 = styled(L12)<StyledL12Props>`
  color: ${({ color }) =>
    color === "green" ? colors.success : colors.redAlert};
`

export default function MobileHeader() {
  const t = useTranslate()

  const [mobileParentContainerRef, setMobileParentContainerRef] =
    useState<HTMLElement | null>(null)
  const [dialogOpen, setDialogOpen] = useState(false)

  const { user: me } = useUser()
  const { from, to, userShowWeekends } = usePlanningContext()

  const { slots } = usePlanningUserSlots({
    userId: me?.id ?? "",
    from,
    to,
  })

  const getNullSlots = (slots: UTCSlot[]) => {
    return userShowWeekends
      ? slots.filter((slot) => slot.value === null || slot.value === "null")
      : slots
          .filter((slot) => slot.value === null || slot.value === "null")
          .filter(
            (slot) =>
              !(slot.date.getUTCDay() === 0 || slot.date.getUTCDay() === 6)
          )
  }

  const remainingCompletionDays = Math.ceil(getNullSlots(slots).length / 2)

  const nbDaysFilled = userShowWeekends
    ? 7 - remainingCompletionDays
    : 5 - remainingCompletionDays

  const nbDaysToFilled = userShowWeekends ? 7 : 5

  const getColor = () => {
    if (nbDaysFilled === nbDaysToFilled) return "green"
    else return "red"
  }

  if (me === null) return <></>

  return (
    <>
      <Layout>
        <BurgerButton />

        <div>
          <P16Bold>{getUserDisplayName(me, me.company.flags)}</P16Bold>
          <StyledL12 color={getColor()}>
            {t(`days remain unfilled`, {
              days: `${
                userShowWeekends
                  ? 7 - remainingCompletionDays
                  : 5 - remainingCompletionDays
              }/${userShowWeekends ? 7 : 5}`,
            })}
          </StyledL12>
        </div>
        <Action>
          <SearchIcon onClick={() => setDialogOpen(true)} />
        </Action>
      </Layout>
      <SearchDialog
        open={dialogOpen}
        mobileParentContainer={mobileParentContainerRef}
        setMobileParentContainer={setMobileParentContainerRef}
        setOpen={setDialogOpen}
      />
    </>
  )
}
