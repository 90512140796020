import { gql, useMutation, useQuery } from "@apollo/client"
import { Checkbox, CircularProgress, Link as MuiLink } from "@mui/material"
import ErrorDialog from "components/ErrorDialog"
import PasswordInput from "components/PasswordInput"
import useLoginWithEmailAndPassword from "hooks/auth/useLoginWithEmailAndPassword"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { useCallback, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useHistory, useParams } from "react-router-dom"
import styled from "styled-components/macro"
import { Button, colors, Grid, H3, mediaQueries, P14, TextField } from "ui"
import { CheckOff, CheckOn } from "ui/icons"

import SSOButtons from "./SSOButtons"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 480px;

  @media ${mediaQueries.isMobile} {
    margin: 0 auto;
    width: 80vw;
  }

  h3 {
    color: ${colors.purple};
  }

  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 370px;
    margin-top: 32px;

    @media ${mediaQueries.isMobile} {
      width: 280px;
    }

    .terms-of-service {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 16px;
    }
  }

  .divider {
    height: 20px;
  }
`

const REGISTER_USER_WITH_TOKEN = gql`
  mutation RegisterUserWithToken($userToRegister: InputRegisterUserWithToken!) {
    registerUserWithToken(userToRegister: $userToRegister)
  }
`

const GET_USER_FROM_TOKEN = gql`
  query GetUserFromToken($token: String!) {
    userFromToken(token: $token) {
      id
      email
      managerEmail
      companyName
      companyId
      companyFlags
    }
  }
`

export default function SignUp() {
  const t = useTranslate()
  const { loading: loginLoading, loginWithEmailAndPassword } =
    useLoginWithEmailAndPassword()
  const history = useHistory()
  const { isMobile } = useMediaQueries()
  const intl = useIntl()
  const [name, setName] = useState("")
  const [managerEmail, setManagerEmail] = useState("")
  const [password, setPassword] = useState("")
  const [passwordValid, setPasswordValid] = useState(false)
  const [checked, setChecked] = useState(false)
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  // Dirty fix when send in blue adds extra URL parameters such as "&_se=blah in the url"
  const { token: maybeTokenWithExtraCharacters } = useParams() as any
  let token = maybeTokenWithExtraCharacters
  if (token.includes("&")) {
    token = maybeTokenWithExtraCharacters.split("&")[0]
  }
  if (token.includes("?")) {
    token = maybeTokenWithExtraCharacters.split("?")[0]
  }

  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery(GET_USER_FROM_TOKEN, { variables: { token } })

  const [registerUserWithToken, { loading: mutationLoading, data }] =
    useMutation(REGISTER_USER_WITH_TOKEN)

  useEffect(() => {
    if (data) {
      loginWithEmailAndPassword(data.registerUserWithToken, password)
    }
  }, [data, password, loginWithEmailAndPassword])

  const reg = useCallback(async () => {
    setLoading(true)
    try {
      const userToRegister = {
        name,
        token,
        managerEmail: queryData?.userFromToken?.managerEmail || managerEmail,
        password,
      }
      await registerUserWithToken({ variables: { userToRegister } })
      history.push("/planning")
    } catch (e) {
      setError(e.message)
      setLoading(false)
    }
  }, [
    queryData,
    history,
    name,
    password,
    managerEmail,
    token,
    registerUserWithToken,
  ])

  const useB4BPasswords = Boolean(
    queryData?.userFromToken?.companyFlags?.B4BPasswords
  )
  const valid = checked && name.length > 2 && passwordValid

  return (
    <Container>
      <H3>{t("welcome")}</H3>
      <ErrorDialog
        onClose={() => {
          if (error !== "") setError("")
          else {
            history.push("/")
          }
        }}
        open={error !== "" || !!queryError}
        error={error || queryError?.message || ""}
        isError={false}
      />
      {loginLoading || queryLoading || loading || mutationLoading ? (
        <CircularProgress style={{ marginTop: 100 }} />
      ) : (
        <form className="form" noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                autoComplete="name"
                name="name"
                variant="outlined"
                required
                disabled
                label="Your email"
                initialValue={queryData?.userFromToken?.email || ""}
                handleChange={() => {
                  //
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                autoComplete="name"
                name="name"
                variant="outlined"
                required
                label="Your name"
                initialValue={name}
                handleChange={(e) => setName(e)}
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordInput
                name="password"
                newPassword={false}
                label="Password"
                useB4BPasswords={useB4BPasswords}
                handleChange={(value, valid) => {
                  setPassword(value)
                  setPasswordValid(valid)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                variant="filled"
                disabled={!!queryData?.userFromToken?.managerEmail}
                label="Your direct manager's email"
                name="managerEmail"
                initialValue={
                  queryData?.userFromToken?.managerEmail || managerEmail
                }
                handleChange={(e) => setManagerEmail(e.toLowerCase())}
              />
            </Grid>

            <div className="terms-of-service">
              <Checkbox
                name="termsOfService"
                value={checked}
                onChange={() => setChecked(!checked)}
                style={{ color: colors.black, padding: "0 9px 0 0" }}
                icon={<CheckOff />}
                checkedIcon={<CheckOn />}
              />
              {intl.locale === "fr" && (
                <P14>
                  J&apos;accepte les
                  <MuiLink
                    href="https://offishall.io/conditions-generales-dutilisation-du-compte"
                    target="_blank"
                  >
                    &nbsp;conditions d&apos;utilisation
                  </MuiLink>
                </P14>
              )}
              {intl.locale === "en" && (
                <P14>
                  I accept Offishall&apos;s
                  <MuiLink
                    href="https://en.offishall.io/conditions-generales-dutilisation-du-compte"
                    target="_blank"
                  >
                    &nbsp;terms of service
                  </MuiLink>
                </P14>
              )}
            </div>
          </Grid>
          <Button
            disabled={loading || !valid}
            type="submit"
            onClick={reg}
            width={isMobile ? 280 : 370}
          >
            {t("Sign up")}
          </Button>
        </form>
      )}

      <div className="divider" />
      <SSOButtons />
    </Container>
  )
}
