import UserAvatar from "components/UserAvatar/UserAvatar"
import useLastSharedLocation from "graphql/teamDays/useLastSharedLocation"
import useCompanyFlags from "hooks/useCompanyFlags"
import useTranslate from "intl/useTranslate"
import React from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { LastTimeAtTheOffice, UserBase } from "types"
import { L12, mediaQueries, P16, TD, TR } from "ui"
import getUserDisplayName from "utils/getUserDisplayName"
import capitalizeFirstLetter from "utils/texts/capitalizeFirstLetter"
import UTCDate from "utils/UTCDate"

const UserName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 250px;
  }

  @media ${mediaQueries.isMobile} {
    p {
      max-width: 70px;
    }
  }
`

function daysSinceToday(target?: UTCDate) {
  const today = new UTCDate("DAY")

  if (target) {
    const difference = today.getTime() - target.getTime()
    return Math.ceil(difference / (1000 * 3600 * 24))
  } else return 0
}

function LastPresence({
  lastTime,
}: {
  lastTime: LastTimeAtTheOffice | undefined
}) {
  const t = useTranslate()

  const daysSinceLastAppearance = daysSinceToday(lastTime?.date)

  if (!lastTime) return <L12 color="grey1">{t("never came to the office")}</L12>
  if (daysSinceLastAppearance === 0)
    return <L12 color="grey1">{capitalizeFirstLetter(t("today"))}</L12>
  return (
    <L12 color="grey1">
      {t("X days ago", {
        nb: `${daysSinceLastAppearance}`,
      })}
    </L12>
  )
}

function LastMeeting({ user }: { user: UserBase }) {
  const t = useTranslate()
  const { lastSharedLocation } = useLastSharedLocation({ userIds: [user.id] })

  if (!lastSharedLocation) return null

  const lastPresence = daysSinceToday(lastSharedLocation.date)

  if (lastPresence === 0)
    return <L12 color="grey1">{capitalizeFirstLetter(t("today"))}</L12>
  return (
    <L12 color="grey1">
      {t("X days ago", {
        nb: `${lastPresence}`,
      })}
    </L12>
  )
}

interface PropsType {
  user: UserBase
  loading: boolean
  lastTime: LastTimeAtTheOffice | undefined
}

export default function OneMember({ user, lastTime, loading }: PropsType) {
  const { companyFlags } = useCompanyFlags()
  const history = useHistory()

  if (loading) return null
  return (
    <TR
      onClick={() => {
        history.push(`/user/${user.id}`)
      }}
    >
      <TD>
        <UserName>
          <UserAvatar user={user} />
          <P16>{getUserDisplayName(user, companyFlags)}</P16>
        </UserName>
      </TD>
      <TD>
        <LastMeeting user={user} />
      </TD>
      <TD>
        <LastPresence lastTime={lastTime} />
      </TD>
    </TR>
  )
}
