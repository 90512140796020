import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function Copy({ stroke, strokeWidth, ...rest }: SvgIconProps) {
  return (
    <SvgIcon
      strokeWidth={strokeWidth ?? 0.5}
      stroke={stroke ? stroke : "currentColor"}
      {...rest}
    >
      <path d="M14.25 9C14.25 9.41421 14.5858 9.75 15 9.75C15.4142 9.75 15.75 9.41421 15.75 9H14.25ZM9 15.75C9.41421 15.75 9.75 15.4142 9.75 15C9.75 14.5858 9.41421 14.25 9 14.25V15.75ZM19.4 19.25H9.6V20.75H19.4V19.25ZM9.6 19.25C9.68282 19.25 9.75 19.3172 9.75 19.4H8.25C8.25 20.1456 8.85444 20.75 9.6 20.75V19.25ZM9.75 19.4V9.6H8.25V19.4H9.75ZM9.75 9.6C9.75 9.68284 9.68284 9.75 9.6 9.75V8.25C8.85442 8.25 8.25 8.85442 8.25 9.6H9.75ZM9.6 9.75H19.4V8.25H9.6V9.75ZM19.4 9.75C19.3172 9.75 19.25 9.68282 19.25 9.6H20.75C20.75 8.85444 20.1456 8.25 19.4 8.25V9.75ZM19.25 9.6V19.4H20.75V9.6H19.25ZM19.25 19.4C19.25 19.3172 19.3172 19.25 19.4 19.25V20.75C20.1456 20.75 20.75 20.1456 20.75 19.4H19.25ZM15.75 9V4.6H14.25V9H15.75ZM15.75 4.6C15.75 3.85444 15.1456 3.25 14.4 3.25V4.75C14.3172 4.75 14.25 4.68282 14.25 4.6H15.75ZM14.4 3.25H4.6V4.75H14.4V3.25ZM4.6 3.25C3.85442 3.25 3.25 3.85442 3.25 4.6H4.75C4.75 4.68284 4.68284 4.75 4.6 4.75V3.25ZM3.25 4.6V14.4H4.75V4.6H3.25ZM3.25 14.4C3.25 15.1456 3.85444 15.75 4.6 15.75V14.25C4.68282 14.25 4.75 14.3172 4.75 14.4H3.25ZM4.6 15.75H9V14.25H4.6V15.75Z" />
    </SvgIcon>
  )
}
