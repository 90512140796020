import React from "react"
import styled from "styled-components"
import { colors, H3 } from "ui"

import DesktopNavBack from "../DesktopNavBack/DesktopNavBack"

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const BreadCrumbs = styled.ul`
  display: flex;
  gap: 4px;

  li {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: ${colors.grey1};

    &::after {
      padding-left: 4px;
      content: "/";
    }
    &:last-child::after {
      content: none;
    }
  }
`

interface PropsType {
  breadcrumbs: {
    name: string
    path: string
  }[]
  title: string
}

export default function AdminPageTitle({ breadcrumbs, title }: PropsType) {
  return (
    <Layout>
      <DesktopNavBack>
        <BreadCrumbs>
          {breadcrumbs.map((crumb, n) => (
            <li key={`crumb-${n}-${crumb.name}`}>{crumb.name}</li>
          ))}
        </BreadCrumbs>
      </DesktopNavBack>

      <H3>{title}</H3>
    </Layout>
  )
}
