import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import useLocationsTree from "graphql/locations/useLocationsTree"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import { GroupSimple, UserBase } from "types"
import { NakedDropDown } from "ui"
import LocationTree, { getChildrenLocations } from "utils/LocationTree"
import UTCDate, { UTCDateClampType } from "utils/UTCDate"

import DatePicker from "../components/DatePicker"
import {
  ChartLayout,
  DataLayout,
  Layout,
  SampleList,
} from "../components/Layout"
import SamplePicker from "../components/SamplePicker/SamplePicker"
import OccupancyRateChart from "./OccupancyRateChart/OccupancyRateChart"
import OccupancyRateOverTimeChart from "./OccupancyRateOverTimeChart/OccupancyRateOverTimeChart"
import OccupancyRatePerDayChart from "./OccupancyRatePerDayChart/OccupancyRatePerDayChart"

interface PropsType {
  from: UTCDate
  to: UTCDate
  enableWeekends: boolean
  selectedPeriod: UTCDateClampType | "personalized"
  pageHeaderHeight: { header?: number; tabsHeader?: number }
  editSelectedPeriod: (string: UTCDateClampType | "personalized") => void
  editFromDate: (date: UTCDate) => void
  editToDate: (date: UTCDate) => void
  editEnableWeekends: (bool: boolean) => void
}

export default function OccupancyRate({
  from,
  to,
  enableWeekends,
  pageHeaderHeight,
  ...props
}: PropsType) {
  const t = useTranslate()

  const alreadyOccupiedHeight =
    pageHeaderHeight.header !== undefined &&
    pageHeaderHeight.tabsHeader !== undefined
      ? pageHeaderHeight.header + pageHeaderHeight.tabsHeader
      : undefined

  const { locations, loading, error } = useLocationsTree()
  const allLocationsFlatten = getChildrenLocations(locations, false)

  const [selectedLocations, setSelectedLocations] = useState<LocationTree[]>([])
  const [viewMode, setViewMode] = useState<{
    name: string
    value: "mean" | "over-time" | "per-day"
  }>({ name: t("stats over-time"), value: "over-time" })

  const [showAllLocationsBar, setShowAllLocationsBar] = useState(true)

  const [showTotal, setShowTotal] = useState(false)

  if (loading || error)
    return <LoaderOrErrorComponent loading={loading} error={error} />

  return (
    <Layout>
      <SampleList alreadyOccupiedHeight={alreadyOccupiedHeight}>
        <SamplePicker
          hideDefaults
          data={{ users: [], groups: [], locations }}
          showAllLocationsBar={showAllLocationsBar}
          onChange={(sample: {
            users: UserBase[]
            groups: GroupSimple[]
            locations: LocationTree[]
          }) => {
            setSelectedLocations(sample.locations)
          }}
          setShowAllLocationsBar={setShowAllLocationsBar}
          cannotCombineAllLocationsWithSelection={viewMode.value === "per-day"}
        />
      </SampleList>
      <DataLayout>
        <div>
          <DatePicker
            from={from}
            to={to}
            enableWeekends={enableWeekends}
            {...props}
          />
        </div>
        <ChartLayout alreadyOccupiedHeight={alreadyOccupiedHeight}>
          <NakedDropDown
            className="stats-occupancy-dropdown"
            placeholder={viewMode.name}
            options={[
              {
                name: t("stats over-time"),
                value: "over-time",
              },
              {
                name: t("stats per-day"),
                value: "per-day",
              },
              {
                name: t("stats mean"),
                value: "mean",
              },
            ]}
            onSelect={({ name, value }) =>
              setViewMode({ name, value: value as "mean" | "over-time" })
            }
          />

          {viewMode.value === "mean" && (
            <OccupancyRateChart
              selectedLocations={selectedLocations}
              allLocationsFlatten={allLocationsFlatten}
              showTotal={showTotal && locations.length > 0}
              from={from}
              to={to}
              includeWeekends={enableWeekends}
              showAllLocationsBar={showAllLocationsBar}
              setShowTotal={setShowTotal}
            />
          )}
          {viewMode.value === "over-time" && (
            <OccupancyRateOverTimeChart
              selectedLocations={selectedLocations}
              allLocationsFlatten={allLocationsFlatten}
              from={from}
              to={to}
              includeWeekends={enableWeekends}
              showAllLocationsBar={showAllLocationsBar}
            />
          )}
          {viewMode.value === "per-day" && (
            <OccupancyRatePerDayChart
              selectedLocations={selectedLocations}
              allLocationsFlatten={allLocationsFlatten}
              from={from}
              to={to}
              includeWeekends={enableWeekends}
              showAllLocationsBar={showAllLocationsBar}
            />
          )}
        </ChartLayout>
      </DataLayout>
    </Layout>
  )
}
