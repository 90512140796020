import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import useAllGroups from "graphql/groups/useAllGroups"
import useSearchUsersWithTeams from "graphql/users/useSearchUsersWithTeams"
import useCompanyFlags from "hooks/useCompanyFlags"
import useTranslate from "intl/useTranslate"
import React, { useCallback, useState } from "react"
import { BulkSlotRuleInput, Group, UserWithTeam } from "types"
import { Button, Dialog } from "ui"
import getUserDisplayName from "utils/getUserDisplayName"

import GroupsTeamsList from "./GroupsTeamsList"
import Search from "./Search"

interface PropsType {
  open: boolean
  onClose: () => void
  setRule: (value: (value: BulkSlotRuleInput) => BulkSlotRuleInput) => void
}

export default function TargetSelection({ open, onClose, setRule }: PropsType) {
  const t = useTranslate()
  const { companyFlags } = useCompanyFlags()

  const { users: allUsers, loading, error } = useSearchUsersWithTeams()

  const managerUsers = allUsers.filter((user) => user.team.length > 0)

  const { groups: allGroups } = useAllGroups()

  const [searchString, setSearchString] = useState<string>("")
  const [isEveryoneSelected, setIsEveryoneSelected] = useState<boolean>(false)

  const [selectedGroups, setSelectedGroups] = useState<Group[]>([])
  const [filteredGroups, setFilteredGroups] = useState<Group[]>([])

  const [selectedTeams, setSelectedTeams] = useState<UserWithTeam[]>([])
  const [filteredTeams, setFilteredTeams] = useState<UserWithTeam[]>([])

  const getTeamsToDisplay = useCallback(() => {
    const managersWithTeamName = managerUsers.map((user) =>
      user.teamName !== ""
        ? user
        : {
            ...user,
            teamName: `${getUserDisplayName(user, companyFlags)}'s team`,
          }
    )

    return filteredTeams.length > 0 ? filteredTeams : managersWithTeamName
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managerUsers, filteredTeams])

  const getGroupsToDisplay = useCallback(() => {
    return filteredGroups.length > 0 ? filteredGroups : allGroups
  }, [filteredGroups, allGroups])

  if (loading || error)
    return <LoaderOrErrorComponent loading={loading} error={error} />
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t("attributed to")}
      rightButton={
        <Button
          secondary
          onClick={() => {
            setRule((prev) => ({
              ...prev,
              who: {
                everyone: isEveryoneSelected,
                groups: selectedGroups,
                teams: selectedTeams,
              },
            }))
            onClose()
          }}
        >
          {t("Save")}
        </Button>
      }
    >
      <Search
        teams={getTeamsToDisplay()}
        groups={getGroupsToDisplay()}
        placeholder={t("search for a group or a team")}
        searchString={searchString}
        setSearchString={(str) => setSearchString(str)}
        onChange={(str) =>
          setSearchString((prev) => {
            if (prev.length > 0 && str === "") {
              setFilteredGroups([])
              setFilteredTeams([])
            }
            return str
          })
        }
        onNewGroupResults={(results) => {
          setFilteredGroups(results)
        }}
        onNewTeamResults={(results) => {
          setFilteredTeams(results)
        }}
      />
      <GroupsTeamsList
        teams={getTeamsToDisplay()}
        groups={getGroupsToDisplay()}
        selectedTeams={selectedTeams}
        selectedGroups={selectedGroups}
        onSelect={(group) => {
          const newGroups = [
            ...selectedGroups.filter((g) => g.id !== group.id),
            group,
          ]
          setSelectedGroups(newGroups)
        }}
        onSelectTeam={(team) => {
          const newTeams = [
            ...selectedTeams.filter((t) => t.id !== team.id),
            team,
          ]
          setSelectedTeams(newTeams)
        }}
        onDeselect={(group) => {
          const newGroups = selectedGroups.filter((g) => g.id !== group.id)

          setSelectedGroups(newGroups)
        }}
        onDeselectTeam={(team) => {
          const newTeams = selectedTeams.filter((t) => t.id !== team.id)

          setSelectedTeams(newTeams)
        }}
        isEveryoneSelected={isEveryoneSelected}
        setIsEveryoneSelected={setIsEveryoneSelected}
      />
    </Dialog>
  )
}
