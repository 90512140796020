import { ApolloError, gql, useQuery } from "@apollo/client"

type License = {
  id: string
  name: string
  quantity: number
  status: string
}

const GET_LICENSE = gql`
  query license {
    license {
      id
      name
      quantity
      status
    }
  }
`

export default function useLicense(): {
  loading: boolean
  error?: ApolloError
  license: License | null
} {
  const { loading, error, data } = useQuery(GET_LICENSE)
  return {
    license: data?.license || null,
    loading,
    error,
  }
}
