import { gql, useMutation } from "@apollo/client"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import { IntegrationEventFragment } from "graphql/events/fragments/integrationEventFragments"
import { MEETING_ROOM_EVENTS_QUERY } from "graphql/meetingRooms/useMeetingRoomEvents"
import useLogEvent from "graphql/misc/useLogEvent"
import useTranslate from "intl/useTranslate"
import { IntegrationEvent, UpsertEventInput } from "types"
import uploadFileToSignedURL from "utils/uploadFileToSignedURL"
import UTCDate from "utils/UTCDate"

import updateCache from "./updateCache"
import { USER_INTEGRATION_EVENTS_QUERY } from "./useUserEvents"

const MEETING_ROOM_EVENT_MUTATION = gql`
  ${IntegrationEventFragment}
  mutation integrations_upsertMeetingRoomEvent($event: IntegrationEventInput) {
    integrations_upsertMeetingRoomEvent(event: $event) {
      ...IntegrationEventFragment
    }
  }
`

const SIMPLE_EVENT_MUTATION = gql`
  ${IntegrationEventFragment}
  mutation integrations_upsertEvent($event: IntegrationEventInput!) {
    integrations_upsertEvent(event: $event) {
      ...IntegrationEventFragment
    }
  }
`

interface HookInputs {
  event: UpsertEventInput
  image: File | null
}

export default function useUpsertEvent(options?: { withRoomId?: boolean }) {
  const t = useTranslate()
  const { addToast } = useToasts()
  const logEvent = useLogEvent()

  const [mutate] = useMutation<{
    integrations_upsertEvent: IntegrationEvent
  }>(options?.withRoomId ? MEETING_ROOM_EVENT_MUTATION : SIMPLE_EVENT_MUTATION)

  return async ({
    event: {
      from,
      to,
      attendees,
      isAllDay,
      roomId,
      __typename, // omit
      organizer, // omit
      integration, // omit
      ...rest
    },
    image,
  }: HookInputs) => {
    try {
      // for whole day events, we want it to be in UTC all the time
      // ie: a "whole day" is the same day in the US an in Europe
      const shiftedFrom = isAllDay ? new UTCDate(from, "DAY", true) : from
      const shiftedTo = isAllDay ? new UTCDate(to, "DAY", true) : to
      const event = {
        ...rest,
        from: `${shiftedFrom.getTime()}`,
        to: `${shiftedTo.getTime()}`,
        isAllDay,
        roomId,
        attendees: attendees.map(({ email, status }) => ({
          email,
          status,
        })),
      }
      const { data } = await mutate({
        variables: { event },
        refetchQueries: [
          MEETING_ROOM_EVENTS_QUERY,
          USER_INTEGRATION_EVENTS_QUERY,
        ],
        update: async (cache, { data }) => {
          const signedUrl = data?.integrations_upsertEvent?.flags?.signedUrl
          if (image && signedUrl) {
            try {
              await uploadFileToSignedURL(image, signedUrl)
              logEvent("event_image_uploaded", { image })
            } catch (e) {
              console.error(e)
              logEvent("event_image_upload_failed", {
                image,
                message: e.message,
              })
            }
          }
          updateCache({
            cache,
            data,
            mutationName: "addEvent",
          })
        },
      })

      if (event.id) {
        return addToast(t("your event has been updated"), {
          appearance: "success",
        })
      }

      addToast(t("your event has been created"), {
        appearance: "success",
      })

      return data?.integrations_upsertEvent
    } catch (e) {
      console.error(e.message)
      addToast(e.message, { appearance: "error" })
    }
  }
}
