import { ApolloCache } from "@apollo/client"

import { EquipmentFragment } from "./fragments/equipmentFragments"

interface PropsType {
  cache: ApolloCache<any>
  data: any
  mutationName: string
  remove?: boolean
}
export default function updateCache({
  cache,
  data,
  mutationName,
  remove,
}: PropsType) {
  if (data && data[mutationName]) {
    const equipment = data[mutationName]
    const fields = {
      equipments: (existing = []) => {
        const s = existing.filter(
          (es: any) => es.__ref !== `${equipment.__typename}:${equipment.id}`
        )
        if (remove) {
          return s
        }
        const newRef = cache.writeFragment({
          data: equipment,
          fragment: EquipmentFragment,
        })
        return [...s, newRef]
      },
    }
    cache.modify({ fields })
  }
}
