import { ApolloCache, gql } from "@apollo/client"
import { LocationFragment } from "graphql/locations/fragments/locationFragment"

interface PropsType {
  cache: ApolloCache<any>
  data: any
  remove?: boolean
}

export function updateVisualAreaCache({ cache, data }: PropsType) {
  if (data && data.saveFloorVisualArea) {
    const floorVisualArea = data.saveFloorVisualArea
    cache.writeQuery({
      query: gql`
        query floorVisualArea($locationId: ID!) {
          floorVisualArea(locationId: $locationId) {
            locationId
            area
          }
        }
      `,
      data: {
        floorVisualArea,
      },
      variables: {
        locationId: floorVisualArea.locationId,
      },
    })
  }
}

interface LocationPropsType extends PropsType {
  floorPlanImageUrl: string | null
  mutationName: string
}

export function updateLocationCache({
  cache,
  data,
  floorPlanImageUrl,
  mutationName,
}: LocationPropsType) {
  if (data && data[mutationName]) {
    const floorPlanImage = data[mutationName]
    cache.updateFragment(
      {
        id: `Location:${floorPlanImage.locationId}`,
        fragment: LocationFragment,
      },
      (data) => ({
        ...data,
        floorPlanImageUrl,
      })
    )
  }
}
