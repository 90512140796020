import useDeleteGroupHybridRule from "graphql/hybridPolicy/useDeleteGroupHybridRule"
import useTranslate from "intl/useTranslate"
import React from "react"
import { GroupHybridRule } from "types"
import { LU12 } from "ui"

import HyridRuleCard from "../HyridRuleCard/HyridRuleCard"
import { RulesList } from "../SharedLayouts"

interface WrapperPropsType {
  groupHybridRules: GroupHybridRule[]
  setSelectedGroupHybridRule: (rule: GroupHybridRule) => void
}

interface PropsType extends WrapperPropsType {
  deleteHybridRule: (groupId: string) => void
}

function GroupsHybridRules({
  groupHybridRules,
  setSelectedGroupHybridRule,
  deleteHybridRule,
}: PropsType) {
  const t = useTranslate()

  if (groupHybridRules.length === 0) return <></>

  return (
    <RulesList>
      <li className="header">
        <LU12>{t("grouped rules")}</LU12>
      </li>
      {groupHybridRules.map((rule, n) => {
        if (!rule.groupId) return null
        return (
          <HyridRuleCard
            key={`user-rule-${n}`}
            groupId={rule.groupId}
            rule={rule}
            setSelectedUserOrGroupHybridRule={(hybridRule) => {
              setSelectedGroupHybridRule({
                ...hybridRule,
                groupId: rule.groupId,
                name: rule.name,
                userIds: rule.userIds,
              })
            }}
            deleteHybridRule={deleteHybridRule}
          />
        )
      })}
    </RulesList>
  )
}

export default function Wrapper({
  groupHybridRules,
  setSelectedGroupHybridRule,
}: WrapperPropsType) {
  const deleteGroupHybridRule = useDeleteGroupHybridRule()

  if (groupHybridRules.length === 0) return <></>

  return (
    <GroupsHybridRules
      groupHybridRules={groupHybridRules}
      setSelectedGroupHybridRule={setSelectedGroupHybridRule}
      deleteHybridRule={deleteGroupHybridRule}
    />
  )
}
