import React from "react"
import styled from "styled-components/macro"
import { mediaQueries } from "ui"

const Avatar = styled.img`
  width: 92px;
  height: 92px;
  text-align: center;
  display: flex;
  margin: 0 auto 12px auto;

  @media ${mediaQueries.isMobile} {
    width: 70px;
    height: 70px;
    margin-top: 30px;
  }
`

export default function AuthHeader() {
  return (
    <>
      <Avatar alt="Offishall Logo" src="/static/icons/icon_violet_green.svg" />
    </>
  )
}
