import useTranslate from "intl/useTranslate"
import React from "react"
import { Button, DropDown, L12GreyBold } from "ui"
import LocationTree from "utils/LocationTree"

import { Fieldset } from "../../../pages/AdminPage/AdminLocationsPage/components/DialogComponents"

interface PropsType {
  defaultValue?: string
  validParents: LocationTree[]
  disabled?: boolean

  onSelect: (obj: { name: string; value: any }) => void
}

export default function CategoryDropDown({
  defaultValue,
  validParents,
  disabled = false,

  onSelect,
}: PropsType) {
  const t = useTranslate()

  return (
    <Fieldset>
      <div style={{ width: "100%" }}>
        <L12GreyBold>{t("parent zone")}</L12GreyBold>
        <DropDown
          minWidth="100%"
          disabled={disabled}
          options={[
            { name: t("no zone"), value: { parentId: null } },
            ...validParents.map((l) => ({
              name: `${[...l.breadCrumbs, l.name].join(" / ")}`,
              value: { parentId: l.id },
            })),
          ]}
          title={defaultValue}
          onSelect={onSelect}
        />
      </div>

      {defaultValue !== t("no parent zone") && !disabled && (
        <div style={{ marginTop: 25 }}>
          <Button
            ghost
            onClick={() =>
              onSelect({ name: t("no zone"), value: { parentId: null } })
            }
          >
            {t("Remove")}
          </Button>
        </div>
      )}
    </Fieldset>
  )
}
