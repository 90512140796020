import React from "react"
import styled from "styled-components"
import { mediaQueries } from "ui"

const Layout = styled.div`
  padding: 0 32px 20px 32px;

  @media ${mediaQueries.isMobile} {
    padding: 16px 8px;
    overflow-y: scroll;
  }
`

interface PropsType {
  children: React.ReactNode
}

export default function PageContent({ children }: PropsType) {
  return <Layout>{children}</Layout>
}

function PageContentHasRef(
  { children }: PropsType,
  ref: React.Ref<HTMLDivElement>
) {
  return <Layout ref={ref}>{children}</Layout>
}

export const PageContentWithRef = React.forwardRef(PageContentHasRef)
