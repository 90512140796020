import useTranslate from "intl/useTranslate"
import React from "react"
import { colors, H2, H3, H4, L12, P14Bold, P16Bold } from "ui"

import { useHomeContext } from "../HomeContextProvider"

interface PropsType {
  title: string
  subtitle: string
  companyTitle: string
  size: number
}

export default function BubbleContent({
  title,
  subtitle,
  companyTitle,
  size,
}: PropsType) {
  const t = useTranslate()
  const { isMyTeamDisplay } = useHomeContext()

  const textColor = {
    color:
      subtitle === t("Home") || subtitle === t("at work")
        ? colors.white
        : colors.black,
  }

  if (size <= 140 && size >= 120)
    return (
      <>
        {isMyTeamDisplay ? (
          <H2 className="old" style={textColor}>
            {title}
          </H2>
        ) : (
          <H2 className="old" style={textColor}>
            {companyTitle}
          </H2>
        )}
        <H4 style={textColor}>{subtitle}</H4>
      </>
    )
  else if (size < 120 && size >= 100)
    return (
      <>
        {isMyTeamDisplay ? (
          <H3 className="old" style={textColor}>
            {title}
          </H3>
        ) : (
          <H3 className="old" style={textColor}>
            {companyTitle}
          </H3>
        )}
        {subtitle === t("Home") ? (
          <P16Bold style={textColor}>{subtitle}</P16Bold>
        ) : (
          <H4 style={textColor}>{subtitle}</H4>
        )}
      </>
    )
  else if (size < 100 && size > 70)
    return (
      <>
        {isMyTeamDisplay ? (
          <H4 className="old" style={textColor}>
            {title}
          </H4>
        ) : (
          <H4 className="old" style={textColor}>
            {companyTitle}
          </H4>
        )}
        <P14Bold style={textColor}>{subtitle}</P14Bold>
      </>
    )
  else
    return (
      <>
        {isMyTeamDisplay ? (
          <P14Bold className="old" style={textColor}>
            {title}
          </P14Bold>
        ) : (
          <P14Bold className="old" style={textColor}>
            {companyTitle}
          </P14Bold>
        )}
        <L12 style={textColor}>{subtitle}</L12>
      </>
    )
}
