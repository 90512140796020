import { ClickAwayListener, Fade } from "@mui/material"
import React, { useState } from "react"
import styled from "styled-components"
import { colors, Popper } from "ui"
import { ArrowDropDown } from "ui/icons"

const Button = styled.button`
  padding: 8px 0;
  border: none;
  background: none;
  outline: none;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    width: 12px;
    height: 12px;
  }
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  background: ${colors.white};
  border-radius: 8px;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.24), 0px 2px 2px rgba(0, 0, 0, 0.12);
  cursor: pointer;

  li {
    padding: 8px 16px;
    &:hover {
      background-color: ${colors.grey4};
    }
    transition: background-color 250ms ease-in-out;
  }
`

interface PropsType {
  placeholder: string
  options: {
    name: string
    value: string
  }[]
  className?: string
  onSelect: (option: { name: string; value: string }) => void
}

export function NakedDropDown({
  placeholder,
  options,
  className,
  onSelect,
}: PropsType) {
  const [buttonRef, setButtonRef] = useState<HTMLButtonElement | null>(null)
  const [popperOpen, setPopperOpen] = useState(false)
  const [title, setTitle] = useState(placeholder)

  return (
    <>
      <Button
        ref={(el) => setButtonRef(el)}
        className={className}
        onClick={() => setPopperOpen((prev) => !prev)}
      >
        {title}
        <ArrowDropDown />
      </Button>
      {popperOpen && (
        <ClickAwayListener
          disableReactTree
          onClickAway={() => {
            setPopperOpen(false)
          }}
        >
          <Popper
            open={popperOpen}
            anchorEl={buttonRef}
            transition
            placement="bottom-start"
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={250}>
                <List>
                  {React.Children.map(
                    options.map((o) => o.name),
                    (child, n) => {
                      return (
                        <li
                          onClick={() => {
                            setPopperOpen(false)
                            setTitle(options[n].name)
                            onSelect(options[n])
                          }}
                        >
                          {child}
                        </li>
                      )
                    }
                  )}
                </List>
              </Fade>
            )}
          </Popper>
        </ClickAwayListener>
      )}
    </>
  )
}
