import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function AddUser({
  stroke,
  strokeWidth,
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      strokeWidth={strokeWidth ?? 1.5}
      stroke={stroke ? stroke : "currentColor"}
      fill={rest.fill ? rest.fill : "transparent"}
      {...rest}
    >
      <path d="M17 10H20M20 10H23M20 10V7M20 10V13M1 20V19C1 15.134 4.13401 12 8 12M8 12C11.866 12 15 15.134 15 19V20M8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12Z" />
    </SvgIcon>
  )
}
