import PageHeader from "components/Layout/PageLayout/PageHeader/PageHeader"
import MobileNavBack from "components/Layout/PageLayout/PageHeader/PageTitle/MobileNavBack/MobileNavBack"
import PageTitle from "components/Layout/PageLayout/PageHeader/PageTitle/PageTitle"
import PageLayout from "components/Layout/PageLayout/PageLayout"
import useCompanyHybridRules from "graphql/hybridPolicy/useCompanyHybridRules"
import useTeamDays from "graphql/teamDays/useTeamDays"
import useUser from "graphql/users/useUser"
import useCompanyFlags from "hooks/useCompanyFlags"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import HybridRulesBrowserButton from "pages/AdminPage/HybridWorkPolicy/DefaultHybridWorkRule/CompanyScoreCard/HybridRulesBrowserButton/HybridRulesBrowserButton"
import React, { useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import styled from "styled-components"
import { Button, colors, Grid, L12, Link, mediaQueries, Tabs } from "ui"
import UTCDate from "utils/UTCDate"

import MembersList from "./MembersList/MembersList"
import RenameTeamDialog from "./RenameTeamDialog/RenameTeamDialog"
import NewTeamDayButton from "./TeamDaysList/NewTeamDayButton/NewTeamDayButton"
import TeamDaysList from "./TeamDaysList/TeamDaysList"
import TeamStatsDialog from "./TeamStatsDialog/TeamStatsDialog"

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const CustomPageTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const Content = styled.div`
  padding: 0px;
  overflow: hidden;
`

const StyledLink = styled(Link)`
  margin-right: auto;
  &:hover,
  &:link,
  &:visited,
  &:active {
    color: ${colors.purple};
  }
  @media ${mediaQueries.isMobile} {
    margin: 0;
  }
`

const NumberOfTeamDays = styled.div`
  background-color: ${colors.grey3};
  height: 18px;
  width: 18px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const DesktopHeaderActions = styled.div`
  display: flex;
  gap: 16px;
  margin-left: auto;
`

export default function MyTeamPage() {
  const t = useTranslate()

  const { isMobile } = useMediaQueries()
  const { user: me } = useUser()
  const { companyHybridRules } = useCompanyHybridRules()
  const { companyFlags } = useCompanyFlags()
  const { teamDays } = useTeamDays({
    managerId: me ? me.id : "",
    filterPastTeamDays: true,
  })

  const shouldHideTeamDays = Boolean(companyFlags.hideTeamDays)

  const { search } = useLocation()
  const timestamp = new URLSearchParams(search).get("timestamp")
  const teamDayDateFromUrl = timestamp
    ? new UTCDate(+timestamp, "DAY")
    : undefined

  const to = `/planning/group/myteam`

  const [tabsHeight, setTabsHeight] = useState<number>(0)

  const headerRef = useRef<HTMLDivElement>(null)

  const headerHeightMobile = headerRef.current
    ? headerRef?.current?.getBoundingClientRect().height + tabsHeight
    : 0
  const headerHeightDesktop = headerRef?.current?.getBoundingClientRect().height

  const [openRenameTeamDialog, setOpenRenameTeamDialog] =
    useState<boolean>(false)

  const teamName = me
    ? me.teamName === ""
      ? t("My team")
      : me.teamName
    : t("My team")

  const [isStatsDialogOpen, setIsStatsDialogOpen] = useState<boolean>(false)

  if (!me) return <></>
  if (isMobile) {
    if (shouldHideTeamDays) {
      return (
        <PageLayout>
          <PageHeader ref={headerRef}>
            <MobileNavBack />
            <PageTitle title={"My team"} />
          </PageHeader>
          <Content>
            <MembersList users={me.team} headerHeight={headerHeightMobile} />
          </Content>
        </PageLayout>
      )
    }
    return (
      <PageLayout>
        <PageHeader ref={headerRef}>
          <MobileNavBack />
          <PageTitle title={"My team"} />
          <NewTeamDayButton initialTeamDayDate={teamDayDateFromUrl} />
        </PageHeader>
        <Content>
          <Tabs
            defaultValue={0}
            getBoudingRect={(rect) => {
              setTabsHeight(rect.height)
            }}
            tabs={[
              {
                label: t("Members"),
                value: 0,
                component: (
                  <MembersList
                    users={me.team}
                    headerHeight={headerHeightMobile}
                  />
                ),
              },
              {
                label: t("team days"),
                value: 1,
                component: (
                  <TeamDaysList
                    managerId={me.id}
                    headerHeight={headerHeightMobile}
                  />
                ),
                icon: (
                  <NumberOfTeamDays>
                    <L12>{teamDays.length}</L12>
                  </NumberOfTeamDays>
                ),
              },
            ]}
          />
        </Content>
      </PageLayout>
    )
  }

  return (
    <PageLayout>
      <PageHeader ref={headerRef}>
        <Header>
          <CustomPageTitle>
            <PageTitle title={teamName} />
            <StyledLink to={to}>{`${t("see planning")} →`}</StyledLink>
          </CustomPageTitle>
        </Header>

        <DesktopHeaderActions>
          <HybridRulesBrowserButton
            title={t("Hybrid rule")}
            options={{
              myTeamScoresOnly: true,
            }}
            disabled={companyHybridRules.default === undefined}
          />

          <Button secondary onClick={() => setIsStatsDialogOpen(true)}>
            {t("team stats simple")}
          </Button>
          <TeamStatsDialog
            open={isStatsDialogOpen}
            onClose={() => setIsStatsDialogOpen(false)}
          />

          <Button onClick={() => setOpenRenameTeamDialog(true)}>
            {t("Rename")}
          </Button>
          <RenameTeamDialog
            me={me}
            open={openRenameTeamDialog}
            onClose={() => setOpenRenameTeamDialog(false)}
          />
        </DesktopHeaderActions>
      </PageHeader>
      <Content>
        <Grid container direction="row">
          <MembersList users={me.team} headerHeight={headerHeightDesktop} />
          {!shouldHideTeamDays && (
            <TeamDaysList
              managerId={me.id}
              headerHeight={headerHeightDesktop}
              initialTeamDayDate={teamDayDateFromUrl}
            />
          )}
        </Grid>
      </Content>
    </PageLayout>
  )
}
