import useAllGroups from "graphql/groups/useAllGroups"
import useUserTree from "hooks/useUserTree"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { Flags, Group } from "types"
import { colors, P14, P16, transitions } from "ui"
import { ArrowRightFill, Group as GroupIcon } from "ui/icons"
import UserTree from "utils/UserTree"

import UsersTab from "../UsersTab"

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const CustomArrowRightFill = styled(ArrowRightFill)`
  align-self: center;
`

const GroupHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 7px 14px;
  border-bottom: solid 1px ${colors.grey3};

  > p:nth-child(3) {
    padding: 4px;
    margin-left: auto;
    cursor: pointer;
    background: ${colors.grey4}00;
    border: solid 1px ${colors.purple}00;
    border-radius: 4px;
    &:hover {
      background: ${colors.grey4};
      border-color: ${colors.purple}08;
    }
    transition: ${transitions.fade};
  }
`

const Item = styled.li`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;
`

const Layout = styled.div`
  display: block;
  position: relative;

  > input {
    cursor: pointer;
    width: 24px;
    height: 24px;
    margin: 0;
    opacity: 0;
  }

  input:checked ~ svg {
    rotate: 90deg;
  }

  svg {
    position: absolute;
    z-index: 0;
    inset: 0 0 0 0;
    width: 24px;
    height: 24px;
    pointer-events: none;
    transition: rotate 250ms ease-in-out;
  }
`

function CollapseCheckBox({
  layoutStyle,
  onChange,
}: {
  layoutStyle?: React.CSSProperties
  onChange: (checked: boolean) => void
}) {
  return (
    <Layout style={layoutStyle}>
      <input
        type="checkbox"
        onChange={(e) => {
          onChange(e.target.checked)
        }}
      />
      <CustomArrowRightFill />
    </Layout>
  )
}

interface ItemPropsType {
  group: Group
  companyFlags: Flags
  selectedUsers: UserTree[]
  disableMySelf?: boolean
  extraAction?: React.ReactNode
  extraActionFilteredTargetIds?: string[]
  onSelectUser: (user: UserTree) => void
  onSelectGroup: (group: Group) => void
}

function GroupItem({
  selectedUsers,
  group,
  companyFlags,
  disableMySelf = false,
  extraAction,
  extraActionFilteredTargetIds,
  onSelectUser,
  onSelectGroup,
}: ItemPropsType) {
  const t = useTranslate()

  const { userTree } = useUserTree()
  const [showMembers, setShowMembers] = useState(
    selectedUsers.find(({ id }) => group.users.find((gu) => gu.id === id)) !==
      undefined
  )

  const groupUsers = group.users
    .map(({ id }) => UserTree.findUserById(userTree, id))
    .filter((u): u is UserTree => u !== undefined)

  return (
    <>
      <Item>
        <CollapseCheckBox
          layoutStyle={{ margin: "8px 8px 0 12px" }}
          onChange={setShowMembers}
        />
        <GroupHeader>
          <GroupIcon />
          <P16>{group.name}</P16>
          {showMembers && (
            <P14 onClick={() => onSelectGroup(group)} color="purple">
              {t("select-all-group-members")}
            </P14>
          )}
        </GroupHeader>
      </Item>
      {showMembers && (
        <UsersTab
          externalUserTree={groupUsers}
          selectedUsers={[
            ...selectedUsers,
            ...groupUsers.filter(({ id }) =>
              selectedUsers.find((u) => u.id === id)
            ),
          ]}
          companyFlags={companyFlags}
          disableMySelf={disableMySelf}
          disableUserTreeRecursion
          extraAction={extraAction}
          extraActionFilteredTargetIds={extraActionFilteredTargetIds}
          onSelectUser={onSelectUser}
        />
      )}
    </>
  )
}

interface PropsType {
  disabled?: boolean
  companyFlags: Flags
  selectedUsers: UserTree[]
  disableMySelf?: boolean
  extraAction?: React.ReactNode
  extraActionFilteredTargetIds?: string[]
  onSelectUser: (user: UserTree) => void
  onSelectGroup: (group: Group) => void
}

export default function GroupsPicker({
  selectedUsers,
  disabled,
  companyFlags,
  disableMySelf = false,
  extraAction,
  extraActionFilteredTargetIds,
  onSelectGroup,
  onSelectUser,
}: PropsType) {
  const { groups: allGroups } = useAllGroups()

  const updateSelectedGroups = (group: Group) => {
    if (disabled) return
    onSelectGroup(group)
  }

  return (
    <List>
      {allGroups.map((g) => (
        <GroupItem
          key={`group-${g.id}`}
          group={g}
          companyFlags={companyFlags}
          selectedUsers={selectedUsers}
          disableMySelf={disableMySelf}
          extraAction={extraAction}
          extraActionFilteredTargetIds={extraActionFilteredTargetIds}
          onSelectUser={onSelectUser}
          onSelectGroup={updateSelectedGroups}
        />
      ))}
    </List>
  )
}
