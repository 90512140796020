import React, { useEffect, useState } from "react"
import { CompanySettingString } from "types"
import { TextField } from "ui"

interface PropsType {
  setting: CompanySettingString
  onChange: (value: string) => void
}

function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return debouncedValue
}

export default function ManageSettingsString({ setting, onChange }: PropsType) {
  const [value, setValue] = useState(setting.valueAsString)
  const debouncedValue = useDebounce(value, 500)

  useEffect(() => {
    if (debouncedValue !== setting.valueAsString) {
      onChange(value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue])

  return (
    <TextField
      disabled={!setting.enabled}
      initialValue={value}
      handleChange={(e) => {
        if (e.length <= setting.maxLength) {
          setValue(e)
        }
      }}
    />
  )
}
