import PageContent from "components/Layout/PageLayout/PageContent/PageContent"
import PageHeader from "components/Layout/PageLayout/PageHeader/PageHeader"
import PageTitle from "components/Layout/PageLayout/PageHeader/PageTitle/PageTitle"
import PageLayout from "components/Layout/PageLayout/PageLayout"
import Search from "components/Search/Search"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { mediaQueries } from "ui"
import {
  Building,
  Calendar,
  FloorPlan,
  Group,
  Handbag,
  Label,
  Laptop,
  Lifebelt,
  Refresh,
  Settings,
} from "ui/icons"

import AdminButton from "./components/AdminButton"

const Container = styled.section`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  padding: 0 0 16px 0;

  @media ${mediaQueries.isTouchable} {
    flex-direction: column;
    flex-wrap: none;
  }
`

export default function AdminPage() {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()

  return (
    <PageLayout>
      {isMobile ? (
        <Search />
      ) : (
        <PageHeader>
          <PageTitle title={t("admin settings")} />
        </PageHeader>
      )}
      <PageContent>
        <Container>
          <AdminButton
            title={t("Company Settings")}
            icon={<Settings />}
            path="/admin/settings"
          />
          <AdminButton
            title={t("Company Labels")}
            icon={<Label />}
            path="/admin/labels"
          />
          <AdminButton
            title={t("Spaces")}
            icon={<Building />}
            path="/admin/locations"
          />

          <AdminButton
            title={t("Floor plans")}
            icon={<FloorPlan />}
            path="/admin/floor-plans"
          />

          <AdminButton
            title={t("Services")}
            icon={<Lifebelt />}
            path="/admin/services"
          />

          <AdminButton
            title={t("Users")}
            icon={<Group />}
            path="/admin/users"
          />

          <AdminButton
            title={t("Integrations")}
            icon={<Laptop />}
            path="/admin/integrations"
          />
          <AdminButton
            title={t("typical week")}
            icon={<Refresh />}
            path="/admin/tw-stats"
          />
          <AdminButton
            title={t("special days")}
            icon={<Calendar />}
            path="/admin/special-days"
          />
          <AdminButton
            title={t("Hybrid work policy")}
            icon={<Handbag />}
            path="/admin/hybrid-policy"
          />
        </Container>
      </PageContent>
    </PageLayout>
  )
}
