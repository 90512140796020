import useCompanyFlags from "hooks/useCompanyFlags"
import useTranslate from "intl/useTranslate"
import EditUserDialog from "pages/AdminPage/UsersPage/AdminControlSection/EditUserDialog"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { User, UserBase } from "types"
import { Button, P16Bold, Toggle } from "ui"
import { Cancel } from "ui/icons"
import getUserDisplayName from "utils/getUserDisplayName"

import { Table, TD, TH, TR } from "./components/TableComponents"
import RemoveUserFromTeam from "./RemoveUserFromTeam/RemoveUserFromTeam"

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* margin-top: 20px; */
  /* margin-bottom: 20px; */
`

const RESULTS_PER_PAGE = 20

interface PropsType {
  usersTeam: UserBase[]
  currentUser: User
}

export default function ManagerTeamTable({
  usersTeam,
  currentUser,
}: PropsType) {
  const t = useTranslate()

  const [isRemoveUserModalOpen, setIsRemoveUserModalOpen] =
    useState<boolean>(false)
  const [isAdminModalOpen, setIsAdminModalOpen] = useState<boolean>(false)
  const [selectedUser, setSelectedUser] = useState<UserBase>()

  const [usersToDisplay, setUsersToDisplay] = useState<UserBase[]>(usersTeam)
  const { companyFlags } = useCompanyFlags()

  // Display more user on the table when user click on "view more" button
  const displayMoreUsers = () =>
    setUsersToDisplay(
      usersTeam.slice(0, usersToDisplay.length + RESULTS_PER_PAGE)
    )

  // By default, set the usersToDisplay with all users
  useEffect(() => {
    if (usersTeam.length > 0) setUsersToDisplay(usersTeam.slice(0, 20))
  }, [usersTeam])

  return (
    <>
      <Table>
        <thead>
          <TR>
            <TH>
              <P16Bold>{t("Name")}</P16Bold>
            </TH>
            <TH>
              <P16Bold>{t("Email address")}</P16Bold>
            </TH>
            <TH>
              <P16Bold>{t("Admin")}</P16Bold>
            </TH>
            <TH>
              <P16Bold> </P16Bold>
            </TH>
          </TR>
        </thead>

        <tbody>
          {usersTeam &&
            usersTeam.map((user) => {
              return (
                <TR key={user.id}>
                  {/* NAME */}
                  <TD
                    className="align-left"
                    onClick={() => {
                      setSelectedUser(user)
                      setIsAdminModalOpen(true)
                    }}
                  >
                    {getUserDisplayName(user, companyFlags)}
                  </TD>
                  {/* EMAIL */}
                  <TD
                    onClick={() => {
                      setSelectedUser(user)
                      setIsAdminModalOpen(true)
                    }}
                    className="align-left"
                  >
                    {user.email}
                  </TD>
                  {/* ADMIN ? */}
                  <TD
                    onClick={() => {
                      setSelectedUser(user)
                      setIsAdminModalOpen(true)
                    }}
                  >
                    <Toggle
                      checked={user.role === "admin" ? true : false}
                      onChange={() => {
                        //
                      }}
                    />
                  </TD>
                  {/* REMOVE USER FROM THE TEAM */}
                  <TD
                    onClick={() => {
                      setIsRemoveUserModalOpen(true)
                      setSelectedUser(user)
                    }}
                  >
                    <Cancel />
                  </TD>
                </TR>
              )
            })}
        </tbody>
      </Table>
      {isRemoveUserModalOpen && selectedUser && (
        <RemoveUserFromTeam
          open={isRemoveUserModalOpen}
          onClose={() => setIsRemoveUserModalOpen(false)}
          title={t("remove user from team")}
          user={selectedUser}
          currentUser={currentUser}
        />
      )}
      {isAdminModalOpen && selectedUser && (
        <EditUserDialog
          open={isAdminModalOpen}
          userId={selectedUser.id}
          onClose={() => setIsAdminModalOpen(false)}
          onSuccess={() => {
            //
          }}
        />
      )}

      <Footer>
        {usersTeam.length !== usersToDisplay.length && (
          <Button
            secondary
            onClick={() => {
              if (usersToDisplay.length > usersTeam.length) displayMoreUsers()
            }}
          >
            {usersTeam.length > usersToDisplay.length
              ? t("View more users")
              : t(usersTeam.length > 0 ? "No more user" : "No result")}
          </Button>
        )}
      </Footer>
    </>
  )
}
