import styled from "styled-components"
import { colors, mediaQueries } from "ui"

export const Table = styled.table`
  width: 100%;
  cursor: pointer;

  @media ${mediaQueries.isMobile} {
    width: calc(100vw - 16px); // available space, 2 * margins
    margin: 0 8px;
  }
`

export const TR = styled.tr`
  &.table-header {
    background: ${colors.grey4};
  }
  &:hover {
    background: rgba(0, 0, 0, 0.04);
    svg {
      color: ${colors.black};
    }
  }
  &:nth-child(even) {
    background: ${colors.grey4};
    &:hover {
      background: rgba(0, 0, 0, 0.04);
      svg {
        color: ${colors.black};
      }
    }
  }
`

export const TH = styled.th`
  padding: 8px;
  text-align: left;
  width: min-content;
  background: ${colors.grey4};

  p {
    color: ${colors.grey1};
  }

  &.align-center {
    text-align: center;
  }

  @media ${mediaQueries.isMobile} {
    position: -webkit-sticky;
    position: sticky;

    overflow: hidden; // overflow with ellipsis
    white-space: nowrap; // overflow with ellipsis
    text-overflow: ellipsis; // overflow with ellipsis
    max-width: 0; // overflow with ellipsis
  }
`

export const TD = styled.td`
  text-align: left;
  width: 25%;
  padding: 8px;

  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.black};

  &.disabled {
    text-decoration: overline;
  }

  &.align-center {
    text-align: center;
  }

  svg {
    color: ${colors.grey1};
    display: flex;
    margin-left: auto;
  }

  overflow: hidden; // overflow with ellipsis
  white-space: nowrap; // overflow with ellipsis
  text-overflow: ellipsis; // overflow with ellipsis
  max-width: 0; // overflow with ellipsis
`
