import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { Button, colors, FieldsetColumn, Label as UILabel, TextField } from "ui"
import isEmailValid from "utils/isEmailValid"

const Input = styled.div`
  display: flex;
  gap: 8px;
  min-width: 100%;

  > div:first-child {
    width: 100%;
  }
`

const Label = styled(UILabel)`
  margin: 0;
`

const ErrorParagraph = styled.p`
  font-weight: 400;
  font-size: 0.75rem;
  color: ${colors.redAlert};
  padding-left: 14px;
`

interface PropsType {
  label?: string
  value: string
  initialValue?: string
  placeholder?: string
  inputRef?: React.Ref<HTMLInputElement>
  autoFocus?: boolean
  disabled?: boolean
  externalError?: string
  onChange: (str: string, error?: boolean) => void
  onAddEmail: () => void
  resetExternalError?: () => void
}

export default function EmailInputText({
  label,
  value,
  initialValue,
  placeholder,
  inputRef,
  autoFocus,
  disabled,
  externalError,
  onAddEmail,
  onChange,
  resetExternalError,
}: PropsType) {
  const t = useTranslate()
  const [error, setError] = useState<boolean | undefined>(undefined)

  const handleValidation = (withDelay?: boolean) => {
    if (!isEmailValid(value) && value !== "") {
      if (withDelay) setTimeout(() => setError(true), 300)
      else setError(true)
    }
  }

  const handleChange = (str: string) => {
    onChange(str, !isEmailValid(str))
    if (str === "") return setError(undefined)
  }

  return (
    <FieldsetColumn>
      <Label>{label}</Label>
      <Input>
        <TextField
          inputRef={inputRef}
          initialValue={initialValue}
          placeholder={placeholder}
          handleChange={handleChange}
          error={error}
          helperText={error ? t("invalid email") : undefined}
          resetOnInitialValue
          autoFocus={autoFocus}
          disabled={disabled}
          onBlur={() => handleValidation()}
          onEnterKey={() => {
            if (isEmailValid(value)) onAddEmail()
          }}
          onFocus={() => {
            setError(undefined)
            if (resetExternalError) resetExternalError()
          }}
        />
        <Button disabled={!isEmailValid(value)} onClick={onAddEmail}>
          {t("Add")}
        </Button>
      </Input>
      {externalError ? (
        <ErrorParagraph color="redAlert">{externalError ?? ""}</ErrorParagraph>
      ) : (
        <ErrorParagraph>&nbsp;</ErrorParagraph>
      )}
    </FieldsetColumn>
  )
}
