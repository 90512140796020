import {
  Attendee,
  AttendeeStatus,
  Event,
  IntegrationEvent,
  UpsertEventInput,
  User,
  UserBase,
} from "types"

import LocalDate from "./LocalDate"
import UserTree from "./UserTree"

export function getUserAsAttendee(attendees: Attendee[], userEmail?: string) {
  return attendees.find(({ email }) => email === userEmail)
}

export function getAttendeeAsUser(userTree: UserTree[], attendees: Attendee[]) {
  const users: (UserTree & { attendingStatus: AttendeeStatus })[] = []
  const guests: { email: string; attendingStatus: AttendeeStatus }[] = []
  for (const { email, status } of attendees) {
    const user = UserTree.findUserByEmail(userTree, email)
    if (user) {
      users.push({ ...user, attendingStatus: status })
      continue
    }
    guests.push({ email, attendingStatus: status })
  }
  return { users, guests }
}

export function getEmptyEventInput(
  date: LocalDate,
  forceToNow = true,
  roomId?: string,
  me?: User | null
): UpsertEventInput {
  const now = new LocalDate()
  const from = new LocalDate(date)
  from.setHours(forceToNow ? now.getHours() : from.getHours(), 0, 0, 0)
  const to = new LocalDate(from)
  to.setHours(to.getHours() + 1, 0, 0, 0)

  return {
    id: undefined,
    name: "",
    description: "",
    from,
    to,
    roomId,
    organizer: me ? { email: me.email, status: "attending" } : undefined,
    attendees: me ? [{ email: me.email, status: "attending" }] : [],
    flags: { raw: "{}", signedUrl: "" },
    isAllDay: false,
    isPrivate: false,
  }
}

export function isPastEvent(event: IntegrationEvent) {
  return event.to.getTime() < new LocalDate("DAY").getTime()
}

export function areSameDay(from: Date, to: Date) {
  const _from = new Date(from)
  _from.setHours(0, 0, 0, 0)
  const _to = new Date(to)
  _to.setHours(0, 0, 0, 0)
  return _from.getTime() === _to.getTime()
}

export function isSingleDayEvent(event: IntegrationEvent) {
  // TODO google all day over a single day ends the next day at 01:00
  return areSameDay(event.from, event.to)
}

export function canUserUpdateAttendingStatus(
  event: IntegrationEvent,
  status: string,
  user?: UserBase | null
) {
  const userAsAttendee = getUserAsAttendee(event.attendees, user?.email)

  if (!userAsAttendee && event.isPrivate) return false

  const meIsOrganizer = user?.email === event.organizer?.email

  if (meIsOrganizer) return false

  const deadlineIsPast = false // TODO event deadline
  // event.deadline && event.deadline.getTime() < new Date().getTime()
  const eventIsPast = event.to && event.to.getTime() < new Date().getTime()

  const timeOut = deadlineIsPast || eventIsPast

  const alreadyAttending = compareUserAttendingStatus(event, status, user)

  const notInvited = event.isPrivate && userAsAttendee === undefined

  return (
    !alreadyAttending ||
    meIsOrganizer ||
    deadlineIsPast ||
    timeOut ||
    notInvited
  )
}

export function compareUserAttendingStatus(
  event: IntegrationEvent,
  status: string,
  user?: UserBase | null
) {
  if (!user) return true
  const attendee = getUserAsAttendee(event.attendees, user.email)
  if (!attendee || attendee.status === "pending") return false
  return attendee?.status === status
}

export function isIntegrationEvent(
  event: IntegrationEvent | Event
): event is IntegrationEvent {
  return (event as IntegrationEvent).isAllDay !== undefined
}

export function isOldEvent(event: IntegrationEvent | Event): event is Event {
  return (event as Event).guests !== undefined
}

export default function filterEventsOfTheDay(day: Date) {
  return (event: IntegrationEvent | Event) => {
    const date = new LocalDate(day, "DAY")
    const from = new LocalDate(event.from)
    from.setHours(0, 0, 0, 0)
    const to = new LocalDate(event.to)
    to.setHours(0, 0, 0, 0)
    to.shift("HOUR", 24)

    return date.getTime() >= from.getTime() && date.getTime() < to.getTime()
  }
}

export function filterMeAsAttendingAttendeeEvents(me: User | null) {
  return (e: IntegrationEvent) => {
    if (e.organizer?.email === me?.email) return true
    const meAsGuest = getUserAsAttendee(e.attendees, me?.email)

    if (meAsGuest) return meAsGuest.status === "attending"
    if (!e.isPrivate) return true
    return false
  }
}
export function filterMeAsAttendingGuestEvents(me: User | null) {
  return (e: Event) => {
    const meAsGuest = e.guests.find((g) => g.id === me?.id)

    if (meAsGuest) return meAsGuest.status === "attending"
    if (!e.private) return true
    return false
  }
}
