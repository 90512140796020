import { green, grey } from "@mui/material/colors"
import { createTheme as createMuiTheme } from "@mui/material/styles"

import breakpoints from "./breakpoints"
import shadows from "./shadows"
import typography from "./typography"

const customBlue = {
  50: "#e9f0fb",
  100: "#c8daf4",
  200: "#a3c1ed",
  300: "#7ea8e5",
  400: "#6395e0",
  500: "#4782da",
  600: "#407ad6",
  700: "#376fd0",
  800: "#2f65cb",
  900: "#2052c2 ",
}

const defaultVariant = {
  name: "DEFAULT",
  palette: {
    primary: {
      main: "#220075",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#FFA348",
      contrastText: "#fff",
    },
    background: {
      default: "#F7F9FC",
      paper: "#FFF",
    },
  },
  header: {
    color: grey[500],
    background: "#FFF",
    search: {
      color: grey[800],
    },
    indicator: {
      background: customBlue[600],
    },
  },
  footer: {
    color: grey[500],
    background: "#FFF",
  },
  sidebar: {
    color: grey[800],
    background: "#ffffff",
    header: {
      color: grey[800],
      background: "#ffffff",
      brand: {
        color: customBlue[500],
      },
    },
    footer: {
      color: grey[800],
      background: "#ffffff",
      online: {
        background: green[500],
      },
    },
    badge: {
      color: "#FFF",
      background: customBlue[500],
    },
  },
}

const createTheme = () => {
  return createMuiTheme(
    {
      spacing: 4,
      breakpoints: breakpoints,
      typography: typography,
      shadows: shadows,
      palette: defaultVariant.palette,
    },
    {
      name: defaultVariant.name,
      header: defaultVariant.header,
      footer: defaultVariant.footer,
      sidebar: defaultVariant.sidebar,
    }
  )
}

export default createTheme()
