import React from "react"
import { Route, Switch, useParams } from "react-router-dom"
import { UserBase } from "types"

import FavoriteUsersHeader from "./Headers/FavoriteUsersHeader"
import GroupHeader from "./Headers/GroupHeader"
import LocationHeader from "./Headers/LocationHeader"

interface PropsType {
  usersFromRouter: UserBase[]
}

export default function PlanningHeadersRouter({ usersFromRouter }: PropsType) {
  const { locationId, groupId } =
    useParams<{ locationId?: string; groupId?: string }>()

  return (
    <Switch>
      <Route path={[`/planning/favorites`]}>
        <FavoriteUsersHeader users={usersFromRouter} />
      </Route>
      <Route path={[`/planning/group/:groupId`]}>
        {groupId === undefined ? (
          <></>
        ) : (
          <GroupHeader groupId={groupId} users={usersFromRouter} />
        )}
      </Route>
      <Route path={[`/planning/location/:locationId`]}>
        {locationId === undefined ? (
          <></>
        ) : (
          <LocationHeader locationId={locationId} users={usersFromRouter} />
        )}
      </Route>
      <Route path="TODO teams route">{/* TODO */}</Route>
    </Switch>
  )
}
