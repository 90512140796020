import { gql, useMutation } from "@apollo/client"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import { IntegrationEventFragment } from "graphql/events/fragments/integrationEventFragments"
import { MEETING_ROOM_EVENTS_QUERY } from "graphql/meetingRooms/useMeetingRoomEvents"
import { IntegrationEvent } from "types"

import { USER_INTEGRATION_EVENTS_QUERY } from "./useUserEvents"

const MUTATION = gql`
  ${IntegrationEventFragment}
  mutation integrations_updateMyAttendeeStatus(
    $eventId: String!
    $status: String!
  ) {
    integrations_updateMyAttendeeStatus(eventId: $eventId, status: $status) {
      ...IntegrationEventFragment
    }
  }
`

export default function useUpdateMyAttendeeStatus() {
  const { addToast } = useToasts()

  const [mutate] = useMutation<{
    integrations_updateMyAttendeeStatus: IntegrationEvent
  }>(MUTATION)

  return async ({ eventId, status }: { eventId: string; status: string }) => {
    try {
      const { data } = await mutate({
        variables: { eventId, status },
        refetchQueries: [
          MEETING_ROOM_EVENTS_QUERY,
          USER_INTEGRATION_EVENTS_QUERY,
        ],
      })
      return data?.integrations_updateMyAttendeeStatus
    } catch (e) {
      console.error(e.message)
      addToast(e.message, { appearance: "error" })
    }
  }
}
