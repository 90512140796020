import VisualAreaEditor, {
  VisualEditorMode,
} from "components/FloorPlanComponents/VisualAreaEditor/VisualAreaEditor"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useFloorVisualArea from "graphql/floorPlans/useFloorVisualArea"
// import useFloorPlanOccupation from "hooks/useFloorPlanOccupation"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { useCallback } from "react"
import styled from "styled-components"
import { WorkingMode } from "types"
import { colors, mediaQueries, P16 } from "ui"
import LocationTree from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

import LocationOccupations from "../LocationOccupations/LocationOccupations"
import FloorPlanTiles from "./FloorPlanTiles/FloorPlanTiles"

const EmptyStateLayout = styled.div<{ maxHeight: number }>`
  display: flex;
  justify-content: center;
  border: solid 1px ${colors.grey3};
  border-radius: 8px;
  background: ${colors.grey4};

  ${({ maxHeight }) => `
  min-height: calc(${maxHeight}px);
  max-height: calc(${maxHeight}px);
  `}

  @media ${mediaQueries.isNarrowScreen} {
  }

  @media ${mediaQueries.isMobile} {
  }
`
const Layout = styled.div<{ maxHeight: number }>`
  &.with-padding {
    padding: 0 16px;
  }
  &.FLOOR_PLAN {
    ${({ maxHeight }) => `
  min-height: calc(${maxHeight}px - 40px);
  max-height: calc(${maxHeight}px - 40px);
  `}
  }
  &.SLOTS_EDITOR {
    ${({ maxHeight }) => `
  min-height: calc(${maxHeight}px - 40px);
  max-height: calc(${maxHeight}px - 40px);
  `}
  }
`

function getActiveFloorPlan(
  locations: LocationTree[],
  selectedLocation: LocationTree
): LocationTree | null {
  if (selectedLocation.floorPlanImageUrl) {
    return selectedLocation
  }
  for (const parent of selectedLocation.getParents(locations)) {
    const activeFloorPlan = getActiveFloorPlan(locations, parent)
    if (activeFloorPlan) {
      return activeFloorPlan
    }
  }
  return null
}

interface SharedPropsType {
  from: UTCDate
  to: UTCDate
  locations: LocationTree[]
  selectedLocation: LocationTree | undefined
  maxHeight: number
  setSelectedLocation: (location?: LocationTree) => void
  saveWorkingMode?: (workingMode: WorkingMode) => void | Promise<void>
}

interface FloorPlanPropsType extends SharedPropsType {
  disabledLocationIds: string[]
}

function FloorPlan({
  locations,
  selectedLocation,
  disabledLocationIds,
  maxHeight,
  setSelectedLocation,
  saveWorkingMode,
}: FloorPlanPropsType) {
  const t = useTranslate()
  const activeFloorPlan = selectedLocation
    ? getActiveFloorPlan(locations, selectedLocation)
    : null

  const { area, loading: loadingArea } = useFloorVisualArea(activeFloorPlan?.id)

  const setSelectedLocationId = useCallback(
    (locationId: string) => {
      if (!activeFloorPlan) {
        return
      }

      const location = LocationTree.getLocationNode(
        [activeFloorPlan],
        locationId
      )
      if (location) {
        setSelectedLocation(location)
      }
    },
    [activeFloorPlan, setSelectedLocation]
  )

  const selectedLocationHasArea =
    area?.objects?.find((o) => o.data.locationId === selectedLocation?.id) !==
    undefined
  const selectedCategoryHasParentWithPlan =
    selectedLocation?.isCategory &&
    selectedLocation?.getParents(locations)?.some((p) => p.floorPlanImageUrl)

  const cannotShowPlanNorTiles =
    selectedLocation &&
    !(
      selectedLocationHasArea ||
      selectedCategoryHasParentWithPlan ||
      selectedLocation?.floorPlanImageUrl
    )

  if (cannotShowPlanNorTiles)
    return (
      <Layout maxHeight={maxHeight} className="with-padding">
        <EmptyStateLayout maxHeight={maxHeight - 92}>
          <P16 style={{ margin: "auto 0" }}>
            {t("location has no area", { name: selectedLocation?.name ?? "" })}
          </P16>
        </EmptyStateLayout>
      </Layout>
    )

  if (!activeFloorPlan) {
    return (
      <Layout maxHeight={maxHeight} className="with-padding">
        <EmptyStateLayout maxHeight={maxHeight - 42}>
          <FloorPlanTiles setSelectedLocation={setSelectedLocation} />
        </EmptyStateLayout>
      </Layout>
    )
  }

  return (
    <Layout maxHeight={maxHeight}>
      <VisualAreaEditor
        location={activeFloorPlan}
        area={area}
        loadingArea={loadingArea}
        mode={VisualEditorMode.VIEWER}
        activeSublocation={selectedLocation}
        setSelectedLocationId={setSelectedLocationId}
        disabledLocationIds={disabledLocationIds}
        maxHeight={maxHeight}
        saveWorkingMode={saveWorkingMode}
      />
    </Layout>
  )
}

function FloorPlanWithOccupations({
  from,
  to,
  locations,
  selectedLocation,
  maxHeight,
  setSelectedLocation,
  saveWorkingMode,
}: SharedPropsType) {
  const { openDrawer } = usePlanningContext()
  const { isMobile } = useMediaQueries()

  // off-3108-greyed-area-although-mostly-available
  // I think we should not grey out the area if there is no occupation, so always have no disabledLocationIds
  /*
  const occupations = useFloorPlanOccupation(
    locations,
    selectedLocation,
    from,
    to
  )

  const disabledLocationIds = occupations
    .filter((o) => o.available === 0)
    .map((o) => o.locationId)*/

  const disabledLocationIds: string[] = []

  return (
    <Layout className={openDrawer ?? undefined} maxHeight={maxHeight}>
      {!isMobile && openDrawer === "FLOOR_PLAN" && (
        <LocationOccupations location={selectedLocation} />
      )}
      <FloorPlan
        from={from}
        to={to}
        locations={locations}
        selectedLocation={selectedLocation}
        disabledLocationIds={disabledLocationIds}
        maxHeight={maxHeight}
        setSelectedLocation={setSelectedLocation}
        saveWorkingMode={saveWorkingMode}
      />
    </Layout>
  )
}

interface WrapperProps extends SharedPropsType {
  withOccupations?: boolean
  maxHeight: number
}

export default function FloorPlanWrapper({
  withOccupations,
  ...props
}: WrapperProps) {
  if (withOccupations) return <FloorPlanWithOccupations {...props} />
  return <FloorPlan disabledLocationIds={[]} {...props} />
}
