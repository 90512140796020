import useUser from "graphql/users/useUser"
import useCompanyFlag from "hooks/useCompanyFlag"
import useMediaQueries from "hooks/useMediaQueries"
import useUserTree from "hooks/useUserTree"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { User } from "types"
import { colors, L12, mediaQueries, P16 } from "ui"
import getUserDisplayName from "utils/getUserDisplayName"
import UserTree from "utils/UserTree"

const Layout = styled.div`
  padding: 16px 32px 0 32px;
  @media ${mediaQueries.isMobile} {
    background-color: ${colors.grey4};
    text-align: center;
    padding: 8px;
  }
`

interface PropsType {
  selectedUser: User
}

export default function CanMutatePlanningMessage({ selectedUser }: PropsType) {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()
  const { user: me } = useUser()
  const { userTree } = useUserTree()

  const isAdmin = me?.role === "admin"
  const isMe = me?.id === selectedUser.id

  const P = isMobile ? L12 : P16

  const managersCanMutate = useCompanyFlag(
    "enableUserMutationForManagers",
    false
  )

  const selectedUserManagedByMe = UserTree.isManagedBy(
    userTree,
    me?.id,
    selectedUser.id
  )

  const showAdminMessage = isAdmin && !isMe
  const showManagerMessage =
    managersCanMutate && selectedUserManagedByMe && !isAdmin && !isMe

  if (!showAdminMessage && !showManagerMessage) return <></>

  return (
    <Layout>
      {showAdminMessage && (
        <P>
          {t("admin selected user permissions", {
            name: getUserDisplayName(selectedUser, selectedUser.company.flags),
          })}
        </P>
      )}
      {showManagerMessage && (
        <P>
          {t("manager selected user permissions", {
            name: getUserDisplayName(selectedUser, selectedUser.company.flags),
          })}
        </P>
      )}
    </Layout>
  )
}
