import styled from "styled-components"
import { colors } from "ui"

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 8px 2px 8px;

  border-bottom: solid 1px ${colors.grey3};

  .star--is-favorite {
    fill: ${colors.yellow};
  }
  .favorite-title {
    margin-right: auto;
  }
`

export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding-bottom: 4px;
  max-width: 100vw;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 80vw;
  }

  svg {
    width: 18px;
    height: 18px;
  }

  .actions {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }

  > span:nth-last-child(-n + 2) {
    margin-left: auto;
  }
`
