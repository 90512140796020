import PageContent from "components/Layout/PageLayout/PageContent/PageContent"
import PageHeader from "components/Layout/PageLayout/PageHeader/PageHeader"
import BurgerButton from "components/Layout/PageLayout/PageHeader/PageTitle/BurgerButton/BurgerButton"
import PageTitle from "components/Layout/PageLayout/PageHeader/PageTitle/PageTitle"
import PageLayout from "components/Layout/PageLayout/PageLayout"
import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import MobileBottomNavigation from "components/MobileBottomNavigation/MobileBottomNavigation"
import SimpleSearch from "components/SimpleSearch/SimpleSearch"
import useAllGroups from "graphql/groups/useAllGroups"
import useLocationsTree from "graphql/locations/useLocationsTree"
import useMediaQueries from "hooks/useMediaQueries"
import useUsers from "hooks/useUsers"
import useUserTree from "hooks/useUserTree"
import useTranslate from "intl/useTranslate"
import { GroupsCollapse } from "pages/DirectoryPage/Collapses/GroupsCollapse/GroupsCollapse"
import { LocationsCollapse } from "pages/DirectoryPage/Collapses/LocationsCollapse/LocationsCollapse"
import { UsersCollapse } from "pages/DirectoryPage/Collapses/UsersCollapse/UsersCollapse"
import React, { useState } from "react"
import { useHistory } from "react-router"
import styled from "styled-components"
import { SearchResult } from "types"

import { OrganisationChart } from "./Collapses/OrganisationChart/OrganisationChart"
const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export default function DirectoryPage() {
  const { locations, loading, error } = useLocationsTree()
  const t = useTranslate()
  const history = useHistory()
  const { isMobile } = useMediaQueries()

  const { users } = useUsers()
  const { groups: allGroups } = useAllGroups()
  const { userTree } = useUserTree()

  const [collapseOpen, setCollapseOpen] = useState<string | null>(null)

  const updateWhichCollapseIsOpen = (str: string | null) => {
    setCollapseOpen((oldCollapseValue) => {
      if (oldCollapseValue === str) return null
      else return str
    })
  }

  const onSelect = (result: SearchResult) => {
    if (result.type === "user") history.push(`/${result.type}/${result.id}`)
    else history.push(`/planning/${result.type}/${result.id}`)
  }

  if (loading || error)
    return <LoaderOrErrorComponent loading={loading} error={error} />

  return (
    <PageLayout>
      {isMobile ? (
        <PageHeader>
          <BurgerButton />
          <PageTitle title={t("Directory")} />
        </PageHeader>
      ) : (
        <PageHeader>
          <PageTitle title={t("Directory")} />
        </PageHeader>
      )}

      <PageContent>
        <Layout>
          <SimpleSearch
            placeholder={t("Groups, Locations, People")}
            onSelect={onSelect}
          />
          <OrganisationChart
            users={userTree}
            collapseOpen={collapseOpen}
            setCollapseOpen={updateWhichCollapseIsOpen}
          />
          <UsersCollapse
            users={users}
            collapseOpen={collapseOpen}
            setCollapseOpen={updateWhichCollapseIsOpen}
          />
          <GroupsCollapse
            groups={allGroups}
            collapseOpen={collapseOpen}
            setCollapseOpen={updateWhichCollapseIsOpen}
          />
          <LocationsCollapse
            locations={locations}
            collapseOpen={collapseOpen}
            setCollapseOpen={updateWhichCollapseIsOpen}
          />
        </Layout>
      </PageContent>
      {isMobile && <MobileBottomNavigation />}
    </PageLayout>
  )
}
