import { ApolloError, gql, useQuery } from "@apollo/client"
import { CompanyIntegration } from "types"

import { CompanyIntegrationFragment } from "./fragments/CompanyIntegrationFragments"

const QUERY = gql`
  ${CompanyIntegrationFragment}
  query companyIntegrations {
    companyIntegrations {
      ...CompanyIntegrationFragment
    }
  }
`

export default function useCompanyIntegrations(): {
  loading: boolean
  error?: ApolloError
  companyIntegrations: CompanyIntegration[]
} {
  const { loading, error, data } = useQuery<{
    companyIntegrations: CompanyIntegration[]
  }>(QUERY, { fetchPolicy: "cache-and-network" })
  return {
    companyIntegrations: data?.companyIntegrations || [],
    loading,
    error,
  }
}
