import useTranslate from "intl/useTranslate"
import {} from "pages/AdminPage/HybridWorkPolicy/utils"
import React, { useState } from "react"
import styled from "styled-components"
import { P14Bold, Table, TH, TR } from "ui"
import UTCDate from "utils/UTCDate"

import { DropDownOptionType } from "../HybridRulesBrowserButton"
import NavigationButton from "./NavigationButton/NavigationButton"
import UserHybridRuleScoreList from "./UserHybridRuleScoreList/UserHybridRuleScoreList"

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px 24px 24px 24px;
`

interface TablePropsType {
  rule: DropDownOptionType
  options?: {
    showUserRuleType?: boolean
  }
}

export default function HybridRuleScoreTable({
  rule,
  options: { showUserRuleType } = { showUserRuleType: false },
}: TablePropsType) {
  const { period } = rule.value.rule
  const t = useTranslate()

  const [selectedDate, setSelectedDate] = useState(new UTCDate())

  const isExportReady = false
  const isWeekDetailReady = false

  return (
    <>
      <div style={{ gridArea: "empty" }} />
      <NavigationButton
        type={period}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
      {isExportReady && (
        <div style={{ gridArea: "export" }}>
          <button>export</button>
        </div>
      )}
      <Content
        style={{
          gridArea: "table",
        }}
      >
        <Table>
          <thead>
            <TR>
              <TH displayBorder>
                <P14Bold>{t("Name")}</P14Bold>
              </TH>
              {showUserRuleType && (
                <TH displayBorder>
                  <P14Bold>{t("hybrid-rule-origin")}</P14Bold>
                </TH>
              )}
              <TH displayBorder>
                <P14Bold>{t("hybrid-rule-label--period")}</P14Bold>
              </TH>
              <TH displayBorder>
                <P14Bold>{t("rule")}</P14Bold>
              </TH>
              <TH displayBorder>
                <P14Bold>{t("programmed")}</P14Bold>
              </TH>
              {isWeekDetailReady && (
                <>
                  <TH displayBorder>
                    <P14Bold>{t("week details")}</P14Bold>
                  </TH>
                  <TH displayBorder />
                </>
              )}
            </TR>
          </thead>
          <tbody>
            <UserHybridRuleScoreList rule={rule} selectedDate={selectedDate} />
          </tbody>
        </Table>
      </Content>
    </>
  )
}
