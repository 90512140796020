import useAddCompanyFile from "graphql/company/useAddCompanyFiles"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import { Button } from "ui"
import { Plus } from "ui/icons"

import UploadDialog from "./UploadDialog/UploadDialog"

export default function UploadButton() {
  const t = useTranslate()

  const { addCompanyFile } = useAddCompanyFile()

  const [openDialog, setOpenDialog] = useState(false)

  return (
    <>
      <Button secondary onClick={() => setOpenDialog(true)}>
        <Plus />
        {t("company_file--add_file")}
      </Button>
      {openDialog && (
        <UploadDialog
          open={openDialog}
          onConfirm={({ file, filename }) => {
            addCompanyFile({ file, filename })
            setOpenDialog(false)
          }}
          onCancel={() => setOpenDialog(false)}
        />
      )}
    </>
  )
}
