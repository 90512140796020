import PageContent from "components/Layout/PageLayout/PageContent/PageContent"
import PageHeader from "components/Layout/PageLayout/PageHeader/PageHeader"
import BurgerButton from "components/Layout/PageLayout/PageHeader/PageTitle/BurgerButton/BurgerButton"
import PageTitle from "components/Layout/PageLayout/PageHeader/PageTitle/PageTitle"
import PageLayout from "components/Layout/PageLayout/PageLayout"
import Loader from "components/Loader/Loader"
import MobileBottomNavigation from "components/MobileBottomNavigation/MobileBottomNavigation"
import useUserEvents from "graphql/events/useUserEvents"
import useCompanyIntegrations from "graphql/integrations/useCompanyIntegrations"
import useUser from "graphql/users/useUser"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import MeetingRoomEventForm from "pages/MeetingRoomsPage/MeetingRoomEventForm/MeetingRoomEventForm"
import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { UpsertEventInput } from "types"
import { Button, colors, P16 } from "ui"
import { Plus } from "ui/icons"
import { getEmptyEventInput } from "utils/events"
import LocalDate from "utils/LocalDate"

import EventList from "../EventList/EventList"

const AddEventButtonMobile = styled(Plus)`
  stroke: ${colors.grey1};
  margin-left: auto;
`

const ErrorMessage = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  margin: auto;
  align-items: center;
`

export default function IntegrationEventsPage() {
  const t = useTranslate()
  const { user: me } = useUser()
  const history = useHistory()

  const { isMobile } = useMediaQueries()

  const { userEvents, loading } = useUserEvents({
    from: new LocalDate("WEEK-MONDAY"),
    to: new LocalDate("NEXT-MONTH"),
  })

  const { companyIntegrations } = useCompanyIntegrations()
  const hasEventsIntegration = companyIntegrations.find(
    ({ name }) => name === "google-events" || name === "microsoft-outlook"
  )

  const [selectedEvent, setSelectedEvent] = useState<
    UpsertEventInput | undefined
  >(undefined)

  const openEmptyEventForm = () => {
    const date = new LocalDate()
    date.setHours(date.getHours(), 0, 0, 0)
    setSelectedEvent(getEmptyEventInput(date, true, undefined, me))
  }

  if (!hasEventsIntegration)
    return (
      <PageLayout>
        {isMobile ? (
          <PageHeader>
            <BurgerButton />
            <PageTitle title={t("Events")} />
          </PageHeader>
        ) : (
          <PageHeader>
            <PageTitle title={t("Events")} />
          </PageHeader>
        )}

        <ErrorMessage>
          <P16>{t("meeting-room-error--no-integration")}</P16>
          {me?.role === "admin" && (
            <Button ghost onClick={() => history.push("/admin/integrations")}>
              {t("Go to Offishall's integrations configuration page.")}
            </Button>
          )}
        </ErrorMessage>
        {isMobile && <MobileBottomNavigation />}
      </PageLayout>
    )

  if (!me) return <></>

  if (loading)
    return (
      <PageLayout>
        {isMobile ? (
          <PageHeader>
            <BurgerButton />
            <PageTitle title={t("Events")} />
            <AddEventButtonMobile
              data-component="create-event-button"
              onClick={openEmptyEventForm}
            />
          </PageHeader>
        ) : (
          <PageHeader>
            <PageTitle title={t("Events")} />
            <Button
              data-component="create-event-button"
              onClick={openEmptyEventForm}
              ghost
            >
              <Plus />
            </Button>
          </PageHeader>
        )}

        <Loader />
        {isMobile && <MobileBottomNavigation />}
      </PageLayout>
    )

  return (
    <>
      <PageLayout>
        {isMobile ? (
          <PageHeader>
            <BurgerButton />
            <PageTitle title={t("Events")} />
            <AddEventButtonMobile
              data-component="create-event-button"
              onClick={openEmptyEventForm}
            />
          </PageHeader>
        ) : (
          <PageHeader>
            <PageTitle title={t("Events")} />
            <Button
              data-component="create-event-button"
              onClick={openEmptyEventForm}
              ghost
            >
              <Plus />
            </Button>
          </PageHeader>
        )}

        <PageContent>
          <EventList
            events={userEvents.filter((e) => e.initiator !== null)}
            setEventToBeEdited={setSelectedEvent}
          />
        </PageContent>
        {isMobile && <MobileBottomNavigation />}
      </PageLayout>

      {selectedEvent !== undefined && (
        <MeetingRoomEventForm
          event={selectedEvent}
          meetingRooms={[]}
          onClose={() => setSelectedEvent(undefined)}
        />
      )}
    </>
  )
}
