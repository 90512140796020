export default {
  id: "fr",
  flag: "🇫🇷",
  messages: {
    // LOGIN
    welcome: "Bienvenue",
    Company: "Entreprise",
    "Company name": "Nom de l'entreprise",
    Phone: "Numéro de téléphone",
    "Sign in to your account to continue":
      "Connectez-vous à votre compte pour continuer",
    "Email address": "Adresse email",
    Password: "Mot de passe",
    "Confirm Password": "Confirmer le mot de passe",
    "Old Password": "Ancien mot de passe",
    "Your password": "Votre mot de passe",
    "New Password": "Nouveau mot de passe",
    "Sign in": "Se connecter",
    "Sign up": "S'inscrire",
    "Sign-in with Microsoft": "Se connecter avec Microsoft",
    "Sign-in with Google": "Se connecter avec Google",
    "Sign-in with Okta": "Se connecter avec Okta",
    "Sign-in with SSO": "Se connecter en SSO",
    "Log in with your team's identity provider":
      "Se connecter avec votre fournisseur d'identité",
    "Your Offishall company ID": "Votre ID d'entreprise Offishall",
    "SSO simple tip":
      "Astuce: vous pouvez directement aller sur [id entreprise].offishall.io pour vous connecter en SSO",
    "Cannot connect to this account": "Impossible de se connecter à ce compte",
    "Cannot connect to this account: user is disabled":
      "Impossible de se connecter à ce compte: l'utilisateur est désactivé.",
    "Cannot connect to this account: company is disabled":
      "Impossible de se connecter à ce compte: l'entreprise est désactivée.",
    "Forgot password": "Mot de passe oublié ?",
    "Reset password": "Réinitialiser le mot de passe",
    "Back to sign-in": "Retour à la connexion",
    "Wrong email or password": "Erreur dans l'adresse email ou le mot de passe",
    "Wrong password": "Erreur dans le mot de passe",
    "Must be at least 8 characters": "Doit comporter au moins 8 caractères",
    "Must be at least 12 characters": "Doit comporter au moins 12 caractères",
    B4BPasswordRule:
      "Votre mot de passe doit au moins comporter : 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial (l'ordre n'a aucune importance 🙂)",
    "Must be a valid email": "Doit être une adresse email valide",
    Required: "Obligatoire",
    "Email is required": "Adresse email obligatoire",
    "Password is required": "Mot de passe obligatoire",
    "old pawword is required": "L'ancien mot de passe est obligatoire",
    "Something went wrong": "Une erreur s'est produite",
    "Enter your email to reset your password":
      "Entrez votre adresse email pour réinitialiser votre mot de passe",
    "An email has been sent to the address to reset your password":
      "Un email a été envoyé à l'adresse {address} pour réinitialiser votre mot de passe.",
    "Get started": "Commencer",
    "create your company account here": "Créez votre compte entreprise !",
    "configure your company account here":
      "Configurez votre compte entreprise !",
    "Both passwords need to be the same":
      "Les mots de passe doivent être identiques 🤓",
    "Don't worry about payment":
      "Pas d'inquiétude ! Aucune information de paiement ne doit être renseignée maintenant",
    "I accept Offishall TOS": "J'accepte les {tos} d'Offishall",
    "terms of service": "Conditions d'utilisation",
    "Already have an account ? Click here to sign-in":
      "Déjà un compte ? Cliquez ici pour vous connecter",
    "Confirm your email": "Confirmez votre email",
    "Please check your inbox for a confirmation email from noreply@offishall.io.":
      "Consultez vos emails, vous allez recevoir un lien de confirmation depuis noreply@offishall.io 😉 Merci !",
    "Click the link in the email to confirm your email address.":
      "Cliquez le lien dans l'email pour confirmer votre adresse email.",
    "Send validation email again": "Envoyer à nouveau l'email de validation",
    "Email validation": "Vérification d'email",
    "Verifying your email now...": "Verification de l'email en cours...",
    "Failed to verify email.": "La vérification a échoué",
    "Your email has been successfully verified.":
      "Votre adresse email a été vérifiée avec succès.",
    "Continue to sign-in": "Continuer vers la connexion",
    "Log out": "Se déconnecter",
    "Company is required":
      "Le champ 'entreprise' est obligatoire pour continuer",
    "Confirm password is required": "Confirmation de mot de passe obligatoire",
    "You must accept terms of service":
      "Vous devez accepter les conditions générales d'utilisation",
    Confirm: "Confirmer",
    "signin-missing-account-title": "Utilisateur inconnu",
    "signin-missing-account-content":
      "Vous ne semblez pas encore avoir de compte Offishall. Demandez à votre administrateur de vous créer un compte, ou cliquez ici pour créer un nouveau compte entreprise.",
    "site protected by reCAPTCHA & google":
      "Ce site est protégé par reCAPTCHA et les",
    "of Google": "de Google",
    "link-account":
      "C'est la première fois que vous vous connectez avec ce compte en utilisant cette méthode. Veuillez entrer votre mot de passe actuel pour lier ce compte à votre compte Offishall.",

    "App language": "Langue de l'application",

    // DEMO
    "demo-popup-title": "Mode démo",
    "demo-popup-content": `Vous êtes dans un environnement de démo, avec des faux utilisateurs, espaces et groupes pré-générés pour vous. Vous êtes libre de naviguer, créer et utiliser complètement l'application ; quand vous quitterez l'environnement de démo, toutes les données de la démo seront supprimées. Tout ce que vous aurez crée sera conservé. Quand vous êtes prêt.e, cliquez sur le bouton "Mettez-le en place pour votre entreprise" en haut de l'application, et vous pourrez réserver un rendez-vous avec nous pour vous aider, ou le faire seul.e de votre côté.`,
    "Setup your organization": "Configurez votre entreprise",
    "demo-leave-popup-title": "Souhaitez-vous quitter le mode démo ?",
    "demo-leave-popup-content": `Vous allez quitter l'environnement de démo. Toutes les données de la démo seront supprimées. Tout ce que vous avez créé sera sauvegardé. Voulez vous réserver un rendez-vous pour que nous vous aidions à configurer l'environnement parfait pour votre société ?`,
    "demo-leave-popup-yes": "Prendre rendez-vous et quitter le mode démo",
    "demo-leave-popup-no": "Quitter le mode démo sans prendre rendez vous",

    // DATE
    day: "Jour",
    days: "jours",
    "half-days": "demi-journées",
    Sunday: "Dimanche",
    Monday: "Lundi",
    Tuesday: "Mardi",
    Wednesday: "Mercredi",
    Thursday: "Jeudi",
    Friday: "Vendredi",
    Saturday: "Samedi",
    January: "Janvier",
    February: "Février",
    March: "Mars",
    April: "Avril",
    May: "Mai",
    June: "Juin",
    July: "Juillet",
    August: "Août",
    September: "Septembre",
    October: "Octobre",
    November: "Novembre",
    December: "Décembre",
    Date: "Date",
    week: "semaine",
    month: "mois",
    year: "année",
    "DD/MM/YY": "JJ/MM/AA",
    "MM/DD/YY": "MM/JJ/AA",

    // MISC
    Me: "Moi",
    you: "vous",
    from: "de",
    to: "à",
    Search: "Rechercher",
    All: "Tous",
    Save: "Sauvegarder",
    Back: "Retour",
    New: "Nouveau",
    Edit: "Editer",
    Update: "Modifier",
    Delete: "Effacer",
    Remove: "Supprimer",
    "Remove all": "Tout supprimer",
    Rename: "Renommer",
    Add: "Ajouter",
    Cancel: "Annuler",
    Default: "Défaut",
    French: "🇫🇷 Français",
    English: "🇺🇸 Anglais",
    "Manager name": "Manager : {name}",
    Manager: "Manager",
    Managers: "Managers",
    Understood: "Compris ! 😄",
    Other: "Autre",
    Unavailable: "Indisponible",
    Remote: "Télétravail",
    Name: "Nom",
    Clear: "Effacer",
    Close: "Fermer",
    "Clear selection": "Effacer la sélection",
    "simple view more": "Voir plus",
    "view more": "Voir plus...",
    "view less": "Voir moins",
    "share feedback": "Aidez-nous à nous améliorer",
    Morning: "matin",
    "long morning": "matin",
    Afternoon: "après-midi",
    "long afternoon": "après-midi",
    Role: "Rôle",
    Collaborator: "Collaborateur",
    Collaborators: "Collaborateurs",
    Legend: "Légende",
    Places: "Lieux",
    Place: "Lieu",
    Employee: "Utilisateur",
    Employees: "Utilisateurs",
    managers: "Managers",
    people: "personnes",
    organizer: "organisateur",
    Email: "Email",
    Remotely: "À distance",
    Or: "ou",
    Total: "Total",
    Copy: "Copier",
    To: "À",
    Planned: "Planifié",
    Resend: "Renvoyer",
    here: "ici",
    person: "personne",
    persons: "personnes",
    "Select all": "Tout sélectionner",
    "Deselect all": "Tout désélectionner",
    Group: "Group",
    groups: "Groupes",
    Groups: "Groupes",
    "Places & groups": "Lieux & groupes",
    Reinitialize: "Réinitialiser",
    "Organisation chart": "Organigramme",
    places: "places",
    seats: "places",
    and: "et",
    apply: "s'appliquent",
    Object: "Objet",
    Members: "Membres",
    invitations: "invitations",
    Unauthorized: "Non autorisé",
    "text copied confirmation":
      "Le texte a été copié avec succès dans le presse-papiers.",
    "no match": "Pas de résultat",
    zone: "Zone",
    space: "Espace de travail",
    "email example": "machin.truc@sarl.xyz",
    help: "Aide",
    "changes-saved--success": "Vos changements ont été enregistrés.",
    "changes-saved--failure":
      "Une erreur s'est produite pendant la sauvegarde.",
    name: "Nom",
    hello: "Bonjour",
    others: "Autres",
    "required field": "Champ requis",
    meeting: "réunion",
    "a meeting": "une réunion",
    event: "événement",
    "an event": "un événement",
    create: "Créer",
    previous: "précédent",
    next: "suivant",

    // STATUS
    Office: "Bureau",
    Home: "Télétravail",
    "Out Of Contract": "Hors Contrat",
    Else: "Autre",
    Off: "Absent.e",
    "Not set": "Non rempli",
    office: "Bureau",
    home: "Télétravail",
    ooc: "Hors Contrat",
    else: "Autre",
    off: "Absent.e",
    null: "Non rempli",
    "home-tooltip-title":
      "Tous les lieux autorisés pour le télétravail (votre domicile, espace de co-working, le café en bas de votre rue...)",
    "office-tooltip-title": "Assez facile à comprendre celui-là...",
    "else-tooltip-title":
      "Lieux où il est possible de travailler mais dans des conditions particulières (train, séminaire, alternance...)",

    "off-tooltip-title":
      "Absent.e: Congés, RTT, Maladie. Toutes les absences dans le cadre du travail",
    "ooc-tooltip-title":
      "Hors contrat: Jours non travaillés contractuellement. Ex: jours fériés, temps partiel etc…",
    "null-tooltip-title": "null-tooltip-title",
    "mistake-tooltip-title":
      "Vous avez fait une erreur, effacez là et n'y pensez plus.",

    // MENU
    Services: "Services",
    Statistics: "Statistiques",
    Favorites: "Favoris",
    "my groups": "Mes groupes",
    "create a group": "Créer un groupe",
    "join a group": "Rejoindre un groupe",
    Directory: "Répertoire",

    // PLANNING PAGE
    Planning: "Planning",
    "got it": "D'accord 🙂",
    today: "aujourd'hui",
    filter: "Filtre",
    filters: "Filtres",
    "Manage my groups": "Gérer mes groupes",
    "n members": "{count} membres",
    "delete group": "Supprimer un groupe",
    "mail all members": "Envoyer un email à tous les membres",
    "copy the mailing list": "Copier les adresses email",
    "copy group name": "copier le nom du groupe",
    "Your company status is set to inactive":
      "Le statut de votre entreprise est inactif 😬 Contactez votre administrateur pour éditer des créneaux.",
    "Select your working mode": "Sélectionnez votre mode de travail 🙂",
    "desks available": "Bureaux disponibles",
    capacity: "Capacité",
    "Update planning": "Mettre à jour mon planning",
    "Advanced Options": "Options avancées",
    repeat: "Répéter",
    "repeat every week": "Répéter toutes les semaines",
    "repeat from this day until": "Répéter de ce jour jusqu'à",
    "repeat every week until": "Répéter toutes les semaines jusqu'au",
    "Clear Selection": "Effacer la sélection",
    "many more": "{count} supplémentaires ↓",
    "location is full": "Cet espace est complet 😬",
    "days remain unfilled": "{days} jours remplis ",
    "full week completion": "Votre semaine est complète ! 🎉",
    "Weekend included": "Avec week-end",
    "Weekend not included": "Sans week-end",
    "Back to planning": "Retour au planning",
    "recurrence summary":
      "Tous les {firstDate} {firstTime} jusqu'au {lastDate} {lastTime}, {label} jusqu'au {endDate}.",
    "small recurrence": "Tous les {firstDate}, {label} jusqu'au {endDate}.",
    "every day recurrence": "Tous les jours {label} jusqu'au {endDate}.",
    "only once": "Seulement pour cette fois",
    "every week": "Toutes les semaines jusqu'au JJ/MM/AA",
    "every day": "Tous les jours jusqu'au JJ/MM/AA",
    "all day long": "Toute la journée",
    "this day": "Ce jour",
    "new recurrence": "Ajouter une récurrence",
    recurrence: "Récurrence",
    "recurence end": "Fin de récurrence",
    "copy mailing list": "Copier la liste d'emails",
    "see planning": "voir planning",
    "Cancel recurrence": "Annuler la récurrence",
    "nobody fits filtering":
      "Personne ne correspond à vos critères de recherche.",
    "choose week": "Choisissez une semaine",
    "send me a reminder": "M'envoyer un rappel",
    oops: "Oups !",
    "You are too forward thinking for us":
      "Vous êtes trop prévoyant·e pour nous !",
    "not yet authorized to fill your schedule for this date":
      "Vous n êtes pas encore autorisé·e à remplir votre planning pour cette date. Les créneaux de réservation seront ouverts à compter du",
    "apply typical week": "Appliquer ma semaine type",
    "go edit typical week": "Modifier ma semaine type",
    "go create typical week": "Créer ma semaine type",
    "desk-booking-recurrence-warning":
      "La réservation sera faite jusqu'au {date}.",
    "desk-booking-recurrence-warning--tooltip":
      "En accord avec votre politique d'entreprise, vous ne pouvez pas réserver de bureau après le {date}.",
    "category-children-list-title": "Lieux dans la zone “{categoryName}”",
    "apply-typical-week-automatically--label": "Appliquer automatiquement",
    "apply-typical-week-automatically--tooltip":
      "Nous essaierons d'appliquer votre semaine type automatiquement.",
    "Loosing previous service bookings warning":
      "Attention, vous êtes sur le point d'annuler votre précédente réservation des services suivants:",
    "Loosing previous guests warning":
      "Attention, vous êtes sur le point d'annuler les invitations suivantes:",
    "Automatically fill my schedule with my typical week":
      "Remplir automatiquement mon planning avec ma semaine type",
    "Define your typical week": "Définir votre semaine type",
    "show all teams": "voir les équipes",
    "hide all teams": "cacher les équipes",

    // WHO's THERE PAGE
    "Who's there ?": "Qui est là?",
    "one person found": "Nous avons trouvé une personne ",
    "many persons found": "Nous avons trouvé {count} personnes",
    "Apparently, nobody.": "Oops, apparemment il n'y a personne 😬 ",
    "Copy email addresses": "Copier les adresses email",
    "whos there locations empty":
      "Votre enterprise n'a pas encore configuré d'espaces de travail. Vous ne pouvez donc pas profiter de cette fonctionnnalité.",
    "whos there location empty admin":
      "En tant qu'administrateur vous pouvez vous diriger vers la page d'administration pour créer des espaces de travail.",
    "go to admin spaces": "Gestion des espaces de travail",
    "select a date": "Sélectionner une date :",

    // DASHBOARD
    Dashboard: "Tableau de bord",
    Timeframe: "Plage de temps",
    "You need to have filled some slots before accessing your stats.":
      "Vous devez remplir au moins un créneau avant d'accéder aux statistiques",
    "My team": "Mon équipe",
    "person's team": "L'équipe de {person}",
    "Compare with": "Comparer avec",
    "Completion rate": "Taux de remplissage",
    completed: "Complété ",
    "not completed": "Non complété",
    "Occupancy Rates": "Taux d'occupation",
    "not occupied": "non occupé",
    occupied: "occupé",
    available: "Quelle chance ! C'est disponible",
    "booked desks / total full capacity of the office":
      "Places réservées / Capacité totale du bureau",
    "Working modes habits": "Modes de travail",
    "Working modes habits per day": "Modes de travail par jour",
    "per day": "Par jour",
    "in percent": "En pourcentage",
    "Concat data": "Concaténer les données",
    "Concat data description":
      "Sélectionner les groupes dont vous voulez additionner la donnée pour afficher la donnée consolidée",
    "Concat data location description":
      "Sélectionner les espaces dont vous voulez additionner la donnée pour afficher la donnée consolidée",
    "Concatened data": "Données concaténées",

    // SETTINGS
    Settings: "Paramètres",
    "Click here to manage your groups": "Cliquez ici pour gérer vos groupes",
    "My groups": "Mes groupes",
    "My manager": "Mon manager",
    "your manager": "L'email de votre manager",
    "Change my password": "Changer mon mot de passe",
    "Password changed successfully.": "Mot de passe mis à jour",
    "My preferences": "Mes préférences",
    "Personal preferences": "Préférences personnelles",
    Languages: "Langues",
    "your profile photo has been saved":
      "Votre photo de profil a été enregistrée",
    "your profile photo has been deleted":
      "Votre photo de profil a été supprimée",
    "Add a profile picture": "Ajouter une photo de profil",
    "Read more": "En savoir plus",
    "profile picture": "Photo de profil",

    // MANAGE MY GROUPS
    "Join or create your groups of interest":
      "Rejoindre ou créer des groupes d'intérêt",
    "Create group": "Créer le groupe",
    "Delete group": "Effacer le groupe",
    "Group deleted": "Le groupe a bien été effacé",
    "group name already exist": "Désolé, mais ce nom de groupe existe déjà 😥",
    "group name": "Nom du groupe",
    "confirm group name": "Confirmer le nom de groupe",
    "group names must match": "Les noms de groupe doivent correspondre 😬",
    join: "Rejoindre",
    leave: "Quitter",
    "members invitation": "Inviter des membres",
    "enter a name or an email address":
      "Saisissez un nom ou une adresse email ",
    "Leave group": "Voulez-vous vraiment quitter le groupe ? 😔",
    "select group":
      "Sélectionnez un groupe que vous souhaitez rejoindre (plus on est de fous, plus on rit)",
    "search groups": "Rechercher un groupe",
    "remove user from group": "Retirer l'utilisateur du groupe",
    "edit group": "Modifier le groupe",
    "update group name": "Modifier le nom du groupe",
    "no user in group": "Ce groupe ne contient aucun utilisateur.",
    "Are you sure you want to delete this group?":
      "Êtes-vous sûr de vouloir supprimer ce groupe ?",
    "group created by at date": "Créé par {name} le {date}",
    "add user": "Ajouter un utilisateur",
    "add user to team": "Ajouter un utilisateur à l'équipe",
    "remove user from team": "Retirer un utilisateur de l'équipe",
    "confirmation remove user from team":
      "Êtes-vous CERTAIN de vouloir retirer {username} de cette équipe : {teamname} ?",
    "must invite at least 1 user to group":
      "Vous devez inviter au moins 1 utilisateur dans le groupe",
    "delete manager": "Supprimer le manager",
    "delete manager confirmation":
      "Etes vous sûr de vouloir supprimer le manager ?",
    "save manager confirmation":
      "Etes vous sûr de vouloir changer le manager ?",
    "previous manager": "Précedent manager",
    "new manager": "Nouveau manager",

    // ADMIN
    Admin: "Admin",
    Locations: "Espaces",
    Users: "Utilisateurs",
    Message: "Message",
    Messages: "Messages",
    Billing: "Facturation",
    Capacity: "Capacité",
    "admin explanation":
      "En tant qu’admin, l’utilisateur aura libre accès aux paramétrages de l’entreprise (espaces, services, utilisateurs), il pourra aussi créer/rejoindre des groupes et modifier les plannings des autres utilisateurs.",
    "manager explanation":
      "Le manager pourra modifier son propre planning, créer/rejoindre un groupe, consulter et modifier le planning des autres utilisateurs de son entreprise.",
    "admin settings": "Paramètres des admins",
    "manager team length": "Il y a  {teamLength} membres dans son équipe",
    "no manager": "pas de manager",
    "remove a team member": "Retirer un membre de l'équipe",
    "edit user profile": "Modifier le profil utilisateur",
    "search users": "Nom, prénom, mail ...",
    "Send messages": "Envoyer des messages",

    // ADMIN SERVICES
    Service: "Service",
    "Company services": "Services de l'entreprise",
    "Manage units": "Gestion des unités",
    "Add service": "Ajouter un service",
    "Add a service": "Ajouter un service",
    "Edit the service": "Modifier le service",
    "No service available message": "Vous n'avez aucun service.",
    "Service location": "Lieu du service",
    "Total capacity": "Capacité totale",
    "confirm service deletion title": "Confirmez la supression d'un service",
    "confirm service deletion message":
      "Vous êtes sur le point de supprimer le service suivant:",
    "Book a service": "Réserver un service",
    "You booked": "C'est réservé ! 😁",
    "The above equipment is available at the following spaces":
      "Le service ci-dessus est accessible dans les lieux suivants:",
    "The equipments from above are available in the following space":
      "Les services ci-dessus sont accessibles dans les lieux suivants:",
    "Create a custom service": "Créer un service personnalisé",
    "Service name": "Nom du service",
    "Service description": "Description du service",
    "Service icon": "Icône du service",
    "Standalone service": "Service à l'heure",
    "Example: sport class, lunch restauration, meeting room, etc":
      "Exemple: cours de sport, service de restauration, salle de réunion...",
    "AM/PM service": "Service à la demie journée",
    "mostly goes along with a desk reservation: extra screen, parking, etc":
      "Très souvent lié à votre confort au travail (deuxième écran, place de parking...)",
    "Create service": "Créer le service",
    "Delete service": "Effacer le service",
    "Create new service": "Créer un nouveau service",
    "Add new unit": "Ajouter une nouvelle unité",
    "Add a unit into this service": "Ajouter une unité à ce service",
    "Unit name": "Nom de l'unité",
    "Unit description": "Description de l'unité",
    "Delete unit": "Effacer l'unité",
    "Create unit": "Créer une unité",
    "Active days": "Disponibilités",
    "Default capacity": "Capacité par défaut",
    "No locations associated to this service":
      "Aucun espace n'est associé à ce service",
    "Add service location": "Situer ce service",
    "Link a service to a location": "Lié un espace à ce service",
    "Add keyframe": "Ajouter une keyframe",
    "Yes, delete service": "Oui, effacer ce service",
    "You're about to delete a whole service. This means that everything in this service will be deleted, along with all associated data. Are you sure ?":
      "Ce service va entièrement être supprimé. Toutes les données qui lui sont associées seront perdues. Confirmez vous la suppression de ce service ?",
    "Lock to location": "Attacher aux locations",
    "Users won't be able to book this service without attached locations":
      "Les utilisateurs ne pourront réserver ce service que s'ils ont réservé un espace associé",
    "Computer hardware": "Matériel Informatique , ...",
    "Second screen": "Deuxième écran, écran télévisé, ...",
    "A/V": "Matériel Audiovisuel, ...",
    Parking: "Parking, ...",
    "Accessible facilities": "Équipement d’accessibilité, ...",
    Bike: "Vélo, vélo électrique, ...",
    "Self service": "Cafétéria, ...",

    "Company Locations": "Espaces de l'entreprise",
    "Manage your company locations": "Gérer les espaces de votre entreprise",
    "Create location": "Créer un espace",
    "Update location": "Mettre à jour un espace",
    "location name": "Nom de l'espace",
    "location name already exists":
      "À moins que vous soyez un agent double, ce nom existe déjà 😬",
    "shortname is too long": "C'est trop long pour être une abréviation !",
    "shortname already exists": "Cette abréviation existe déjà",
    "Shortname (max 3 characters)": "Abréviation (max 3 characters)",
    "Capacity can't be 0": "La capacité ne peut être nulle",
    "Warning: changing capacity can affect stats":
      "Attention: modifier la capacité peut affecter les statistiques",
    "Bookable capacity can't be greater than capaciy":
      "La capacité réservable ne peut pas être plus grande que la capacité",
    "Bookable Capacity": "Capacité réservable",
    "Archive location": "Archiver l'espace",
    "archived locations won't be visible": `Les espaces archivés ne seront
    plus visibles pour les utilisateurs, mais apparaîtront toujours dans vos tableaux de bord.`,
    "Billing Portal": "Portail de facturation",
    Subscription: "Abonnement",
    "Your are subscribed to Offishall, and your status is ":
      "Vous êtes abonnés à Offishall, et votre status est : ",
    "Your estimated cost for this billing period is":
      "Coût estimé pour cette période : {price}",
    "After your free trial ends": `Après que votre essai gratuit se termine au {date}, cet abonnement continuera automatiquement.`,
    "You will be billed next on": `Prochaine facture le {date}`,
    "To retrieve invoices and update payment information.": `Pour voir vos factures et mettre à jour vos informations de paiement`,
    "Your subscription is not active. Please choose one of the plans below.":
      "Votre abonnement n'est pas activé. Merci de choisir un des plans ci dessous.",
    "Company Messages": "Messages d'entreprise",
    "Manage your company messages": "Gérer les messages d'entreprise",
    "Create message": "Créer un message",
    "Update message": "Mettre à jour le message",
    "Delete message": "Supprimer le message",
    "All (will be displayed in planning page)":
      "Tous (sera affiché dans la vue planning)",
    "Display in office": "Afficher dans l'espace",
    "description (max. 144 characters)": "description (max. 144 caractères)",
    "description is too long":
      "Il est important de communiquer, mais c'est un peu trop long là...",
    "message name": "nom du message",
    "message name already exists": "ce nom de message existe déjà",
    "Add users": "Ajouter des utilisateurs",
    "Configure user provisioning": "Configurer le provisionnement",
    "User provisioning (provider)":
      "Provisionnement des utilisateurs ({provider})",
    "Configure provider user provisioning with the following information:":
      "Configurez le provisionnement des utilisateurs {provider} avec les informations suivantes :",
    "Generate new token": "Générer un nouveau token",
    "Previously generated tokens are not shown here for security reasons.":
      "Les tokens précédents ne sont pas affichés pour des raisons de sécurité.",
    "Custom domain": "Domaine personnalisé",
    "SCIM provisioning": "Provisionnement SCIM",
    "Single sign-on (SAML):": "Authentification unique - SSO (SAML) :",
    "Single sign-on (OIDC):": "Authentification unique - SSO (OIDC) :",
    "Configure custom SAML SSO": "Configurer le SSO SAML personnalisé",
    "Configure custom OIDC SSO": "Configurer le SSO OIDC personnalisé",
    "Service Provider": "Fournisseur de service",
    "Identity Provider": "Fournisseur d'identité",
    "Callback URL": "URL de retour",
    'Must start with "-----BEGIN CERTIFICATE-----", and end with "-----END CERTIFICATE-----"': `Doit commencer par "-----BEGIN CERTIFICATE-----", et finir par "-----END CERTIFICATE-----"`,
    "Enable provisioning": "Activer le provisionnement",
    "Enable provisioning with provider":
      "Activer le provisionnement avec {provider}",
    "Enable OIDC SSO": "Activer le SSO OIDC",
    "You can only configure one provider per organization.":
      "Vous ne pouvez configurer qu'un seul fournisseur par organisation.",
    "Please disable provider provisioning to proceed.":
      "Merci de désactiver le provisionnement {provider} pour continuer.",
    "Save custom domain": "Enregistrer le domaine personnalisé",
    "Save SAML configuration": "Enregistrer la configuration SAML",
    "Save OIDC configuration": "Enregistrer la configuration OIDC",
    manager: "manager",
    "base url": "URL de base",
    certificate: "Certificat",
    "entity ID": "Identifiant de l'entité",
    "Single sign-on URL": "URL d'authentification unique (SSO)",
    "ACS URL": "URL ACS",
    "new line": "nouvelle ligne",
    "Add users from CSV file": "Ajouter des utilisateurs depuis un fichier CSV",
    "Manage your users accounts": "Gérer les comptes utilisateur",
    "Update user": "Mettre à jour l'utilisateur",
    "User is not registered.": "L'utilisateur n'est pas enregistré.",
    License: "License",
    PendingFulfillmentStart: "En attente",
    Subscribed: "Activée",
    Unsubscribed: "Résiliée",
    Suspended: "Suspendue",
    "manager email": "Email du manager",
    admin: "administrateur",
    "disable user": "désactiver l'utilisateur",
    "Users successfully added. They will soon receive an email with a registration link.":
      "Les utilisateurs ont été correctement ajoutés. Ils recevront bientôt un email avec un lien d'enregistrement.",
    "invalid user":
      "Nous avons trouvé {count} utilisateur invalide dans vos données. Merci de vérifiez que les adresses emails ont été écrites correctement.",
    "invalid users":
      "Nous avons trouvé {count} utilisateurs invalides dans vos données. Merci de vérifiez que les adresses emails ont été écrites correctement.",
    "Example of invalid user :": "Exemple d'utilisateur invalide : ",
    Previous: "Précédent",
    Next: "Suivant",
    Import: "Importer",
    "You're about to import user": "Vous allez importer {count} utilisateur.",
    "You're about to import users": "Vous allez importer {count} utilisateurs.",
    "user have a manager": "{managers} utilisateur a un manager.",
    "users have a manager": "{managers} utilisateurs ont un manager.",
    "user have no manager": "{nomanager} n'a pas de manager.",
    "users have no manager": "{nomanager} n'ont pas de manager.",
    "managers-or-not":
      "{managers} utilisateurs ont un manager. {nomanager} n'ont pas de manager",
    "If a user with the same adress already exists in our database, it will be skipped.":
      "Si un utilisateur avec la même adresse email existe déjà dans notre base de données, il sera passé.",
    "Upload CSV File": "Uploader un fichier CSV",
    "We will guide you step by step.": "Nous vous guiderons pas à pas",
    "CSV File is empty": "Le fichier CSV est vide.",
    "Cannot read CSV File": "Impossible de lire le fichier CSV.",
    "Does it have column names ?":
      "Le fichier CSV contient-il le nom des colonnes en première ligne ?",
    "this is an extract": `Voici un extrait (premières 10 lignes) du fichier CSV. Est-ce que la première ligne ressemble aux autres, ou plutôt à des noms de colonnes ?`,
    Upload: "Uploader",
    "Yes, first line is column names":
      "Oui, la première ligne est le nom des colonnes",
    "No, first line is just like the others":
      "Non, la première ligne est comme les autres",
    "Select email column": "Sélectionner la colonne 'email'",
    "Please click on the column corresponding to the user email address, and click on next.":
      "Cliquez sur la colonne correspondant à l'email utilisateur, et cliquez sur suivant",
    "Select manager column": "Sélectionner la colonne 'manager'",
    "Please click on the column corresponding to the user's manager email address, and click on next. If no such column, you can directly click on next.":
      "Cliquez sur la colonne correspondant à l'email du manager, and cliquez sur suivant. Si aucune colonne ne contient l'email du manager, cliquez sur suivant directement.",
    "Messages center": "Centre de messages",
    Labels: "Labels",
    "Can't unadmin yourself":
      "Vous ne pouvez pas vous désadministrer vous même.",
    "Does the first row of your csv contain the column names?":
      "La première ligne de votre csv contient-elle les noms des colonnes ?",

    // Services
    "one person has booked this service": "Une personne a réservé ce service",
    "many have booked this service": "{count} personnes ont réservé ce service",
    "Apparently, nobody has booked this service":
      "Visiblement, personne n'a réservé ce service",
    "available services": "Services disponibles",
    "at work": "Au bureau",
    "Modify previous service bookings": "Changement des présences ",

    // Users
    "Find users": "Recherche d'utilisateurs",
    "View more results": "Voir plus de résultats",
    "View more users": "Voir plus d'utilisateurs",
    "No more result": "Pas d'autre résultat",
    "No more user": "Pas d'autre utilisateur",
    "No result": "Aucun résultat",
    "Update Display Name": "Changer le nom affiché",
    "Display Name": "Nom Affiché",
    "Update Manager": "Mettre à jour le manager",
    "Search and select an existing user to be the manager":
      "Sélectionnez le nouveau manager",
    "Selected manager": "Manager sélectionné",
    "Set as manager": "Choisir {name} comme manager",
    "Search again": "Nouvelle recherche",
    "Remove current manager": "Supprimer le manager actuel",
    "Remove manager": "Supprimer le manager",
    "Update manager": "Modifier le manager",
    "Add favorites": "Ajouter aux favoris",
    "Remove favorites": "Retirer des favoris",
    "Add one favorite": "Ajouter un favori",
    "Already in favorites":
      "Cet élément est déjà dans vos favoris, vous allez le retirer.",
    "You are about to add": "Vous êtes sûr le point d'ajouter :",
    "favorite added confirmation": "Votre favoris a bien été ajouté ! 👌",
    "Your document has been successfully imported":
      "Votre document a bien été importé",

    // Users : admin
    users: "utilisateurs",
    User: "Utilisateur",
    State: "État",
    Status: "Statut",
    Registration: "Inscription",
    "Active(status)": "Actif",
    "Many active": "Actifs",
    "Not Active(status)": "Non-actif",
    "Many Not Active(status)": "Non-actifs",
    Both: "Indifférent",
    Disabled: "Désactivé",
    Registered: "Inscrit",
    "Many registered": "Inscrits",
    "Not Registered": "Non-Inscrit",
    "Many Not Registered": "Non-Inscrits",
    "User is registered": "Utilisateur inscrit",
    "User is not registered": "Utilisateur non inscrit",
    "Registration email sent successfully":
      "Email d'inscription envoyé avec succcès",
    "Resend registration email": "Renvoyer l'email d'inscription",
    "Registration emails sent successfully":
      "Emails d'inscription envoyés avec succcès",
    "Resend registration emails": "Renvoyer les emails d'inscription",
    "define manager": "Définir un manager",
    "admin dashboard": "Tableau de bord de l'administrateur",
    "new collaborator": "Ajouter un collaborateur",
    "update user settings": "Mettre à jour les informations du collaborateur",

    "User is active": "Utilisateur actif",
    Active: "Activer",
    Disable: "Désactiver",
    "active users": "utilisateurs actifs",
    "registered users": "utilisateurs inscrits",
    "disabled users": "utilisateurs désactivés",
    "users in total": "utilisateurs au total",
    "Registration status": "Inscriptions",
    "All users are registered": "Youpi, tous les utilisateurs sont inscrits !",
    "are still not registered": "ne sont toujours pas inscrits",
    "You can see them by clicking on the Not registered option below, while keeping the search box empty":
      "Vous pouvez les voir en cliquant sur l'option «Non enregistré» ci-après, en gardant la barre de recherche vide",
    "To resend a new registration email to them all at once, click on the button below":
      "Pour envoyer un nouvel email d'inscription à tous ces utilisateurs non inscrits, cliquez sur ce bouton :",
    "This can be empty": "Cette recherche peut rester vide",
    "resend activation email invitation":
      "{name} n'a pas complété le processus d'enregistrement.",

    "User is disabled": "Utilisateur désactivé",
    "User is an admin": "L'utilisateur est un admin",
    "Revoke admin privilege": "Supprimer le role d'admin",
    "User is not an admin": "L'utilisateur n'est pas admin",
    "Make admin": "Donner le role d'admin à l'utilisateur",
    "Delete User": "Supprimer l'utilisateur",
    "delete user confirm": `Êtes-vous sûr de vouloir supprimer cet utilisateur et toutes ses données associées ? Cette action est irréversible.`,
    "User has been deleted": "L'utilisateur a été supprimé",
    "Yes, delete user": "Oui, supprimer l'utilisateur",
    "Export all results as CSV": "Exporter tous les résultats en CSV",
    "Export the list as CSV": "Exporter la liste en CSV",
    "Manager email": "Email du manager",
    "New user email": "Email du nouvel utilisateur",

    // USERS DASHBOARD
    "Users Dashboard": "Stats Utilisateurs",

    "Design System": "Design System",

    // COMPANY SETTINGS
    "Company Settings": "Paramètres Entreprise",
    "Manage your company settings": "Gérer les paramètres de l'entreprise",
    "company-settings-locale-title": "Langue par défaut de l'application",
    "company-settings-locale-description":
      "Langue par défaut de l'application. Chaque utilisateur peut modifier dans ses paramètres la langue de l'application.",
    "company-setting-group-display": "Affichage",
    "company-setting-group-default": "Autres paramètres",
    "company-setting-group-notifications": "Notifications",
    "openEveryoneGroup-name": "Ouvrir le groupe #{company} en haut du planning",
    "openEveryoneGroup-description":
      "Dans le menu des groupes, afficher par défaut le groupe avec tous les utilisateurs de votre entreprise en haut de la liste.",
    "hideEveryoneGroup-name": "Masquer le groupe #{company}",
    "hideEveryoneGroup-description":
      "Dans la liste des groupes, ne pas afficher le groupe avec tous les utilisateurs",
    "bypassDisplayName-name":
      "Utiliser l’adresse email comme nom affiché sur le planning",
    "bypassDisplayName-description":
      "Empêcher les utilisateurs de changer leur nom affiché",
    "enableUserMutationForManagers-name":
      "Autoriser les managers à modifier les plannings d'équipe",
    "enableUserMutationForManagers-description":
      "Les managers peuvent modifier les plannings des utilisateurs de leurs équipes",
    "limitSlotInput-name": "Limiter la saisie",
    "limitSlotInput-description":
      "Restreindre le délai de déclaration de présence des utilisateurs au-delà d'un certain nombre de semaines:",
    "weeklyReminder-name": "Choisir un jour de rappel hebdomadaire",
    "weeklyReminder-description":
      "Les utilisateurs n'ayant pas saisi leurs données de présence pour la semaine à venir seront notifiés ce jour: ",
    "weeklyReminder-value-Monday": "Lundi",
    "weeklyReminder-value-Tuesday": "Mardi",
    "weeklyReminder-value-Wednesday": "Mercredi",
    "weeklyReminder-value-Thursday": "Jeudi",
    "weeklyReminder-value-Friday": "Vendredi",
    "weeklyReminder-value-Saturday": "Samedi",
    "weeklyReminder-value-Sunday": "Dimanche",
    "userCanCreateGroups-name": "Création de groupe ouverte à tous",
    "userCanCreateGroups-description":
      "Tous les utilisateurs peuvent créer un groupe",
    "blockPastSlots-name": "Bloquer les changements des données du passé",
    "blockPastSlots-description":
      "Les utilisateurs ne peuvent pas modifier leur donnée de présence des semaines passées",
    "company-setting-anchor--notifications": "Notifications",
    "company-setting-anchor--display": "Affichage",
    "company-setting-anchor--default": "Autres",
    "company-setting-anchor--documents": "Documents",

    // ADMIN/COMPANY SETTINGS/COMPANY FILES
    "corporate documents": "Documents d'entreprise",

    "company_file--add_file": "Ajouter un document",
    "company_file--input_title": "Titre du document",
    "company_file--input_title_placeholder": "Donnez un titre à votre fichier",
    "company_file--deleted": "Votre fichier a bien été effacé",
    "company_file--uploaded": "Votre fichier a bien été sauvegardé",
    "company_file--confirm_deletion_title": "Suppression d'un fichier",
    "company_file--confirm_deletion_message":
      "Vous êtes sur le point d'effacer le fichier suivant:",
    "company_file--empty_file_list":
      "Aucun document d'entreprise n'a été partagé pour le moment.",

    // COMPANY LABELS
    "Company Labels": "Labels Personnalisés",
    "Choose working mode associated with label":
      "Choisissez le mode de travail associé à votre label",
    "Add custom label for your company":
      "Ajouter le label personnalisé pour votre entreprise",
    "edit label": "Modifier mes labels",

    // ADMIN/HYBRID-POLICY
    "Hybrid work policy": "Politique du travail hybride",
    "company-hybrid-rule-empty":
      "Vous n’avez pas encore défini de politique du travail hybride.",
    "user-hybrid-rule-empty":
      "Votre entreprise n'a pas encore déclaré de règle de travail hybride personnalisée.",
    "hybrid-rule--upsert-success": "Règle ajoutée/modifiée avec succès.",
    "hybrid-rule--delete-success": "Règle effacée avec succès.",
    "hybrid-rule-form--select-period-first":
      "Vous devez d'abord choisir une période.",
    "hybrid-rule-form-error--max-too-high": "valeur trop élevée",
    "hybrid-rule-label--period": "Période",
    "hybrid-rule-label--target-name": "appliquer à",
    "hybrid-rule-label--group-toggle": "règle de groupe",
    "hybrid-rule-label--applies-to-manager-toggle":
      "appliquer (aussi) au manager",
    "hybrid-rule-placeholder--group-name": "Nom unique de la règle",
    "hybrid-rule-label--max-slots": "Jours de télétravail maximum",
    "hybrid-rule-label--restricted-days": "Jours de la semaine à favoriser",
    "hybrid-rule-add-edit--manager": "Ajouter/Modifier une règle d'équipe",
    "hybrid-rule-add-edit--user": "Ajouter/Modifier une règle utilisateur",
    "hybrid-rule-organisation-chart-title":
      "Choisissez un manager ou un utilisateur",
    "hybrid-rule-form--title-edit": "Modifier une règle de travail hybride",
    "hybrid-rule-form--title-add":
      "Créer une nouvelle règle de travail hybride",
    "hybrid-rule-form--targets-button":
      "Modifier les {nbUsers} utilisateur(s) concerné(s)",
    "hybrid-rule-list--targets-recap":
      "{nbTeams} équipe(s) et {nbUsers} utilisateur(s)",
    "target-picker-recap":
      "{nbTeams} équipe(s) et {nbUsers} utilisateur(s) sélectionné(s)",
    "select-all-group-members": "Sélectionner tous les membres",
    "Compliance with hybrid rules": "Respect des règles hybrides",
    "See score": "Voir score",
    respected: "Respectés",
    "not respected": "Non respectés",
    rule: "Règle",
    programmed: "Programmés",
    "week details": "Détails de la semaine",
    remind: "Relancer",
    "Details of hybrid compliance": "Détails du respect des règles hybrides",
    "view scores": "Voir scores",
    "default rule": "Règle d'entreprise",
    "Maximum in home office": "Maximum en télétravail",
    "Days of the week to be favored": "Jours de la semaine à favoriser",
    "grouped rules": "Règles groupées",
    "user rules": "Règles d'utilisateur",
    "team rules": "Règles d'équipe",
    "people do not respect": "personnes ne respectent pas",
    "Hybrid rule": "Règle hybride",
    "myteam-scores": "Scores de mon équipe",
    "hybrid-rule-origin": "Origine de la règle",
    "are not allowed remotely": "ne sont pas autorisés à distance",
    "is not allowed remotely": "n'est pas autorisé à distance",
    "user-hybrid-rule-current-score--title": "Jours en télétravail restants",
    "user added to group": "Utilisateur ajouté au groupe",
    "user already in group": "Utilisateur déjà ajouté au groupe",
    "Preferred day for home office": "Jour à favoriser pour le télétravail",
    "Preferred days for home office": "Jours à favoriser pour le télétravail",
    "remaining day of home office this week":
      "jour en télétravail restant cette semaine",
    "remaining days of home office this week":
      "jours en télétravail restants cette semaine",
    "You have no more days available for home office this week":
      "Vous n'avez plus de jours disponibles en télétravail pour cette semaine",
    "nb day available in home office each month":
      "Chaque mois, vous avez {nb} jour disponible en télétravail.",
    "nb days available in home office each month":
      "Chaque mois, vous avez {nb} jours disponible en télétravail.",

    // USER SETTINGS
    "user-setting-group-display": "Affichage",
    "user-setting-group-notifications": "Notifications",
    "user-setting-group-notifications--description":
      "Gérer la façon dont vous préférez être notifié",
    "user-setting-group-notifications--tooltip-nudge":
      "Vous pouvez être directement notifié par un collègue.",
    "user-setting-group-notifications--tooltip-weeklyReminder":
      "Vous pouvez recevoir un rappel automatique d'Offishall pour remplir votre planning.",
    "user-setting-group-notifications--tooltip-teamDayInvitation":
      "Vous pouvez être notifié lorsque votre manager créé une journée d'équipe.",
    "user-setting-group-notifications--tooltip-userSubscriptions":
      "Vous pouvez être notifié lorsqu'un collègue auquel vous avez souscrit modifie son planning.",
    "user-settings-subscriptions-empty-message":
      "Vous n'avez souscrit à aucun utilisateur pour le moment.",
    Subscribe: "Souscrire",
    "user-setting-group-default": "Autres paramètres",
    "user-setting-group-personal": "Informations personnelles",
    "userShowWeekends-name": "Afficher les weekends",
    "userShowWeekends-description":
      "Sur la page planning, afficher les samedis et dimanches",
    "user guide": "Guide utilisateur",
    cgu: "Conditions générales d'utilisation",
    "simple cgu": "CGU",
    "privacy policy": "Politique de confidentialité",
    "manage my team": "Modifier l'équipe",
    "manage user team": "Modifier l'équipe de {user}",
    "edit personal information": "Modifier mes informations personnelles",
    "first last name": "Nom Prénom",
    "personal information": "Informations personnelles",
    "notifications & display": "Notifications & affichage",
    "news & feedback": "Nouveautés et feedback",
    "report a claim": "Déclarer un sinistre",
    "user-setting-planning-template--title": "Semaine type",
    "user-setting-planning-template--go_to_link": "Modifier votre semaine type",
    "user-subscription-dialog--title": "Modifier vos souscriptions",
    "nudge explanation":
      "Un collègue vous informe que votre planning n’est pas rempli",
    "weekly-reminder-explanation":
      "Offishall vous informe automatiquement que votre planning n’est pas rempli",
    "teamDayInvitation explanation": "Un manager crée une journée d’équipe",
    "userSubscriptions explanation":
      "Un collègue auquel vous avez souscrit modifie son planning",
    "my account": "Mon compte",
    "edit my user name": "Modifier mon nom d'utilisateur",
    "edit my password": "Modifier mon mot de passe",

    // JOINING GROUP
    "Join group": "Rejoindre un groupe",

    // USER PAGE
    "user's planning": "Planning de {name}",
    "user is disabled": "L'utilisateur est désactivé",
    "user is active": "L'utilisateur est activé",
    "user is admin": "l'utilisateur est administrateur",
    "user is not admin": "l'utilisateur n'est pas administrateur",
    "admin selected user actions": "Gestion de l'utilisateur sélectionné",
    "admin selected user status": "Status de l'utilisateur sélectionné",
    "admin selected user permissions":
      "En tant qu'administrateur vous pouvez modifier le planning et le statut de {name}. 👇",
    "manager selected user permissions":
      "En tant que manager de {name} vous pouvez modifier son planning.",
    "search manager users": "Cherchez un manager parmi les utilisateurs",
    "user team": "Equipe de {name}",
    "no user": "Aucun utilisateur n'est présent cette semaine",
    "no user simple": "Aucun utilisateur n'est présent.",
    "user-subscription--on": "alertes activées",
    "user-subscription--off": "alertes désactivées",
    "add user subscription confirmation":
      "Un collègue a été ajouté à votre liste de souscription.",
    "remove user subscription confirmation":
      "Un collègue a été retiré de votre liste de souscription.",
    "activate alerts": "Activer les alertes",
    "disable alerts": "Désactiver les alertes",
    "add to favorites": "Ajouter aux favoris",
    "remove from favorites": "Retirer des favoris",

    // LOCATIONS
    "search spaces": "Chercher un espace",
    "working spaces": "Espaces de travail",
    "selected locations": "Zones et espaces sélectionnés",
    "Your organisation allows for desk booking until the":
      "Votre organisation autorise la réservation de bureau jusqu'au",

    // EVENTS PAGE
    Events: "Evénements",
    "create event": "Créer un événement",
    "create the event": "Créer l'événement",
    "edit event": "Modifier un événement",
    "edit the event": "Modifier l'événement",
    "delete this event": "Supprimer l'événement",
    "event name": "Nom de l'événement",
    at: "à",
    ongoing: "en cours",
    "event description": "Description de l'événement",
    "event location": "Lieu de l'événement",
    "example event location": "23 rue des Lilas",
    "event capacity": "Capacité de l'événement",
    "event booking message": "Il reste encore {seats} places disponibles.",
    "add event capacity": "Ajouter une capacité ",
    "event guests": "Liste des invités",
    "event deadline": "Date limite d'inscription",
    "event deadline explanation":
      "Date de réponse attendue à propos de la présence",
    "event deadline message": "Vous avez jusqu'au {date} pour vous inscrire.",
    "delete event confirmation message":
      "Êtes-vous sur de vouloir supprimer cet événement ?",
    "attending event": "Je participe",
    "not attending event": "Je ne participe pas",
    "share event": "Partager",
    "event media": "Image de l'événement",
    "upload media title": "Ajouter une photo/image",
    "upload media after title": "ou glisser-déposer",
    "event link copied":
      "Le lien de l’événement a été collé dans le presse-papier.",
    "whole day event": "Toute la journée ",
    "add event description": "Ajouter une description",
    "event privacy": "Evénement privé",
    "event privacy explanation":
      "Evenement partagé avec les invités uniquement ou par l'ensemble de l'organisation",
    "ending date": "Date de fin",
    "already attending": "Je participe déjà",
    "send attendings reminder": "Envoyer un rappel à tous",
    "attending users": "Participants",
    "view attending users": "Voir les participants",
    attending: "Participe",
    attendings: "Participants",
    pending: "En attente",
    "not attending": "Ne participe pas",
    "not-attending": "Ne participe pas",
    "no event create yet": "Vous n'avez pas d'événements Offishall.",
    details: "Détails",
    "remaining places": "Places restantes",
    "search groups or users": "Rechercher un groupe ou un utilisateur",
    "start search/select event groups/users":
      "Sélectionner ou rechercher des groupes/utilisateurs dans la liste du dessus.",
    Guests: "Invités",
    Guest: "Invité",
    "received on": "reçu le",
    "you have been invited": "Vous avez été invité à un événement!  🎉",
    "I am going": "J'y vais !",
    "I am not going": "Je n'y vais pas",
    "not participating in this event": "Vous ne participez pas à cet événement",
    Today: "Aujourd'hui",
    "This week": "Cette semaine",
    "Next week": "La semaine prochaine",
    Past: "Passés",
    Invited: "Invités",
    "your status has been updated for this event":
      "Votre statut a été mis à jour pour cet événement",
    "Starting date": "Date de début",
    "Starting time": "Heure de début",
    "Ending date": "Date de fin",
    "Ending time": "Heure de fin",
    "show groups": "afficher les groupes",
    "long attending event": "Je participe à cet événement",
    "long not attending event": "Je ne participe pas à cet événement",
    "your participation": "Votre participation",
    "created by": "créé par",
    "you have no upcoming events": "Vous n'avez pas d'événements à venir.",
    "event address": "Adresse de l’événement",
    "title of the meeting": "Titre de la réunion",
    "event name example": "Afterwork, team building, fête, ...",
    "meeting title example": "ex : réunion d’équipe",
    "meeting location": "Lieu de la réunion",
    "already booked": "déjà réservé",

    // NEW UX TRANSLATIONS
    "Nobody's here this week": "Personne n'est là cette semaine",
    "No one in this group": "Personne dans ce groupe",
    "No one else in this group": "Personne d'autre dans ce groupe",
    "You have not selected any user to be part of your favorite ones.":
      "Personne n'est votre petit chouchou",
    "Groups, Locations, People": "Personnes, Groupes, Espaces",
    Spaces: "Espaces",
    "Choose a date": "Choisissez une date",
    "Search for a space": "Chercher un espace",
    "no user at": "aucun utilisateur à {location} du {from} au {to}",
    "Your working spaces": "Vos espaces de travail",
    "Go to space planning": "Aller au planning de l'espace de travail",
    "Full capacity is": "La capacité totale est de",
    "no workspace": "Aucun espace de travail",
    "but temporary reduced to": "mais est temporairement réduite à",
    "This space is not available": "Cet espace n'est pas disponible",
    "This space is available to reservation":
      "Cet espace est disponible pour réservation",
    "Allocated space": "Espace attribué",
    "Allocated space to":
      "Espace attribué à {groups} groupes et {users} utilisateurs",
    "Allocated space update": "Modifier la liste",
    Inbox: "Boîte de réception",
    Archived: "Archives",
    archived: "archivé(e)",
    "Delete confirmation": "Confirmation de suppression",
    "delete message": "Supprimer le message",
    "Are you sure you want to delete this message?":
      "Êtes-vous sûr de vouloir supprimer définitivement ce message ?",
    "Move to inbox": "Restaurer",
    "Move to archives": "Archiver",
    "Favorite users": "Utilisateurs favoris",
    "no favorites at this time": "Vous n'avez aucun favoris pour le moment.",
    "delete zone and its content":
      "Attention, vous êtes sur le point d'effacer {name} et son contenu.",
    "zones to be deleted": "Contenu qui va également être supprimé :",
    "spaces to be deleted": "espaces à effacer",
    "asking confirmation": "En êtes-vous certain ? 🧐",
    "search for zone or space": "Rechercher une zone ou un espace",
    "you have no favorites users yet - add to favorites":
      "Vous n'avez pas d'utilisateurs favoris pour le moment. N'hésitez pas à en ajouter pour les retrouver plus facilement.",

    "Write a message": "Écrire un message",
    "Edit a message": "Écrire un message",
    subject: "Sujet",
    body: "Contenu",
    operations: "Opérations",

    "empty destination": "Veuillez sélectionner un destinataire",
    "empty subject": "Veuillez saisir un sujet",
    "empty body": "Veuillez saisir un contenu",
    "Update a message": "Modifier un message",
    "Add a message": "Ajouter un message",
    "Search for recipients": "Chercher des destinataires",
    "send to everyone": "Envoyer à tout le monde",
    "Message subject": "Sujet du message",
    "Message body": "Contenu du message",
    Recipients: "Destinataires",
    Schedule: "Planifier",
    "Now!": "Maintenant !",
    Later: "Plus tard",
    Recurrent: "Récurrent",
    "The message will be sent right away !":
      "Du tac au tac, votre message est envoyé",
    send: "Envoyer",
    update: "Modifier",
    "The message will be sent on:": "Le message sera envoyé le :",
    "Create scheduled message": "Créer un message planifié",
    "Create recurrent message": "Créer un message récurrent",
    until: "jusqu'à",
    Frequency: "Fréquence",
    Once: "Une seule fois",
    Weekly: "Hebdomadaire",
    Daily: "Quotidien",
    Monthly: "Mensuel",
    "The message will be sent when you choose to.":
      "Le message sera envoyé quand vous le choisissez.",
    "the message will be sent from to":
      "le message sera envoyé du {from} au {to}",

    "Add a zone": "Ajouter une zone",
    "Add a working space": "Ajouter un espace",
    "Update a zone": "Modifier une zone",
    "parent zone": "Zone parent",
    "Zone name": "Nom de la zone",
    "no zone": "aucune zone",
    "no parent zone": "aucune zone parent",
    "Update a working space": "Modifier un espace",
    "If you remove this space from its parent zone, it will be moved up to the root with its content.":
      "Si vous retirez cet espace de sa zone parente, il sera déplacé vers la racine avec son contenu.",

    "zone unchanged": "la zone n'a pas été modifiée",
    "name too short": "le nom est trop court",
    "space unchanged": "l'espace de travail n'a pas été modifié",
    "negative capacity": "la capacité est négative",
    "bookable out of range": "la capacité est hors limite",
    "Full capacity": "Capacité totale",
    "Temporary reduce capacity": "Réduction temporaire de la capacité",

    "Space name": "Nom du lieu",

    "including yourself": "Vous-même inclu",

    "Try out our new UX !": "Essayez notre nouvelle UX !",
    "try new ux content":
      "Vous allez essayer notre nouvelle UX ! Vous pouvez toujours revenir à cette version via vos paramètres utilisateurs.",
    "Yes, let s give it a try !": "Oui, je veux tenter !",
    "No, stay on this ux": "Non, rester sur cette ux",

    messaging: "Messagerie",
    "messages send": "Messages envoyés",
    "no message send yet": "Vous n'avez pas encore envoyé de message",

    "create new": "Créer un nouveau",

    // NEW EMPLOYEES
    "recap title": "Vous êtes prêt",
    "recap info":
      "Veuillez vérifier les informations ci-dessous. Si elles sont correctes, cliquez sur le bouton d'importation pour lancer l'importation. Cela peut prendre plusieurs minutes.",
    "select manager column title": "Sélectionner la colonne du manager",
    "select manager column info":
      "Veuillez cliquer sur la colonne correspondant à l'adresse e-mail du manager de l'utilisateur, puis cliquez sur suivant.",
    "if this column does not exist, you can click directly on next":
      "Si cette colonne n'existe pas, vous pouvez directement cliquer sur suivant.",
    "select email column title":
      "Sélectionnez la colonne de courrier électronique",
    "select email column info":
      "Veuillez cliquer sur la colonne correspondant à l'adresse électronique de l'utilisateur, puis cliquez sur suivant.",
    "first line checker title": "Y a-t-il des noms de colonnes ?",
    "first line checker info": "Voici un extrait",
    "file upload title": "Télécharger le fichier CSV",
    "file upload info": "Nous vous guiderons pas à pas.",
    "CSV formatted error": "Le CSV n'est pas bien formaté(ligne #{count})",
    "manual addition": "Ajout manuel",
    okta: "Okta",
    CSV: "CSV",
    "add new user": "Ajouter un autre utilisateur",
    "use comma separator instead of semicolon":
      "Utilisez un séparateur virgule au lieu d'un point-virgule",
    "chosen separator : comma (,)": "Séparateur choisi : virgule (,)",
    "chosen separator : semicolon (;)": "Séparateur choisi : point-virgule (;)",
    "Use this email": "Utiliser cet email",
    "Pick this perfect match": "Sélectionner l'utilisateur correspondant",
    "what the csv must contain":
      "Votre fichier doit comporter uniquement et obligatoirement ces deux colonnes “email de l’utilisateur” et “email du manager”.",
    "csv can only contain 2 columns":
      "Le csv ne peut contenir que 2 colonnes maximum.",
    "drop a csv file or browse your documents":
      "Déposez un fichier .csv ou Parcourez vos documents.",
    "download a CSV template": "Téléchargez un modèle CSV",
    "change my file": "Changer mon fichier",
    "user email": "Email de l’utilisateur",
    "choose separator": "Choisissez le séparateur adapté à votre csv",
    "check if each value corresponds to a data type":
      "Vérifiez si chaque chaque valeur correspond au bon type de donnée (e-mail, e-mail manager)",
    "data requested by offishall": "Données demandées par Offishall",
    "file data": "Donnée du fichier",
    data: "Donnée",
    "Name / Last Name": "Prénom / Nom",

    // USERS DASHBOARD
    "office days": "Bureau jours",
    "home days": "Télétravail jours",
    "out of contract days": "Hors contrat jours",
    "else days": "Autres jours",
    "off days": "Absent jours",

    // INTEGRATIONS
    Integrations: "Intégrations",
    "writeSlotsEvents-toggle":
      "Écrire les modes de travail dans votre calendrier",
    "writeEvents-toggle": "Écrire les événements dans votre calendrier",
    "link offishall outlook":
      "Offishall est lié à votre compte Microsoft Outlook.",
    "link offishall google": "Offishall est lié à votre compte Google.",
    "revoke access": "Révoquer l'accès",
    "cannot setup integration": "Impossible de configurer l'intégration {name}",
    Link: "ce lien",
    "Client ID": "Client ID",
    "Client Secret": "Client Secret",
    "Issuer URL": "Issuer URL",
    "Refresh Token": "Refresh Token",
    "Token Endpoint": "Token Endpoint",
    "Authorization Endpoint": "Authorization Endpoint",
    "update values": "Mettre à jour les valeurs",
    "connect to": "Connecté à",
    // GOOGLE
    "Google Calendar": "Google Calendrier",
    "google improve offishall experience":
      "Afficher et partager vos statuts de présence directement depuis votre calendrier Google",
    "refresh access": "Rafraîchir l'accès",
    "autorize offishall access google account":
      "Autoriser Offishall à accéder à votre compte Google",
    // Outlook
    "Outlook Calendar": "Outlook Calendrier",
    "outlook improve offishall experience":
      "Afficher et partager vos statuts de présence directement depuis votre calendrier Outlook",
    "autorize offishall access outlook account":
      "Autoriser Offishall à accéder à votre compte Outlook",
    // SLACK
    "link offishall slack": "Offishall est lié à votre compte Slack.",
    "slack improve offishall experience":
      "Afficher votre présence dans Slack pour vos collègues (emoji à côté de votre nom)",
    "authorize offishall access slack account":
      "Autoriser Offishall à accéder à votre compte Slack",
    // KELIO
    "offishall linked to kelio": "Offishall est lié à votre compte Kelio.",
    "offishall not linked to kelio":
      "Veuillez nous contacter pour établir la connexion entre Offishall et votre compte Kelio.",
    "find documentation": "Vous pouvez trouver la documentation",
    "know how to retrieve the needed information":
      "pour savoir comment récupérer l'information nécessaire.",
    // LUCCA
    "offishall linked to lucca": "Offishall est lié à votre compte Lucca.",
    "lucca instance name": "Le nom de votre instance Lucca est {extranalId}.",
    "offishall not linked to lucca":
      "Offishall peut être connecté à votre compte Lucca pour récupérer automatiquement les jours de congés des utilisateurs.",
    "connect to lucca": "Connecter à Lucca",
    "company instance name at Lucca":
      "Le nom d'instance de votre entreprise chez Lucca",
    // MICROSOFT
    "offishall linked to microsoft":
      "Offishall est lié à votre compte d'entreprise Microsoft.",
    "your microsoft tenant id":
      "Votre identifiant client Microsoft est {externalId}",
    "offishall not linked to microsoft":
      "Offishall peut être connecté à votre compte d'entreprise Microsoft pour améliorer l'expérience Offishall (lecture et écriture des événements du calendrier, par exemple).",
    "authorize offishall access microsoft":
      "Autoriser Offishall à accéder à votre compte d'entreprise Microsoft",
    "error while connecting to Microsoft":
      "Erreur lors de la connexion à Microsoft",
    // MICROSOFT TEAMS
    "offishall linked to microsoft teams":
      "Offishall est lié à votre organisation Microsoft Teams.",
    "offishall not linked to microsoft teams":
      "La fonction planning d'Offishall peut être utilisée dans l'application Microsoft Teams.",
    "authorize offishall access microsoft teams":
      "Autoriser Offishall à accéder à votre organisation Microsoft Teams.",
    "private chat members": "Membres du chat privé",
    "team members": "Membres de l'équipe",
    // WORKDAY
    "offishall linked to workday": "Offishall est lié à votre compte Workday.",
    "your workday tenant id":
      "Votre identifiant client Workday est {externalId}.",
    "offishall not linked to workday":
      "Offishall peut être connecté à votre compte Wordkay pour récupérer automatiquement les jours de congés des utilisateurs.",
    "connect to workday": "Connecter à Workday",
    "Workday REST API Endpoint": "Workday REST API Endpoint",

    // FLOOR PLAN
    "Floor plans": "Plans des bureaux",
    "floor-plans-short-title": "Plans",
    "upload floorPlan media title": "Ajouter une image",
    "replace floorPlan media title": "Remplacer l'image",
    "your floor plan image has been saved":
      "L'image de l'étage a été enregistrée",
    "your floor plan image has been deleted":
      "L'image de l'étage a été supprimée",
    "your floor areas have been saved": "Vos surfaces ont été enregistrées",
    "ask-delete-areas-message":
      "Voulez vous supprimer les surfaces dessinées sur le plan ?",
    Keep: "Conserver",
    "select location on left panel":
      "Sélectionnez un espace sur le panneau de gauche pour afficher son plan d'étage.",
    "select location on left panel, or add floor plan":
      "Sélectionnez un espace sur le panneau de gauche pour afficher son plan d'étage, ou appuyez sur + pour ajouter un nouveau plan d'étage.",
    "floor plan only works within zone":
      "Vous devez créer au moins une zone afin de pouvoir offrir une expérience de plan à vos équipes. Créez en une facilement dans l'administration des espaces/zones.",
    "go create some zone": "Voir la page d'administration",
    "delete-plan": "supprimer le plan",
    "delete-plan-title": "Supprimer le plan",
    "delete-plan-confirm-1":
      "Attention, vous êtes sur le point d'effacer le plan {name} et son contenu.",
    "delete-plan-confirm-2":
      "Contenu de la zone {name} qui va également être supprimé :",
    "delete-plan-confirm-simple":
      "Vous êtes sur le point d'effacer le plan {name}.",
    "no floor plan": "Aucun plan",
    "cant-add-floorPlan-with-children":
      "Impossible d'ajouter un plan d'étage car il y a déjà un plan attaché à un sous-espace. Supprimez le plan d'étage du sous espace pour pouvoir en ajouter un ici.",
    "see floor plan": "Voir le plan",
    "location has no area": "{name} n'apparait sur aucun plan.",

    // NOTIFICATIONS
    Notifications: "Notifications",
    "delete notification": "Supprimer la notification",
    "Are you sure you want to delete this notification?":
      "Êtes-vous sûr de vouloir supprimer définitivement cette notification?",

    // TEAMS
    "Authorize access": "Autoriser l'accès",
    "The Offishall app requires access to your Teams organization.":
      "L'application Offishall requiert l'accès à votre organisation Teams.",
    "Your tab is ready!": "Votre onglet est prêt !",
    "Click save to return to the app.":
      "Cliquez sur enregistrer pour revenir à l'application.",
    "Sign off from Offishall": "Se déconnecter de Offishall",
    "Your Teams organization is not configured to use the Offishall app.":
      "L'organisation Teams n'est pas configurée pour utiliser l'application Offishall.",
    "Sign-up": "Inscrivez-vous",
    "or make sure the Offishall - MS Teams integration is configured correctly.":
      "ou assurez-vous que l'intégration Offishall - MS Teams est configurée correctement.",
    "Go to Offishall's integrations configuration page.":
      "Aller à la page de configuration des intégrations Offishall.",
    Retry: "Réessayer",

    // INTERACTIONS MENU
    "export to csv": "Exporter en csv",
    "copy emails address": "Copier les adresses emails",
    "send email": "Envoyer un email",
    "Emails copied": "Les emails ont bien été copiés.",

    // ADVANCED SEARCH
    "Advanced Search": "Recherche avancée",
    "Advanced Search Results": "Résultats de la recherche avancée",
    "please configure new advanced search":
      "Veuillez configurer une nouvelle recherche avancée.",
    "new search": "Nouvelle recherche",

    "nudge sent": "Nudge envoyé",
    "send reminder message": "Envoyer un message de rappel",
    "send reminder by email to user":
      " Relancez gentiment {name} pour qu'il.elle remplisse son planning.",
    "reminder has been saved": "Le rappel a été enregistré",

    // Custom Errors
    "generic error": "Une erreur s'est produite.",
    "too far in the future":
      "Vous ne pouvez pas réserver plus que {numWeeks} semaines à l'avance",
    "slot is full":
      "Impossible de réserver cet espace de travail à certaines dates parce qu'il est déjà complet",
    "way too far in the future":
      "Vous ne pouvez pas réserver d'espace de travail aussi loin dans le futur",
    "cannot update slot in the past":
      "Vous ne pouvez pas modifier un mode de travail dans le passé",
    "equipment is full":
      "Impossible de réserver ce service le {date} parcequ'il est déjà complet",
    "valid email": "Veuillez entrer une adresse email valide",
    "sad oops": "Oops :(",
    "servers under heavy pressure":
      "Il semble que nos serveurs soient soumis à une forte pression. Veuillez réessayer dans quelques minutes.",
    "invalid password": "Mot de passe non valide",
    "wrong old password provided": "Ancien mot de passe incorrect",
    "password too many attempts":
      "Trop de tentatives, contactez votre administrateur",
    "email invalid": "L'email n'est pas valide",

    // ONBOARDING
    "fictitious users will be displayed":
      "Des utilisateurs fictifs seront affichés afin de profiter pleinement de l’expérience d’Offishall !",
    "fictitious spaces will be displayed":
      "Des espaces fictifs seront affichés afin de profiter pleinement de l’expérience d’Offishall !",
    "welcome to Offishall": "Bienvenue sur Offishall",
    "configure platform in 2 steps":
      "Configurons votre plateforme en 2 étapes.",
    "add your colleagues": "Ajoutez vos collègues",
    "invite your colleagues": "Invitez vos collaborateurs",
    "add their email addresses and receive invitation to join Offishall":
      "J'ajoute leurs adresses emails, ils recevront une invitation à rejoindre Offishall",
    "add fictitious users": "Ajoutez des utilisateurs fictifs",
    "fake users creation to test in the best conditions":
      "Nous créons pour vous de faux utilisateurs pour tester dans les meilleures conditions",
    "invite by email": "Inviter par email",
    "create a workspace": "Créez un espace de travail",
    "add your workspace": "Ajoutez votre espace de travail",
    "specify the name and capacity of my workspace":
      "Je précise le nom et la capacité de mon lieu de travail",
    "add dummy offices": "Ajoutez des bureaux fictifs",
    "create fake workspaces to test in the best conditions":
      "Nous créons pour vous de faux espaces de travail pour tester dans les meilleures conditions",
    "workspace name": "Nom de l'espace de travail",
    "Congratulations you have created your Offishall environment":
      "Félicitations ! Vous avez créé votre environnement Offishall !",
    "explore the platform as you want":
      "Vous pouvez maintenant naviguer sur la plateforme comme il vous semble.",
    "launch offishall": "Lancer Offishall",
    "Filling out your planning": "Remplir son planning",
    "Filling out your planning explanation part 1":
      "Planifiez en un clic votre semaine selon votre lieu de travail (bureau, télétravail, autre), vos collègues et votre organisation personnelle.",
    "Filling out your planning explanation part 2":
      "Bénéficiez d'une vue globale de qui est où, quand.",
    "Filling out your planning explanation part 3":
      "Ils ont besoin de vous comme vous avez besoin d'eux 😉",
    "email already present": "Email déjà présent dans la liste",
    continue: "Continuer",
    "workspace name placeholder":
      "ex : Bureau Paris, Espace marketing, Département RH",

    // USER ONBOARDING
    "typical week": "Semaine type",
    "typical week stats": "Statistiques semaine type",
    "stats per user": "Statistiques par utilisateur",
    "stats per team": "Statistiques par équipe",
    "tell us more about you": "Dites nous en plus sur vous",
    "your name": "Votre nom",
    "your email": "Votre email",
    "congratulations you have created your profile":
      "Félicitations ! Vous avez créé votre profil Offishall !",
    "We hope you’ll enjoy the new ways of collaborating":
      "Nous espérons que vous apprécierez les nouvelles façons de collaborer.",
    "Have fun exploring the application and see what your team is up to for the week ahead":
      "N'hésitez pas à profiter de l'expérience complète de l'application et découvrez ce que vos collègues ont prévu pour la semaine.",
    "typical week description":
      "Remplissez votre semaine de travail type, vous pourrez ensuite utiliser ce modèle pour remplir en un seul clic votre présence.",
    "how to do it": "Comment faire ?",
    "use drag and drop":
      "Utilisez le drag & drop pour sélectionner les jours de la semaine et indiquer d'où vous souhaitez travailler.",
    "cant add in typical week": "Impossible de l'ajouter en semaine type",

    "user-onboarding-welcome-title": "Bienvenue sur Offishall",
    "user-onboarding-welcome-p-1":
      "Le planning préféré de ceux qui aiment se (re)trouver au bureau !",
    "user-onboarding-welcome-p-2":
      "Nous allons valider quelques informations afin de nous assurer que la plateforme soit la plus adaptée à votre profil.",
    "user-onboarding-welcome-p-3":
      "Nous allons aussi vous demander de déclarer votre présence pour cette semaine.",
    "user-onboarding-welcome-p-4": "Suivez le guide 😃",

    "user-onboarding-manager-title": "Confirmez votre manager",
    "user-onboarding-manager-p-1":
      "Nous avons besoin de connaître le nom de votre manager afin de vous donner accès au planning des membres de votre équipe.",
    "user-onboarding-manager-p-2":
      "Si vous n'avez pas de manager, pas besoin de remplir.",

    "user-onboarding-planning-title": "A quoi ressemble votre semaine ?",
    "user-onboarding-planning-p-1":
      "Partagez avec vos collègues le(s) lieu(x) d'où vous travaillez cette semaine.",
    "user-onboarding-planning-p-2":
      "Vous pourrez ensuite compléter vos prévisions pour les semaines à venir. ",
    "user-onboarding-planning-choose-location":
      "Sélectionnez le bureau à partir du quel vous voudriez travailler.",
    "user-typical-week-update-slots":
      "Bien joué ! Vous pouvez maintenant la paramétrer dans votre planning",
    "user-typical-week-update-slots--invalid":
      "Votre semaine type n'est pas complète. Vous devez la remplir en entier pour qu'elle soit valide ☝️.",
    "user-typical-week-update-slots--no-offices":
      "⚠️ Votre entreprise n'a pas encore de bureau réservable. Attendez qu'un administrateur en créé au moins un.",

    "user-onboarding-password-title": "Choisissez votre mot de passe",
    "user-onboarding-password-p-1":
      "Découvrez en intégralité l’expérience Offishall.",
    "user-onboarding-password-p-2":
      "Vous pouvez compléter votre planning, consulter le planning de vos collègues ainsi que le taux d’occupation des différents espaces. ",

    // Toasts
    "message has been well planned": "Votre message a bien été planifié 👌",
    "not allowed to fill your schedule for this period":
      "Ohoh, vous n’êtes pas autorisés à remplir votre planning pour cette période.",
    "new notification is waiting": "Une nouvelle notification vous attend !",
    "new space confirmation": "Le nouvel espace a bien été enregistré ! 👌",
    "new zone confirmation": "La nouvelle zone a bien été enregistrée ! 👌",
    "edition confirmation": "La modification a bien été prise en compte !",
    "add new user confirmation":
      "L’ajout a été un succès ! 🤩 Un email d’enregistrement sera bientôt envoyé.",
    "confirmation add service when filling out the planning":
      "L’ajout de service(s) a bien été pris en compte.",
    "confirmation add a service": "Le service a bien été ajouté ! 👌",
    "confirmation delete a service": "Le service a bien été supprimé ! 👌",
    "confirmation update a service": "Le service a bien été modifié ! 👌",
    "confirmation copy email": "Les emails ont bien été copiés",
    "confirmation connexion to integration":
      "La connexion à {name} a réussi 🎉",
    "event pending user nudge": "{number} utilisateur en attente a été nudgé !",
    "event pending users nudge":
      "{number} utilisateurs en attente ont été nudgés !",
    "your event has been created": "Votre événement a bien été créé ! 🎉",
    "your event has been updated": "Votre événement a bien été modifié !",
    "your event has been deleted": "Votre événement a bien été supprimé.",
    "rule has been created": "Votre règle a bien été créée ! 🎉",
    "rule has been deleted": "Votre règle a bien été supprimée.",

    // STATS
    "occupation rate": "Taux d'occupation",
    "completion rate": "Taux de remplissage",
    "working mode": "Mode de travail",
    "week days": "Jour de la semaine",
    "team stats": "Stats équipe (manager)",
    "admin user stats": "Stats utilisateurs (admin)",
    "admin teams stats": "Stats équipes (admin)",
    "admin groups stats": "Stats de groupes (admin)",
    MONTH: "Ce mois-ci",
    QUARTER: "Ce trimestre",
    SEMESTER: "Ce semestre",
    YEAR: "Cette année",
    personalized: "Période personnalisée ",
    "includes week-ends": "Inclure les week-ends",
    "WEEK-MONDAY": "Cette semaine",
    "stats mean": "Moyenne",
    "stats over-time": "Journalière",
    "stats per-day": "Par jour",
    filled: "Rempli",
    "not filled": "Non rempli",
    percent: "Pourcentage %",
    "All locations": "Tous les lieux",
    "booked seats": "places occupées",
    "occupied seats": "places occupées",
    "not occupied seats": "places vacantes",

    // Users at filters
    "users_at_filter--empty-planning": "Plannings vides",
    "users_at_filter--not-empty-planning": "Plannings non vides",
    "users_at_filter--available": "Disponibles",
    "users_at_filter--office": "Bureau",
    "users_at_filter--home": "Télétravail",
    "users_at_filter--else": "Autre",
    "users_at_filter--off": "Absent.e",

    // Updators
    "update available": "Mise à jour disponible 🎉",
    "mandatory update title": "Mise à jour obligatoire",
    "mandatory update description":
      "Nous avons fiat des changement sur l'application qui nécessitent une mise à jour. Pas d'inquiétude, c'est quasiment instantané !",
    "mandatory update button": "Cliquer ici pour mettre à jour",

    // Team day
    Team: "Équipe",
    "team day": "Journée d'équipe",
    "team days": "Journées d'équipe",
    "No upcoming team day": "Aucune journée d'équipe à venir",
    "rename team": "Renommer votre équipe",
    "create new team day": "Créer une nouvelle journée d'équipe",
    "selection of attendees": "Sélection des participants",
    "list of attendees": "Liste des participants",
    "remove team day title": "Supression d'une journée d'équipe",
    "you will delete this team day":
      "Vous allez supprimer la journée d'équipe du {date}",
    "remove team day confirmation": "La journée d’équipe a bien été supprimée.",
    "creation team day confirmation": "La journée d'équipe a été créée !",
    "member are unavailable on": "membre n'est pas disponible le",
    "members are unavailable on": "membres ne sont pas disponibles le",
    "please select a location": "Merci de sélectionner un lieu",
    "select a location": "Sélectionnez un lieu",
    "No room in the selected location": "Pas de place dans le lieu sélectionné",
    "your entire team is available on":
      "Toute votre équipe est disponible le {date}",
    "you are about to set a team day on date for nb users at location locationName":
      "vous êtes sur le point de créer une journée d'équipe le {date} pour {nbUsers} personnes à {locationName}",
    "last attendance at the office X days ago":
      "Dernière présence au bureau il y a {nbDays} jours.",
    "never came to the office": "n'est jamais venu au bureau.",
    "come to the office today": "est venu au bureau aujourd'hui.",
    "have not seen userName for nbDays days":
      "Vous n'avez pas vu {userName} depuis {nbDays} jours.",
    "confirm team day deletion title":
      "Confirmez la supression de la journée d'équipe",
    "confirm team day deletion message":
      "Vous êtes sur le point de supprimer la journée d'équipe du {date}.",
    "details of the participants": "Détails des partipants",
    "team stats simple": "Statistiques de l'équipe",
    "members explanation":
      "Des données utiles pour connaitre les usages du bureau de votre équipe",
    "team-day-members-available": "{count} collaborateurs disponibles",
    "team-day-members-not-available": "{count} collaborateurs indisponibles",
    "new-team-day-recap": "Votre nouvelle journée d'équipe",
    "X days ago": "Il y a {nb} jours",
    "last meeting": "Dernière rencontre",
    "last attendance": "Dernière présence",
    "You are going to modify the planning of":
      "Vous allez modifier le planning de",

    // Guests
    "nb guests": "Nombre d'invités",
    "add guests": "Ajouter des invités",
    "guest list": "Liste d'invités",
    "guests outside of offishall": "Invités hors Offishall",
    "write here emails from your guests":
      "Ecrire ici les e-mails de vos invités",
    "guest outside Offishall": "invité hors Offishall",
    "guests outside Offishall": "invités hors Offishall",
    emails: "Emails",
    description: "Description",
    "guest description placeholder":
      "Précisez l'adresse, le code d'entrée, autre...",
    "sent description in email message":
      "Description visible dans l'email envoyé à vos invité.e.s",
    "guests confirmation toast":
      "Une invitation vient d'être envoyée avec les détails de cette réservation 👌",
    "show guests": "Voir les invités",
    "meeting-room--guests-list-recap-part-01": "Cet événement concerne",
    "meeting-room--guests-list-recap-part-02": "(organisateur inclus).",

    // 404
    "this page does not exist": "Cette page n'existe pas",
    "break the internet":
      "Vous n'avez pas cassé internet, mais nous ne pouvons pas trouver ce que vous cherchez.",

    //SLOTS EDITOR
    "user_onboarding--slots_editor--title":
      "Elaborez votre semaine de prédilection",
    "user_onboarding--manager--subtitle--confirm":
      "Si le manager suivant n'est pas correct, vous pouvez l'enlever ou en choisir un nouveau.",
    "user_onboarding--manager--subtitle--choose":
      "Vous pouvez commencer à taper le nom ou l'email de votre manager pour le retrouver.",

    // Suggestions
    suggestions: "Suggestions",
    "locationSuggestion::locationId provided":
      "Vous avez précédemment sélectionné cet espace",
    "locationSuggestion::max people in group ":
      "{nbMembers} membres {selectedGroup} ont choisi ce lieu à cette date",
    "locationSuggestion::max people in group singular":
      "{nbMembers} membre {selectedGroup} a choisi ce lieu à cette date",
    "of your team": "de votre équipe",
    "of your manager team": "de l'équipe de votre manager",
    "of your favorites": "de vos utilisateurs favoris",
    "of the selected group": "du groupe sélectionné",

    // FORM
    "invalid email": "Email non valide",
    "invalid confirm password": "Les mots de passe ne correspondent pas",
    "invalid form": "Formulaire non valide",

    // BULK CHANGE STATUS
    "special days": "Jours spéciaux",
    "day title": "Titre de la journée",
    "who is affected": "Qui est concerné ?",
    "what is the status applied": "Quel est le status à appliquer ?",
    "new rule": "Créer une nouvelle journée spéciale",
    "delete a rule": "Supprimer cette règle",
    "delete that rule": "Vous êtes sur le point d'effacer la règle suivante:",
    "this change is applied over a period":
      "Ce changement est appliqué sur une période ?",
    "apply a status to several members of the company":
      "Appliquer un status à plusieurs membres de l'entreprise",
    "bulk change status example": "Jour férié de l'ascension",
    everyone: "Tout le monde",
    myteam: "Mon équipe",
    "notify this change":
      "Notifier les personnes concernées de ce changement ?",
    "attributed to": "Attribué à",
    "title of the day": "Titre de la journée",
    untitled: "Sans titre",
    entities: "entités",
    "no special days": "Vous n'avez aucun jour spécial pour l'instant",
    "select a team, group, collaborator":
      "Sélectionner une équipe, groupe, collaborateur",

    // HOME
    Reception: "Accueil",
    "presence of the day": "Présences de la journée",
    "event of the day": "Événement de la journée",
    "events of the day": "Événements de la journée",
    "go to the planning": "Accéder au planning",
    "the whole company": "Toute l'entreprise",
    "please fill out your planning": "Veuillez remplir votre planning",
    "you are here": "vous êtes ici :",
    "you are": "vous êtes",
    "you are in": "vous êtes en",
    "Today the": "Aujourd'hui le",
    "search for a group or a team": "Rechercher un groupe ou une équipe",
    "select who is affected": "Sélectionner qui est concerné",
    "X elems selected": "{nb} éléments sélectionnés",
    "are affected": "Sont concernés",

    // OFFISHALL CARE
    "psychological follow-up title": "Suivi psychologique",
    "psychological follow-up subtitle":
      "L’isolement, le burn-out et les cas de dépression sont de plus en plus courants de nos jours. Nous faisons de votre bien-être mental une priorité.",
    "Nutri coach title": "Nutri coach",
    "Nutri coach subtitle":
      "Besoin d'une remise en forme suite à la sédentarité de votre mode de travail ?",
    "childcare title": "Garde d'enfant",
    "childcare subtitle":
      "La vie de famille des différents collaborateurs évolue et nécessite des adaptations en conséquence. La garde à domicile des enfants de moins de 15ans est mise en place.",

    // DAILY VIEW
    "weekend planning text":
      "Personne n’est présent aujourd’hui. Profitez bien du week end !",

    // MEETING ROOMS
    "Meeting rooms": "Salles de réunion",
    "search meeting room": "Chercher le nom de la salle",
    "meeting-room-event-form--input-attendees": "Ajouter des invités",
    "guest-email-picker--dialog": "Liste des invités",
    "guest-picker-form--input-email": "Adresse email",
    "meeting-room-event-form--guest-nb-part1": "Cet événement concerne",
    "meeting-room-event-form--guest-nb-part2": "(organisateur inclus)",
    "meeting-room-error--no-integration":
      "Votre entreprise n'a pas activé cette fonctionnalité.",
    "meeting-room-event-form--guests-button": "Modifier les invités",
    "meeting-room-event-form--targets-button":
      "Modifier les utilisateurs concernées",
    "meeting-room-event-form--max-capacity-reached":
      "Vous avez dépassé la capacité maximale de la salle: {guests}/{capacity}",
    "meeting-room--available-seats": "{nbSeats} place(s) disponible(s)",
    "meeting-room--attendees-attending": "{nbAttending} participant(s)",

    //INTEGRATION EVENTS
    "cwi-integration--card--title": "Intégration Google ou Microsoft",
    "cwi-integration--card--description-title":
      "Connectez votre compte d'entreprise Google/Microsoft afin d'améliorer votre expérience d'Offishall:",
    "cwi-integration--card--description-sentence-1":
      "afficher et créer des événements",
    "cwi-integration--card--description-sentence-2":
      "réserver des salles de réunions",
    "cwi-integration--card--description-sentence-3":
      "afficher et partager vos modes de travail",
    // GOOGLE
    "cwi-google-events--card--title": "Google Calendar",
    "cwi-google-events--form--title": "Google Calendar",
    "cwi-google-events--card--description--success":
      "Offishall est lié à votre compte d'entreprise Google Calendar.",
    "cwi-google-events--card--description--error":
      "Offishall n'est pas lié à votre compte d'entreprise Google Calendar.",

    // MICROSOFT
    "cwi-microsoft-outlook--card--title": "Microsoft Office365",
    "cwi-microsoft-outlook--form--title": "Microsoft Office365",
    "cwi-microsoft-outlook--card--description--success":
      "Offishall est lié à votre compte d'entreprise Microsoft.",
    "cwi-microsoft-outlook--card--description--error":
      "Offishall n'est pas lié à votre compte d'entreprise Microsoft.",
  },
}
