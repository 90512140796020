import PageContent from "components/Layout/PageLayout/PageContent/PageContent"
import AdminPageTitle from "components/Layout/PageLayout/PageHeader/AdminPageTitle/AdminPageTitle"
import PageHeader from "components/Layout/PageLayout/PageHeader/PageHeader"
import PageLayout from "components/Layout/PageLayout/PageLayout"
import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import useUserFromId from "graphql/users/useUserFromId"
import useTranslate from "intl/useTranslate"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import { UserBase } from "types"
import getUserDisplayName from "utils/getUserDisplayName"

import AddUserToTeamButton from "./AddUserToTeamButton/AddUserToTeamButton"
import ManagerTeamTable from "./ManageTeamTable/ManagerTeamTable"

const TitleActions = styled.div`
  margin-top: auto;
  margin-left: auto;
`

export default function ManageTeam() {
  const t = useTranslate()
  const { userId } = useParams<{ userId: string }>()
  const { loading, error, user: selectedUser } = useUserFromId(userId)
  const breadcrumb = [
    {
      name: t("Admin"),
      path: "/admin",
    },
    {
      name: `${t("Users")} / ${t("manage my team")}`,
      path: "/admin/users",
    },
  ]
  const [usersTeam, setUsersTeam] = useState<UserBase[]>([])

  useEffect(() => {
    // Sort users alphabetically
    if (selectedUser)
      setUsersTeam(
        [...selectedUser.team].sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1
          } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1
          } else return 0
        })
      )
  }, [selectedUser])

  if (loading || error || selectedUser === null)
    return <LoaderOrErrorComponent loading={loading} error={error} />

  return (
    <PageLayout>
      <PageHeader>
        <AdminPageTitle
          title={t("user team", {
            name: getUserDisplayName(selectedUser, selectedUser.company.flags),
          })}
          breadcrumbs={breadcrumb}
        />
        <TitleActions>
          <AddUserToTeamButton user={selectedUser} />
        </TitleActions>
      </PageHeader>
      <PageContent>
        <ManagerTeamTable usersTeam={usersTeam} currentUser={selectedUser} />
      </PageContent>
    </PageLayout>
  )
}
