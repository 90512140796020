import InteractionsMenu from "components/InteractionsMenu/InteractionsMenu"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useAllGroups from "graphql/groups/useAllGroups"
import useMyGroupsSimple from "graphql/groups/useMyGroupsSimple"
import useUser from "graphql/users/useUser"
import useCompanyFlags from "hooks/useCompanyFlags"
import useTranslate from "intl/useTranslate"
import React from "react"
import { useTeamsAppContext } from "TeamsApp/TeamsAppContext"
import { UserBase } from "types"
import getTeamDisplayName from "utils/getTeamDisplayName"
import UTCDate from "utils/UTCDate"

import GroupOrMSTeamsPlanningHeader from "./GroupOrMSTeamsPlanningHeader/GroupOrMSTeamsPlanningHeader"
import MessageOrActiveLocationsList from "./MessageOrActiveLocationsList/MessageOrActiveLocationsList"

interface PropsType {
  groupId: string
  users: UserBase[]
  firstWeekday: UTCDate
  lastWeekday: UTCDate
  noActiveFilters: boolean
}

export default function GroupPlanningHeader({
  groupId,
  users,
  firstWeekday,
  lastWeekday,
  noActiveFilters,
}: PropsType) {
  const t = useTranslate()
  const { user: me } = useUser()
  const { companyFlags } = useCompanyFlags()
  const { isTeamsApp } = useTeamsAppContext()

  const { activeLocation } = usePlanningContext()

  const { groups: allGroups } = useAllGroups()
  const { groups: myGroups } = useMyGroupsSimple()
  const group = [...allGroups, ...myGroups].find((g) => g.id === groupId)

  if (group === undefined) return <></>

  return (
    <>
      <GroupOrMSTeamsPlanningHeader
        name={getTeamDisplayName(group, companyFlags, t)}
        id={group.id}
        actions={
          group && !isTeamsApp
            ? [
                <InteractionsMenu
                  group={group}
                  key={`loc-${group.id}-interactions`}
                  from={firstWeekday}
                  to={lastWeekday}
                  users={users}
                />,
              ]
            : undefined
        }
      />
      <MessageOrActiveLocationsList
        users={users}
        noActiveFilters={noActiveFilters}
        activeLocation={activeLocation}
        me={me}
      />
    </>
  )
}
