import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { Group, MeetingRoom, PickedTargets } from "types"
import { Button, Dialog } from "ui"
import { Edit } from "ui/icons"
import UserTree from "utils/UserTree"

import TargetPickerContent from "./TargetPickerContent/TargetPickerContent"

const Container = styled.div`
  padding: 8px 0;
`

interface PropsType {
  title: string
  selectedTargets: PickedTargets
  disabled?: {
    users?: boolean
    managers?: boolean
    groups?: boolean
    emails?: boolean
    picker?: boolean
  }
  meetingRoom?: MeetingRoom
  options?: {
    singleSelection?: boolean
    disableMySelf?: boolean
  }
  extraAction?: React.ReactNode
  extraActionFilteredTargetIds?: string[]
  updateSelectedUsers: (users: UserTree[]) => void
  updateSelectedManagers: (managers: UserTree[]) => void
  updateSelectedGroups: (groups: Group[]) => void
  updateSelectedEmails: (emails: string[]) => void
}

export default function TargetPicker({
  title,
  selectedTargets,
  options = {
    singleSelection: false,
    disableMySelf: false,
  },
  disabled = {},
  meetingRoom,
  extraAction,
  extraActionFilteredTargetIds,
  updateSelectedUsers,
  updateSelectedManagers,
  updateSelectedGroups,
  updateSelectedEmails,
}: PropsType) {
  const t = useTranslate()
  const { user: me } = useUser()

  const [open, setOpen] = useState(false)

  if (!me) return <></>

  return (
    <>
      <Container>
        <Button
          secondary
          fullWidth
          gap={8}
          disabled={disabled.picker}
          onClick={() => setOpen(true)}
        >
          <Edit />
          {title}
        </Button>
      </Container>

      {open && (
        <Dialog
          open
          title={title}
          onClose={() => setOpen(false)}
          leftButton={
            <Button secondary onClick={() => setOpen(false)}>
              {t("Cancel")}
            </Button>
          }
          rightButton={
            <Button onClick={() => setOpen(false)}>{t("Confirm")}</Button>
          }
          forceToMaxHeight
        >
          <TargetPickerContent
            selectedTargets={selectedTargets}
            companyFlags={me.company.flags}
            options={options}
            disabled={{
              ...disabled,
              groups: options.singleSelection || disabled.groups ? true : false,
            }}
            extraAction={extraAction}
            extraActionFilteredTargetIds={extraActionFilteredTargetIds}
            meetingRoom={meetingRoom}
            onSelectUser={(user: UserTree) => {
              updateSelectedUsers([user])
              // if (options.singleSelection) setOpen(false)
            }}
            onSelectManager={(manager: UserTree) => {
              updateSelectedManagers([manager])
              // if (options.singleSelection) setOpen(false)
            }}
            onSelectGroup={(group: Group) => {
              updateSelectedGroups([group])
              // if (options.singleSelection) setOpen(false)
            }}
            onSelectEmail={(email) => {
              updateSelectedEmails([email])
              // if (options.singleSelection) setOpen(false)
            }}
          />
        </Dialog>
      )}
    </>
  )
}
