import React from "react"
import styled from "styled-components"

import { Cancel } from "../icons"

const CloseButtonContainer = styled.button``

interface PropsType {
  strokeWidth?: number
  stroke?: string
  onClick?: () => void
  className?: string
}

export function CloseButton({
  strokeWidth,
  stroke,
  onClick,
  className,
  ...rest
}: PropsType) {
  return (
    <CloseButtonContainer
      className={className}
      onClick={() => {
        if (onClick) onClick()
      }}
      {...rest}
    >
      <Cancel strokeWidth={strokeWidth} stroke={stroke} />
    </CloseButtonContainer>
  )
}
