import useMediaQueries from "hooks/useMediaQueries"
import React from "react"
import styled from "styled-components"
import { colors, mediaQueries } from "ui"

const DayTitleLayout = styled.span`
  display: flex;
  gap: 8px;

  @media ${mediaQueries.isMobile} {
    gap: 6px;
    &.day-title--smaller {
      gap: 0;
    }
  }
`

const P = styled.p`
  &.p {
    margin: 0;
    padding: 0;
    &--bold {
      font-weight: 600;
    }
    &--normal {
      font-weight: 400;
    }
    &--lg {
      font-size: 20px;
      line-height: 24px;
    }
    &--md {
      font-size: 16px;
      line-height: 20px;
    }
    &--sm {
      font-size: 14px;
      line-height: 18px;
    }
    &--purple {
      color: ${colors.purple};
    }
    &--white {
      color: ${colors.white};
    }
    &--grey {
      color: ${colors.grey1};
    }
    &--black {
      color: ${colors.black};
    }
  }
`

interface DayTitlePropsType {
  isToday: boolean
  isSelected: boolean
  dayName: string
  dayNumber: string
  userShowWeekend: boolean
}

export default function DayTitle({
  isToday,
  dayName,
  dayNumber,
  isSelected,
  userShowWeekend,
}: DayTitlePropsType) {
  const { isMobile } = useMediaQueries()

  const getDayTileLayoutClasses = () => {
    if (isMobile && userShowWeekend) return "day-title--smaller"
    return ""
  }

  if (isMobile && !isToday)
    return (
      <DayTitleLayout className={getDayTileLayoutClasses()}>
        <P
          className={`p p--sm p--normal ${isSelected ? "p--white" : "p--grey"}`}
        >
          {dayName}
        </P>
        <P
          className={`p p--sm p--normal ${
            isSelected ? "p--white" : "p--black"
          }`}
        >
          {dayNumber}
        </P>
      </DayTitleLayout>
    )
  if (isMobile && isToday)
    return (
      <DayTitleLayout className={getDayTileLayoutClasses()}>
        <P
          className={`p p--sm p--normal ${
            isSelected ? "p--white" : "p--purple"
          }`}
        >
          {dayName}
        </P>
        <P
          className={`p p--sm p--bold ${isSelected ? "p--white" : "p--purple"}`}
        >
          {dayNumber}
        </P>
      </DayTitleLayout>
    )
  if (isToday)
    return (
      <DayTitleLayout className={getDayTileLayoutClasses()}>
        <P
          className={`p p--lg p--bold ${isSelected ? "p--white" : "p--purple"}`}
        >
          {dayName}
        </P>
        <P
          className={`p p--lg p--bold ${isSelected ? "p--white" : "p--purple"}`}
        >
          {dayNumber}
        </P>
      </DayTitleLayout>
    )
  return (
    <DayTitleLayout className={getDayTileLayoutClasses()}>
      <P
        className={`p p--lg ${
          isSelected ? "p--white p--bold" : "p--black p--normal"
        }`}
      >
        {dayName}
      </P>
      <P
        className={`p p--lg ${
          isSelected ? "p--white p--bold" : "p--black p--normal"
        }`}
      >
        {dayNumber}
      </P>
    </DayTitleLayout>
  )
}
