import { ApolloError, gql, useQuery } from "@apollo/client"
import { useMemo } from "react"
import { Slot, UTCSlot } from "types"
import UTCDate from "utils/UTCDate"

import { SlotTemplateFragment } from "./fragments/slotTemplateFragment"

export const SLOT_TEMPLATES_QUERY = gql`
  ${SlotTemplateFragment}
  query getSlotTemplates($userId: ID!) {
    slotTemplates(userId: $userId) {
      ...SlotTemplateFragment
    }
  }
`

export default function useUserSlotTemplates(userId: string): {
  slots: UTCSlot[]
  loading: boolean
  error?: ApolloError
} {
  const weekSize = 7
  const { loading, error, data } = useQuery(SLOT_TEMPLATES_QUERY, {
    variables: {
      userId,
    },
    skip: userId === "",
    fetchPolicy: "cache-first",
  })
  const slots: UTCSlot[] = useMemo(() => {
    if (userId && data) {
      const { slotTemplates }: { slotTemplates: Slot[] } = data
      const d: UTCSlot[] = []
      const currentDate = UTCDate.firstEpochMonday()
      for (let i = 0; i < weekSize * 2; i++) {
        const id = `${userId}_${currentDate.getTime()}`
        const foundSlot = slotTemplates.find((s) => s.id === id)
        const slot: UTCSlot = {
          id,
          value: foundSlot?.value || null,
          locationId: foundSlot?.locationId || null,
          userId,
          date: new UTCDate(currentDate),
          guests: 0,
          guestsEmails: [],
          label: foundSlot?.label || "",
        }
        currentDate.increment("HALF-DAY")
        d.push(slot)
      }
      return d
    }
    return []
  }, [userId, data])

  return { slots, loading, error }
}
