import { Form } from "components/Layout/Form/Form"
import useDeleteUserPhoto from "graphql/users/useDeleteUserPhoto"
import useSetUserPhoto from "graphql/users/useSetUserPhoto"
import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { Button, colors, Dialog } from "ui"
import mediaQueries from "ui/mediaQueries"

import MediaInput from "./MediaInput/MediaInput"

const FormStyled = styled(Form)`
  padding: 16px 0;
`

const DialogBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;

  .content {
    &-dates {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 16px;
      @media ${mediaQueries.isMobile} {
        flex-direction: column;
      }

      p {
        color: ${colors.purple};
        text-transform: uppercase;
      }
    }
    &-title {
      margin-bottom: 16px;
      margin-top: 16px;
      @media ${mediaQueries.isMobile} {
        margin-top: 8px;
      }
    }
    &-details {
      margin-bottom: 4px;
      color: ${colors.grey1};
    }
    &-body p {
      color: ${colors.black};
      margin-bottom: 16px;
    }
    &-infos {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      @media ${mediaQueries.isMobile} {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    &-booking-info {
      color: ${colors.grey1};
    }
    &-deadline-info {
      color: ${colors.redAlert};
    }
    &-deadline-info-green {
      color: ${colors.green};
    }
    &-column {
      display: flex;
      flex-direction: column;
    }
  }
`

const ActionsDiv = styled.div`
  display: flex;
  gap: 8px;

  button {
    justify-content: center;
  }

  @media ${mediaQueries.isMobile} {
    justify-content: space-between;
  }
`

interface PropsType {
  open: boolean
  onClose: () => void
}

export default function UpdateUserPhotoDialog({ open, onClose }: PropsType) {
  const t = useTranslate()
  const { loading, user } = useUser()
  const setUserPhoto = useSetUserPhoto()
  const deleteUserPhoto = useDeleteUserPhoto()

  const [image, setImage] = useState<File | undefined>(undefined)

  const handleDelete = async () => {
    await deleteUserPhoto()
    onClose()
  }

  const handleSave = async () => {
    if (image) {
      await setUserPhoto({ image })
      onClose()
    }
  }

  if (loading || !user) return null

  return (
    <Dialog
      open={open}
      onClose={onClose}
      rightButton={
        <ActionsDiv>
          {user.photo && (
            <Button onClick={handleDelete} danger>
              {t("Delete")}
            </Button>
          )}
          <Button disabled={!image} onClick={handleSave}>
            {t("Save")}
          </Button>
        </ActionsDiv>
      }
    >
      <DialogBody>
        <FormStyled
          onSubmit={(e) => {
            e.preventDefault()
          }}
        >
          <MediaInput
            defaultImage={user.photo || undefined}
            onChange={(files) => {
              if (files.length > 0) {
                setImage(files[0])
              } else {
                setImage(undefined)
              }
            }}
          />
        </FormStyled>
      </DialogBody>
    </Dialog>
  )
}
