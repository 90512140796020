import PageHeader from "components/Layout/PageLayout/PageHeader/PageHeader"
import MobileNavBack from "components/Layout/PageLayout/PageHeader/PageTitle/MobileNavBack/MobileNavBack"
import PageTitle from "components/Layout/PageLayout/PageHeader/PageTitle/PageTitle"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useUpdateUserValues from "graphql/users/useUpdateUserValues"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { User } from "types"
import { Button, Dialog, HeaderTextField } from "ui"

const Container = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

interface PropsType {
  user: User
  open: boolean
  onClose: () => void
}

export default function UpdateInformationsDialog({
  user,
  open,
  onClose,
}: PropsType) {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()
  const { updateUserValues } = useUpdateUserValues()
  const { addToast } = useToasts()

  const [name, setName] = useState<string>(user.name)

  const saveForm = () => {
    try {
      updateUserValues({ name: name }, user.id)
      onClose()
    } catch (e) {
      const errorText = e.message ? t(e.message) : t("Something went wrong")
      addToast(errorText, {
        appearance: "error",
      })
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        setName(user.name)
        onClose()
      }}
      title={isMobile ? undefined : t("edit my user name")}
      rightButton={
        <Button onClick={saveForm} gap={8} fullWidth={isMobile}>
          {t("Save")}
        </Button>
      }
      fullscreen={isMobile}
    >
      {isMobile && (
        <PageHeader>
          <MobileNavBack onClose={onClose} />
          <PageTitle title={t("edit my user name")} />
        </PageHeader>
      )}
      <Container>
        <HeaderTextField
          initialValue={user.name}
          handleChange={(name) => setName(name)}
          placeholder={t("first last name")}
        />
      </Container>
    </Dialog>
  )
}
