import BottomNavigation from "@mui/material/BottomNavigation"
import BottomNavigationAction from "@mui/material/BottomNavigationAction"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import { useSettingsContext } from "components/SettingsDialog/components/SettingsContextProvider"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import { useHistory } from "react-router"
import styled from "styled-components"
import { colors } from "ui"
import { Drawer, Event, ListSorted, Settings } from "ui/icons"

import HomeButton from "../HomeButton/HomeButton"
const Footer = styled(BottomNavigation)`
  border-top: 1px solid ${colors.grey3};
  background: linear-gradient(
      0deg,
      rgba(34, 0, 117, 0.01),
      rgba(34, 0, 117, 0.01)
    ),
    ${colors.white};
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 20;
  .list-sorted-icon {
    transform: rotate(90deg);
  }
`

const Link = styled(BottomNavigationAction)`
  &.link {
    svg {
      stroke: ${colors.grey1};
      fill: ${colors.grey1};
    }
    span {
      color: ${colors.grey1};
    }

    &--active {
      svg {
        stroke: ${colors.purple};
        fill: ${colors.purple};
      }
      span {
        color: ${colors.purple};
      }
    }
  }
`

export default function MobileBottomNavigation() {
  const t = useTranslate()
  const [selectedPage, setSelectedPage] = useState(0)
  const { setViewMode } = usePlanningContext()
  const history = useHistory()
  const { shouldDisplaySettings, setShouldDisplaySettings } =
    useSettingsContext()
  const cleanupThingsBeforeNavigation = () => {
    setViewMode("WEEK")
  }

  const goToUrl = (url: string) => {
    cleanupThingsBeforeNavigation()
    setShouldDisplaySettings(false)
    history.push(url)
  }

  return (
    <Footer
      showLabels
      value={selectedPage}
      onChange={(_event, newValue) => {
        setSelectedPage(newValue)
      }}
    >
      <Link
        label={t("Planning")}
        icon={<ListSorted className="list-sorted-icon" />}
        onClick={() => goToUrl("/")}
        className={
          (location.pathname.startsWith("/planning") ||
            location.pathname === "/" ||
            location.pathname.startsWith("/user/")) &&
          !shouldDisplaySettings
            ? "link link--active"
            : "link"
        }
      />
      <Link
        label={t("Directory")}
        icon={<Drawer />}
        onClick={() => goToUrl("/directory")}
        className={
          location.pathname.startsWith("/directory") && !shouldDisplaySettings
            ? "link link--active"
            : "link"
        }
      />
      <HomeButton />
      <Link
        label={t("Events")}
        icon={<Event strokeWidth="0.2" />}
        onClick={() => goToUrl("/events")}
        className={
          location.pathname.startsWith("/events") && !shouldDisplaySettings
            ? "link link--active"
            : "link"
        }
      />
      <Link
        label={t("Settings")}
        icon={<Settings />}
        onClick={() => setShouldDisplaySettings(!shouldDisplaySettings)}
        className={shouldDisplaySettings ? "link link--active" : "link"}
      />
    </Footer>
  )
}
