import UpdateCategoryDialog from "pages/AdminPage/AdminLocationsPage/AddCategoryButton/UpdateCategoryDialog/UpdateCategoryDialog"
import React, { useState } from "react"
import { UnstyledButton } from "ui"
import { Edit } from "ui/icons"
import LocationTree from "utils/LocationTree"

import UpdateLocationDialog from "../../../../pages/AdminPage/AdminLocationsPage/AddLocationButton/UpdateLocationDialog/UpdateLocationDialog"

interface PropsType {
  location: LocationTree
  disabled?: boolean
}

export default function EditLocationButton({ location, disabled }: PropsType) {
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)

  if (location.isCategory)
    return (
      <>
        <UnstyledButton
          className="edit-icon"
          onClick={(e) => {
            e.stopPropagation()
            setIsEditModalOpen(true)
          }}
          disabled={disabled}
        >
          <Edit />
        </UnstyledButton>
        {isEditModalOpen && (
          <UpdateCategoryDialog
            open={isEditModalOpen}
            categoryToMutate={location}
            onClose={() => setIsEditModalOpen(false)}
          />
        )}
      </>
    )

  return (
    <>
      <UnstyledButton
        className="edit-icon"
        onClick={(e) => {
          e.stopPropagation()
          setIsEditModalOpen(true)
        }}
        disabled={disabled}
      >
        <Edit />
      </UnstyledButton>
      {isEditModalOpen && (
        <UpdateLocationDialog
          open={isEditModalOpen}
          locationToMutate={location}
          onClose={() => setIsEditModalOpen(false)}
        />
      )}
    </>
  )
}
