import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { Attendee, MeetingRoom } from "types"
import { P14 } from "ui"

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

interface PropsType {
  meetingRoom?: MeetingRoom
  attendees: Attendee[]
}

export default function GuestListRecap({ meetingRoom, attendees }: PropsType) {
  const t = useTranslate()
  return (
    <Layout>
      <P14 color={"grey1"}>
        {t("meeting-room-event-form--guest-nb-part1")}
        <strong>
          {" "}
          {attendees.length} {t(attendees.length === 1 ? "person" : "people")}{" "}
        </strong>
        {t("meeting-room-event-form--guest-nb-part2")}
      </P14>
      {meetingRoom && meetingRoom.capacity < attendees.length && (
        <P14 color={"redAlert"}>
          {t("meeting-room-event-form--max-capacity-reached", {
            capacity: `${meetingRoom?.capacity}`,
            guests: `${attendees.length}`,
          })}
        </P14>
      )}
    </Layout>
  )
}
