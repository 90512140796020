import useUser from "graphql/users/useUser"
import React from "react"
import { UserBase } from "types"
import MultiSelectionListItem from "ui/Lists/MultiSelectionListItem"
import getUserDisplayName from "utils/getUserDisplayName"

interface PropsType {
  users: UserBase[]
  selectedUsers: UserBase[]
  noInteractionWithMyself?: boolean
  onSelect: (user: UserBase) => void
}

export default function UserGroupList({
  users,
  selectedUsers,
  noInteractionWithMyself = false,
  onSelect,
}: PropsType) {
  const { user: me } = useUser()
  if (me === null) return <></>
  return (
    <ul>
      {users.map((u) => {
        const disabled = noInteractionWithMyself && u.id === me?.id
        return (
          <MultiSelectionListItem
            key={`multi-sel-item-${u.id}`}
            item={{ ...u, name: getUserDisplayName(u, me.company.flags) }}
            selectedItems={selectedUsers}
            disabled={disabled}
            onClick={() => {
              if (!disabled) onSelect(u)
            }}
          />
        )
      })}
    </ul>
  )
}
