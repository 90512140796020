import "firebase/auth"

import { Capacitor } from "@capacitor/core"
import { FirebaseAuthentication } from "@capacitor-firebase/authentication"
import { getApps, initializeApp } from "firebase/app"
// import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check"
import {
  applyActionCode,
  AuthCredential,
  AuthProvider,
  confirmPasswordReset as confirmPasswordResetFirebase,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  fetchSignInMethodsForEmail,
  getAuth,
  GoogleAuthProvider,
  inMemoryPersistence,
  linkWithCredential,
  OAuthCredential,
  OAuthProvider,
  reauthenticateWithCredential,
  SAMLAuthProvider,
  sendEmailVerification,
  setPersistence,
  signInWithCredential,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updatePassword as updatePasswordFirebase,
  User,
  verifyPasswordResetCode as verifyPasswordResetCodeFirebase,
} from "firebase/auth"
import { useEffect, useState } from "react"

let _history: any = null

export function setHistory(history: any) {
  _history = history
}

const configs = {
  "dwight-local": {
    apiKey: "AIzaSyDYIWiusYuPGZGLm_EUEtcEoNCuUqDOK6w",
    authDomain: "dwight-local.firebaseapp.com",
    databaseURL: "https://dwight-local.firebaseio.com",
    projectId: "dwight-local",
    storageBucket: "dwight-local.appspot.com",
    messagingSenderId: "991574087179",
    appId: "1:991574087179:web:880d0287e60ebc2498190e",
    measurementId: "G-DP17CL14EX",
  },
  "dwight-dev": {
    apiKey: "AIzaSyC0XMrgJ9jQKjB8-_skcDzQEC6QSwkqlvs",
    authDomain: "dwight-dev.firebaseapp.com",
    databaseURL: "https://dwight-dev.firebaseio.com",
    projectId: "dwight-dev",
    storageBucket: "dwight-dev.appspot.com",
    messagingSenderId: "1062438981555",
    appId: "1:1062438981555:web:a78e71ee99fcd3760185c6",
    measurementId: "G-VMLWJCL669",
  },
  "dwight-prod": {
    apiKey: "AIzaSyDPH-mF7NqNrk4Td4UFlv1SXN-yTIk6tE4",
    authDomain: "dwight-prod.firebaseapp.com",
    databaseURL: "https://dwight-prod.firebaseio.com",
    projectId: "dwight-prod",
    storageBucket: "dwight-prod.appspot.com",
    messagingSenderId: "140267536290",
    appId: "1:140267536290:web:76ba1a092c3fb2d87a3cfb",
    measurementId: "G-F6RNTTBBW5",
  },
}

export class NotVerifiedError extends Error {
  constructor(message: string) {
    super(message)
  }
}

export type { User } from "firebase/auth"

let isSigningInWithProvider = false
let _pendingCredential: AuthCredential | null = null

export function useAuth() {
  let app: any = null
  const [loading, setLoading] = useState(true)
  if (getApps().length === 0) {
    const index = process.env.REACT_APP_FIREBASE
    if (index === "dwight-local") {
      app = initializeApp(configs["dwight-local"])
    } else if (index === "dwight-prod") {
      app = initializeApp(configs["dwight-prod"])
    } else {
      app = initializeApp(configs["dwight-dev"])
    }
    if (!Capacitor.isNativePlatform()) {
      setPersistence(getAuth(), inMemoryPersistence)
      signinWithCookie().then(() => {
        setLoading(false)
      })
    } else {
      setLoading(false)
    }
  }
  if (app && process.env.REACT_APP_CAPTCHA_KEY) {
    /*initializeAppCheck(app, {
      provider: new ReCaptchaV3Provider(process.env.REACT_APP_CAPTCHA_KEY),
      // Optional argument. If true, the SDK automatically refreshes App Check
      // tokens as needed.
      // isTokenAutoRefreshEnabled: true,
    })*/
  }
  const [authUser, setAuthUser] = useState<User | null | undefined>(undefined)

  useEffect(() => {
    const onProviderSignin = () => {
      setAuthUser(getAuth().currentUser)
    }
    document.addEventListener(
      "offishall-provider-signin",
      onProviderSignin,
      false
    )
    const unlisten = getAuth().onAuthStateChanged((authUser) => {
      if (authUser && authUser.emailVerified && !isSigningInWithProvider) {
        // Link with provider

        setAuthUser(authUser)
      } else {
        setAuthUser(null)
      }
    })

    return () => {
      unlisten()
      document.removeEventListener(
        "offishall-provider-signin",
        onProviderSignin
      )
    }
  })

  return { loading, authUser }
}

async function signinWithCookie() {
  const res = await fetch(
    `${process.env.REACT_APP_API}/auth/user/login/cookie`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    }
  )
  if (res.ok) {
    const { token } = await res.json()
    if (token) {
      await loginWithToken(token)
    }
  }
}

async function signinWithMicrosoftCredentials(credentials: {
  idToken: string
  accessToken: string
  email: string
}) {
  const res = await fetch(
    `${process.env.REACT_APP_API}/auth/user/login/microsoft`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(credentials, null, 2),
    }
  )
  if (res.ok) {
    const { token } = await res.json()
    if (token) {
      await loginWithToken(token)
    }
  }
}

export function getToken(): Promise<string | null> {
  const user = getAuth().currentUser
  if (user && user.emailVerified) {
    return user.getIdToken()
  }
  return Promise.resolve(null)
}

async function loginWithProviderMobile(providerId: string) {
  try {
    switch (providerId) {
      case "google.com": {
        const result = await FirebaseAuthentication.signInWithGoogle()
        const credentials = GoogleAuthProvider.credential(
          result.credential?.idToken
        )
        await signInWithCredential(getAuth(), credentials)
        break
      }
      case "microsoft.com": {
        const result = await FirebaseAuthentication.signInWithMicrosoft()
        const { credential, user } = result
        const idToken = credential?.idToken
        const accessToken = credential?.accessToken
        const email = user?.email
        if (idToken && accessToken && email) {
          await signinWithMicrosoftCredentials({
            idToken,
            accessToken,
            email,
          })
        } else {
          throw new Error("No credentials")
        }
        break
      }
      default:
        throw new Error(`Provider not supported: ${providerId}.`)
    }
  } catch (e) {
    throw new Error(`An error occured when signing you in: ${e.message}`)
  }
}

export async function linkWithPassword(
  email: string,
  password: string
): Promise<void> {
  if (!_pendingCredential) {
    throw new Error("No pending credential")
  }
  isSigningInWithProvider = true
  const result = await signInWithEmailAndPassword(getAuth(), email, password)
  await linkWithCredential(result.user, _pendingCredential)
  isSigningInWithProvider = false
  document.dispatchEvent(new Event("offishall-provider-signin"))
}

const BYPASS_COOKIES_AUTH = false

export async function loginWithProvider(
  providerId: string,
  pendingCredentials?: AuthCredential
): Promise<void> {
  if (BYPASS_COOKIES_AUTH || Capacitor.isNativePlatform()) {
    // TODO: handle auth/account-exists-with-different-credential error
    try {
      const res = await loginWithProviderMobile(providerId)
      return res
    } catch (e) {
      if (e.code === "auth/account-exists-with-different-credential") {
        const methods = await fetchSignInMethodsForEmail(
          getAuth(),
          e.customData.email
        )
        const credential = OAuthProvider.credentialFromResult(
          e.customData
        ) as OAuthCredential
        if (methods.length > 0) {
          const method = methods[0]
          if (method !== "password") {
            return loginWithProvider(method, credential)
          } else {
            // password: TODO: https://cloud.google.com/identity-platform/docs/link-accounts
          }
        }
        return
      }
      isSigningInWithProvider = false
      throw new Error(`An error occured when signing you in: ${e.message}`)
    }
  }
  let credentials = null
  isSigningInWithProvider = true
  try {
    let provider: AuthProvider | null = null
    if (providerId === "google.com") {
      provider = new GoogleAuthProvider()
    } else if (providerId === "microsoft.com") {
      provider = new OAuthProvider(providerId)
    } else if (providerId.startsWith("saml.")) {
      provider = new SAMLAuthProvider(providerId)
    } else if (providerId.startsWith("oidc.")) {
      throw new Error("OIDC not supported. Use loginWithOIDC method.")
    } else throw new Error(`Provider not supported.`)

    credentials = await signInWithPopup(getAuth(), provider)
    const idToken = await credentials.user?.getIdToken()
    const res = await verifyLogin(idToken)
    if (!res.ok) {
      if (res.status === 403) {
        isSigningInWithProvider = false
        location.href = "/signup-company?notifyMissingAccount=true"
        return
      } else {
        throw new Error("Could not verify user.")
      }
    }
  } catch (e) {
    if (e.code === "auth/account-exists-with-different-credential") {
      const methods = await fetchSignInMethodsForEmail(
        getAuth(),
        e.customData.email
      )
      const credential = OAuthProvider.credentialFromResult(
        e.customData
      ) as OAuthCredential
      if (methods.length > 0) {
        const method = methods[0]
        if (method !== "password") {
          return loginWithProvider(method, credential)
        } else {
          localStorage.setItem("emailForSignIn", e.customData.email)
          _pendingCredential = credential
          // go to /link-account
          if (_history) {
            _history.replace("/link-account")
          }
        }
      }
      return
    }
    isSigningInWithProvider = false
    throw new Error(`An error occured when signing you in: ${e.message}`)
  }
  isSigningInWithProvider = false
  if (!credentials?.user?.emailVerified) {
    throw new NotVerifiedError(
      `This user account has not been verified yet. Please contact your administrator.`
    )
  }
  if (pendingCredentials) {
    await linkWithCredential(credentials.user, pendingCredentials)
  }
  document.dispatchEvent(new Event("offishall-provider-signin"))
}

export async function verifyLogin(idToken: string) {
  const csrfRes = await fetch(`${process.env.REACT_APP_API}/auth/user/csrf`, {
    method: "POST",
    credentials: "include",
  })
  const { csrfToken } = await csrfRes.json()
  return await fetch(`${process.env.REACT_APP_API}/auth/user/verify`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      idToken,
      csrfToken,
    }),
    credentials: "include",
  })
}

export async function register(email: string, password: string) {
  try {
    const { user } = await createUserWithEmailAndPassword(
      getAuth(),
      email,
      password
    )
    await sendEmailVerification(user)
    return user
  } catch (e) {
    throw e
  }
}

export async function logout(errorMessage?: string) {
  if (errorMessage) {
    localStorage.setItem("offishall-session-error", errorMessage)
  }
  const token = await getToken()
  const url = process.env.REACT_APP_API + "/auth/user/logout"
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    credentials: "include",
  }).finally(() => {
    return signOut(getAuth())
  })
}

export async function loginWithToken(token: string) {
  const credentials = await signInWithCustomToken(getAuth(), token)
  const idToken = await credentials.user?.getIdToken()
  const res = await verifyLogin(idToken)
  if (!res.ok) {
    throw new Error("Could not verify user.")
  }
  return credentials
}

export async function loginWithSignupToken(signupToken: string) {
  const res = await fetch(
    `${process.env.REACT_APP_API}/auth/user/login/signup-token`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        signupToken,
      }),
    }
  )
  if (res.ok) {
    const { token } = await res.json()
    if (token) {
      await loginWithToken(token)
    }
  } else {
    throw new Error("Invalid signup token.")
  }
}

export function getUserId() {
  return getAuth().currentUser?.uid
}

export async function verifyPasswordResetCode(actionCode: string) {
  return verifyPasswordResetCodeFirebase(getAuth(), actionCode)
}

export async function confirmPasswordReset(
  actionCode: string,
  newPassword: string
) {
  return confirmPasswordResetFirebase(getAuth(), actionCode, newPassword)
}

export async function verifyEmail(actionCode: string) {
  return applyActionCode(getAuth(), actionCode)
}

export async function updatePassword(oldPassword: string, newPassword: string) {
  const user = getAuth().currentUser
  if (user && user.email) {
    const credential = EmailAuthProvider.credential(user.email, oldPassword)
    try {
      await reauthenticateWithCredential(user, credential)
    } catch (e) {
      if (e.message.includes("auth/too-many-requests"))
        throw new Error("password too many attempts")
      throw new Error("wrong old password provided")
    }
    try {
      await updatePasswordFirebase(user, newPassword)
    } catch (e) {
      throw new Error("Something bad happened")
    }
  } else {
    throw new Error(`User is disconnected.`)
  }
}
