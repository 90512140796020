import useUsersStats from "graphql/stats/useUsersStats"
import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import AdminUsersStatsTable from "pages/StatisticsPage/TeamStats/components/AdminUsersStatsTable"
import React, { useEffect, useState } from "react"
import styled from "styled-components/macro"
import { Button } from "ui"
import UTCDate from "utils/UTCDate"

interface TablePropsType {
  maxHeight?: number
}

const Layout = styled.div<TablePropsType>`
  display: grid;
  grid-template-rows: auto 1fr auto;
  padding-top: 18px;
  padding-bottom: 18px;
  ${({ maxHeight }) => `height: calc(100vh - ${maxHeight}px);`}
`

const Footer = styled.div`
  padding-top: 8px;
`

const Table = styled.div<TablePropsType>`
  overflow-y: scroll;
`

const RESULTS_PER_PAGE = 15

interface PropsType {
  from: UTCDate
  to: UTCDate
  pageHeaderHeight: {
    header?: number | undefined
    tabsHeader?: number | undefined
  }
  variant: "admin" | "team"
}

export default function PWStatsUserStats({
  from,
  to,
  pageHeaderHeight,
  variant,
}: PropsType) {
  const t = useTranslate()
  const { user } = useUser()

  const maxHeight =
    pageHeaderHeight.header !== undefined &&
    pageHeaderHeight.tabsHeader !== undefined
      ? pageHeaderHeight.header + pageHeaderHeight.tabsHeader
      : undefined

  const [orderBy, setOrderBy] = useState("order by email")

  const { loading, usersStats, start, fetchMore, canFetchMore, hasSearched } =
    useUsersStats(variant, RESULTS_PER_PAGE)

  useEffect(() => {
    start(from, to, orderBy)
  }, [start, orderBy, from, to])

  if (user?.role !== "admin") return null
  return (
    <Layout maxHeight={maxHeight}>
      <Table>
        {hasSearched && (
          <AdminUsersStatsTable
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            usersStats={usersStats}
            fetchMore={fetchMore}
            canFetchMore={canFetchMore}
            loading={loading}
          />
        )}
      </Table>
      <Footer>
        {canFetchMore && !loading && (
          <Button
            secondary
            disabled={loading || !canFetchMore}
            onClick={() => {
              if (canFetchMore) fetchMore()
            }}
          >
            {t("View more results")}
          </Button>
        )}
      </Footer>
    </Layout>
  )
}
