import React from "react"
import { Grid } from "ui"

import SignInWithGoogleButton from "./SignInWithGoogleButton"
import SignInWithMicrosoftButton from "./SignInWithMicrosoftButton"
import SignInWithOktaButton from "./SignInWithOktaButton"
import SignInWithSSOButton from "./SignInWithSSOButton"

export default function SSOButtons() {
  return (
    <>
      <Grid container direction="column">
        <SignInWithMicrosoftButton />
        <SignInWithGoogleButton />
        <SignInWithOktaButton />
      </Grid>
      <Grid item xs={12}>
        <SignInWithSSOButton />
      </Grid>
    </>
  )
}
