import {
  ApolloCache,
  DefaultContext,
  DocumentNode,
  MutationFunctionOptions,
  MutationHookOptions,
  OperationVariables,
  TypedDocumentNode,
  useMutation,
} from "@apollo/client"
import { useCallback } from "react"
// import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

export default function useProtectedMutation<
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>
>(
  name: string,
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables, TContext>
) {
  // const { executeRecaptcha } = useGoogleReCaptcha()
  const [mutate] = useMutation<TData, TVariables, TContext, TCache>(
    mutation,
    options
  )
  const execute = useCallback(
    async (
      options: MutationFunctionOptions<TData, TVariables, TContext, TCache>
    ) => {
      /* if (!executeRecaptcha) {
        throw new Error(`Google ReCaptcha not ready`)
      }*/
      // const token = await executeRecaptcha(name)
      return mutate({
        ...options,
        /*context: {
          headers: {
            "g-recaptcha-response": token,
          },
        } as any, // force context,*/
      })
    },
    [/*name, executeRecaptcha, */ mutate]
  )
  return execute
}
