import useTranslate from "intl/useTranslate"
import React from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { Button, colors, P14, P16 } from "ui"
import LocationTree from "utils/LocationTree"

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: ${colors.grey4};
  border-radius: 8px;
  border: solid 1px ${colors.grey3};
  .message {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 70%;
    text-align: center;
  }
`

const ToolBar = styled.nav`
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
  flex-wrap: wrap;
  border-bottom: 1px solid ${colors.grey3};
  border-radius: 5px 5px 0 0;
  background: ${colors.white};

  .left-side {
    display: flex;
    align-items: left;
  }
`

interface PropsType {
  zones: LocationTree[]
  selectedZone: LocationTree | undefined
  activeFloorPlan: LocationTree | undefined
}

export default function FloorPlanMessage({
  zones,
  selectedZone,
  activeFloorPlan,
}: PropsType) {
  const t = useTranslate()
  const history = useHistory()

  const companyHasNoZone = zones.length === 0
  const emptyFloorFlanSelection = activeFloorPlan === undefined

  const getMessageWithAction = () => {
    if (companyHasNoZone)
      return {
        message: t("floor plan only works within zone"),
        action: {
          title: t("go create some zone"),
          fn: () => history.push("/admin/locations"),
        },
      }
    if (emptyFloorFlanSelection)
      return {
        message: t("select location on left panel, or add floor plan"),
        action: undefined,
      }

    return { message: "", action: undefined }
  }

  const { message, action } = getMessageWithAction()

  return (
    <>
      <Layout>
        {selectedZone && (
          <ToolBar>
            <div className="left-side">{selectedZone.name}</div>
            <div className="right-side">
              <P14 color="grey2">{t("no floor plan")}</P14>
            </div>
          </ToolBar>
        )}
        <div className="message">
          <P16>{message}</P16>
          {action && <Button onClick={action.fn}>{action.title}</Button>}
        </div>
      </Layout>
    </>
  )
}
