import styled from "styled-components"

export const ProgressBar = styled.progress<{
  backgroundColor: string
  foreGroudColor: string
}>`
  ${({ backgroundColor, foreGroudColor }) => `
  --bg: ${backgroundColor};
  --fg: ${foreGroudColor};
`}
  // background
  background: var(--bg);
  border-radius: 100vh;
  border: solid 1px none;

  &::-webkit-progress-bar {
    background: var(--bg);
    border-radius: 100vh;
  }
  //foreground
  &::-webkit-progress-value {
    background: var(--fg);
    border-radius: 100vh;
  }
  &::-moz-progress-bar {
    background: var(--fg);
    border-radius: 100vh;
  }
`
