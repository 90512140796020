import useTranslate from "intl/useTranslate"
import React from "react"
import { Button } from "ui"

interface SaveProps {
  confirmChanges: () => void
}

export default function SaveButton({ confirmChanges }: SaveProps) {
  const t = useTranslate()
  return <Button onClick={confirmChanges}>{t("Save")}</Button>
}
