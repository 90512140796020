import "moment/locale/fr"

import useSetUserLocale from "graphql/settings/useSetUserLocale"
import useTranslate from "intl/useTranslate"
import moment from "moment"
import React from "react"
import { DropDown } from "ui"

import getTranslation from "../intl/translations/getTranslation"
import useTranslation, {
  readLocaleFromLocalStorage,
} from "../intl/useTranslation"
const availableTranslations = [getTranslation("fr"), getTranslation("en")]

moment.updateLocale(readLocaleFromLocalStorage(), { week: { dow: 1 } })

export default function LocaleSelector() {
  const { setUserLocale } = useSetUserLocale()
  const t = useTranslate()
  const [translation, setLocale] = useTranslation()
  const handleChange = (obj: { name: string; value: any }) => {
    if (obj.value === "fr") {
      moment.updateLocale("fr", { week: { dow: 1 } })
    } else {
      moment.updateLocale("en", { week: { dow: 1 } })
    }
    setLocale(obj.value as string)
    setUserLocale(obj.value as string)
  }

  return (
    <>
      <DropDown
        title={translation.id === "fr" ? t("French") : t("English")}
        options={[
          ...availableTranslations.map((tr) => ({
            name: tr.id === "fr" ? t("French") : t("English"),
            value: tr.id,
          })),
        ]}
        onSelect={handleChange}
      />
    </>
  )
}
