import AdvancedSearchPopUp from "components/AdvancedSearchPopUp/AdvancedSearchPopUp"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import { ChipButton } from "ui"

export default function DesktopAdvancedSearchButton() {
  const t = useTranslate()

  const [openSearchPopUp, setOpenSerachPopup] = useState(false)

  return (
    <>
      <div>
        <ChipButton onClick={() => setOpenSerachPopup(true)}>
          {t("Advanced Search")}
        </ChipButton>
      </div>
      {openSearchPopUp && (
        <AdvancedSearchPopUp
          open={openSearchPopUp}
          onClose={() => setOpenSerachPopup(false)}
        />
      )}
    </>
  )
}
