import { gql, useApolloClient } from "@apollo/client"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useTranslate from "intl/useTranslate"
import LocalDate from "utils/LocalDate"

const QUERY = gql`
  query setPlanningReminderAt($date: LocalDate!) {
    setPlanningReminderAt(date: $date)
  }
`

export default function useSetPlanningReminderAt(): (
  date: LocalDate
) => Promise<void> {
  const client = useApolloClient()
  const { addToast } = useToasts()
  const t = useTranslate()
  return async (date: LocalDate) => {
    try {
      await client.query({
        query: QUERY,
        variables: {
          date,
        },
        fetchPolicy: "no-cache",
      })
      addToast(t("reminder has been saved"), { appearance: "success" })
    } catch (error) {
      addToast(error.message, { appearance: "error" })
      console.error(error)
      return
    }
  }
}
