import React from "react"
import styled from "styled-components"
import { UTCSlot, ViewModeType } from "types"
import { mediaQueries } from "ui"

import UserSlotEntry from "./UserSlotEntry/UserSlotEntry"
import UserSlotEntryDayView from "./UserSlotEntry/UserSlotEntryDayView"
interface SlotsListLayoutPropsType {
  gridSize: number
}

const SlotsListLayout = styled.div<SlotsListLayoutPropsType>`
  display: grid;
  padding: 0 11px 0 11px;

  ${({ gridSize }) => {
    return `grid-template-columns: repeat(${gridSize}, 1fr);`
  }}

  align-items: center;

  .slot-item {
    height: 40px;
    @media ${mediaQueries.isMobile} {
      height: 46px;
    }
  }

  @media ${mediaQueries.isMobile} {
    padding: 0 8px;
  }
`

interface SlotsListLayoutPropsType {
  gridSize: number
  isMe?: boolean
  viewMode: ViewModeType
}

const SlotsDayListLayout = styled.div<SlotsListLayoutPropsType>`
  display: grid;
  ${({ gridSize }) => {
    return `grid-template-columns: repeat(${gridSize}, 1fr);`
  }}

  align-items: center;

  ${({ isMe, viewMode }) => {
    // 24 is tht interactions button width
    if (isMe && viewMode === "DAY") return `margin-right: 36px;`
    else if (!isMe && viewMode === "DAY") return `margin-right: 12px;`
  }}

  .slot-item {
    width: 70px;
    height: 40px;
  }
`

interface PropsType {
  slots: UTCSlot[]
  focusedSlot: UTCSlot | undefined
  viewMode: ViewModeType
  isMe?: boolean
  setFocusedSlot: (slot: UTCSlot | undefined) => void
}

/**
 * Display the line of one user (not the current user)
 */
export default function UserSlots({
  slots,
  focusedSlot,
  viewMode,
  isMe,
  setFocusedSlot,
}: PropsType) {
  if (viewMode === "DAY")
    return (
      <SlotsDayListLayout
        gridSize={slots.length}
        isMe={isMe}
        viewMode={viewMode}
      >
        {slots.map((slot, i) => (
          <UserSlotEntryDayView
            key={slot.id}
            slot={slot}
            slotIndex={i}
            slots={slots}
          />
        ))}
      </SlotsDayListLayout>
    )
  return (
    <SlotsListLayout gridSize={slots.length} viewMode={viewMode}>
      {slots.map((slot, i) => (
        <UserSlotEntry
          key={slot.id}
          slot={slot}
          slotIndex={i}
          slots={slots}
          focusedSlot={focusedSlot}
          setFocusedSlot={setFocusedSlot}
        />
      ))}
    </SlotsListLayout>
  )
}
