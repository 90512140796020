import LocationsList from "components/Collapses/LocationsCollapse/LocationsList"
import VisualAreaEditor, {
  VisualEditorMode,
} from "components/FloorPlanComponents/VisualAreaEditor/VisualAreaEditor"
import { PageContentWithRef } from "components/Layout/PageLayout/PageContent/PageContent"
import AdminPageTitle from "components/Layout/PageLayout/PageHeader/AdminPageTitle/AdminPageTitle"
import PageHeader from "components/Layout/PageLayout/PageHeader/PageHeader"
import PageLayout from "components/Layout/PageLayout/PageLayout"
import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import useFloorVisualArea from "graphql/floorPlans/useFloorVisualArea"
import useLocationsTree from "graphql/locations/useLocationsTree"
import useTranslate from "intl/useTranslate"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import colors from "ui/colors"
import { hideScrollBarsCSS } from "ui/utilities"
import LocationTree from "utils/LocationTree"

import FloorPlanMessage from "./FloorPlanMessage/FloorPlanMessage"

const ContentLayout = styled.div<{ maxHeight?: number }>`
  display: grid;
  grid-template-columns: 320px 1fr;
  padding-right: 8px;
  gap: 16px;
  ${({ maxHeight }) => {
    if (maxHeight)
      return `
    overflow: hidden;
    height: calc(${maxHeight}px - 20px); // 20px = padding bottom of page content
    max-height: calc(${maxHeight}px - 20px); // same reason
    `
  }};
`

const ListLayout = styled.ul`
  display: flex;
  flex-direction: column;
  background-color: ${colors.grey4};
  border: 1px solid ${colors.grey3};
  border-radius: 5px;
  overflow-y: scroll;

  ${hideScrollBarsCSS}
`
interface SelectionInfo {
  activeFloorPlan?: LocationTree
  activeSublocation?: LocationTree
}

/*
 * This recursively searches the locations tree for the location
 * containing the floor plan we are currently editing,
 * as well as the sub-location which visual area we're
 * currently drawing.
 */
function computeSelectionInfo(
  locations: LocationTree[],
  id: string,
  floorPlan: LocationTree | null = null,
  level = 0
): SelectionInfo | null {
  for (const location of locations) {
    if (location.id === id) {
      if (location.floorPlanImageUrl) {
        return {
          activeFloorPlan: location,
        }
      } else if (floorPlan) {
        return {
          activeFloorPlan: floorPlan,
          activeSublocation: location,
        }
      } else {
        return {}
      }
    }
    const found = computeSelectionInfo(
      location.children,
      id,
      location.floorPlanImageUrl ? location : floorPlan,
      location.floorPlanImageUrl || !floorPlan ? 0 : level + 1
    )
    if (found) {
      return found
    }
  }
  return null
}

export default function FloorPlansAdminPage() {
  const t = useTranslate()

  const [contentRef, setContentRef] = useState<HTMLDivElement | null>(null)
  const [maxHeight, setMaxheight] = useState<number | undefined>(undefined)

  const { locations, loading: loadingLocations } = useLocationsTree(false)

  const breadcrumb = [
    {
      name: t("Admin"),
      path: "/admin",
    },
    {
      name: t("Floor plans"),
      path: "/admin/floor-plans",
    },
  ]

  const [selectionId, setSelectionId] = useState<string | null>(null)

  const selectionInfo = selectionId
    ? computeSelectionInfo(locations, selectionId) || {}
    : {}

  const [selectedZone, setSelectedZone] = useState<LocationTree | undefined>(
    undefined
  )

  const getCompanyZones = (
    root: LocationTree[],
    companyZones: LocationTree[] = []
  ) => {
    for (const location of root) {
      if (location.isCategory) {
        companyZones.push(location)
        getCompanyZones(location.children, companyZones)
      }
    }
    return companyZones
  }

  const showVisualEditor =
    getCompanyZones(locations).length > 0 && selectionInfo.activeFloorPlan

  useEffect(() => {
    setMaxheight(contentRef?.getBoundingClientRect().height)
  }, [contentRef])

  const { area, loading } = useFloorVisualArea(
    selectionInfo.activeFloorPlan?.id
  )

  if (loadingLocations)
    return <LoaderOrErrorComponent loading={loadingLocations} error={null} />

  return (
    <PageLayout>
      <PageHeader>
        <AdminPageTitle breadcrumbs={breadcrumb} title={t("Floor plans")} />
      </PageHeader>
      <PageContentWithRef ref={setContentRef}>
        <ContentLayout maxHeight={maxHeight}>
          <ListLayout>
            <LocationsList
              withActions={false}
              showArchivedLocations
              floorPlan={{
                select: setSelectionId,
                activeFloorPlan: selectionInfo.activeFloorPlan,
                activeSublocation: selectionInfo.activeSublocation,
                visualArea: area,
                parentHasFloorPlan: false,
                isPartOfActiveFloorPlan: false,
                mode: VisualEditorMode.EDITOR,
              }}
              selectedZone={selectedZone}
              setSelectedZone={setSelectedZone}
            />
          </ListLayout>

          {showVisualEditor ? (
            <VisualAreaEditor
              location={selectionInfo.activeFloorPlan!}
              activeSublocation={selectionInfo.activeSublocation}
              setSelectedLocationId={setSelectionId}
              area={area}
              loadingArea={loading}
              mode={VisualEditorMode.EDITOR}
            />
          ) : (
            <FloorPlanMessage
              zones={getCompanyZones(locations)}
              selectedZone={selectedZone}
              activeFloorPlan={selectionInfo.activeFloorPlan}
            />
          )}
        </ContentLayout>
      </PageContentWithRef>
    </PageLayout>
  )
}
