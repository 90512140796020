import { gql, useQuery } from "@apollo/client"
import UTCDate from "utils/UTCDate"

const SlotGuestsFragment = gql`
  fragment SlotGuestsFragment on SlotGuests {
    id
    date
    guestsEmails
  }
`

const USER_SLOTS_GUESTS_QUERY = gql`
  ${SlotGuestsFragment}
  query userSlotsGuests($userId: ID!, $from: String!, $to: String!) {
    userSlotsGuests(userId: $userId, from: $from, to: $to) {
      ...SlotGuestsFragment
    }
  }
`

interface QueryData {
  userSlotsGuests: {
    id: string
    date: string
    guestsEmails: string[]
  }[]
}

interface QueryVariables {
  userId: string
  from: string
  to: string
}

export default function useUserSlotsGuests({
  userId,
  from,
  to,
}: {
  userId: string
  from: UTCDate
  to: UTCDate
}) {
  const { data, loading, error } = useQuery<QueryData, QueryVariables>(
    USER_SLOTS_GUESTS_QUERY,
    {
      variables: {
        userId,
        from: `${from.getTime()}`,
        to: `${to.getTime()}`,
      },
      fetchPolicy: "network-only",
    }
  )

  return {
    userSlotsGuests: data?.userSlotsGuests ?? [],
    loading,
    error,
  }
}
