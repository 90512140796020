import MobileHeader from "components/MobileHeader/MobileHeader"
import CalendarDays from "pages/PlanningPage/components/CalendarDays/CalendarDays"
import PlanningNavigation from "pages/PlanningPage/components/PlanningNavigation/PlanningNavigation"
import React from "react"
import { useTeamsAppContext } from "TeamsApp/TeamsAppContext"
import { UserBase } from "types"

import EditableUserSlots from "../../../components/EditableUserSlots/EditableUserSlots"
import { UserCalendarContainer } from "./components/components"
import PlanningHeadersRouter from "./PlanningHeadersRouter/PlanningHeadersRouter"

interface PropsType {
  usersFromRouter: UserBase[]
}

export default function PlanningPageHeader({ usersFromRouter }: PropsType) {
  const { isTeamsApp } = useTeamsAppContext()

  return (
    <div>
      {!isTeamsApp && <MobileHeader />}
      <PlanningNavigation />
      <UserCalendarContainer>
        <CalendarDays />
        <div
          style={{
            position: "relative",
            zIndex: 20,
            width: "100%",
          }}
        >
          <EditableUserSlots />
        </div>
      </UserCalendarContainer>

      <PlanningHeadersRouter usersFromRouter={usersFromRouter} />
    </div>
  )
}
