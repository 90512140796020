import { LinearProgress } from "@mui/material"
import IntegrationLayout from "components/IntegrationsLayout/IntegrationLayout"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useCheckCompanyIntegration from "graphql/integrations/useCheckCompanyIntegration"
import useRevokeCompanyIntegration from "graphql/integrations/useRevokeCompanyIntegration"
import useSetupCompanyIntegration from "graphql/integrations/useSetupCompanyIntegration"
import useTranslate from "intl/useTranslate"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { CompanyIntegration } from "types"
import { Button, P16 } from "ui"
import { Microsoft } from "ui/icons"
import getUrlSearchParam from "utils/getUrlSearchParam"

const TextContainer = styled.div`
  margin-left: 12px;
  margin-bottom: 18px;
`
interface PropsType {
  integration?: CompanyIntegration
}

export default function MicrosoftIntegrationComponent({
  integration,
}: PropsType) {
  const t = useTranslate()
  const history = useHistory()

  const setupCompanyIntegration = useSetupCompanyIntegration()
  const revoke = useRevokeCompanyIntegration()
  const { addToast } = useToasts()

  const [loading, setLoading] = useState(false)

  const shouldSkip =
    integration?.name === undefined || getUrlSearchParam("code") !== null
  const { result, loading: checkLoading } = useCheckCompanyIntegration(
    integration?.name,
    shouldSkip
  )
  const error = result !== "success"

  // Display an error if any
  useEffect(() => {
    if (window.location.search.includes("error="))
      addToast(
        `${t("error while connecting to Microsoft")} : ${getUrlSearchParam(
          "error"
        )}`,
        {
          appearance: "error",
        }
      )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // setup the tenant id if success
  useEffect(() => {
    const success = window.location.search.includes(
      "admin_consent=True&tenant="
    )
    if (
      success &&
      localStorage.getItem("integration-type") === "microsoft-outlook"
    ) {
      const externalId = getUrlSearchParam("tenant")
      if (externalId) {
        setLoading(true)
        setupCompanyIntegration({
          name: "microsoft-outlook",
          externalId,
          flags: {
            forceRefresh: !integration,
          },
        }).then(() => {
          setLoading(false)
          history.replace("/admin/integrations")
        })
      } else {
        addToast(t("error while connecting to Microsoft"), {
          appearance: "error",
        })
      }
      localStorage.removeItem("integration-type")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <IntegrationLayout
      connected={Boolean(integration)}
      title="Microsoft Outlook"
    >
      {!error && integration && (
        <>
          <TextContainer>
            <P16>{t("offishall linked to microsoft")}</P16>
            <P16>
              {t("your microsoft tenant id").replace(
                "{externalId}",
                integration.externalId
              )}
            </P16>
          </TextContainer>
          <Button
            secondary
            icon={<Microsoft />}
            onClick={() => {
              revoke({ name: integration.name })
            }}
          >
            {t("revoke access")}
          </Button>
        </>
      )}
      {(error || !integration) && (
        <TextContainer>
          <P16>{t("offishall not linked to microsoft")}</P16>
        </TextContainer>
      )}
      {(error || !integration) && (
        <Button
          disabled={loading || checkLoading}
          secondary
          icon={<Microsoft />}
          onClick={() => {
            localStorage.setItem("integration-type", "microsoft-outlook")
            // we can optionnaly pass a "&state=some_state" option to that url
            location.href = `https://login.microsoftonline.com/common/adminconsent?client_id=${process.env.REACT_APP_OUTLOOK_CLIENT_ID}&redirect_uri=${window.location.origin}/admin/integrations`
          }}
        >
          {t("authorize offishall access microsoft")}
        </Button>
      )}
      {loading || (checkLoading && <LinearProgress />)}
    </IntegrationLayout>
  )
}
