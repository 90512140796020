import useClearCache from "hooks/useClearCache"
import useTemporaryLocaleSetter from "hooks/useTemporaryLocaleSetter"
import React from "react"

import PlanningPageRouter from "./PlanningPageRouter"

export default function PlanningPage() {
  useClearCache()
  useTemporaryLocaleSetter()

  return (
    <>
      <PlanningPageRouter />
    </>
  )
}
