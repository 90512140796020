import useMultiUserSlots from "graphql/slots/useMultiUserSlots"
import useUser from "graphql/users/useUser"
import { UserWithDailySlots } from "types"
import UTCDate from "utils/UTCDate"

export default function useTeamUsersWithDailySlots(AM: UTCDate, PM: UTCDate) {
  const { user: me } = useUser()
  const usersId = me ? me.team.map((user) => user.id) : []
  const { slots } = useMultiUserSlots(usersId, AM, PM)

  const usersWithSlots = me
    ? me.team.map((user): UserWithDailySlots => {
        const userSlots = slots.filter((slot) => slot.userId === user.id)

        const AMSlot = userSlots.length > 0 ? userSlots[0] : null
        const PMSlot = userSlots.length > 1 ? userSlots[1] : null

        const AM = AMSlot?.value ?? null
        const PM = PMSlot?.value ?? null

        const available = !(
          AM === "ooc" ||
          AM === "off" ||
          AM === "else" ||
          PM === "ooc" ||
          PM === "off" ||
          PM === "else"
        )

        return {
          ...user,
          AMSlot: AMSlot ?? null,
          PMSlot: PMSlot ?? null,
          available,
        }
      })
    : []

  return usersWithSlots
}
