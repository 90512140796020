import useCompletionRate from "graphql/stats/useCompletionRate"
import React, { useEffect, useState } from "react"
import { GroupSimple } from "types"
import UTCDate from "utils/UTCDate"

interface PropsType {
  group: GroupSimple
  from: UTCDate
  to: UTCDate
  includeWeekends: boolean
  groupsCompletionRate: {
    group: GroupSimple
    completionRate: number
  }[]
  updateGroupsCompletionRate: (
    {
      group,
      completionRate,
    }: {
      group: GroupSimple
      completionRate: number
    },
    remove?: boolean
  ) => void
}

export default function GroupCompletionRateBar({
  from,
  to,
  includeWeekends,
  group,
  groupsCompletionRate,
  updateGroupsCompletionRate,
}: PropsType) {
  const { completionRate, loading, error } = useCompletionRate({
    from,
    to,
    userIds: [],
    groupIds: [group.id],
    includeWeekends,
  })

  const [previousTimeFrame, setPreviousTimeFrame] = useState<
    { from: UTCDate; to: UTCDate; includeWeekends: boolean } | undefined
  >(undefined)

  useEffect(() => {
    if (loading || error) return

    const timeFrameHasChanged =
      previousTimeFrame?.from.getTime() !== from.getTime() ||
      previousTimeFrame?.to.getTime() !== to.getTime() ||
      previousTimeFrame?.includeWeekends !== includeWeekends

    const previousCompletionRate = groupsCompletionRate.find(
      (gcr) => gcr.group.id === group.id
    )
    if (previousCompletionRate === undefined || timeFrameHasChanged) {
      // update parent dataset with this data if:
      // * this data does not exist in parent dataset yet
      // * parent time frame has changed
      updateGroupsCompletionRate({ group, completionRate })
      setPreviousTimeFrame({ from, to, includeWeekends })
    }
  }, [
    loading,
    error,
    group,
    completionRate,
    groupsCompletionRate,
    updateGroupsCompletionRate,
    from,
    to,
    includeWeekends,
    previousTimeFrame,
  ])

  useEffect(() => {
    return () => updateGroupsCompletionRate({ group, completionRate }, true)
    // eslint-disable-next-line
  }, [])

  return <></>
}
