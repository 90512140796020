import { ApolloError, gql, useQuery } from "@apollo/client"
import { LocationSuggestion } from "types"
import UTCDate from "utils/UTCDate"

const QUERY = gql`
  query locationSuggestions(
    $afterTimestamp: String!
    $beforeTimestamp: String!
    $locationId: String
    $groupId: String
  ) {
    locationSuggestions(
      afterTimestamp: $afterTimestamp
      beforeTimestamp: $beforeTimestamp
      locationId: $locationId
      groupId: $groupId
    ) {
      id
      locationId
      groupId
      reason
      value
    }
  }
`

export default function useLocationSuggestions({
  from,
  to,
  locationId,
  groupId,
}: {
  from: UTCDate
  to: UTCDate
  locationId?: string
  groupId?: string // id, or "myteam" or "mymanagerteam" or "everyone" or "favorites"
}): {
  loading: boolean
  error?: ApolloError
  suggestions: LocationSuggestion[]
} {
  const { loading, error, data } = useQuery<{
    locationSuggestions: LocationSuggestion[]
  }>(QUERY, {
    variables: {
      afterTimestamp: from.getTime().toString(),
      beforeTimestamp: to.getTime().toString(),
      locationId,
      groupId,
    },
  })

  return {
    suggestions: data?.locationSuggestions || [],
    loading,
    error,
  }
}
