import { gql } from "@apollo/client"

export const CompanyFileFragment = gql`
  fragment CompanyFileFragment on CompanyFile {
    id
    name
    rawName
    url
    type
    companyId
  }
`
