import "moment/locale/fr"

import useSetCompanyLocale from "graphql/settings/useSetCompanyLocale"
import useUser from "graphql/users/useUser"
import getTranslation from "intl/translations/getTranslation"
import useTranslate from "intl/useTranslate"
import React from "react"
import { DropDown } from "ui"
const availableTranslations = [getTranslation("fr"), getTranslation("en")]
import styled from "styled-components"
import { colors, P14, P16 } from "ui"

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`

export default function CompanyLocaleSelector() {
  const t = useTranslate()
  const { setCompanyLocale } = useSetCompanyLocale()
  const handleChange = (obj: { name: string; value: any }) => {
    setCompanyLocale(obj.value as string)
  }

  const { user } = useUser()

  const currentCompanyLocale = user?.company?.flags?.locale || "en"

  return (
    <>
      <Container>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <P16 className="title">{t("company-settings-locale-title")}</P16>
          <P14 style={{ color: colors.grey1, marginTop: 5 }}>
            {t("company-settings-locale-description")}
          </P14>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <DropDown
            title={currentCompanyLocale === "fr" ? t("French") : t("English")}
            options={[
              ...availableTranslations.map((tr) => ({
                name: tr.id === "fr" ? t("French") : t("English"),
                value: tr.id,
              })),
            ]}
            onSelect={handleChange}
          />
        </div>
      </Container>
    </>
  )
}
