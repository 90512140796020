import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import useLocationsTree from "graphql/locations/useLocationsTree"
import OccupancyRatePerDayChart from "pages/StatisticsPage/OccupancyRate/OccupancyRatePerDayChart/OccupancyRatePerDayChart"
import React from "react"
import styled from "styled-components"
import { getChildrenLocations } from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

const Layout = styled.div<{ alreadyOccupiedHeight?: number }>`
  margin-top: 12px;

  ${({ alreadyOccupiedHeight }) => {
    if (alreadyOccupiedHeight)
      return `
      height: calc(100vh - ${alreadyOccupiedHeight}px);
      max-height: calc(100vh - ${alreadyOccupiedHeight}px);
    `
  }}
`

interface PropsType {
  from: UTCDate
  to: UTCDate
  enableWeekends: boolean
  pageHeaderHeight: { header?: number; tabsHeader?: number }
}

export default function PWStatsOccupancyRate({
  from,
  to,
  enableWeekends,
  pageHeaderHeight,
}: PropsType) {
  const { locations, loading, error } = useLocationsTree()
  const allLocationsFlatten = getChildrenLocations(locations, false)

  const extraBottomPadding = 64
  const alreadyOccupiedHeight =
    pageHeaderHeight.header !== undefined &&
    pageHeaderHeight.tabsHeader !== undefined
      ? pageHeaderHeight.header +
        pageHeaderHeight.tabsHeader +
        extraBottomPadding
      : undefined

  if (loading || error)
    return <LoaderOrErrorComponent loading={loading} error={error} />

  return (
    <Layout alreadyOccupiedHeight={alreadyOccupiedHeight}>
      <OccupancyRatePerDayChart
        bypassLocationIds={true}
        showDownloadCSV={false}
        selectedLocations={[]}
        allLocationsFlatten={allLocationsFlatten}
        from={from}
        to={to}
        includeWeekends={enableWeekends}
        showAllLocationsBar={true}
      />
    </Layout>
  )
}
