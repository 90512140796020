import useUser from "graphql/users/useUser"

export default function useUserFlag(flag: string, defaultValue?: any) {
  const { user } = useUser()
  const value = user?.flags[flag]
  if (value !== undefined) {
    return value
  }
  return defaultValue
}
