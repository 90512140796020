import CategoryDropDown from "components/LocationComponents/CategoryDropDown/CategoryDropDown"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useCategoryMutations from "graphql/locations/useCategoryMutations"
import useLocationsTree from "graphql/locations/useLocationsTree"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { InputCategoryWithParentId } from "types"
import { AdminPopup, Button, HeaderTextField } from "ui"
import { LOCATION_NAME_MAX_LENGTH } from "utils/constants"
import LocationTree, { getChildrenLocations } from "utils/LocationTree"

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 18px;
`

interface PropsType {
  open: boolean
  categoryToMutate?: LocationTree
  initialInputCategory?: { name: string } // category not in db yet but some infos are known
  onClose: () => void
}

export default function UpdateCategoryDialog({
  open,
  categoryToMutate,
  initialInputCategory,
  onClose,
}: PropsType) {
  const t = useTranslate()
  const { addToast } = useToasts()

  const { locations } = useLocationsTree()
  const categories = LocationTree.getCategories(locations)

  const validParents = categories.filter((c) =>
    // not self nor any of its children
    {
      if (categoryToMutate) {
        return (
          c.id !== categoryToMutate.id &&
          !getChildrenLocations([categoryToMutate], false).find(
            (child) => child.id === c.id
          )
        )
      }
      return []
    }
  )

  const { updateCategory, addCategory } = useCategoryMutations()

  const initialNewCategory = {
    name: "",
    shortname: "",
    capacity: 1,
    bookable: 1,
    archived: false,
    parentId: null,
    reservedTo: {
      users: [],
      groups: [],
    },
  }

  const getInitialCategory = ({
    categoryToMutate,
    initialInputCategory,
  }: {
    categoryToMutate?: LocationTree
    initialInputCategory?: { name: string }
  }): InputCategoryWithParentId => {
    if (categoryToMutate) return { ...categoryToMutate }
    if (initialInputCategory)
      return { ...initialNewCategory, ...initialInputCategory }
    return initialNewCategory
  }

  const [newCategory, setNewCategory] = useState<InputCategoryWithParentId>(
    getInitialCategory({ categoryToMutate, initialInputCategory })
  )

  const parentCategory = categories.find((l) => l.id === newCategory.parentId)

  const onCancel = () => {
    setNewCategory(initialNewCategory)
    onClose()
  }

  const canAddOrUpdate = (() => {
    const errors = []

    if (categoryToMutate) {
      if (
        categoryToMutate.parentId === newCategory.parentId ||
        (!categoryToMutate.parentId && newCategory.parentId === null)
      )
        errors.push("zone parentId not changed")
      if (categoryToMutate.name === newCategory.name)
        errors.push("zone name unchanged")
      // at least one value should have changed
      return !Boolean(errors.length === 2)
    }

    if (newCategory.name.length < 2) errors.push("zone name too short")
    return !Boolean(errors.length)
  })()

  return (
    <AdminPopup
      open={open}
      onClose={onCancel}
      title={categoryToMutate ? t("Update a zone") : t("Add a zone")}
      rightButton={
        <Button
          disabled={!canAddOrUpdate}
          onClick={() => {
            if (canAddOrUpdate && categoryToMutate) {
              updateCategory(
                categoryToMutate.id,
                newCategory.name,
                newCategory.parentId
              ).then(() =>
                addToast(t("edition confirmation"), { appearance: "success" })
              )
              onCancel()
            }

            if (canAddOrUpdate && !categoryToMutate) {
              addCategory(newCategory.name, newCategory.parentId).then(() =>
                addToast(t("new zone confirmation"), { appearance: "success" })
              )
              onCancel()
            }
          }}
        >
          {categoryToMutate ? t("update") : t("Save")}
        </Button>
      }
    >
      <Form onSubmit={(e) => e.preventDefault()}>
        <HeaderTextField
          placeholder={t("Zone name")}
          maxLength={LOCATION_NAME_MAX_LENGTH}
          autoFocus
          initialValue={newCategory.name}
          handleChange={(name) => setNewCategory((prev) => ({ ...prev, name }))}
        />

        <CategoryDropDown
          defaultValue={
            parentCategory
              ? [...parentCategory.breadCrumbs, parentCategory.name].join(" / ")
              : t("no parent zone")
          }
          validParents={validParents}
          onSelect={({ value }) => {
            setNewCategory((prev) => ({ ...prev, parentId: value.parentId }))
          }}
        />
      </Form>
    </AdminPopup>
  )
}
