import { ProgressBar } from "components/ProgressBar/ProgressBar"
import styled from "styled-components"

export const MeetingProgressBar = styled(ProgressBar)<{
  duration: number
  from: number
}>`
  position: absolute;
  width: 32px;
  height: 4px;
  top: 8px;
  // margin left to set the progress bar
  // at the end of absolute positioned blue section
  // * 48px is extra right padding
  // * duration is event duration in minutes
  // * from is event from mintues
  // * event x postion can been shifted by "from" amount
  ${({ duration, from }) => `
    margin-left: calc((${(2 * (duration + from)) / 60 - 1} * 100%) - 42px);
  `}
  border: none;
  border-radius: 100vh;
  z-index: 2;
`
