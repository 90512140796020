import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function ChatBubbleQuestion({
  stroke,
  strokeWidth,
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      strokeWidth={strokeWidth ?? 1.5}
      stroke={stroke ? stroke : "currentColor"}
      {...rest}
    >
      <path
        d="M9 9C9 5.49997 14.5 5.5 14.5 9C14.5 11.5 12 10.9999 12 13.9999M12 18.01L12.01 17.9989M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 13.8214 2.48697 15.5291 3.33782 17L2.5 21.5L7 20.6622C8.47087 21.513 10.1786 22 12 22Z"
        fill="#fff"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}
