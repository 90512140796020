import { ApolloError, gql, useMutation } from "@apollo/client"
import { useState } from "react"

import {
  AdminUserStatFragment,
  UserAdminBaseFragment,
  UserBaseFragment,
  UserFragment,
} from "../users/fragments/userFragment"

const UPDATE_USER_MUTATION = gql`
  ${UserFragment}
  mutation updateUser($id: ID!, $disabled: Boolean!, $role: String!) {
    updateUser(id: $id, disabled: $disabled, role: $role) {
      ...UserFragment
    }
  }
`
export default function useUpdateUser() {
  const [mutate] = useMutation(UPDATE_USER_MUTATION)
  const [error, setError] = useState<ApolloError | null>(null)
  const [loading, setLoading] = useState(false)
  const updateUser = async (id: string, role: string, disabled: boolean) => {
    setLoading(true)
    try {
      await mutate({
        variables: { id, role, disabled },
        update(cache, { data: { updateUser } }) {
          const { id, role, disabled } = updateUser
          cache.updateFragment(
            {
              id: `UserBase:${id}`,
              fragment: UserBaseFragment,
            },
            (data) => ({ ...data, role, disabled })
          )

          cache.updateFragment(
            {
              id: `UserAdminBase:${id}`,
              fragment: UserAdminBaseFragment,
            },
            (data) => ({ ...data, role, disabled })
          )

          cache.updateFragment(
            {
              id: `AdminUserStat:${id}`,
              fragment: AdminUserStatFragment,
            },
            (data) => ({ ...data, role, disabled })
          )
        },
      })
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }
  return {
    loading,
    error,
    updateUser,
  }
}
