import { ApolloError, gql, useQuery } from "@apollo/client"

const GET_OIDC_CONFIG_QUERY = gql`
  query getOIDCConfig($provider: String!) {
    getOIDCConfig(provider: $provider) {
      config {
        provider
        clientId
        clientSecret
        issuer
      }
    }
  }
`

export type OIDCConfig = {
  provider: string
  clientId: string
  clientSecret: string
  issuer: string
}

export default function useOIDCConfig(provider: string): {
  loading: boolean
  error?: ApolloError
  config: OIDCConfig | null
} {
  const { loading, error, data } = useQuery(GET_OIDC_CONFIG_QUERY, {
    variables: { provider },
    fetchPolicy: "network-only",
  })
  return {
    config: data?.getOIDCConfig?.config || null,
    loading,
    error,
  }
}
