import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import { Button } from "ui"
import { Plus } from "ui/icons"

import HybridRuleFormWrapper from "./HybridRuleFormWrapper/HybridRuleFormWrapper"

export default function AddHybridRuleButton() {
  const t = useTranslate()
  const [open, setOpen] = useState(false)

  const onCancel = () => {
    setOpen(false)
  }

  return (
    <>
      <Button icon={<Plus />} onClick={() => setOpen(true)}>
        {t("Add")}
      </Button>
      {open && <HybridRuleFormWrapper onCancel={onCancel} />}
    </>
  )
}
