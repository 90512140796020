import { MenuItem as MuiMenuItem } from "@mui/material"
import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { UserBase } from "types"
import { colors, L12, P14 } from "ui"
import { Cancel } from "ui/icons"
import LocationTree from "utils/LocationTree"

interface ItemProps {
  $isSelected: boolean
  $archived?: boolean
}

const MenuItem = styled(MuiMenuItem)<ItemProps>`
  padding: 9px 16px;
  ${({ $isSelected }) => {
    if ($isSelected)
      return `
      padding: 6px 16px;
      background-color: ${colors.grey4} !important;
      svg {
        color: ${colors.grey1};
        margin-left: auto;
      }
    `
  }}

  .title {
    color: ${colors.grey1};
  }

  ${({ $archived }) => {
    if ($archived)
      return `
      p:first-child {
        color: ${colors.grey1};
        text-decoration: line-through;
      }
    `
  }}
`

const ArchivedText = styled(L12)`
  font-style: italic;
  padding-left: 4px;
`

interface PropsType<T> {
  item: T
  selectedItems: T[]
  archived?: boolean
  disabled?: boolean
  onClick: (item: T) => void
}

export default function MultiSelectionListItem({
  item,
  selectedItems,
  archived,
  disabled,
  onClick,
}: PropsType<UserBase | LocationTree>) {
  const t = useTranslate()
  const { user: me } = useUser()
  const isSelected = selectedItems.some((i: any) => i.id === item.id)
  return (
    <MenuItem
      $isSelected={isSelected}
      $archived={archived}
      selected={isSelected}
      disabled={disabled}
      onClick={() => onClick(item)}
    >
      <P14 id={`opt-${item.id}`}>
        {item.name}
        {item.id === me?.id && (
          <em
            style={{
              fontStyle: "normal",
              color: colors.grey1,
              paddingLeft: "6px",
            }}
          >
            {t("you")}
          </em>
        )}
      </P14>
      {archived && (
        <ArchivedText style={{ textDecoration: "none" }}>
          {t("archived")}
        </ArchivedText>
      )}
      {isSelected && <Cancel />}
    </MenuItem>
  )
}
