import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { FieldsetColumn, TextField } from "ui"

const Fieldset = styled(FieldsetColumn)`
  gap: 16px;
`

function checkB4BPasswordValidity(
  password: string,
  t: (id: string, values?: Record<string, string> | undefined) => string
): {
  validity: boolean
  errorText?: string
} {
  if (password.length < 12) {
    return {
      validity: false,
      errorText: t("Must be at least 12 characters"),
    }
  }
  const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{12,}$/
  if (re.test(password)) {
    const firstUppercaseIndex = password.search(/[A-Z]/g)
    const nextChar = password[firstUppercaseIndex + 1]
    const nextNextChar = password[firstUppercaseIndex + 2]
    const nextNextNextChar = password[firstUppercaseIndex + 3]
    const isLowercase =
      nextChar !== undefined && nextChar.toLowerCase() === nextChar
    const isNumber = !isNaN(Number(nextNextChar))
    const isSpecial =
      nextNextNextChar !== undefined &&
      nextNextNextChar.search(/[!@#$%^&*]/g) === 0
    if (firstUppercaseIndex !== -1 && isLowercase && isNumber && isSpecial) {
      return {
        validity: false,
        errorText: t("B4BPasswordRule"),
      }
    }
    return { validity: true }
  }
  return {
    validity: false,
    errorText: t("B4BPasswordRule"),
  }
}

function checkGenericPasswordValidity(
  password: string,
  t: (id: string, values?: Record<string, string> | undefined) => string
): {
  validity: boolean
  errorText?: string
} {
  if (password.length < 8) {
    return {
      validity: false,
      errorText: t("Must be at least 8 characters"),
    }
  }
  return { validity: true }
}

export function getPasswordValidity(useB4BPasswords: boolean) {
  if (useB4BPasswords) return checkB4BPasswordValidity
  return checkGenericPasswordValidity
}

interface PropsType {
  label?: string
  value: string
  placeholder?: string
  error?: string
  inputRef?: React.Ref<HTMLInputElement>
  autoFocus?: boolean
  disabled?: boolean
  noAutoComplete?: boolean
  onChange: (str: string, error?: boolean) => void
  onClick?: () => void
}

interface WithConfirmationPropsType extends PropsType {
  confirmLabel?: string
  confirmValue: string
  showConfirmationOnMount?: boolean
  onConfirmChange: (str: string, error?: boolean) => void
}

export function PasswordWithConfirmationInputText({
  label,
  confirmLabel,
  value,
  confirmValue,
  placeholder,
  inputRef,
  autoFocus,
  disabled,
  noAutoComplete,
  showConfirmationOnMount = false,
  onChange,
  onConfirmChange,
  onClick,
}: WithConfirmationPropsType) {
  const t = useTranslate()
  const { user: me } = useUser()

  const useB4BPasswords = Boolean(me?.company?.flags?.B4BPasswords)
  const checkPasswordValidity = getPasswordValidity(useB4BPasswords)

  const [showError, setShowError] = useState<boolean | undefined>(undefined)
  const [showConfirmError, setShowConfirmError] = useState<boolean | undefined>(
    undefined
  )
  const [showConfirmPassword, setShowConfirmPassword] = useState(
    showConfirmationOnMount
  )

  const isValidConfirm = (password: string, confirmPassword: string) =>
    password === confirmPassword

  const handleErrors = (password: string, confirmPassword: string) => {
    if (confirmPassword === "") setShowConfirmError(undefined)
    else setShowConfirmError(!isValidConfirm(password, confirmPassword))
    if (password === "") setShowError(undefined)
    else setShowError(!checkPasswordValidity(password, t).validity)
  }

  const handleChange = (password: string) => {
    onChange(password, !checkPasswordValidity(password, t).validity)
    onConfirmChange(confirmValue, !isValidConfirm(password, confirmValue))
  }
  const handleConfirmChange = (confirmPassword: string) => {
    onConfirmChange(confirmPassword, !isValidConfirm(value, confirmPassword))
    onChange(value, !checkPasswordValidity(value, t).validity)
  }

  return (
    <Fieldset>
      <TextField
        inputRef={inputRef}
        type="password"
        label={label}
        placeholder={placeholder}
        handleChange={handleChange}
        error={showError}
        helperText={showError ? t("invalid password") : undefined}
        resetOnInitialValue
        autoFocus={autoFocus}
        disabled={disabled}
        autoComplete={noAutoComplete ? "new-password" : undefined}
        onClick={() => {
          if (onClick) onClick()
          setShowConfirmPassword(true)
        }}
        onBlur={() => {
          handleErrors(value, confirmValue)
        }}
        onFocus={() => setShowError(undefined)}
        isPassword
      />
      {showConfirmPassword && (
        <TextField
          type="password"
          label={confirmLabel}
          handleChange={handleConfirmChange}
          error={showConfirmError}
          helperText={
            showConfirmError ? t("invalid confirm password") : undefined
          }
          resetOnInitialValue
          autoFocus={autoFocus}
          disabled={disabled}
          autoComplete={noAutoComplete ? "new-password" : undefined}
          onBlur={() => {
            handleErrors(value, confirmValue)
          }}
          onFocus={() => setShowConfirmError(undefined)}
          isPassword
        />
      )}
    </Fieldset>
  )
}

export default function PasswordInputText({
  label,
  value,
  placeholder,
  error,
  inputRef,
  autoFocus,
  disabled,
  noAutoComplete,
  onChange,
  onClick,
}: PropsType) {
  const t = useTranslate()
  const { user: me } = useUser()
  const [showError, setShowError] = useState<boolean | undefined>(undefined)

  const useB4BPasswords = Boolean(me?.company?.flags?.B4BPasswords)
  const checkPasswordValidity = getPasswordValidity(useB4BPasswords)

  const handleErrors = (password: string) => {
    if (password === "") setShowError(undefined)
    else setShowError(!checkPasswordValidity(password, t).validity)
  }

  const handleChange = (password: string) => {
    onChange(password, !checkPasswordValidity(password, t).validity)
  }

  const getErrorMessage = () => {
    if (error) return error
    if (!showError) return undefined
    if (checkPasswordValidity) return checkPasswordValidity(value, t).errorText
    return t("invalid password")
  }

  return (
    <Fieldset>
      <TextField
        inputRef={inputRef}
        type="password"
        label={label}
        placeholder={placeholder}
        handleChange={handleChange}
        error={error ? true : showError}
        helperText={getErrorMessage()}
        resetOnInitialValue
        autoFocus={autoFocus}
        disabled={disabled}
        autoComplete={noAutoComplete ? "new-password" : undefined}
        onBlur={() => {
          handleErrors(value)
        }}
        onFocus={() => setShowError(undefined)}
        onClick={() => {
          if (onClick) onClick()
        }}
        isPassword
      />
    </Fieldset>
  )
}
