import UpdateFloorPlanDialog from "components/FloorPlanComponents/Dialogs/UpdateFloorPlanDialog"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import { colors, UnstyledButton } from "ui"
import { Edit, FloorPlan, Plus } from "ui/icons"
import LocationTree from "utils/LocationTree"

import FloorPlanProps from "./FloorPlanProps"
import { hasVisualArea } from "./VisualAreaEditor/VisualArea"
import { VisualEditorMode } from "./VisualAreaEditor/VisualAreaEditor"

interface PropsType {
  location: LocationTree
  disabled?: boolean
  floorPlan: FloorPlanProps
}

function childrenHaveFloorPlan(location: LocationTree): boolean {
  return location.children.some(
    (child) => child.floorPlanImageUrl || childrenHaveFloorPlan(child)
  )
}

export default function LocationFloorPlanAction({
  location,
  disabled,
  floorPlan,
}: PropsType) {
  const t = useTranslate()
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)

  if (floorPlan.mode === VisualEditorMode.VIEWER) {
    return location.floorPlanImageUrl ? <FloorPlan /> : <></>
  }

  if (
    (location.isCategory && floorPlan.parentHasFloorPlan) ||
    (!location.isCategory && !floorPlan.parentHasFloorPlan) ||
    (!location.isCategory &&
      floorPlan.parentHasFloorPlan &&
      !floorPlan.isPartOfActiveFloorPlan)
  ) {
    return <></>
  }

  const foundVisualArea = hasVisualArea(location.id, floorPlan.visualArea)

  const actionStrokeColor =
    floorPlan.isPartOfActiveFloorPlan &&
    location.id === floorPlan.activeSublocation?.id
      ? colors.purple
      : undefined

  const disabledPlus =
    !location.floorPlanImageUrl && childrenHaveFloorPlan(location)

  return (
    <>
      {(location.floorPlanImageUrl || !floorPlan.parentHasFloorPlan) && (
        <UnstyledButton
          className="floor-plan-icon"
          onClick={(e) => {
            e.stopPropagation()
            setIsEditModalOpen(true)
          }}
          disabled={disabled || disabledPlus}
          title={
            disabledPlus ? t("cant-add-floorPlan-with-children") : undefined
          }
        >
          {location.floorPlanImageUrl ? <FloorPlan /> : <Plus />}
        </UnstyledButton>
      )}
      {floorPlan.parentHasFloorPlan && (
        <UnstyledButton
          className="floor-plan-icon"
          onClick={() => {
            if (!foundVisualArea) {
              window.dispatchEvent(
                new CustomEvent("add-floor-plan-area", {
                  detail: { locationId: location.id },
                })
              )
            }
          }}
          disabled={disabled}
        >
          {foundVisualArea ? (
            <Edit
              className="floor-plan-icon"
              stroke={actionStrokeColor}
              strokeWidth={2}
            />
          ) : (
            <Plus
              className="floor-plan-icon"
              stroke={actionStrokeColor}
              strokeWidth={2}
            />
          )}
        </UnstyledButton>
      )}
      {isEditModalOpen && (
        <UpdateFloorPlanDialog
          open={isEditModalOpen}
          location={location}
          onClose={() => {
            setIsEditModalOpen(false)
          }}
        />
      )}
    </>
  )
}
