import { ApolloError, gql, useQuery } from "@apollo/client"
import { Equipment } from "types"

import { EquipmentFragment } from "./fragments/equipmentFragments"

const QUERY = gql`
  ${EquipmentFragment}
  query equipments($locationId: ID) {
    equipments(locationId: $locationId) {
      ...EquipmentFragment
    }
  }
`

interface PropsType {
  locationId?: string
}

const DefaultPropsType: PropsType = {}

export default function useEquipments({ locationId } = DefaultPropsType): {
  loading: boolean
  error?: ApolloError
  equipments: Equipment[]
} {
  const { loading, error, data } = useQuery<{
    equipments: Equipment[]
  }>(QUERY, { variables: { locationId }, fetchPolicy: "cache-and-network" })
  return {
    equipments: data?.equipments || [],
    loading,
    error,
  }
}
