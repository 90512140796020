import { ViewModeType } from "types"
import UTCDate from "utils/UTCDate"

function getMonthFirstPartialWeekMonday(selectedDate: UTCDate) {
  const from = new UTCDate(selectedDate, "MONTH")
  // shift from until first monday before 1st month's day
  const weekStartOnMondayDelta = (from.getDay() - 1) % 6
  from.shift("DAY", -1 * weekStartOnMondayDelta)
  // shift back once more if month beggining not reached
  if (from.getDate() > 1 && selectedDate.getMonth() === from.getMonth())
    from.shift("WEEK", -1)
  return from
}

function getMonthLastPartialNextMonday(selectedDate: UTCDate) {
  const to = new UTCDate(selectedDate, "MONTH")
  to.shift("MONTH", 1)

  return new UTCDate(to, "NEXT-MONDAY")
}

export function computePlanningFromTo(
  selectedDate: UTCDate,
  viewMode: ViewModeType,
  userShowWeekends: boolean,
  clipToWholeMonth = true
) {
  if (viewMode === "WEEK") {
    const from = new UTCDate(selectedDate, "WEEK-MONDAY")
    const to = userShowWeekends
      ? new UTCDate(from, "NEXT-MONDAY")
      : new UTCDate(from, "NEXT-SATURDAY")
    return { from, to }
  }

  if (viewMode === "DAY") {
    const from = new UTCDate(selectedDate, "DAY")
    const to = new UTCDate(from, "NEXT-DAY")

    return { from, to }
  }

  if (clipToWholeMonth) {
    const from = getMonthFirstPartialWeekMonday(selectedDate)
    const to = getMonthLastPartialNextMonday(selectedDate)
    return { from, to }
  }
  const from = new UTCDate(selectedDate, "WEEK-MONDAY")
  const to = new UTCDate(from)
  to.shift("WEEK", 5)

  return { from, to }
}

export function computeSelectedDate(
  selectedDate: UTCDate,
  viewMode: "WEEK" | "MONTH"
) {
  if (viewMode === "WEEK") return new UTCDate(selectedDate, "WEEK-MONDAY")
  return getMonthFirstPartialWeekMonday(selectedDate)
}

export function computePlanningDays(
  from: UTCDate,
  to: UTCDate,
  userShowWeekends: boolean,
  asHalfDays = true
) {
  const days = []
  for (
    const day = new UTCDate(from);
    day.getTime() < to.getTime();
    day.shift("HALF-DAY", 1)
  ) {
    if (!userShowWeekends && day.isWeekend()) continue
    days.push(new UTCDate(day))
  }
  if (asHalfDays) return days
  return days.filter((d) => d.isMorning())
}
