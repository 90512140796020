import { Capacitor } from "@capacitor/core"
import { Paper } from "@mui/material"
import GlobalStyle from "components/GlobalStyle"
import MobileNavBack from "components/Layout/PageLayout/PageHeader/PageTitle/MobileNavBack/MobileNavBack"
import LocaleSelector from "components/LocaleSelector"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import useTranslation from "intl/useTranslation"
import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"
import styled, { css } from "styled-components/macro"
import { useTeamsAppContext } from "TeamsApp/TeamsAppContext"
import { colors, mediaQueries } from "ui"

import AuthHeader from "./AuthHeader"

interface RootProps {
  noWrapper: boolean
}

const ReCapTcha = styled.small`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 370px;
  margin: 12px auto 0 auto;

  // Font style
  font-size: 12px;

  > a {
    margin-left: 4px;
    margin-right: 4px;
  }

  @media ${mediaQueries.isMobile} {
    width: 90%;
  }
`

const BackButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 20px;
`

const Root = styled.div<RootProps>`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  ${(props) => css`
    background-color: ${props.noWrapper ? colors.white : colors.purple};
  `}

  .locale-selector {
    position: absolute;
    top: 40px;
    right: 42px;
  }
`

const Wrapper = styled(Paper)`
  padding: 32px 0;

  @media ${mediaQueries.isMobile} {
    width: 90%;
    margin-left: 10px;
    margin-right: 10px;
  }
`

const AuthWrapper: React.FC = ({ children }) => {
  const isMobileApp = Capacitor.isNativePlatform()
  return isMobileApp ? <div>{children}</div> : <Wrapper>{children}</Wrapper>
}

const Auth: React.FC = ({ children }) => {
  const t = useTranslate()
  const [locale] = useTranslation()
  const { addToast } = useToasts()
  const { isTeamsApp } = useTeamsAppContext()
  const isMobileApp = Capacitor.isNativePlatform()
  const location = useLocation()
  const { isMobile } = useMediaQueries()
  const shouldDisplayBackButton = isMobile && location.pathname === "/sso"
  useEffect(() => {
    const errorMessage = localStorage.getItem("offishall-session-error")
    if (errorMessage) {
      addToast(t(errorMessage), { appearance: "error" })
      localStorage.removeItem("offishall-session-error")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Root noWrapper={isMobileApp}>
      {!isTeamsApp && !isMobileApp && (
        <div className="locale-selector">
          <LocaleSelector />
        </div>
      )}
      <GlobalStyle />
      {shouldDisplayBackButton && (
        <BackButtonContainer>
          <MobileNavBack path="/" />
        </BackButtonContainer>
      )}
      <AuthWrapper>
        <AuthHeader />
        {children}
        {!isTeamsApp && false && (
          <ReCapTcha>
            {locale.id === "en" && (
              <>
                {t("site protected by reCAPTCHA & google")}
                <a href="https://policies.google.com/privacy">
                  {t("privacy policy")}
                </a>
                {t("and")}
                <a href="https://policies.google.com/terms">
                  {t("terms of service")}
                </a>
                {t("apply")}.
              </>
            )}
            {locale.id === "fr" && (
              <>
                {t("site protected by reCAPTCHA & google")}
                <a href="https://policies.google.com/privacy">
                  {t("privacy policy")}
                </a>
                {t("and")}
                <a href="https://policies.google.com/terms">
                  {t("terms of service")}
                </a>
                {t("of Google")} {t("apply")}.
              </>
            )}
          </ReCapTcha>
        )}
      </AuthWrapper>
    </Root>
  )
}

export default Auth
