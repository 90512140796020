import React from "react"
import styled from "styled-components"
import colors from "ui/colors"
import { Minus, Plus } from "ui/icons"

import FabricJSWrapper from "../FabricJSWrapper"

const ZoomButtons = styled.div`
  width: 40px;
  border-radius: 5px;
  background: ${colors.white};
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.24), 0px 2px 2px rgba(0, 0, 0, 0.12);
  z-index: 2;
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > button {
    border: none;
    outline: none;
    background: transparent;
    height: 35px;

    svg {
      color: ${colors.purple};
      margin-top: 4px;
      margin-left: 2px;
    }
  }

  .divider {
    width: 100%;
    border-bottom: 1px solid ${colors.grey3};
  }
`

interface Props {
  fabricWrapper: FabricJSWrapper | null
}

export default function Zoom({ fabricWrapper }: Props) {
  return (
    <ZoomButtons>
      <button onClick={() => fabricWrapper?.zoomIn()}>
        <Plus />
      </button>
      <div className="divider" />
      <button onClick={() => fabricWrapper?.zoomOut()}>
        <Minus />
      </button>
    </ZoomButtons>
  )
}
