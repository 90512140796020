import useOccupancyRateOverTime from "graphql/stats/useOccupancyRateOverTime"
import React, { useEffect, useState } from "react"
import LocationTree from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

interface PropsType {
  location: LocationTree
  from: UTCDate
  to: UTCDate
  includeWeekends: boolean
  occupancies: {
    location: LocationTree
    occupancyRateOverTime: {
      date: string
      value: number
      booked: number
      total: number
    }[]
  }[]
  updateDatasets: (
    location: LocationTree,
    occupancyRateOverTime: {
      date: string
      value: number
      booked: number
      total: number
    }[],
    remove?: boolean
  ) => void
}

export default function OccupancyRateOverTimeDataset({
  location,
  from,
  to,
  includeWeekends,
  occupancies,
  updateDatasets,
}: PropsType) {
  const { occupancyRateOverTime, loading, error } = useOccupancyRateOverTime({
    from,
    to,
    locationIds: [location.id],
    includeWeekends,
  })

  const [previousTimeFrame, setPreviousTimeFrame] = useState<
    { from: UTCDate; to: UTCDate; includeWeekends: boolean } | undefined
  >(undefined)

  useEffect(() => {
    if (loading || error) return

    const timeFrameHasChanged =
      previousTimeFrame?.from.getTime() !== from.getTime() ||
      previousTimeFrame?.to.getTime() !== to.getTime() ||
      previousTimeFrame?.includeWeekends !== includeWeekends

    const prevOccupancy = occupancies.find((o) => o.location.id === location.id)

    if (prevOccupancy === undefined || timeFrameHasChanged) {
      // update parent datasets with this dataset if:
      // * this dataset does not exist in parent datasets yet
      // * parent time frame has changed
      updateDatasets(location, occupancyRateOverTime)
      setPreviousTimeFrame({ from, to, includeWeekends })
    }
  }, [
    loading,
    error,
    location,
    occupancyRateOverTime,
    occupancies,
    updateDatasets,
    from,
    to,
    includeWeekends,
    previousTimeFrame,
  ])

  useEffect(() => {
    // remove location dataset from parent datasets on un-mount
    return () => updateDatasets(location, occupancyRateOverTime, true)
    // eslint-disable-next-line
  }, [])

  return <></>
}
