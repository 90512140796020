import { gql, useMutation } from "@apollo/client"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useTranslate from "intl/useTranslate"
import { Flags } from "types"

import { CompanyIntegrationFragment } from "./fragments/CompanyIntegrationFragments"
import updateCompanyIntegrationCache from "./updateCompanyIntegrationCache"

const MUTATION = gql`
  ${CompanyIntegrationFragment}
  mutation setupCompanyIntegration(
    $name: String!
    $externalId: ID!
    $token: String
    $flags: JSON
  ) {
    setupCompanyIntegration(
      name: $name
      externalId: $externalId
      token: $token
      flags: $flags
    ) {
      ...CompanyIntegrationFragment
    }
  }
`

interface PropsType {
  name: string
  externalId: string
  token?: string
  flags?: Flags
}

export default function useSetupCompanyIntegration() {
  const t = useTranslate()
  const { addToast } = useToasts()
  const [mutate] = useMutation(MUTATION, {
    update: (cache, { data }) =>
      updateCompanyIntegrationCache({
        cache,
        data,
        mutationName: "setupCompanyIntegration",
      }),
  })

  return async ({ name, externalId, token, flags }: PropsType) => {
    try {
      await mutate({
        variables: { name, externalId, token, flags },
      })
      addToast(
        t("confirmation connexion to integration").replace("{name}", name),
        { appearance: "success" }
      )
    } catch (e) {
      console.error(e.message)
      addToast(t("cannot setup integration", { name }), {
        appearance: "error",
        autoDismissTimeout: 10000,
      })
    }
  }
}
