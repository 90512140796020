import LocationsSelection from "components/LocationsSelection/LocationsSelection"
import useAddEquipment from "graphql/equipments/useAddEquipment"
import useUpdateEquipment from "graphql/equipments/useUpdateEquipment"
import useLocationsTree from "graphql/locations/useLocationsTree"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import { Equipment, EquipmentInput } from "types"
import { AdminPopup, Button } from "ui"
import { defaultServiceIcon, getServiceIcon } from "utils/getServiceIcons"
import LocationTree, { getChildrenLocations } from "utils/LocationTree"

import CapacityFieldset from "../CapacityFieldset/CapacityFieldset"
import IconNameFieldset from "../IconNameFieldset/IconNameFieldset"
import DeleteEquipmentButton from "./DeleteEquipmentButton/DeleteEquipmentButton"

interface PropsType {
  open: boolean
  equipment?: Equipment
  onEquipmentAdded: (service: any) => void
  onClose: () => void
}

export default function UpdateEquipmentDialog({
  open,
  equipment,
  onEquipmentAdded,
  onClose,
}: PropsType) {
  const t = useTranslate()
  const showArchivedLocations = true
  const { locations } = useLocationsTree(!showArchivedLocations)

  const addEquipment = useAddEquipment()
  const udpateEquipment = useUpdateEquipment()

  const initialEquipment: EquipmentInput = {
    icon: defaultServiceIcon.name,
    name: undefined,
    locationId: undefined,
    capacity: 0,
  }

  const [newEquipment, setNewEquipment] = useState<EquipmentInput>(
    equipment ?? initialEquipment
  )

  const checkNewServiceValidity = () => {
    if (newEquipment.name === undefined || newEquipment.name === "")
      return false
    if (newEquipment.locationId === undefined) return false
    if (newEquipment.icon === undefined) return false
    if (newEquipment.capacity === undefined) return false
    return true
  }

  const submitForm = async () => {
    if (!checkNewServiceValidity) return

    if (equipment !== undefined) {
      await udpateEquipment({
        equipmentId: equipment.id,
        name: newEquipment.name!,
        locationId: newEquipment.locationId!,
        capacity: newEquipment.capacity!,
        icon: newEquipment.icon!,
      })
    } else {
      await addEquipment({
        name: newEquipment.name!,
        locationId: newEquipment.locationId!,
        capacity: newEquipment.capacity!,
        icon: newEquipment.icon!,
      })
    }
    onEquipmentAdded(newEquipment)
    onClose()
    resetForm()
  }

  const allLocations = getChildrenLocations(locations, false)

  const resetForm = () => {
    setNewEquipment(initialEquipment)
  }
  return (
    <AdminPopup
      open={open}
      onClose={() => {
        resetForm()
        onClose()
      }}
      title={equipment ? t("Edit the service") : t("Add a service")}
      leftButton={
        equipment ? (
          <DeleteEquipmentButton
            equipment={equipment}
            onSucces={() => {
              resetForm()
              onClose()
            }}
          />
        ) : (
          <div />
        )
      }
      rightButton={
        <Button onClick={submitForm} disabled={!checkNewServiceValidity()}>
          {t("Save")}
        </Button>
      }
    >
      <>
        <IconNameFieldset
          placeholder={
            newEquipment.name
              ? undefined
              : getServiceIcon(newEquipment.icon ?? "")?.description
          }
          initialIcon={newEquipment.icon}
          initialName={newEquipment.name}
          onIconChange={(icon) =>
            setNewEquipment((prev) => ({
              ...prev,
              icon: icon.name,
            }))
          }
          onNameChange={(name) =>
            setNewEquipment((prev) => ({ ...prev, name }))
          }
        />
        <LocationsSelection
          onChange={(location) => {
            if (location.length === 0)
              setNewEquipment((prev) => ({ ...prev, locationId: undefined }))
            else
              setNewEquipment((prev) => ({
                ...prev,
                locationId: location[0].id,
              }))
          }}
          title={t("Service location")}
          placeholder={
            equipment && allLocations
              ? allLocations?.find(
                  (location: LocationTree) =>
                    location.id === equipment.locationId
                )
                ? allLocations.find(
                    (location: LocationTree) =>
                      location.id === equipment.locationId
                  )?.name
                : ""
              : t("search spaces")
          }
          singleSelection
          initialLocationIds={equipment ? [equipment.locationId] : undefined}
          showArchivedLocations={showArchivedLocations}
        />
        <CapacityFieldset
          value={newEquipment.capacity ?? 0}
          onChange={(capacity) =>
            setNewEquipment((prev) => ({ ...prev, capacity }))
          }
        />
      </>
    </AdminPopup>
  )
}
