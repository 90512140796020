import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer as MuiTableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material"
import React from "react"
import styled from "styled-components"
import { colors, P14, P14Bold } from "ui"
import capitalizeFirstLetter from "utils/texts/capitalizeFirstLetter"

const TableContainer = styled(MuiTableContainer)`
  margin-bottom: 24px;
  height: 100%;
  max-width: 100%;
  overflow-x: scroll;
`
const Table = styled(MuiTable)`
  .MuiTable-root {
    max-width: 100%;
    overflow-x: scroll;
  }
  max-width: 100%;
  overflow-x: scroll;
`

const TableHeader = styled(TableHead)`
  height: 40px;
  background-color: ${colors.grey4};
  p {
    color: ${colors.grey1};
  }
`

const TableContent = styled(TableBody)`
  height: 100%;
  > :nth-child(even) {
    background-color: ${colors.grey4};
  }
`

const tableRowlStyling = {
  maxHeight: 40,
  padding: 0,
}

const tableCellHeaderStyling = {
  border: "none",
  minWidth: 150,
  color: "black",
  backgroundColor: colors.grey4,
  opacity: 1,
}

const tableCellStyling = {
  border: "none",
  minWidth: 150,
  color: "black",
  cursor: "pointer",
}

interface PropsType {
  csv: string[][]
  currentStep: number
  hasColumnNames?: boolean
  columnIdx?: number | null
  setColumnIdx?: (nbr: number) => void
}

export default function CSVDisplayer({
  csv,
  currentStep,
  hasColumnNames,
  columnIdx,
  setColumnIdx,
}: PropsType) {
  const firstLine = hasColumnNames ? 1 : 0
  const lastLine = csv.length

  // Pagination logic
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const maxHeight = currentStep === 2 || currentStep === 5 ? "40vh" : "50vh"

  return (
    <>
      <TableContainer sx={{ maxHeight: maxHeight }}>
        <Table stickyHeader>
          {hasColumnNames && (
            <TableHeader>
              {csv.slice(0, 1).map((line, i) => (
                <TableRow key={`csv_line_${i}`}>
                  {line.map((entry, j) => (
                    <TableCell
                      align="left"
                      sx={{ ...tableCellHeaderStyling }}
                      style={{
                        backgroundColor: columnIdx === j ? colors.grey3 : "",
                      }}
                      key={`csv_2_line_entry_${j}`}
                      onClick={() => {
                        if (setColumnIdx) setColumnIdx(j)
                      }}
                    >
                      <P14Bold>{capitalizeFirstLetter(entry)}</P14Bold>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHeader>
          )}
          <TableContent>
            {csv.slice(firstLine, lastLine).map((value, i) => (
              <TableRow key={`csv_line_${i}`} sx={{ ...tableRowlStyling }}>
                {value.map((entry, j) => (
                  <TableCell
                    align="left"
                    sx={{ ...tableCellStyling }}
                    style={{
                      backgroundColor:
                        columnIdx === j ? `rgba(173, 182, 190, 0.1)` : "",
                    }}
                    key={`csv_2_line_entry_${j}`}
                    onClick={() => {
                      if (setColumnIdx) setColumnIdx(j)
                    }}
                  >
                    <P14>{entry}</P14>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableContent>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={csv.length - 1}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  )
}
