import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import { DashedButton } from "ui"
import { AddUser, Cancel } from "ui/icons"

import InviteDialog from "./InviteDialog"

interface PropsType {
  guestsEmails: string[]
  inviteGuestsMessage: string
  updateGuestsEmails: (emails: string[]) => void
  updateInviteGuestsMessage: (msg: string) => void
}

export default function InviteGuests({
  guestsEmails,
  inviteGuestsMessage,
  updateGuestsEmails,
  updateInviteGuestsMessage,
}: PropsType) {
  const t = useTranslate()

  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false)

  const nbGuests = guestsEmails.length

  const reset = () => {
    updateGuestsEmails([])
    updateInviteGuestsMessage("")
    setIsInviteDialogOpen(false)
  }

  return (
    <>
      {nbGuests === 0 ? (
        <DashedButton
          title={t("Guests")}
          icon={<AddUser />}
          onClick={() => {
            setIsInviteDialogOpen(true)
          }}
        />
      ) : (
        <DashedButton
          title={`
          ${nbGuests}${" "}
          ${nbGuests > 1 ? t("Guests").toLowerCase() : t("Guest").toLowerCase()}
          `}
          icon={
            <Cancel
              onClick={(e) => {
                e.stopPropagation()
                reset()
              }}
            />
          }
          iconToTheRight
          active
          onClick={() => {
            setIsInviteDialogOpen(true)
          }}
        />
      )}

      <InviteDialog
        inviteGuestsMessage={inviteGuestsMessage}
        updateInviteGuestsMessage={updateInviteGuestsMessage}
        open={isInviteDialogOpen}
        guestsEmails={guestsEmails}
        onClose={() => setIsInviteDialogOpen(false)}
        updateGuestsEmails={updateGuestsEmails}
      />
    </>
  )
}
