import ButtonResetSearch from "components/ButtonResetSearch/ButtonResetSearch"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Group, UserBase } from "types"
import { colors } from "ui"
import { Search } from "ui/icons"

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  border-top: 1px solid #dfe1e1;
  border-bottom: 1px solid #dfe1e1;
  background: inherit;
  outline: none;
  width: 100%;
  color: ${colors.black};
  text-decoration: none;
  height: 40px;
  gap: 10px;
  padding: 0 10px 0 0;

  svg {
    margin-left: 10px;
    color: ${colors.grey1};
  }
`

const SearchInputText = styled.input.attrs({ type: "search" })`
  border: none;
  border-top: 1px solid #dfe1e1;
  border-bottom: 1px solid #dfe1e1;
  background: inherit;
  outline: none;
  width: 100%;
  color: ${colors.black};
  text-decoration: none;
  height: 40px;

  font-size: 14px;
  line-height: 18px;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${colors.grey2};
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${colors.grey2};
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${colors.grey2};
  }

  /* hide IE or Chrome default cancel button */
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
  &::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
`

interface PropsType {
  users: UserBase[]
  groups: Group[]
  placeholder?: string
  searchString: string
  setSearchString: (string: string) => void
  onChange: (e: any) => void
  onNewResults: (users: UserBase[]) => void
  onNewGroupResults: (groups: Group[]) => void
}

export default function UserGroupSearch({
  users,
  groups,
  placeholder,
  searchString,
  setSearchString,
  onChange,
  onNewResults,
  onNewGroupResults,
}: PropsType) {
  const [newResults, setNewResults] = useState<UserBase[]>([])
  const [newGroupResults, setNewGroupResults] = useState<Group[]>([])

  const resetForm = () => {
    setSearchString("")
    setNewResults([])
    setNewGroupResults([])
    onNewResults([])
    onNewGroupResults([])
  }

  useEffect(() => {
    if (searchString === "") return

    // replace space with . to match email
    const searchStringWithDot = searchString.replace(" ", ".")

    const filteredUsers = users.filter(
      (u) =>
        u.name.toLowerCase().includes(searchString.toLowerCase()) ||
        u.email.toLowerCase().includes(searchString.toLowerCase()) ||
        u.email.toLowerCase().includes(searchStringWithDot.toLowerCase())
    )
    const filteredGroups = groups.filter((g) =>
      g.name.toLowerCase().includes(searchString.toLowerCase())
    )

    if (filteredUsers.length === newResults.length) {
      for (const result of filteredUsers) {
        if (!newResults.find((nr) => nr.id === result.id)) {
          setNewResults(filteredUsers)
          onNewResults(filteredUsers)
          return
        }
      }
    } else {
      setNewResults(filteredUsers)
      onNewResults(filteredUsers)
    }

    if (filteredGroups.length === newGroupResults.length) {
      for (const result of filteredGroups) {
        if (!newGroupResults.find((nr) => nr.id === result.id)) {
          setNewGroupResults(filteredGroups)
          onNewGroupResults(filteredGroups)
          return
        }
      }
    } else {
      setNewGroupResults(filteredGroups)
      onNewGroupResults(filteredGroups)
    }
  }, [
    users,
    groups,
    newResults,
    newGroupResults,
    searchString,
    onNewResults,
    onNewGroupResults,
  ])
  return (
    <Container>
      <Search />
      <SearchInputText
        placeholder={placeholder}
        onChange={(event) => {
          onChange(event.target.value)
        }}
        onClick={() => {
          if (searchString !== "") resetForm()
        }}
        value={searchString}
      />
      {searchString !== "" && <ButtonResetSearch resetForm={resetForm} />}
    </Container>
  )
}
