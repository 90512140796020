import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function Food({
  stroke,
  fill,
  strokeWidth,
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      strokeWidth={strokeWidth ?? 1.5}
      stroke={stroke ? stroke : "currentColor"}
      fill={fill ? fill : "currentColor"}
      {...rest}
    >
      <path
        d="M8.5 20V4M4 4v4.5c0 3 3.25736 3.5 4.5 3.5 1.24264 0 4.5-.5 4.5-3.5V4"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 4h-1c-1.6569 0-3 1.49233-3 3.33333V14"
        fill="none"
        strokeLinejoin="round"
        strokeLinecap="round"
      />
      <path
        d="M20.75 4c0-.41421-.3358-.75-.75-.75s-.75.33579-.75.75h1.5Zm-1.5 16c0 .4142.3358.75.75.75s.75-.3358.75-.75h-1.5Zm0-16v16h1.5V4h-1.5Z"
        stroke="none"
      />
      <path
        d="M20 14.75c.4142 0 .75-.3358.75-.75s-.3358-.75-.75-.75v1.5Zm-4-1.5c-.4142 0-.75.3358-.75.75s.3358.75.75.75v-1.5Zm4 0H16v1.5H20v-1.5Z"
        stroke="none"
      />
    </SvgIcon>
  )
}
