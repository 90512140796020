import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components/macro"
import { mediaQueries, P16Bold } from "ui"
import { Food, Stroller, Yoga } from "ui/icons"

import BigButton from "./BigButton"

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media ${mediaQueries.isMobile} {
    padding: 12px;
    gap: 12px;
  }
`

const Title = styled(P16Bold)`
  margin-bottom: 24px;
`

function Services() {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()

  return (
    <Content>
      {!isMobile && <Title>Offishall Care</Title>}
      <BigButton
        icon={<Yoga />}
        title={t("psychological follow-up title")}
        subtitle={t("psychological follow-up subtitle")}
      />
      <BigButton
        icon={<Food />}
        title={t("Nutri coach title")}
        subtitle={t("Nutri coach subtitle")}
      />
      <BigButton
        icon={<Stroller />}
        title={t("childcare title")}
        subtitle={t("childcare subtitle")}
      />
    </Content>
  )
}
export default Services
