import CircularProgress from "@mui/material/CircularProgress"
import ErrorDialog from "components/ErrorDialog"
import PasswordInput from "components/PasswordInput"
import useLoginWithEmailAndPassword from "hooks/auth/useLoginWithEmailAndPassword"
import useTranslate from "intl/useTranslate"
import React, { useCallback, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { Button, colors, H3, mediaQueries, TextField } from "ui"
import { confirmPasswordReset, verifyPasswordResetCode } from "utils/firebase"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 480px;

  @media ${mediaQueries.isMobile} {
    width: 90%;
    margin-left: 10px;
    margin-right: 10px;
  }

  h3 {
    color: ${colors.purple};
  }

  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 370px;
    margin-top: 32px;
    gap: 16px;

    > button {
      width: 100%;
    }

    @media ${mediaQueries.isMobile} {
      width: 280px;
    }
  }
`

export default function ResetPasswordAction({
  actionCode,
}: {
  actionCode: string
}) {
  const t = useTranslate()
  const { loading, loginWithEmailAndPassword } = useLoginWithEmailAndPassword()
  const history = useHistory()
  const [password, setPassword] = useState<string | null>(null)
  const [passwordConfirm, setPasswordConfirm] = useState<string | undefined>(
    undefined
  )
  const [email, setEmail] = useState<string | null>(null)
  const [passwordValid, setPasswordValid] = useState(false)
  const [error, setError] = useState("")

  const changePassword = useCallback(async () => {
    try {
      if (!email || !password || password !== passwordConfirm)
        throw new Error("Invalid email/password")
      await confirmPasswordReset(actionCode, password)
      await loginWithEmailAndPassword(email, password)
      history.push("/planning")
    } catch (error) {
      setError(error.message)
    }
  }, [
    actionCode,
    email,
    history,
    password,
    passwordConfirm,
    loginWithEmailAndPassword,
  ])

  useEffect(() => {
    verifyPasswordResetCode(actionCode)
      .then((mail) => setEmail(mail))
      .catch((error) => setError(error.message))
  }, [actionCode])

  if (loading) return <CircularProgress style={{ marginTop: 100 }} />
  return (
    <Container>
      <H3>{t("Reset password")}</H3>
      <ErrorDialog
        onClose={() => {
          history.push("/")
        }}
        open={error !== ""}
        error={error}
        isError={false}
      />
      {!email ? (
        <CircularProgress style={{ marginTop: 100 }} />
      ) : (
        <div className="form">
          <TextField
            required
            disabled
            label={t("Email")}
            initialValue={email}
            handleChange={() => {
              //
            }}
          />

          <PasswordInput
            name="password"
            useB4BPasswords={false}
            newPassword={true}
            handleChange={(value, valid) => {
              setPassword(value)
              setPasswordValid(valid)
            }}
            label={t("New Password")}
          />

          <PasswordInput
            name="password"
            useB4BPasswords={false}
            newPassword={true}
            handleChange={(value, valid) => {
              setPasswordConfirm(value)
              setPasswordValid(valid)
            }}
            helperText={
              password !== passwordConfirm
                ? t("Both passwords need to be the same")
                : ""
            }
            label={t("Confirm Password")}
          />
          <Button
            disabled={!email || !passwordValid || password !== passwordConfirm}
            onClick={changePassword}
          >
            {t("Change my password")}
          </Button>
        </div>
      )}
    </Container>
  )
}
