import { ApolloError, gql, useQuery } from "@apollo/client"
import { GroupSimple } from "types"

import { SimpleGroupFragment } from "./fragments/groupFragment"

export const MY_GROUPS_SIMPLE_QUERY = gql`
  ${SimpleGroupFragment}
  query getMyGroupsSimple($userId: ID) {
    myGroupsSimple(userId: $userId) {
      ...SimpleGroupFragment
    }
  }
`

export default function useMyGroupsSimple(
  userId?: string,
  forceEveryone = false,
  everyoneName?: string
): {
  loading: boolean
  error?: ApolloError
  groups: GroupSimple[]
} {
  const { loading, error, data } = useQuery(MY_GROUPS_SIMPLE_QUERY, {
    variables: { userId },
  })
  const groups = data?.myGroupsSimple || []
  // force everyone group
  if (!loading && !error && forceEveryone) {
    if (groups.find((group: any) => group.id === "everyone") === undefined) {
      return {
        groups: [
          ...groups,
          {
            __typename: "Group",
            id: "everyone",
            name: everyoneName || "Everyone",
            flags: {},
            owner: undefined,
          },
        ],
        loading,
        error,
      }
    }
  }
  return {
    groups,
    loading,
    error,
  }
}
