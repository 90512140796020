import useLocationsTree from "graphql/locations/useLocationsTree"
import useOccupancyRateOverTime from "graphql/stats/useOccupancyRateOverTime"
import useTranslate from "intl/useTranslate"
import DownloadCsvButton from "pages/StatisticsPage/components/DownloadCsvButton"
import React, { useCallback, useState } from "react"
import { Bar } from "react-chartjs-2"
import { colors } from "ui"
import LocationTree from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

import getCsvLocations from "../utils/getCsvLocations"
import OccupancyRatePerDayBar from "./OccupancyRatePerDayBar/OccupancyRatePerDayBar"

function shiftSunday<T>(arr: T[]) {
  const [sunday, ...rest] = arr
  return [...rest, sunday]
}

interface PropsType {
  selectedLocations: LocationTree[]
  allLocationsFlatten: LocationTree[]
  from: UTCDate
  to: UTCDate
  includeWeekends: boolean
  showAllLocationsBar: boolean
  showDownloadCSV?: boolean
  bypassLocationIds?: boolean
}

export default function OccupancyRatePerDayChart({
  selectedLocations,
  allLocationsFlatten,
  from,
  to,
  includeWeekends,
  showAllLocationsBar,
  showDownloadCSV = true,
  bypassLocationIds = false,
}: PropsType) {
  const t = useTranslate()
  const { locations: locationsTree } = useLocationsTree()

  const { occupancyRateOverTime } = useOccupancyRateOverTime({
    from,
    to,
    bypassLocationIds,
    locationIds: getCsvLocations({
      showAllLocationsBar,
      allLocationsFlatten,
      selectedLocations,
      locationsTree,
    }).map((l) => l.id),
    includeWeekends,
    perDay: true,
  })

  const getCsv = useCallback(() => {
    const headers = ["location_name", "date", "value", "booked", "total"].join(
      ","
    )
    const rows: string[] = []
    if (showAllLocationsBar) {
      for (const o of occupancyRateOverTime) {
        const date = o.date

        rows.push(
          [
            t("All locations"),
            date,
            Number(o.value),
            Number(o.booked),
            Number(o.total),
          ].join(",")
        )
      }
    }
    if (selectedLocations.length > 0) {
      for (const l of getCsvLocations({
        showAllLocationsBar,
        allLocationsFlatten,
        selectedLocations,
        locationsTree,
      })) {
        for (const o of occupancyRateOverTime) {
          const date = o.date

          rows.push(
            [
              l.name,
              date,
              Number(o.value),
              Number(o.booked),
              Number(o.total),
            ].join(",")
          )
        }
      }
    }
    return Promise.resolve(`${headers}\n${rows.join("\n")}`)
  }, [
    occupancyRateOverTime,
    showAllLocationsBar,
    allLocationsFlatten,
    selectedLocations,
    locationsTree,
    t,
  ])

  const [occupancyPerDate, setOccupancyPerDate] = useState<
    {
      date: string
      value: number
      booked: number
      total: number
    }[]
  >([])

  const weekDays =
    occupancyPerDate.length > 0
      ? Array.from(new Set(occupancyPerDate.map((o) => o.date)))
      : []
  const hasSunday = weekDays.length > 5 && weekDays.includes("Sunday")

  let dateAsLabels = weekDays

  let occupiedData = dateAsLabels
    .map((d) =>
      occupancyPerDate.filter((opd) => opd.date === d).map((d) => d.value)
    )
    .reduce((prev, curr) => prev.concat(curr), [])

  let notOccupiedData = occupiedData.map((o) => 1 - o)

  if (hasSunday) {
    occupiedData = shiftSunday(occupiedData)
    notOccupiedData = shiftSunday(notOccupiedData)
    dateAsLabels = shiftSunday(dateAsLabels)
  }

  const datasets = [
    {
      label: "occupied",
      data: occupiedData,
      backgroundColor: colors.purple,
      maxBarThickness: 32,
      stack: 0,
    },
    {
      label: "not occupied",
      data: notOccupiedData,
      backgroundColor: colors.grey3,
      maxBarThickness: 32,
      stack: 0,
    },
  ]

  const getDatasets = () => {
    return selectedLocations.length > 0 || showAllLocationsBar ? datasets : []
  }

  const getTooltipLabel = (tooltipItem: {
    datasetIndex: number
    index: number
    xLabel: string
    yLabel: number
  }) => {
    const defaultTooltip = `${(100 * tooltipItem.yLabel).toFixed(2)}%`
    const dataset = getDatasets()[tooltipItem.datasetIndex]
    if (dataset !== undefined) {
      let dayIndex = tooltipItem.index
      if (hasSunday && dayIndex === 6) dayIndex = 0
      else if (hasSunday) dayIndex++
      const occupancyOfTheDay = occupancyPerDate[dayIndex]
      if (occupancyOfTheDay !== undefined) {
        if (dataset.label === "occupied") {
          return `${occupancyOfTheDay.booked}/${occupancyOfTheDay.total} ${t(
            "occupied seats"
          )} | ${defaultTooltip}%`
        } else {
          return `${occupancyOfTheDay.total - occupancyOfTheDay.booked}/${
            occupancyOfTheDay.total
          } ${t("not occupied seats")} | ${defaultTooltip}%`
        }
      }
    }
    return defaultTooltip
  }

  const data = {
    labels: dateAsLabels.map((d) => t(d)),
    datasets: getDatasets(),
  }
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          id: "xAxis1",
          type: "category",
          ticks: {
            display: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          gridLines: {
            display: true,
          },
          ticks: {
            callback: function (value: number) {
              return Math.floor(100 * value) + "%"
            },
          },
        },
      ],
    },
    tooltips: {
      mode: "nearest",
      callbacks: {
        label: getTooltipLabel,
      },
    },
    legend: {
      labels: {
        usePointStyle: true,
        boxWidth: 7,
        filter: (legendItem: any) => {
          legendItem.text = t(legendItem.text)

          return true
        },
      },
    },
    onHover: (event: any, elements: any) => {
      event.target.style.cursor = "default"
      if (elements.length > 0) {
        event.target.style.cursor = "pointer"
      }
    },
  }

  return (
    <>
      {showDownloadCSV && (
        <DownloadCsvButton
          className="btn--margin-top"
          fileName="occupancy-rate-per-day.csv"
          getCsv={getCsv}
        />
      )}
      <OccupancyRatePerDayBar
        locations={
          showAllLocationsBar ? allLocationsFlatten : selectedLocations
        }
        from={from}
        to={to}
        bypassLocationIds={bypassLocationIds}
        includeWeekends={includeWeekends}
        occupancyPerDay={occupancyPerDate}
        updateOccupancyPerDate={(wm) => setOccupancyPerDate(wm)}
      />
      <Bar data={data} options={options} />
    </>
  )
}
