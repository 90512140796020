import { User, UserAdminBase, UserBase } from "types"

type InputType = User | UserBase | UserAdminBase

export default function sortAlphabeticallyByNameThenEmail(
  a: InputType,
  b: InputType
) {
  if (a.email < b.email) return -1
  if (a.email > b.email) return 1
  if (a.name < b.name) return -1
  if (a.name > b.name) return 1
  return 0
}
