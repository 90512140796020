import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useUser from "graphql/users/useUser"
import { User } from "types"
import UserTree from "utils/UserTree"

import useBookingMinDate from "./useBookingMinDate"
import useCompanyFlag from "./useCompanyFlag"
import useUserTree from "./useUserTree"

export default function useEditableUserSlotsPermission(
  userToMutate: User | null
) {
  const { user: me } = useUser()

  const { userTree } = useUserTree()
  const { from, isTypicalWeekOpen } = usePlanningContext()
  const managersCanMutate = useCompanyFlag(
    "enableUserMutationForManagers",
    false
  )
  const bookingMinDate = useBookingMinDate()

  const isValidWeek =
    !bookingMinDate || from.getTime() >= bookingMinDate.getTime()
  const selectedUserIsMe = userToMutate?.id === me?.id
  const selectedUserManagedByMe = UserTree.isManagedBy(
    userTree,
    me?.id,
    userToMutate?.id
  )
  const canMutateSelectedUser = selectedUserManagedByMe && managersCanMutate

  if (isTypicalWeekOpen && selectedUserIsMe) return true
  if (!isValidWeek) return false
  if (selectedUserIsMe) return true
  if (me?.role === "admin") return true
  if (canMutateSelectedUser) return true
  return false
}
