import styled from "styled-components"
import { colors, Grid, mediaQueries } from "ui"
import getSafeAvailableHeightCSSProperties from "utils/getSafeAvailableHeightCSSProperties"

export const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  padding: 50px 70px;
  height: 150px;

  @media ${mediaQueries.isMobile} {
    height: 50px;
    padding: 0px 20px;
  }
`

interface ContentProps {
  maxHeight: number
}

export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  height: calc(100vh - 300px);
  padding: 50px 70px;
  gap: 22px;

  @media ${mediaQueries.isMobile} {
    gap: 16px;
    padding: 0px 20px;

    ${({ maxHeight }) =>
      maxHeight
        ? getSafeAvailableHeightCSSProperties(maxHeight - 150)
        : "100vh"}
  }
`

export const MessageContainer = styled.div`
  h2:first-child {
    padding-bottom: 8px;
  }
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const Title = styled.h2`
  font-family: "Sora";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 56px;
  color: ${colors.purple};

  @media ${mediaQueries.isMobile} {
    font-size: 28px;
    line-height: 48px;
  }
`

export const Subtitle = styled.h3`
  font-family: "Sora";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: ${colors.grey1};
  padding-left: 24px;
`

export const RightContainer = styled(Grid)`
  background-color: ${colors.purple};
  height: 100%;
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;

  @media ${mediaQueries.isMobile} {
    display: none;
  }
`

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 50px 70px;
  height: 150px;

  @media ${mediaQueries.isMobile} {
    padding: 0px 30px;
    height: 100px;
  }
`
