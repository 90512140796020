import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useTranslate from "intl/useTranslate"
import FloorPlan from "pages/PlanningPage/components/PlanningHeadersRouter/PlanningHeaders/LocationPlanningHeader/UserFloorPlanDrawer/FloorPlan/FloorPlan"
import React from "react"
import styled from "styled-components"
import { WorkingMode } from "types"
import { Button, colors } from "ui"
import { ArrowLeft, Cancel } from "ui/icons"
import LocationTree from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

import MobileSlotsEditorFloorPlanLocationsList from "./MobileSlotsEditorFloorPlanLocationsList/MobileSlotsEditorFloorPlanLocationsList"

const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  overflow: hidden;
  height: 100%;
  min-height: 100%;

  > div:nth-child(1) {
    // header
    padding: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-right: none;
    width: 100%;
    margin-top: 0;
  }
  > div:nth-child(2) {
    // floor plan
    min-height: 100%;
    justify-content: space-between;
  }
  > div:nth-child(3) {
    // footer actions
    padding: 8px;
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
`

const CloseButton = styled.button`
  padding: 0;
  aspect-ratio: 1;
  border: none;
  background: none;
  svg {
    stroke: ${colors.grey1};
  }
`

const ActionLayout = styled.div`
  padding: 8px;
  background: ${colors.grey4};
  border-top: solid 1px ${colors.grey3};
`

interface PropsType {
  from: UTCDate
  to: UTCDate
  locations: LocationTree[]
  selectedLocation: LocationTree | undefined
  selectedFloorPlan: LocationTree | undefined
  setSelectedLocation: (location: LocationTree | undefined) => void
  saveWorkingMode: (workingMode: WorkingMode) => void | Promise<void>
  onClose: () => void
}

export default function MobileFloorPlanContent({
  from,
  to,
  locations,
  selectedLocation,
  selectedFloorPlan,
  setSelectedLocation,
  saveWorkingMode,
  onClose,
}: PropsType) {
  const t = useTranslate()
  const { openDrawer } = usePlanningContext()

  return (
    <Layout>
      <Header>
        <CloseButton onClick={onClose}>
          {openDrawer === "SLOTS_EDITOR" ? <ArrowLeft /> : <Cancel />}
        </CloseButton>
        <MobileSlotsEditorFloorPlanLocationsList
          from={from}
          to={to}
          locations={locations}
          selectedLocation={selectedLocation}
          selectedFloorPlan={selectedFloorPlan}
          maxHeight={0}
          setSelectedLocation={setSelectedLocation}
        />
      </Header>
      <div>
        <FloorPlan
          from={from}
          to={to}
          locations={locations}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          maxHeight={window.innerHeight - 100}
          saveWorkingMode={saveWorkingMode}
        />
      </div>
      <ActionLayout>
        <Button
          onClick={() => {
            if (!selectedLocation || selectedLocation?.floorPlanImageUrl) return
            saveWorkingMode({
              locationId: selectedLocation?.id,
              genericSlotValue: "office",
              label: null,
              equipments: [],
            })
          }}
          disabled={
            !selectedLocation || selectedLocation?.floorPlanImageUrl !== null
          }
          fullWidth
        >
          {t("Save")}
        </Button>
      </ActionLayout>
    </Layout>
  )
}
