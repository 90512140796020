import styled from "styled-components"

export const RulesList = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 16px 32px;
  width: 100%;

  li.header {
    grid-column-start: 1;
    grid-column-end: -1;
  }
`
