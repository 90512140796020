import { gql, useMutation } from "@apollo/client"
import { spacing } from "@mui/system"
import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useSAMLConfig, { SAMLConfig } from "graphql/admin/useSAMLConfig"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components/macro"
import {
  Alert as UiAlert,
  Button,
  colors,
  L12GreyBold,
  P14,
  P14Bold,
  P16,
  Textarea,
  TextField,
} from "ui"
import { Copy } from "ui/icons"
import copyTextToClipboard from "utils/copyTextToClipboard"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 500px;

  .texts-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    .value {
      color: ${colors.purple};
      max-width: 30ch;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    svg {
      color: ${colors.purple};
      cursor: pointer;
    }
  }

  .text {
    color: ${colors.grey1};
    font-size: 12px;
  }
`

const SAVE_SAML_CONFIG = gql`
  mutation saveSAMLConfig($config: SAMLConfigInput!) {
    saveSAMLConfig(config: $config)
  }
`

const Alert = styled(UiAlert)(spacing)

interface ConfigSAMLProps {
  config: SAMLConfig | null
  provider: "OKTA" | "RAW"
  onClose: () => void
}

function ConfigureSAMLForm({ config, provider, onClose }: ConfigSAMLProps) {
  const t = useTranslate()
  const { addToast } = useToasts()

  const [changed, setChanged] = React.useState(false)
  const [entityID, setEntityID] = React.useState<string>(config?.entityID || "")
  const [ssoURL, setSsoURL] = React.useState<string>(config?.ssoURL || "")
  const [certificate, setCertificate] = React.useState<string>(
    config?.certificate || ""
  )
  const [providerError, setProviderError] = React.useState<string | null>(null)

  const [saveSAMLConfigMutation] = useMutation(SAVE_SAML_CONFIG)

  const saveSAMLConfig = async () => {
    try {
      await saveSAMLConfigMutation({
        variables: {
          config: {
            provider: provider,
            entityID: entityID,
            ssoURL: ssoURL,
            certificate: certificate,
          },
        },
      })
      setProviderError(null)
      onClose()
    } catch (error) {
      setProviderError(error.message)
    }
    setChanged(false)
  }

  const providerSsoURL = `https://${process.env.REACT_APP_FIREBASE}.firebaseapp.com/__/auth/handler`
  const acsURL = `${process.env.REACT_APP_API}/auth/saml/raw`
  const providerEntityId = "offishall.io"

  return (
    <Container>
      <L12GreyBold>{t("Service Provider")}</L12GreyBold>

      <div className="texts-container">
        <P14>Audience URI (SP Entity ID) :</P14>
        <P14Bold className="value">{providerEntityId}</P14Bold>
        <Copy
          onClick={() => {
            copyTextToClipboard(providerEntityId)
            addToast(t("text copied confirmation"), { appearance: "success" })
          }}
        />
      </div>

      {provider === "OKTA" && (
        <div className="texts-container">
          <P14>{t("Single sign-on URL")} :</P14>
          <P14Bold className="value">{providerSsoURL}</P14Bold>
          <Copy
            onClick={() => {
              copyTextToClipboard(providerSsoURL)
              addToast(t("text copied confirmation"), { appearance: "success" })
            }}
          />
        </div>
      )}

      {provider === "RAW" && (
        <div className="texts-container">
          <P14>{t("ACS URL")} :</P14>
          <P14Bold className="value">{acsURL}</P14Bold>
          <Copy
            onClick={() => {
              copyTextToClipboard(acsURL)
              addToast(t("text copied confirmation"), { appearance: "success" })
            }}
          />
        </div>
      )}

      <TextField
        label={t("Identity Provider")}
        placeholder={t("Single sign-on URL")}
        initialValue={ssoURL}
        handleChange={(e) => {
          setChanged(true)
          setSsoURL(e)
        }}
      />

      <TextField
        placeholder={t("entity ID")}
        initialValue={entityID}
        handleChange={(e) => {
          setChanged(true)
          setEntityID(e)
        }}
      />

      <Textarea
        placeholder={t("certificate")}
        value={certificate}
        handleChange={(e) => {
          setChanged(true)
          setCertificate(e)
        }}
      />
      <P14 className="text">
        {t(
          'Must start with "-----BEGIN CERTIFICATE-----", and end with "-----END CERTIFICATE-----"'
        )}
      </P14>

      {providerError && (
        <Alert mt={2} mb={1} severity="error">
          <P16>{providerError}</P16>
        </Alert>
      )}
      <Button
        disabled={!(changed && entityID && ssoURL && certificate)}
        onClick={saveSAMLConfig}
      >
        {t("Save SAML configuration")}
      </Button>
    </Container>
  )
}

export default function ConfigureSAML({
  provider,
  onClose,
}: {
  provider: "OKTA" | "RAW"
  onClose: () => void
}) {
  const { config, loading, error } = useSAMLConfig(provider)

  if (loading || error) {
    return <LoaderOrErrorComponent loading={loading} error={error} />
  }

  return (
    <ConfigureSAMLForm config={config} onClose={onClose} provider={provider} />
  )
}
