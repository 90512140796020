import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useEquipments from "graphql/equipments/useEquipments"
import useUserEquipments from "graphql/equipments/useUserEquipments"
import useLocationsTree from "graphql/locations/useLocationsTree"
import usePlanningUserSlots from "graphql/slots/usePlanningUserSlots"
import useEditableUserSlotsPermission from "hooks/useEditableUserSlotsPermission"
import usePacman from "hooks/usePacman"
import useUserTeamDays from "hooks/useUserTeamDays"
import useTranslate from "intl/useTranslate"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components/macro"
import { EditionTimeFrame, User, UTCSlot } from "types"
import LocationTree, { getParentsFromLocationId } from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

import SlotsEditor from "../../SlotsEditor/SlotsEditor"
import getSlotHoverInfo from "../../utils/getSlotHoverInfo"
import EditableUserSlot from "../EditableUserSlot/EditableUserSlot"
import PacmanLike from "../PacmanLike/PacmanLike"
import getUTCSlotProps from "../utils/getUTCSlotProps"
import updateCurrentUserSlotsClassList from "../utils/updateCurrentUserSlotsClassList"
import updateSelectedSlotsClassList from "../utils/updateSelectedSlotsClassList"

const MainLayout = styled.div`
  position: relative;
  padding: 16px 8px 16px 0;
  width: 140px;

  // prevent blue selection rect when dragging
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  div:last-child {
    background-image: none;
  }
`

const SlotsListLayout = styled.div`
  display: grid;
  align-items: center;
  position: relative; // slot popup position relative to list layout
  grid-template-columns: repeat(2, 1fr);

  background: #ffffff;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.15);
  border-radius: 64px;
`

interface CurrentUserSlotPropsType extends UTCSlot {
  isToday?: boolean
  isMorning?: boolean
  isFirst?: boolean
  isLast?: boolean
  isSelected?: boolean
}

interface PropsType {
  from: UTCDate
  to: UTCDate
  userToMutate: User
}

export default function OneDayEditableSlots({
  from,
  to,
  userToMutate,
}: PropsType) {
  const t = useTranslate()
  const {
    userShowWeekends,
    activeLocation,
    setActiveLocation,
    openDrawer,
    setOpenDrawer,
    isTypicalWeekOpen,
    viewMode,
  } = usePlanningContext()

  const canEdit = useEditableUserSlotsPermission(userToMutate)

  const [editionTimeFrame, setEditionTimeFrame] = useState<EditionTimeFrame>({
    from: undefined,
    to: undefined,
  })

  const openSlotsEditor =
    openDrawer === "SLOTS_EDITOR" &&
    !location.pathname.startsWith("/typical-week")

  const localSelectedSlots = useRef<UTCSlot[]>([])

  const { locations } = useLocationsTree()

  const editionActive = openDrawer === "SLOTS_EDITOR"

  const isEditionActive =
    editionActive &&
    editionTimeFrame.from !== undefined &&
    editionTimeFrame.to !== undefined

  const { slots } = usePlanningUserSlots({
    userId: userToMutate.id,
    from,
    to,
  })

  const userTeamDays = useUserTeamDays({ selectedUser: userToMutate })

  const initialDragEndings: {
    startingSlot: { slot: UTCSlot; index: number } | undefined
    endingSlot: { slot: UTCSlot; index: number } | undefined
    isDragging: boolean
  } = {
    startingSlot: undefined,
    endingSlot: undefined,
    isDragging: false,
  }

  const currentUserSlotsRef = useRef<HTMLTableRowElement>(null)

  const dragEndings = useRef(initialDragEndings)

  const currentSlots = useRef<CurrentUserSlotPropsType[]>(slots)

  const initialPacman = {
    previousX: 0,
    enabled: true,
    direction: 0,
  }

  const pacman = useRef(initialPacman)
  const { setPosition, reset } = usePacman()

  const intersects = (position: number, elem: Element) => {
    const { x, width } = elem.getBoundingClientRect()
    return position >= x && position <= x + width
  }

  /**
   * checks if the pointer intersects with one of the CurrentUserSlots' row children
   */
  const storeStartingSlot = (pointerX: number) => {
    if (
      currentUserSlotsRef.current &&
      currentUserSlotsRef.current.children.length
    ) {
      Array.from(currentUserSlotsRef.current.children).forEach((child, i) => {
        if (intersects(pointerX, child)) {
          dragEndings.current = {
            startingSlot: { slot: slots[i], index: i },
            endingSlot: undefined,
            isDragging: true,
          }
        }
      })
    }
  }

  /**
   * checks if the pointer intersects with one of the CurrentUserSlots' row children
   */
  const updateEndingSlots = (pointerX: number) => {
    if (
      currentUserSlotsRef.current &&
      currentUserSlotsRef.current.children.length
    ) {
      Array.from(currentUserSlotsRef.current.children).forEach((child, i) => {
        if (intersects(pointerX, child)) {
          dragEndings.current = {
            ...dragEndings.current,
            endingSlot: { slot: slots[i], index: i },
            isDragging: true,
          }
        }
      })
    }
    if (pacman.current.enabled) {
      document.documentElement.style.setProperty("--pacman-x", `${pointerX}px`)
      document.documentElement.style.setProperty(
        "--pacman-visibility",
        `visible`
      )
      if (pointerX !== pacman.current.previousX) {
        pacman.current.direction =
          pointerX - pacman.current.previousX > 0 ? 1 : -1
      }
      pacman.current.previousX = pointerX
      document.documentElement.style.setProperty(
        "--pacman-direction",
        `${pacman.current.direction}`
      )
    }
  }

  const updateSelectedSlots = () => {
    if (dragEndings.current.startingSlot && dragEndings.current.endingSlot) {
      /**
       * pointer can move freely around starting position
       * and the starting position indicates one end of the selected timeframe
       *
       * selected slots are slots with a date contained in between
       * the earlier selected slot's date and the later selected slot's date
       *
       * which means that we have to take care of chronology when filtering slots
       */
      const startingDate = dragEndings.current.startingSlot.slot.date
      const endingDate = dragEndings.current.endingSlot.slot.date
      const [from, to] =
        startingDate < endingDate
          ? [startingDate, endingDate]
          : [endingDate, startingDate]

      localSelectedSlots.current = slots.filter(
        (s) => s.date >= from && s.date <= to
      )
    }
  }

  const updateCurrentUserSlots = () => {
    if (currentUserSlotsRef.current) {
      /**
       * a slot state depends on its direct neighbours' state
       * since selected slots have changed because pointer has moved
       * we need to recompute currentSlots state
       */
      currentSlots.current = slots.map((slot, i) => ({
        ...slot,
        ...getUTCSlotProps(
          i,
          slot,
          slots,
          localSelectedSlots.current,
          viewMode,
          userShowWeekends
        ),
      }))
    }
  }

  const updateCurrentUserSlotsStyle = () => {
    if (currentUserSlotsRef.current) {
      /**
       * pointer position changes result in a new set of selected slots
       * since slots style depends on their neighbours' state
       * when selected slots have changed some slots CSS classes are obsolete
       * we need to update those accrodingly
       *
       * (here we recompute all slots, selected included)
       */
      updateCurrentUserSlotsClassList(
        currentUserSlotsRef.current,
        currentSlots.current
      )
    }
  }

  const updateSelectedSlotsStyle = () => {
    if (currentUserSlotsRef.current && dragEndings.current) {
      const { startingSlot, endingSlot } = dragEndings.current
      if (startingSlot && endingSlot) {
        /**
         * in previous step, we cleared all CSS classes on all slots
         * which means we now have to apply CSS classes accrodingly to
         * selected slots new states
         */
        updateSelectedSlotsClassList(
          startingSlot.index,
          endingSlot.index,
          currentUserSlotsRef.current
        )
      }
    }
  }

  const resetPacman = () => {
    /**
     * pacman resetted both at start and end dragging
     */
    pacman.current = initialPacman
    if (currentUserSlotsRef.current) {
      const position = {
        x: currentUserSlotsRef.current.getBoundingClientRect().x,
        y: currentUserSlotsRef.current.getBoundingClientRect().y,
      }
      setPosition(position)
      reset()
    }
  }

  const resetMouseMessage = () => {
    document.documentElement.style.setProperty(
      "--mouse-message-visibility",
      "hidden"
    )
  }

  const resetDragging = useRef(() => {
    dragEndings.current = initialDragEndings
  })

  const updateOnPointerStartDragging = (x: number) => {
    /**
     * store startingSlot is invoked only once here
     * startingSlot will never change throughout the dragging in process
     */
    localSelectedSlots.current = []
    resetDragging.current()
    resetPacman()
    storeStartingSlot(x)
  }

  const updateOnPointerDragging = (x: number) => {
    /**
     * moving the pointer results in constantly changing selected slots
     * which means that we have to recompute which are the selected slots
     *
     * in the computations we have to take care off startingSlot.date being
     * greater than endingSlot.date
     *
     * 1. update ending slots to be able to update selected slots
     * 2. update current user slots states which depend on selected slots
     * 3. reset an apply new style on all slots
     * 4. reset and apply new style to selected slots
     */
    updateEndingSlots(x)
    updateSelectedSlots()
    updateCurrentUserSlots()
    updateCurrentUserSlotsStyle()
    updateSelectedSlotsStyle()
  }

  const updateOnPointerEndDragging = () => {
    /**
     * update endingSlot is invoked  while the pointer is in dragging motion
     * endingSlot will be updated throughout the entire dragging in process
     */
    if (dragEndings.current.startingSlot && !dragEndings.current.endingSlot) {
      localSelectedSlots.current = [dragEndings.current.startingSlot.slot]
    }

    const from =
      localSelectedSlots.current.length > 0
        ? localSelectedSlots.current[0].date
        : undefined
    const to =
      localSelectedSlots.current.length > 0
        ? localSelectedSlots.current[localSelectedSlots.current.length - 1].date
        : undefined

    setEditionTimeFrame({
      from,
      to,
    })
    setOpenDrawer("SLOTS_EDITOR")

    resetDragging.current()
    resetPacman()
  }

  const updateActiveLocation = (slot: UTCSlot, locationId: string | null) => {
    return setActiveLocation({
      location:
        locationId !== null
          ? LocationTree.getLocationNode(locations, locationId) ?? null
          : null,
      persist: false,
      triggeredFrom: "planning",
    })
  }

  useEffect(() => {
    if (currentUserSlotsRef.current) {
      const position = {
        x: currentUserSlotsRef.current.getBoundingClientRect().x,
        y: currentUserSlotsRef.current.getBoundingClientRect().y,
      }
      setPosition(position)
    }
  }, [currentUserSlotsRef, setPosition])

  useEffect(() => {
    // Selecting a menu item must close slots editor
    // so clicking on a menu item sets edition active to false
    // then slots editor has to be reset
    if (!editionActive && dragEndings.current.endingSlot) {
      resetDragging.current()
      localSelectedSlots.current = []
    }
    if (!editionActive && !isTypicalWeekOpen) {
      setEditionTimeFrame({})
    }
  }, [
    editionActive,
    isTypicalWeekOpen,
    localSelectedSlots,
    setEditionTimeFrame,
  ])

  const { equipments } = useEquipments()

  const { userEquipments } = useUserEquipments({
    userId: userToMutate.id,
    from,
    to,
  })

  return (
    <>
      <MainLayout
        onTouchStartCapture={(e) => {
          e.preventDefault()
          if (canEdit) updateOnPointerStartDragging(e.touches[0].pageX)
        }}
        onTouchMoveCapture={(e) => {
          if (canEdit && dragEndings.current.isDragging)
            updateOnPointerDragging(e.touches[0].pageX)
        }}
        onTouchEndCapture={() => {
          if (canEdit && dragEndings.current.isDragging)
            updateOnPointerEndDragging()
        }}
        onMouseDownCapture={(e) => {
          e.preventDefault()
          if (canEdit) updateOnPointerStartDragging(e.pageX)
        }}
        onMouseMove={(e) => {
          if (
            canEdit &&
            dragEndings.current.isDragging &&
            dragEndings.current.startingSlot
          ) {
            return updateOnPointerDragging(e.pageX)
          }
        }}
        onMouseLeave={() => {
          if (canEdit && dragEndings.current.isDragging)
            updateOnPointerEndDragging()
        }}
        onMouseUpCapture={() => {
          if (canEdit && dragEndings.current.isDragging)
            updateOnPointerEndDragging()
        }}
      >
        <SlotsListLayout
          ref={currentUserSlotsRef}
          className={canEdit ? "cursor--pointer" : "cursor--disabled"}
        >
          {slots.map((slot, i) => {
            const parentIsActive =
              slot.locationId !== null &&
              getParentsFromLocationId(locations, slot.locationId)?.find(
                (l) => l.id === activeLocation.location?.id
              ) !== undefined

            const isActive =
              isEditionActive ||
              activeLocation.location === null ||
              activeLocation.location?.id === slot.locationId ||
              parentIsActive

            return (
              <EditableUserSlot
                key={slot.id}
                index={i}
                slot={slot}
                slots={slots}
                actualSelectedSlots={
                  isEditionActive ? localSelectedSlots.current : []
                }
                equipments={equipments}
                userEquipments={userEquipments}
                userTeamDays={userTeamDays}
                editionTimeFrame={editionTimeFrame}
                isActive={isActive}
                hoverInfo={getSlotHoverInfo(slot, locations, t)}
                showHoverInfo={!isTypicalWeekOpen}
                viewMode={viewMode}
                userShowWeekends={userShowWeekends}
                onMouseEnter={() => {
                  if (
                    slot.locationId &&
                    !isTypicalWeekOpen &&
                    !isEditionActive &&
                    !activeLocation.persist &&
                    dragEndings.current.isDragging === false
                  ) {
                    updateActiveLocation(slot, slot.locationId)
                  }
                }}
                onMouseLeave={() => {
                  if (
                    slot.locationId &&
                    activeLocation.location &&
                    !activeLocation.persist &&
                    dragEndings.current.isDragging === false
                  ) {
                    updateActiveLocation(slot, null)
                  }
                  resetMouseMessage()
                }}
              />
            )
          })}
        </SlotsListLayout>
      </MainLayout>
      <PacmanLike />
      {openSlotsEditor && (
        <SlotsEditor
          editionTimeFrame={editionTimeFrame}
          setEditionTimeFrame={setEditionTimeFrame}
        />
      )}
    </>
  )
}
