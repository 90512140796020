import UserAvatar from "components/UserAvatar/UserAvatar"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { AttendeeStatus, Flags, UTCSlot } from "types"
import { L12, P16 } from "ui"
import getColorFromStatus from "utils/getColorFromStatus"
import getUserDisplayName from "utils/getUserDisplayName"
import UserTree from "utils/UserTree"

import { Item } from "../Item"

const SlotBadgesLayout = styled.div`
  display: flex;
  gap: 4px;
`

const SlotValueBadge = styled.div<{ value: string | null }>`
  text-align: center;
  border-radius: 100vh;
  border: solid 2px transparent;
  padding: 2px 4px;
  min-width: 78px;

  ${({ value }) => `
    border-color: ${getColorFromStatus(value)};
    background: ${getColorFromStatus(value)}12;
  `}
`

interface PropsType {
  user: UserTree & {
    attendingStatus: AttendeeStatus
    isOrganizer: boolean
  }
  userSlots: UTCSlot[]
  companyFlags: Flags
}
export default function GuestUserItem({
  user,
  userSlots,
  companyFlags,
}: PropsType) {
  const t = useTranslate()

  return (
    <Item>
      <UserAvatar user={user} />
      <P16 className="name">{getUserDisplayName(user, companyFlags)}</P16>
      {user.isOrganizer && (
        <L12 className="organizer-badge">{t("organizer")}</L12>
      )}
      <SlotBadgesLayout>
        {userSlots.length > 0 && (
          <SlotValueBadge value={userSlots[0].value}>
            <L12>
              {userSlots[0].date.isMorning() ? t("Morning") : t("Afternoon")}
            </L12>
          </SlotValueBadge>
        )}
        {userSlots.length > 1 && (
          <SlotValueBadge value={userSlots[1].value}>
            <L12>
              {userSlots[1].date.isMorning() ? t("Morning") : t("Afternoon")}
            </L12>
          </SlotValueBadge>
        )}
        <L12
          className={`attending-status ${
            user.attendingStatus !== "attending"
              ? "attending-status--error"
              : "attending-status--success"
          }`}
        >
          {t(user.attendingStatus)}
        </L12>
      </SlotBadgesLayout>
    </Item>
  )
}
