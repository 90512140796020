import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useTranslate from "intl/useTranslate"
import AuthLayout from "PublicApp/AuthLayout"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components/macro"
import { colors, H3 } from "ui"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 480px;

  h3 {
    color: ${colors.purple};
  }
`

export default function OnboardingTokenError() {
  const t = useTranslate()
  const { addToast } = useToasts()
  const [errorShown, setErrorShown] = useState(false)

  useEffect(() => {
    if (sessionStorage.getItem("signupToken") === null && !errorShown) {
      addToast("Invalid signup token", {
        appearance: "error",
        autoDismissTimeout: 10000,
      })
      setErrorShown(true)
    }
  }, [addToast, errorShown])

  return (
    <AuthLayout>
      <Container>
        <Helmet title={t("Sign in")} />
        <H3>{t("welcome")}</H3>
      </Container>
    </AuthLayout>
  )
}
