import { Capacitor } from "@capacitor/core"
import { TextField as MuiTextField } from "@mui/material"
import { Formik } from "formik"
import useResetPassword from "hooks/auth/useResetPassword"
import useTranslate from "intl/useTranslate"
import useTranslation from "intl/useTranslation"
import React, { useState } from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components/macro"
import { Button, colors, H3, Link, mediaQueries, P14, P16 } from "ui"
import { Cancel, Check } from "ui/icons"
import * as Yup from "yup"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 480px;

  @media ${mediaQueries.isMobile} {
    margin: 0 auto;
    width: 80vw;
  }

  h3 {
    color: ${colors.purple};
    text-align: center;
  }

  .confirmation-message {
    display: flex;
    flex-direction: row;
    margin-top: 24px;
    p {
      margin-left: 12px;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 370px;
    margin-top: 16px;
    gap: 16px;

    @media ${mediaQueries.isMobile} {
      width: 280px;
    }
  }

  .back-to-signin {
    color: ${colors.purple};
  }
`

const FormikTextField = styled(MuiTextField)`
  width: 100%;

  & .MuiOutlinedInput-root {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: ${colors.black};
    height: 40px;
    border: 1px solid ${colors.grey2};
  }
  & .MuiOutlinedInput-root :hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)),
      ${colors.grey4};
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 10px;
    color: ${colors.black};
    border-radius: 4px;
  }
  & .MuiOutlinedInput-root.Mui-disabled {
    background: ${colors.grey4};
    cursor: not-allowed;
    color: ${colors.grey1};
  }
  & .MuiOutlinedInput-root.Mui-focused {
    background: linear-gradient(0deg, ${colors.white}, ${colors.white}),
      ${colors.grey4};
    border: 2px solid ${colors.green};
    color: ${colors.black};
    height: 40px;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  & .MuiInputAdornment-root {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: ${colors.black};
    height: 40px;
  }
`

const StyledButton = styled(Button)`
  width: 370px;

  @media ${mediaQueries.isMobile} {
    width: 280px;
  }
`

function ResetPassword() {
  const resetPassword = useResetPassword()
  const t = useTranslate()
  const [locale] = useTranslation()
  const isIOSApp = Capacitor.getPlatform() === "ios"
  const [info, setInfo] = useState("")
  if (info) {
    return (
      <Container>
        <Helmet title={t("Reset password")} />
        <H3>{t("Reset password")}</H3>
        <div className="confirmation-message">
          <Check />
          <P16>{info}</P16>
        </div>
      </Container>
    )
  }
  return (
    <Container>
      <Helmet title={t("Reset password")} />

      <H3>{t("Reset password")}</H3>
      <P16>{t("Enter your email to reset your password")}</P16>

      <Formik
        initialValues={{
          email: "",
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(t("Must be a valid email"))
            .max(255)
            .required(t("Email is required")),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await resetPassword(values.email, locale.id)
            setInfo(
              t(
                "An email has been sent to the address to reset your password",
                {
                  address: values.email,
                }
              )
            )
          } catch (error) {
            const message = error.message || t("Something went wrong")

            setStatus({ success: false })
            setErrors({ submit: message })
            setSubmitting(false)
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit} className="form">
            {errors.submit && (
              <div className="confirmation-message">
                <Cancel />
                <P16>{errors.submit}</P16>
              </div>
            )}
            <FormikTextField
              type="email"
              name="email"
              variant="outlined"
              placeholder={t("Email address")}
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              size="small"
            />
            <StyledButton type="submit" disabled={isSubmitting}>
              {t("Reset password")}
            </StyledButton>
            {isIOSApp && (
              <Link to="/">
                <P14 className="back-to-signin">{t("Back to sign-in")}</P14>
              </Link>
            )}
          </form>
        )}
      </Formik>
    </Container>
  )
}

export default ResetPassword
