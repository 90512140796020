import Loader from "components/Loader/Loader"
import useUser from "graphql/users/useUser"
import {
  useDefaultHybridRuleScore,
  useGroupHybridRuleScore,
  useManagerHybridRuleScore,
  useUserHybridRuleScore,
} from "hooks/useHybridRuleScore"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import {
  DefaultHybridRule,
  GroupHybridRule,
  GroupRuleMonthScore,
  GroupRuleWeekScore,
  HybridRule,
  ManagerHybridRule,
  UserHybridRule,
  UserRuleMonthScore,
  UserRuleWeekScore,
} from "types"
import { colors, L12 } from "ui"
import LocalDate from "utils/LocalDate"
import UTCDate from "utils/UTCDate"

import ActionButton from "./ActionButton/ActionButton"
import DaysList from "./DaysList/DaysList"
import Period from "./Period/Period"
import ScoreAsText from "./ScoreAsText/ScoreAsText"

const Layout = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  width: 100%;
  min-height: 219px;
  border-top: solid 1px ${colors.grey3}44;
`

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 16px;

  .rule-respect-summary {
    display: flex;
    align-items: center;
    gap: 16px;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    color: ${colors.black};
    width: 20px;
    height: 20px;
  }
`

const Label = styled(L12)`
  padding: 4px 8px;
  border-radius: 4px;
  background: ${colors.grey4};
  color: ${colors.grey1};
  max-width: fit-content;
`

interface PropsType {
  rule: DefaultHybridRule | UserHybridRule | GroupHybridRule | ManagerHybridRule
  userId?: string
  managerId?: string
  groupId?: string
  hideDelete?: boolean
  setSelectedUserOrGroupHybridRule: (rule: HybridRule) => void
  deleteHybridRule: (ruleId: string) => void
}

interface CardPropsType
  extends Omit<PropsType, "userId" | "managerId" | "groupId"> {
  loading?: boolean
  score: {
    monthIndex?: number
    weekIndex?: number
    concernedUserIds: string[]
    compliantUserIds: string[]
  }
}

function HyridRuleCard({
  rule,
  score,
  hideDelete,
  loading,
  setSelectedUserOrGroupHybridRule,
  deleteHybridRule,
}: CardPropsType) {
  const t = useTranslate()
  const { user: me } = useUser()

  const getRuleName = (
    rule:
      | DefaultHybridRule
      | UserHybridRule
      | GroupHybridRule
      | ManagerHybridRule
  ) => {
    if (rule.name === "") return "n/a"
    if (rule.name === "default rule") return t("default rule")
    return rule.name
  }

  if (!me) return <></>

  if (loading)
    return (
      <Layout>
        <Loader />
      </Layout>
    )

  return (
    <Layout>
      <Main>
        <Header>
          <Label>{getRuleName(rule)}</Label>
          <ActionButton
            hideDelete={hideDelete}
            setSelectedUserOrGroupHybridRule={() =>
              setSelectedUserOrGroupHybridRule(rule)
            }
            deleteHybridWordkRule={() => deleteHybridRule(rule.id)}
          />
        </Header>
        <Period
          rule={{
            ...rule,
            monthIndex: score.monthIndex,
            weekIndex: score.weekIndex,
          }}
        />
        <ScoreAsText
          maxDays={rule.maxDays}
          concernedUserIds={score.concernedUserIds}
          compliantUserIds={score.compliantUserIds}
        />
      </Main>
      <DaysList rule={rule} />
    </Layout>
  )
}

function UserCard({ userId, rule, ...props }: PropsType & { userId: string }) {
  const { score: userScore, loading } = useUserHybridRuleScore(
    new UTCDate("DAY"),
    rule,
    userId
  )

  if (!userScore || loading) return <></>

  return (
    <HyridRuleCard
      rule={rule}
      score={{
        monthIndex: (userScore as UserRuleMonthScore).monthIndex,
        weekIndex: (userScore as UserRuleWeekScore).weekIndex,
        concernedUserIds: userScore.concernedUserIds,
        compliantUserIds: userScore.compliantUserIds,
      }}
      {...props}
    />
  )
}
function ManagerCard({
  managerId,
  rule,
  ...props
}: PropsType & { managerId: string }) {
  const { score: managerScore, loading } = useManagerHybridRuleScore(
    new UTCDate("DAY"),
    rule,
    managerId
  )

  if (!managerScore || loading) return <></>

  return (
    <HyridRuleCard
      userId={managerId}
      rule={rule}
      score={{
        monthIndex: (managerScore as GroupRuleMonthScore).monthIndex,
        weekIndex: (managerScore as GroupRuleWeekScore).weekIndex,
        concernedUserIds: managerScore.concernedUserIds,
        compliantUserIds: managerScore.compliantUserIds,
      }}
      {...props}
    />
  )
}

function GroupRuleCard({ groupId, rule, ...props }: PropsType) {
  const { score: groupScore } = useGroupHybridRuleScore(
    new UTCDate("DAY"),
    rule,
    groupId ?? ""
  )

  if (!groupScore) return <></>

  return (
    <HyridRuleCard
      rule={rule}
      score={{
        monthIndex: (groupScore as GroupRuleMonthScore).monthIndex,
        weekIndex: (groupScore as GroupRuleWeekScore).weekIndex,
        concernedUserIds: groupScore.concernedUserIds,
        compliantUserIds: groupScore.compliantUserIds,
      }}
      {...props}
    />
  )
}

function DefaultRuleCard({ userId, rule, ...props }: PropsType) {
  const { score: defaultScore, loading } = useDefaultHybridRuleScore(
    new UTCDate("DAY"),
    rule
  )

  if (!defaultScore?.score)
    return (
      <Layout>
        <Loader />
      </Layout>
    )

  return (
    <HyridRuleCard
      rule={rule}
      score={{
        monthIndex:
          defaultScore.period === "month"
            ? new LocalDate(Number(defaultScore.score.from)).getMonth()
            : undefined,
        weekIndex:
          defaultScore.period === "week"
            ? new LocalDate(Number(defaultScore.score.from)).getWeekNumber()
            : undefined,
        concernedUserIds: defaultScore.score.concernedUserIds,
        compliantUserIds: defaultScore.score.compliantUserIds,
      }}
      loading={loading}
      {...props}
    />
  )
}

export default function Wrapper({
  userId,
  groupId,
  managerId,
  ...props
}: PropsType) {
  if (userId) return <UserCard userId={userId} {...props} />
  if (managerId) return <ManagerCard managerId={managerId} {...props} />
  if (groupId) return <GroupRuleCard groupId={groupId} {...props} />
  return <DefaultRuleCard hideDelete {...props} />
}
