import ColorLegend from "components/ColorLegend/ColorLegend"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useLocationsTree from "graphql/locations/useLocationsTree"
import useLogEvent from "graphql/misc/useLogEvent"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { BigToggle, IconButton } from "ui"
import { Map } from "ui/icons"
import { getFloorPlans } from "utils/LocationTree"

const StyledIconButton = styled(IconButton)`
  svg {
    width: 20px;
    height: 20px;
    fill: none;
  }
`

interface PropsType {
  variant?: "simple"
}

export default function ExternalActions({ variant }: PropsType) {
  const t = useTranslate()
  const { isDesktop } = useMediaQueries()

  const { viewMode, setViewMode, setOpenDrawer } = usePlanningContext()
  const logEvent = useLogEvent()

  const { locations } = useLocationsTree()
  const floorPlans = getFloorPlans(locations)

  const showActions = isDesktop

  const showWeekMonthToggle =
    isDesktop &&
    (location.pathname === "/" || location.pathname.startsWith("/planning"))

  const showLegend =
    variant !== "simple" &&
    (location.pathname === "/" || location.pathname.startsWith("/planning"))

  const showFloorPlans =
    floorPlans.length > 0 &&
    (location.pathname === "/" || location.pathname.startsWith("/planning"))

  if (!showActions) return <></>

  return (
    <>
      {showWeekMonthToggle && (
        <BigToggle
          checked={viewMode === "MONTH"}
          checkedLabel={t("month")}
          uncheckedLabel={t("week")}
          onChange={(checked) => {
            logEvent("monthly_view_clicked", {
              mode: checked ? "month" : "week",
            })
            if (checked) return setViewMode("MONTH")
            setViewMode("WEEK")
          }}
        />
      )}
      {showLegend && <ColorLegend />}

      {showFloorPlans && (
        <StyledIconButton
          icon={<Map />}
          disabled={viewMode === "MONTH"}
          onClick={() => {
            setOpenDrawer("FLOOR_PLAN")
          }}
        />
      )}
    </>
  )
}
