import FavoriteCheckbox from "components/FavoriteCheckbox"
import FloorPlanProps from "components/FloorPlanComponents/FloorPlanProps"
import LocationFloorPlanAction from "components/FloorPlanComponents/LocationFloorPlanAction"
import { VisualEditorMode } from "components/FloorPlanComponents/VisualAreaEditor/VisualAreaEditor"
import DeleteLocationButton from "components/LocationComponents/Buttons/DeleteLocationButton/DeleteLocationButton"
import EditLocationButton from "components/LocationComponents/Buttons/EditLocationButton/EditLocationButton"
import useTranslate from "intl/useTranslate"
import UpdateLocationDialog from "pages/AdminPage/AdminLocationsPage/AddLocationButton/UpdateLocationDialog/UpdateLocationDialog"
import React, { useState } from "react"
import styled from "styled-components"
import { colors, L12, LU14, P16, transitions } from "ui"
import { ArrowDownFill } from "ui/icons"
import LocationTree, { getChildrenLocations } from "utils/LocationTree"

import { ContentList } from "./components/ContentList"
import { Header } from "./components/Header"
import LocationItem from "./LocationItem"

interface LayoutProps {
  isActive: boolean
  hasBreadcrumbs: boolean
}

const Layout = styled.ul<LayoutProps>`
  display: flex;
  flex-direction: column;
  border-radius: 8px 0px 0px 8px;

  ${(props) => props.hasBreadcrumbs && `margin: 0 0 0 12px; `}

  ${(props) => props.isActive && `background: #EDEEEE;`}

  .actions {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 4px;

    svg {
      width: 18px;
      stroke: ${colors.grey2};
    }

    .trash-icon:hover {
      svg {
        stroke: ${colors.red};
      }
    }

    .edit-icon:hover {
      svg {
        stroke: ${colors.black};
      }
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  transition: ${transitions.generic};
`

const AddSpaceText = styled(L12)`
  cursor: pointer;
  padding-bottom: 12px;
  width: fit-content;
`

interface PropsType {
  category: LocationTree
  withActions?: boolean
  showFavoriteCheckbox?: boolean
  disabled?: boolean
  selectedZone?: LocationTree

  onClick?: () => void
  setSelectedZone?: (zone: LocationTree) => void

  floorPlan?: FloorPlanProps
}

export default function CategoryItem({
  category,
  withActions,
  showFavoriteCheckbox,
  disabled,
  onClick,
  floorPlan,
  selectedZone,
  setSelectedZone,
}: PropsType) {
  const t = useTranslate()

  const [isCollapseOpen, setIsCollapseOpen] = useState<boolean>(false)
  const [isAddLocationOpen, setIsAddLocationOpen] = useState<boolean>(false)

  const skipChildren =
    floorPlan &&
    floorPlan.mode === VisualEditorMode.VIEWER &&
    !!category.floorPlanImageUrl

  const childFloorPlan = floorPlan && {
    ...floorPlan,
    parentHasFloorPlan:
      floorPlan.parentHasFloorPlan || !!category.floorPlanImageUrl,
    isPartOfActiveFloorPlan:
      floorPlan.isPartOfActiveFloorPlan ||
      (!!category.floorPlanImageUrl &&
        category.id === floorPlan.activeFloorPlan?.id),
  }

  const isActive = selectedZone ? category.id === selectedZone.id : false
  return (
    <Layout
      isActive={isActive}
      hasBreadcrumbs={category.breadCrumbs.length === 0}
    >
      <Header
        canClick={floorPlan ? true : false || onClick}
        onClick={() => {
          if (floorPlan) {
            if (!isCollapseOpen) setIsCollapseOpen(true)
            floorPlan.select(category.id)
          } else if (onClick) {
            onClick()
          }
          if (setSelectedZone) setSelectedZone(category)
        }}
      >
        {!skipChildren && (
          <ArrowDownFill
            onClick={() => {
              setIsCollapseOpen((prev) => !prev)
            }}
            className={
              isCollapseOpen ? "arrow-btn" : "arrow-btn arrow-btn--turned"
            }
          />
        )}

        <LU14 className="title">{category.name}</LU14>

        <div className="actions">
          {showFavoriteCheckbox && (
            <FavoriteCheckbox
              type="location"
              itemId={category.id}
              disabled={disabled}
            />
          )}
          {withActions && (
            <>
              <DeleteLocationButton location={category} disabled={disabled} />
              <EditLocationButton location={category} disabled={disabled} />
            </>
          )}
        </div>
        {floorPlan ? (
          <P16 className="more-text">
            <LocationFloorPlanAction
              location={category}
              disabled={disabled}
              floorPlan={floorPlan}
            />
          </P16>
        ) : (
          <P16 className="more-text">{`${
            getChildrenLocations(category.children).length
          } ${t(
            getChildrenLocations(category.children).length > 1
              ? "Places"
              : "Place"
          ).toLowerCase()}`}</P16>
        )}
      </Header>
      {isCollapseOpen && (
        <ContentList>
          <li>
            <ul className="list">
              {category.children
                .filter((c) => !c.isCategory)
                .map((child) => (
                  <li key={`parent-${category.id}-loc-${child.id}`}>
                    <LocationItem
                      location={child}
                      withActions={withActions}
                      showFavoriteCheckbox={showFavoriteCheckbox}
                      floorPlan={childFloorPlan}
                    />
                  </li>
                ))}
            </ul>
          </li>
          {!skipChildren && (
            <li>
              <ul className="list">
                {category.children
                  .filter((c) => c.isCategory)
                  .map((child) => (
                    <li key={`parent-${category.id}-cat-${child.id}`}>
                      <CategoryItem
                        category={child}
                        withActions={withActions}
                        showFavoriteCheckbox={showFavoriteCheckbox}
                        floorPlan={childFloorPlan}
                        selectedZone={selectedZone}
                        setSelectedZone={setSelectedZone}
                      />
                    </li>
                  ))}
              </ul>
            </li>
          )}
          {isActive && (
            <>
              <AddSpaceText
                color="purple"
                onClick={() => setIsAddLocationOpen(true)}
              >
                {t("Add a working space")}
              </AddSpaceText>
              <UpdateLocationDialog
                open={isAddLocationOpen}
                initialInputLocation={{
                  name: "",
                  parentId: category.id,
                }}
                onClose={() => setIsAddLocationOpen(false)}
              />
            </>
          )}
        </ContentList>
      )}
    </Layout>
  )
}
