import styled from "styled-components"
import { colors } from "ui"

export const Item = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 100vh;
  border: solid 1px ${colors.grey3};
  padding: 8px 16px;
  width: 100%;
  max-height: 36px;
  cursor: default;

  > :last-child {
    margin-left: auto;
  }

  .name {
    padding-right: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .organizer-badge {
    color: ${colors.purple};
    font-weight: bold;
  }

  .attending-status {
    border: solid 1px ${colors.purple}14;
    border-radius: 4px;
    padding: 2px 4px;
    background: ${colors.purple}07;
    min-width: 68px;
    text-align: center;

    &--success {
      color: ${colors.purple};
    }

    &--error {
      color: ${colors.purple}44;
    }
  }
`
