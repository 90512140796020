import useTranslate from "intl/useTranslate"
import React from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components/macro"
import { colors, H3, mediaQueries, P16 } from "ui"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 480px;

  @media ${mediaQueries.isMobile} {
    width: 90%;
    margin-left: 15px;
    height: 210px;
  }

  .divider {
    height: 20px;
  }

  .texts-container {
    width: 430px;
    margin-top: 20px;

    @media ${mediaQueries.isMobile} {
      width: 320px;
      margin-top: 10px;
    }
  }

  p {
    text-align: center;
  }

  h3 {
    color: ${colors.purple};
    margin-bottom: 20px;
  }
`

export default function AccountValidation() {
  const t = useTranslate()
  return (
    <Container>
      <Helmet title={t("Confirm your email")} />

      <H3>{t("Confirm your email")}</H3>
      <div className="texts-container">
        <P16>
          {t(
            "Please check your inbox for a confirmation email from noreply@offishall.io."
          )}
        </P16>
        <div className="divider" />
        <P16>
          {t("Click the link in the email to confirm your email address.")}
        </P16>
      </div>
    </Container>
  )
}
