import { Grid } from "@mui/material"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useSendNewRegistrationEmail from "graphql/admin/useSendNewRegistrationEmail"
import useTranslate from "intl/useTranslate"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components/macro"
import { AdminUsersFilter, UserAdminBase, UserBase } from "types"
import { Button, DropDown, mediaQueries, RemovableChipButton } from "ui"
import { Download } from "ui/icons"
import downloadTextFile from "utils/downloadTextFile"

import UserSearch from "./UserSearch/UserSearch"

const DropDownLine = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  min-height: 40px;

  .registration {
    display: flex;
    flex-direction: row;
  }

  @media ${mediaQueries.isMobile} {
    margin: 8px 0 0 0;

    .registration {
      flex-direction: column;
      margin-bottom: 20px;
    }
  }

  .clear-button {
    margin-right: auto;

    @media ${mediaQueries.isMobile} {
      margin-right: 10px;
    }
  }
`

const ChipButtonList = styled(Grid)`
  margin-bottom: 20px;
  gap: 10px;
  @media ${mediaQueries.isMobile} {
    margin-top: 10px;
  }
`

interface PropsType {
  loading: boolean
  filters: AdminUsersFilter
  allUsers: UserAdminBase[]
  usersToDisplay: UserAdminBase[]
  filteredUsers: UserAdminBase[]
  setFilteredUsers: (arr: UserAdminBase[]) => void
  updateFilters: (value: {
    key: "active" | "registration" | "status"
    value: string | undefined
  }) => void
  clearFilters: () => void
  setHeightFilters: (arg1: any) => void
}

export default function AdminUserFilters({
  loading,
  allUsers,
  setFilteredUsers,
  filters,
  filteredUsers,
  updateFilters,
  usersToDisplay,
  clearFilters,
  setHeightFilters,
}: PropsType) {
  const t = useTranslate()

  const { sendNewRegistrationEmail } = useSendNewRegistrationEmail()
  const { addToast } = useToasts()

  const [noFilter, setNoFilter] = useState<boolean>(true)

  useEffect(() => {
    if (
      filters.active === undefined &&
      filters.status === undefined &&
      filters.registration === undefined &&
      filteredUsers.length === 0
    ) {
      setNoFilter(true)
    } else setNoFilter(false)
  }, [filters, filteredUsers])

  const filtersRef = useRef<HTMLDivElement>(null)
  const filtersObserver = useRef(
    new ResizeObserver((entries) =>
      setHeightFilters(entries[0].contentRect.height)
    )
  )
  useEffect(() => {
    /**
     * this useEffect makes it possible to observe if Filters size have changed
     */
    const observer = filtersObserver.current
    const element = filtersRef.current
    if (element) {
      observer.observe(element)
      return () => observer.unobserve(element)
    }
  }, [filtersRef, filtersObserver])

  return (
    <Grid container direction="column" ref={filtersRef}>
      <DropDownLine item>
        <UserSearch
          width="190px"
          groups={allUsers}
          onNewResults={(filteredUsers: UserBase[]) => {
            if (filteredUsers.length === allUsers.length)
              return setFilteredUsers([])
            setFilteredUsers(
              filteredUsers
                .map((filteredUser) =>
                  allUsers.find((u) => u.id === filteredUser.id)
                )
                .filter(
                  (u: UserAdminBase | undefined): u is UserAdminBase =>
                    u !== undefined
                )
            )
          }}
          onSubmit={(filteredUsers: UserBase[]) => {
            if (filteredUsers.length === allUsers.length)
              return setFilteredUsers([])
            setFilteredUsers(
              filteredUsers
                .map((filteredUser) =>
                  allUsers.find((u) => u.id === filteredUser.id)
                )
                .filter(
                  (u: UserAdminBase | undefined): u is UserAdminBase =>
                    u !== undefined
                )
            )
          }}
          onClose={() => setFilteredUsers([])}
        />

        <DropDown
          options={[
            { name: t("Active(status)"), value: "active" },
            {
              name: t("Not Active(status)"),
              value: "not active",
            },
          ]}
          title={t("State")}
          onSelect={(option) => {
            updateFilters({ key: "active", value: option.value })
          }}
          checkBox
          state={
            filters.active === undefined
              ? undefined
              : filters.active === "active"
              ? t("Active(status)")
              : t("Not Active(status)")
          }
          minWidth={"150px"}
        />

        <DropDown
          options={[{ name: t("Admin"), value: "admin" }]}
          title={t("Status")}
          onSelect={(option) => {
            updateFilters({ key: "status", value: option.value })
          }}
          checkBox
          state={filters.status === undefined ? undefined : t("Admin")}
          minWidth={"150px"}
        />

        <div style={{ marginRight: noFilter ? "auto" : 0 }}>
          <DropDown
            options={[
              { name: t("Registered"), value: "registered" },
              {
                name: t("Not Registered"),
                value: "not registered",
              },
            ]}
            title={t("Registration")}
            onSelect={(option) => {
              updateFilters({ key: "registration", value: option.value })
            }}
            checkBox
            state={
              filters.registration === undefined
                ? undefined
                : filters.registration === "registered"
                ? t("Registered")
                : t("Not Registered")
            }
            minWidth={"150px"}
          />
        </div>
        {!noFilter && (
          <div className="clear-button">
            <Button ghost onClick={() => clearFilters()}>
              {t("Clear")}
            </Button>
          </div>
        )}

        <Button
          secondary
          disabled={loading}
          icon={<Download style={{ strokeWidth: 0.5 }} />}
          onClick={async () => {
            const headers = [
              "email",
              "name",
              "manager",
              "role",
              "state",
              "registered",
            ]
            const csv = usersToDisplay
              .map((user) => {
                return [
                  user.email,
                  user.name,
                  user.manager ? user.manager.email : "",
                  user.role,
                  user.disabled ? "disabled" : "active",
                  user.registered ? "registered" : "not registered",
                ].join(",")
              })
              .join("\n")
            const csvWithHeaders = headers.join(",") + "\n" + csv
            // const csv = await getCSV()
            downloadTextFile("users.csv", csvWithHeaders)
          }}
        >
          {t("Export the list as CSV")}
        </Button>
      </DropDownLine>

      {/* Display active filters */}
      <ChipButtonList container direction="row">
        {/* ACTIVE USERS FILTERS */}
        {filters.active !== undefined && (
          <RemovableChipButton
            active
            onRemove={() => {
              updateFilters({ key: "active", value: undefined })
            }}
          >
            {filters.active === "active"
              ? t("Active(status)")
              : t("Not Active(status)")}
          </RemovableChipButton>
        )}
        {/* ADMIN USERS FILTERS */}
        {filters.status !== undefined && (
          <RemovableChipButton
            active
            onRemove={() => {
              updateFilters({ key: "status", value: undefined })
            }}
          >
            {filters.status === "admin" ? t("Admin") : undefined}
          </RemovableChipButton>
        )}
        {/* USERS REGISTRATION FILTERS */}
        {filters.registration !== undefined && (
          <RemovableChipButton
            active
            onRemove={() => {
              updateFilters({ key: "registration", value: undefined })
            }}
          >
            {filters.registration === "registered"
              ? t("Registered")
              : t("Not Registered")}
          </RemovableChipButton>
        )}
        {filters.registration === "not registered" && (
          <div className="registration">
            <Button
              ghost
              onClick={() => {
                sendNewRegistrationEmail()
                addToast(t("Registration emails sent successfully"), {
                  appearance: "success",
                })
              }}
            >
              {t("Resend registration emails")}
            </Button>
          </div>
        )}
      </ChipButtonList>
    </Grid>
  )
}
