import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import useTranslation from "intl/useTranslation"
import React from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { Button, colors, H4, Link, mediaQueries, P14, P14Bold } from "ui"
import { ChatBubbleQuestion, LogOut } from "ui/icons"

const Container = styled.div`
  padding-top: 24px;
  border-top: 1px solid ${colors.grey3};
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media ${mediaQueries.isMobile} {
    flex-direction: column;
  }
`

const LinksContainer = styled.footer`
  display: flex;
  flex-direction: row;
  background: white;
  gap: 16px;
  margin-top: 16px;
  border-top: 1px solid ${colors.grey3};
  padding-top: 16px;

  p {
    display: flex;
    gap: 0.25rem;
    color: ${colors.grey1};
  }

  @media ${mediaQueries.isMobile} {
    padding: 16px 0;
    flex-direction: column;
  }
`

const FeedbackContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 15px;
  text-align: center;
  border: 1px solid ${colors.grey3};
  border-radius: 5px;
  cursor: pointer;
  width: fit-content;

  @media ${mediaQueries.isMobile} {
    width: 100%;
  }

  svg {
    margin-right: 15px;
  }

  &:hover {
    background-color: ${colors.grey4};
  }

  a {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 14px;
  }

  p {
    text-align: center;
    font-size: 14px;
    color: ${colors.purple};
  }

  svg {
    color: ${colors.purple};
  }
`

export default function GlobalUserSettings() {
  const t = useTranslate()
  const history = useHistory()
  const [locale] = useTranslation()
  const { isMobile } = useMediaQueries()

  const links = {
    fr: {
      userGuide:
        "https://www.notion.so/offishall/Guide-utilisateur-Offishall-f2714d7d5eee43cda7b98c9355e5483e",
      cgu: "https://www.offishall.io/conditions-generales-dutilisation-du-compte",
      privacyPolicy: "https://www.offishall.io/politique-de-confidentialite",
    },
    en: {
      userGuide:
        "https://www.notion.so/offishall/User-Guide-Offishall-5cf1d772331249e6916440630a2f99e3",
      cgu: "https://en.offishall.io/conditions-generales-dutilisation-du-compte",
      privacyPolicy: "https://en.offishall.io/politique-de-confidentialite",
    },
  }

  return (
    <Container>
      <H4>{t("news & feedback")}</H4>
      <ButtonsContainer>
        <FeedbackContainer>
          <a
            target="_blank"
            href="https://docs.google.com/forms/d/e/1FAIpQLSdhz8qHYWlmeatVPy26zxxQNSOImanCyvutZF3XWHijT8Vmkw/viewform?usp=sf_link"
            rel="noreferrer"
          >
            <ChatBubbleQuestion />
            <P14Bold>{t("share feedback")}</P14Bold>
          </a>
        </FeedbackContainer>
        <Button
          danger
          icon={<LogOut />}
          fullWidth={isMobile}
          onClick={() => history.push("/logout")}
        >
          {t("Log out")}
        </Button>
      </ButtonsContainer>
      <LinksContainer>
        <P14>
          <Link
            external
            variant="hover"
            to={links[`${locale.id === "fr" ? "fr" : "en"}`].userGuide}
          >
            {t("user guide")}
          </Link>
        </P14>
        <P14>
          <Link
            external
            variant="hover"
            to={links[`${locale.id === "fr" ? "fr" : "en"}`].cgu}
          >
            {t("cgu")}
          </Link>
        </P14>
        <P14>
          <Link
            external
            variant="hover"
            to={links[`${locale.id === "fr" ? "fr" : "en"}`].privacyPolicy}
          >
            {t("privacy policy")}
          </Link>
        </P14>
      </LinksContainer>
    </Container>
  )
}
