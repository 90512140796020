import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useNudgeEventUsers from "graphql/events/useNudgeEventUsers"
import useUser from "graphql/users/useUser"
import useUserTree from "hooks/useUserTree"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { Attendee, IntegrationEvent } from "types"
import { Button, Dialog, Tabs } from "ui"
import copyTextToClipboard from "utils/copyTextToClipboard"
import UserTree from "utils/UserTree"

import AttendeesList from "./AttendeesList/AttendeesList"

const TabsLayout = styled.div`
  padding: 0 16px;
`

interface SendReminderButtonPropsType {
  title: string
  onClick: () => void
}

function SendReminderButton({ title, onClick }: SendReminderButtonPropsType) {
  return <Button onClick={onClick}>{title}</Button>
}

interface PropsType {
  event: IntegrationEvent
  open: boolean
  onClose: () => void
}

export default function AttendingUsersListButton({
  event,
  open,
  onClose,
}: PropsType) {
  const t = useTranslate()
  const { user: me } = useUser()
  const nudgeEventUsers = useNudgeEventUsers()

  const { userTree } = useUserTree()

  const filterOutResources = ({ email }: Attendee) => email !== event.roomId
  const attendees = event.attendees.filter(filterOutResources)

  const getAttendeesAsUsers = (filter: ({ status }: Attendee) => boolean) =>
    attendees
      .filter(filterOutResources)
      .filter(filter)
      .map(({ email }) => UserTree.findUserByEmail(userTree, email))
      .filter((u): u is UserTree => u !== undefined)
  const attendeesAsGuests = attendees
    .filter(filterOutResources)
    .filter(
      ({ email }) =>
        !getAttendeesAsUsers(() => true).find((u) => u.email === email)
    )

  const { addToast } = useToasts()

  const filterAttending = ({ status }: Attendee) => status === "attending"
  const filterPending = ({ status }: Attendee) => status === "pending"
  const filterNotAttenting = ({ status }: Attendee) =>
    status !== "pending" && status !== "attending"

  const attendingUsers = attendees.filter((u) => u.status === "attending")
  const pendingUsers = attendees.filter((u) => u.status === "pending")
  const notAttendingUsers = attendees.filter(
    (u) => u.status === "not-attending"
  )

  const showActions =
    me?.role === "admin" || event.organizer?.email === me?.email

  if (me === null) return <></>

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t("attending users")}
      leftButton={
        showActions ? (
          <Button
            secondary
            onClick={() => {
              copyTextToClipboard(attendees.map((g) => g.email).join(","))
              addToast(t("Emails copied"), { appearance: "success" })
            }}
          >
            {t("copy the mailing list")}
          </Button>
        ) : undefined
      }
      rightButton={
        showActions ? (
          <SendReminderButton
            title={t("send attendings reminder")}
            onClick={() => {
              nudgeEventUsers(event.id)
              onClose()
            }}
          />
        ) : undefined
      }
      stopEventPropagation
    >
      <TabsLayout>
        <Tabs
          tabs={[
            {
              label: `${t("attendings")} (${attendingUsers.length})`,
              value: 0,
              component: (
                <AttendeesList
                  attendeesAsUsers={getAttendeesAsUsers(filterAttending)}
                  attendeesAsExternalGuests={attendeesAsGuests.filter(
                    filterAttending
                  )}
                />
              ),
            },
            {
              label: `${t("pending")} (${pendingUsers.length})`,
              value: 1,
              component: (
                <AttendeesList
                  attendeesAsUsers={getAttendeesAsUsers(filterPending)}
                  attendeesAsExternalGuests={attendeesAsGuests.filter(
                    filterPending
                  )}
                />
              ),
            },
            {
              label: `${t("not attending")} (${notAttendingUsers.length})`,
              value: 2,
              component: (
                <AttendeesList
                  attendeesAsUsers={getAttendeesAsUsers(filterNotAttenting)}
                  attendeesAsExternalGuests={attendeesAsGuests.filter(
                    filterNotAttenting
                  )}
                />
              ),
            },
          ]}
        />
      </TabsLayout>
    </Dialog>
  )
}
