import { Drawer } from "@mui/material"
import Menu from "components/Menu/Menu"
import useIsProductFruitsReady from "hooks/useIsProductFruitsReady"
import useMediaQueries from "hooks/useMediaQueries"
import useMenu from "hooks/useMenu"
import React from "react"
import styled from "styled-components"
import { colors, Grid, mediaQueries } from "ui"
import { menuWidth } from "ui/variables"

const AppLayoutContainer = styled.div`
  display: flex;
  background: ${colors.purple200};
  overflow: hidden;

  @media ${mediaQueries.isMobile} {
    position: fixed;
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    padding-top: env(safe-area-inset-top, 0);
    padding-bottom: env(safe-area-inset-bottom, 0);
  }
`

const DrawerContainer = styled(Drawer)`
  width: ${menuWidth}px;
  flex-shrink: 0;
  & .MuiDrawer-paper {
    width: ${menuWidth}px;
    box-sizing: border-box;
  }

  @media ${mediaQueries.isMobile} {
    width: ${menuWidth}px;
    z-index: 1500;

    & .MuiDrawer-paper {
      width: ${menuWidth}px;
    }
  }
`

const MainContainer = styled(Grid)`
  position: relative;
  flex-grow: 1;
  margin-left: 0;
  width: 100%;
  background: ${colors.white};

  main {
    width: 100%;
  }

  @media ${mediaQueries.isDesktop} {
    main {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
`

interface PropsType {
  children: React.ReactNode
}

export default function AppLayout({ children }: PropsType) {
  const { isMobile, isNarrowScreen } = useMediaQueries()
  const isProductFruitsReady = useIsProductFruitsReady()

  const { menuDrawerOpen, setMenuDrawerOpen } = useMenu()

  const persistentDrawer = !isMobile && !isNarrowScreen

  return (
    <AppLayoutContainer>
      <DrawerContainer
        open={persistentDrawer || menuDrawerOpen}
        anchor="left"
        variant={persistentDrawer ? "persistent" : undefined}
        onClose={() => setMenuDrawerOpen(false)}
        BackdropProps={{
          sx: {
            background: "rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <aside>
          <Menu isProductFruitsReady={isProductFruitsReady} />
        </aside>
      </DrawerContainer>
      <MainContainer container>
        <main>{children}</main>
      </MainContainer>
    </AppLayoutContainer>
  )
}
