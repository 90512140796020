import { ApolloError, gql, useQuery } from "@apollo/client"
import { useMemo } from "react"
import LocationTree from "utils/LocationTree"

import { LocationFragment } from "./fragments/locationFragment"

const LOCATIONS_QUERY = gql`
  ${LocationFragment}
  query getLocations {
    locations {
      ...LocationFragment
    }
  }
`

export default function useLocationsTree(filterArchived = true): {
  loading: boolean
  error?: ApolloError
  locations: LocationTree[]
} {
  const { loading, error, data } = useQuery(LOCATIONS_QUERY)
  const locations = useMemo(
    () => LocationTree.make(data?.locations || [], filterArchived),
    [data, filterArchived]
  )

  return {
    locations,
    loading,
    error,
  }
}
