import useTranslate from "intl/useTranslate"
import React from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { colors, P14Bold } from "ui"
import { ArrowLeft } from "ui/icons"

const Container = styled.button`
  color: ${colors.grey1};
  border: none;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  p {
    color: ${colors.grey1};
  }
`

interface PropsType {
  realGoBack: boolean
  onClick: () => void
}

export default function NavBack({ realGoBack, onClick }: PropsType) {
  const history = useHistory()
  const t = useTranslate()

  return (
    <Container
      onClick={() => {
        if (realGoBack) history.goBack()
        else onClick()
      }}
    >
      <ArrowLeft />
      <P14Bold>{t("Back")}</P14Bold>
    </Container>
  )
}
