import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function Bubbles({
  stroke,
  strokeWidth,
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      strokeWidth={strokeWidth ?? 1.5}
      stroke={stroke ? stroke : "currentColor"}
      fill={rest.fill ? rest.fill : "transparent"}
      {...rest}
    >
      <circle cx="8" cy="8" r="6" fill="white" />
      <circle cx="18" cy="14" r="4" fill="white" />
      <circle cx="9" cy="18" r="3" fill="white" />
      <circle cx="17.5" cy="6.5" r="1.5" fill="white" />
    </SvgIcon>
  )
}
