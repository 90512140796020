import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import UTCDatePicker from "components/UTCDatePicker/UTCDatePicker"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { colors, L12, P14, transitions } from "ui"

const Layout = styled.span`
  display: flex;
  align-items: center;
  padding: 0px 2px;
  background: ${colors.grey4};
  cursor: pointer;
  color: ${colors.grey1};

  &:hover {
    color: ${colors.black};
  }

  transition: ${transitions.fade};
`

export default function WeekNumberTitle() {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()
  const { selectedDate, setWeek } = usePlanningContext()

  const [datePickerOpen, setDatePickerOpen] = useState(false)

  const title = `${t("week")} ${selectedDate.getWeekNumber()}`
  const as = isMobile ? P14 : L12

  return (
    <>
      <Layout
        onClick={() => {
          setDatePickerOpen((prev) => !prev)
        }}
        as={as}
      >
        {title}
      </Layout>
      {datePickerOpen && (
        <UTCDatePicker
          label={t("choose week")}
          open={datePickerOpen}
          onClose={() => setDatePickerOpen(false)}
          onSelect={(date) => {
            if (date) setWeek(date)
            setDatePickerOpen(false)
          }}
        />
      )}
    </>
  )
}
