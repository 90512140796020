import UsersAtFilters from "pages/PlanningPage/components/PlanningHeadersRouter/ActiveLocationsList/UsersAtFilters/UsersAtFilters"
import React from "react"
import styled from "styled-components"
import { colors, P14 } from "ui"

const Layout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 0;
`

export const Paragraph = styled(P14)`
  text-align: left;
  color: ${colors.grey1};
`

interface PropsType {
  message: string
}

export default function HeaderMessage({ message }: PropsType) {
  return (
    <Layout>
      <Paragraph>{message}</Paragraph>
      <UsersAtFilters />
    </Layout>
  )
}
