import { gql, useApolloClient, useMutation } from "@apollo/client"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useLogEvent from "graphql/misc/useLogEvent"
import { GET_USER_QUERY } from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import uploadFileToSignedURL from "utils/uploadFileToSignedURL"

const MUTATION = gql`
  mutation setUserPhoto {
    setUserPhoto {
      imageUrl
      signedUrl
    }
  }
`

interface PropsType {
  image: File
}

export default function useSetUserPhoto() {
  const { addToast } = useToasts()
  const logEvent = useLogEvent()
  const [mutate] = useMutation(MUTATION)
  const client = useApolloClient()
  const t = useTranslate()

  return async ({ image }: PropsType) => {
    try {
      await mutate({
        update: async (cache, { data }) => {
          const signedUrl = data?.setUserPhoto?.signedUrl
          if (signedUrl) {
            try {
              await uploadFileToSignedURL(image, signedUrl)
              logEvent("user_photo_uploaded", { image })
              await client.refetchQueries({
                include: [GET_USER_QUERY],
              })
            } catch (e) {
              console.error(e)
              logEvent("user_photo_upload_failed", {
                image,
                message: e.message,
              })
            }
          }
          // cache update
        },
      })
      addToast(t("your profile photo has been saved"), {
        appearance: "success",
      })
    } catch (e) {
      console.error(e.message)
      addToast(e.message, { appearance: "error" })
    }
  }
}
