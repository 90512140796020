import { Tabs as MuiTabs } from "@mui/material"
import Tab from "@mui/material/Tab"
import React, { useState } from "react"
import styled from "styled-components"
import { colors } from "ui"

const TabsStyled = styled(MuiTabs)`
  border-bottom: 1px solid ${colors.grey3};
  max-height: 60px;
  button {
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: ${colors.grey1};
    text-transform: none;
    cursor: pointer;

    &.Mui-selected {
      color: ${colors.purple};
    }
  }
  & .MuiTabs-indicator {
    background: ${colors.purple};
  }

  & .MuiTabs-scrollButtons {
    margin-top: 16px;
    color: ${colors.purple};
    width: 28px;
  }
`

interface TabPanelPropsType {
  value: number
  index: number
  children: React.ReactNode
}

function TabPanel({ value, index, children, ...rest }: TabPanelPropsType) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...rest}
    >
      {value === index && children}
    </div>
  )
}

interface PropsType {
  defaultValue?: number
  tabs: {
    label: string
    value: number
    component: JSX.Element
    icon?: JSX.Element
    iconPosition?: string
    disabled?: boolean
  }[]
  onSelect?: (value: number) => void
  getBoudingRect?: (rect: DOMRect) => void
}

export function Tabs({
  tabs,
  defaultValue,
  onSelect,
  getBoudingRect,
}: PropsType) {
  const [value, setValue] = useState(defaultValue ? defaultValue : 0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
    if (onSelect) onSelect(newValue)
  }

  return (
    <>
      <TabsStyled value={value} onChange={handleChange} variant="scrollable">
        {tabs.map((tab, n) => (
          <Tab
            ref={(el) => {
              if (el !== null && getBoudingRect !== undefined)
                getBoudingRect(el.getBoundingClientRect())
            }}
            key={`tab-${n}-${tab.label}`}
            label={tab.label}
            icon={tab.icon}
            iconPosition="end"
            value={tab.value}
            disableRipple
            disabled={tab.disabled}
          />
        ))}
      </TabsStyled>

      {tabs.map((tab, n) => (
        <TabPanel
          key={`tabpanel-${n}-${tab.label}`}
          index={tab.value}
          value={value}
        >
          {tab.component}
        </TabPanel>
      ))}
    </>
  )
}
