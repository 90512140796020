interface Shape extends Record<string, any> {
  type: string
  data: {
    locationId?: string
  }
}

/*
 ** This object is the result of serializing a floor plan canvas
 ** with FabricJS. It is what we store in the database for indicating
 ** the visual shapes that make up the plan. Only the properties that
 ** we are directly using in our code are explicitly indicated here,
 ** but the actual objects contain more properties.
 */
export default interface VisualArea extends Record<string, any> {
  objects?: Shape[]
}

export function hasVisualArea(locationId: string, visualArea: VisualArea) {
  return !!visualArea.objects?.find(
    (object) => object.data?.locationId === locationId
  )
}
