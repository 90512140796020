import { LinearProgress } from "@mui/material"
import IntegrationLayout from "components/IntegrationsLayout/IntegrationLayout"
import useCheckUserIntegration from "graphql/integrations/useCheckUserIntegration"
import useRevokeUserIntegration from "graphql/integrations/useRevokeUserIntegration"
import useSetupUserIntegration from "graphql/integrations/useSetupUserIntegration"
import useTranslate from "intl/useTranslate"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { UserIntegration } from "types"
import { Button, P16 } from "ui"
import { Microsoft } from "ui/icons"
import getUrlSearchParam from "utils/getUrlSearchParam"

import { ButtonText } from "./components/IntegrationButtonText"
import WriteSlotsEventsToggle from "./WriteSlotsEventsToggle"

const TextContainer = styled.div`
  margin-left: 12px;
  margin-bottom: 18px;
`

interface PropsType {
  integration?: UserIntegration
  loading: boolean
}

export default function OutlookUserIntegrationComponent({
  integration,
  loading: allIntegrationsLoading,
}: PropsType) {
  const t = useTranslate()
  const history = useHistory()

  const redirectUri = `${window.location.origin}/settings`

  const url =
    "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?" +
    `client_id=${process.env.REACT_APP_OUTLOOK_CLIENT_ID}` +
    "&response_type=code" +
    `&redirect_uri=${redirectUri}` +
    "&response_mode=query" +
    "&scope=" +
    "https%3A%2F%2Fgraph.microsoft.com%2Fcalendars.ReadWrite%20" +
    "https%3A%2F%2Fgraph.microsoft.com%2FUser.Read%20" +
    "https%3A%2F%2Fgraph.microsoft.com%2Fprofile%20" +
    "https%3A%2F%2Fgraph.microsoft.com%2Foffline_access%20" +
    "https%3A%2F%2Fgraph.microsoft.com%2Femail%20" +
    "&state=microsoft-outlook"
  const shouldSkip =
    integration?.name === undefined || getUrlSearchParam("code") !== null
  const { result, loading: checkLoading } = useCheckUserIntegration(
    integration?.name,
    shouldSkip
  )

  const error = result !== "success"

  const setupUserIntegration = useSetupUserIntegration()

  const revoke = useRevokeUserIntegration()

  const [loading, setLoading] = useState(false)

  // setup the tenant id if success
  useEffect(() => {
    const code = getUrlSearchParam("code")
    const state = getUrlSearchParam("state")
    if (code && state === "microsoft-outlook") {
      setLoading(true)
      setupUserIntegration({
        name: "microsoft-outlook",
        token: code,
        flags: {
          redirectUri,
        },
      }).then(() => {
        setLoading(false)
        history.replace("/settings")
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <IntegrationLayout
      connected={Boolean(!checkLoading && !error)}
      title={t("Outlook Calendar")}
    >
      {integration && !error && (
        <>
          <TextContainer>
            <P16>{t("link offishall outlook")}</P16>
          </TextContainer>
          <TextContainer>
            <WriteSlotsEventsToggle integration={integration} />
          </TextContainer>
          <Button
            secondary
            icon={<Microsoft />}
            onClick={() => {
              revoke({ name: integration.name })
            }}
          >
            <ButtonText>{t("revoke access")}</ButtonText>
          </Button>
        </>
      )}
      {(!integration || error) && (
        <TextContainer>
          <P16>{t("outlook improve offishall experience")}</P16>
        </TextContainer>
      )}
      {(!integration || error) && (
        <Button
          secondary
          icon={<Microsoft />}
          onClick={() => {
            if (url) {
              location.href = url
            }
          }}
        >
          <ButtonText>
            {t("autorize offishall access outlook account")}
          </ButtonText>
        </Button>
      )}
      {(loading || checkLoading || allIntegrationsLoading) && (
        <LinearProgress />
      )}
    </IntegrationLayout>
  )
}
