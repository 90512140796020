import styled from "styled-components"

export const FieldsetRow = styled.fieldset`
  margin: 0;
  padding: 0;
  border: 0;
  display: flex;
  align-items: center;
  gap: 4px;

  &.align-right {
    justify-content: flex-end;
  }
`

export const FieldsetColumn = styled.fieldset`
  margin: 0;
  padding: 0;
  border: 0;
  display: flex;
  flex-direction: column;
  gap: 4px;

  &.full-width {
    width: 100%;
  }
`
