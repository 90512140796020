import {
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
  TextFieldProps,
} from "@mui/material"
import React, { ChangeEvent, useEffect, useState } from "react"
import styled from "styled-components/macro"
import { colors, Label, P14, P16 } from "ui"
import { CloseEye, Copy, Edit, EyeOpen } from "ui/icons"
import transitions from "ui/transitions"
import copyTextToClipboard from "utils/copyTextToClipboard"

import { NavArrowDown, NavArrowUp } from "./icons"

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const PrimaryTextfield = styled(MuiTextField)`
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${colors.grey1};
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${colors.grey1};
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${colors.grey1};
  }

  & .MuiOutlinedInput-root {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: ${colors.black};
    min-height: 40px;
    border: 1px solid ${colors.grey2};
    background: ${colors.grey4};
    height: 40px;
  }

  & .MuiOutlinedInput-root:hover:not(.Mui-disabled) {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)),
      ${colors.grey4};
    border: 1px solid ${colors.grey2};
    ${({ error }) => {
      if (error) return `border: 1px solid ${colors.redAlert};`
    }}
  }

  & .MuiOutlinedInput-root.Mui-disabled {
  }

  > .Mui-disabled {
    > input.Mui-disabled {
      background: ${colors.grey4};
      cursor: not-allowed;
      color: ${colors.grey1};
    }
  }

  & .MuiOutlinedInput-root {
    ${({ error }) => {
      if (error) return `border: 1px solid ${colors.redAlert};`
    }}
    &.Mui-focused {
      background: linear-gradient(0deg, ${colors.white}, ${colors.white}),
        ${colors.grey4};
      border: 2px solid ${colors.green};
      ${({ error }) => {
        if (error) return `border: 1px solid ${colors.redAlert};`
      }}
      color: ${colors.black};
    }
  }

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .MuiInputAdornment-root {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: ${colors.black};
    min-height: 40px;
  }
  & .MuiFormHelperText-root.Mui-error {
    color: ${colors.redAlert};
  }
  & .Mui-error {
    color: ${colors.redAlert};
  }

  p {
    color: ${colors.grey1};
  }
`

const NumberFieldContainer = styled(MuiTextField)`
  min-height: 40px;
  min-width: 50px;

  input:disabled + .actions {
    display: none;
  }

  /* hide arrows on firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  & .MuiOutlinedInput-root {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: ${colors.black};
    background: ${colors.grey4};
    min-height: 40px;
    border: 1px solid ${colors.grey2};

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  & .MuiOutlinedInput-root :hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)),
      ${colors.grey4};
    border: 1px solid ${colors.grey2};
  }

  & .MuiOutlinedInput-root.Mui-disabled {
    background: ${colors.grey4};
    cursor: not-allowed;
    color: ${colors.grey1};
  }

  & .MuiOutlinedInput-root.Mui-focused {
    background: linear-gradient(0deg, ${colors.white}, ${colors.white}),
      ${colors.grey4};
    border: 2px solid ${colors.green};
    color: ${colors.black};
  }

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  &.MuiFormControl-root {
    p {
      color: ${colors.grey1};
      margin: 0;
      padding: 2px 4px;
      &.Mui-error {
        color: ${colors.redAlert};
      }
    }
  }
`

const HeaderTextContainer = styled.div`
  min-height: 40px;
  width: 100%;
  & .MuiOutlinedInput-root {
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: ${colors.black};
    min-height: 40px;
  }
`

const HTextField = styled(MuiTextField)`
  & .MuiInput-root {
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: ${colors.black};
    min-height: 40px;

    // icon or/and maxLength info
    .end-adornment {
      padding-right: 8px;
    }
    span.end-adornment {
      display: flex;
      align-items: center;
      gap: 4px;
      p {
        color: ${colors.grey1};
      }
      svg {
        color: ${colors.grey3};
      }
    }
    p.end-adornment {
      color: ${colors.grey1};
    }
    svg.end-adornment {
      color: ${colors.grey3};
      height: 30px;
      width: 30px;
    }
  }
  > .MuiInput-root {
    &::before {
      border-color: ${colors.grey1};
    }
    &:hover:not(.Mui-disabled)::before {
      border-bottom: solid 1px ${colors.grey2};
    }
    &::after {
      border-color: ${colors.green};
    }
    .Mui-disabled {
      cursor: not-allowed;
      border-bottom: solid 1px ${colors.grey3};
    }
  }
`

export type TextFieldPropsType = {
  initialValue?: string
  icon?: boolean
  endAdornment?: React.ReactElement
  type?: React.InputHTMLAttributes<unknown>["type"]
  resetOnInitialValue?: boolean
  isPassword?: boolean
  onEnterKey?: () => void
  handleChange: (str: string) => void
} & TextFieldProps

export function TextField({
  label,
  initialValue,
  handleChange,
  endAdornment,
  className,
  type,
  resetOnInitialValue,
  autoComplete,
  isPassword,
  onEnterKey,
  ...rest
}: TextFieldPropsType) {
  const [value, setValue] = useState<string>(initialValue ? initialValue : "")

  useEffect(() => {
    if (resetOnInitialValue) setValue(initialValue ?? "")
  }, [resetOnInitialValue, initialValue])

  const [showPassword, setShowPassword] = useState<boolean>(true)

  const passwordEndAdornment = (
    <IconButton
      aria-label="toggle password visibility"
      onClick={() => setShowPassword((oldShowPassword) => !oldShowPassword)}
      onMouseDown={(e) => e.preventDefault()}
      edge="end"
      style={{ height: 30, width: 30 }}
    >
      {showPassword ? (
        <CloseEye style={{ color: colors.grey1, fill: "transparent" }} />
      ) : (
        <EyeOpen style={{ color: colors.grey1, fill: "transparent" }} />
      )}
    </IconButton>
  )

  const defineType = () => {
    if (type === "password" && isPassword && !showPassword) return "text"
    if (type === "text" && isPassword && showPassword) return "password"
    else return type
  }

  return (
    <Container className={className}>
      {label && (
        <Label className={rest.required ? "required" : undefined}>
          {label}
        </Label>
      )}
      <PrimaryTextfield
        fullWidth
        type={defineType()}
        size="small"
        margin="none"
        value={value}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          handleChange(event.target.value)
          setValue(event.target.value)
        }}
        InputProps={{
          autoComplete,
          onKeyDown: (e) => {
            if (e.key === "Enter" && onEnterKey) onEnterKey()
          },
          endAdornment: (
            <InputAdornment position="end">
              {isPassword ? passwordEndAdornment : endAdornment}
            </InputAdornment>
          ),
        }}
        autoComplete={autoComplete}
        {...rest}
      />
    </Container>
  )
}

type NumberFieldPropsType = {
  value: number
  handleChange: (int: number) => void
  min?: number
  max?: number
  label?: string
} & TextFieldProps

export function NumberField({
  handleChange,
  value,
  min,
  max,
  label,
  placeholder,
  ...rest
}: NumberFieldPropsType) {
  const clampValue = (min: number, value: number, max: number) =>
    Math.min(Math.max(min, value), max)

  const incrementValue = () => {
    const newValue = clampValue(min ?? -Infinity, value + 1, max ?? Infinity)

    handleChange(newValue)
  }

  const decrementValue = () => {
    const newValue = clampValue(min ?? -Infinity, value - 1, max ?? Infinity)
    handleChange(newValue)
  }

  const onChange = (evt: ChangeEvent<HTMLInputElement>) => {
    handleChange(parseInt(evt.currentTarget.value))
  }

  return (
    <Container>
      {label && (
        <Label className={rest.required ? "required" : undefined}>
          {label}
        </Label>
      )}

      <NumberFieldContainer
        fullWidth
        size="small"
        margin="none"
        type="number"
        placeholder={isNaN(value) ? placeholder : undefined}
        value={value.toString()} // prevent leading 0
        onChange={onChange}
        InputLabelProps={{
          shrink: true,
        }}
        {...rest}
        InputProps={{
          disabled: rest.disabled,
          inputProps: {
            min,
            max,
            style: {
              cursor: rest.disabled ? "not-allowed" : "auto",
            },
          },
          endAdornment: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "space-around",
              }}
            >
              <IconButton
                style={{ height: 10, width: 15, borderRadius: 0 }}
                disabled={rest.disabled}
                onClick={incrementValue}
              >
                <NavArrowUp style={{ height: 15 }} />
              </IconButton>
              <IconButton
                style={{ height: 15, width: 15, borderRadius: 0 }}
                disabled={rest.disabled}
                onClick={decrementValue}
              >
                <NavArrowDown style={{ height: 15 }} />
              </IconButton>
            </div>
          ),
        }}
      />
    </Container>
  )
}

export type PropsType = {
  initialValue?: string
  icon?: boolean
  maxLength?: number
  onEnterKey?: () => void
  handleChange: (str: string) => void
} & TextFieldProps

export function HeaderTextField({
  initialValue,
  maxLength,
  icon = false,
  onEnterKey,
  handleChange,
  ...rest
}: PropsType) {
  const [value, setValue] = useState<string>(initialValue ? initialValue : "")

  const getRemainingCharactersCount = () => {
    if (!maxLength) return Infinity
    return maxLength - value.length >= 0 ? maxLength - value.length : 0
  }

  const getEndAdornment = () => {
    if (icon && !maxLength) return <Edit className="end-adornment" />

    if (maxLength && !icon)
      return (
        <P16 className="end-adornment">{getRemainingCharactersCount()}</P16>
      )
    if (maxLength && icon) {
      return (
        <span className="end-adornment">
          <P16>{getRemainingCharactersCount()}</P16>
          <Edit />
        </span>
      )
    }
    return undefined
  }

  return (
    <HeaderTextContainer>
      <HTextField
        fullWidth
        variant="standard"
        multiline={false}
        value={value}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          handleChange(event.target.value)
          setValue(event.target.value)
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter" && onEnterKey) onEnterKey()
        }}
        InputProps={{
          endAdornment: getEndAdornment(),
          disableUnderline: rest.disabled,
        }}
        inputProps={{
          maxLength,
        }}
        {...rest}
      />
    </HeaderTextContainer>
  )
}

export type TextFieldMaxCharactersProps = {
  initialValue?: string
  maxCharacters: number
  label?: string
  handleChange: (str: string) => void
} & TextFieldProps
export function TextFieldMaxCharacters({
  initialValue,
  maxCharacters,
  label,
  handleChange,
  ...rest
}: TextFieldMaxCharactersProps) {
  const [value, setValue] = useState<string>(initialValue ? initialValue : "")

  const valueLength: number = value.length

  const charactersStillAvailable: number = maxCharacters - valueLength

  return (
    <Container>
      {label && (
        <Label className={rest.required ? "required" : undefined}>
          {label}
        </Label>
      )}
      <PrimaryTextfield
        fullWidth
        size="small"
        margin="none"
        value={value}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          handleChange(event.target.value)
          setValue(event.target.value)
        }}
        inputProps={{
          maxLength: maxCharacters,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <P16>{charactersStillAvailable}</P16>
            </InputAdornment>
          ),
        }}
        {...rest}
      />
    </Container>
  )
}

export type TextFieldToCopyPropsType = {
  valueToCopy?: string
} & TextFieldProps

export function TextFieldToCopy({
  label,
  valueToCopy,
  ...rest
}: TextFieldToCopyPropsType) {
  const [value, setValue] = useState<string>(valueToCopy ? valueToCopy : "")

  // If the value of initialValue change,
  // we need to refresh the displayed value
  useEffect(() => {
    if (valueToCopy !== undefined) setValue(valueToCopy)
  }, [valueToCopy])

  return (
    <Container>
      {label && (
        <Label className={rest.required ? "required" : undefined}>
          {label}
        </Label>
      )}
      <PrimaryTextfield
        fullWidth
        size="small"
        margin="none"
        value={value}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setValue(event.target.value)
        }}
        {...rest}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                aria-label={`Copy ${label}`}
                onClick={() => {
                  valueToCopy && copyTextToClipboard(valueToCopy as string)
                }}
              >
                <Copy style={{ color: colors.purple }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Container>
  )
}

const Fieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 0;
  border: none;
  padding: 0;

  &.disabled {
    cursor: not-allowed;
  }
`

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background: ${colors.grey4};
  // cannot use border since border-width changes on focus
  // therefore block size changes also
  box-shadow: 0 0 0 1px ${colors.grey2};
  border-radius: 5px;
  padding: 9px 8px;

  &::placeholder,
  &.with-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${colors.grey1};
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder,
  &.with-placeholder {
    /* Internet Explorer 10-11 */
    color: ${colors.grey1};
  }

  &::-ms-input-placeholder,
  &.with-placeholder {
    /* Microsoft Edge */
    color: ${colors.grey1};
  }

  &.error {
    box-shadow: 0 0 0 1px ${colors.red};
  }

  > svg:first-child {
    color: ${colors.grey1};
  }

  line-height: 26px;

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
  &:focus-within:not(error) {
    box-shadow: 0 0 0 2px ${colors.green};
  }
  &.static {
    cursor: default;
    padding: 6px 8px;
  }
  &.static.with-action {
    cursor: pointer;
  }

  &:disabled,
  &.static.disabled {
    cursor: not-allowed;
    background: rgba(0, 0, 0, 0.04);
    color: ${colors.grey2};
    svg {
      stroke: ${colors.grey2};
    }
  }

  transition: ${transitions.fade};
`

const Input = styled.input`
  background: none;
  width: 100%;

  outline: none;
  border: none;

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  &:disabled {
    cursor: not-allowed;
  }
`

const Textarea = styled.textarea<{ minLines?: number }>`
  background: none;
  width: 100%;

  outline: none;
  border: none;

  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  min-height: calc(4 * 18px);

  ${({ minLines }) => `min-height: calc(${minLines} * 18px);`}

  &:disabled {
    cursor: not-allowed;
  }
`

export function TextFieldAsDisplay({
  value,
  placeholder,
  label,
  icon,
  disabled,
  onClick,
}: {
  value?: string
  placeholder?: string
  label?: string
  icon?: JSX.Element
  disabled?: boolean
  onClick?: () => void
}) {
  const getClassName = () => {
    const classList = ["static"]
    if (onClick) classList.push("with-action")
    if (placeholder && !value) classList.push("with-placeholder")
    if (disabled) classList.push("disabled")
    return classList.join(" ")
  }
  return (
    <Fieldset className={disabled ? "disabled" : undefined}>
      {label && <Label>{label}</Label>}
      <InputWrapper
        onClick={() => {
          if (!disabled && onClick) onClick()
        }}
        className={getClassName()}
      >
        {icon}
        {value ?? placeholder}
      </InputWrapper>
    </Fieldset>
  )
}

export function TextFieldInput({
  label,
  icon,
  placeholder,
  error,
  required,
  disabled,
  textarea,
  autoFocus,
  onClick,
  onChange,
}: {
  icon?: JSX.Element
  label?: string
  placeholder?: string
  error?: string
  textarea?: {
    minLines?: number
  }
  required?: boolean
  disabled?: boolean
  autoFocus?: boolean
  onClick?: () => void
  onChange: (str: string) => void
}) {
  return (
    <Fieldset className={disabled ? "disabled" : undefined}>
      {label && (
        <Label className={required ? "required" : undefined}>{label}</Label>
      )}
      <InputWrapper
        className={error !== undefined && error !== "" ? "error" : undefined}
        onClick={onClick}
      >
        {icon}
        {textarea ? (
          <Textarea
            minLines={textarea.minLines}
            placeholder={placeholder}
            onChange={(e) => onChange(e.target.value)}
            disabled={disabled}
            autoFocus={autoFocus}
          />
        ) : (
          <Input
            placeholder={placeholder}
            onChange={(e) => onChange(e.target.value)}
            type="text"
            disabled={disabled}
            autoFocus={autoFocus}
          />
        )}
      </InputWrapper>
      <P14 color={error ? "red" : undefined}>{error ?? <>&nbsp;</>}</P14>
    </Fieldset>
  )
}
