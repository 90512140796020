import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import React, { lazy, Suspense } from "react"

const TeamsAppOrSignin = lazy(() => import("./TeamsAppOrSignin"))

export default function TeamsAppLoader() {
  return (
    <Suspense fallback={<LoaderOrErrorComponent loading={true} error={null} />}>
      <TeamsAppOrSignin />
    </Suspense>
  )
}
