import ManageSettingsSlider from "components/Settings/ManageSettingsSlider"
import useTranslate from "intl/useTranslate"
import React from "react"
import { CompanySetting } from "types"
import { H4 } from "ui"

interface PropsType {
  group: string
  groups: Record<string, CompanySetting[]>
}

export default function SettingItem({ group, groups }: PropsType) {
  const t = useTranslate()

  return (
    <>
      <div className="title">
        <H4>{t(`company-setting-group-${group}`)}</H4>
      </div>
      {groups[group].map((setting) => (
        <ManageSettingsSlider
          type="company"
          key={setting.key}
          setting={setting}
        />
      ))}
    </>
  )
}
