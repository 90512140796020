import useDeleteUserHybridRule from "graphql/hybridPolicy/useDeleteUserHybridRule"
import useTranslate from "intl/useTranslate"
import React from "react"
import { UserHybridRule } from "types"
import { LU12 } from "ui"

import HyridRuleCard from "../HyridRuleCard/HyridRuleCard"
import { RulesList } from "../SharedLayouts"

interface WrapperPropsType {
  userHybridRules: UserHybridRule[]
  setSelectedUserHybridRule: (rule: UserHybridRule) => void
}

interface PropsType extends WrapperPropsType {
  deleteHybridRule: (ruleId: string) => void
}

function UsersHybridRules({
  userHybridRules,
  setSelectedUserHybridRule,
  deleteHybridRule,
}: PropsType) {
  const t = useTranslate()

  return (
    <RulesList>
      {userHybridRules.length > 0 && (
        <>
          <li className="header">
            <LU12>{t("user rules")}</LU12>
          </li>
          {userHybridRules.map((rule, n) => {
            if (!rule.userId) return null
            return (
              <HyridRuleCard
                key={`user-rule-${n}`}
                userId={rule.userId}
                rule={rule}
                setSelectedUserOrGroupHybridRule={(hybridRule) => {
                  setSelectedUserHybridRule({
                    ...hybridRule,
                    userId: rule.userId,
                  })
                }}
                deleteHybridRule={deleteHybridRule}
              />
            )
          })}
        </>
      )}
    </RulesList>
  )
}

export default function Wrapper({
  userHybridRules,
  setSelectedUserHybridRule,
}: WrapperPropsType) {
  const deleteUserHybridRule = useDeleteUserHybridRule()

  if (userHybridRules.length === 0) return <></>

  return (
    <UsersHybridRules
      userHybridRules={userHybridRules}
      setSelectedUserHybridRule={setSelectedUserHybridRule}
      deleteHybridRule={deleteUserHybridRule}
    />
  )
}
