import useUser from "graphql/users/useUser"
import useWindowSize from "hooks/useWindowResize"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { colors, H4, P16Bold, transitions } from "ui"
import LocalDate from "utils/LocalDate"
import UTCDate from "utils/UTCDate"

import { useHomeContext } from "../HomeContextProvider"
import { BubbleType } from "./BubbleType"
import ElseBubble from "./ElseBubble"
import HomeBubble from "./HomeBubble"
import OffBubble from "./OffBubble"
import OfficeBubble from "./OfficeBubble"

interface TabProps {
  active: boolean
}

const Tab = styled.button<TabProps>`
  background: none;
  padding: 8px 10px;
  gap: 10px;
  border: none;
  transition: ${transitions.generic};

  p {
    color: rgba(255, 255, 255, 0.5);
  }
  ${({ active }) => {
    if (active)
      return `border-bottom: 1px solid ${colors.white};
    p {
      color: ${colors.white};
    }`
  }}
`

const initialHierarchy = [
  { bubble: "office", companyNumber: 0, teamNumber: 0 },
  { bubble: "off", companyNumber: 0, teamNumber: 0 },
  { bubble: "home", companyNumber: 0, teamNumber: 0 },
  { bubble: "else", companyNumber: 0, teamNumber: 0 },
]

interface PropsType {
  AM: LocalDate
  PM: LocalDate
}

export default function BubblesList({ AM, PM }: PropsType) {
  const t = useTranslate()
  const { user: me } = useUser()
  const {
    selectedPlanningGroup,
    isMyTeamDisplay,
    setSelectedPlanningGroup,
    selectMyTeamPlanning,
  } = useHomeContext()

  const [maxWidth] = useWindowSize()
  const MAX_SIZE = maxWidth < 300 ? 80 : 120
  const MIN_SIZE = 60

  const [bubblesHierarchy, setBubblesHierarchy] =
    useState<BubbleType[]>(initialHierarchy)

  const editBubblesHierarchy = (
    value: (oldState: BubbleType[]) => BubbleType[]
  ) => setBubblesHierarchy(value)

  const teamSortedTab = bubblesHierarchy
    .slice()
    .sort((a, b) => a.teamNumber - b.teamNumber)
  const teamEcart =
    teamSortedTab[teamSortedTab.length - 1].teamNumber -
    teamSortedTab[0].teamNumber
  const teamFirstValue = teamSortedTab[0].teamNumber
  const teamPercentTab = teamSortedTab.map((e) => ({
    ...e,
    percent: ((e.teamNumber - teamFirstValue) * 100) / teamEcart,
  }))

  const companySortedTab = bubblesHierarchy
    .slice()
    .sort((a, b) => a.companyNumber - b.companyNumber)
  const companyEcart =
    companySortedTab[companySortedTab.length - 1].companyNumber -
    companySortedTab[0].companyNumber
  const companyFirstValue = companySortedTab[0].companyNumber
  const companyPercentTab = companySortedTab.map((e) => ({
    ...e,
    percent: ((e.companyNumber - companyFirstValue) * 100) / companyEcart,
  }))

  const valuesWithSize = bubblesHierarchy.map((el) => {
    const teamPercent: number =
      teamPercentTab.find((e) => e.bubble === el.bubble)?.percent ?? 100
    const companyPercent: number =
      companyPercentTab.find((e) => e.bubble === el.bubble)?.percent ?? 100

    return {
      ...el,
      teamBubbleSize:
        teamEcart !== 0
          ? (teamPercent * (MAX_SIZE - MIN_SIZE)) / 100 + MIN_SIZE
          : MIN_SIZE,
      companyBubbleSize:
        companyEcart !== 0
          ? (companyPercent * (MAX_SIZE - MIN_SIZE)) / 100 + MIN_SIZE
          : MIN_SIZE,
    }
  })

  const getBubbleSize = (type: string) => {
    return isMyTeamDisplay
      ? valuesWithSize.find((el) => el.bubble === type)?.teamBubbleSize ?? 0
      : valuesWithSize.find((el) => el.bubble === type)?.companyBubbleSize ?? 0
  }

  if (!me) return <></>

  return (
    <div className="bubbles-section">
      {selectedPlanningGroup === null && (
        <H4 className="section-title">{t("presence of the day")}</H4>
      )}
      {selectedPlanningGroup !== null && (
        <div className="target-selection">
          <Tab
            active={isMyTeamDisplay}
            onClick={() => {
              selectMyTeamPlanning()
            }}
          >
            <P16Bold>{t("My team")}</P16Bold>
          </Tab>
          <Tab
            active={!isMyTeamDisplay}
            onClick={() => {
              setSelectedPlanningGroup("everyone")
            }}
          >
            <P16Bold>{t("the whole company")}</P16Bold>
          </Tab>
        </div>
      )}
      <div className="bubbles-list">
        <OfficeBubble
          from={new UTCDate(AM)}
          to={new UTCDate(PM)}
          size={getBubbleSize("office")}
          setBubblesHierarchy={editBubblesHierarchy}
        />
        <ElseBubble
          from={new UTCDate(AM)}
          to={new UTCDate(PM)}
          size={getBubbleSize("else")}
          setBubblesHierarchy={editBubblesHierarchy}
        />
        <OffBubble
          from={new UTCDate(AM)}
          to={new UTCDate(PM)}
          size={getBubbleSize("off")}
          setBubblesHierarchy={editBubblesHierarchy}
        />
        <HomeBubble
          from={new UTCDate(AM)}
          to={new UTCDate(PM)}
          size={getBubbleSize("home")}
          setBubblesHierarchy={editBubblesHierarchy}
        />
      </div>
    </div>
  )
}
