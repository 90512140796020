import { Avatar } from "@mui/material"
import useLink from "hooks/useLink"
import useTranslate from "intl/useTranslate"
import useTranslation from "intl/useTranslation"
import React from "react"

import { ConnectionButton } from "./ConnectionButton"

export default function SignInWithOktaButton() {
  const goToSSO = useLink("/sso")
  const t = useTranslate()
  const [translation] = useTranslation()

  return (
    <>
      <ConnectionButton onClick={goToSSO} language={translation.id}>
        <Avatar
          sx={{ width: 22, height: 22 }}
          variant="square"
          alt="Okta"
          src="/static/img/okta.png"
        />
        <p>{t("Sign-in with Okta")}</p>
      </ConnectionButton>
    </>
  )
}
