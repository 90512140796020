import { CircularProgress } from "@mui/material"
import React from "react"
import colors from "ui/colors"

type SizeType = "medium"
type ColorType = "black" | "purple" | "white" | "grey-200"

interface PropsType {
  size?: SizeType
  color?: ColorType
}

export function CircularSpinner({ size, color }: PropsType) {
  const getSize = (size?: SizeType) => {
    if (!size) return
    switch (size) {
      case "medium":
        return 24
      default:
        return
    }
  }

  const getColor = (color?: ColorType) => {
    if (!color) return colors.black
    switch (color) {
      case "black":
        return colors.black
      case "white":
        return colors.white
      case "purple":
        return colors.purple
      case "grey-200":
        return colors.grey2
      default:
        return colors.black
    }
  }
  return (
    <CircularProgress
      size={getSize(size)}
      style={{
        color: getColor(color),
      }}
    />
  )
}
