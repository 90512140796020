import { ApolloError, gql, useQuery } from "@apollo/client"

const GET_CUSTOM_DOMAIN_QUERY = gql`
  query getCustomDomain {
    getCustomDomain
  }
`

export default function useCustomDomain(): {
  loading: boolean
  error?: ApolloError
  domain: string | null
} {
  const { loading, error, data } = useQuery(GET_CUSTOM_DOMAIN_QUERY)
  return {
    domain: data?.getCustomDomain || null,
    loading,
    error,
  }
}
