import React from "react"
import styled from "styled-components"
import { colors, mediaQueries } from "ui"

const Layout = styled.footer`
  @media ${mediaQueries.isMobile} {
    padding: 8px;
    background: ${colors.white};
    border-top: solid 1px ${colors.grey3};

    button {
      width: 100%;
    }
  }
`

interface PropsType {
  children: React.ReactNode
}

export default function PageFooter({ children }: PropsType) {
  return <Layout>{children}</Layout>
}
