import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function Laptop({ stroke, strokeWidth, ...rest }: SvgIconProps) {
  return (
    <SvgIcon
      strokeWidth={strokeWidth ?? 1.5}
      stroke={stroke ? stroke : "currentColor"}
      {...rest}
    >
      <path
        d="M3.19959 14.2222L1.71928 19.4556C1.35822 20.7321 2.31721 22 3.64377 22H20.3554C21.682 22 22.641 20.7321 22.2799 19.4556L20.7996 14.2222M3.19959 14.2222V4C3.19959 2.89543 4.09502 2 5.19959 2H18.7996C19.9042 2 20.7996 2.89543 20.7996 4V14.2222H3.19959ZM3.19959 14.2222H20.7996H3.19959Z"
        fill="none"
      />
      <path
        d="M11 19H13"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}
