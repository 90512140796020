import React from "react"
import { SearchResult } from "types"

import { UserResultsGroup } from "../ResultsGroup/ResultsGroup"
import SearchResultsContainer from "../SearchResultsContainer/SearchResultsContainer"

interface PropsType {
  anchorEl: HTMLElement | null
  searchString: string
  results: SearchResult[]
  onSelect: (input: SearchResult) => void
  onClick?: () => void
  handleReset: () => void
}

export default function UpdateManagerSearchResults({
  searchString,
  results,
  anchorEl,
  onClick,
  onSelect,
  handleReset,
}: PropsType) {
  const users = results.filter((r) => r.type === "user")

  const handleSelect = (input: SearchResult) => {
    onSelect(input)
  }

  if (users.length === 0) return <></>

  return (
    <SearchResultsContainer
      anchorEl={anchorEl}
      searchString={searchString}
      results={results}
      onClick={onClick}
      handleReset={handleReset}
      forceBringToTop
    >
      <UserResultsGroup results={users} onSelect={handleSelect} />
    </SearchResultsContainer>
  )
}
