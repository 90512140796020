import { gql } from "@apollo/client"

export const FavoriteFragment = gql`
  fragment FavoriteFragment on Favorite {
    id
    type
    itemId
    itemName
  }
`

export const UserFavoritesFragment = gql`
  ${FavoriteFragment}
  fragment UserFavoritesFragment on UserFavorites {
    users {
      ...FavoriteFragment
    }
    locations {
      ...FavoriteFragment
    }
    groups {
      ...FavoriteFragment
    }
  }
`
