import useFloorVisualArea from "graphql/floorPlans/useFloorVisualArea"
import LocationTree, { findOldestParentWithFloorPlan } from "utils/LocationTree"

export default function useLocationArea(
  companyLocations: LocationTree[],
  location: LocationTree
) {
  const nearestParentWithFloorPlan = findOldestParentWithFloorPlan(
    companyLocations,
    location
  )

  const { area: parentArea } = useFloorVisualArea(
    nearestParentWithFloorPlan?.id ?? ""
  )

  if (!parentArea.objects || parentArea.objects.length === 0) return undefined

  return parentArea.objects.find((o) => o.data.locationId === location.id)
}
