import UserAvatar from "components/UserAvatar/UserAvatar"
import useUser from "graphql/users/useUser"
import React from "react"
import styled from "styled-components"
import { UserBase } from "types"
import { colors, P16Bold, transitions } from "ui"
import getUserDisplayName from "utils/getUserDisplayName"

const StyledComponent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px;
  width: fit-content;
  border: solid 1px ${colors.grey3};
  background: ${colors.grey3};
  border-radius: 100vh;
  cursor: pointer;

  svg:last-child {
    stroke: ${colors.grey1};
    width: 20px;
    height: 20px;
  }

  p:last-child {
    padding-right: 8px; // add extra padding when no cancel button
  }

  &:hover {
    background: ${colors.grey3}99;
  }

  transition: ${transitions.fade};
`

interface PropsType {
  user: UserBase
  actionComponent?: JSX.Element
}

export default function UserChipButton({ user, actionComponent }: PropsType) {
  const { user: me } = useUser()

  if (!me) return <></>
  return (
    <StyledComponent>
      <UserAvatar user={user} />
      <P16Bold>{getUserDisplayName(user, me.company.flags)}</P16Bold>
      {actionComponent}
    </StyledComponent>
  )
}
