import useMultiUserSlots from "graphql/slots/useMultiUserSlots"
import useCompanyFlags from "hooks/useCompanyFlags"
import useUserTree from "hooks/useUserTree"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { UpsertEventInput } from "types"
import { Button, Dialog, P14 } from "ui"
import { EyeOpen } from "ui/icons"
import { getAttendeeAsUser } from "utils/events"
import UTCDate from "utils/UTCDate"

import GuestEmailItem from "./GuestEmailItem/GuestEmailItem"
import GuestUserItem from "./GuestUserItem/GuestUserItem"

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 16px;
  flex-wrap: wrap;
`

interface PropsType {
  meetingRoomEvent: UpsertEventInput
}

export default function GuestsLists({ meetingRoomEvent }: PropsType) {
  const t = useTranslate()
  const { userTree } = useUserTree()
  const { companyFlags } = useCompanyFlags()
  const [showPopup, setShowPopup] = useState(false)

  const { users, guests } = getAttendeeAsUser(
    userTree,
    meetingRoomEvent.attendees
  )

  const getFromTo = () => {
    if (meetingRoomEvent.from.isMorning() && meetingRoomEvent.to.isMorning())
      return {
        from: new UTCDate(meetingRoomEvent.from, "DAY"),
        to: new UTCDate(meetingRoomEvent.from, "NEXT-HALF-DAY"),
      }

    if (meetingRoomEvent.from.isMorning() && !meetingRoomEvent.to.isMorning())
      return {
        from: new UTCDate(meetingRoomEvent.from, "DAY"),
        to: new UTCDate(meetingRoomEvent.from, "NEXT-DAY"),
      }

    return {
      from: new UTCDate(meetingRoomEvent.from, "HALF-DAY"),
      to: new UTCDate(meetingRoomEvent.from, "NEXT-HALF-DAY"),
    }
  }

  const { slots } = useMultiUserSlots(
    users.map(({ id }) => id),
    getFromTo().from,
    getFromTo().to
  )

  return (
    <div>
      <Button secondary fullWidth gap={8} onClick={() => setShowPopup(true)}>
        <EyeOpen />
        {t("show guests")}
      </Button>
      <P14 color="grey1" style={{ paddingTop: "8px" }}>
        {t("meeting-room--guests-list-recap-part-01")}{" "}
        <strong>
          {meetingRoomEvent.attendees.length} {t("people")}
        </strong>{" "}
        {t("meeting-room--guests-list-recap-part-02")}
      </P14>
      {showPopup && (
        <Dialog
          title={t("guest list")}
          open
          transparentBackdrop
          width="560px"
          onClose={() => setShowPopup(false)}
        >
          <List>
            {users.map((user, n) => (
              <GuestUserItem
                key={`user-attendee-${n}`}
                user={{
                  ...user,
                  isOrganizer: user.email === meetingRoomEvent.organizer?.email,
                }}
                companyFlags={companyFlags}
                userSlots={slots.filter(({ userId }) => user.id === userId)}
              />
            ))}
            {guests.map((guest, n) => (
              <GuestEmailItem
                key={`guest-attendee-${n}`}
                guest={{
                  ...guest,
                  isOrganizer:
                    guest.email === meetingRoomEvent.organizer?.email,
                }}
              />
            ))}
          </List>
        </Dialog>
      )}
    </div>
  )
}
