import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { colors, P14, P14Bold } from "ui"
import { Trash } from "ui/icons"

const List = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr min-content;
  overflow-y: scroll;
  margin-top: 12px;
`

const Item = styled.li`
  padding: 4px 16px;
  height: 36px;
  display: flex;
  align-items: center;

  &.header {
    background: ${colors.grey4};
    p {
      color: ${colors.grey1};
    }
  }

  &.with-bg {
    background: ${colors.grey4};
  }

  svg {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
`

interface UserInputType {
  userEmail: string
  managerEmail?: string
  error?: boolean
}

interface PropsType {
  users: UserInputType[]
  removeUser: (user: UserInputType) => void
}

export default function UsersToBeAddedList({ users, removeUser }: PropsType) {
  const t = useTranslate()

  if (users.length === 0) return <></>

  return (
    <List>
      <Item className="header">
        <P14Bold>{t("user email")}</P14Bold>
      </Item>
      <Item className="header">
        <P14Bold>{t("manager email")}</P14Bold>
      </Item>
      <Item className="header" />
      {users.map((user, n) => {
        const className = n % 2 ? "with-bg" : ""
        return (
          <React.Fragment key={`user-to-be-added-${n}`}>
            <Item className={className}>
              <P14>{user.userEmail}</P14>
            </Item>
            <Item className={className}>
              <P14>{user.managerEmail ?? ""}</P14>
            </Item>
            <Item className={className}>
              <Trash
                onClick={() => removeUser(user)}
                htmlColor={colors.redAlert}
              />
            </Item>
          </React.Fragment>
        )
      })}
    </List>
  )
}
