import { ApolloError, gql, useQuery } from "@apollo/client"
import { Group } from "types"

import { GroupFragment } from "./fragments/groupFragment"

const GROUP_USERS_QUERY = gql`
  ${GroupFragment}
  query getGroupUsers($id: ID!) {
    groupUsers(id: $id) {
      ...GroupFragment
    }
  }
`

export default function useGroupUsers(id: string): {
  loading: boolean
  error?: ApolloError
  group: Group | null
} {
  const { loading, error, data } = useQuery(GROUP_USERS_QUERY, {
    variables: { id },
    skip: !id,
  })
  return { group: data?.groupUsers || null, loading, error }
}
