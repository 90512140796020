import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import { Button } from "ui"

import UpdateLocationDialog from "./UpdateLocationDialog/UpdateLocationDialog"

export default function AddLocationButton() {
  const t = useTranslate()

  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)

  return (
    <>
      <Button onClick={() => setIsEditModalOpen(true)}>
        {t("Add a working space")}
      </Button>
      {isEditModalOpen && (
        <UpdateLocationDialog
          open={isEditModalOpen}
          locationToMutate={undefined}
          onClose={() => setIsEditModalOpen(false)}
        />
      )}
    </>
  )
}
