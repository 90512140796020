import { gql, useMutation } from "@apollo/client"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"

import { UserIntegrationFragment } from "./fragments/UserIntegrationFragments"
import updateCache from "./updateUserIntegrationCache"
import { QUERY as CHECK_USER_INTEGRATION_QUERY } from "./useCheckUserIntegration"

const MUTATION = gql`
  ${UserIntegrationFragment}
  mutation revokeUserIntegration($name: String!) {
    revokeUserIntegration(name: $name) {
      ...UserIntegrationFragment
    }
  }
`

interface PropsType {
  name: string
}

export default function useRevokeUserIntegration() {
  const { addToast } = useToasts()
  const [mutate] = useMutation(MUTATION, {
    update: (cache, { data }) =>
      updateCache({
        cache,
        data,
        mutationName: "revokeUserIntegration",
        remove: true,
      }),
  })

  return async ({ name }: PropsType) => {
    try {
      await mutate({
        variables: { name },
        refetchQueries: [
          {
            query: CHECK_USER_INTEGRATION_QUERY,
            variables: { name },
          },
        ],
      })
      addToast(`connexion to ${name} revoked`, {
        appearance: "success",
      })
    } catch (e) {
      console.error(e.message)
      addToast(e.message, { appearance: "error" })
    }
  }
}
