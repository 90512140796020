import { useUserHybridRuleScore } from "hooks/useHybridRuleScore"
import React, { useEffect } from "react"
import { UserHybridRule } from "types"
import UTCDate from "utils/UTCDate"

import { LoadedScoreEventDetail, LoadingScore } from "../../types"

export default function UserScoreNullComponent({
  score,
  addScoreAsLoaded,
}: {
  score: LoadingScore<UserHybridRule>
  addScoreAsLoaded: (detail: LoadedScoreEventDetail) => void
}) {
  const {
    score: userScore,
    loading,
    error,
  } = useUserHybridRuleScore(new UTCDate(), score.rule, score.rule.userId)

  useEffect(() => {
    if (error)
      return addScoreAsLoaded({
        id: score.rule.id,
        concernedUserIds: [],
        compliantUserIds: [],
      })
    if (!loading && userScore)
      addScoreAsLoaded({
        id: userScore.id,
        concernedUserIds: userScore.concernedUserIds,
        compliantUserIds: userScore.compliantUserIds,
      })
  }, [loading, error, addScoreAsLoaded, userScore, score])
  return <></>
}
