import useWindowSize from "hooks/useWindowResize"
import React from "react"
import styled from "styled-components"
import { mediaQueries } from "ui"
import getSafeAvailableHeightCSSProperties from "utils/getSafeAvailableHeightCSSProperties"

export const Layout = styled.div<{
  maxHeight: number
  contentNotScrollable?: boolean
}>`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100vh;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;

  > div:nth-child(2) {
    ${({ contentNotScrollable }) => {
      if (contentNotScrollable) return "overflow-y: hidden;"
      return "overflow-y: scroll;"
    }}
  }

  @media ${mediaQueries.isMobile} {
    ${({ maxHeight }) => getSafeAvailableHeightCSSProperties(maxHeight)}
  }
`

interface PropsType {
  children: React.ReactNode
  contentNotScrollable?: boolean
}

export default function PageLayout({
  children,
  contentNotScrollable,
}: PropsType) {
  const [, maxHeight] = useWindowSize()
  return (
    <Layout maxHeight={maxHeight} contentNotScrollable={contentNotScrollable}>
      {children}
    </Layout>
  )
}
