import React from "react"
import styled from "styled-components/macro"

const Container = styled.div`
  max-width: 523px;
  padding: 16px;
  padding-left: 0px;
  height: 100%;
`

interface PropsType {
  children: React.ReactNode | React.ReactNode[]
}

export default function CSVStep({ children }: PropsType) {
  return <Container>{children}</Container>
}
