import { ApolloError, gql, useQuery } from "@apollo/client"

const GET_SAML_CONFIG_QUERY = gql`
  query getSAMLConfig($provider: String!) {
    getSAMLConfig(provider: $provider) {
      config {
        provider
        entityID
        ssoURL
        certificate
      }
    }
  }
`

export type SAMLConfig = {
  provider: string
  entityID: string
  ssoURL: string
  certificate: string
}

export default function useSAMLConfig(provider: string): {
  loading: boolean
  error?: ApolloError
  config: SAMLConfig | null
} {
  const { loading, error, data } = useQuery(GET_SAML_CONFIG_QUERY, {
    variables: { provider },
    fetchPolicy: "network-only",
  })
  return {
    config: data?.getSAMLConfig?.config || null,
    loading,
    error,
  }
}
