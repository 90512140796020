import MobileNavBack from "components/Layout/PageLayout/PageHeader/PageTitle/MobileNavBack/MobileNavBack"
import useSetAllowedUserFlags from "graphql/users/useSetAllowedUserFlags"
import useWindowSize from "hooks/useWindowResize"
import useTranslate from "intl/useTranslate"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Transition } from "react-transition-group"
import { Button, Grid } from "ui"

import {
  Content,
  Footer,
  Header,
  RightContainer,
  Subtitle,
  Title,
} from "./components/Layout"
import StepIndicator from "./components/StepIndicator"

const duration = 600

const defaultStyleBluePopUp = {
  transition: `right ${duration}ms ease-in-out 300ms`,
  position: "absolute",
  top: "75%",
}
const defaultStyleGreenPopUp = {
  transition: `right ${duration}ms ease-in-out 300ms`,
  position: "absolute",
  top: "13%",
}

const transitionStylesBluePopUp: any = {
  entering: { right: "15%" },
  entered: { right: "15%" },
  exiting: { right: -200 },
  exited: { right: -200 },
}

const transitionStylesGreenPopUp: any = {
  entering: { right: "35%" },
  entered: { right: "35%" },
  exiting: { right: -200 },
  exited: { right: -200 },
}

export default function OnboardingCongrats() {
  const t = useTranslate()
  const { loading, setAllowedUserFlags } = useSetAllowedUserFlags()
  const history = useHistory()
  const [, maxHeight] = useWindowSize()

  const [inProp, setInProp] = useState(false)

  useEffect(() => {
    setTimeout(() => setInProp(true), 1)
  }, [])

  return (
    <Grid container direction="row">
      <Grid
        item
        xs={12}
        md={8}
        xl={9}
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header>
          <MobileNavBack />
        </Header>
        <Content maxHeight={maxHeight}>
          <Title>{t("congratulations you have created your profile")}</Title>
          <div>
            <Subtitle>
              {t("We hope you’ll enjoy the new ways of collaborating")}
            </Subtitle>
            <Subtitle>
              {t(
                "Have fun exploring the application and see what your team is up to for the week ahead"
              )}
            </Subtitle>
          </div>
        </Content>
        <Footer>
          <StepIndicator />

          <Button
            disabled={loading}
            onClick={() => {
              setAllowedUserFlags({
                shouldOnboardUser: false,
              }).then(() => history.push("/planning"))
            }}
          >
            {t("launch offishall")}
          </Button>
        </Footer>
      </Grid>

      <RightContainer
        item
        xs={0}
        md={4}
        xl={3}
        style={{
          backgroundImage: `url(/static/img/onboarding/onboarding_step4.svg)`,
        }}
      >
        <Transition in={inProp} timeout={duration}>
          {(state) => (
            <div
              style={{
                ...defaultStyleBluePopUp,
                ...transitionStylesBluePopUp[state],
              }}
            >
              <img
                alt="Blue user"
                src="/static/img/onboarding/pop_up_blue_step4.svg"
              />
            </div>
          )}
        </Transition>
        <Transition in={inProp} timeout={duration}>
          {(state) => (
            <div
              style={{
                ...defaultStyleGreenPopUp,
                ...transitionStylesGreenPopUp[state],
              }}
            >
              <img
                alt="Green user"
                src="/static/img/onboarding/pop_up_green_step4.svg"
              />
            </div>
          )}
        </Transition>
      </RightContainer>
    </Grid>
  )
}
