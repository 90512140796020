import useCompanyFlags from "hooks/useCompanyFlags"
import UTCDate from "utils/UTCDate"

export default function useBookingMaxDate() {
  const {
    companyFlags,
  }: {
    companyFlags: { limitSlotInput?: { enabled: boolean; value?: number } }
  } = useCompanyFlags()

  const computeMaxDate = () => {
    if (
      companyFlags.limitSlotInput !== undefined &&
      companyFlags.limitSlotInput.enabled &&
      companyFlags.limitSlotInput.value !== undefined
    ) {
      const max = new UTCDate("WEEK-MONDAY")
      max.shift("WEEK", companyFlags.limitSlotInput.value + 1)
      return max
    }

    const limit = new UTCDate("WEEK-MONDAY")
    limit.shift("WEEK", 52)
    return limit
  }

  return computeMaxDate()
}
