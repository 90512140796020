import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function CheckOff({
  stroke,
  strokeWidth,
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      strokeWidth={strokeWidth ?? 1.5}
      stroke={stroke ? stroke : "currentColor"}
      {...rest}
    >
      <rect x="3" y="3" width="18" height="18" rx="1" fill="none" />
    </SvgIcon>
  )
}
