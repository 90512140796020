import Divider from "components/Divider"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { Button, colors, Grid, P14, P14Bold } from "ui"

import CSVDisplayer from "./CSVDisplayer"

const Container = styled.div`
  gap: 8px;
  max-width: 100%;
  max-height: 44vh;
`

const Text = styled(P14)`
  color: ${colors.grey1};
  margin-bottom: 26px;
`

const BoldText = styled(P14Bold)`
  color: ${colors.grey1};
  margin-bottom: 26px;
`

interface PropsType {
  csv: string[][]
  hasColumnNames: boolean
  currentStep: number
  managerColumn: number | null
  onPrevious: () => void
  onNext: () => void
  setManagerColumn: (nb: number) => void
}

export function CSVSelectManagerColumn({
  csv,
  hasColumnNames,
  currentStep,
  managerColumn,
  setManagerColumn,
  onPrevious,
  onNext,
}: PropsType) {
  const t = useTranslate()

  return (
    <Container>
      <Text>{t("select manager column info")}</Text>
      <BoldText>
        {t("if this column does not exist, you can click directly on next")}
      </BoldText>

      <CSVDisplayer
        csv={csv}
        hasColumnNames={hasColumnNames}
        columnIdx={managerColumn}
        setColumnIdx={setManagerColumn}
        currentStep={currentStep}
      />

      <Divider />
      <Grid container spacing={6} justifyContent="center">
        <Grid item>
          <Button secondary onClick={onPrevious}>
            {t("Previous")}
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => {
              onNext()
            }}
          >
            {t("Next")}
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}
