import useNudge from "graphql/notifications/useNudge"
import useCompanyFlags from "hooks/useCompanyFlags"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { UserBase } from "types"
import { Button, Dialog, P16 } from "ui"
import getUserDisplayName from "utils/getUserDisplayName"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
`

interface PropsType {
  user: UserBase
  open: boolean
  onClose: () => void
}

export default function NudgeModal({ user, open, onClose }: PropsType) {
  const t = useTranslate()
  const { companyFlags } = useCompanyFlags()

  const nudge = useNudge()

  return (
    <Dialog
      title={t("send reminder message")}
      open={open}
      onClose={onClose}
      stopEventPropagation
      leftButton={
        <Button secondary onClick={onClose}>
          {t("Cancel")}
        </Button>
      }
      rightButton={
        <Button
          onClick={() => {
            nudge(user.id)
            onClose()
          }}
        >
          {t("send")}
        </Button>
      }
    >
      <Container>
        <P16>
          {t("send reminder by email to user").replace(
            "{name}",
            getUserDisplayName(user, companyFlags)
          )}
        </P16>
      </Container>
    </Dialog>
  )
}
