import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useUserHybridRule from "graphql/hybridPolicy/useUserHybridRule"
import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { colors, L12 } from "ui"
import { Warning } from "ui/icons"
import UTCDate, { days } from "utils/UTCDate"

export const Reminder = styled.div`
  padding: 4px 8px;
  gap: 8px;
  background: rgba(255, 0, 0, 0.04);
  border-radius: 4px;
  display: flex;
  align-items: center;
  svg {
    color: ${colors.redAlert};
    stroke: ${colors.redAlert};
  }
`

interface PropsType {
  from: UTCDate
  to: UTCDate
}

export default function RestrictedDays({ from, to }: PropsType) {
  const t = useTranslate()

  const { user: me } = useUser()
  const { userHybridRule } = useUserHybridRule(me ? me.id : "")
  const { isTypicalWeekOpen } = usePlanningContext()

  // Returns an array of dates between the two dates
  const getDates = (startDate: UTCDate, endDate: UTCDate) => {
    const days: UTCDate[] = []

    for (
      const cursor = new UTCDate(startDate);
      cursor.getTime() < new UTCDate(endDate, "NEXT-DAY").getTime();
      cursor.shift("DAY", 1)
    ) {
      days.push(new UTCDate(cursor))
    }
    return days
  }

  const getNotBookableDays = () => {
    if (!userHybridRule) return []
    if (userHybridRule.period === "month") return []
    if (userHybridRule.restrictedDays.length === 0) return []

    const notBookableDays = getDates(from, to)
      .map((date) => {
        return date.getUTCDay()
      })
      .filter((item) => !userHybridRule.restrictedDays.includes(item))

    return notBookableDays.map((el) => days[el])
  }

  const shouldDisplayRestrictedDays =
    getNotBookableDays()?.length > 0 &&
    !isTypicalWeekOpen &&
    location.pathname.includes("/planning")

  if (!shouldDisplayRestrictedDays) return <></>
  return (
    <Reminder>
      <Warning />
      <L12 color="redAlert">
        {getNotBookableDays()
          .map((day) => t(day))
          .join(", ")}{" "}
        {getNotBookableDays().length > 1
          ? t("are not allowed remotely")
          : t("is not allowed remotely")}
      </L12>
    </Reminder>
  )
}
