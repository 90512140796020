import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { Flags, Group, MeetingRoom, PickedTargets } from "types"
import { P14, P16Bold, Tabs } from "ui"
import UserTree from "utils/UserTree"

import EmailsPicker from "./Tabs/EmailsPicker/EmailsPicker"
import GroupsPicker from "./Tabs/GroupsPicker/GroupsPicker"
import ManagersTab from "./Tabs/ManagersTab"
import UsersTab from "./Tabs/UsersTab"

const Layout = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr;
  overflow: hidden;
  max-height: 100%;
  > div:first-child {
    // tabs
  }
  > [role="tabpanel"] {
    // tab content
    overflow-y: scroll;
  }
`

const Header = styled.div`
  padding: 4px 16px;
`

interface WrapperPropsType {
  selectedTargets: PickedTargets
  companyFlags: Flags
  options?: {
    singleSelection?: boolean
    disableMySelf?: boolean
  }
  disabled?: {
    users?: boolean
    managers?: boolean
    groups?: boolean
    emails?: boolean
  }
  meetingRoom?: MeetingRoom
  extraAction?: React.ReactNode
  extraActionFilteredTargetIds?: string[]
  onSelectUser: (user: UserTree) => void
  onSelectManager: (manager: UserTree) => void
  onSelectGroup: (group: Group) => void
  onSelectEmail: (email: string) => void
}

export default function TargetPickerContent({
  selectedTargets,
  companyFlags,
  options = {
    singleSelection: false,
    disableMySelf: false,
  },
  disabled,
  meetingRoom,
  extraAction,
  extraActionFilteredTargetIds,
  onSelectUser,
  onSelectGroup,
  onSelectManager,
  onSelectEmail,
}: WrapperPropsType) {
  const t = useTranslate()
  const allTargetsLength =
    selectedTargets.emails.length +
    selectedTargets.users.length +
    selectedTargets.managers.length
  const availableSeats = meetingRoom
    ? meetingRoom.capacity - allTargetsLength
    : Infinity

  return (
    <Layout>
      {meetingRoom ? (
        <Header>
          <P16Bold>{meetingRoom.name}</P16Bold>
          <P14 color={availableSeats <= 0 ? "redAlert" : "grey1"}>
            {t("event booking message", { seats: `${availableSeats}` })}
          </P14>
        </Header>
      ) : (
        <div /> // Needed to preserve scrolling
      )}
      <Tabs
        tabs={[
          {
            label: "Collaborators",
            component: (
              <UsersTab
                companyFlags={companyFlags}
                selectedUsers={selectedTargets.users}
                disableMySelf={options.disableMySelf}
                extraAction={extraAction}
                extraActionFilteredTargetIds={extraActionFilteredTargetIds}
                onSelectUser={onSelectUser}
              />
            ),
          },
          {
            label: "managers",
            component: (
              <ManagersTab
                companyFlags={companyFlags}
                selectedManagers={selectedTargets.managers}
                disableMySelf={options.disableMySelf}
                extraAction={extraAction}
                extraActionFilteredTargetIds={extraActionFilteredTargetIds}
                onSelectManager={onSelectManager}
              />
            ),
          },
          {
            label: "groups",
            component: (
              <GroupsPicker
                companyFlags={companyFlags}
                disabled={options.singleSelection}
                selectedUsers={[
                  ...selectedTargets.users,
                  ...selectedTargets.managers,
                ]}
                disableMySelf={options.disableMySelf}
                extraAction={extraAction}
                extraActionFilteredTargetIds={extraActionFilteredTargetIds}
                onSelectUser={onSelectUser}
                onSelectGroup={onSelectGroup}
              />
            ),
          },
          {
            label: "guests outside of offishall",
            component: (
              <EmailsPicker
                emails={selectedTargets.emails}
                onAddEmail={onSelectEmail}
              />
            ),
          },
        ]
          .filter(({ label }) => {
            if (!disabled) return true
            if (label === "Collaborators") return !disabled.users
            if (label === "managers") return !disabled.managers
            if (label === "groups") return !disabled.groups
            if (label === "guests outside of offishall") return !disabled.emails
            return true
          })
          .map((tab, n) => ({ ...tab, label: t(tab.label), value: n }))}
      />
    </Layout>
  )
}
