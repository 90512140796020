import { gql, useMutation } from "@apollo/client"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useTranslate from "intl/useTranslate"

import { EquipmentFragment } from "./fragments/equipmentFragments"
import updateCache from "./updateCache"

const MUTATION = gql`
  ${EquipmentFragment}
  mutation updateEquipment(
    $equipmentId: ID!
    $name: String!
    $icon: String!
    $capacity: Int!
    $locationId: ID!
  ) {
    updateEquipment(
      equipmentId: $equipmentId
      name: $name
      icon: $icon
      capacity: $capacity
      locationId: $locationId
    ) {
      ...EquipmentFragment
    }
  }
`

interface PropsType {
  equipmentId: string
  name: string
  icon: string
  capacity: number
  locationId: string
}

export default function useUpdateEquipment() {
  const t = useTranslate()
  const { addToast } = useToasts()
  const [mutate] = useMutation(MUTATION, {
    update: (cache, { data }) =>
      updateCache({
        cache,
        data,
        mutationName: "updateEquipment",
      }),
  })

  return async ({
    equipmentId,
    name,
    icon,
    capacity,
    locationId,
  }: PropsType) => {
    try {
      await mutate({
        variables: { equipmentId, name, icon, capacity, locationId },
      })
      addToast(t("confirmation update a service"), { appearance: "success" })
    } catch (e) {
      console.error(e.message)
      addToast(e.message, { appearance: "error" })
    }
  }
}
