import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useSaveFloorVisualArea from "graphql/floorPlans/useSaveFloorVisualArea"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { WorkingMode } from "types"
import { colors } from "ui"
import mediaQueries from "ui/mediaQueries"
import LocationTree from "utils/LocationTree"

import DeletePlanConfirmDialog from "./components/DeletePlanConfirmDialog"
import InlineTools from "./components/InlineTools"
import SaveOfficeSlotButton from "./components/SaveOfficeSlotButton"
import Zoom from "./components/Zoom"
import FabricJSWrapper from "./FabricJSWrapper"
import VisualArea from "./VisualArea"

const Layout = styled.div<{ maxHeight?: number }>`
  display: grid;
  grid-template-rows: auto 1fr;
  padding-right: 16px;

  ${({ maxHeight }) => {
    if (maxHeight)
      return `
      padding-left: 16px;
  `
  }}

  @media ${mediaQueries.isMobile} {
    padding-left: 0;
    padding-right: 0;
  }
`

const CanvasWrapper = styled.div<{ maxHeight?: number }>`
  display: flex;
  flex-direction: column;
  background-color: ${colors.grey4};
  border: 1px solid ${colors.grey3};
  border-radius: 5px;

  ${({ maxHeight }) => {
    if (maxHeight) {
      const extraPaddings = 90
      return `
      max-height: calc(${maxHeight}px - ${extraPaddings}px);
      min-height: calc(${maxHeight}px - ${extraPaddings}px);
      `
    }
  }}

  // useful for displaying the zoom buttons
  position: relative;

  @media ${mediaQueries.isMobile} {
    ${({ maxHeight }) => {
      if (maxHeight) {
        const extraPaddings = 64
        return `
      max-height: calc(${maxHeight}px - ${extraPaddings}px);
      min-height: calc(${maxHeight}px - ${extraPaddings}px);
      `
      }
    }}
    &.SLOTS_EDITOR {
      ${({ maxHeight }) => {
        if (maxHeight) {
          const extraPaddings = 130
          return `
      max-height: calc(${maxHeight}px - ${extraPaddings}px);
      min-height: calc(${maxHeight}px - ${extraPaddings}px);
      `
        }
      }}
    }
  }
`

const ToolBar = styled.nav`
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
  flex-wrap: wrap;
  border: 1px solid ${colors.grey3};
  border-radius: 5px 5px 0 0;
  border-bottom: none;

  @media ${mediaQueries.isMobile} {
    padding: 14px 0 0 0;
    flex-direction: column;

    h2,
    h3 {
      text-align: center;
      padding: 0 0 16px 0;
      letter-spacing: 0em;
    }
  }

  .left-side {
    display: flex;
    flex-direction: column;
    align-items: left;
    flex-wrap: wrap;
    gap: 14px;
  }
`

const RedButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: "fit-content";
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  border: none;
  background: hsla(${colors.blackHSL}, 0);
  color: ${colors.redAlert};

  &:hover {
    background: hsla(${colors.blackHSL}, 0.04);
  }

  &:active {
    background: hsla(${colors.blackHSL}, 0.24);
  }
`

export enum VisualEditorMode {
  VIEWER,
  EDITOR,
}

interface Props {
  location: LocationTree
  mode: VisualEditorMode
  area: VisualArea
  loadingArea: boolean
  activeSublocation?: LocationTree
  disabledLocationIds?: string[]
  maxHeight?: number
  setSelectedLocationId: (locationId: string) => void
  saveWorkingMode?: (workingMode: WorkingMode) => void | Promise<void>
}

export default function VisualAreaEditor({
  location,
  area,
  loadingArea,
  mode,
  activeSublocation,
  disabledLocationIds,
  maxHeight,
  setSelectedLocationId,
  saveWorkingMode,
}: Props) {
  const t = useTranslate()
  const saveVisualArea = useSaveFloorVisualArea()
  const { isMobile } = useMediaQueries()
  const { openDrawer } = usePlanningContext()

  const canvasEl = useRef<HTMLCanvasElement>(null)
  const canvasElParent = useRef<HTMLDivElement>(null)
  const fabricWrapper = useRef<FabricJSWrapper | null>(null)
  const [deletePopupOpen, setDeletePopupOpen] = useState(false)

  useEffect(() => {
    const localCanvasEl = canvasEl.current
    if (localCanvasEl) {
      const wrapper = new FabricJSWrapper(localCanvasEl, canvasElParent, mode)
      wrapper.setCallbacks({
        saveVisualArea: saveVisualArea,
        setSelectedLocationId,
      })
      fabricWrapper.current = wrapper
      return () => {
        wrapper.dispose()
      }
    }
  }, [
    canvasEl,
    mode,
    location,
    setSelectedLocationId,
    saveVisualArea,
    maxHeight,
  ])

  useEffect(() => {
    if (loadingArea || !area || !fabricWrapper.current) {
      return
    }

    fabricWrapper.current.init(location, area, disabledLocationIds)
  }, [location, area, loadingArea, disabledLocationIds])

  useEffect(() => {
    fabricWrapper.current?.updateActiveSublocation(
      activeSublocation,
      disabledLocationIds
    )
  }, [activeSublocation, disabledLocationIds])

  return (
    <Layout maxHeight={maxHeight}>
      {mode === VisualEditorMode.EDITOR && (
        <ToolBar>
          <div className="left-side">{location.name}</div>
          <div className="right-side">
            <RedButton onClick={() => setDeletePopupOpen(true)}>
              {t("delete-plan")}
            </RedButton>
            <DeletePlanConfirmDialog
              location={location}
              area={area}
              open={deletePopupOpen}
              setOpen={setDeletePopupOpen}
            />
          </div>
        </ToolBar>
      )}
      {/*
       * Not sure why this div is needed here, but if I remove it,
       * the layout breaks when in viewer mode.
       */}
      {mode === VisualEditorMode.VIEWER && <div />}

      <CanvasWrapper
        className={openDrawer ?? undefined}
        ref={canvasElParent}
        maxHeight={maxHeight}
      >
        <InlineTools
          loading={loadingArea}
          fabricWrapper={fabricWrapper.current}
          location={location}
          activeSublocation={activeSublocation}
          mode={mode}
        />
        <Zoom fabricWrapper={fabricWrapper.current} />
        <canvas ref={canvasEl} />
        {mode === VisualEditorMode.VIEWER && !isMobile && saveWorkingMode && (
          <SaveOfficeSlotButton
            activeSublocation={activeSublocation}
            saveWorkingMode={saveWorkingMode}
          />
        )}
      </CanvasWrapper>
    </Layout>
  )
}
