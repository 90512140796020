import useLink from "hooks/useLink"
import useTranslate from "intl/useTranslate"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { User, UserBase } from "types"
import { Button, P16 } from "ui"

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button:last-child {
    margin-left: auto;
  }
`

interface PropsType {
  user: User
  onClose: () => void
}

export default function ManageTeam({ user, onClose }: PropsType) {
  const t = useTranslate()
  const goToManageTeamPage = useLink(`/admin/manage-team/${user.id}`)

  const [usersTeam, setUsersTeam] = useState<UserBase[]>([])

  useEffect(() => {
    // Sort users alphabetically
    setUsersTeam(
      [...user.team].sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1
        } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1
        } else return 0
      })
    )
  }, [user])

  if (usersTeam.length === 0) return <></>

  return (
    <Container>
      <P16>
        {t("manager team length", {
          teamLength: `${usersTeam.length}`,
        })}
      </P16>

      <Button
        secondary
        onClick={() => {
          goToManageTeamPage()
          onClose()
        }}
      >
        {t("manage my team")}
      </Button>
    </Container>
  )
}
