import { gql } from "@apollo/client"

export const UserHistoryFragment = gql`
  fragment UserHistoryFragment on UserHistory {
    id
    userId
    date
    type
    value
  }
`
