import PasswordInput from "components/PasswordInput"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components/macro"
import { Button, colors, H3, Link, mediaQueries, P14 } from "ui"
import { linkWithPassword } from "utils/firebase"

const MessageContainer = styled.div`
  margin-top: 24px;
  width: 80%;
  text-align: center;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 480px;

  @media ${mediaQueries.isMobile} {
    width: 90%;
    margin-left: 10px;
    margin-right: 10px;
  }

  h3 {
    color: ${colors.purple};
  }

  .forgot-password {
    color: ${colors.purple};
    text-align: flex-end;
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 370px;
    margin-top: 32px;
    gap: 16px;

    .input {
      width: 100%;
    }

    @media ${mediaQueries.isMobile} {
      width: 280px;
    }

    .error-message {
      display: flex;
      flex-direction: row;
      margin-top: 24px;
      p {
        margin-left: 12px;
      }
    }
  }

  .divider {
    height: 20px;
  }
`

const StyledButton = styled(Button)`
  width: 370px;

  @media ${mediaQueries.isMobile} {
    width: 280px;
  }
`

function SignIn() {
  const t = useTranslate()
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")

  const emailFromLocalStorage = window.localStorage.getItem("emailForSignIn")

  const linkWithAccount = async () => {
    if (!emailFromLocalStorage) {
      setError(`No email found.`)
    }
    try {
      await linkWithPassword(emailFromLocalStorage as string, password)
    } catch (e) {
      // log
      if (e.code === "auth/wrong-password") {
        setError(t(`Wrong password`))
      } else {
        setError(e.message)
      }
    }
  }

  return (
    <Container>
      <Helmet title={t("Sign in")} />
      <H3>{t("welcome")}</H3>

      <MessageContainer>
        <P14>{t("link-account")}</P14>
      </MessageContainer>

      <MessageContainer>
        <PasswordInput
          name="password"
          fullWidth
          useB4BPasswords={false}
          newPassword={true}
          placeholder={t("Password")}
          value={password}
          error={Boolean(error)}
          helperText={error}
          variant="outlined"
          size="small"
          handleChange={(e) => setPassword(e)}
        />
      </MessageContainer>
      <Link to="/reset-password">
        <P14 className="forgot-password">{t("Forgot password")}</P14>
      </Link>

      <StyledButton type="submit" onClick={linkWithAccount}>
        {t("Sign in")}
      </StyledButton>
    </Container>
  )
}

export default SignIn
