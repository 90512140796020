import { ApolloError, gql, useQuery } from "@apollo/client"
import { Event, IntegrationEvent } from "types"
import LocalDate from "utils/LocalDate"

import { EventFragment } from "./fragments/eventFragments"
import { IntegrationEventFragment } from "./fragments/integrationEventFragments"

const USER_OLD_EVENTS_QUERY = gql`
  ${EventFragment}
  query userEvents {
    userEvents {
      ...EventFragment
    }
  }
`

export const USER_INTEGRATION_EVENTS_QUERY = gql`
  ${IntegrationEventFragment}
  query integrations_userEvents($from: String!, $to: String!) {
    integrations_userEvents(from: $from, to: $to) {
      ...IntegrationEventFragment
    }
  }
`

export default function useUserEvents({
  from,
  to,
}: {
  from?: LocalDate
  to?: LocalDate
}): {
  userEvents: IntegrationEvent[]
  oldUserEvents: Event[]
  loading: boolean
  error?: ApolloError
} {
  const useIntegrationEvents = from !== undefined && to !== undefined
  const query = useIntegrationEvents
    ? USER_INTEGRATION_EVENTS_QUERY
    : USER_OLD_EVENTS_QUERY
  const variables = useIntegrationEvents
    ? {
        from: `${from.getTime()}`,
        to: `${to.getTime()}`,
      }
    : {}

  const { loading, error, data } = useQuery<{
    integrations_userEvents: IntegrationEvent[]
    userEvents: Event[]
  }>(query, {
    variables,
  })

  const userEvents: IntegrationEvent[] = data?.integrations_userEvents
    ? data?.integrations_userEvents
        .map((event) => ({
          ...event,
          from: new LocalDate(Number(event.from)),
          to: new LocalDate(Number(event.to)),
        }))
        .sort((a, b) => a.from.getTime() - b.from.getTime())
    : []

  const oldUserEvents: Event[] = data?.userEvents
    ? data?.userEvents.map((e) => ({
        ...e,
        from: new LocalDate(Number(e.from)),
        to: new LocalDate(Number(e.to)),
        deadline: e.deadline ? new LocalDate(Number(e.deadline)) : null,
      }))
    : []

  return {
    loading,
    error,
    userEvents,
    oldUserEvents,
  }
}
