import useDeleteManagerHybridRule from "graphql/hybridPolicy/useDeleteManagerHybridRule"
import useTranslate from "intl/useTranslate"
import React from "react"
import { ManagerHybridRule } from "types"
import { LU12 } from "ui"

import HyridRuleCard from "../HyridRuleCard/HyridRuleCard"
import { RulesList } from "../SharedLayouts"

interface WrapperPropsType {
  managerHybridRules: ManagerHybridRule[]
  setSelectedManagerHybridRule: (rule: ManagerHybridRule) => void
}

interface PropsType extends WrapperPropsType {
  deleteHybridRule: (ruleId: string) => void
}

function ManagersHybridRules({
  managerHybridRules,
  setSelectedManagerHybridRule,
  deleteHybridRule,
}: PropsType) {
  const t = useTranslate()

  return (
    <RulesList>
      {managerHybridRules.length > 0 && (
        <>
          <li className="header">
            <LU12>{t("team rules")}</LU12>
          </li>
          {managerHybridRules.map((rule, n) => {
            if (!rule.managerId) return null
            return (
              <HyridRuleCard
                key={`manager-rule-${n}`}
                managerId={rule.managerId}
                rule={rule}
                setSelectedUserOrGroupHybridRule={(hybridRule) => {
                  setSelectedManagerHybridRule({
                    ...hybridRule,
                    managerId: rule.managerId,
                    userIds: rule.userIds,
                    appliesToManager: rule.appliesToManager,
                  })
                }}
                deleteHybridRule={deleteHybridRule}
              />
            )
          })}
        </>
      )}
    </RulesList>
  )
}

export default function Wrapper({
  managerHybridRules,
  setSelectedManagerHybridRule,
}: WrapperPropsType) {
  const deleteManagerHybridRule = useDeleteManagerHybridRule()

  if (managerHybridRules.length === 0) return <></>

  return (
    <ManagersHybridRules
      managerHybridRules={managerHybridRules}
      setSelectedManagerHybridRule={setSelectedManagerHybridRule}
      deleteHybridRule={deleteManagerHybridRule}
    />
  )
}
