import { gql, useMutation } from "@apollo/client"
import { Button } from "@mui/material"
import useTranslate from "intl/useTranslate"
import React, { useCallback, useState } from "react"

const RESEND_VALIDATION_MAIL = gql`
  mutation ResendValidationMail($email: String!) {
    resendValidationMail(email: $email)
  }
`

function ResendValidationMail({ email }: { email: string }) {
  const t = useTranslate()
  const [sending, setSending] = useState(false)
  const [sent, setSent] = useState(false)
  const [resendValidationMail] = useMutation(RESEND_VALIDATION_MAIL)
  const resendValidationMailCallback = useCallback(async () => {
    if (sending || sent) return
    setSending(true)
    const userId = await resendValidationMail({ variables: { email } })
    setSending(false)
    if (userId) {
      setSent(true)
    }
  }, [resendValidationMail, email, sending, sent])
  return (
    <Button
      color="secondary"
      variant="contained"
      onClick={resendValidationMailCallback}
      disabled={sending || sent}
    >
      {t("Send validation email again")} {sent && "✅"}
    </Button>
  )
}

export default ResendValidationMail
