import {
  ActiveLocation,
  usePlanningContext,
} from "components/PlanningContextProvider/PlanningContextProvider"
import useMyGroupsSimple from "graphql/groups/useMyGroupsSimple"
import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import React from "react"
import { useTeamsAppContext } from "TeamsApp/TeamsAppContext"
import { User, UserBase } from "types"
import UTCDate from "utils/UTCDate"

import getDefaultGroup from "../../../utils/getDefaultGroup"
import { ActiveLocationsList } from "../../ActiveLocationsList/ActiveLocationsList"
import GroupOrMSTeamsPlanningHeader from "../GroupPlanningHeader/GroupOrMSTeamsPlanningHeader/GroupOrMSTeamsPlanningHeader"
import GroupPlanningHeader from "../GroupPlanningHeader/GroupPlanningHeader"
import PlanningHeaderMessage from "../PlanningHeaderMessage/PlanningHeaderMessage"

interface LocalComponentPropsType {
  users: UserBase[]
  activeLocation: ActiveLocation
  noActiveFilters: boolean
  me: User | null
}

function MessageOrActiveLocationsList({
  users,
  noActiveFilters,
  activeLocation,
  me,
}: LocalComponentPropsType) {
  if (users.length === 0 && noActiveFilters)
    return (
      <PlanningHeaderMessage messageToBeTranslated="No one in this group" />
    )
  if (
    !activeLocation.persist &&
    users.length === 1 &&
    users[0].id === me?.id &&
    noActiveFilters
  )
    return (
      <PlanningHeaderMessage messageToBeTranslated="No one else in this group" />
    )
  return <ActiveLocationsList />
}

interface PropsType {
  users: UserBase[]
  firstWeekday: UTCDate
  lastWeekday: UTCDate
  noActiveFilters: boolean
}

export default function TeamsPlanningHeader({
  users,
  firstWeekday,
  lastWeekday,
  noActiveFilters,
}: PropsType) {
  const t = useTranslate()
  const { users: teamsUsers, teamName, isPrivateChat } = useTeamsAppContext()
  const { user: me } = useUser()
  const { groups: myGroups } = useMyGroupsSimple()
  const { activeLocation } = usePlanningContext()

  if (!teamsUsers) {
    const defaultGroup = getDefaultGroup(me, myGroups)
    if (defaultGroup) {
      return (
        <GroupPlanningHeader
          groupId={defaultGroup.id}
          users={users}
          firstWeekday={firstWeekday}
          lastWeekday={lastWeekday}
          noActiveFilters={noActiveFilters}
        />
      )
    }
  }

  return (
    <>
      <GroupOrMSTeamsPlanningHeader
        name={
          teamName ||
          (isPrivateChat ? t("private chat members") : t("team members"))
        }
        id="teams-virtual-group"
      />
      <MessageOrActiveLocationsList
        users={users}
        noActiveFilters={noActiveFilters}
        activeLocation={activeLocation}
        me={me}
      />
    </>
  )
}
