import Loader from "components/Loader/Loader"
import useLocationsTree from "graphql/locations/useLocationsTree"
import useWeeklyUserSlots from "graphql/slots/useWeeklyUserSlots"
import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { colors, P14Bold, P16Bold } from "ui"
import LocalDate from "utils/LocalDate"
import { getChildrenLocations } from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

import { TodayLocation } from "../TodayLocation"

const LocationDiv = styled.div<{
  workingPlace: string | undefined | null
}>`
  &.location {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;

    .location-name {
      padding: 4px 8px;
      gap: 8px;
      border-radius: 24px;
      width: fit-content;
      background-color: ${({ workingPlace }) => {
        if (workingPlace === "null") return `${colors.null}`
        else if (workingPlace === null) return `${colors.null}`
        else if (workingPlace === "home") return `${colors.home}`
        else if (workingPlace === "else") return `${colors.else}`
        else if (workingPlace === "off" || workingPlace === "ooc")
          return `${colors.ooc}`
        return `${colors.office}`
      }};

      p {
        color: ${({ workingPlace }) => {
          if (
            workingPlace === "off" ||
            workingPlace === "ooc" ||
            workingPlace === "else"
          )
            return `${colors.black}`
          return `${colors.white}`
        }};
      }
    }
  }
`

const FillOutPlanningDiv = styled.div`
  &.fill-out-planning {
    background-color: ${colors.white};
    border: 3px solid ${colors.redAlert};
    padding: 4px 12px;
    border-radius: 24px;
    width: fit-content;
    p {
      font-family: "Source Sans Pro";
      line-height: 20px;
      color: ${colors.null};
    }
  }
`

export default function WorkingPlace() {
  const t = useTranslate()
  const { user: me } = useUser()
  const today = new UTCDate("DAY")
  const {
    slots,
    loading: slotsLoading,
    error: slotsError,
  } = useWeeklyUserSlots(me ? me.id : "", today)

  const mySlots = slots.filter((slot) => slot.date.isToday())

  const userActualSlot = new UTCDate("HALF-DAY").isMorning()
    ? mySlots.length > 0
      ? mySlots[0]
      : undefined
    : mySlots.length > 1
    ? mySlots[1]
    : undefined

  const {
    locations,
    loading: locationsLoading,
    error: locationsError,
  } = useLocationsTree()
  const allLocations = getChildrenLocations(locations, false)
  const todayLocation = allLocations.find(
    (location) => location.id === userActualSlot?.locationId
  )

  const formattedDate = new LocalDate().format("short", t)

  const workingPlace = userActualSlot ? userActualSlot.value : null

  if (locationsLoading || slotsLoading) return <Loader />

  if (locationsError || slotsError) return <></>

  if (!userActualSlot)
    return (
      <FillOutPlanningDiv className="fill-out-planning">
        <P14Bold>{t("please fill out your planning")}</P14Bold>
      </FillOutPlanningDiv>
    )
  if (userActualSlot.value === null)
    return (
      <LocationDiv className="location" workingPlace={workingPlace}>
        <TodayLocation value={userActualSlot.value} date={formattedDate} />
        <FillOutPlanningDiv className="fill-out-planning">
          <P16Bold>{t("Not set")}</P16Bold>
        </FillOutPlanningDiv>
      </LocationDiv>
    )
  if (userActualSlot.value !== "office")
    return (
      <LocationDiv className="location" workingPlace={workingPlace}>
        <TodayLocation value={userActualSlot.value} date={formattedDate} />
        <div className="location-name">
          <P16Bold>{t(userActualSlot.value)}</P16Bold>
        </div>
      </LocationDiv>
    )

  return (
    <LocationDiv className="location" workingPlace={workingPlace}>
      <TodayLocation value={todayLocation?.name} date={formattedDate} />
      <div className="location-name">
        <P16Bold>{todayLocation?.name}</P16Bold>
      </div>
    </LocationDiv>
  )
}
