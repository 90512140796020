import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function Yoga({ stroke, strokeWidth, ...rest }: SvgIconProps) {
  return (
    <SvgIcon
      strokeWidth={strokeWidth ?? 2.5}
      stroke={stroke ? stroke : "currentColor"}
      {...rest}
    >
      <path
        d="M19.4287 20.0049L20.5716 22.4649C20.5716 22.4649 25.7144 23.5571 25.7144 26.155C25.7144 28.0001 23.4287 28.0001 23.4287 28.0001H17.3335L14.3335 26.3334"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M12.5714 20.0049L11.4285 22.4649C11.4285 22.4649 6.28564 23.5571 6.28564 26.155C6.28564 28.0001 8.57135 28.0001 8.57135 28.0001H11.3333L14.3333 26.3334L18 24.0001"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M4 21.2348C4 21.2348 6.85715 20.6199 8.57143 20.0048C10.2857 11.3946 15.4285 12.0096 16 12.0096C16.5715 12.0096 21.7143 11.3946 23.4285 20.0048C25.1428 20.6199 28 21.2348 28 21.2348"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M16.0002 9.33333C17.473 9.33333 18.6668 8.13943 18.6668 6.66667C18.6668 5.19391 17.473 4 16.0002 4C14.5274 4 13.3335 5.19391 13.3335 6.66667C13.3335 8.13943 14.5274 9.33333 16.0002 9.33333Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  )
}
