import { Flags, GroupSimple, UserBase } from "types"
import getUserDisplayName from "utils/getUserDisplayName"

type Translator = (
  id: string,
  values?: Record<string, string> | undefined
) => string

export default function getTeamDisplayName(
  group: GroupSimple,
  flags: Flags,
  translator: Translator
) {
  if (group.id === "myteam" && group.name === "" && group.owner)
    return translator("My team")
  if (group.id === "mymanagerteam" && group.name === "" && group.owner)
    return translator("person's team", {
      person: getUserDisplayName(group.owner, flags),
    })
  if (group.id === "everyone") return group.name
  return group.name
}

export function getManagerTeamDisplayName(manager: UserBase, t: Translator) {
  if (manager.teamName !== "") {
    return manager.teamName
  }
  return t("person's team", { person: manager.name })
}
