import { useMediaQuery } from "@mui/material"
import React from "react"
import styled from "styled-components"
import { colors, P14Bold } from "ui"
import { ArrowSeparateVertical } from "ui/icons"
import getColorFromStatus from "utils/getColorFromStatus"

const Layout = styled.div`
  display: flex;
  align-items: center;

  p {
    color: ${colors.grey1};
  }

  svg {
    stroke: ${colors.grey1};
    width: 24px;
    cursor: pointer;
  }
`

interface LabelBadgePropsType {
  status: "home" | "office" | "else" | "off" | "ooc"
}

const LabelWithBadge = styled(P14Bold)<LabelBadgePropsType>`
  display: flex;
  align-items: center;
  gap: 8px;

  .label-badge {
    width: 10px;
    aspect-ratio: 1;
    border-radius: 50px;

    ${({ status }) => {
      return `background: ${getColorFromStatus(status)}`
    }}
  }
`

interface PropsType {
  field: string
  children: React.ReactNode
  orderBy: string

  setOrderBy: (str: string) => void
}

export default function TableLabelWithSortingButton({
  field,
  children,
  orderBy,
  setOrderBy,
}: PropsType) {
  return (
    <Layout
      onClick={() =>
        orderBy === `order by "${field}"`
          ? setOrderBy(`order by "${field}" desc`)
          : setOrderBy(`order by "${field}"`)
      }
    >
      <P14Bold>{children}</P14Bold>
      <ArrowSeparateVertical />
    </Layout>
  )
}

interface WithBadgePropsType extends PropsType {
  field: "home" | "office" | "else" | "off" | "ooc"
}

export function TableLabelWithBadgeAndSortingButton({
  field,
  children,
  orderBy,
  setOrderBy,
}: WithBadgePropsType) {
  const isNarrowScreen = useMediaQuery("(max-width: 1400px)")

  return (
    <Layout
      onClick={() =>
        orderBy === `order by "${field}"`
          ? setOrderBy(`order by "${field}" desc`)
          : setOrderBy(`order by "${field}"`)
      }
    >
      <LabelWithBadge status={field}>
        {!isNarrowScreen && <>{children}</>}
        <span className="label-badge" />
      </LabelWithBadge>

      <ArrowSeparateVertical />
    </Layout>
  )
}
