import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useCheckCompanyIntegration from "graphql/integrations/useCheckCompanyIntegration"
import useSetupCompanyIntegration from "graphql/integrations/useSetupCompanyIntegration"
import useTranslate from "intl/useTranslate"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { CompanyIntegration } from "types"
import { Microsoft } from "ui/icons"
import getUrlSearchParam from "utils/getUrlSearchParam"

import CompanyWideIntegrationCard from "../CompanyWideIntegrationCard/CompanyWideIntegrationCard"
import { CustomButton } from "../CompanyWideIntegrationCard/CustomButton"
import RevokeCompanyWideIntegrationToggle from "../RevokeCompanyWideIntegrationToggle/RevokeCompanyWideIntegratioToggle"

export function AuthorizeMicrosoftButton({
  integration,
}: {
  integration: CompanyIntegration | undefined
}) {
  const t = useTranslate()
  const history = useHistory()

  const setupCompanyIntegration = useSetupCompanyIntegration()
  const { addToast } = useToasts()

  const [loading, setLoading] = useState(false)

  const shouldSkip = getUrlSearchParam("code") !== null
  const { result, loading: checkLoading } = useCheckCompanyIntegration(
    integration?.name,
    shouldSkip
  )

  // Display an error if any
  useEffect(() => {
    if (window.location.search.includes("error="))
      addToast(
        `${t("error while connecting to Microsoft")} : ${getUrlSearchParam(
          "error"
        )}`,
        {
          appearance: "error",
        }
      )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // setup the tenant id if success
  useEffect(() => {
    const success = window.location.search.includes(
      "admin_consent=True&tenant="
    )
    if (
      success &&
      localStorage.getItem("integration-type") === "microsoft-outlook"
    ) {
      const externalId = getUrlSearchParam("tenant")
      if (externalId) {
        setLoading(true)
        setupCompanyIntegration({
          name: "microsoft-outlook",
          externalId,
          flags: {
            forceRefresh: !integration,
          },
        }).then(() => {
          setLoading(false)
          history.replace("/admin/integrations")
        })
      } else {
        addToast(t("error while connecting to Microsoft"), {
          appearance: "error",
        })
      }
      localStorage.removeItem("integration-type")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <CustomButton
        disabled={loading || checkLoading || result === "success"}
        onClick={() => {
          localStorage.setItem("integration-type", "microsoft-outlook")
          // we can optionnaly pass a "&state=some_state" option to that url
          location.href = `https://login.microsoftonline.com/common/adminconsent?client_id=${process.env.REACT_APP_OUTLOOK_CLIENT_ID}&redirect_uri=${window.location.origin}/admin/integrations`
        }}
      >
        <Microsoft />
        {t("Authorize access")}
      </CustomButton>
    </>
  )
}

interface PropsType {
  integration: CompanyIntegration
}

export default function MicrosoftCompanyWideIntegration({
  integration,
}: PropsType) {
  return (
    <CompanyWideIntegrationCard
      integrationName="microsoft-outlook"
      authorizeComponent={
        <AuthorizeMicrosoftButton integration={integration} />
      }
      revokeComponent={
        <RevokeCompanyWideIntegrationToggle integration={integration} />
      }
    />
  )
}
