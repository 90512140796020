import { SvgIconProps } from "@mui/material"
import useLocationsTree from "graphql/locations/useLocationsTree"
import React from "react"
import { Building, Zone } from "ui/icons"

import LocationTree from "../utils/LocationTree"

interface LocationFromIdPropsType extends SvgIconProps {
  locationId: string
}

export function LocationIconFromId({
  locationId,
  ...props
}: LocationFromIdPropsType) {
  const { locations } = useLocationsTree()
  const location = LocationTree.getLocationNode(locations, locationId)
  return location?.isCategory ? <Zone {...props} /> : <Building {...props} />
}

interface LocationIconPropsType extends SvgIconProps {
  location: LocationTree
}

export default function LocationIcon({
  location,
  ...props
}: LocationIconPropsType) {
  return location.isCategory ? <Zone {...props} /> : <Building {...props} />
}
