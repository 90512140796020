import { ApolloError, gql, useQuery } from "@apollo/client"
import { UserBase, UsersAtInput } from "types"

import { UserBaseFragment } from "./fragments/userFragment"

export const QUERY = gql`
  ${UserBaseFragment}
  query usersAt(
    $from: String
    $to: String
    $userIds: [String]
    $locationIds: [String]
    $groupIds: [String]
    $filters: [String]
  ) {
    usersAt(
      from: $from
      to: $to
      userIds: $userIds
      locationIds: $locationIds
      groupIds: $groupIds
      filters: $filters
    ) {
      ...UserBaseFragment
    }
  }
`

export default function useUsersAt({
  from,
  to,
  userIds,
  locationIds,
  groupIds,
  filters = [],
}: UsersAtInput): {
  loading: boolean
  error?: ApolloError
  users: UserBase[]
} {
  const { loading, error, data } = useQuery(QUERY, {
    variables: {
      from: from ? `${from.getTime()}` : undefined,
      to: to ? `${to.getTime()}` : undefined,
      userIds: userIds && userIds.length > 0 ? userIds : undefined,
      locationIds,
      groupIds,
      filters,
    },
  })
  return { users: data?.usersAt || [], loading, error }
}
