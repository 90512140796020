import LocaleSelector from "components/LocaleSelector"
import ManageSettingsSlider from "components/Settings/ManageSettingsSlider"
import useUserSettings from "graphql/settings/useUserSettings"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { CompanySetting } from "types"
import { colors, Grid, H4, mediaQueries, P14Bold } from "ui"

const SliderContainer = styled.div`
  margin-left: 32px;
  display: flex;
  align-items: center;
  width: 100%;
`

const ButtonsLine = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 25%;
  margin-top: 25px;

  p {
    color: ${colors.grey1};
  }

  @media ${mediaQueries.isMobile} {
    width: 100%;
    padding: 0 8px;
    justify-content: space-between;
  }
`

export default function ManageUserSettings() {
  const t = useTranslate()

  const { userSettings } = useUserSettings()
  const groups = userSettings.reduce((grouped, setting) => {
    if (grouped[setting.group] !== undefined) {
      grouped[setting.group].push(setting)
    } else {
      grouped[setting.group] = [setting]
    }
    return grouped
  }, {} as Record<string, CompanySetting[]>)
  if (Object.keys(groups).length === 0) {
    return null
  }
  return (
    <>
      {Object.keys(groups).map((group) => (
        <Grid container spacing={4} key={group}>
          <Grid item xs={12}>
            <H4>{t(`user-setting-group-${group}`)}</H4>
          </Grid>
          <SliderContainer>
            {groups[group].map((setting) => (
              <ManageSettingsSlider
                type="user"
                key={setting.key}
                setting={setting}
              />
            ))}
          </SliderContainer>
        </Grid>
      ))}
      <ButtonsLine>
        <P14Bold>{t("App language")}</P14Bold>
        <LocaleSelector />
      </ButtonsLine>
    </>
  )
}
