import React, { useContext, useState } from "react"
import styled from "styled-components"
import { mediaQueries, Toast } from "ui"

const Container = styled.div`
  bottom: 50px;
  right: 50px;
  position: fixed;
  z-index: 2000;
  gap: 20px;

  @media ${mediaQueries.isMobile} {
    left: 5px;
    right: 5px;
  }
`

interface Options {
  // A convenience prop; the time until a toast will be dismissed automatically, in milliseconds.
  // Note that specifying this will override any defaults set on individual children Toasts.
  autoDismissTimeout?: number
  // Which element to attach the container's portal to, defaults to the `body`.
  appearance: "error" | "info" | "success"
  pathname?: string
}
interface Toast {
  id: number
  text: string
  isOpen: boolean
  options: Options
}
interface ToastContextType {
  addToast: (text: string, options: Options) => void
}

const emptyFunc = () => {
  //
}

const initialToastContext: ToastContextType = {
  addToast: emptyFunc,
}

const ToastContext = React.createContext(initialToastContext)

const DEFAULT_DURATION = 3000

interface PropsType {
  children: React.ReactNode
}

let TOAST_COUNTER = 42 // magic number that will be used as id

export default function AddToastProvider({ children }: PropsType) {
  const [toasts, setToasts] = useState<Toast[]>([])

  // Reset notification params
  const resetNotification = (id: number) => {
    setToasts((oldToasts: Toast[]): Toast[] =>
      oldToasts.reduce((acc: Toast[], toast: Toast): Toast[] => {
        if (toast.id === id) return [...acc, { ...toast, isOpen: false }]
        else return [...acc, toast]
      }, [])
    )
    const timer = setTimeout(() => {
      setToasts((oldToast) => oldToast.filter((toast) => toast.id !== id))
    }, 500)
    return () => clearTimeout(timer)
  }

  // Close notifications after duration define or 3 secondes (default duration)
  const closeNotificationAfterDuration = (
    id: number,
    autoDismissTimeout: number
  ) => {
    const timer = setTimeout(() => resetNotification(id), autoDismissTimeout)
    return () => clearTimeout(timer)
  }

  // Toast creation
  const addToast = (text: string, options: Options) => {
    const autoDismissTimeout = options.autoDismissTimeout
      ? options.autoDismissTimeout
      : DEFAULT_DURATION

    const id = TOAST_COUNTER++

    if (options) {
      closeNotificationAfterDuration(id, autoDismissTimeout)
    }

    setToasts((oldToasts) => [
      ...oldToasts,
      {
        id: id,
        text: text,
        isOpen: true,
        options: {
          autoDismissTimeout: autoDismissTimeout,
          appearance: options.appearance,
          pathname: options?.pathname,
        },
      },
    ])
  }

  const value = {
    addToast,
  }

  return (
    <ToastContext.Provider value={value}>
      {children}
      <Container>
        {toasts.map((toast) => (
          <Toast
            key={toast.id}
            isOpen={toast.isOpen}
            text={toast.text}
            appearance={toast.options.appearance}
            path={toast.options?.pathname}
            onClose={() => resetNotification(toast.id)}
          />
        ))}
      </Container>
    </ToastContext.Provider>
  )
}

export function useToasts() {
  return useContext(ToastContext)
}
