import PageHeader from "components/Layout/PageLayout/PageHeader/PageHeader"
import BurgerButton from "components/Layout/PageLayout/PageHeader/PageTitle/BurgerButton/BurgerButton"
import PageTitle from "components/Layout/PageLayout/PageHeader/PageTitle/PageTitle"
import useCompanyFlag from "hooks/useCompanyFlag"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { colors, L12, mediaQueries } from "ui"
import { NavArrowRight } from "ui/icons"

import { Item } from "./components/Item"
import MenuItem from "./MenuItem"
import MenuLink from "./MenuLink"
import MenuTitle from "./MenuTitle"

const AnchorList = styled.ul`
  display: flex;
  flex-direction: column;
  border-radius: 8px 0px 0px 8px;
  background: linear-gradient(
      0deg,
      rgba(34, 0, 117, 0.02),
      rgba(34, 0, 117, 0.02)
    ),
    ${colors.white};
  height: 100%;
  width: 180px;
  padding: 10px 0px;

  @media ${mediaQueries.isMobile} {
    width: 100vw;
    background: ${colors.white};
    padding: 0px;
    border-radius: 0px;
  }

  .offishall-care {
    margin-top: 12px;
    @media ${mediaQueries.isMobile} {
      margin-top: 0px;
    }
  }

  .logout {
    margin-top: auto;
  }

  .offishall-logo {
    height: 15px;
    margin-right: auto;
    margin-top: 20px;
    margin-left: 16px;
  }
`

interface PropsType {
  selectedAnchor: { id: string; text: string }
  setSelectedAnchor: (obj: { id: string; text: string }) => void
  setShouldDisplayMenu: (bool: boolean) => void
}

export default function Menu({
  selectedAnchor,
  setSelectedAnchor,
  setShouldDisplayMenu,
}: PropsType) {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()
  const history = useHistory()
  const careActive = Boolean(useCompanyFlag("care", false))

  const anchors = [
    { id: "personal information", text: t("personal information") },
    { id: "notifications & display", text: t("notifications & display") },
    { id: "integrations", text: t("Integrations") },
    { id: "corporate documents", text: t("corporate documents") },
  ]

  return (
    <AnchorList>
      {isMobile && (
        <PageHeader>
          <BurgerButton />
          <PageTitle title={t("Settings")} />
        </PageHeader>
      )}
      <MenuTitle title={t("my account")} />
      {anchors.map((anchor) => (
        <MenuItem
          key={anchor.id}
          title={anchor.text}
          isSelected={!isMobile && anchor.id === selectedAnchor.id}
          onClick={() => {
            setSelectedAnchor({ id: anchor.id, text: anchor.text })
            if (isMobile) setShouldDisplayMenu(false)
          }}
        />
      ))}
      {careActive && (
        <div className="offishall-care">
          <MenuTitle title="Offishall care" />
          <MenuItem
            title={t("Services")}
            isSelected={!isMobile && selectedAnchor.id === t("Services")}
            onClick={() => {
              setSelectedAnchor({ id: "Services", text: "Services" })
              if (isMobile) setShouldDisplayMenu(false)
            }}
          />
          <MenuItem title={t("report a claim")} />
        </div>
      )}
      <Item doubleBorder className="logout">
        <L12 color="redAlert" onClick={() => history.push("/logout")}>
          {t("Log out")}
        </L12>
        {isMobile && <NavArrowRight />}
      </Item>
      <MenuLink title={t("user guide")} />
      <MenuLink title={t("simple cgu")} />
      <MenuLink title={t("privacy policy")} />
      {!isMobile && (
        <img src="/static/icons/icon_grey.svg" className="offishall-logo" />
      )}
    </AnchorList>
  )
}
