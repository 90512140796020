export default {
  id: "en",
  flag: "🇺🇸",
  messages: {
    // LOGIN
    welcome: "welcome",
    Company: "Company",
    "Company name": "Company name",
    Phone: "Phone Number",
    "Sign in to your account to continue":
      "Sign in to your account to continue",
    "Email address": "Email address",
    Password: "Password",
    "Confirm Password": "Confirm Password",
    "Old Password": "Old Password",
    "New Password": "New password",
    "Your password": "Your password",
    "Reset password": "Reset Password",
    "Wrong email or password": "Wrong email or password",
    "Wrong password": "Wrong password",
    "Must be at least 8 characters": "Must be at least 8 characters",
    "Must be at least 12 characters": "Must be at least 12 characters",
    B4BPasswordRule:
      "Your password must contain at least: 1 upper case, 1 lower case, 1 number and 1 special character (the order does not matter 🙂 )",
    "Back to sign-in": "Back to sign-in",
    "Sign in": "Sign in",
    "Sign up": "Sign up",
    "Sign-in with Microsoft": "Sign-in with Microsoft",
    "Sign-in with Google": "Sign-in with Google",
    "Sign-in with Okta": "Sign-in with Okta",
    "Sign-in with SSO": "Sign-in with SSO",
    "Log in with your team's identity provider":
      "Log in with your team's identity provider",
    "Your Offishall company ID": "Your Offishall company ID",
    "SSO simple tip":
      "Tip: You can directly go to [your company id].offishall.io to sign in with your company's identity provider",
    "Cannot connect to this account": "Cannot connect to this account",
    "Cannot connect to this account: user is disabled":
      "Cannot connect to this account: user is disabled",
    "Cannot connect to this account: company is disabled":
      "Cannot connect to this account: company is disabled",
    "Forgot password": "Forgot password ?",
    "Must be a valid email": "Must be a valid email",
    Required: "Required",
    "Email is required": "Email is required",
    "Password is required": "Password is required",
    "old pawword is required": "Old password is required",
    "Something went wrong": "Something went wrong",
    "Enter your email to reset your password":
      "Enter your email to reset your password",
    "An email has been sent to the address to reset your password":
      "An email has been sent to the address {address} to reset your password",
    "Get started": "Get started",
    "create your company account here": "Create your company account!",
    "configure your company account here": "Configure your company account!",
    "Both passwords need to be the same": "Passwords must be identical 🤓",
    "Don't worry about payment":
      "No need to worry! No payment information needs to be filled in now",
    "I accept Offishall": "I accept Offishall&apos;s {TOS}",
    "terms of service": "Terms of services",
    "Already have an account ? Click here to sign-in":
      "Already have an account ? Click here to sign-in",
    "Confirm your email": "Confirm your email",
    "Please check your inbox for a confirmation email from noreply@offishall.io.":
      "Check your email, you will receive a confirmation link from noreply@offishall.io 😉 Thank you!",
    "Click the link in the email to confirm your email address.":
      "Click the link in the email to confirm your email address.",
    "Send validation email again": "Send validation email again",
    "Email validation": "Email validation",
    "Verifying your email now...": "Verifying your email now...",
    "Failed to verify email.": "Failed to verify email.",
    "Your email has been successfully verified.":
      "Your email has been successfully verified.",
    "Continue to sign-in": "Continue to sign-in",
    "Log out": "Log out",
    "Company is required": "Company is required",
    "Confirm password is required": "Confirm password is required",
    "You must accept terms of service": "You must accept terms of service",
    Confirm: "Confirm",
    "signin-missing-account-title": "User not found",
    "signin-missing-account-content":
      "It seems like you don't have an Offishall account yet. Please contact your administrator to create an account for you, or create a new company account here.",
    "site protected by reCAPTCHA & google":
      "This site is protected by reCAPTCHA and the Google",
    "of Google": "of Google",
    "link-account":
      "This is the first time you sign in with this account using this method. Please enter your current password to link this account to your Offishall account.",

    "App language": "App language",

    // DEMO
    "demo-popup-title": "Demo mode",
    "demo-popup-content": `You are in a demo environment, with fake users, spaces and groups pre-generated for you. You are free to browse, create and use the application completely; when you leave the demo environment, all the demo data will be deleted. Everything you have created will be retained. When you are ready, click on the "Set it up for your business" button at the top of the application, and you can book an appointment with us to help you, or do it on your own.`,
    "Setup your organization": "Setup your organization",
    "demo-leave-popup-title": "Leave demo mode ?",
    "demo-leave-popup-content": `When you leave the demo environment, all data will be deleted except for the data you have created. Would you like to make an appointment to set up your perfect business environment?`,
    "demo-leave-popup-yes": "Book a meeting and leave the demo mode",
    "demo-leave-popup-no": "Exit the demo mode without booking a meeting",
    // DATE
    day: "Day",
    days: "days",
    "half-days": "half-days",
    Sunday: "Sunday",
    Monday: "Monday",
    Tuesday: "Tuesday",
    Wednesday: "Wednesday",
    Thursday: "Thursday",
    Friday: "Friday",
    Saturday: "Saturday",
    January: "January",
    February: "February",
    March: "March",
    April: "April",
    May: "May",
    June: "June",
    July: "July",
    August: "August",
    September: "September",
    October: "October",
    November: "November",
    December: "December",
    Date: "Date",
    week: "week",
    month: "month",
    year: "year",
    "DD/MM/YY": "DD/MM/YY",
    "MM/DD/YY": "MM/DD/YY",

    // MISC
    Me: "Me",
    you: "you",
    from: "from",
    to: "to",
    Search: "Search",
    All: "All",
    Save: "Save",
    Back: "Back",
    New: "New",
    Edit: "Edit",
    Update: "Update",
    Delete: "Delete",
    Remove: "Remove",
    "Remove all": "Remove all",
    Rename: "Rename",
    Add: "Add",
    Cancel: "Cancel",
    Default: "Default",
    French: "🇫🇷 French",
    English: "🇺🇸 English",
    "Manager name": "Manager : {name}",
    Manager: "Manager",
    Managers: "Managers",
    Understood: "Got it! 😄",
    Other: "Other",
    Unavailable: "unavailable",
    Remote: "Remote",
    Name: "Name",
    Clear: "Clear",
    Close: "Close",
    "Clear selection": "Clear selection",
    "simple view more": "View more",
    "view more": "View more...",
    "view less": "View less",
    "share feedback": "Help us improve",
    Morning: "AM",
    "long morning": "Morning",
    Afternoon: "PM",
    "long afternoon": "Afternoon",
    Role: "Role",
    Collaborator: "Collaborator",
    Collaborators: "Collaborators",
    Legend: "Legend",
    Places: "Places",
    Place: "Place",
    Employee: "User",
    Employees: "Users",
    managers: "Managers",
    people: "people",
    organizer: "organizer",
    Email: "Email",
    Remotely: "Remotely",
    Or: "or",
    Total: "Total",
    Copy: "Copy",
    To: "To",
    Planned: "Planned",
    Resend: "Resend",
    here: "here",
    person: "person",
    persons: "persons",
    "Select all": "Select all",
    "Deselect all": "Deselect all",
    Group: "Group",
    groups: "Groups",
    "Places & groups": "Places & groups",
    Reinitialize: "Reinitialize",
    "Organisation chart": "Organisation chart",
    places: "places",
    seats: "seats",
    and: "and",
    apply: "apply",
    Object: "Object",
    Members: "Members",
    invitations: "invitations",
    Unauthorized: "Unauthorized",
    "text copied confirmation": "Text sucessfully copied to clipboard.",
    "no match": "No match",
    zone: "Zone",
    space: "Working space",
    "email example": "john.doe@acme.xyz",
    help: "Help Center",
    "changes-saved--success": "Changes have been saved.",
    "changes-saved--failure": "Error while saving.",
    name: "Name",
    hello: "Hello",
    others: "Others",
    "required field": "Required field",
    meeting: "meeting",
    "the meeting": "the meeting",
    "a meeting": "une réunion",
    event: "event",
    "an event": "the event",
    create: "Create",
    previous: "previous",
    next: "next",

    // STATUS
    Office: "Office",
    Home: "Home Office",
    "Out Of Contract": "Out Of Contract",
    Else: "Else",
    Off: "Off",
    "Not set": "Not set",
    office: "Office",
    home: "Home",
    ooc: "Out Of Contract",
    else: "Else",
    off: "Off",
    null: "Not set",
    "home-tooltip-title":
      "All authorized locations (your home, coworking space, coffeeshop...)",
    "office-tooltip-title": "Quite easy to understand this one... ",
    "else-tooltip-title":
      "Places where it is possible to work but under special conditions (training, seminar, work-study...) ",
    "off-tooltip-title":
      "Absent: Leave, time off, sickness. All work-related absences",
    "ooc-tooltip-title":
      "Out of contract: Days not worked by contract. Ex: holidays, part time etc...",
    "null-tooltip-title": "null-tooltip-title",
    "mistake-tooltip-title": "You did a mistake, erase and forget about it.",

    // MENU
    Services: "Services",
    Statistics: "Statistics",
    Favorites: "Favorites",
    Groups: "Groups",
    "my groups": "My groups",
    "join a group": "Join a group",
    Directory: "Directory",

    // PLANNING PAGE
    Planning: "Planning",
    "got it": "Got it 🙂",
    today: "today",
    filter: "Filter",
    filters: "Filters",
    "Manage my groups": "Manage my groups",
    "n members": "{count} members",
    "delete group": "Delete a group",
    "mail all members": "Send an email to all members",
    "copy the mailing list": "Copy the mailing list",
    "copy group name": "copy group name",
    "Your company status is set to inactive": `Your company status is set to inactive. You can't edit any slot. Please contact your admin.`,
    "Select your working mode": "Select your working mode",
    "desks available": "Desks available",
    capacity: "Capacity",
    "Update planning": "Update planning",
    "Advanced Options": "Advanced Options",
    repeat: "Repeat",
    "repeat every week": "Repeat every week",
    "repeat from this day until": "Repeat from this day until",
    "repeat every week until": "Repeat every week until",
    "Clear Selection": "Clear Selection",
    "many more": "{count} more ↓",
    "location is full": "This location is full 😬",
    "days remain unfilled": "{days} filled days",
    "Weekend included": "Weekend included",
    "Weekend not included": "Weekend not included",
    "Back to planning": "Back to planning",
    "recurrence summary":
      "Every {firstDate} {firstTime} until {lastDate} {lastTime}, {label} until {endDate}.",
    "small recurrence": "Every {firstDate}, {label} until {endDate}.",
    "every day recurrence": "Everyday {label} until {endDate}.",
    "only once": "Only once",
    "every week": "Every week until DD/MM/YY",
    "every day": "Every day until DD/MM/YY",
    "all day long": "All day long",
    "this day": "This day",
    "new recurrence": "Add new recurrence",
    recurrence: "Recurrence",
    "recurence end": "End of recurrence",
    "copy mailing list": "Copy mailing list",
    "see planning": "see planning",
    "Weekly repeated": "repeat weekly",
    "Weekly repeated until": "repeat weekly until",
    "Cancel recurrence": "Cancel recurrence",
    "nobody fits filtering": "Nobody fits your search criteria.",
    "choose week": "Choose a week",
    "send me a reminder": "Send me a reminder",
    oops: "Oops!",
    "You are too forward thinking for us":
      "You are too forward thinking for us!",
    "not yet authorized to fill your schedule for this date":
      "You are not yet authorized to fill your schedule for this date. Reservation slots will be open as of",
    "apply typical week": "Apply my typical week",
    "go edit typical week": "Edit my typical week",
    "go create typical week": "Create my typical week",
    "desk-booking-recurrence-warning":
      "Desk booking will be made until the {date}.",
    "desk-booking-recurrence-warning--tooltip":
      "You cannot book an office after the {date} accordingly to your company policy.",
    "category-children-list-title": "Places in  zone “{categoryName}”",
    "apply-typical-week-automatically--label": "Apply automatically",
    "apply-typical-week-automatically--tooltip":
      "We'll try to apply your typical week automatically.",
    "Loosing previous service bookings warning":
      "Be careful you are about to lose the following previous service bookings:",
    "Loosing previous guests warning":
      "Be careful you are about to cancel the following guests:",
    "Automatically fill my schedule with my typical week":
      "Automatically fill my schedule with my typical week",
    "Define your typical week": "Define your typical week",
    "show all teams": "show teams",
    "hide all teams": "hide teams",

    // WHO's THERE PAGE
    "Who's there ?": "Who's there ?",
    "one person found": "We found one person",
    "many persons found": "We found {count} people",
    "Apparently, nobody.": "Oops, apparently there's no one there 😬",
    "Copy email addresses": "Copy email addresses",
    "whos there locations empty":
      "You don't have any spaces configured yet. Therefore you can't use this feature.",
    "whos there location empty admin":
      "As an admin you can go to your admin page to create some working spaces.",
    "go to admin spaces": "Manage working spaces",
    "select a date": "Select a date:",

    // DASHBOARD
    Dashboard: "Dashboard",
    Timeframe: "Timeframe",
    "You need to have filled some slots before accessing your stats.":
      "You need to have filled some slots before accessing your stats.",
    "My team": "My team",
    "person's team": "{person}'s team",
    "Compare with": "Compare with",
    "Completion rate": "Completion rate",
    completed: "Completed",
    "not completed": "Not completed",
    occupied: "occupied",
    available: "Lucky you! It's available",
    "booked desks / total full capacity of the office":
      "Booked desks / Total office capacity",
    "Occupancy Rates": "Occupancy Rates",
    "not occupied": "not occupied",
    "Working modes habits": "Working modes habits",
    "Working modes habits per day": "Working modes habits per day",
    "per day": "Per day",
    "in percent": "In percent",
    "Concat data": "Concatenate data",
    "Concat data description":
      "Select the groups that you want to sum together to see the merged data",
    "Concat data location description":
      "Select the locations that you want to sum together to see the merged data",
    "Concatened data": "Concatenated data",

    // SETTINGS
    Settings: "Settings",
    "Click here to manage your groups": "Click here to manage your groups",
    "My groups": "My groups",
    "My manager": "My manager",
    "your manager": "your manager's email",
    "Change my password": "Change my password",
    "Password changed successfully.": "Password changed successfully.",
    "My preferences": "My preferences",
    "Personal preferences": "Personal preferences",
    Languages: "Languages",
    "your profile photo has been saved": "Your profile photo has been saved.",
    "your profile photo has been deleted":
      "Your profile photo has been deleted.",
    "Add a profile picture": "Add a profile picture",
    "Read more": "Read more",
    "profile picture": "Profile picture",

    // MANAGE MY GROUPS
    "Join or create your groups of interest":
      "Join or create your groups of interest",
    "create a group": "Create a group",
    "Create group": "Create group",
    "Delete group": "Delete group",
    "Group deleted": "Group deleted successfully",
    "group name already exist": "Sorry, but this group name already exists 😥",
    "group name": "Group name",
    "confirm group name": "Confirm group name",
    "group names must match": "Group names must match 😬",
    join: "Join",
    leave: "Leave",
    "members invitation": "Invite people",
    "enter a name or an email address": "Enter a name or an email address",
    "Leave group": "Do you really want to leave the group? 😔",
    "select group": "Select a group to join (be a barrel of laughs)",
    "search groups": "Search for a group",
    "remove user from group": "Remove the user from the group",
    "edit group": "Edit group",
    "update group name": "Edit group name",
    "no user in group": "This group does not contain any users.",
    "Are you sure you want to delete this group?":
      "Are you sure you want to delete this group?",
    "group created by at date": "Created by {name} on the {date}",
    "add user": "Add a user",
    "add user to team": "Add an other user to the team",
    "remove user from team": "Remove user from team",
    "confirmation remove user from team":
      "Are you SURE you want to remove {username} from this team: {teamname}?",
    "must invite at least 1 user to group":
      "You must invite at least 1 user in the group",
    "delete manager": "Delete manager",
    "delete manager confirmation":
      "Are you sure you want to remove the manager?",
    "save manager confirmation": "Are you sure you want to change the manager?",
    "previous manager": "Previous manager",
    "new manager": "New manager",

    // ADMIN
    Admin: "Admin",
    Locations: "Locations",
    Users: "Users",
    Message: "Message",
    Messages: "Messages",
    Billing: "Billing",
    Capacity: "Capacity",
    "admin explanation":
      "By making this user admin, he will have access to the same rights as you. He will then be able to set up the company (spaces, services and users), create/join groups and modify the schedules of other users.",
    "manager explanation":
      "The manager will be able to edit his own schedule, create/join a group, consult and edit the schedules of other users in his company.",
    "admin settings": "Admin settings",
    "manager team length": "There is {teamLength} members in his team",
    "no manager": "no manager",
    "remove a team member": "Remove a team member",
    "edit user profile": "Change the user profile",
    "search users": "Name, first name, email ...",
    "Send messages": "Send messages",

    // ADMIN SERVICES
    Service: "Service",
    "Company services": "Company services",
    "Manage units": "Manage units",
    "Add service": "Add service",
    "Add a service": "Add a service",
    "Edit the service": "Edit the service",
    "No service available message": "You don't have any service.",
    "Service location": "Service location",
    "Total capacity": "Service capacity",
    "confirm service deletion title": "Confirm service deletion",
    "confirm service deletion message":
      "You are about to delete the following service:",
    "Book a service": "Book a service",
    "You booked": "You booked",
    "The above equipment is available at the following spaces":
      "The above equipment is available at the following space:",
    "The equipments from above are available in the following space":
      "The equipments from above are available in the following space:",
    "Create a custom service": "Create a custom service",
    "Service name": "Service name",
    "Service description": "Service description",
    "Service icon": "Service icon",
    "Standalone service": "Standalone service",
    "Example: sport class, lunch restauration, meeting room, etc":
      "Example: sport class, lunch restauration, meeting room, etc",
    "AM/PM service": "AM/PM service",
    "mostly goes along with a desk reservation: extra screen, parking, etc":
      "Very often linked to your comfort at work (second screen, parking space, etc.)",

    "Create service": "Create service",
    "Delete service": "Delete service",
    "Create new service": "Create new service",
    "Add new unit": "Add new unit",
    "Add a unit into this service": "Add a unit into this service",
    "Unit name": "Unit name",
    "Unit description": "Unit description",
    "Delete unit": "Delete unit",
    "Create unit": "Create unit",
    "Active days": "Active days",
    "Default capacity": "Default capacity",
    "No locations associated to this service":
      "No locations associated to this service",
    "Add service location": "Add service location",
    "Link a service to a location": "Link a service to a location",
    "Add keyframe": "Add keyframe",
    "Yes, delete service": "Yes, delete service",
    "You're about to delete a whole service. This means that everything in this service will be deleted, along with all associated data. Are you sure ?":
      "You're about to delete a whole service. This means that everything in this service will be deleted, along with all associated data. Are you sure ?",
    "Lock to locations": "Lock to locations",
    "Users won't be able to book this service without attached locations":
      "Users won't be able to book this service without attached locations",
    "Computer hardware": "Computer hardware, ...",
    "Second screen": "Second screen, TV screen, ...",
    "A/V": "A/V hardware, ...",
    Parking: "Parking, ...",
    "Accessible facilities": "Accessible facilities, ...",
    Bike: "Bike, eBike, ...",
    "Self service": "Self service, ...",

    "Company Locations": "Company Locations",
    "Manage your company locations": "Manage your company locations",
    "Create location": "Create location",
    "Update location": "Update location",
    "location name": "Location name",
    "location name already exists":
      "Unless you are a double agent, this name already exists 😬",
    "shortname is too long": "It's too long to be a shortname!",
    "shortname already exists": "This shortname already exists",
    "Shortname (max 3 characters)": "Shortname (max 3 characters)",
    "Capacity can't be 0": "Capacity can't be 0",
    "Warning: changing capacity can affect stats":
      "Warning: changing capacity can affect stats",
    "Bookable capacity can't be greater than capaciy":
      "Bookable capacity can't be greater than capaciy",
    "Bookable Capacity": "Bookable Capacity",
    "Archive location": "Archive location",
    "archived locations won't be visible": `Archived locations won't be visible to users, but still
    appear in your dashboards.`,
    "Billing Portal": "Billing Portal",
    Subscription: "Subscription",
    "Your are subscribed to Offishall, and your status is ":
      "Your are subscribed to Offishall, and your status is ",
    "Your estimated cost for this billing period is":
      "Your estimated cost for this billing period is {price}",
    "After your free trial ends": `After your free trial ends on {date}, this plan will continue automatically.`,
    "You will be billed next on": `You will be billed next on {date}`,
    "To retrieve invoices and update payment information.": `To retrieve invoices and update payment information.`,
    "Your subscription is not active. Please choose one of the plans below.":
      "Your subscription is not active. Please choose one of the plans below.",
    "Company Messages": "Company Messages",
    "Manage your company messages": "Manage your company messages",
    "Create message": "Create message",
    "Update message": "Update message",
    "Delete message": "Delete message",
    "All (will be displayed in planning page)":
      "All (will be displayed in planning page)",
    "Display in office": "Display in office",
    "description (max. 144 characters)": "description (max. 144 characters)",
    "description is too long":
      "It is important to communicate, but this is a bit too long...",
    "message name": "message name",
    "message name already exists": "Message name already exists",
    "Add users": "Add users",
    "Configure user provisioning": "Configure user provisioning",
    "User provisioning (provider)": "User provisioning ({provider})",
    "Configure provider user provisioning with the following information:":
      "Configure {provider} user provisioning with the following information:",
    "Generate new token": "Generate new token",
    "Previously generated tokens are not shown here for security reasons.":
      "Previously generated tokens are not shown here for security reasons.",
    "Custom domain": "Custom domain",
    "SCIM provisioning": "SCIM provisioning",
    "Single sign-on (SAML):": "Single sign-on (SAML):",
    "Single sign-on (OIDC):": "Single sign-on (OIDC):",
    "Configure custom SAML SSO": "Configure custom SAML SSO",
    "Configure custom OIDC SSO": "Configure custom OIDC SSO",
    "Callback URL": "Callback URL",
    "Service Provider": "Service Provider",
    "Identity Provider": "Identity Provider",
    'Must start with "-----BEGIN CERTIFICATE-----", and end with "-----END CERTIFICATE-----"': `Must start with "-----BEGIN CERTIFICATE-----", and end with "-----END CERTIFICATE-----"`,
    "Enable provisioning": "Enable provisioning",
    "Enable provisioning with provider": "Enable provisioning with {provider}",
    "Enable OIDC SSO": "Enable OIDC SSO",
    "You can only configure one provider per organization.":
      "You can only configure one provider per organization.",
    "Please disable provider provisioning to proceed.":
      "Please disable {provider} provisioning to proceed.",
    "Save custom domain": "Save custom domain",
    "Save SAML configuration": "Save SAML configuration",
    "Save OIDC configuration": "Save OIDC configuration",
    manager: "manager",
    "base url": "Base URL",
    certificate: "Certificate",
    "entity ID": "Entity ID",
    "Single sign-on URL": "Single sign-on URL",
    "ACS URL": "ACS URL",
    "new line": "new line",
    "Add users from CSV file": "Add users from CSV file",
    "Manage your users accounts": "Manage your users accounts",
    "Update user": "Update user",
    "User is not registered.": "User is not registered.",
    "manager email": "Manager's email",
    admin: "admin",
    "disable user": "disable user",
    "Users successfully added. They will soon receive an email with a registration link.":
      "Users successfully added. They will soon receive an email with a registration link.",
    "invalid user":
      "We found {count} invalid user in your data. Please check that the email addresses have been written correctly.",
    "invalid users":
      "We found {count} invalid users in your data. Please check that the email addresses have been written correctly.",
    "Example of invalid user :": "Example of invalid user :",
    Previous: "Previous",
    Next: "Next",
    Import: "Import",
    "You're about to import user": "You're about to import {count} user.",
    "You're about to import users": "You're about to import {count} users.",
    "user have a manager": "{managers} user have a manager",
    "users have a manager": "{managers} users have a manager",
    "user have no manager": "{nomanager} have no manager.",
    "users have no manager": "{nomanager} have no manager.",
    "managers-or-not":
      "{managers} users have a manager. {nomanager} have no manager",
    "If a user with the same adress already exists in our database, it will be skipped.":
      "If a user with the same adress already exists in our database, it will be skipped.",
    "Upload CSV File": "Upload CSV File",
    "We will guide you step by step.": "We will guide you step by step.",
    "CSV File is empty": "CSV file is empty.",
    "Cannot read CSV File": "Cannot read CSV file.",
    "Does it have column names ?": "Does it have column names ?",
    "this is an extract": `This is an extract (first 10 lines) of your CSV. Does the first line looks line the others, or much more like "column names" ? `,
    Upload: "Upload",
    "Yes, first line is column names": "Yes, first line is column names",
    "No, first line is just like the others":
      "No, first line is just like the others",
    "Select email column": "Select email column",
    "Please click on the column corresponding to the user email address, and click on next.":
      "Please click on the column corresponding to the user email address, and click on next.",
    "Select manager column": "Select manager column",
    "Please click on the column corresponding to the user's manager email address, and click on next. If no such column, you can directly click on next.":
      "Please click on the column corresponding to the user's manager email address, and click on next. If no such column, you can directly click on next.",
    "Messages center": "Messages center",
    Labels: "Labels",
    "Can't unadmin yourself": "You can't unadmin yourself.",
    "Does the first row of your csv contain the column names?":
      "Does the first row of your csv contain the column names?",

    // Services
    "one person has booked this service": "One person has booked this service",
    "many have booked this service": "{count} persons have booked this service",
    "Apparently, nobody has booked this service":
      "Apparently, nobody has booked this service",
    "available services": "Available services",
    "at work": "Office",
    "Modify previous service bookings": "Booking modifications",

    // Users
    "Find users": "Find users",
    "View more results": "View more results",
    "View more users": "View more users",
    "No more result": "No more result",
    "No more user": "No more user",
    "No result": "No result",
    "Update Display Name": "Update Display Name",
    "Display Name": "Display Name",
    "Update Manager": "Update manager",
    "Search and select an existing user to be the manager":
      "Select your new manager",
    "Selected manager": "Selected manager",
    "Set as manager": "Set {name} as manager",
    "Search again": "Search again",
    "Remove current manager": "Remove current manager",
    "Remove manager": "Remove manager",
    "Update manager": "Update manager",
    "Add favorites": "Add to favorite",
    "Remove favorites": "Remove from favorite",
    "Add one favorite": "Add one favorite",
    "Already in favorites":
      "This item is already in your favorites, you will remove it.",
    "You are about to add": "You are about to add:",
    "favorite added confirmation": "Your favorite has been added! 👌",
    "Your document has been successfully imported":
      "Your document has been successfully imported",

    // Users : admin
    users: "users",
    User: "User",
    State: "State",
    Status: "Status",
    Registration: "Registration",
    "Active(status)": "Active",
    "Many active": "Active",
    "Not Active(status)": "Not-active",
    "Many Not Active(status)": "Not-active",
    Both: "Both",
    Disabled: "Disabled",
    Registered: "Registered",
    "Many registered": "Registered",
    "Not Registered": "Not Registered",
    "Many Not Registered": "Not-Registered",
    "User is registered": "User is registered",
    "User is not registered": "User is not registered",
    License: "License",
    PendingFulfillmentStart: "Pending Fulfillment",
    Subscribed: "Subscribed",
    Unsubscribed: "Unsubscribed",
    Suspended: "Suspended",
    "Registration email sent successfully":
      "Registration email sent successfully",
    "Resend registration email": "Resend registration email",
    "Registration emails sent successfully":
      "Registration emails sent successfully",
    "Resend registration emails": "Resend registration emails",
    "define manager": "Define a manager",
    "admin dashboard": "Admin Dashboard",
    "new collaborator": "Add a collaborator",
    "update user settings": "Update collaborator informations",

    "User is active": "User is active",
    Active: "Active",
    Disable: "Disable",
    "active users": "active users",
    "registered users": "registered users",
    "disabled users": "disabled users",
    "users in total": "users in total",
    "User is disabled": "User is disabled",
    "Registration status": "Registration status",
    "All users are registered": "Yay, all users are registered!",
    "are still not registered": "are still not registered",
    "You can see them by clicking on the Not registered option below, while keeping the search box empty":
      "You can see them by clicking on the  «Not registered» option below, while keeping the search box empty",
    "To resend a new registration email to them all at once, click on the button below":
      "To resend a new registration email to them all at once, click on this button:",
    "This can be empty": "This can be empty",
    "resend activation email invitation":
      "{name} does not have completed the registration process.",

    "User is an admin": "User is an admin",
    "Revoke admin privilege": "Revoke admin privilege",
    "User is not an admin": "User is not an admin",
    "Make admin": "Make admin",
    "Delete User": "Delete User",
    "delete user confirm": `Are you sure you want to delete this user and all its associated data ?
    This action is not reversible.`,
    "User has been deleted": "User has been deleted",
    "Yes, delete user": "Yes, delete user",
    "Export all results as CSV": "Export all results as CSV",
    "Export the list as CSV": "Export the list as CSV",
    "Manager email": "Manager email",
    "New user email": "New user email",

    // USERS DASHBOARD
    "Users Dashboard": "Users Dashboard",

    "Design System": "Design System",

    // COMPANY SETTINGS
    "Company Settings": "Company Settings",
    "Manage your company settings": "Manage your company settings",
    "company-settings-locale-title": "Prefered Application Language",
    "company-settings-locale-description":
      "Default language of the application. A user can still set another language.",
    "company-setting-group-display": "Display",
    "company-setting-group-default": "Other settings",
    "company-setting-group-notifications": "Notifications",
    "openEveryoneGroup-name": "Open #{company} group first",
    "openEveryoneGroup-description":
      "In the group menu, have the default group with all users of your company at the top of the list",
    "hideEveryoneGroup-name": "Hide #{company} group",
    "hideEveryoneGroup-description":
      "In the groups list, do not display the group with all users at all",
    "bypassDisplayName-name": "Use email address as display name",
    "bypassDisplayName-description":
      "Prevent users from changing their display name",
    "enableUserMutationForManagers-name":
      "Authorize managers to edit team plannings",
    "enableUserMutationForManagers-description":
      "Managers are authorized to edit users' planning in their teams",
    "limitSlotInput-name": "Limit planning input",
    "limitSlotInput-description":
      "Prevents users from setting their status beyond a specified number of weeks:",
    "weeklyReminder-name": "Weekly reminder",
    "weeklyReminder-description":
      "Only if they haven't set their next week, users get a friendly reminder on: ",
    "weeklyReminder-value-Monday": "Monday",
    "weeklyReminder-value-Tuesday": "Tuesday",
    "weeklyReminder-value-Wednesday": "Wednesday",
    "weeklyReminder-value-Thursday": "Thursday",
    "weeklyReminder-value-Friday": "Friday",
    "weeklyReminder-value-Saturday": "Saturday",
    "weeklyReminder-value-Sunday": "Sunday",
    "userCanCreateGroups-name": "Group creation open to everybody",
    "userCanCreateGroups-description": "All users can create groups",
    "blockPastSlots-name": "Block the modification of past slots",
    "blockPastSlots-description":
      "Prevent users from modifying slots from past weeks",
    "company-setting-anchor--notifications": "Notifications",
    "company-setting-anchor--display": "Display",
    "company-setting-anchor--default": "Others",
    "company-setting-anchor--documents": "Documents",

    // ADMIN/COMPANY SETTINGS/COMPANY FILES
    "corporate documents": "Corporate documents",

    "company_file--add_file": "Add document",
    "company_file--input_title": "Document title",
    "company_file--input_title_placeholder": "Give a title to your file",
    "company_file--deleted": "Your file has been deleted",
    "company_file--uploaded": "Your file has been uploaded",
    "company_file--confirm_deletion_title": "File deletion",
    "company_file--confirm_deletion_message":
      "You are about to delete the following file:",
    "company_file--empty_file_list":
      "No shared corporate documents for the moment.",

    // COMPANY LABELS
    "Company Labels": "Company Labels",
    "Choose working mode associated with label":
      "Choose working mode associated with label",
    "Add custom label for your company": "Add custom label for your company",
    "edit label": "Edit my labels",

    // ADMIN/HYBRID-POLICY
    "Hybrid work policy": "Hybrid work policy",
    "company-hybrid-rule-empty":
      "You have not yet defined a hybrid work policy.",
    "hybrid-rule--upsert-success": "Rule successfully added/updated",
    "hybrid-rule--delete-success": "Rule successfully deleted",
    "user-hybrid-rule-empty":
      "Your company does not have any user hybrid work rules yet.",
    "hybrid-rule-form--select-period-first":
      "You first need to choose a period.",
    "hybrid-rule-form-error--max-too-high": "too high value",
    "hybrid-rule-label--period": "Period",
    "hybrid-rule-label--target-name": "apply to",
    "hybrid-rule-label--group-toggle": "group rule",
    "hybrid-rule-label--applies-to-manager-toggle": "apply (also) to manager",
    "hybrid-rule-placeholder--group-name": "Group rule unique name",
    "hybrid-rule-label--max-slots": "Maximum remote working days",
    "hybrid-rule-label--restricted-days": "Recommended week days",
    "hybrid-rule-add-edit--manager": "Add/Edit a team rule",
    "hybrid-rule-add-edit--user": "Add/Edit a user rule",
    "hybrid-rule-organisation-chart-title": "Choose a manager or a user",
    "hybrid-rule-form--title-edit": "Edit a hybrid work rule",
    "hybrid-rule-form--title-add": "Create a new hybrid work rule",
    "hybrid-rule-form--targets-button": "Update {nbUsers} concerned user(s)",
    "hybrid-rule-list--targets-recap":
      "{nbTeams} team(s) and {nbUsers} user(s)",
    "target-picker-recap": "{nbTeams} team(s) and {nbUsers} user(s) selected",
    "select-all-group-members": "Select all members",
    "Compliance with hybrid rules": "Compliance with hybrid rules",
    "See score": "See score",
    respected: "Respected",
    "not respected": "Not respected",
    rule: "Rule",
    programmed: "Programmed",
    "week details": "Details of the week",
    remind: "Remind",
    "Details of hybrid compliance": "Details of hybrid compliance",
    "view scores": "View scores",
    "default rule": "Company rule",
    "Maximum in home office": "Maximum in home office",
    "Days of the week to be favored": "Recommended days of the week",
    "grouped rules": "Grouped rules",
    "user rules": "User rules",
    "team rules": "Team rules",
    "people do not respect": "people do not respect",
    "Hybrid rule": "Hybrid rule",
    "myteam-scores": "My team scores",
    "hybrid-rule-origin": "Hybrid rule origin",
    "are not allowed remotely": "are not allowed remotely",
    "is not allowed remotely": "is not allowed remotely",
    "user-hybrid-rule-current-score--title": "Remaining remote days",
    "Preferred day for home office": "Preferred day for home office",
    "Preferred days for home office": "Preferred days for home office",
    "remaining day of home office this week":
      "remaining day of home office this week",
    "remaining days of home office this week":
      "remaining days of home office this week",
    "You have no more days available for home office this week":
      "You have no more days available for home office this week",
    "nb day available in home office each month":
      "Each month, you have {nb} day allowed in home office.",
    "nb days available in home office each month":
      "Each month, you have {nb} days allowed in home office.",

    // USER SETTINGS
    "user-setting-group-display": "Display",
    "user-setting-group-notifications": "Notifications",
    "user-setting-group-notifications--description":
      "Manage the way you want to be notified",
    "user-setting-group-notifications--tooltip-nudge":
      "You can be nudge by a colleague",
    "user-setting-group-notifications--tooltip-weeklyReminder":
      "You can be automatically reminded by Offishall to fill your planning.",
    "user-setting-group-notifications--tooltip-teamDayInvitation":
      "You can be notified when your manager creates a team day.",
    "user-setting-group-notifications--tooltip-userSubscriptions":
      "You can be notified when a user who you subscribe to has a planning change.",
    "user-settings-subscriptions-empty-message":
      "You haven't subscribe to any user yet.",
    Subscribe: "Subscribe",
    "user-setting-group-default": "Other settings",
    "user-setting-group-personal": "Personal informations",
    "userShowWeekends-name": "Show Weekends",
    "userShowWeekends-description":
      "In the planning page, display saturdays and sundays",
    "user guide": "User guide",
    cgu: "Terms and conditions of use",
    "simple cgu": "TCU",
    "privacy policy": "Privacy policy",
    "manage my team": "Manage team",
    "manage user team": "Manage {user} team",
    "edit personal information": "Edit my personal information",
    "first last name": "Last name First name",
    "personal information": "Personal information",
    "notifications & display": "Notifications & display",
    "news & feedback": "News & feedbacks",
    "report a claim": "Report a claim",
    "user-setting-planning-template--title": "Typical week",
    "user-setting-planning-template--go_to_link": "Update your typical week",
    "user-subscription-dialog--title": "Manage user subscriptions",
    "nudge explanation":
      "A colleague informs you that your schedule is not filled",
    "weekly-reminder-explanation":
      "Offishall automatically informs you when your schedule is not filled",
    "teamDayInvitation explanation": "Your manager creates a team day",
    "userSubscriptions explanation":
      "A colleague to whom you have subscribed changes his schedule",
    "my account": "My account",
    "edit my user name": "Change my user name ",
    "edit my password": "Change my password ",

    // JOINING GROUP
    "Join group": "Join group",
    "user added to group": "User added to group",
    "user already in group": "User already in group",

    // USER PAGE
    "user's planning": "{name}'s planning",
    "user is disabled": "user is disabled",
    "user is active": "User is active",
    "user is admin": "User is admin",
    "user is not admin": "user is not admin",
    "admin selected user actions": "Manage selected user",
    "admin selected user status": "Selected user status",
    "admin selected user permissions":
      "As an admin you can make changes in {name}'s planning and status. 👇",
    "manager selected user permissions":
      "As a manager you can make changes in {name}'s planning.",
    "search manager users": "Search a manager among users",
    "user team": "{name}'s team",
    "no user": "No users are present this week",
    "no user simple": "No users are present.",
    "user-subscription--on": "alerts activated",
    "user-subscription--off": "alerts deactivated",
    "add user subscription confirmation":
      "One coworker has been added to your subscription list.",
    "remove user subscription confirmation":
      "One coworker has been removed from your subscription list.",
    "activate alerts": "Activate alerts",
    "disable alerts": "Disable alerts",
    "add to favorites": "Add to favorites",
    "remove from favorites": "Remove from favorites",

    // LOCATIONS
    "search spaces": "Search spaces",
    "working spaces": "Working spaces",
    "selected locations": "Selected zones and spaces",
    "Your organisation allows for desk booking until the":
      "Your organisation allows for desk booking until the",

    // EVENTS PAGE
    Events: "Events",
    "create event": "Create an event",
    "create the event": "Create the event",
    "edit event": "Edit an event",
    "edit the event": "Edit the event",
    "delete this event": "Delete this event",
    "event name": "Event name",
    at: "at",
    ongoing: "ongoing",
    "event description": "Event description",
    "event location": "Event location",
    "example event location": "101 Independence Avenue",
    "event capacity": "Event capacity",
    "event booking message": "{seats} seats still remaining",
    "add event capacity": "Add event capacity",
    "event guests": "Event guest list",
    "event deadline": "Subscription deadline",
    "event deadline explanation":
      "Date at which you expect an answer about attendance",
    "event deadline message": "You have until the {date} to respond.",
    "delete event confirmation message": "You are about to delete an event.",
    "attending event": "Attending",
    "not attending event": "Not attending",
    "share event": "Share",
    "event media": "Event picture",
    "upload media title": "Add a picture/image",
    "upload media after title": "or drag and drop",
    "event link copied": "The event link has been pasted to the clipboard.",
    "whole day event": "All day",
    "add event description": "Add a description",
    "event privacy": "Private event",
    "event privacy explanation":
      "Event shared with the guests only or to the whole organisation",
    "ending date": "Ending date",
    "already attending": "Already attending",
    "send attendings reminder": "Send a reminder to all",
    "attending users": "Attending users",
    "view attending users": "View attending users",
    attending: "Attending",
    attendings: "Attending",
    pending: "Pending",
    "not attending": "Not attending",
    "not-attending": "Not attending",
    "no event create yet": "You have no Offishall events.",
    details: "Details",
    "remaining places": "remaining places",
    "search groups or users": "Search for groups or users",
    "start search/select event groups/users":
      "Select or search groups/users into the list above.",
    Guests: "Guests",
    Guest: "Guest",
    "received on": "received on",
    "you have been invited": "You have been invited to an event! 🎉",
    "I am going": "I'm going!",
    "I am not going": "I'm not going",
    "not participating in this event":
      "You are not participating in this event",
    Today: "Today",
    "This week": "This week",
    "Next week": "Next week",
    Past: "Past",
    Invited: "Invited",
    "your status has been updated for this event":
      "Your status has been updated for this event",
    "Starting date": "Starting date",
    "Starting time": "Starting time",
    "Ending date": "Ending date",
    "Ending time": "Ending time",
    "show groups": "show groups",
    "long attending event": "I am attending this event ",
    "long not attending event": "I am not attending to this event ",
    "your participation": "Your participation",
    "created by": "created by",
    "you have no upcoming events": "You have no upcoming events.",
    "event address": "Event address",
    "title of the meeting": "Title of the meeting",
    "event name example": "Afterwork, team building, celebration, ...",
    "meeting title example": "ex : team meeting",
    "meeting location": "Location of the meeting",
    "already booked": "already booked",

    // NEW UX TRANSLATIONS
    "Nobody's here this week": "Nobody's here this week",
    "No one in this group": "No one in this group",
    "No one else in this group": "No one else in this group",
    "You have not selected any user to be part of your favorite ones.":
      "No one is your favourite",
    "Groups, Locations, People": "People, Groups, Locations",
    Spaces: "Spaces",
    "Choose a date": "Choose a date",
    "Search for a space": "Search for a space",
    "no user at": "no user at {location} from {from} to {to}",
    "Your working spaces": "Your working spaces",
    "Go to space planning": "Go to space planning",
    "Full capacity is": "Full capacity is",
    "but temporary reduced to": "but temporary reduced to",
    "This space is not available": "This space is not available",
    "This space is available to reservation":
      "This space is available to reservation",
    "Allocated space": "Allocated space",
    "Allocated space to":
      "Space allocated to {groups} groups and {users} users",
    "Allocated space update": "Update List",
    Inbox: "Inbox",
    Archived: "Archived",
    archived: "archived",
    "Delete confirmation": "Delete confirmation",
    "delete message": "Delete message",
    "no workspace": "No workspace",
    "Are you sure you want to delete this message?":
      "Are you sure you want to delete definitively this message?",
    "Move to inbox": "Restore",
    "Move to archives": "Archive",
    "Favorite users": "Favorite users",
    "no favorites at this time": "You have no favorites at this time.",
    "delete zone and its content":
      "Warning, you are about to delete {name} and its content.",
    "zones to be deleted": "Content which will also be deleted",
    "spaces to be deleted": "spaces to be deleted",
    "asking confirmation": "Are you sure? 🧐",
    "search for zone or space": "Search for a zone or a space",
    "you have no favorites users yet - add to favorites":
      "You don't have any favorites users yet. Don't hesitate to add some to find them more easily.",

    "Write a message": "Write a message",
    "Edit a message": "Edit a message",

    subject: "Subject",
    body: "Body",
    operations: "Operations",

    "empty destination": "empty destination",
    "empty subject": "empty subject",
    "empty body": "empty body",
    "Update a message": "Update a message",
    "Add a message": "Add a message",
    "Search for recipients": "Search for recipients",
    "send to everyone": "Send to everyone",
    "Message subject": "Message subject",
    "Message body": "Message body",
    Recipients: "Recipients",
    Schedule: "Schedule",
    "Now!": "Now!",
    Later: "Later",
    Recurrent: "Recurrent",
    "The message will be sent right away !":
      "The message will be sent right away !",
    send: "Send",
    update: "Update",
    "The message will be sent on:": "The message will be sent on:",
    "Create scheduled message": "Create scheduled message",
    "Create recurrent message": "Create recurrent message",
    until: "until",
    Frequency: "Frequency",
    Once: "Once",
    Weekly: "Weekly",
    Daily: "Daily",
    Monthly: "Monthly",
    "The message will be sent when you choose to.":
      "The message will be sent when you choose to.",

    "the message will be sent from to":
      "the message will be sent from {from} to {to}",

    "Add a zone": "Add a zone",
    "Add a working space": "Add a space",
    "Update a zone": "Update a zone",
    "parent zone": "Parent zone",
    "no parent zone": "no parent zone",
    "Zone name": "Zone name",
    "no zone": "no zone",
    "Update a working space": "Update a space",
    "If you remove this space from its parent zone, it will be moved up to the root with its content.":
      "If you remove this space from its parent zone, it will be moved up to the root with its content.",

    "zone unchanged": "zone unchanged",
    "name too short": "name too short",
    "space unchanged": "space unchanged",
    "negative capacity": "negative capacity",
    "bookable out of range": "bookable out of range",
    "Full capacity": "Full capacity",
    "Temporary reduce capacity": "Temporary reduce capacity",

    "Space name": "Space name",

    "including yourself": "including yourself",

    "Try out our new UX !": "Try out our new UX !",
    "try new ux content":
      "You're about to try our new UX. You can always switch back from your settings in the new UX.",
    "Yes, let s give it a try !": "Yes, let's give it a try !",
    "No, stay on this ux": "No, stay on this ux",

    messaging: "Messaging",
    "messages send": "Messages send",
    "no message send yet": "You have not yet sent a message",

    "create new": "Create new",

    // CSV IMPORTER
    "recap title": "You're all set",
    "recap info":
      "Please check the information below. If they are correct, click on the import button to start the import. This can take several minutes.",
    "select manager column title": "Select manager column",
    "select manager column info":
      "Please click on the column corresponding to the user's manager email address, and click on next.",
    "if this column does not exist, you can click directly on next":
      "If no such column, you can directly click on next.",
    "select email column title": "Select email column",
    "select email column info":
      "Please click on the column corresponding to the user email address, and click on next.",
    "first line checker title": "Does it have column names ?",
    "first line checker info": "this is an extract",
    "file upload title": "Upload CSV File",
    "file upload info": "We will guide you step by step.",
    "manual addition": "Manual addition",
    okta: "Okta",
    CSV: "CSV",
    "add new user": "Add an other user",
    "use comma separator instead of semicolon":
      "use comma separator instead of semicolon",
    "chosen separator : comma (,)": "chosen separator : comma (,)",
    "chosen separator : semicolon (;)": "chosen separator : semicolon (;)",
    "Use this email": "Use this email",
    "Pick this perfect match": "Pick this perfect match",
    "what the csv must contain": `Your file must contain only these two columns "user's email" and "manager's email".`,
    "csv can only contain 2 columns": "The csv can only contain 2 columns",
    "drop a csv file or browse your documents":
      "Drop a .csv file or Browse your documents.",
    "download a CSV template": "Download a CSV template",
    "change my file": "Change my file",
    "user email": "User's email",
    "choose separator": "Choose the appropriate separator for your csv",
    "check if each value corresponds to a data type":
      "Check if each value corresponds to the good data type (e-mail,  e-mail manager)",
    "data requested by offishall": "Data requested by Offishall",
    "file data": "File data",
    data: "Data",
    "Name / Last Name": "Name / Last Name",

    // USERS DASHBOARD
    "office days": "Office days",
    "home days": "Home days",
    "out of contract days": "Out of contract days",
    "else days": "Else days",
    "off days": "Off days",

    // INTEGRATIONS
    Integrations: "Integrations",
    "writeSlotsEvents-toggle": "Write working modes into your calendar",
    "writeEvents-toggle": "Write events into your calendar",
    "link offishall outlook": "Offishall is linked to your Microsoft Outlook.",
    "link offishall google": "Offishall is linked to your Google account.",
    "revoke access": "Revoke access",
    "cannot setup integration": "Cannot setup {name} integration",
    Link: "this link",
    "Client ID": "Client ID",
    "Client Secret": "Client Secret",
    "Issuer URL": "Issuer URL",
    "Refresh Token": "Refresh Token",
    "Token Endpoint": "Token Endpoint",
    "Authorization Endpoint": "Authorization Endpoint",
    "update values": "Update values",
    "connect to": "Connect to",
    // GOOGLE
    "Google Calendar": "Google Calendar",
    "google improve offishall experience":
      "Show and share your presence directly from your Google Calendar",
    "refresh access": "Refresh Access",
    "autorize offishall access google account":
      "Authorize Offishall to access your Google account",
    // Outlook
    "Outlook Calendar": "Outlook Calendar",
    "outlook improve offishall experience":
      "Show and share your presence directly from your Outlook Calendar",
    "autorize offishall access outlook account":
      "Authorize Offishall to access your Microsoft Outlook account",
    // SLACK
    "link offishall slack": "Offishall is linked to your Slack account.",
    "slack improve offishall experience":
      "Show your presence in Slack for your colleagues (emoji next to your name)",
    "authorize offishall access slack account":
      "Allow Offishall access to your slack account",
    // KELIO
    "offishall linked to kelio": "Offishall is linked to your Kelio account.",
    "offishall not linked to kelio":
      "Please contact us to set up the connection between Offishall and your Kelio account.",
    "find documentation": "You can find some documentation",
    "know how to retrieve the needed information":
      "to know how to retrieve the needed information.",
    // LUCCA
    "offishall linked to lucca": "Offishall is linked to your Lucca account.",
    "lucca instance name": "Your Lucca instance name is {extranalId}.",
    "offishall not linked to lucca":
      "Offishall can be connected to your Lucca account to automatically fetch users days off.",
    "connect to lucca": "Connect to Lucca",
    "company instance name at Lucca": "Your company's instance name at Lucca",
    // MICROSOFT
    "offishall linked to microsoft":
      "Offishall is linked to your Microsoft company account.",
    "your microsoft tenant id": "Your Microsoft tenant id is {externalId}",
    "offishall not linked to microsoft":
      "Offishall can be connected to your Microsoft company account to improve Offishall experience (reading and writing Calendar events, for instance)",
    "authorize offishall access microsoft":
      "Authorize Offishall to access your Microsoft enterprise account",
    "error while connecting to Microsoft":
      "Error while connecting to Microsoft",
    // MICROSOFT TEAMS
    "offishall linked to microsoft teams":
      "Offishall is linked to your Microsoft Teams organization.",
    "offishall not linked to microsoft teams":
      "Offishall planning can be used within the Microsoft Teams app.",
    "authorize offishall access microsoft teams":
      "Authorize Offishall to access your Microsoft Teams organization",
    "private chat members": "Private chat members",
    "team members": "Team members",
    // WORKDAY
    "offishall linked to workday":
      "Offishall is linked to your Workday account.",
    "your workday tenant id": "Your Workday client ID is {externalId}",
    "offishall not linked to workday":
      "Offishall can be connected to your Wordkay account to automatically fetch users days off.",
    "connect to workday": "Connect to Workday",
    "Workday REST API Endpoint": "Workday REST API Endpoint",

    // FLOOR PLAN
    "Floor plans": "Floor plans",
    "floor-plans-short-title": "Floor plans",
    "upload floorPlan media title": "Upload image",
    "replace floorPlan media title": "Replace image",
    "your floor plan image has been saved":
      "Your floor plan image has been saved",
    "your floor plan image has been deleted":
      "Your floor plan image has been deleted",
    "your floor areas have been saved": "Your floor areas have been saved",
    "ask-delete-areas-message":
      "Do you want to delete the areas you have drawn on the floor plan?",
    Keep: "Keep",
    "select location on left panel":
      "Select a location on the left panel to display its floor plan.",
    "select location on left panel, or add floor plan":
      "Select a location on the left panel to display its floor plan or add one with the + icon.",
    "floor plan only works within zone":
      "You first need to create at least one zone to offer the floor plan experience to your teams. Easily create a zone in spaces/zones admin page.",
    "go create some zone": "Go to admin",
    "delete-plan": "delete floor plan",
    "delete-plan-title": "Delete floor plan",
    "delete-plan-confirm-1":
      "Warning! You are about to delete the floor plan for {name} and its content.",
    "delete-plan-confirm-2":
      "Content of the zone {name} that will also be deleted:",
    "delete-plan-confirm-simple":
      "You are about to delete the floor plan for {name}.",
    "no floor plan": "No floor plan",
    "cant-add-floorPlan-with-children":
      "You can't add a floor plan to this zone because there is already one attached to a child zone. Please remove the floor plan from the child zone first.",
    "see floor plan": "See plan",
    "location has no area": "{name} does not appear on any plan.",

    // NOTIFICATIONS
    Notifications: "Notifications",
    "delete notification": "Delete notification",
    "Are you sure you want to delete this notification?":
      "Are you sure you want to delete definitively this notification?",

    // TEAMS
    "Authorize access": "Authorize access",
    "The Offishall app requires access to your Teams organization.":
      "The Offishall app requires access to your Teams organization.",
    "Your tab is ready!": "Your tab is ready!",
    "Click save to return to the app.": "Click save to return to the app.",
    "Sign off from Offishall": "Sign off from Offishall",
    "Your Teams organization is not configured to use the Offishall app.":
      "Your Teams organization is not configured to use the Offishall app.",
    "Sign-up": "Sign-up",
    "or make sure the Offishall - MS Teams integration is configured correctly.":
      "or make sure the Offishall - MS Teams integration is configured correctly.",
    "Go to Offishall's integrations configuration page.":
      "Go to Offishall's integrations configuration page.",
    Retry: "Retry",

    // INTERACTIONS MENU
    "export to csv": "Export to csv",
    "copy emails address": "Copy email addresses",
    "send email": "Send an email",
    "Emails copied": "The emails have been copied.",
    // ADVANCED SEARCH
    "Advanced Search": "Advanced Search",
    "Advanced Search Results": "Advanced Search Results",
    "please configure new advanced search":
      "Please set up a new advanced search.",
    "new search": "New search",

    "nudge sent": "Nudge sent",
    "send reminder message": "Send a reminder message",
    "send reminder by email to user":
      "Sends a nice email asking {name} to fill his.her planning",
    "reminder has been saved": "Reminder has been saved",

    // Custom Errors
    "generic error": "An error occured",
    "too far in the future":
      "You can't set your working status beyond {numWeeks} weeks in the future",
    "slot is full":
      "Couldn't book this workspace on some of the selected dates because it is already full",
    "way too far in the future":
      "We can't set your working status that far in the future",
    "cannot update slot in the past": "You can't update a slot in the past",
    "equipment is full":
      "Couldn't book this service at {date} because it is already full",
    "valid email": "Please enter a valid email",
    "sad oops": "Oops :(",
    "servers under heavy pressure":
      "It seems that our servers are under heavy pressure, please try again in a few minutes.",
    "invalid password": "Invalid password",
    "wrong old password provided": "Old password is not correct",
    "password too many attempts":
      "Too many attempts, contact your administrator",
    "email invalid": "The email is not valid",

    // ONBOARDING
    "fictitious users will be displayed":
      "Fictitious users will be displayed in order to fully enjoy the Offishall experience!",
    "fictitious spaces will be displayed":
      "Fictitious spaces will be displayed in order to fully enjoy the Offishall experience!",
    "welcome to Offishall": "Welcome to Offishall",
    "configure platform in 2 steps":
      "Let's configure your platform in 2 steps.",
    "add your colleagues": "Add your colleagues",
    "invite your colleagues": "Invite your collaborators",
    "add their email addresses and receive invitation to join Offishall":
      "I add their email addresses, they will receive an invitation to join Offishall",
    "add fictitious users": "Add fictitious users",
    "fake users creation to test in the best conditions":
      "We create for you fake users to test in the best conditions",
    "invite by email": "Invite by email",
    "create a workspace": "Create a workspace",
    "add your workspace": "Add your workspace",
    "specify the name and capacity of my workspace":
      "I specify the name and capacity of my workplace",
    "add dummy offices": "Add dummy offices",
    "create fake workspaces to test in the best conditions":
      "We create for you fake workspaces to test in the best conditions",
    "workspace name": "Name of the workspace",
    "Congratulations you have created your Offishall environment":
      "Congratulations! You have created your Offishall environment!",
    "explore the platform as you want":
      "You can now explore the platform as you wish.",
    "launch offishall": "Launch Offishall",
    "Filling out your planning": "Filling out your planning",
    "Filling out your planning explanation part 1":
      "Organize your week with a single click based on your workplace (office, remote, other), your colleagues and your own availability.",
    "Filling out your planning explanation part 2":
      "Get a global view of who's where, when.",
    "Filling out your planning explanation part 3":
      "They need you like you need them 😉",
    "email already present": "Email already present in the list",
    continue: "Continue",
    "workspace name placeholder":
      "e.g. Paris office, Marketing area, HR department",

    // USER ONBOARDING
    "typical week": "Typical week",
    "typical week stats": "Typical week statistics",
    "stats per user": "Statistics per user",
    "stats per team": "Statistics per team",
    "tell us more about you": "Tell us more about you",
    "your name": "Your name",
    "your email": "Your email",

    "congratulations you have created your profile":
      "Congratulations! You have created your Offishall profile!",
    "We hope you’ll enjoy the new ways of collaborating":
      "We hope you’ll enjoy the new ways of collaborating.",
    "typical week description":
      "Fill in your typical work week, you will then be able to use it to fill out the platform in one click.",

    "how to do it": "How to do it?",
    "use drag and drop":
      "Use drag & drop to select the days of the week and indicate where you want to work.",

    "cant add in typical week": "Can't add in typical week",

    "user-onboarding-welcome-title": "Welcome to Offishall",
    "user-onboarding-welcome-p-1":
      "The favorite planning for those who like to be in the office.",
    "user-onboarding-welcome-p-2":
      "We will be validating a couple of information for you to connect hassle free to Offishall. ",
    "user-onboarding-welcome-p-3":
      "We will also ask you to declare your presence for this week, where you are working from. ",
    "user-onboarding-welcome-p-4": "Just follow our lead 😃",

    "user-onboarding-manager-title": "Confirm your manager",
    "user-onboarding-manager-p-1":
      "We need your manager's name to be able to show you the presence of the people of the team you belong to.",
    "user-onboarding-manager-p-2":
      "If you don't have a manager, just leave it blank.",

    "user-onboarding-planning-title": "How is your week looking?",
    "user-onboarding-planning-p-1":
      "Tell your colleague where you were, where you will be working from for the current week.",
    "user-onboarding-planning-p-2":
      "Once in Offishall, you'll be able to modify for the upcoming weeks.",
    "user-onboarding-planning-choose-location":
      "Select the actual office where you'd like to work from.",
    "user-typical-week-update-slots":
      "Well done ! You can now use it to plan your week",
    "user-typical-week-update-slots--invalid":
      "Your typical week is not complete. Fill all days to use it in your planning ☝️.",
    "user-typical-week-update-slots--no-offices":
      "⚠️ Your company do not have any bookable offices for now. Wait for an admin to create some.",

    "user-onboarding-password-title": "Choose your password",
    "user-onboarding-password-p-1":
      "Discover what the full offishall experience looks like.",
    "user-onboarding-password-p-2":
      "You can complete your planning and see your colleagues locations and statistics.",

    // Toasts
    "message has been well planned": "Your message has been well planned 👌",
    "not allowed to fill your schedule for this period":
      "Ohoh, you are not allowed to fill your schedule for this period.",
    "new notification is waiting": "A new notification is waiting for you!",
    "new space confirmation":
      "The new space has been successfully registered! 👌",
    "new zone confirmation":
      "The new area has been successfully registered! 👌",
    "edition confirmation": "The change has been duly noted",
    "add new user confirmation":
      "The addition was a success! 🤩 A registration email will be sent soon.",
    "confirmation add service when filling out the planning":
      "The addition of service(s) has been duly noted.",
    "confirmation add a service": "The service has been added successfully! 👌",
    "confirmation delete a service":
      "The service has been deleted successfully! 👌",
    "confirmation update a service":
      "The service has been updated successfully! 👌",
    "confirmation copy email": "The emails have been copied",
    "confirmation connexion to integration":
      "Connexion to {name} successfull 🎉",
    "event pending user nudge": "{number} pending user has been nudged !",
    "event pending users nudge": "{number} pending users have been nudged !",
    "your event has been created": "Your event has been created ! 🎉",
    "your event has been updated": "Your event has been modified!",
    "your event has been deleted": "Your event has been deleted.",
    "rule has been created": "Your rule has been created ! 🎉",
    "rule has been deleted": "Your rule has been deleted..",

    // STATS
    "occupation rate": "Occupation rate",
    "completion rate": "Completion rate",
    "working mode": "Working mode",
    "week days": "Week days",
    "team stats": "Team stats (manager)",
    "admin user stats": "User stats (admin)",
    "admin teams stats": "Teams Stats (admin)",
    "admin groups stats": "Groups Stats (admin)",
    MONTH: "This month",
    QUARTER: "This quarter",
    SEMESTER: "This semester",
    YEAR: "This year",
    personalized: "Custom period",
    "includes week-ends": "Include week-ends",
    "WEEK-MONDAY": "This week",
    "stats mean": "Average",
    "stats over-time": "Over time",
    "stats per-day": "Per day",
    filled: "Filled",
    "not filled": "Not filled",
    percent: "Percent %",
    "All locations": "All locations",
    "booked seats": "booked seats",
    "occupied seats": "occupied seats",
    "not occupied seats": "not occupied seats",

    // Users at filters
    "users_at_filter--empty-planning": "Empty plannings",
    "users_at_filter--not-empty-planning": "Not empty plannings",
    "users_at_filter--available": "Available",
    "users_at_filter--office": "Office",
    "users_at_filter--home": "Home office",
    "users_at_filter--else": "Else",
    "users_at_filter--off": "Off",

    // Updators
    "update available": "Update available 🎉",
    "mandatory update title": "You need to update your Offishall",
    "mandatory update description":
      "We've made some changes to the Offishall App which require a mandatory update. Don't worry, it won't take a long time !",
    "mandatory update button": "Click here to update",

    // Team day
    Team: "Team",
    "team day": "Team day",
    "team days": "Team days",
    "No upcoming team day": "No upcoming team day",
    "rename team": "Rename your team",
    "create new team day": "Create a new team day",
    "selection of attendees": " Selection of attendees",
    "list of attendees": "List of attendees",
    "remove team day title": "Suppression of a team day",
    "you will delete this team day":
      "You will delete the team day on the {date}",
    "remove team day confirmation": "The team day has been removed.",
    "creation team day confirmation": "The team day has been created!",
    "member are unavailable on": "member are unavailable on",
    "members are unavailable on": "members are unavailable on",
    "please select a location": "Please, select a location",
    "select a location": "Select a location",
    "No room in the selected location": "No room in the selected location",
    "your entire team is available on":
      "Your entire team is available on {date}",
    "you are about to set a team day on date for nb users at location locationName":
      "you are about to set a team day on {date} for {nbUsers} people at location {locationName}",
    "last attendance at the office X days ago":
      "Last attendance at the office {nbDays} days ago.",
    "never came to the office": "never came to the office.",
    "come to the office today": "came to the office today.",
    "have not seen userName for nbDays days":
      "You haven't seen {userName} for {nbDays} days.",
    "confirm team day deletion title":
      "Confirm the suppression of the team day",
    "confirm team day deletion message":
      "You are about to delete the team day from {date}.",
    "details of the participants": "Details of the participants",
    "team stats simple": "Team statistics",
    "members explanation": "Useful data about your team's workplace usage",
    "team-day-members-available": "{count} available coworkers",
    "team-day-members-not-available": "{count} not available coworkers",
    "new-team-day-recap": "Your new team day",
    "X days ago": "{nb} days ago",
    "last meeting": "Last meeting",
    "last attendance": "Last attendance",
    "You are going to modify the planning of":
      "You are going to modify the planning of",

    // Guests
    "nb guests": "Number of guests",
    "add guests": "Add guests",
    "guest list": "Guest list",
    "guests outside of offishall": "Guests outside of Offishall",
    "write here emails from your guests":
      "Write here the emails of your guests",
    "guest outside Offishall": "guest outside Offishall",
    "guests outside Offishall": "guests outside Offishall",
    emails: "Emails",
    description: "Description",
    "guest description placeholder": "Address, code, etc...",
    "sent description in email message":
      "This description will be visible in the email sent to your guests",
    "guests confirmation toast":
      "An invitation has just been sent with the details of the booked desk 👌",
    "show guests": "Show guests",

    // 404
    "this page does not exist": "This page does not exist",
    "break the internet":
      "You didn't break the internet, but we can't find what you are looking for.",

    //USER ONBOARDING
    //SLOTS EDITOR
    "user_onboarding--slots_editor--title": "Elaborate your week of choice",
    "user_onboarding--manager--subtitle--confirm":
      "If the following manager is not the correct one, you still can remove it and/or choose a new one.",
    "user_onboarding--manager--subtitle--choose":
      "You can start typing your manager name or email to find her/him.",

    // Suggestions
    suggestions: "Suggestions",
    "locationSuggestion::locationId provided":
      "You have previously selected this space",
    "locationSuggestion::max people in group ":
      "{nbMembers} members {selectedGroup} have chosen this place on this date",
    "locationSuggestion::max people in group singular":
      "{nbMembers} member {selectedGroup} has chosen this place on this date",
    "of your team": "of your team",
    "of your manager team": "of your manager team",
    "of your favorites": "of your favorite users",
    "of the selected group": "of the selected group",

    // FORM
    "invalid email": "Invalid email",
    "invalid confirm password": "Confirm password does not match",
    "invalid form": "Invalid form",

    // BULK CHANGE STATUS
    "special days": "Special days",
    "day title": "Title of the day",
    "who is affected": "Who is affected?",
    "what is the status applied": "What is the status to be applied ?",
    "new rule": "Creating a new special day",
    "delete a rule": "Delete a rule",
    "delete that rule": "You are about to delete the next rule:",
    "this change is applied over a period":
      "This change is applied over a period ?",
    "apply a status to several members of the company":
      "Apply a status to several members of the company",
    "bulk change status example": "Independence Day",
    everyone: "Everyone",
    myteam: "My team",
    "notify this change": "Notify the people affected by this change?",
    "attributed to": "Attributed to",
    "title of the day": "Title of the day",
    untitled: "Untitled",
    entities: "entities",
    "no special days": "You don't have any special day at the moment",
    "select a team, group, collaborator": "Select a team, group, collaborator",

    // HOME
    Reception: "Home",
    "presence of the day": "Presence of the day",
    "event of the day": "Event of the day",
    "events of the day": "Events of the day",
    "go to the planning": "Go to the planning",
    "the whole company": "The whole company",
    "please fill out your planning": "Please fill out your planning",
    "you are here": "you are here :",
    "you are": "you are",
    "you are in": "you are on",
    "Today the": "Today,",
    "search for a group or a team": "Search for a group or a team",
    "select who is affected": "Select who is affected",
    "X elems selected": "{nb} selected elements",
    "are affected": "Are affected",

    // OFFISHALL CARE
    "psychological follow-up title": "Psychological follow-up",
    "psychological follow-up subtitle":
      "Isolation, burn-out and depression are becoming more and more common these days. We make your mental well-being a priority.",
    "Nutri coach title": "Nutri coach",
    "Nutri coach subtitle":
      "Need to get back into shape following the sedentary nature of your work?",
    "childcare title": "Childcare",
    "childcare subtitle":
      "The family life of the various employees is evolving and needs to be adapted accordingly. Home care for children under 15 years of age is implemented.",

    // DAILY VIEW
    "weekend planning text": "No one is present today. Enjoy the weekend!",

    // MEETING ROOMS
    "Meeting rooms": "Meeting rooms",
    "search meeting room": "Search room name",
    "meeting-room-event-form--input-attendees": "Add guests",
    "guest-email-picker--dialog": "Guest invitations",
    "guest-picker-form--input-email": "Guest email address",
    "meeting-room-event-form--guest-nb-part1": "This event concerns",
    "meeting-room-event-form--guest-nb-part2": "(organizer included)",
    "meeting-room-error--no-integration":
      "Your company did not activated this feature",
    "meeting-room-event-form--guests-button": "Update guests",
    "meeting-room-event-form--targets-button": "Update concerned users",
    "meeting-room-event-form--max-capacity-reached":
      "You've past room's max capacity: {guests}/{capacity}",
    "meeting-room--available-seats": "{nbSeats} available seat(s)",
    "meeting-room--attendees-attending": "{nbAttending} attending",
    "meeting-room--guests-list-recap-part-01": "This event concerns",
    "meeting-room--guests-list-recap-part-02": "(organizer included).",

    //INTEGRATION EVENTS
    "cwi-integration--card--title": "Google or Microsoft integration",
    "cwi-integration--card--description-title":
      "Connect your Google/Microsoft company account to improve your Offishall experience:",
    "cwi-integration--card--description-sentence-1": "show and create events",
    "cwi-integration--card--description-sentence-2": "book meeting rooms",
    "cwi-integration--card--description-sentence-3":
      "show and share working modes",
    // GOOGLE
    "cwi-google-events--card--title": "Google Calendar",
    "cwi-google-events--form--title": "Google Calendar",
    "cwi-google-events--card--description--success":
      "Offishall is linked to your Google company account.",
    "cwi-google-events--card--description--error":
      "Offishall is not linked to your Google company account.",

    // MICROSOFT
    "cwi-microsoft-outlook--card--title": "Microsoft Office365",
    "cwi-microsoft-outlook--form--title": "Microsoft Office365",
    "cwi-microsoft-outlook--card--description--success":
      "Offishall is linked to your Microsoft company account.",
    "cwi-microsoft-outlook--card--description--error":
      "Offishall is not linked to your Microsoft company account.",
  },
}
