import React, { useState } from "react"
import styled from "styled-components"
import { colors } from "ui"
import { Calendar } from "ui/icons"
import LocalDate from "utils/LocalDate"
import UTCDate from "utils/UTCDate"

import DatePicker from "./DatePicker"

const CustomButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  color: ${colors.grey1};
  font-weight: 400;
  font-size: 14px;
  background: none;
  border: solid 1px ${colors.grey3};
  border-radius: 4px;
  padding: 0 16px;
  margin: 0;
  &.error {
    border-color: ${colors.redAlert};
    color: ${colors.redAlert};
  }

  svg {
    height: 16px;
    width: 16px;
    color: ${colors.grey1};
  }
`

interface PropsType {
  date: LocalDate | UTCDate
  minDate?: UTCDate | LocalDate
  maxDate?: UTCDate | LocalDate
  defaultDate?: UTCDate | LocalDate
  error?: string
  updateDate: (date?: LocalDate | UTCDate) => void
  onClick?: () => void
}

export default function DatePickerButton({
  date,
  error,
  updateDate,
  onClick,
  ...rest
}: PropsType) {
  const [openPicker, setOpenPicker] = useState(false)

  return (
    <>
      <CustomButton
        className={error ? "error" : undefined}
        onClick={() => {
          setOpenPicker(true)
          if (onClick) onClick()
        }}
      >
        {date.format("long")} <Calendar />
      </CustomButton>
      <DatePicker
        open={openPicker}
        label=""
        onSelect={updateDate}
        onClose={() => setOpenPicker(false)}
        {...rest}
      />
    </>
  )
}
