import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function Circle({ stroke, strokeWidth, ...rest }: SvgIconProps) {
  return (
    <SvgIcon
      strokeWidth={strokeWidth ?? 1.5}
      stroke={stroke ? stroke : "currentColor"}
      {...rest}
    >
      <svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.2">
          <circle cx="128" cy="128" r="96" />
        </g>
        <path d="M128,232A104,104,0,1,1,232,128,104.11782,104.11782,0,0,1,128,232Zm0-192a88,88,0,1,0,88,88A88.09961,88.09961,0,0,0,128,40Z" />
      </svg>
    </SvgIcon>
  )
}
