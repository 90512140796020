import useMediaQueries from "hooks/useMediaQueries"
import React from "react"
import { L12 } from "ui"
import { NavArrowRight } from "ui/icons"

import { Item } from "./components/Item"

interface PropsType {
  title: string
  isSelected?: boolean
  onClick?: () => void
}

export default function MenuItem({ title, isSelected, onClick }: PropsType) {
  const { isMobile } = useMediaQueries()

  return (
    <Item onClick={onClick} isSelected={isSelected}>
      <L12>{title}</L12>
      {isMobile && <NavArrowRight />}
    </Item>
  )
}
