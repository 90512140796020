import MuiPopover from "@mui/material/Popover"
import React, { MouseEvent } from "react"
import { colors } from "ui"

interface PropsType {
  open: boolean
  anchorEl: Element | null
  children: React.ReactNode
  onClose: (event: MouseEvent<HTMLDivElement>) => void
}

export function Popover({ open, anchorEl, children, onClose }: PropsType) {
  return (
    <MuiPopover
      sx={{
        pointerEvents: "none",
      }}
      hideBackdrop
      elevation={0}
      PaperProps={{
        style: {
          background: colors.grey3,
          padding: "2px 8px",
          marginLeft: "24px",
          maxWidth: 500,
        },
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      onClose={onClose}
      disableRestoreFocus
    >
      {children}
    </MuiPopover>
  )
}
