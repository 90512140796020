import { SxProps, Tooltip as MuiTooltip, TooltipProps } from "@mui/material"
import React from "react"
import { colors } from "ui"

interface TooltipChildrenPropsType {
  children: React.ReactNode
}

const TooltipChildren = React.forwardRef(
  (
    { children, ...rest }: TooltipChildrenPropsType,
    ref: React.Ref<HTMLDivElement>
  ) => {
    return (
      <div {...rest} ref={ref}>
        {children}
      </div>
    )
  }
)

interface PropsType extends TooltipProps {
  disabled?: boolean
  tooltipStyle?: SxProps
}

export function Tooltip({
  children,
  placement,
  arrow,
  disabled,
  tooltipStyle,
  ...rest
}: PropsType) {
  return (
    <MuiTooltip
      placement={placement ?? "top"}
      disableHoverListener={disabled}
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: colors.grey3,
            color: colors.black,
            fontSize: "14px",
            lineHeight: "18px",
            fontWeight: "normal",
            top: "4px",
            "& .MuiTooltip-arrow": {
              color: colors.grey3,
            },
            ...tooltipStyle,
          },
        },
      }}
      enterDelay={300}
      enterTouchDelay={200}
      leaveTouchDelay={0}
      {...rest}
    >
      <TooltipChildren>{children}</TooltipChildren>
    </MuiTooltip>
  )
}
