import { Grid } from "@mui/material"
import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import useCompanyUserStats from "graphql/users/useCompanyUserStats"
import useTranslate from "intl/useTranslate"
import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { colors, mediaQueries, P14 } from "ui"

import AdminLicenseStatus from "./AdminLicenseStatus"

const Container = styled(Grid)`
  display: flex;
  flex-direction: row;
  margin-top: 0px;
  margin-bottom: 20px;
  min-height: 20px;

  @media ${mediaQueries.isTouchable} {
    width: 100%;
    flex-wrap: wrap;
    height: 50px;
  }
`

const Line = styled(Grid)`
  display: flex;
  flex-direction: row;
  padding: 0px 10px 0px 10px;
  margin-top: 10px;

  .title {
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: ${colors.purple};
    padding-right: 5px;
  }
`

interface PropsType {
  setHeightStats: (arg1: any) => void
}

export default function AdminCompanyUserStats({ setHeightStats }: PropsType) {
  const t = useTranslate()
  const { loading, error, stats } = useCompanyUserStats()
  const usersStatsRef = useRef<HTMLDivElement>(null)
  const usersStatsObserver = useRef(
    new ResizeObserver((entries) =>
      setHeightStats(entries[0].contentRect.height)
    )
  )
  useEffect(() => {
    /**
     * this useEffect makes it possible to observe if userStats size have changed
     */
    const observer = usersStatsObserver.current
    const element = usersStatsRef.current
    if (element) {
      observer.observe(element)
      return () => observer.unobserve(element)
    }
  }, [usersStatsRef, usersStatsObserver])

  if (loading || error || stats === null)
    return <LoaderOrErrorComponent loading={loading} error={error} />

  const { registered, total, disabled } = stats
  const notRegistered = total - registered
  const active = total - disabled - notRegistered
  return (
    <Container container ref={usersStatsRef}>
      <Line item>
        <p className="title">{t("Total")} :</p>
        <P14>{total}</P14>
      </Line>
      <Line item>
        <p className="title">{t("Many active")} :</p>
        <P14>{active}</P14>
      </Line>
      <Line item>
        <p className="title">{t("Many Not Active(status)")} :</p>
        <P14>{disabled}</P14>
      </Line>
      <Line item>
        <p className="title">{t("Many registered")} :</p>
        <P14>{registered}</P14>
      </Line>
      <Line item>
        <p className="title">{t("Many Not Registered")} :</p>
        <P14>{notRegistered}</P14>
      </Line>
      <AdminLicenseStatus activeUsers={active} />
    </Container>
  )
}
