import {
  ApolloError,
  gql,
  useQuery,
  WatchQueryFetchPolicy,
} from "@apollo/client"
import { SlotOccupationForLocation } from "types"

const GET_OCCUPATIONS_QUERY = gql`
  query getOccupations2($afterTimestamp: String!, $beforeTimestamp: String!) {
    occupations2(
      afterTimestamp: $afterTimestamp
      beforeTimestamp: $beforeTimestamp
    ) {
      id
      locationId
      occupation
    }
  }
`

export default function useOccupations(
  after: number,
  before: number,
  fetchPolicy: WatchQueryFetchPolicy = "network-only"
): {
  loading: boolean
  error?: ApolloError
  occupations: SlotOccupationForLocation[]
} {
  const { loading, error, data } = useQuery(GET_OCCUPATIONS_QUERY, {
    fetchPolicy,
    variables: { afterTimestamp: `${after}`, beforeTimestamp: `${before}` },
  })
  return {
    occupations: data?.occupations2 || [],
    loading,
    error,
  }
}
