import { SimpleLocationChipWithBreadCrumbs } from "components/LocationComponents/Buttons/SimpleLocationChipWithBreadCrumbs/SimpleLocationChipWithBreadCrumbs"
import useCategoryMutations from "graphql/locations/useCategoryMutations"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { Button, Dialog, P16 } from "ui"
import colors from "ui/colors"
import LocationTree, { getChildrenLocations } from "utils/LocationTree"

const Content = styled.div`
  padding: 18px;
`

const LocationsList = styled.ul`
  li {
    display: flex;
    gap: 4px;
  }
  li:before {
    content: "-";
    color: ${colors.grey2};
  }
  li:first-child {
    padding: 0 0 8px 0;
    &:before {
      content: "";
    }
  }
`

interface PropsType {
  category: LocationTree
  open: boolean
  onClose: () => void
}

export function ConfirmDeleteLocationDialog({
  open,
  onClose,
  category,
}: PropsType) {
  const t = useTranslate()

  const childrenLocations = getChildrenLocations([category], false)

  const { removeCategory } = useCategoryMutations()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t("Delete confirmation")}
      leftButton={
        <Button secondary onClick={onClose}>
          {t("Cancel")}
        </Button>
      }
      rightButton={
        <Button
          danger
          onClick={() => {
            removeCategory(category.id)
            onClose()
          }}
          stopPropagation
        >
          {t("Remove all")}
        </Button>
      }
    >
      <Content>
        <P16>{t("delete zone and its content", { name: category.name })}</P16>
        <LocationsList>
          <P16 style={{ marginTop: 10 }}>
            {t("zones to be deleted", { name: category.name })}
          </P16>

          {childrenLocations.map((l) => (
            <div key={l.id} style={{ marginTop: 16 }}>
              <SimpleLocationChipWithBreadCrumbs
                location={l}
                showBreadCrumbs={false}
                moreText={`${t("capacity")} : ${l.bookable}`}
              />
            </div>
          ))}
        </LocationsList>
      </Content>
    </Dialog>
  )
}
