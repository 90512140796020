import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import { User } from "types"
import { Button } from "ui"

import AddUserToTeamDialog from "./AddUserToTeamDialog/AddUserToTeamDialog"

interface PropsType {
  user: User
}

export default function AddUserToTeamButton({ user }: PropsType) {
  const t = useTranslate()
  const [dialogOpen, setDialogOpen] = useState(false)
  return (
    <>
      <Button onClick={() => setDialogOpen(true)}>{t("add user")}</Button>

      <AddUserToTeamDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title={t("add user to team")}
        manager={user}
      />
    </>
  )
}
