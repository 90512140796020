import React from "react"
import styled from "styled-components"
import { colors, P16, transitions } from "ui"
import LocationTree from "utils/LocationTree"

interface LayoutPropsType {
  disabled?: boolean
}

const Layout = styled.div<LayoutPropsType>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-radius: 50px;
  padding: 5px 16px;

  background: ${colors.grey4};
  border: solid 1px ${colors.grey3};

  &:hover,
  &:focus {
    border-color: ${colors.grey2};
    background: ${colors.grey3};
  }

  .breadcrumbs {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${colors.grey2};
    font-size: 16px;
    line-height: 20px;

    .crumb:after {
      content: "/";
      margin: 4px;
    }
  }

  .content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .more {
    white-space: nowrap;
    margin-left: auto;
    font-size: 14px;
    line-height: 18px;
  }

  transition: ${transitions.generic};
`

interface PropsType {
  location: LocationTree
  moreText: string
  showBreadCrumbs?: boolean
}

export function SimpleLocationChipWithBreadCrumbs({
  location,
  moreText,
  showBreadCrumbs,
}: PropsType) {
  return (
    <Layout role="button">
      <span className="breadcrumbs">
        {showBreadCrumbs &&
          location.breadCrumbs.map((crumb, n) => (
            <span key={`loc-${location.id}-crumb-${n}`} className="crumb">
              {crumb}
            </span>
          ))}
      </span>
      <P16 className="content">{location.name}</P16>

      <div className="more">
        <p>{moreText}</p>
      </div>
    </Layout>
  )
}
