import { useManagerHybridRuleScore } from "hooks/useHybridRuleScore"
import React, { useEffect } from "react"
import { ManagerHybridRule } from "types"
import UTCDate from "utils/UTCDate"

import { LoadedScoreEventDetail, LoadingScore } from "../../types"

export default function ManagerScoreNullComponent({
  score,
  addScoreAsLoaded,
}: {
  score: LoadingScore<ManagerHybridRule>
  addScoreAsLoaded: (detail: LoadedScoreEventDetail) => void
}) {
  const {
    score: managerScore,
    loading,
    error,
  } = useManagerHybridRuleScore(new UTCDate(), score.rule, score.rule.managerId)

  useEffect(() => {
    if (error)
      return addScoreAsLoaded({
        id: score.rule.id,
        concernedUserIds: [],
        compliantUserIds: [],
      })
    if (!loading && managerScore)
      addScoreAsLoaded({
        id: managerScore.id,
        concernedUserIds: managerScore.concernedUserIds,
        compliantUserIds: managerScore.compliantUserIds,
      })
  }, [loading, error, addScoreAsLoaded, managerScore, score])
  return <></>
}
