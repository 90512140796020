import React from "react"
import styled from "styled-components"
import { colors } from "ui"
import LocalDate from "utils/LocalDate"

import { getTimeTableHours } from "../utils"

const List = styled.ul<{ size: number }>`
  display: grid;
  ${({ size }) => `
    grid-template-columns: repeat(${size}, 1fr);
  `}
`

const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  // right border behind content
  box-shadow: -1px 0 ${colors.grey3} inset;
  // vertical dashed line
  background-image: linear-gradient(
    180deg,
    transparent,
    transparent 27px,
    ${colors.grey3} 27px,
    ${colors.grey3} 31px,
    transparent 31px,
    transparent 35px,
    ${colors.grey3} 35px,
    ${colors.grey3} 39px
  );
  background-position: center;
  background-size: 1px 100%;
  background-repeat: no-repeat;

  &:last-child {
    box-shadow: 0 0;
  }
`

interface PropsType {
  minHour: LocalDate
  maxHour: LocalDate
}

export default function TimeTable({ minHour, maxHour }: PropsType) {
  const hours = getTimeTableHours(minHour, maxHour)
  return (
    <List size={hours.length}>
      {hours.map((h) => (
        <Item key={`${h.getTime()}`}>{h.format("HH:MM")}</Item>
      ))}
    </List>
  )
}
