import useCompanyFiles from "graphql/company/useCompanyFiles"
import React from "react"
import styled from "styled-components"

import FileItem from "./FileItem/FileItem"

const List = styled.ul`
  padding: 16px 0;
`

export default function UploadedFilesList() {
  const { companyFiles } = useCompanyFiles()
  return (
    <List>
      {companyFiles.map((file) => (
        <FileItem key={`company-file-${file.id}`} file={file} />
      ))}
    </List>
  )
}
