import useTranslate from "intl/useTranslate"
import React from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components/macro"
import { colors, H2, mediaQueries, P16 } from "ui"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 480px;

  @media ${mediaQueries.isMobile} {
    width: 100%;
    padding: 0 10px;
  }

  h2 {
    color: ${colors.purple};
  }

  .subtitle {
    margin-top: 20px;
    text-align: center;
  }
`

function Oops() {
  const t = useTranslate()

  return (
    <Wrapper>
      <Helmet title="sad oops" />
      <H2>{t("sad oops")}</H2>
      <P16 className="subtitle">{t("servers under heavy pressure")}</P16>
    </Wrapper>
  )
}

export default Oops
