import useUser from "graphql/users/useUser"
import useCompanyFlags from "hooks/useCompanyFlags"
import useTranslate from "intl/useTranslate"
import DownloadCsvButton from "pages/StatisticsPage/components/DownloadCsvButton"
import React, { useCallback, useState } from "react"
import { Bar } from "react-chartjs-2"
import { GroupSimple, UserBase } from "types"
import { colors } from "ui"
import getTeamDisplayName from "utils/getTeamDisplayName"
import getUserDisplayName from "utils/getUserDisplayName"
import UTCDate from "utils/UTCDate"

import GroupCompletionRateBar from "./GroupCompletionRateBar/GroupCompletionRateBar"
import TotalCompletionRateBar from "./TotalCompletionRateBar/TotalCompletionRateBar"
import UserCompletionRateBar from "./UserCompletionRateBar/UserCompletionRateBar"

interface PropsType {
  groups: GroupSimple[]
  users: UserBase[]
  from: UTCDate
  to: UTCDate
  includeWeekends: boolean
  showTotal: boolean
}

export default function CompletionRateChart({
  groups,
  users,
  from,
  to,
  includeWeekends,
  showTotal,
}: PropsType) {
  const t = useTranslate()
  const { user: me } = useUser()
  const { companyFlags } = useCompanyFlags()

  const [usersCompletionRate, setUsersCompletionRate] = useState<
    {
      user: UserBase
      completionRate: number
    }[]
  >([])

  const [groupsCompletionRate, setGroupsCompletionRate] = useState<
    {
      group: GroupSimple
      completionRate: number
    }[]
  >([])

  const [totalCompletionRate, setTotalCompletionRate] = useState<
    number | undefined
  >(undefined)

  const updateGroupsCompletionRate = (
    {
      group,
      completionRate,
    }: {
      group: GroupSimple
      completionRate: number
    },
    remove = false
  ) => {
    const completion = remove ? [] : [{ group, completionRate }]

    setGroupsCompletionRate((prev) => [
      ...prev.filter((gcr) => gcr.group.id !== group.id),
      ...completion,
    ])
  }

  const updateUsersCompletionRate = (
    {
      user,
      completionRate,
    }: {
      user: UserBase
      completionRate: number
    },
    remove = false
  ) => {
    const completion = remove ? [] : [{ user, completionRate }]
    setUsersCompletionRate((prev) => [
      ...prev.filter((ucr) => ucr.user.id !== user.id),
      ...completion,
    ])
  }

  const canShowTotal = (users.length > 0 || groups.length > 0) && showTotal

  const getCsv = useCallback(async () => {
    if (me === null) return

    const headers = ["email", "name", "type", "from", "to", "value"].join(",")
    const rows: string[] = []
    if (canShowTotal && totalCompletionRate)
      rows.push(
        [
          "",
          "total",
          "total",
          from.format("DD/MM/YY HH:MM"),
          to.format("DD/MM/YY HH:MM"),
          Number(totalCompletionRate),
        ].join(",")
      )
    for (const u of users) {
      const rate = usersCompletionRate.find((ucr) => ucr.user.id === u.id)
      if (rate) {
        rows.push(
          [
            u.email,
            getUserDisplayName(u, me.company.flags),
            "user",
            from.format("DD/MM/YY HH:MM"),
            to.format("DD/MM/YY HH:MM"),
            Number(rate.completionRate),
          ].join(",")
        )
      }
    }
    for (const g of groups) {
      const rate = groupsCompletionRate.find((gcr) => gcr.group.id === g.id)
      if (rate) {
        rows.push(
          [
            "",
            g.name,
            "group",
            from.format("DD/MM/YY HH:MM"),
            to.format("DD/MM/YY HH:MM"),
            Number(rate.completionRate),
          ].join(",")
        )
      }
    }

    return `${headers}\n${rows.join("\n")}`
  }, [
    groups,
    groupsCompletionRate,
    users,
    usersCompletionRate,
    from,
    to,
    me,
    canShowTotal,
    totalCompletionRate,
  ])

  const labels: string[] = canShowTotal
    ? [
        ...groupsCompletionRate.map(({ group }) => {
          return getTeamDisplayName(group, companyFlags, t)
        }),
        ...usersCompletionRate.map(({ user }) => {
          if (me === null) return user.name
          return getUserDisplayName(user, me.company.flags)
        }),
        "Total",
      ]
    : [
        ...groupsCompletionRate.map(({ group }) => {
          return getTeamDisplayName(group, companyFlags, t)
        }),
        ...usersCompletionRate.map(({ user }) => {
          if (me === null) return user.name
          return getUserDisplayName(user, me.company.flags)
        }),
      ]

  const datasets: any[] = [
    {
      label: "filled",
      data:
        canShowTotal && totalCompletionRate !== undefined
          ? [
              ...groupsCompletionRate.map((gcr) => gcr.completionRate),
              ...usersCompletionRate.map((ucr) => ucr.completionRate),
              totalCompletionRate,
            ]
          : [
              ...groupsCompletionRate.map((gcr) => gcr.completionRate),
              ...usersCompletionRate.map((ucr) => ucr.completionRate),
            ],
      backgroundColor: colors.black,
      maxBarThickness: 32,
      stack: 0,
    },
    {
      label: "not filled",
      data:
        canShowTotal && totalCompletionRate !== undefined
          ? [
              ...groupsCompletionRate.map((gcr) => 1 - gcr.completionRate),
              ...usersCompletionRate.map((ucr) => 1 - ucr.completionRate),
              1 - totalCompletionRate,
            ]
          : [
              ...groupsCompletionRate.map((gcr) => 1 - gcr.completionRate),
              ...usersCompletionRate.map((ucr) => 1 - ucr.completionRate),
            ],
      backgroundColor: colors.grey3,
      maxBarThickness: 32,
      stack: 0,
    },
  ]

  const data = {
    labels,
    datasets,
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          ticks: {
            display: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: true,
          },
          ticks: {
            callback: function (value: number) {
              return Math.floor(100 * value) + "%"
            },
          },
        },
      ],
    },
    tooltips: {
      mode: "nearest",
      callbacks: {
        label: function (tooltipItem: any) {
          return `${(100 * tooltipItem.yLabel).toFixed(2)}%`
        },
      },
    },
    legend: {
      labels: {
        usePointStyle: true,
        boxWidth: 7,
        filter: (legendItem: any) => {
          legendItem.text = t(legendItem.text)

          return true
        },
      },
    },
  }

  return (
    <>
      <DownloadCsvButton fileName="completion-rate.csv" getCsv={getCsv} />
      {groups.map((g) => (
        <GroupCompletionRateBar
          key={`group-completion-bar-${g.id}`}
          group={g}
          from={from}
          to={to}
          includeWeekends={includeWeekends}
          groupsCompletionRate={groupsCompletionRate}
          updateGroupsCompletionRate={updateGroupsCompletionRate}
        />
      ))}
      {users.map((u, n) => (
        <UserCompletionRateBar
          key={`user-completion-bar-${n}-${u.id}`}
          user={u}
          from={from}
          to={to}
          includeWeekends={includeWeekends}
          usersCompletionRate={usersCompletionRate}
          updateUsersCompletionRate={updateUsersCompletionRate}
        />
      ))}
      {canShowTotal && (
        <TotalCompletionRateBar
          users={users}
          groups={groups}
          from={from}
          to={to}
          includeWeekends={includeWeekends}
          totalCompletionRate={totalCompletionRate}
          updateTotalCompletionRate={setTotalCompletionRate}
        />
      )}
      <Bar data={data} options={options} />
    </>
  )
}
