import PageContent from "components/Layout/PageLayout/PageContent/PageContent"
import AdminPageTitle from "components/Layout/PageLayout/PageHeader/AdminPageTitle/AdminPageTitle"
import PageHeader from "components/Layout/PageLayout/PageHeader/PageHeader"
import PageLayout from "components/Layout/PageLayout/PageLayout"
import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import useEquipments from "graphql/equipments/useEquipments"
import useLocationsTree from "graphql/locations/useLocationsTree"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { Equipment } from "types"

import AddEquipmentButton from "./AddEquipmentButton/AddEquipmentButton"
import UpdateEquipmentDialog from "./AddEquipmentButton/UpdateEquipmentDialog/UpdateEquipmentDialog"
import EquipmentsList from "./EquipmentsList/EquipmentsList"

const TitleAction = styled.div`
  margin-top: auto;
  margin-left: auto;
`

export default function ServicesPage() {
  const t = useTranslate()
  const { equipments } = useEquipments()
  const { locations, loading, error } = useLocationsTree()

  const [equipmentToUpdate, setEquipmentToUpdate] = useState<
    Equipment | undefined
  >(undefined)

  const onEquipmentAdded = () => {
    setEquipmentToUpdate(undefined)
  }

  const breadcrumb = [
    {
      name: t("Admin"),
      path: "/admin",
    },
    {
      name: t("Services"),
      path: "/admin/services",
    },
  ]

  if (loading || error)
    return <LoaderOrErrorComponent loading={loading} error={error} />

  return (
    <>
      <PageLayout>
        <PageHeader>
          <AdminPageTitle title={t("Services")} breadcrumbs={breadcrumb} />
          <TitleAction>
            <AddEquipmentButton onEquipmentAdded={onEquipmentAdded} />
          </TitleAction>
        </PageHeader>
        <PageContent>
          <EquipmentsList
            equipments={equipments}
            locations={locations}
            onSelectEquipment={(service) => setEquipmentToUpdate(service)}
          />
        </PageContent>
      </PageLayout>
      {equipmentToUpdate && (
        <UpdateEquipmentDialog
          open
          equipment={equipmentToUpdate}
          onEquipmentAdded={() => setEquipmentToUpdate(undefined)}
          onClose={() => setEquipmentToUpdate(undefined)}
        />
      )}
    </>
  )
}
