import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import React, { useEffect, useRef } from "react"
import { UsersAtFilter } from "types"

import UsersAtFiltersContent from "./UsersAtFiltersContent/UsersAtFiltersContent"

export default function UsersAtFilters() {
  const { setUsersAtFilters, usersAtFilters } = usePlanningContext()

  // local copy instantaneously updated vs filter from planning ctx
  // so that being rendered elements reflect what currently selected filter is
  // even if ctx takes time to return filtered informations.
  const localUsersAtFilters = useRef<UsersAtFilter[]>([...usersAtFilters])

  const updateFilter = (filter: UsersAtFilter | undefined) => {
    const filters = filter ? [filter] : []
    setUsersAtFilters(filters)
    localUsersAtFilters.current = filters
  }

  useEffect(() => {
    localUsersAtFilters.current = [...usersAtFilters]
  }, [usersAtFilters])

  return (
    <UsersAtFiltersContent
      usersAtFilters={usersAtFilters}
      updateFilter={updateFilter}
    />
  )
}
