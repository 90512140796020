import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function Rectangle({
  stroke,
  strokeWidth,
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      strokeWidth={strokeWidth ?? 1.5}
      stroke={stroke ? stroke : "currentColor"}
      {...rest}
    >
      <svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.2">
          <rect x="32.00781" y="48.00005" width="192" height="160" />
        </g>
        <path d="M216.00781,216h-176a16.0181,16.0181,0,0,1-16-16V56a16.0181,16.0181,0,0,1,16-16h176a16.0181,16.0181,0,0,1,16,16V200A16.0181,16.0181,0,0,1,216.00781,216Zm-176-160V200h176l.01-.00037L216.00781,56Z" />
      </svg>
    </SvgIcon>
  )
}
