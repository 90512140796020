import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { Equipment } from "types"
import { colors, LU14, P16, P16Bold, PopperMenu } from "ui"
import { ArrowDownFill } from "ui/icons"
import LocationTree from "utils/LocationTree"

import EquipmentItem from "../../EquipmentItem/EquipmentItem"
import LocationName from "../../LocationName/LocationName"

const DialogInnerLayout = styled.div`
  padding: 16px 24px 24px 24px;
  max-width: 600px;
`

const LocationEquipmentsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 12px;
`

const Paragraph = styled(P16Bold)`
  display: flex;
  padding: 32px 0 16px 0;
`

interface PropsType {
  open: boolean
  location: LocationTree
  equipments: Equipment[]
  anchorEl: HTMLDivElement | null
}

export default function LocationEquipmentsDialog({
  open,
  location,
  equipments,
  anchorEl,
}: PropsType) {
  const t = useTranslate()

  return (
    <PopperMenu
      open={open}
      placement="bottom-start"
      style={{ zIndex: 3000 }}
      anchorEl={anchorEl}
      onClose={() => {
        //
      }}
    >
      <DialogInnerLayout>
        <LocationName location={location} />
        <LocationEquipmentsList>
          {React.Children.map(
            equipments.map((equipment, n) => (
              <EquipmentItem
                key={`equipment-item-${n}`}
                equipment={equipment}
                onClick={() => {
                  //
                }}
              />
            )),
            (child) => (
              <>{child}</>
            )
          )}
        </LocationEquipmentsList>
        <Paragraph>
          {t("The equipments from above are available in the following space")}
        </Paragraph>
        <LocationTreeRenderer locations={[location]} />
      </DialogInnerLayout>
    </PopperMenu>
  )
}

const LocationTreeLayout = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ul {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 0 0 16px;
  }
`

const CategoryLayout = styled.li`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const CategoryName = styled(LU14)`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${colors.grey1};

  svg {
    width: 20px;
  }
`

function LocationTreeRenderer({ locations }: { locations: LocationTree[] }) {
  return (
    <LocationTreeLayout>
      {locations.map((location) => {
        if (!location.isCategory)
          return (
            <li key={`location-${location.id}`}>
              <P16>{location.name}</P16>
            </li>
          )
        return (
          <CategoryLayout key={`category-${location.id}`}>
            <CategoryName>
              <ArrowDownFill />
              {location.name}
            </CategoryName>

            <ul>
              {location.children.map((child) => (
                <li key={`cat-${location.id}-child-${child.id}`}>
                  <LocationTreeRenderer locations={[child]} />
                </li>
              ))}
            </ul>
          </CategoryLayout>
        )
      })}
    </LocationTreeLayout>
  )
}
