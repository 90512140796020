import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function Drawer({ stroke, strokeWidth, ...rest }: SvgIconProps) {
  return (
    <SvgIcon
      strokeWidth={strokeWidth ?? 1.5}
      stroke={stroke ? stroke : "currentColor"}
      {...rest}
    >
      <path
        d="M21 14H3M3 8H21M11 17H13M11 11H13M11 5H13M17.5 20V22M6.5 20V22M21 2.6V19.4C21 19.7314 20.7314 20 20.4 20H3.6C3.26863 20 3 19.7314 3 19.4V2.6C3 2.26863 3.26863 2 3.6 2H20.4C20.7314 2 21 2.26863 21 2.6Z"
        fill="none"
      />
    </SvgIcon>
  )
}
