import useWorkingModes from "graphql/stats/useWorkingModes"
import useTranslate from "intl/useTranslate"
import React, { useEffect, useRef } from "react"
import { Pie } from "react-chartjs-2"
import styled from "styled-components"
import { GroupSimple, UserBase } from "types"
import { colors, P14 } from "ui"
import getLabelFromStatus from "utils/getLabelFromStatus"
import UTCDate from "utils/UTCDate"

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

interface PropsType {
  users: UserBase[]
  groups: GroupSimple[]
  from: UTCDate
  to: UTCDate
  includeWeekends: boolean
  totalWorkingModes:
    | {
        homeValue: number
        officeValue: number
        elseValue: number
        offValue: number
        oocValue: number
        nullValue: number
      }
    | undefined
  setTotalWorkingModes: React.Dispatch<
    React.SetStateAction<
      | {
          homeValue: number
          officeValue: number
          elseValue: number
          offValue: number
          oocValue: number
          nullValue: number
        }
      | undefined
    >
  >
}

export default function TotalWorkingModePie({
  users,
  groups,
  from,
  to,
  includeWeekends,
  totalWorkingModes,
  setTotalWorkingModes,
}: PropsType) {
  const t = useTranslate()

  const { workingModes, loading, error } = useWorkingModes({
    userIds: users.map((u) => u.id),
    groupIds: groups.map((g) => g.id),
    from,
    to,
    includeWeekends,
  })

  const labels = ["home", "office", "else", "off", "ooc", "null"]

  const homeValue =
    workingModes.find((wm) => wm.workingMode === "home")?.value ?? 0

  const officeValue =
    workingModes.find((wm) => wm.workingMode === "office")?.value ?? 0

  const elseValue =
    workingModes.find((wm) => wm.workingMode === "else")?.value ?? 0

  const offValue =
    workingModes.find((wm) => wm.workingMode === "off")?.value ?? 0

  const oocValue =
    workingModes.find((wm) => wm.workingMode === "ooc")?.value ?? 0

  const nullValue =
    workingModes.find((wm) => wm.workingMode === "null")?.value ?? 0

  const datasets = [
    {
      data: [homeValue, officeValue, elseValue, offValue, oocValue, nullValue],
      backgroundColor: [
        colors.home,
        colors.office,
        colors.else,
        colors.off,
        colors.ooc,
        colors.nullStats,
      ],
    },
  ]

  const data = { labels, datasets }

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    tooltips: {
      mode: "nearest",
      callbacks: {
        label: (item: any, obj: any) => {
          const status = obj.labels[item.index]
          const value = obj.datasets[0].data[item.index]
          return `${t(getLabelFromStatus(status))}: ${100 * value.toFixed(2)}%`
        },
      },
    },
    legend: {
      display: false,
    },
  }

  const previousWorkingModes = useRef([...workingModes])

  const statsHaveChanged =
    JSON.stringify(previousWorkingModes.current) !==
    JSON.stringify(workingModes)

  useEffect(() => {
    if (loading || error) return
    if (statsHaveChanged) {
      setTotalWorkingModes({
        homeValue,
        officeValue,
        elseValue,
        offValue,
        oocValue,
        nullValue,
      })
      previousWorkingModes.current = [...workingModes]
    }
  }, [
    loading,
    error,
    statsHaveChanged,
    homeValue,
    officeValue,
    elseValue,
    offValue,
    oocValue,
    nullValue,
    workingModes,
    totalWorkingModes,
    setTotalWorkingModes,
  ])

  if (loading || error) return <></>
  return (
    <Layout>
      <Pie data={data} options={options} />
      <P14>Total</P14>
    </Layout>
  )
}
