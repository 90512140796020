import EditManager from "components/UpdateManagerButton/EditManager"
import useUpdateUser from "graphql/admin/useUpdateUser"
import useUpdateManager from "graphql/users/useUpdateManager"
import useUser from "graphql/users/useUser"
import useUserFromId from "graphql/users/useUserFromId"
import useTranslate from "intl/useTranslate"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { AdminProfileChanges, UserBase } from "types"
import { colors, Dialog, H4, mediaQueries } from "ui"
import getUserDisplayName from "utils/getUserDisplayName"

import EditUserEmail from "./components/EditUserEmail"
import ManageTeam from "./components/ManageTeam"
import UserDisabledStatus from "./components/UserDisabledStatus"
import UserRegisteredStatus from "./components/UserRegisteredStatus"
import UserRole from "./components/UserRole"
import DeleteButton from "./DeleteButton"
import SaveButton from "./SaveButton"

const AdminControlSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 16px 24px;

  background-color: white;
  hr {
    width: 100%;
    border: none;
    border-top: solid 1px ${colors.grey3};
    margin: 0 auto;
  }

  div.admin-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
  }

  @media ${mediaQueries.isTouchable} {
    padding: 16px;

    h3 {
      text-align: center;
      font-size: 1.25rem;
    }

    div.admin-controls {
      button {
        width: 100%;
        justify-content: space-between;
      }
    }
  }
`

const initialChanges = {
  isAdmin: false,
  isDisabled: true,
  userEmail: "",
  userName: "",
  newManager: null,
}

interface PropsType {
  open: boolean
  userId: string
  onClose: () => void
  onSuccess: () => void
}

export default function EditUserDialog({
  open,
  userId,
  onClose,
  onSuccess,
}: PropsType) {
  const t = useTranslate()
  const { user: me } = useUser()
  const { user: userToMutate } = useUserFromId(userId)

  const { updateManager } = useUpdateManager()

  const { updateUser } = useUpdateUser()

  const [dialogOpen, setDialogOpen] = useState(false)
  const [changesToApply, setChangesToApply] =
    useState<AdminProfileChanges>(initialChanges)

  const updateChanges = (newChanges: {
    isAdmin?: boolean
    isDisabled?: boolean
    userEmail?: string
    userName?: string
    newManager?: UserBase | null
  }) => {
    setChangesToApply((prev) => ({ ...prev, ...newChanges }))
  }

  const meAndUserToMutateAreValid =
    me !== null && me.role === "admin" && userToMutate !== null

  const userStatusHasChanged =
    userToMutate !== null &&
    (changesToApply.isAdmin !== (userToMutate.role === "admin") ||
      changesToApply.isDisabled !== userToMutate.disabled)

  const userManagerHasChanged =
    userToMutate !== null &&
    changesToApply.newManager?.email !== userToMutate.manager?.email

  const confirmChanges = async () => {
    if (!meAndUserToMutateAreValid) {
      console.error("Me or user to mutate to mutate not found.")
      return onClose()
    }

    if (userStatusHasChanged) {
      await updateUser(
        userToMutate.id,
        changesToApply.isAdmin ? "admin" : "user",
        changesToApply.isDisabled
      )
    }

    if (userManagerHasChanged) {
      await updateManager(
        changesToApply.newManager?.email ?? "",
        userToMutate.id
      )
    }
    onSuccess()
    onClose()
  }

  const onUserHasBeenDeleted = () => {
    setDialogOpen(false)
    onClose()
  }

  useEffect(() => {
    if (userToMutate)
      setChangesToApply((prev) => ({
        ...prev,
        userEmail: userToMutate.email,
        userName: userToMutate.name,
        isAdmin: userToMutate.role === "admin",
        isDisabled: userToMutate.disabled,
        newManager: userToMutate.manager,
      }))
  }, [userToMutate])

  if (!me || !userToMutate) return <></>

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t("edit user profile")}
      leftButton={
        <DeleteButton
          canBeEdited={me.role === "admin"}
          user={userToMutate}
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          onUserHasBeenDeleted={onUserHasBeenDeleted}
        />
      }
      rightButton={<SaveButton confirmChanges={confirmChanges} />}
    >
      <AdminControlSection>
        <H4>{getUserDisplayName(userToMutate, me.company.flags)}</H4>
        <UserRegisteredStatus user={userToMutate} />
        <UserRole user={userToMutate} updateChanges={updateChanges} />
        <UserDisabledStatus user={userToMutate} updateChanges={updateChanges} />
        <EditUserEmail user={userToMutate} updateChanges={updateChanges} />
        <EditManager selectedUser={userToMutate} />
        <ManageTeam user={userToMutate} onClose={onClose} />
      </AdminControlSection>
    </Dialog>
  )
}
