import React, { FormHTMLAttributes } from "react"
import styled from "styled-components/macro"
import { colors } from "ui"

const CloseButton = styled.button`
  border: 1px solid transparent;
  background-color: transparent;
  display: inline-block;
  vertical-align: middle;
  outline: 0;
  cursor: pointer;
  margin-left: auto;

  &::after {
    content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28 28' fill='%23777'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
    display: block;
    width: 20px;
    height: 20px;
    padding: 2px 0px 0px 2px;
    border-radius: 50%;
    border: solid 1px ${colors.grey1};
    color: ${colors.grey1};
    cursor: pointer;
  }
`

interface PropsType extends FormHTMLAttributes<HTMLFormElement> {
  handleClearForm?: () => void
  resetForm: () => void
}

export default function ButtonResetSearch({
  handleClearForm,
  resetForm,
}: PropsType) {
  return (
    <CloseButton
      className="close-button"
      onClick={() => {
        if (handleClearForm !== undefined) handleClearForm()
        else resetForm()
      }}
    />
  )
}
