import { ApolloCache } from "@apollo/client"

import { GroupFragment } from "./fragments/groupFragment"

interface PropsType {
  cache: ApolloCache<any>
  data: any
  mutationName: string
  removeFromAllGroups?: boolean
  removeFromUserGroups?: boolean
}

function updateCache(
  cache: ApolloCache<any>,
  data: any,
  mutationName: string,
  fieldToUpdate: string,
  remove = false
) {
  if (data && data[mutationName]) {
    const group = data[mutationName]
    const fields: Record<string, any> = {}
    fields[fieldToUpdate] = (existing = []) => {
      const s = existing.filter(
        (es: any) => es.__ref !== `${group.__typename}:${group.id}`
      )
      if (remove) {
        return s
      }
      const newRef = cache.writeFragment({
        data: group,
        fragment: GroupFragment,
        fragmentName: "GroupFragment",
      })
      return [...s, newRef]
    }
    cache.modify({ fields })
  }
}

export default function updateGroupInCache({
  cache,
  data,
  mutationName,
  removeFromAllGroups,
  removeFromUserGroups,
}: PropsType) {
  {
    updateCache(
      cache,
      data,
      mutationName,
      "myGroupsSimple",
      removeFromUserGroups
    )
    updateCache(cache, data, mutationName, "allGroups", removeFromAllGroups)
  }
}
