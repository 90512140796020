import FavoriteCheckbox from "components/FavoriteCheckbox"
import PageContent from "components/Layout/PageLayout/PageContent/PageContent"
import PageHeader from "components/Layout/PageLayout/PageHeader/PageHeader"
import MobileNavBack from "components/Layout/PageLayout/PageHeader/PageTitle/MobileNavBack/MobileNavBack"
import PageTitle from "components/Layout/PageLayout/PageHeader/PageTitle/PageTitle"
import PageLayout from "components/Layout/PageLayout/PageLayout"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import UserSearch from "components/UserSearch/UserSearch"
import useGroupUsers from "graphql/groups/useGroupUsers"
import useJoinOrLeaveGroup from "graphql/groups/useJoinOrLeaveGroup"
import useUser from "graphql/users/useUser"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import { SearchResult } from "types"
import {
  Button,
  colors,
  L12GreyBold,
  Link,
  mediaQueries,
  P14Bold,
  P16,
  UserListItem,
} from "ui"
import getUserDisplayName from "utils/getUserDisplayName"

import DeleteGroupDialog from "./DeleteGroupDialog"
import UpdateDisplayGroupName from "./UpdateDisplayGroupName"

const CustomPageTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  h2,
  h4 {
    max-width: calc(100vw - 880px);
  }

  @media ${mediaQueries.isNarrowScreen} {
    h2,
    h4 {
      max-width: calc(100vw - 680px);
    }
  }

  @media ${mediaQueries.isMobile} {
    h2,
    h4 {
      max-width: calc(100vw - 128px);
    }
  }
`

const MemberInvitation = styled.div`
  padding: 0 32px;
  p {
    margin-bottom: 8px;
  }

  @media ${mediaQueries.isMobile} {
    padding: 0;
  }
`

const DesktopHeaderActions = styled.div`
  display: flex;
  gap: 16px;
  margin-left: auto;

  button {
    width: 120px;
  }
`

const MobileHeaderActions = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 8px 0 8px;
  gap: 8px;
`

const MobileMainActions = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;

  button {
    width: 100px;
  }
`
const MobileSecondaryActions = styled.div`
  display: flex;
  justify-content: center;
`

const List = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  > div:last-child {
    border-bottom: solid 1px ${colors.grey3};
  }

  @media ${mediaQueries.isMobile} {
    padding: 8px 0;
  }
`

const StyledLink = styled(Link)`
  margin-right: auto;
  &:hover,
  &:link,
  &:visited,
  &:active {
    color: ${colors.purple};
  }

  @media ${mediaQueries.isMobile} {
    margin: 0;
  }
`

const NoUserInGroup = styled(P16)`
  margin-top: 20px;
`

export default function GroupPage() {
  const t = useTranslate()
  const { groupId } = useParams<{ groupId: string }>()
  const { user } = useUser()
  const { isMobile } = useMediaQueries()
  const { addToast } = useToasts()

  const { group } = useGroupUsers(groupId || "")
  const { joinGroup, leaveGroup } = useJoinOrLeaveGroup()

  const [openRenameGroupModal, setOpenRenameGroupModal] =
    useState<boolean>(false)
  const [openDeleteGroupModal, setOpenDeleteGroupModal] =
    useState<boolean>(false)

  const renameGroupModal = () => setOpenRenameGroupModal(false)

  const updateGroup = (user: SearchResult) => {
    if (!group) return
    joinGroup(group.id, user.id).then(() => {
      if (Boolean(group.users.find((u) => u.id === user.id))) {
        addToast(t("user already in group"), {
          appearance: "error",
        })
      } else {
        addToast(t("user added to group"), {
          appearance: "success",
        })
      }
    })
  }

  const isMemberOfTeam =
    group &&
    user &&
    Boolean(group.users.find((oldUser) => oldUser.id === user.id))

  const to = group ? `/planning/group/${group.id}` : ""

  const leaveJoinGroup = () => {
    if (isMemberOfTeam) {
      group && user && leaveGroup(group.id, user.id)
    } else {
      group && user && joinGroup(group.id, user.id)
    }
  }

  if (group === null) return <></>

  return (
    <PageLayout>
      {isMobile ? (
        <div>
          <PageHeader>
            <MobileNavBack />
            <CustomPageTitle>
              <PageTitle title={group.name} />
              <FavoriteCheckbox type="group" itemId={group.id} />
            </CustomPageTitle>
          </PageHeader>
          <MobileHeaderActions>
            <MobileMainActions>
              <Button secondary onClick={leaveJoinGroup}>
                {isMemberOfTeam ? t("leave") : t("join")}
              </Button>
              {user &&
                (user.role === "admin" ||
                  user.id === group.flags?.createdBy) && (
                  <>
                    <Button
                      danger
                      onClick={() => {
                        setOpenDeleteGroupModal(true)
                      }}
                    >
                      {t("Delete")}
                    </Button>
                    <DeleteGroupDialog
                      groupId={group.id}
                      open={openDeleteGroupModal}
                      onClose={() => setOpenDeleteGroupModal(false)}
                    />
                    <Button onClick={() => setOpenRenameGroupModal(true)}>
                      {t("Rename")}
                    </Button>
                  </>
                )}
              <UpdateDisplayGroupName
                group={group}
                open={openRenameGroupModal}
                onClose={renameGroupModal}
              />
            </MobileMainActions>
            <MobileSecondaryActions>
              <StyledLink to={to}>{`${t("see planning")} →`}</StyledLink>
            </MobileSecondaryActions>
            <MemberInvitation>
              <P14Bold style={{ paddingBottom: "8px", color: colors.grey1 }}>
                {t("members invitation")}
              </P14Bold>

              <UserSearch onSelect={updateGroup} />
            </MemberInvitation>
          </MobileHeaderActions>
        </div>
      ) : (
        <div>
          <PageHeader>
            <CustomPageTitle>
              <PageTitle title={group.name} />
              <FavoriteCheckbox type="group" itemId={group.id} />
              <StyledLink to={to}>{`${t("see planning")} →`}</StyledLink>
            </CustomPageTitle>
            <DesktopHeaderActions>
              <Button secondary onClick={leaveJoinGroup}>
                {isMemberOfTeam ? t("leave") : t("join")}
              </Button>
              {user &&
                (user.role === "admin" ||
                  user.id === group.flags?.createdBy) && (
                  <>
                    <Button
                      danger
                      onClick={() => {
                        setOpenDeleteGroupModal(true)
                      }}
                    >
                      {t("Delete")}
                    </Button>
                    <DeleteGroupDialog
                      groupId={group.id}
                      open={openDeleteGroupModal}
                      onClose={() => setOpenDeleteGroupModal(false)}
                    />
                    <Button onClick={() => setOpenRenameGroupModal(true)}>
                      {t("Rename")}
                    </Button>
                  </>
                )}
              <UpdateDisplayGroupName
                group={group}
                open={openRenameGroupModal}
                onClose={renameGroupModal}
              />
            </DesktopHeaderActions>
          </PageHeader>
          <MemberInvitation>
            <L12GreyBold>{t("members invitation")}</L12GreyBold>
            <UserSearch onSelect={updateGroup} />
          </MemberInvitation>
        </div>
      )}
      <PageContent>
        {user && group.users.length >= 1 ? (
          <List>
            {group.users.map((u) => {
              return (
                <UserListItem key={u.id} user={u} group={group}>
                  {getUserDisplayName(u, user.company.flags)}
                </UserListItem>
              )
            })}
          </List>
        ) : (
          <NoUserInGroup>{t("no user in group")}</NoUserInGroup>
        )}
      </PageContent>
    </PageLayout>
  )
}
