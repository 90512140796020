import useCompanyFiles from "graphql/company/useCompanyFiles"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { colors, mediaQueries, P16 } from "ui"

import { SectionTitle } from "../components/components"
import FileItem from "./FileItem/FileItem"

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  background: ${colors.purple200};
  border-radius: 4px;

  width: 100%;
  max-width: 100%;

  @media ${mediaQueries.isMobile} {
    gap: 4px;
    padding: 8px;
  }
`

export default function CompanyFiles() {
  const t = useTranslate()
  const { companyFiles } = useCompanyFiles()

  return (
    <>
      <SectionTitle title="corporate documents" />

      <List>
        {companyFiles.length === 0 && (
          <P16>{t("company_file--empty_file_list")}</P16>
        )}
        {companyFiles.map((file) => (
          <li key={`company-file-${file.id}`}>
            <FileItem file={file} />
          </li>
        ))}
      </List>
    </>
  )
}
