import useUser from "graphql/users/useUser"
import React from "react"
import { UserIntegration } from "types"

import SlackIntegration from "./SlackIntegration/SlackIntegration"

const possibleIntegrations = [
  {
    name: "slack",
    Component: SlackIntegration,
    enabledByDefault: true,
  },
]

interface PropsType {
  userIntegrations: UserIntegration[]
}

export default function UserIntegrations({ userIntegrations }: PropsType) {
  const { user: me } = useUser()

  const enabledIntegrations: string[] =
    me?.company.flags.enabledIntegrations || []

  return (
    <ul>
      {possibleIntegrations
        .filter(
          ({ name, enabledByDefault }) =>
            enabledByDefault || enabledIntegrations.includes(name)
        )
        .map(({ name, Component }) => (
          <div key={`integration-${name}`}>
            <Component
              integration={userIntegrations.find((i) => i.name === name)}
            />
          </div>
        ))}
    </ul>
  )
}
