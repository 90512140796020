import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { colors, L12, P16, transitions } from "ui"
import { NavArrowLeft, NavArrowRight } from "ui/icons"
import LocalDate from "utils/LocalDate"

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;

  svg {
    cursor: pointer;
  }
`

const TodayButton = styled(L12)`
  border: solid 1px ${colors.grey3};
  border-radius: 4px;
  padding: 2px 8px;
  color: ${colors.purple};
  background: ${colors.purple}00;
  cursor: pointer;

  &:hover {
    background: ${colors.purple}08;
  }

  transition: ${transitions.fade};
`
interface PropsType {
  from: LocalDate
  shiftTime: (amount: number) => void
}

export default function TimeNavigation({ from, shiftTime }: PropsType) {
  const t = useTranslate()
  const today = new LocalDate("DAY")
  const jumptToToday = () => {
    const delta = (today.getTime() - from.getTime()) / (1000 * 60 * 60 * 24)
    shiftTime(delta)
  }
  return (
    <Nav>
      <NavArrowLeft onClick={() => shiftTime(-1)} />
      {from.getTime() > today.getTime() && (
        <TodayButton onClick={jumptToToday}>{t("today")}</TodayButton>
      )}
      {from.isToday() ? (
        <TodayButton onClick={jumptToToday}>
          {from.format("short", t)}
        </TodayButton>
      ) : (
        <P16>{from.format("short", t)}</P16>
      )}
      {from.getTime() < today.getTime() && (
        <TodayButton onClick={jumptToToday}>{t("today")}</TodayButton>
      )}

      <NavArrowRight onClick={() => shiftTime(1)} />
    </Nav>
  )
}
