export default function copyTextToClipboard(text: string): boolean {
  // const textArea = document.createElement("textarea")
  // textArea.value = text

  // // Avoid scrolling to bottom
  // textArea.style.top = "0"
  // textArea.style.left = "0"
  // textArea.style.position = "fixed"

  // document.body.appendChild(textArea)
  // textArea.focus()
  // textArea.select()

  const successful = false

  try {
    navigator.clipboard.writeText(text)
    console.info("Text sucessfully copied to clipboard.")
  } catch (error) {
    console.error("Fallback: Oops, unable to copy", error)
  }

  return successful
}
