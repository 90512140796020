import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import { PlanningDrawer } from "components/PlanningDrawer/PlanningDrawer"
import useUser from "graphql/users/useUser"
import useUserFromId from "graphql/users/useUserFromId"
import useMediaQueries from "hooks/useMediaQueries"
import React, { useEffect, useRef, useState } from "react"
import { Route, Switch, useParams } from "react-router-dom"
import { EditionTimeFrame, User } from "types"
import UTCDate from "utils/UTCDate"

import SlotsEditorContent from "./SlotsEditorContent/SlotsEditorContent"

interface RouterPropsType {
  editionTimeFrame: EditionTimeFrame
  setEditionTimeFrame: React.Dispatch<React.SetStateAction<EditionTimeFrame>>
}

interface PropsType extends RouterPropsType {
  from: UTCDate
  to: UTCDate
  selectedUser: User
}

function SlotsEditorComponent({
  from,
  to,
  selectedUser,
  setEditionTimeFrame,
}: PropsType) {
  const { isNarrowScreen } = useMediaQueries()
  const { openDrawer, setOpenDrawer } = usePlanningContext()
  const [isOpen, setIsOpen] = useState(false)

  const handleClose = useRef(() => {
    setEditionTimeFrame({})
    setOpenDrawer(null)
  })

  useEffect(() => {
    document.body.addEventListener("keydown", (e) => {
      if (e.code === "Escape") handleClose.current()
    })
    return () => {
      document.body.removeEventListener("keydown", () => {
        //
      })
    }
  }, [handleClose])

  useEffect(() => {
    if (openDrawer === "SLOTS_EDITOR") setIsOpen(true)

    return () => setIsOpen(false)
  }, [openDrawer])

  return (
    <PlanningDrawer
      open={isOpen}
      desktopWidth={isNarrowScreen ? "calc(100vw - 32px)" : "920px"} // TODO check drawer width mgmt
      onClose={() => {
        setIsOpen(false)
      }}
    >
      <SlotsEditorContent
        from={from}
        to={to}
        selectedUser={selectedUser}
        setEditionTimeFrame={setEditionTimeFrame}
      />
    </PlanningDrawer>
  )
}

function SlotsEditorRouter({
  editionTimeFrame,
  setEditionTimeFrame,
}: RouterPropsType) {
  const { user: me } = useUser()
  const { userId } = useParams<{ userId?: string }>()
  const { user: selectedUser } = useUserFromId(userId ?? "")

  const from = editionTimeFrame.from
    ? new UTCDate(editionTimeFrame.from)
    : undefined
  const to = editionTimeFrame.to ? new UTCDate(editionTimeFrame.to) : undefined

  if (!from || !to) return <></>

  return (
    <Switch>
      <Route path="/user/:userId">
        {() => {
          if (!selectedUser) return <></>

          return (
            <SlotsEditorComponent
              from={from}
              to={to}
              selectedUser={selectedUser}
              editionTimeFrame={editionTimeFrame}
              setEditionTimeFrame={setEditionTimeFrame}
            />
          )
        }}
      </Route>
      <Route path={["/", "/planning"]}>
        {() => {
          if (!me) return <></>
          return (
            <SlotsEditorComponent
              from={from}
              to={to}
              selectedUser={me}
              editionTimeFrame={editionTimeFrame}
              setEditionTimeFrame={setEditionTimeFrame}
            />
          )
        }}
      </Route>
    </Switch>
  )
}

export { SlotsEditorRouter as default }
