import PageHeader from "components/Layout/PageLayout/PageHeader/PageHeader"
import BurgerButton from "components/Layout/PageLayout/PageHeader/PageTitle/BurgerButton/BurgerButton"
import PageTitle from "components/Layout/PageLayout/PageHeader/PageTitle/PageTitle"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { User } from "types"
import { Button, colors } from "ui"
import { Edit } from "ui/icons"
import getUserDisplayName from "utils/getUserDisplayName"

import MeAvatarPicker from "../MeAvatarPicker/MeAvatarPicker"

const MobileEditIcon = styled(Edit)`
  stroke: ${colors.grey1};
  margin-left: auto;
`

const NarrowScreenEditButton = styled(Button)`
  margin-right: 20vw;
`

interface PropsType {
  me: User
  setOpenUserInfosDialog: (open: boolean) => void
}

export default function MePageHeader({
  me,
  setOpenUserInfosDialog,
}: PropsType) {
  const t = useTranslate()
  const { isMobile, isNarrowScreen } = useMediaQueries()
  const [openPhotoDialog, setOpenPhotoDialog] = useState(false)

  if (isMobile)
    return (
      <PageHeader>
        <BurgerButton />
        <PageTitle title={getUserDisplayName(me, me.company.flags)} />
        <MobileEditIcon onClick={() => setOpenUserInfosDialog(true)} />
      </PageHeader>
    )

  if (isNarrowScreen)
    return (
      <PageHeader>
        <MeAvatarPicker
          me={me}
          open={openPhotoDialog}
          onClose={() => setOpenPhotoDialog(false)}
        />
        <PageTitle title={getUserDisplayName(me, me.company.flags)} />
        <NarrowScreenEditButton onClick={() => setOpenUserInfosDialog(true)}>
          {t("Edit")}
        </NarrowScreenEditButton>
      </PageHeader>
    )

  return <></>
}
