import { UTCSlot } from "types"
import getLabelFromStatus from "utils/getLabelFromStatus"
import LocationTree from "utils/LocationTree"

const genericLabels = ["Remote", "Other", "Off"]

function isGenericLabel(label: string) {
  if (label === "") return true
  return genericLabels.some((l) => l === label)
}

export default function getHoverInfo(
  slot: UTCSlot,
  activeLocations: LocationTree[],
  t: (id: string, values?: Record<string, string> | undefined) => string
) {
  const location = slot.locationId
    ? LocationTree.getLocationNode(activeLocations, slot.locationId)
    : undefined

  if (!isGenericLabel(slot.label)) return slot.label
  if (location) return [...location.breadCrumbs, location.name].join(" / ")
  return slot.value !== null ? t(getLabelFromStatus(slot.value)) : t("Not set")
}
