import useSetTeamName from "graphql/users/useSetTeamName"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { UserWithTeam } from "types"
import { Button, Dialog, mediaQueries, TextField } from "ui"

const Form = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media ${mediaQueries.isMobile} {
    .actions {
      flex-direction: column;
      gap: 4px;

      button {
        width: 100%;
      }
    }
  }
`

interface PropsType {
  me: UserWithTeam
  open: boolean
  onClose: () => void
}

export default function RenameTeamDialog({ me, open, onClose }: PropsType) {
  const t = useTranslate()

  const [teamName, setTeamName] = useState<string>(me.teamName)

  const { setTeamName: editTeamName } = useSetTeamName()

  const reset = () => {
    setTeamName("")
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        reset()
        onClose()
      }}
      title={t("rename team")}
      leftButton={
        <Button
          danger
          onClick={() => {
            onClose()
          }}
        >
          {t("Cancel")}
        </Button>
      }
      rightButton={
        <Button
          onClick={() => {
            editTeamName(teamName)
            onClose()
          }}
        >
          {t("Confirm")}
        </Button>
      }
    >
      <Form>
        <TextField
          label={t("Name")}
          initialValue={teamName}
          placeholder={"Nom de votre équipe"}
          handleChange={(str) => setTeamName(str)}
        />
      </Form>
    </Dialog>
  )
}
