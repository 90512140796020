import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { colors, mediaQueries, P16 } from "ui"
import { ArrowRight } from "ui/icons"
import LocationTree from "utils/LocationTree"

import { LocationsListFromCategory } from "../../../ActiveLocationsList/ActiveLocationsList"

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0 16px 0 32px;
  border-right: solid 1px ${colors.grey3};

  @media ${mediaQueries.isNarrowScreen} {
    padding: 0 16px 4px 16px;
  }

  @media ${mediaQueries.isMobile} {
    padding: 4px 0 0 0;
    border-right: none;
  }
`

interface PropsType {
  category: LocationTree
}
export default function CategoryLocationsList({ category }: PropsType) {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()
  return (
    <>
      <Header>
        {!isMobile && <ArrowRight />}
        <P16>
          {t("category-children-list-title", { categoryName: category.name })}
          {isMobile && <>{" :"}</>}
        </P16>
      </Header>
      <LocationsListFromCategory category={category} />
    </>
  )
}
