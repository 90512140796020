import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React from "react"
import { P16Bold } from "ui"
import UTCDate from "utils/UTCDate"

type Breakpoint = "DESKTOP" | "NARROW" | "MOBILE"

interface IndexedMonth {
  month: string
  index: number
}

interface WeightedMonth extends IndexedMonth {
  occurrences: number
}

type TranslateFunction = (
  id: string,
  values?: Record<string, string> | undefined
) => string

function reduceToWeightedMonths(prev: WeightedMonth[], curr: IndexedMonth) {
  const f = prev.find(({ index }) => index === curr.index)
  if (f)
    return [
      ...prev.filter(({ index }) => index !== curr.index),
      { ...curr, occurrences: f.occurrences + 1 },
    ]
  return [...prev, { ...curr, occurrences: 1 }]
}

function filterMeaningfulMonths({ occurrences }: WeightedMonth) {
  return occurrences > 5
}

function sortAsc({ index: ia }: WeightedMonth, { index: ib }: WeightedMonth) {
  // specific condition for the month of January which has an index 0
  if ((ia === 0 && ib === 11) || (ia === 11 && ib === 0)) return ib - ia
  return ia - ib
}

function getMonthsArray(from: UTCDate, to: UTCDate) {
  const arr: IndexedMonth[] = []
  for (
    const cursor = new UTCDate(from);
    cursor.getTime() < to.getTime();
    cursor.shift("DAY", 1)
  ) {
    arr.push({ month: cursor.getMonthAsString(), index: cursor.getMonth() })
  }
  return arr
}

function getMonthModeName({
  from,
  to,
  selectedDate,
  t,
}: {
  from: UTCDate
  to: UTCDate
  selectedDate: UTCDate
  t: TranslateFunction
  breakPoint: Breakpoint
}) {
  const months = getMonthsArray(from, to)
  const names = months
    .reduce(reduceToWeightedMonths, [])
    .filter(filterMeaningfulMonths)
    .sort(sortAsc)
    .map(({ month }) => month)

  if (names.length === 0) return ""

  const endOfTheYear =
    names.length > 1 && names[0] === "December" && names[1] === "January"

  if (names.length > 1 && endOfTheYear) {
    return `${t(names[0]).substring(0, 3)}./${t(names[1]).substring(0, 3)}. ${
      selectedDate.getFullYear() + 1
    } `
  }
  if (names.length > 1) {
    return `${t(names[0]).substring(0, 3)}./${t(names[1]).substring(0, 3)}. ${
      endOfTheYear ? selectedDate.getFullYear() + 1 : selectedDate.getFullYear()
    }`
  }

  return `${t(names[0])} ${selectedDate.getFullYear()}`
}

function getWeekModeName({
  from,
  to,
  selectedDate,
  t,
  breakPoint,
}: {
  from: UTCDate
  to: UTCDate
  selectedDate: UTCDate
  t: TranslateFunction
  breakPoint: Breakpoint
}) {
  const months = getMonthsArray(from, to)
  const names = months
    .reduce(reduceToWeightedMonths, [])
    .sort(sortAsc)
    .map(({ month }) => month)

  if (names.length === 0) return ""

  const endOfTheYear =
    names.length > 1 && names[0] === "December" && names[1] === "January"

  if (names.length > 1) {
    return `${t(names[0]).substring(0, 3)}./${t(names[1]).substring(0, 3)}. ${
      endOfTheYear ? selectedDate.getFullYear() + 1 : selectedDate.getFullYear()
    }`
  }

  if (names.length > 1 && !endOfTheYear) {
    return `${t(names[0]).substring(0, 3)}./${t(names[1]).substring(
      0,
      3
    )}. ${selectedDate.getFullYear()}`
  }

  if (breakPoint === "MOBILE") {
    return `${t(names[0])} ${selectedDate.getFullYear()}`
  }

  if (breakPoint === "NARROW") {
    return `${t(names[0]).slice(0, 3)}. ${selectedDate.getFullYear()}`
  }

  return `${t(names[0])} ${selectedDate.getFullYear()}`
}

export default function SelectedPeriodName() {
  const t = useTranslate()
  const { isMobile, isNarrowScreen } = useMediaQueries()

  const { from, to, selectedDate, viewMode } = usePlanningContext()

  const breakPoint: Breakpoint = isMobile
    ? "MOBILE"
    : isNarrowScreen
    ? "NARROW"
    : "DESKTOP"

  const getSelectedMonths = () => {
    if (viewMode === "MONTH") {
      return getMonthModeName({ from, to, selectedDate, t, breakPoint })
    }

    return getWeekModeName({ from, to, selectedDate, t, breakPoint })
  }

  return (
    <P16Bold style={{ textAlign: viewMode === "WEEK" ? "left" : "center" }}>
      {getSelectedMonths()}
    </P16Bold>
  )
}
