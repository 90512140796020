import { gql, useMutation } from "@apollo/client"
import { spacing } from "@mui/system"
import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useOIDCConfig, { OIDCConfig } from "graphql/admin/useOIDCConfig"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components/macro"
import {
  Alert as UiAlert,
  Button,
  colors,
  L12GreyBold,
  P14,
  P14Bold,
  P16,
  Textarea,
  TextField,
} from "ui"
import { Copy } from "ui/icons"
import copyTextToClipboard from "utils/copyTextToClipboard"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 500px;

  .texts-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    .value {
      color: ${colors.purple};
      max-width: 30ch;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    svg {
      color: ${colors.purple};
      cursor: pointer;
    }
  }

  .text {
    color: ${colors.grey1};
    font-size: 12px;
  }
`

const SAVE_OIDC_CONFIG = gql`
  mutation saveOIDCConfig($config: OIDCConfigInput!) {
    saveOIDCConfig(config: $config)
  }
`

const Alert = styled(UiAlert)(spacing)

interface ConfigOIDCProps {
  config: OIDCConfig | null
  provider: "RAW"
  onClose: () => void
}

function ConfigureOIDCForm({ config, provider, onClose }: ConfigOIDCProps) {
  const t = useTranslate()
  const { addToast } = useToasts()

  const [changed, setChanged] = React.useState(false)
  const [clientId, setClientId] = React.useState<string>(config?.clientId || "")
  const [issuer, setIssuer] = React.useState<string>(config?.issuer || "")
  const [clientSecret, setClientSecret] = React.useState<string>(
    config?.clientSecret || ""
  )
  const [providerError, setProviderError] = React.useState<string | null>(null)

  const [saveOIDCConfigMutation] = useMutation(SAVE_OIDC_CONFIG)

  const saveOIDCConfig = async () => {
    try {
      await saveOIDCConfigMutation({
        variables: {
          config: {
            provider: provider,
            clientId: clientId,
            clientSecret: clientSecret,
            issuer: issuer,
          },
        },
      })
      setProviderError(null)
      onClose()
    } catch (error) {
      setProviderError(error.message)
    }
    setChanged(false)
  }

  const providerSsoURL = `${process.env.REACT_APP_URL}/sso`

  return (
    <Container>
      <L12GreyBold>{t("Service Provider")}</L12GreyBold>

      <div className="texts-container">
        <P14>{t("Callback URL")} :</P14>
        <P14Bold className="value">{providerSsoURL}</P14Bold>
        <Copy
          onClick={() => {
            copyTextToClipboard(providerSsoURL)
            addToast(t("text copied confirmation"), { appearance: "success" })
          }}
        />
      </div>

      <TextField
        label={t("Identity Provider")}
        placeholder={t("Client ID")}
        initialValue={clientId}
        handleChange={(e) => {
          setChanged(true)
          setClientId(e)
        }}
      />

      <Textarea
        placeholder={t("Client Secret")}
        value={clientSecret}
        handleChange={(e) => {
          setChanged(true)
          setClientSecret(e)
        }}
      />

      <TextField
        placeholder={t("Issuer URL")}
        initialValue={issuer}
        handleChange={(e) => {
          setChanged(true)
          setIssuer(e)
        }}
      />

      {providerError && (
        <Alert mt={2} mb={1} severity="error">
          <P16>{providerError}</P16>
        </Alert>
      )}
      <Button
        disabled={!(changed && clientId && issuer && clientSecret)}
        onClick={saveOIDCConfig}
      >
        {t("Save OIDC configuration")}
      </Button>
    </Container>
  )
}

export default function ConfigureOIDC({
  provider,
  onClose,
}: {
  provider: "RAW"
  onClose: () => void
}) {
  const { config, loading, error } = useOIDCConfig(provider)

  if (loading || error) {
    return <LoaderOrErrorComponent loading={loading} error={error} />
  }

  return (
    <ConfigureOIDCForm config={config} onClose={onClose} provider={provider} />
  )
}
