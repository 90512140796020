import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { colors, P16 } from "ui"
import { Building, Plus, Zone } from "ui/icons"

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`

const Header = styled.div`
  display: flex;
  align-items: center;
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 20px;
  padding-bottom: 8px;
  width: 100%;
`

const Item = styled.li`
  display: flex;
  align-items: center;
`

const ItemButton = styled.button`
  padding: 2px;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;

  &:hover {
    background: hsla(${colors.blackHSL}, 0.04);
  }

  &:active {
    background: hsla(${colors.blackHSL}, 0.24);
  }
`

interface PropsType {
  updateAction: (action: undefined | "ADD_LOCATION" | "ADD_CATEGORY") => void
}

export default function CreateEntry({ updateAction }: PropsType) {
  const t = useTranslate()

  return (
    <Layout>
      <Header>
        <Plus />
        <P16>{t("create new")}</P16>
      </Header>
      <List>
        <Item>
          <ItemButton
            onClick={() => {
              updateAction("ADD_LOCATION")
            }}
          >
            <Building />
            <P16>{t("space")}</P16>
          </ItemButton>
        </Item>
        <Item>
          <ItemButton onClick={() => updateAction("ADD_CATEGORY")}>
            <Zone />
            <P16>{t("zone")}</P16>
          </ItemButton>
        </Item>
      </List>
    </Layout>
  )
}
