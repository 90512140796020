import React from "react"
import styled from "styled-components"
import { UTCSlot } from "types"
import getColorFromStatus from "utils/getColorFromStatus"
import UTCDate from "utils/UTCDate"

interface SlotValues {
  date: number
  morning: string | null
  afternoon?: string | null
}

interface SlotsProps {
  displayWeekends: boolean
}

const Slots = styled.div<SlotsProps>`
  display: grid;
  align-items: center;
  justify-items: center;
  width: 100%;
  padding: 0px 8px 12px 0;
  ${({ displayWeekends }) => {
    if (displayWeekends) return "grid-template-columns: repeat(7, 1fr);"
    return "grid-template-columns: repeat(5, 1fr);"
  }}
`

interface SlotProps {
  morning: string | null
  afternoon: string | null | undefined
}

const Slot = styled.div<SlotProps>`
  height: 8px;
  width: 8px;
  border-radius: 8px;

  ${({ morning, afternoon }) => {
    if (afternoon !== undefined)
      return `background: linear-gradient(
            to right,
            ${getColorFromStatus(morning)} 0%,
            ${getColorFromStatus(morning)}  50%,
            ${getColorFromStatus(afternoon)}  50%,
            ${getColorFromStatus(afternoon)}  100%
        );`
  }}
`

interface PropsType {
  slots: UTCSlot[]
  displayWeekends: boolean
}

export default function WeeklySlots({ slots, displayWeekends }: PropsType) {
  const slotsValues = slots.reduce((acc: SlotValues[], el: UTCSlot) => {
    if (el.date.isMorning()) {
      return [...acc, { date: el.date.getDate(), morning: el.value }]
    } else
      return acc.map((e) => {
        if (e.date === el.date.getDate()) return { ...e, afternoon: el.value }
        else return e
      })
  }, [])

  return (
    <Slots displayWeekends={displayWeekends}>
      {slotsValues.map((el) =>
        el.date === new UTCDate("DAY").getDate() ? (
          <div key={el.date} />
        ) : (
          <Slot key={el.date} morning={el.morning} afternoon={el.afternoon} />
        )
      )}
    </Slots>
  )
}
