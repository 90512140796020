import useOccupancyRate from "graphql/stats/useOccupancyRate"
import React, { useEffect } from "react"
import LocationTree from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

interface Propstype {
  locations: LocationTree[]
  from: UTCDate
  to: UTCDate
  includeWeekends: boolean
  totalOccupancyRate:
    | { value: number; booked: number; total: number }
    | undefined
  setTotalOccupancyRate: (occupancyRate: {
    value: number
    booked: number
    total: number
  }) => void
}

export default function OccupancyBar({
  locations,
  from,
  to,
  includeWeekends,
  totalOccupancyRate,
  setTotalOccupancyRate,
}: Propstype) {
  const { occupancyRate, loading, error } = useOccupancyRate({
    from,
    to,
    locationIds: locations.map((l) => l.id),
    includeWeekends,
  })

  useEffect(() => {
    if (loading || error) return

    if (
      totalOccupancyRate === undefined ||
      occupancyRate !== totalOccupancyRate
    ) {
      setTotalOccupancyRate(occupancyRate)
    }
  }, [
    loading,
    error,
    totalOccupancyRate,
    locations,
    occupancyRate,
    setTotalOccupancyRate,
  ])

  return <></>
}
