import { ApolloError, gql, useMutation } from "@apollo/client"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useLogEvent from "graphql/misc/useLogEvent"
import useTranslate from "intl/useTranslate"
import { useState } from "react"
import { CompanyFile } from "types"
import uploadFileToSignedURL from "utils/uploadFileToSignedURL"

import updateCache from "./updateCache"

const QUERY = gql`
  mutation addCompanyFile($file: CompanyFileInput!) {
    addCompanyFile(file: $file) {
      id
      name
      rawName
      url
      type
      companyId
      signedUrl
    }
  }
`

interface SavedCompanyFile extends CompanyFile {
  signedUrl: string
}

export default function useAddCompanyFiles() {
  const [mutate] = useMutation<{
    addCompanyFile: SavedCompanyFile
  }>(QUERY)
  const t = useTranslate()
  const { addToast } = useToasts()
  const logEvent = useLogEvent()

  const [error, setError] = useState<ApolloError | null>(null)
  const [loading, setLoading] = useState(false)

  const addCompanyFile = async ({
    file,
    filename,
  }: {
    file: File
    filename: string
  }) => {
    try {
      setLoading(true)
      const filePath = file.name.split("/")
      const rawFilename = `${filePath[filePath.length - 1]}`

      await mutate({
        variables: {
          file: {
            name: filename,
            rawName: rawFilename,
          },
        },
        update: async (cache, { data }) => {
          const signedUrl = data?.addCompanyFile?.signedUrl
          if (signedUrl) {
            try {
              await uploadFileToSignedURL(file, signedUrl)
              logEvent("company_file_uploaded", { file })
            } catch (e) {
              console.error(e)
              logEvent("company_file_upload_failed", {
                file,
                message: e.message,
              })
            }
          }
          updateCache({
            cache,
            data,
            mutationName: "addCompanyFile",
          })
        },
      })
      addToast(t("company_file--uploaded"), {
        appearance: "success",
      })
    } catch (e) {
      console.error(e.message)
      setError(error)
      addToast(e.message, { appearance: "error" })
    } finally {
      setLoading(false)
    }
  }

  return { addCompanyFile, loading, error }
}
