import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function Refresh({
  stroke,
  strokeWidth,
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      strokeWidth={strokeWidth ?? 1.5}
      stroke={stroke ? stroke : "currentColor"}
      {...rest}
    >
      <path
        d="M21.168 8A10.003 10.003 0 0012 2C6.815 2 2.55 5.947 2.05 11"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M17 8h4.4a.6.6 0 00.6-.6V3M2.881 16c1.544 3.532 5.068 6 9.168 6 5.186 0 9.45-3.947 9.951-9"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M7.05 16h-4.4a.6.6 0 00-.6.6V21"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </SvgIcon>
  )
}
