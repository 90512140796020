import { gql, useMutation } from "@apollo/client"
import useCustomErrorToast from "hooks/useCustomErrorToast"
import { useState } from "react"

const STOP_DEMO_MUTATION = gql`
  mutation stopDemo {
    stopDemo
  }
`

export default function useStopDemo() {
  const [loading, setLoading] = useState(false)
  const { addToastError } = useCustomErrorToast()
  const [mutate] = useMutation(STOP_DEMO_MUTATION)
  const stopDemo = async () => {
    setLoading(true)
    try {
      await mutate()
      window.location.reload()
    } catch (e) {
      console.error("Error while stopping demo : ", e.message)
      addToastError({ message: `generic error` })
      setLoading(false)
    }
  }
  return {
    loading,
    stopDemo,
  }
}
