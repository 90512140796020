import { Menu } from "@mui/material"
import useTranslate from "intl/useTranslate"
import React, { useRef, useState } from "react"
import styled from "styled-components"
import { ServiceIconType } from "types"
import { colors, P14 } from "ui"
import { ArrowDropDown } from "ui/icons"
import {
  defaultServiceIcon,
  getServiceIcon,
  serviceIcons,
} from "utils/getServiceIcons"

const Button = styled.button.attrs({ role: "select" })`
  display: flex;
  background: #fafafa;
  border: none;
  padding: 8px;
  gap: 8px;
  width: min-content;

  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  .arrow-dropdown {
    width: 16px;
    margin-left: auto;
    fill: ${colors.grey1};
  }
`

const IconsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px;
  cursor: pointer;
`

const IconsListItem = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 6px;
  border-radius: 4px;

  &:hover {
    background: hsla(${colors.blackHSL}, 0.04);
  }

  > svg {
    stroke: ${colors.black};
    fill: ${colors.black};
  }
`

interface PropsType {
  initialIconName?: string
  onChange: (icon: ServiceIconType) => void
}

export default function IconPicker({ initialIconName, onChange }: PropsType) {
  const t = useTranslate()
  const ref = useRef<HTMLButtonElement>(null)

  const [isDropDownOpen, setIsDropDownOpen] = useState(false)
  const [selection, setSelection] = useState<ServiceIconType | undefined>(
    initialIconName ? getServiceIcon(initialIconName) : undefined
  )

  const updateSelection = (serviceIcon: ServiceIconType) => {
    setSelection(serviceIcon)
    setIsDropDownOpen(false)
    onChange(serviceIcon)
  }

  return (
    <>
      <Button ref={ref} onClick={() => setIsDropDownOpen(true)}>
        {selection !== undefined ? selection.icon : defaultServiceIcon.icon}
        <ArrowDropDown className="arrow-dropdown" />
      </Button>
      <Menu
        open={isDropDownOpen}
        anchorEl={ref.current ?? undefined}
        onClose={() => setIsDropDownOpen(false)}
      >
        <IconsList>
          {serviceIcons
            .filter((si) => si.name !== "default")
            .map((s) => (
              <IconsListItem
                key={`${s.name}`}
                onClick={() => updateSelection(s)}
              >
                {s.icon}
                <P14>{t(s.description)}</P14>
              </IconsListItem>
            ))}
        </IconsList>
      </Menu>
    </>
  )
}
