import { useDefaultHybridRuleScore } from "hooks/useHybridRuleScore"
import React, { useEffect } from "react"
import { DefaultHybridRule } from "types"
import UTCDate from "utils/UTCDate"

import { LoadedScoreEventDetail, LoadingScore } from "../../types"

export default function DefaultScoreNullComponent({
  score,
  addScoreAsLoaded,
}: {
  score: LoadingScore<DefaultHybridRule>
  addScoreAsLoaded: (detail: LoadedScoreEventDetail) => void
}) {
  const {
    score: defaultScore,
    loading,
    error,
  } = useDefaultHybridRuleScore(new UTCDate(), score.rule)

  useEffect(() => {
    if (error) {
      return addScoreAsLoaded({
        id: score.rule.id,
        concernedUserIds: [],
        compliantUserIds: [],
      })
    }
    if (!loading && defaultScore) {
      return addScoreAsLoaded({
        id: defaultScore.id,
        concernedUserIds: defaultScore.score?.concernedUserIds ?? [],
        compliantUserIds: defaultScore.score?.compliantUserIds ?? [],
      })
    }
  }, [loading, error, addScoreAsLoaded, defaultScore, score])
  return <></>
}
