import { gql } from "@apollo/client"
import {
  Checkbox,
  FormHelperText,
  LinearProgress,
  Link as MuiLink,
  TextField as MuiTextField,
} from "@material-ui/core"
import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import { Formik } from "formik"
import useProtectedMutation from "graphql/useProtectedMutation"
import useMediaQueries from "hooks/useMediaQueries"
import useTransactableSignupDetails from "hooks/useTransactableSignupDetails"
import useTranslate from "intl/useTranslate"
import React, { useCallback, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useIntl } from "react-intl"
import { useHistory } from "react-router"
import styled from "styled-components/macro"
import { Button, colors, H3, H4, mediaQueries, P14, P16 } from "ui"
import { Cancel } from "ui/icons"
import { CheckOff, CheckOn } from "ui/icons"
import { loginWithToken } from "utils/firebase"
import * as Yup from "yup"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 480px;

  @media ${mediaQueries.isMobile} {
    width: 90%;
    margin-left: 10px;
    margin-right: 10px;
  }

  h3 {
    color: ${colors.purple};
  }

  h4 {
    margin-top: 10px;
  }

  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 370px;
    margin-top: 32px;
    gap: 16px;

    @media ${mediaQueries.isMobile} {
      width: 280px;
    }

    .terms-of-service {
      display: flex;
      flex-direction: column;
      padding: 9px 0;

      .checkbox-line {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
`
const FormikTextField = styled(MuiTextField)`

    & .MuiOutlinedInput-root {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: ${colors.black};
      height: 40px;
      border: 1px solid ${colors.grey2};
    }
    & .MuiOutlinedInput-root :hover {
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.04),
          rgba(0, 0, 0, 0.04)
        ),
        ${colors.grey4};
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 10px;
      color: ${colors.black};
      border-radius: 4px;
    }
    & .MuiOutlinedInput-root.Mui-disabled {
      background: ${colors.grey4};
      cursor: not-allowed;
      color: ${colors.grey1};
    }
    & .MuiOutlinedInput-root.Mui-focused {
      background: linear-gradient(0deg, ${colors.white}, ${colors.white}),
        ${colors.grey4};
      border: 2px solid ${colors.green};
      color: ${colors.black};
      height: 40px;
    }
    & .MuiOutlinedInput-notchedOutline {
      border: none;
    }
    & .MuiInputAdornment-root {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: ${colors.black};
      height: 40px;
    }
  }
`

const REGISTER_COMPANY_TRANSACTABLE = gql`
  mutation RegisterCompanyTransactable(
    $companyToRegister: InputTransactableCompany!
  ) {
    registerCompanyTransactable(companyToRegister: $companyToRegister) {
      companyId
      signinToken
    }
  }
`

const ConfirmationMessage = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  p {
    margin-left: 12px;
  }
`

function SignupWithMSTransactable() {
  const t = useTranslate()
  const history = useHistory()
  const { isMobile } = useMediaQueries()

  const intl = useIntl()
  const registerMutation = useProtectedMutation(
    "registerCompanyTransactable",
    REGISTER_COMPANY_TRANSACTABLE
  )

  const { loading, error, details } = useTransactableSignupDetails()

  const [tosCheckBoxTouched, setTosCheckBoxTouched] = useState(false)
  const [registering, setRegistering] = useState(false)

  const onSubmit = useCallback(
    async (
      values: {
        company: string
        submit: boolean
      },
      { setErrors, setStatus, setSubmitting }: any
    ) => {
      try {
        setRegistering(true)
        const {
          data: {
            registerCompanyTransactable: { companyId, signinToken },
          },
        } = await registerMutation({
          variables: {
            companyToRegister: {
              user: details?.user,
              companyName: values.company,
              subscription: details?.subscription,
            },
          },
        })
        const res = await fetch(
          `${process.env.REACT_APP_API}/transactable/update`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              companyId,
              subscriptionId: details?.subscription.id,
              state: "ACTIVE",
            }),
          }
        )
        if (res.ok) {
          await loginWithToken(signinToken)
        } else {
          throw new Error("Failed to update subscription")
        }
      } catch (error) {
        setRegistering(false)
        const message = error.message || "Something went wrong"
        setStatus({ success: false })
        setErrors({ submit: message })
        setSubmitting(false)
      }
    },
    [details, registerMutation]
  )

  useEffect(() => {
    if (details?.alreadyActivated && details?.signinToken) {
      loginWithToken(details?.signinToken).then(() => {
        history.push("/admin/users")
      })
    }
  }, [history, details])

  if (loading || error || !details || details?.alreadyActivated) {
    return (
      <LoaderOrErrorComponent
        loading={loading || !!details?.alreadyActivated}
        error={error}
      />
    )
  }

  return (
    <Container>
      <Helmet title="Sign Up" />
      <H3>{t("configure your company account here")}</H3>

      <H4 className=".plan">{details.subscription.name}</H4>

      <P16>
        {details.subscription.quantity}
        &nbsp;
        {details.subscription.quantity > 1 ? t("users") : t("user")}
      </P16>

      <Formik
        initialValues={{
          company: details.companyName || "",
          termsOfService: false,
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          company: Yup.string().max(255).required(t("Company is required")),
          termsOfService: Yup.boolean().oneOf(
            [true],
            t("You must accept terms of service")
          ),
        })}
        onSubmit={onSubmit}
        validateOnChange
        validateOnMount
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          isValid,
        }) => (
          <form onSubmit={handleSubmit} className="form">
            {errors.submit && (
              <ConfirmationMessage>
                <Cancel />
                <P16>{errors.submit}</P16>
              </ConfirmationMessage>
            )}
            <FormikTextField
              type="text"
              name="company"
              placeholder={t("Company name")}
              value={values.company}
              error={Boolean(touched.company && errors.company)}
              helperText={touched.company && errors.company}
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              required
              size="small"
            />

            <div className="terms-of-service">
              <div className="checkbox-line">
                <Checkbox
                  name="termsOfService"
                  value={values.termsOfService}
                  onChange={(e) => {
                    setTosCheckBoxTouched(true)
                    handleChange(e)
                  }}
                  style={{ color: colors.black, padding: "0 9px 0 0" }}
                  icon={<CheckOff />}
                  checkedIcon={<CheckOn />}
                />
                {intl.locale === "fr" && (
                  <P14>
                    J&apos;accepte les
                    <MuiLink
                      href="https://offishall.io/conditions-generales-dutilisation-du-compte"
                      target="_blank"
                    >
                      &nbsp;conditions d&apos;utilisation
                    </MuiLink>
                  </P14>
                )}
                {intl.locale === "en" && (
                  <P14>
                    I accept Offishall&apos;s
                    <MuiLink
                      href="https://en.offishall.io/conditions-generales-dutilisation-du-compte"
                      target="_blank"
                    >
                      &nbsp;terms of service
                    </MuiLink>
                  </P14>
                )}
              </div>
              {Boolean(errors.termsOfService) && (
                <FormHelperText error>
                  {tosCheckBoxTouched && errors.termsOfService}
                </FormHelperText>
              )}
            </div>

            <Button
              type="submit"
              disabled={!isValid || registering}
              width={isMobile ? 280 : 370}
            >
              {t("Sign up")}
            </Button>
            {registering && <LinearProgress style={{ marginTop: 5 }} />}
          </form>
        )}
      </Formik>
    </Container>
  )
}

export default SignupWithMSTransactable
