import React from "react"
import styled from "styled-components"
import { colors } from "ui"
import { CheckOff, CheckOn } from "ui/icons"

const Layout = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

const Input = styled.input`
  position: absolute;
  opacity: 0;
  z-index: 1;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`

interface SimplePropsType {
  className?: string
  checked: boolean
  disabled?: boolean
  onChange: () => void
}

export function Checkbox({
  className,
  checked,
  disabled,
  onChange,
}: SimplePropsType) {
  return (
    <Layout className={className}>
      <Input
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={(e) => {
          if (disabled) return
          e.stopPropagation()
          onChange()
        }}
      />
      {checked && <CheckOn stroke={disabled ? colors.grey2 : undefined} />}
      {!checked && <CheckOff stroke={disabled ? colors.grey2 : undefined} />}
    </Layout>
  )
}
