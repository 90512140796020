import React, { useEffect, useRef, useState } from "react"
import { DropDownButton, DropDownMenu, P14 } from "ui"

import { DropDownChipButton } from "./DropDownChipButton"

interface PropsType {
  title?: string
  icon?: JSX.Element
  options: { name: string; value: any }[]
  disabled?: boolean
  minWidth?: string
  multiSelection?: boolean
  state?: undefined | string | string[]
  checkBox?: boolean
  onSelect: (obj: { name: string; value: any }) => void
}

export function DropDown({
  title,
  options,
  disabled,
  minWidth,
  icon,
  onSelect,
  multiSelection,
  checkBox,
  state,
}: PropsType) {
  const dropDownButtonRef = useRef<HTMLButtonElement>(null)

  const [dropDownMenuOpen, setDropDownMenuOpen] = useState(false)

  const [dynamicTitle, setDynamicTitle] = useState(
    title ? title : options[0].name
  )

  const handleSelect = (obj: { name: string; value: any }) => {
    onSelect(obj)
    setDynamicTitle(obj.name)
    setDropDownMenuOpen(false)
  }

  // Change the title of the dropdown without having to select a dropdown data.
  // Example : if a reset button is used, the title must also be reset
  useEffect(() => {
    setDynamicTitle(title ? title : options[0].name)
  }, [title, options])

  return (
    <>
      <DropDownButton
        ref={dropDownButtonRef}
        onClick={() => setDropDownMenuOpen((prev) => !prev)}
        disabled={disabled}
        minWidth={minWidth}
        icon={icon}
        dropDownMenuOpen={dropDownMenuOpen}
      >
        <P14>{dynamicTitle}</P14>
      </DropDownButton>
      <DropDownMenu
        open={dropDownMenuOpen}
        options={options}
        onSelect={handleSelect}
        anchorEl={dropDownButtonRef.current}
        multiSelection={multiSelection}
        onClick={() => setDropDownMenuOpen(false)}
        state={state}
        checkBox={checkBox}
        parentWidth={dropDownButtonRef.current?.getBoundingClientRect().width}
      />
    </>
  )
}

export function DropDownChip({ title, icon, options, onSelect }: PropsType) {
  const dropDownButtonRef = useRef<HTMLDivElement>(null)

  const [dropDownMenuOpen, setDropDownMenuOpen] = useState(false)

  const handleSelect = (obj: { name: string; value: any }) => {
    onSelect(obj)
    setDropDownMenuOpen(false)
  }

  return (
    <>
      <DropDownChipButton
        ref={dropDownButtonRef}
        icon={icon}
        onClick={() => setDropDownMenuOpen((prev) => !prev)}
      >
        {title}
      </DropDownChipButton>
      <DropDownMenu
        open={dropDownMenuOpen}
        options={options}
        onSelect={handleSelect}
        anchorEl={dropDownButtonRef.current}
        onClick={() => setDropDownMenuOpen(false)}
      />
    </>
  )
}
