import { LinearProgress } from "@mui/material"
import IntegrationLayout from "components/IntegrationsLayout/IntegrationLayout"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useSetupCompanyIntegration from "graphql/integrations/useSetupCompanyIntegration"
import useTranslate from "intl/useTranslate"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { CompanyIntegration } from "types"
import { Button, P16 } from "ui"
import getUrlSearchParam from "utils/getUrlSearchParam"

const TextContainer = styled.div`
  margin-left: 12px;
  margin-bottom: 18px;
`
interface PropsType {
  integration?: CompanyIntegration
}

export default function MicrosoftTeamsIntegrationComponent({
  integration,
}: PropsType) {
  const t = useTranslate()
  const setupCompanyIntegration = useSetupCompanyIntegration()
  const { addToast } = useToasts()

  const [loading, setLoading] = useState(false)

  // Display an error if any
  useEffect(() => {
    const error = getUrlSearchParam("error")
    if (error)
      addToast(`${t("error while connecting to Microsoft")} : ${error}`, {
        appearance: "error",
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // setup the tenant id if success
  useEffect(() => {
    const success = window.location.search.includes(
      "admin_consent=True&tenant="
    )
    if (
      success &&
      localStorage.getItem("integration-type") === "microsoft-teams"
    ) {
      const externalId = getUrlSearchParam("tenant")
      if (externalId) {
        setLoading(true)
        setupCompanyIntegration({
          name: "microsoft-teams",
          externalId,
          flags: {
            forceRefresh: !integration,
          },
        }).then(() => {
          setLoading(false)
        })
      } else {
        addToast(t("error while connecting to Microsoft"), {
          appearance: "error",
        })
      }
      localStorage.removeItem("integration-type")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <IntegrationLayout connected={Boolean(integration)} title="Microsoft Teams">
      {integration && (
        <TextContainer>
          <P16>{t("offishall linked to microsoft teams")}</P16>
          <P16>
            {t("your microsoft tenant id").replace(
              "{externalId}",
              integration.externalId
            )}
          </P16>
        </TextContainer>
      )}
      {!integration && (
        <TextContainer>
          <P16>{t("offishall not linked to microsoft teams")} </P16>
        </TextContainer>
      )}
      <Button
        disabled={loading}
        secondary
        onClick={() => {
          localStorage.setItem("integration-type", "microsoft-teams")
          // we can optionnaly pass a "&state=some_state" option to that url
          location.href = `https://login.microsoftonline.com/common/adminconsent?client_id=${process.env.REACT_APP_TEAMS_CLIENT_ID}&redirect_uri=${window.location.origin}/admin/integrations`
        }}
      >
        {integration
          ? t("refresh access")
          : t("authorize offishall access microsoft teams")}
      </Button>
      {loading && <LinearProgress />}
    </IntegrationLayout>
  )
}
