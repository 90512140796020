import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function Download({
  stroke,
  strokeWidth,
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      strokeWidth={strokeWidth ?? 0.5}
      stroke={stroke ? stroke : "currentColor"}
      {...rest}
    >
      <path d="M6 19.25C5.58579 19.25 5.25 19.5858 5.25 20C5.25 20.4142 5.58579 20.75 6 20.75V19.25ZM18 20.75C18.4142 20.75 18.75 20.4142 18.75 20C18.75 19.5858 18.4142 19.25 18 19.25V20.75ZM12.75 4C12.75 3.58579 12.4142 3.25 12 3.25C11.5858 3.25 11.25 3.58579 11.25 4H12.75ZM12 16L11.4697 16.5303C11.7626 16.8232 12.2374 16.8232 12.5303 16.5303L12 16ZM16.0303 13.0303C16.3232 12.7374 16.3232 12.2626 16.0303 11.9697C15.7374 11.6768 15.2626 11.6768 14.9697 11.9697L16.0303 13.0303ZM9.03033 11.9697C8.73744 11.6768 8.26256 11.6768 7.96967 11.9697C7.67678 12.2626 7.67678 12.7374 7.96967 13.0303L9.03033 11.9697ZM6 20.75H18V19.25H6V20.75ZM11.25 4V16H12.75V4H11.25ZM12.5303 16.5303L16.0303 13.0303L14.9697 11.9697L11.4697 15.4697L12.5303 16.5303ZM12.5303 15.4697L9.03033 11.9697L7.96967 13.0303L11.4697 16.5303L12.5303 15.4697Z" />
    </SvgIcon>
  )
}
