import "./style.css"

import useTranslate from "intl/useTranslate"
import { DropzoneArea } from "material-ui-dropzone"
import React, { useEffect } from "react"

interface PropsType {
  defaultImage?: string
  onChange: (files: File[]) => void
}

export default function MediaInput({ onChange, defaultImage }: PropsType) {
  const t = useTranslate()

  const hasMedia = Boolean(defaultImage)
  const defaultImageSrc = hasMedia ? defaultImage : ""

  const getImageData = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = function (event) {
        let _event$target

        resolve(
          event === null || event === void 0
            ? void 0
            : (_event$target = event.target) === null ||
              _event$target === void 0
            ? void 0
            : _event$target.result
        )
      }

      reader.onerror = function (event) {
        reader.abort()
        reject(event)
      }
      reader.readAsDataURL(file)
    })

  const handleChange = (files: File[]) => {
    onChange(files)

    if (files.length > 0) {
      getImageData(files[0]).then((data) => {
        document.documentElement.style.setProperty(
          "--event-dropzone-bg-url",
          `url("${data}")`
        )
      })
    }
  }

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--event-dropzone-bg-url",
      `url("${defaultImageSrc}")`
    )
  }, [defaultImageSrc])

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--MuiDropzoneArea-textContainer-after",
      `"${t("upload media after title")}"`
    )
    return () => {
      document.documentElement.style.removeProperty(
        "--MuiDropzoneArea-textContainer-after"
      )
      return
    }
  }, [t])

  return (
    <DropzoneArea
      acceptedFiles={["image/*"]}
      dropzoneClass="user-picture-dropzone-area"
      filesLimit={1}
      dropzoneText={
        hasMedia
          ? t("replace floorPlan media title")
          : t("upload floorPlan media title")
      }
      onChange={handleChange}
      showPreviewsInDropzone={false}
    />
  )
}
