import AppUpdator from "components/AppUpdator/AppUpdator"
import StopDemoButton from "components/Demo/StopDemoButton"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useUserFavorites from "graphql/favorites/useUserFavorites"
import useMyGroupsSimple from "graphql/groups/useMyGroupsSimple"
import useCompanyFlag from "hooks/useCompanyFlag"
import useMenu from "hooks/useMenu"
import useWindowSize from "hooks/useWindowResize"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { colors, P14 } from "ui"
import { ArrowDownFill } from "ui/icons"
import { hideScrollBarsCSS } from "ui/utilities"

import MenuFavorites from "./MenuFavorites/MenuFavorites"
import MenuFooter from "./MenuFooter/MenuFooter"
import MenuGroups from "./MenuGroups/MenuGroups"
import TeamMenuGroups from "./MenuGroups/TeamMenuGroups"
import { MenuLogoButton } from "./MenuLogoButton/MenuLogoButton"
import { MenuSettings } from "./MenuSettings/MenuSettings"

const MenuNav = styled.nav<{ maxHeight: number }>`
  ${({ maxHeight }) => `
  height: ${maxHeight}px;
  max-height: ${maxHeight}px;
  `}
  display: grid;
  grid-template-rows: min-content auto min-content;
  border-right: solid 1px ${colors.grey3};
  background: ${colors.purple100};
  overflow-x: hidden;
  padding-top: env(safe-area-inset-top, 0);
  padding-bottom: env(safe-area-inset-bottom, 0);

  .menu-element-active {
    border-left: solid 2px ${colors.purple};
    background: ${colors.purple300};
  }

  .notifications-element-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .menu-upper-section {
    padding-bottom: 8px;
  }

  .menu-scrollable-section {
    visibility: visible;
    opacity: 1;

    &--hidden {
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
      height: 0;
    }

    overflow-y: scroll;

    ${hideScrollBarsCSS}
  }
`

const SectionTitle = styled(P14)`
  display: flex;
  align-items: center;
  gap: 8px;

  padding: 14px 0 0 24px;
  color: ${colors.grey1};

  svg {
    fill: ${colors.grey1};
    stroke: ${colors.grey1};
    width: 16px;
    height: 16px;

    &.btn-content-collapse--right {
      transform: rotate(-90deg);
    }
    transition: transform 250ms ease-in-out;
  }

  cursor: pointer;
`

interface PropsType {
  isProductFruitsReady: boolean
}

export default function Menu({ isProductFruitsReady }: PropsType) {
  const t = useTranslate()
  const [, maxHeight] = useWindowSize()
  const [collapseMenuContent, setCollapseMenuContent] = useState(false)
  const { handlePathAndLogEvent } = useMenu()
  const { openDrawer: openedDrawers, setOpenDrawer: setOpenedDrawers } =
    usePlanningContext()

  const { favorites } = useUserFavorites()

  const { groups: myGroups } = useMyGroupsSimple()
  const myTeam = myGroups.find((group) => group.id === "myteam")
  const myManagerTeam = myGroups.find((group) => group.id === "mymanagerteam")

  const hideEveryoneGroup = useCompanyFlag("hideEveryoneGroup", false)
  const everyoneGroup = myGroups.find((group) => group.id === "everyone")

  return (
    <MenuNav
      maxHeight={maxHeight}
      onClick={() => {
        if (openedDrawers !== null) setOpenedDrawers(null)
      }}
    >
      <div className="menu-upper-section">
        <MenuLogoButton />
        <AppUpdator />
        <StopDemoButton />
        <MenuSettings />
        <SectionTitle
          onClick={() => {
            handlePathAndLogEvent("/", "menu-action", {
              to: "/",
              from: "planning-collapse",
            })
          }}
        >
          {t("Planning")}
          <ArrowDownFill
            onClick={(e) => {
              e.stopPropagation()
              setCollapseMenuContent((prev) => !prev)
            }}
            className={
              collapseMenuContent
                ? "btn-content-collapse--right"
                : "btn-content-collapse--down"
            }
          />
        </SectionTitle>
      </div>
      <div
        className={
          collapseMenuContent
            ? "menu-scrollable-section--hidden"
            : "menu-scrollable-section"
        }
      >
        <TeamMenuGroups
          myTeam={myTeam}
          myManagerTeam={myManagerTeam}
          everyoneGroup={everyoneGroup}
          hideEveryoneGroup={hideEveryoneGroup}
        />
        <MenuFavorites favorites={favorites} />
        <MenuGroups
          myGroups={myGroups}
          myTeam={myTeam}
          myManagerTeam={myManagerTeam}
          everyoneGroup={everyoneGroup}
          hideEveryoneGroup={hideEveryoneGroup}
        />
      </div>
      <MenuFooter isProductFruitsReady={isProductFruitsReady} />
    </MenuNav>
  )
}
