import { UTCSlot, ViewModeType } from "types"

interface NullableSlotType {
  slot: UTCSlot | null
  isSelected: boolean
}

interface NonNullableSlotType {
  slot: UTCSlot
  isSelected: boolean
}

interface ParamsType {
  current: NonNullableSlotType
  previous: NullableSlotType
  next: NullableSlotType
  nextNext: NullableSlotType
  previousPrevious: NullableSlotType
  viewMode: ViewModeType
  userShowWeekends: boolean
}
function checkSlotsAreDifferent(
  { slot, isSelected }: NonNullableSlotType,
  other: NullableSlotType
) {
  return (
    other.slot?.value !== slot.value ||
    other.slot.locationId !== slot.locationId ||
    other.slot.label !== slot.label ||
    other.isSelected !== isSelected
  )
}

function isFirstSlot({
  current,
  previous,
  next,
  previousPrevious,
  viewMode,
}: ParamsType) {
  if (!previous.slot) return true

  const arePreviousAndCurrentSlotsDifferent = checkSlotsAreDifferent(
    current,
    previous
  )

  const areNextAndCurrentSlotsDifferent = checkSlotsAreDifferent(current, next)

  const arePreviousAndPreviousPrevioustSlotsDifferent =
    previous.slot !== null
      ? checkSlotsAreDifferent(
          previous as NonNullableSlotType,
          previousPrevious
        )
      : false

  const { slot, isSelected } = current

  if (viewMode === "MONTH") {
    if (slot.date.getDay() === 1 && slot.date.isMorning()) return true
    if (arePreviousAndCurrentSlotsDifferent && slot.date.isMorning())
      return true
  }

  if (viewMode === "MONTH" && !slot.date.isMorning()) {
    if (
      arePreviousAndCurrentSlotsDifferent &&
      areNextAndCurrentSlotsDifferent &&
      arePreviousAndPreviousPrevioustSlotsDifferent
    )
      return false
  }

  if (previous.isSelected && isSelected) return false
  return arePreviousAndCurrentSlotsDifferent
}

function isLastSlot({
  current,
  previous,
  next,
  nextNext,
  viewMode,
  userShowWeekends,
}: ParamsType) {
  if (!next.slot) return true

  const arePreviousAndCurrentSlotsDifferent = checkSlotsAreDifferent(
    current,
    previous
  )

  const areNextAndCurrentSlotsDifferent = checkSlotsAreDifferent(current, next)
  const areNextAndNextNextSlotsDifferent =
    next.slot !== null
      ? checkSlotsAreDifferent(next as NonNullableSlotType, nextNext)
      : false

  const { slot, isSelected } = current

  if (viewMode === "MONTH" && !slot.date.isMorning()) {
    if (!userShowWeekends && slot.date.getDay() === 5) return true
    if (userShowWeekends && slot.date.getDay() === 0) return true
    if (areNextAndCurrentSlotsDifferent) return true
  }

  if (viewMode === "MONTH" && slot.date.isMorning()) {
    if (
      areNextAndCurrentSlotsDifferent &&
      arePreviousAndCurrentSlotsDifferent &&
      areNextAndNextNextSlotsDifferent
    )
      return false
  }

  if (next.isSelected && isSelected) return false
  return areNextAndCurrentSlotsDifferent
}

export default function getUTCSlotProps(
  i: number,
  slot: UTCSlot,
  slots: UTCSlot[],
  selectedSlots: UTCSlot[],
  viewMode: ViewModeType,
  userShowWeekends: boolean
) {
  const previousSlot = i > 0 ? slots[i - 1] : null
  const nextSlot = i < slots.length - 1 ? slots[i + 1] : null
  const nextNextSlot = i < slots.length - 2 ? slots[i + 2] : null
  const previousPreviousSlot = i > 1 ? slots[i - 2] : null

  const nextSlotIsSelected = Boolean(
    nextSlot && !!selectedSlots.find((s) => s.id === nextSlot.id)
  )
  const previousSlotIsSelected = Boolean(
    previousSlot && !!selectedSlots.find((s) => s.id === previousSlot.id)
  )
  const isSelected = !!selectedSlots.find((s) => s.id === slot.id)
  const isToday = slot.date.isToday()
  const isMorning = slot.date.isMorning()
  const params = {
    current: { slot, isSelected },
    previous: {
      slot: previousSlot,
      isSelected: previousSlotIsSelected,
    },
    next: {
      slot: nextSlot,
      isSelected: nextSlotIsSelected,
    },
    nextNext: {
      slot: nextNextSlot,
      isSelected: false, // non relevant in this case
    },
    previousPrevious: {
      slot: previousPreviousSlot,
      isSelected: false, // non relevant in this case
    },
    viewMode,
    userShowWeekends,
  }
  const isFirst = isFirstSlot(params)
  const isLast = isLastSlot(params)

  return {
    isToday,
    isMorning,
    isFirst,
    isLast,
    isSelected,
  }
}
