import { gql, makeVar, useQuery } from "@apollo/client"

import getTranslation from "./translations/getTranslation"
import { Translation } from "./translations/types"

export const GET_APP_LOCALE = gql`
  query getAppLocale {
    appLocale @client
  }
`

export function readLocaleFromLocalStorage() {
  const localeFromStorage = localStorage.getItem("locale")
  if (localeFromStorage) {
    return localeFromStorage
  }
  if (
    navigator.language &&
    ["en", "fr"].includes(navigator.language.substring(0, 2))
  ) {
    return navigator.language.substring(0, 2)
  }
  return "en"
}

const appLocaleVar = makeVar(readLocaleFromLocalStorage())
export function getAppLocale() {
  return appLocaleVar()
}
export function setAppLocale(locale: string) {
  localStorage.setItem("locale", locale)
  appLocaleVar(locale)
}

export default function useTranslation(): [
  Translation,
  (landId: string) => void
] {
  const { data } = useQuery(GET_APP_LOCALE)
  const translation = getTranslation(
    data && data.appLocale ? data.appLocale : getAppLocale()
  )
  return [translation, setAppLocale]
}
