import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { CompanyHybridRules } from "types"
import { P16 } from "ui"

const EmptyStateLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  height: 100%;
  padding: 16px;
  margin: auto 0;
`

interface PropsType {
  companyHybridRules: CompanyHybridRules
}

export default function EmptyRulesListMessage({
  companyHybridRules,
}: PropsType) {
  const t = useTranslate()

  if (companyHybridRules.default) return <></>

  return (
    <EmptyStateLayout>
      <img src="/static/img/empty_hybrid_rules.svg" />
      <P16 color="grey1">{t("company-hybrid-rule-empty")}</P16>
    </EmptyStateLayout>
  )
}
