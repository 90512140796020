import { useState } from "react"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore-next-line
import { useProductFruits } from "react-product-fruits"

export default function useIsProductFruitsReady() {
  const [isReady, setIsReady] = useState(false)
  useProductFruits((_api: any) => {
    setIsReady(true)
  }, [])
  return isReady
}
