import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Transition } from "react-transition-group"
import styled from "styled-components"
import { colors } from "ui"

const duration = 600

const defaultStyle = {
  transition: `width ${duration}ms ease-in-out 300ms`,
}
const transitionStyles: any = {
  entering: { width: 40, opacity: 1 },
  entered: { width: 40, opacity: 1 },
  exiting: { width: 8, opacity: 1 },
  exited: { width: 8, opacity: 1 },
}

const Container = styled.div`
  gap: 8px;
  height: 8px;
  width: 88px;
  display: flex;
  flex-direction: row;

  .active-step {
    width: 40px;
    height: 8px;
    border-radius: 8px;
    background-color: ${colors.purple};
  }

  .unactive-step {
    height: 8px;
    width: 8px;
    border-radius: 8px;
    background-color: ${colors.grey3};
    cursor: pointer;
  }
`

export default function StepIndicator() {
  const history = useHistory()
  const steps = [
    "/",
    "/onboarding/user/informations",
    "/onboarding/user/manager",
    "/onboarding/user/perfect-week",
    "/onboarding/user/password",
    "/onboarding/user/congrats",
  ]

  const currentUrl = location.pathname

  const activeStep = (el: string) => {
    return currentUrl === el
  }

  const currentStep = steps.findIndex(activeStep)

  const [inProp, setInProp] = useState(false)

  useEffect(() => {
    setTimeout(() => setInProp(true), 1)
  }, [currentUrl])

  return (
    <Container>
      {steps.map((e, idx) => {
        return (
          <div key={idx}>
            {currentStep === idx ? (
              <Transition in={inProp} timeout={duration}>
                {(state) => (
                  <div
                    style={{
                      ...defaultStyle,
                      ...transitionStyles[state],
                    }}
                    className={"active-step"}
                  />
                )}
              </Transition>
            ) : (
              <div className="unactive-step" onClick={() => history.push(e)} />
            )}
          </div>
        )
      })}
    </Container>
  )
}
