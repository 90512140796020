import React from "react"
import styled from "styled-components"
import { colors, P14, P16 } from "ui"
import { NavArrowRight } from "ui/icons"

const Container = styled.button`
  width: 100%;
  height: 130px;
  background: #fff;
  border: 2px solid ${colors.grey3};
  display: flex;
  flex-direction: row;
  padding: 40px 80px 40px 40px;
  border-radius: 4px;
  margin-bottom: 16px;

  &:hover {
    background: rgba(0, 220, 163, 0.05);
    border: 2px solid ${colors.green};
  }

  &:active {
    background: rgba(0, 220, 163, 0.15);
    border: 2px solid ${colors.green};
  }

  .texts-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    text-align: left;

    .title {
      font-weight: 600;
    }
  }
  svg {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
`

interface PropsType {
  onClick: () => void
  className?: string
  title: string
  subtitle: string
}

export default function BigButton({
  onClick,
  className,
  title,
  subtitle,
  ...rest
}: PropsType) {
  return (
    <Container
      className={className}
      onClick={() => {
        if (onClick) onClick()
      }}
      {...rest}
    >
      <div className="texts-container">
        <P16 className="title">{title}</P16>
        <P14>{subtitle}</P14>
      </div>
      <NavArrowRight />
    </Container>
  )
}
