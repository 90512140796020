import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import useCompanyIntegrations from "graphql/integrations/useCompanyIntegrations"
import useUserIntegrations from "graphql/integrations/useUserIntegrations"
import useSetAllowedUserFlags from "graphql/users/useSetAllowedUserFlags"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { NotificationTriggers, NotificationType, TriggerType } from "types"
import { colors, InfoTooltip, Toggle } from "ui"
import {
  Gauge100,
  Group,
  Mail,
  Megaphone,
  Slack,
  Teams,
  User as UserIcon,
} from "ui/icons"

import UserSubscriptionsSettingsButton from "./UserSubscriptionsSettingsButton/UserSubscriptionsSettingsButton"

const ALL_NOTIFICATION_TRIGGERS: TriggerType[] = [
  "email",
  "slack",
  "teams",
  "push",
]

const DISABLED_NOTIFICATION_TRIGGERS: TriggerType[] = ["push"]

const TABLE_COLS_NB = ALL_NOTIFICATION_TRIGGERS.filter(
  (t) => !DISABLED_NOTIFICATION_TRIGGERS.includes(t)
).length

const Table = styled.ul`
  display: grid;
  grid-template-columns: min-content repeat(${TABLE_COLS_NB}, 1fr) min-content;
  gap: 0 4px;
  align-items: center;
  justify-items: center;
  > li {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 4px 0;
    :nth-child(${TABLE_COLS_NB + 2}n + 1) {
      /* every first cell */
      justify-content: flex-start;
    }

    &.disabled {
      background: ${colors.grey4};
      cursor: not-allowed;

      > svg {
        filter: saturate(0);
      }
    }
  }
`

const RowHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

interface PropsType {
  initialTriggers?: NotificationTriggers
}

export default function NotificationTriggerChoices({
  initialTriggers,
}: PropsType) {
  const t = useTranslate()

  const { userIntegrations, loading: loadingUI } = useUserIntegrations()
  const { companyIntegrations, loading: loadingCI } = useCompanyIntegrations()
  const loading = loadingUI || loadingCI
  const { setAllowedUserFlags } = useSetAllowedUserFlags()

  const getUserEnabledTriggers = () => {
    const defaultTriggers: TriggerType[] = ["email"]
    const enabledTriggers: TriggerType[] = [...defaultTriggers]
    for (const integration of userIntegrations) {
      const trigger = ALL_NOTIFICATION_TRIGGERS.find(
        (t) => t === integration.name
      )
      if (trigger) enabledTriggers.push(trigger)
    }
    if (companyIntegrations?.find((c) => c.name === "microsoft-teams"))
      enabledTriggers.push("teams")
    return enabledTriggers
  }

  const defaultNotificationTriggers: NotificationTriggers = {
    nudge: {
      email: true,
      slack: true,
      teams: true,
      push: true,
    },
    weeklyReminder: {
      email: true,
      slack: true,
      teams: true,
      push: true,
    },
    teamDayInvitation: {
      email: true,
      slack: true,
      teams: true,
      push: true,
    },
    userSubscriptions: {
      email: true,
      slack: true,
      teams: true,
      push: true,
    },
  }

  const triggersInitialState = initialTriggers
    ? { ...defaultNotificationTriggers, ...initialTriggers }
    : defaultNotificationTriggers

  const [triggers, setTriggers] =
    useState<NotificationTriggers>(triggersInitialState)

  const updateNotificationTriggers = (
    notificationTriggers: NotificationTriggers
  ) => {
    setAllowedUserFlags({ notificationTriggers })
  }

  const getColumnTextOrIcon = (type: TriggerType) => {
    switch (type) {
      case "email":
        return <Mail />
      case "slack":
        return <Slack />
      case "teams":
        return <Teams />
      default:
        return type
    }
  }

  const getRowTextOrIcon = (type: NotificationType) => {
    switch (type) {
      case "nudge":
        return <Megaphone />

      case "weeklyReminder":
        return <Gauge100 />

      case "teamDayInvitation":
        return <Group />

      case "userSubscriptions":
        return <UserIcon />

      default:
        return type
    }
  }

  const getNotificationSettings = (type: NotificationType) => {
    switch (type) {
      case "userSubscriptions":
        return <UserSubscriptionsSettingsButton />

      default:
        return null
    }
  }

  if (loading) return <LoaderOrErrorComponent loading={loading} error={null} />

  return (
    <Table>
      <li></li> {/* first empty cell */}
      {React.Children.map(ALL_NOTIFICATION_TRIGGERS, (trigger) => {
        const disabled = !getUserEnabledTriggers().find((t) => t === trigger)
        if (DISABLED_NOTIFICATION_TRIGGERS.includes(trigger)) return null
        return (
          <li className={disabled ? "disabled" : ""}>
            {getColumnTextOrIcon(trigger)}
          </li>
        )
      })}
      <li></li> {/* first row last empty cell */}
      {Object.entries(triggers).map(([notificationType, value]) => {
        const EntrySettings = getNotificationSettings(
          notificationType as NotificationType
        )
        return (
          <React.Fragment key={`trigger-${notificationType}`}>
            <li>
              <RowHeader>
                {getRowTextOrIcon(notificationType as NotificationType)}
                <InfoTooltip
                  text={t(
                    `user-setting-group-notifications--tooltip-${notificationType}`
                  )}
                />
              </RowHeader>
            </li>
            {Object.entries(value).map(([notificationTrigger, v]) => {
              const disabled = !getUserEnabledTriggers().find(
                (t) => t === notificationTrigger
              )
              if (
                DISABLED_NOTIFICATION_TRIGGERS.includes(
                  notificationTrigger as TriggerType
                )
              )
                return null

              return (
                <li
                  className={disabled ? "disabled" : ""}
                  key={`trigger-${notificationType}-${notificationTrigger}`}
                >
                  <Toggle
                    checked={disabled ? false : (v as boolean)}
                    disabled={disabled}
                    onChange={(checked) => {
                      // object copy hack to avoid object mutation read-only error
                      const newNotificationTriggers = JSON.parse(
                        JSON.stringify(triggers)
                      )
                      newNotificationTriggers[
                        notificationType as NotificationType
                      ][notificationTrigger as TriggerType] = checked

                      setTriggers(newNotificationTriggers)
                      updateNotificationTriggers(newNotificationTriggers)
                    }}
                  />
                </li>
              )
            })}

            <li>{EntrySettings ?? <></>}</li>
          </React.Fragment>
        )
      })}
    </Table>
  )
}
