export const CompanySettingInlineFragment = `
  ... on CompanySettingSimple {
    key
    type
    group
    defaultEnabled
    enabled
  }
  ... on CompanySettingNumber {
    key
    type
    group
    defaultEnabled
    enabled
    defaultValueAsNumber: defaultValue
    valueAsNumber: value
    minValue
    maxValue
  }
  ... on CompanySettingString {
    key
    type
    group
    defaultEnabled
    enabled
    defaultValueAsString: defaultValue
    valueAsString: value
    maxLength
  }
  ... on CompanySettingEnum {
    key
    type
    group
    defaultEnabled
    enabled
    defaultValueAsEnum: defaultValue
    valueAsEnum: value
    possibleValues
  }
`
