import useCheckCompanyIntegration from "graphql/integrations/useCheckCompanyIntegration"
import useSetupCompanyIntegration from "graphql/integrations/useSetupCompanyIntegration"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { CompanyIntegration } from "types"
import {
  Button,
  Dialog,
  FieldsetColumn,
  FieldsetRow,
  Label,
  P16,
  TextFieldInput,
} from "ui"
import { Google } from "ui/icons"

import CompanyWideIntegrationCard from "../CompanyWideIntegrationCard/CompanyWideIntegrationCard"
import { CustomButton } from "../CompanyWideIntegrationCard/CustomButton"
import RevokeCompanyWideIntegrationToggle from "../RevokeCompanyWideIntegrationToggle/RevokeCompanyWideIntegratioToggle"

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 32px;
`

interface FormPropsType {
  onClose: () => void
}

function GoogleAuthorizationForm({ onClose }: FormPropsType) {
  const setupCompanyIntegration = useSetupCompanyIntegration()

  const [serviceAccountKey, setServiceAccountKey] = useState("")

  return (
    <Form onSubmit={(e) => e.preventDefault()}>
      <FieldsetColumn>
        <Label>Instructions</Label>
        <P16>
          Documentation:{" "}
          <a
            href="https://offishall.notion.site/Setup-Google-Company-Wide-Integration-03c5abcd5192401eb6201c5be94354c1"
            target="_blank"
            rel="noreferrer"
          >
            link
          </a>
        </P16>
      </FieldsetColumn>
      <FieldsetColumn className="full-width">
        <Label>Paste your Service Account Key:</Label>
        <TextFieldInput
          placeholder={`
{
  "type": "service_account",
  "project_id": "xxx",
  "private_key_id": "xxx",
  "private_key": "-----BEGIN PRIVATE KEY-----xxx-----END PRIVATE KEY-----",
  "client_email": "xxx@xxx.com",
  "client_id": "xxx",
  "auth_uri": "https://x",
  "token_uri": "https://xxx",
  "auth_provider_x509_cert_url": "https://xxx",
  "client_x509_cert_url": "https://xxx"
}
          `}
          textarea={{ minLines: 14 }}
          onChange={setServiceAccountKey}
        />
        <FieldsetRow className="align-right">
          <Button
            disabled={serviceAccountKey === ""}
            onClick={async () => {
              if (serviceAccountKey === "") return
              await setupCompanyIntegration({
                name: "google-events",
                externalId: "",
                token: undefined,
                flags: {
                  serviceAccountKey: serviceAccountKey,
                },
              })
              onClose()
            }}
          >
            Send
          </Button>
        </FieldsetRow>
      </FieldsetColumn>
    </Form>
  )
}

export function AuthorizeGoogleButton({
  integration,
}: {
  integration: CompanyIntegration | undefined
}) {
  const t = useTranslate()
  const [openForm, setOpenForm] = useState(false)

  const shouldSkip = integration?.name === undefined
  const { result, loading: checkLoading } = useCheckCompanyIntegration(
    integration?.name,
    shouldSkip
  )
  const disabled = result === "success" || checkLoading

  return (
    <>
      <CustomButton disabled={disabled} onClick={() => setOpenForm(true)}>
        <Google />
        {t("Authorize access")}
      </CustomButton>
      <Dialog
        title={t(`cwi-google-events--form--title`)}
        open={openForm}
        onClose={() => setOpenForm(false)}
      >
        <GoogleAuthorizationForm onClose={() => setOpenForm(false)} />
      </Dialog>
    </>
  )
}

interface PropsType {
  integration: CompanyIntegration
}

export default function GoogleCompanyWideIntegration({
  integration,
}: PropsType) {
  return (
    <CompanyWideIntegrationCard
      integrationName="google-events"
      authorizeComponent={<AuthorizeGoogleButton integration={integration} />}
      revokeComponent={
        <RevokeCompanyWideIntegrationToggle integration={integration} />
      }
    />
  )
}
