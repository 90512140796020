import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { CloseButton, colors } from "ui"

const HubSpotChatHolder = styled.div`
  position: fixed;
  right: 30px;
  bottom: 30px;
  background-color: ${colors.purple};
  border-radius: 5px;
  visibility: hidden;
  text-align: right;

  .hubspot-close-button {
    border: 0;
    background: transparent;
    margin-right: 4px;
    margin-top: 4px;
  }

  iframe {
    height: 100%;
    border: 0;
  }

  #hubspot-conversations-inline-parent {
    height: 100%;
  }
`

const HubSpotChat = styled.div`
  height: 500px;
  width: 300px;
`

export default function PFHubspotChat() {
  const [loading, setLoading] = useState(false)
  const [, setLoaded] = useState(false)
  const [showWidget, setShowWidget] = useState(false)

  useEffect(() => {
    if (loading) return
    const w = window as any
    w.hsConversationsSettings = {
      loadImmediately: true,
      inlineEmbedSelector: "#hubspot-chat",
    }
    setLoading(true)

    w.addEventListener("productfruits_button_ext_widget_init", () => {
      setShowWidget(true)
    })

    const script = document.createElement("script")
    script.src = "//js-na1.hs-scripts.com/19653516.js"
    script.async = true
    script.onload = () => {
      setLoaded(true)
    }
    document.body.appendChild(script)
  }, [loading])

  return (
    <HubSpotChatHolder
      id="hubspot-chat-holder"
      style={{ visibility: showWidget ? "visible" : "hidden" }}
    >
      <CloseButton
        stroke={colors.white}
        className="hubspot-close-button"
        onClick={() => {
          setShowWidget(false)
        }}
      />
      <HubSpotChat id="hubspot-chat"></HubSpotChat>
    </HubSpotChatHolder>
  )
}
