import UTCDatePicker from "components/UTCDatePicker/UTCDatePicker"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { Button, colors, mediaQueries, P14, P14Bold } from "ui"
import UTCDate from "utils/UTCDate"

const ButtonStyled = styled(Button)`
  border: none;
  background: ${colors.grey4};

  p {
    color: ${colors.purple};
  }

  &:hover {
    background: ${colors.lightGrey};
    border: none;
  }
  &:active {
    background: ${colors.grey3};
  }
  &:disabled {
    font-weight: 400;
    cursor: not-allowed;
  }

  @media ${mediaQueries.isMobile} {
    width: 9em;
    background: none;
    border: solid 1px ${colors.grey3};

    &:disabled {
      background: none;
      border: solid 1px ${colors.grey3};
    }
  }
`
interface PropsType {
  initialDate: UTCDate
  minDate?: UTCDate
  maxDate: UTCDate
  setDate: (date?: UTCDate) => void
}

export default function DateButton({
  initialDate,
  minDate,
  maxDate,
  setDate,
}: PropsType) {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()

  const [openDate, setOpenDate] = useState(false)

  const handleDatePicking = (date?: UTCDate) => {
    setDate(date)
    setOpenDate(false)
  }

  const getName = () => {
    const amPmPostfix = `${initialDate.isMorning() ? "AM" : "PM"}`
    return `${t(initialDate.getDayAsString()).slice(
      0,
      3
    )}. ${initialDate.getUTCDate()} • ${amPmPostfix}`
  }

  return (
    <>
      <ButtonStyled
        secondary
        onClick={() => {
          setOpenDate(true)
        }}
      >
        {isMobile ? <P14Bold>{getName()}</P14Bold> : <P14>{getName()}</P14>}
      </ButtonStyled>
      {openDate && (
        <UTCDatePicker
          open={openDate}
          minDate={minDate}
          maxDate={maxDate}
          defaultDate={initialDate}
          onClose={() => setOpenDate(false)}
          onSelect={handleDatePicking}
        />
      )}
    </>
  )
}
