import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function Search({ stroke, strokeWidth, ...rest }: SvgIconProps) {
  return (
    <SvgIcon
      strokeWidth={strokeWidth ?? 1.5}
      stroke={stroke ? stroke : "currentColor"}
      {...rest}
    >
      <path
        d="M15.5 15.5L19 19"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 11C5 14.3137 7.68629 17 11 17C12.6597 17 14.1621 16.3261 15.2483 15.237C16.3308 14.1517 17 12.654 17 11C17 7.68629 14.3137 5 11 5C7.68629 5 5 7.68629 5 11Z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}
