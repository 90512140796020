import InteractionsMenu from "components/InteractionsMenu/InteractionsMenu"
import LocationIcon from "components/LocationIcon"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useLocationsTree from "graphql/locations/useLocationsTree"
import React, { useEffect } from "react"
import styled from "styled-components"
import { UserBase } from "types"
import LocationTree from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

import OccupationList from "../../OccupationList/OccupationList"
import { GroupOrLocationHeader } from "../GroupOrLocationHeader/GroupOrLocationHeader"
import CategoryLocationsList from "./CategoryLocationsList/CategoryLocationsList"

const LocationOccupation = styled.div`
  padding: 4px 16px;
`

interface PropsType {
  users: UserBase[]
  firstWeekDay: UTCDate
  lastWeekDay: UTCDate
}

interface WrapperPropsType extends PropsType {
  locationId: string
}

interface HeaderPropsType extends PropsType {
  location: LocationTree
}

function LocationPlanningHeader({
  location,
  users,
  firstWeekDay,
  lastWeekDay,
}: HeaderPropsType) {
  const { setActiveLocation, activeLocation, viewMode } = usePlanningContext()
  useEffect(() => {
    if (activeLocation.location?.id !== location.id) {
      setActiveLocation({
        location,
        persist: true,
        triggeredFrom: activeLocation.triggeredFrom,
      })
    }
  }, [setActiveLocation, location, activeLocation])

  return (
    <>
      <GroupOrLocationHeader
        icon={<LocationIcon location={location} />}
        path={undefined}
        itemId={location.id}
        type="location"
        actions={[
          <InteractionsMenu
            location={location}
            key={`loc-${location.id}-interactions`}
            from={firstWeekDay}
            to={lastWeekDay}
            users={users}
          />,
        ]}
      >
        {location.name}
      </GroupOrLocationHeader>

      {viewMode !== "MONTH" ? (
        <LocationOccupation>
          <OccupationList location={location} />
        </LocationOccupation>
      ) : (
        <LocationOccupation />
      )}
      {location.isCategory && <CategoryLocationsList category={location} />}
    </>
  )
}

export default function LocationPlanningHeaderWrapper({
  locationId,
  ...rest
}: WrapperPropsType) {
  const { locations } = useLocationsTree()
  const location = LocationTree.getLocationNode(locations, locationId)

  if (location === undefined) return <></>

  return <LocationPlanningHeader location={location} {...rest} />
}
