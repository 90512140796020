import { ApolloError, gql, useQuery } from "@apollo/client"
import { DefaultHybridRule } from "types"
import UTCDate from "utils/UTCDate"

import { DefaultHybridRuleFragment } from "./fragments/hybridPolicyFragments"

export const DEFAULT_HYBRID_RULE_QUERY = gql`
  ${DefaultHybridRuleFragment}
  query defaultHybridRule($from: String!, $to: String!) {
    defaultHybridRule(from: $from, to: $to) {
      ...DefaultHybridRuleFragment
    }
  }
`

export default function useDefaultHybridRule({
  from,
  to,
}: {
  from: UTCDate
  to: UTCDate
}): {
  loading: boolean
  error: ApolloError | undefined
  defaultHybridRule?: DefaultHybridRule
} {
  const { loading, error, data } = useQuery<{
    defaultHybridRule?: Omit<DefaultHybridRule, "maxDays"> & {
      maxSlots: number
    }
  }>(DEFAULT_HYBRID_RULE_QUERY, {
    variables: { from: `${from.getTime()}`, to: `${to.getTime()}` },
    notifyOnNetworkStatusChange: true,
  })
  const defaultHybridRule = data?.defaultHybridRule
    ? {
        ...data.defaultHybridRule,
        maxDays: data.defaultHybridRule.maxSlots / 2,
      }
    : undefined
  return {
    loading,
    error,
    defaultHybridRule,
  }
}
