import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import { BulkSlotRuleInput } from "types"
import { TextField } from "ui"
import { getManagerTeamDisplayName } from "utils/getTeamDisplayName"

import TargetSelection from "./TargetSelection"

interface PropsType {
  rule: BulkSlotRuleInput
  setRule: (value: (value: BulkSlotRuleInput) => BulkSlotRuleInput) => void
}

export default function TargetPicker({ rule, setRule }: PropsType) {
  const t = useTranslate()

  const [isTargetSelectionOpen, setIsTargetSelectionOpen] = useState(false)
  const whoIsSelected = () => {
    if (rule.who.everyone === true) return t("everyone")
    else if (rule.who.groups.length > 0 || rule.who.teams.length > 0)
      return `${rule.who.groups.map((el) => el.name).join(", ")} ${
        rule.who.teams.length > 0
          ? `, ${rule.who.teams
              .map((team) => {
                return getManagerTeamDisplayName(team, t)
              })
              .join(", ")}`
          : ""
      }`
    else return ""
  }

  return (
    <div className="target">
      <TextField
        placeholder={t("select a team, group, collaborator")}
        label={t("attributed to")}
        initialValue={whoIsSelected()}
        handleChange={() => {
          //
        }}
        resetOnInitialValue
        onClick={() => setIsTargetSelectionOpen(true)}
      />
      <TargetSelection
        open={isTargetSelectionOpen}
        onClose={() => setIsTargetSelectionOpen(false)}
        setRule={setRule}
      />
    </div>
  )
}
