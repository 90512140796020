import { gql, useMutation } from "@apollo/client"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import { USER_INTEGRATION_EVENTS_QUERY } from "graphql/events/useUserEvents"

import { MEETING_ROOM_EVENTS_QUERY } from "../meetingRooms/useMeetingRoomEvents"

const MUTATION = gql`
  mutation integrations_deleteEvent($eventId: String!) {
    integrations_deleteEvent(eventId: $eventId)
  }
`

export default function useDeleteEvent() {
  const { addToast } = useToasts()
  const [mutate] = useMutation(MUTATION)

  return async (eventId: string) => {
    try {
      await mutate({
        variables: { eventId },
        refetchQueries: [
          MEETING_ROOM_EVENTS_QUERY,
          USER_INTEGRATION_EVENTS_QUERY,
        ],
      })
      addToast(`Event successfully deleted.`, { appearance: "success" })
    } catch (error) {
      console.error(error.message)
      addToast(error.message, { appearance: "error" })
    }
  }
}
