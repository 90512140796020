import LocationTree from "utils/LocationTree"

export default function findOlderParentsInTree(
  targetedCategories: LocationTree[],
  tree: LocationTree[],
  result: LocationTree[] = []
) {
  // find the higher parent for each targeted category throughout the tree
  for (const location of tree) {
    if (location.isCategory) {
      if (targetedCategories.find((c) => c.id === location.id)) {
        result.push(location)
      } else
        findOlderParentsInTree(location.children, targetedCategories, result)
    }
  }
  return result
}
