import useTranslate from "intl/useTranslate"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Transition } from "react-transition-group"
import { Button, Grid } from "ui"

import BigButton from "./components/BigButton"
import DefineWorkspace from "./components/DefineWorkspace"
import DialogSpacesCreation from "./components/DialogSpacesCreation"
import {
  Content,
  Footer,
  Header,
  RightContainer,
  Title,
} from "./components/Layout"
import NavBack from "./components/NavBack"
import StepIndicator from "./components/StepIndicator"
import { OnboardingLocation } from "./OnboardingTypes"

const duration = 600

const defaultStyleSmallPopUp = {
  transition: `right ${duration}ms ease-in-out 400ms`,
  position: "absolute",
  top: "48%",
  width: "30%",
}
const defaultStyleMainPopUp = {
  transition: `right ${duration}ms ease-in-out 300ms`,
  position: "absolute",
  top: "25%",
}

const transitionStylesSmallPopUp: any = {
  entering: { right: 250 },
  entered: { right: 250 },
  exiting: { right: -300 },
  exited: { right: -300 },
}

const transitionStylesMainPop: any = {
  entering: { right: -80 },
  entered: { right: -80 },
  exiting: { right: -500 },
  exited: { right: -500 },
}

interface PropsType {
  locations: OnboardingLocation[]
  setLocations: (locations: OnboardingLocation[]) => void
}

export default function OnboardingLocations({
  locations,
  setLocations,
}: PropsType) {
  const history = useHistory()
  const t = useTranslate()

  const [displayReelLocations, setDisplayReelLocations] =
    useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const [inProp, setInProp] = useState(false)

  useEffect(() => {
    setTimeout(() => setInProp(true), 1)
  }, [])

  return (
    <Grid container direction="row">
      <Grid
        item
        md={8}
        xl={9}
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <Header>
          <NavBack
            realGoBack={!displayReelLocations}
            onClick={() => setDisplayReelLocations(false)}
          />
        </Header>
        <Content>
          <Title>{t("create a workspace")}</Title>
          {displayReelLocations ? (
            <DefineWorkspace
              locations={locations}
              setLocations={setLocations}
            />
          ) : (
            <>
              <BigButton
                title={t("add your workspace")}
                subtitle={t("specify the name and capacity of my workspace")}
                onClick={() => setDisplayReelLocations(true)}
              />
              <BigButton
                title={t("add dummy offices")}
                subtitle={t(
                  "create fake workspaces to test in the best conditions"
                )}
                onClick={() => setIsModalOpen(true)}
              />
              <DialogSpacesCreation
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSuccess={() => {
                  setIsModalOpen(false)
                  history.push("/congrats")
                }}
              />
            </>
          )}
        </Content>
        <Footer>
          <StepIndicator />
          {displayReelLocations && (
            <Button
              onClick={() => {
                history.push("/congrats")
              }}
            >
              {t("continue")}
            </Button>
          )}
        </Footer>
      </Grid>

      <RightContainer item md={4} xl={3}>
        <Transition in={inProp} timeout={duration}>
          {(state) => (
            <div
              style={{
                ...defaultStyleMainPopUp,
                ...transitionStylesMainPop[state],
              }}
            >
              <img
                alt="Fictitious users creation"
                src="/static/img/onboarding/pop_up1_step3.svg"
              />
            </div>
          )}
        </Transition>
        <Transition in={inProp} timeout={duration}>
          {(state) => (
            <div
              style={{
                ...defaultStyleSmallPopUp,
                ...transitionStylesSmallPopUp[state],
              }}
            >
              <img
                alt="Fictitious users creation"
                src="/static/img/onboarding/pop_up2_step3.svg"
              />
            </div>
          )}
        </Transition>
      </RightContainer>
    </Grid>
  )
}
