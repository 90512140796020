import AddManagerToNewUserSearchResults from "components/Search/ResultsList/AddManagerToNewUserSearchResults/AddManagerToNewUserSearchResults"
import { SearchBarFormControlledFromParentWithRef } from "components/Search/SearchBarForm/SearchBarForm"
import useSearchUsersByName from "graphql/search/useSearchUsersByName"
import useCheckSearchResults from "hooks/useCheckSearchResults"
import useTranslate from "intl/useTranslate"
import React, { useRef, useState } from "react"
import styled from "styled-components"
import { SearchResult } from "types"
import { L12GreyBold } from "ui"

const SearchContainer = styled.div`
  .search-bar--textfield input {
    font-family: inherit;
  }
  p {
    margin-bottom: 8px;
  }
`

interface PropsType {
  value: string
  placeholder?: string
  hideLabel?: boolean
  variant?: "textfield"
  setValue: (str: string) => void
  updateManagerEmail: (email: string) => void
}

export default function ManagerSearchControlled({
  variant,
  placeholder,
  value,
  hideLabel,
  setValue,
  updateManagerEmail,
}: PropsType) {
  const t = useTranslate()
  const searchType = "user"

  const resultListAnchorElRef = useRef<HTMLDivElement>(null)

  const { results, loading: isSearching } = useSearchUsersByName(value)
  const [newResults, setNewResults] = useState<SearchResult[]>([])
  const [searchString, setSearchString] = useState("")

  const handleUpdateManagerEmail = (email: string) => {
    updateManagerEmail(email)
    setSearchString("")
    setNewResults([])
  }

  const handleReset = () => {
    setNewResults([])
  }

  useCheckSearchResults({
    searchString: value,
    searchType,
    newResults,
    setNewResults,
    results,
    isSearching,
  })

  return (
    <>
      <SearchContainer ref={resultListAnchorElRef}>
        {!hideLabel && <L12GreyBold>{t("Manager email")}</L12GreyBold>}
        <SearchBarFormControlledFromParentWithRef
          dynamicPlaceholder={placeholder}
          value={value}
          variant={variant}
          onReset={handleReset}
          setValue={(str: string) => {
            setSearchString(str)
            setValue(str)
          }}
        />
      </SearchContainer>

      <AddManagerToNewUserSearchResults
        searchString={searchString}
        results={newResults}
        anchorEl={resultListAnchorElRef.current}
        updateManagerEmail={handleUpdateManagerEmail}
        handleReset={handleReset}
      />
    </>
  )
}
