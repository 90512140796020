import { LinearProgress } from "@mui/material"
import IntegrationLayout from "components/IntegrationsLayout/IntegrationLayout"
import useCheckUserIntegration from "graphql/integrations/useCheckUserIntegration"
import useGoogleAuthUrl from "graphql/integrations/useGoogleAuthUrl"
import useRevokeUserIntegration from "graphql/integrations/useRevokeUserIntegration"
import useSetupUserIntegration from "graphql/integrations/useSetupUserIntegration"
import useTranslate from "intl/useTranslate"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { UserIntegration } from "types"
import { Button, P16 } from "ui"
import { Google } from "ui/icons"
import getUrlSearchParam from "utils/getUrlSearchParam"

import { ButtonText } from "./components/IntegrationButtonText"
import WriteEventsToggle from "./WriteEventsToggle"
import WriteSlotsEventsToggle from "./WriteSlotsEventsToggle"

const TextContainer = styled.div`
  margin-left: 12px;
  margin-bottom: 18px;
`

interface PropsType {
  integration?: UserIntegration
  loading: boolean
}

export default function GoogleIntegrationComponent({
  integration,
  loading: allIntegrationsLoading,
}: PropsType) {
  const t = useTranslate()
  const history = useHistory()

  const { loading: urlLoading, url } = useGoogleAuthUrl()
  const shouldSkip =
    integration?.name === undefined || getUrlSearchParam("code") !== null
  const { result, loading: checkLoading } = useCheckUserIntegration(
    integration?.name,
    shouldSkip
  )

  const error = result !== "success"

  const setupUserIntegration = useSetupUserIntegration()
  const revoke = useRevokeUserIntegration()

  const [loading, setLoading] = useState(false)

  // setup the tenant id if success
  useEffect(() => {
    const token = getUrlSearchParam("code")
    const scope = getUrlSearchParam("scope")
    if (token && scope === "https://www.googleapis.com/auth/calendar") {
      setLoading(true)
      setupUserIntegration({
        name: "google",
        token,
      }).then(() => {
        setLoading(false)
        history.replace("/settings")
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <IntegrationLayout
      connected={Boolean(!checkLoading && !error)}
      title={t("Google Calendar")}
    >
      {/*error && (
        <TextContainer>
          <P16>{`${result}`}</P16>
        </TextContainer>
      )*/}

      {integration && !error && (
        <>
          <TextContainer>
            <P16>{t("link offishall google")}</P16>
          </TextContainer>
          <TextContainer>
            <WriteSlotsEventsToggle integration={integration} />
          </TextContainer>
          <TextContainer>
            <WriteEventsToggle integration={integration} />
          </TextContainer>
          <Button
            secondary
            icon={<Google />}
            onClick={() => {
              revoke({ name: integration.name })
            }}
          >
            <ButtonText>{t("revoke access")}</ButtonText>
          </Button>
        </>
      )}
      {(!integration || error) && (
        <TextContainer>
          <P16>{t("google improve offishall experience")}</P16>

          {/*error && (
            <P16>
              {t("revoke access")}
              {` `}
              <a
                target="_blank"
                href="https://myaccount.google.com/u/2/permissions"
                rel="noreferrer"
              >
                {t("Link")}
              </a>
              .
            </P16>
          )*/}
        </TextContainer>
      )}
      {(!integration || error) && (
        <Button
          disabled={loading || urlLoading || !url || allIntegrationsLoading}
          secondary
          icon={<Google />}
          onClick={() => {
            if (url) {
              location.href = url
            }
          }}
        >
          <ButtonText>
            {t("autorize offishall access google account")}
          </ButtonText>
        </Button>
      )}
      {(loading || checkLoading || allIntegrationsLoading) && (
        <LinearProgress />
      )}
    </IntegrationLayout>
  )
}
