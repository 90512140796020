import { Avatar, LinearProgress } from "@mui/material"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useTranslate from "intl/useTranslate"
import useTranslation from "intl/useTranslation"
import React, { useState } from "react"
import { loginWithProvider } from "utils/firebase"

import { ConnectionButton } from "./ConnectionButton"

export default function SignInWithGoogleButton() {
  const t = useTranslate()
  const [translation] = useTranslation()
  const [loading, setLoading] = useState(false)
  const { addToast } = useToasts()
  const onClickSigning = async () => {
    try {
      setLoading(true)
      await loginWithProvider("google.com")
    } catch (e) {
      addToast(e.message, { appearance: "error", autoDismissTimeout: 10000 })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <ConnectionButton
        disabled={loading}
        onClick={onClickSigning}
        language={translation.id}
      >
        <Avatar
          sx={{ width: 22, height: 22 }}
          variant="square"
          alt="Google"
          src="/static/img/google_icon.png"
        />
        <p>{t("Sign-in with Google")}</p>
      </ConnectionButton>
      {loading && <LinearProgress style={{ marginTop: 5 }} />}
    </>
  )
}
