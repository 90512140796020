import { Grid } from "@mui/material"
import useLicense from "graphql/admin/useLicense"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { Alert, P16 } from "ui"
import { colors } from "ui"
import { Warning } from "ui/icons"

const License = styled(Grid)`
  display: flex;
  flex-direction: row;

  &.wrapper {
    margin-left: auto;
  }

  .head {
    padding-bottom: 2px;
  }

  .title {
    font-family: Source Sans Pro;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: ${colors.purple};
    padding-right: 15px;
  }

  .license {
    padding: 2px 6px;
    display: inline-block;
    background: ${colors.grey4};
    border: 1px solid ${colors.grey3};
    box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.12);
  }

  .plan {
    font-weight: 400;
    float: right;
  }
`

interface StatusProps {
  status: string
}

const Status = styled.span<StatusProps>`
  padding: 0 10px;
  border-radius: 15px;
  float: right;
  font-weight: bold;
  ${({ status }) => {
    switch (status) {
      case "Subscribed":
        return `
          color: ${colors.success};
          background: ${colors.success}16;
          border: 1.5px solid ${colors.success};
        `
      case "Unsubscribed":
      case "Suspended":
        return `
        color: ${colors.error};
        background: ${colors.error}16;
        border: 1.5px solid ${colors.error};
        `
      case "PendingFulfillmentStart":
      default:
        return `
          color: ${colors.yellow};
          background: ${colors.yellow}16;
          border: 1.5px solid ${colors.yellow};
        `
    }
  }};
`

interface UserCountProps {
  remaining: number
}

const UserCount = styled.span<UserCountProps>`
  padding-right: 15px;
  color: ${({ remaining }) => {
    if (remaining < 0) {
      return colors.error
    }
    if (remaining < 2) {
      return "orange"
    }
    return "black"
  }};
`

export default function AdminLicenseStatus({
  activeUsers,
}: {
  activeUsers: number
}) {
  const t = useTranslate()
  const { loading, error, license } = useLicense()

  if (error)
    return (
      <Alert severity="warning">
        <P16>{error.message}</P16>
      </Alert>
    )

  if (loading || !license) return null

  const remainingQuantity = license.quantity - activeUsers

  return (
    <License item className="wrapper">
      <div className="license">
        <div className="head">
          <span className="title">{t("License")} :</span>
          <span className="plan">{license.name}</span>
        </div>

        <UserCount remaining={remainingQuantity}>
          {remainingQuantity < 0 && (
            <>
              <Warning
                style={{
                  fontSize: 16,
                  verticalAlign: "middle",
                  lineHeight: 16,
                  height: 16,
                }}
              />
              &nbsp;
            </>
          )}
          {activeUsers}&nbsp;/&nbsp;{license.quantity}&nbsp;
          {license.quantity > 1 ? t("users") : t("user")}
        </UserCount>
        <Status status={license.status}>{t(license.status)}</Status>
      </div>
    </License>
  )
}
