import AdvancedSearchPopUp from "components/AdvancedSearchPopUp/AdvancedSearchPopUp"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { colors, P14 } from "ui"
import { Building, Calendar, Cancel } from "ui/icons"
import LocationTree from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 5px 24px;
  background-color: ${colors.grey4};
  border: 1px solid ${colors.grey3};
  border-radius: 100vh;
  width: 100%;
  cursor: pointer;

  .content {
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: calc(100vw - 98px); // 98px sum of other components sizes/pads

    .location {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: fit-content;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: ${colors.grey3};
    border: 1px solid ${colors.grey2};
  }

  .title {
    color: ${colors.grey1};
  }

  svg {
    width: 18px;
    stroke-width: 2px;
  }

  .cancel-button {
    align-self: center;
    cursor: pointer;

    color: ${colors.grey1};
    width: 24px;
    height: 24px;

    &:hover {
      background-color: ${colors.grey3};
      border-radius: 13px;
    }
  }
`

interface PropsType {
  date: UTCDate
  location: LocationTree
}

export default function AdvancedSearchSummary({ location, date }: PropsType) {
  const t = useTranslate()
  const history = useHistory()
  const [openPopup, setOpenPopup] = useState(false)
  const { isMobile } = useMediaQueries()

  return (
    <>
      <Container onClick={() => setOpenPopup(true)}>
        <div>
          <P14 className="title">{t("Advanced Search")}</P14>
          <div className="content">
            <Calendar />
            <P14>{date.format(isMobile ? "DD/MM/YY" : "short", t)}</P14>
            <Building />
            <P14 className="location">{location.name}</P14>
          </div>
        </div>

        <Cancel
          className="cancel-button"
          onClick={(e) => {
            e.stopPropagation()
            setOpenPopup(false)
            history.push("/")
          }}
        />
      </Container>
      {openPopup && (
        <AdvancedSearchPopUp
          open={openPopup}
          onClose={() => setOpenPopup(false)}
        />
      )}
    </>
  )
}
