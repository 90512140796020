import { ButtonBase } from "@mui/material"
import PageContent from "components/Layout/PageLayout/PageContent/PageContent"
import PageFooter from "components/Layout/PageLayout/PageFooter/PageFooter"
import PageHeader from "components/Layout/PageLayout/PageHeader/PageHeader"
import MobileNavBack from "components/Layout/PageLayout/PageHeader/PageTitle/MobileNavBack/MobileNavBack"
import PageTitle from "components/Layout/PageLayout/PageHeader/PageTitle/PageTitle"
import PageLayout from "components/Layout/PageLayout/PageLayout"
import { UserAvatarFromId } from "components/UserAvatar/UserAvatar"
import useToggleFavorite from "graphql/favorites/useToggleFavorite"
import useUserFavorites from "graphql/favorites/useUserFavorites"
import useMediaQueries from "hooks/useMediaQueries"
import useWindowSize from "hooks/useWindowResize"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import { useHistory } from "react-router"
import styled from "styled-components"
import { Favorite } from "types"
import { Button, colors, H4, IconButton, mediaQueries, P16 } from "ui"
import { Building, Cancel, Group, Plus } from "ui/icons"
import transitions from "ui/transitions"

import CreateFavorite from "./components/CreateFavorite"

interface ContainerProps {
  height: number
}

const Container = styled.div<ContainerProps>`
  height: ${({ height }) => `${height}px`};
  background-color: ${colors.grey4};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`

const List = styled.div`
  border-bottom: solid 1px ${colors.grey3};
`

const Item = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  /* height: 45px; */
  padding: 0 16px 0 12px;
  padding: 8px 16px;
  border-top: solid 1px ${colors.grey3};
  background: hsla(${colors.blackReal}, 0);

  cursor: pointer;

  > div:first-child {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 2;

    > span {
      visibility: hidden;
      opacity: 0;
      transition: ${transitions.generic};
    }
  }
  div:last-child {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 2;
    border-bottom: solid 1px ${colors.grey3};
    > span {
      visibility: hidden;
      opacity: 0;
      transition: ${transitions.generic};
    }
  }

  &:hover {
    background: hsla(${colors.blackReal}, 0.04);

    svg {
      color: ${colors.black};
    }

    div:first-child > span {
      visibility: visible;
      opacity: 1;
    }
  }

  transition: ${transitions.generic};
`

const Subtitle = styled(H4)`
  padding-bottom: 24px;
  @media ${mediaQueries.isMobile} {
    padding-top: 10px;
  }
`

const ButtonIconStyled = styled(ButtonBase)`
  margin-left: auto;

  svg {
    color: ${colors.grey2};
    height: 24px;
    width: 24px;
  }

  &:hover {
    svg {
      color: ${colors.grey1};
    }
  }
`

function FavoritesPage() {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()
  const history = useHistory()

  const toggleFavorite = useToggleFavorite()
  const { favorites } = useUserFavorites()

  const [openNewFavoriteModal, setOpenNewFavoriteModal] =
    useState<boolean>(false)

  const closeNewFavoriteModal = () => setOpenNewFavoriteModal(false)

  const favoritesUsers = favorites.users
  const favoritesGroups = favorites.groups
  const favoritesLocations = favorites.locations

  const redirection = (favorite: Favorite) => {
    if (favorite.type === "user")
      return history.push(`/${favorite.type}/${favorite.itemId}`)
    else return history.push(`/planning/${favorite.type}/${favorite.itemId}`)
  }

  const [, maxHeight] = useWindowSize()

  const noFavorites =
    favoritesUsers.length === 0 &&
    favoritesGroups.length === 0 &&
    favoritesLocations.length === 0

  if (noFavorites)
    return (
      <>
        <PageLayout>
          {isMobile && (
            <PageHeader>
              <MobileNavBack />
              <PageTitle title={t("Favorites")} />
            </PageHeader>
          )}
          <Container height={maxHeight}>
            <P16>{t("no favorites at this time")}</P16>
            <Button onClick={() => setOpenNewFavoriteModal(true)}>
              {t("Add")}
            </Button>
          </Container>
        </PageLayout>
        <CreateFavorite
          favorites={favorites}
          open={openNewFavoriteModal}
          onClose={closeNewFavoriteModal}
        />
      </>
    )

  return (
    <>
      <PageLayout>
        {isMobile ? (
          <PageHeader>
            <MobileNavBack />
            <PageTitle title={t("Favorites")} />
          </PageHeader>
        ) : (
          <PageHeader>
            <PageTitle title={t("Favorites")} />
            <IconButton
              icon={<Plus />}
              onClick={() => setOpenNewFavoriteModal(true)}
            />
          </PageHeader>
        )}

        <PageContent>
          {favoritesUsers.length >= 1 && (
            <>
              <Subtitle>{t("Favorite users")}</Subtitle>
              <List>
                {favoritesUsers.map((favoriteUser) => (
                  <Item
                    key={favoriteUser.id}
                    onClick={() => {
                      redirection(favoriteUser)
                    }}
                  >
                    <div className="left-part">
                      <UserAvatarFromId id={favoriteUser.itemId} />
                      <P16>{favoriteUser.itemName}</P16>
                    </div>
                    <ButtonIconStyled
                      onClick={(e) => {
                        e.stopPropagation()
                        toggleFavorite(favoriteUser.itemId, "user")
                      }}
                    >
                      <Cancel />
                    </ButtonIconStyled>
                  </Item>
                ))}
              </List>
            </>
          )}
          {(favoritesGroups.length >= 1 || favoritesLocations.length >= 1) && (
            <>
              <Subtitle
                style={{ marginTop: favoritesUsers.length >= 1 ? 56 : 0 }}
              >
                {t("Places & groups")}
              </Subtitle>
              <List>
                {[...favoritesGroups, ...favoritesLocations].map((favorite) => (
                  <Item
                    key={favorite.id}
                    onClick={() => {
                      redirection(favorite)
                    }}
                  >
                    <div className="left-part">
                      {favorite.type === "group" ? <Group /> : <Building />}
                      <P16>{favorite.itemName}</P16>
                    </div>
                    <ButtonIconStyled
                      onClick={(e) => {
                        e.stopPropagation()
                        toggleFavorite(favorite.itemId, favorite.type)
                      }}
                    >
                      <Cancel />
                    </ButtonIconStyled>
                  </Item>
                ))}
              </List>
            </>
          )}
        </PageContent>

        {isMobile && (
          <PageFooter>
            <Button onClick={() => setOpenNewFavoriteModal(true)}>
              {t("Add one favorite")}
            </Button>
          </PageFooter>
        )}
      </PageLayout>
      <CreateFavorite
        favorites={favorites}
        open={openNewFavoriteModal}
        onClose={closeNewFavoriteModal}
      />
    </>
  )
}

export default FavoritesPage
