import Avatar from "@mui/material/Avatar"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import usePlanningUserSlots from "graphql/slots/usePlanningUserSlots"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { User } from "types"
import { colors, P14 } from "ui"
import { Camera } from "ui/icons"
import getUserDisplayName from "utils/getUserDisplayName"

import UpdateUserPhotoDialog from "./UpdateUserPhotoDialog/UpdateUserPhotoDialog"

const StyledAvatar = styled(Avatar)<{ $statusColor: string }>`
  img {
    border-radius: 100vh;
  }
`

const AddAvatar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  overflow: hidden;
`

const AddText = styled(P14)`
  color: ${colors.purple};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

interface PropsType {
  me: User
  open: boolean
  onClose: () => void
}

export default function MeAvatarPicker({ me, open, onClose }: PropsType) {
  const t = useTranslate()

  const { from, to } = usePlanningContext()

  const { slots } = usePlanningUserSlots({
    userId: me.id,
    from,
    to,
  })
  const todaySlots = slots.filter((slot) => slot.date.isToday())

  const statusColor =
    todaySlots[0] && todaySlots[0].value !== null
      ? todaySlots.length === 1
        ? todaySlots[0].value === "null" || todaySlots[0].value === null
          ? colors.black
          : colors[todaySlots[0].value]
        : todaySlots.length === 2
        ? todaySlots[1].value === "null" || todaySlots[1].value === null
          ? colors.black
          : colors[todaySlots[1].value]
        : colors.black
      : colors.black

  return (
    <>
      <>
        {me.photo ? (
          <AddAvatar>
            <StyledAvatar
              alt={getUserDisplayName(me, me.company.flags)}
              src={me.photo}
              sx={{ width: 24, height: 24 }}
              $statusColor={statusColor}
            />
          </AddAvatar>
        ) : (
          <AddAvatar>
            <Camera />
            <AddText>{t("Add a profile picture")}</AddText>
          </AddAvatar>
        )}
      </>
      <UpdateUserPhotoDialog open={open} onClose={() => onClose()} />
    </>
  )
}
