import PageContent from "components/Layout/PageLayout/PageContent/PageContent"
import PageLayout from "components/Layout/PageLayout/PageLayout"
import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import React, { useRef, useState } from "react"
import styled from "styled-components"
import { colors, L12, mediaQueries } from "ui"
import { hideScrollBarsCSS } from "ui/utilities"

import CompanyFiles from "./CompanyFiles/CompanyFiles"
import Section from "./components/Section"
import GlobalUserSettings from "./GlobalUserSettings"
import ManageUserSettings from "./ManageUserSettings"
import MePageHeader from "./MePageHeader/MePageHeader"
import NotificationSettings from "./NotificationSettings/NotificationSettings"
import PersonalInformations from "./PersonalInformations/PersonalInformations"
import UserSettings from "./UserSettings"

const Layout = styled.div`
  display: grid;
  grid-template-columns: 20vw auto;

  @media ${mediaQueries.isDesktop} {
    max-height: calc(
      100vh - 20px - 35px
    ); // 20px is page content bottom padding
    height: calc(100vh - 20px - 35px);
    margin-top: 35px;
  }

  @media ${mediaQueries.isNarrowScreen} {
    max-height: calc(
      100vh - 20px - 128px
    ); // page content bottom padding + header height
    height: calc(100vh - 20px - 128px);
    margin-top: 0;
  }

  @media ${mediaQueries.isMobile} {
    display: flex;
    max-height: unset;
    height: unset;
  }
`

const AnchorList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 16px;

  p {
    cursor: pointer;
    color: ${colors.grey2};
    text-align: right;
    margin: 4px;
  }

  @media ${mediaQueries.isMobile} {
    display: none;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20vw 16px 16px;
  overflow-y: scroll;
  ${hideScrollBarsCSS}

  @media ${mediaQueries.isMobile} {
    padding: 0;
  }
`

const NewUX = styled.div`
  margin-bottom: 24px;
  padding-bottom: 40px;
  border-bottom: 1px solid ${colors.grey3};
  padding-top: 20px;
  border-top: 1px solid ${colors.grey3};

  @media ${mediaQueries.isMobile} {
    margin-top: 10px;
    margin-bottom: 20px;
  }
`

export default function CurrentUserSettingsPage() {
  const t = useTranslate()
  const { user: currentUser, loading, error } = useUser()
  const UserInfos = useRef<HTMLDivElement>(null)
  const notificationsRef = useRef<HTMLDivElement>(null)
  const DisplayRef = useRef<HTMLDivElement>(null)
  const IntegrationsRef = useRef<HTMLDivElement>(null)
  const NewsRef = useRef<HTMLDivElement>(null)
  const companyFilesRef = useRef<HTMLDivElement>(null)
  const planningTemplateRef = useRef<HTMLDivElement>(null)

  const [openUserInfosDialog, setOpenUserInfosDialog] = useState<boolean>(false)

  const [selectedAnchor, setSelectedAnchor] = useState({
    id: "personal information",
    text: t("personal information"),
  })

  const anchors = [
    { id: "personal information", text: t("personal information") },
    { id: "notifications", text: t("Notifications") },
    {
      id: "planning template",
      text: t("user-setting-planning-template--title"),
    },
    { id: "display", text: t("user-setting-group-display") },
    { id: "integrations", text: t("Integrations") },
    { id: "corporate documents", text: t("corporate documents") },
    { id: "news & feedback", text: t("news & feedback") },
  ]

  const executeScroll = (anchorId: string) => {
    if (anchorId === "personal information") UserInfos.current?.scrollIntoView()
    if (anchorId === "notifications") notificationsRef.current?.scrollIntoView()
    if (anchorId === "planning template")
      if (planningTemplateRef.current)
        planningTemplateRef.current.scrollIntoView()
    if (anchorId === "display") DisplayRef.current?.scrollIntoView()
    if (anchorId === "integrations") IntegrationsRef.current?.scrollIntoView()
    if (anchorId === "corporate documents")
      if (companyFilesRef.current) companyFilesRef.current.scrollIntoView()
    if (anchorId === "news & feedback") NewsRef.current?.scrollIntoView()
  }

  if (loading || error)
    return <LoaderOrErrorComponent loading={loading} error={error} />
  if (!currentUser) return <></>

  return (
    <PageLayout>
      <MePageHeader
        me={currentUser}
        setOpenUserInfosDialog={setOpenUserInfosDialog}
      />

      <PageContent>
        <Layout>
          <AnchorList>
            {anchors.map((anchor) => (
              <li key={anchor.id}>
                <L12
                  style={{
                    color:
                      anchor.id === selectedAnchor.id
                        ? colors.black
                        : colors.grey2,
                  }}
                  onClick={() => {
                    executeScroll(anchor.id)
                    setSelectedAnchor({ id: anchor.id, text: anchor.text })
                  }}
                >
                  {anchor.text}
                </L12>
              </li>
            ))}
          </AnchorList>
          <Content>
            <PersonalInformations
              user={currentUser}
              ref={UserInfos}
              openUserInfosDialog={openUserInfosDialog}
              setOpenUserInfosDialog={setOpenUserInfosDialog}
            />
            <NotificationSettings ref={notificationsRef} />
            <NewUX ref={DisplayRef}>
              <ManageUserSettings />
            </NewUX>
            <UserSettings user={currentUser} ref={IntegrationsRef} />
            <Section ref={companyFilesRef} component={<CompanyFiles />} />
            <div ref={NewsRef}>
              <GlobalUserSettings />
            </div>
          </Content>
        </Layout>
      </PageContent>
    </PageLayout>
  )
}
