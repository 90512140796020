import React, { MouseEvent, useState } from "react"
import styled from "styled-components"
import { Equipment } from "types"
import { colors } from "ui"
import { Info } from "ui/icons"
import LocationTree from "utils/LocationTree"

import LocationEquipmentsDialog from "./LocationEquipmentsDialog/LocationEquipmentsDialog"

const InfoStyled = styled(Info)`
  stroke: ${colors.grey1};
  width: 16px !important;
  height: 16px !important;
  cursor: pointer;
`

interface PropsType {
  location: LocationTree
  equipments: Equipment[]
}

export default function LocationEquipmentsPopper({
  location,
  equipments,
}: PropsType) {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const handlePopoverOpen = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handlePopoverClose = () => {
    setAnchorEl(null)
  }
  const openPopper = Boolean(anchorEl)

  return (
    <>
      <div
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        style={{ height: 16, width: 16 }}
      >
        <InfoStyled />
      </div>

      <LocationEquipmentsDialog
        open={openPopper}
        anchorEl={anchorEl}
        location={location}
        equipments={equipments}
      />
    </>
  )
}
