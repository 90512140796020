import { gql } from "@apollo/client"

export const UserFragment = gql`
  fragment UserFragment on User {
    id
    email
    name
    role
    teamName
    isManager
    flags
    disabled
    registered
    timezoneOffset
    teamName
    photo
    company {
      id
      name
      flags
    }
    manager {
      id
      name
      email
      role
      teamName
      isManager
      photo
    }
    team {
      id
      name
      email
      role
    }
  }
`

export const UserBaseFragment = gql`
  fragment UserBaseFragment on UserBase {
    id
    email
    name
    role
    teamName
    isManager
    photo
  }
`

export const UserWithTeamFragment = gql`
  fragment UserWithTeamFragment on UserWithTeam {
    id
    email
    name
    role
    teamName
    isManager
    photo
    team {
      id
      name
      email
      role
      teamName
      isManager
      photo
      registered
      disabled
    }
    manager {
      id
      name
      email
      role
      teamName
      isManager
      photo
    }
  }
`

export const UserAdminBaseFragment = gql`
  fragment UserAdminBaseFragment on UserAdminBase {
    id
    email
    name
    role
    teamName
    isManager
    photo
    registered
    disabled
    team {
      id
      name
      email
      role
      teamName
      isManager
      photo
      registered
      disabled
    }
    manager {
      id
      name
      email
      role
      teamName
      isManager
      photo
    }
  }
`

export const AdminUserStatFragment = gql`
  fragment AdminUserStatFragment on AdminUserStat {
    id
    email
    name
    role
    teamName
    photo
    registered
    disabled
    home
    office
    ooc
    else
    off
    null
    registrationDate
    completion
  }
`

export const UserCompleteStatsFragment = gql`
  fragment UserCompleteStatsFragment on UserCompleteStats {
    id
    email
    name
    role
    teamName
    photo
    disabled
    manager {
      id
      name
      email
      role
      teamName
      photo
    }
    home
    office
    ooc
    else
    off
    null
    total
    completion
  }
`

export const TeamCompleteStatsFragment = gql`
  fragment TeamCompleteStatsFragment on TeamCompleteStats {
    id
    email
    name
    role
    teamName
    photo
    teamSize
    home
    office
    ooc
    else
    off
    null
    total
    completion
  }
`
