import { gql } from "@apollo/client"
import {
  Checkbox,
  FormHelperText,
  Link as MuiLink,
  TextField as MuiTextField,
} from "@mui/material"
import PasswordInput from "components/PasswordInput"
import { getYupPassword } from "components/PasswordInput"
import { Formik } from "formik"
import useProtectedMutation from "graphql/useProtectedMutation"
import useLink from "hooks/useLink"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { useCallback, useState } from "react"
import { Helmet } from "react-helmet"
import { useIntl } from "react-intl"
import { useHistory } from "react-router-dom"
import styled from "styled-components/macro"
import { Button, colors, Dialog, H3, mediaQueries, P14, P16 } from "ui"
import { Cancel } from "ui/icons"
import { CheckOff, CheckOn } from "ui/icons"
import { loginWithToken } from "utils/firebase"
import getUrlSearchParam from "utils/getUrlSearchParam"
import * as Yup from "yup"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 480px;

  @media ${mediaQueries.isMobile} {
    width: 90%;
    margin-left: 10px;
    margin-right: 10px;
  }

  h3 {
    color: ${colors.purple};
  }

  .forgot-password {
    color: ${colors.purple};
    margin-top: 5px;
    text-align: flex-end;
    margin-bottom: 10px;
  }

  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 370px;
    margin-top: 32px;
    gap: 16px;

    @media ${mediaQueries.isMobile} {
      width: 280px;
    }

    .terms-of-service {
      display: flex;
      flex-direction: column;

      .checkbox-line {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
`
const FormikTextField = styled(MuiTextField)`
  & .MuiOutlinedInput-root {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: ${colors.black};
    height: 40px;
    border: 1px solid ${colors.grey2};
  }
  & .MuiOutlinedInput-root :hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)),
      ${colors.grey4};
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 10px;
    color: ${colors.black};
    border-radius: 4px;
  }
  & .MuiOutlinedInput-root.Mui-disabled {
    background: ${colors.grey4};
    cursor: not-allowed;
    color: ${colors.grey1};
  }
  & .MuiOutlinedInput-root.Mui-focused {
    background: linear-gradient(0deg, ${colors.white}, ${colors.white}),
      ${colors.grey4};
    border: 2px solid ${colors.green};
    color: ${colors.black};
    height: 40px;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  & .MuiInputAdornment-root {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: ${colors.black};
    height: 40px;
  }
`

const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
`

const REGISTER_USER = gql`
  mutation RegisterCompany($companyToRegister: InputRegisterCompany!) {
    registerCompany(companyToRegister: $companyToRegister) {
      id
      email
      company {
        id
        name
      }
      flags
    }
  }
`

const ConfirmationMessage = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  p {
    margin-left: 12px;
  }
`

function SignUp() {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()

  const intl = useIntl()
  const history = useHistory()
  const registerMutation = useProtectedMutation(
    "registerCompany",
    REGISTER_USER
  )
  const goToSignIn = useLink("/signin")

  const email = getUrlSearchParam("email")

  const [selectedPlan /*, setSelectedPlan*/] = useState<string | undefined>(
    "early"
  )

  const notifyMissingAccount = window.location.search.includes(
    "notifyMissingAccount=true"
  )

  const [showPopup, setShowPopup] = useState(notifyMissingAccount)
  const closePopup = useCallback(() => setShowPopup(false), [])

  const [tosCheckBoxTouched, setTosCheckBoxTouched] = useState(false)

  const onSubmit = useCallback(
    async (
      values: {
        company: string
        email: string
        phone: string
        password: string
        confirmPassword: string
        submit: boolean
      },
      { setErrors, setStatus, setSubmitting }: any
    ) => {
      try {
        const { data } = await registerMutation({
          variables: {
            companyToRegister: {
              name: values.company,
              phone: values.phone,
              email: values.email.toLowerCase(),
              password: values.password,
              plan: selectedPlan,
            },
          },
        })
        const customToken = data?.registerCompany?.flags?.customToken
        console.log("YES", customToken)
        if (customToken) {
          await loginWithToken(customToken)
          history.replace("/")
        }
      } catch (error) {
        const message = error.message || "Something went wrong"
        setStatus({ success: false })
        setErrors({ submit: message })
        setSubmitting(false)
      }
    },
    [history, registerMutation, selectedPlan]
  )

  return (
    <Container>
      <Dialog
        open={showPopup}
        onClose={closePopup}
        title={t("signin-missing-account-title")}
        rightButton={<Button onClick={closePopup}>OK</Button>}
      >
        <PopupContent>
          <P16>{t("signin-missing-account-content")}</P16>
        </PopupContent>
      </Dialog>
      <Helmet title="Sign Up" />
      <H3>{t("create your company account here")}</H3>

      <Formik
        initialValues={{
          company: "",
          email: email || "",
          phone: "",
          password: "",
          confirmPassword: "",
          termsOfService: false,
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(t("Must be a valid email"))
            .max(255)
            .required(t("Email is required")),
          password: getYupPassword(false, t),
          termsOfService: Yup.boolean().oneOf(
            [true],
            t("You must accept terms of service")
          ),
        })}
        onSubmit={onSubmit}
        validateOnChange
        validateOnMount
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          isValid,
        }) => (
          <form onSubmit={handleSubmit} className="form">
            {errors.submit && (
              <ConfirmationMessage>
                <Cancel />
                <P16>{errors.submit}</P16>
              </ConfirmationMessage>
            )}
            <FormikTextField
              type="email"
              name="email"
              placeholder={t("Email address")}
              value={values.email}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              required
              size="small"
              disabled={!!email}
            />
            <PasswordInput
              name="password"
              fullWidth
              isFormik
              useB4BPasswords={false}
              newPassword={true}
              placeholder={t("Password")}
              value={values.password}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
              handleChange={handleChange}
              variant="outlined"
              size="small"
            />

            <div className="terms-of-service">
              <div className="checkbox-line">
                <Checkbox
                  name="termsOfService"
                  value={values.termsOfService}
                  onChange={(e) => {
                    setTosCheckBoxTouched(true)
                    handleChange(e)
                  }}
                  style={{ color: colors.black, padding: "0 9px 0 0" }}
                  icon={<CheckOff />}
                  checkedIcon={<CheckOn />}
                />
                {intl.locale === "fr" && (
                  <P14>
                    J&apos;accepte les
                    <MuiLink
                      href="https://offishall.io/conditions-generales-dutilisation-du-compte"
                      target="_blank"
                    >
                      &nbsp;conditions d&apos;utilisation
                    </MuiLink>
                  </P14>
                )}
                {intl.locale === "en" && (
                  <P14>
                    I accept Offishall&apos;s
                    <MuiLink
                      href="https://en.offishall.io/conditions-generales-dutilisation-du-compte"
                      target="_blank"
                    >
                      &nbsp;terms of service
                    </MuiLink>
                  </P14>
                )}
              </div>
              {Boolean(errors.termsOfService) && (
                <FormHelperText error>
                  {tosCheckBoxTouched && errors.termsOfService}
                </FormHelperText>
              )}
            </div>

            <Button
              type="submit"
              disabled={!isValid || selectedPlan === null}
              width={isMobile ? 280 : 370}
            >
              {t("Sign up")}
            </Button>
            <Button secondary onClick={goToSignIn} width={isMobile ? 280 : 370}>
              {t("Already have an account ? Click here to sign-in")}
            </Button>
          </form>
        )}
      </Formik>
    </Container>
  )
}

export default SignUp
