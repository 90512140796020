import useMediaQueries from "hooks/useMediaQueries"
import React, { MouseEvent, useState } from "react"
import styled from "styled-components"
import { colors, P16, Popover } from "ui"
import mediaQueries from "ui/mediaQueries"

const IconTextContainer = styled.div`
  display: flex;
  align-items: center;

  font-size: 16px;
  line-height: 20px;

  .icon-text {
    &__icon {
      display: flex;
      margin-right: 8px;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    &__button {
      display: flex;
      margin-left: 28px;

      svg {
        width: 16px;
        height: 16px;
        color: ${colors.grey1};
      }
    }

    &__close-button {
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  @media ${mediaQueries.isTouchable} {
    font-size: 12px;
    line-height: 16px;

    .icon-text {
      &__icon {
        display: flex;
        margin-right: 4px;

        svg {
          width: 16px;
          height: 16px;
        }
      }

      &__button {
        display: flex;
        margin-left: 16px;

        svg {
          width: 16px;
          height: 16px;
          color: ${colors.grey1};
        }
      }

      &__close-button {
        display: none;
      }
    }
  }
`

interface PropsType {
  children: React.ReactNode
  icon?: JSX.Element
  button?: JSX.Element
  withEllipsis?: boolean
  popoverChildren?: React.ReactNode
  fullWidth?: boolean
}

export function IconText({
  children,
  icon,
  button,
  withEllipsis,
  popoverChildren,
  fullWidth,
}: PropsType) {
  const { isMobile } = useMediaQueries()
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

  const isNotPlanningPage =
    location.pathname !== "/" && !location.pathname.startsWith("/planning")

  const handlePopoverOpen = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  return (
    <IconTextContainer
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      {icon && <span className="icon-text__icon">{icon}</span>}
      <P16
        style={
          withEllipsis
            ? {
                width: isMobile ? "14ch" : "30ch",
                minWidth: isMobile ? "14ch" : "30ch",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }
            : fullWidth
            ? { width: "100%" }
            : undefined
        }
      >
        {children}
      </P16>
      {isNotPlanningPage && withEllipsis && (
        <Popover anchorEl={anchorEl} open={open} onClose={handlePopoverClose}>
          {popoverChildren}
        </Popover>
      )}
      {button && <span className="icon-text__button">{button}</span>}
    </IconTextContainer>
  )
}
