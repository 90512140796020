import useTranslate from "intl/useTranslate"
import TeamStats from "pages/StatisticsPage/TeamStats/TeamStats"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Dialog } from "ui"
import UTCDate, { UTCDateClampType } from "utils/UTCDate"

const Content = styled.div`
  padding: 0 20px 20px 20px;
`

interface PropsType {
  open: boolean
  onClose: () => void
}

export default function TeamStatsDialog({ open, onClose }: PropsType) {
  const t = useTranslate()

  const pageHeaderHeight = {
    header: 160,
    tabsHeader: 0,
  }

  const [selectedPeriod, setSelectedPeriod] = useState<
    UTCDateClampType | "personalized"
  >("WEEK-MONDAY")
  const editSelectedPeriod = (value: UTCDateClampType | "personalized") =>
    setSelectedPeriod(value)

  const [from, setFrom] = useState<UTCDate>(new UTCDate("WEEK-MONDAY"))
  const [to, setTo] = useState<UTCDate>(new UTCDate("NEXT-SUNDAY"))
  const editFromDate = (value: UTCDate) => setFrom(value)
  const editToDate = (value: UTCDate) => setTo(value)

  useEffect(() => {
    if (selectedPeriod === "YEAR") {
      setFrom(new UTCDate("YEAR"))
      const to = new UTCDate("NEXT-YEAR")
      to.shift("DAY", -1)
      setTo(to)
      return
    }
    if (selectedPeriod === "SEMESTER") {
      setFrom(new UTCDate("SEMESTER"))
      const to = new UTCDate("NEXT-SEMESTER")
      to.shift("DAY", -1)
      setTo(to)
      return
    }
    if (selectedPeriod === "QUARTER") {
      setFrom(new UTCDate("QUARTER"))
      const to = new UTCDate("NEXT-QUARTER")
      to.shift("DAY", -1)
      setTo(to)
      return
    }
    if (selectedPeriod === "MONTH") {
      setFrom(new UTCDate("MONTH"))
      const to = new UTCDate("NEXT-MONTH")
      to.shift("DAY", -1)
      setTo(to)
      return
    }
    if (selectedPeriod === "WEEK-MONDAY") {
      setFrom(new UTCDate("WEEK-MONDAY"))
      setTo(new UTCDate("NEXT-SUNDAY"))
      return
    }
    if (selectedPeriod === "personalized") return

    setFrom(new UTCDate("WEEK-MONDAY"))
    setTo(new UTCDate("NEXT-SUNDAY"))
  }, [selectedPeriod])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t("team stats simple")}
      maxWidth="lg"
    >
      <Content>
        <TeamStats
          from={from}
          to={to}
          selectedPeriod={selectedPeriod}
          pageHeaderHeight={pageHeaderHeight}
          editSelectedPeriod={editSelectedPeriod}
          editFromDate={editFromDate}
          editToDate={editToDate}
          variant="team"
        />
      </Content>
    </Dialog>
  )
}
