import { ActiveLocation } from "components/PlanningContextProvider/PlanningContextProvider"
import React from "react"
import { User, UserBase } from "types"

import { ActiveLocationsList } from "../../../ActiveLocationsList/ActiveLocationsList"
import PlanningHeaderMessage from "../../PlanningHeaderMessage/PlanningHeaderMessage"

interface LocalComponentPropsType {
  users: UserBase[]
  activeLocation: ActiveLocation
  noActiveFilters: boolean
  me: User | null
}

export default function MessageOrActiveLocationsList({
  users,
  noActiveFilters,
  activeLocation,
  me,
}: LocalComponentPropsType) {
  if (users.length === 0 && noActiveFilters)
    return (
      <PlanningHeaderMessage messageToBeTranslated="No one in this group" />
    )
  if (
    !activeLocation.persist &&
    users.length === 1 &&
    users[0].id === me?.id &&
    noActiveFilters
  )
    return (
      <PlanningHeaderMessage messageToBeTranslated="No one else in this group" />
    )
  return <ActiveLocationsList />
}
