import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import React, { useState } from "react"
import styled from "styled-components/macro"
import { colors, IconButton } from "ui"
import { ListSorted } from "ui/icons"

import ColorLegendModal from "./ColorLegendModal"

interface StyledIconButtonProps {
  isOpen: boolean
}

const StyledIconButton = styled(IconButton)<StyledIconButtonProps>`
  svg {
    width: 20px;
    height: 20px;
    transform: rotate(90deg);
  }
  ${({ isOpen }) => {
    if (isOpen)
      return `
        background-color: ${colors.purple};
        svg {
          color: ${colors.white};
        }
      `
  }}
`

export default function ColorLegend() {
  const { openDrawer: openedDrawers, setOpenDrawer: setOpenedDrawers } =
    usePlanningContext()
  const [open, setOpen] = useState<boolean>(false)

  return (
    <>
      <StyledIconButton
        icon={<ListSorted />}
        onClick={() => {
          setOpen(true)
          if (openedDrawers !== null) setOpenedDrawers(null)
        }}
        isOpen={open}
      />
      <ColorLegendModal open={open} onClose={() => setOpen(false)} />
    </>
  )
}
