import UserAvatar from "components/UserAvatar/UserAvatar"
import useUser from "graphql/users/useUser"
import useUserTree from "hooks/useUserTree"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { IntegrationEvent, MeetingRoom, UpsertEventInput } from "types"
import { Button, colors, Dialog, H4, L12, P14, P14Bold } from "ui"
import getUserDisplayName from "utils/getUserDisplayName"
import UserTree from "utils/UserTree"

import MeetingRoomEventForm from "../MeetingRoomEventForm/MeetingRoomEventForm"
import GuestsList from "./GuestsList/GuestsList"
import MeetingProgressBar from "./MeetingProgressBar/MeetingProgressBar"

const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px 24px 16px 24px;
  background: ${colors.grey4};
  .title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;

  .organizer {
    display: flex;
    align-items: center;
    gap: 10px;

    > div:first-child {
      // enforce manager avatar alignment
      height: 24px;
    }
  }
`

interface PropsType {
  meetingRoom: MeetingRoom
  meetingRoomEvent: IntegrationEvent
  meetingRooms: MeetingRoom[]
  onClose: () => void
}

export default function MeetingRoomEventPopup({
  meetingRoom,
  meetingRoomEvent,
  meetingRooms,
  onClose,
}: PropsType) {
  const t = useTranslate()
  const { user: me } = useUser()
  const { userTree } = useUserTree()

  const [showPopup, setShowPopup] = useState(true)
  const [eventToBeEdited, setEventToBeEdited] = useState<
    UpsertEventInput | undefined
  >(undefined)

  const canUpdateEvent = meetingRoomEvent.organizer?.email === me?.email

  const organizerAsUser = UserTree.findUserByEmail(
    userTree,
    meetingRoomEvent.organizer?.email ?? ""
  )

  if (!me) return <></>

  return (
    <>
      <Dialog
        open={showPopup}
        onClose={onClose}
        leftButton={
          <Button secondary onClick={onClose}>
            {t("Cancel")}
          </Button>
        }
        rightButton={
          <Button
            disabled={!canUpdateEvent}
            onClick={() => {
              if (!canUpdateEvent) return
              setEventToBeEdited(meetingRoomEvent)
              setShowPopup(false)
            }}
          >
            {t("Update")}
          </Button>
        }
      >
        <Header>
          <div className="title">
            <H4>{meetingRoom.name}</H4>
            <MeetingProgressBar
              meetingRoom={meetingRoom}
              meetingRoomEvent={meetingRoomEvent}
            />
          </div>
          <div>
            <P14Bold color="grey1">
              {t("from")} {meetingRoomEvent.from.format("HH:MM")} {t("to")}{" "}
              {meetingRoomEvent.to.format("HH:MM")}
            </P14Bold>
            <L12 color="grey1">
              {t("organizer")}: {meetingRoomEvent.organizer?.email}
            </L12>
          </div>
        </Header>
        <Body>
          <div>
            <H4>{meetingRoomEvent.name}</H4>

            <P14Bold color="grey1">{meetingRoom.description}</P14Bold>
          </div>
          {organizerAsUser && (
            <div className="organizer">
              <div>
                <UserAvatar user={organizerAsUser} />
              </div>
              <div>
                <L12 color="grey1">{t("organizer")}</L12>
                <P14>
                  {getUserDisplayName(organizerAsUser, me.company.flags)}
                </P14>
              </div>
            </div>
          )}
          <GuestsList meetingRoomEvent={meetingRoomEvent} />
        </Body>
      </Dialog>
      {eventToBeEdited !== undefined && (
        <MeetingRoomEventForm
          event={eventToBeEdited}
          meetingRooms={meetingRooms}
          onClose={() => {
            onClose()
            setEventToBeEdited(undefined)
          }}
        />
      )}
    </>
  )
}
