import styled from "styled-components"
import { colors, mediaQueries, transitions } from "ui"
import getColorFromStatus from "utils/getColorFromStatus"

function getBorderRadius(isFirst: boolean, isLast: boolean) {
  if (isFirst && isLast) return `border-radius: 50px;`
  if (isFirst) return `border-radius: 50px 0 0 50px;`
  if (isLast) return `border-radius: 0 50px 50px 0;`
}

interface PropsType {
  isFirst: boolean
  isLast: boolean
  isActive: boolean
}

const Base = styled.div<PropsType>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 16px;
  width: 100%;

  p {
    transition: ${transitions.toggleActiveLocation};
  }

  ${({ isFirst, isLast }) => getBorderRadius(isFirst, isLast)}

  transition: ${transitions.toggleActiveLocation};

  @media ${mediaQueries.isMobile} {
    height: 14px;
  }
`

interface SlotPropsType extends PropsType {
  value: string | null
}

export const Slot = styled(Base)<SlotPropsType>`
  ${({ isActive, value }) => {
    if (isActive) return `background: ${getColorFromStatus(value)};`
    return `background: ${getColorFromStatus(value)}14;`
  }}

  p {
    ${({ isActive }) => {
      if (isActive)
        return `
        color: ${colors.black};`
      else return `color: ${colors.black}14;`
    }}
  }
`

export const MonthViewNullSlot = styled(Base)<PropsType>`
  border-top: solid 2px ${colors.grey3};
  border-bottom: solid 2px ${colors.grey3};
  ${({ isFirst, isLast }) => {
    if (isFirst && isLast)
      return `
    border-left: solid 2px ${colors.grey3};
    border-right: solid 2px ${colors.grey3};
    `
    if (isFirst) return `border-left: solid 2px ${colors.grey3};`
    if (isLast) return `border-right: solid 2px ${colors.grey3};`
  }}
`
