import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function AppleImage2021({
  stroke,
  strokeWidth,
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      strokeWidth={strokeWidth ?? 1.5}
      stroke={stroke ? stroke : "currentColor"}
      {...rest}
    >
      <path
        d="M13.5 22V18M2 15.5V2.6C2 2.26863 2.26863 2 2.6 2H21.4C21.7314 2 22 2.26863 22 2.6V15.5H2ZM2 15.5V17.4C2 17.7314 2.26863 18 2.6 18H21.4C21.7314 18 22 17.7314 22 17.4V15.5H2ZM2 15.5H22H2ZM9 22H10.5H9ZM10.5 22V18V22ZM10.5 22H13.5H10.5ZM13.5 22H15H13.5Z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}
