import styled from "styled-components"
import { colors } from "ui"

export const NotBookedItem = styled.li<{
  "data-content": string
  duration: number
  from: number
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 4px 0;
  cursor: pointer;

  // time separators
  // right border behind content
  box-shadow: -1px 0 ${colors.grey3} inset;
  // vertical dashed line
  background-image: repeating-linear-gradient(
    180deg,
    ${colors.grey3},
    ${colors.grey3} 2px,
    transparent 2px,
    transparent 6px,
    ${colors.grey3} 6px,
    ${colors.grey3} 10px
  );
  background-position: center;
  background-size: 1px 100%;
  background-repeat: no-repeat;
  &:last-child {
    box-shadow: none;
  }

  // normal empty slot (not booked)
  &:not(.booked) {
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      border: solid 2px ${colors.office}00;
      border-radius: 8px;
    }
    &:hover {
      div {
        border: solid 2px ${colors.office};
      }
    }
  }
`
