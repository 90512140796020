import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { Button, colors } from "ui"

const Layout = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-top: 1px solid ${colors.grey3};
`

interface PropsType {
  confirmDisabled?: boolean
  cancelDisabled?: boolean
  onCancel: () => void
  onConfirm: () => void
}

export default function FormFooter({
  confirmDisabled,
  cancelDisabled,
  onConfirm,
  onCancel,
}: PropsType) {
  const t = useTranslate()
  return (
    <Layout>
      <Button
        secondary
        disabled={cancelDisabled}
        onClick={() => {
          if (cancelDisabled) return
          onCancel()
        }}
      >
        {t("Cancel")}
      </Button>
      <Button
        onClick={() => {
          if (confirmDisabled) return
          onConfirm()
        }}
        disabled={confirmDisabled}
      >
        {t("Confirm")}
      </Button>
    </Layout>
  )
}
