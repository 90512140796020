import { Capacitor } from "@capacitor/core"

function getPlatform() {
  if (!Capacitor.isNativePlatform()) {
    if (process.env.REACT_APP_API === "https://api-dev.offishall.io") {
      return "origin/dev"
    }
    if (process.env.REACT_APP_API === "https://api.offishall.io") {
      return "origin/prod"
    }
    // we don't care about local dev
    return "origin/dev" // we can replace this by unknown if we want to get rid of the button
  }
  return Capacitor.getPlatform()
}

export type UpdatorType =
  | "UP-TO-DATE"
  | "UPDATE-AVAILABLE"
  | "FORCE-UPDATE"
  | "UNKNOWN"

export default async function checkAppVersion(): Promise<UpdatorType> {
  const url = `${process.env.REACT_APP_API}/versions`
  const branch = getPlatform()
  try {
    const res = await fetch(url)
    const { current, lastCompatible } = await res.json()
    if (!current || !lastCompatible) {
      return "UNKNOWN"
    }
    const currentVersionFound = current.find((r: any) => r.branch === branch)
    const lastCompatibleVersionFound = lastCompatible.find(
      (r: any) => r.branch === branch
    )
    if (currentVersionFound && lastCompatibleVersionFound) {
      const { date, hash } = currentVersionFound
      const versionOnServer = `${date} ${hash}`
      const versionOnClient = process.env.REACT_APP_VERSION
      if (versionOnServer !== versionOnClient) {
        if (!versionOnClient) {
          return "FORCE-UPDATE"
        }
        const [clientDate] = versionOnClient.split(" ")
        if (
          !clientDate ||
          Number(clientDate) < Number(lastCompatibleVersionFound.date)
        ) {
          return "FORCE-UPDATE"
        }
        return "UPDATE-AVAILABLE"
      } else {
        return "UP-TO-DATE"
      }
    }
  } catch (e) {
    console.error(`Error while fetching /versions: ${e.message}`)
  }
  return "UNKNOWN"
}
