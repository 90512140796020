import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import useTranslation from "intl/useTranslation"
import React from "react"
import { L12, Link } from "ui"

import { Item } from "./components/Item"

interface PropsType {
  title: string
}

export default function MenuLink({ title }: PropsType) {
  const t = useTranslate()
  const [locale] = useTranslation()
  const { isMobile } = useMediaQueries()

  const links = {
    fr: {
      userGuide:
        "https://www.notion.so/offishall/Guide-utilisateur-Offishall-f2714d7d5eee43cda7b98c9355e5483e",
      cgu: "https://www.offishall.io/conditions-generales-dutilisation-du-compte",
      privacyPolicy: "https://www.offishall.io/politique-de-confidentialite",
    },
    en: {
      userGuide:
        "https://www.notion.so/offishall/User-Guide-Offishall-5cf1d772331249e6916440630a2f99e3",
      cgu: "https://en.offishall.io/conditions-generales-dutilisation-du-compte",
      privacyPolicy: "https://en.offishall.io/politique-de-confidentialite",
    },
  }

  const defineLink = () => {
    if (title === t("privacy policy"))
      return links[`${locale.id === "fr" ? "fr" : "en"}`].privacyPolicy
    else if (title === t("simple cgu"))
      return links[`${locale.id === "fr" ? "fr" : "en"}`].cgu
    else if (title === t("user guide"))
      return links[`${locale.id === "fr" ? "fr" : "en"}`].userGuide
    else return ""
  }

  return (
    <Item zeroBorder>
      <L12 color={isMobile ? "grey1" : "grey2"}>
        <Link external to={defineLink()}>
          {title}
        </Link>
      </L12>
    </Item>
  )
}
