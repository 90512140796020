import { gql, useQuery } from "@apollo/client"
import { CompanyFile } from "types"

import { CompanyFileFragment } from "./fragments/companyFileFragments"

const QUERY = gql`
  ${CompanyFileFragment}
  query companyFiles {
    companyFiles {
      ...CompanyFileFragment
    }
  }
`

export default function useComanyFiles() {
  const { data, loading, error } =
    useQuery<{ companyFiles: CompanyFile[] }>(QUERY)

  return {
    companyFiles: data?.companyFiles || [],
    loading,
    error,
  }
}
