import { gql } from "@apollo/client"

export const BulkSlotRuleFragment = gql`
  fragment BulkSlotRuleFragment on BulkSlotRule {
    id
    title
    status
    from
    to
    who {
      everyone
      teams {
        id
        name
        teamName
      }
      groups {
        id
        name
      }
    }
  }
`
