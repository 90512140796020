import { Theme } from "@mui/material"
import { createGlobalStyle } from "styled-components/macro"
import { ThemeProps } from "styled-components/macro"

type GlobalStyleProps = {
  theme: ThemeProps<Theme> & { palette: any }
}

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
  }
`

export default GlobalStyle
