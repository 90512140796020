import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import useAllGroups from "graphql/groups/useAllGroups"
import useMyGroupsSimple from "graphql/groups/useMyGroupsSimple"
import uniqBy from "lodash.uniqby"
import {
  DataLayout,
  Layout,
  SampleList,
} from "pages/StatisticsPage/components/Layout"
import SamplePicker from "pages/StatisticsPage/components/SamplePicker/SamplePicker"
import WorkingModeChart from "pages/StatisticsPage/WorkingMode/WorkingModeChart/WorkingModeChart"
import React, { useState } from "react"
import styled from "styled-components"
import { GroupSimple, UserBase } from "types"
import { Toggle } from "ui"
import LocationTree from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

const ChartLayout = styled.div`
  display: flex;
  flex-direction: column;

  > div > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 16px 0;
  }
  canvas {
    max-width: 250px;
  }

  > :last-child {
    margin-left: auto;
  }

  @media (max-width: 1080px) {
    > div > div {
      margin: 0 auto;
    }

    canvas {
      max-width: 200px;
    }
  }
`

interface PropsType {
  from: UTCDate
  to: UTCDate
  enableWeekends: boolean
  pageHeaderHeight: { header?: number; tabsHeader?: number }
}

export default function PWStatsWorkingMode({
  from,
  to,
  enableWeekends,
  pageHeaderHeight,
}: PropsType) {
  const maxHeight =
    pageHeaderHeight.header !== undefined &&
    pageHeaderHeight.tabsHeader !== undefined
      ? pageHeaderHeight.header + pageHeaderHeight.tabsHeader
      : undefined

  const { groups: myGroupsSimple, loading, error } = useMyGroupsSimple()
  const {
    groups: allGroups,
    loading: loadingAllGroups,
    error: errorAllGroups,
  } = useAllGroups()

  const [selectedGroups, setSelectedGroups] = useState<GroupSimple[]>([])
  const [selectedUsers, setSelectedUsers] = useState<UserBase[]>([])

  const [showTotal, setShowTotal] = useState(false)

  const canShowTotal =
    (selectedGroups.length > 0 || selectedUsers.length > 0) && showTotal

  if (loading || loadingAllGroups || error || errorAllGroups)
    return (
      <LoaderOrErrorComponent
        loading={loading || loadingAllGroups}
        error={error || errorAllGroups}
      />
    )

  return (
    <Layout>
      <SampleList alreadyOccupiedHeight={maxHeight}>
        <SamplePicker
          data={{
            users: [],
            groups: uniqBy([...myGroupsSimple, ...allGroups], "id"),
            locations: [],
          }}
          onChange={(sample: {
            users: UserBase[]
            groups: GroupSimple[]
            locations: LocationTree[]
          }) => {
            setSelectedGroups(sample.groups)
            setSelectedUsers(sample.users)
          }}
        />
      </SampleList>
      <DataLayout>
        <ChartLayout>
          <WorkingModeChart
            showDownloadCSV={false}
            users={selectedUsers}
            groups={selectedGroups}
            from={from}
            to={to}
            includeWeekends={enableWeekends}
            showTotal={canShowTotal}
          />
          <Toggle
            checked={canShowTotal}
            onChange={setShowTotal}
            label={"Total"}
            labelToTheLeft
            disabled={selectedGroups.length === 0 && selectedUsers.length === 0}
          />
        </ChartLayout>
      </DataLayout>
    </Layout>
  )
}
