import { ApolloError, gql, useQuery } from "@apollo/client"

const QUERY = gql`
  query googleAuthUrl {
    googleAuthUrl
  }
`

export default function useGoogleAuthUrl(): {
  loading: boolean
  error?: ApolloError
  url?: string
} {
  const { loading, error, data } = useQuery<{
    googleAuthUrl: string
  }>(QUERY)
  return {
    url: data?.googleAuthUrl,
    loading,
    error,
  }
}
