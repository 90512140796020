import useTranslate from "intl/useTranslate"
import React, { useState } from "react"

import { CSVFileUpload } from "./CSVImporter/CSVFileUpload"
import { CSVFirstLineChecker } from "./CSVImporter/CSVFirstLineChecker"
import { CSVRecap } from "./CSVImporter/CSVRecap"
import { CSVSelectEmailColumn } from "./CSVImporter/CSVSelectEmailColumn"
import { CSVSelectManagerColumn } from "./CSVImporter/CSVSelectManagerColumn"
import { CSVSeparator } from "./CSVImporter/CSVSeparator"
import CSVStep from "./CSVImporter/CSVStep"

enum STEP {
  SEPARATOR,
  UPLOAD_FILE,
  HAS_COLUMN_NAMES,
  EMAIL_COLUMN,
  MANAGER_COLUMN,
  RECAP,
}

interface PropsType {
  onClose: () => void
}

export default function AddEmployeesFromCSV({ onClose }: PropsType) {
  const t = useTranslate()
  const [currentStep, setCurrentStep] = useState<STEP>(STEP.SEPARATOR)
  const [error, setError] = useState("")
  const [csv, setCSV] = useState<string[][]>([])
  const [fileName, setFileName] = useState<string>("")
  const [separator, setSeparator] = useState(";")
  const [emailColumn, setEmailColumn] = useState<number | null>(null)
  const [managerColumn, setManagerColumn] = useState<number | null>(null)
  const [hasColumnNames, setHasColumnNames] = useState(false)

  const editSeparator = (separator: string) => setSeparator(separator)

  const handleFile = (files: File[] | undefined | null) => {
    if (files)
      files.map((file) => {
        if (file) {
          setFileName(file.name)
          setError("")
          const fileReader = new FileReader()
          fileReader.onloadend = () => {
            if (fileReader.result) {
              const lines =
                fileReader.result.toString().match(/[^\r\n]+/g) || []
              // validate CSV
              if (lines.length === 0) {
                setError(t("CSV File is empty"))
              } else {
                const columnCount = lines[0].split(separator).length
                let error = false
                for (let i = 0; i < lines.length; i++) {
                  const line = lines[i]
                  if (line.split(separator).length !== columnCount) {
                    setError(
                      t("CSV formatted error").replace("{count}", `${i + 1}`)
                    )
                    error = true
                    break
                  }
                }
                if (!error) {
                  // CSV IS Well formatted
                  setCSV(lines.map((l) => l.split(",").map((p) => p.trim())))
                }
              }
            } else {
              setError(t("CSV File is empty"))
            }
          }
          fileReader.onerror = () => {
            setError(t("Cannot read CSV File"))
          }
          fileReader.readAsText(file)
        }
      })
  }

  const makeDataFromCSV = () => {
    const data = hasColumnNames ? csv.slice(1) : csv
    return data.map((row) => {
      const email = emailColumn !== null ? row[emailColumn] : ""
      const manager = managerColumn !== null ? row[managerColumn] : ""
      return [email, manager]
    })
  }

  const steps = {
    [STEP.SEPARATOR]: {
      component: (
        <CSVSeparator
          fileName={fileName}
          separator={separator}
          editSeparator={editSeparator}
          error={error}
          onNext={() =>
            setCurrentStep(
              fileName !== "" ? STEP.HAS_COLUMN_NAMES : STEP.UPLOAD_FILE
            )
          }
          handleFile={handleFile}
        />
      ),
    },
    [STEP.UPLOAD_FILE]: {
      component: (
        <CSVFileUpload
          fileName={fileName}
          handleFile={handleFile}
          error={error}
          onPrevious={() => setCurrentStep(STEP.SEPARATOR)}
          onNext={() => setCurrentStep(STEP.HAS_COLUMN_NAMES)}
        />
      ),
    },
    [STEP.HAS_COLUMN_NAMES]: {
      title: t("first line checker title"),
      component: (
        <CSVFirstLineChecker
          csv={csv}
          hasColumnNames={hasColumnNames}
          setHasColumnNames={(csv) => {
            setHasColumnNames(csv)
            setCurrentStep(STEP.EMAIL_COLUMN)
          }}
          currentStep={currentStep}
          onPrevious={() => setCurrentStep(STEP.SEPARATOR)}
          onNext={() => setCurrentStep(STEP.EMAIL_COLUMN)}
        />
      ),
    },
    [STEP.EMAIL_COLUMN]: {
      component: (
        <CSVSelectEmailColumn
          csv={csv}
          onPrevious={() => setCurrentStep(STEP.HAS_COLUMN_NAMES)}
          onNext={() => setCurrentStep(STEP.MANAGER_COLUMN)}
          hasColumnNames={hasColumnNames}
          currentStep={currentStep}
          emailColumn={emailColumn}
          setEmailColumn={setEmailColumn}
        />
      ),
    },
    [STEP.MANAGER_COLUMN]: {
      component: (
        <CSVSelectManagerColumn
          csv={csv}
          onPrevious={() => setCurrentStep(STEP.EMAIL_COLUMN)}
          onNext={() => setCurrentStep(STEP.RECAP)}
          hasColumnNames={hasColumnNames}
          currentStep={currentStep}
          managerColumn={managerColumn}
          setManagerColumn={setManagerColumn}
        />
      ),
    },
    [STEP.RECAP]: {
      component: (
        <CSVRecap
          users={makeDataFromCSV()}
          onClose={onClose}
          onPrevious={() => setCurrentStep(STEP.MANAGER_COLUMN)}
        />
      ),
    },
  }

  const step = steps[currentStep]

  return <CSVStep>{step.component}</CSVStep>
}
