import ConcatenatedList from "components/ConcatenatedList/ConcatenatedList"
import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import useLocationsTree from "graphql/locations/useLocationsTree"
import useTranslate from "intl/useTranslate"
import React, { useCallback, useRef, useState } from "react"
import styled from "styled-components/macro"
import { ChipButton, colors, Label, P16, RemovableChipButton } from "ui"
import { Building } from "ui/icons"
import LocationTree, { getChildrenLocations } from "utils/LocationTree"

import LocationsPicker from "./LocationsPicker/LocationsPicker"

const FakeSearch = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
  cursor: pointer;

  p {
    color: ${colors.grey1};
  }

  svg {
    stroke: ${colors.grey2};
  }
`

const RemovableLocation = styled(RemovableChipButton)`
  white-space: nowrap;
`

const EditValue = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

interface PropsType {
  singleSelection?: boolean
  initialLocationIds?: string[]
  showArchivedLocations?: boolean
  placeholder?: string
  title?: string
  onChange: (locations: LocationTree[]) => void
}

export default function LocationsSelection({
  singleSelection,
  initialLocationIds,
  placeholder,
  title,
  showArchivedLocations = false,
  onChange,
}: PropsType) {
  const t = useTranslate()

  const [isResultDisplay, setIsResultDisplay] = useState(false)
  const { locations, loading, error } = useLocationsTree(!showArchivedLocations)
  const fakeSearch = useRef<HTMLDivElement>(null)

  const [searchString, setSearchString] = useState<string>("")
  const [selectedLocations, setSelectedLocations] = useState<LocationTree[]>([])
  const [filteredLocations, setFilteredLocations] = useState<LocationTree[]>([])
  const [hiddenItems, setHiddenItems] = useState<
    {
      id: string
      primary: React.ReactNode
      secondary: React.ReactNode
    }[]
  >([])

  const oldLocations = initialLocationIds
    ? initialLocationIds?.map((id) =>
        getChildrenLocations(locations, false).find(
          (location) => location.id === id
        )
      )
    : []

  const addOrRemoveLocation = (place: LocationTree) => {
    if (singleSelection) {
      const places =
        selectedLocations.find((l) => l.id === place.id) !== undefined
          ? []
          : [place]
      onChange(places)
      setSelectedLocations(places)
      setHiddenItems([])
      return
    }
    setSelectedLocations((oldSelectedLocations) => {
      if (
        selectedLocations.some((oldLocation) => oldLocation.id === place.id)
      ) {
        setHiddenItems((prev) => {
          if (!prev.find((item) => item.id === place.id)) return []
          return prev.filter((item) => item.id !== place.id)
        })
        const locations = oldSelectedLocations.filter(
          (oldLocation) => place.id !== oldLocation.id
        )
        onChange(locations)
        return locations
      }
      onChange([...oldSelectedLocations, place])
      return [...oldSelectedLocations, place]
    })
  }

  const removeLocation = (location: LocationTree) => {
    setSelectedLocations((oldSelectedLocations) => {
      const locations = oldSelectedLocations.filter(
        (oldLocation) => location.id !== oldLocation.id
      )
      onChange(locations)
      return locations
    })
    setHiddenItems((prev) => {
      if (!prev.find((item) => item.id === location.id)) return []
      return prev.filter((item) => item.id !== location.id)
    })
  }

  const getLocationsToDisplay = useCallback(() => {
    return filteredLocations.length > 0 ? filteredLocations : locations
  }, [filteredLocations, locations])

  const getDifference = (
    locations: LocationTree[],
    selectedLocations: LocationTree[]
  ) => {
    return locations.filter((location) => {
      return !selectedLocations.some((selectedLocation) => {
        return location.id === selectedLocation.id
      })
    })
  }

  const selectAllLocations = () => {
    getChildrenLocations(locations, false).map((location) => {
      if (
        !selectedLocations.some((oldLocation) => oldLocation.id === location.id)
      ) {
        const locations = [...selectedLocations, location]
        onChange(locations)
        return setSelectedLocations(locations)
      }
    })
  }

  const isEverythingIsSelected =
    getDifference(getLocationsToDisplay(), selectedLocations).length > 0
      ? false
      : true

  if (loading || error) {
    return <LoaderOrErrorComponent loading={loading} error={error} />
  }
  return (
    <div>
      <Label>{title}</Label>
      <div ref={fakeSearch} onClick={() => setIsResultDisplay(true)}>
        <ConcatenatedList
          title={title}
          items={
            selectedLocations.length === 0 && oldLocations.length === 0
              ? [
                  {
                    id: "search-icon",
                    primary: (
                      <FakeSearch>
                        <P16>{placeholder}</P16>
                        <Building />
                      </FakeSearch>
                    ),
                    secondary: <></>,
                  },
                ]
              : oldLocations.length > 0 && singleSelection
              ? [
                  {
                    id: "display-old-value",
                    primary: (
                      <EditValue>
                        {oldLocations.length > 0 &&
                        selectedLocations.length === 0 ? (
                          oldLocations.map((oldLocation) =>
                            oldLocation ? (
                              <ChipButton
                                key={oldLocation.id}
                                id={oldLocation.id}
                              >
                                {oldLocation.name}
                              </ChipButton>
                            ) : (
                              <></>
                            )
                          )
                        ) : (
                          <></>
                        )}
                        {selectedLocations.length > 0 ? (
                          selectedLocations.map((location) => (
                            <RemovableLocation
                              key={location.id}
                              onClick={() => removeLocation(location)}
                              onRemove={() => removeLocation(location)}
                            >
                              {location.name}
                            </RemovableLocation>
                          ))
                        ) : (
                          <></>
                        )}
                      </EditValue>
                    ),
                    secondary: <></>,
                  },
                ]
              : selectedLocations.map((l) => ({
                  id: l.id,
                  primary: (
                    <RemovableLocation
                      onClick={() => removeLocation(l)}
                      onRemove={() => removeLocation(l)}
                      id={l.id}
                    >
                      {l.name}
                    </RemovableLocation>
                  ),
                  secondary: (
                    <RemovableLocation
                      onClick={() => removeLocation(l)}
                      onRemove={() => removeLocation(l)}
                      id={l.id}
                    >
                      {l.name}
                    </RemovableLocation>
                  ),
                }))
          }
          hiddenItems={hiddenItems}
          dialogTitle={t("selected locations")}
          setHiddenItems={setHiddenItems}
        />
      </div>

      <LocationsPicker
        singleSelection={singleSelection}
        open={isResultDisplay}
        anchorEl={fakeSearch.current}
        locations={getLocationsToDisplay()}
        selectedLocations={selectedLocations}
        isEverythingSelected={isEverythingIsSelected}
        searchString={searchString}
        setSearchString={setSearchString}
        onLocationSearch={(results) => {
          setFilteredLocations(results)
        }}
        onLocationSelection={addOrRemoveLocation}
        onSelectEverything={() => {
          if (isEverythingIsSelected) {
            setSelectedLocations([])
            onChange([])
            setHiddenItems([])
          } else {
            selectAllLocations()
          }
        }}
        onClose={() => setIsResultDisplay(false)}
        showArchivedLocations={showArchivedLocations}
      />
    </div>
  )
}
