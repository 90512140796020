import { ApolloError, gql, useMutation } from "@apollo/client"
import { useState } from "react"

import { CompanySettingInlineFragment } from "./fragments/settingsFragments"

const SET_COMPANY_SETTINGS_MUTATION = gql`
  mutation setCompanySetting($key: String!, $value: JSON!) {
    setCompanySetting(key: $key, value: $value) {
      ${CompanySettingInlineFragment}
    }
  }
`

const SET_USER_SETTINGS_MUTATION = gql`
  mutation setUserSetting($key: String!, $value: JSON!) {
    setUserSetting(key: $key, value: $value) {
      ${CompanySettingInlineFragment}
    }
  }
`

export default function useSetSetting(type: "company" | "user") {
  const [mutate] = useMutation(
    type === "company"
      ? SET_COMPANY_SETTINGS_MUTATION
      : SET_USER_SETTINGS_MUTATION,
    {
      update(cache, { data }) {
        if (type === "company") {
          cache.modify({
            fields: {
              companySettings() {
                return data.setCompanySetting
              },
            },
          })
        } else {
          cache.modify({
            fields: {
              userSettings() {
                return data.setUserSetting
              },
            },
          })
        }
      },
      refetchQueries: () => {
        return ["getUser"]
      },
    }
  )
  const [error, setError] = useState<ApolloError | null>(null)
  const [loading, setLoading] = useState(false)
  const setSetting = async (
    key: string,
    value: boolean | { enabled: boolean; value: number | string }
  ) => {
    setLoading(true)
    try {
      await mutate({ variables: { key, value } })
    } catch (e: any) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }
  return {
    loading,
    error,
    setSetting,
  }
}
