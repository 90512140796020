import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import React from "react"
import { colors } from "ui"
import { NavArrowLeft, NavArrowRight } from "ui/icons"

const style: React.CSSProperties = {
  stroke: colors.purple,
  width: "24px",
  height: "24px",
  cursor: "pointer",
}

export function PreviousWeekButton() {
  const { setPreviousWeek } = usePlanningContext()

  return <NavArrowLeft style={style} onClick={setPreviousWeek} />
}

export function NextWeekButton() {
  const { setNextWeek } = usePlanningContext()

  return <NavArrowRight style={style} onClick={setNextWeek} />
}
