import useMediaQueries from "hooks/useMediaQueries"
import React, { useState } from "react"
import styled from "styled-components"
import { mediaQueries, P14, P16, Tooltip, transitions } from "ui"
import colors from "ui/colors"
import getColorFromStatus from "utils/getColorFromStatus"
import LocationTree from "utils/LocationTree"

const EllispisButtonContainer = styled.div`
  width: fit-content;
`

const TooltipStyled = styled(Tooltip)`
  &:hover {
    background: none;
  }
`

interface ButtonStylesPropsType {
  active?: boolean
}

const ButtonStyled = styled.button<ButtonStylesPropsType>`
  display: grid;
  grid-template-columns: auto auto 1fr 1fr;
  align-items: center;
  border-radius: 50px;
  padding: 5px 16px;

  @media ${mediaQueries.isMobile} {
    padding: 2px 16px;
  }

  background: ${colors.grey4};
  border: solid 1px ${colors.grey3};
  cursor: pointer;

  &:hover {
    border-color: ${getColorFromStatus("office")};
    background: ${getColorFromStatus("office")}14;
  }

  ${({ active }) => {
    if (active)
      return `
      border-color: ${getColorFromStatus("office")};
      background: ${getColorFromStatus("office")}14;
  `
  }}

  &:first-child {
    color: ${colors.grey1};
  }

  &:disabled {
    cursor: auto;
    background: ${colors.grey4};
    border: solid 1px ${colors.grey3};
    color: ${colors.grey2};

    p {
      color: inherit;
    }
  }

  span,
  p {
    margin: 0;
    padding: 0;
    display: inline;
  }

  .breadcrumbs {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${colors.grey2};
    font-size: 16px;
    line-height: 20px;
  }

  span.crumb {
    white-space: nowrap;
  }

  .crumb:after {
    content: "/";
    margin: 4px;
  }

  .content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ${transitions.toggleActiveLocation}
`

interface PropsType {
  location: LocationTree
  active: boolean
  showBreadCrumbs?: boolean
  onClick: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

export function ActiveLocationChipButtonWithBreadCrumbs({
  location,
  active,
  showBreadCrumbs,
  ...rest
}: PropsType) {
  const { isMobile } = useMediaQueries()

  const isNestedInZones = location.breadCrumbs.length > 0
  const [openTooltip, setOpenTooltip] = useState<boolean>(false)

  const Paragraph = isMobile ? (
    <P14 className="content">{location.name}</P14>
  ) : (
    <P16 className="content">{location.name}</P16>
  )

  const name = `${location.breadCrumbs.join("/")}/...`

  if (isNestedInZones)
    return (
      <EllispisButtonContainer>
        <TooltipStyled
          title={name}
          open={openTooltip}
          onClose={() => setOpenTooltip(false)}
        >
          <ButtonStyled
            {...rest}
            active={active}
            onMouseEnter={() => {
              setOpenTooltip(true)
              if (rest.onMouseEnter) rest.onMouseEnter()
            }}
          >
            {".../ "} {Paragraph}
          </ButtonStyled>
        </TooltipStyled>
      </EllispisButtonContainer>
    )
  return (
    <ButtonStyled {...rest} active={active}>
      <span className="breadcrumbs">
        {showBreadCrumbs &&
          location.breadCrumbs.map((crumb, n) => (
            <span key={`loc-${location.id}-crumb-${n}`} className="crumb">
              {crumb}
            </span>
          ))}
      </span>
      {Paragraph}
    </ButtonStyled>
  )
}
