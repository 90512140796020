import useTranslate from "intl/useTranslate"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Transition } from "react-transition-group"
import { Button, Grid } from "ui"

import BigButton from "./components/BigButton"
import DialogUsersCreation from "./components/DialogUsersCreation"
import InviteByEmails from "./components/InviteByEmails"
import {
  Content,
  Footer,
  Header,
  RightContainer,
  Title,
} from "./components/Layout"
import NavBack from "./components/NavBack"
import StepIndicator from "./components/StepIndicator"

const duration = 600

const defaultStyleSmallPopUp = {
  transition: `right ${duration}ms ease-in-out 300ms`,
  position: "absolute",
  top: "40%",
}

const defaultStyleSmallPopUp2 = {
  transition: `right ${duration}ms ease-in-out 400ms`,
  position: "absolute",
  top: "45%",
}

const transitionStylesSmallPopUp: any = {
  entering: { right: "25%" },
  entered: { right: "25%" },
  exiting: { right: -300 },
  exited: { right: -300 },
}

const transitionStylesSmallPopUp2: any = {
  entering: { right: "20%" },
  entered: { right: "20%" },
  exiting: { right: -300 },
  exited: { right: -300 },
}

interface PropsType {
  userEmails: string[]
  setUserEmails: React.Dispatch<React.SetStateAction<string[]>>
}

export default function OnboardingUsers({
  userEmails,
  setUserEmails,
}: PropsType) {
  const history = useHistory()
  const t = useTranslate()

  const [displayReelUsers, setDisplayReelUsers] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const [searchString, setSearchString] = useState<string>("")

  const removeEmail = (email: string) => {
    setUserEmails((oldEmails: string[]) =>
      oldEmails.filter((oldEmail) => oldEmail !== email)
    )
  }

  const [inProp, setInProp] = useState(false)

  useEffect(() => {
    setTimeout(() => setInProp(true), 1)
  }, [])

  return (
    <Grid container direction="row">
      <Grid
        item
        md={8}
        xl={9}
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <Header>
          <NavBack
            realGoBack={!displayReelUsers}
            onClick={() => setDisplayReelUsers(false)}
          />
        </Header>
        <Content>
          <Title>{t("invite your colleagues")}</Title>
          {displayReelUsers ? (
            <InviteByEmails
              placeholder="nom@offishall.io"
              searchString={searchString}
              emails={userEmails}
              onChange={(input) => setSearchString(input)}
              resetForm={() => setSearchString("")}
              onValidation={(email: string) =>
                setUserEmails((oldEmails: string[]) =>
                  oldEmails.includes(email)
                    ? [...oldEmails]
                    : [...oldEmails, email]
                )
              }
              onRemove={removeEmail}
            />
          ) : (
            <>
              <BigButton
                title={t("add your colleagues")}
                subtitle={t(
                  "add their email addresses and receive invitation to join Offishall"
                )}
                onClick={() => setDisplayReelUsers(true)}
              />
              <BigButton
                title={t("add fictitious users")}
                subtitle={t(
                  "fake users creation to test in the best conditions"
                )}
                onClick={() => {
                  setIsModalOpen(true)
                  setUserEmails([])
                }}
              />
              <DialogUsersCreation
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSuccess={() => {
                  setIsModalOpen(false)
                  history.push({
                    pathname: "/locations",
                    state: userEmails,
                  })
                }}
              />
            </>
          )}
        </Content>
        <Footer>
          <StepIndicator />
          {displayReelUsers && (
            <Button
              disabled={userEmails.length === 0}
              onClick={() =>
                history.push({
                  pathname: "/locations",
                  state: userEmails,
                })
              }
            >
              {t("continue")}
            </Button>
          )}
        </Footer>
      </Grid>
      <RightContainer
        item
        md={4}
        xl={3}
        style={{
          backgroundImage: `url(/static/img/onboarding/onboarding_step2.svg)`,
        }}
      >
        <Transition in={inProp} timeout={duration}>
          {(state) => (
            <div
              style={{
                ...defaultStyleSmallPopUp,
                ...transitionStylesSmallPopUp[state],
              }}
            >
              <img
                alt="Fictitious users creation"
                src="/static/img/onboarding/pop_up_2_step2.svg"
              />
            </div>
          )}
        </Transition>
        <Transition in={inProp} timeout={duration}>
          {(state) => (
            <div
              style={{
                ...defaultStyleSmallPopUp2,
                ...transitionStylesSmallPopUp2[state],
              }}
            >
              <img
                alt="Fictitious users creation"
                src="/static/img/onboarding/pop_up_2_step2.svg"
              />
            </div>
          )}
        </Transition>
      </RightContainer>
    </Grid>
  )
}
