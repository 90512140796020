import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function ArrowRightFill({
  stroke,
  strokeWidth,
  fill,
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      strokeWidth={strokeWidth ?? 1.5}
      stroke={stroke ? stroke : "currentColor"}
      fill={fill ? fill : "currentColor"}
      {...rest}
    >
      <path d="M15.6364 11.3013C16.1212 11.6118 16.1212 12.3882 15.6364 12.6987L9.09091 16.8907C8.60606 17.2012 8 16.8131 8 16.192L8 7.80796C8 7.18692 8.60606 6.79877 9.09091 7.10929L15.6364 11.3013Z" />
    </SvgIcon>
  )
}
