import styled from "styled-components"
import { Checkbox } from "ui"

export const CustomCheckbox = styled(Checkbox)`
  padding: 0 11px;
`

export const List = styled.ul`
  display: flex;
  flex-direction: column;
`

export const Item = styled.li`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }
`
