import { Fieldset } from "components/Layout/Form/Fieldset/Fieldset"
import UsersGroupsSelection from "components/UsersGroupsSelection/UsersGroupsSelection"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { Group, UserBase } from "types"
import { Button, colors, Dialog } from "ui"
import { Edit, Plus } from "ui/icons"

const FieldsetStyled = styled(Fieldset)`
  p {
    font-weight: 400;
    margin-left: auto;
    color: ${colors.purple};
  }
`

const FullWidthButton = styled(Button)`
  width: 100%;
  gap: 8px;

  svg {
    width: 20px;
    height: 20px;
  }
`

interface PropsType {
  initialValue: string[]
  groupIds: string[]
  onChange: (iniput: { users: UserBase[]; groups: Group[] }) => void
}

export default function LocationReservedToPicker({
  initialValue,
  groupIds,
  onChange,
}: PropsType) {
  const t = useTranslate()
  const [dialogOpen, setDialogOpen] = useState(false)

  return (
    <>
      <FieldsetStyled>
        <FullWidthButton secondary onClick={() => setDialogOpen(true)}>
          {initialValue.length === 0 ? <Plus /> : <Edit />}
          {t("Allocated space update")}
        </FullWidthButton>
      </FieldsetStyled>
      <Dialog
        open={dialogOpen}
        title={t("Allocated space update")}
        onClose={() => {
          setDialogOpen(false)
        }}
        stopEventPropagation
      >
        <UsersGroupsSelection
          initialUserIds={initialValue}
          userIds={[]}
          groupIds={groupIds}
          hideDefaults
          onChange={onChange}
          onSave={() => setDialogOpen(false)}
        />
      </Dialog>
    </>
  )
}
