import useSetSetting from "graphql/settings/useSetSetting"
import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import React, { useCallback, useState } from "react"
import styled from "styled-components"
import { CompanySetting } from "types"
import { colors, P14, P16, Toggle } from "ui"

import ManageCompanySettingsEnum from "./ManageSettingsEnum"
import ManageCompanySettingsNumber from "./ManageSettingsNumber"
import ManageCompanySettingsString from "./ManageSettingsString"

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: solid 1px ${colors.grey3};
  width: 100%;

  &:last-child {
    border: none;
    padding-bottom: 0;
  }
`

const Label = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  .description {
    color: ${colors.grey1};
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`

interface PropsType {
  type: "company" | "user"
  setting: CompanySetting
}

function getValueFromSetting(setting: CompanySetting) {
  switch (setting.type) {
    case "number":
      return setting.valueAsNumber
    case "string":
      return setting.valueAsString
    case "enum":
      return setting.valueAsEnum
    default:
      return undefined
  }
}

function formatValueAsJson(
  setting: CompanySetting,
  enabled: boolean,
  value: any
) {
  if (setting.type === "simple") {
    return enabled
  }
  return { enabled, value }
}

export default function ManageSettingsSlider({ setting, type }: PropsType) {
  const t = useTranslate()
  const { setSetting } = useSetSetting(type)
  const { user } = useUser()
  const company = user?.company.name || ""
  const [value, setValue] = useState(getValueFromSetting(setting))
  const name = t(`${setting.key}-name`, { company })
  const description = t(`${setting.key}-description`, { company })

  const onToggleEnabled = useCallback(
    (checked) => {
      setSetting(setting.key, formatValueAsJson(setting, checked, value))
    },
    [setSetting, setting, value]
  )

  const onChangeValue = useCallback(
    (newValue: any) => {
      setValue(newValue)
      setSetting(
        setting.key,
        formatValueAsJson(setting, setting.enabled, newValue)
      )
    },
    [setSetting, setting]
  )

  return (
    <>
      <Container>
        <Label>
          <P16>{name}</P16>
          <P14 className="description">{description}</P14>
        </Label>
        <Content>
          {setting.type === "number" && (
            <ManageCompanySettingsNumber
              setting={setting}
              onChange={onChangeValue}
            />
          )}
          {setting.type === "string" && (
            <ManageCompanySettingsString
              setting={setting}
              onChange={onChangeValue}
            />
          )}
          {setting.type === "enum" && (
            <ManageCompanySettingsEnum
              setting={setting}
              onChange={onChangeValue}
            />
          )}

          <Toggle checked={setting.enabled} onChange={onToggleEnabled} />
        </Content>
      </Container>
    </>
  )
}
