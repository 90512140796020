import { gql, useMutation, useQuery } from "@apollo/client"
import { Label } from "types"

const ADD_COMPANY_LABEL_MUTATION = gql`
  mutation addCompanyLabel($type: String!, $label: String!) {
    addCompanyLabel(type: $type, label: $label) {
      type
      label
    }
  }
`

const REMOVE_COMPANY_LABEL_MUTATION = gql`
  mutation removeCompanyLabel($type: String!, $label: String!) {
    removeCompanyLabel(type: $type, label: $label) {
      type
      label
    }
  }
`

const GET_COMPANY_LABELS_QUERY = gql`
  query companyLabels {
    companyLabels {
      type
      label
    }
  }
`

export default function useCompanyLabels() {
  const [mutateAddLabel] = useMutation(ADD_COMPANY_LABEL_MUTATION, {
    update(cache, { data: { addCompanyLabel } }) {
      cache.modify({
        fields: {
          companyLabels() {
            return addCompanyLabel
          },
        },
      })
    },
  })
  const addCompanyLabel = async (type: string, label: string) => {
    try {
      await mutateAddLabel({ variables: { type, label } })
    } catch (e) {
      console.error(e.message)
    }
  }

  const [mutateRemoveLabel] = useMutation(REMOVE_COMPANY_LABEL_MUTATION, {
    update(cache, { data: { removeCompanyLabel } }) {
      cache.modify({
        fields: {
          companyLabels() {
            return removeCompanyLabel
          },
        },
      })
    },
  })
  const removeCompanyLabel = async (type: string, label: string) => {
    try {
      await mutateRemoveLabel({ variables: { type, label } })
    } catch (e) {
      console.error(e.message)
    }
  }

  const { loading, error, data } = useQuery(GET_COMPANY_LABELS_QUERY)

  return {
    addCompanyLabel,
    removeCompanyLabel,
    labels: (data?.companyLabels || []) as Label[],
    loading,
    error,
  }
}
