import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import useEquipments from "graphql/equipments/useEquipments"
import useEquipmentsAvailability from "graphql/equipments/useEquipmentsAvailability"
import useUserFavorites from "graphql/favorites/useUserFavorites"
import useLocationsTreeForUser from "graphql/locations/useLocationsTreeForUser"
import SlotsEditorLocation from "pages/PlanningPage/components/SlotsEditor/SlotsEditorContent/SlotsEditorLocation"
import React from "react"
import styled from "styled-components"
import { SlotOccupationForLocation, User, WorkingMode } from "types"
import { mediaQueries } from "ui"
import LocationTree from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

import SlotsEditorCategoryItem from "./SlotsEditorCategoryItem"

const Layout = styled.ul`
  padding: 16px 8px;

  display: flex;
  flex-direction: column;
  gap: 8px;

  @media ${mediaQueries.isMobile} {
    padding: 16px 8px;
  }
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
interface PropsType {
  from: UTCDate
  to: UTCDate
  selectedUser: User
  occupations: SlotOccupationForLocation[]
  externalLocations?: LocationTree[]
  hidePrivateLocationsEvenIfUserIsAuthorized?: boolean
  floorPlan?: {
    selectedFloorPlan?: LocationTree
    selectedArea?: LocationTree
    updateSelectedFloorPlan: (location: LocationTree) => void
    updateSelectedVisualArea: (visualArea: LocationTree) => void
  }
  saveWorkingMode: (workingMode: WorkingMode) => void | Promise<void>
  selectedLocationId?: string
  disableEquipments?: boolean
  guestIds: string[]
  isTypicalWeek?: boolean
}

export default function SlotsEditorLocationsList({
  from,
  to,
  selectedUser,
  occupations,
  floorPlan,
  saveWorkingMode,
  selectedLocationId,
  externalLocations,
  disableEquipments = false,
  hidePrivateLocationsEvenIfUserIsAuthorized = false,
  isTypicalWeek = false,
  guestIds,
}: PropsType) {
  const {
    error,
    loading,
    locations: localLocations,
  } = useLocationsTreeForUser(
    selectedUser,
    hidePrivateLocationsEvenIfUserIsAuthorized
  )
  const locations = externalLocations ?? localLocations

  const { equipments: eq } = useEquipments()
  const equipments = disableEquipments ? [] : eq

  const { favorites } = useUserFavorites()

  const favoriteLocations = favorites.locations
    .map((favorite) => LocationTree.getLocationNode(locations, favorite.itemId))
    .filter((l) => l !== undefined) as LocationTree[]

  const notFavoriteLocations = locations
    .filter(
      (location) =>
        !favoriteLocations.find((favorite) => favorite.id === location.id)
    )
    .filter((l) => l !== undefined) as LocationTree[]

  const { equipmentsAvailability } = useEquipmentsAvailability({ from, to })

  if (error || loading)
    return <LoaderOrErrorComponent loading={loading} error={error} />

  return (
    <Layout>
      {favoriteLocations.filter((f) => !f.isCategory).length > 0 && (
        <li>
          <List>
            {favoriteLocations
              .filter((f) => !f.isCategory)
              .map((location) => (
                <li key={`fav-loc-${location.id}`}>
                  <SlotsEditorLocation
                    isSelected={selectedLocationId === location.id}
                    location={location}
                    companyLocations={locations}
                    occupations={occupations}
                    equipments={equipments}
                    equipmentsAvailability={equipmentsAvailability}
                    floorPlan={floorPlan}
                    showBreadCrumbs
                    showFavoriteCheckbox
                    guestIds={guestIds}
                    saveWorkingMode={saveWorkingMode}
                    isTypicalWeek={isTypicalWeek}
                  />
                </li>
              ))}
          </List>
        </li>
      )}

      {favoriteLocations.filter((f) => f.isCategory).length > 0 && (
        <li>
          <ul>
            {favoriteLocations
              .filter((f) => f.isCategory)
              .map((category) => (
                <li key={`cat-${category.id}`}>
                  <SlotsEditorCategoryItem
                    category={category}
                    companyLocations={locations}
                    occupations={occupations}
                    floorPlan={floorPlan}
                    equipments={equipments}
                    equipmentsAvailability={equipmentsAvailability}
                    saveWorkingMode={saveWorkingMode}
                    guestIds={guestIds}
                    showFavoriteCheckbox
                  />
                </li>
              ))}
          </ul>
        </li>
      )}
      {notFavoriteLocations.filter((l) => !l.isCategory).length > 0 && (
        <li>
          <List>
            {notFavoriteLocations
              .filter((l) => !l.isCategory)
              .map((location) => (
                <li key={`root-loc-${location.id}`}>
                  <SlotsEditorLocation
                    location={location}
                    isSelected={selectedLocationId === location.id}
                    companyLocations={locations}
                    occupations={occupations}
                    equipments={equipments}
                    equipmentsAvailability={equipmentsAvailability}
                    floorPlan={floorPlan}
                    showBreadCrumbs
                    showFavoriteCheckbox
                    guestIds={guestIds}
                    saveWorkingMode={saveWorkingMode}
                    isTypicalWeek={isTypicalWeek}
                  />
                </li>
              ))}
          </List>
        </li>
      )}

      {notFavoriteLocations.filter((c) => c.isCategory).length > 0 && (
        <li>
          <ul>
            {notFavoriteLocations
              .filter((c) => c.isCategory)
              .map((category) => (
                <li key={`cat-${category.id}`}>
                  <SlotsEditorCategoryItem
                    category={category}
                    companyLocations={locations}
                    occupations={occupations}
                    floorPlan={floorPlan}
                    equipments={equipments}
                    equipmentsAvailability={equipmentsAvailability}
                    saveWorkingMode={saveWorkingMode}
                    showFavoriteCheckbox
                    guestIds={guestIds}
                    selectedLocationId={selectedLocationId}
                  />
                </li>
              ))}
          </ul>
        </li>
      )}
    </Layout>
  )
}
