import useCompanyLabels from "graphql/labels/useCompanyLabels"
import useUserLabels from "graphql/labels/useUserLabels"
import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import NewCustomLabelButton from "pages/PlanningPage/components/SlotsEditor/SlotsEditorContent/WorkingModeButtons/NewCustomLabelButton/NewCustomLabelButton"
import {
  WorkingModeButtonWithLabel,
  WorkingModeButtonWithUserLabel,
} from "pages/PlanningPage/components/SlotsEditor/SlotsEditorContent/WorkingModeButtons/WorkingModeButton/WorkingModeButton"
import React from "react"
import styled from "styled-components"
import { Label, User, WorkingMode } from "types"
import { mediaQueries } from "ui"
import { hideScrollBarsCSS } from "ui/utilities"
import UTCDate from "utils/UTCDate"

import StatusTitle from "./StatusTitle/StatusTitle"

const WorkingModeContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 10px 16px;
  gap: 10px;
  ${hideScrollBarsCSS}

  @media ${mediaQueries.isMobile} {
    padding: 10px 8px 0 8px;
  }
`

interface PropsType {
  genericSlotValue: "home" | "else" | "off" | "ooc"
  i8nKey: string
  label: string
  selectedUser: User
  from: UTCDate
  to: UTCDate
  saveWorkingMode: (workingMode: WorkingMode) => void | Promise<void>
}

export default function WorkingModeButton({
  genericSlotValue,
  label,
  selectedUser,
  from,
  to,
  saveWorkingMode,
}: PropsType) {
  const t = useTranslate()
  const { labels } = useCompanyLabels()
  const { userLabels: meLabels, removeUserLabel } = useUserLabels()

  const { user: me } = useUser()
  const selectedUserIsMe = selectedUser.id === me?.id

  const getLabels = () => {
    if (!selectedUserIsMe)
      return (selectedUser?.flags.labels as Label[] | undefined) ?? []

    if (selectedUserIsMe && meLabels) return meLabels
    return []
  }

  if (!me) return <></>

  return (
    <>
      <StatusTitle status={genericSlotValue} from={from} to={to} />
      <WorkingModeContainer>
        <WorkingModeButtonWithLabel
          value={genericSlotValue}
          label={label}
          onClick={() => {
            saveWorkingMode({
              equipments: [],
              locationId: null,
              label: label,
              genericSlotValue,
            })
          }}
        />
        {/* FOR "OFF" mode, we also display "OOC" mode*/}
        {genericSlotValue === "off" && (
          <WorkingModeButtonWithLabel
            value="ooc"
            label={t("Out Of Contract")}
            onClick={() => {
              saveWorkingMode({
                equipments: [],
                locationId: null,
                label: null,
                genericSlotValue: "ooc",
              })
            }}
          />
        )}
        {/* COMPANY LABELS */}
        {labels
          .filter((l) => l.type === genericSlotValue)
          .map((l, n) => (
            <WorkingModeButtonWithLabel
              key={`label-company-${genericSlotValue}-${n}`}
              value={genericSlotValue}
              label={l.label}
              onClick={() => {
                saveWorkingMode({
                  equipments: [],
                  locationId: null,
                  label: l.label,
                  genericSlotValue,
                })
              }}
            />
          ))}
        {/* FOR "OFF" mode, we also display "OOC" mode*/}
        {genericSlotValue === "off" &&
          labels
            .filter((l) => l.type === "ooc")
            .map((l, n) => (
              <WorkingModeButtonWithLabel
                key={`label-company-ooc-${n}`}
                value="ooc"
                label={l.label}
                onClick={() =>
                  saveWorkingMode({
                    equipments: [],
                    locationId: null,
                    label: l.label,
                    genericSlotValue: "ooc",
                  })
                }
              />
            ))}
        {/* USER LABELS */}
        {getLabels()
          .filter((l) => l.type === genericSlotValue)
          .map((l, n) => (
            <WorkingModeButtonWithUserLabel
              key={`label-user-${genericSlotValue}-${n}`}
              value={genericSlotValue}
              label={l.label}
              onClick={() =>
                saveWorkingMode({
                  equipments: [],
                  locationId: null,
                  label: l.label,
                  genericSlotValue,
                })
              }
              onRemove={() => removeUserLabel(l.type, l.label)}
            />
          ))}
        {/* FOR "OFF" mode, we also display "OOC" mode*/}
        {genericSlotValue === "off" &&
          getLabels()
            .filter((l) => l.type === "ooc")
            .map((l, n) => (
              <WorkingModeButtonWithUserLabel
                key={`label-user-ooc-${n}`}
                value="ooc"
                label={l.label}
                onClick={() =>
                  saveWorkingMode({
                    equipments: [],
                    locationId: null,
                    label: l.label,
                    genericSlotValue: "ooc",
                  })
                }
                onRemove={() => removeUserLabel(l.type, l.label)}
              />
            ))}
        {selectedUserIsMe && (
          <NewCustomLabelButton type={genericSlotValue} target="user" />
        )}
      </WorkingModeContainer>
    </>
  )
}
