import { ApolloCache } from "@apollo/client"

interface PropsType {
  cache: ApolloCache<any>
  data: any
  mutationName: string
  remove?: boolean
}
export default function updateCache({
  cache,
  data,
  mutationName,
  remove = false,
}: PropsType) {
  if (data && data[mutationName]) {
    const hybridRule = data[mutationName]
    const fields = {
      companyHybridRules: (
        existing = {
          default: undefined,
          users: [],
          groups: [],
          managers: [],
        }
      ) => {
        if (
          mutationName === "deleteDefaultHybridRule" ||
          mutationName === "upsertDefaultHybridRule"
        ) {
          if (remove) {
            return { ...existing, default: undefined }
          }

          return {
            ...existing,
            default: {
              ...hybridRule,
              __ref: `DefaultHybridRule:${hybridRule.id}`,
            },
          }
        }

        if (
          mutationName === "deleteUserHybridRule" ||
          mutationName === "upsertUserHybridRule"
        ) {
          const isRuleCahchedAsManagerRule =
            existing.managers.find(
              ({ __ref }) => __ref === `ManagerHybridRule:${hybridRule.id}`
            ) !== undefined
          const users: any[] = existing.users.filter(
            (e: any) =>
              e.__ref !== `${hybridRule.__typename}:${hybridRule.id}` &&
              e.id !== hybridRule.id
          )
          const managers: any[] = existing.managers.filter(
            (e: any) =>
              e.__ref !== `ManagerHybridRule:${hybridRule.id}` &&
              e.id !== hybridRule.id
          )
          if (remove) {
            return { ...existing, users, managers }
          }

          if (!isRuleCahchedAsManagerRule) {
            return {
              ...existing,
              users: [...users, hybridRule],
            }
          }

          return {
            ...existing,
            managers: [...managers, hybridRule],
          }
        }

        if (
          mutationName === "deleteGroupHybridRule" ||
          mutationName === "upsertGroupHybridRule"
        ) {
          const groups: any[] = existing.groups.filter(
            (e: any) =>
              e.__ref !== `${hybridRule.__typename}:${hybridRule.id}` &&
              e.id !== hybridRule.id
          )
          if (remove) {
            return { ...existing, groups }
          }

          return { ...existing, groups: [...groups, hybridRule] }
        }
      },
    }
    cache.modify({ fields })
  }
}
