import SearchResultsContainer from "components/Search/ResultsList/SearchResultsContainer/SearchResultsContainer"
import SearchBarForm from "components/Search/SearchBarForm/SearchBarForm"
import useLocationsTree from "graphql/locations/useLocationsTree"
import useTranslate from "intl/useTranslate"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { P14 } from "ui"
import { Search } from "ui/icons"
import isIphone from "utils/isIphone"
import LocationTree, { getChildrenLocations } from "utils/LocationTree"

import UpdateCategoryDialog from "../AddCategoryButton/UpdateCategoryDialog/UpdateCategoryDialog"
import UpdateLocationDialog from "../AddLocationButton/UpdateLocationDialog/UpdateLocationDialog"
import CreateEntry from "./CreateEntry/CreateEntry"

const List = styled.ul`
  cursor: default;
`

const Item = styled.li`
  display: flex;
  align-items: center;
  padding: 8px 0;
  gap: 4px;

  > :first-child {
    padding: 0 0 0 16px;
  }
`

interface PropsType {
  showArchivedLocations?: boolean
  onSubmit: (locations: LocationTree[]) => void
  onNewResults: (locations: LocationTree[]) => void
  onClose: () => void
}

export default function AdminLocationsSearch({
  showArchivedLocations,
  onSubmit,
  onNewResults,
  onClose,
}: PropsType) {
  const t = useTranslate()

  const { locations } = useLocationsTree(!showArchivedLocations)

  const [searchString, setSearchString] = useState("")
  const [newResults, setNewResults] = useState<LocationTree[]>([])
  const [action, setAction] = useState<{
    state: undefined | "ADD_LOCATION" | "ADD_CATEGORY"
    inputLocation?: { name: string; parentId: null }
  }>({ state: undefined, inputLocation: undefined })

  const searchInputRef = useRef<HTMLFormElement>(null)

  const resetForm = useRef(() => {
    setSearchString("")
    setNewResults([])
    onNewResults([])
  })

  useEffect(() => {
    // user has reach "" with backspace but had results
    if (searchString === "" && newResults.length > 0) {
      return resetForm.current()
    }

    // prevent infinite loop
    if (searchString === "") return

    const filteredLocations = getChildrenLocations(locations, false).filter(
      (l) => l.name.toLowerCase().includes(searchString.toLowerCase())
    )

    if (filteredLocations.length === newResults.length) {
      for (const result of filteredLocations) {
        if (!newResults.find((nr) => nr.id === result.id)) {
          setNewResults(filteredLocations)
          onNewResults(filteredLocations)
          return
        }
      }
    } else {
      setNewResults(filteredLocations)
      onNewResults(filteredLocations)
    }
  }, [locations, newResults, searchString, onNewResults, resetForm])

  return (
    <>
      <SearchBarForm
        ref={searchInputRef}
        searchString={searchString}
        handleChange={(str) => {
          if (searchString !== "" && str === "") {
            onClose()
          }
          setSearchString(str)
        }}
        resetForm={resetForm.current}
        icon={isIphone ? undefined : <Search />}
        placeholder={t("search for zone or space")}
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit(newResults)
          resetForm.current()
        }}
        autoFocus
      />
      {newResults.length === 0 && (
        <SearchResultsContainer
          anchorEl={searchInputRef.current}
          searchString={searchString}
          results={newResults.map((r) => ({
            type: "location",
            email: "",
            id: r.id,
            name: r.name,
          }))}
          handleReset={resetForm.current}
        >
          <List>
            <Item>
              <P14 color="grey1">{t("no match")}</P14>
            </Item>

            {newResults.length === 0 && action.state === undefined && (
              <Item>
                <CreateEntry
                  updateAction={(action) => {
                    setAction({
                      state: action,
                      inputLocation: { name: searchString, parentId: null },
                    })
                    setSearchString("")
                  }}
                />
              </Item>
            )}
          </List>
        </SearchResultsContainer>
      )}
      {action.state === "ADD_LOCATION" && (
        <UpdateLocationDialog
          open
          initialInputLocation={action.inputLocation}
          onClose={() =>
            setAction({ state: undefined, inputLocation: undefined })
          }
        />
      )}
      {action.state === "ADD_CATEGORY" && (
        <UpdateCategoryDialog
          open
          initialInputCategory={action.inputLocation}
          onClose={() =>
            setAction({ state: undefined, inputLocation: undefined })
          }
        />
      )}
    </>
  )
}
