import { ApolloError, gql, useQuery } from "@apollo/client"
import { UserHistory } from "types"

import { UserHistoryFragment } from "./fragments/userHistoryFragments"

export const QUERY = gql`
  ${UserHistoryFragment}
  query userHistory($type: String!, $limit: Int) {
    userHistory(type: $type, limit: $limit) {
      ...UserHistoryFragment
    }
  }
`

interface PropsType {
  type: string
  limit?: number
}

export default function useUserHistory({ type, limit }: PropsType): {
  loading: boolean
  error?: ApolloError
  userHistory: UserHistory[]
} {
  const { loading, error, data } = useQuery<{
    userHistory: UserHistory[]
  }>(QUERY, {
    variables: {
      type,
      limit,
    },
    fetchPolicy: "cache-and-network",
  })
  return {
    userHistory: data?.userHistory || [],
    loading,
    error,
  }
}
