import { gql, useMutation } from "@apollo/client"
import useCompanyFlags from "hooks/useCompanyFlags"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components/macro"
import { Alert, Grid, P16Bold, Toggle } from "ui"

import ConfigureCustomDomain from "./ConfigureCustomDomain"
import ConfigureSAML from "./ConfigureSAML"
import ConfigureSCIM from "./ConfigureSCIM"

const ProvisioningContainer = styled.div`
  padding-top: 0px;
  padding-bottom: 0px;
  overflow-x: hidden;
  overflow-y: scroll;

  .toggle-container {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .text {
    margin-top: 20px;
  }
`

interface ProviderSettings {
  shortName: string
  longName: string
  sso?: string
}

export const providerSettings: Record<string, ProviderSettings> = {
  OKTA: {
    shortName: "Okta",
    longName: "Okta",
    sso: "SAML",
  },
  AAD: {
    shortName: "AAD",
    longName: "Azure Active Directory",
    sso: "",
  },
  Rippling: {
    shortName: "Rippling",
    longName: "Rippling",
    sso: "SAML",
  },
}

const TOGGLE_PROVIDER = gql`
  mutation toggleProvisioningProvider($provider: String!, $enabled: Boolean!) {
    toggleProvisioningProvider(provider: $provider, enabled: $enabled)
  }
`

interface PropsType {
  provider: string
  onClose: () => void
}

export default function ProvisioningForm({ provider, onClose }: PropsType) {
  const t = useTranslate()

  const { companyFlags } = useCompanyFlags()
  const [toggleProvisioningProvider] = useMutation(TOGGLE_PROVIDER, {
    update(cache) {
      cache.modify({
        fields: {
          user: (_user) => {
            return
          },
        },
      })
    },
  })

  const settings = providerSettings[provider]

  const providerEnabled =
    "SCIM" in companyFlags && companyFlags.SCIM.provider === provider

  const toggleProvider = (enabled: boolean) => {
    toggleProvisioningProvider({
      variables: {
        provider,
        enabled,
      },
    })
  }

  if ("SCIM" in companyFlags && companyFlags.SCIM.provider !== provider) {
    const otherProvider = providerSettings[companyFlags.SCIM.provider]
    return (
      <>
        <Grid container spacing={6}>
          <Grid item xs={12} mt={2}>
            <Alert severity="warning">
              <>
                {t("You can only configure one provider per organization.")}
                <br />
                {t("Please disable provider provisioning to proceed.", {
                  provider: otherProvider.longName,
                })}
              </>
            </Alert>
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <ProvisioningContainer>
      <Grid container direction="row" className="toggle-container">
        <Toggle
          labelToTheLeft
          label={t("Enable provisioning with provider", {
            provider: settings.longName,
          })}
          checked={providerEnabled}
          onChange={(e) => toggleProvider(e)}
        />
      </Grid>
      <ConfigureCustomDomain />
      <ConfigureSCIM provider={provider} />
      {settings.sso === "SAML" && (
        <>
          <P16Bold className="text">
            {t(`Single sign-on (${settings.sso}):`)}
          </P16Bold>
          <ConfigureSAML
            provider={provider === "Rippling" ? "RAW" : "OKTA"}
            onClose={onClose}
          />
        </>
      )}
    </ProvisioningContainer>
  )
}
