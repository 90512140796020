import { ApolloError, gql, useMutation } from "@apollo/client"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import { useEffect, useState } from "react"

import { UserFragment } from "./fragments/userFragment"

interface UpdateUserValues {
  name?: string
}

const UPDATE_USER_VALUES = gql`
  ${UserFragment}
  mutation updateUserValues($userId: ID, $values: InputUpdateUserValues!) {
    updateUserValues(userId: $userId, values: $values) {
      ...UserFragment
    }
  }
`
export default function useUpdateUserValues() {
  const { addToast } = useToasts()
  const [mutate] = useMutation(UPDATE_USER_VALUES)
  const [error, setError] = useState<ApolloError | null>(null)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (error !== null) {
      addToast(error.message, { appearance: "error" })
      setError(null)
    }
  }, [error, addToast])
  const updateUserValues = async (
    values: UpdateUserValues,
    userId?: string
  ) => {
    setLoading(true)
    try {
      await mutate({ variables: { values, userId } })
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }
  return {
    loading,
    error,
    updateUserValues,
  }
}
