import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import React from "react"
import { colors } from "ui"
import { FastBackward, FastForward } from "ui/icons"

const style: React.CSSProperties = {
  stroke: colors.grey1,
  width: "20px",
  height: "20px",
  cursor: "pointer",
}

export function NextMonthButton() {
  const { setNextMonth } = usePlanningContext()
  return <FastForward style={style} onClick={setNextMonth} />
}

export function PreviousMonthButton() {
  const { setPreviousMonth } = usePlanningContext()
  return <FastBackward style={style} onClick={setPreviousMonth} />
}
