import useLocationOccupation from "hooks/useLocationOccupation"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { colors, L12, mediaQueries, P16 } from "ui"
import LocationTree from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

import { EditableSlotTooltip } from "../../../EditableUserSlots/EditableUserSlot/EditableUserSlotEntry/EditableSlotTootlip/EditableSlotTootlip"

const Layout = styled.div<{ isDailyView: boolean }>`
  &.occupation-slot {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${colors.grey4};
    border-radius: 100vh;
    border: 1px solid ${colors.grey3};
    aspect-ratio: 1;
    max-height: 32px;
    margin: 0 auto;

    &--purple {
      border-color: ${colors.purple};
    }
  }

  @media ${mediaQueries.isMobile} {
    &.occupation-slot {
      aspect-ratio: unset;
      height: 24px;
      ${({ isDailyView }) => {
        if (isDailyView) return `width: 24px;`
      }}
    }
  }
`

interface PropsType {
  location: LocationTree
  from: UTCDate
  to: UTCDate
  variant?: "purple"
  viewMode: "WEEK" | "MONTH" | "DAY"
}

export default function OccupationSlot({
  location,
  from,
  to,
  variant,
  viewMode,
}: PropsType) {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()

  const { occupation, available } = useLocationOccupation(location, from, to)

  const getContent = () => {
    if (occupation === undefined) return location.bookable
    return available
  }

  return (
    <EditableSlotTooltip
      key="nb-desks-available"
      title={`${t("desks available")} : ${getContent()} / ${location.bookable}`}
    >
      <Layout
        className={
          variant
            ? `occupation-slot occupation-slot--${variant}`
            : "occupation-slot"
        }
        isDailyView={viewMode === "DAY"}
      >
        {isMobile ? <L12>{getContent()}</L12> : <P16>{getContent()}</P16>}
      </Layout>
    </EditableSlotTooltip>
  )
}
