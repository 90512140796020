import useRenameGroup from "graphql/groups/useRenameGroup"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { Group } from "types"
import { Button, Dialog, HeaderTextField } from "ui"

const Editor = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
`

interface PropsType {
  group: Group
  open: boolean
  onClose: () => void
}

export default function UpdateDisplayNameDialog({
  group,
  open,
  onClose,
}: PropsType) {
  const t = useTranslate()
  const [name, setName] = useState<string>("")

  const renameGroup = useRenameGroup()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t("update group name")}
      leftButton={
        <Button secondary onClick={onClose}>
          {t("Cancel")}
        </Button>
      }
      rightButton={
        <Button
          onClick={() => {
            renameGroup(group.id, name)
            setName("")
            onClose()
          }}
        >
          {t("Save")}
        </Button>
      }
    >
      <Editor>
        <HeaderTextField
          placeholder={group.name}
          autoFocus
          handleChange={setName}
          onEnterKey={() => {
            renameGroup(group.id, name)
            onClose()
          }}
        />
      </Editor>
    </Dialog>
  )
}
