import { ApolloError, gql, useMutation } from "@apollo/client"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useTranslate from "intl/useTranslate"
import { useState } from "react"
import { GroupHybridRule, GroupHybridRuleInput } from "types"

import { GroupHybridRuleFragment } from "./fragments/hybridPolicyFragments"
import updateCache from "./updateCache"
import { COMPANY_HYBRID_RULES_QUERY } from "./useCompanyHybridRules"
import { DEFAULT_HYBRID_RULE_QUERY } from "./useDefaultHybridRule"

const MUTATION = gql`
  ${GroupHybridRuleFragment}
  mutation upsertGroupHybridRule(
    $groupId: ID
    $userIds: [String]
    $name: String!
    $period: String!
    $maxSlots: Int!
    $restrictedDays: [Int]!
  ) {
    upsertGroupHybridRule(
      groupId: $groupId
      userIds: $userIds
      name: $name
      period: $period
      maxSlots: $maxSlots
      restrictedDays: $restrictedDays
    ) {
      ...GroupHybridRuleFragment
    }
  }
`

export default function useUpsertGroupHybridRule() {
  const t = useTranslate()
  const { addToast } = useToasts()
  const [mutate] = useMutation<
    { upsertGroupHybridWorkRule?: GroupHybridRule },
    Omit<GroupHybridRuleInput, "maxDays"> & { maxSlots: number }
  >(MUTATION, {
    update: (cache, { data }) => {
      updateCache({
        cache,
        data,
        mutationName: "upsertGroupHybridRule",
      })
    },
    refetchQueries: [COMPANY_HYBRID_RULES_QUERY, DEFAULT_HYBRID_RULE_QUERY],
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ApolloError | undefined>(undefined)

  const upsertGroupHybridRule = async (groupRule: GroupHybridRuleInput) => {
    try {
      setLoading(true)
      await mutate({
        variables: {
          ...groupRule,
          maxSlots: 2 * groupRule.maxDays,
        },
      })
      addToast(t("hybrid-rule--upsert-success"), { appearance: "success" })
    } catch (error) {
      console.error(error)
      addToast(error.message, { appearance: "error" })
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  return { loading, error, upsertGroupHybridRule }
}
