import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { colors } from "ui"
import { CloseCircled, Search } from "ui/icons"

const Form = styled.form`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 8px;
  border-bottom: solid 1px ${colors.grey3};

  button {
    padding: 0;
    border: none;
    background: none;

    svg {
      display: flex;
      stroke: ${colors.grey2};
    }
  }
  input {
    border: none;
    padding: 0;
    margin: 0;
    outline: none;

    width: 100%;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${colors.grey2};
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: ${colors.grey2};
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: ${colors.grey2};
    }
  }

  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
`

const ClearSearch = styled.button`
  padding: 0;
  margin: 0;
  outline: none;

  svg {
    stroke: ${colors.grey2};
    width: 20px;
    height: 20px;
  }
`

interface PropsType {
  onChange: (str: string) => void
  onReset: () => void
}

export default function SearchForm({ onChange, onReset }: PropsType) {
  const [searchString, setSearchString] = useState("")
  const previousSearchString = useRef<string | undefined>(undefined)

  useEffect(() => {
    if (previousSearchString.current !== undefined && searchString === "") {
      previousSearchString.current = undefined
      onReset()
    }
  }, [onReset, searchString])

  return (
    <Form onSubmit={(e) => e.preventDefault()}>
      <button type="submit">
        <Search />
      </button>
      <input
        type="search"
        placeholder="Search"
        onChange={(e) => {
          previousSearchString.current = searchString
          setSearchString(e.target.value)
          onChange(e.target.value)
        }}
        value={searchString}
      />
      <ClearSearch
        onClick={() => {
          previousSearchString.current = undefined
          setSearchString("")
          onReset()
        }}
      >
        {searchString !== "" && <CloseCircled />}
      </ClearSearch>
    </Form>
  )
}
