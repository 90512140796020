import { createContext, useContext } from "react"
import { UserBase } from "types"

interface TeamsAppContextValue {
  isTeamsApp: boolean
  users?: UserBase[]
  microsoftTeams?: any
  teamName?: string
  isPrivateChat: boolean
}

const TeamsAppContext = createContext<TeamsAppContextValue>({
  isTeamsApp: false,
  isPrivateChat: false,
})

export function useTeamsAppContext() {
  return useContext(TeamsAppContext)
}

export default TeamsAppContext
