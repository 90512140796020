import useCompanyLabels from "graphql/labels/useCompanyLabels"
import useTranslate from "intl/useTranslate"
import NewCustomLabelButton from "pages/PlanningPage/components/SlotsEditor/SlotsEditorContent/WorkingModeButtons/NewCustomLabelButton/NewCustomLabelButton"
import { WorkingModeButtonWithUserLabel } from "pages/PlanningPage/components/SlotsEditor/SlotsEditorContent/WorkingModeButtons/WorkingModeButton/WorkingModeButton"
import React, { useState } from "react"
import styled from "styled-components"
import { Label } from "types"
import { P16 } from "ui"
import getColorFromStatus from "utils/getColorFromStatus"

const CustomLabelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

interface CutomLabelHeaderPropsTtype {
  value: string | null
}
const CutomLabelHeader = styled.div<CutomLabelHeaderPropsTtype>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 0;

  &:before {
    content: "";
    width: 8px;
    height: 8px;
    ${({ value }) => `background: ${getColorFromStatus(value)};`}
    border-radius: 50%;
  }
`

const CustomLabelContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

export default function CustomLabels() {
  const t = useTranslate()
  const { labels, removeCompanyLabel } = useCompanyLabels()

  const [state, setState] = useState<"IDLE" | "ADD_LABEL">("IDLE")

  const genericLabels: Label[] = [
    { type: "home", label: "Remote" },
    { type: "else", label: "Other" },
    { type: "off", label: "Off" },
    { type: "ooc", label: "Out Of Contract" },
  ]

  return (
    <CustomLabelsContainer>
      {genericLabels.map(({ type, label }) => (
        <div key={`label-section-${type}`}>
          <CutomLabelHeader value={type}>
            <P16>{t(label)}</P16>
          </CutomLabelHeader>
          <CustomLabelContent>
            {labels
              .filter((label) => label.type === type)
              .map((label, n) => (
                <WorkingModeButtonWithUserLabel
                  key={`${label.type}-${n}`}
                  value={label.type}
                  label={label.label}
                  onClick={() => {
                    //
                  }}
                  onRemove={() => {
                    removeCompanyLabel(label.type, label.label)
                  }}
                />
              ))}
            <NewCustomLabelButton
              target="company"
              type={type}
              onClick={() => setState("ADD_LABEL")}
              onSubmit={() => setState("IDLE")}
              disabled={state === "ADD_LABEL"}
            />
          </CustomLabelContent>
        </div>
      ))}
    </CustomLabelsContainer>
  )
}
