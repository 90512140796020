import { CircularProgress, Switch as MuiSwitch } from "@mui/material"
import React from "react"
import styled from "styled-components"
import { colors, P16 } from "ui"

const ToggleDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

function getSwitchProps() {
  return {
    classes: {
      root: "root",
      switchBase: "switchBase",
      thumb: "thumb",
      track: "track",
      checked: "checked",
      disabled: "disabled",
      focusVisible: "focusVisible",
    },
    disableRipple: true,
    focusVisibleClassName: "focusVisible",
  }
}

const IOSSwitch = styled(MuiSwitch).attrs(getSwitchProps)`
  &.root {
    width: 40px;
    height: 24px;
    padding: 0;
    margin: 0;
  }

  &.small.root {
    width: 32px;
    height: 18px;
  }

  .switchBase {
    padding: 2px;

    &.checked {
      transform: translateX(16px);
      color: ${colors.white};

      // Defines the main style of the toggle (under the circle)
      & + .track {
        background-color: ${colors.green};
        opacity: 1;
        border: none;
      }
    }
  }
  &.small .switchBase {
    &.checked {
      transform: translateX(14px);
    }
  }

  // Small white circle in the toggle
  .thumb {
    width: 20px;
    height: 20px;
    color: ${colors.grey4};
  }

  &.small .thumb {
    width: 14px;
    height: 14px;
  }

  .tumb .disabled {
    opacity: 0.7;
    & + .track {
      cursor: not-allowed;
      background-color: ${colors.grey1};
    }
  }

  // Gives the general shape of the toggle
  & .track {
    border-radius: 13px;
    background-color: ${colors.grey3};
    opacity: 1;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1),
      border 300ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .checked {
  }
  .focusVisible {
  }
`
interface PropsType {
  label?: string
  labelComponent?: React.FC
  labelToTheLeft?: boolean
  checked: boolean
  disabled?: boolean
  loading?: boolean
  variant?: "small"
  onChange: (checked: boolean) => void
}

export function Toggle({
  label,
  labelComponent: LabelComponent,
  labelToTheLeft,
  checked = false,
  disabled,
  loading = false,
  variant,
  onChange,
}: PropsType) {
  const renderLabel = () => {
    if (LabelComponent !== undefined)
      return <LabelComponent>{label}</LabelComponent>
    if (label === undefined) return null
    return <P16>{label}</P16>
  }

  if (loading) return <CircularProgress size={24} />

  if (labelToTheLeft)
    return (
      <ToggleDiv>
        {renderLabel()}
        <IOSSwitch
          className={variant}
          onChange={(e) => onChange(e.target.checked)}
          checked={checked}
          disabled={disabled}
        />
      </ToggleDiv>
    )

  return (
    <ToggleDiv>
      <IOSSwitch
        className={variant}
        onChange={(e) => onChange(e.target.checked)}
        checked={checked}
        disabled={disabled}
      />
      {renderLabel()}
    </ToggleDiv>
  )
}
