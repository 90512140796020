import useWorkingModes from "graphql/stats/useWorkingModes"
import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import React, { useEffect, useRef } from "react"
import { Pie } from "react-chartjs-2"
import styled from "styled-components"
import { GroupSimple, UserBase } from "types"
import { colors, P14 } from "ui"
import getLabelFromStatus from "utils/getLabelFromStatus"
import getUserDisplayName from "utils/getUserDisplayName"
import UTCDate from "utils/UTCDate"

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

interface PropsType {
  user: UserBase
  from: UTCDate
  to: UTCDate
  includeWeekends: boolean
  selectedWorkingModes: {
    sample: UserBase | GroupSimple
    type: "user" | "group"
    workingModes: {
      homeValue: number
      officeValue: number
      elseValue: number
      offValue: number
      oocValue: number
      nullValue: number
    }
  }[]
  setSelectedWorkingModes: React.Dispatch<
    React.SetStateAction<
      {
        sample: UserBase | GroupSimple
        type: "user" | "group"
        workingModes: {
          homeValue: number
          officeValue: number
          elseValue: number
          offValue: number
          oocValue: number
          nullValue: number
        }
      }[]
    >
  >
}

export default function UserWorkingModePie({
  user,
  from,
  to,
  includeWeekends,
  selectedWorkingModes,
  setSelectedWorkingModes,
}: PropsType) {
  const t = useTranslate()
  const { user: me } = useUser()

  const { workingModes, loading, error } = useWorkingModes({
    userIds: [user.id],
    groupIds: [],
    from,
    to,
    includeWeekends,
  })

  const labels = ["home", "office", "else", "off", "ooc", "null"]

  const homeValue =
    workingModes.find((wm) => wm.workingMode === "home")?.value ?? 0

  const officeValue =
    workingModes.find((wm) => wm.workingMode === "office")?.value ?? 0

  const elseValue =
    workingModes.find((wm) => wm.workingMode === "else")?.value ?? 0

  const offValue =
    workingModes.find((wm) => wm.workingMode === "off")?.value ?? 0

  const oocValue =
    workingModes.find((wm) => wm.workingMode === "ooc")?.value ?? 0

  const nullValue =
    workingModes.find((wm) => wm.workingMode === "null")?.value ?? 0

  const datasets = [
    {
      data: [homeValue, officeValue, elseValue, offValue, oocValue, nullValue],
      backgroundColor: [
        colors.home,
        colors.office,
        colors.else,
        colors.off,
        colors.ooc,
        colors.nullStats,
      ],
    },
  ]

  const data = { labels, datasets }

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    tooltips: {
      mode: "nearest",
      callbacks: {
        label: (item: any, obj: any) => {
          const status = obj.labels[item.index]
          const value = 100 * obj.datasets[0].data[item.index]
          return `${t(getLabelFromStatus(status))}: ${value.toFixed(2)}%`
        },
      },
    },
    legend: {
      display: false,
    },
  }

  const previousWorkingModes = useRef<
    | {
        workingMode: string
        value: number
      }[]
    | undefined
  >(undefined)

  useEffect(() => {
    if (workingModes.length > 0 && previousWorkingModes.current === undefined) {
      previousWorkingModes.current = { ...workingModes }

      setSelectedWorkingModes((prev) => [
        ...prev.filter(
          (swm) => swm.type !== "user" || swm.sample.id !== user.id
        ),
        {
          type: "user",
          sample: user,
          workingModes: {
            homeValue,
            officeValue,
            elseValue,
            offValue,
            oocValue,
            nullValue,
          },
        },
      ])
    }
  }, [
    homeValue,
    officeValue,
    elseValue,
    offValue,
    oocValue,
    nullValue,
    selectedWorkingModes,
    setSelectedWorkingModes,
    user,
    workingModes,
  ])

  useEffect(() => {
    return () => {
      setSelectedWorkingModes((prev) => [
        ...prev.filter(
          (swm) => swm.type !== "user" || swm.sample.id !== user.id
        ),
      ])
    }
    // eslint-disable-next-line
  }, [])

  if (loading || error || me === null) return <></>
  return (
    <Layout>
      <Pie data={data} options={options} />
      <P14>{getUserDisplayName(user, me.company.flags)}</P14>
    </Layout>
  )
}
