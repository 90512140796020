export type Color =
  | "black"
  | "grey1"
  | "grey2"
  | "grey3"
  | "grey4"
  | "white"
  | "blackReal"
  | "grey5"
  | "lightGrey"
  | "success"
  | "warning"
  | "error"
  | "home"
  | "office"
  | "else"
  | "ooc"
  | "off"
  | "null"
  | "yellowHSL"
  | "blackHSL"
  | "purpleHSL"
  | "whiteHSL"
  | "greenHSL"
  | "greenGradient"
  | "orangeGradient"
  | "redGradient"
  | "greyGradient"
  | "green"
  | "yellow"
  | "blue"
  | "red"
  | "purple"
  | "completed"
  | "notCompleted"
  | "redAlert"
  | "nullStats"

const colors: Record<string, string> = {
  // Black & White
  black: "#141C1F",
  grey1: "#7D8182",
  grey2: "#AFB4B6",
  grey3: "#DFE1E1",
  grey4: "#FAFAFA",
  white: "#FFFFFF",
  blackReal: "0, 0%, 0%",
  grey5: "#DADADA",
  lightGrey: "#f2f2f2",
  transparent: "#ffffff00",

  // Colors
  green: "#00DCA3",
  yellow: "#FFD166",
  blue: "#0093C3",
  red: "#FF3767",
  purple: "#220075", // rgb(34, 0, 117)
  purple100: "#fdfdfe", // rgba(34, 0, 117, 0.01)
  purple200: "#22007505", // rgba(34, 0, 117, 0.02)
  purple300: "#2200750A", // rgba(34, 0, 117, 0.04)
  purple400: "#22007514", // rgba(34, 0, 117, 0.08)
  purple600: "#22007552", // rgba(34, 0, 117, 0.26)
  magenta: "#FF00FF", // rgb(255, 0, 255)
  magenta400: "#FF00FF14", // rgba(255, 0, 255, 0.08)
  magentaLight: "#FF80FF", // rgb(255, 128, 255)
  magentaLight400: "#FF80FF14", // rgba(255, 128, 255, 0.08)
  success: "#00DCA3",
  warning: "#FFD166",
  error: "#FF3767",
  redAlert: "#FF0000",
  nullStats: "#ff7798",

  home: "#00DCA3", // was "#3FE093"
  office: "#0093C3", // was "#220075"
  else: "#FFD166", // was "#FCC454"
  ooc: "#DFE1E1", // was "#3D3DB2"
  off: "#DFE1E1", // was "#FFA348"
  null: "#FF3767", // was "#FEFEFE", new ui empty slots is "#FF3767"

  completed: "#00DCA3", // home
  notCompleted: "#DFE1E1", // off

  cyan: "#00FFFF",
  lightCyan: "#00FFFF14",

  // HSL values to be used like this: hsla(${HSL_VALUES, ALPHA})
  yellowHSL: "42, 100%, 70%",
  blackHSL: "0, 0%, 0%",
  whiteHSL: "0, 0%, 100%",
  greenHSL: "164, 100%, 43%",

  // Linear gradients
  greenGradient:
    "linear-gradient(0deg, rgba(0, 220, 163, 0.1), rgba(0, 220, 163, 0.1)), #FFFFFF",
  orangeGradient:
    "linear-gradient(0deg, rgba(255, 209, 102, 0.1), rgba(255, 209, 102, 0.1)), #FFFFFF",
  redGradient:
    "linear-gradient(0deg, rgba(255, 55, 103, 0.1), rgba(255, 55, 103, 0.1)), #FFFFFF",
  greyGradient:
    "linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), #FAFAFA",

  // box shadow
  boxShadow:
    "0px 2px 16px rgba(0, 0, 0, 0.24), 0px 2px 2px rgba(0, 0, 0, 0.12)",
}

export default colors

export function getColor(color?: string | Color) {
  if (color) {
    return colors[color] || colors.black
  }
  return colors.black
}
