import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function Stroller({
  strokeWidth,
  stroke,
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      strokeWidth={strokeWidth ? strokeWidth : 1.5}
      stroke={stroke ? stroke : "currentColor"}
      {...rest}
    >
      <path
        d="M15.3333 4C9.07411 4 4 9.07411 4 15.3333C4 17.5367 4.6288 19.5933 5.71674 21.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M24.9502 21.3332C26.0381 19.5931 26.6669 17.5366 26.6669 15.3332V12.6665H30.0002"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M10.6668 27.9998C9.19407 27.9998 8.00016 26.8059 8.00016 25.3332C8.00016 23.8604 9.19407 22.6665 10.6668 22.6665C12.1396 22.6665 13.3335 23.8604 13.3335 25.3332C13.3335 26.8059 12.1396 27.9998 10.6668 27.9998Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M19.9998 27.9998C18.5271 27.9998 17.3332 26.8059 17.3332 25.3332C17.3332 23.8604 18.5271 22.6665 19.9998 22.6665C21.4726 22.6665 22.6665 23.8604 22.6665 25.3332C22.6665 26.8059 21.4726 27.9998 19.9998 27.9998Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M15.3335 4V16"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M4.6665 16H25.9998"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  )
}
