import useSetApplyTypicalWeekAutomatically from "graphql/typicalWeek/useSetApplyTypicalWeekAutomatically"
import useUserFlag from "hooks/useUserFlag"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { colors, mediaQueries, P14Bold, Toggle } from "ui"

const Layout = styled.div`
  margin-left: auto;
  background-color: ${colors.grey4};
  padding: 10px 24px;
  margin-bottom: 24px;

  p {
    color: ${colors.grey1};
  }
  @media ${mediaQueries.isMobile} {
    padding-bottom: 16px;
    > div:first-child {
      display: flex;
      justify-content: space-between;
    }
  }
`

const Span = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: auto;
`

function Label() {
  const t = useTranslate()
  return (
    <Span>
      <P14Bold>
        {t("Automatically fill my schedule with my typical week")}{" "}
      </P14Bold>
    </Span>
  )
}

export default function ToggleAutoApplySlotTemplates() {
  const { setApplyTypicalWeekAutomatically, loading } =
    useSetApplyTypicalWeekAutomatically()
  const applyTypicalWeekAutomatically = useUserFlag(
    "applyTypicalWeekAutomatically",
    false
  )

  return (
    <Layout>
      <Toggle
        labelComponent={Label}
        loading={loading}
        labelToTheLeft
        checked={applyTypicalWeekAutomatically}
        onChange={(checked) => {
          if (loading) return
          setApplyTypicalWeekAutomatically(checked)
        }}
      />
    </Layout>
  )
}
