import PageContent from "components/Layout/PageLayout/PageContent/PageContent"
import PageHeader from "components/Layout/PageLayout/PageHeader/PageHeader"
import PageTitle from "components/Layout/PageLayout/PageHeader/PageTitle/PageTitle"
import PageLayout from "components/Layout/PageLayout/PageLayout"
import Loader from "components/Loader/Loader"
import useCompanyIntegrations from "graphql/integrations/useCompanyIntegrations"
import useMeetingRooms from "graphql/meetingRooms/useMeetingRooms"
import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { MeetingRoom } from "types"
import { Button, colors, P16 } from "ui"
import LocalDate from "utils/LocalDate"

import MeetingRoomsFilter from "./MeetingRoomsFilter/MeetingRoomsFilter"
import MeetingRoomsList from "./MeetingRoomsList/MeetingRoomsList"
import MeetingRoomsSearch from "./MeetingRoomsSearch/MeetingRoomsSearch"
import { Row } from "./SharedLayoutComponents"
import TimeNavigation from "./TimeNavigation/TimeNavigation"
import TimeTable from "./TimeTable/TimeTable"

const Layout = styled.div`
  border-left: solid 1px ${colors.grey3};
  border-top: solid 1px ${colors.grey3};
  min-height: 100%;
`

const ErrorMessage = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  margin: auto;
  align-items: center;
`

interface PropsType {
  meetingRooms: MeetingRoom[]
  from: LocalDate
  to: LocalDate
  minHour: LocalDate
  maxHour: LocalDate
  shiftTime: (amount: number) => void
  createLoadedEvent: (id: string) => CustomEvent<{
    loaded: string
  }>
}

function Content({
  meetingRooms,
  from,
  to,
  minHour,
  maxHour,
  shiftTime,
  createLoadedEvent,
}: PropsType) {
  const [filteredMeetingRooms, setFilteredMeetingRooms] = useState<
    MeetingRoom[]
  >([])

  return (
    <>
      <PageContent>
        <Layout>
          <Row>
            <TimeNavigation from={from} shiftTime={shiftTime} />
            <MeetingRoomsFilter />
          </Row>

          <Row>
            <MeetingRoomsSearch
              meetingRooms={meetingRooms}
              updateFilteredMeetingRooms={setFilteredMeetingRooms}
            />
            <TimeTable minHour={minHour} maxHour={maxHour} />
          </Row>

          <MeetingRoomsList
            meetingRooms={
              filteredMeetingRooms.length > 0
                ? filteredMeetingRooms
                : meetingRooms
            }
            from={from}
            to={to}
            minHour={minHour}
            maxHour={maxHour}
            createLoadedEvent={createLoadedEvent}
          />
        </Layout>
      </PageContent>
    </>
  )
}

function ContentContainer({ children }: { children: React.ReactNode }) {
  const t = useTranslate()

  return (
    <PageLayout>
      <PageHeader>
        <PageTitle title={t("Meeting rooms")} />
      </PageHeader>
      {children}
    </PageLayout>
  )
}

export default function Wrapper() {
  const t = useTranslate()
  const { user: me } = useUser()
  const history = useHistory()

  const { meetingRooms, loading: loadingRooms } = useMeetingRooms()
  const [loadedItems, setLoadedItems] = useState<string[]>([])
  const createLoadedEvent = (id: string) =>
    new CustomEvent("itemLoaded", {
      detail: { loaded: id },
    })
  const loading = loadedItems.length < meetingRooms.length
  const { companyIntegrations } = useCompanyIntegrations()
  const hasEventsIntegration = companyIntegrations.find(
    ({ name }) => name === "google-events" || name === "microsoft-outlook"
  )

  const [from, setFrom] = useState(new LocalDate("DAY"))
  const [to, setTo] = useState(new LocalDate("NEXT-DAY"))
  const shiftTime = (amount: number) => {
    const newFrom = new LocalDate(from)
    newFrom.shift("DAY", amount)
    const newTo = new LocalDate(newFrom)
    newTo.shift("DAY", 1)
    setFrom(newFrom)
    setTo(newTo)
    setLoadedItems([])
  }

  const minHour = new LocalDate(from)
  minHour.setHours(8, 0, 0)
  const maxHour = new LocalDate(from)
  maxHour.setHours(20, 0, 0)

  useEffect(() => {
    const loadedEventCallBack = ((e: CustomEvent) => {
      if (!loadedItems.find((id) => e.detail.loaded === id))
        setLoadedItems((prev) => [...prev, e.detail.loaded])
    }) as EventListener
    document.addEventListener("itemLoaded", loadedEventCallBack)

    return () =>
      document.removeEventListener("itemLoaded", () => {
        //
      })
    // eslint-disable-next-line
  }, [])

  if (loadingRooms)
    return (
      <ContentContainer>
        <Loader />
      </ContentContainer>
    )

  if (!hasEventsIntegration)
    return (
      <ContentContainer>
        <ErrorMessage>
          <P16>{t("meeting-room-error--no-integration")}</P16>
          {me?.role === "admin" && (
            <Button ghost onClick={() => history.push("/admin/integrations")}>
              {t("Go to Offishall's integrations configuration page.")}
            </Button>
          )}
        </ErrorMessage>
      </ContentContainer>
    )

  return (
    <>
      <ContentContainer>
        <Content
          meetingRooms={meetingRooms}
          from={from}
          to={to}
          minHour={minHour}
          maxHour={maxHour}
          shiftTime={shiftTime}
          createLoadedEvent={createLoadedEvent}
        />
      </ContentContainer>
      {loading && <Loader />}
    </>
  )
}
