import MobileBottomNavigation from "components/MobileBottomNavigation/MobileBottomNavigation"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useUser from "graphql/users/useUser"
import useWindowSize from "hooks/useWindowResize"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { useTeamsAppContext } from "TeamsApp/TeamsAppContext"
import { Button } from "ui"
import getSafeAvailableHeightCSSProperties from "utils/getSafeAvailableHeightCSSProperties"

import UserFloorPlanRouter from "../components/PlanningHeadersRouter/PlanningHeaders/LocationPlanningHeader/UserFloorPlanDrawer/UserFloorPlanDrawer"
import UsersPlanningList from "../components/UsersPlanningList/UsersPlanningList"
import DailyView from "./components/DailyView/DailyView"
import PlanningPageHeader from "./components/PlanningPageHeader/PlanningPageHeader"

const Layout = styled.div<{ maxHeight: number }>`
  display: grid;
  grid-auto-rows: auto 1fr auto;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;

  ${({ maxHeight }) => getSafeAvailableHeightCSSProperties(maxHeight)}
`

const Body = styled.div<{ disablePointerEvents: boolean }>`
  overflow-y: scroll;
  overflow-x: hidden;
  ${({ disablePointerEvents }) => {
    if (disablePointerEvents) return "pointer-events: none;"
  }}
`

const ViewMoreButton = styled(Button)`
  margin: 4px;
`

const GROUP_LIST_LIMIT = 20
const GROUP_LIST_INCREMENT = 10

export default function PlanningPageMobile() {
  const { users, openDrawer, from, to, setOpenDrawer, viewMode } =
    usePlanningContext()
  const t = useTranslate()
  const [maxWidth, maxHeight] = useWindowSize()

  const [limit, setLimit] = useState(GROUP_LIST_LIMIT)

  const { isTeamsApp } = useTeamsAppContext()

  const editionActive = openDrawer === "SLOTS_EDITOR"

  const { user: me } = useUser()

  if (!me) return null

  if (viewMode === "DAY")
    return (
      <Layout maxHeight={maxHeight}>
        <DailyView />
      </Layout>
    )

  return (
    <Layout maxHeight={maxHeight}>
      <PlanningPageHeader usersFromRouter={users} />

      {!editionActive && (
        <>
          <Body disablePointerEvents={false}>
            <UsersPlanningList users={users} limit={limit} />
            {limit < users.length && (
              <ViewMoreButton
                ghost
                onClick={() => setLimit(limit + GROUP_LIST_INCREMENT)}
              >
                {t("view more")}
              </ViewMoreButton>
            )}
          </Body>
          {openDrawer === "FLOOR_PLAN" && (
            <UserFloorPlanRouter
              from={from}
              to={to}
              drawerWidth={maxWidth}
              onClose={() => {
                if (openDrawer === "FLOOR_PLAN") setOpenDrawer(null)
              }}
            />
          )}
          {!isTeamsApp && <MobileBottomNavigation />}
        </>
      )}
    </Layout>
  )
}
