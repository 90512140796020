export type MediaQuery =
  | "isTouchable"
  | "isMobile"
  | "isTablet"
  | "isDesktop"
  | "isNarrowScreen"

const mediaQueries: Record<MediaQuery, string> = {
  isTouchable: "(pointer: coarse)",
  isMobile:
    "(pointer: coarse) and (max-width: 900px), (hover: none) and (max-width: 900px), (hover: hover) and (max-width: 750px)", // README need to follow
  isTablet:
    "(pointer: coarse) and (max-width: 900px), (hover: none) and (max-width: 900px)",
  isDesktop: "(hover: hover) and (min-width: 900px)",
  isNarrowScreen: "(max-width: 1200px)",
}

export default mediaQueries
