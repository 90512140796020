import { ApolloError, gql, useMutation } from "@apollo/client"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import { useEffect, useState } from "react"

const SET_PASSWORD = gql`
  mutation setPassword($password: String!, $token: String!) {
    setPassword(password: $password, token: $token)
  }
`

export default function useSetPassword() {
  const { addToast } = useToasts()
  const [mutate] = useMutation(SET_PASSWORD)
  const [error, setError] = useState<ApolloError | null>(null)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (error !== null) {
      addToast(error.message, { appearance: "error" })
      setError(null)
    }
  }, [error, addToast])
  const setPassword = async (
    password: string,
    token: string
  ): Promise<string | null> => {
    setLoading(true)
    let result = null
    try {
      const { data } = await mutate({ variables: { password, token } })
      result = data?.setPassword
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
    return result
  }
  return {
    loading,
    error,
    setPassword,
  }
}
