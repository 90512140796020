export default function uploadFileToSignedURL(file: File, url: string) {
  return fetch(url, {
    method: "PUT",
    body: file,
    headers: {
      "Content-Type": file.type,
      "x-amz-acl": "public-read",
    },
  })
}
