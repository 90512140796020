import EventDateTitle from "components/Events/EventDateTitle/EventDateTitle"
import EventDialog from "components/Events/EventDialog/EventDialog"
import useUser from "graphql/users/useUser"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import styled from "styled-components"
import { IntegrationEvent, UpsertEventInput } from "types"
import { Button, colors, L12, Markdown, mediaQueries, P16Bold } from "ui"
import { Cancel, Check, Edit, MidlineHorizontalEllispsis } from "ui/icons"
import { getUserAsAttendee } from "utils/events"

const Item = styled.li`
  display: flex;
  gap: 40px;
  cursor: pointer;
  margin-top: 20px;
  min-height: 96px;
  @media ${mediaQueries.isTouchable} {
    gap: 12px;
    flex-direction: column;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  .content {
    &-dates {
      display: flex;
      align-items: center;
      padding-bottom: 4px;
      p {
        color: ${colors.purple};
        text-transform: uppercase;
      }
      .event-actions {
        margin-left: auto;
      }
    }
    &-location {
      padding-bottom: 8px;
      p {
        color: ${colors.grey1};
      }
    }
    &-title p {
      padding-bottom: 4px;
    }
    &-body p {
      color: ${colors.grey1};
      font-size: 14px;
      line-height: 18px;
      padding-bottom: 10px;
      max-height: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      max-width: 600px;
      @media ${mediaQueries.isTouchable} {
        padding-bottom: 8px;
        height: 24px;
        -webkit-line-clamp: 1;
      }
    }
    &-booking-info {
      display: flex;
      align-items: center;
      gap: 8px;
      p {
        color: ${colors.grey2};
      }

      &--green {
        p,
        svg {
          color: ${colors.green};
          font-weight: 600;
        }
      }
      &--red {
        p,
        svg {
          color: ${colors.redAlert};
        }
      }
      &--grey {
        p,
        svg {
          color: ${colors.grey2};
          font-weight: 600;
        }
      }
    }
    &-deadline-info p {
      color: ${colors.redAlert};
    }
  }
`

const MobileEdition = styled(Button)`
  background-color: #fff;
  margin-left: auto;
  padding: 0 10px;
  svg {
    height: 18px;
    width: 18px;
    color: ${colors.purple};
    stroke-width: 2px;
  }
`

interface PropsType {
  event: IntegrationEvent
  isEditable: boolean
  setEventToBeEdited: (event: UpsertEventInput) => void
}

export default function EventItem({
  event,
  isEditable,
  setEventToBeEdited,
}: PropsType) {
  const t = useTranslate()
  const history = useHistory()
  const { user: me } = useUser()
  const { eventId } = useParams<{ eventId: string | undefined }>()
  const meAsGuest = getUserAsAttendee(event.attendees, me?.email)

  const alreadyResponded =
    meAsGuest?.status === "attending" || meAsGuest?.status === "not-attending"

  const { isMobile } = useMediaQueries()

  const [isAttendDialogOpen, setIsAttendDialogOpen] = useState(false)

  useEffect(() => {
    if (eventId === event.id) {
      setIsAttendDialogOpen(true)
    }
  }, [eventId, setIsAttendDialogOpen, event.id])

  const deadline = undefined // TODO rsvp deadline

  const openSeats = undefined // TODO event capacity ?

  return (
    <>
      <Item
        className={isEditable ? "event-item-editable" : ""}
        onClick={(e) => {
          e.stopPropagation()
          setIsAttendDialogOpen(true)
        }}
      >
        <Content>
          <div className="content-dates">
            <EventDateTitle {...event} />
          </div>

          <div className="content-location">
            {/* <P14>{event.flags.location?.name}</P14> */}
          </div>

          <div className="content-title">
            <P16Bold>
              {event.name}
              {event.roomId && (
                <span
                  style={{
                    fontWeight: 400,
                    paddingLeft: 8,
                    color: colors.grey2,
                  }}
                >
                  ({t("meeting")})
                </span>
              )}
            </P16Bold>
          </div>
          <div className="content-body">
            <Markdown>{event.description}</Markdown>
          </div>
          {openSeats !== undefined && (
            <div className="content-booking-info">
              <L12>{t("event booking message", { seats: `${openSeats}` })}</L12>
            </div>
          )}
          {!alreadyResponded && deadline && (
            <div className="content-booking-info content-booking-info--red">
              <L12>{t("event deadline message", { date: deadline })}</L12>
            </div>
          )}

          {meAsGuest && meAsGuest.status === "attending" && (
            <div className="content-booking-info content-booking-info--green">
              <Check />
              <L12>{t("long attending event")}</L12>
            </div>
          )}

          {meAsGuest && meAsGuest.status === "not-attending" && (
            <div className="content-booking-info content-booking-info--red">
              <Cancel />
              <L12>{t("long not attending event")}</L12>
            </div>
          )}

          {meAsGuest && meAsGuest.status === "pending" && (
            <div className="content-booking-info content-booking-info--grey">
              <MidlineHorizontalEllispsis />
              <L12>{t("pending")}</L12>
            </div>
          )}
        </Content>

        {isMobile && isEditable && (
          <MobileEdition
            secondary
            stopPropagation
            onClick={() => {
              if (isEditable) setEventToBeEdited(event)
            }}
          >
            <Edit
              onClick={() => {
                if (isEditable) setEventToBeEdited(event)
              }}
            />
          </MobileEdition>
        )}

        <div
          className="event-actions"
          onClick={(e) => {
            e.stopPropagation()
            if (isEditable) setEventToBeEdited(event)
          }}
        >
          {!isMobile && isEditable && (
            <Button secondary icon={<Edit className="edit-event-icon" />}>
              {t("Edit")}
            </Button>
          )}
        </div>
      </Item>
      {isAttendDialogOpen && (
        <EventDialog
          open={isAttendDialogOpen}
          event={event}
          isEditable={isEditable}
          openEventToBeEditable={(event) => setEventToBeEdited(event)}
          onClose={() => {
            if (eventId === event.id) {
              history.replace("events")
            }
            setIsAttendDialogOpen(false)
          }}
        />
      )}
    </>
  )
}
