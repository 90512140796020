import PageContent from "components/Layout/PageLayout/PageContent/PageContent"
import AdminPageTitle from "components/Layout/PageLayout/PageHeader/AdminPageTitle/AdminPageTitle"
import PageHeader from "components/Layout/PageLayout/PageHeader/PageHeader"
import PageLayout from "components/Layout/PageLayout/PageLayout"
import useCompanyIntegrations from "graphql/integrations/useCompanyIntegrations"
import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"

import CompanyWideIntegrationsList from "./CompanyWideIntegrationsList/CompanyWideIntegrationsList"
import KelioIntegrationComponent from "./KelioIntegrationComponent"
import LuccaIntegrationComponent from "./LuccaIntegrationComponent"
import MicrosoftIntegrationComponent from "./MicrosoftIntegrationComponent"
import MicrosoftTeamsIntegrationComponent from "./MicrosoftTeamsIntegrationComponent"
import WorkdayIntegrationComponent from "./WorkdayIntegrationComponent"

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 32px;
`

const possibleIntegrations = [
  {
    name: "microsoft-outlook",
    Component: MicrosoftIntegrationComponent,
    enabledByDefault: true,
  },
  {
    name: "microsoft-teams",
    Component: MicrosoftTeamsIntegrationComponent,
    enabledByDefault: true,
  },
  {
    name: "workday",
    Component: WorkdayIntegrationComponent,
    enabledByDefault: true,
  },
  {
    name: "kelio",
    Component: KelioIntegrationComponent,
    enabledByDefault: true,
  },
  {
    name: "lucca",
    Component: LuccaIntegrationComponent,
    enabledByDefault: true,
  },
]

export default function IntegrationsPage() {
  const { user } = useUser()
  const enabledIntegrations = user?.company.flags.enabledIntegrations || []
  const { companyIntegrations } = useCompanyIntegrations()
  const t = useTranslate()
  const breadcrumb = [
    {
      name: t("Admin"),
      path: "/admin",
    },
    {
      name: t("Integrations"),
      path: "/admin/integrations",
    },
  ]

  return (
    <PageLayout>
      <PageHeader>
        <AdminPageTitle title={t("Integrations")} breadcrumbs={breadcrumb} />
      </PageHeader>
      <PageContent>
        <CompanyWideIntegrationsList />
        <List>
          {possibleIntegrations
            .filter(
              ({ name, enabledByDefault }) =>
                enabledByDefault || enabledIntegrations.includes(name)
            )
            .map(({ name, Component }) => {
              if (name === "microsoft-outlook") return null
              return (
                <li key={`integration-${name}`}>
                  <Component
                    integration={companyIntegrations.find(
                      (i) => i.name === name
                    )}
                  />
                </li>
              )
            })}
        </List>
      </PageContent>
    </PageLayout>
  )
}
