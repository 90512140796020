import React from "react"
import styled from "styled-components"
import { colors } from "ui"

const Layout = styled.div`
  padding: 24px 0 40px 0;
  border-top: solid 1px ${colors.grey3};
  display: flex;
  flex-direction: column;
  width: inherit;
  max-width: inherit;
`

interface PropsType {
  component: React.ReactNode
}

function Section({ component }: PropsType, ref: React.Ref<HTMLDivElement>) {
  return <Layout ref={ref}>{component}</Layout>
}

export default React.forwardRef(Section)
