import { gql } from "@apollo/client"

export const EventFragment = gql`
  fragment EventFragment on Event {
    id
    name
    description
    from
    to
    deadline
    capacity
    private
    flags
    locationId
    createdBy {
      id
      name
      email
      role
    }
    guests {
      id
      status
      user {
        id
        name
        email
        role
      }
    }
  }
`
