import { gql, useMutation } from "@apollo/client"
import VisualArea from "components/FloorPlanComponents/VisualAreaEditor/VisualArea"
import { useCallback } from "react"

import { updateVisualAreaCache } from "./updateCache"

const MUTATION = gql`
  mutation saveFloorVisualArea($locationId: ID!, $area: JSON!) {
    saveFloorVisualArea(locationId: $locationId, area: $area) {
      locationId
      area
    }
  }
`

interface PropsType {
  locationId: string
  area: VisualArea
}

export default function useSaveFloorVisualArea() {
  const [mutate] = useMutation(MUTATION)

  return useCallback(
    async ({ locationId, area }: PropsType) => {
      try {
        await mutate({
          variables: {
            locationId,
            area,
          },
          update: (cache, { data }) => {
            updateVisualAreaCache({
              cache,
              data,
            })
          },
        })
      } catch (e) {
        console.error(e.message)
      }
    },
    [mutate]
  )
}
