import React from "react"
import styled from "styled-components"
import { Equipment } from "types"
import { colors, LU14 } from "ui"
import LocationTree from "utils/LocationTree"

import LocationEquipmentsPopper from "../LocationEquipmentsPopper/LocationEquipmentsPopper"

const Layout = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const BreadcrumbsText = styled(LU14)`
  color: ${colors.grey2};
`

interface PropsType {
  location: LocationTree
}

export default function LocationName({ location }: PropsType) {
  return (
    <Layout>
      {location.breadCrumbs.length > 0 && (
        <BreadcrumbsText className="located-services-breadcrumbs">
          {location.breadCrumbs.join(" / ")} /
        </BreadcrumbsText>
      )}

      <LU14>{location.name}</LU14>
    </Layout>
  )
}

interface LocationNameWithTooltipPropsType extends PropsType {
  equipments: Equipment[]
}

export function LocationNameWithTooltip({
  location,
  equipments,
}: LocationNameWithTooltipPropsType) {
  return (
    <Layout>
      {location.breadCrumbs.length > 0 && (
        <BreadcrumbsText className="located-services-breadcrumbs">
          {location.breadCrumbs.join(" / ")} /
        </BreadcrumbsText>
      )}

      <LU14>{location.name}</LU14>
      <LocationEquipmentsPopper location={location} equipments={equipments} />
    </Layout>
  )
}
