import React, { AnchorHTMLAttributes } from "react"
import styled from "styled-components"

const UnstyledAnchor = styled.a`
  text-decoration: none;

  &:hover,
  &:link,
  &:visited,
  &:active {
    color: inherit;
  }
`

interface PropsType extends AnchorHTMLAttributes<HTMLAnchorElement> {
  children: React.ReactNode
}

export function ExternalLink({ children, rel, ...props }: PropsType) {
  return (
    <UnstyledAnchor target="_blank" rel={rel ?? "noreferrer"} {...props}>
      {children}
    </UnstyledAnchor>
  )
}
