import { Alert as MuiAlert } from "@mui/material"
import { spacing } from "@mui/system"
import React from "react"
import styled from "styled-components/macro"

const AlertM = styled(MuiAlert)(spacing)

interface PropsType {
  severity: "success" | "info" | "warning" | "error"
  children: JSX.Element
  noIcon?: boolean
  width?: string | number
}

export function Alert({
  severity,
  children,
  noIcon = false,
  width = "100%",
}: PropsType) {
  return (
    <AlertM
      mt={2}
      mb={1}
      severity={severity}
      style={{ width }}
      icon={noIcon ? false : undefined}
    >
      {children}
    </AlertM>
  )
}
