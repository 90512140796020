import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function ArrowDownFill({
  stroke,
  strokeWidth,
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      strokeWidth={strokeWidth ?? 1.5}
      stroke={stroke ? stroke : "currentColor"}
      {...rest}
    >
      <path d="M12.6987 15.6364C12.3882 16.1212 11.6118 16.1212 11.3013 15.6364L7.10929 9.09091C6.79877 8.60606 7.18692 8 7.80796 8L16.192 8C16.8131 8 17.2012 8.60606 16.8907 9.09091L12.6987 15.6364Z" />
    </SvgIcon>
  )
}
