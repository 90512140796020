import { ApolloError, gql, useQuery } from "@apollo/client"
import UTCDate from "utils/UTCDate"

const QUERY = gql`
  query stats_occupancyRateAverage(
    $from: String!
    $to: String!
    $locationIds: [String]!
    $includeWeekends: Boolean!
  ) {
    stats_occupancyRateAverage(
      from: $from
      to: $to
      locationIds: $locationIds
      includeWeekends: $includeWeekends
    ) {
      value
      booked
      total
    }
  }
`

interface PropsType {
  from: UTCDate
  to: UTCDate
  locationIds: string[]
  includeWeekends: boolean
}

export default function useOccupancyRate({
  from,
  to,
  locationIds,
  includeWeekends,
}: PropsType): {
  loading: boolean
  error?: ApolloError
  occupancyRate: { value: number; booked: number; total: number }
} {
  const { loading, error, data } = useQuery(QUERY, {
    variables: {
      from: `${from.getTime()}`,
      to: `${to.getTime()}`,
      locationIds,
      includeWeekends,
    },
  })
  return {
    occupancyRate: data?.stats_occupancyRateAverage || {
      value: 0,
      booked: 0,
      total: 0,
    },
    loading,
    error,
  }
}
