import { gql, useMutation } from "@apollo/client"
import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components/macro"
import { Button, colors, LU12, TextFieldToCopy } from "ui"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 500px;

  .title {
    color: ${colors.grey1};
    margin-top: 20px;
  }
`

const GENERATE_TOKEN = gql`
  mutation generateSCIMToken($provider: String!) {
    generateSCIMToken(provider: $provider)
  }
`

export default function ConfigureSCIM({ provider }: { provider: string }) {
  const t = useTranslate()

  const user = useUser()

  const [token, setToken] = React.useState<string | undefined>(undefined)

  const [generateSCIMToken] = useMutation(GENERATE_TOKEN)

  const generateToken = async () => {
    const {
      data: { generateSCIMToken: newToken },
    } = await generateSCIMToken({ variables: { provider } })
    setToken(newToken)
  }

  const hasGeneratedToken = user.user?.company.flags.generatedScimToken

  const tenantURL = `${process.env.REACT_APP_API}/scim/v2`

  return (
    <Container>
      <LU12 className="title">{t("SCIM provisioning").toUpperCase()}</LU12>

      <TextFieldToCopy label={t("base url")} valueToCopy={tenantURL} disabled />

      <TextFieldToCopy
        label="Token"
        valueToCopy={token}
        placeholder={
          hasGeneratedToken
            ? t(
                "Previously generated tokens are not shown here for security reasons."
              )
            : " "
        }
        disabled
      />
      <Button disabled={!!token} onClick={generateToken}>
        {t("Generate new token")}
      </Button>
    </Container>
  )
}
