import React, { useState } from "react"
import { Route, Switch } from "react-router-dom"

import OnboardingCongrats from "./OnboardingCongrats"
import OnboardingLayout from "./OnboardingLayout"
import OnboardingLocations from "./OnboardingLocations"
import { OnboardingLocation } from "./OnboardingTypes"
import OnboardingUsers from "./OnboardingUsers"
import OnboardingWelcome from "./OnboardingWelcome"

const initialLocation: OnboardingLocation = {
  name: "",
  capacity: 25,
}

export default function OnboardingPage() {
  const [userEmails, setUserEmails] = useState<string[]>([])
  const [locations, setLocations] = useState<OnboardingLocation[]>([
    initialLocation,
  ])

  return (
    <OnboardingLayout>
      <Switch>
        <Route path="/users" exact>
          <OnboardingUsers
            userEmails={userEmails}
            setUserEmails={setUserEmails}
          />
        </Route>
        <Route path="/locations" exact>
          <OnboardingLocations
            locations={locations}
            setLocations={setLocations}
          />
        </Route>
        <Route path="/congrats" exact>
          <OnboardingCongrats locations={locations} userEmails={userEmails} />
        </Route>
        <Route path="/">
          <OnboardingWelcome />
        </Route>
      </Switch>
    </OnboardingLayout>
  )
}
