import DeleteButtonWithConfirmation from "components/ButtonsWithConfirmation/DeleteButtonWithConfirmation/DeleteButtonWithConfirmation"
import useRemoveTeamDay from "graphql/teamDays/useRemoveTeamDay"
import useTeamDays from "graphql/teamDays/useTeamDays"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { colors, Grid, H4Light, L12, mediaQueries, P16, P16Bold } from "ui"
import UTCDate from "utils/UTCDate"

import NewTeamDayButton from "./NewTeamDayButton/NewTeamDayButton"

interface ListProps {
  headerheight: number | undefined
}

const List = styled(Grid)<ListProps>`
  border-top: 1px solid ${colors.grey3};
  ${({ headerheight }) => `height: calc(100vh - ${headerheight}px);`}
  ${({ headerheight }) => `max-height: calc(100vh - ${headerheight}px);`}
  padding: 32px 32px 32px 32px;
  overflow: hidden;
  &:first-child {
    padding-bottom: 32px;
  }
  @media ${mediaQueries.isMobile} {
    padding: 16px;
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 32px;
    gap: 8px;
    @media ${mediaQueries.isMobile} {
      display: none;
    }
    .number-of-team-day {
      background-color: ${colors.grey3};
      height: 18px;
      width: 18px;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: auto;
    }
  }
  .team-day {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    gap: 8px;
    height: 62px;
    border: 1px solid ${colors.grey3};
    border-radius: 16px;
    margin-bottom: 15px;

    .date {
      max-width: 45%;
    }
    .location {
      border: 1px solid ${colors.blue};
      background-color: rgba(0, 147, 195, 0.04);
      padding: 5px 16px;
      gap: 8px;
      border-radius: 24px;
      height: 32px;
      max-width: 50%;
      p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    svg {
      height: 16px;
      width: 16px;
      color: ${colors.grey1};
      cursor: pointer;
    }
  }
  .no-team-day {
    padding: 16px;
    background: ${colors.grey4};
    border-radius: 16px;
    text-align: center;
    p {
      color: ${colors.grey2};
    }
  }
`

interface PropsType {
  managerId: string
  headerHeight: number | undefined
  initialTeamDayDate?: UTCDate
}

export default function TeamDaysList({
  managerId,
  headerHeight,
  initialTeamDayDate,
}: PropsType) {
  const t = useTranslate()

  const [, setDialogTeamDayDetails] = useState(false)

  const { removeTeamDay } = useRemoveTeamDay()
  const { teamDays } = useTeamDays({
    managerId: managerId,
    filterPastTeamDays: true,
  })

  return (
    <List item xs={12} md={4} headerheight={headerHeight}>
      <div className="header">
        <H4Light>{t("team days")}</H4Light>
        <div className="number-of-team-day">
          <L12>{teamDays.length}</L12>
        </div>
        <NewTeamDayButton initialTeamDayDate={initialTeamDayDate} />
      </div>
      {teamDays.length >= 1 ? (
        teamDays.map((teamDay) => (
          <div key={teamDay.id}>
            <div
              className="team-day"
              onClick={() => {
                setDialogTeamDayDetails(true)
              }}
            >
              <P16 className="date">{teamDay.date.format("short", t)}</P16>
              <div className="location">
                <P16Bold>{teamDay.location.name}</P16Bold>
              </div>
              <DeleteButtonWithConfirmation
                onConfirm={() => removeTeamDay(teamDay.id)}
                dialogTitle={t("remove team day title")}
              >
                <P16>
                  {t("you will delete this team day", {
                    date: teamDay.date.format("short", t),
                  })}
                </P16>
              </DeleteButtonWithConfirmation>
            </div>
          </div>
        ))
      ) : (
        <div className="no-team-day">
          <P16>{t("No upcoming team day")}</P16>
        </div>
      )}
    </List>
  )
}
