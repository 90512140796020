import { ApolloError, gql, useMutation } from "@apollo/client"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useTranslate from "intl/useTranslate"
import { useState } from "react"
import { UserHybridRuleInput } from "types"

import { UserHybridRuleFragment } from "./fragments/hybridPolicyFragments"
import updateCache from "./updateCache"
import { COMPANY_HYBRID_RULES_QUERY } from "./useCompanyHybridRules"
import { DEFAULT_HYBRID_RULE_QUERY } from "./useDefaultHybridRule"

export const MUTATION = gql`
  ${UserHybridRuleFragment}
  mutation upsertUserHybridRule(
    $userId: ID
    $period: String!
    $maxSlots: Int!
    $restrictedDays: [Int]!
  ) {
    upsertUserHybridRule(
      userId: $userId
      period: $period
      maxSlots: $maxSlots
      restrictedDays: $restrictedDays
    ) {
      ...UserHybridRuleFragment
    }
  }
`

export default function useUpsertUserHybridRule() {
  const t = useTranslate()
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ApolloError | null>(null)
  const [mutate] = useMutation(MUTATION, {
    update: (cache, { data }) => {
      updateCache({
        cache,
        data,
        mutationName: "upsertUserHybridRule",
      })
    },
    refetchQueries: [COMPANY_HYBRID_RULES_QUERY, DEFAULT_HYBRID_RULE_QUERY],
  })
  const upsertUserHybridRule = async ({
    userId,
    period,
    maxDays,
    restrictedDays,
  }: UserHybridRuleInput) => {
    setLoading(true)
    try {
      await mutate({
        variables: {
          userId,
          period,
          maxSlots: 2 * maxDays,
          restrictedDays,
        },
      })
      addToast(t("hybrid-rule--upsert-success"), { appearance: "success" })
    } catch (error) {
      console.error(error)
      addToast(error.message, { appearance: "error" })
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    error,
    upsertUserHybridRule,
  }
}
