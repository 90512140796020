import useTranslate from "intl/useTranslate"
import React from "react"
import { IntegrationEvent } from "types"
import { Event } from "types"
import { LU14 } from "ui"
import { isSingleDayEvent } from "utils/events"

import { days } from "../../../utils/UTCDate"

export function OldEventDateTitle(event: Event) {
  const t = useTranslate()

  if (event && event.flags.wholeDayEvent) {
    if (event.from.format("short") === event.to.format("short")) {
      // whole day event, from === to
      return (
        <LU14>
          {`${t(days[event.from.getDay()])} ${event.from.format("short", t)}`}
        </LU14>
      )
    } else {
      // whole day event, from !== to
      return (
        <LU14>
          {`${t(days[event.from.getDay()]).substring(0, 3)} ${event.from.format(
            "short",
            t
          )}`}{" "}
          &mdash;{" "}
          {`${t(days[event.to.getDay()]).substring(0, 3)} ${event.to.format(
            "short",
            t
          )}`}
        </LU14>
      )
    }
  }

  if (event) {
    if (event.from.getTime() === event.to.getTime()) {
      // event with hours, from === to
      return (
        <LU14>
          {`${t(days[event.from.getDay()])} ${event.from.format(
            "short",
            t
          )} ${t("at")} ${event.to.format("HH:MM")}`}
        </LU14>
      )
    } else {
      // event with hours, from !== to
      return (
        <LU14>
          {t(days[event.from.getDay()]).substring(0, 3)}
          {". "}
          {event.from.getDate()}/
          {event.from.getMonth() < 9
            ? `0${event.from.getMonth() + 1}`
            : event.from.getMonth() + 1}{" "}
          {event.from.format("HH:MM")} &mdash;{" "}
          {t(days[event.to.getDay()]).substring(0, 3)}
          {". "}
          {event.to.getDate()}/
          {event.from.getMonth() < 9
            ? `0${event.from.getMonth() + 1}`
            : event.from.getMonth() + 1}{" "}
          {event.to.format("HH:MM")}
        </LU14>
      )
    }
  }
  return <></>
}

export default function EventDateTitle(event: IntegrationEvent) {
  const t = useTranslate()

  const oneDayEvent = isSingleDayEvent(event)

  if (event.isAllDay && oneDayEvent)
    return (
      <LU14>
        {`${t(days[event.from.getDay()])} ${event.from.format("short", t)}`}
      </LU14>
    )

  if (event.isAllDay)
    return (
      <LU14>
        {`${t(days[event.from.getDay()]).substring(0, 3)} ${event.from.format(
          "short",
          t
        )}`}{" "}
        &mdash;{" "}
        {`${t(days[event.to.getDay()]).substring(0, 3)} ${event.to.format(
          "short",
          t
        )}`}
      </LU14>
    )

  if (oneDayEvent) {
    return (
      <LU14>
        {`${t(days[event.from.getDay()])} ${event.from.format("short", t)} ${t(
          "at"
        )} ${event.from.format("HH:MM")}`}
      </LU14>
    )
  }

  return (
    <LU14>
      {t(days[event.from.getDay()]).substring(0, 3)}
      {". "}
      {event.from.getDate()}/
      {event.from.getMonth() < 9
        ? `0${event.from.getMonth() + 1}`
        : event.from.getMonth() + 1}{" "}
      {event.from.format("HH:MM")} &mdash;{" "}
      {t(days[event.to.getDay()]).substring(0, 3)}
      {". "}
      {event.to.getDate()}/
      {event.from.getMonth() < 9
        ? `0${event.from.getMonth() + 1}`
        : event.from.getMonth() + 1}{" "}
      {event.to.format("HH:MM")}
    </LU14>
  )
}
