import { ApolloError, gql, useQuery } from "@apollo/client"
import { EquipmentAvailability } from "types"
import UTCDate from "utils/UTCDate"

import { EquipmentAvailabilityFragment } from "./fragments/equipmentFragments"

const QUERY = gql`
  ${EquipmentAvailabilityFragment}
  query getEquipmentsAvailability($from: String!, $to: String!) {
    equipmentsAvailability(from: $from, to: $to) {
      ...EquipmentAvailabilityFragment
    }
  }
`

interface PropsType {
  from: UTCDate
  to: UTCDate
}

export default function useEquipmentsAvailability({ from, to }: PropsType): {
  loading: boolean
  error?: ApolloError
  equipmentsAvailability: EquipmentAvailability[]
} {
  const { loading, error, data } = useQuery<{
    equipmentsAvailability: EquipmentAvailability[]
  }>(QUERY, {
    variables: {
      from: `${from.getTime()}`,
      to: `${to.getTime()}`,
    },
    fetchPolicy: "cache-and-network",
  })

  return {
    equipmentsAvailability: data?.equipmentsAvailability || [],
    loading,
    error,
  }
}
