import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useUser from "graphql/users/useUser"
import useUserTree from "hooks/useUserTree"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { UTCSlot } from "types"
import { colors, transitions } from "ui"
import { Group } from "ui/icons"
import UserTree from "utils/UserTree"

import { GroupOrLocationHeader } from "../../GroupOrLocationHeader/GroupOrLocationHeader"

const CustomButton = styled.button`
  background: ${colors.grey4};
  border: solid 1px ${colors.grey3};
  border-radius: 100vh;
  padding: 2px 4px;
  color: ${colors.grey1};
  font-size: 12px;

  &:hover {
    border-color: ${colors.grey2}99;
    background: ${colors.grey4}44;
    color: ${colors.black}bb;
  }

  transition: ${transitions.fade};
`

function ShowHideTeamButton({ meAsManager }: { meAsManager: UserTree }) {
  const t = useTranslate()
  const { setVisibleTeams, visibleTeams } = usePlanningContext()
  const managersManagedByMe = UserTree.getUserTreeManagers([
    meAsManager,
  ]).filter(({ id }) => meAsManager.id !== id)

  const hideAll = visibleTeams.length === 0
  return (
    <CustomButton
      onClick={(e) => {
        e.stopPropagation()
        if (hideAll) setVisibleTeams(managersManagedByMe.map(({ id }) => id))
        else setVisibleTeams([])
      }}
    >
      {hideAll ? t("show all teams") : t("hide all teams")}
    </CustomButton>
  )
}

function ToggleTeamsVisibility() {
  const { user: me } = useUser()
  const { userTree } = useUserTree()
  const meAsUserTree = UserTree.findUserById(userTree, me?.id ?? "")

  if (!meAsUserTree || !meAsUserTree.isManager) return <></>
  return <ShowHideTeamButton meAsManager={meAsUserTree} />
}

interface PropsType {
  name: string
  id: string
  actions?: JSX.Element[]
  slots?: UTCSlot[]
}

export default function GroupOrMSTeamsPlanningHeader({
  name: propName,
  id,
  actions = [],
}: PropsType) {
  const getGroupPath = (id: string) => {
    if (id === "mymanagerteam") return undefined
    else if (id === "myteam") return "/myteam"
    else return `/group/${id}`
  }

  if (id === "myteam")
    return (
      <GroupOrLocationHeader
        path={getGroupPath(id)}
        icon={<Group />}
        itemId={id}
        type={"group"}
        actions={actions}
      >
        <span
          style={{
            display: "flex",
            gap: "16px",
            alignItems: "center",
          }}
        >
          {propName}
          <ToggleTeamsVisibility />
        </span>
      </GroupOrLocationHeader>
    )

  return (
    <GroupOrLocationHeader
      path={getGroupPath(id)}
      icon={<Group />}
      itemId={id}
      type={"group"}
      actions={actions}
    >
      {propName}
    </GroupOrLocationHeader>
  )
}
