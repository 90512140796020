import { gql, useMutation } from "@apollo/client"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useTranslate from "intl/useTranslate"

const MUTATION = gql`
  mutation nudgeEventUsers($eventId: ID!, $userId: ID) {
    nudgeEventUsers(eventId: $eventId, userId: $userId)
  }
`

export default function useNudgeEventUsers() {
  const { addToast } = useToasts()
  const [mutate] = useMutation(MUTATION)
  const t = useTranslate()

  return async (eventId: string, userId?: string) => {
    try {
      const res = await mutate({ variables: { eventId, userId } })
      const totalUsers = res?.data?.nudgeEventUsers?.length

      if (totalUsers !== undefined) {
        if (totalUsers === 1)
          addToast(
            t("event pending user nudge").replace("{number}", `${totalUsers}`),
            {
              appearance: "success",
            }
          )
        else
          addToast(
            t("event pending users nudge").replace("{number}", `${totalUsers}`),
            {
              appearance: "success",
            }
          )
      }
    } catch (e) {
      console.error(e.message)
      addToast(e.message, { appearance: "error" })
    }
  }
}
