import React from "react"
import styled from "styled-components"
import colors from "ui/colors"
import { Trash } from "ui/icons"

const Wrapper = styled.div`
  position: absolute;
  pointer-events: auto;
  padding: 6px;
  padding-bottom: 8px;
`

const Button = styled.button`
  background: white;
  border: 1px solid ${colors.grey2};
  border-radius: 4px;
  padding: 5px;
  min-height: 0;
  line-height: 0;
  height: auto;
  box-sizing: border-box;
  display: inline-flex;
`

export interface DeleteButtonProps {
  locationId: string
  left?: number
  right?: number
  top?: number
  bottom?: number
}

interface Props extends DeleteButtonProps {
  onDelete: (locationId: string) => void
}

export default function DeleteButton({
  locationId,
  left,
  right,
  top,
  bottom,
  onDelete,
}: Props) {
  return (
    <Wrapper
      style={{
        left,
        right,
        top,
        bottom,
      }}
    >
      <Button onClick={() => onDelete(locationId)}>
        <Trash htmlColor={colors.redAlert} fontSize={"12px" as any} />
      </Button>
    </Wrapper>
  )
}
