import { gql, useApolloClient } from "@apollo/client"
import { useCallback } from "react"
import UTCDate from "utils/UTCDate"

const QUERY = gql`
  query exportPlanning(
    $from: String!
    $to: String!
    $userIds: [String]
    $locationId: String
    $groupId: String
  ) {
    exportPlanning(
      from: $from
      to: $to
      userIds: $userIds
      locationId: $locationId
      groupId: $groupId
    )
  }
`

export default function useExportPlanning({
  from,
  to,
  userIds,
  locationId,
  groupId,
}: {
  from: UTCDate
  to: UTCDate
  userIds?: string[]
  locationId?: string
  groupId?: string
}): {
  getCSV: () => Promise<string>
} {
  const client = useApolloClient()

  const getCSV = useCallback(async () => {
    try {
      const { data } = await client.query({
        query: QUERY,
        variables: {
          from: `${from.getTime()}`,
          to: `${to.getTime()}`,
          userIds,
          locationId,
          groupId,
        },

        fetchPolicy: "no-cache",
      })
      if (data?.exportPlanning) {
        return data.exportPlanning
      }
      return
    } catch (error) {
      console.error(error)
      return
    }
  }, [client, from, to, userIds, locationId, groupId])

  return {
    getCSV,
  }
}
