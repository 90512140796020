import { gql } from "@apollo/client"

export const EquipmentFragment = gql`
  fragment EquipmentFragment on Equipment {
    id
    name
    icon
    capacity
    flags
    locationId
  }
`

export const UserEquipmentFragment = gql`
  fragment UserEquipmentFragment on UserEquipment {
    id
    equipmentId
    slotId
  }
`

export const EquipmentAvailabilityFragment = gql`
  fragment EquipmentAvailabilityFragment on EquipmentAvailability {
    id
    equipmentId
    available
  }
`
