export default function getLabelFromStatus(status: string | null) {
  if (status === null) return ""
  switch (status) {
    case "office":
      return "Office"
    case "home":
      return "Home"
    case "ooc":
      return "Out Of Contract"
    case "else":
      return "Else"
    case "off":
      return "Off"
    default:
      return "Not set"
  }
}
