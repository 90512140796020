import { gql, useMutation } from "@apollo/client"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useTranslate from "intl/useTranslate"

import { CompanyFileFragment } from "./fragments/companyFileFragments"
import updateCache from "./updateCache"

const MUTATION = gql`
  ${CompanyFileFragment}
  mutation deleteCompanyFile($id: ID!) {
    deleteCompanyFile(id: $id) {
      ...CompanyFileFragment
    }
  }
`

export default function useDeleteCompanyFile() {
  const t = useTranslate()
  const { addToast } = useToasts()

  const [mutate] = useMutation(MUTATION)

  return async (fileId: string) => {
    try {
      await mutate({
        variables: { id: fileId },
        update: async (cache, { data }) => {
          updateCache({
            cache,
            data,
            mutationName: "deleteCompanyFile",
            remove: true,
          })
        },
      })
      addToast(t("company_file--deleted"), {
        appearance: "success",
      })
    } catch (error) {
      console.error(error.message)
      addToast(error.message, { appearance: "error" })
    }
  }
}
