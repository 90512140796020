import useLocationsTree from "graphql/locations/useLocationsTree"
import useTranslate from "intl/useTranslate"
import DownloadCsvButton from "pages/StatisticsPage/components/DownloadCsvButton"
import React, { useCallback, useState } from "react"
import { Line } from "react-chartjs-2"
import { colors } from "ui"
import LocationTree from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

import getCsvLocations from "../utils/getCsvLocations"
import AllLocationsOccupancyRateOverTimeDataset from "./AllLocationsOccupancyRateOverTimeDataset/AllLocationsOccupancyRateOverTimeDataset"
import OccupancyRateOverTimeDataset from "./OccupancyRateOverTimeDataset/OccupancyRateOverTimeDataset"

interface PropsType {
  selectedLocations: LocationTree[]
  allLocationsFlatten: LocationTree[]
  from: UTCDate
  to: UTCDate
  includeWeekends: boolean
  showAllLocationsBar: boolean
}

export default function OccupancyRateOverTimeChart({
  selectedLocations,
  allLocationsFlatten,
  from,
  to,
  includeWeekends,
  showAllLocationsBar,
}: PropsType) {
  const t = useTranslate()

  const computeDays = (
    from: UTCDate,
    to: UTCDate,
    includeWeekends: boolean,
    step: "HALF-DAY" | "DAY" = "HALF-DAY"
  ) => {
    const days: UTCDate[] = []
    const cursor = new UTCDate(from.getTime())
    while (cursor.getTime() <= to.getTime()) {
      days.push(new UTCDate(cursor.getTime()))
      cursor.shift(step, 1)
    }
    if (step === "HALF-DAY") days.push(new UTCDate(to.getTime(), step))

    if (!includeWeekends)
      return days.filter((d) => d.getDay() !== 0 && d.getDay() !== 6)
    return days
  }

  const [occupancies, setOccupancies] = useState<
    {
      location: LocationTree
      occupancyRateOverTime: {
        date: string
        value: number
        booked: number
        total: number
      }[]
    }[]
  >([])

  const [allLocationsOccupancyOverTime, setAllLocationsOccupancyOverTime] =
    useState<{ date: string; value: number; booked: number; total: number }[]>(
      []
    )

  const { locations: locationsTree } = useLocationsTree()

  const getCsv = useCallback(() => {
    const headers = ["location_name", "date", "value", "booked", "total"].join(
      ","
    )
    const rows: string[] = []
    if (showAllLocationsBar) {
      for (const o of allLocationsOccupancyOverTime) {
        const date = new UTCDate(Number(o.date)).format("DD/MM/YY HH:MM")
        rows.push(
          [
            t("All locations"),
            date,
            Number(o.value),
            Number(o.booked),
            Number(o.total),
          ].join(",")
        )
      }
    }
    for (const l of getCsvLocations({
      showAllLocationsBar,
      allLocationsFlatten,
      selectedLocations,
      locationsTree,
    })) {
      const locactionOccupancy = occupancies.find((o) => o.location.id === l.id)
      if (locactionOccupancy !== undefined) {
        for (const o of locactionOccupancy.occupancyRateOverTime) {
          const date = new UTCDate(Number(o.date)).format("DD/MM/YY HH:MM")

          rows.push(
            [
              l.name,
              date,
              Number(o.value),
              Number(o.booked),
              Number(o.total),
            ].join(",")
          )
        }
      }
    }
    return Promise.resolve(`${headers}\n${rows.join("\n")}`)
  }, [
    occupancies,
    allLocationsOccupancyOverTime,
    showAllLocationsBar,
    selectedLocations,
    allLocationsFlatten,
    locationsTree,
    t,
  ])

  const labels = computeDays(from, to, includeWeekends).map((d) =>
    d.format("DD/MM")
  )

  const getDatasets = () => {
    const datasets = occupancies.map((o, n) => {
      const data = o.occupancyRateOverTime
        .slice()
        .sort((a, b) => +a.date - +b.date)
        .map((o) => 100 * o.value)
      const color = `hsl(${(164 + n * 20) % 255}, 100%, 40%)`
      return {
        label: o.location.name,
        data,
        fill: false,
        borderColor: color,
        backgroundColor: color,
        tension: 0.3,
      }
    })
    if (showAllLocationsBar && allLocationsOccupancyOverTime.length > 0) {
      const color = colors.purple

      datasets.push({
        label: t("All locations"),
        data: allLocationsOccupancyOverTime.map((o) => 100 * o.value),
        fill: false,
        borderColor: color,
        backgroundColor: color,
        tension: 0.3,
      })
    }

    return datasets
  }

  const getTooltipLabel = (tooltipItem: {
    index: number
    datasetIndex: number
    xLabel: string
    yLabel: number
  }) => {
    const dataset = getDatasets()[tooltipItem.datasetIndex]
    if (dataset !== undefined) {
      // All locations has a special label
      if (dataset.label === t("All locations")) {
        const allLocationsOccupancyOfTheDay2 =
          allLocationsOccupancyOverTime.filter(
            (o) =>
              tooltipItem.xLabel === new UTCDate(Number(o.date)).format("DD/MM")
          )
        if (allLocationsOccupancyOfTheDay2.length === 2) {
          const isMorning = tooltipItem.index % 2 === 0
          const dayIndex = isMorning ? 0 : 1
          const allLocationsOccupancyOfTheDay =
            allLocationsOccupancyOfTheDay2[dayIndex]

          if (allLocationsOccupancyOfTheDay !== undefined) {
            return `${allLocationsOccupancyOfTheDay.booked}/${
              allLocationsOccupancyOfTheDay.total
            } ${t("booked seats")} | ${tooltipItem.yLabel.toFixed(2)}%`
          }
        }
      }

      // Normal location
      const locationOccupancies = occupancies.find(
        (o) => o.location.name === dataset.label
      )
      if (locationOccupancies !== undefined) {
        const locationOccupancyOfTheDay2 =
          locationOccupancies.occupancyRateOverTime.filter(
            (o) =>
              tooltipItem.xLabel === new UTCDate(Number(o.date)).format("DD/MM")
          )
        if (locationOccupancyOfTheDay2.length === 2) {
          const isMorning = tooltipItem.index % 2 === 0
          const dayIndex = isMorning ? 0 : 1
          const locationOccupancyOfTheDay = locationOccupancyOfTheDay2[dayIndex]
          if (locationOccupancyOfTheDay !== undefined) {
            return `${locationOccupancyOfTheDay.booked}/${
              locationOccupancyOfTheDay.total
            } ${t("booked seats")} | ${tooltipItem.yLabel.toFixed(2)}%`
          }
        }
      }
    }

    // Fall back
    return `${tooltipItem.yLabel.toFixed(2)}%`
  }

  const data = {
    labels,
    datasets: getDatasets(),
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    stacked: true,
    min: 0,
    // max: 100,
    scales: {
      xAxes: [
        {
          ticks: {
            display: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: true,
          },

          ticks: {
            callback: function (value: number) {
              return Math.floor(value) + "%"
            },
            stepSize: 10,
            // suggestedMax: 100,
            beginAtZero: true,
          },
        },
      ],
    },
    tooltips: {
      mode: "nearest",
      callbacks: {
        label: getTooltipLabel,
      },
    },
    legend: {
      labels: {
        usePointStyle: true,
        boxWidth: 7,
      },
    },
  }

  const updateDatasets = (
    location: LocationTree,
    occupancyRateOverTime: {
      date: string
      value: number
      booked: number
      total: number
    }[],
    remove = false
  ) => {
    const occupancy = remove ? [] : [{ location, occupancyRateOverTime }]
    setOccupancies((prev) => [
      ...prev.filter((o) => o.location.id !== location.id),
      ...occupancy,
    ])
  }

  return (
    <>
      <DownloadCsvButton
        className="btn--margin-top"
        fileName="occupancy-rate-over-time.csv"
        getCsv={getCsv}
      />
      {selectedLocations.map((l) => (
        <OccupancyRateOverTimeDataset
          key={`occupancy-bar-dt-${l.id}`}
          location={l}
          from={from}
          to={to}
          includeWeekends={includeWeekends}
          occupancies={occupancies}
          updateDatasets={updateDatasets}
        />
      ))}
      {showAllLocationsBar && (
        <AllLocationsOccupancyRateOverTimeDataset
          locations={allLocationsFlatten}
          from={from}
          to={to}
          includeWeekends={includeWeekends}
          allLocationsOccupancyOverTime={allLocationsOccupancyOverTime}
          setAllLocationsOccupancyOverTime={setAllLocationsOccupancyOverTime}
        />
      )}
      <Line data={data} options={options} />
    </>
  )
}
