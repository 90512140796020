import PageHeader from "components/Layout/PageLayout/PageHeader/PageHeader"
import MobileNavBack from "components/Layout/PageLayout/PageHeader/PageTitle/MobileNavBack/MobileNavBack"
import PageTitle from "components/Layout/PageLayout/PageHeader/PageTitle/PageTitle"
import useUser from "graphql/users/useUser"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { IntegrationEvent } from "types"
import {
  Button,
  colors,
  Dialog,
  H4,
  L12,
  L12GreyBold,
  LU14,
  mediaQueries,
  P16Bold,
} from "ui"
import { Edit, User as UserIcon } from "ui/icons"
import { getUserAsAttendee, isPastEvent } from "utils/events"
import { days } from "utils/LocalDate"

import EventDateTitle from "../EventDateTitle/EventDateTitle"
import AttendingUsersListButton from "./AttendingUsersListButton/AttendingUsersListButton"
import {
  AttendingAndShareButtons,
  NotAttendingButton,
  ParticipationButton,
  ShareButton,
} from "./components/Buttons"

interface BodyProps {
  isEditable: boolean
}

const DialogBody = styled.div<BodyProps>`
  display: flex;
  flex-direction: column;
  padding: 24px;

  @media ${mediaQueries.isMobile} {
    padding: 16px;
  }

  .content {
    &-dates {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 16px;
      @media ${mediaQueries.isMobile} {
        flex-direction: column;
      }

      p {
        color: ${colors.purple};
        text-transform: uppercase;
      }
    }
    &-title {
      ${({ isEditable }) => {
        if (!isEditable) return `margin-top: 16px;`
      }}
      @media ${mediaQueries.isMobile} {
        margin-top: 8px;
      }
    }
    &-creator {
      margin: 4px 0px 16px 0px;
      color: ${colors.grey1};
    }
    &-details {
      margin-bottom: 4px;
      color: ${colors.grey1};
    }
    &-body p {
      color: ${colors.black};
      margin-bottom: 16px;
      font-size: 14px;
    }
    &-infos {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media ${mediaQueries.isMobile} {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    &-booking-info {
      color: ${colors.grey1};
    }
    &-deadline-info {
      color: ${colors.redAlert};
    }
    &-deadline-info-green {
      color: ${colors.green};
    }
    &-column {
      display: flex;
      flex-direction: column;
    }
  }
`

interface ImageWrapperProps {
  hasMedia: boolean
}

const ImageWrapper = styled.div<ImageWrapperProps>`
  height: 180px;
  contain: content;

  img,
  svg {
    width: 100%;
    ${({ hasMedia }) => {
      if (hasMedia)
        return `
        height: 180px;
      object-fit: cover;
      `
    }}
  }

  .participation-info {
    max-height: 121px;
    background-color: ${colors.white};
    display: flex;
    flex-direction: column;
    width: 260px;
    border-radius: 16px;
    position: fixed;
    bottom: 16px;
    right: 24px;
    padding: 16px;
    gap: 17px;

    .participation-location {
      display: flex;
      align-items: center;
      padding: 0 0 0 3px;

      p {
        height: 32px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      svg {
        height: 18px;
        width: 18px;
        color: ${colors.grey1};
        margin: 0px 8px auto 0px;
        stroke-width: 2.5px;
      }
    }

    .participation-stats {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      svg {
        height: 18px;
        width: 18px;
        color: ${colors.grey1};
        margin-bottom: auto;
        stroke-width: 2px;
      }

      &-column {
        display: flex;
        flex-direction: column;
      }

      &-subtitle {
        color: ${colors.grey1};
      }
    }
  }
`

interface PropsType {
  open: boolean
  event: IntegrationEvent
  isEditable: boolean
  onClose: () => void
  openEventToBeEditable: (event: IntegrationEvent) => void
}

export default function EventDialog({
  open,
  event,
  isEditable,
  onClose,
  openEventToBeEditable,
}: PropsType) {
  const { isMobile } = useMediaQueries()
  const hasMedia = false
  const { user: me } = useUser()
  const meAsGuest = getUserAsAttendee(event.attendees, me?.email)
  const alreadyResponded =
    meAsGuest?.status === "attending" || meAsGuest?.status === "not-attending"
  const t = useTranslate()

  const [dialogOpen, setDialogOpen] = useState(false)

  const from = `${t(days[event.from.getDay()])} ${event.from.format(
    "short",
    t
  )} ${event.isAllDay ? "" : event.from.format("HH:MM")}`
  const to = `${t(days[event.to.getDay()])} ${event.to.format("short", t)} ${
    event.isAllDay ? "" : event.to.format("HH:MM")
  }`

  const deadline = undefined // TODO rsvp deadline

  const openSeats = undefined // TODO event capacity ?

  const attendingUsers = event.attendees.filter((u) => u.status === "attending")
  const pendingUsers = event.attendees.filter((u) => u.status === "pending")
  const notAttendingUsers = event.attendees.filter(
    (u) => u.status === "not-attending"
  )

  return (
    <Dialog
      open={open}
      onClose={onClose}
      leftButton={
        isMobile ? (
          <ParticipationButton event={event} onClick={onClose} />
        ) : (
          <NotAttendingButton event={event} onClick={onClose} />
        )
      }
      rightButton={
        isMobile ? (
          <ShareButton event={event} onClick={onClose} />
        ) : (
          <AttendingAndShareButtons event={event} onClick={onClose} />
        )
      }
      fullscreen={isMobile}
    >
      {isMobile && (
        <PageHeader>
          <MobileNavBack onClose={onClose} />
          <PageTitle title={t("details")} />
        </PageHeader>
      )}
      <ImageWrapper hasMedia={hasMedia}>
        <img src="/static/img/default_event.jpeg" />
        <div className="participation-info">
          <div
            className="participation-stats"
            onClick={() => setDialogOpen(true)}
          >
            <UserIcon />
            <div className="participation-stats-column">
              <P16Bold>{attendingUsers.length}</P16Bold>
              <L12 className="participation-stats-subtitle">
                {t("attending")}
              </L12>
            </div>
            <div className="participation-stats-column">
              <P16Bold>{pendingUsers.length}</P16Bold>
              <L12 className="participation-stats-subtitle">{t("pending")}</L12>
            </div>
            <div className="participation-stats-column">
              <P16Bold>{notAttendingUsers.length}</P16Bold>
              <L12 className="participation-stats-subtitle">
                {t("not attending")}
              </L12>
            </div>
          </div>
        </div>
      </ImageWrapper>

      <DialogBody isEditable={isEditable}>
        <div className="content-dates">
          {event.from.format("short", t) === event.to.format("short", t) ? (
            <EventDateTitle {...event} />
          ) : (
            <div className="content-column">
              <LU14>
                {from} &mdash; {to}
              </LU14>
            </div>
          )}

          {isEditable && !isMobile && (
            <Button
              secondary
              icon={<Edit />}
              onClick={() => {
                onClose()
                openEventToBeEditable(event)
              }}
            >
              {t("Edit")}
            </Button>
          )}
        </div>
        <H4 className="content-title">{event.name}</H4>

        {event.organizer?.email && (
          <L12 className="content-creator">
            {t("created by")} {event.organizer.email}
          </L12>
        )}

        {/* TODO event description */}
        {/* {event.description && (
          <P14Bold className="content-details">{t("details")}</P14Bold>
        )} */}
        <div className="content-body">
          {/* Display event description */}
          {/* {event.description && <ViewMore text={event.description} />} */}
        </div>
        {!isPastEvent(event) && (
          <div className="content-infos">
            {openSeats && (
              <L12 className="content-booking-info">
                {openSeats} {t("remaining places")}
              </L12>
            )}
            {!alreadyResponded && deadline && (
              <L12 className="content-deadline-info">
                {t("event deadline message", { date: deadline })}
              </L12>
            )}

            {meAsGuest && meAsGuest.status === "attending" && (
              <L12GreyBold className="content-deadline-info-green">
                {t("long attending event")}
              </L12GreyBold>
            )}

            {meAsGuest && meAsGuest.status === "not-attending" && (
              <L12 className="content-deadline-info">
                {t("long not attending event")}
              </L12>
            )}
          </div>
        )}
        <AttendingUsersListButton
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          event={event}
        />
      </DialogBody>
    </Dialog>
  )
}
