import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import { Button } from "ui"

import UpdateEquipmentDialog from "./UpdateEquipmentDialog/UpdateEquipmentDialog"

interface PropsType {
  onEquipmentAdded: (service: any) => void
}

export default function AddEquipmentButton({ onEquipmentAdded }: PropsType) {
  const t = useTranslate()

  const [isDialogOpen, setIsDialogOpen] = useState(false)

  return (
    <>
      <Button onClick={() => setIsDialogOpen(true)}>
        {t("Add a service")}
      </Button>
      <UpdateEquipmentDialog
        open={isDialogOpen}
        onEquipmentAdded={onEquipmentAdded}
        onClose={() => {
          setIsDialogOpen(false)
        }}
      />
    </>
  )
}
