import useBookingMaxDate from "hooks/useBookingMaxDate"
import useSlotsEditorDisabledStatus from "hooks/useSlotsEditorDisabledStatus"
import useTranslate from "intl/useTranslate"
import React from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { SlotOccupationForLocation, User, WorkingMode } from "types"
import { Button } from "ui"
import LocationTree from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

import StatusTitle from "../WorkingModeButtons/StatusTitle/StatusTitle"
import SlotsEditorLocationsListRouter from "./SlotsEditorLocationsListRouter/SlotsEditorLocationsListRouter"
import WarningCantFill from "./WarningCantFill/WarningCantFill"

const RedirectionButton = styled(Button)`
  margin: 10px;
`

interface PropsType {
  from: UTCDate
  to: UTCDate
  showRedirectToAdminButton: boolean
  selectedUser: User
  guestIds: string[]
  occupations: SlotOccupationForLocation[]
  floorPlan:
    | {
        selectedFloorPlan?: LocationTree | undefined
        selectedVisualArea?: LocationTree | undefined
        editing: boolean
      }
    | undefined
  setFloorPlan: React.Dispatch<
    React.SetStateAction<
      | {
          selectedFloorPlan?: LocationTree | undefined
          selectedVisualArea?: LocationTree | undefined
          editing: boolean
        }
      | undefined
    >
  >
  saveWorkingMode: (workingMode: WorkingMode) => void | Promise<void>
}

export default function OfficeStatusPicker({
  from,
  to,
  showRedirectToAdminButton,
  floorPlan,
  selectedUser,
  guestIds,
  occupations,
  setFloorPlan,
  saveWorkingMode,
}: PropsType) {
  const t = useTranslate()
  const history = useHistory()
  const goToAdminSpaces = () => history.push("/admin/locations")

  const disabledStatus = useSlotsEditorDisabledStatus(to)
  const officesNotBookable = disabledStatus.includes("office")
  const lastBookableSunday = new UTCDate(useBookingMaxDate())
  lastBookableSunday.shift("DAY", -1)

  const statusTitleError =
    from.getTime() <= lastBookableSunday.getTime() && officesNotBookable

  return (
    <>
      {!floorPlan?.editing && (
        <StatusTitle
          status="office"
          error={
            statusTitleError
              ? t("desk-booking-recurrence-warning", {
                  date: lastBookableSunday.format("DD/MM/YY"),
                })
              : undefined
          }
          from={from}
          to={to}
        >
          {t("at work")}
        </StatusTitle>
      )}
      {showRedirectToAdminButton && (
        <RedirectionButton onClick={() => goToAdminSpaces()}>
          {t("Add a working space")}
        </RedirectionButton>
      )}
      {officesNotBookable ? (
        <WarningCantFill to={to} />
      ) : (
        <SlotsEditorLocationsListRouter
          from={from}
          to={to}
          guestIds={guestIds}
          floorPlan={floorPlan}
          setFloorPlan={setFloorPlan}
          selectedUser={selectedUser}
          saveWorkingMode={saveWorkingMode}
          occupations={occupations}
        />
      )}
    </>
  )
}
