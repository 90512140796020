import Loader from "components/Loader/Loader"
import useCheckCompanyIntegration from "graphql/integrations/useCheckCompanyIntegration"
import useTranslate from "intl/useTranslate"
import React from "react"
import { CompanyIntegration } from "types"
import { L12, P14Bold } from "ui"
import { Microsoft } from "ui/icons"
import getUrlSearchParam from "utils/getUrlSearchParam"

import WriteSlotsEventsToggle from "../WriteSlotsEventsToggle"

interface PropsType {
  integration?: CompanyIntegration
}

export default function OutlookUserIntegration({ integration }: PropsType) {
  const t = useTranslate()

  const shouldSkip =
    integration?.name === undefined || getUrlSearchParam("code") !== null
  const { result, loading: checkLoading } = useCheckCompanyIntegration(
    integration?.name,
    shouldSkip
  )

  const error = result !== "success"

  return (
    <li className="item">
      <div className="top-part">
        <Microsoft />
        <P14Bold className="title">{t("Outlook Calendar")}</P14Bold>

        <div className="status">
          <P14Bold color={Boolean(!checkLoading && !error) ? "green" : "grey3"}>
            {t("Subscribed")}
          </P14Bold>
        </div>
      </div>
      {(!integration || error) && (
        <L12 className="description">
          {t("outlook improve offishall experience")}
        </L12>
      )}
      {integration && !error && (
        <div className="bottom-part">
          <WriteSlotsEventsToggle integration={integration} />
        </div>
      )}

      {checkLoading && <Loader />}
    </li>
  )
}
