import { LinearProgress } from "@mui/material"
import IntegrationLayout from "components/IntegrationsLayout/IntegrationLayout"
import useRevokeUserIntegration from "graphql/integrations/useRevokeUserIntegration"
import useToken from "hooks/useToken"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { UserIntegration } from "types"
import { Button, P16 } from "ui"
import { Slack } from "ui/icons"

import { ButtonText } from "./components/IntegrationButtonText"

const TextContainer = styled.div`
  margin-left: 12px;
  margin-bottom: 18px;
`

const ButtonsContainer = styled.div`
  margin-left: 12px;
  margin-bottom: 18px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 12px;
`

interface PropsType {
  integration?: UserIntegration
  loading: boolean
}

export default function SlackIntegrationComponent({
  integration,
  loading,
}: PropsType) {
  const t = useTranslate()
  const token = useToken()
  const revoke = useRevokeUserIntegration()
  if (!token) return null
  return (
    <IntegrationLayout connected={false} title="Slack">
      {!integration && (
        <TextContainer>
          <P16>{t("slack improve offishall experience")}</P16>
        </TextContainer>
      )}
      {integration && (
        <TextContainer>
          <P16>{t("link offishall slack")}</P16>
        </TextContainer>
      )}
      <ButtonsContainer>
        <Button
          secondary
          icon={<Slack />}
          onClick={() => {
            location.href = `${process.env.REACT_APP_API}/slack/install?token=${token}`
          }}
        >
          <ButtonText>
            {integration
              ? t("refresh access")
              : t("authorize offishall access slack account")}
          </ButtonText>
        </Button>

        {integration && (
          <Button
            secondary
            icon={<Slack />}
            onClick={() => {
              revoke({ name: integration.name })
            }}
          >
            <ButtonText>{t("revoke access")}</ButtonText>
          </Button>
        )}
      </ButtonsContainer>
      {loading && <LinearProgress />}
    </IntegrationLayout>
  )
}
