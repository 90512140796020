import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function Warning({
  stroke,
  strokeWidth,
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      strokeWidth={strokeWidth ?? 1}
      stroke={stroke ? stroke : "currentColor"}
      {...rest}
    >
      <path d="M2.22326 18.0031L2.87344 18.377L2.87344 18.377L2.22326 18.0031ZM10.2662 4.01533L10.9164 4.38918L10.9164 4.38918L10.2662 4.01533ZM13.7338 4.01532L13.0836 4.38917L13.0836 4.38917L13.7338 4.01532ZM21.7768 18.0031L22.427 17.6293L22.427 17.6292L21.7768 18.0031ZM12.75 9C12.75 8.58579 12.4142 8.25 12 8.25C11.5858 8.25 11.25 8.58579 11.25 9H12.75ZM11.25 13C11.25 13.4142 11.5858 13.75 12 13.75C12.4142 13.75 12.75 13.4142 12.75 13H11.25ZM11.4428 16.508C11.1655 16.8158 11.1903 17.29 11.498 17.5672C11.8058 17.8445 12.28 17.8197 12.5573 17.512L11.4428 16.508ZM12.5673 17.5009C12.8445 17.1931 12.8198 16.7189 12.512 16.4417C12.2043 16.1644 11.73 16.1892 11.4528 16.4969L12.5673 17.5009ZM20.0429 20.25H3.95707V21.75H20.0429V20.25ZM3.95707 20.25C2.99579 20.25 2.39429 19.2102 2.87344 18.377L1.57308 17.6292C0.518911 19.4626 1.84229 21.75 3.95707 21.75V20.25ZM2.87344 18.377L10.9164 4.38918L9.61604 3.64148L1.57308 17.6292L2.87344 18.377ZM10.9164 4.38918C11.397 3.5533 12.603 3.5533 13.0836 4.38917L14.384 3.64147C13.3266 1.80252 10.6734 1.80254 9.61604 3.64148L10.9164 4.38918ZM13.0836 4.38917L21.1266 18.377L22.427 17.6292L14.384 3.64147L13.0836 4.38917ZM21.1266 18.3769C21.6057 19.2102 21.0043 20.25 20.0429 20.25V21.75C22.1578 21.75 23.4811 19.4626 22.427 17.6293L21.1266 18.3769ZM11.25 9V13H12.75V9H11.25ZM12.5573 17.512L12.5673 17.5009L11.4528 16.4969L11.4428 16.508L12.5573 17.512Z" />
    </SvgIcon>
  )
}
