import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useAddBulkSlotRule from "graphql/bulkSlots/useAddBulkSlotRule"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { BulkSlotRuleInput } from "types"
import { AdminPopup, Button, TextField } from "ui"
import UTCDate from "utils/UTCDate"

import DatePicker from "./DatePicker/DatePicker"
import StatusList from "./StatusList/StatusList"
import TargetPicker from "./TargetPicker/TargetPicker"

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding-bottom: 60px;

  .target {
    display: flex;
    flex-direction: column;
  }

  .big-toggle-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: fit-content;
  }

  .toggle-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .textfields-container {
    display: flex;
    flex-direction: column;
    .last-date-picker {
      display: flex;
      flex-direction: column;
      margin-top: 8px;
    }
  }

  .status-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;

    .working-mode-btn {
      margin-bottom: 12px;
    }
  }
`

const initialRule = {
  title: "",
  from: new UTCDate(),
  to: null,
  status: "",
  label: "",
  locationId: null,
  who: {
    everyone: false,
    groups: [],
    teams: [],
  },
  notify: false,
}

interface PropsType {
  open: boolean
  onClose: () => void
}

export default function NewRuleDialog({ open, onClose }: PropsType) {
  const t = useTranslate()
  const { addToast } = useToasts()
  const addRule = useAddBulkSlotRule()

  const [rule, setRule] = useState<BulkSlotRuleInput>(initialRule)

  const checkRuleValidity = () => {
    return (
      rule.from !== undefined &&
      rule.status !== "" &&
      (rule.who.everyone ||
        rule.who.groups.length > 0 ||
        rule.who.teams.length > 0)
    )
  }

  const isRuleValid = checkRuleValidity()

  const resetForm = () => {
    setRule(initialRule)
    onClose()
  }

  const submitForm = async () => {
    if (isRuleValid) {
      const { from, to, status, who, title, label, locationId } = rule
      await addRule(from, to, status, label, locationId, title, {
        everyone: who.everyone,
        groups: who.groups.map((g) => g.id),
        teams: who.teams.map((t) => t.id),
      }).then(() =>
        addToast(t("rule has been created"), { appearance: "success" })
      )
    } else {
    }
    resetForm()
  }

  return (
    <AdminPopup
      open={open}
      onClose={() => {
        resetForm()
        onClose()
      }}
      title={t("new rule")}
      leftButton={
        <Button
          secondary
          onClick={() => {
            onClose()
            resetForm()
          }}
        >
          {t("Cancel")}
        </Button>
      }
      rightButton={
        <Button disabled={!isRuleValid} onClick={submitForm}>
          {t("Save")}
        </Button>
      }
    >
      <Layout>
        <TextField
          label={t("day title")}
          initialValue={rule.title}
          placeholder={t("bulk change status example")}
          handleChange={(e) => {
            setRule((prev) => ({
              ...prev,
              title: e,
            }))
          }}
        />
        <DatePicker rule={rule} setRule={setRule} />
        <TargetPicker rule={rule} setRule={setRule} />
        <StatusList rule={rule} setRule={setRule} />
      </Layout>
    </AdminPopup>
  )
}
