import { gql, useMutation } from "@apollo/client"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import { USER_HYBRID_WORK_RULE_QUERY } from "graphql/hybridPolicy/useUserHybridRule"
import { QUERY as LOCATIONS_AT_QUERY } from "graphql/locations/useLocationsAt"
import { CustomErrorFragment } from "graphql/misc/customErrorFragment"
import { QUERY as USERS_AT_QUERY } from "graphql/users/useUsersAt"
import useCustomErrorToast from "hooks/useCustomErrorToast"
import { CustomError } from "types"
import UTCDate from "utils/UTCDate"

import { SlotFragment } from "./fragments/slotFragment"

const SET_SLOTS_MUTATION = gql`
  ${SlotFragment}
  ${CustomErrorFragment}
  mutation setSlots(
    $timestamps: [String]!
    $value: String!
    $locationId: ID
    $equipmentIds: [ID!]
    $repeatEveryWeek: Boolean
    $until: String
    $userId: ID
    $label: String
    $guestsEmails: [String]
    $inviteGuestsMessage: String
  ) {
    setSlots(
      value: $value
      timestamps: $timestamps
      locationId: $locationId
      equipmentIds: $equipmentIds
      repeatEveryWeek: $repeatEveryWeek
      until: $until
      userId: $userId
      label: $label
      guestsEmails: $guestsEmails
      inviteGuestsMessage: $inviteGuestsMessage
    ) {
      slots {
        ...SlotFragment
      }
      errors {
        ...CustomErrorFragment
      }
    }
  }
`

export default function useSetSlotsUTC(userId?: string) {
  const {
    usersAtQuery: { from, to, userIds, locationIds, groupIds },
  } = usePlanningContext()

  const refetchVariables = {
    from: `${(from || new UTCDate()).getTime()}`,
    to: `${(to || new UTCDate()).getTime()}`,
    userIds: userIds && userIds.length > 0 ? userIds : undefined,
    locationIds,
    groupIds,
  }

  const { addToastError } = useCustomErrorToast()
  const [setSlots] = useMutation<{
    setSlots: {
      slots: any
      errors: CustomError[]
    }
  }>(SET_SLOTS_MUTATION, {
    update(cache, { data }) {
      if (data?.setSlots) {
        const { setSlots } = data
        const { slots: slotsFromQuery, errors } = setSlots
        cache.modify({
          fields: {
            slots(existing = []) {
              const slots = existing.filter(
                (es: any) =>
                  es.__ref !==
                  `${slotsFromQuery.__typename}:${slotsFromQuery.id}`
              )
              const newRefs = slotsFromQuery.map((slot: any) =>
                cache.writeFragment({
                  data: slot,
                  fragment: SlotFragment,
                })
              )
              return [...slots, ...newRefs]
            },
          },
        })
        if (errors.length > 0) {
          addToastError(errors[0])
        }
      }
    },
    refetchQueries: [
      "userEquipments",
      {
        query: USERS_AT_QUERY,
        fetchPolicy: "network-only",
        variables: refetchVariables,
      },
      {
        query: LOCATIONS_AT_QUERY,
        fetchPolicy: "network-only",
        variables: refetchVariables,
      },
      {
        query: USER_HYBRID_WORK_RULE_QUERY,
        variables: {
          userId: userId ?? "",
          from: from ? `${from.getTime()}` : undefined,
          to: to ? `${to.getTime()}` : undefined,
        },
      },
    ],
  })
  return (
    timestamps: number[],
    value: string,
    locationId: string | null,
    repeatEveryWeek: boolean,
    until: UTCDate | null,
    label: string | null,
    guestsEmails: string[],
    inviteGuestsMessage: string,
    equipmentIds?: string[]
  ) => {
    return setSlots({
      variables: {
        timestamps: timestamps.map((t) => `${t}`),
        userId,
        value,
        locationId,
        equipmentIds,
        repeatEveryWeek,
        guestsEmails,
        inviteGuestsMessage,
        until: until ? `${until.getTime()}` : null,
        label,
      },
    }).catch(({ message }) => {
      addToastError({ message })
    })
  }
}
