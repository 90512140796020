import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function Plus({ stroke, strokeWidth, ...rest }: SvgIconProps) {
  return (
    <SvgIcon
      stroke={stroke ? stroke : "currentColor"}
      strokeWidth={strokeWidth ? strokeWidth : 1.5}
      {...rest}
    >
      <path
        d="M12 12V18M6 12H12H6ZM18 12H12H18ZM12 12V6V12Z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}
