import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function NavArrowRight({
  strokeWidth,
  stroke,
  ...props
}: SvgIconProps) {
  return (
    <SvgIcon
      strokeWidth={strokeWidth ? strokeWidth : 2}
      stroke={stroke ? stroke : "currentColor"}
      {...props}
    >
      <path
        d="M9 6L15 12L9 18"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}
