import { ActiveLocationChipButtonWithBreadCrumbs } from "components/LocationComponents/Buttons/ActiveLocationChipButtonWithBreadCrumbs/ActiveLocationChipButtonWithBreadCrumbs"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useMediaQueries from "hooks/useMediaQueries"
import React, { useState } from "react"
import styled from "styled-components"
import { colors, mediaQueries, PopperMenu } from "ui"
import { Cancel } from "ui/icons"
import LocationTree from "utils/LocationTree"

const Layout = styled.div`
  padding: 8px;
  cursor: pointer;
  max-width: 75vw; // temporary solution waiting for UX team

  @media ${mediaQueries.isMobile} {
    max-width: 100vw;
  }
`

const CloseButton = styled(Cancel)`
  background: ${colors.black}00;
  border-radius: 4px;
  stroke: ${colors.grey1};

  &:hover {
    background: ${colors.black}14;
    stroke: ${colors.black};
    transition-property: stroke;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
  }
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 4px;

  @media ${mediaQueries.isMobile} {
    li button {
      padding: 2px 16px;
    }
  }
`

const Counter = styled.button`
  padding: 6px;
  background: ${colors.black}08;
  border: solid 1px ${colors.grey3};
  border-radius: 100vh;
  min-width: 32px;
  display: flex;
  justify-content: center;
  color: ${colors.black};

  @media ${mediaQueries.isMobile} {
    padding: 2px;
    min-width: 24px;
  }
`

interface PropsType {
  locations: LocationTree[]
  onSelect: (location: LocationTree) => void
}

export default function HiddenActiveLocationsButton({
  locations,
  onSelect,
}: PropsType) {
  const {
    activeLocation,
    setActiveLocation,
    showHiddenActiveLocations,
    setShowHiddenActiveLocations,
  } = usePlanningContext()

  const { isMobile } = useMediaQueries()

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  if (locations.length === 0) return <></>
  return (
    <>
      <Counter
        ref={setAnchorEl}
        onClick={() => {
          setShowHiddenActiveLocations(!showHiddenActiveLocations)
        }}
      >{`+${locations.length}`}</Counter>
      {showHiddenActiveLocations && anchorEl !== null && (
        <PopperMenu
          anchorEl={anchorEl}
          open
          placement={isMobile ? "top" : "bottom-end"}
          style={
            isMobile ? { zIndex: 30, padding: "8px 2vw" } : { padding: "8px" }
          }
          onClose={() => {
            setShowHiddenActiveLocations(false)
            setActiveLocation({
              location: null,
              persist: false,
              triggeredFrom: "planning",
            })
          }}
        >
          <Layout>
            <CloseButton
              role="button"
              style={{
                width: "20px",
                height: "20px",
              }}
              onClick={() => {
                setShowHiddenActiveLocations(false)
                setActiveLocation({
                  location: null,
                  persist: false,
                  triggeredFrom: "planning",
                })
              }}
            />
            <List>
              {locations.map((location) => {
                const isActive = location.id === activeLocation.location?.id
                return (
                  <li
                    // onClick={(e) => e.stopPropagation()}
                    key={`hidden-location-${location.id}`}
                  >
                    <ActiveLocationChipButtonWithBreadCrumbs
                      location={location}
                      active={isActive}
                      showBreadCrumbs
                      onClick={() => {
                        onSelect(location)
                      }}
                      onMouseEnter={() => {
                        if (!activeLocation.persist) {
                          setActiveLocation({
                            location,
                            persist: false,
                            triggeredFrom: "planning",
                          })
                        }
                      }}
                      onMouseLeave={() => {
                        if (!activeLocation.persist) {
                          setActiveLocation({
                            location: null,
                            persist: false,
                            triggeredFrom: "planning",
                          })
                        }
                      }}
                    />
                  </li>
                )
              })}
            </List>
          </Layout>
        </PopperMenu>
      )}
    </>
  )
}
