import useMediaQueries from "hooks/useMediaQueries"
import React from "react"
import styled from "styled-components"
import { colors, L12, LU12, mediaQueries, P16 } from "ui"
import { ArrowDownFill } from "ui/icons"

import { ContentList } from "./ContentList"
import { Header } from "./Header"

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  border: solid 1px ${colors.grey3};
  border-radius: 8px;
  background-color: ${colors.grey4};
  max-height: 50vh;
  overflow-y: hidden;
  overflow-x: hidden;

  @media ${mediaQueries.isMobile} {
    max-height: 50vh;
  }

  .content-list {
    display: flex;
    flex-direction: column;
    gap: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    @media ${mediaQueries.isMobile} {
      padding: 8px;
    }
  }
`

interface PropsType {
  title: string
  subtitle: string
  children: React.ReactNode
  collapseOpen: string | null
  setCollapseOpen: (str: string | null) => void
  id: string
}

export default function CollapseContainer({
  title,
  subtitle,
  children,
  collapseOpen,
  setCollapseOpen,
  id,
}: PropsType) {
  const { isMobile } = useMediaQueries()

  return (
    <Layout>
      <Header
        onClick={(e) => {
          setCollapseOpen(collapseOpen ? null : id)
          e.preventDefault()
        }}
      >
        <ArrowDownFill
          className={
            collapseOpen === id ? "arrow-btn" : "arrow-btn arrow-btn--turned"
          }
        />

        <LU12 className="title">{title}</LU12>
        {isMobile ? (
          <L12 className="more-text">{subtitle}</L12>
        ) : (
          <P16 className="more-text">{subtitle}</P16>
        )}
      </Header>
      {collapseOpen === id && (
        <ContentList className="content-list">{children}</ContentList>
      )}
    </Layout>
  )
}
