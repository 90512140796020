import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useSetSlotTemplatesUTC from "graphql/typicalWeek/useSetSlotTemplatesUTC"
import useTranslate from "intl/useTranslate"
import React, { useEffect, useState } from "react"
import { EditionTimeFrame, WorkingMode } from "types"
import UTCDate from "utils/UTCDate"

import TypicalWeekHowTo from "./TypicalWeekHowTo/TypicalWeekHowTo"
import TypicalWeekLocationPicker from "./TypicalWeekLocationPicker/TypicalWeekLocationPicker"
import TypicalWeekSlotsEditor from "./TypicalWeekSlotsEditor/TypicalWeekSlotsEditor"

interface SharedPropsType {
  editionTimeFrame: EditionTimeFrame
  setEditionTimeFrame: React.Dispatch<React.SetStateAction<EditionTimeFrame>>
}
interface SlotsEditorContentPropsType extends SharedPropsType {
  from?: UTCDate
  to?: UTCDate
  isTypicalWeekValid: boolean
  handleSaveSlot: ({ genericSlotValue, label, locationId }: WorkingMode) => void
  handleResetSlots: () => void
  reset: () => void
}

function SlotsEditorContent({
  from,
  to,
  isTypicalWeekValid,
  handleSaveSlot,
  handleResetSlots,
  reset,
}: SlotsEditorContentPropsType) {
  const { openDrawer } = usePlanningContext()
  const [contentType, setContentType] = useState<
    "HOW_TO" | "SLOTS_EDITOR" | "LOCATION_PICKER"
  >("HOW_TO")

  useEffect(() => {
    const invalidTimeFrame = !from || !to
    if (invalidTimeFrame) return setContentType("HOW_TO")

    if (!invalidTimeFrame && contentType === "HOW_TO") {
      return setContentType("SLOTS_EDITOR")
    }
  }, [from, to, contentType, openDrawer])

  switch (contentType) {
    case "HOW_TO":
      return <TypicalWeekHowTo isTypicalWeekValid={isTypicalWeekValid} />
    case "SLOTS_EDITOR":
      return (
        <TypicalWeekSlotsEditor
          onNavback={() => {
            setContentType("HOW_TO")
            reset()
          }}
          setContentType={setContentType}
          handleSaveSlot={handleSaveSlot}
          handleResetSlots={handleResetSlots}
        />
      )
    case "LOCATION_PICKER":
      if (!from || !to) return <></>
      return (
        <TypicalWeekLocationPicker
          from={from}
          to={to}
          onNavback={() => {
            setContentType("SLOTS_EDITOR")
          }}
          setContentType={setContentType}
          handleSaveSlot={handleSaveSlot}
        />
      )
    default:
      return <></>
  }
}

interface UserOnboardingSlotsEditor extends SharedPropsType {
  from?: UTCDate
  to?: UTCDate
  isTypicalWeekValid: boolean
}

function UserOnboardingSlotsEditor({
  from,
  to,
  isTypicalWeekValid,
  editionTimeFrame,
  setEditionTimeFrame,
}: UserOnboardingSlotsEditor) {
  const t = useTranslate()
  const { setOpenDrawer } = usePlanningContext()
  const { addToast } = useToasts()

  const cleanupAfterMutation = () => {
    setOpenDrawer(null)
    setEditionTimeFrame({})
  }
  const setSlotTemplates = useSetSlotTemplatesUTC()

  const getTimestamps = (from: UTCDate, to: UTCDate) => {
    const timestampsStart = new UTCDate(from)
    const timestampsEnd = new UTCDate(to)
    const timestamps: number[] = []
    for (
      const timestampCursor = timestampsStart;
      timestampsStart.getTime() <= timestampsEnd.getTime();
      timestampCursor.shift("HALF-DAY", 1)
    ) {
      timestamps.push(new UTCDate(timestampCursor).getTime())
    }
    return timestamps
  }

  const handleSaveSlot = ({
    genericSlotValue,
    label,
    locationId,
  }: WorkingMode) => {
    if (!from || !to) return
    setSlotTemplates(
      getTimestamps(from, to),
      genericSlotValue,
      locationId,
      label
    )
      .then(() =>
        addToast(t("changes-saved--success"), { appearance: "success" })
      )
      .catch(() =>
        addToast(t("changes-saved--failure"), { appearance: "error" })
      )

    cleanupAfterMutation()
  }

  const handleResetSlots = () => {
    if (!from || !to) return
    setSlotTemplates(getTimestamps(from, to), "null", null, null)

    cleanupAfterMutation()
  }

  return (
    <SlotsEditorContent
      from={from}
      to={to}
      setEditionTimeFrame={setEditionTimeFrame}
      isTypicalWeekValid={isTypicalWeekValid}
      editionTimeFrame={editionTimeFrame}
      reset={cleanupAfterMutation}
      handleSaveSlot={handleSaveSlot}
      handleResetSlots={handleResetSlots}
    />
  )
}

interface WrapperPropsType extends SharedPropsType {
  isTypicalWeekValid: boolean
}

export default function Wrapper({
  editionTimeFrame,
  setEditionTimeFrame,
  isTypicalWeekValid,
}: WrapperPropsType) {
  const from = editionTimeFrame.from
    ? new UTCDate(editionTimeFrame.from)
    : undefined
  const to = editionTimeFrame.to ? new UTCDate(editionTimeFrame.to) : undefined

  return (
    <UserOnboardingSlotsEditor
      from={from}
      to={to}
      isTypicalWeekValid={isTypicalWeekValid}
      editionTimeFrame={editionTimeFrame}
      setEditionTimeFrame={setEditionTimeFrame}
    />
  )
}
