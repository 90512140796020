import { useEffect, useState } from "react"

interface SignupDetails {
  user: {
    id: string
    email: string
    name: string
    phone?: string
  }
  companyName: string
  subscription: {
    id: string
    name: string
    quantity: number
  }
  alreadyActivated?: boolean
  signinToken?: string
}

interface SignupDetailsResult {
  loading: boolean
  details?: SignupDetails
  error?: Error
}

export default function useTransactableSignupDetails(): SignupDetailsResult {
  const [details, setDetails] = useState<SignupDetails | undefined>()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | undefined>()

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API}/transactable/signup_details${window.location.search}`
    )
      .then((res) => {
        if (res.ok) {
          res.json().then((data) => {
            setDetails(data)
            setLoading(false)
          })
        } else {
          setError(
            new Error(`Failed to fetch signup details: code=${res.status}`)
          )
          setLoading(false)
        }
      })
      .catch((e) => {
        setError(new Error(`Failed to fetch signup details: ${e.message}`))
        setLoading(false)
      })
  }, [])

  return { loading, error, details }
}
