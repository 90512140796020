import useOccupancyRate from "graphql/stats/useOccupancyRate"
import React, { useEffect } from "react"
import LocationTree from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

interface Propstype {
  locations: LocationTree[]
  from: UTCDate
  to: UTCDate
  includeWeekends: boolean
  allLocationsOccupancyRate:
    | { value: number; booked: number; total: number }
    | undefined
  setAllLocationsOccupancyRate: (occupancyRate: {
    value: number
    booked: number
    total: number
  }) => void
}

export default function AllLocationsOccupancyBar({
  locations,
  from,
  to,
  includeWeekends,
  allLocationsOccupancyRate,
  setAllLocationsOccupancyRate,
}: Propstype) {
  const { occupancyRate, loading, error } = useOccupancyRate({
    from,
    to,
    locationIds: locations.map((l) => l.id),
    includeWeekends,
  })

  useEffect(() => {
    if (loading || error) return

    if (
      allLocationsOccupancyRate === undefined ||
      occupancyRate !== allLocationsOccupancyRate
    ) {
      setAllLocationsOccupancyRate(occupancyRate)
    }
  }, [
    loading,
    error,
    allLocationsOccupancyRate,
    locations,
    occupancyRate,
    setAllLocationsOccupancyRate,
  ])

  return <></>
}
