import { Capacitor } from "@capacitor/core"

export default function updateApp() {
  switch (Capacitor.getPlatform()) {
    case "ios":
      // TODO: replace by proper app name and id once we have them
      // (ex of a valid link: "https://apps.apple.com/fr/app/twitter/id333903271")
      window.location.href =
        "https://apps.apple.com/fr/app/<app-name>/idXXXXXXXXXX"
      break
    case "android":
      window.location.href =
        "http://play.google.com/store/apps/details?id=io.offishall.app"
      break
    default:
      window.location.reload()
      break
  }
}
