import useUser from "graphql/users/useUser"
import React from "react"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore-next-line
import ProductFruits from "react-product-fruits"

interface PropsType {
  locale: string
}

export default function ProductFruitsWrapper({ locale }: PropsType) {
  const { user } = useUser()
  const code = process.env.REACT_APP_PRODUCT_FRUITS_CODE
  if (code && user) {
    const { email, role, id, company, ...restUser } = user
    const userInfo = {
      username: id,
      email,
      role,
      props: {
        ...restUser,
        companyName: company.name,
        companyId: company.id,
      },
    }
    return (
      <>
        <ProductFruits projectCode={code} language={locale} {...userInfo} />
      </>
    )
  }
  return <></>
}
