import PageHeader from "components/Layout/PageLayout/PageHeader/PageHeader"
import MobileNavBack from "components/Layout/PageLayout/PageHeader/PageTitle/MobileNavBack/MobileNavBack"
import PageTitle from "components/Layout/PageLayout/PageHeader/PageTitle/PageTitle"
import UserSearch from "components/UserSearch/UserSearch"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { SearchResult, User } from "types"
import {
  colors,
  Dialog,
  L12GreyBold,
  mediaQueries,
  P14,
  TextFieldMaxCharacters,
} from "ui"
import { GROUP_NAME_MAX_LENGTH } from "utils/constants"
import getUserDisplayName from "utils/getUserDisplayName"

import { AddedUsersList } from "./AddedUsersList"
import SaveGroupButton from "./SaveGroupButton/SaveGroupButton"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;

  .search-container {
    p {
      margin-bottom: 8px;
    }
  }

  @media ${mediaQueries.isMobile} {
    padding: 16px 8px;
  }
`

const HelperText = styled(P14)`
  color: ${colors.grey1};
`

interface PropsType {
  open: boolean
  me: User
  onClose: () => void
}

export default function CreateGroupModal({ open, me, onClose }: PropsType) {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()

  const [groupName, setGroupName] = useState("")
  const [usersToBeAdded, setUsersToBeAdded] = useState<SearchResult[]>([
    {
      email: me.email,
      id: me.id,
      name: getUserDisplayName(me, me.company.flags),
      type: "user",
    },
  ])

  const addUserToGroup = (user: SearchResult) => {
    setUsersToBeAdded((prev) => {
      if (!prev.find((u) => u.id === user.id)) return [...prev, user]
      return prev
    })
  }

  const removeUserFromToBeAdded = (user: SearchResult) => {
    setUsersToBeAdded((oldUsersList) =>
      oldUsersList.filter((oldUser) => oldUser.id !== user.id)
    )
  }

  const cleanUp = () => {
    onClose()
    setGroupName("")
    setUsersToBeAdded([])
  }

  const canAddGroup =
    groupName !== "" &&
    groupName.length <= GROUP_NAME_MAX_LENGTH &&
    usersToBeAdded.length > 0

  if (!me) return <></>

  return (
    <Dialog
      open={open}
      title={isMobile ? undefined : t("create a group")}
      onClose={() => {
        setGroupName("")
        setUsersToBeAdded([])
        onClose()
      }}
      rightButton={
        <SaveGroupButton
          groupUsers={usersToBeAdded.map((u) => u.id)}
          groupName={groupName}
          disabled={!canAddGroup}
          cleanupAfterSaving={cleanUp}
        >
          {t("create a group")}
        </SaveGroupButton>
      }
      fullscreen={isMobile}
    >
      <>
        {isMobile && (
          <PageHeader>
            <MobileNavBack onClose={onClose} />
            <PageTitle title={t("create a group")} />
          </PageHeader>
        )}
        <Container>
          <TextFieldMaxCharacters
            maxCharacters={GROUP_NAME_MAX_LENGTH}
            label={t("Name")}
            placeholder={t("group name")}
            handleChange={setGroupName}
          />

          <div className="search-container">
            <L12GreyBold>{t("members invitation")}</L12GreyBold>
            <UserSearch onSelect={addUserToGroup} />
          </div>
          {usersToBeAdded.filter((u) => u.id !== me.id).length === 0 && (
            <HelperText>{t("must invite at least 1 user to group")}</HelperText>
          )}

          <AddedUsersList
            usersToBeAdded={usersToBeAdded}
            onClick={removeUserFromToBeAdded}
          />
        </Container>
      </>
    </Dialog>
  )
}
