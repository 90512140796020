import { Tooltip as MuiTooltip } from "@mui/material"
import React from "react"
import { colors } from "ui"

interface TooltipChildrenPropsType {
  children: React.ReactNode
}

const TooltipChildren = React.forwardRef(
  (
    { children, ...rest }: TooltipChildrenPropsType,
    ref: React.Ref<HTMLDivElement>
  ) => {
    return (
      <div {...rest} ref={ref}>
        {children}
      </div>
    )
  }
)

interface PropsType {
  title: string
  className?: string
  disabled?: boolean
  children: React.ReactNode
}

export function EditableSlotTooltip({
  title,
  className,
  disabled,
  children,
}: PropsType) {
  return (
    <MuiTooltip
      title={title}
      className={className}
      disableHoverListener={disabled}
      placement="top"
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: colors.grey3,
            color: colors.black,
            fontSize: "14px",
            lineHeight: "18px",
            fontWeight: "normal",
            whiteSpace: "pre-line",
            top: "4px",
            "& .MuiTooltip-arrow": {
              color: colors.grey3,
            },
          },
        },
      }}
      enterDelay={250}
      enterNextDelay={250}
    >
      <TooltipChildren>{children}</TooltipChildren>
    </MuiTooltip>
  )
}
