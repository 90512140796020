import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import { computePlanningDays } from "components/PlanningContextProvider/utils"
import React from "react"
import styled from "styled-components"
import UTCDate from "utils/UTCDate"

import Day from "./Day"

interface DaysContainerPropsType {
  displayWeekends: boolean
}

const DaysContainer = styled.div<DaysContainerPropsType>`
  display: grid;
  gap: 2px;
  ${({ displayWeekends }) => {
    if (displayWeekends) return "grid-template-columns: repeat(7, 1fr);"
    return "grid-template-columns: repeat(5, 1fr);"
  }}

  width: 100%;
  padding: 4px 8px 4px 0;
`

const DayWrapper = styled.div`
  padding: 8px 0;
`

interface PropsType {
  todayStatus: {
    AM: string | null
    PM: string | null
  }
  from: UTCDate
  to: UTCDate
}

export default function Days({ todayStatus, from, to }: PropsType) {
  const { userShowWeekends } = usePlanningContext()

  const days = computePlanningDays(from, to, userShowWeekends, false)

  return (
    <DaysContainer displayWeekends={userShowWeekends}>
      {days.map((day) => {
        return (
          <DayWrapper key={day.getTime()}>
            <Day
              day={day}
              userShowWeekend={userShowWeekends}
              todayStatus={todayStatus}
            />
          </DayWrapper>
        )
      })}
    </DaysContainer>
  )
}
