import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React from "react"
import { P14Bold } from "ui"
import { Edit } from "ui/icons"

interface PropsType {
  onClick: () => void
}

function EditionBtn({ onClick }: PropsType) {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()

  return (
    <div className="action" onClick={onClick}>
      {!isMobile ? <P14Bold>{t("update")}</P14Bold> : <Edit />}
    </div>
  )
}
export default EditionBtn
