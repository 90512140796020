import React from "react"
import styled from "styled-components"
import { colors } from "ui"

const SearchInputText = styled.input.attrs({ type: "search" })`
  border: none;
  background: inherit;
  outline: none;
  width: 100%;
  color: ${colors.black};
  text-decoration: none;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${colors.grey1};
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${colors.grey1};
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${colors.grey1};
  }

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  /* hide IE or Chrome default cancel button */
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
  &::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  /* For Chrome to delete auto-complete hightlight */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: none;
    -webkit-box-shadow: 0 0 0 30px ${colors.grey4} inset !important;
  }
`

interface PropsType {
  placeholder?: string
  searchString: string
  onChange: (str: string) => void
  resetForm: () => void
  onClick?: () => void
  id?: string | undefined
  autoFocus?: boolean
}

function SearchBarTextInput(
  {
    id,
    placeholder,
    searchString,
    autoFocus,
    onChange,
    resetForm,
    onClick,
  }: PropsType,
  ref: React.Ref<HTMLInputElement>
) {
  return (
    <SearchInputText
      id={id}
      ref={ref}
      placeholder={placeholder}
      onChange={(event) => {
        onChange(event.target.value)
      }}
      onClick={(e) => {
        if (onClick) onClick()
        if (searchString !== "") {
          e.stopPropagation() // prevent search reset
        }
      }}
      onKeyDown={(e) => {
        if (e.key === "Escape") resetForm()
      }}
      value={searchString}
      autoFocus={autoFocus}
    />
  )
}

export const SearchBarTextInputWithRef = React.forwardRef(SearchBarTextInput)
export default SearchBarTextInputWithRef
