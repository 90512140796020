import { ApolloError, gql, useQuery } from "@apollo/client"
import { LastTimeAtTheOffice } from "types"
import UTCDate from "utils/UTCDate"

export const QUERY = gql`
  query lastTimeAtTheOffice($userIds: [ID]!, $currentTimestamp: String!) {
    lastTimeAtTheOffice(
      userIds: $userIds
      currentTimestamp: $currentTimestamp
    ) {
      userId
      date
    }
  }
`

export default function useLastTimeAtTheOffice({
  userIds,
}: {
  userIds: string[]
}): {
  loading: boolean
  error?: ApolloError
  lastTimes: LastTimeAtTheOffice[]
} {
  const today = new UTCDate("DAY")
  const { loading, error, data } = useQuery(QUERY, {
    variables: {
      userIds,
      currentTimestamp: today.getTime().toString(),
    },
  })
  const lastTimes = (data?.lastTimeAtTheOffice || []).map((td: any) => {
    const { date: rawDate, ...rest } = td
    const date = new UTCDate(new Date(rawDate), "DAY", false)
    return {
      ...rest,
      date,
    }
  })
  return { lastTimes, loading, error }
}
