import { ApolloError, gql, useQuery } from "@apollo/client"
import UTCDate from "utils/UTCDate"

const QUERY = gql`
  query stats_workingModes(
    $from: String!
    $to: String!
    $groupIds: [String]!
    $userIds: [String]!
    $includeWeekends: Boolean!
  ) {
    stats_workingModes(
      from: $from
      to: $to
      groupIds: $groupIds
      userIds: $userIds
      includeWeekends: $includeWeekends
    ) {
      workingMode
      value
    }
  }
`

interface PropsType {
  from: UTCDate
  to: UTCDate
  groupIds: string[]
  userIds: string[]
  includeWeekends: boolean
}

interface ResponseType {
  workingMode: string
  value: number
}

export default function useWorkingModes({
  from,
  to,
  groupIds,
  userIds,
  includeWeekends,
}: PropsType): {
  loading: boolean
  error?: ApolloError
  workingModes: ResponseType[]
} {
  const { loading, error, data } = useQuery(QUERY, {
    variables: {
      from: `${from.getTime()}`,
      to: `${to.getTime()}`,
      groupIds,
      userIds,
      includeWeekends,
    },
  })
  return {
    workingModes: data?.stats_workingModes || [],
    loading,
    error,
  }
}
