import React from "react"
import styled from "styled-components"
import { User } from "types"
import { TextField } from "ui"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

interface PropsType {
  user: User
  canBeEdited?: boolean
  updateChanges: (value: { userEmail?: string }) => void
}

export default function EditUserEmail({ user, updateChanges }: PropsType) {
  return (
    <Container>
      <TextField
        label="Email"
        initialValue={user.email}
        handleChange={(value) => {
          console.log("TODO, edit user email")
          updateChanges({ userEmail: value })
        }}
        disabled
      />
    </Container>
  )
}
