import PageContent from "components/Layout/PageLayout/PageContent/PageContent"
import DesktopNavBack from "components/Layout/PageLayout/PageHeader/DesktopNavBack/DesktopNavBack"
import PageHeader from "components/Layout/PageLayout/PageHeader/PageHeader"
import BurgerButton from "components/Layout/PageLayout/PageHeader/PageTitle/BurgerButton/BurgerButton"
import PageTitle from "components/Layout/PageLayout/PageHeader/PageTitle/PageTitle"
import PageLayout from "components/Layout/PageLayout/PageLayout"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useUserSlotTemplates from "graphql/typicalWeek/useUserSlotTemplates"
import useUser from "graphql/users/useUser"
import useEditableUserSlotsPermission from "hooks/useEditableUserSlotsPermission"
import useMediaQueries from "hooks/useMediaQueries"
import useWindowSize from "hooks/useWindowResize"
import useTranslate from "intl/useTranslate"
import { EditableUserSlots } from "pages/PlanningPage/components/EditableUserSlots/EditableUserSlots"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Transition } from "react-transition-group"
import styled from "styled-components"
import { EditionTimeFrame, User, UTCSlot } from "types"
import { Button, Grid, mediaQueries, P16 } from "ui"
import { getTypicalWeekFromTo } from "utils/typicalWeek"

import {
  Content,
  Footer,
  Header,
  MessageContainer,
  RightContainer,
  Subtitle,
  Title,
} from "../components/Layout"
import NavBack from "../components/NavBack"
import StepIndicator from "../components/StepIndicator"
import UserOnboardingSlotsEditor from "./UserOnboardingSlotsEditor/UserOnboardingSlotsEditor"
import ToggleAutoApplySlotTemplates from "./UserOnboardingWeekDays/ToggleAutoApplySlotTemplates/ToggleAutoApplySlotTemplates"
import UserOnboardingWeekDays from "./UserOnboardingWeekDays/UserOnboardingWeekDays"

const duration = 600

const defaultStyleSmallPopUp = {
  transition: `right ${duration}ms ease-in-out 300ms`,
  position: "absolute",
  top: "40%",
}

const defaultStyleSmallPopUp2 = {
  transition: `right ${duration}ms ease-in-out 400ms`,
  position: "absolute",
  top: "45%",
}

const transitionStylesSmallPopUp: any = {
  entering: { right: "25%" },
  entered: { right: "25%" },
  exiting: { right: -300 },
  exited: { right: -300 },
}

const transitionStylesSmallPopUp2: any = {
  entering: { right: "20%" },
  entered: { right: "20%" },
  exiting: { right: -300 },
  exited: { right: -300 },
}

const HeaderTitle = styled.div`
  display: flex;
`

const ContentLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  gap: 24px;
  padding: 0 5vw;
  max-width: 960px;

  @media ${mediaQueries.isMobile} {
    padding: 0 8px;
  }
`
interface PropsType {
  selectedUser: User
  inOnboarding: boolean
  shouldSaveAsSlotTemplates: boolean
  slots: UTCSlot[]
  editionTimeFrame: EditionTimeFrame
  setEditionTimeFrame: React.Dispatch<React.SetStateAction<EditionTimeFrame>>
}

function PerfectPlanningPage({
  selectedUser,
  inOnboarding,
  editionTimeFrame,
  slots,
  setEditionTimeFrame,
}: PropsType) {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()
  const history = useHistory()
  const [, maxHeight] = useWindowSize()

  const [displayReelUsers, setDisplayReelUsers] = useState<boolean>(false)
  const { userShowWeekends } = usePlanningContext()

  const { from, to } = getTypicalWeekFromTo(userShowWeekends)

  const [inProp, setInProp] = useState(false)

  const canEditSlots = useEditableUserSlotsPermission(null)

  useEffect(() => {
    setTimeout(() => setInProp(true), 1)
  }, [])

  const isPlanningFullyCompleted =
    slots
      .filter((s) => userShowWeekends || !s.date.isWeekend())
      .filter((s) => s.value === null || s.value === "null").length === 0

  if (!inOnboarding) {
    return (
      <PageLayout>
        {isMobile ? (
          <PageHeader>
            <BurgerButton />
            <PageTitle title={t("typical week")} />
          </PageHeader>
        ) : (
          <PageHeader>
            <DesktopNavBack />
            <div>
              <HeaderTitle>
                <PageTitle title={t("typical week")} />
                <ToggleAutoApplySlotTemplates />
              </HeaderTitle>
              <P16>{t("typical week description")}</P16>
            </div>
          </PageHeader>
        )}
        <PageContent>
          <ContentLayout>
            {isMobile && (
              <div>
                <ToggleAutoApplySlotTemplates />

                <P16>{t("typical week description")}</P16>
              </div>
            )}

            <div>
              <UserOnboardingWeekDays />
              <EditableUserSlots
                from={from}
                to={to}
                userToMutate={selectedUser}
                editionTimeFrame={editionTimeFrame}
                canEdit={canEditSlots}
                setEditionTimeFrame={setEditionTimeFrame}
              />
            </div>
            <UserOnboardingSlotsEditor
              isTypicalWeekValid={isPlanningFullyCompleted}
              editionTimeFrame={editionTimeFrame}
              setEditionTimeFrame={setEditionTimeFrame}
            />
          </ContentLayout>
        </PageContent>
      </PageLayout>
    )
  }

  return (
    <Grid container direction="row">
      <Grid
        item
        xs={12}
        md={8}
        xl={9}
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <Header>
          <NavBack
            realGoBack={!displayReelUsers}
            onClick={() => setDisplayReelUsers(false)}
          />
        </Header>
        <Content maxHeight={maxHeight}>
          <MessageContainer>
            <Title>{t("user-onboarding-planning-title")}</Title>
            <Subtitle>{t("user-onboarding-planning-p-1")}</Subtitle>
            <Subtitle>{t("user-onboarding-planning-p-2")}</Subtitle>
          </MessageContainer>
          <div>
            <UserOnboardingWeekDays />
            <EditableUserSlots
              from={from}
              to={to}
              userToMutate={selectedUser}
              editionTimeFrame={editionTimeFrame}
              canEdit={canEditSlots}
              setEditionTimeFrame={setEditionTimeFrame}
            />
          </div>
          <UserOnboardingSlotsEditor
            isTypicalWeekValid={isPlanningFullyCompleted}
            editionTimeFrame={editionTimeFrame}
            setEditionTimeFrame={setEditionTimeFrame}
          />
        </Content>
        <Footer>
          <StepIndicator />
          <Button
            onClick={() => {
              history.push("/onboarding/user/password")
            }}
            disabled={!isPlanningFullyCompleted}
          >
            {t("continue")}
          </Button>
        </Footer>
      </Grid>
      <RightContainer
        item
        xs={0}
        md={4}
        xl={3}
        style={{
          backgroundImage: `url(/static/img/onboarding/onboarding_step2.svg)`,
        }}
      >
        <Transition in={inProp} timeout={duration}>
          {(state) => (
            <div
              style={{
                ...defaultStyleSmallPopUp,
                ...transitionStylesSmallPopUp[state],
              }}
            >
              <img
                alt="Fictitious users creation"
                src="/static/img/onboarding/pop_up_2_step2.svg"
              />
            </div>
          )}
        </Transition>
        <Transition in={inProp} timeout={duration}>
          {(state) => (
            <div
              style={{
                ...defaultStyleSmallPopUp2,
                ...transitionStylesSmallPopUp2[state],
              }}
            >
              <img
                alt="Fictitious users creation"
                src="/static/img/onboarding/pop_up_2_step2.svg"
              />
            </div>
          )}
        </Transition>
      </RightContainer>
    </Grid>
  )
}

interface PagePropsType {
  selectedUser: User
  inOnboarding?: boolean
  slots: UTCSlot[]
  shouldSaveAsSlotTemplates: boolean
}

function UserOnboardingPlanningPage({
  selectedUser,
  inOnboarding = true,
  slots,
  shouldSaveAsSlotTemplates,
}: PagePropsType) {
  const { openDrawer, setActiveLocation, activeLocation } = usePlanningContext()

  const [editionTimeFrame, setEditionTimeFrame] = useState<EditionTimeFrame>({
    from: undefined,
    to: undefined,
  })
  useEffect(() => {
    if (openDrawer !== "SLOTS_EDITOR") setEditionTimeFrame({})
  }, [openDrawer, setEditionTimeFrame])

  useEffect(() => {
    // always reset active location when browsing /planning/... pages
    if (activeLocation.location !== null) {
      setActiveLocation({
        location: null,
        persist: false,
        triggeredFrom: "active-locations-list",
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PerfectPlanningPage
      selectedUser={selectedUser}
      inOnboarding={inOnboarding}
      shouldSaveAsSlotTemplates={shouldSaveAsSlotTemplates}
      editionTimeFrame={editionTimeFrame}
      slots={slots}
      setEditionTimeFrame={setEditionTimeFrame}
    />
  )
}

export default function UserOnboardingSlotTemplatesPage() {
  const { user: me } = useUser()
  const { slots: slotTemplates } = useUserSlotTemplates(me?.id ?? "")

  const inOboarding = location.pathname.startsWith("/onboardind/user")

  if (!me) return <></>

  return (
    <UserOnboardingPlanningPage
      selectedUser={me}
      inOnboarding={inOboarding}
      slots={slotTemplates}
      shouldSaveAsSlotTemplates={true}
    />
  )
}
