import styled from "styled-components"
import { colors } from "ui"

export const NullSlot = styled.div`
  background-color: ${colors.white};
  border: 3px solid ${colors.grey3};
  border-radius: 50%;
  width: 14px;
  height: 14px;
  font-size: 9px;
  margin: auto;
`
