import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useEquipments from "graphql/equipments/useEquipments"
import useUserEquipments from "graphql/equipments/useUserEquipments"
import useLocationsTreeForUser from "graphql/locations/useLocationsTreeForUser"
import useOccupations from "graphql/slots/useOccupations"
import useSetSlotsUTC from "graphql/slots/useSetSlotsUTC"
import useUserSlotsGuests from "graphql/slots/useUserSlotsGuests"
import useUser from "graphql/users/useUser"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import uniqBy from "lodash.uniqby"
import React, { useState } from "react"
import styled from "styled-components/macro"
import { useTeamsAppContext } from "TeamsApp/TeamsAppContext"
import { EditionTimeFrame, Equipment, User, WorkingMode } from "types"
import { Button, colors, mediaQueries } from "ui"
import LocationTree from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

import OfficeStatusPicker from "./OfficeStatusPicker/OfficeStatusPicker"
import PreviouslyBookedItemsDialog from "./PreviouslyBookedItemsDialog/PreviouslyBookedItemsDialog"
import SlotsSettings from "./SlotsSettings/SlotsSettings"
import Suggestions from "./Suggestions/Suggestions"
import WorkingModeButtons from "./WorkingModeButtons/WorkingModeButtons"

const Layout = styled.div`
  display: grid;
  grid-template-rows: auto auto;

  > div:last-child {
    overflow-y: scroll;
  }

  width: 100%;
  overflow-x: hidden;

  @media ${mediaQueries.isMobile} {
    height: 100%;
    min-height: 100%;
    max-width: 100vw;
    padding: 4px 0 env(safe-area-inset-bottom, 0) 0;
    overflow-x: hidden;
    grid-template-rows: auto 1fr;
  }
`

interface SlotsEditorContainerProps {
  isFloorPlanDisplay?: boolean
}

const SlotsEditorContainer = styled.div<SlotsEditorContainerProps>`
  ${({ isFloorPlanDisplay }) => {
    if (isFloorPlanDisplay) `padding-left: 0px;`
    else return `padding-left: 64px;`
  }}

  @media ${mediaQueries.isMobile} {
    display: grid;
    grid-template-rows: 1fr min-content;
    width: 100vw;
    padding-left: 0;

    .locations-list {
      overflow-y: scroll;
      overflow-x: hidden;
    }
    overflow-y: scroll;
  }

  .favorite-icon,
  .zone-icon {
    width: 16px;
    margin: 0 10px 0 0;
  }

  .favorite-icon {
    fill: ${colors.yellow};
  }

  .zones-list {
    margin: 10px 0 0 0;
    padding: 4px 8px 0 8px;
    background: ${colors.grey4};
    border: solid 1px ${colors.grey3};
    border-radius: 8px;
  }

  @media ${mediaQueries.isMobile} {
    overflow-x: hidden;
    .zones-list {
      padding: 4px 0 0 0;
    }
  }
`

const MobileFooter = styled.footer`
  padding: 8px;
  border-top: solid 1px ${colors.grey3};
  background: ${colors.grey4};
`

interface PropsType {
  selectedUser: User
  from: UTCDate
  to: UTCDate
  setEditionTimeFrame: React.Dispatch<React.SetStateAction<EditionTimeFrame>>
}

export default function SlotsEditorContent({
  selectedUser,
  from,
  to,
  setEditionTimeFrame,
}: PropsType) {
  const { isTeamsApp } = useTeamsAppContext()
  const t = useTranslate()
  const { isMobile } = useMediaQueries()

  const [floorPlan, setFloorPlan] = useState<
    | {
        selectedFloorPlan?: LocationTree
        selectedVisualArea?: LocationTree
        editing: boolean
      }
    | undefined
  >({
    selectedFloorPlan: undefined,
    selectedVisualArea: undefined,
    editing: false,
  })

  const [workingModeToBeSaved, setWorkingModeToBeSaved] = useState<
    WorkingMode | undefined
  >(undefined)

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)

  const { equipments } = useEquipments()

  const { userEquipments } = useUserEquipments({
    userId: selectedUser.id,
    from,
    to: new UTCDate(to, "NEXT-HALF-DAY"), // from >= getSlots < to
  })
  const { userSlotsGuests } = useUserSlotsGuests({
    userId: selectedUser.id,
    from,
    to: new UTCDate(to, "NEXT-HALF-DAY"), // from >= getSlots < to
  })
  const previousUserSlotsGuests = Array.from(
    new Set(
      userSlotsGuests.reduce(
        (prev: string[], curr) => [...prev, ...curr.guestsEmails],
        []
      )
    )
  ) // "array from set" to flush out duplicates

  const previouslyBookedEquipments = userEquipments
    .map((ue) => equipments.find((e) => ue.equipmentId === e.id))
    .filter((x): x is Equipment => x !== undefined)

  const {
    error: locactionsError,
    loading: locationsLoading,
    locations,
  } = useLocationsTreeForUser(selectedUser)

  const { setOpenDrawer } = usePlanningContext()

  const [guestsEmails, setGuestsEmails] = useState<string[]>([])
  const [inviteGuestsMessage, setInviteGuestsMessage] = useState<string>("")

  const { user: me } = useUser()

  const { error, loading, occupations } = useOccupations(
    from.getTime(),
    to.getTime()
  )

  const { addToast } = useToasts()

  const setSlotsUTC = useSetSlotsUTC(selectedUser.id)

  const onClose = () => {
    setEditionTimeFrame({})
    setOpenDrawer(null)
  }

  const saveWorkingMode = (workingMode: WorkingMode) => {
    setWorkingModeToBeSaved(workingMode)
    if (
      previouslyBookedEquipments.length === 0 &&
      previousUserSlotsGuests.length === 0
    )
      return saveConfirmedWorkingMode(workingMode)
    return setIsConfirmDialogOpen(true)
  }

  const saveConfirmedWorkingMode = async (workingMode: WorkingMode) => {
    try {
      const { equipments, locationId, label, genericSlotValue } = workingMode

      if (genericSlotValue === null) return

      if (from === undefined || to === undefined) return

      // compute selected dates against from and to
      // since selected slots are clamped to a week + one day length
      const limit = 365 * 2 + 1
      const selectedDates: UTCDate[] = []
      let cursor = 0
      let current = from
      while (current.getTime() <= to.getTime() && cursor < limit) {
        selectedDates.push(current)
        current = new UTCDate(current)
        current.increment("HALF-DAY")
        cursor++
      }

      if (selectedDates.length === 0) return

      await setSlotsUTC(
        selectedDates.map((date) => date.getTime()),
        genericSlotValue,
        locationId,
        true,
        null,
        label,
        guestsEmails,
        inviteGuestsMessage,
        equipments.map((e) => e.id)
      )

      if (guestsEmails.length > 0 && workingMode.genericSlotValue === "office")
        addToast(t("guests confirmation toast"), {
          appearance: "success",
        })
      onClose()
    } catch (error) {
      addToast(error.message, {
        appearance: "error",
        autoDismissTimeout: 10000,
      })
    }
  }

  const clearSlots = () => {
    setInviteGuestsMessage("")
    setGuestsEmails([])
    saveWorkingMode({
      equipments: [],
      locationId: null,
      label: null,
      genericSlotValue: "null",
    })
  }

  if (loading || locationsLoading || error || locactionsError) {
    return <LoaderOrErrorComponent loading={loading} error={error} />
  }

  return (
    <>
      <Layout>
        <SlotsSettings
          from={from}
          to={to}
          guestsEmails={guestsEmails}
          inviteGuestsMessage={inviteGuestsMessage}
          setEditionTimeFrame={setEditionTimeFrame}
          updateGuestsEmails={setGuestsEmails}
          updateInviteGuestsMessage={setInviteGuestsMessage}
          onClearSlots={clearSlots}
        />
        <SlotsEditorContainer isFloorPlanDisplay={floorPlan?.editing}>
          {!floorPlan?.editing && (
            <Suggestions
              from={from}
              to={to}
              guestIds={guestsEmails}
              saveWorkingMode={saveWorkingMode}
            />
          )}
          <div className="locations-list">
            {!floorPlan?.editing && (
              <>
                <WorkingModeButtons
                  genericSlotValue="home"
                  i8nKey="Home"
                  label="Remote"
                  selectedUser={selectedUser}
                  saveWorkingMode={saveWorkingMode}
                  from={from}
                  to={to}
                />
                <WorkingModeButtons
                  genericSlotValue="else"
                  i8nKey="Other"
                  label="Other"
                  selectedUser={selectedUser}
                  saveWorkingMode={saveWorkingMode}
                  from={from}
                  to={to}
                />
                <WorkingModeButtons
                  genericSlotValue="off"
                  i8nKey="Unavailable"
                  label="Off"
                  selectedUser={selectedUser}
                  saveWorkingMode={saveWorkingMode}
                  from={from}
                  to={to}
                />
              </>
            )}
            <OfficeStatusPicker
              from={from}
              to={to}
              guestIds={guestsEmails}
              showRedirectToAdminButton={
                locations.length === 0 && me?.role === "admin" && !isTeamsApp
              }
              selectedUser={selectedUser}
              occupations={occupations}
              saveWorkingMode={saveWorkingMode}
              floorPlan={floorPlan}
              setFloorPlan={setFloorPlan}
            />
          </div>
        </SlotsEditorContainer>
        {isMobile && (
          <MobileFooter>
            <Button danger fullWidth onClick={clearSlots}>
              {t("Reinitialize")}
            </Button>
          </MobileFooter>
        )}
      </Layout>
      {isConfirmDialogOpen && (
        <PreviouslyBookedItemsDialog
          open={isConfirmDialogOpen}
          equipments={uniqBy(previouslyBookedEquipments, "id")}
          guests={previousUserSlotsGuests}
          onClose={() => {
            setIsConfirmDialogOpen(false)
            onClose()
          }}
          onCancel={() => {
            setIsConfirmDialogOpen(false)
            onClose()
          }}
          onSave={() => {
            if (workingModeToBeSaved !== undefined)
              saveConfirmedWorkingMode(workingModeToBeSaved)
          }}
        />
      )}
    </>
  )
}
