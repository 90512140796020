import React from "react"
import MultiSelectionListItem from "ui/Lists/MultiSelectionListItem"
import LocationTree from "utils/LocationTree"

interface PropsType {
  location: LocationTree
  selectedLocations: LocationTree[]
  onClick: (el: LocationTree) => void
}

export default function LocationItem({
  location,
  selectedLocations,
  onClick,
}: PropsType) {
  return (
    <MultiSelectionListItem
      item={location}
      archived={location.archived}
      selectedItems={selectedLocations}
      onClick={() => onClick(location)}
    />
  )
}
