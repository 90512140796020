import { ApolloError, gql, useQuery } from "@apollo/client"
import { UserEquipment } from "types"
import UTCDate from "utils/UTCDate"

import { UserEquipmentFragment } from "./fragments/equipmentFragments"

const QUERY = gql`
  ${UserEquipmentFragment}
  query userEquipments($userId: ID!, $from: String!, $to: String!) {
    userEquipments(userId: $userId, from: $from, to: $to) {
      ...UserEquipmentFragment
    }
  }
`

interface PropsType {
  userId: string
  from: UTCDate
  to: UTCDate
}

export default function useUserEquipments({ userId, from, to }: PropsType): {
  loading: boolean
  error?: ApolloError
  userEquipments: UserEquipment[]
} {
  const { loading, error, data } = useQuery<{
    userEquipments: UserEquipment[]
  }>(QUERY, {
    variables: {
      userId,
      from: `${from.getTime()}`,
      to: `${to.getTime()}`,
    },
    fetchPolicy: "cache-and-network",
  })
  return {
    userEquipments: data?.userEquipments || [],
    loading,
    error,
  }
}
