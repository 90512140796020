import SaveButtonWithConfirmation from "components/ButtonsWithConfirmation/SaveButtonWithConfirmation/SaveButtonWithConfirmation"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React from "react"
import { User } from "types"
import { P16 } from "ui"

interface PropsType {
  previousManager: User | null
  newManager: User | null
  disabled?: boolean
  onConfirm: () => void
}

export default function SaveManagerButton({
  previousManager,
  newManager,
  disabled,
  onConfirm,
}: PropsType) {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()

  return (
    <SaveButtonWithConfirmation
      dialogTitle={t("Update manager")}
      buttonTitle={t("Update manager")}
      disabled={disabled}
      fullWidth={isMobile}
      onConfirm={onConfirm}
    >
      <P16>{t("save manager confirmation")}</P16>

      <P16>
        {t("previous manager")}:{" "}
        <em
          style={{
            textDecoration: "line-through",
          }}
        >
          {previousManager?.name ?? "null"}
        </em>
      </P16>

      <P16>
        {t("new manager")}: <strong>{newManager?.name ?? "null"}</strong>
      </P16>
    </SaveButtonWithConfirmation>
  )
}
