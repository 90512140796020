import useDeleteFloorPlanImage from "graphql/floorPlans/useDeleteFloorPlanImage"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { Button, Dialog, LocationChipButton, transitions } from "ui"
import colors from "ui/colors"
import LocationTree from "utils/LocationTree"

import VisualArea, { hasVisualArea } from "../VisualArea"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
  gap: 16px;
  background-color: ${colors.white};

  transition: ${transitions.generic};
`

interface Props {
  location: LocationTree
  area: VisualArea
  open: boolean
  setOpen: (open: boolean) => void
}

function collectSublocation(
  location: LocationTree,
  area: VisualArea
): LocationTree[] {
  if (!location.isCategory && hasVisualArea(location.id, area)) {
    return [location]
  }

  if (location.children) {
    return location.children.flatMap((child) => collectSublocation(child, area))
  }

  return []
}

export default function DeletePlanConfirmDialog({
  location,
  area,
  open,
  setOpen,
}: Props) {
  const t = useTranslate()
  const deleteFloorPlanImage = useDeleteFloorPlanImage()

  const handleDelete = async () => {
    await deleteFloorPlanImage({ locationId: location.id })
    setOpen(false)
  }

  const sublocations = collectSublocation(location, area)

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      title={t("delete-plan-title")}
      rightButton={
        <div style={{ marginLeft: "auto" }}>
          <Button danger onClick={handleDelete}>
            {sublocations.length > 0 ? t("Remove all") : t("Remove")}
          </Button>
        </div>
      }
    >
      <Container>
        {sublocations.length > 0 ? (
          <>
            <div>{t("delete-plan-confirm-1", { name: location.name })}</div>
            <div>{t("delete-plan-confirm-2", { name: location.name })}</div>
            <div>
              {collectSublocation(location, area).map((location) => (
                <div key={location.id} style={{ padding: 2 }}>
                  <LocationChipButton>{location.name}</LocationChipButton>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div>{t("delete-plan-confirm-simple", { name: location.name })}</div>
        )}
      </Container>
    </Dialog>
  )
}
