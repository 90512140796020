import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import { RetryLink } from "@apollo/client/link/retry"
import { Capacitor } from "@capacitor/core"

import { getAppLocale } from "../intl/useTranslation"
import { getToken, logout } from "./firebase"

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API}/graphql`,
  credentials: "include",
})

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
  },
  attempts: {
    max: 15,
    retryIf: (error, _operation) => !!error,
  },
})

const authLink = setContext(async (_, { headers }) => {
  const token = await getToken()
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  }
})

const link = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) => {
      const tokenError = "Context creation failed"
      if (message.substring(0, tokenError.length) === tokenError) {
        const customMessage = message.substring(tokenError.length + 2) // "2 chars for ': ' string"
        if (customMessage === "User is disabled") {
          logout("Cannot connect to this account: user is disabled")
        } else if (customMessage === "Company is disabled") {
          logout("Cannot connect to this account: company is disabled")
        } else {
          logout("Cannot connect to this account")
        }
      }
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    })

  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const defaultTypePolicy = {
  merge(_existing: any, incoming: any) {
    // Equivalent to what happens if there is no custom merge function.
    return incoming
  },
}

const client = new ApolloClient({
  link:
    process.env.NODE_ENV === "development"
      ? link.concat(authLink.concat(httpLink)) // no retryLink in dev
      : retryLink.concat(link.concat(authLink.concat(httpLink))),
  credentials: "include",
  name: Capacitor.getPlatform(),
  version: `client-${process.env.REACT_APP_VERSION}`,
  cache: new InMemoryCache({
    typePolicies: {
      Event: {
        fields: {
          guests: defaultTypePolicy,
        },
      },
      Group: {
        fields: {
          users: defaultTypePolicy,
        },
      },
      LocationUsers: {
        fields: {
          users: defaultTypePolicy,
        },
      },
      ServiceGroup: {
        fields: {
          services: defaultTypePolicy,
        },
      },
      Service: {
        fields: {
          keyframes: defaultTypePolicy,
        },
      },
      Query: {
        fields: {
          userHistory: defaultTypePolicy,
          notifications: defaultTypePolicy,
          appLocale: {
            read() {
              return getAppLocale()
            },
          },
        },
      },
    },
  }),
})

export default client
