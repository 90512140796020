import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import { computePlanningDays } from "components/PlanningContextProvider/utils"
import React from "react"
import styled from "styled-components"
import { mediaQueries } from "ui"
import LocationTree from "utils/LocationTree"

import OccupationSlot from "./OccupationSlot/OccupationSlot"

const Layout = styled.ul<{ weekLength: number }>`
  display: grid;
  place-items: center;
  gap: 8px;
  ${({ weekLength }) => `grid-template-columns: repeat(${weekLength}, 1fr);`}

  &.FLOOR_PLAN {
    padding-right: 3px; // side padding to align with editable slots
    padding-left: 41px; // side padding to align with editable slots
    padding-top: 12px;
    padding-bottom: 16px;
  }
  &.SLOTS_EDITOR {
    padding-right: 15px; // side padding to align with editable slots
    padding-left: 12px; // side padding to align with editable slots
  }

  @media ${mediaQueries.isNarrowScreen} {
    padding: 0 2px 0 3px;
    &.with-drawer-paddings {
      padding: 25px 4px 0 20px;
    }
    &.FLOOR_PLAN {
      padding-right: 3px; // side padding to align with editable slots
      padding-left: 36px; // side padding to align with editable slots
    }
    &.SLOTS_EDITOR {
      padding-right: 15px; // side padding to align with editable slots
      padding-left: 36px; // side padding to align with editable slots
    }
  }

  @media ${mediaQueries.isMobile} {
    gap: 4px;
    padding: 0 6px 0 4px;
    &.with-drawer-paddings {
      padding: 0 2px 0 2px;
    }
    &.FLOOR_PLAN {
      padding: 0 10px 0 0; // side padding to align with editable slots
    }
    &.SLOTS_EDITOR {
      padding: 0 10px 0 0; // side padding to align with editable slots
    }
  }
`

const Item = styled.li`
  width: 100%;
`

interface PropsType {
  location: LocationTree
  variant?: "purple"
}

export default function OccupationList({ location, variant }: PropsType) {
  const { from, to, userShowWeekends, openDrawer, viewMode } =
    usePlanningContext()

  const days = computePlanningDays(from, to, userShowWeekends)

  return (
    <Layout className={openDrawer ?? undefined} weekLength={days.length}>
      {days.map((d, n) => {
        return (
          <Item key={`loc-${location.id}-occ-${n}-${d.getUTCMinutes()}`}>
            {/* AM */}
            <OccupationSlot
              location={location}
              from={d}
              to={d}
              variant={variant}
              viewMode={viewMode}
            />
          </Item>
        )
      })}
    </Layout>
  )
}
