import { ApolloError, gql, useQuery } from "@apollo/client"
import { CompanySetting } from "types"

import { CompanySettingInlineFragment } from "./fragments/settingsFragments"

const GET_USER_SETTINGS_QUERY = gql`
  query getUserSettings {
    userSettings {
      ${CompanySettingInlineFragment}
    }
  }
`

export default function useUserSettings(): {
  loading: boolean
  error?: ApolloError
  userSettings: CompanySetting[]
} {
  const { loading, error, data } = useQuery(GET_USER_SETTINGS_QUERY)
  const userSettings: CompanySetting[] = data?.userSettings || []
  return {
    userSettings,
    loading,
    error,
  }
}
