import { ClickAwayListener } from "@mui/material"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useLocationsTree from "graphql/locations/useLocationsTree"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import getUTCSlotProps from "pages/PlanningPage/components/EditableUserSlots/utils/getUTCSlotProps"
import getSlotHoverInfo from "pages/PlanningPage/components/utils/getSlotHoverInfo"
import React from "react"
import styled from "styled-components/macro"
import { UTCSlot } from "types"
import { colors, L12, Tooltip } from "ui"
import LocationTree, { getParentsFromLocationId } from "utils/LocationTree"

import { NullSlot } from "./Slot/NullSlot"
import { Slot } from "./Slot/Slot"

const Backdrop = styled.div`
  z-index: 1000;
  position: fixed;
  inset: 0 0 0 0;
`

interface SlotItemLayoutPropsType {
  isFirst: boolean
  isLast: boolean
}

const SlotItemLayout = styled.div<SlotItemLayoutPropsType>`
  display: flex;
  align-items: center;
  background: ${colors.white};
  border: none;

  ${({ isFirst, isLast }) => {
    if (isFirst && isLast) return `padding: 0px 4px 0 4px;`
    if (isFirst) return `padding: 0 0 0 4px;`
    if (isLast) return `padding: 0 4px 0 0;`
    return `padding: 0px;`
  }}
`

interface PropsType {
  slot: UTCSlot
  slotIndex: number
  slots: UTCSlot[]
}

export default function UserSlotEntry({ slot, slotIndex, slots }: PropsType) {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()

  const { activeLocation, setActiveLocation, viewMode, userShowWeekends } =
    usePlanningContext()
  const { locations } = useLocationsTree()

  const [selectedSlot, setSelectedSlot] = React.useState<UTCSlot | undefined>()

  const open = slot.id === selectedSlot?.id

  const showSlotValue = (slot: UTCSlot) => {
    if (slot.locationId !== null && !activeLocation.persist) {
      const loc = LocationTree.getLocationNode(locations, slot.locationId)
      setActiveLocation({
        location: loc || null,
        persist: false,
        triggeredFrom: "planning",
      })
    }
    setSelectedSlot(slot)
  }

  const hideSlotValue = (slot: UTCSlot, forceReset = false) => {
    if (slot.locationId !== null && !activeLocation.persist) {
      setActiveLocation({
        location: null,
        persist: false,
        triggeredFrom: "planning",
      })
    }
    if (forceReset) {
      setActiveLocation({
        location: null,
        persist: false,
        triggeredFrom: "planning",
      })
    }
    setSelectedSlot(undefined)
  }

  const parentIsActive =
    slot.locationId !== null &&
    getParentsFromLocationId(locations, slot.locationId)?.find(
      (l) => l.id === activeLocation.location?.id
    ) !== undefined

  const isActive =
    activeLocation.location === null ||
    slot.locationId === activeLocation.location?.id ||
    parentIsActive

  const { isFirst, isLast } = getUTCSlotProps(
    slotIndex,
    slot,
    slots,
    [],
    viewMode,
    userShowWeekends
  )

  const tooltipStyle = {
    top: "-20px",
    padding: "4px 8px",
    backgroundColor: colors.grey3,
    color: colors.grey1,
    "& .MuiTooltip-arrow": {
      color: "#FFFFFF00",
    },
  }

  const MobileTooltipStyle = {
    ...tooltipStyle,
    top: "-8px",
    "& .MuiTooltip-arrow": {
      color: colors.grey3,
    },
  }

  const getTooltipContent = () => {
    if (slot.guests > 0 && slot.locationId) {
      return (
        <L12>
          <span style={{ color: colors.black }}>
            {getSlotHoverInfo(slot, locations, t)}
          </span>{" "}
          <span style={{ color: colors.grey1 }}>{`+ ${slot.guests} ${t(
            "Guests"
          ).toLowerCase()}`}</span>
        </L12>
      )
    }
    return (
      <L12 style={{ color: colors.black }}>
        {getSlotHoverInfo(slot, locations, t)}
      </L12>
    )
  }

  return (
    <>
      {isMobile && open && (
        <Backdrop
          onTouchStart={(e) => {
            e.stopPropagation()
          }}
          onTouchEnd={(e) => {
            e.preventDefault()
            e.stopPropagation()
            hideSlotValue(slot, true)
          }}
        />
      )}
      <Tooltip
        title={getTooltipContent()}
        placement="bottom"
        arrow={isMobile}
        open={open}
        tooltipStyle={
          isMobile
            ? {
                ...MobileTooltipStyle,
              }
            : {
                ...tooltipStyle,
              }
        }
      >
        <ClickAwayListener
          touchEvent="onTouchStart"
          onClickAway={(e) => {
            e.stopPropagation()
            if (open) hideSlotValue(slot)
          }}
        >
          <SlotItemLayout
            className="slot-item"
            isFirst={isFirst}
            isLast={isLast}
            onTouchStart={(e) => e.stopPropagation()}
          >
            {slot.value === null || slot.value === "null" ? (
              <NullSlot
                onTouchStart={() => {
                  if (isMobile) {
                    showSlotValue(slot)
                  }
                }}
              />
            ) : (
              <Slot
                className="user-slot-entry"
                onTouchStart={() => {
                  if (isMobile) {
                    showSlotValue(slot)
                  }
                }}
                onMouseEnter={() => {
                  showSlotValue(slot)
                }}
                onMouseLeave={() => {
                  hideSlotValue(slot)
                }}
                isFirst={isFirst}
                isLast={isLast}
                value={slot.value}
                isActive={isActive}
              >
                {slot.locationId && slot.guests > 0 ? (
                  <L12>+ {slot.guests}</L12>
                ) : (
                  <></>
                )}
              </Slot>
            )}
          </SlotItemLayout>
        </ClickAwayListener>
      </Tooltip>
    </>
  )
}
