import useUpdateManager from "graphql/users/useUpdateManager"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { User, UserBase } from "types"
import { Button, Dialog, P16 } from "ui"
import getUserDisplayName from "utils/getUserDisplayName"

const Content = styled.div`
  margin: 24px;
`

interface PropsType {
  onClose: () => void
  open: boolean
  title: string
  user: UserBase | undefined
  currentUser: User
}

export default function RemoveUserFromTeam({
  onClose,
  open,
  title,
  user,
  currentUser,
}: PropsType) {
  const t = useTranslate()
  const { updateManager } = useUpdateManager()

  if (user)
    return (
      <Dialog
        open={open}
        onClose={onClose}
        title={title}
        leftButton={
          <Button secondary onClick={onClose}>
            {t("Cancel")}
          </Button>
        }
        rightButton={
          <Button
            onClick={() => {
              updateManager("", user.id)
              onClose()
            }}
          >
            {t("Confirm")}
          </Button>
        }
      >
        {user && (
          <Content>
            <P16>
              {t("confirmation remove user from team")
                .replace("{username}", user.name)
                .replace(
                  "{teamname}",
                  t("user team", {
                    name: getUserDisplayName(
                      currentUser,
                      currentUser.company.flags
                    ),
                  })
                )}
            </P16>
          </Content>
        )}
      </Dialog>
    )
  else return <></>
}
