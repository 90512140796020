import PageContent from "components/Layout/PageLayout/PageContent/PageContent"
import AdminPageTitle from "components/Layout/PageLayout/PageHeader/AdminPageTitle/AdminPageTitle"
import PageHeader from "components/Layout/PageLayout/PageHeader/PageHeader"
import PageLayout from "components/Layout/PageLayout/PageLayout"
import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import useBulkSlotRules from "graphql/bulkSlots/useBulkSlotRules"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { Button, colors, P14Bold, P16, Table, TH, TR } from "ui"
import { Plus } from "ui/icons"

import NewRuleDialog from "./components/NewRuleDialog"
import Rule from "./components/Rule/Rule"

const TitleAction = styled.div`
  margin-top: auto;
  margin-left: auto;
`
const Rules = styled.div`
  border-top: 1px solid ${colors.grey3};
  .date {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
  .members-list {
    display: flex;
    gap: 8px;
  }
`

const NoSpecialDays = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export default function BulkChangeStatusPage() {
  const t = useTranslate()

  const { rules, loading, error } = useBulkSlotRules()

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const breadcrumb = [
    {
      name: t("Admin"),
      path: "/admin",
    },
    {
      name: t("special days"),
      path: "/admin/special-days",
    },
  ]

  if (loading || error) {
    return <LoaderOrErrorComponent loading={loading} error={error} />
  }

  return (
    <PageLayout>
      <PageHeader>
        <AdminPageTitle title={t("special days")} breadcrumbs={breadcrumb} />
        <TitleAction>
          <Button icon={<Plus />} onClick={() => setIsDialogOpen(true)}>
            {t("Add")}
          </Button>

          <NewRuleDialog
            open={isDialogOpen}
            onClose={() => setIsDialogOpen(false)}
          />
        </TitleAction>
      </PageHeader>

      <PageContent>
        {rules.length > 0 ? (
          <Rules>
            <Table>
              <thead>
                <TR>
                  <TH displayBorder>
                    <P14Bold>{t("title of the day")}</P14Bold>
                  </TH>
                  <TH displayBorder>
                    <P14Bold>{t("Date")}</P14Bold>
                  </TH>
                  <TH displayBorder>
                    <P14Bold>{t("attributed to")}</P14Bold>
                  </TH>
                  <TH displayBorder>
                    <P14Bold>{t("Status")}</P14Bold>
                  </TH>
                  <TH />
                </TR>
              </thead>
              <tbody>
                {rules.map((rule, i) => (
                  <Rule key={i} rule={rule} />
                ))}
              </tbody>
            </Table>
          </Rules>
        ) : (
          <NoSpecialDays>
            <P16>{t("no special days")}</P16>
          </NoSpecialDays>
        )}
      </PageContent>
    </PageLayout>
  )
}
