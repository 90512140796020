export const menuWidth = 216

export const leftDashedBorder = `
background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3cline x1='0%25' y1='4%25' x2='0%25' y2='100%25' width='100%25' height='100%25' fill='none' stroke='%23DFE1E1' stroke-width='1' stroke-dasharray='4%2c 4' stroke-linecap='round'/%3e%3c/svg%3e");
`

export const rightDashedBorder = `
background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3cline x1='100%25' y1='4%25' x2='100%25' y2='100%25' width='100%25' height='100%25' fill='none' stroke='%23DFE1E1' stroke-width='1' stroke-dasharray='4%2c 4' stroke-linecap='round'/%3e%3c/svg%3e");
`

export const doubleDashedBorder = `
background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3cline x1='0%25' y1='4%25' x2='0%25' y2='100%25' width='100%25' height='100%25' fill='none' stroke='%23DFE1E1' stroke-width='1' stroke-dasharray='4%2c 4' stroke-linecap='round'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3cline x1='100%25' y1='4%25' x2='100%25' y2='100%25' width='100%25' height='100%25' fill='none' stroke='%23DFE1E1' stroke-width='1' stroke-dasharray='4%2c 4' stroke-linecap='round'/%3e%3c/svg%3e");
`
