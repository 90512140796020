import React from "react"
import styled from "styled-components"
import { Equipment } from "types"
import { colors, L12, P16 } from "ui"
import { getServiceIcon } from "utils/getServiceIcons"

const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  border-radius: 50px;
  border: solid 1px ${colors.grey3};
  background: ${colors.grey4};

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)),
      #fafafa;
  }
`

const Counter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: rgba(0, 0, 0, 0.12);
  width: 18px;
  aspect-ratio: 1;
`

interface PropsType {
  equipment: Equipment
  onClick: () => void
}

export default function EquipmentItem({ equipment, onClick }: PropsType) {
  return (
    <Button onClick={onClick}>
      {getServiceIcon(equipment.icon).icon}
      <P16>{equipment.name}</P16>
      <Counter>
        <L12>{equipment.capacity}</L12>
      </Counter>
    </Button>
  )
}
