interface QueueItem {
  promise: () => Promise<void>
  resolve: (value: any) => void
  reject: (err: any) => void
}

export default class PromiseQueue {
  private queue: QueueItem[] = []
  private workingOnPromise = false

  enqueue(promise: () => Promise<void>) {
    return new Promise((resolve, reject) => {
      this.queue.push({
        promise,
        resolve,
        reject,
      })
      this.dequeue()
    })
  }

  dequeue() {
    if (this.workingOnPromise) {
      return false
    }
    const item = this.queue.shift()
    if (!item) {
      return false
    }
    try {
      this.workingOnPromise = true
      item
        .promise()
        .then((value: any) => {
          this.workingOnPromise = false
          item.resolve(value)
          this.dequeue()
        })
        .catch((err: any) => {
          this.workingOnPromise = false
          item.reject(err)
          this.dequeue()
        })
    } catch (err) {
      this.workingOnPromise = false
      item.reject(err)
      this.dequeue()
    }
    return true
  }
}
