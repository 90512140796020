import useCompanyHybridRules from "graphql/hybridPolicy/useCompanyHybridRules"
import useUpsertDefaultHybridRule from "graphql/hybridPolicy/useUpsertDefaultHybridRule"
import useUpsertGroupHybridRule from "graphql/hybridPolicy/useUpsertGroupHybridRule"
import useUpsertManagerHybridRule from "graphql/hybridPolicy/useUpsertManagerHybridRule"
import useUpsertUserHybridRule from "graphql/hybridPolicy/useUpsertUserHybridRule"
import useUserTree from "hooks/useUserTree"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import {
  DefaultHybridRule,
  GroupHybridRule,
  GroupHybridRuleInput,
  HybridRule,
  HybridRuleInput,
  ManagerHybridRule,
  PickedTargets,
  UserHybridRule,
} from "types"
import { AdminPopup } from "ui"
import UserTree from "utils/UserTree"

import HybridRuleForm from "./HybridRuleForm/HybridRuleForm"

function DefaultForm({
  initialHybridRule,
  isGroupRule,
  applyToManager,
  formInputs,
  setFormInputs,
  setApplyToManager,
  setIsGroupRule,
  setSelectedTargets,
  onCancel,
}: {
  initialHybridRule?: HybridRule
  isGroupRule: boolean
  applyToManager: boolean
  formInputs: GroupHybridRuleInput
  setFormInputs: (formInputs: GroupHybridRuleInput) => void
  setApplyToManager: (checked: boolean) => void
  setIsGroupRule: (checked: boolean) => void
  setSelectedTargets: (targets: PickedTargets) => void
  onCancel: () => void
}) {
  const { upsertDefaultHybridRule } = useUpsertDefaultHybridRule()

  const updateHybridWorkRule = (rule: HybridRuleInput) => {
    upsertDefaultHybridRule(rule).then(() => onCancel())
  }

  return (
    <HybridRuleForm
      type="default"
      initialHybridRule={
        initialHybridRule ? { ...initialHybridRule, name: "n/a" } : undefined
      }
      selectedTargets={{ users: [], managers: [], emails: [] }}
      setSelectedTargets={setSelectedTargets}
      isGroupRule={isGroupRule}
      applyToManager={applyToManager}
      formInputs={formInputs}
      setFormInputs={setFormInputs}
      setApplyToManager={setApplyToManager}
      setIsGroupRule={setIsGroupRule}
      updateHybridWorkRule={updateHybridWorkRule}
      onCancel={onCancel}
    />
  )
}

interface UserOrGroupFormPropsType {
  selectedTargets: PickedTargets
  singleTarget?: boolean
  isGroupRule: boolean
  applyToManager: boolean
  formInputs: GroupHybridRuleInput
  setFormInputs: (formInputs: GroupHybridRuleInput) => void
  setApplyToManager: (checked: boolean) => void
  setIsGroupRule: (checked: boolean) => void
  setSelectedTargets: (targets: PickedTargets) => void
  onCancel: () => void
}

function UserOrGroupForm({
  initialHybridRule,
  selectedTargets,
  isGroupRule,
  applyToManager,
  formInputs,
  setFormInputs,
  setApplyToManager,
  setIsGroupRule,
  updateHybridRule,
  setSelectedTargets,
  onCancel,
  singleTarget = false,
}: UserOrGroupFormPropsType & {
  initialHybridRule?: HybridRule
  selectedTargets: PickedTargets
  updateHybridRule: (rule: HybridRuleInput) => void
}) {
  return (
    <HybridRuleForm
      type="user"
      initialHybridRule={initialHybridRule}
      selectedTargets={selectedTargets}
      setSelectedTargets={setSelectedTargets}
      singleTarget={singleTarget}
      isGroupRule={isGroupRule}
      applyToManager={applyToManager}
      formInputs={formInputs}
      setFormInputs={setFormInputs}
      setApplyToManager={setApplyToManager}
      setIsGroupRule={setIsGroupRule}
      updateHybridWorkRule={updateHybridRule}
      onCancel={onCancel}
    />
  )
}

function UserFormWrapper({
  initialUserHyridRule,
  selectedTargets,
  setSelectedTargets,
  ...props
}: UserOrGroupFormPropsType & {
  userId?: string
  initialUserHyridRule?: UserHybridRule
}) {
  const { upsertUserHybridRule } = useUpsertUserHybridRule()
  const updateHybridWorkRule = (rule: HybridRuleInput) => {
    const ruleUserId = initialUserHyridRule
      ? initialUserHyridRule.userId
      : selectedTargets.users.length > 0
      ? selectedTargets.users[0].id
      : undefined
    if (!ruleUserId) return
    upsertUserHybridRule({
      ...rule,
      userId: ruleUserId,
    }).then(() => props.onCancel())
  }

  return (
    <UserOrGroupForm
      {...props}
      initialHybridRule={initialUserHyridRule}
      selectedTargets={selectedTargets}
      updateHybridRule={updateHybridWorkRule}
      setSelectedTargets={setSelectedTargets}
      singleTarget
    />
  )
}

function MangerFormWrapper({
  initialManagerHybridRule,
  selectedTargets,
  setSelectedTargets,
  applyToManager,
  ...props
}: UserOrGroupFormPropsType & {
  managerId?: string
  initialManagerHybridRule?: ManagerHybridRule
}) {
  const { upsertManagerHybridRule } = useUpsertManagerHybridRule()
  const updateHybridWorkRule = (rule: HybridRuleInput) => {
    const ruleMangerId = initialManagerHybridRule
      ? initialManagerHybridRule.managerId
      : selectedTargets.managers.length > 0
      ? selectedTargets.managers[0].id
      : undefined
    if (!ruleMangerId) return
    upsertManagerHybridRule({
      ...rule,
      managerId: ruleMangerId,
      appliesToManager: applyToManager,
    }).then(() => props.onCancel())
  }

  return (
    <UserOrGroupForm
      {...props}
      applyToManager={applyToManager}
      initialHybridRule={initialManagerHybridRule}
      selectedTargets={selectedTargets}
      updateHybridRule={updateHybridWorkRule}
      setSelectedTargets={setSelectedTargets}
      singleTarget
    />
  )
}

function GroupFormWrapper({
  groupId,
  selectedTargets,
  initialGroupHybridRule,
  ...props
}: UserOrGroupFormPropsType & {
  groupId?: string
  initialGroupHybridRule?: GroupHybridRule
}) {
  const { upsertGroupHybridRule } = useUpsertGroupHybridRule()
  const updateHybridWorkRule = (rule: HybridRuleInput) => {
    if (rule.name === "") return
    upsertGroupHybridRule({
      ...rule,
      groupId,
      userIds: [
        ...selectedTargets.users.map(({ id }) => id),
        ...selectedTargets.managers.map(({ id }) => id),
      ],
    }).then(() => props.onCancel())
  }
  return (
    <UserOrGroupForm
      {...props}
      initialHybridRule={initialGroupHybridRule}
      selectedTargets={selectedTargets}
      updateHybridRule={updateHybridWorkRule}
    />
  )
}

interface WrapperPropsType {
  initialUserHybridRule?: UserHybridRule
  initialManagerHybridRule?: ManagerHybridRule
  initialDefaultHybridRule?: DefaultHybridRule
  initialGroupHybridRule?: GroupHybridRule

  onCancel: () => void
}

function FormWrapper({
  userTree,
  initialDefaultHybridRule,
  initialUserHybridRule,
  initialManagerHybridRule,
  initialGroupHybridRule,
  onCancel,
}: WrapperPropsType & { userTree: UserTree[] }) {
  const getDefaultGroupRule = () => {
    if (
      initialDefaultHybridRule ||
      initialUserHybridRule ||
      initialManagerHybridRule
    )
      return false
    return true
  }
  const [isGroupRule, setIsGroupRule] = useState(getDefaultGroupRule())

  const [applyToManager, setApplyToManager] = useState(
    initialManagerHybridRule?.appliesToManager ?? false
  )
  const getInitialTargets = (userTree: UserTree[]): PickedTargets => {
    if (initialUserHybridRule) {
      const user = UserTree.findUserById(userTree, initialUserHybridRule.userId)
      return {
        users: user ? [user] : [],
        managers: [],
        emails: [],
      }
    }
    if (initialManagerHybridRule) {
      const manager = UserTree.findUserById(
        userTree,
        initialManagerHybridRule.managerId
      )
      return {
        users: [],
        managers: manager ? [manager] : [],
        emails: [],
      }
    }
    if (initialGroupHybridRule) {
      const targets = initialGroupHybridRule.userIds
        .map((id) => UserTree.findUserById(userTree, id))
        .filter((u): u is UserTree => u !== undefined)
      return {
        users: targets.filter((u) => !u.isManager),
        managers: targets.filter((u) => u.isManager),
        emails: [],
      }
    }

    return { users: [], managers: [], emails: [] }
  }
  const [selectedTargets, setSelectedTargets] = useState<PickedTargets>(
    getInitialTargets(userTree)
  )

  const getInitialHybriRule = (): GroupHybridRuleInput | undefined => {
    if (initialUserHybridRule)
      return {
        ...initialUserHybridRule,
        userIds: [initialUserHybridRule.userId],
      }
    if (initialGroupHybridRule) return initialGroupHybridRule
    if (initialManagerHybridRule) return initialManagerHybridRule
    if (initialDefaultHybridRule)
      return { ...initialDefaultHybridRule, userIds: [] }
    return undefined
  }

  const emptyHybridRule: GroupHybridRuleInput = {
    period: "week",
    maxDays: 1,
    restrictedDays: [],
    name: "",
    userIds: [],
  }
  const [formInputs, setFormInputs] = useState<GroupHybridRuleInput>(
    getInitialHybriRule() ?? emptyHybridRule
  )

  const { companyHybridRules, loading } = useCompanyHybridRules()
  const showCompanyForm =
    companyHybridRules.default === undefined ||
    initialDefaultHybridRule !== undefined

  if (loading) return <></>

  if (showCompanyForm)
    return (
      <DefaultForm
        initialHybridRule={initialDefaultHybridRule}
        isGroupRule={false}
        applyToManager={false}
        formInputs={formInputs}
        setFormInputs={setFormInputs}
        setApplyToManager={() => {
          //
        }}
        setIsGroupRule={() => {
          //
        }}
        setSelectedTargets={setSelectedTargets}
        onCancel={onCancel}
      />
    )

  if (isGroupRule)
    return (
      <GroupFormWrapper
        selectedTargets={selectedTargets}
        groupId={initialGroupHybridRule?.groupId}
        initialGroupHybridRule={initialGroupHybridRule}
        isGroupRule={isGroupRule}
        applyToManager={false}
        formInputs={formInputs}
        setFormInputs={setFormInputs}
        setApplyToManager={() => {
          //
        }}
        setIsGroupRule={setIsGroupRule}
        setSelectedTargets={setSelectedTargets}
        onCancel={onCancel}
      />
    )

  if (initialManagerHybridRule || selectedTargets.managers.length > 0)
    return (
      <MangerFormWrapper
        selectedTargets={selectedTargets}
        initialManagerHybridRule={initialManagerHybridRule}
        isGroupRule={isGroupRule}
        applyToManager={applyToManager}
        formInputs={formInputs}
        setFormInputs={setFormInputs}
        setApplyToManager={setApplyToManager}
        setIsGroupRule={setIsGroupRule}
        setSelectedTargets={setSelectedTargets}
        onCancel={onCancel}
        singleTarget
      />
    )

  return (
    <UserFormWrapper
      selectedTargets={selectedTargets}
      initialUserHyridRule={initialUserHybridRule}
      isGroupRule={isGroupRule}
      applyToManager={false}
      formInputs={formInputs}
      setFormInputs={setFormInputs}
      setApplyToManager={() => {
        //
      }}
      setIsGroupRule={setIsGroupRule}
      setSelectedTargets={setSelectedTargets}
      onCancel={onCancel}
      singleTarget
    />
  )
}

// Wrapper to avoid unmount/mount FormWrapper on toggling group rule on/off
// since each rule type has its specific form
// switching from one type to another unmount dialog
// therefore dialog is closed then reopened shortly after
export default function HybridRuleFormWrapper({ ...props }: WrapperPropsType) {
  const t = useTranslate()
  const { userTree, loading } = useUserTree()

  const isEdition =
    props.initialDefaultHybridRule ||
    props.initialGroupHybridRule ||
    props.initialUserHybridRule ||
    props.initialManagerHybridRule

  if (loading) return <></>

  return (
    <AdminPopup
      open
      title={
        isEdition
          ? t("hybrid-rule-form--title-edit")
          : t("hybrid-rule-form--title-add")
      }
      contentStyle={{
        padding: 0,
      }}
      onClose={props.onCancel}
    >
      <FormWrapper userTree={userTree} {...props} />
    </AdminPopup>
  )
}
