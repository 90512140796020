import { gql } from "@apollo/client"

export const CompanyIntegrationFragment = gql`
  fragment CompanyIntegrationFragment on CompanyIntegration {
    id
    externalId
    name
    flags
  }
`
