import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import React from "react"
import { BulkSlotRuleInput } from "types"

import SimpleWorkingModeButton from "./SimpleWorkingModeBtn"

interface PropsType {
  rule: BulkSlotRuleInput
  setRule: (value: (value: BulkSlotRuleInput) => BulkSlotRuleInput) => void
}

export default function StatusList({ rule, setRule }: PropsType) {
  const t = useTranslate()
  const { user: me } = useUser()

  const defineStatus = (status: string, label: string) => {
    setRule((prev) => ({
      ...prev,
      status: status,
      locationId: null,
      label: label,
    }))
  }

  if (me)
    return (
      <div className="status-list">
        <SimpleWorkingModeButton
          genericSlotValue="home"
          label={t("home")}
          rule={rule}
          saveStatus={defineStatus}
        />
        <SimpleWorkingModeButton
          genericSlotValue="else"
          label={t("else")}
          rule={rule}
          saveStatus={defineStatus}
        />
        <SimpleWorkingModeButton
          genericSlotValue="off"
          label={t("off")}
          rule={rule}
          saveStatus={defineStatus}
        />
      </div>
    )
  else return <></>
}
