import { ApolloError, gql, useQuery } from "@apollo/client"
import { User } from "types"

import { UserFragment } from "./fragments/userFragment"

export const GET_USER_FROM_ID_QUERY = gql`
  ${UserFragment}
  query getUser($id: ID!) {
    userFromId(id: $id) {
      ...UserFragment
    }
  }
`

export default function useUserFromId(id: string): {
  loading: boolean
  error?: ApolloError
  user: User | null
} {
  const { loading, error, data } = useQuery(GET_USER_FROM_ID_QUERY, {
    variables: { id },
    skip: !id,
  })
  return { user: data?.userFromId || null, loading, error }
}
