import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useTranslate from "intl/useTranslate"
import { CustomError } from "types"

export default function useCustomErrorToast() {
  const t = useTranslate()
  const { addToast } = useToasts()
  return {
    addToastError: (error: CustomError) => {
      const message = t(error.message, error.values)
      addToast(message, { appearance: "error" })
    },
  }
}
