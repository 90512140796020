import React from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { useTeamsAppContext } from "TeamsApp/TeamsAppContext"
import { Event, IntegrationEvent } from "types"
import { colors, LU14, mediaQueries, P16Bold } from "ui"

const Item = styled.div`
  background: ${colors.white};
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.24), 0px 2px 2px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  height: 56px;
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  width: 100%;
  cursor: pointer;
  @media ${mediaQueries.isDesktop} {
    max-width: 315px;
  }

  > img {
    height: 100%;
    border-radius: 8px 0px 0px 8px;
  }

  .right-side {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 8px 8px 8px 16px;

    p {
      max-width: 280px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    & :first-child {
      color: ${colors.purple};
    }

    & :last-child {
      color: ${colors.black};
    }
  }
`

interface PropsType {
  event: IntegrationEvent | Event
  onClose: () => void
}

export default function EventItem({ event, onClose }: PropsType) {
  const history = useHistory()
  const { isTeamsApp } = useTeamsAppContext()

  return (
    <Item
      onClick={() => {
        if (!isTeamsApp) {
          onClose()
          history.push(`/events/${event.id}`)
        }
      }}
    >
      <div className="right-side">
        <LU14>
          {event.from.format("HH:MM")} - {event.to.format("HH:MM")}
        </LU14>
        <P16Bold>{event.name}</P16Bold>
      </div>
    </Item>
  )
}
