import useCompanyFlags from "hooks/useCompanyFlags"
import useTranslate from "intl/useTranslate"
import React from "react"
import { AdminPopup, Tabs } from "ui"

import AddEmployeesFromCSV from "./AddEmployeesFromCSV"
import FormAddEmployees from "./FormAddEmployees"
import ProvisionningForm from "./Provisioning/ProvisionningForm"
import RawOIDCSSOForm from "./Provisioning/RawOIDCSSOForm"
import RawSAMLSSOForm from "./Provisioning/RawSAMLSSOForm"

interface PropsType {
  open: boolean
  onClose: () => void
}

export default function AddUsersModal({ open, onClose }: PropsType) {
  const t = useTranslate()
  const { companyFlags } = useCompanyFlags()

  const { enableRawSAMLSSO, enableRawOIDCSSO } = companyFlags

  const tabs = [
    {
      label: t("manual addition"),
      value: 0,
      component: <FormAddEmployees onClose={onClose} />,
    },
    {
      label: t("CSV"),
      value: 1,
      component: <AddEmployeesFromCSV onClose={onClose} />,
    },
    {
      label: t("okta"),
      value: 2,
      component: <ProvisionningForm onClose={onClose} provider="OKTA" />,
    },
    {
      label: "Rippling",
      value: 3,
      component: <ProvisionningForm onClose={onClose} provider="Rippling" />,
    },
    {
      label: "Azure directory",
      value: 4,
      component: <ProvisionningForm onClose={onClose} provider="AAD" />,
    },
  ]

  if (true || enableRawSAMLSSO) {
    tabs.push({
      label: "SAML",
      value: 5,
      component: <RawSAMLSSOForm onClose={onClose} />,
    })
  }

  if (enableRawOIDCSSO) {
    tabs.push({
      label: "OIDC",
      value: 6,
      component: <RawOIDCSSOForm onClose={onClose} />,
    })
  }

  return (
    <>
      <AdminPopup open={open} onClose={onClose} title={t("Add users")}>
        <Tabs tabs={tabs} />
      </AdminPopup>
    </>
  )
}
