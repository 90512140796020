import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useUser from "graphql/users/useUser"
import useMediaQueries from "hooks/useMediaQueries"
import React, { useContext, useState } from "react"
import { useHistory } from "react-router-dom"
import { UsersAtFilter } from "types"

interface HomeContextType {
  isMyTeamDisplay: boolean
  selectedPlanningGroup: "myteam" | "mymanagerteam" | "everyone" | null
  setSelectedPlanningGroup: (
    id: "myteam" | "mymanagerteam" | "everyone" | null
  ) => void
  selectMyTeamPlanning: () => void
  openHomeDialog: boolean
  onBubbleClick: (filter: UsersAtFilter) => void
  onPlanningLinkClick: () => void
  onCancelHome: () => void
  showHomeDialog: () => void
}

const emptyFunc = () => {
  //
}

const initialHomeContext: HomeContextType = {
  isMyTeamDisplay: true,
  selectedPlanningGroup: null,
  setSelectedPlanningGroup: emptyFunc,
  selectMyTeamPlanning: emptyFunc,
  openHomeDialog: false,
  onBubbleClick: emptyFunc,
  onPlanningLinkClick: emptyFunc,
  onCancelHome: emptyFunc,
  showHomeDialog: emptyFunc,
}

const HomeContext = React.createContext(initialHomeContext)

interface PropsType {
  children: React.ReactNode
}

export default function HomeContextProvider({ children }: PropsType) {
  const history = useHistory()
  const { user: me } = useUser()
  const { isMobile } = useMediaQueries()
  const { setUsersAtFilters, setViewMode } = usePlanningContext()
  const teamId =
    me && me.team.length > 0
      ? "myteam"
      : me && me.manager !== null
      ? "mymanagerteam"
      : null

  const [openHomeDialog, setOpenHomeDialog] = useState<boolean>(false)
  const [selectedPlanningGroup, setSelectedPlanningGroup] = useState<
    "myteam" | "mymanagerteam" | "everyone" | null
  >(teamId)
  const selectMyTeamPlanning = () => setSelectedPlanningGroup(teamId)
  const goToSelectedPlanning = () => {
    if (!selectedPlanningGroup) return history.push(`/`)
    history.push(`/planning/group/${selectedPlanningGroup}`)
  }

  const showHomeDialog = () => {
    setOpenHomeDialog(true)
    if (isMobile) setViewMode("DAY")
  }
  const onBubbleClick = (filter: UsersAtFilter) => {
    goToSelectedPlanning()
    setUsersAtFilters([filter])
    setOpenHomeDialog(false)
  }
  const onPlanningLinkClick = () => {
    setOpenHomeDialog(false)
    goToSelectedPlanning()
  }
  const onCancelHome = () => {
    setOpenHomeDialog(false)
  }

  const isMyTeamDisplay =
    selectedPlanningGroup === "myteam" ||
    selectedPlanningGroup === "mymanagerteam"

  const value: HomeContextType = {
    selectedPlanningGroup,
    setSelectedPlanningGroup,
    isMyTeamDisplay,
    selectMyTeamPlanning,
    openHomeDialog,
    onBubbleClick,
    onPlanningLinkClick,
    onCancelHome,
    showHomeDialog,
  }

  return <HomeContext.Provider value={value}>{children}</HomeContext.Provider>
}

export function useHomeContext() {
  return useContext(HomeContext)
}
