import FavoriteCheckbox from "components/FavoriteCheckbox"
import useJoinOrLeaveGroup from "graphql/groups/useJoinOrLeaveGroup"
import useMyGroupsSimple from "graphql/groups/useMyGroupsSimple"
import useUserFromId from "graphql/users/useUserFromId"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { HTMLAttributes } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components/macro"
import { Group as GroupType } from "types"
import { Button, colors, IconText, P14 } from "ui"
import { Group as GroupIcon } from "ui/icons"
import mediaQueries from "ui/mediaQueries"
import transitions from "ui/transitions"
import getUserDisplayName from "utils/getUserDisplayName"
import UTCDate from "utils/UTCDate"

const GroupContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 16px 8px 12px;
  border-top: solid 1px ${colors.grey3};
  cursor: pointer;

  .icon-text-help-container {
    display: flex;
    flex-direction: column;

    .group-name {
      display: flex;
      align-items: center;
      gap: 8px;

      > span {
        visibility: hidden;
        opacity: 0;
        transition: ${transitions.generic};
      }
    }

    .help-text-container {
      margin-left: 32px;
      font-size: 12px;
      line-height: 16px;
      color: ${colors.grey1};

      @media ${mediaQueries.isMobile} {
        margin-left: 16px;
      }
    }
  }

  &:hover .icon-text-help-container .group-name > span {
    visibility: visible;
    opacity: 1;
  }

  .group-button {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: auto;
  }

  .settings-button {
    visibility: hidden;
    opacity: 0;
  }

  &:hover {
    background: hsla(${colors.blackReal}, 0.04);

    .group-button {
      display: flex;
    }

    .settings-button {
      visibility: visible;
      opacity: 1;
    }
  }

  @media ${mediaQueries.isMobile} {
    .icon-text-help-container {
      .help-text-container {
        margin-left: 0;
      }
    }
    .group-button {
      button:last-child {
        width: unset;
      }
    }
  }

  transition: ${transitions.generic};
`

interface PropsType extends HTMLAttributes<HTMLDivElement> {
  group: GroupType
  withFavoriteCheckbox?: boolean
}

export function GroupListItem({
  group,
  withFavoriteCheckbox = false,
}: PropsType) {
  const t = useTranslate()
  const history = useHistory()
  const { isMobile } = useMediaQueries()
  const ELLIPSIS_MIN_CHAR = isMobile ? 14 : 30

  const { joinGroup, leaveGroup } = useJoinOrLeaveGroup()

  const createdBy: string | undefined = group?.flags.createdBy
  const createdAt: string | undefined = group?.flags.createdAt

  const { user: owner } = useUserFromId(createdBy ?? "")
  const { groups: myGroups } = useMyGroupsSimple()

  const isJoined = myGroups.some((g) => g.id === group.id)

  const getHelpText = () => {
    const nbUsers = group.users.length
    const helpText = t("n members").replace(
      "{count}",
      `${nbUsers !== null ? nbUsers : 0}`
    )
    if (owner && createdAt && !isMobile)
      return [
        helpText,
        t("group created by at date", {
          name: getUserDisplayName(owner, owner.company.flags),
          date: new UTCDate(new Date(createdAt)).toLocaleDateString(),
        }),
      ].join(" - ")
    return helpText
  }

  return (
    <GroupContainer onClick={() => history.push(`/group/${group.id}`)}>
      <div className="icon-text-help-container">
        <div className="group-name">
          <IconText
            withEllipsis={Boolean(group.name.length > ELLIPSIS_MIN_CHAR)}
            popoverChildren={<P14>{group.name}</P14>}
            icon={<GroupIcon />}
          >
            {group.name}
          </IconText>
          {withFavoriteCheckbox && (
            <FavoriteCheckbox itemId={group.id} type="group" />
          )}
        </div>
        <div className="help-text-container">{getHelpText()}</div>
      </div>
      <div className="group-button">
        {isJoined ? (
          <Button
            danger
            width={90}
            onClick={() => {
              leaveGroup(group.id)
            }}
            stopPropagation
          >
            {t("leave")}
          </Button>
        ) : (
          <Button
            width={90}
            onClick={() => {
              joinGroup(group.id)
            }}
            stopPropagation
          >
            {t("join")}
          </Button>
        )}
      </div>
    </GroupContainer>
  )
}
