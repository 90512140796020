import { Card, CardContent } from "@mui/material"
import React from "react"
import styled from "styled-components"
import { P16Bold } from "ui"
import colors from "ui/colors"

const CardContentStyled = styled(CardContent)`
  background-color: ${colors.grey4};
  > :first-child {
    margin-bottom: 16px;
  }
`

interface CutomLabelHeaderPropsTtype {
  connected: boolean
}
const CutomLabelHeader = styled.div<CutomLabelHeaderPropsTtype>`
  display: flex;
  align-items: center;
  gap: 8px;
`

interface PropsType {
  title: string
  connected: boolean
  children?: React.ReactNode | React.ReactNode[]
}

export default function IntegrationLayout({
  title,
  connected,
  children,
}: PropsType) {
  return (
    <Card>
      <CardContentStyled>
        <CutomLabelHeader connected={connected}>
          <P16Bold>{title}</P16Bold>
        </CutomLabelHeader>
        {children}
      </CardContentStyled>
    </Card>
  )
}
