import { useMediaQuery } from "@mui/material"
import { mediaQueries } from "ui"

export default function useMediaQueries() {
  return {
    isTouchable: useMediaQuery(mediaQueries.isTouchable),
    isMobile: useMediaQuery(mediaQueries.isMobile),
    isTablet: useMediaQuery(mediaQueries.isTablet),
    isDesktop: useMediaQuery(mediaQueries.isDesktop),
    isNarrowScreen: useMediaQuery(mediaQueries.isNarrowScreen),
  }
}
