import useMediaQueries from "hooks/useMediaQueries"
import React from "react"
import styled from "styled-components"
import colors from "ui/colors"
import mediaQueries from "ui/mediaQueries"
import { L12, P14 } from "ui/Typography"

export const CustomButton = styled.button`
  border: 1px dashed ${colors.grey3};
  border-radius: 4px;
  padding: 10px 16px;
  gap: 8px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:hover {
    background: ${colors.grey4};
  }

  &:focus,
  &:active {
    background: ${colors.grey3};
  }

  &.active {
    background: rgba(0, 0, 0, 0.04);
    border: none;
  }

  p {
    color: ${colors.purple};
    font-weight: 600;
    cursor: pointer;
  }
  svg {
    color: ${colors.purple};
    stroke-width: 1.5px;
    height: 18px;
    width: 18px;
    fill: transparent;
  }

  @media ${mediaQueries.isMobile} {
    width: 100%;
    border: solid 1px ${colors.grey3};
    padding: 7px 16px;

    svg {
      width: 24px;
      height: 24px;
    }

    &.active {
      justify-content: space-between;
    }
  }
`

interface PropsType {
  title: string
  icon?: JSX.Element
  active?: boolean
  iconToTheRight?: boolean
  onClick: () => void
}

export function DashedButton({
  title,
  icon,
  active,
  iconToTheRight,
  onClick,
}: PropsType) {
  const { isMobile } = useMediaQueries()
  if (isMobile)
    return (
      <CustomButton className={active ? "active" : ""} onClick={onClick}>
        {!iconToTheRight && icon}
        <P14>{title}</P14>
        {iconToTheRight && icon}
      </CustomButton>
    )
  return (
    <CustomButton className={active ? "active" : ""} onClick={onClick}>
      {!iconToTheRight && icon}
      <L12>{title}</L12>
      {iconToTheRight && icon}
    </CustomButton>
  )
}
