import useUpdateUserValues from "graphql/users/useUpdateUserValues"
import useUser from "graphql/users/useUser"
import useWindowSize from "hooks/useWindowResize"
import useTranslate from "intl/useTranslate"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Transition } from "react-transition-group"
import { Button, Grid, TextField } from "ui"

import {
  Content,
  Footer,
  Header,
  RightContainer,
  Title,
} from "./components/Layout"
import NavBack from "./components/NavBack"
import StepIndicator from "./components/StepIndicator"

const duration = 600

const defaultStyleSmallPopUp = {
  transition: `right ${duration}ms ease-in-out 400ms`,
  position: "absolute",
  top: "48%",
  width: "30%",
}
const defaultStyleMainPopUp = {
  transition: `right ${duration}ms ease-in-out 300ms`,
  position: "absolute",
  top: "25%",
}

const transitionStylesSmallPopUp: any = {
  entering: { right: 250 },
  entered: { right: 250 },
  exiting: { right: -300 },
  exited: { right: -300 },
}

const transitionStylesMainPop: any = {
  entering: { right: -80 },
  entered: { right: -80 },
  exiting: { right: -500 },
  exited: { right: -500 },
}

export default function OnboardingInfos() {
  const history = useHistory()
  const t = useTranslate()
  const { user } = useUser()
  const { loading, updateUserValues } = useUpdateUserValues()
  const [, maxHeight] = useWindowSize()

  const [userName, setUserName] = useState(user ? user.name : "")

  const bypassDisplayName = user ? user.company.flags.bypassDisplayName : true

  const [inProp, setInProp] = useState(false)

  useEffect(() => {
    setTimeout(() => setInProp(true), 1)
  }, [])

  return (
    <Grid container direction="row">
      <Grid
        item
        xs={12}
        md={8}
        xl={9}
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <Header>
          <NavBack realGoBack onClick={() => history.goBack()} />
        </Header>
        <Content maxHeight={maxHeight}>
          <Title>{t("tell us more about you")}</Title>
          <TextField
            value={userName}
            disabled={bypassDisplayName}
            label={t("your name")}
            placeholder="John Doe"
            handleChange={(e) => setUserName(e)}
          />
          <TextField
            value={user?.email}
            label={t("your email")}
            disabled
            handleChange={() => {
              //
            }}
          />
        </Content>
        <Footer>
          <StepIndicator />
          <Button
            disabled={loading}
            onClick={() => {
              if (userName && user && userName !== user.name)
                updateUserValues({ name: userName }, user.id)
              history.push("/onboarding/user/manager")
            }}
          >
            {t("continue")}
          </Button>
        </Footer>
      </Grid>

      <RightContainer item xs={0} md={4} xl={3}>
        <Transition in={inProp} timeout={duration}>
          {(state) => (
            <div
              style={{
                ...defaultStyleMainPopUp,
                ...transitionStylesMainPop[state],
              }}
            >
              <img
                alt="Fictitious users creation"
                src="/static/img/onboarding/pop_up1_step3.svg"
              />
            </div>
          )}
        </Transition>
        <Transition in={inProp} timeout={duration}>
          {(state) => (
            <div
              style={{
                ...defaultStyleSmallPopUp,
                ...transitionStylesSmallPopUp[state],
              }}
            >
              <img
                alt="Fictitious users creation"
                src="/static/img/onboarding/pop_up2_step3.svg"
              />
            </div>
          )}
        </Transition>
      </RightContainer>
    </Grid>
  )
}
