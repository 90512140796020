import { useEffect, useState } from "react"

export default function usePacman() {
  const [position, setPosition] = useState({ x: 0, y: 0 })

  const reset = () => {
    document.documentElement.style.setProperty("--pacman-visibility", `hidden`)
  }

  useEffect(() => {
    document.documentElement.style.setProperty("--pacman-x", `${position.x}px`)
    document.documentElement.style.setProperty("--pacman-y", `${position.y}px`)
    // document.documentElement.style.setProperty("--pacman-visibility", `hidden`)
  }, [position])

  return { position, setPosition, reset }
}
