import TargetPicker from "components/TargetPicker/TargetPicker"
import useDeleteEvent from "graphql/events/useDeleteEvent"
import useUpsertEvent from "graphql/events/useUpsertEvent"
import useUser from "graphql/users/useUser"
import useUserTree from "hooks/useUserTree"
import useTranslate from "intl/useTranslate"
import uniqBy from "lodash.uniqby"
import React, { useState } from "react"
import styled from "styled-components"
import { MeetingRoom, UpsertEventInput } from "types"
import {
  AdminPopup,
  Button,
  Checkbox,
  colors,
  FieldsetColumn,
  FieldsetRow,
  Label as UILabel,
  TextField,
} from "ui"
import UserTree from "utils/UserTree"

import DateAndTimePicker from "./DateAndTimePicker/DateAndTimePicker"
import Description from "./Description/Description"
import GuestListRecap from "./GuestListRecap/GuestListRecap"
import Privacy from "./Privacy/Privacy"
import RoomPicker from "./RoomPicker/RoomPicker"

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 100%;

  .clickable-text {
    cursor: pointer;
    width: fit-content;
  }

  > :last-child {
    margin-top: auto;
  }
`

const Label = styled(UILabel)`
  margin: 0;
`

const StyledFieldsetColumn = styled(FieldsetColumn)`
  border-top: 1px solid ${colors.grey4};
  padding-top: 12px;
`

export interface UpsertEventFormErrorsType {
  name?: string
  attendees?: string
  dates?: string
}

interface PropsType {
  event: UpsertEventInput
  meetingRooms: MeetingRoom[]
  onClose: () => void
}

interface FormPropsType extends PropsType {
  roomRequired?: boolean
}

export function EventForm({
  event,
  meetingRooms,
  roomRequired,
  onClose,
}: FormPropsType) {
  const t = useTranslate()
  const [inputs, setInputs] = useState<UpsertEventInput>({ ...event })
  const { userTree } = useUserTree()

  const [errors, setErrors] = useState<UpsertEventFormErrorsType>({})

  const attendeesAsUserTree = inputs.attendees
    .map((a) => UserTree.findUserByEmail(userTree, a.email))
    .filter((a): a is UserTree => a !== undefined)

  const attendeesAsGuests = inputs.attendees.filter(
    (a) => UserTree.findUserByEmail(userTree, a.email) === undefined
  )

  const getNameError = (name: string, roomRequired?: boolean) => {
    if (roomRequired) return undefined
    if (name !== "") return undefined
    return "required field"
  }

  const upsertMeetingRoomEvent = useUpsertEvent({ withRoomId: roomRequired })
  const deleteMeetingRoomEvent = useDeleteEvent()

  const addEmailAsAttendee = (
    email: string,
    disableFilterDuplicate = false
  ) => {
    if (disableFilterDuplicate) {
      return setInputs((prev) => ({
        ...prev,
        attendees: [
          ...prev.attendees.filter((a) => a.email !== email),
          { email, status: "pending" },
        ],
      }))
    }

    setInputs((prev) => {
      const found = inputs.attendees.find((a) => a.email === email)

      if (found)
        return {
          ...prev,
          attendees: prev.attendees.filter((a) => a.email !== email),
        }
      return {
        ...prev,
        attendees: [
          ...prev.attendees.filter((a) => a.email !== email),
          { email, status: "pending" },
        ],
      }
    })
  }

  const getSelectedMeetingRoom = () => {
    if (!roomRequired) return undefined
    const meetingRoom = meetingRooms.find(({ id }) => id === inputs.roomId)
    if (!meetingRoom) return undefined
    return meetingRoom
  }

  const isFormValid = () => {
    if (Object.values(errors).some((v) => v !== undefined && v !== ""))
      return false
    if (roomRequired && inputs.roomId === undefined) return false
    if (inputs.name === "" && !roomRequired) return false
    return true
  }

  const submitForm = () => {
    if (!isFormValid()) return
    upsertMeetingRoomEvent({ event: inputs, image: null })
    onClose()
  }

  return (
    <AdminPopup
      open
      onClose={onClose}
      title={`${inputs.id ? t("Update") : t("create")} ${
        roomRequired ? t("a meeting") : t("an event")
      }`}
      leftButton={
        <Button secondary onClick={onClose}>
          {t("Cancel")}
        </Button>
      }
      rightButton={
        <Button disabled={!isFormValid()} onClick={submitForm}>
          {t("Confirm")}
        </Button>
      }
    >
      <Form onSubmit={(e) => e.preventDefault()}>
        {roomRequired && (
          <RoomPicker
            meetingRooms={meetingRooms}
            inputs={inputs}
            errors={errors}
            setInputs={setInputs}
          />
        )}
        <FieldsetColumn>
          <Label className={!roomRequired ? "required" : undefined}>
            {!roomRequired ? t("event name") : t("title of the meeting")}
          </Label>
          <TextField
            autoFocus
            value={inputs.name}
            placeholder={
              !roomRequired
                ? t("event name example")
                : t("meeting title example")
            }
            error={errors.name !== undefined}
            helperText={errors.name ? t(errors.name) : ""}
            handleChange={(name) => {
              setErrors((prev) => ({
                ...prev,
                name: getNameError(name, roomRequired),
              }))
              setInputs((prev) => ({ ...prev, name }))
            }}
          />
        </FieldsetColumn>
        {!roomRequired && (
          <FieldsetColumn>
            <Label>{t("event location")}</Label>
            <TextField
              value={inputs.location}
              placeholder={t("event address")}
              handleChange={(location) => {
                setInputs((prev) => ({ ...prev, location }))
              }}
            />
          </FieldsetColumn>
        )}

        <Description inputs={inputs} setInputs={setInputs} />

        <DateAndTimePicker
          inputs={inputs}
          updateFrom={(from) => setInputs((prev) => ({ ...prev, from }))}
          updateTo={(to) => setInputs((prev) => ({ ...prev, to }))}
          onError={(error) => setErrors((prev) => ({ ...prev, dates: error }))}
        />
        {!location.pathname.startsWith("/meeting-rooms") && (
          <FieldsetRow>
            <Checkbox
              checked={inputs.isAllDay}
              onChange={() =>
                setInputs((prev) => ({ ...prev, isAllDay: !inputs.isAllDay }))
              }
            />
            <Label style={{ marginBottom: 0 }}>{t("whole day event")}</Label>
          </FieldsetRow>
        )}
        <StyledFieldsetColumn>
          <TargetPicker
            title={t("meeting-room-event-form--guests-button")}
            selectedTargets={{
              users: attendeesAsUserTree.filter(({ isManager }) => !isManager),
              managers: attendeesAsUserTree.filter(
                ({ isManager }) => isManager
              ),
              emails: attendeesAsGuests.map(({ email }) => email),
            }}
            disabled={{ groups: true }}
            options={{ disableMySelf: true }}
            meetingRoom={getSelectedMeetingRoom()}
            updateSelectedUsers={(users) => {
              for (const { email } of users) {
                addEmailAsAttendee(email)
              }
            }}
            updateSelectedManagers={(users) => {
              for (const { email } of users) {
                addEmailAsAttendee(email)
              }
            }}
            updateSelectedGroups={(groups) => {
              const groupsUsers = uniqBy(
                groups.map(({ users }) => users).flat(),
                "email"
              )
              for (const { email } of groupsUsers) {
                addEmailAsAttendee(email, true)
              }
            }}
            updateSelectedEmails={(emails) => {
              emails.forEach((email) => addEmailAsAttendee(email))
            }}
          />
        </StyledFieldsetColumn>

        <GuestListRecap
          meetingRoom={getSelectedMeetingRoom()}
          attendees={inputs.attendees}
        />

        <Privacy inputs={inputs} setInputs={setInputs} />
        {inputs.id && (
          <Button
            fullWidth
            danger
            disabled={!inputs.id || (roomRequired && !inputs.roomId)}
            onClick={() => {
              if (!inputs.id || (roomRequired && !inputs.roomId)) return
              deleteMeetingRoomEvent(inputs.id)
              onClose()
            }}
          >
            {t("Delete")}
          </Button>
        )}
      </Form>
    </AdminPopup>
  )
}

export default function Wrapper({ meetingRooms, event, ...props }: PropsType) {
  const { user: me } = useUser()

  if (event.organizer?.email !== me?.email) return <></>

  if (event.roomId === undefined)
    return <EventForm meetingRooms={meetingRooms} event={event} {...props} />

  return (
    <EventForm
      meetingRooms={meetingRooms}
      event={event}
      roomRequired
      {...props}
    />
  )
}
