import PageHeader from "components/Layout/PageLayout/PageHeader/PageHeader"
import MobileNavBack from "components/Layout/PageLayout/PageHeader/PageTitle/MobileNavBack/MobileNavBack"
import PageTitle from "components/Layout/PageLayout/PageHeader/PageTitle/PageTitle"
import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import ManagerSearch from "components/ManagerSearch/ManagerSearch"
import useUpdateManager from "graphql/users/useUpdateManager"
import useUserFromId from "graphql/users/useUserFromId"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { SearchResult, User } from "types"
import { Dialog, mediaQueries } from "ui"
import getUserDisplayName from "utils/getUserDisplayName"

import DeleteManagerButton from "./DeleteManagerButton/DeleteManagerButton"
import SaveManagerButton from "./SaveManagerButton/SaveManagerButton"

const Form = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media ${mediaQueries.isMobile} {
    .actions {
      flex-direction: column;
      gap: 4px;

      button {
        width: 100%;
      }
    }
  }
`

const MobileFooterActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`

interface PropsType {
  selectedUser: User
  open: boolean
  onClose: () => void
}

export default function UpdateManagerDialog({
  selectedUser,
  open,
  onClose,
}: PropsType) {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()

  const { updateManager } = useUpdateManager()
  const [pendingManager, setPendingManager] = useState<SearchResult | null>(
    null
  )
  const [mutating, setMutating] = useState(false)

  const { user: previousManager } = useUserFromId(
    selectedUser?.manager?.id ?? ""
  )
  const { user: newManager } = useUserFromId(
    pendingManager ? pendingManager.id : ""
  )

  const getUserAsSearchResult = (user: User) => ({
    id: user.id,
    type: "user",
    email: user.email,
    name: getUserDisplayName(user, user.company.flags),
  })

  const [initialValue, setInitialValue] = useState<SearchResult | undefined>(
    previousManager
      ? getUserAsSearchResult(previousManager)
      : newManager
      ? getUserAsSearchResult(newManager)
      : undefined
  )

  const isValidForm = () => {
    if (newManager?.id === previousManager?.id) return false
    return true
  }

  const mutateManager = () => {
    if (!isValidForm) return
    setMutating(true)
    updateManager(newManager?.email ?? "", selectedUser.id).then(() => {
      reset()
    })
  }

  const reset = () => {
    setMutating(false)
    setPendingManager(null)
    onClose()
  }

  // re set initial value when query done
  useEffect(() => {
    if (previousManager) setInitialValue(getUserAsSearchResult(previousManager))
  }, [previousManager])

  return (
    <Dialog
      open={open}
      onClose={() => {
        reset()
      }}
      title={isMobile ? undefined : t("Update Manager")}
      leftButton={
        isMobile ? (
          <MobileFooterActions>
            <DeleteManagerButton
              previousManager={previousManager}
              newManager={newManager}
              onClick={() => setPendingManager(null)}
              onConfirm={mutateManager}
            />
            <SaveManagerButton
              previousManager={previousManager}
              newManager={newManager}
              disabled={isValidForm()}
              onConfirm={mutateManager}
            />
          </MobileFooterActions>
        ) : (
          <DeleteManagerButton
            previousManager={previousManager}
            newManager={newManager}
            onClick={() => setPendingManager(null)}
            onConfirm={mutateManager}
          />
        )
      }
      rightButton={
        isMobile ? undefined : (
          <SaveManagerButton
            previousManager={previousManager}
            newManager={newManager}
            disabled={isValidForm()}
            onConfirm={mutateManager}
          />
        )
      }
      fullscreen={isMobile}
    >
      {isMobile && (
        <PageHeader>
          <MobileNavBack onClose={onClose} />
          <PageTitle title={t("Update Manager")} />
        </PageHeader>
      )}
      <Form>
        {mutating && <LoaderOrErrorComponent loading error={null} />}
        {!mutating && (
          <ManagerSearch
            variant="textfield"
            initialValue={initialValue}
            onSelect={(manager) => {
              setPendingManager(manager)
              setInitialValue(manager)
            }}
            handleClearForm={() => {
              setInitialValue(undefined)
              setPendingManager(null)
            }}
          />
        )}
      </Form>
    </Dialog>
  )
}
