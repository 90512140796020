import PageHeader from "components/Layout/PageLayout/PageHeader/PageHeader"
import MobileNavBack from "components/Layout/PageLayout/PageHeader/PageTitle/MobileNavBack/MobileNavBack"
import PageTitle from "components/Layout/PageLayout/PageHeader/PageTitle/PageTitle"
import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import MobileBottomNavigation from "components/MobileBottomNavigation/MobileBottomNavigation"
import { useSettingsContext } from "components/SettingsDialog/components/SettingsContextProvider"
import useUser from "graphql/users/useUser"
import useCompanyFlag from "hooks/useCompanyFlag"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { Dialog, mediaQueries } from "ui"

import CompanyFiles from "./components/CompanyFiles"
import Integrations from "./components/Integrations/Integrations"
import Menu from "./components/Menu/Menu"
import NotificationsDisplay from "./components/NotificationsDisplay"
import Services from "./components/OffishallCare/Services/Services"
import PersonalInformations from "./components/PersonalInformations"

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  height: 600px;
  overflow-y: scroll;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 80%;
  @media ${mediaQueries.isMobile} {
    width: 100vw;
    padding: 0px;
  }
`

export default function SettingsDialog() {
  const t = useTranslate()
  const careActive = Boolean(useCompanyFlag("care", false))
  const { isMobile } = useMediaQueries()
  const { user: currentUser, loading, error } = useUser()
  const { shouldDisplaySettings, setShouldDisplaySettings } =
    useSettingsContext()

  const [selectedAnchor, setSelectedAnchor] = useState({
    id: "personal information",
    text: t("personal information"),
  })
  const [shouldDisplayMenu, setShouldDisplayMenu] = useState(true)

  if (loading || error)
    return <LoaderOrErrorComponent loading={loading} error={error} />
  if (!currentUser || !shouldDisplaySettings) return <></>

  return (
    <Dialog
      open={shouldDisplaySettings}
      onClose={() => setShouldDisplaySettings(false)}
      maxWidth="md"
      fullscreen={isMobile}
      width="60%"
      transparentBackdrop={false}
    >
      <Layout>
        {shouldDisplayMenu && (
          <Menu
            selectedAnchor={selectedAnchor}
            setSelectedAnchor={setSelectedAnchor}
            setShouldDisplayMenu={setShouldDisplayMenu}
          />
        )}
        {((!shouldDisplayMenu && isMobile) || !isMobile) && (
          <Content>
            {isMobile && (
              <PageHeader>
                <MobileNavBack
                  onClose={() => {
                    setShouldDisplayMenu(true)
                    setSelectedAnchor({ id: "", text: "" })
                  }}
                />

                <PageTitle title={selectedAnchor.text} />
              </PageHeader>
            )}
            {selectedAnchor.id === "personal information" && (
              <PersonalInformations user={currentUser} />
            )}
            {selectedAnchor.id === "notifications & display" && (
              <NotificationsDisplay />
            )}
            {selectedAnchor.id === "integrations" && <Integrations />}
            {selectedAnchor.id === "corporate documents" && <CompanyFiles />}
            {selectedAnchor.id === "Services" && careActive && <Services />}
          </Content>
        )}
      </Layout>
      {isMobile && <MobileBottomNavigation />}
    </Dialog>
  )
}
