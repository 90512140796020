import useLocationOccupation from "hooks/useLocationOccupation"
import useMediaQueries from "hooks/useMediaQueries"
import FloorPlanLocationsList from "pages/PlanningPage/components/PlanningHeadersRouter/PlanningHeaders/LocationPlanningHeader/UserFloorPlanDrawer/FloorPlanLocationsList/FloorPlanLocationsList"
import React from "react"
import styled from "styled-components"
import { colors, L12, P14Bold } from "ui"
import LocationTree from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

const DirectParentLayout = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
  max-height: 120px;
`

const Counter = styled.div`
  border: solid 1px ${colors.grey3};
  border-radius: 100vh;
  padding: 0 4px;
`
const DeskAvailable = styled.div`
  display: flex;
  align-items: center;
  gap: 1px;
`

function MobileLocationTitle({
  location,
  from,
  to,
}: {
  location: LocationTree
  from: UTCDate
  to: UTCDate
}) {
  const { available } = useLocationOccupation(location, from, to)

  return (
    <DirectParentLayout>
      <P14Bold>{location.name}</P14Bold>
      <Counter>
        <DeskAvailable>
          <L12 color="black">{available}</L12>
          <L12 color="grey1">/{location.bookable}</L12>
        </DeskAvailable>
      </Counter>
    </DirectParentLayout>
  )
}

interface MobileLocationsListAsHeaderPropsType {
  from: UTCDate
  to: UTCDate
  selectedLocation: LocationTree | undefined
}

function MobileLocationsListAsHeader({
  from,
  to,
  selectedLocation,
}: MobileLocationsListAsHeaderPropsType) {
  if (!selectedLocation) return <></>
  return <MobileLocationTitle from={from} to={to} location={selectedLocation} />
}

interface PropsType {
  from: UTCDate
  to: UTCDate
  locations: LocationTree[]
  selectedLocation: LocationTree | undefined
  selectedFloorPlan: LocationTree | undefined
  maxHeight: number
  setSelectedLocation: (l: LocationTree | undefined) => void
}

export default function MobileSlotsEditorFloorPlanLocationsList({
  from,
  to,
  locations,
  selectedLocation,
  selectedFloorPlan,
  maxHeight,
  setSelectedLocation,
}: PropsType) {
  const { isMobile } = useMediaQueries()

  if (isMobile)
    return (
      <MobileLocationsListAsHeader
        from={from}
        to={to}
        selectedLocation={selectedLocation}
      />
    )

  return (
    <FloorPlanLocationsList
      from={from}
      to={to}
      locations={locations}
      selectedLocation={selectedLocation}
      selectedFloorPlan={selectedFloorPlan}
      maxHeight={maxHeight}
      onSelect={setSelectedLocation}
    />
  )
}
