import useCompanyFlags from "hooks/useCompanyFlags"
import React from "react"
import {
  DefaultHybridRule,
  DefaultHybridRuleScore,
  GroupHybridRule,
  ManagerHybridRule,
  UserHybridRule,
} from "types"
import UTCDate from "utils/UTCDate"

import { DropDownOptionType } from "../../HybridRulesBrowserButton"
import DefaultHybridRuleScores from "./DefaultHybridRuleScores/DefaultHybridRuleScores"
import MyTeamScores from "./MyTeamScores/MyTeamScores"
import UserHybridRuleScoreRow from "./UserHybridRuleScoreRow/UserHybridRuleScoreRow"

interface WrapperPropsType {
  rule: DropDownOptionType
  selectedDate: UTCDate
}

export default function UserHybridRuleScoreList({
  rule: {
    value: { rule, type },
  },
  selectedDate,
}: WrapperPropsType) {
  const { companyFlags, loading } = useCompanyFlags()
  if (loading) return <></>
  if (type === "user" && (rule as UserHybridRule).userId !== undefined)
    return (
      <UserHybridRuleScoreRow
        rule={rule}
        selectedDate={selectedDate}
        userId={(rule as UserHybridRule).userId}
        companyFlags={companyFlags}
      />
    )

  if (type === "manager" && (rule as ManagerHybridRule).managerId !== undefined)
    return (
      <>
        {(rule as ManagerHybridRule).userIds.map((userId, n) => (
          <UserHybridRuleScoreRow
            key={`${userId}-${n}`}
            rule={rule}
            selectedDate={selectedDate}
            userId={userId}
            companyFlags={companyFlags}
          />
        ))}
      </>
    )

  if (type === "group" && (rule as GroupHybridRule).groupId !== undefined)
    return (
      <>
        {(rule as GroupHybridRule).userIds.map((userId, n) => (
          <UserHybridRuleScoreRow
            key={`${userId}-${n}`}
            rule={rule}
            selectedDate={selectedDate}
            userId={userId}
            companyFlags={companyFlags}
          />
        ))}
      </>
    )
  if (type === "default" && (rule as DefaultHybridRule).score)
    return (
      <DefaultHybridRuleScores
        rule={rule as DefaultHybridRule & { score: DefaultHybridRuleScore }}
        selectedDate={selectedDate}
        companyFlags={companyFlags}
      />
    )
  if (type === "myteam")
    return (
      <MyTeamScores selectedDate={selectedDate} companyFlags={companyFlags} />
    )
  return <></>
}
