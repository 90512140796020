import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useUser from "graphql/users/useUser"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components/macro"
import { IntegrationEvent } from "types"
import { colors, mediaQueries } from "ui"
import { Cancel } from "ui/icons"
import filterEventsOfTheDay, {
  filterMeAsAttendingAttendeeEvents,
} from "utils/events"
import UTCDate from "utils/UTCDate"

import DayTitle from "./DayTitle/DayTitle"
import EventCounter from "./EventCounter/EventCounter"
import EventsOfTheDayPopup from "./EventsOfTheDayPopup/EventsOfTheDayPopup"

const Layout = styled.div`
  &.day-layout {
    display: flex;
    justify-content: space-between;
    background: ${colors.white};
    border: solid 1px ${colors.white};
    border-radius: 8px;
    cursor: pointer;
    padding-right: 4px;

    &:hover:not(.day-layout--selected) {
      background: ${colors.purple200};
      border: solid 1px ${colors.purple400};
    }

    &--today {
      background: ${colors.purple400};
    }

    &--selected {
      background: ${colors.purple};
      border: solid 1px ${colors.purple};
    }
  }

  @media ${mediaQueries.isMobile} {
    &.day-layout {
      justify-content: center;
      gap: 4px;
      padding-right: 0;
    }
  }
`

const CancelDaySelectionButton = styled(Cancel)`
  &.cancel-selection {
    transform: scale(0.85);
    stroke: ${colors.white};
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    border-radius: 8px;

    &--visible {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }
  }
`

interface DayPropsType {
  day: UTCDate
  userShowWeekend: boolean
  isSelected: boolean
  isToday: boolean
  eventsOfTheDayAttendingByMe: IntegrationEvent[]
  setPopupAnchorEl: (el: HTMLElement | null) => void
}

function Day({
  day,
  userShowWeekend,
  isSelected,
  isToday,
  eventsOfTheDayAttendingByMe,
  setPopupAnchorEl,
}: DayPropsType) {
  const t = useTranslate()
  const { isDesktop } = useMediaQueries()

  const getDayName = (day: UTCDate) => {
    if (isDesktop && !userShowWeekend)
      return `${t(day.getDayAsString()).substring(0, 3)}.`
    return `${t(day.getDayAsString()).substring(0, 1)}.`
  }
  const getDayNumber = (day: UTCDate) => `${day.getUTCDate()}`

  const getCancelSelectionClasses = () => {
    const base = "cancel-selection"
    if (isSelected) return `${base} cancel-selection--visible`
    return base
  }

  return (
    <>
      {isDesktop && (
        <CancelDaySelectionButton className={getCancelSelectionClasses()} />
      )}
      <DayTitle
        dayName={getDayName(day)}
        dayNumber={getDayNumber(day)}
        isSelected={isSelected}
        isToday={isToday}
        userShowWeekend={userShowWeekend}
      />
      <EventCounter
        events={eventsOfTheDayAttendingByMe}
        day={day}
        setPopupAnchorEl={setPopupAnchorEl}
      />
    </>
  )
}

interface WrapperPropsType {
  day: UTCDate
  events: IntegrationEvent[]
  userShowWeekend: boolean
  setSelectedEvent: (event: IntegrationEvent) => void
  setSelectedDay: (day: UTCDate) => void
}

export default function Wrapper({
  day,
  events,
  userShowWeekend,
  setSelectedDay,
  setSelectedEvent,
}: WrapperPropsType) {
  const { user: me } = useUser()
  const { isMobile } = useMediaQueries()
  const { focusedDate } = usePlanningContext()

  const isSelected = day.getTime() === focusedDate?.getTime()

  const [popupAnchorEl, setPopupAnchorEl] = useState<HTMLElement | null>(null)

  const eventsOfTheDayAttendingByMe = events
    .filter(filterEventsOfTheDay(day))
    .filter(filterMeAsAttendingAttendeeEvents(me))
    .filter((e): e is IntegrationEvent => e !== undefined)

  const isToday = day.getTime() === new UTCDate("DAY").getTime()

  const getLayoutClasses = () => {
    const base = isSelected ? "day-layout day-layout--selected" : "day-layout"

    if (day.isToday()) return `${base} day-layout--today`

    if (isMobile && userShowWeekend) {
      return `${base} day-layout--smaller`
    }
    if (isToday) {
      return `${base} day-layout--today`
    }

    return base
  }

  return (
    <Layout
      className={getLayoutClasses()}
      onClick={() => setSelectedDay(day)}
      onMouseLeave={() => setPopupAnchorEl(null)}
    >
      <Day
        day={day}
        isSelected={isSelected}
        isToday={isToday}
        userShowWeekend={userShowWeekend}
        eventsOfTheDayAttendingByMe={eventsOfTheDayAttendingByMe}
        setPopupAnchorEl={setPopupAnchorEl}
      />
      {popupAnchorEl && (
        <EventsOfTheDayPopup
          open={popupAnchorEl !== null}
          anchorEl={popupAnchorEl}
          events={eventsOfTheDayAttendingByMe}
          onLeave={() => setPopupAnchorEl(null)}
          onSelectEvent={setSelectedEvent}
        />
      )}
    </Layout>
  )
}
