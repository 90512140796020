import PageContent from "components/Layout/PageLayout/PageContent/PageContent"
import AdminPageTitle from "components/Layout/PageLayout/PageHeader/AdminPageTitle/AdminPageTitle"
import PageHeader from "components/Layout/PageLayout/PageHeader/PageHeader"
import PageLayout from "components/Layout/PageLayout/PageLayout"
import useTranslate from "intl/useTranslate"
import React from "react"

import CustomLabels from "./CustomLabelsContainer/CustomLabelsContainer"

export default function CompanyLabelsPage() {
  const t = useTranslate()

  const breadcrumb = [
    {
      name: t("Admin"),
      path: "/admin",
    },
    {
      name: t("Labels"),
      path: "/admin/labels",
    },
  ]

  return (
    <PageLayout>
      <PageHeader>
        <AdminPageTitle title={t("Company Labels")} breadcrumbs={breadcrumb} />
      </PageHeader>
      <PageContent>
        <CustomLabels />
      </PageContent>
    </PageLayout>
  )
}
