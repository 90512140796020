import PageHeader from "components/Layout/PageLayout/PageHeader/PageHeader"
import MobileNavBack from "components/Layout/PageLayout/PageHeader/PageTitle/MobileNavBack/MobileNavBack"
import PageTitle from "components/Layout/PageLayout/PageHeader/PageTitle/PageTitle"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useUpdateUserValues from "graphql/users/useUpdateUserValues"
import useUser from "graphql/users/useUser"
import useCompanyFlag from "hooks/useCompanyFlag"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import EmailInputText from "pages/AdminPage/UsersPage/NewEmployees/EmailInputText/EmailInputText"
import PasswordInputText, {
  getPasswordValidity,
  PasswordWithConfirmationInputText,
} from "pages/AdminPage/UsersPage/NewEmployees/PasswordInputText/PasswordInputText"
import React, { useState } from "react"
import styled from "styled-components"
import { User } from "types"
import { Button, CircularSpinner, Dialog, HeaderTextField } from "ui"
import { updatePassword } from "utils/firebase"

const Container = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

interface InputType {
  value?: string
  mutated: boolean
  error?: boolean
}

interface InputsType {
  email: InputType
  name: InputType
  oldPassword: InputType
  password: InputType
  confirmPassword: InputType
}

interface PropsType {
  user: User
  open: boolean
  onClose: () => void
}

export default function UpdateInformationsDialog({
  user,
  open,
  onClose,
}: PropsType) {
  const t = useTranslate()
  const { user: me } = useUser()
  const { isMobile } = useMediaQueries()
  const { updateUserValues } = useUpdateUserValues()
  const { addToast } = useToasts()

  const [showNewPasswordInputs, setShowNewPasswordInputs] = useState(false)
  const [mutationError, setMutationError] = useState<string | undefined>(
    undefined
  )
  const [mutationInProgress, setMutationInProgress] = useState(false)

  const bypassDisplayName = useCompanyFlag("bypassDisplayName", false)
  const useB4BPasswords = Boolean(me?.company?.flags?.B4BPasswords)

  const initialInputs: InputsType = {
    email: { value: user.email, mutated: false },
    name: { value: user.name, mutated: false },
    oldPassword: { mutated: false },
    password: { mutated: false },
    confirmPassword: { mutated: false },
  }
  const [inputs, setInputs] = useState<InputsType>(initialInputs)

  const haveFormMutation =
    Object.keys(inputs).find(
      (key) => inputs[key as keyof InputsType].mutated
    ) !== undefined
  const haveFormError =
    Object.keys(inputs).find((key) => inputs[key as keyof InputsType].error) !==
    undefined

  const saveForm = async () => {
    try {
      if (haveFormMutation && !haveFormError) {
        setMutationInProgress(true)
        if (inputs.name.mutated) {
          await updateUserValues({ name: inputs.name.value }, user.id)
        }
        if (
          inputs.password.mutated &&
          inputs.oldPassword.value &&
          inputs.password.value &&
          inputs.confirmPassword.value
        ) {
          if (inputs.password.value !== inputs.confirmPassword.value)
            return addToast(t("Both passwords need to be the same"), {
              appearance: "error",
            })
          const checkValidity = getPasswordValidity(useB4BPasswords)
          const { validity, errorText } = checkValidity(
            inputs.password.value,
            t
          )
          if (!validity)
            return addToast(errorText ?? t("invalid password"), {
              appearance: "error",
            })
          await updatePassword(inputs.oldPassword.value, inputs.password.value)
          addToast(t("Password changed successfully."), {
            appearance: "success",
          })
        }
      }
      onClose()
    } catch (e) {
      const errorText = e.message ? t(e.message) : t("Something went wrong")
      setMutationError(errorText)
      addToast(errorText, {
        appearance: "error",
      })
    } finally {
      setMutationInProgress(false)
    }
  }

  const disabledSaveForm = !haveFormMutation || haveFormError

  return (
    <Dialog
      open={open}
      onClose={() => {
        setInputs(initialInputs)
        onClose()
      }}
      title={isMobile ? undefined : t("edit personal information")}
      rightButton={
        <Button
          onClick={saveForm}
          disabled={disabledSaveForm || mutationInProgress}
          gap={8}
          fullWidth={isMobile}
        >
          {mutationInProgress && (
            <CircularSpinner size="medium" color="grey-200" />
          )}
          {t("Save")}
        </Button>
      }
      fullscreen={isMobile}
    >
      {isMobile && (
        <PageHeader>
          <MobileNavBack onClose={onClose} />
          <PageTitle title={t("edit personal information")} />
        </PageHeader>
      )}
      <Container>
        <HeaderTextField
          initialValue={inputs.name.value}
          handleChange={(name) =>
            setInputs((prev) => ({
              ...prev,
              name: {
                value: name,
                mutated: name !== user.name,
                error: name === "",
              },
            }))
          }
          placeholder={t("first last name")}
          disabled={bypassDisplayName}
        />
        <EmailInputText
          value={inputs.email.value ?? ""}
          initialValue={inputs.email.value}
          label={t("Email")}
          disabled
          onChange={(email, error) => {
            setInputs((prev) => ({
              ...prev,
              email: {
                value: email,
                mutated: email !== user.email,
                error,
              },
            }))
          }}
          onAddEmail={() => {
            //
          }}
        />

        <PasswordInputText
          value={inputs.oldPassword.value ?? ""}
          label={t("Old Password")}
          noAutoComplete
          onChange={(oldPassword, error) => {
            setInputs((prev) => ({
              ...prev,
              oldPassword: {
                value: oldPassword,
                mutated: false,
                error,
              },
            }))
          }}
          error={mutationError}
          onClick={() => {
            if (!showNewPasswordInputs) setShowNewPasswordInputs(true)
            setMutationError(undefined)
          }}
        />
        {showNewPasswordInputs && (
          <PasswordWithConfirmationInputText
            value={inputs.password.value ?? ""}
            confirmValue={inputs.confirmPassword.value ?? ""}
            label={t("New Password")}
            confirmLabel={t("Confirm Password")}
            showConfirmationOnMount
            noAutoComplete
            onChange={(password, error) => {
              setInputs((prev) => ({
                ...prev,
                password: {
                  value: password,
                  mutated: true,
                  error,
                },
              }))
            }}
            onConfirmChange={(confirmPassword, error) => {
              setInputs((prev) => ({
                ...prev,
                confirmPassword: {
                  value: confirmPassword,
                  mutated: true,
                  error,
                },
              }))
            }}
          />
        )}
      </Container>
    </Dialog>
  )
}
