import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { colors, P16, P16Bold } from "ui"

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  gap: 8px;
  background: ${colors.grey4};
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

interface PropsType {
  integrationName?: "microsoft-outlook" | "google-events"
  authorizeComponent: React.ReactNode
  revokeComponent: React.ReactNode
}

export default function CompanyWideIntegrationCard({
  integrationName,
  authorizeComponent: authorizeComponent,
  revokeComponent: revokeComponent,
}: PropsType) {
  const t = useTranslate()
  if (integrationName === undefined)
    return (
      <Card>
        <Header>
          <P16Bold>{t(`cwi-integration--card--title`)}</P16Bold>
        </Header>

        <>
          <P16>{t(`cwi-integration--card--description-title`)}</P16>
          <P16
            style={{
              paddingLeft: "32px",
            }}
          >
            &#x2022; {t(`cwi-integration--card--description-sentence-1`)}
          </P16>
          <P16
            style={{
              paddingLeft: "32px",
            }}
          >
            &#x2022; {t(`cwi-integration--card--description-sentence-2`)}
          </P16>
          <P16
            style={{
              paddingLeft: "32px",
            }}
          >
            &#x2022; {t(`cwi-integration--card--description-sentence-3`)}
          </P16>
        </>

        {authorizeComponent}
      </Card>
    )

  return (
    <Card>
      <Header>
        <P16Bold>{t(`cwi-${integrationName}--card--title`)}</P16Bold>
        {revokeComponent}
      </Header>
      <P16>{t(`cwi-${integrationName}--card--description--success`)}</P16>
      {authorizeComponent}
    </Card>
  )
}
