import { ApolloError, gql, useQuery } from "@apollo/client"
import { UserIntegration } from "types"

import { UserIntegrationFragment } from "./fragments/UserIntegrationFragments"

const QUERY = gql`
  ${UserIntegrationFragment}
  query userIntegrations {
    userIntegrations {
      ...UserIntegrationFragment
    }
  }
`

export default function useUserIntegrations(): {
  loading: boolean
  error?: ApolloError
  userIntegrations: UserIntegration[]
} {
  const { loading, error, data } = useQuery<{
    userIntegrations: UserIntegration[]
  }>(QUERY, { fetchPolicy: "cache-and-network" })
  return {
    userIntegrations: data?.userIntegrations || [],
    loading,
    error,
  }
}
