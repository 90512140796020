import { ClickAwayListener } from "@mui/material"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useLocationsTree from "graphql/locations/useLocationsTree"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import getUTCSlotProps from "pages/PlanningPage/components/EditableUserSlots/utils/getUTCSlotProps"
import getSlotHoverInfo from "pages/PlanningPage/components/utils/getSlotHoverInfo"
import React from "react"
import styled from "styled-components/macro"
import { UTCSlot, ViewModeType } from "types"
import { colors, L12, mediaQueries, Tooltip } from "ui"
import { leftDashedBorder, rightDashedBorder } from "ui/variables"
import LocationTree, { getParentsFromLocationId } from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

import { MonthWeekendEmptySlot } from "./Slot/MonthWeekendEmptySlot"
import { NullSlot } from "./Slot/NullSlot"
import { MonthViewNullSlot, Slot } from "./Slot/Slot"

function getLayoutBorders(
  isToday: boolean,
  isMorning: boolean,
  viewMode: ViewModeType,
  isLastIndex: boolean
) {
  if (viewMode === "MONTH" && isMorning) return leftDashedBorder
  if (viewMode === "MONTH" && isToday && !isMorning)
    return `border-left: solid 1px ${colors.purple600};`
  if (viewMode === "MONTH" && isLastIndex) return rightDashedBorder
  if (viewMode === "DAY") return `border: none;`

  if (isToday && isMorning) return leftDashedBorder
  if (isToday && !isMorning && viewMode === "WEEK") return rightDashedBorder
}

function getLayoutBackground(
  isWeekend: boolean,
  isToday: boolean,
  viewMode: ViewModeType
) {
  if (isWeekend && viewMode === "MONTH")
    return `
    background: ${colors.grey4};
    `

  if (viewMode === "WEEK" && isToday)
    return `
    background: ${colors.grey4};
    `

  return `background: ${colors.white};`
}

function getLayoutPadding(
  isFirst: boolean,
  isLast: boolean,
  viewMode: ViewModeType,
  isMobile = false
) {
  if (isMobile) {
    if (viewMode === "DAY") {
      if (isFirst && isLast) return `padding: 0px 4px 0 4px;`
      if (isFirst) return `padding: 0 0 0 4px;`
      if (isLast) return `padding: 0 4px 0 0;`
      return `padding: 0px;`
    } else {
      if (isFirst && isLast) return `padding: 18px 4px 0 4px;`
      if (isFirst) return `padding: 18px 0 0 4px;`
      if (isLast) return `padding: 18px 4px 0 0;`
      return `padding: 18px 0 0 0;`
    }
  }

  const pad = viewMode === "MONTH" ? 2 : 8

  if (isFirst && isLast) return `padding: 0 ${pad}px;`
  if (isFirst) return `padding: 0 0 0 ${pad}px;`
  if (isLast) return `padding: 0 ${pad}px 0 0;`
}

const Backdrop = styled.div`
  z-index: 1000;
  position: fixed;
  inset: 0 0 0 0;
`

interface SlotItemLayoutPropsType {
  isToday: boolean
  isMorning: boolean
  isFirst: boolean
  isLast: boolean
  isWeekend: boolean
  isLastIndex: boolean
  viewMode: ViewModeType
}

const SlotItemLayout = styled.div<SlotItemLayoutPropsType>`
  display: flex;
  align-items: center;
  ${({ isWeekend, isToday, viewMode }) =>
    getLayoutBackground(isWeekend, isToday, viewMode)}

  ${({ isToday, isMorning, viewMode, isLastIndex }) =>
    getLayoutBorders(isToday, isMorning, viewMode, isLastIndex)}

  ${({ isFirst, isLast, viewMode }) =>
    getLayoutPadding(isFirst, isLast, viewMode)}

  @media ${mediaQueries.isMobile} {
    ${({ isFirst, isLast, viewMode }) =>
      getLayoutPadding(isFirst, isLast, viewMode, true)}
  }
`

interface SlotItemPropsType {
  viewMode: ViewModeType
  isWeekend: boolean
  userShowWeekends: boolean
  isMobile: boolean
  isFirst: boolean
  isLast: boolean
  isActive: boolean
  slot: UTCSlot
  showSlotValue: (slot: UTCSlot) => void
  hideSlotValue: (slot: UTCSlot, forceReset?: boolean) => void
}

function SlotItem({
  viewMode,
  isWeekend,
  userShowWeekends,
  isMobile,
  isFirst,
  isLast,
  isActive,
  slot,
  showSlotValue,
  hideSlotValue,
}: SlotItemPropsType) {
  if (viewMode === "MONTH") {
    if (isWeekend && !userShowWeekends)
      return (
        <MonthWeekendEmptySlot
          onTouchStart={() => {
            if (isMobile) {
              showSlotValue(slot)
            }
          }}
        />
      )
    if (slot.value === null || slot.value === "null")
      return (
        <MonthViewNullSlot
          isFirst={isFirst}
          isLast={isLast}
          isActive={isActive}
          onTouchStart={() => {
            if (isMobile) {
              showSlotValue(slot)
            }
          }}
        />
      )
  }

  if (slot.value === null || slot.value === "null") {
    return (
      <NullSlot
        onTouchStart={() => {
          if (isMobile) {
            showSlotValue(slot)
          }
        }}
      />
    )
  }

  return (
    <Slot
      className="user-slot-entry"
      onTouchStart={() => {
        if (isMobile) {
          showSlotValue(slot)
        }
      }}
      onMouseEnter={() => {
        showSlotValue(slot)
      }}
      onMouseLeave={() => {
        hideSlotValue(slot)
      }}
      isFirst={isFirst}
      isLast={isLast}
      value={slot.value}
      isActive={isActive}
    >
      {slot.locationId && slot.guests > 0 ? <L12>+ {slot.guests}</L12> : <></>}
    </Slot>
  )
}

interface PropsType {
  slot: UTCSlot
  slotIndex: number
  slots: UTCSlot[]
  focusedSlot: UTCSlot | undefined
  setFocusedSlot: (slot: UTCSlot | undefined) => void
}

export default function UserSlotEntry({
  slot,
  slotIndex,
  slots,
  focusedSlot,
  setFocusedSlot,
}: PropsType) {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()

  const {
    activeLocation,
    setActiveLocation,
    focusedDate,
    viewMode,
    userShowWeekends,
  } = usePlanningContext()
  const { locations } = useLocationsTree()

  const open = slot.id === focusedSlot?.id

  const focusedDatePM = focusedDate
    ? new UTCDate(focusedDate, "NEXT-HALF-DAY")
    : undefined

  const showSlotValue = (slot: UTCSlot) => {
    if (slot.locationId !== null && !activeLocation.persist) {
      const loc = LocationTree.getLocationNode(locations, slot.locationId)
      setActiveLocation({
        location: loc || null,
        persist: false,
        triggeredFrom: "planning",
      })
    }
    setFocusedSlot(slot)
  }

  const hideSlotValue = (slot: UTCSlot, forceReset = false) => {
    if (slot.locationId !== null && !activeLocation.persist) {
      setActiveLocation({
        location: null,
        persist: false,
        triggeredFrom: "planning",
      })
    }
    if (forceReset) {
      setActiveLocation({
        location: null,
        persist: false,
        triggeredFrom: "planning",
      })
    }
    setFocusedSlot(undefined)
  }
  const isFocused =
    !focusedDate ||
    !focusedDatePM ||
    (slot.date.getTime() >= focusedDate.getTime() &&
      slot.date.getTime() <= focusedDatePM.getTime())

  const parentIsActive =
    slot.locationId !== null &&
    getParentsFromLocationId(locations, slot.locationId)?.find(
      (l) => l.id === activeLocation.location?.id
    ) !== undefined

  const isActive =
    (activeLocation.location === null && isFocused) ||
    (slot.locationId === activeLocation.location?.id && isFocused) ||
    parentIsActive

  const { isToday, isMorning, isFirst, isLast } = getUTCSlotProps(
    slotIndex,
    slot,
    slots,
    [],
    viewMode,
    userShowWeekends
  )
  const isWeekend = slot.date.isWeekend()

  const isLastIndex = slots.length - 1 === slotIndex

  const tooltipStyle = {
    top: "-20px",
    padding: "4px 8px",
    backgroundColor: colors.grey3,
    color: colors.grey1,
    "& .MuiTooltip-arrow": {
      color: "#FFFFFF00",
    },
  }

  const MobileTooltipStyle = {
    ...tooltipStyle,
    top: "-8px",
    "& .MuiTooltip-arrow": {
      color: colors.grey3,
    },
  }

  const getTooltipContent = () => {
    if (slot.guests > 0 && slot.locationId) {
      return (
        <L12>
          <span style={{ color: colors.black }}>
            {getSlotHoverInfo(slot, locations, t)}
          </span>{" "}
          <span style={{ color: colors.grey1 }}>{`+ ${slot.guests} ${t(
            "Guests"
          ).toLowerCase()}`}</span>
        </L12>
      )
    }
    return (
      <L12 style={{ color: colors.black }}>
        {getSlotHoverInfo(slot, locations, t)}
      </L12>
    )
  }

  return (
    <>
      {isMobile && open && (
        <Backdrop
          onTouchStart={(e) => {
            e.stopPropagation()
          }}
          onTouchEnd={(e) => {
            e.preventDefault()
            e.stopPropagation()
            hideSlotValue(slot, true)
          }}
        />
      )}
      <Tooltip
        title={getTooltipContent()}
        placement="bottom"
        arrow={isMobile}
        open={open}
        tooltipStyle={
          isMobile
            ? {
                ...MobileTooltipStyle,
              }
            : {
                ...tooltipStyle,
              }
        }
      >
        <ClickAwayListener
          touchEvent="onTouchStart"
          onClickAway={(e) => {
            e.stopPropagation()
            if (open) hideSlotValue(slot)
          }}
        >
          <SlotItemLayout
            className="slot-item"
            isToday={isToday}
            isMorning={isMorning}
            isFirst={isFirst}
            isLast={isLast}
            isWeekend={isWeekend}
            viewMode={viewMode}
            isLastIndex={isLastIndex}
            onTouchStart={(e) => e.stopPropagation()}
          >
            <SlotItem
              viewMode={viewMode}
              isWeekend={isWeekend}
              userShowWeekends={userShowWeekends}
              isMobile={isMobile}
              isFirst={isFirst}
              isLast={isLast}
              isActive={isActive}
              slot={slot}
              showSlotValue={showSlotValue}
              hideSlotValue={hideSlotValue}
            />
          </SlotItemLayout>
        </ClickAwayListener>
      </Tooltip>
    </>
  )
}
