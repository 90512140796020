import SearchBarForm from "components/Search/SearchBarForm/SearchBarForm"
import useTranslate from "intl/useTranslate"
import React, { useEffect, useState } from "react"
import { UserBase } from "types"
import { Search } from "ui/icons"

interface PropsType {
  groups: UserBase[]
  width: string
  onSubmit: (results: UserBase[]) => void
  onNewResults: (results: UserBase[]) => void
  onClose: () => void
}

export default function UserSearch({
  groups,
  width,
  onSubmit,
  onNewResults,
  onClose,
}: PropsType) {
  const t = useTranslate()

  const [searchString, setSearchString] = useState("")
  const [newResults, setNewResults] = useState<UserBase[]>([])
  const [previousResults, setPreviousResults] = useState<UserBase[]>([])
  const results: UserBase[] = groups.filter(
    (group) =>
      group.email.toLowerCase().includes(searchString.toLowerCase()) ||
      group.name.toLowerCase().includes(searchString.toLowerCase())
  )

  const resetForm = () => {
    setSearchString("")
    setNewResults([])
    onNewResults([])
  }

  useEffect(() => {
    if (searchString === "") return

    // do not change previous results if last part of search string has no result
    if (results.length === 0 && previousResults.length > 0) {
      // setNewResults(previousResults)
      // onNewResults(previousResults)

      return
    }

    if (results.length === newResults.length) {
      for (const result of results) {
        if (!newResults.find((nr) => nr.id === result.id)) {
          setPreviousResults(newResults)
          setNewResults(results)
          onNewResults(results)
          return
        }
      }
    } else {
      setPreviousResults(newResults)
      setNewResults(results)
      onNewResults(results)
    }
  }, [results, newResults, searchString, previousResults, onNewResults])

  return (
    <div style={{ width: width }}>
      <SearchBarForm
        searchString={searchString}
        handleChange={(str) => {
          if (searchString !== "" && str === "") onClose()
          setSearchString(str)
        }}
        resetForm={resetForm}
        icon={<Search />}
        placeholder={t("search users")}
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit(results)
          resetForm()
        }}
        autoFocus
      />
    </div>
  )
}
