import { gql, useQuery } from "@apollo/client"

const QUERY = gql`
  query getSigninProviderId($domain: String!) {
    getSigninProviderId(domain: $domain)
  }
`

export default function useSigninProviderId(
  domain: string,
  providedProviderId: string | null
) {
  const { data, loading, error } = useQuery(QUERY, {
    variables: { domain },
    skip: Boolean(providedProviderId),
  })

  if (providedProviderId) {
    return {
      loading: false,
      error: null,
      providerId: providedProviderId,
    }
  }

  const providerId = data?.getSigninProviderId

  return {
    loading,
    error,
    providerId,
  }
}
