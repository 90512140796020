import useUserHybridRule from "graphql/hybridPolicy/useUserHybridRule"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { useRef, useState } from "react"
import styled from "styled-components"
import {
  User,
  UserHybridRuleWithScore,
  UserRuleMonthScore,
  UserRuleWeekScore,
} from "types"
import { colors, DashedButton, Dialog, PopperMenu } from "ui"
import { Handbag } from "ui/icons"
import UTCDate from "utils/UTCDate"

import HybridRulePopupContent from "./HybridRulePopupContent/HybridRulePopupContent"

const Layout = styled.div`
  --animation-timing-function: ease-in-out;
  --animation-duration: 250ms;
  --animation-delay: 200ms;

  position: relative;
  cursor: pointer;
  svg {
    width: 16px;
    height: 16px;
    stroke: ${colors.grey1};
    &:hover {
      stroke: ${colors.black};
    }
  }
  div.user-hybrid-rule-status {
    position: absolute;
    display: flex;
    visibility: hidden;
    flex-direction: column;
    background: white;
    z-index: 1;
    box-shadow: ${colors.boxShadow};
    border-radius: 5px;
    width: fit-content;
    cursor: default;

    &.fade-in {
      visibility: visible;
      animation: fade-in;
      animation-duration: var(--animation-duration);
      animation-timing-function: var(--animation-timing-function);
    }

    &.fade-out {
      visibility: hidden;
      animation: fade-out;
      animation-delay: var(--animation-delay);
      animation-duration: var(--animation-duration);
      animation-timing-function: var(--animation-timing-function);
      transition-delay: var(--animation-delay);
    }

    transition: visibility var(--animation-duration)
      var(--animation-timing-function);
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fade-out {
    from {
      opacity: 1;
      pointer-events: none;
    }
    to {
      opacity: 0;
    }
  }
`

interface ComponentPropsType {
  userHybridRule: UserHybridRuleWithScore
  score: UserRuleWeekScore | UserRuleMonthScore | undefined
}

export function HybridRuleStatusTooltip({
  userHybridRule,
  score,
}: ComponentPropsType) {
  const popupRef = useRef<HTMLDivElement>(null)

  return (
    <Layout
      onMouseEnter={() => {
        popupRef.current?.classList.remove("fade-out")
        popupRef.current?.classList.add("fade-in")
      }}
      onMouseLeave={() => {
        popupRef.current?.classList.remove("fade-in")
        popupRef.current?.classList.add("fade-out")
      }}
    >
      <Handbag />
      <div ref={popupRef} className="user-hybrid-rule-status">
        <HybridRulePopupContent userHybridRule={userHybridRule} score={score} />
      </div>
    </Layout>
  )
}

export function HybridRuleStatusButton({
  userHybridRule,
  score,
}: ComponentPropsType) {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()
  const [open, setOpen] = useState(false)
  const buttonRef = useRef<HTMLDivElement>(null)

  return (
    <>
      <div ref={buttonRef}>
        <DashedButton
          title={t("Hybrid rule")}
          icon={<Handbag />}
          onClick={() => {
            setOpen(true)
          }}
        />
      </div>
      {isMobile ? (
        <Dialog
          open={open}
          title={t("Hybrid rule")}
          onClose={() => setOpen(false)}
          fullscreen
        >
          <HybridRulePopupContent
            userHybridRule={userHybridRule}
            score={score}
          />
        </Dialog>
      ) : (
        <PopperMenu
          anchorEl={buttonRef.current ?? undefined}
          open={open}
          onClose={() => setOpen(false)}
          style={{
            zIndex: 6000,
          }}
        >
          <HybridRulePopupContent
            userHybridRule={userHybridRule}
            score={score}
          />
        </PopperMenu>
      )}
    </>
  )
}

interface PropsType {
  selectedUser: User
  from?: UTCDate
  to?: UTCDate
  variant?: "tooltip" | "dashed-button"
}

export default function Fetcher({
  selectedUser,
  from,
  to,
  variant = "tooltip",
}: PropsType) {
  const { userHybridRule } = useUserHybridRule(selectedUser.id, from, to)

  const getScore = () => {
    if (!from || !to) return undefined
    if (userHybridRule?.period === "week")
      return userHybridRule?.weeksScores.find(
        ({ weekIndex }) => weekIndex === from.getWeekNumber()
      )
    return userHybridRule?.monthsScores.find(
      ({ monthIndex }) => monthIndex === from.getMonth()
    )
  }

  if (!userHybridRule) return <></>

  if (variant === "tooltip")
    return (
      <HybridRuleStatusTooltip
        userHybridRule={userHybridRule}
        score={getScore()}
      />
    )

  return (
    <HybridRuleStatusButton
      userHybridRule={userHybridRule}
      score={getScore()}
    />
  )
}
