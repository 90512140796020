import CreateGroupModal from "components/CreateGroupModal/CreateGroupModal"
import PageContent from "components/Layout/PageLayout/PageContent/PageContent"
import PageFooter from "components/Layout/PageLayout/PageFooter/PageFooter"
import PageHeader from "components/Layout/PageLayout/PageHeader/PageHeader"
import MobileNavBack from "components/Layout/PageLayout/PageHeader/PageTitle/MobileNavBack/MobileNavBack"
import PageTitle from "components/Layout/PageLayout/PageHeader/PageTitle/PageTitle"
import PageLayout from "components/Layout/PageLayout/PageLayout"
import useAllGroups from "graphql/groups/useAllGroups"
import useUser from "graphql/users/useUser"
import useCompanyFlag from "hooks/useCompanyFlag"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { Group, GroupSimple } from "types"
import { Button, colors, GroupListItem, mediaQueries } from "ui"
import sortByKey from "utils/sortByKey"

import GroupsSearchComponent from "./GroupsSearchComponent"

const SearchContainer = styled.div`
  @media ${mediaQueries.isMobile} {
    padding-bottom: 16px;
  }
`

const CreateGroupButton = styled(Button)`
  white-space: nowrap;

  @media ${mediaQueries.isMobile} {
    width: 100%;
  }
`

const GroupsListLayout = styled.div`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  max-height: 92%;
  margin-top: 16px;

  @media ${mediaQueries.isMobile} {
    margin: 0px;
  }

  ul {
    display: flex;
    flex-direction: column;

    li.btn-clear-filter {
      margin-left: auto;
      padding: 0 0 8px 0;
    }

    li:last-child > div {
      border-bottom: solid 1px ${colors.grey3};
    }
  }
`

export default function GroupsPage() {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()

  const { groups: allGroups } = useAllGroups()
  const [groupsFiltered, setGroupsFiltered] = useState<Group[]>([])
  const { user: me } = useUser()

  const canCreateGroup =
    useCompanyFlag("userCanCreateGroups", true) !== false ||
    me?.role === "admin"

  const [openCreateGroupModal, setOpenCreateGroupModal] =
    useState<boolean>(false)

  const updateGroupsFiltered = (groupsFiltered: GroupSimple[]) => {
    if (groupsFiltered.length === allGroups.length) return setGroupsFiltered([])
    setGroupsFiltered(
      groupsFiltered
        .map((filteredGroup) =>
          allGroups.find((g) => g.id === filteredGroup.id)
        )
        .filter((g: Group | undefined): g is Group => g !== undefined)
    )
  }

  if (!me) return null

  return (
    <>
      <PageLayout>
        {isMobile ? (
          <PageHeader>
            <MobileNavBack />
            <PageTitle title={t("Groups")} />
          </PageHeader>
        ) : (
          <PageHeader>
            <PageTitle title={t("Groups")} />
            {canCreateGroup && (
              <CreateGroupButton onClick={() => setOpenCreateGroupModal(true)}>
                {t("create a group")}
              </CreateGroupButton>
            )}
          </PageHeader>
        )}

        <PageContent>
          <SearchContainer>
            <GroupsSearchComponent
              groups={allGroups}
              onSubmit={updateGroupsFiltered}
              onNewResults={(results) => updateGroupsFiltered(results)}
            />
          </SearchContainer>

          <GroupsListLayout>
            <ul>
              {sortByKey(
                groupsFiltered.length > 0 ? groupsFiltered : allGroups
              ).map((group) => {
                return (
                  <li key={group.id}>
                    <GroupListItem group={group} />
                  </li>
                )
              })}
            </ul>
          </GroupsListLayout>
        </PageContent>
        {isMobile && canCreateGroup && (
          <PageFooter>
            <Button
              onClick={() => {
                return setOpenCreateGroupModal(true)
                // goToGroupsCreationPage()
              }}
            >
              {t("create a group")}
            </Button>
          </PageFooter>
        )}
      </PageLayout>
      {openCreateGroupModal && (
        <CreateGroupModal
          open={openCreateGroupModal}
          me={me}
          onClose={() => setOpenCreateGroupModal(false)}
        />
      )}
    </>
  )
}
