import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function Handbag({
  stroke,
  strokeWidth,
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      strokeWidth={strokeWidth ?? 1.5}
      stroke={stroke ? stroke : "currentColor"}
      {...rest}
    >
      <path
        d="M9 8H4a2 2 0 00-2 2v9a2 2 0 002 2h16a2 2 0 002-2v-9a2 2 0 00-2-2h-5M9 8V3.6a.6.6 0 01.6-.6h4.8a.6.6 0 01.6.6V8M9 8h6M9 8v6m6-6v6"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}
