import { gql, useQuery } from "@apollo/client"
import { MeetingRoom } from "types"

import { MeetingRoomFragment } from "./fragments/meetingRoomFragments"

const QUERY = gql`
  ${MeetingRoomFragment}
  query integrations_meetingRooms {
    integrations_meetingRooms {
      ...MeetingRoomFragment
    }
  }
`

export default function useMeetingRooms() {
  const { loading, error, data } = useQuery<{
    integrations_meetingRooms: MeetingRoom[]
  }>(QUERY)

  return {
    loading,
    error,
    meetingRooms: data?.integrations_meetingRooms ?? [],
  }
}
