import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { IntegrationEvent, UpsertEventInput } from "types"
import { colors, H4, mediaQueries, P16, P16Bold, transitions } from "ui"

import CollapseEvents from "./CollapseEvents/CollapseEvents"
import EventItem from "./EventItem/EventItem"
import getFilteredEvents from "./getFilteredEvents"

const NoEventsMessage = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-bottom: solid 1px ${colors.grey3};
  cursor: default;

  padding: 24px 0;

  :first-child {
    padding: 0 0 24px 0;
  }

  @media ${mediaQueries.isMobile} {
    padding: 24px 0;
  }

  &:last-child {
    border: none;
  }

  .header {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    width: fit-content;

    .arrow-btn {
      &--turned {
        transform: rotate(-90deg);
      }
      transition: ${transitions.generic};
    }
  }
`

function sortChronologically(
  a: IntegrationEvent,
  b: IntegrationEvent
): 1 | -1 | 0 {
  if (a.from.getTime() < b.from.getTime()) return -1
  if (a.from.getTime() > b.from.getTime()) return 1
  return 0
}

interface WrapperPropsType {
  events: IntegrationEvent[]
  setEventToBeEdited: (event: UpsertEventInput) => void
}

interface PropsType extends WrapperPropsType {
  title: string
}

function EventList({ events, title, setEventToBeEdited }: PropsType) {
  const { user: me } = useUser()

  const checkEventEditPermission = (event: IntegrationEvent) => {
    if (event.roomId) return false
    if (me?.role === "admin") return true
    if (event.organizer?.email === me?.email) return true
    return false
  }
  const sortedEvents = events.sort(sortChronologically)

  if (sortedEvents.length === 0) return <></>

  return (
    <List>
      <H4>{title}</H4>
      {sortedEvents.map((e) => (
        <EventItem
          key={`${title}-evt-${e.id}`}
          event={e}
          isEditable={checkEventEditPermission(e)}
          setEventToBeEdited={setEventToBeEdited}
        />
      ))}
    </List>
  )
}

export default function Wrapper({
  events,
  setEventToBeEdited,
}: WrapperPropsType) {
  const t = useTranslate()

  const todayEvents = getFilteredEvents(events, "TODAY")
  const thisWeekEvents = getFilteredEvents(events, "THIS-WEEK")
  const laterEvents = getFilteredEvents(events, "LATER")
  const pastEvents = getFilteredEvents(events, "PAST")
  const noEventInFuture =
    laterEvents.length === 0 &&
    thisWeekEvents.length === 0 &&
    todayEvents.length === 0

  const noEvents = noEventInFuture && pastEvents.length === 0

  if (noEvents)
    return (
      <NoEventsMessage>
        <P16Bold>{t("no event create yet")}</P16Bold>
      </NoEventsMessage>
    )

  return (
    <>
      {noEventInFuture ? (
        <P16>{t("you have no upcoming events")}</P16>
      ) : (
        <>
          <EventList
            title={t("Today")}
            events={todayEvents}
            setEventToBeEdited={setEventToBeEdited}
          />
          <EventList
            title={t("This week")}
            events={thisWeekEvents}
            setEventToBeEdited={setEventToBeEdited}
          />
          <EventList
            title={t("Later")}
            events={laterEvents}
            setEventToBeEdited={setEventToBeEdited}
          />
        </>
      )}

      <CollapseEvents
        title={t("Past")}
        events={pastEvents}
        setEventToBeEdited={setEventToBeEdited}
      />
    </>
  )
}
