import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components/macro"
import { colors, L12, P16 } from "ui"
import getColorFromStatus from "utils/getColorFromStatus"
import UTCDate from "utils/UTCDate"

const Layout = styled.div`
  display: flex;
  background: ${colors.white};
  justify-content: center;
  gap: 4px;
`
interface DayTitleLayoutProps {
  todayStatus?: { AM: string | null; PM: string | null }
  isToday: boolean | undefined
}

const DayTitleLayout = styled.span<DayTitleLayoutProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ isToday }) => {
    if (!isToday) return "gap: 4px;"
    return `gap: 2px;`
  }}

  .today {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ todayStatus }) => {
      if (todayStatus)
        return `background: linear-gradient(
            to right,
            ${getColorFromStatus(todayStatus.AM)} 0%,
            ${getColorFromStatus(todayStatus.AM)}  50%,
            ${getColorFromStatus(todayStatus.PM)}  50%,
            ${getColorFromStatus(todayStatus.PM)}  100%
        );`
    }}
  }

  .selectedDate {
    width: 22px;
    height: 22px;
    border-radius: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.grey1};
  }

  .weekend {
    background-color: ${colors.grey2};
  }
`

interface DayTitlePropsType {
  isToday?: boolean
  dayName: string
  dayNumber: string
  isWeekend: boolean
  todayStatus: { AM: string | null; PM: string | null }
  day: UTCDate
  selectedDate: UTCDate
}

function DayTitle({
  isToday,
  dayName,
  dayNumber,
  isWeekend,
  todayStatus,
  day,
  selectedDate,
}: DayTitlePropsType) {
  const isSelectedDate = day.getTime() === selectedDate.getTime()

  if (isWeekend && !isToday)
    return (
      <DayTitleLayout isToday={isToday}>
        <L12 color="grey2">{dayName}</L12>
        {isSelectedDate ? (
          <div className="selectedDate weekend">
            <P16 color="white">{dayNumber}</P16>
          </div>
        ) : (
          <L12 color="grey2">{dayNumber}</L12>
        )}
      </DayTitleLayout>
    )
  if (!isToday)
    return (
      <DayTitleLayout isToday={isToday}>
        <L12 color="purple">{dayName}</L12>
        <div className={isSelectedDate ? "selectedDate" : ""}>
          <P16 color={isSelectedDate ? "white" : "purple"}>{dayNumber}</P16>
        </div>
      </DayTitleLayout>
    )

  return (
    <DayTitleLayout todayStatus={todayStatus} isToday={isToday}>
      <L12 color="purple">{dayName}</L12>
      <div className="today">
        <P16 color="purple">{dayNumber}</P16>
      </div>
    </DayTitleLayout>
  )
}

interface DayPropsType {
  dayName: string
  dayNumber: string
  isWeekend: boolean
  todayStatus: { AM: string | null; PM: string | null }
  day: UTCDate
  selectedDate: UTCDate
}

/**
 * Content of the top calendar when user is on a desktop
 */
function GenericDay({
  dayName,
  dayNumber,
  isWeekend,
  todayStatus,
  day,
  selectedDate,
}: DayPropsType) {
  const { setSelectedDate } = usePlanningContext()
  return (
    <Layout onClick={() => setSelectedDate(day)}>
      <DayTitle
        dayName={dayName}
        dayNumber={dayNumber}
        isWeekend={isWeekend}
        todayStatus={todayStatus}
        day={day}
        selectedDate={selectedDate}
      />
    </Layout>
  )
}

function Today({
  dayName,
  dayNumber,
  isWeekend,
  todayStatus,
  day,
  selectedDate,
}: DayPropsType) {
  const { setSelectedDate } = usePlanningContext()
  return (
    <Layout onClick={() => setSelectedDate(day)}>
      <DayTitle
        dayName={dayName}
        dayNumber={dayNumber}
        isWeekend={isWeekend}
        isToday
        todayStatus={todayStatus}
        day={day}
        selectedDate={selectedDate}
      />
    </Layout>
  )
}

interface PropsType {
  day: UTCDate
  userShowWeekend: boolean
  todayStatus: { AM: string | null; PM: string | null }
}

export default function Day({ day, userShowWeekend, todayStatus }: PropsType) {
  const t = useTranslate()
  const { selectedDate } = usePlanningContext()

  const isToday = day.isToday()

  const getDayName = (day: UTCDate) => {
    return `${t(day.getDayAsString()).substring(0, 1)}`
  }

  const getDayNumber = (day: UTCDate) => `${day.getUTCDate()}`

  const sharedProps = {
    dayName: getDayName(day),
    dayNumber: getDayNumber(day),
    userShowWeekend,
    isWeekend: day.isWeekend(),
    todayStatus: todayStatus,
    day: day,
    selectedDate: selectedDate,
  }

  if (isToday && selectedDate.isToday()) return <Today {...sharedProps} />
  return <GenericDay {...sharedProps} />
}
