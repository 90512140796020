import React from "react"
import styled from "styled-components"
import { colors } from "ui"

const Container = styled.div`
  display: flex;
  width: 100%;
`

const TimeInput = styled.input.attrs({ type: "time" })`
  &.time-input {
    padding: 10px 16px;
    border: solid 1px ${colors.grey3};
    border-radius: 4px;
    font-family: Source Sans Pro;
    line-height: 18px;
    color: ${colors.black};
    cursor: pointer;
    background-color: ${colors.white};
    height: 40px;
    flex: 2;
    font-weight: 400;
    font-size: 14px;
    text-align: start;

    &:disabled {
      background: ${colors.grey4};
      color: ${colors.grey2};
      cursor: not-allowed;
    }
  }

  &.time-input--error {
    color: ${colors.redAlert};
    outline: solid 1px ${colors.redAlert};
  }

  &.time-input:focus:not(.time-input--error) {
    outline: solid 1px ${colors.green};
  }
`

interface PropsType {
  hours: number
  minutes: number
  error?: string
  disabled?: boolean
  onChange: (hours: number, minutes: number) => void
  onClick?: () => void
}

const format = (hours: number, minutes: number) => {
  const hoursString = hours < 10 ? `0${hours}` : hours.toString()
  const minutesString = minutes < 10 ? `0${minutes}` : minutes.toString()
  return `${hoursString}:${minutesString}`
}

export default function LocalTimePicker({
  hours,
  minutes,
  error,
  disabled,
  onChange,
  onClick,
}: PropsType) {
  return (
    <Container>
      <TimeInput
        type="time"
        value={format(hours, minutes)}
        className={error ? `time-input time-input--error` : `time-input`}
        disabled={disabled}
        onChange={(e) => {
          if (e.target.value !== "") {
            const [h, m] = e.target.value.split(":")
            if (m !== undefined) {
              onChange(Number(h), Number(m))
            }
          }
        }}
        onClick={onClick}
      />
    </Container>
  )
}
