import React from "react"
import { ChipButton } from "ui"

interface PropsType {
  children?: string
  icon?: JSX.Element
  onClick: () => void
}
function DropDownChipButtonWithRef(
  { children, onClick, icon }: PropsType,
  ref: React.Ref<HTMLDivElement>
) {
  if (icon)
    return (
      <div ref={ref}>
        <ChipButton icon={icon} onClick={onClick}>
          {children}
        </ChipButton>
      </div>
    )

  return (
    <div ref={ref}>
      <ChipButton onClick={onClick}>{children}</ChipButton>
    </div>
  )
}

export const DropDownChipButton = React.forwardRef(DropDownChipButtonWithRef)
