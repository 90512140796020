import useMediaQueries from "hooks/useMediaQueries"
import React from "react"
import styled from "styled-components"
import { colors, mediaQueries } from "ui"
import { menuWidth } from "ui/variables"

import BurgerButton from "./PageTitle/BurgerButton/BurgerButton"

const Header = styled.header`
  display: flex;
  align-items: center;
  padding: 32px;
  gap: 24px;
  width: calc(100vw - ${menuWidth}px);
  max-width: calc(100vw - ${menuWidth}px);

  button:last-child {
    margin-left: auto;
  }

  @media ${mediaQueries.isNarrowScreen} {
    width: 100vw;
    max-width: 100vw;
    gap: 16px;

    > svg {
      margin: 0;
    }
  }

  @media ${mediaQueries.isMobile} {
    width: unset;
    max-width: unset;
    gap: 16px;
    padding: 16px 24px;
    background: ${colors.purple200};
    border-bottom: solid 1px ${colors.grey3};

    > svg {
      margin: 0;
    }
  }
`

interface PropsType {
  children: React.ReactNode
}

function PageHeader({ children }: PropsType, ref: React.Ref<HTMLElement>) {
  const { isNarrowScreen, isMobile } = useMediaQueries()

  const showBurgerButton =
    isNarrowScreen &&
    !isMobile &&
    !location.pathname.startsWith("/admin/") &&
    !location.pathname.startsWith("/typical-week")

  return (
    <Header ref={ref}>
      {showBurgerButton && <BurgerButton />}
      {children}
    </Header>
  )
}

export default React.forwardRef(PageHeader)
