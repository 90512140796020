import { ApolloError, gql, useMutation } from "@apollo/client"
import { useState } from "react"

const DELETE_USER_MUTATION = gql`
  mutation deleteUser($userId: ID!) {
    deleteUser(userId: $userId)
  }
`
export default function useDeleteUser() {
  const [mutate] = useMutation(DELETE_USER_MUTATION)
  const [error, setError] = useState<ApolloError | null>(null)
  const [loading, setLoading] = useState(false)
  const deleteUser = async (userId: string) => {
    setLoading(true)
    try {
      await mutate({
        variables: { userId },
        update(cache, { data: { deleteUser: id } }) {
          const types = ["User", "UserBase", "UserAdminBase", "AdminUserStat"]
          for (const __typename of types) {
            const normalizedId = cache.identify({
              id,
              __typename,
            })
            cache.evict({ id: normalizedId })
          }
          cache.gc()
        },
      })
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }
  return {
    loading,
    error,
    deleteUser,
  }
}
