import React, { useState } from "react"
import styled from "styled-components"
import { Equipment } from "types"
import { colors, mediaQueries, P16 } from "ui"
import getColorFromStatus from "utils/getColorFromStatus"
import { getServiceIcon } from "utils/getServiceIcons"

const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 14px;
  border-radius: 50px;
  border: 1px solid ${colors.grey3};
  background: ${colors.grey4};
  padding: 6px;
  color: ${colors.black};

  &.equipment-btn {
    &--idle {
      border: 1px solid ${colors.grey3};
      background: ${colors.grey4};

      &:hover {
        background: ${getColorFromStatus("office")}14;
        border: 1.5px solid ${getColorFromStatus("office")};
      }
    }

    &--selected {
      background: ${getColorFromStatus("office")}14;
      border: 1.5px solid ${getColorFromStatus("office")};
    }

    &--unselected {
      border: 1px solid ${colors.grey3};
      background: ${colors.grey4};

      &:hover {
        border: 1px solid ${colors.grey3};
        background: ${colors.grey4};
      }
    }
  }

  &:disabled {
    cursor: not-allowed;
    color: ${colors.grey2};
    &:hover {
      border: 1px solid ${colors.grey3};
      background: ${colors.grey4};
    }
    > svg {
      stroke: ${colors.grey3};
      fill: ${colors.grey3};
    }
  }

  > svg {
    stroke: ${colors.black};
    fill: ${colors.black};
  }

  > p {
    color: inherit;
    white-space: nowrap;
  }

  @media ${mediaQueries.isMobile} {
    > svg {
      width: 20px;
      height: 20px;
    }
  }
`

const Counter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.12);
  min-width: 18px;
  height: 18px;

  color: inherit;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`

type Mode = "IDLE" | "SELECTED" | "UNSELECTED"

interface PropsType {
  equipment: Equipment
  availability?: number
  selected?: boolean

  handleClick: (equipement: Equipment) => void
}

export default function EquipmentButton({
  equipment,
  availability,
  selected,
  handleClick,
}: PropsType) {
  // component can only be one of the 3 specific modes
  // the sequence is as follows:
  // IDLE => SELECTED => UNSELECTED => IDLE...

  // This a way of dealing with this use case:
  // * when unselecting the service and hovering it
  // * then hover effect needs to disappear to show thet the service is unselected

  // Therefore the button needs to be restored as IDLE
  // when as UNSELECTED the cursor leaves the button
  const [mode, setMode] = useState<Mode>(selected ? "SELECTED" : "IDLE")

  return (
    <Button
      className={`equipment-btn--${mode.toLowerCase()}`}
      onClick={() => {
        setMode((prev) => {
          if (prev === "IDLE") return "SELECTED"
          if (prev === "SELECTED") return "UNSELECTED"
          if (prev === "UNSELECTED") return "IDLE"
          return prev
        })
        handleClick(equipment)
      }}
      onMouseLeave={() => {
        if (mode === "UNSELECTED") setMode("IDLE")
      }}
      disabled={availability !== undefined && availability <= 0}
    >
      {getServiceIcon(equipment.icon).icon}
      <P16>{equipment.name}</P16>
      {availability !== undefined && <Counter>{availability}</Counter>}
    </Button>
  )
}
