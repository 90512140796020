import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import React from "react"
import styled from "styled-components"
import { colors } from "ui"
import { doubleDashedBorder, leftDashedBorder } from "ui/variables"
import UTCDate from "utils/UTCDate"

const BaseItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.grey1};
  user-select: none; // hide text selection bg

  ${leftDashedBorder}
  &:last-child {
    ${doubleDashedBorder}
  }

  &.disabled > div {
    color: ${colors.grey3};
  }

  &.today > div {
    text-align: center;
    background: ${colors.purple};
    color: ${colors.white};
    border-radius: 100vh;
    width: 18px;
    height: 18px;
  }

  &.weekend {
    background-color: ${colors.grey4};
  }

  &.today > div::after {
    content: "";
    height: 11px;
    width: 1px;
    background-color: ${colors.purple600};
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }
`

const Item = styled(BaseItem)`
  // dedicated to slots when in selection mode
  // has to come after base version so that selected styles has higher css priority
  &.is-selected {
    border-top: solid 3px ${colors.red};
    border-bottom: solid 3px ${colors.red};
    background: ${colors.white};
    &.weekend {
      background-color: ${colors.grey4};
    }
  }

  &.is-first {
    border-left: solid 3px ${colors.red};
    border-top-left-radius: 100vh;
    border-bottom-left-radius: 100vh;
  }
  &.is-last {
    border-right: solid 3px ${colors.red};
    border-bottom-right-radius: 100vh;
    border-top-right-radius: 100vh;
  }
`

function getClassName(day: UTCDate, disabled: boolean) {
  return `${disabled ? "disabled" : ""} ${day.isToday() ? "today" : ""} ${
    day.isWeekend() ? "weekend" : ""
  }`
}

interface PropsType {
  day: UTCDate
  minBookingDate: UTCDate | undefined
}

export default function MonthDay({ day, minBookingDate }: PropsType) {
  const { selectedDate, clipToWholeMonth, userShowWeekends } =
    usePlanningContext()

  const disabled =
    clipToWholeMonth && day.getMonth() !== selectedDate.getMonth()
  const showDayNumber = !day.isWeekend() || userShowWeekends
  const canHavePointerInteraction =
    !minBookingDate ||
    (minBookingDate && day.getTime() >= minBookingDate.getTime())

  return (
    <Item
      className={getClassName(day, disabled)}
      data-timestamp={`${day.getTime()}`}
      style={{
        cursor: canHavePointerInteraction ? "pointer" : "not-allowed",
      }}
    >
      <div>
        {showDayNumber && <>{String(day.getDate()).padStart(2, "0")}</>}
      </div>
    </Item>
  )
}
