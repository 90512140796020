import { ApolloError, gql, useQuery } from "@apollo/client"
import {
  CompanyHybridRules,
  DefaultHybridRule,
  GroupHybridRule,
  ManagerHybridRule,
  UserHybridRule,
} from "types"

import {
  DefaultHybridRuleFragment,
  GroupHybridRuleFragment,
  ManagerHybridRuleFragment,
  UserHybridRuleFragment,
} from "./fragments/hybridPolicyFragments"

export const COMPANY_HYBRID_RULES_QUERY = gql`
  ${DefaultHybridRuleFragment}
  ${UserHybridRuleFragment}
  ${ManagerHybridRuleFragment}
  ${GroupHybridRuleFragment}
  query companyHybridRules {
    companyHybridRules {
      default {
        ...DefaultHybridRuleFragment
      }
      users {
        ...UserHybridRuleFragment
      }
      managers {
        ...ManagerHybridRuleFragment
      }
      groups {
        ...GroupHybridRuleFragment
      }
    }
  }
`
type QueriedRuleResultType<T> = Omit<T, "maxDays"> & {
  maxSlots: number
}

export default function useCompanyHybridRules(): {
  loading: boolean
  error?: ApolloError
  companyHybridRules: CompanyHybridRules
} {
  const { loading, error, data } = useQuery<{
    companyHybridRules: {
      default?: QueriedRuleResultType<DefaultHybridRule>
      users: QueriedRuleResultType<UserHybridRule>[]
      managers: QueriedRuleResultType<ManagerHybridRule>[]
      groups: QueriedRuleResultType<GroupHybridRule>[]
    }
  }>(COMPANY_HYBRID_RULES_QUERY, {
    notifyOnNetworkStatusChange: true,
  })
  const companyHybridRules: CompanyHybridRules = data
    ? {
        default: data.companyHybridRules.default
          ? {
              ...data.companyHybridRules.default,
              maxDays: data.companyHybridRules.default.maxSlots / 2,
            }
          : undefined,
        users: data.companyHybridRules.users.map((r) => ({
          ...r,
          maxDays: r.maxSlots / 2,
        })),
        managers: data.companyHybridRules.managers.map((r) => ({
          ...r,
          maxDays: r.maxSlots / 2,
        })),
        groups: data.companyHybridRules.groups.map((r) => ({
          ...r,
          maxDays: r.maxSlots / 2,
        })),
      }
    : { default: undefined, users: [], managers: [], groups: [] }

  return {
    loading,
    error,
    companyHybridRules,
  }
}
