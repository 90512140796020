import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { Group } from "types"
import { colors, GroupListItem as GroupItem } from "ui"
import sortByKey from "utils/sortByKey"

import CollapseContainer from "../components/CollapseContainer"

const LI = styled.li`
  &:last-child {
    padding-bottom: 8px;
    & > div {
      border-bottom: solid 1px ${colors.grey3};
    }
  }
`

interface PropsType {
  groups: Group[]
  collapseOpen: string | null
  setCollapseOpen: (str: string | null) => void
}

export function GroupsCollapse({
  groups,
  collapseOpen,
  setCollapseOpen,
}: PropsType) {
  const t = useTranslate()

  return (
    <CollapseContainer
      id="groups"
      title={t("Groups")}
      subtitle={`${groups.length} ${t(
        groups.length <= 1 ? "Group" : "Groups"
      ).toLowerCase()}`}
      collapseOpen={collapseOpen}
      setCollapseOpen={setCollapseOpen}
    >
      {sortByKey(groups).map((group) => {
        return (
          <LI key={group.id}>
            <GroupItem group={group} withFavoriteCheckbox />
          </LI>
        )
      })}
    </CollapseContainer>
  )
}
