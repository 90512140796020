import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function FloorPlan({
  stroke,
  fill,
  strokeWidth,
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      strokeWidth={strokeWidth ?? 1.5}
      stroke={stroke ? stroke : "currentColor"}
      fill={fill ? fill : "currentColor"}
      {...rest}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path d="m464.16197,41.58861c-4.73822,-3.40805 -10.36146,-5.17723 -16.05117,-5.17723c-2.91569,0 -5.8515,0.46613 -8.68673,1.41062l-115.28354,38.4567l-128.92887,-38.70857c-2.57375,-0.77396 -5.23408,-1.15876 -7.89441,-1.15876c-2.93581,0 -5.87161,0.47225 -8.68673,1.41062l-123.42631,41.17738c-11.20626,3.74038 -18.76572,14.22865 -18.76572,26.04096l0,343.09583c0,8.82316 4.24236,17.11373 11.40653,22.27435c4.73122,3.40805 10.36146,5.17723 16.04505,5.17723c2.92269,0 5.85062,-0.46613 8.68586,-1.41062l115.18296,-38.4261l128.95511,38.68146c2.57375,0.77046 5.23408,1.15613 7.88916,1.15613c2.93493,0 5.86462,-0.47312 8.68586,-1.41062l123.50677,-41.17825c11.20626,-3.73251 18.76572,-14.22515 18.76572,-26.04096l0,-343.09583c0.00087,-8.82404 -4.24148,-17.11461 -11.39953,-22.27435zm-263.33407,26.33218l109.83432,32.97426l0,343.0591l-109.83432,-32.94452l0,-343.08883zm-136.93784,380.21537l0,-343.09583l109.48626,-36.52661l0,343.09583l-109.48626,36.52661zm384.22073,-41.17738l-109.99611,36.67353l0,-343.07834l109.99611,-36.69102l0,343.09583z" />
      </svg>
    </SvgIcon>
  )
}
