import { Flags, UserBase } from "types"

function toTitleCase(str: string) {
  return str.replace(/\w*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

function isEmail(email: string) {
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    email
  )
}

export default function getUserDisplayName(
  user: UserBase,
  companyFlags: Flags
) {
  const bypassDisplayName = companyFlags?.bypassDisplayName === true
  if (!bypassDisplayName && user.name && !isEmail(user.name)) {
    return user.name
  }
  const email = user.email.split("@")[0].replace(".", " ")
  return toTitleCase(email)
}
