import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import { PlanningDrawer } from "components/PlanningDrawer/PlanningDrawer"
import React, { useState } from "react"
import styled from "styled-components"
import { WorkingMode } from "types"
import { colors, mediaQueries } from "ui"
import { ArrowLeft, Cancel } from "ui/icons"
import LocationTree from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

import FloorPlan from "./FloorPlan/FloorPlan"
import FloorPlanLocationsList from "./FloorPlanLocationsList/FloorPlanLocationsList"

const Layout = styled.div`
  display: grid;
  grid-template-columns: auto 233px 1fr;
  padding: 16px 0;
  overflow-x: hidden;

  @media ${mediaQueries.isNarrowScreen} {
  }
`

const CloseButton = styled.button`
  padding: 0 0 0 24px;
  aspect-ratio: 1;
  border: none;
  background: none;
  svg {
    stroke: ${colors.purple};
  }
`

interface SharedPropsType {
  from: UTCDate
  to: UTCDate
  locations: LocationTree[]
  selectedFloorPlan: LocationTree | undefined
  selectedLocation: LocationTree | undefined
  setSelectedLocation: (location: LocationTree | undefined) => void
  onClose: () => void
}

interface ContentPropsType extends SharedPropsType {
  saveWorkingMode?: (workingMode: WorkingMode) => void | Promise<void>
}

interface PropsType extends SharedPropsType {
  drawerWidth: number
}

export function DesktopFloorPlanContent({
  from,
  to,
  locations,
  selectedLocation,
  selectedFloorPlan,
  setSelectedLocation,
  saveWorkingMode,
  onClose,
}: ContentPropsType) {
  const { openDrawer } = usePlanningContext()
  const [maxHeight, setMaxHeight] = useState(0)

  return (
    <Layout
      ref={(el) =>
        setMaxHeight(
          window.innerHeight - (el?.getBoundingClientRect().top ?? 0)
        )
      }
    >
      <div>
        <CloseButton onClick={onClose}>
          {openDrawer === "SLOTS_EDITOR" ? <ArrowLeft /> : <Cancel />}
        </CloseButton>
      </div>

      <div>
        <FloorPlanLocationsList
          from={from}
          to={to}
          locations={locations}
          selectedLocation={selectedLocation}
          selectedFloorPlan={selectedFloorPlan}
          maxHeight={maxHeight}
          onSelect={setSelectedLocation}
        />
      </div>
      <div>
        <FloorPlan
          from={from}
          to={to}
          locations={locations}
          selectedLocation={selectedLocation}
          maxHeight={maxHeight}
          withOccupations
          setSelectedLocation={setSelectedLocation}
          saveWorkingMode={saveWorkingMode}
        />
      </div>
    </Layout>
  )
}

export default function DesktopFloorPlanDrawer({
  drawerWidth,
  onClose,
  ...props
}: PropsType) {
  return (
    <PlanningDrawer open desktopWidth={`${drawerWidth}px`} onClose={onClose}>
      <DesktopFloorPlanContent onClose={onClose} {...props} />
    </PlanningDrawer>
  )
}
