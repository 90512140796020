import React from "react"
import { Link as RouterDomLink } from "react-router-dom"
import styled from "styled-components"

interface PrimaryPropsType {
  $variant?: "hover"
}

const PrimaryLink = styled(RouterDomLink)<PrimaryPropsType>`
  &:hover {
    ${({ $variant }) => {
      if ($variant) return "text-decoration: underline;"
    }}
  }
`

const NakedLink = styled(PrimaryLink)<PrimaryPropsType>`
  display: flex;
  text-decoration: none;

  &:hover,
  &:link,
  &:visited,
  &:active {
    color: inherit;
  }
`

const A = styled.a<PrimaryPropsType>`
  text-decoration: none;

  &:hover,
  &:link,
  &:visited,
  &:active {
    color: inherit;
  }

  &:hover {
    ${({ $variant }) => {
      if ($variant) return "text-decoration: underline;"
    }}
  }
`

interface PropsType {
  children?: React.ReactNode
  to: string
  naked?: boolean
  variant?: "hover"
  external?: boolean
}

export function Link({
  children,
  to,
  variant,
  external,
  naked = false,
  ...rest
}: PropsType) {
  if (external)
    return (
      <A target="_blank" $variant={variant} href={to} rel="noreferrer">
        {children}
      </A>
    )

  if (naked) {
    return (
      <NakedLink to={to} $variant={variant} {...rest}>
        {children}
      </NakedLink>
    )
  }
  return (
    <PrimaryLink to={to} $variant={variant} {...rest}>
      {children}
    </PrimaryLink>
  )
}
