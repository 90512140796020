import ManagerSearch from "components/ManagerSearch/ManagerSearch"
import useUpdateManager from "graphql/users/useUpdateManager"
import useUser from "graphql/users/useUser"
import useWindowSize from "hooks/useWindowResize"
import useTranslate from "intl/useTranslate"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Transition } from "react-transition-group"
import { SearchResult } from "types"
import { Button, Grid } from "ui"

import {
  Content,
  Footer,
  Header,
  MessageContainer,
  RightContainer,
  Subtitle,
  Title,
} from "./components/Layout"
import NavBack from "./components/NavBack"
import StepIndicator from "./components/StepIndicator"

const duration = 600

const defaultStyleBluePopUp = {
  transition: `right ${duration}ms ease-in-out 300ms`,
  position: "absolute",
  top: "75%",
}
const defaultStyleGreenPopUp = {
  transition: `right ${duration}ms ease-in-out 300ms`,
  position: "absolute",
  top: "13%",
}

const transitionStylesBluePopUp: any = {
  entering: { right: "15%" },
  entered: { right: "15%" },
  exiting: { right: -200 },
  exited: { right: -200 },
}

const transitionStylesGreenPopUp: any = {
  entering: { right: "35%" },
  entered: { right: "35%" },
  exiting: { right: -200 },
  exited: { right: -200 },
}
export default function OnboardingManager() {
  const history = useHistory()
  const t = useTranslate()
  const { updateManager } = useUpdateManager()
  const { user: me } = useUser()
  const [, maxHeight] = useWindowSize()

  const [isManagerRemove, seIsManagerRemove] = useState<boolean>(false)
  const [temporaryManager, setTemporaryManager] = useState<SearchResult | null>(
    me?.manager ? { ...me.manager, type: "user" } : null
  )

  const [inProp, setInProp] = useState(false)

  useEffect(() => {
    setTimeout(() => setInProp(true), 1)
  }, [])

  return (
    <Grid container direction="row">
      <Grid
        item
        xs={12}
        md={8}
        xl={9}
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <Header>
          <NavBack realGoBack onClick={() => history.goBack()} />
        </Header>
        <Content maxHeight={maxHeight}>
          <MessageContainer>
            <Title>{t("user-onboarding-manager-title")}</Title>
            <Subtitle>{t("user-onboarding-manager-p-1")}</Subtitle>
            <Subtitle>{t("user-onboarding-manager-p-2")}</Subtitle>
          </MessageContainer>
          <ManagerSearch
            variant="textfield"
            initialValue={temporaryManager}
            hideLabel
            onSelect={(newManager) => {
              setTemporaryManager(newManager)
            }}
            handleClearForm={() => {
              setTemporaryManager(null)
              seIsManagerRemove(true)
            }}
          />
        </Content>
        <Footer>
          <StepIndicator />
          <Button
            onClick={() => {
              if (temporaryManager && temporaryManager.email && me)
                updateManager(temporaryManager.email, me.id)
              else if (isManagerRemove && me) updateManager("", me.id)
              history.push("/onboarding/user/password")
              // history.push("/onboarding/user/perfect-week")
            }}
          >
            {t("continue")}
          </Button>
        </Footer>
      </Grid>

      <RightContainer
        item
        xs={0}
        md={4}
        xl={3}
        style={{
          backgroundImage: `url(/static/img/onboarding/onboarding_step4.svg)`,
        }}
      >
        <Transition in={inProp} timeout={duration}>
          {(state) => (
            <div
              style={{
                ...defaultStyleBluePopUp,
                ...transitionStylesBluePopUp[state],
              }}
            >
              <img
                alt="Blue user"
                src="/static/img/onboarding/pop_up_blue_step4.svg"
              />
            </div>
          )}
        </Transition>
        <Transition in={inProp} timeout={duration}>
          {(state) => (
            <div
              style={{
                ...defaultStyleGreenPopUp,
                ...transitionStylesGreenPopUp[state],
              }}
            >
              <img
                alt="Green user"
                src="/static/img/onboarding/pop_up_green_step4.svg"
              />
            </div>
          )}
        </Transition>
      </RightContainer>
    </Grid>
  )
}
