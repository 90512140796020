import Search from "components/Search/Search"
import React from "react"
import { SearchResult } from "types"

interface SearchPropsType {
  placeholder: string
  forceBringToTop?: boolean
  onSelect: (result: SearchResult) => void
}

export default function SimpleSearch({
  placeholder,
  forceBringToTop,
  onSelect,
}: SearchPropsType) {
  return (
    <Search
      variant="simple"
      placeholder={placeholder}
      forceBringToTop={forceBringToTop}
      onSelect={onSelect}
    />
  )
}
