import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function ArrowDropDown({ fill, ...rest }: SvgIconProps) {
  return (
    <SvgIcon viewBox="6 6 12 12" fill={fill ? fill : "currentColor"} {...rest}>
      <path d="M7 10l5 5 5-5H7z" stroke="none" />
    </SvgIcon>
  )
}
