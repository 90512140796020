import FavoriteCheckbox from "components/FavoriteCheckbox"
import UserAvatar from "components/UserAvatar/UserAvatar"
import useCompanyFlags from "hooks/useCompanyFlags"
import useTranslate from "intl/useTranslate"
import React, { useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { UserBase } from "types"
import {
  Button,
  colors,
  L12,
  mediaQueries,
  P16,
  transitions,
  UserListItem,
} from "ui"
import { ArrowDownFill, ArrowRightFill } from "ui/icons"
import getUserDisplayName from "utils/getUserDisplayName"
import UserTree from "utils/UserTree"

const ManagerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 8px 8px 13px;
  border-top: 1px solid ${colors.grey3};
  cursor: pointer;
  &:hover {
    background: hsla(${colors.blackReal}, 0.04);
  }
  > button:last-child {
    /* see planning button */
    white-space: nowrap;
  }

  @media ${mediaQueries.isMobile} {
    padding: 4px 0px;
  }
  .favorite-checkbox {
    visibility: hidden;
    opacity: 0;
    transition: ${transitions.generic};
  }
  &:hover {
    .favorite-checkbox {
      visibility: visible;
      opacity: 1;
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
  .subtitle {
    color: ${colors.grey1};
    padding-left: 32px;
    @media ${mediaQueries.isMobile} {
      padding-left: 16px;
    }
  }
`

interface PropsType {
  manager: UserTree
  onUserSelect?: (user: UserBase) => void
}

export function Manager({ manager, onUserSelect }: PropsType) {
  const t = useTranslate()
  const history = useHistory()
  const { companyFlags } = useCompanyFlags()
  const [isCollapseOpen, setIsCollapseOpen] = useState<boolean>(false)

  const nbCollaborators = UserTree.getUserTreeCollaborators([manager]).length
  const ref = useRef(null)

  const handleUserSelect = (user: UserBase) => {
    if (!onUserSelect) return
    onUserSelect(user)
  }

  return (
    <div style={{ marginLeft: 30 }}>
      <ManagerContainer
        ref={ref}
        key={manager.id}
        onClick={() => {
          if (!onUserSelect) {
            setIsCollapseOpen((oldValue) => !oldValue)
          }
        }}
      >
        <div
          style={{ width: "100%" }}
          onClick={() => setIsCollapseOpen((prev) => !prev)}
        >
          <div className="header">
            {isCollapseOpen && manager.team.length > 0 ? (
              <ArrowDownFill
                onPointerDown={(e) => {
                  setIsCollapseOpen((prev) => !prev)
                  e.stopPropagation()
                }}
              />
            ) : manager.team.length < 0 ? (
              <UserAvatar user={manager} />
            ) : (
              <ArrowRightFill
                onPointerDown={(e) => {
                  e.stopPropagation()
                  setIsCollapseOpen((prev) => !prev)
                }}
              />
            )}
            <P16>{getUserDisplayName(manager, companyFlags)}</P16>
            {manager.teamName !== "" && (
              <>
                {"-"}
                <P16>{manager.teamName}</P16>
              </>
            )}
            <FavoriteCheckbox
              itemId={manager.id}
              type="user"
              className="favorite-checkbox"
            />
          </div>
          <L12 className="subtitle">
            {nbCollaborators > 1
              ? `${nbCollaborators} ${t("Collaborators")}`
              : `${nbCollaborators} ${t("Collaborator")}`}
          </L12>
        </div>
        {onUserSelect ? (
          <Button ghost onClick={() => handleUserSelect(manager)}>
            {t("hybrid-rule-add-edit--manager")}
          </Button>
        ) : (
          <Button ghost onClick={() => history.push(`/user/${manager.id}`)}>
            {t("see planning")}
          </Button>
        )}
      </ManagerContainer>
      {isCollapseOpen &&
        manager.team.map((teamMember) => {
          if (teamMember.isManager) {
            return (
              <Manager
                key={teamMember.id}
                manager={teamMember}
                onUserSelect={onUserSelect}
              />
            )
          } else
            return (
              <div key={teamMember.id} style={{ paddingLeft: 30 }}>
                <UserListItem
                  user={teamMember}
                  onUserSelect={onUserSelect ? handleUserSelect : undefined}
                  showFavoriteCheckbox
                  showLeaveGroupButton={false}
                >
                  {getUserDisplayName(teamMember, companyFlags)}
                </UserListItem>
              </div>
            )
        })}
    </div>
  )
}
