import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import useUser from "graphql/users/useUser"
import useTransactableSignupDetails from "hooks/useTransactableSignupDetails"
import React, { useEffect } from "react"
import { useHistory } from "react-router"
import { logout } from "utils/firebase"

function TransactableRedirectHandler() {
  const { user } = useUser()
  const { loading, error, details } = useTransactableSignupDetails()
  const history = useHistory()

  useEffect(() => {
    const originalPath = `${document.location.pathname}${document.location.search}`
    if (user && details) {
      if (details.alreadyActivated || user.email === details.user.email) {
        history.push("/admin/users")
      } else {
        logout().then(() => {
          history.push(originalPath)
        })
      }
    }
  }, [history, user, details])

  return <LoaderOrErrorComponent error={error} loading={loading || !error} />
}

export default TransactableRedirectHandler
