import styled from "styled-components"
import { colors } from "ui"

export const Row = styled.div`
  display: grid;
  grid-template-columns: 320px 1fr;
  > * {
    height: 40px;
    border-bottom: solid 1px ${colors.grey3};
  }
  > *:first-child {
  }
  > *:last-child {
    border-left: solid 1px ${colors.grey3};
    border-right: solid 1px ${colors.grey3};
  }
`
