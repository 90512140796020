import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useMediaQueries from "hooks/useMediaQueries"
import React from "react"
import styled from "styled-components"
import { colors, mediaQueries } from "ui"

const LayoutBase = styled.div`
  border-bottom: solid 1px ${colors.grey3};

  > div:first-child {
    border-right: solid 1px ${colors.grey3};
  }

  &.top-border {
    border-top: solid 1px ${colors.grey3};
  }

  @media ${mediaQueries.isMobile} {
    > div:first-child {
      border-right: none;
    }
  }
`

const LayoutDesktop = styled(LayoutBase)`
  display: grid;
  grid-template-columns: 360px auto;

  &.month-view {
    grid-template-columns: 280px auto;
  }
`

const LayoutMobile = styled(LayoutBase)`
  display: grid;
  grid-template-columns: auto;
`

const LayoutNarrow = styled(LayoutBase)`
  display: grid;
  grid-template-columns: 320px auto;

  &.month-view,
  &.full-week {
    grid-template-columns: 280px auto;
  }
`

interface ColumnsProps {
  children: React.ReactNode
  options?: {
    topBorder?: boolean
  }
}

export default function PlanningRowLayout({ options, children }: ColumnsProps) {
  const { isMobile, isNarrowScreen } = useMediaQueries()
  const { userShowWeekends, viewMode } = usePlanningContext()
  const showFullWeek = viewMode === "WEEK" && userShowWeekends

  const classList = []
  if (showFullWeek) classList.push("full-week")
  if (viewMode === "MONTH") classList.push("month-view")
  if (options?.topBorder) classList.push("top-border")
  const className = classList.join(" ")

  if (isMobile)
    return <LayoutMobile className={className}>{children}</LayoutMobile>

  if (isNarrowScreen && showFullWeek)
    return <LayoutNarrow className={className}>{children}</LayoutNarrow>
  if (isNarrowScreen)
    return <LayoutNarrow className={className}>{children}</LayoutNarrow>

  return <LayoutDesktop className={className}>{children}</LayoutDesktop>
}
