import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useAllGroups from "graphql/groups/useAllGroups"
import useLocationSuggestions from "graphql/misc/useLocationSuggestions"
import useUser from "graphql/users/useUser"
import useSlotsEditorDisabledStatus from "hooks/useSlotsEditorDisabledStatus"
import React from "react"
import { useLocation } from "react-router-dom"
import styled from "styled-components"
import { WorkingMode } from "types"
import { colors } from "ui"
import UTCDate from "utils/UTCDate"

import Suggestion from "./components/Suggestion"

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.grey4};
  border-radius: 16px;
  padding: 16px;
  margin: 16px 8px 16px 8px;
  gap: 8px;
`

interface PropsType {
  from: UTCDate
  to: UTCDate
  guestIds: string[]
  saveWorkingMode: (workingMode: WorkingMode) => void
}

export default function Suggestions({
  from,
  to,
  guestIds,
  saveWorkingMode,
}: PropsType) {
  const { pathname } = useLocation()
  const { groupId, locationId } = usePlanningContext()
  const { user } = useUser()
  const disabledStatus = useSlotsEditorDisabledStatus(to)

  const getDefaultGroupId = () => {
    if (groupId) return groupId
    if (pathname.includes("/favorites")) return "favorites"
    if (user && user.team.length > 0) {
      return "myteam"
    }
    return undefined
  }

  const { error, loading, suggestions } = useLocationSuggestions({
    from,
    to,
    groupId: getDefaultGroupId(),
    locationId: locationId,
  })

  const { groups: allGroups } = useAllGroups()
  const suggestion = suggestions.length > 0 ? suggestions[0] : undefined
  const showSuggestions = suggestion && !disabledStatus.includes("office")

  if (!showSuggestions) return null

  if (loading || error)
    return <LoaderOrErrorComponent loading={loading} error={error} />

  return (
    <Layout>
      <Suggestion
        key={suggestion.id}
        from={from}
        to={to}
        locationId={suggestion.locationId}
        groupId={suggestion.groupId}
        groupName={allGroups.find((g) => g.id === suggestion.groupId)?.name}
        suggestion={suggestion}
        guestIds={guestIds}
        saveWorkingMode={saveWorkingMode}
      />
    </Layout>
  )
}
