import VisualArea from "components/FloorPlanComponents/VisualAreaEditor/VisualArea"
import {
  LocationChipButton,
  LocationChipButtonWithServices,
} from "components/LocationComponents/Buttons/LocationChipButton/LocationChipButton"
import useLocationArea from "hooks/useLocationArea"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components/macro"
import {
  Equipment,
  EquipmentAvailability,
  SlotOccupationForLocation,
  WorkingMode,
} from "types"
import { Tooltip } from "ui"
import LocationTree from "utils/LocationTree"

const Container = styled.div`
  &:hover .favorite-checkbox-icon {
    visibility: visible;
    opacity: 1;
  }

  .favorite-checkbox-icon {
    display: flex;
    visibility: hidden;
    opacity: 0;
  }

  .favorite-checkbox-input:checked ~ .favorite-checkbox-icon {
    visibility: visible;
    opacity: 1;
  }

  &.with-services {
    // TESTING PURPOSE
  }
`

function getLocationAvailability(
  location: LocationTree,
  occupations: SlotOccupationForLocation[],
  guestsIds: string[] = []
) {
  const currentAvailability =
    location.bookable -
    (occupations.find((o) => o.locationId === location.id)?.occupation || 0)
  const afterMutationAvailability = currentAvailability - guestsIds.length

  return afterMutationAvailability > 0 ? currentAvailability : 0
}

interface PropsType {
  location: LocationTree
  occupations: SlotOccupationForLocation[]
  visualArea?: { area: VisualArea; onSelectArea: () => void }
  isSelected: boolean
  showBreadCrumbs?: boolean
  showFavoriteCheckbox?: boolean
  guestIds: string[]
  saveWorkingMode: (workingMode: WorkingMode) => void | Promise<void>
  isTypicalWeek?: boolean
}

function SlotsEditorLocationWithoutService({
  location,
  occupations,
  visualArea,
  isSelected,
  showFavoriteCheckbox,
  guestIds,
  saveWorkingMode,
  showBreadCrumbs = false,
  isTypicalWeek = false,
}: PropsType) {
  const t = useTranslate()
  const available = getLocationAvailability(location, occupations, guestIds)

  const content = (
    <LocationChipButton
      location={location}
      availability={{
        available,
        bookable: location.bookable,
      }}
      active={isSelected}
      disabled={available <= 0}
      showBreadCrumbs={showBreadCrumbs}
      showFavoriteCheckbox={showFavoriteCheckbox}
      visualArea={visualArea}
      onClick={() => {
        if (available > 0) {
          saveWorkingMode({
            equipments: [],
            locationId: location.id,
            label: null,
            genericSlotValue: "office",
          })
        }
      }}
    />
  )

  if (isTypicalWeek && available <= 0) {
    return (
      <Container>
        <Tooltip title={t("cant add in typical week")} placement="top">
          {content}
        </Tooltip>
      </Container>
    )
  }
  return <Container>{content}</Container>
}

interface WithServicePropsType extends PropsType {
  equipments: Equipment[]
  equipmentsAvailability: EquipmentAvailability[]
  saveWorkingMode: (workingMode: WorkingMode) => void | Promise<void>
}

export function SlotsEditorLocationWithServices({
  location,
  occupations,
  visualArea,
  isSelected,
  guestIds,
  saveWorkingMode,
  showBreadCrumbs = false,
  ...rest
}: WithServicePropsType) {
  const { isMobile } = useMediaQueries()
  const available = getLocationAvailability(location, occupations, guestIds)

  return (
    <Container className="with-services">
      <LocationChipButtonWithServices
        location={location}
        visualArea={visualArea}
        availability={{
          available,
          bookable: location.bookable,
        }}
        active={isSelected}
        disabled={available === 0}
        showBreadCrumbs={showBreadCrumbs}
        onClick={() => {
          if (isMobile) return
          saveWorkingMode({
            equipments: [],
            locationId: location.id,
            label: null,
            genericSlotValue: "office",
          })
        }}
        saveWorkingMode={saveWorkingMode}
        {...rest}
      />
    </Container>
  )
}

interface SlotsEditorLocationPropsType {
  location: LocationTree
  companyLocations: LocationTree[]
  occupations: SlotOccupationForLocation[]
  equipments: Equipment[]
  equipmentsAvailability: EquipmentAvailability[]
  floorPlan?: {
    selectedFloorPlan?: LocationTree
    selectedArea?: LocationTree
    updateSelectedFloorPlan: (location: LocationTree) => void
    updateSelectedVisualArea: (visualArea: LocationTree) => void
  }
  isSelected?: boolean
  showBreadCrumbs?: boolean
  showFavoriteCheckbox?: boolean
  guestIds: string[]
  saveWorkingMode: (workingMode: WorkingMode) => void | Promise<void>
  isTypicalWeek?: boolean
}

export default function SlotsEditorLocation({
  location,
  companyLocations,
  equipments,
  floorPlan,
  isSelected = false,
  isTypicalWeek = false,
  ...rest
}: SlotsEditorLocationPropsType) {
  const locationEquiments = equipments.filter(
    (e) => e.locationId === location.id
  )

  const locationArea = useLocationArea(companyLocations, location)
  const visualArea =
    floorPlan && locationArea
      ? {
          area: locationArea,
          onSelectArea: () => {
            if (!floorPlan) return
            floorPlan.updateSelectedVisualArea(location)
          },
        }
      : undefined

  const parentEquipments = location
    .getParents(companyLocations)
    .map((parent) => {
      const found = equipments.filter((e) => e.locationId === parent.id)
      if (found) return [...found]
      return []
    })
    .reduce((p, c) => [...p, ...c], [])

  const slotEquipments = [...parentEquipments, ...locationEquiments]

  if (slotEquipments.length > 0)
    return (
      <SlotsEditorLocationWithServices
        equipments={slotEquipments}
        isSelected={isSelected}
        location={location}
        visualArea={visualArea}
        {...rest}
      />
    )

  return (
    <SlotsEditorLocationWithoutService
      isSelected={isSelected}
      location={location}
      visualArea={visualArea}
      isTypicalWeek={isTypicalWeek}
      {...rest}
    />
  )
}
