import { Avatar } from "@mui/material"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { Button, Dialog, P14 } from "ui"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
`

interface PropsType {
  open: boolean
  onClose: () => void
  onSuccess: () => void
}

export default function DialogSpacesCreation({
  open,
  onClose,
  onSuccess,
}: PropsType) {
  const t = useTranslate()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t("Create location")}
      leftButton={
        <Button secondary onClick={onClose}>
          {t("Back")}
        </Button>
      }
      rightButton={<Button onClick={onSuccess}>OK</Button>}
    >
      <Avatar
        sx={{ width: "100%", height: 146, marginTop: 3 }}
        variant="square"
        alt="Fictitious users creation"
        src="/static/img/onboarding/spaces_creation_dialog.svg"
      />
      <Container>
        <P14>{t("fictitious spaces will be displayed")}</P14>
      </Container>
    </Dialog>
  )
}
