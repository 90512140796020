import useTranslate from "intl/useTranslate"
import React from "react"
import { ChipButton, RemovableChipButton } from "ui"
import getLabelFromStatus from "utils/getLabelFromStatus"

interface WorkingModeButtonWithLabelPropsType {
  value: string
  label: string
  isSelected?: boolean
  onClick: () => void
}

export function WorkingModeButtonWithLabel({
  value,
  label,
  isSelected,
  onClick,
}: WorkingModeButtonWithLabelPropsType) {
  const t = useTranslate()

  const getLabelDisplayName = (label: string, value: string) => {
    const genericLabels = ["Remote", "Other", "Off", "Out of Contract"]
    if (!genericLabels.find((gl) => gl === label)) return label
    return t(getLabelFromStatus(value))
  }

  return (
    <ChipButton subject={value} onClick={onClick} active={isSelected}>
      {getLabelDisplayName(label, value)}
    </ChipButton>
  )
}

interface UserWorkingModeButtonPropsType {
  label: string
  value: string
  isSelected?: boolean
  onClick: () => void
  onRemove: () => void
}

export function WorkingModeButtonWithUserLabel({
  value,
  label,
  isSelected,
  onRemove,
  onClick,
}: UserWorkingModeButtonPropsType) {
  return (
    <RemovableChipButton
      subject={value}
      onClick={onClick}
      onRemove={onRemove}
      active={isSelected}
    >
      {label}
    </RemovableChipButton>
  )
}
