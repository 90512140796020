import useTranslate from "intl/useTranslate"
import React from "react"
import { Pie } from "react-chartjs-2"
import { colors } from "ui"

interface PropsType {
  concerned: number
  notCompliant: number
}

export default function ScoreChart({ concerned, notCompliant }: PropsType) {
  const t = useTranslate()

  const labels = ["respected", "not respected"]

  const datasets = [
    {
      data: [concerned - notCompliant, notCompliant],
      backgroundColor: [colors.purple, colors.redAlert],
    },
  ]

  const data = { labels, datasets }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      mode: "nearest",
      callbacks: {
        label: (item: any, obj: any) => {
          const value = obj.datasets[0].data[item.index]
          return `${t(obj.labels[item.index])}: ${value}`
        },
      },
    },
    legend: {
      display: false,
    },
  }

  return <Pie data={data} options={options} height={100} width={100} />
}
