import { InputLocation, Location, RawLocationEntry } from "types"

export default function isLocationPrivate(
  location: Location | RawLocationEntry | InputLocation
): boolean {
  return (
    location.reservedTo.users.length > 0 ||
    location.reservedTo.groups.length > 0
  )
}
