import InteractionsMenu from "components/InteractionsMenu/InteractionsMenu"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { UserBase } from "types"
import { colors } from "ui"
import { Star } from "ui/icons"
import UTCDate from "utils/UTCDate"

import { ActiveLocationsList } from "../../ActiveLocationsList/ActiveLocationsList"
import PlanningHeaderMessage from "../PlanningHeaderMessage/PlanningHeaderMessage"

const FavoritesHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 6px 0 32px;
  min-height: 40px;

  > .favorites-planning-header-icon {
    fill: ${colors.yellow};
  }

  div:last-child {
    margin-left: auto;
    svg {
      width: 16px;
    }
  }
`

interface PropsType {
  users: UserBase[]
  firstWeekday: UTCDate
  lastWeekDay: UTCDate
  noActiveFilters: boolean
}

export default function FavoritesPlanningHeader({
  users,
  firstWeekday,
  lastWeekDay,
  noActiveFilters,
}: PropsType) {
  const t = useTranslate()
  return (
    <>
      <FavoritesHeader>
        <Star className="favorites-planning-header-icon" />
        {t("Favorite users")}
        <InteractionsMenu users={users} from={firstWeekday} to={lastWeekDay} />
      </FavoritesHeader>
      <>
        {users.length === 0 && noActiveFilters ? (
          <PlanningHeaderMessage messageToBeTranslated="You have not selected any user to be part of your favorite ones." />
        ) : (
          <ActiveLocationsList />
        )}
      </>
    </>
  )
}
