import useLink from "hooks/useLink"
import useTranslate from "intl/useTranslate"
import React from "react"
import { Button } from "ui"

export default function SignInWithSSOButton() {
  const goToSSO = useLink("/sso")
  const t = useTranslate()
  return (
    <Button ghost onClick={goToSSO}>
      {t("Sign-in with SSO")}
    </Button>
  )
}
