import DeleteButtonWithConfirmation from "components/ButtonsWithConfirmation/DeleteButtonWithConfirmation/DeleteButtonWithConfirmation"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useDeleteBulkSlotRule from "graphql/bulkSlots/useDeleteBulkSlotRule"
import useTranslate from "intl/useTranslate"
import React from "react"
import { BulkSlotRule } from "types"
import { InfoTooltip, L12, P16, TD, TR } from "ui"
import { getManagerTeamDisplayName } from "utils/getTeamDisplayName"
import UTCDate, { months } from "utils/UTCDate"

interface PropsType {
  rule: BulkSlotRule
}

export default function Rule({ rule }: PropsType) {
  const t = useTranslate()
  const { addToast } = useToasts()
  const deleteRule = useDeleteBulkSlotRule()

  const getDate = (date: UTCDate) => {
    return (
      <>{`${date.getUTCDate()} ${t(
        months[date.getUTCMonth()]
      )} ${date.getUTCFullYear()}`}</>
    )
  }

  const getStatus = (status: string) => {
    if (status === "off") return t("Off")
    if (status === "ooc") return t("Out Of Contract")
    if (status === "home") return t("Home")
    if (status === "else") return t("Else")
    if (status === "office") return t("Office")
  }

  const nbEntitiesConcerned = rule.who.groups.length + rule.who.teams.length

  const nameOfTheEntityConcerned =
    rule.who.groups.length > 0
      ? rule.who.groups[0].name
      : rule.who.teams.length > 0
      ? t("person's team").replace("{person}", rule.who.teams[0].name)
      : ""

  return (
    <TR>
      <TD displayBorder>
        <P16>{rule.title || t("untitled")}</P16>
      </TD>
      <TD displayBorder>
        {!rule.to ? (
          <L12 color="grey1">{getDate(rule.from)}</L12>
        ) : (
          <div className="date">
            <L12 color="grey1">{getDate(rule.from)}</L12>
            <L12 color="grey1">- {getDate(rule.to)}</L12>
          </div>
        )}
      </TD>
      <TD displayBorder>
        {nbEntitiesConcerned > 1 ? (
          <div className="members-list">
            <L12 color="grey1">
              {nbEntitiesConcerned} {t("entities").toLocaleLowerCase()}
            </L12>
            <InfoTooltip
              text={`${rule.who.groups.map(
                (el) => el.name
              )} ${rule.who.teams.map((el) =>
                getManagerTeamDisplayName(el, t)
              )}`}
            />
          </div>
        ) : rule.who.everyone ? (
          <L12 color="grey1">{t("everyone")}</L12>
        ) : (
          <L12 color="grey1">{nameOfTheEntityConcerned}</L12>
        )}
      </TD>
      <TD displayBorder>
        <L12 color="grey1">{getStatus(rule.status)}</L12>
      </TD>
      <TD displayBorder>
        <DeleteButtonWithConfirmation
          onConfirm={async () => {
            const deleted = await deleteRule(rule.id)
            if (deleted) {
              addToast(t("rule has been deleted"), {
                appearance: "success",
              })
            }
          }}
          dialogTitle={t("delete a rule")}
        >
          <P16>{t("delete that rule")}</P16>
          <div className="file-item-title">
            <P16>{rule.title}</P16>
          </div>
        </DeleteButtonWithConfirmation>
      </TD>
    </TR>
  )
}
