import useOccupations from "graphql/slots/useOccupations"
import LocationTree from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

export default function useLocationOccupation(
  location: LocationTree,
  from: UTCDate,
  to: UTCDate
) {
  const { occupations } = useOccupations(from.getTime(), to.getTime())
  const occupation = occupations.find((o) => o.locationId === location.id)
  const available = occupation
    ? location.bookable - occupation.occupation
    : location.bookable

  return { occupation, available }
}
