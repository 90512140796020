import IntegrationLayout from "components/IntegrationsLayout/IntegrationLayout"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { CompanyIntegration } from "types"
import { P16 } from "ui"

const TextContainer = styled.div`
  margin-left: 12px;
  margin-bottom: 18px;
`
interface PropsType {
  integration?: CompanyIntegration
}

export default function KelioIntegrationComponent({ integration }: PropsType) {
  const t = useTranslate()

  return (
    <IntegrationLayout connected={Boolean(integration)} title="Kelio">
      {integration && (
        <TextContainer>
          <P16>{t("offishall linked to kelio")}</P16>
        </TextContainer>
      )}
      {!integration && (
        <TextContainer>
          <P16>{t("offishall not linked to kelio")}</P16>
        </TextContainer>
      )}
    </IntegrationLayout>
  )
}
