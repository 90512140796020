import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { colors, Dialog } from "ui"

interface LayoutProps {
  title?: string
}

const Layout = styled.div<LayoutProps>`
  display: grid;
  grid-template-columns: auto min-content;
  &.concat-list--empty {
    grid-template-columns: auto;
  }
  gap: 8px;

  border: solid 1px ${colors.grey2};
  border-radius: 4px;
  padding: 4px 8px;
  cursor: default;
  ${({ title }) => (title ? "margin-top: 6px;" : "")}

  &:hover,
  &:not(.concat-list--empty) {
    border: solid 1px ${colors.green};
  }
`

const List = styled.ul`
  display: flex;
  align-items: center;
  gap: 4px;
  max-width: inherit;
  overflow: hidden;
`

const Item = styled.li`
  width: 100%;
`

const Button = styled.button`
  padding: 2px 4px;
  background: ${colors.grey4};
  border: solid 1px ${colors.grey3};
  border-radius: 100vh;
  font-weight: 400;
  font-size: 16px;
`

const DialogContainer = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
`

interface ItemPropsType {
  item: {
    id: string
    primary: React.ReactNode
    secondary: React.ReactNode
  }
  parentRef: HTMLDivElement | null
  children: React.ReactNode
  setHiddenItems: React.Dispatch<
    React.SetStateAction<
      {
        id: string
        primary: React.ReactNode
        secondary: React.ReactNode
      }[]
    >
  >
}

function ConcatenatedItem({
  item,
  children,
  parentRef,
  setHiddenItems,
}: ItemPropsType) {
  const [ref, setRef] = useState<HTMLLIElement | null>(null)

  const observerRef = useRef(
    new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const element = entry.target as HTMLElement
          if (!entry.isIntersecting) {
            element.style.opacity = "0"
            element.style.pointerEvents = "none"
            setHiddenItems((prev) => {
              if (prev.find(({ id }) => id === item.id)) return prev
              return [...prev, item]
            })
          } else {
            element.style.opacity = "1"
            element.style.pointerEvents = "all"
            setHiddenItems((prev) => {
              return prev.filter(({ id }) => id !== item.id)
            })
          }
        })
      },
      {
        root: parentRef,
        rootMargin: "0% 0% 0% 0%",
        threshold: 1,
      }
    )
  )

  useEffect(() => {
    const currentObserverRef = observerRef.current
    if (ref !== null) {
      observerRef.current.observe(ref)
      return () => {
        currentObserverRef.unobserve(ref)
      }
    }
  }, [ref])

  return <Item ref={(element) => setRef(element)}>{children}</Item>
}

interface PropsType {
  items: {
    id: string
    primary: React.ReactNode
    secondary: React.ReactNode
  }[]
  hiddenItems: {
    id: string
    primary: React.ReactNode
    secondary: React.ReactNode
  }[]
  dialogTitle?: string
  title?: string
  setHiddenItems: React.Dispatch<
    React.SetStateAction<
      {
        id: string
        primary: React.ReactNode
        secondary: React.ReactNode
      }[]
    >
  >
}

export default function ConcatenatedList({
  items,
  hiddenItems,
  dialogTitle,
  title,
  setHiddenItems,
}: PropsType) {
  const [ref, setRef] = useState<HTMLDivElement | null>(null)

  const [isDialogOpen, setIsDialogOpen] = useState(false)

  return (
    <>
      <Layout
        ref={(element) => setRef(element)}
        title={title}
        className={hiddenItems.length === 0 ? "concat-list--empty" : ""}
      >
        {ref !== null && (
          <List>
            {React.Children.map(
              items.map((item) => item.primary),
              (child, n) => {
                return (
                  <ConcatenatedItem
                    item={items[n]}
                    parentRef={ref}
                    setHiddenItems={setHiddenItems}
                  >
                    {child}
                  </ConcatenatedItem>
                )
              }
            )}
          </List>
        )}
        {hiddenItems.length > 0 && (
          <Button
            onClick={(e) => {
              e.stopPropagation()
              setIsDialogOpen(true)
            }}
          >
            +{`${hiddenItems.length}`}
          </Button>
        )}
      </Layout>
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        title={dialogTitle ?? ""}
        stopEventPropagation
      >
        <DialogContainer>
          {React.Children.map(
            items.map((i) => i.secondary),
            (child) => (
              <li>{child}</li>
            )
          )}
        </DialogContainer>
      </Dialog>
    </>
  )
}
