import {
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
  TextFieldProps,
} from "@mui/material"
import { useField } from "formik"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components/macro"
import { colors, Label } from "ui"
import { CloseEye, EyeOpen } from "ui/icons"
import * as Yup from "yup"

interface PrimaryTextfieldPropsType {
  disabled?: boolean
}

const Container = styled.div<PrimaryTextfieldPropsType>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ disabled }) => {
    if (disabled) `cursor: not-allowed;`
    else return `cursor: auto;`
  }}
`

const PrimaryTextfield = styled(MuiTextField)<PrimaryTextfieldPropsType>`
  ${({ disabled }) => {
    if (disabled) `cursor: not-allowed;`
    else return `cursor: auto;`
  }}

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${colors.grey1};
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${colors.grey1};
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${colors.grey1};
  }

  & .MuiOutlinedInput-root {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: ${colors.black};
    min-height: 40px;
    border: 1px solid ${colors.grey2};
    background: ${colors.grey4};
    height: 40px;
  }

  & .MuiOutlinedInput-root :hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)),
      ${colors.grey4};
    border: 1px solid ${colors.grey2};
  }

  & .MuiOutlinedInput-root.Mui-disabled {
    background: ${colors.grey4};
    cursor: not-allowed;
    color: ${colors.grey1};
  }

  & .MuiOutlinedInput-root.Mui-focused {
    background: linear-gradient(0deg, ${colors.white}, ${colors.white}),
      ${colors.grey4};
    border: 2px solid ${colors.green};
    color: ${colors.black};
  }

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .MuiInputAdornment-root {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: ${colors.black};
    min-height: 40px;
  }
  & .MuiOutlinedInput-helperText {
    color: ${colors.purple};
  }
`

export type TextFieldPropsType = {
  initialValue?: string
  icon?: boolean
  endAdornment?: React.ReactElement
  useB4BPasswords: boolean
  type?: React.InputHTMLAttributes<unknown>["type"]
  resetOnInitialValue?: boolean
  newPassword: boolean
  isFormik?: boolean
  name: string
  onEnterKey?: () => void
  handleChange: (value: string, valid: boolean) => void
} & TextFieldProps

function getPasswordError(password: string, useB4BPasswords: boolean) {
  if (useB4BPasswords) {
    if (password.length < 12) {
      return "Must be at least 12 characters"
    }
    const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{12,}$/
    if (re.test(password)) {
      const firstUppercaseIndex = password.search(/[A-Z]/g)
      const nextChar = password[firstUppercaseIndex + 1]
      const nextNextChar = password[firstUppercaseIndex + 2]
      const nextNextNextChar = password[firstUppercaseIndex + 3]
      const isLowercase =
        nextChar !== undefined && nextChar.toLowerCase() === nextChar
      const isNumber = !isNaN(Number(nextNextChar))
      const isSpecial =
        nextNextNextChar !== undefined &&
        nextNextNextChar.search(/[!@#$%^&*]/g) === 0
      if (firstUppercaseIndex !== -1 && isLowercase && isNumber && isSpecial) {
        return "B4BPasswordRule"
      }
      return null
    }
    return "B4BPasswordRule"
  }
  if (password.length < 8) {
    return "Must be at least 8 characters"
  }
  return null // valid
}

export function getYupPassword(
  useB4BPasswords: boolean,
  t: (id: string, values?: Record<string, string> | undefined) => string
) {
  if (useB4BPasswords) {
    return Yup.string()
      .min(12, t("Must be at least 12 characters"))
      .test(
        "isB4B",
        t("B4BPasswordRule"),
        (value) => getPasswordError(value || "", useB4BPasswords) === null
      )
      .max(255)
      .required(t("Required"))
  }
  return Yup.string()
    .min(8, t("Must be at least 8 characters"))
    .max(255)
    .required(t("Password is required"))
}

type FormikPasswordInputProps = TextFieldPropsType & {
  showPassword: boolean
  endAdornment: JSX.Element
}

function FormikPasswordInput(props: FormikPasswordInputProps) {
  const { newPassword, label, name, showPassword, endAdornment, ...rest } =
    props
  // Without it, formik can't detect the changes we make in that textfield and we can't write in it
  const [field] = useField(name)
  return (
    <Container>
      {label && <Label style={{ marginBottom: 8 }}>{label}*</Label>}
      <PrimaryTextfield
        required
        fullWidth
        type={showPassword ? "text" : "password"}
        autoComplete={newPassword ? "new-password" : "current-password"}
        {...rest}
        {...field}
        InputProps={{ endAdornment }}
      />
    </Container>
  )
}

export default function PasswordInput(props: TextFieldPropsType) {
  const {
    handleChange,
    useB4BPasswords,
    newPassword = false,
    label,
    helperText,
    isFormik = false,
    name,
    ...rest
  } = props
  const t = useTranslate()
  const [password, setPassword] = useState("")
  const [passwordError, setPasswordError] = useState<string | null>(null)
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const endAdornment = {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => setShowPassword((oldShowPassword) => !oldShowPassword)}
          onMouseDown={(e) => e.preventDefault()}
          edge="end"
          style={{ height: 30, width: 30 }}
        >
          {showPassword ? (
            <EyeOpen style={{ color: colors.grey1, fill: "transparent" }} />
          ) : (
            <CloseEye style={{ color: colors.grey1, fill: "transparent" }} />
          )}
        </IconButton>
      </InputAdornment>
    ),
  }

  if (isFormik)
    return (
      <FormikPasswordInput
        {...props}
        {...endAdornment}
        showPassword={showPassword}
      />
    )
  else
    return (
      <Container>
        {label && <Label style={{ marginBottom: 8 }}>{label}*</Label>}
        <PrimaryTextfield
          required
          fullWidth
          error={passwordError !== null}
          helperText={helperText ?? passwordError}
          name="password"
          type={showPassword ? "text" : "password"}
          autoComplete={newPassword ? "new-password" : "current-password"}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value)
            const error = getPasswordError(e.target.value, useB4BPasswords)
            setPasswordError(error ? t(error) : null)
            handleChange(e.target.value, error === null)
          }}
          {...rest}
          InputProps={endAdornment}
        />
      </Container>
    )
}
