import ResendValidationMailButton from "components/ResendValidationMailButton/ResendValidationMailButton"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { User } from "types"
import { P16 } from "ui"

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .info-tooltip {
    display: flex;
    svg {
      width: 18px;
    }
  }

  &.with-resend-btn {
    justify-content: space-between;
  }
`

interface PropsType {
  user: User
}

export default function UserRegisteredStatus({ user }: PropsType) {
  const t = useTranslate()

  if (!user.registered)
    return (
      <Container className="with-resend-btn">
        <P16>{t("User is not registered.")}</P16>
        <ResendValidationMailButton email={user.email} />
      </Container>
    )

  return <></>
}
