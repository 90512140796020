import { MenuItem } from "@mui/material"
import React from "react"
import styled from "styled-components"
import { colors, LU12 } from "ui"
import { Cancel } from "ui/icons"
import LocationTree from "utils/LocationTree"

import LocationItem from "./LocationItem"

interface ItemProps {
  $isSelected: boolean
}

const CategoryItem = styled(MenuItem)<ItemProps>`
  display: flex;
  align-items: center;
  padding: 4px 16px;
  cursor: pointer;

  p {
    color: ${colors.grey1};
  }

  ${({ $isSelected }) => {
    if ($isSelected)
      return `
    background-color: ${colors.grey4} !important;

    svg {
      height: 24px;
      width: 24px;
      color: ${colors.grey1};
      margin-left: auto;
    }
    `
  }}

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
`

interface PropsType {
  locations: LocationTree[]
  selectedLocations: LocationTree[]
  recursiveRendering: boolean
  addLocation: (place: LocationTree) => void
}

export default function LocationsList({
  locations,
  selectedLocations,
  recursiveRendering = true,
  addLocation,
}: PropsType) {
  return (
    <ul>
      {locations.map((location) => {
        if (location.isCategory) {
          const isSelected = selectedLocations.some(
            (oldLocation) => oldLocation.id === location.id
          )
          if (!recursiveRendering) {
            return (
              <CategoryItem
                key={`cat-${location.id}`}
                as="li"
                onClick={() => addLocation(location)}
                $isSelected={isSelected}
              >
                <LU12>{location.name}</LU12>
                {isSelected && <Cancel />}
              </CategoryItem>
            )
          }
          return (
            <li key={`cat-${location.id}`}>
              <ul>
                <CategoryItem
                  as="li"
                  onClick={() => addLocation(location)}
                  $isSelected={isSelected}
                >
                  <LU12>{location.name}</LU12>
                  {isSelected && <Cancel />}
                </CategoryItem>
                <li>
                  {location.children.map((child) => (
                    <LocationsList
                      key={`cat-${location.id}-child-${child.id}`}
                      locations={[child]}
                      selectedLocations={selectedLocations}
                      recursiveRendering={recursiveRendering}
                      addLocation={addLocation}
                    />
                  ))}
                </li>
              </ul>
            </li>
          )
        }
        return (
          <LocationItem
            key={`loc-${location.id}`}
            location={location}
            selectedLocations={selectedLocations}
            onClick={addLocation}
          />
        )
      })}
    </ul>
  )
}
