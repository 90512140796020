import CategoryDropDown from "components/LocationComponents/CategoryDropDown/CategoryDropDown"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useLocationMutations from "graphql/locations/useLocationMutations"
import useLocationsTree from "graphql/locations/useLocationsTree"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { InputLocationWithParentId } from "types"
import {
  AdminPopup,
  Button,
  HeaderTextField,
  NumberField,
  P14,
  Toggle,
} from "ui"
import { LOCATION_NAME_MAX_LENGTH } from "utils/constants"
import isLocationPrivate from "utils/isLocationPrivate"
import LocationTree from "utils/LocationTree"

import { Fieldset } from "../../components/DialogComponents"
import LocationReservedToPicker from "./LocationReservedToPicker"

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 18px;
`

interface PropsType {
  open: boolean
  locationToMutate?: LocationTree
  initialInputLocation?: { name?: string | undefined; parentId?: string | null } // location not in db yet but some infos are known
  onClose: () => void
}

export default function UpdateLocationDialog({
  open,
  locationToMutate,
  initialInputLocation,
  onClose,
}: PropsType) {
  const { updateLocation, addLocation } = useLocationMutations()

  const t = useTranslate()
  const { addToast } = useToasts()

  const initialNewLocation = {
    name: "",
    shortname: "",
    capacity: 0,
    bookable: 0,
    archived: false,
    parentId: null,
    reservedTo: {
      users: [],
      groups: [],
    },
  }

  const getInitialLocation = ({
    locationToMutate,
    initialInputLocation,
  }: {
    locationToMutate?: LocationTree
    initialInputLocation?: {
      name?: string | undefined
      parentId?: string | null
    }
  }): InputLocationWithParentId => {
    if (locationToMutate) return { ...locationToMutate }
    if (initialInputLocation)
      return { ...initialNewLocation, ...initialInputLocation }
    return initialNewLocation
  }

  const [newLocation, setNewLocation] = useState<InputLocationWithParentId>(
    getInitialLocation({ locationToMutate, initialInputLocation })
  )

  const [reducedCapacity, setReducedCapacity] = useState<number | undefined>(
    locationToMutate && locationToMutate.capacity !== locationToMutate.bookable
      ? locationToMutate.bookable
      : undefined
  )

  const [showReservedTo, setShowReservedTo] = useState(
    locationToMutate ? isLocationPrivate(locationToMutate) : false
  )

  const { locations } = useLocationsTree()
  const categories = LocationTree.getCategories(locations)
  const parentCategory = categories.find((l) => l.id === newLocation.parentId)

  const validParents = categories

  const onCancel = () => {
    setNewLocation(initialNewLocation)
    onClose()
  }

  const canAddOrUpdate = (() => {
    const errors = []

    if (locationToMutate) {
      const scalarArrayCompare = (array1: string[], array2: string[]) =>
        array1.length === array2.length &&
        array1.every((value, index) => value === array2[index])
      if (
        locationToMutate.name === newLocation.name &&
        locationToMutate.capacity === newLocation.capacity &&
        locationToMutate.bookable === newLocation.bookable &&
        locationToMutate.archived === newLocation.archived &&
        scalarArrayCompare(
          [
            ...locationToMutate.reservedTo.users,
            ...locationToMutate.reservedTo.groups,
          ],
          [...newLocation.reservedTo.users, ...newLocation.reservedTo.groups]
        ) &&
        locationToMutate.archived === newLocation.archived &&
        (locationToMutate.parentId === newLocation.parentId ||
          (!locationToMutate.parentId && newLocation.parentId === null))
      )
        errors.push(t("space unchanged"))

      return !Boolean(errors.length)
    }
    if (newLocation.capacity < 0) errors.push(t("negative capacity"))
    if (newLocation.bookable < 0 || newLocation.bookable > newLocation.capacity)
      errors.push(t("bookable out of range"))
    if (newLocation.name.length < 1) errors.push(t("name too short"))

    return !Boolean(errors.length)
  })()

  const clampValue = (min: number, value: number, max: number) =>
    Math.min(Math.max(min, value), max)

  return (
    <AdminPopup
      open={open}
      onClose={onCancel}
      title={
        locationToMutate
          ? t("Update a working space")
          : t("Add a working space")
      }
      rightButton={
        <Button
          disabled={!canAddOrUpdate}
          onClick={() => {
            if (canAddOrUpdate && locationToMutate) {
              const {
                name,
                shortname,
                capacity,
                bookable,
                archived,
                reservedTo,
              } = newLocation
              updateLocation(
                locationToMutate.id,
                {
                  name,
                  shortname,
                  capacity,
                  bookable,
                  archived,
                  reservedTo,
                },
                newLocation.parentId
              ).then(() =>
                addToast(t("edition confirmation"), { appearance: "success" })
              )
              onCancel()
            }

            if (canAddOrUpdate && !locationToMutate) {
              const {
                name,
                shortname,
                capacity,
                bookable,
                archived,
                reservedTo,
              } = newLocation

              addLocation(
                {
                  name,
                  shortname,
                  capacity,
                  bookable,
                  archived,
                  reservedTo,
                },
                newLocation.parentId
              ).then(() =>
                addToast(t("new space confirmation"), { appearance: "success" })
              )
              onCancel()
            }
          }}
        >
          {locationToMutate ? t("Save") : t("Add")}
        </Button>
      }
    >
      <Form onSubmit={(e) => e.preventDefault()}>
        <HeaderTextField
          placeholder={t("Space name")}
          maxLength={LOCATION_NAME_MAX_LENGTH}
          autoFocus
          initialValue={newLocation.name}
          handleChange={(name) => setNewLocation((prev) => ({ ...prev, name }))}
        />

        <CategoryDropDown
          defaultValue={
            parentCategory
              ? [...parentCategory.breadCrumbs, parentCategory.name].join(" / ")
              : t("no parent zone")
          }
          validParents={validParents}
          disabled={initialInputLocation?.parentId ? true : false}
          onSelect={({ value }) => {
            setNewLocation((prev) => ({ ...prev, parentId: value.parentId }))
          }}
        />

        <NumberField
          min={0}
          label={t("Full capacity")}
          value={newLocation.capacity}
          handleChange={(int) => {
            {
              const capacity = clampValue(0, int, Infinity)

              const bookable =
                reducedCapacity !== undefined
                  ? clampValue(0, newLocation.bookable, capacity) // if reduced bookable clamped
                  : capacity // if not reduced bookable = capacity
              setReducedCapacity((prev) =>
                prev !== undefined ? bookable : undefined
              )
              setNewLocation((prev) => {
                const capacity = clampValue(0, int, Infinity)
                return {
                  ...prev,
                  capacity,
                  bookable,
                }
              })
            }
          }}
        />
        <Fieldset>
          <P14>{t("Temporary reduce capacity")}</P14>
          {reducedCapacity !== undefined && (
            <NumberField
              min={0}
              max={newLocation.capacity}
              value={newLocation.archived ? 0 : reducedCapacity}
              handleChange={(int) => {
                setReducedCapacity(clampValue(0, int, newLocation.capacity))
                setNewLocation((prev) => ({
                  ...prev,
                  archived: prev.archived,
                  bookable: clampValue(0, int, newLocation.capacity), // bookable always clamped within capacity
                }))
              }}
              disabled={newLocation.archived}
            />
          )}

          <Toggle
            onChange={(checked) => {
              setNewLocation((prev) => ({
                ...prev,
                bookable: checked ? 0 : prev.capacity,
              }))

              setReducedCapacity(checked ? 0 : undefined)
            }}
            label={undefined}
            labelComponent={P14}
            labelToTheLeft
            checked={reducedCapacity !== undefined}
          />
        </Fieldset>
        <Fieldset>
          <P14>
            {newLocation.archived
              ? t("This space is not available")
              : t("This space is available to reservation")}
          </P14>
          <Toggle
            checked={!newLocation.archived}
            onChange={(checked) => {
              if (checked) setReducedCapacity(undefined)
              // if unarchived bookable = capacity
              else setReducedCapacity(0)
              setNewLocation((prev) => ({
                ...prev,
                bookable: checked ? newLocation.capacity : 0, // if archived bookable = 0
                archived: !checked,
              }))
            }}
          />
        </Fieldset>
        <Fieldset>
          <P14>{t("Allocated space")}</P14>
          <Toggle
            onChange={(checked) => {
              setNewLocation((prev) => {
                if (!checked)
                  return {
                    ...prev,
                    reservedTo: {
                      users: [],
                      groups: [],
                    },
                  }
                return {
                  ...prev,
                  reservedTo: {
                    users: locationToMutate
                      ? locationToMutate.reservedTo.users
                      : [],
                    groups: locationToMutate
                      ? locationToMutate.reservedTo.groups
                      : [],
                  },
                }
              })
              setShowReservedTo(checked)
            }}
            checked={showReservedTo}
          />
        </Fieldset>
        {showReservedTo && (
          <P14>
            {t("Allocated space to", {
              groups: `${newLocation.reservedTo.groups.length}`,
              users: `${newLocation.reservedTo.users.length}`,
            })}
          </P14>
        )}
        {showReservedTo && (
          <LocationReservedToPicker
            initialValue={newLocation.reservedTo.users}
            groupIds={newLocation.reservedTo.groups}
            onChange={({ users, groups }) => {
              // setReservedToUsers(users)
              setNewLocation((prev) => ({
                ...prev,
                reservedTo: {
                  groups: groups.map((group) => group.id),
                  users: users.map((user) => user.id),
                },
              }))
            }}
          />
        )}
      </Form>
    </AdminPopup>
  )
}
