import styled from "styled-components"
import colors from "ui/colors"

export const Fieldset = styled.fieldset`
  display: flex;
  align-items: center;
  gap: 16px;
  border: none;
  width: 100%;
  margin: 0;
  padding: 0;

  p {
    margin-right: auto;
  }
`

export const FieldsetColumn = styled.fieldset`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: none;
  width: 100%;
  margin: 0;
  padding: 0;
`

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-top: 1px solid ${colors.grey3};
  padding: 15px;
`
