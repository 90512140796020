import useOccupancyRateOverTime from "graphql/stats/useOccupancyRateOverTime"
import React, { useEffect } from "react"
import LocationTree from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

interface PropsType {
  locations: LocationTree[]
  from: UTCDate
  to: UTCDate
  includeWeekends: boolean
  allLocationsOccupancyOverTime: {
    date: string
    value: number
    booked: number
    total: number
  }[]
  setAllLocationsOccupancyOverTime: React.Dispatch<
    React.SetStateAction<
      {
        date: string
        value: number
        booked: number
        total: number
      }[]
    >
  >
}

export default function AllLocationsOccupancyRateOverTimeDataset({
  locations,
  from,
  to,
  includeWeekends,
  allLocationsOccupancyOverTime,
  setAllLocationsOccupancyOverTime,
}: PropsType) {
  const { occupancyRateOverTime, loading, error } = useOccupancyRateOverTime({
    from,
    to,
    locationIds: locations.map((l) => l.id),
    includeWeekends,
  })

  useEffect(() => {
    if (loading || error) return
    if (allLocationsOccupancyOverTime.length !== occupancyRateOverTime.length) {
      // update if newly selected
      // or if previously selected, value must have changed
      // TODO better value has changed check
      setAllLocationsOccupancyOverTime(occupancyRateOverTime)
    }
  }, [
    loading,
    error,
    occupancyRateOverTime,
    allLocationsOccupancyOverTime,
    setAllLocationsOccupancyOverTime,
  ])

  return <></>
}
