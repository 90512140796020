import { ApolloError, gql, useQuery } from "@apollo/client"
import { SearchResult } from "types"

import { SearchResultFragment } from "./fragments/searchFragments"

const SEARCH_BY_NAME_QUERY = gql`
  ${SearchResultFragment}
  query searchByName($search: String!) {
    searchByName(search: $search) {
      ...SearchResultFragment
    }
  }
`

export default function useSearchByName(search: string): {
  loading: boolean
  error?: ApolloError
  results: SearchResult[]
} {
  const { loading, error, data } = useQuery<{ searchByName: SearchResult[] }>(
    SEARCH_BY_NAME_QUERY,
    {
      variables: { search },
      skip: search.length === 0,
    }
  )
  return {
    results: data?.searchByName || [],
    loading,
    error,
  }
}
