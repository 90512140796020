import clearElementClasslist from "./clearElementClassList"

function getSelectedSlotClassList({
  isSolo,
  isFirst,
  isLast,
  isBetween,
}: {
  isSolo?: boolean
  isFirst?: boolean
  isBetween?: boolean
  isLast?: boolean
}) {
  if (isSolo)
    return [
      "cuse",
      "cuse--no-transition",
      "cuse--selected",
      "cuse--selected--solo",
    ]
  if (isFirst)
    return [
      "cuse",
      "cuse--no-transition",
      "cuse--selected",
      "cuse--selected--first",
    ]
  if (isLast)
    return [
      "cuse",
      "cuse--no-transition",
      "cuse--selected",
      "cuse--selected--last",
    ]
  if (isBetween)
    return [
      "cuse",
      "cuse--no-transition",
      "cuse--selected",
      "cuse--selected--between",
    ]
  return ["cuse", "cuse--no-transition", "cuse-selected"]
}

function updateSelectedSlotClassList(
  element: Element,
  {
    isSolo,
    isFirst,
    isLast,
    isBetween,
  }: {
    isSolo?: boolean
    isFirst?: boolean
    isBetween?: boolean
    isLast?: boolean
  }
) {
  clearElementClasslist(element)
  if (isSolo)
    return element.classList.add(...getSelectedSlotClassList({ isSolo: true }))
  if (isFirst)
    return element.classList.add(...getSelectedSlotClassList({ isFirst: true }))
  if (isBetween)
    return element.classList.add(
      ...getSelectedSlotClassList({ isBetween: true })
    )
  if (isLast)
    return element.classList.add(...getSelectedSlotClassList({ isLast: true }))
}

/**
 * updateSelectedSlotsClassList first reorder starting and ending indexes
 *
 * then we have to update CSS classes accordingly
 */
export default function updateSelectedSlotsClassList(
  from: number,
  to: number,
  element: HTMLElement
) {
  /**
   * since the ending index depends on the pointer position which
   * can freely move around starting index
   * we need to reorder indexes
   *
   * because selected slots/HTMLElement are slots/HTMLElement contained inside:
   * startingSlot.date/index < selectedSlot.date/index <  endingSlot.date/index
   */
  let start = 0
  let end = 0
  if (from > to) {
    start = to
    end = from
  } else {
    start = from
    end = to
  }

  if (start === end) {
    return updateSelectedSlotClassList(element.children[start].children[0], {
      isSolo: true,
    })
  }

  for (let n = start + 1; n < end; n++) {
    updateSelectedSlotClassList(element.children[n].children[0], {
      isBetween: true,
    })
  }

  updateSelectedSlotClassList(element.children[start].children[0], {
    isFirst: true,
  })

  updateSelectedSlotClassList(element.children[end].children[0], {
    isLast: true,
  })
}
