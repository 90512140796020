import EventDialog from "components/Events/EventDialog/EventDialog"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import { computePlanningDays } from "components/PlanningContextProvider/utils"
import useUserEvents from "graphql/events/useUserEvents"
import useLogEvent from "graphql/misc/useLogEvent"
import useUser from "graphql/users/useUser"
import useMediaQueries from "hooks/useMediaQueries"
import React, { useState } from "react"
import styled from "styled-components"
import { IntegrationEvent } from "types"
import { mediaQueries } from "ui"
import filterEventsOfTheDay, {
  filterMeAsAttendingAttendeeEvents,
} from "utils/events"
import UTCDate from "utils/UTCDate"

import Day from "./Day/Day"

interface DaysContainerPropsType {
  displayWeekends: boolean
  enablePointerEvents: boolean
}

const DaysContainer = styled.div<DaysContainerPropsType>`
  display: grid;
  gap: 2px;
  ${({ displayWeekends }) => {
    if (displayWeekends) return "grid-template-columns: repeat(7, 1fr);"
    return "grid-template-columns: repeat(5, 1fr);"
  }}
  ${({ enablePointerEvents }) => {
    if (!enablePointerEvents) return "pointer-events: none;"
  }}
  

  width: 100%;
  padding: 0 16px;
  @media ${mediaQueries.isMobile} {
    padding: 4px 8px 4px 0;
  }
`

const DayWrapper = styled.div`
  padding: 8px 0;
`

export default function WeekDays() {
  const { user: me } = useUser()
  const logEvent = useLogEvent()
  const { isMobile } = useMediaQueries()
  const {
    from,
    to,
    userShowWeekends,
    focusedDate,
    setFocusedDate,
    openDrawer,
    setOpenDrawer,
    setViewMode,
    setSelectedDate,
  } = usePlanningContext()

  const days = computePlanningDays(from, to, userShowWeekends, false)

  const { userEvents } = useUserEvents({ from, to })

  const [selectedEvent, setSelectedEvent] = useState<
    IntegrationEvent | undefined
  >(undefined)

  const setSelectedDay = (date: UTCDate) => {
    if (focusedDate?.getTime() !== date.getTime()) {
      logEvent("planning_day_selected", {
        dateTime: date.getTime(),
        date: date.format("long"),
        isToday: date.isToday(),
      })
      setFocusedDate(date)
    } else {
      if (focusedDate !== undefined) {
        logEvent("planning_day_unselected")
      }
      setFocusedDate(undefined)
    }
    if (isMobile) {
      if (location.pathname.startsWith("/planning")) setViewMode("DAY")
      setSelectedDate(date)
    }
  }

  return (
    <>
      <DaysContainer
        displayWeekends={userShowWeekends}
        enablePointerEvents
        onClick={() => {
          if (openDrawer !== null) setOpenDrawer(null)
        }}
      >
        {days.map((day) => {
          const eventsOfTheDayAttendingByMe = userEvents
            .filter(filterEventsOfTheDay(day))
            .filter(filterMeAsAttendingAttendeeEvents(me))
            .filter((e): e is IntegrationEvent => e !== undefined)

          return (
            <DayWrapper key={day.getTime()}>
              <Day
                events={eventsOfTheDayAttendingByMe}
                day={day}
                userShowWeekend={userShowWeekends}
                setSelectedDay={setSelectedDay}
                setSelectedEvent={setSelectedEvent}
              />
            </DayWrapper>
          )
        })}
      </DaysContainer>
      {selectedEvent && (
        <EventDialog
          open
          event={selectedEvent}
          onClose={() => {
            setSelectedEvent(undefined)
          }}
          isEditable={false}
          openEventToBeEditable={() => {
            //
          }}
        />
      )}
    </>
  )
}
