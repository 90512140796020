import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { colors, Grid, H1, H2, mediaQueries, P16, P16Bold } from "ui"
import { ArrowLeft } from "ui/icons"

const BackButton = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  color: ${colors.grey1};

  p {
    color: ${colors.grey1};
  }
`

const Container = styled.div`
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  padding-top: 12%;
  justify-content: center;
  align-items: center;

  @media ${mediaQueries.isMobile} {
    padding-top: 35%;
  }

  > img {
    height: 400px;
    width: 460px;

    @media ${mediaQueries.isMobile} {
      height: 250px;
      width: 310px;
    }
  }

  .text-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 20px 0 12px 0;
    gap: 12px;
  }

  .explanation-text {
    max-width: 300px;
    text-align: center;
  }
`

export default function Page404() {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()
  const history = useHistory()

  return (
    <>
      {isMobile && (
        <BackButton onClick={() => history.replace("/")}>
          <ArrowLeft />
          <P16Bold>{t("Back")}</P16Bold>
        </BackButton>
      )}
      <Container>
        <img src="/static/img/misc_gif/404.gif" />
        <Grid container className="text-container">
          <H1>404 - </H1>
          <H2>{t("this page does not exist")}</H2>
        </Grid>
        <P16 className="explanation-text">{t("break the internet")}</P16>
      </Container>
    </>
  )
}
