import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { UpsertEventInput } from "types"
import { FieldsetColumn, L12, Label as UILabel, TextField } from "ui"

const Label = styled(UILabel)`
  margin: 0;
`

interface PropsType {
  inputs: UpsertEventInput
  setInputs: (inputs: UpsertEventInput) => void
}

export default function Description({ inputs, setInputs }: PropsType) {
  const t = useTranslate()
  const [addDescription, setAddDescription] = useState(false)

  if (addDescription)
    return (
      <FieldsetColumn>
        <Label>{t("event description")}</Label>
        <TextField
          value={inputs.description}
          handleChange={(description) => {
            setInputs({ ...inputs, description })
          }}
        />
        <div className="line" />
      </FieldsetColumn>
    )
  return (
    <FieldsetColumn>
      <L12
        color="purple"
        onClick={() => setAddDescription(true)}
        className="clickable-text"
      >
        {t("add event description")}
      </L12>
      <div
        style={{
          borderBottom: "1px solid #fafafa",
          height: "0px",
          width: "100%",
          paddingTop: "8px",
        }}
      />
    </FieldsetColumn>
  )
}
