import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { UserWithDailySlots } from "types"
import { colors, P14Bold, Table, TD, TH, TR, transitions } from "ui"
import { Plus, Trash } from "ui/icons"
import { overflowWithEllipsisCSS } from "ui/utilities"
import getUserDisplayName from "utils/getUserDisplayName"
import capitalizeFirstLetter from "utils/texts/capitalizeFirstLetter"

import SlotValueAsChipButton from "./SlotAsChipButton/SlotAsChipButton"

const StyledTH = styled(TH)`
  box-shadow: none;
`

const StyledTD = styled(TD)`
  border: none;
  padding: 4px;

  &.ellipsis {
    ${overflowWithEllipsisCSS}
  }
  &.line-through {
    text-decoration: line-through;
    color: ${colors.grey2};
  }

  svg {
    height: 22px;
    width: 22px;
  }
  &.btn-remove svg {
    &:hover {
      stroke: ${colors.redAlert};
    }
  }
  &.btn-add svg {
    &:hover {
      stroke: ${colors.black};
    }
  }

  svg {
    stroke: ${colors.grey2};
    cursor: pointer;

    ${transitions.fade}
  }
`

interface PropsType {
  teamDayUsersWithDailySlots: UserWithDailySlots[]
  teamDayInvitedUserIds: string[]
  setTeamDayInvitedUserIds: React.Dispatch<React.SetStateAction<string[]>>
}

export default function UsersAvailabilityList({
  teamDayUsersWithDailySlots,
  teamDayInvitedUserIds,
  setTeamDayInvitedUserIds,
}: PropsType) {
  const t = useTranslate()
  const { user: me } = useUser()

  if (!me) return <></>
  return (
    <Table>
      <thead>
        <TR>
          <TH>
            <P14Bold>{t("Name")}</P14Bold>
          </TH>
          <TH>
            <P14Bold>{capitalizeFirstLetter(t("Morning"))}</P14Bold>
          </TH>
          <TH>
            <P14Bold>{capitalizeFirstLetter(t("Afternoon"))}</P14Bold>
          </TH>
          <StyledTH></StyledTH>
        </TR>
      </thead>
      <tbody>
        {[...teamDayUsersWithDailySlots].map((u) => {
          const isTeamDayUser =
            teamDayInvitedUserIds.find((id) => u.id === id) !== undefined

          return (
            <TR key={`member-${u.id}`}>
              <StyledTD
                className={isTeamDayUser ? "ellipsis" : "ellipsis line-through"}
              >
                {getUserDisplayName(u, me?.company.flags)}
              </StyledTD>
              <StyledTD>
                <SlotValueAsChipButton
                  value={u.AMSlot?.value ?? null}
                  disabled={!isTeamDayUser}
                />
              </StyledTD>
              <StyledTD>
                <SlotValueAsChipButton
                  value={u.PMSlot?.value ?? null}
                  disabled={!isTeamDayUser}
                />
              </StyledTD>
              <StyledTD className={isTeamDayUser ? "btn-remove" : "btn-add"}>
                {isTeamDayUser ? (
                  <Trash
                    onClick={() => {
                      setTeamDayInvitedUserIds((prev) =>
                        prev.filter((id) => id !== u.id)
                      )
                    }}
                  />
                ) : (
                  <Plus
                    onClick={() => {
                      setTeamDayInvitedUserIds((prev) => [
                        ...prev.filter((id) => id !== u.id),
                        u.id,
                      ])
                    }}
                  />
                )}
              </StyledTD>
            </TR>
          )
        })}
      </tbody>
    </Table>
  )
}
