import styled from "styled-components"
import { colors, transitions } from "ui"

interface HeaderProps {
  canClick: boolean | (() => void) | undefined
}

export const Header = styled.div<HeaderProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 16px;
  gap: 8px;

  .arrow-btn {
    cursor: pointer;
    &--turned {
      transform: rotate(-90deg);
    }
    transition: ${transitions.generic};
  }

  .title {
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    color: ${colors.grey1};
  }

  &:hover .actions .favorite-checkbox-icon {
    visibility: visible;
    opacity: 1;
  }

  &:hover .actions {
    .trash-icon,
    .edit-icon {
      visibility: visible;
      opacity: 1;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 8px;
    .trash-icon,
    .edit-icon,
    .favorite-checkbox-icon {
      visibility: hidden;
      opacity: 0;
    }

    .favorite-checkbox-input:checked ~ .favorite-checkbox-icon {
      visibility: visible;
      opacity: 1;
    }

    transition: ${transitions.generic};

    svg {
      transition: ${transitions.generic};
      width: 16px;
      height: 16px;
    }
  }

  .more-text {
    margin-left: auto;
    color: ${colors.grey1};

    .floor-plan-icon {
      width: 19px;
      height: 19px;
    }
  }

  .breadcrumbs {
    color: ${colors.grey2};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  ${({ canClick }) =>
    canClick
      ? `&:hover {
        cursor: pointer;
        }`
      : ""}

  transition: ${transitions.generic};
`
