import { ApolloError, gql, useMutation } from "@apollo/client"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import { UserBaseFragment } from "graphql/users/fragments/userFragment"
import useTranslate from "intl/useTranslate"
import { useState } from "react"
import { UserBase } from "types"

const MUTATION = gql`
  ${UserBaseFragment}
  mutation removeUserSubscription($userId: ID!) {
    removeUserSubscription(userId: $userId) {
      ...UserBaseFragment
    }
  }
`

export default function useRemoveUserSubscription() {
  const { addToast } = useToasts()
  const t = useTranslate()

  const [mutate] = useMutation<{ removeUserSubscription: UserBase[] }>(
    MUTATION,
    {
      refetchQueries: ["getUserSubscriptions"],
    }
  )
  const [error, setError] = useState<ApolloError | null>(null)
  const [loading, setLoading] = useState(false)
  const removeUserSubscription = async (userId: string) => {
    setLoading(true)
    try {
      await mutate({
        variables: { userId },
      })
      addToast(t("remove user subscription confirmation"), {
        appearance: "success",
      })
    } catch (e) {
      setError(e)
      addToast(e.message, { appearance: "error" })
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    error,
    removeUserSubscription,
  }
}
