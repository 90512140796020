import { ApolloError, gql, useQuery } from "@apollo/client"
import { UserFavorites } from "types"

import { UserFavoritesFragment } from "./fragments/favoriteFragments"

const GET_USER_FAVORITES_QUERY = gql`
  ${UserFavoritesFragment}
  query getUserFavorites {
    userFavorites {
      ...UserFavoritesFragment
    }
  }
`

export default function useUserFavorites(): {
  loading: boolean
  error?: ApolloError
  favorites: UserFavorites
} {
  const { loading, error, data } = useQuery<{ userFavorites: UserFavorites }>(
    GET_USER_FAVORITES_QUERY
  )
  const favorites: UserFavorites = data?.userFavorites
    ? data.userFavorites
    : {
        users: [],
        locations: [],
        groups: [],
      }
  return {
    favorites,
    loading,
    error,
  }
}
