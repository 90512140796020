import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { DefaultHybridRule, DefaultHybridRuleScore, Flags } from "types"
import { colors, L12, transitions } from "ui"
import UTCDate from "utils/UTCDate"

import UserHybridRuleScoreRow from "../UserHybridRuleScoreRow/UserHybridRuleScoreRow"

const PaginationLayout = styled.div`
  display: flex;
  padding: 4px 8px;
  width: 100%;

  .buttons {
    margin-left: auto;
    display: flex;
    gap: 4px;
  }
`

const PaginationButton = styled.button`
  margin: 0;
  padding: 2px 8px;
  min-width: 6.5em;
  background: ${colors.grey4};
  border: solid 1px ${colors.grey3};
  border-radius: 4px;
  cursor: pointer;
  p {
    color: ${colors.black};
  }

  &:hover:not(:disabled) {
    background: ${colors.grey4}66;
    p {
      color: ${colors.black}dd;
    }
  }

  &:disabled {
    cursor: not-allowed;
    p {
      color: ${colors.black}88;
    }
  }

  transition: ${transitions.fade};
`

function Pagination({
  rule,
  pageLength,
  cursor,
  setCursor,
}: {
  rule: DefaultHybridRule
  pageLength: number
  cursor: number
  setCursor: React.Dispatch<React.SetStateAction<number>>
}) {
  const t = useTranslate()
  return (
    <tr>
      <td colSpan={4}>
        <PaginationLayout>
          <L12 color="grey1">
            page {cursor / pageLength + 1}/
            {Math.round(rule.score!.concernedUserIds.length / pageLength)}
          </L12>
          <div className="buttons">
            {cursor > 0 && (
              <PaginationButton
                onClick={() =>
                  setCursor((prev) =>
                    prev - pageLength <= 0 ? 0 : prev - pageLength
                  )
                }
              >
                <L12>{t("previous")}</L12>
              </PaginationButton>
            )}
            <PaginationButton
              disabled={
                cursor + pageLength >= rule.score!.concernedUserIds.length
              }
              onClick={() =>
                setCursor((prev) =>
                  prev + pageLength >= rule.score!.concernedUserIds.length
                    ? prev
                    : prev + pageLength
                )
              }
            >
              <L12>{t("next")}</L12>
            </PaginationButton>
          </div>
        </PaginationLayout>
      </td>
    </tr>
  )
}

export default function DefaultHybridRuleScores({
  rule,
  selectedDate,
  companyFlags,
}: {
  rule: DefaultHybridRule & { score: DefaultHybridRuleScore }
  selectedDate: UTCDate
  companyFlags: Flags
}) {
  const pageLength = 10
  const [cursor, setCursor] = useState(0)
  return (
    <>
      {rule.score.concernedUserIds
        .slice(cursor, cursor + pageLength)
        .map((id, n) => {
          return (
            <UserHybridRuleScoreRow
              key={`default-${(rule as DefaultHybridRule).score!.id}-user-${n}`}
              rule={rule}
              selectedDate={selectedDate}
              userId={id}
              companyFlags={companyFlags}
            />
          )
        })}
      <Pagination
        rule={rule}
        pageLength={pageLength}
        cursor={cursor}
        setCursor={setCursor}
      />
    </>
  )
}
