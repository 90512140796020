import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { colors, H4, P14 } from "ui"

const Title = styled(H4)`
  padding-bottom: 24px;

  &.with-subtitle {
    padding-bottom: 4px;
  }
`

const Subitle = styled(P14)`
  padding: 0 0 24px 16px;
  color: ${colors.grey1};
`

export function SectionTitle({
  title,
  subtitle,
}: {
  title: string
  subtitle?: string
}) {
  const t = useTranslate()

  if (subtitle)
    return (
      <>
        <Title className="with-subtitle">{t(title)}</Title>
        <Subitle>{t(title)}</Subitle>
      </>
    )
  return <Title>{t(title)}</Title>
}
