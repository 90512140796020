import React from "react"
import styled from "styled-components"
import colors from "ui/colors"
import transitions from "ui/transitions"
import { P14 } from "ui/Typography"

const Layout = styled.div<{
  content: string
  checked: boolean
}>`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 4px;
  gap: 2px;
  background: ${colors.purple400};
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.5s ease-out;
  min-width: 168px;

  &.secondary {
    background: ${colors.grey3};
  }

  &.checked::after {
    translate: 78px;
  }

  &::after {
    ${({ content }) => `content: "${content}";`}
    position: absolute;
    top: 4px;
    bottom: 4px;
    left: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${colors.white};
    display: flex;
    align-items: center;
    padding: 6px 12px;
    border-radius: 4px;
    z-index: 1;
    background: ${colors.white}ff;
    transition-property: translate;
    transition-duration: 250ms;
    transition-easing-function: ease-in-out;
    min-width: 76px;
  }
`

const Item = styled.div`
  min-width: 76px;
  padding: 6px 12px;
  border-radius: 4px;
  text-transform: capitalize;
  text-align: center;
  background: ${colors.white}00;
  z-index: 2;
  transition: ${transitions.fade};
`

interface PropsType {
  checkedLabel: string
  uncheckedLabel: string
  checked: boolean
  variant?: "pimary" | "secondary"
  onChange: (checked: boolean) => void
}

export function BigToggle({
  checkedLabel,
  uncheckedLabel,
  checked,
  variant = "pimary",
  onChange,
}: PropsType) {
  const className = checked ? `${variant} checked` : `${variant}`
  const content = checked ? checkedLabel : uncheckedLabel

  return (
    <Layout
      className={className}
      content={content}
      checked={checked}
      onClick={() => onChange(!checked)}
    >
      <Item>
        <P14>{uncheckedLabel}</P14>
      </Item>
      <Item>
        <P14>{checkedLabel}</P14>
      </Item>
    </Layout>
  )
}
