import { ApolloError, gql, useQuery } from "@apollo/client"
import {
  UserHybridRuleWithScore,
  UserRuleMonthScore,
  UserRuleWeekScore,
} from "types"
import UTCDate from "utils/UTCDate"

import { UserHybridRuleFragment } from "./fragments/hybridPolicyFragments"

export const USER_HYBRID_WORK_RULE_QUERY = gql`
  ${UserHybridRuleFragment}
  query userHybridRule($userId: ID!, $from: String, $to: String) {
    userHybridRule(userId: $userId, from: $from, to: $to) {
      ...UserHybridRuleFragment
    }
  }
`

export default function useUserHybridRule(
  userId: string,
  from?: UTCDate,
  to?: UTCDate
): {
  loading: boolean
  error?: ApolloError
  userHybridRule: UserHybridRuleWithScore | undefined
} {
  const { loading, error, data } = useQuery(USER_HYBRID_WORK_RULE_QUERY, {
    variables: {
      userId,
      from: from ? `${from.getTime()}` : undefined,
      to: to ? `${to.getTime()}` : undefined,
    },
    fetchPolicy: "cache-and-network",
  })

  const userHybridRule: UserHybridRuleWithScore | undefined =
    data?.userHybridRule
      ? {
          ...data.userHybridRule,
          maxDays: data.userHybridRule.maxSlots / 2,
          weeksScores: data.userHybridRule.weeksScores
            ? data.userHybridRule.weeksScores.map(
                (
                  weekScore: Omit<UserRuleWeekScore, "homeDayDelta"> & {
                    homeSlotDelta: number
                  }
                ) => ({
                  ...weekScore,
                  homeDayDelta: weekScore.homeSlotDelta / 2,
                  days: weekScore.days.map((day) => ({
                    ...day,
                    date: new UTCDate(Number(day.date)),
                  })),
                })
              )
            : [],
          monthsScores: data.userHybridRule.monthsScores
            ? data.userHybridRule.monthsScores.map(
                (
                  monthScore: Omit<UserRuleMonthScore, "homeDayDelta"> & {
                    homeSlotDelta: number
                  }
                ) => ({
                  ...monthScore,
                  homeDayDelta: monthScore.homeSlotDelta / 2,
                  date: new UTCDate(Number(monthScore.date)),
                })
              )
            : [],
        }
      : undefined

  return {
    userHybridRule,
    loading,
    error,
  }
}
