import BurgerButton from "components/Layout/PageLayout/PageHeader/PageTitle/BurgerButton/BurgerButton"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useLocationsTree from "graphql/locations/useLocationsTree"
import useSearchByName from "graphql/search/useSearchByName"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { ChangeEvent, useEffect, useRef, useState } from "react"
import { useHistory } from "react-router"
import styled from "styled-components"
import { SearchResult } from "types"
import { mediaQueries } from "ui"
import { Search as SearchIcon } from "ui/icons"
import isIphone from "utils/isIphone"
import LocationTree from "utils/LocationTree"

import ExternalActions from "./ExternalActions/ExternalActions"
import MainSearchResults from "./ResultsList/MainSearchResults/MainSearchResults"
import SearchBarForm from "./SearchBarForm/SearchBarForm"

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;

  height: 72px;
  padding: 16px 16px 16px 32px;

  @media ${mediaQueries.isNarrowScreen} {
    padding: 16px;
  }

  @media ${mediaQueries.isMobile} {
    padding: 0;
    height: auto;
  }
`

interface PropsType {
  placeholder?: string
  results: SearchResult[]
  searchString: string
  mobileParentContainer?: HTMLElement | null
  forceBringToTop?: boolean
  variant?: "simple"
  setSearchString: (str: string) => void
  onSubmit?: (result: SearchResult) => void
  onReset: () => void
}

function SearchContent({
  results,
  searchString,
  mobileParentContainer,
  forceBringToTop,
  setSearchString,
  onSubmit,
  onReset,
  placeholder,
  variant,
}: PropsType) {
  const t = useTranslate()

  const searchFormRef = useRef<HTMLFormElement>(null)
  const searchInputRef = useRef<HTMLInputElement>(null)

  const [selectedResult, setSelectedResult] = useState<
    SearchResult | undefined
  >(undefined)

  const { isMobile, isNarrowScreen } = useMediaQueries()

  const anchorEl =
    isMobile && mobileParentContainer
      ? mobileParentContainer
      : searchFormRef.current

  const showBurgerMenu = isNarrowScreen && !isMobile && variant !== "simple"

  const handleSearchFormChange = (str: string) => {
    setSearchString(str)
  }

  const resetForm = () => {
    setSearchString("")
    setSelectedResult(undefined)
    onReset()
  }

  const handleSelect = (input: SearchResult) => {
    setSelectedResult(input)
    if (onSubmit) onSubmit(input)
    resetForm()
  }

  const handleSubmit = (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (selectedResult && onSubmit) {
      onSubmit(selectedResult)
    }
    resetForm()
  }

  return (
    <>
      {showBurgerMenu && <BurgerButton />}
      <SearchBarForm
        ref={searchFormRef}
        id="search-bar-form"
        icon={isIphone ? undefined : <SearchIcon />}
        placeholder={
          placeholder !== undefined
            ? placeholder
            : t("Groups, Locations, People")
        }
        searchString={searchString}
        searchInputRef={searchInputRef}
        variant="big-search"
        withAdvancedSearch={variant !== "simple"}
        handleChange={handleSearchFormChange}
        onSubmit={handleSubmit}
        resetForm={resetForm}
      />

      <ExternalActions variant={variant} />

      <MainSearchResults
        anchorEl={anchorEl}
        results={results}
        searchString={searchString}
        variant={variant}
        forceBringToTop={forceBringToTop}
        onSelect={handleSelect}
        giveFocusToInput={() => {
          if (searchInputRef.current) {
            searchInputRef.current.focus()
          }
        }}
        handleReset={resetForm}
      />
    </>
  )
}

function SearchContentContainer({
  searchString,
  variant,
  filter,
  setSearchString,
  onSelect,
  onSubmit,
  ...props
}: {
  searchString: string
  setSearchString: (str: string) => void
  filter?: (result: SearchResult) => boolean
  onSelect?: (result: SearchResult) => void
  onSubmit?: () => void
  placeholder?: string
  locationsTree: LocationTree[]
  mobileParentContainer?: HTMLElement | null
  variant?: "simple"
}) {
  const history = useHistory()
  const { results, loading, error } = useSearchByName(searchString)
  const { setActiveLocation, openDrawer, setOpenDrawer } = usePlanningContext()

  const [searchResults, setSearchResults] = useState<SearchResult[]>([])

  const handleSubmit = (result: SearchResult) => {
    if (onSelect) onSelect(result)
    else if (result.type === "user")
      history.push(`/${result.type}/${result.id}`)
    else if (result.type === "location") {
      history.push(`/planning/${result.type}/${result.id}`)
      setActiveLocation({
        location: null,
        persist: false,
        triggeredFrom: "search",
      })
    } else history.push(`/planning/${result.type}/${result.id}`)
    if (onSubmit) onSubmit()
  }

  const reset = () => {
    setSearchString("")
    setSearchResults([])
  }

  useEffect(() => {
    const trueResults = filter ? results.filter(filter) : results
    if (!loading && !error && trueResults.length > 0)
      setSearchResults(trueResults)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results, loading, error])

  if (variant === "simple")
    return (
      <SearchContent
        onReset={reset}
        results={searchResults}
        onSubmit={handleSubmit}
        searchString={searchString}
        setSearchString={setSearchString}
        variant={variant}
        {...props}
      />
    )

  return (
    <SearchContainer
      onClick={() => {
        if (openDrawer !== null) setOpenDrawer(null)
      }}
    >
      <SearchContent
        onReset={reset}
        results={searchResults}
        onSubmit={handleSubmit}
        searchString={searchString}
        setSearchString={setSearchString}
        variant={variant}
        {...props}
      />
    </SearchContainer>
  )
}

interface SearchPropsType {
  placeholder?: string
  mobileParentContainer?: HTMLElement | null
  variant?: "simple"
  forceBringToTop?: boolean
  filter?: (result: SearchResult) => boolean
  onSelect?: (result: SearchResult) => void
  onSubmit?: () => void
}

export default function Search({ ...props }: SearchPropsType) {
  const [searchString, setSearchString] = useState("")
  const { locations: locationsTree } = useLocationsTree()

  return (
    <SearchContentContainer
      searchString={searchString}
      setSearchString={setSearchString}
      locationsTree={locationsTree}
      {...props}
    />
  )
}
