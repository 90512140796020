import useUser from "graphql/users/useUser"
import useIndexedDays from "hooks/useIndexedDays"
import useUserTree from "hooks/useUserTree"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import {
  Group,
  GroupHybridRuleInput,
  HybridRule,
  HybridRuleInput,
  IndexedDay,
  PickedTargets,
} from "types"
import {
  colors,
  DropDown,
  FieldsetColumn,
  Label,
  NumberField,
  Toggle,
  Tooltip,
} from "ui"
import UserTree from "utils/UserTree"

import DaysSelection from "./DaysSelection/DaysSelection"
import FormFooter from "./FormFooter/FormFooter"
import TargetAndNamePicker from "./TargetAndNamePicker/TargetAndNamePicker"

const FormContainer = styled.div`
  height: calc(100% - 72px); // 72px is footer height
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  margin-bottom: auto;
  height: 100%;
`

const Divider = styled.div`
  border-top: solid 1px ${colors.grey3};
`

const DEFAULT_MAX_DAYS = 1
const WEEK_MAX_DAYS = 7

interface PropsType {
  type: "user" | "default"
  initialHybridRule?: HybridRule
  selectedTargets: PickedTargets
  singleTarget?: boolean
  isGroupRule: boolean
  applyToManager: boolean
  formInputs: GroupHybridRuleInput
  setFormInputs: (formInputs: GroupHybridRuleInput) => void
  setApplyToManager: (checked: boolean) => void
  setIsGroupRule: (checked: boolean) => void
  setSelectedTargets: (targets: PickedTargets) => void
  updateHybridWorkRule: (rule: HybridRuleInput) => void
  onCancel: () => void
}

export default function UpdateHybridWorkRuleForm({
  type,
  initialHybridRule,
  selectedTargets,
  isGroupRule,
  singleTarget = false,
  applyToManager,
  formInputs,
  setFormInputs,
  setApplyToManager,
  setIsGroupRule,
  setSelectedTargets,
  updateHybridWorkRule,
  onCancel,
}: PropsType) {
  const t = useTranslate()
  const { user: me } = useUser()
  const { userTree } = useUserTree()

  const isTeamRule = !isGroupRule && selectedTargets.managers.length > 0

  const updateSelectedUsers = (
    users: UserTree[],
    targetType: "users" | "managers",
    disableFilterDuplicated?: boolean
  ) => {
    if (users.length === 0) return

    if (singleTarget) {
      setFormInputs({ ...formInputs, userIds: [users[0].id] })
      return setSelectedTargets({
        ...selectedTargets,
        users: targetType === "users" ? [users[0]] : [],
        managers: targetType === "managers" ? [users[0]] : [],
      })
    }

    let mutatedUsersOrManagersTargets = [...selectedTargets[targetType]]
    for (const user of users) {
      if (disableFilterDuplicated) {
        mutatedUsersOrManagersTargets = [...mutatedUsersOrManagersTargets, user]
        continue
      }

      const found = mutatedUsersOrManagersTargets.find(
        ({ id }) => id === user.id
      )
      if (found) {
        mutatedUsersOrManagersTargets = mutatedUsersOrManagersTargets.filter(
          ({ id }) => id !== found.id
        )
        continue
      }

      mutatedUsersOrManagersTargets = [...mutatedUsersOrManagersTargets, user]
    }
    const targets = {
      ...selectedTargets,
      users:
        targetType === "users"
          ? mutatedUsersOrManagersTargets
          : selectedTargets.users,
      managers:
        targetType === "managers"
          ? mutatedUsersOrManagersTargets
          : selectedTargets.managers,
    }
    setSelectedTargets(targets)
    setFormInputs({
      ...formInputs,
      userIds: [
        ...targets.users.map(({ id }) => id),
        ...targets.managers.map(({ id }) => id),
      ],
    })
  }

  const updateSelectedGroups = (groups: Group[]) => {
    for (const { users } of groups) {
      const groupUsers = users
        .map(({ id }) => UserTree.findUserById(userTree, id))
        .filter((u): u is UserTree => u !== undefined)
      if (
        users.some(({ id }) => !selectedTargets.users.find((u) => u.id === id))
      ) {
        // at least one group users is not already selected
        // group users are qualified to be all selected
        updateSelectedUsers(groupUsers, "users", true)
      } else {
        // all group users already selected
        // group users are qualified to be all unselected
        updateSelectedUsers(groupUsers, "users", false)
      }
    }
  }

  const [disabledRestrictedDays, setDisabledRestrictedDays] = useState(
    initialHybridRule ? initialHybridRule.restrictedDays.length === 0 : true
  )

  const indexedDays = useIndexedDays(true)
  const selectedIndexedDays = indexedDays.filter(
    (d) =>
      formInputs.restrictedDays.find((index) => index === d.index) === undefined
  )

  const toggleSelectedDay = (indexedDay: IndexedDay) => {
    const restrictedDays = formInputs.restrictedDays.filter(
      (index) => index !== indexedDay.index
    )
    if (
      formInputs.restrictedDays.find((index) => index === indexedDay.index) ===
      undefined
    )
      restrictedDays.push(indexedDay.index)

    setFormInputs({ ...formInputs, restrictedDays })
  }

  const getMaxSlotsError = () => {
    if (formInputs.period === "month") {
      if (!disabledRestrictedDays) return t("invalid form")
      if (formInputs.maxDays > 31)
        return t("hybrid-rule-form-error--max-too-high")
      return undefined
    }
    if (formInputs.period === "week") {
      if (disabledRestrictedDays && formInputs.maxDays > WEEK_MAX_DAYS)
        return t("hybrid-rule-form-error--max-too-high")
      if (
        !disabledRestrictedDays &&
        formInputs.maxDays > formInputs.restrictedDays.length
      )
        return t("hybrid-rule-form-error--max-too-high")
      return undefined
    }
    return undefined
  }

  const getFormValidity = () => {
    const targetsLength =
      selectedTargets.users.length + selectedTargets.managers.length
    if (type !== "default" && targetsLength === 0) return false
    if (isGroupRule && (!formInputs.name || formInputs.name === ""))
      return false
    if (formInputs.period && getMaxSlotsError() !== undefined) return false
    return true
  }

  if (!me) return <></>

  return (
    <FormContainer>
      <Form onSubmit={(e) => e.preventDefault()}>
        <TargetAndNamePicker
          type={type}
          initialHybridRule={initialHybridRule}
          selectedTargets={selectedTargets}
          isGroupRule={isGroupRule}
          setIsGroupRule={setIsGroupRule}
          formInputs={formInputs}
          setFormInputs={setFormInputs}
          updateSelectedUsers={(users) => updateSelectedUsers(users, "users")}
          updateSelectedManagers={(managers) =>
            updateSelectedUsers(managers, "managers")
          }
          updateSelectedGroups={updateSelectedGroups}
          updateSelectedEmails={(emails) => console.log(emails)}
        />
        {isTeamRule && (
          <div style={{ marginLeft: "auto" }}>
            <Toggle
              labelToTheLeft
              label={t("hybrid-rule-label--applies-to-manager-toggle")}
              onChange={() => {
                setApplyToManager(!applyToManager)
              }}
              checked={applyToManager}
            />
          </div>
        )}

        <FieldsetColumn>
          <Label>{t("hybrid-rule-label--period")}</Label>
          <DropDown
            title={t(formInputs.period) ?? t("period")}
            minWidth={"100%"}
            options={[
              { name: t("week"), value: "week" },
              { name: t("month"), value: "month" },
            ]}
            onSelect={({ value: period }) => {
              if (period === "month") setDisabledRestrictedDays(true)
              setFormInputs({
                ...formInputs,
                period,
                restrictedDays: [],
                maxDays: DEFAULT_MAX_DAYS,
              })
            }}
          />
        </FieldsetColumn>
        <FieldsetColumn>
          <Label>{t("hybrid-rule-label--max-slots")}</Label>
          <Tooltip
            title={
              !formInputs.period
                ? t("hybrid-rule-form--select-period-first")
                : ""
            }
          >
            <NumberField
              min={0}
              handleChange={(maxSlots) =>
                setFormInputs({ ...formInputs, maxDays: maxSlots })
              }
              max={formInputs.period !== "week" ? undefined : WEEK_MAX_DAYS}
              value={formInputs.maxDays ?? 1}
              disabled={!formInputs.period}
              error={getMaxSlotsError() !== undefined}
              helperText={getMaxSlotsError()}
            />
          </Tooltip>
        </FieldsetColumn>
        <Divider />

        <DaysSelection
          selectedDays={selectedIndexedDays}
          disabled={disabledRestrictedDays}
          forceDisabled={formInputs.period === "month"}
          setDisabled={(disabled) => {
            setDisabledRestrictedDays(disabled)
            if (disabled)
              setFormInputs({
                ...formInputs,
                restrictedDays: [],
                maxDays: DEFAULT_MAX_DAYS,
              })
          }}
          onUpdate={toggleSelectedDay}
        />

        <Divider />
      </Form>
      <FormFooter
        confirmDisabled={!getFormValidity()}
        onConfirm={() => updateHybridWorkRule(formInputs)}
        onCancel={onCancel}
      />
    </FormContainer>
  )
}
