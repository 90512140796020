import { ApolloSandbox } from "@apollo/sandbox"
import PageLayout from "components/Layout/PageLayout/PageLayout"
import React, { useLayoutEffect, useState } from "react"
import styled from "styled-components"

const Content = styled.div`
  padding: 0px;
  overflow: hidden;
`

const Container = styled.div`
  position: absolute;
  background-color: black;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`

export default function MyTeamPage() {
  const [loaded, setLoaded] = useState(false)
  useLayoutEffect(() => {
    if (process.env.NODE_ENV === "development" && !loaded) {
      new ApolloSandbox({
        target: "#embedded-sandbox",
        initialEndpoint: `${process.env.REACT_APP_API}/graphql`,
        includeCookies: true,
      })
      setLoaded(true)
    }
  }, [loaded])

  if (process.env.NODE_ENV !== "development") return null
  return (
    <PageLayout>
      <Content>
        <Container id="embedded-sandbox" style={{ height: "100%" }} />
      </Content>
    </PageLayout>
  )
}
