import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { MeetingRoom, UpsertEventInput } from "types"
import { colors, DropDown, FieldsetColumn, Label } from "ui"

import { UpsertEventFormErrorsType } from "../MeetingRoomEventForm"

const ErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors.redAlert};
  padding: 4px 0 0 4px;
`

interface PropsType {
  meetingRooms: MeetingRoom[]
  inputs: UpsertEventInput
  errors: UpsertEventFormErrorsType
  setInputs: (inputs: UpsertEventInput) => void
}

export default function RoomPicker({
  meetingRooms,
  inputs,
  errors,
  setInputs,
}: PropsType) {
  const t = useTranslate()
  const getDropDownTitle = () => {
    const found = meetingRooms.find((mr) => mr.id === inputs.roomId)
    if (!found) return t("meeting location")
    return `${found.name} (${found.capacity} ${t("seats")})`
  }
  return (
    <FieldsetColumn>
      <Label className={"required"}>{t("meeting location")}</Label>
      <DropDown
        title={getDropDownTitle()}
        options={[
          ...meetingRooms.map((mr) => ({
            name: mr.name,
            value: mr.id,
          })),
        ]}
        state={inputs.roomId}
        onSelect={({ value }) => {
          setInputs({ ...inputs, roomId: value })
        }}
      />
      {errors.attendees && (
        <ErrorMessage>
          <p>{errors.attendees}</p>
        </ErrorMessage>
      )}
    </FieldsetColumn>
  )
}
