import { ApolloCache } from "@apollo/client"

import { CompanyIntegrationFragment } from "./fragments/CompanyIntegrationFragments"

interface PropsType {
  cache: ApolloCache<any>
  data: any
  mutationName: string
  remove?: boolean
}
export default function updateCompanyIntegrationCache({
  cache,
  data,
  mutationName,
  remove,
}: PropsType) {
  if (data && data[mutationName]) {
    const notification = data[mutationName]
    const fields = {
      companyIntegrations: (existing = []) => {
        const s = existing.filter(
          (es: any) =>
            es.__ref !== `${notification.__typename}:${notification.id}`
        )
        if (remove) {
          return s
        }
        const newRef = cache.writeFragment({
          data: notification,
          fragment: CompanyIntegrationFragment,
        })
        return [...s, newRef]
      },
    }
    cache.modify({ fields })
  }
}
