import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useUserHybridRule from "graphql/hybridPolicy/useUserHybridRule"
import usePlanningUserSlots from "graphql/slots/usePlanningUserSlots"
import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import React from "react"
import { L12, L12GreyBold } from "ui"
import { getTypicalWeekFromTo } from "utils/typicalWeek"

export default function HybridInfos() {
  const t = useTranslate()
  const { user: me } = useUser()

  const { userShowWeekends } = usePlanningContext()

  const { from, to } = getTypicalWeekFromTo(userShowWeekends)

  const { slots } = usePlanningUserSlots({
    userId: me ? me.id : "",
    from,
    to,
  })

  const { userHybridRule } = useUserHybridRule(me ? me.id : "", from, to)

  const nbHomeDayUsed = slots.filter((slot) => slot.value === "home").length / 2

  const nbHomeDayAvailable = userHybridRule
    ? userHybridRule.maxDays - nbHomeDayUsed
    : 0
  const daysToHighlight =
    userHybridRule && userHybridRule.period === "week" && nbHomeDayAvailable > 0
      ? userHybridRule.restrictedDays
      : []

  if (!me) return <></>
  if (!userHybridRule) return <></>
  if (userHybridRule.period === "month")
    return (
      <div className="hybrid-rules-info">
        <L12GreyBold>
          {userHybridRule.maxDays <= 1
            ? t("nb day available in home office each month", {
                nb: `${userHybridRule.maxDays}`,
              })
            : t("nb days available in home office each month", {
                nb: `${userHybridRule.maxDays}`,
              })}
        </L12GreyBold>
      </div>
    )

  if (userHybridRule.period === "week")
    return (
      <div className="hybrid-rules-info">
        {nbHomeDayAvailable > 0 ? (
          <L12 color="grey1">
            <strong>{nbHomeDayAvailable}</strong>{" "}
            {t(
              nbHomeDayAvailable <= 1
                ? "remaining day of home office this week"
                : "remaining days of home office this week"
            )}
          </L12>
        ) : (
          <L12 className="hybrid-rules-error">
            {t("You have no more days available for home office this week")}
          </L12>
        )}
        {daysToHighlight.length > 0 && (
          <L12 className="hybrid-rules-caption">
            {t(
              daysToHighlight.length === 1
                ? "Preferred day for home office"
                : "Preferred days for home office"
            )}
          </L12>
        )}
      </div>
    )
  else return <></>
}
