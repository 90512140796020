import { gql, useMutation } from "@apollo/client"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useTranslate from "intl/useTranslate"

import { UserHybridRuleFragment } from "./fragments/hybridPolicyFragments"
import updateCache from "./updateCache"

const MUTATION = gql`
  ${UserHybridRuleFragment}
  mutation deleteUserHybridRule($ruleId: ID!) {
    deleteUserHybridRule(ruleId: $ruleId) {
      ...UserHybridRuleFragment
    }
  }
`
export default function useDeleteUserHybridRule() {
  const t = useTranslate()
  const { addToast } = useToasts()
  const [mutate] = useMutation(MUTATION, {
    update: (cache, { data }) =>
      updateCache({
        cache,
        data,
        mutationName: "deleteUserHybridRule",
        remove: true,
      }),
  })

  return async (ruleId: string) => {
    try {
      await mutate({
        variables: {
          ruleId,
        },
      })
      addToast(t("hybrid-rule--delete-success"), { appearance: "success" })
    } catch (error) {
      console.error(error)
      addToast(error.message, { appearance: "error" })
    }
  }
}
