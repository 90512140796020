import { ApolloClient, ApolloError, gql, useApolloClient } from "@apollo/client"
import { UserCompleteStatsFragment } from "graphql/users/fragments/userFragment"
import { useCallback, useState } from "react"
import { UserCompleteStats } from "types"
import UTCDate from "utils/UTCDate"

const USERS_STATS_QUERY = gql`
  ${UserCompleteStatsFragment}
  query stats_usersStats(
    $variant: String!
    $from: Date!
    $to: Date!
    $orderBy: String!
    $limit: Int
    $offset: Int
  ) {
    stats_usersStats(
      variant: $variant
      from: $from
      to: $to
      limit: $limit
      offset: $offset
      orderBy: $orderBy
    ) {
      ...UserCompleteStatsFragment
    }
  }
`

const USERS_STATS_CSV_QUERY = gql`
  query stats_usersStatsCSV(
    $variant: String!
    $from: Date!
    $to: Date!
    $orderBy: String!
  ) {
    stats_usersStatsCSV(
      variant: $variant
      from: $from
      to: $to
      orderBy: $orderBy
    )
  }
`

export default function useUsersStats(
  variant: "team" | "admin",
  limit?: number
): {
  loading: boolean
  hasSearched: boolean
  error?: ApolloError
  usersStats: UserCompleteStats[]
  start: (from: UTCDate, to: UTCDate, orderBy: string) => void
  getCSV: () => Promise<string>
  fetchMore: () => void
  canFetchMore: boolean
} {
  const client = useApolloClient()
  const [loading, setLoading] = useState(false)
  const [canFetchMore, setCanFetchMore] = useState(false)
  const [offset, setOffset] = useState<number | undefined>(
    limit && limit > 0 ? 0 : undefined
  )
  const [orderBy, setOrderBy] = useState<string>("")
  const [from, setFrom] = useState<UTCDate | null>(null)
  const [to, setTo] = useState<UTCDate | null>(null)
  const [hasSearched, setHasSearched] = useState<boolean>(false)
  const [usersStats, setUsersStats] = useState<UserCompleteStats[]>([])
  const [error, setError] = useState<ApolloError | undefined>(undefined)

  const executeQuery = async (
    apolloClient: ApolloClient<any>,
    variant: "team" | "admin",
    from: UTCDate,
    to: UTCDate,
    orderBy: string,
    limit?: number,
    offset?: number
  ) => {
    const { data } = await apolloClient.query({
      query: USERS_STATS_QUERY,
      variables: { variant, limit, offset, orderBy, from: from, to: to },
    })
    return data.stats_usersStats || []
  }

  const start = useCallback(
    async (from: UTCDate, to: UTCDate, orderBy: string) => {
      if (limit) setOffset(0)
      try {
        setLoading(true)
        setOrderBy(orderBy)
        setFrom(from)
        setTo(to)
        const newUsers = await executeQuery(
          client,
          variant,
          from,
          to,
          orderBy,
          limit,
          limit ? 0 : undefined
        )
        setHasSearched(true)
        setLoading(false)
        setUsersStats(newUsers)
        setCanFetchMore(newUsers.length > 0 && newUsers.length === limit)
      } catch (e) {
        setError(e)
      }
    },
    [variant, client, setUsersStats, limit]
  )

  const fetchMore = useCallback(async () => {
    if (limit) {
      let newOffset = limit
      if (offset) newOffset += offset
      try {
        if (from && to) {
          setLoading(true)
          const newUsersStats = await executeQuery(
            client,
            variant,
            from,
            to,
            orderBy,
            limit,
            newOffset
          )
          setLoading(false)
          setCanFetchMore(
            newUsersStats.length > 0 && newUsersStats.length === limit
          )
          setUsersStats([...usersStats, ...newUsersStats])
          setOffset(newOffset)
        }
      } catch (e) {
        setError(e)
      }
    }
  }, [
    variant,
    setUsersStats,
    from,
    to,
    orderBy,
    limit,
    offset,
    client,
    usersStats,
  ])

  const getCSV = useCallback(async () => {
    if (from && to) {
      const { data } = await client.query({
        query: USERS_STATS_CSV_QUERY,
        variables: { variant, from: from, to: to, orderBy },
      })
      if (data?.stats_usersStatsCSV) {
        return data.stats_usersStatsCSV
      }
    }
    return ""
  }, [variant, client, from, to, orderBy])

  return {
    usersStats,
    loading,
    error,
    start,
    fetchMore,
    getCSV,
    canFetchMore,
    hasSearched,
  }
}
