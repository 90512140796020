import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useLocationOccupation from "hooks/useLocationOccupation"
import React from "react"
import styled from "styled-components"
import { colors, L12, P16, transitions } from "ui"
import LocationTree from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

import { Counter } from "../components/ParentChildSharedComponents"

const Item = styled.li`
  padding-right: 8px;
`

const Layout = styled.button`
  display: grid;
  grid-template-columns: 1fr min-content;
  padding: 4px 16px;
  align-items: center;
  margin: 0;
  border: solid 1px ${colors.grey2};
  border-radius: 100vh;
  background: ${colors.grey4};
  width: 100%;
  gap: 4px;

  &.focused {
    border: solid 1px ${colors.purple};
  }

  &.disabled {
    border: solid 1px ${colors.grey3};
    cursor: not-allowed;
    > p {
      color: ${colors.grey2};
    }
  }

  > p:first-child {
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  transition: ${transitions.fade};
`

interface PropsType {
  location: LocationTree
  selectedLocation: LocationTree | undefined
  from: UTCDate
  to: UTCDate
  onSelect: (location: LocationTree) => void
}

export default function ChildLocation({
  location,
  selectedLocation,
  from,
  to,
  onSelect,
}: PropsType) {
  const { openDrawer } = usePlanningContext()
  const { available } = useLocationOccupation(location, from, to)

  const disabled =
    openDrawer === "SLOTS_EDITOR" && (available === 0 || location.bookable <= 0)
  const hasFocused = !disabled && selectedLocation?.id === location.id

  const className = disabled ? "disabled" : hasFocused ? "focused" : ""
  return (
    <Item
      onClick={() => {
        if (!disabled) onSelect(location)
      }}
    >
      <Layout className={className}>
        <P16>{location.name}</P16>
        {openDrawer === "SLOTS_EDITOR" ? (
          <Counter className={disabled ? "disabled" : ""}>
            <L12 color="black">{available}</L12>
            <L12 color="grey1">/{location.bookable}</L12>
          </Counter>
        ) : (
          <div />
        )}
      </Layout>
    </Item>
  )
}
