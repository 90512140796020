import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function Lucca({ fill, ...rest }: SvgIconProps) {
  return (
    <SvgIcon fill={fill ? fill : "currentColor"} {...rest}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 711.25 247.46">
        <g id="Calque_2" data-name="Calque 2">
          <g id="Calque_1-2" data-name="Calque 1">
            <path
              className="cls-1"
              d="M159,79.16A78.93,78.93,0,0,0,79.78,0C35.87,0,0,35.25,0,79.16c0,33.4,21,63.71,52.57,74.84-24.74,14.84-32.16,46.39-17.32,71.12,4.33,7.43,11.14,13.61,18.56,17.94a12.27,12.27,0,0,0,16.08-18.55c-1.24-.62-2.48-1.86-3.71-1.86-13-7.42-17.94-24.74-9.9-37.73C63.7,171.94,81,167,94,175c13,7.42,17.93,24.74,9.89,37.73A30.24,30.24,0,0,1,94,222.65c-5.57,3.71-7.42,11.75-3.71,17.32a11.69,11.69,0,0,0,9.89,5.56,10.44,10.44,0,0,0,6.19-1.85,51.82,51.82,0,0,0,1.23-89.06C137.92,142.87,159,112.56,159,79.16Zm-53.81,45.15a12.19,12.19,0,1,1-12.37-21c13-7.43,17.94-24.74,9.9-37.73s-24.74-17.94-37.73-9.9C52,63.08,47,80.4,55,93.39a30.39,30.39,0,0,0,9.9,9.9,12.52,12.52,0,0,1-8.66,23.5,12.93,12.93,0,0,1-3.71-2.48A51.65,51.65,0,0,1,33.4,53.81a52.71,52.71,0,0,1,71.74-19.18,51.66,51.66,0,0,1,19.17,70.51c-4.94,8-11.75,14.23-19.17,19.17Z"
            />
            <path className="cls-2" d="M255.43,244.92H237.49V70.51h17.94Z" />
            <path
              className="cls-2"
              d="M384.69,213.37c0,12.37.62,22.27,1.24,31.55H369.85l-.62-18.56h-.62c-8,13.61-22.26,21.65-37.73,21-17.93,0-39-10.51-39-51.33V126.17h18.55V193c0,22.89,6.81,39,26.6,39,17.32-.62,31.54-14.85,30.92-32.16h0v-73.6h17.94Z"
            />
            <path
              className="cls-2"
              d="M501,240.59a81,81,0,0,1-32.16,6.8c-34,0-56.28-24.12-56.28-60.61s24.12-63.7,60.61-63.7c9.9,0,19.79,1.85,28.45,6.18l-4.33,14.84a48.43,48.43,0,0,0-24.12-6.18c-27.21,0-42.05,22.27-42.05,47.62,0,29.07,17.31,47,41.43,47,8.66,0,17.94-2.48,26-6.19Z"
            />
            <path
              className="cls-2"
              d="M606.11,240.59a81.05,81.05,0,0,1-32.17,6.8c-34,0-56.28-24.12-56.28-60.61s24.12-63.7,60.61-63.7c9.9,0,19.8,1.85,28.45,6.18l-4.33,14.84a49.09,49.09,0,0,0-24.73-6.18c-27.22,0-42.06,22.27-42.06,47.62,0,29.07,17.32,47,41.44,47,8.66,0,17.93-2.48,26-6.19Z"
            />
            <path
              className="cls-2"
              d="M709.39,216.47a219.32,219.32,0,0,0,1.86,28.45h-16.7l-1.86-15.47h-.62a40.27,40.27,0,0,1-35.25,17.94c-17.94,1.24-33.4-13-34.63-30.92V214c0-28.45,24.73-43.91,69.27-43.91,0-9.9,0-32.78-27.22-32.78A53.38,53.38,0,0,0,635.17,146l-4.33-12.37a68.38,68.38,0,0,1,35.88-9.9c33.39,0,42,23.51,42,47Zm-17.93-33.4c-22.27,0-50.72,3.09-50.72,28.45,0,15.46,9.9,22.26,20.41,22.26A29.07,29.07,0,0,0,691.46,206Z"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  )
}
