import "./App.css"

import LocalizationProvider from "@mui/lab/LocalizationProvider"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import HomeButton from "components/HomeButton/HomeButton"
import HomeContextProvider from "components/HomeDialog/components/HomeContextProvider"
import HomeDialog from "components/HomeDialog/HomeDialog"
import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import PageOops from "components/PageOops"
import PlanningContextProvider from "components/PlanningContextProvider/PlanningContextProvider"
import ProductFruitsWrapper from "components/ProductFruits/ProductFruitsWrapper"
import SettingsContextProvider from "components/SettingsDialog/components/SettingsContextProvider"
import SettingsDialog from "components/SettingsDialog/SettingsDialog"
import enLocale from "date-fns/locale/en-US"
import frLocale from "date-fns/locale/fr"
import useUser from "graphql/users/useUser"
import useMediaQueries from "hooks/useMediaQueries"
import OnboardingPage from "pages/OnboardingPage/OnboardingPage"
import OnboardingRedirectWithToken from "pages/OnboardingUserPage/OnboardingRedirectWithToken"
import OnboardingTokenError from "pages/OnboardingUserPage/OnboardingTokenError"
import OnboardingUserPage from "pages/OnboardingUserPage/OnboardingUserPage"
import Page404 from "pages/Page404/Page404"
import AuthLayout from "PublicApp/AuthLayout"
import React from "react"
import { useIntl } from "react-intl"
import { Redirect, Route, Switch } from "react-router-dom"

import AppLayout from "./AppLayout/AppLayout"
import routes, {
  notAccessibleOnDesktopPaths,
  notAccessibleOnMobilePaths,
} from "./routes"

export default function App() {
  const { locale } = useIntl()
  const { isMobile } = useMediaQueries()

  const getRoutes = () => {
    const authorizedRoutes = routes.filter(
      (route) => !route.role || route.role === user?.role
    )
    if (isMobile)
      return authorizedRoutes.filter(
        (route) =>
          notAccessibleOnMobilePaths.find((path) => route.path === path) ===
          undefined
      )
    else if (!isMobile)
      return authorizedRoutes.filter(
        (route) =>
          notAccessibleOnDesktopPaths.find((path) => route.path === path) ===
          undefined
      )
    return authorizedRoutes
  }

  const { user, loading, error } = useUser()
  if (error || (loading === false && user === null)) {
    return (
      <AuthLayout>
        <PageOops />
      </AuthLayout>
    )
  }
  if (loading || error || !user)
    return <LoaderOrErrorComponent loading={loading} error={error} />

  const shouldOnboard = Boolean(user.flags?.shouldOnboard)

  const shouldOnboardUser = false // Boolean(user.flags?.shouldOnboardUser)

  if (!isMobile && shouldOnboard) {
    return (
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        locale={locale === "fr" ? frLocale : enLocale}
      >
        <OnboardingPage />
      </LocalizationProvider>
    )
  }

  if (shouldOnboardUser) {
    return (
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        locale={locale === "fr" ? frLocale : enLocale}
      >
        <PlanningContextProvider>
          <Switch>
            <Route path="/onboarding/signin/:token" exact>
              <OnboardingRedirectWithToken />
            </Route>
            <Route path="/">
              {sessionStorage.getItem("signupToken") !== null ? (
                <OnboardingUserPage />
              ) : (
                <OnboardingTokenError />
              )}
            </Route>
          </Switch>
        </PlanningContextProvider>
      </LocalizationProvider>
    )
  }

  return (
    <>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        locale={locale === "fr" ? frLocale : enLocale}
      >
        <ProductFruitsWrapper locale={locale} />
        <SettingsContextProvider>
          <PlanningContextProvider>
            <HomeContextProvider>
              <Switch>
                <Redirect from="/congrats" to="/planning" />
                <Redirect from="/signup" to="/planning" />
                <Redirect from="/signup-company" to="/planning" />
                <Redirect from="/signup/:token" to="/planning" />
                <Redirect from="/sign-in" to="/planning" />
                <Redirect from="/sign-in-ms" to="/planning" />
                <Redirect from="/sign-in-okta" to="/planning" />
                <Redirect from="/sso" to="/planning" />
                <Redirect from="/account-validation" to="/planning" />
                <Redirect from="/link-account" to="/planning" />
                <Redirect from="/email-action" to="/planning" />
                {getRoutes().map((route) => (
                  <Route key={route.path} path={route.path} exact>
                    <AppLayout>
                      <route.component />
                      {isMobile && <HomeDialog />}
                      {!isMobile && <HomeButton />}
                      <SettingsDialog />
                    </AppLayout>
                  </Route>
                ))}
                {/* DEFAULT PAGE */}
                <Route path="/">
                  <AppLayout>
                    <Page404 />
                  </AppLayout>
                </Route>
              </Switch>
            </HomeContextProvider>
          </PlanningContextProvider>
        </SettingsContextProvider>
      </LocalizationProvider>
    </>
  )
}
