import { gql } from "@apollo/client"

export const SlotTemplateFragment = gql`
  fragment SlotTemplateFragment on Slot {
    id
    value
    date
    userId
    locationId
    label
  }
`
