import useMediaQueries from "hooks/useMediaQueries"
import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { Button, colors } from "ui"
import { Plus } from "ui/icons"
import UTCDate from "utils/UTCDate"

import NewTeamDayDialog from "./NewTeamDayDialog/NewTeamDayDialog"

const AddTeamDayButtonMobile = styled(Plus)`
  stroke: ${colors.grey1};
  margin-left: auto;
`

const AddTeamDayButton = styled(Button)`
  padding: 8px;
  height: unset;
`

interface PropsType {
  initialTeamDayDate?: UTCDate
}

export default function NewTeamDayButton({ initialTeamDayDate }: PropsType) {
  const history = useHistory()

  const { isMobile } = useMediaQueries()
  const [dialogOpen, setDialogOpen] = useState(
    initialTeamDayDate !== undefined ?? false
  )

  return (
    <>
      {isMobile ? (
        <AddTeamDayButtonMobile onClick={() => setDialogOpen(true)} />
      ) : (
        <AddTeamDayButton secondary onClick={() => setDialogOpen(true)}>
          <Plus className="icon" />
        </AddTeamDayButton>
      )}
      {dialogOpen && (
        <NewTeamDayDialog
          initialTeamDayDate={initialTeamDayDate}
          onClose={() => {
            setDialogOpen(false)
            if (history.location.search !== "") {
              history.replace("/myteam")
            }
          }}
        />
      )}
    </>
  )
}
