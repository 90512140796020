import { ButtonBase } from "@mui/material"
import FavoriteCheckbox from "components/FavoriteCheckbox"
import UserAvatar from "components/UserAvatar/UserAvatar"
import useJoinOrLeaveGroup from "graphql/groups/useJoinOrLeaveGroup"
import useUser from "graphql/users/useUser"
import useUserTree from "hooks/useUserTree"
import useTranslate from "intl/useTranslate"
import React from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { Group, UserBase } from "types"
import { Button, colors } from "ui"
import { Trash } from "ui/icons"
import mediaQueries from "ui/mediaQueries"
import { P16 } from "ui/Typography"
import UserTree from "utils/UserTree"

import transitions from "../transitions"

const UserContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 45px;
  padding: 0 16px 0 12px;
  border-top: solid 1px ${colors.grey3};
  background: hsla(${colors.blackReal}, 0);

  cursor: pointer;

  > div:first-child {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 2;

    > span {
      visibility: hidden;
      opacity: 0;
      transition: ${transitions.generic};
    }
  }

  @media ${mediaQueries.isDesktop} {
    &:hover {
      background: hsla(${colors.blackReal}, 0.04);

      svg {
        color: ${colors.black};
      }

      div:first-child > span {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  @media ${mediaQueries.isMobile} {
    div:first-child > span {
      visibility: visible;
      opacity: 1;
    }
  }

  transition: ${transitions.generic};
`

const ButtonIconStyled = styled(ButtonBase)`
  margin-left: auto;

  svg {
    color: ${colors.grey2};
    height: 20px;
    width: 20px;
  }

  &:hover {
    svg {
      color: ${colors.grey1};
    }
  }
`

interface PropsType {
  children: React.ReactNode
  user: UserBase
  group?: Group
  showFavoriteCheckbox?: boolean
  showLeaveGroupButton?: boolean
  onUserSelect?: (user: UserBase) => void
}

export function UserListItem({
  children,
  user,
  group,
  showFavoriteCheckbox = false,
  showLeaveGroupButton = true,
  onUserSelect,
}: PropsType) {
  const t = useTranslate()
  const history = useHistory()

  const { leaveGroup } = useJoinOrLeaveGroup()
  const { userTree } = useUserTree()
  const { user: currentUser } = useUser()

  const isLeaveGroupButtonDisplayed =
    (group !== undefined &&
      ((currentUser && currentUser.role === "admin") ||
        (currentUser && currentUser.id === group.flags?.createdBy))) ||
    (UserTree.isManagedBy(userTree, currentUser?.id, user.id) &&
      showLeaveGroupButton)

  return (
    <UserContainer>
      <div
        onClick={() => {
          if (onUserSelect) return onUserSelect(user)
          history.push(`/user/${user.id}`)
        }}
      >
        <UserAvatar user={user} />
        <P16>{children}</P16>
        {showFavoriteCheckbox && (
          <FavoriteCheckbox itemId={user.id} type="user" />
        )}
      </div>
      {isLeaveGroupButtonDisplayed && (
        <ButtonIconStyled
          onClick={() => group && user && leaveGroup(group.id, user.id)}
        >
          <Trash />
        </ButtonIconStyled>
      )}
      {onUserSelect && (
        <Button ghost onClick={() => onUserSelect(user)}>
          {t("hybrid-rule-add-edit--user")}
        </Button>
      )}
    </UserContainer>
  )
}
