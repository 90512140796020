import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { mediaQueries, P16 } from "ui"

const List = styled.ul`
  display: grid;
  grid-gap: 8px;
  &.user-onboarding-week-days-list {
    grid-template-columns: repeat(5, 1fr);

    &--show-weekend {
      grid-template-columns: repeat(7, 1fr);
    }
  }
  margin: 0 24px;

  @media ${mediaQueries.isMobile} {
    margin: 0 4px;
  }

  li {
    text-align: center;
  }

  .highlight {
    background-color: rgba(0, 220, 163, 0.15);
    border-radius: 4px;
    padding: 0 8px;
  }
`

interface PropsType {
  daysToHighlight?: number[]
}

export default function UserOnboardingWeekDays({ daysToHighlight }: PropsType) {
  const t = useTranslate()
  const { userShowWeekends } = usePlanningContext()
  const showWeekends = userShowWeekends
  const { isMobile } = useMediaQueries()

  const days = showWeekends
    ? [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ]
    : ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"]

  return (
    <List
      className={
        showWeekends
          ? `user-onboarding-week-days-list--show-weekend`
          : `user-onboarding-week-days-list`
      }
    >
      {React.Children.map(days, (day, i) => (
        <li className={daysToHighlight?.includes(i + 1) ? "highlight" : ""}>
          <P16>{!isMobile ? t(day) : `${t(day).slice(0, 1)}.`}</P16>
        </li>
      ))}
    </List>
  )
}
