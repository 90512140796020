import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useLocationOccupation from "hooks/useLocationOccupation"
import React, { useState } from "react"
import styled from "styled-components"
import { colors, L12, LU14 } from "ui"
import { ArrowRightFill } from "ui/icons"
import LocationTree from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

import ChildLocation from "../ChildLocation/ChildLocation"
import { Counter } from "../components/ParentChildSharedComponents"

const List = styled.ul`
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  gap: 8px;

  &.selected {
    background: ${colors.grey3}88;
    border-radius: 8px;
  }
`

const ParentHeader = styled.li`
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  align-items: center;
  padding: 2px 0;
  cursor: pointer;

  p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  > :last-child {
    margin-left: auto;
  }
`

const CollapseButton = styled.button`
  margin: 0;
  padding: 0;
  display: flex;
  background: none;
  border: none;

  &.btn-collapse--rotated {
    transform: rotate(90deg);
  }

  transition: transform 250ms ease-in-out;
`

const ChildrenList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  animation: fadeIn 500ms ease-in-out;
`

interface PropsType {
  location: LocationTree
  selectedLocation: LocationTree | undefined
  selectedFloorPlan: LocationTree | undefined
  from: UTCDate
  to: UTCDate
  onSelect: (location: LocationTree) => void
}

export default function ParentLocation({
  location,
  selectedLocation,
  selectedFloorPlan,
  from,
  to,
  onSelect,
}: PropsType) {
  const [isCollapsed, setIsCollasped] = useState(false)
  const { openDrawer } = usePlanningContext()
  const { available } = useLocationOccupation(location, from, to)

  const isSelected = selectedFloorPlan?.id === location.id

  return (
    <li>
      <List className={isSelected ? "selected" : ""}>
        <ParentHeader
          onClick={() => {
            onSelect(location)
          }}
        >
          <CollapseButton
            onClick={(e) => {
              e.stopPropagation()

              setIsCollasped((prev) => !prev)
            }}
            className={isCollapsed ? "" : "btn-collapse--rotated"}
          >
            <ArrowRightFill />
          </CollapseButton>
          <LU14>{location.name}</LU14>
          {openDrawer === "SLOTS_EDITOR" ? (
            <Counter>
              <L12 color="black">{available}</L12>
              <L12 color="grey1">/{location.bookable}</L12>{" "}
            </Counter>
          ) : (
            <div />
          )}
        </ParentHeader>
        {!isCollapsed && (
          <ChildrenList>
            {location.children.map((child) => {
              const key = `loc-parent-${location.id}-${child.id}`
              if (child.isCategory)
                return (
                  <ParentLocation
                    key={key}
                    location={child}
                    selectedLocation={selectedLocation}
                    selectedFloorPlan={selectedFloorPlan}
                    from={from}
                    to={to}
                    onSelect={onSelect}
                  />
                )
              return (
                <ChildLocation
                  key={key}
                  location={child}
                  selectedLocation={selectedLocation}
                  from={from}
                  to={to}
                  onSelect={onSelect}
                />
              )
            })}
          </ChildrenList>
        )}
      </List>
    </li>
  )
}
