import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function Celebration({ fill, ...rest }: SvgIconProps) {
  return (
    <SvgIcon {...rest}>
      <path
        stroke="none"
        fill={fill ? fill : "currentColor"}
        d="M3.75,21c-0.2,0-0.39-0.08-0.53-0.22c-0.2-0.2-0.27-0.5-0.18-0.77l3.5-10.5c0.08-0.25,0.29-0.43,0.54-0.49
			c0.25-0.06,0.52,0.02,0.7,0.2l7,7c0.18,0.18,0.26,0.45,0.2,0.7c-0.06,0.25-0.25,0.46-0.49,0.54l-10.5,3.5
			C3.91,20.99,3.83,21,3.75,21z M7.58,11.14l-2.64,7.93l7.93-2.64L7.58,11.14z"
      />
      <path
        stroke="none"
        fill={fill ? fill : "currentColor"}
        d="M13.04,11.04c-0.19,0-0.38-0.07-0.53-0.22c-0.29-0.29-0.29-0.77,0-1.06c0.56-0.56,1.19-0.53,1.57-0.52
			c0.33,0.02,0.36,0,0.44-0.08c0.08-0.08,0.09-0.11,0.08-0.44c-0.02-0.38-0.04-1.01,0.51-1.56c0.56-0.56,1.19-0.53,1.57-0.52
			c0.33,0.01,0.36,0,0.44-0.08c0.08-0.08,0.09-0.12,0.08-0.44c-0.02-0.38-0.04-1.01,0.52-1.57c0.56-0.56,1.19-0.53,1.57-0.52
			c0.33,0.02,0.36,0,0.44-0.08c0.29-0.29,0.77-0.29,1.06,0c0.29,0.29,0.29,0.77,0,1.06c-0.56,0.56-1.19,0.53-1.57,0.52
			c-0.33-0.01-0.36,0-0.44,0.08c-0.08,0.08-0.09,0.12-0.08,0.44c0.02,0.38,0.04,1.01-0.52,1.57c-0.56,0.56-1.19,0.53-1.56,0.52
			c-0.33-0.01-0.36,0-0.44,0.08c-0.08,0.08-0.09,0.11-0.08,0.44c0.02,0.38,0.04,1.01-0.51,1.56c-0.56,0.56-1.18,0.53-1.57,0.52
			c-0.33-0.01-0.36,0-0.44,0.08C13.43,10.97,13.24,11.04,13.04,11.04z"
      />
      <path
        stroke="none"
        fill={fill ? fill : "currentColor"}
        d="M10.25,9C9.84,9,9.5,8.66,9.5,8.25v-2c0-0.96-0.79-1.75-1.75-1.75C7.34,4.5,7,4.16,7,3.75S7.34,3,7.75,3
			C9.54,3,11,4.46,11,6.25v2C11,8.66,10.66,9,10.25,9z"
      />
      <path
        stroke="none"
        fill={fill ? fill : "currentColor"}
        d="M20.25,17c-0.41,0-0.75-0.34-0.75-0.75c0-0.96-0.79-1.75-1.75-1.75h-2c-0.41,0-0.75-0.34-0.75-0.75
			S15.34,13,15.75,13h2c1.79,0,3.25,1.46,3.25,3.25C21,16.66,20.66,17,20.25,17z"
      />
      <path
        stroke="none"
        fill={fill ? fill : "currentColor"}
        d="M12.75,5.5c-0.1,0-0.19-0.02-0.29-0.06c-0.09-0.04-0.17-0.09-0.24-0.16C12.08,5.14,12,4.95,12,4.75
			c0-0.2,0.08-0.39,0.22-0.53c0.21-0.21,0.54-0.28,0.82-0.16c0.09,0.04,0.17,0.09,0.24,0.16c0.07,0.07,0.12,0.15,0.16,0.24
			c0.04,0.1,0.06,0.19,0.06,0.29c0,0.2-0.08,0.39-0.22,0.53c-0.07,0.07-0.15,0.12-0.24,0.16C12.94,5.48,12.85,5.5,12.75,5.5z"
      />
      <path
        stroke="none"
        fill={fill ? fill : "currentColor"}
        d="M20.25,12c-0.2,0-0.39-0.08-0.53-0.22c-0.07-0.07-0.12-0.15-0.16-0.24c-0.04-0.09-0.06-0.19-0.06-0.29
			c0-0.2,0.08-0.39,0.22-0.53c0.07-0.07,0.15-0.12,0.24-0.16c0.19-0.08,0.39-0.08,0.58,0c0.09,0.04,0.17,0.09,0.24,0.16
			c0.14,0.14,0.22,0.33,0.22,0.53c0,0.1-0.02,0.2-0.06,0.29c-0.04,0.09-0.09,0.17-0.16,0.24C20.64,11.92,20.45,12,20.25,12z"
      />
    </SvgIcon>
  )
}
