import Drawer from "@mui/material/Drawer"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useUpdateEventUserStatus from "graphql/events/useUpdateEventUserStatus"
import useUser from "graphql/users/useUser"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { IntegrationEvent } from "types"
import { Button, IconButton, mediaQueries } from "ui"
import { ChatBubbleQuestion, ShareAndroid } from "ui/icons"
import copyTextToClipboard from "utils/copyTextToClipboard"
import {
  canUserUpdateAttendingStatus,
  compareUserAttendingStatus,
} from "utils/events"

const AttendingAndShareButtonsLayout = styled.div`
  display: flex;
  gap: 8px;
  @media ${mediaQueries.isMobile} {
    flex-direction: column;
  }
`

const ParticipationButtons = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  button {
    width: 100%;
  }
`

const StyledIconButton = styled(IconButton)`
  padding: 0 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: #fff;
    stroke-width: 1.5px;
    height: 24px;
    width: 24px;
  }
`

interface ButtonPropsType {
  event: IntegrationEvent
  onClick: () => void
}

export function ShareButton({ event, onClick }: ButtonPropsType) {
  const t = useTranslate()
  const { addToast } = useToasts()
  const { isMobile } = useMediaQueries()

  const handleClick = () => {
    copyTextToClipboard(window.location.origin + "/events/" + event.id)
    onClick()
    addToast(t("event link copied"), {
      appearance: "success",
    })
  }

  const canShareEvent =
    !event.isPrivate &&
    (location.pathname.startsWith("/events") || event.initiator !== null)

  if (!canShareEvent) return <></>

  if (isMobile)
    return <StyledIconButton icon={<ShareAndroid />} onClick={handleClick} />

  return (
    <Button secondary onClick={handleClick}>
      {t("share event")}
    </Button>
  )
}

function AttendingButton({ event, onClick }: ButtonPropsType) {
  const t = useTranslate()
  const { user: me } = useUser()
  const updateEventUserStatus = useUpdateEventUserStatus()

  const alreadyAttending = compareUserAttendingStatus(event, "attending", me)

  const canUpdateAttendingStatus = canUserUpdateAttendingStatus(
    event,
    "attending",
    me
  )

  return (
    <Button
      onClick={() => {
        if (canUpdateAttendingStatus) {
          updateEventUserStatus({
            eventId: event.id,
            status: "attending",
          })
          onClick()
        }
      }}
      stopPropagation
      disabled={!canUpdateAttendingStatus}
    >
      {alreadyAttending ? t("already attending") : t("attending event")}
    </Button>
  )
}

export function NotAttendingButton({ event, onClick }: ButtonPropsType) {
  const t = useTranslate()
  const { user: me } = useUser()
  const updateEventUserStatus = useUpdateEventUserStatus()

  const canUpdateAttendingStatus = canUserUpdateAttendingStatus(
    event,
    "not-attending",
    me
  )

  return (
    <Button
      danger
      stopPropagation
      onClick={() => {
        updateEventUserStatus({
          eventId: event.id,
          status: "not-attending",
        })
        onClick()
      }}
      disabled={!canUpdateAttendingStatus}
    >
      {t("not attending event")}
    </Button>
  )
}

export function AttendingAndShareButtons({ event, onClick }: ButtonPropsType) {
  return (
    <AttendingAndShareButtonsLayout>
      <ShareButton event={event} onClick={onClick} />
      <AttendingButton event={event} onClick={onClick} />
    </AttendingAndShareButtonsLayout>
  )
}

export function ParticipationButton({ event, onClick }: ButtonPropsType) {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)

  return (
    <>
      <Button
        secondary
        stopPropagation
        icon={<ChatBubbleQuestion />}
        fullWidth={isMobile}
        onClick={() => {
          setIsDrawerOpen(true)
        }}
      >
        {t("your participation")}
      </Button>
      <Drawer
        anchor={"bottom"}
        open={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false)
        }}
        style={{ zIndex: 4000 }}
      >
        <ParticipationButtons>
          <AttendingButton event={event} onClick={onClick} />
          <NotAttendingButton event={event} onClick={onClick} />
          <Button ghost onClick={onClick}>
            {t("Cancel")}
          </Button>
        </ParticipationButtons>
      </Drawer>
    </>
  )
}
