import useUser from "graphql/users/useUser"
import useUserFromId from "graphql/users/useUserFromId"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import { Route, Switch, useParams } from "react-router-dom"
import styled from "styled-components"
import { Equipment, EquipmentAvailability, User, WorkingMode } from "types"
import { Button, colors, P14 } from "ui"
import LocationTree from "utils/LocationTree"

import EquipmentButton from "./EquipmentButton/EquipmentButton"

const Form = styled.fieldset`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 16px;
  border: none;
  margin: 0;

  &.form-add-equipment {
    visibility: hidden;
    opacity: 0;
    height: 0;
    pointer-events: none;
    padding: 0;
    &--visible {
      visibility: visible;
      opacity: 1;
      height: unset;
      pointer-events: all;
    }
  }
`

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
`

const Actions = styled.li`
  margin-left: auto;
  display: flex;
  gap: 8px;
`

const P14Styled = styled(P14)`
  color: ${colors.grey1};
`

interface PropsType {
  open: boolean
  location: LocationTree
  equipments: Equipment[]
  equipmentsAvailability: EquipmentAvailability[]
  onCancel: () => void
  saveWorkingMode: (workingMode: WorkingMode) => void | Promise<void>
}

interface BookEquipmentFormPropsType extends PropsType {
  selectedUser: User
}

function BookEquipmentForm({
  open,
  location,
  equipments,
  equipmentsAvailability,
  onCancel,
  saveWorkingMode,
}: BookEquipmentFormPropsType) {
  const t = useTranslate()
  const [bookedEquipments, setBookedEquipments] = useState<Equipment[]>([])

  const updateEquipments = (equipement: Equipment) => {
    setBookedEquipments((prev) => {
      if (!prev.find((e) => e.id === equipement.id))
        return [...prev, equipement]
      return prev.filter((e) => e.id !== equipement.id)
    })
  }

  const handleSave = () => {
    saveWorkingMode({
      equipments: bookedEquipments,
      locationId: location.id,
      label: null,
      genericSlotValue: "office",
    })
  }

  const reset = () => {
    setBookedEquipments([])
  }

  return (
    <Form
      className={open ? "form-add-equipment--visible" : "form-add-equipment"}
      onSubmit={(e) => {
        e.preventDefault()
      }}
    >
      <P14Styled>{t("available services")}</P14Styled>
      <List>
        {/* 
        React.children.map needed to generate unique static key
        since equipments can be rendered more than once
        if the related location is set as favorite
        therefore rendered two times
         */}
        {React.Children.map(
          equipments.map((e) => {
            const equipment = equipmentsAvailability.find(
              (ea) => ea.equipmentId === e.id
            )
            if (!equipment) return <></>

            return (
              <EquipmentButton
                key={`equipment-${e.id}`}
                equipment={e}
                handleClick={updateEquipments}
                selected={
                  bookedEquipments.find((be) => be.id === e.id) !== undefined
                }
                availability={equipment.available}
              />
            )
          }),
          (child) => (
            <li>{child}</li>
          )
        )}
        <Actions>
          <Button
            ghost
            onClick={() => {
              reset()
              onCancel()
            }}
          >
            {t("Cancel")}
          </Button>
          <Button onClick={handleSave}>{t("Save")}</Button>
        </Actions>
      </List>
    </Form>
  )
}

function BookEquipmentFormRouter({ ...props }: PropsType) {
  const { userId } = useParams<{ userId?: string }>()
  const { user: me } = useUser()
  const { user: selectedUser } = useUserFromId(userId ?? "")

  return (
    <Switch>
      <Route path="/user/:userId">
        {() => {
          if (selectedUser === null) return <></>
          return <BookEquipmentForm selectedUser={selectedUser} {...props} />
        }}
      </Route>
      <Route>
        {() => {
          if (me === null) return <></>
          return <BookEquipmentForm selectedUser={me} {...props} />
        }}
      </Route>
    </Switch>
  )
}

export default BookEquipmentFormRouter
