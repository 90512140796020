import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useSetPlanningReminderAt from "graphql/notifications/useSetPlanningReminderAt"
import useBookingSlotReminderDate from "hooks/useBookingSlotReminderDate"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { Button, colors, mediaQueries, P16 } from "ui"
import LocalDate from "utils/LocalDate"
import UTCDate, { days, months } from "utils/UTCDate"

const Layout = styled.div`
  padding: 8px;

  @media ${mediaQueries.isMobile} {
    padding: 8px 0;
  }
`

const Warning = styled.div`
  display: flex;
  border-radius: 16px;
  gap: 36px;
  background: ${colors.grey4};
  padding: 24px;

  button {
    white-space: nowrap;
    margin-top: auto;
  }

  @media ${mediaQueries.isMobile} {
    flex-direction: column;
    border-radius: 0;
    border-top: solid 1px ${colors.grey3};
    border-bottom: solid 1px ${colors.grey3};

    button {
      width: 100%;
    }
  }
`

interface PropsType {
  to: UTCDate
}

export default function WarningCantFill({ to }: PropsType) {
  const t = useTranslate()
  const { setOpenDrawer } = usePlanningContext()
  const setPlanningReminderAt = useSetPlanningReminderAt()
  const reminderDate = useBookingSlotReminderDate(to)

  if (!reminderDate) return <></>

  return (
    <Layout>
      <Warning>
        <div>
          <P16>
            {t("not yet authorized to fill your schedule for this date")}{" "}
            <strong>{`${t(
              days[reminderDate.getDay()]
            )} ${reminderDate.getUTCDate()} ${t(
              months[reminderDate.getUTCMonth()]
            )}`}</strong>
            .
          </P16>
        </div>
        <Button
          onClick={() => {
            setPlanningReminderAt(new LocalDate(reminderDate, "DAY"))
            setOpenDrawer(null)
          }}
        >
          {t("send me a reminder")}
        </Button>
      </Warning>
    </Layout>
  )
}
