import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useUserHybridRule from "graphql/hybridPolicy/useUserHybridRule"
import useUser from "graphql/users/useUser"
import useEditableUserSlotsPermission from "hooks/useEditableUserSlotsPermission"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import ToggleAutoApplySlotTemplates from "pages/OnboardingUserPage/UserOnboardingPlanningPage/UserOnboardingWeekDays/ToggleAutoApplySlotTemplates/ToggleAutoApplySlotTemplates"
import UserOnboardingWeekDays from "pages/OnboardingUserPage/UserOnboardingPlanningPage/UserOnboardingWeekDays/UserOnboardingWeekDays"
import { EditableUserSlots } from "pages/PlanningPage/components/EditableUserSlots/EditableUserSlots"
import React, { useEffect, useState } from "react"
import styled from "styled-components/macro"
import { EditionTimeFrame } from "types"
import { Button, colors, Dialog, mediaQueries } from "ui"
import { getTypicalWeekFromTo } from "utils/typicalWeek"

import HybridInfos from "./components/HybridInfos"
import SimpleSlotEditor from "./components/SimpleSlotEditor"

const ContentLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  gap: 24px;
  padding: 16px;
  overflow: hidden;

  @media ${mediaQueries.isMobile} {
    padding: 16px 8px;
    overflow: none;
    .locations-list {
      margin-bottom: 16px;
    }
  }

  .hybrid-rules-info {
    display: flex;
    gap: 24px;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

    .hybrid-rules-error {
      padding: 4px 8px;
      background: rgba(255, 0, 0, 0.04);
      border-radius: 4px;
      font-weight: bold;
      color: ${colors.redAlert};
    }
    .hybrid-rules-caption {
      padding: 4px 8px;
      background: rgba(0, 220, 163, 0.08);
      border-radius: 4px;
      color: ${colors.green};
      font-weight: bold;
    }
  }
`

interface PropsType {
  open: boolean
  onClose: () => void
}

export default function TypicalWeekDialog({ open, onClose }: PropsType) {
  const t = useTranslate()
  const { user: me } = useUser()
  const { isMobile } = useMediaQueries()

  const {
    setActiveLocation,
    activeLocation,
    setOpenDrawer,
    userShowWeekends,
    setIsTypicalWeekOpen,
  } = usePlanningContext()

  const { from, to } = getTypicalWeekFromTo(userShowWeekends)

  const [editionTimeFrame, setEditionTimeFrame] = useState<EditionTimeFrame>({
    from: undefined,
    to: undefined,
  })
  const [loading, setLoading] = useState<boolean>(false)

  const canEditSlots = useEditableUserSlotsPermission(me)

  useEffect(() => {
    // always reset active location when browsing /planning/... pages
    if (activeLocation.location !== null) {
      setActiveLocation({
        location: null,
        persist: false,
        triggeredFrom: "active-locations-list",
      })
    }

    return () => setIsTypicalWeekOpen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isSlotEditorDisplay =
    editionTimeFrame.from && editionTimeFrame.to && !loading

  const { userHybridRule } = useUserHybridRule(me ? me.id : "", from, to)

  const daysToHighlight =
    isSlotEditorDisplay && userHybridRule && userHybridRule.period === "week"
      ? userHybridRule.restrictedDays
      : []

  if (!me) return <></>

  return (
    <Dialog
      title={t("Define your typical week")}
      open={open}
      onClose={() => {
        onClose()
        setOpenDrawer(null)
      }}
      leftButton={
        <Button
          secondary
          onClick={() => {
            onClose()
            setOpenDrawer(null)
          }}
        >
          {t("Close")}
        </Button>
      }
      fullscreen={isMobile}
    >
      <ContentLayout>
        <div>
          <UserOnboardingWeekDays daysToHighlight={daysToHighlight} />
          <EditableUserSlots
            from={from}
            to={to}
            userToMutate={me}
            editionTimeFrame={editionTimeFrame}
            canEdit={canEditSlots}
            setEditionTimeFrame={setEditionTimeFrame}
          />
          {isSlotEditorDisplay && <HybridInfos />}
        </div>
        {isSlotEditorDisplay && (
          <SimpleSlotEditor
            me={me}
            setOpenDrawer={setOpenDrawer}
            editionTimeFrame={editionTimeFrame}
            setEditionTimeFrame={setEditionTimeFrame}
            setLoading={setLoading}
          />
        )}
        {loading && (
          <LoaderOrErrorComponent loading={loading} error={undefined} />
        )}
      </ContentLayout>
      <div>
        <ToggleAutoApplySlotTemplates />
      </div>
    </Dialog>
  )
}
