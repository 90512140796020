import { ApolloError, gql, useQuery } from "@apollo/client"
import { ManagerHybridRuleWithScore } from "types"
import UTCDate from "utils/UTCDate"

import { ManagerHybridRuleFragment } from "./fragments/hybridPolicyFragments"

export const MANAGER_HYBRID_RULE_QUERY = gql`
  ${ManagerHybridRuleFragment}
  query managerHybridRule($managerId: ID!, $from: String, $to: String) {
    managerHybridRule(managerId: $managerId, from: $from, to: $to) {
      ...ManagerHybridRuleFragment
    }
  }
`

export default function useManagerHybridRule(
  managerId: string,
  from?: UTCDate,
  to?: UTCDate
): {
  loading: boolean
  error?: ApolloError
  managerHybridRule: ManagerHybridRuleWithScore | undefined
} {
  const { loading, error, data } = useQuery<{
    managerHybridRule: Omit<ManagerHybridRuleWithScore, "maxDays"> & {
      maxSlots: number
    }
  }>(MANAGER_HYBRID_RULE_QUERY, {
    variables: {
      managerId,
      from: from ? `${from.getTime()}` : undefined,
      to: to ? `${to.getTime()}` : undefined,
    },
    fetchPolicy: "cache-and-network",
  })

  const managerHybridRule = data?.managerHybridRule
    ? {
        ...data.managerHybridRule,
        maxDays: data.managerHybridRule.maxSlots / 2,
        weeksScores: data.managerHybridRule.weeksScores.map((weekScore) => ({
          ...weekScore,
          days: weekScore.days.map((day) => ({
            ...day,
            date: new UTCDate(Number(day.date)),
          })),
        })),
        monthsScores: data.managerHybridRule.monthsScores.map((monthScore) => ({
          ...monthScore,
          date: new UTCDate(Number(monthScore.date)),
        })),
      }
    : undefined

  return {
    managerHybridRule,
    loading,
    error,
  }
}
