import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useMenu from "hooks/useMenu"
import React from "react"
import styled from "styled-components"
import { colors } from "ui"
import { MidlineHorizontalEllispsis } from "ui/icons"

const Layout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  padding: 0 4px 4px 24px;
  outline: none;

  .menu-element-group-heading {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: ${colors.grey1};
    cursor: pointer;
  }

  &:hover,
  &:focus-within {
    .menu-element-group-heading {
      color: ${colors.grey1};
    }
  }

  .action {
    height: 20px;
    width: 20px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }

    svg {
      width: 16px;
      color: ${colors.grey2};
    }
  }
`

interface PropsType {
  children: React.ReactNode
  path: string
}

export function MenuTitle({ children, path }: PropsType) {
  const { handlePathAndLogEvent } = useMenu()
  const { setViewMode } = usePlanningContext()

  const onClick = () => {
    handlePathAndLogEvent(path, "menu-action", { to: path })
    setViewMode("WEEK")
  }

  return (
    <Layout className="menu-element-group-container">
      <h2 className="menu-element-group-heading" onClick={onClick}>
        {children}
      </h2>

      <div className="action" onClick={onClick}>
        <MidlineHorizontalEllispsis />
      </div>
    </Layout>
  )
}
