import useCompanyIntegrations from "graphql/integrations/useCompanyIntegrations"
import React from "react"
import styled from "styled-components"

import CompanyWideIntegrationCard from "../CompanyWideIntegrationCard/CompanyWideIntegrationCard"
import GoogleCompanyWideIntegration, {
  AuthorizeGoogleButton,
} from "../GoogleCompanyWideIntegration/GoogleCompanyWideIntegration"
import MicrosoftCompanyWideIntegration, {
  AuthorizeMicrosoftButton,
} from "../MicrosoftCompanyWideIntegration/MicrosoftCompanyWideIntegration"

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 32px;
`

const ButtonsGroup = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  padding-top: 16px;
  button {
    width: 100%;
    justify-content: center;
  }
`

export default function CompanyWideIntegrationsList() {
  const { companyIntegrations } = useCompanyIntegrations()

  const googleIntegration = companyIntegrations.find(
    ({ name }) => name === "google-events"
  )

  const microsoftIntegration = companyIntegrations.find(
    ({ name }) => name === "microsoft-outlook"
  )

  if (!googleIntegration && !microsoftIntegration)
    return (
      <List>
        <li>
          <CompanyWideIntegrationCard
            authorizeComponent={
              <ButtonsGroup>
                <AuthorizeGoogleButton
                  integration={companyIntegrations.find(
                    ({ name }) => name === "google-events"
                  )}
                />
                <AuthorizeMicrosoftButton
                  integration={companyIntegrations.find(
                    ({ name }) => name === "microsoft-outlook"
                  )}
                />
              </ButtonsGroup>
            }
            revokeComponent={<></>}
          />
        </li>
      </List>
    )

  return (
    <List>
      {googleIntegration && (
        <GoogleCompanyWideIntegration integration={googleIntegration} />
      )}
      {microsoftIntegration && (
        <MicrosoftCompanyWideIntegration integration={microsoftIntegration} />
      )}
    </List>
  )
}
