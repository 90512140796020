import InteractionsMenu from "components/InteractionsMenu/InteractionsMenu"
import useTranslate from "intl/useTranslate"
import uniqBy from "lodash.uniqby"
import React from "react"
import styled from "styled-components"
import { UserBase } from "types"
import { colors, P16Bold } from "ui"
import LocationTree from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

import AdvancedSearchSummary from "./AdvancedSearchSummary/AdvancedSearchSummary"
import HalfDay from "./components/HalfDay"

const Header = styled.div`
  padding: 8px;
  border-bottom: 1px solid ${colors.grey3};
`

const Content = styled.div`
  .header {
    height: 56px;
    border-bottom: 1px solid ${colors.grey3};
    padding: 18px 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .location-name {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .building-icon {
      margin-right: 14px;
      visibility: visible;
      opacity: 1;
      color: ${colors.black};
      height: 28px;
      width: 24px;
    }

    svg {
      color: ${colors.grey1};
      margin-left: auto;
      height: 20px;
      width: 24px;
    }
  }

  .time-container {
    background-color: ${colors.grey4};
    height: 50px;
    padding: 18px 16px;
    margin-left: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    svg {
      color: ${colors.grey1};
      height: 20px;
      width: 24px;
    }
  }

  .location {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin: 20px 24px 0px 24px;

    .blue-circle {
      height: 8px;
      width: 8px;
      border-radius: 8px;
      background-color: ${colors.office};
    }
  }

  .users-list {
    margin: 12px 40px 48px 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
  }
`

interface PropsType {
  AMUsers: UserBase[]
  PMUsers: UserBase[]
  date: UTCDate
  location: LocationTree
}

export default function MobileResultsPage({
  AMUsers,
  PMUsers,
  location,
  date,
}: PropsType) {
  const t = useTranslate()
  const pm = new UTCDate(date, "NEXT-HALF-DAY")
  const tomorrow = new UTCDate(date, "NEXT-DAY")

  return (
    <>
      <Header>
        <AdvancedSearchSummary location={location} date={date} />
      </Header>
      <Content>
        <div className="header">
          <P16Bold>{date.format("long", t)}</P16Bold>
          <InteractionsMenu
            users={uniqBy(AMUsers.concat(PMUsers), "id")}
            location={location}
            from={date}
            to={tomorrow}
          />
        </div>

        <div className="right-column">
          <HalfDay
            title={t("long morning").toUpperCase()}
            users={AMUsers}
            date={date}
            location={location}
          />
          <HalfDay
            title={t("long afternoon").toUpperCase()}
            users={PMUsers}
            date={pm}
            location={location}
          />
        </div>
      </Content>
    </>
  )
}
