import { ApolloCache } from "@apollo/client"

import { CompanyFileFragment } from "./fragments/companyFileFragments"

interface PropsType {
  cache: ApolloCache<any>
  data: any
  mutationName: string
  remove?: boolean
}
export default function updateCache({
  cache,
  data,
  mutationName,
  remove,
}: PropsType) {
  if (data && data[mutationName]) {
    const companyFile = data[mutationName]
    const fields = {
      companyFiles: (existing = []) => {
        const s = existing.filter(
          (es: any) =>
            es.__ref !== `${companyFile.__typename}:${companyFile.id}`
        )
        if (remove) {
          return s
        }
        let convertedSavedCompanyFile = {}
        if (companyFile.__typename === "SavedCompanyFile") {
          const { signedUrl, ...rest } = companyFile
          convertedSavedCompanyFile = { ...rest, __typename: "CompanyFile" }
        }
        const newRef = cache.writeFragment({
          data:
            companyFile.__typename === "SavedCompanyFile"
              ? convertedSavedCompanyFile
              : companyFile,
          fragment: CompanyFileFragment,
        })
        return [...s, newRef]
      },
    }
    cache.modify({ fields })
  }
}
