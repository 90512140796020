import { IconButton as MuiIconButton, Slide } from "@mui/material"
import React from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { colors, mediaQueries, P16 } from "ui"
import { Cancel } from "ui/icons"

interface ContainerProps {
  $appearanceColor: string
  displayPointer: string
}

const Container = styled.div<ContainerProps>`
  width: 440px;
  margin-top: 20px;

  @media ${mediaQueries.isMobile} {
    right: 5px;
    left: 5px;
    width: 100%;
  }

  .alert {
    background-color: ${colors.white};
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.24),
      0px 2px 2px rgba(0, 0, 0, 0.12);
    min-height: 40px;
    border-radius: 4px;
    display: flex;
    width: 100%;
    height: 100%;
    ${({ displayPointer }) =>
      `cursor: ${displayPointer !== "" ? "pointer" : "auto"};`}
  }

  .severity-identificator {
    width: 8px;
    min-height: 40px;
    max-height: 60px;
    bottom: 50px;
    margin-right: 15px;
    border-radius: 4px 0 0 4px;
    ${({ $appearanceColor }) => `background-color: ${$appearanceColor};`}
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 9px 8px 0px;
    width: 100%;
  }

  svg {
    height: 20px;
    width: 20px;
    color: ${colors.grey1};
  }
`
const IconButton = styled(MuiIconButton)`
  padding: 5px;
  margin-left: 5px;
`

interface PropsType {
  isOpen: boolean
  text: string | boolean
  appearance: "error" | "info" | "success"
  onClose: () => void
  path?: string
}

export function Toast({ isOpen, text, appearance, onClose, path }: PropsType) {
  const history = useHistory()

  const defineSeverityColor = () => {
    if (appearance === "success") return colors.green
    else if (appearance === "error") return colors.redAlert
    else if (appearance === "info") return colors.purple
    else return colors.purple
  }

  return (
    <Container
      $appearanceColor={defineSeverityColor()}
      displayPointer={path ? path : ""}
    >
      <Slide
        direction={isOpen ? "up" : "left"}
        in={isOpen}
        mountOnEnter
        unmountOnExit
      >
        <div
          className="alert"
          onClick={() => {
            if (path) history.push(path)
          }}
        >
          <div className="severity-identificator" />
          <div className="content">
            <P16>{text}</P16>
            <IconButton
              style={{ height: 20, width: 20 }}
              onClick={(e) => {
                e.stopPropagation()
                onClose()
              }}
            >
              <Cancel />
            </IconButton>
          </div>
        </div>
      </Slide>
    </Container>
  )
}
