import { Modal } from "@mui/material"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { colors, P16 } from "ui"
import capitalizeFirstLetter from "utils/texts/capitalizeFirstLetter"

const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 3000;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Backdrop = styled.div`
  z-index: -1;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
`

const Container = styled.div`
  position: fixed;
  top: 56px;
  right: 15px;
  background: white;
  border-radius: 4px;
  padding: 16px;
  outline: none;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.24), 0px 2px 2px rgba(0, 0, 0, 0.12);
`

const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px;
`

interface CircleColor {
  color: string
  border?: boolean
}

const Circle = styled.div<CircleColor>`
  height: 16px;
  width: 16px;
  left: 0px;
  top: 1px;
  border-radius: 8px;
  background-color: ${({ color }) => color};
  border: ${({ border }) => (border ? `4px solid ${colors.grey3}` : `none`)};
`

interface PropsTypes {
  open: boolean
  onClose: () => void
}

export default function ColorLegendModal({ open, onClose }: PropsTypes) {
  const t = useTranslate()

  return (
    <StyledModal open={open} onClose={onClose} BackdropComponent={Backdrop}>
      <Container>
        <Line>
          <Circle color={colors.office} />
          <P16 style={{ marginLeft: 10 }}>{t("at work")}</P16>
        </Line>
        <Line>
          <Circle color={colors.home} />
          <P16 style={{ marginLeft: 10 }}>{t("Home")}</P16>
        </Line>
        <Line>
          <Circle color={colors.else} />
          <P16 style={{ marginLeft: 10 }}>{t("Other")}</P16>
        </Line>
        <Line>
          <Circle color={colors.off} />
          <P16 style={{ marginLeft: 10 }}>
            {capitalizeFirstLetter(t("Unavailable"))}
          </P16>
        </Line>

        <Line>
          <Circle color={colors.white} border />
          <P16 style={{ marginLeft: 10 }}>{t("Not set")}</P16>
        </Line>
      </Container>
    </StyledModal>
  )
}
