import { ApolloError, gql, useMutation } from "@apollo/client"
import { GET_USER_QUERY } from "graphql/users/useUser"
import { useState } from "react"

const MUTATION = gql`
  mutation setUserLocale($locale: String!) {
    setUserLocale(locale: $locale)
  }
`

export default function useSetUserLocale() {
  const [mutate] = useMutation(MUTATION)
  const [error, setError] = useState<ApolloError | null>(null)
  const [loading, setLoading] = useState(false)
  const setUserLocale = async (locale: string) => {
    setLoading(true)
    try {
      await mutate({
        variables: { locale },
        refetchQueries: [
          {
            query: GET_USER_QUERY,
            variables: { user: null },
          },
        ],
      })
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }
  return {
    loading,
    error,
    setUserLocale,
  }
}
