import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import useUsersAt from "graphql/users/useUsersAt"
import useTranslate from "intl/useTranslate"
import React, { useEffect } from "react"
import UTCDate from "utils/UTCDate"

import { useHomeContext } from "../HomeContextProvider"
import { Bubble } from "../Layout"
import BubbleContent from "./BubbleContent"
import { BubbleType } from "./BubbleType"

interface PropsType {
  from: UTCDate
  to: UTCDate
  size: number
  setBubblesHierarchy: (value: (oldState: BubbleType[]) => BubbleType[]) => void
}

export default function OfficeBubble({
  from,
  to,
  size,
  setBubblesHierarchy,
}: PropsType) {
  const t = useTranslate()
  const { selectedPlanningGroup, onBubbleClick } = useHomeContext()
  const { users, loading, error } = useUsersAt({
    from: from,
    to: to,
    groupIds: ["everyone"],
    locationIds: undefined,
    userIds: undefined,
    filters: ["office"],
  })

  const { users: myUsersTeam, loading: myTeamLoading } = useUsersAt({
    from: from,
    to: to,
    groupIds: [selectedPlanningGroup ?? ""],
    locationIds: undefined,
    userIds: undefined,
    filters: ["office"],
  })

  const emptyArray: BubbleType[] = []

  useEffect(() => {
    if (!loading && !myTeamLoading)
      setBubblesHierarchy((old) => {
        const result = old.reduce((acc, el) => {
          if (el.bubble !== "office") return [...acc, el]
          else {
            return [
              ...acc,
              {
                bubble: "office",
                companyNumber: users.length,
                teamNumber: myUsersTeam.length,
              },
            ]
          }
        }, emptyArray)

        return result
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, myTeamLoading])

  if (loading || error || myTeamLoading)
    return (
      <LoaderOrErrorComponent
        loading={loading || myTeamLoading}
        error={error}
      />
    )
  else
    return (
      <div className="bubble">
        <Bubble
          size={size}
          value="office"
          onClick={() => {
            onBubbleClick("office")
          }}
        >
          <BubbleContent
            title={myUsersTeam.length.toString()}
            companyTitle={users.length.toString()}
            subtitle={t("at work")}
            size={size}
          />
        </Bubble>
      </div>
    )
}
