import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import { Dialog } from "ui"
import { Settings } from "ui/icons"

import UserSubscriptionsList from "./UserSubscriptionsList/UserSubscriptionsList"

const settingsButtonStyle: React.CSSProperties = {
  cursor: "pointer",
}

export default function UserSubscriptionsSettingsButton() {
  const t = useTranslate()
  const [openDialog, setOpenDialog] = useState(false)

  return (
    <>
      <Settings
        style={settingsButtonStyle}
        onClick={() => setOpenDialog(true)}
      />
      <Dialog
        title={t("user-subscription-dialog--title")}
        onClose={() => setOpenDialog(false)}
        open={openDialog}
      >
        <UserSubscriptionsList />
      </Dialog>
    </>
  )
}
