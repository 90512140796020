import useTranslate from "intl/useTranslate"
import React from "react"
import { H4 } from "ui"

import UploadButton from "./UploadButton/UploadButton"
import UploadedFilesList from "./UploadedFilesList/UploadedFilesList"

export default function FileUploader() {
  const t = useTranslate()
  return (
    <>
      <div className="title">
        <H4>{t("corporate documents")}</H4>
        <UploadButton />
      </div>
      <UploadedFilesList />
    </>
  )
}
