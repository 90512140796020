import { gql, useMutation } from "@apollo/client"
import { FavoritePossibleType, UserFavorites } from "types"

import { UserFavoritesFragment } from "./fragments/favoriteFragments"

const TOGGLE_FAVORITE_MUTATION = gql`
  ${UserFavoritesFragment}
  mutation toggleFavorite($id: ID!, $type: String!) {
    toggleFavorite(id: $id, type: $type) {
      ...UserFavoritesFragment
    }
  }
`

export default function useToggleFavorite() {
  const [mutate] = useMutation<{ toggleFavorite: UserFavorites }>(
    TOGGLE_FAVORITE_MUTATION,
    {
      update(cache, { data }) {
        cache.modify({
          fields: {
            userFavorites() {
              if (data?.toggleFavorite) {
                return data.toggleFavorite
              }
            },
          },
        })
      },
    }
  )
  const toggleFavorite = async (id: string, type: FavoritePossibleType) => {
    try {
      await mutate({ variables: { id, type } })
    } catch (e) {
      console.error(e.message)
    } finally {
    }
  }
  return toggleFavorite
}
