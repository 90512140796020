import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components/macro"
import { GroupCompleteStats } from "types"
import { colors, P14 } from "ui"
import getLabelFromStatus from "utils/getLabelFromStatus"

import TableLabelWithSortingButton, {
  TableLabelWithBadgeAndSortingButton,
} from "./TableLabelWithSortingButton"

const Table = styled.div`
  display: grid;
  grid-template-columns:
    minmax(140px, auto)
    minmax(130px, 240px)
    repeat(5, minmax(min-content, 140px));
  align-items: center;
`

const TD = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 4px;
  height: 38px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default;
  font-size: 1rem;

  &.with-background {
    background: ${colors.grey4};
  }

  &.hovered {
    background: #00000009;
  }

  > p {
    padding-top: 0px;
    font-size: 1rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.text-grey {
      color: ${colors.grey1};
    }
    &.centered {
      margin: 0 auto;
    }
  }

  > svg {
    stroke-width: 2px;
    width: 20px;
    height: 20px;
    margin: 0 auto;
  }

  &.align-center {
    text-align: center;
  }

  &.cursor-pointer {
    cursor: pointer;
  }

  &.cursor-disabled {
    cursor: not-allowed;
  }
`

interface PropsType {
  orderBy: string
  setOrderBy: (str: string) => void
  groupsStats: GroupCompleteStats[]
  fetchMore: () => void
  canFetchMore: boolean
  loading: boolean
}

export default function AdminGroupsStatsTable({
  orderBy,
  setOrderBy,
  groupsStats,
}: PropsType) {
  const history = useHistory()
  const onGroupClick = (groupId: string) => {
    history.push(`/group/${groupId}`)
  }
  const t = useTranslate()

  const [hoveredGroupId, setHoveredGroupId] = useState<string | undefined>(
    undefined
  )

  const getMouseInteractions = (groupId: string) => ({
    onMouseEnter: () => {
      setHoveredGroupId(groupId)
    },
    onMouseLeave: () => {
      setHoveredGroupId(undefined)
    },
  })

  return (
    <>
      <Table aria-label="simple table">
        <TD className="with-background">
          <TableLabelWithSortingButton
            field="name"
            orderBy={orderBy}
            setOrderBy={setOrderBy}
          >
            {t("Name")}
          </TableLabelWithSortingButton>
        </TD>
        <TD className="with-background">
          <TableLabelWithSortingButton
            field="total"
            orderBy={orderBy}
            setOrderBy={setOrderBy}
          >
            {t("Completion rate")}
          </TableLabelWithSortingButton>
        </TD>
        <TD className="with-background">
          <TableLabelWithBadgeAndSortingButton
            field="home"
            orderBy={orderBy}
            setOrderBy={setOrderBy}
          >
            {t(getLabelFromStatus("home"))}
          </TableLabelWithBadgeAndSortingButton>
        </TD>
        <TD className="with-background">
          <TableLabelWithBadgeAndSortingButton
            field="office"
            orderBy={orderBy}
            setOrderBy={setOrderBy}
          >
            {t(getLabelFromStatus("office"))}
          </TableLabelWithBadgeAndSortingButton>
        </TD>
        <TD className="with-background">
          <TableLabelWithBadgeAndSortingButton
            field="else"
            orderBy={orderBy}
            setOrderBy={setOrderBy}
          >
            {t(getLabelFromStatus("else"))}
          </TableLabelWithBadgeAndSortingButton>
        </TD>
        <TD className="with-background">
          <TableLabelWithBadgeAndSortingButton
            field="off"
            orderBy={orderBy}
            setOrderBy={setOrderBy}
          >
            {t(getLabelFromStatus("off"))}
          </TableLabelWithBadgeAndSortingButton>
        </TD>
        <TD className="with-background">
          <TableLabelWithBadgeAndSortingButton
            field="ooc"
            orderBy={orderBy}
            setOrderBy={setOrderBy}
          >
            {t(getLabelFromStatus("ooc"))}
          </TableLabelWithBadgeAndSortingButton>
        </TD>

        {groupsStats.map((groupStats, n) => {
          const getClassName = () => {
            const withBackground = (n + 1) % 2 === 0
            const isHovered = hoveredGroupId === groupStats.groupId
            const classList = []
            if (withBackground) classList.push("with-background")
            if (isHovered) classList.push("hovered")
            return classList.join(" ")
          }
          return (
            <React.Fragment key={`stats-user-${n}-${groupStats.groupId}`}>
              <TD
                className={`${getClassName()} cursor-pointer`}
                onClick={() => onGroupClick(groupStats.groupId)}
                {...getMouseInteractions(groupStats.groupId)}
              >
                <P14>{groupStats.groupName}</P14>
              </TD>
              <TD
                className={`${getClassName()}`}
                {...getMouseInteractions(groupStats.groupId)}
              >
                <P14>{(groupStats.completion * 100).toFixed(2)} %</P14>
              </TD>
              <TD
                className={`${getClassName()}`}
                {...getMouseInteractions(groupStats.groupId)}
              >
                <P14>
                  {groupStats.home / 2} (
                  {(
                    (100 * groupStats.home) /
                    (groupStats.groupSize * groupStats.total)
                  ).toFixed(0)}
                  %)
                </P14>
              </TD>
              <TD
                className={`${getClassName()}`}
                {...getMouseInteractions(groupStats.groupId)}
              >
                <P14>
                  {groupStats.office / 2} (
                  {(
                    (100 * groupStats.office) /
                    (groupStats.groupSize * groupStats.total)
                  ).toFixed(0)}
                  %)
                </P14>
              </TD>
              <TD
                className={`${getClassName()}`}
                {...getMouseInteractions(groupStats.groupId)}
              >
                <P14>
                  {groupStats.else / 2} (
                  {(
                    (100 * groupStats.else) /
                    (groupStats.groupSize * groupStats.total)
                  ).toFixed(0)}
                  %)
                </P14>
              </TD>
              <TD
                className={`${getClassName()}`}
                {...getMouseInteractions(groupStats.groupId)}
              >
                <P14>
                  {groupStats.off / 2} (
                  {(
                    (100 * groupStats.off) /
                    (groupStats.groupSize * groupStats.total)
                  ).toFixed(0)}
                  %)
                </P14>
              </TD>
              <TD
                className={`${getClassName()}`}
                {...getMouseInteractions(groupStats.groupId)}
              >
                <P14>
                  {groupStats.ooc / 2} (
                  {(
                    (100 * groupStats.ooc) /
                    (groupStats.groupSize * groupStats.total)
                  ).toFixed(0)}
                  %)
                </P14>
              </TD>
            </React.Fragment>
          )
        })}
      </Table>
    </>
  )
}
