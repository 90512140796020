import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { Button } from "ui"
import { Download } from "ui/icons"
import downloadTextFile from "utils/downloadTextFile"

interface ButtonStyledPropsType {
  className?: string
}

const ButtonStyled = styled(Button)<ButtonStyledPropsType>`
  margin-left: auto;
  min-height: 40px;

  &.btn--margin-top {
    margin-top: -52px;
  }
`

interface PropsType {
  fileName: string
  className?: string
  getCsv: () => Promise<string | undefined>
}

export default function DownloadCsvButton({
  fileName,
  className,
  getCsv,
}: PropsType) {
  const t = useTranslate()
  const [loading, setLoading] = useState(false)

  return (
    <ButtonStyled
      secondary
      disabled={loading}
      className={className}
      icon={
        <Download
          style={{
            strokeWidth: "0.5px",
          }}
        />
      }
      onClick={async () => {
        setLoading(true)
        const csv = await getCsv()
        if (csv === undefined) return
        downloadTextFile(fileName, csv)
        setLoading(false)
      }}
    >
      {t("export to csv")}
    </ButtonStyled>
  )
}
