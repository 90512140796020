import { ProgressBar } from "components/ProgressBar/ProgressBar"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { IntegrationEvent, MeetingRoom } from "types"
import { colors, L12 } from "ui"

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  > div:first-child {
    display: flex;
    justify-content: space-between;
  }
`

const CustomProgressBar = styled(ProgressBar)`
  height: 8px;
  width: 224px;
`

interface PropsType {
  meetingRoomEvent: IntegrationEvent
  meetingRoom: MeetingRoom
}

export default function MeetingProgressBar({
  meetingRoomEvent,
  meetingRoom,
}: PropsType) {
  const t = useTranslate()

  return (
    <Layout>
      <div>
        <L12 color="grey1">
          {t("meeting-room--attendees-attending", {
            nbAttending: `${
              meetingRoomEvent.attendees.filter(
                ({ status }) => status === "attending"
              ).length
            }`,
          })}
        </L12>
        <L12 color="grey1">
          {t("meeting-room--available-seats", {
            nbSeats: `${meetingRoom.capacity}`,
          })}
        </L12>
      </div>
      <CustomProgressBar
        backgroundColor={colors.grey3}
        foreGroudColor={colors.grey1}
        max={meetingRoom.capacity}
        value={
          meetingRoomEvent.attendees.filter(
            ({ status }) => status === "attending"
          ).length
        }
      />
    </Layout>
  )
}
