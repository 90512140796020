import { Capacitor } from "@capacitor/core"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components/macro"
import { Button, colors, Grid, H3, mediaQueries, TextField } from "ui"

import SignInWithDomainButton from "./SignInWithDomainButton"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 480px;

  @media ${mediaQueries.isMobile} {
    width: 90%;
    margin-left: 10px;
    margin-right: 10px;
  }

  h3 {
    color: ${colors.purple};
    margin-bottom: 32px;
  }

  .form {
    width: 370px;

    button {
      margin-top: 16px;
    }

    @media ${mediaQueries.isMobile} {
      width: 280px;
    }
  }
`

interface PropsType {
  domainInUrl?: string
}

export default function SignInWithSSOPage({ domainInUrl = "" }: PropsType) {
  const t = useTranslate()
  const isNative = Capacitor.isNativePlatform()
  const { isMobile } = useMediaQueries()

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const providerIdInParams = urlParams.get("state")

  const { addToast } = useToasts()
  const [domain, setDomain] = useState(domainInUrl)
  const [displayConnectButton, setDisplayConnectButton] = useState(
    Boolean(domainInUrl || providerIdInParams)
  )

  useEffect(() => {
    if (!providerIdInParams && !domainInUrl && !isNative) {
      addToast(t("SSO simple tip"), { appearance: "info" })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainInUrl, isNative, providerIdInParams])

  return (
    <Container>
      <Helmet title={t("Sign in")} />
      <H3>Single sign-on</H3>

      <Grid className="form" container>
        {displayConnectButton ? (
          <Grid item xs={12}>
            <SignInWithDomainButton
              domain={domain}
              providerId={providerIdInParams}
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <TextField
                name="domain"
                variant="outlined"
                placeholder={t("Your Offishall company ID")}
                initialValue={domain}
                handleChange={(e) => setDomain(e)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                width={isMobile ? 280 : 370}
                disabled={!domain}
                onClick={() => {
                  setDisplayConnectButton(true)
                }}
              >
                {t("Continue to sign-in")}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  )
}
