import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import { computePlanningDays } from "components/PlanningContextProvider/utils"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { colors, L12, mediaQueries } from "ui"
import UTCDate from "utils/UTCDate"

const Button = styled.button`
  padding: 2px 4px;
  border: 1px solid ${colors.grey3};
  border-radius: 4px;
  background: ${colors.white};
  p::first-letter {
    text-transform: capitalize;
  }

  p {
    color: ${colors.purple};
  }

  @media ${mediaQueries.isMobile} {
    padding: 4px 7px;
  }
`

export default function TodayButton() {
  const t = useTranslate()
  const { from, to, userShowWeekends, setOnToday } = usePlanningContext()

  const selectedWeekDays = computePlanningDays(
    from,
    to,
    userShowWeekends,
    false
  )

  const selectedWeekIsCurrentWeek = selectedWeekDays.find(
    (d) => d.getTime() === new UTCDate("DAY").getTime()
  )

  if (selectedWeekIsCurrentWeek) return <></>

  return (
    <Button
      onClick={(e) => {
        e.stopPropagation()
        setOnToday()
      }}
    >
      <L12>{t("today")}</L12>
    </Button>
  )
}
