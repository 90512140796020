import ButtonResetSearch from "components/ButtonResetSearch/ButtonResetSearch"
import useMediaQueries from "hooks/useMediaQueries"
import React, { FormHTMLAttributes, useEffect, useRef } from "react"
import styled from "styled-components/macro"
import { colors, mediaQueries } from "ui"
import { Search as SearchIcon } from "ui/icons"

import DesktopAdvancedSearchButton from "./DesktopAdvancedSearchButton/DesktopAdvancedSearchButton"
import ResetSearchButton from "./ResetSearchButton/ResetSearchButton"
import SearchBarButton from "./SearchBarButton/SearchBarButton"
import SearchBarTextInput from "./SearchBarTextInput/SearchBarTextInput"

const SearchForm = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 2px;
  &.search-bar--big-search {
    padding: 2px 2px 2px 8px;
  }
  max-height: 40px; // needed to avoid conditional padding as form is shared a lot
  min-height: 40px; // needed to avoid conditional padding as form is shared a lot
  gap: 4px;
  background: ${colors.grey4};
  border: 1px solid ${colors.grey2};
  border-radius: 4px;

  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.grey3};

  .search-icon-text {
    display: flex;
    flex-direction: row;
    width: 95%;
    padding-left: 2px;

    > input {
      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${colors.grey2};
        opacity: 1; /* Firefox */
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${colors.grey2};
      }
      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${colors.grey2};
      }
    }
  }

  &.highlight-placeholder .search-icon-text > input {
    ::placeholder {
      color: ${colors.black};
    }
  }

  &.search-bar {
    &.search-bar--textfield {
      &:hover {
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.04),
            rgba(0, 0, 0, 0.04)
          ),
          ${colors.grey4};
        border: 1px solid ${colors.grey2};

        color: ${colors.grey3};
      }

      &:focus-within {
        background: linear-gradient(0deg, ${colors.white}, ${colors.white}),
          ${colors.grey4};
        border: 2px solid ${colors.green};

        color: ${colors.black};
      }
    }
  }

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)),
      ${colors.grey4};
  }

  @media ${mediaQueries.isTouchable} {
    height: 32px;
    padding: 0 0 0 4px;

    &:focus-within {
      padding: 0 8px;

      button[type="submit"] {
        display: none;
      }
    }
  }
`

interface PropsType extends FormHTMLAttributes<HTMLFormElement> {
  icon?: JSX.Element
  searchString: string
  variant?: "textfield" | "big-search"
  hasResult?: boolean
  searchInputRef?: React.Ref<HTMLInputElement>
  autoFocus?: boolean
  withAdvancedSearch?: boolean
  handleChange: (str: string) => void
  handleClearForm?: () => void
  resetForm: () => void
  onClick?: () => void
}

function SearchBarForm(
  {
    icon,
    id,
    placeholder,
    searchString,
    variant,
    hasResult,
    searchInputRef,
    autoFocus,
    withAdvancedSearch = false,
    handleChange,
    handleClearForm,
    resetForm,
    onClick,
    ...rest
  }: PropsType,
  ref: React.Ref<HTMLFormElement>
) {
  const { isMobile } = useMediaQueries()
  const className = `search-bar ${variant ? `search-bar--${variant}` : ""}`
  const showResetSearchButton = searchString !== "" || hasResult

  return (
    <SearchForm
      ref={ref}
      className={
        hasResult ? `${className} highlight-placeholder` : `${className}`
      }
      onSubmit={(e) => {
        e.preventDefault()
      }}
      {...rest}
    >
      <div className="search-icon-text">
        {icon && <SearchBarButton icon={icon} />}
        <SearchBarTextInput
          ref={searchInputRef}
          id={id}
          placeholder={placeholder}
          searchString={searchString}
          onChange={handleChange}
          resetForm={resetForm}
          autoFocus={autoFocus}
          onClick={onClick}
        />
      </div>
      {showResetSearchButton && (
        <ButtonResetSearch
          resetForm={resetForm}
          handleClearForm={handleClearForm}
        />
      )}
      {!isMobile && withAdvancedSearch && <DesktopAdvancedSearchButton />}
    </SearchForm>
  )
}

interface ControlledFromParentPropsType {
  dynamicPlaceholder?: string
  value: string
  searchInputRef?: React.Ref<HTMLInputElement>
  autoFocus?: boolean
  variant?: "textfield" | "big-search"
  withAdvancedSearch?: boolean
  setValue: (str: string) => void
  onReset: () => void
}

function SearchBarFormControlledFromParent(
  {
    dynamicPlaceholder,
    value,
    searchInputRef,
    autoFocus,
    variant,
    withAdvancedSearch = false,
    setValue,
    onReset,
  }: ControlledFromParentPropsType,
  ref: React.Ref<HTMLFormElement>
) {
  const showResetSearchButton = value !== ""
  const className = `search-bar${variant ? ` search-bar--${variant}` : ""}`

  const placeholderRef = useRef<string | undefined>(dynamicPlaceholder)

  const handleChange = (str: string) => {
    setValue(str)
    setValue(str)
  }

  const handleReset = () => {
    onReset()
    setValue("")
  }

  useEffect(() => {
    const placeholderHasChanged = dynamicPlaceholder !== placeholderRef.current
    if (placeholderHasChanged) {
      placeholderRef.current = dynamicPlaceholder
      setValue("")
    }
  }, [dynamicPlaceholder, setValue])

  return (
    <SearchForm
      ref={ref}
      className={className}
      onSubmit={(e) => {
        e.preventDefault()
      }}
    >
      <div className="search-icon-text">
        <SearchIcon />
        <SearchBarTextInput
          ref={searchInputRef}
          searchString={value}
          placeholder={dynamicPlaceholder}
          autoFocus={autoFocus}
          onChange={handleChange}
          resetForm={onReset}
        />
      </div>
      {showResetSearchButton && <ResetSearchButton onClick={handleReset} />}
      {withAdvancedSearch && <DesktopAdvancedSearchButton />}
    </SearchForm>
  )
}

export const SearchBarFormWithRef = React.forwardRef(SearchBarForm)
export const SearchBarFormControlledFromParentWithRef = React.forwardRef(
  SearchBarFormControlledFromParent
)
export default SearchBarFormWithRef
