import useTranslate from "intl/useTranslate"
import React from "react"
import {
  DefaultHybridRule,
  GroupHybridRule,
  ManagerHybridRule,
  UserHybridRule,
} from "types"
import { L12, P16Bold } from "ui"
import { months } from "utils/LocalDate"

interface PropsType {
  rule: (
    | DefaultHybridRule
    | UserHybridRule
    | GroupHybridRule
    | ManagerHybridRule
  ) & { monthIndex?: number; weekIndex?: number }
}

export default function Period({ rule }: PropsType) {
  const t = useTranslate()

  const getPeriodAsText = () => {
    if (rule.weekIndex !== undefined) return `${t("week")} ${rule.weekIndex}`
    if (rule.monthIndex !== undefined) return `${t(months[rule.monthIndex])}`
    return "n/a"
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <L12 color="grey1">{t("hybrid-rule-label--period")}</L12>
      <P16Bold color="purple">{getPeriodAsText()}</P16Bold>
    </div>
  )
}
