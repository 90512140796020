import useSetUserIntegrationFlag from "graphql/integrations/useSetUserIntegrationFlag"
import useTranslate from "intl/useTranslate"
import React from "react"
import { UserIntegration } from "types"
import { Toggle } from "ui"

interface PropsType {
  integration: UserIntegration
}

export default function WriteSlotsEventsToggle({ integration }: PropsType) {
  const t = useTranslate()
  const setFlag = useSetUserIntegrationFlag()

  const writeSlotEvents =
    integration.flags["writeSlotsEvents"] !== undefined
      ? Boolean(integration.flags["writeSlotsEvents"])
      : true

  return (
    <Toggle
      label={t("writeSlotsEvents-toggle")}
      checked={writeSlotEvents}
      onChange={() => {
        setFlag({
          name: integration.name,
          key: "writeSlotsEvents",
          value: !writeSlotEvents,
        })
      }}
    />
  )
}
