import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { User } from "types"
import { Button, colors } from "ui"

import DeleteUserConfirmation from "./components/DeleteUserConfirmation"

const ButtonStyled = styled(Button)`
  border-color: ${colors.grey3};
  color: ${colors.redAlert};
  margin-right: auto;
`

interface DeletePropsType {
  canBeEdited: boolean
  user: User
  dialogOpen: boolean
  setDialogOpen: (arg: boolean) => void
  onUserHasBeenDeleted: () => void
}
export default function DeleteButton({
  canBeEdited,
  user,
  dialogOpen,
  setDialogOpen,
  onUserHasBeenDeleted,
}: DeletePropsType) {
  const t = useTranslate()
  return (
    <>
      <ButtonStyled
        secondary
        onClick={() => {
          if (canBeEdited) setDialogOpen(true)
        }}
        disabled={!canBeEdited}
      >
        {t("Delete User")}
      </ButtonStyled>
      <DeleteUserConfirmation
        user={user}
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false)
        }}
        onUserHasBeenDeleted={onUserHasBeenDeleted}
      />
    </>
  )
}
