import React from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { colors } from "ui"
import { ArrowLeft } from "ui/icons"

const NavBackButton = styled.button`
  padding: 0;
  border: none;
  background: none;
  width: fit-content;

  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    stroke: ${colors.grey1};
    width: 24px;
    height: 24px;
  }
`

interface PropsType {
  children?: React.ReactNode
}

export default function DesktopNavBack({ children }: PropsType) {
  const history = useHistory()
  return (
    <NavBackButton onClick={() => history.goBack()}>
      <ArrowLeft />
      {children}
    </NavBackButton>
  )
}
