import PageHeader from "components/Layout/PageLayout/PageHeader/PageHeader"
import MobileNavBack from "components/Layout/PageLayout/PageHeader/PageTitle/MobileNavBack/MobileNavBack"
import PageTitle from "components/Layout/PageLayout/PageHeader/PageTitle/PageTitle"
import LocationsSelection from "components/LocationsSelection/LocationsSelection"
import useLocationsTree from "graphql/locations/useLocationsTree"
import useAddUserHistory from "graphql/userHistory/useAddUserHistory"
import useUserHistory from "graphql/userHistory/useUserHistory"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import { useHistory } from "react-router"
import styled from "styled-components"
import { Button, colors, Dialog, mediaQueries, P16 } from "ui"
import { Building, Calendar } from "ui/icons"
import TextfieldDatePicker from "ui/TextfieldDatePicker/TextfieldDatePicker"
import LocationTree from "utils/LocationTree"
import capitalizeFirstLetter from "utils/texts/capitalizeFirstLetter"
import UTCDate, { months } from "utils/UTCDate"

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  overflow: hidden;
`

const LatestResearch = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 8px;
`

const StyledLocationChipButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${colors.grey4};
  border: 1px solid ${colors.grey3};
  box-sizing: border-box;
  border-radius: 24px;
  padding: 6px 16px;
  max-height: 32px;
  cursor: pointer;
  max-width: 100%;

  @media ${mediaQueries.isDesktop} {
    width: fit-content;
  }

  @media ${mediaQueries.isMobile} {
    display: flex;
    flex-direction: column;
    max-height: 84px;
    align-items: flex-start;
  }

  &:hover,
  &:focus {
    background: ${colors.greyGradient};
    border-color: ${colors.grey3};
  }

  .building-icon {
    @media ${mediaQueries.isDesktop} {
      margin-left: 20px;
    }
  }

  .date-container {
    display: flex;
    flex-direction: row;
  }

  .location-container {
    display: flex;
    flex-direction: row;

    @media ${mediaQueries.isMobile} {
      margin-top: 15px;
    }
  }

  p  {
    overflow: hidden;
    white-space: nowrap;
  }

  svg {
    margin-right: 10px;
    max-height: 20px;
  }
`

const LocationText = styled(P16)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 75%;

  @media ${mediaQueries.isMobile} {
    max-width: calc(100vw - 200px);
  }
`

interface PropsType {
  open: boolean
  onClose: () => void
}

export default function AdvancedSearchPopUp({ open, onClose }: PropsType) {
  const { isMobile } = useMediaQueries()
  const { userHistory } = useUserHistory({ type: "advanced-search", limit: 2 })
  const t = useTranslate()
  const history = useHistory()
  const addUserHistory = useAddUserHistory()
  const [date, setDate] = useState(new UTCDate("DAY"))
  const [selectedLocation, setSelectedLocation] = useState<
    LocationTree | undefined
  >(undefined)

  const goToAdvancedSearch = (locationId: string, date: number) => {
    const replaceUrl = location.pathname.startsWith("/advanced-search/results/")
    if (replaceUrl)
      history.replace(`/advanced-search/results/${locationId}/${date}`)
    else history.push(`/advanced-search/results/${locationId}/${date}`)
  }

  const { locations } = useLocationsTree()

  const defineDate = (date: Date) => {
    const formatedDate = new UTCDate(Number(date))
    return `${formatedDate.getUTCDate()} ${t(
      months[formatedDate.getUTCMonth()]
    )} ${formatedDate.getUTCFullYear()}`
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={!isMobile ? t("Advanced Search") : undefined}
      leftButton={
        !isMobile ? (
          <Button secondary onClick={onClose}>
            {t("Cancel")}
          </Button>
        ) : undefined
      }
      rightButton={
        <Button
          disabled={selectedLocation === undefined}
          fullWidth={isMobile}
          onClick={() => {
            onClose()
            if (selectedLocation) {
              goToAdvancedSearch(selectedLocation.id, date.getTime())

              addUserHistory({
                type: "advanced-search",
                value: {
                  date: `${date.getTime()}`,
                  today: date.isToday(),
                  locationId: selectedLocation.id,
                },
              })
            }
          }}
        >
          {t("Confirm")}
        </Button>
      }
      fullscreen={isMobile}
    >
      {isMobile && (
        <PageHeader>
          <MobileNavBack onClose={onClose} />
          <PageTitle title={t("Advanced Search")} />
        </PageHeader>
      )}
      <Content>
        <TextfieldDatePicker
          label={t("Date")}
          defaultDate={date}
          onSelect={(utcDate) => utcDate && setDate(utcDate)}
        />
        <div style={{ marginTop: 24 }} />
        <LocationsSelection
          onChange={(locations) => {
            if (locations.length > 0) setSelectedLocation(locations[0])
          }}
          title={t("Places")}
          placeholder={t("Places")}
          singleSelection
        />
        <LatestResearch>
          {userHistory.map((item) => (
            <StyledLocationChipButton
              key={item.id}
              onClick={() => {
                onClose()
                const isToday = Boolean(item.value.today)
                const date = isToday
                  ? new UTCDate("DAY").getTime()
                  : Number(item.value.date)
                goToAdvancedSearch(item.value.locationId, date)

                addUserHistory({
                  type: "advanced-search",
                  value: {
                    date,
                    today: isToday,
                    locationId: item.value.locationId,
                  },
                })
              }}
            >
              <div className="date-container">
                <Calendar />
                <P16>
                  {item.value.today
                    ? capitalizeFirstLetter(t("today"))
                    : defineDate(item.value.date)}
                </P16>
              </div>
              <div className="location-container">
                <Building className="building-icon" />
                <LocationText>
                  {
                    LocationTree.getLocationNode(
                      locations,
                      item.value.locationId
                    )?.name
                  }
                </LocationText>
              </div>
            </StyledLocationChipButton>
          ))}
        </LatestResearch>
      </Content>
    </Dialog>
  )
}
