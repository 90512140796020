import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import useAdminSearchUsers from "graphql/users/useAdminSearchUsers"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { AdminUsersFilter, UserAdminBase } from "types"
import { Button } from "ui"
import sortAlphabeticallyByNameThenEmail from "utils/sortAlphabeticallyByNameThenEmail"

import AdminCompanyUserStats from "./AdminCompanyUserStats"
import AdminUserFilters from "./AdminUserFilters"
import AdminUsersTable from "./AdminUsersTable"

const Layout = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: calc(100vh - 144px);
  max-height: calc(100vh - 144px); // 100vh - parent "fixed" header
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: scroll;

  button:first-child {
    margin-left: auto;
  }
`
const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 0;
`
const RESULTS_PER_PAGE = 6

interface FilterInputType {
  key: "active" | "registration" | "status"
  value: string | undefined
}

const initialFilters = {
  active: undefined,
  status: undefined,
  registration: undefined,
}

export default function AdminUsersPageContent() {
  const { loading, error, users: allUsers } = useAdminSearchUsers()

  const t = useTranslate()

  const [sampleSize, setSampleSize] = useState(RESULTS_PER_PAGE)
  const [filteredUsers, setFilteredUsers] = useState<UserAdminBase[]>([])

  const [filters, setFilters] = useState<AdminUsersFilter>(initialFilters)
  const [registered, setRegistered] = useState<boolean | undefined>(undefined)
  const [enabled, setEnabled] = useState<boolean | undefined>(undefined)
  const [status, setStatus] = useState<string | undefined>(undefined)

  // Heights of the three previous components when the page is loaded
  const [heightStats, setHeightStats] = useState(70 + 35 + 80)
  const [heightFilters, setHeightFilters] = useState(0)

  // Function to used to edit filters
  const updateFilters = (newFilter: FilterInputType) => {
    const filterToUpdate = filters[newFilter.key]
    const resetFilterToUpdate =
      filterToUpdate === newFilter.value || newFilter.value === undefined

    switch (newFilter.key) {
      case "active":
        if (resetFilterToUpdate) {
          setEnabled(undefined)
          return setFilters((prev) => ({ ...prev, active: undefined }))
        }
        setEnabled(newFilter.value === "active")
        return setFilters((prev) => ({
          ...prev,
          active: newFilter.value,
        }))
      case "registration":
        if (resetFilterToUpdate) {
          setRegistered(undefined)
          return setFilters((prev) => ({ ...prev, registration: undefined }))
        }
        setRegistered(newFilter.value === "registered")
        return setFilters((prev) => ({
          ...prev,
          registration: newFilter.value,
        }))
      case "status":
        if (resetFilterToUpdate) {
          setStatus(undefined)
          return setFilters((prev) => ({ ...prev, status: undefined }))
        }
        setStatus(newFilter.value)
        return setFilters((prev) => ({
          ...prev,
          status: newFilter.value,
        }))
    }
  }

  // Reset all filters values
  const clearFilters = () => {
    setFilteredUsers([])
    setFilters(initialFilters)
    setRegistered(undefined)
    setStatus(undefined)
    setEnabled(undefined)
  }

  const sampledUsers =
    filteredUsers.length > 0
      ? allUsers
          .filter((u) => filteredUsers.find((f) => f.id === u.id))
          .sort(sortAlphabeticallyByNameThenEmail)
      : [...allUsers].sort(sortAlphabeticallyByNameThenEmail)

  const usersToDisplay = sampledUsers
    .filter((u) => registered === undefined || u.registered === registered)
    .filter((u) => status === undefined || u.role === status)
    .filter((u) => enabled === undefined || u.disabled === !enabled)

  const usersToDisplaySampled = usersToDisplay.slice(0, sampleSize)

  if (loading || error)
    return <LoaderOrErrorComponent loading={loading} error={error} />

  return (
    <Layout>
      <div>
        <AdminCompanyUserStats setHeightStats={setHeightStats} />

        <AdminUserFilters
          loading={loading}
          filteredUsers={filteredUsers}
          filters={filters}
          allUsers={allUsers}
          usersToDisplay={usersToDisplay}
          setFilteredUsers={setFilteredUsers}
          updateFilters={updateFilters}
          clearFilters={clearFilters}
          setHeightFilters={setHeightFilters}
        />
      </div>

      <Body>
        <AdminUsersTable
          users={usersToDisplaySampled}
          heightStats={heightStats}
          heightFilters={heightFilters}
        />
      </Body>
      <Footer>
        {usersToDisplaySampled.length < usersToDisplay.length && (
          <Button
            secondary
            onClick={() => {
              setSampleSize(sampleSize + RESULTS_PER_PAGE)
            }}
          >
            {t("View more results")}
          </Button>
        )}
      </Footer>
    </Layout>
  )
}
