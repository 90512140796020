import Divider from "components/Divider"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { Button, colors, Grid, P14 } from "ui"

import CSVDisplayer from "./CSVDisplayer"

const Container = styled.div`
  gap: 24px;
  max-width: 100%;
`

const Text = styled(P14)`
  color: ${colors.grey1};
  margin-bottom: 26px;
`

interface PropsType {
  csv: string[][]
  hasColumnNames: boolean
  currentStep: number
  emailColumn: number | null
  onPrevious: () => void
  onNext: () => void
  setEmailColumn: (nb: number) => void
}

export function CSVSelectEmailColumn({
  csv,
  hasColumnNames,
  currentStep,
  emailColumn,
  setEmailColumn,
  onPrevious,
  onNext,
}: PropsType) {
  const t = useTranslate()

  return (
    <Container>
      <Text>{t("select email column info")}</Text>

      <CSVDisplayer
        csv={csv}
        hasColumnNames={hasColumnNames}
        columnIdx={emailColumn}
        setColumnIdx={setEmailColumn}
        currentStep={currentStep}
      />

      <Divider />
      <Grid container spacing={6} justifyContent="center">
        <Grid item>
          <Button secondary onClick={onPrevious}>
            {t("Previous")}
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled={emailColumn === null ? true : false}
            onClick={() => {
              onNext()
            }}
          >
            {t("Next")}
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}
