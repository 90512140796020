import React from "react"
import { MeetingRoom } from "types"
import LocalDate from "utils/LocalDate"

import MeetingRoomItem from "./MeetingRoomItem/MeetingRoomItem"

interface PropsType {
  meetingRooms: MeetingRoom[]
  from: LocalDate
  to: LocalDate
  minHour: LocalDate
  maxHour: LocalDate
  createLoadedEvent: (id: string) => CustomEvent<{
    loaded: string
  }>
}

export default function MeetingRoomsList({
  meetingRooms,
  from,
  to,
  minHour,
  maxHour,
  createLoadedEvent,
}: PropsType) {
  return (
    <>
      {meetingRooms.map((m) => (
        <MeetingRoomItem
          key={m.id}
          meetingRoom={m}
          meetingRooms={meetingRooms}
          from={from}
          to={to}
          minHour={minHour}
          maxHour={maxHour}
          createLoadedEvent={createLoadedEvent}
        />
      ))}
    </>
  )
}
