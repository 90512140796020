import { TextField as MuiTextField, TextFieldProps } from "@mui/material"
import React, { ChangeEvent, useState } from "react"
import styled from "styled-components"
import { colors, Label } from "ui"

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const PrimaryTextfield = styled(MuiTextField)`
  & .MuiOutlinedInput-root {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: ${colors.black};
    height: 80px;
    border: 1px solid ${colors.grey2};
    background: ${colors.grey4};

    &:hover {
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.04),
          rgba(0, 0, 0, 0.04)
        ),
        ${colors.grey4};
    }
  }

  & .MuiOutlinedInput-root.Mui-disabled {
    background: ${colors.grey4};
    cursor: not-allowed;
    color: ${colors.grey1};
  }
  & .MuiOutlinedInput-root.Mui-focused {
    background: linear-gradient(0deg, ${colors.white}, ${colors.white}),
      ${colors.grey4};
    border: 2px solid ${colors.green};
    color: ${colors.black};
  }

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  p {
    color: ${colors.grey1};
  }
`

type TextFieldPropsType = {
  initialValue?: string
  icon?: boolean
  onEnterKey?: () => void
  handleChange: (str: string) => void
} & TextFieldProps

export function Textarea({
  label,
  initialValue,
  handleChange,
  className,
  ...rest
}: TextFieldPropsType) {
  const [value, setValue] = useState<string>(initialValue ? initialValue : "")

  return (
    <Container className={className}>
      {label && <Label>{rest.required ? `${label}*` : label}</Label>}
      <PrimaryTextfield
        fullWidth
        size="small"
        margin="none"
        minRows={3}
        maxRows={3}
        multiline
        value={value}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          handleChange(event.target.value)
          setValue(event.target.value)
        }}
        {...rest}
      />
    </Container>
  )
}
