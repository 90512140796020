import React from "react"
import styled from "styled-components"
import { Equipment } from "types"
import LocationTree, { getChildrenLocations } from "utils/LocationTree"

import NoServiceAvailableMessage from "../NoServiceAvailableMessage/NoServiceAvailableMessage"
import EquipmentItem from "./EquipmentItem/EquipmentItem"
import { LocationNameWithTooltip } from "./LocationName/LocationName"

const GlobalList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 32px;

  margin-top: 24px;
`

const LocationEquipments = styled.li`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const LocationEquipmentsList = styled.ul`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`

interface LocationEquipments {
  location: LocationTree
  equipments: Equipment[]
}

interface PropsType {
  equipments: Equipment[]
  locations: LocationTree[]
  onSelectEquipment: (service: Equipment) => void
}

export default function EquipmentsList({
  equipments,
  locations,
  onSelectEquipment,
}: PropsType) {
  const allLocations = getChildrenLocations(locations, false)

  const reduceEquipmentsToLocationEquipments = (
    prev: LocationEquipments[],
    curr: Equipment
  ) => {
    const currentEquipmentLocation = allLocations.find(
      (l) => l.id === curr.locationId
    )
    if (currentEquipmentLocation === undefined) return prev

    const locationAlreadyIncluded = prev.find(
      (p) => p.location.id === currentEquipmentLocation.id
    )
    if (locationAlreadyIncluded) {
      locationAlreadyIncluded.equipments.push(curr)
      return [...prev]
    }
    return [...prev, { location: currentEquipmentLocation, equipments: [curr] }]
  }

  const sortLocationsByBreadcrumbedName = (
    a: LocationEquipments,
    b: LocationEquipments
  ) => {
    if (
      a.location.breadCrumbs.length === 0 &&
      b.location.breadCrumbs.length > 0
    ) {
      const locationBWithBreadCrumbsName = [
        b.location.breadCrumbs,
        b.location.name,
      ].join("")
      if (a.location.name > locationBWithBreadCrumbsName) return 1
      if (a.location.name < locationBWithBreadCrumbsName) return -1
      return 0
    }

    if (
      a.location.breadCrumbs.length > 0 &&
      b.location.breadCrumbs.length === 0
    ) {
      const locationAWithBreadCrumbsName = [
        a.location.breadCrumbs,
        a.location.name,
      ].join("")
      if (locationAWithBreadCrumbsName > b.location.name) return 1
      if (locationAWithBreadCrumbsName < b.location.name) return -1
      return 0
    }

    const locationAWithBreadCrumbsName = [
      a.location.breadCrumbs,
      a.location.name,
    ].join("")

    const locationBWithBreadCrumbsName = [
      b.location.breadCrumbs,
      b.location.name,
    ].join("")

    if (locationAWithBreadCrumbsName > locationBWithBreadCrumbsName) return 1
    if (locationAWithBreadCrumbsName < locationBWithBreadCrumbsName) return -1
    return 0
  }

  const sortedByLocationEquipments = equipments
    .reduce(reduceEquipmentsToLocationEquipments, [])
    .sort(sortLocationsByBreadcrumbedName)

  if (equipments.length === 0) return <NoServiceAvailableMessage />

  return (
    <GlobalList>
      {sortedByLocationEquipments.map(
        (locationEquipment: LocationEquipments, m) => (
          <LocationEquipments key={`located-services-${m}`}>
            <LocationNameWithTooltip
              location={locationEquipment.location}
              equipments={locationEquipment.equipments}
            />
            <LocationEquipmentsList>
              {React.Children.map(
                locationEquipment.equipments.map((equipment, n) => (
                  <EquipmentItem
                    key={`equipment-item-${n}`}
                    equipment={equipment}
                    onClick={() => onSelectEquipment(equipment)}
                  />
                )),
                (child) => (
                  <>{child}</>
                )
              )}
            </LocationEquipmentsList>
          </LocationEquipments>
        )
      )}
    </GlobalList>
  )
}
