import UserAvatar from "components/UserAvatar/UserAvatar"
import useUser from "graphql/users/useUser"
import useUserTree from "hooks/useUserTree"
import React from "react"
import styled from "styled-components"
import { Flags } from "types"
import { colors, P16 } from "ui"
import getUserDisplayName from "utils/getUserDisplayName"
import UserTree from "utils/UserTree"

import { CustomCheckbox, Item, List } from "./SharedLayouts"

const UserHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 7px 14px;
  border-bottom: solid 1px ${colors.grey3};

  .extra-action {
  }
`

interface UserItemPropsType {
  user: UserTree
  selectedUsers: UserTree[]
  companyFlags: Flags
  disabled: boolean
  extraAction?: React.ReactNode
  extraActionFilteredTargetIds?: string[]
  onSelectUser: (user: UserTree) => void
}

function UserItem({
  user,
  selectedUsers,
  companyFlags,
  disabled,
  extraAction: ExtraAction,
  extraActionFilteredTargetIds,
  onSelectUser,
}: UserItemPropsType) {
  const checked = selectedUsers.find(({ id }) => id === user.id) !== undefined

  return (
    <Item
      className={disabled ? "disabled" : undefined}
      onClick={() => {
        if (disabled) return
        onSelectUser(user)
      }}
    >
      <CustomCheckbox
        checked={checked}
        disabled={disabled}
        onChange={() => {
          //
        }}
      />
      <UserHeader>
        <UserAvatar user={user} />
        <P16 color={disabled ? "grey2" : undefined}>
          {getUserDisplayName(user, companyFlags)}
        </P16>
        {extraActionFilteredTargetIds?.find((id) => id === user.id) && (
          <div className="extra-action">{ExtraAction}</div>
        )}
      </UserHeader>
    </Item>
  )
}

interface PropsType {
  externalUserTree?: UserTree[]
  selectedUsers: UserTree[]
  companyFlags: Flags
  disableMySelf?: boolean
  disableUserTreeRecursion?: boolean
  extraAction?: React.ReactNode
  extraActionFilteredTargetIds?: string[]
  onSelectUser: (user: UserTree) => void
}

export default function UsersTab({
  externalUserTree,
  selectedUsers,
  companyFlags,
  disableMySelf = false,
  disableUserTreeRecursion = false,
  extraAction,
  extraActionFilteredTargetIds,
  onSelectUser,
}: PropsType) {
  const { userTree } = useUserTree()
  const { user: me } = useUser()

  return (
    <List>
      {(externalUserTree ? externalUserTree : userTree).map((u, n) => {
        if (u.isManager && !disableUserTreeRecursion) {
          return (
            <UsersTab
              key={`manager-team-${u.id}-${n}`}
              selectedUsers={selectedUsers}
              externalUserTree={u.team}
              companyFlags={companyFlags}
              extraAction={extraAction}
              extraActionFilteredTargetIds={extraActionFilteredTargetIds}
              onSelectUser={onSelectUser}
              disableMySelf={disableMySelf}
            />
          )
        }

        return (
          <UserItem
            key={`user-${u.id}-${n}`}
            user={u}
            selectedUsers={selectedUsers}
            companyFlags={companyFlags}
            extraAction={extraAction}
            extraActionFilteredTargetIds={extraActionFilteredTargetIds}
            onSelectUser={onSelectUser}
            disabled={disableMySelf && me?.id === u.id}
          />
        )
      })}
    </List>
  )
}
