import {
  GroupHybridRule,
  HybridRule,
  ManagerHybridRule,
  UserHybridRule,
} from "types"

export const LOADED_SCORE_EVENT_KEY = "loadedscore"

export interface LoadedScoreEventDetail {
  id: string
  concernedUserIds: string[]
  compliantUserIds: string[]
}

export interface LoadingScore<T> {
  rule: T
  loading: boolean
}

export function isGroupHybridRule(rule: HybridRule): rule is GroupHybridRule {
  return (rule as GroupHybridRule)?.groupId !== undefined
}

export function isUserHybridRule(rule: HybridRule): rule is UserHybridRule {
  return (rule as UserHybridRule)?.userId !== undefined
}

export function isManagerHybridRule(
  rule: HybridRule
): rule is ManagerHybridRule {
  return (rule as ManagerHybridRule)?.managerId !== undefined
}
