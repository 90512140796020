import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function ArrowLeft({
  stroke,
  strokeWidth,
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      strokeWidth={strokeWidth ?? 1.5}
      stroke={stroke ? stroke : "currentColor"}
      {...rest}
    >
      <path
        d="M6 12L12 18M18.5 12H6H18.5ZM6 12L12 6L6 12Z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}
