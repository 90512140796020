import useBookingMinDate from "hooks/useBookingMinDate"
import React, { useState } from "react"
import { EditionTimeFrame } from "types"
import { ArrowRight } from "ui/icons"
import UTCDate from "utils/UTCDate"

import DateButton from "./DateButton/DateButton"

interface PropsType {
  from: UTCDate
  to: UTCDate
  setEditionTimeFrame: React.Dispatch<React.SetStateAction<EditionTimeFrame>>
}

export default function FromToPicker({
  from,
  to,
  setEditionTimeFrame,
}: PropsType) {
  const bookingMaxDate = new UTCDate("DAY")
  bookingMaxDate.increment("YEAR")
  const [maxDate, setMaxDate] = useState(bookingMaxDate)
  const minDate = useBookingMinDate()

  const updateToDate = (to: UTCDate) => {
    setEditionTimeFrame((prev) => ({
      ...prev,
      to,
    }))
  }

  const updateFromDate = (from: UTCDate) => {
    setMaxDate(bookingMaxDate)
    setEditionTimeFrame((prev) => ({
      ...prev,
      from,
    }))
  }

  const handleFromDatePicking = (date?: UTCDate) => {
    if (date === undefined) return

    if (date <= to) {
      // date is a new from candidate
      const maxDate = bookingMaxDate

      if (to >= maxDate) updateToDate(maxDate)

      updateFromDate(date)
      setMaxDate(maxDate)
      return
    }

    // date is a new to candidate
    const maxDate = bookingMaxDate
    if (date >= maxDate) updateToDate(maxDate)
    updateFromDate(to)
    updateToDate(date)
    setMaxDate(maxDate)
  }

  const handleToDatePicking = (date?: UTCDate) => {
    if (date === undefined) return

    if (date >= from) {
      // date is a new to candidate
      const maxDate = bookingMaxDate
      if (date >= maxDate) return
      updateToDate(date)
      setMaxDate(maxDate)
      return
    }

    // date is a new from candidate
    const maxDate = bookingMaxDate
    if (to >= maxDate) updateToDate(maxDate)
    updateFromDate(date)
    updateToDate(from)
    setMaxDate(maxDate)
  }
  return (
    <>
      <DateButton
        initialDate={from}
        minDate={minDate}
        maxDate={maxDate}
        setDate={handleFromDatePicking}
      />
      <ArrowRight />
      <DateButton
        initialDate={to}
        minDate={minDate}
        maxDate={maxDate}
        setDate={handleToDatePicking}
      />
    </>
  )
}
