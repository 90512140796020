import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { UsersAtFilter } from "types"
import { colors, mediaQueries, P14, P16 } from "ui"
import { Plus } from "ui/icons"

import { ButtonBase } from "../components/components"
import FiltersDialog from "./FiltersDialog"
import UsersAtActive from "./UsersAtActive"

const Button = styled(ButtonBase)`
  border-style: dashed;
  &:hover,
  &.btn-users-at-filters--focus {
    background: ${colors.black}14;
    p {
      color: ${colors.black};
    }
    svg {
      stroke: ${colors.black};
    }
  }

  p {
    color: ${colors.grey1};
    transition: color 250ms ease-in-out;
  }
  @media ${mediaQueries.isMobile} {
    padding: 3px 8px;

    p {
      color: ${colors.black};
    }
    svg {
      stroke: ${colors.black};
      height: 16px;
      width: 16px;
      stroke-width: 2px;
    }
  }

  transition: background-color 250ms ease-in-out;
`

interface PropsType {
  usersAtFilters: UsersAtFilter[]
  updateFilter: (filter: UsersAtFilter | undefined) => void
}

export default function UsersAtFiltersContent({
  usersAtFilters,
  updateFilter,
}: PropsType) {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [open, setOpen] = useState(false)

  const handleFilterUpdate = (filter: UsersAtFilter | undefined) => {
    updateFilter(filter)
    setOpen(false)
  }

  return (
    <>
      {usersAtFilters.length > 0 && (
        <UsersAtActive
          usersAtFilters={usersAtFilters}
          updateFilter={updateFilter}
          setOpen={setOpen}
          setAnchorEl={setAnchorEl}
        />
      )}
      {usersAtFilters.length === 0 && (
        <Button
          ref={(el) => setAnchorEl(el)}
          className={open ? "btn-users-at-filters--focus" : ""}
          onClick={(e) => {
            setOpen(true)
            e.stopPropagation()
          }}
        >
          <Plus />
          {isMobile ? <P14>{t("filters")}</P14> : <P16>{t("filters")}</P16>}
        </Button>
      )}
      <FiltersDialog
        open={open}
        anchorEl={anchorEl}
        setOpen={setOpen}
        handleFilterUpdate={handleFilterUpdate}
      />
    </>
  )
}
