import { ApolloError } from "@apollo/client"
import { CircularProgress, Container, Grid } from "@mui/material"
import { spacing } from "@mui/system"
import React, { useEffect, useState } from "react"
import styled from "styled-components/macro"
import { Alert as UiAlert, P16 } from "ui"

import PageOops from "./PageOops"

const Alert = styled(UiAlert)(spacing)
interface PropsType {
  error: ApolloError | { message: string } | undefined | null
  loading: boolean
  marginTop?: number
}

export default function LoaderOrErrorComponent({
  error,
  loading,
  marginTop = 100,
}: PropsType) {
  const [fetchError, setFetchError] = useState(false)
  useEffect(() => {
    if (error) {
      if (error.message === "Failed to fetch") {
        setFetchError(true)
      }
    }
  }, [error])
  if (fetchError) {
    return <PageOops />
  }
  return (
    <Container maxWidth="sm" style={{ marginTop }}>
      <Grid container justifyContent="center">
        {error && (
          <Alert mt={2} mb={1} severity="warning">
            <P16>{error.message}</P16>
          </Alert>
        )}
        {!error && loading && <CircularProgress />}
        {!loading && !error && "Unhandled Error. "}
      </Grid>
    </Container>
  )
}
