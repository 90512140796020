import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import DownloadCsvButton from "pages/StatisticsPage/components/DownloadCsvButton"
import React, { useCallback, useRef, useState } from "react"
import styled from "styled-components"
import { GroupSimple, UserBase } from "types"
import { colors, L12 } from "ui"
import getUserDisplayName from "utils/getUserDisplayName"
import UTCDate from "utils/UTCDate"

import GroupWorkingModePie from "./GroupWorkingModePie/GroupWorkingModePie"
import TotalWorkingModePie from "./TotalWorkingModePie/TotalWorkingModePie"
import UserWorkingModePie from "./UserWorkingModePie/UserWorkingModePie"

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Legend = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  width: 100%;

  p.wm-chart-legend {
    display: flex;
    align-items: center;
    gap: 4px;

    color: ${colors.grey1};

    &::before {
      content: "";
      display: block;
      width: 12px;
      aspect-ratio: 1;
      border-radius: 100vh;
    }
    &--home::before {
      background: ${colors.home};
    }
    &--office::before {
      background: ${colors.office};
    }
    &--else::before {
      background: ${colors.else};
    }
    &--off::before {
      background: ${colors.off};
    }
    &--ooc::before {
      background: ${colors.ooc};
    }
    &--null::before {
      background: ${colors.nullStats};
    }
  }
`

interface PropsType {
  users: UserBase[]
  groups: GroupSimple[]
  from: UTCDate
  to: UTCDate
  includeWeekends: boolean
  showTotal: boolean
  showDownloadCSV?: boolean
}

export default function WorkingModeChart({
  users,
  groups,
  from,
  to,
  includeWeekends,
  showTotal,
  showDownloadCSV = true,
}: PropsType) {
  const { user: me } = useUser()
  const t = useTranslate()
  const [selectedWorkingModes, setSelectedWorkingModes] = useState<
    {
      sample: UserBase | GroupSimple
      type: "user" | "group"
      workingModes: {
        homeValue: number
        officeValue: number
        elseValue: number
        offValue: number
        oocValue: number
        nullValue: number
      }
    }[]
  >([])
  const [totalWorkingModes, setTotalWorkingModes] = useState<
    | {
        homeValue: number
        officeValue: number
        elseValue: number
        offValue: number
        oocValue: number
        nullValue: number
      }
    | undefined
  >(undefined)

  const legends = useRef<string[]>([
    "home",
    "office",
    "else",
    "off",
    "ooc",
    "null",
  ])

  const getCsv = useCallback(() => {
    if (me === null) return Promise.resolve(undefined)
    const headers = ["email", "name", "type", ...legends.current]

    const rows = []
    if (showTotal && totalWorkingModes)
      rows.push(
        [
          "",
          "total",
          "total",
          totalWorkingModes.homeValue,
          totalWorkingModes.officeValue,
          totalWorkingModes.elseValue,
          totalWorkingModes.offValue,
          totalWorkingModes.oocValue,
          totalWorkingModes.nullValue,
        ].join(",")
      )
    const usersWorkingModes = selectedWorkingModes.filter(
      (swm) => swm.type === "user"
    ) as {
      type: "user"
      sample: UserBase
      workingModes: {
        homeValue: number
        officeValue: number
        elseValue: number
        offValue: number
        oocValue: number
        nullValue: number
      }
    }[]

    for (const uwm of usersWorkingModes) {
      rows.push(
        [
          uwm.sample.email,
          getUserDisplayName(uwm.sample, me.company.flags),
          "user",
          uwm.workingModes.homeValue,
          uwm.workingModes.officeValue,
          uwm.workingModes.elseValue,
          uwm.workingModes.offValue,
          uwm.workingModes.oocValue,
          uwm.workingModes.nullValue,
        ].join(",")
      )
    }

    const groupsWorkingModes = selectedWorkingModes.filter(
      (swm) => swm.type === "group"
    ) as {
      type: "group"
      sample: GroupSimple
      workingModes: {
        homeValue: number
        officeValue: number
        elseValue: number
        offValue: number
        oocValue: number
        nullValue: number
      }
    }[]

    for (const gwm of groupsWorkingModes) {
      rows.push(
        [
          "",
          gwm.sample.name,
          "group",
          gwm.workingModes.homeValue,
          gwm.workingModes.officeValue,
          gwm.workingModes.elseValue,
          gwm.workingModes.offValue,
          gwm.workingModes.oocValue,
          gwm.workingModes.nullValue,
        ].join(",")
      )
    }

    return Promise.resolve(`${headers}\n${rows.join("\n")}`)
  }, [selectedWorkingModes, totalWorkingModes, showTotal, me])

  return (
    <>
      {showDownloadCSV && (
        <DownloadCsvButton fileName="working-modes.csv" getCsv={getCsv} />
      )}
      <Layout>
        <Legend>
          {React.Children.map(legends.current, (legend) => (
            <L12 className={`wm-chart-legend wm-chart-legend--${legend}`}>
              {t(legend)}
            </L12>
          ))}
        </Legend>
        {groups.map((g) => (
          <GroupWorkingModePie
            key={`work-mode-pie-group-${g.id}`}
            group={g}
            from={from}
            to={to}
            includeWeekends={includeWeekends}
            selectedWorkingModes={selectedWorkingModes}
            setSelectedWorkingModes={setSelectedWorkingModes}
          />
        ))}
        {users.map((u) => (
          <UserWorkingModePie
            key={`work-mode-pie-user-${u.id}`}
            user={u}
            from={from}
            to={to}
            includeWeekends={includeWeekends}
            selectedWorkingModes={selectedWorkingModes}
            setSelectedWorkingModes={setSelectedWorkingModes}
          />
        ))}
        {showTotal && (
          <TotalWorkingModePie
            users={users}
            groups={groups}
            from={from}
            to={to}
            includeWeekends={includeWeekends}
            totalWorkingModes={totalWorkingModes}
            setTotalWorkingModes={setTotalWorkingModes}
          />
        )}
      </Layout>
    </>
  )
}
