import useUser from "graphql/users/useUser"
import useUserFromId from "graphql/users/useUserFromId"
import React from "react"
import styled from "styled-components"
import { UserBase } from "types"
import {
  ProfileCircled,
  User as UserIcon,
  VerifiedBadge,
  VerifiedUser,
} from "ui/icons"

interface AvatarPropsType {
  size?: number
  color?: string
}

const Avatar = styled.img<AvatarPropsType>`
  ${({ size }) => {
    return `width: ${size}px !important;`
  }}
  ${({ size }) => {
    return `height: ${size}px !important;`
  }}
  ${({ color }) => {
    if (color) {
      return `border: 2px solid ${color};`
    }
  }}

  border-radius: 100vh;
`

const ManagerAvatarContainer = styled.div<AvatarPropsType>`
  position: relative;
  img:first-child {
  }
  svg:last-child {
    position: absolute;
    right: -2px;
    bottom: 2px;
    z-index: 1;
    stroke-width: 2px;
    width: 12px;
    height: 12px;
    ${({ size }) => {
      if (!size) return "scale: 1;"
      return `scale: ${(size * 1) / 24};`
    }}
  }
`

interface PropsType extends AvatarPropsType {
  user: UserBase
}

export default function UserAvatar({ user, size = 24, color }: PropsType) {
  const { user: me } = useUser()
  const selectedUser = me?.id === user.id ? me : user
  const hasPhoto = selectedUser.photo !== "" && selectedUser.photo !== undefined

  if (hasPhoto && user.isManager)
    return (
      <ManagerAvatarContainer size={size}>
        <Avatar
          size={size}
          alt="user avatar"
          src={selectedUser.photo}
          color={color}
        />
        <VerifiedBadge />
      </ManagerAvatarContainer>
    )

  if (hasPhoto)
    return (
      <Avatar
        size={size}
        alt="user avatar"
        src={selectedUser.photo}
        color={color}
      />
    )

  if (me?.id === selectedUser.id)
    return (
      <ProfileCircled
        width={size}
        height={size}
        style={color ? { color } : {}}
      />
    )

  if (user.isManager) return <VerifiedUser width={size} height={size} />

  return <UserIcon width={size} height={size} />
}

interface UserAvatarFromIdPropType extends AvatarPropsType {
  id: string
}

export function UserAvatarFromId({ id, ...props }: UserAvatarFromIdPropType) {
  const { user: favoriteUser } = useUserFromId(id)

  if (!favoriteUser) return <UserIcon />
  return <UserAvatar user={favoriteUser} {...props} />
}
