import { gql, useMutation } from "@apollo/client"
import { spacing } from "@mui/system"
import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import useCustomDomain from "graphql/admin/useCustomDomain"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components/macro"
import { Alert as UiAlert, Button, P14, P16, TextField } from "ui"

const Container = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const SAVE_DOMAIN = gql`
  mutation saveCustomDomain($domain: String!) {
    saveCustomDomain(domain: $domain)
  }
`

const Alert = styled(UiAlert)(spacing)

export default function ConfigureCustomDomain() {
  const t = useTranslate()
  const { domain: domainBase, loading, error } = useCustomDomain()

  const [domain, setDomain] = React.useState<string | null>(null)
  const [saved, setSaved] = React.useState(true)
  const [savingError, setDomainError] = React.useState<string | null>(null)

  const [saveCustomDomainMutation] = useMutation(SAVE_DOMAIN)

  const saveCustomDomain = async () => {
    try {
      await saveCustomDomainMutation({ variables: { domain } })
      setDomainError(null)
      setSaved(true)
    } catch (error) {
      setDomainError(error.message)
    }
  }

  if (loading || error) {
    return <LoaderOrErrorComponent loading={loading} error={error} />
  }

  return (
    <Container>
      <TextField
        label={t("Custom domain")}
        placeholder={t("Custom domain")}
        initialValue={domain || domainBase || ""}
        endAdornment={<P14>.offishall.io</P14>}
        handleChange={(e) => {
          setDomain(e)
          setDomainError(null)
          setSaved(false)
        }}
      />

      {savingError && (
        <Alert mt={2} mb={1} severity="error">
          <P16>{savingError}</P16>
        </Alert>
      )}
      <Button disabled={!domain || saved} onClick={saveCustomDomain}>
        {t("Save custom domain")}
      </Button>
    </Container>
  )
}
