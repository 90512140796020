import FloorPlanProps from "components/FloorPlanComponents/FloorPlanProps"
import { LocationChipButton } from "components/LocationComponents/Buttons/LocationChipButton/LocationChipButton"
import UpdateLocationDialog from "pages/AdminPage/AdminLocationsPage/AddLocationButton/UpdateLocationDialog/UpdateLocationDialog"
import React, { useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import styled from "styled-components"
import LocationTree from "utils/LocationTree"

interface LocationProps {
  hasBreadcrumbs: boolean
}

const Location = styled.div<LocationProps>`
  ${(props) =>
    !props.hasBreadcrumbs &&
    `padding: 0 8px 0 0;
`}
`

interface PropsType {
  location: LocationTree
  showBreadCrumbs?: boolean
  showFavoriteCheckbox?: boolean
  withActions?: boolean
  disabled?: boolean
  floorPlan?: FloorPlanProps
}

export default function LocationItem({
  location,
  showBreadCrumbs,
  showFavoriteCheckbox,
  withActions,
  floorPlan,
  disabled,
}: PropsType) {
  const history = useHistory()
  const { pathname } = useLocation()

  const temporaryCapacity = location.bookable
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)

  return (
    <Location hasBreadcrumbs={location.breadCrumbs.length === 0}>
      <LocationChipButton
        location={location}
        showBreadCrumbs={showBreadCrumbs}
        onClick={() => {
          if (floorPlan) {
            floorPlan.select(location.id)
          } else if (pathname.includes("/admin/")) {
            setIsEditModalOpen(true)
          } else {
            history.push(`/planning/location/${location.id}`)
          }
        }}
        availability={{ bookable: temporaryCapacity }}
        active={floorPlan?.activeSublocation?.id === location.id}
        showFavoriteCheckbox={showFavoriteCheckbox}
        withActions={withActions}
        disabled={disabled}
        floorPlan={floorPlan}
      />
      {isEditModalOpen && pathname.includes("/admin/") && (
        <UpdateLocationDialog
          open={isEditModalOpen}
          locationToMutate={location}
          onClose={() => setIsEditModalOpen(false)}
        />
      )}
    </Location>
  )
}
