import useLocationsTree from "graphql/locations/useLocationsTree"
import useUsersAt from "graphql/users/useUsersAt"
import useMediaQueries from "hooks/useMediaQueries"
import React from "react"
import { useParams } from "react-router-dom"
import LocationTree from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

import DesktopResultsPage from "./DesktopResultsPage"
import MobileResultsPage from "./MobileResultsPage"

export default function AdvancedSearchResultsPage() {
  const { isMobile } = useMediaQueries()
  const { locationId, timestamp } = useParams() as {
    locationId: string
    timestamp: string
  }

  const { loading, locations } = useLocationsTree()

  const morning = new UTCDate(Number(timestamp))
  const afternoon = new UTCDate(Number(timestamp))
  afternoon.increment("HALF-DAY")

  const dayAfter = new UTCDate(Number(timestamp))
  dayAfter.increment("DAY")

  const {
    loading: amLoading,
    users: amUsers,
    error: amError,
  } = useUsersAt({
    from: morning,
    to: afternoon,
    locationIds: [locationId],
    filters: [],
  })

  const {
    loading: pmLoading,
    users: pmUsers,
    error: pmError,
  } = useUsersAt({
    from: afternoon,
    to: dayAfter,
    locationIds: [locationId],
    filters: [],
  })

  const location = LocationTree.getLocationNode(locations, locationId)
  if (!location || loading || pmLoading || amLoading || amError || pmError) {
    return null
  }

  if (isMobile)
    return (
      <MobileResultsPage
        date={morning}
        AMUsers={amUsers}
        PMUsers={pmUsers}
        location={location}
      />
    )
  else
    return (
      <DesktopResultsPage
        date={morning}
        AMUsers={amUsers}
        PMUsers={pmUsers}
        location={location}
      />
    )
}
