import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { colors, H4Light } from "ui"

const Layout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  h4 {
    color: ${colors.grey2};
  }
`

export default function NoServiceAvailableMessage() {
  const t = useTranslate()

  return (
    <Layout>
      <H4Light>{t("No service available message")}</H4Light>
    </Layout>
  )
}
