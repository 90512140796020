import UpdateManagerDialog from "components/UpdateManagerDialog/UpdateManagerDialog"
import useUser from "graphql/users/useUser"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import { User } from "types"
import { Button, colors, P14Bold } from "ui"
import { VerifiedUser } from "ui/icons"
import getUserDisplayName from "utils/getUserDisplayName"

interface PropsType {
  selectedUser: User
  variant?: "small" | "medium"
}

export default function EditManager({
  selectedUser,
  variant = "medium",
}: PropsType) {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()
  const { user: currentUser } = useUser()

  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  if (selectedUser.id === currentUser?.id || currentUser?.role === "admin")
    return (
      <>
        {isMobile && variant === "small" ? (
          <VerifiedUser onClick={() => setDialogOpen(true)} />
        ) : (
          <Button
            secondary
            iconColor={colors.black}
            icon={<VerifiedUser />}
            onClick={() => setDialogOpen(true)}
          >
            {!selectedUser.manager ? (
              <P14Bold style={{ color: colors.purple }}>
                {t("define manager")}
              </P14Bold>
            ) : (
              <>
                <P14Bold style={{ marginRight: 10, color: colors.purple }}>
                  {t("Manager")} :
                </P14Bold>
                <P14Bold style={{ color: colors.purple }}>
                  {getUserDisplayName(
                    selectedUser.manager,
                    currentUser.company.flags
                  )}
                </P14Bold>
              </>
            )}
          </Button>
        )}
        {dialogOpen && (
          <UpdateManagerDialog
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            selectedUser={selectedUser}
          />
        )}
      </>
    )
  else return <></>
}
