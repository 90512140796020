import React from "react"
import styled from "styled-components"
import { IntegrationEvent, MeetingRoom } from "types"
import { colors } from "ui"
import LocalDate from "utils/LocalDate"

import { NotBookedItem } from "../NotBookedItem/NotBookedItem"
import { MeetingProgressBar } from "./MeetingProgressBar/MeetingProgressBar"

export const Item = styled(NotBookedItem)`
  // blue section representing actual booked slot
  &.booked.anchor {
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 1;
      // shift blue section to the right by minutes amount
      ${({ from }) => `
        left: calc(${from} * 100 / 60 * 1%);
      `}
      // * width is synced to event duration
      // * 1h is 100% width
      ${({ duration }) => `
        width: calc((${duration} / 60) * 100%);
      `}
      height: calc(100% - 8px);
      background: ${colors.office};
      color: ${colors.black}44;
      border-radius: 8px;
      &::after {
        // display from/to
        position: absolute;
        left: 0;
        z-index: 1;
        display: flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        ${({ "data-content": dataContent }) => `content: "${dataContent}";`}
        color: ${colors.white};
        padding-left: 4px;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
`

interface PropsType {
  meetingRoom: MeetingRoom
  booked: IntegrationEvent
  slotHour: LocalDate
  setSelectedEventToShow: (event: IntegrationEvent) => void
}

export default function BookedItem({
  meetingRoom,
  booked,
  slotHour,
  setSelectedEventToShow,
}: PropsType) {
  const isFirstSlot = booked?.from.getHours() === slotHour.getHours()
  // const isLastSlot =
  //   booked?.to.getMinutes() === 0
  //     ? booked?.to.getHours() - 1 === slotHour.getHours()
  //     : booked?.to.getHours() === slotHour.getHours()

  const time = `${booked.from.format("HH:MM")} \u2014 ${booked.to.format(
    "HH:MM"
  )}`

  if (!isFirstSlot)
    return (
      <Item
        key={`${meetingRoom.id}-${slotHour.getTime()}`}
        className="booked"
        duration={60}
        from={0}
        data-content={isFirstSlot ? time : `${slotHour.getHours()}`}
      >
        <div />
      </Item>
    )

  return (
    <Item
      key={`${meetingRoom.id}-${slotHour.getTime()}`}
      className="booked anchor"
      duration={(booked.to.getTime() - booked.from.getTime()) / (60 * 1000)}
      from={booked.from.getMinutes()}
      data-content={isFirstSlot ? time : `${slotHour.getHours()}`}
      onClick={() =>
        setSelectedEventToShow({
          ...booked,
          roomId: meetingRoom.id,
        })
      }
    >
      <div />
      <MeetingProgressBar
        backgroundColor="rgba(255, 255, 255, 0.24)"
        foreGroudColor="rgba(255, 255, 255, 0.4)"
        duration={(booked.to.getTime() - booked.from.getTime()) / (60 * 1000)}
        from={booked.from.getMinutes()}
        value={
          booked.attendees.filter(({ status }) => status === "attending").length
        }
        max={meetingRoom.capacity}
      />
    </Item>
  )
}
