import React from "react"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import styled from "styled-components"
import { colors, H4, P14, P16 } from "ui"

const AnchorStyled = styled.a`
  color: ${colors.black};
`

interface PropsType {
  children: string
}

function Paragraph(props: any) {
  return <P16 {...props}>{props.children}</P16>
}

function Title(props: any) {
  return <H4 {...props}>{props.children}</H4>
}

function ListItem(props: any) {
  const prefix = props.ordered ? `${props.index + 1}.` : "-"
  return (
    <P14 {...props}>
      {prefix} {props.children}
    </P14>
  )
}

function Anchor(props: any) {
  return (
    <AnchorStyled target="_blank" href={props.href} rel="noreferrer">
      {props.children}
    </AnchorStyled>
  )
}

export function Markdown({ children }: PropsType) {
  return (
    <ReactMarkdown
      className="body"
      components={{
        p: Paragraph,
        h1: Title,
        h2: Title,
        h3: Title,
        h4: Title,
        h5: Title,
        h6: Title,
        li: ListItem,
        a: Anchor,
      }}
      remarkPlugins={[remarkGfm]}
    >
      {children}
    </ReactMarkdown>
  )
}
