import PageContent from "components/Layout/PageLayout/PageContent/PageContent"
import AdminPageTitle from "components/Layout/PageLayout/PageHeader/AdminPageTitle/AdminPageTitle"
import PageHeader from "components/Layout/PageLayout/PageHeader/PageHeader"
import PageLayout from "components/Layout/PageLayout/PageLayout"
import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import { Tabs } from "ui"
import UTCDate from "utils/UTCDate"

import PWStatsOccupancyRate from "./PWStatsOccupancyRate"
import PWStatsTeamStats from "./PWStatsTeamStats"
import PWStatsUserStats from "./PWStatsUserStats"
import PWStatsWeekDays from "./PWStatsWeekDays"
import PWStatsWorkingMode from "./PWStatsWorkingMode"

const ENABLE_WEEKENDS = true
const FROM = UTCDate.firstEpochMonday()
const TO = new UTCDate(UTCDate.firstEpochMonday(), "NEXT-SUNDAY")

export default function StatisticsPage() {
  const t = useTranslate()
  const { user: me } = useUser()

  const breadcrumb = [
    {
      name: t("Admin"),
      path: "/admin",
    },
    {
      name: t("Statistics"),
      path: "/admin/tw-stats",
    },
  ]

  const [pageHeaderHeight, setPageHeaderHeight] = useState<{
    header?: number
    tabsHeader?: number
  }>({})

  const tabs: any[] = [
    {
      label: t("occupation rate"),
      value: 0,
      component: (
        <PWStatsOccupancyRate
          from={FROM}
          to={TO}
          enableWeekends={ENABLE_WEEKENDS}
          pageHeaderHeight={pageHeaderHeight}
        />
      ),
    },
    {
      label: t("working mode"),
      value: 1,
      component: (
        <PWStatsWorkingMode
          from={FROM}
          to={TO}
          enableWeekends={ENABLE_WEEKENDS}
          pageHeaderHeight={pageHeaderHeight}
        />
      ),
    },
    {
      label: t("week days"),
      value: 2,
      component: (
        <PWStatsWeekDays
          from={FROM}
          to={TO}
          enableWeekends={ENABLE_WEEKENDS}
          pageHeaderHeight={pageHeaderHeight}
        />
      ),
    },
  ]

  tabs.push(
    {
      label: t("stats per user"),
      value: 4,
      component: (
        <PWStatsUserStats
          from={FROM}
          to={TO}
          pageHeaderHeight={pageHeaderHeight}
          variant="admin"
        />
      ),
    },
    {
      label: t("stats per team"),
      value: 5,
      component: (
        <PWStatsTeamStats
          from={FROM}
          to={TO}
          pageHeaderHeight={pageHeaderHeight}
        />
      ),
    }
  )

  if (me?.role !== "admin") {
    return <></>
  }

  return (
    <PageLayout contentNotScrollable>
      <PageHeader
        ref={(el) =>
          setPageHeaderHeight((prev) => {
            if (prev.header !== undefined) return prev
            return {
              ...prev,
              header: el?.getBoundingClientRect().height,
            }
          })
        }
      >
        <AdminPageTitle
          title={t("typical week stats")}
          breadcrumbs={breadcrumb}
        />
      </PageHeader>
      <PageContent>
        <Tabs
          tabs={tabs}
          defaultValue={0}
          getBoudingRect={(rect) => {
            setPageHeaderHeight((prev) => {
              if (prev.tabsHeader !== undefined) return prev
              return {
                ...prev,
                tabsHeader: rect.height,
              }
            })
          }}
        />
      </PageContent>
    </PageLayout>
  )
}
