import useUser from "graphql/users/useUser"
import React from "react"
import styled from "styled-components"
import { Attendee } from "types"
import { ChipButton } from "ui"
import { User as UserIcon } from "ui/icons"
import UserTree from "utils/UserTree"

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 16px 0;
`

interface PropsType {
  attendeesAsUsers: UserTree[]
  attendeesAsExternalGuests: Attendee[]
}

export default function AttendeesList({
  attendeesAsExternalGuests,
  attendeesAsUsers,
}: PropsType) {
  const { user: me } = useUser()

  if (me === null) return <></>
  return (
    <List>
      {attendeesAsUsers.map(({ email }) => (
        <React.Fragment key={`attending-user-${email}`}>
          <ChipButton icon={<UserIcon />}>{email}</ChipButton>
        </React.Fragment>
      ))}
      {attendeesAsExternalGuests.map(({ email }) => (
        <React.Fragment key={`guests-user-${email}`}>
          <ChipButton icon={<UserIcon />}>{email}</ChipButton>
        </React.Fragment>
      ))}
    </List>
  )
}
