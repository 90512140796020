import styled from "styled-components"

export const MenuList = styled.ul`
  padding-bottom: 24px;

  .menu-element-group-container {
    height: 16px;
    margin-bottom: 6px;
  }
`
