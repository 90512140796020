import useTranslate from "intl/useTranslate"
import React from "react"

import TypicalWeekTuto from "../../TypicalWeekTuto/TypicalWeekTuto"

interface PropsType {
  isTypicalWeekValid: boolean
}

export default function TypicalWeekHowTo({ isTypicalWeekValid }: PropsType) {
  const t = useTranslate()
  const isOnboarding = location.pathname.startsWith("/onboarding/user")

  if (isOnboarding)
    return (
      <div>
        <TypicalWeekTuto />
      </div>
    )

  if (!isTypicalWeekValid)
    return (
      <div
        style={{
          margin: "0 auto",
        }}
      >
        {t("user-typical-week-update-slots--invalid")}
      </div>
    )

  return (
    <div
      style={{
        margin: "0 auto",
      }}
    >
      {t("user-typical-week-update-slots")}
    </div>
  )
}
