import TargetPicker from "components/TargetPicker/TargetPicker"
import useCompanyHybridRules from "graphql/hybridPolicy/useCompanyHybridRules"
import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import {
  DefaultHybridRule,
  Group,
  GroupHybridRule,
  GroupHybridRuleInput,
  HybridRule,
  ManagerHybridRule,
  PickedTargets,
  UserHybridRule,
} from "types"
import {
  colors,
  FieldsetColumn,
  L12,
  Label,
  TextFieldAsDisplay,
  TextFieldInput,
  Toggle,
} from "ui"
import { Handbag } from "ui/icons"
import UserTree from "utils/UserTree"

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const TargetLabel = styled.div`
  background: ${colors.grey4};
  border-radius: 4px;
  width: max-content;
  padding: 4px 8px;

  p {
    color: ${colors.grey1};
  }
`

interface PropsType {
  type: "user" | "default"
  initialHybridRule?: HybridRule
  selectedTargets: PickedTargets
  isGroupRule: boolean
  setIsGroupRule: (checked: boolean) => void
  updateSelectedUsers: (users: UserTree[]) => void
  updateSelectedManagers: (managers: UserTree[]) => void
  updateSelectedGroups: (groups: Group[]) => void
  updateSelectedEmails: (emails: string[]) => void
  formInputs: GroupHybridRuleInput
  setFormInputs: (formInputs: GroupHybridRuleInput) => void
}

export default function TargetAndNamePicker({
  type,
  initialHybridRule,
  isGroupRule,
  setIsGroupRule,
  selectedTargets,
  updateSelectedUsers,
  updateSelectedManagers,
  updateSelectedGroups,
  updateSelectedEmails,
  formInputs,
  setFormInputs,
}: PropsType) {
  const t = useTranslate()
  const { user: me } = useUser()
  const { companyHybridRules } = useCompanyHybridRules()

  const [displayName, setDisplayName] = useState(
    initialHybridRule?.name !== undefined
  )

  const getRuleName = (
    rule:
      | DefaultHybridRule
      | UserHybridRule
      | GroupHybridRule
      | ManagerHybridRule
  ) => {
    if (rule.name === "default rule") return t("default rule")
    if (rule.name) return rule.name
    return "n/a"
  }

  const getRuleTargetName = (
    selectedTargets: PickedTargets,
    isGroupRule: boolean
  ) => {
    if (isGroupRule) return formInputs.name !== "" ? formInputs.name : "n/a"
    if (selectedTargets.users.length > 0) return selectedTargets.users[0].email
    if (selectedTargets.managers.length > 0)
      return selectedTargets.managers[0].email
    return "n/a"
  }

  const usersWithOtherThanDefaultRule = Array.from(
    new Set([
      ...companyHybridRules.users.map(({ userId }) => userId),
      ...companyHybridRules.managers.map(({ managerId }) => managerId),
      ...companyHybridRules.groups.map(({ userIds }) => userIds).flat(),
    ])
  )

  const canToggleGroupRule =
    type !== "default" &&
    (initialHybridRule === undefined ||
      (initialHybridRule as GroupHybridRule).groupId !== undefined)

  if (!me) return <></>

  return (
    <FieldsetColumn>
      <Label>{t("hybrid-rule-label--target-name")}</Label>
      <Header>
        <TargetLabel>
          <L12>
            {initialHybridRule
              ? getRuleName(initialHybridRule)
              : getRuleTargetName(selectedTargets, isGroupRule)}
          </L12>
        </TargetLabel>
        {canToggleGroupRule && (
          <Toggle
            label={t("hybrid-rule-label--group-toggle")}
            checked={isGroupRule}
            onChange={(checked) => {
              setIsGroupRule(checked)
              if (!checked) {
                updateSelectedUsers([])
                updateSelectedGroups([])
                updateSelectedManagers([])
                updateSelectedEmails([])
              }
            }}
            labelToTheLeft
            disabled={
              initialHybridRule &&
              (initialHybridRule as GroupHybridRule).groupId !== undefined
            }
          />
        )}
      </Header>
      {type === "user" && (
        <div style={{ padding: "16px 0 8px 0" }}>
          <TargetPicker
            title={t("hybrid-rule-form--targets-button", {
              nbUsers: isGroupRule ? `${selectedTargets.users.length}` : "",
            })}
            selectedTargets={selectedTargets}
            options={{ singleSelection: !isGroupRule, disableMySelf: false }}
            disabled={{
              emails: true,
              picker: initialHybridRule && !isGroupRule,
            }}
            extraAction={
              <Handbag
                style={{
                  width: "18px",
                  height: "18px",
                  display: "flex",
                  stroke: colors.grey2,
                }}
              />
            }
            extraActionFilteredTargetIds={usersWithOtherThanDefaultRule}
            updateSelectedUsers={updateSelectedUsers}
            updateSelectedManagers={updateSelectedManagers}
            updateSelectedGroups={updateSelectedGroups}
            updateSelectedEmails={updateSelectedEmails}
          />
        </div>
      )}
      {isGroupRule && (
        <>
          {displayName ? (
            <TextFieldAsDisplay
              onClick={() => setDisplayName(false)}
              label={t("Name")}
              value={initialHybridRule?.name}
              placeholder={t("hybrid-rule-placeholder--group-name")}
            />
          ) : (
            <TextFieldInput
              onChange={(name) => {
                setFormInputs({ ...formInputs, name })
              }}
              label={t("Name")}
              placeholder={t("hybrid-rule-placeholder--group-name")}
              autoFocus={!displayName}
              required
            />
          )}
        </>
      )}
    </FieldsetColumn>
  )
}
