import { ApolloError, gql, useQuery } from "@apollo/client"
import { useMemo } from "react"
import { Slot, UTCSlot } from "types"
import UTCDate from "utils/UTCDate"

import { SlotFragment } from "./fragments/slotFragment"

export const SLOTS_QUERY = gql`
  ${SlotFragment}
  query getSlots($userId: ID!, $before: String!, $after: String!) {
    slots(userId: $userId, after: $after, before: $before) {
      ...SlotFragment
    }
  }
`

export default function usePlanningUserSlots({
  userId,
  from,
  to,
}: {
  userId: string
  from: UTCDate
  to: UTCDate
}): {
  slots: UTCSlot[]
  loading: boolean
  error?: ApolloError
} {
  const { loading, error, data } = useQuery(SLOTS_QUERY, {
    variables: {
      userId,
      before: `${to.getTime()}`,
      after: `${from.getTime()}`,
    },
    skip: userId === "",
    fetchPolicy: "cache-first",
  })
  const slots: UTCSlot[] = useMemo(() => {
    if (userId && data) {
      const { slots }: { slots: Slot[] } = data
      const d: UTCSlot[] = []

      for (
        const cursor = new UTCDate(from);
        cursor.getTime() < to.getTime();
        cursor.shift("HALF-DAY", 1)
      ) {
        const id = `${userId}_${cursor.getTime()}`
        const foundSlot = slots.find((s) => s.id === id)
        const slot: UTCSlot = {
          id,
          value: foundSlot?.value || null,
          locationId: foundSlot?.locationId || null,
          userId,
          date: new UTCDate(cursor),
          guests: foundSlot?.guests || 0,
          guestsEmails: foundSlot?.guestsEmails || [],
          label: foundSlot?.label || "",
        }
        d.push(slot)
      }
      return d
    }
    return []
  }, [userId, from, to, data])

  return { slots, loading, error }
}
