import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { colors, mediaQueries, P14, P16 } from "ui"
import { overflowWithEllipsisCSS } from "ui/utilities"

interface SlotProps {
  variant: string | null
}

const Slot = styled.div<SlotProps>`
  padding: 0px 8px;
  gap: 8px;
  width: 18ch;
  height: 24px;
  border-radius: 24px;
  text-align: center;

  @media ${mediaQueries.isMobile} {
    width: 8ch;
  }

  ${({ variant }) => {
    if (variant === "off" || variant === "ooc")
      return `
       background: ${colors.grey4};
        border: 1.5px solid ${colors.grey2};
      `
    else if (variant === "home")
      return `
       background:  ${colors.green}16;
        border: 1.5px solid ${colors.green};`
    else if (variant === "office")
      return `
        background:  ${colors.blue}16;
        border: 1.5px solid ${colors.blue};`
    else if (variant === "else")
      return `
        background: ${colors.yellow}16;
        border: 1.5px solid ${colors.yellow};`
    else if (variant === null)
      return `
        background: ${colors.red}16;
        border: 1.5px solid ${colors.red};`
  }}

  p {
    ${overflowWithEllipsisCSS}
  }

  &.dimmed {
    opacity: 0.3;
  }
`

interface PropsType {
  value: string | null
  disabled: boolean
}

export default function SlotValueAsChipButton({ value, disabled }: PropsType) {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()

  const getSlotValue = () => {
    if (value === null) return t("Not set")
    else return t(value)
  }

  return (
    <Slot variant={value} className={disabled ? "dimmed" : undefined}>
      {isMobile ? <P14>{getSlotValue()}</P14> : <P16>{getSlotValue()}</P16>}
    </Slot>
  )
}
