import React from "react"
import styled from "styled-components"
import { colors } from "ui"
import { ArrowDropDown } from "ui/icons"
import mediaQueries from "ui/mediaQueries"

const Button = styled.button<{ minWidth?: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 11px 8px;
  ${({ minWidth }) =>
    minWidth ? `min-width: ${minWidth};` : "min-width: 24ch;"}

  background: ${colors.grey4};
  box-shadow: 0px 2px 2px hsla(${colors.blackHSL}, 0.25);
  border: none;
  border-radius: 4px;
  p:first-letter {
    text-transform: capitalize;
  }

  svg {
    fill: ${colors.grey1};
    stroke-width: 1.5px;
    width: 14px;
    height: 14px;
    margin-top: 2px;
  }

  @media ${mediaQueries.isTouchable} {
    min-width: unset;
    box-shadow: none;
    background: ${colors.white};
    border: solid 1px ${colors.grey3};

    svg {
      fill: ${colors.grey1};
      stroke-width: 1.5px;
      width: 20px;
      height: 20px;
      margin-top: 2px;
    }
  }

  &:disabled {
    cursor: not-allowed;
    p {
      color: ${colors.grey1};
    }
  }
`

interface PropsType {
  children: JSX.Element
  icon?: JSX.Element
  disabled?: boolean
  minWidth?: string
  dropDownMenuOpen?: boolean
  onClick: () => void
}

function DropDownButtonWithRef(
  { children, icon, disabled, minWidth, dropDownMenuOpen, onClick }: PropsType,
  ref: React.Ref<HTMLButtonElement>
) {
  if (icon)
    return (
      <Button
        ref={ref}
        onClick={onClick}
        disabled={disabled}
        minWidth={minWidth}
      >
        {icon}
      </Button>
    )

  return (
    <Button ref={ref} onClick={onClick} disabled={disabled} minWidth={minWidth}>
      {children}
      {dropDownMenuOpen ? (
        <ArrowDropDown />
      ) : (
        <ArrowDropDown style={{ transform: `rotate(0.75turn)` }} />
      )}
    </Button>
  )
}

export const DropDownButton = React.forwardRef(DropDownButtonWithRef)
