import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function Info({ stroke, strokeWidth, ...rest }: SvgIconProps) {
  return (
    <SvgIcon
      strokeWidth={strokeWidth ?? 0.5}
      stroke={stroke ? stroke : "currentColor"}
      {...rest}
    >
      <path
        d="M12.75 11.5C12.75 11.0858 12.4142 10.75 12 10.75C11.5858 10.75 11.25 11.0858 11.25 11.5H12.75ZM11.25 16.5C11.25 16.9142 11.5858 17.25 12 17.25C12.4142 17.25 12.75 16.9142 12.75 16.5H11.25ZM11.4426 7.00825C11.1654 7.31612 11.1904 7.79034 11.4983 8.06745C11.8061 8.34456 12.2803 8.31962 12.5574 8.01175L11.4426 7.00825ZM12.5674 8.00064C12.8446 7.69277 12.8196 7.21855 12.5118 6.94144C12.2039 6.66433 11.7297 6.68927 11.4526 6.99714L12.5674 8.00064ZM11.25 11.5V16.5H12.75V11.5H11.25ZM12.5574 8.01175L12.5674 8.00064L11.4526 6.99714L11.4426 7.00825L12.5574 8.01175ZM12 22.75C17.937 22.75 22.75 17.937 22.75 12H21.25C21.25 17.1086 17.1086 21.25 12 21.25V22.75ZM22.75 12C22.75 6.06293 17.937 1.25 12 1.25V2.75C17.1086 2.75 21.25 6.89137 21.25 12H22.75ZM12 1.25C6.06294 1.25 1.25 6.06294 1.25 12H2.75C2.75 6.89136 6.89136 2.75 12 2.75V1.25ZM1.25 12C1.25 17.937 6.06293 22.75 12 22.75V21.25C6.89137 21.25 2.75 17.1086 2.75 12H1.25Z"
        fill="#141C1F"
      />
    </SvgIcon>
  )
}
