import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function Lock({ stroke, strokeWidth, ...rest }: SvgIconProps) {
  return (
    <SvgIcon
      strokeWidth={strokeWidth ?? 1.5}
      stroke={stroke ? stroke : "currentColor"}
      {...rest}
    >
      <path
        d="M11 9H3M11 9H12.4C12.7314 9 13 9.2686 13 9.6V16.4C13 16.7314 12.7314 17 12.4 17H1.6C1.26863 17 1 16.7314 1 16.4V9.6C1 9.2686 1.26863 9 1.6 9H3H11ZM11 9V5C11 3.66667 10.2 1 7 1C3.8 1 3 3.66667 3 5V9H11Z"
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}
