import { gql, useMutation, useQuery } from "@apollo/client"
import { Label } from "types"

const ADD_USER_LABEL_MUTATION = gql`
  mutation addUserLabel($type: String!, $label: String!) {
    addUserLabel(type: $type, label: $label) {
      type
      label
    }
  }
`

const REMOVE_USER_LABEL_MUTATION = gql`
  mutation removeUserLabel($type: String!, $label: String!) {
    removeUserLabel(type: $type, label: $label) {
      type
      label
    }
  }
`

const GET_USER_LABELS_QUERY = gql`
  query userLabels {
    userLabels {
      type
      label
    }
  }
`

export default function useUserLabels() {
  const [mutateAddLabel] = useMutation(ADD_USER_LABEL_MUTATION, {
    update(cache, { data: { addUserLabel } }) {
      cache.modify({
        fields: {
          userLabels() {
            return addUserLabel
          },
        },
      })
    },
  })
  const addUserLabel = async (type: string, label: string) => {
    try {
      await mutateAddLabel({ variables: { type, label } })
    } catch (e) {
      console.error(e.message)
    }
  }

  const [mutateRemoveLabel] = useMutation(REMOVE_USER_LABEL_MUTATION, {
    update(cache, { data: { removeUserLabel } }) {
      cache.modify({
        fields: {
          userLabels() {
            return removeUserLabel
          },
        },
      })
    },
  })
  const removeUserLabel = async (type: string, label: string) => {
    try {
      await mutateRemoveLabel({ variables: { type, label } })
    } catch (e) {
      console.error(e.message)
    }
  }

  const { loading, error, data } = useQuery(GET_USER_LABELS_QUERY)

  return {
    addUserLabel,
    removeUserLabel,
    userLabels: (data?.userLabels || []) as Label[],
    loading,
    error,
  }
}
