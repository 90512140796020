import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { HybridRule, UserRuleMonthScore, UserRuleWeekScore } from "types"
import { colors, L12, mediaQueries, P14, P16Bold } from "ui"
import { days } from "utils/LocalDate"

const RestrictedDaysList = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 2px;
  padding-top: 8px;

  li {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100vh;
    width: 24px;
    height: 24px;
    color: ${colors.grey1};
    background: ${colors.grey3};
    font-size: 14px;

    &.is-restricted {
      color: ${colors.white};
      background: ${colors.green};
    }
    &.is-disabled {
      color: ${colors.grey3};
      background: ${colors.grey4};
    }
  }
`

const Layout = styled.div`
  .header {
    padding: 16px 24px;
    background: ${colors.grey4};
    border-radius: 5px 5px 0 0;
  }
  .body {
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  p {
    white-space: nowrap;
  }

  @media ${mediaQueries.isMobile} {
    .header {
      background: ${colors.white};
    }
  }
`

interface PropsTtpe {
  userHybridRule: HybridRule
  score: UserRuleWeekScore | UserRuleMonthScore | undefined
}

export default function HybridRulePopupContent({
  userHybridRule,
  score,
}: PropsTtpe) {
  const t = useTranslate()
  const { userShowWeekends } = usePlanningContext()
  const [sunday, ...rest] = days.map((name, n) => ({
    name,
    index: n,
  }))
  const sortedWeekDays = [...rest, sunday]
  // .slice(0, userShowWeekends ? 7 : 5) /* hide weekends as another restricted days display option */

  return (
    <Layout>
      <div className="header">
        <P14>{t("hybrid-rule-origin")}</P14>
        <P16Bold>{userHybridRule.name}</P16Bold>
      </div>
      <div className="body">
        <div>
          <L12 color="grey1">{t("hybrid-rule-label--period")}</L12>
          <P16Bold color="purple">{t(userHybridRule.period)}</P16Bold>
        </div>
        {userHybridRule.restrictedDays.length > 0 && (
          <div className="restricted-days">
            <L12 color="grey1">{t("Preferred days for home office")}</L12>
            <RestrictedDaysList>
              {sortedWeekDays.map(({ name, index }, n) => {
                if (userHybridRule.restrictedDays.includes(index))
                  return (
                    <li key={n} className="is-restricted">
                      {t(name)[0]}
                    </li>
                  )
                if (!userShowWeekends && (index === 0 || index === 6))
                  return (
                    <li key={n} className="is-disabled">
                      {t(name)[0]}
                    </li>
                  )
                return <li key={n}>{t(name)[0]}</li>
              })}
            </RestrictedDaysList>
          </div>
        )}
        <div>
          <L12 color="grey1">{t("Maximum in home office")}</L12>
          <P16Bold color="purple">
            {userHybridRule.maxDays} {t("days")}
          </P16Bold>
        </div>
        {score && (
          <div>
            <L12 color="grey1">
              {t("user-hybrid-rule-current-score--title", {
                period: t(userHybridRule.period),
              })}
            </L12>
            <P16Bold color={score.homeDayDelta >= 0 ? "green" : "redAlert"}>
              {score.homeDayDelta}
            </P16Bold>
          </div>
        )}
      </div>
    </Layout>
  )
}
