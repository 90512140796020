import HybridRuleStatus from "components/HybridRuleStatus/HybridRuleStatus"
import useUser from "graphql/users/useUser"
import React from "react"

export default function HybridRule() {
  const { user: me } = useUser()

  if (!me) return <></>

  return (
    <>
      <HybridRuleStatus selectedUser={me} variant="dashed-button" />
    </>
  )
}
