import PageContent from "components/Layout/PageLayout/PageContent/PageContent"
import AdminPageTitle from "components/Layout/PageLayout/PageHeader/AdminPageTitle/AdminPageTitle"
import PageHeader from "components/Layout/PageLayout/PageHeader/PageHeader"
import PageLayout from "components/Layout/PageLayout/PageLayout"
import useCompanyHybridRules from "graphql/hybridPolicy/useCompanyHybridRules"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import {
  DefaultHybridRule,
  GroupHybridRule,
  ManagerHybridRule,
  UserHybridRule,
} from "types"

import AddHybridRuleButton from "./AddHybridRuleButton/AddHybridRuleButton"
import UpdateHybridWorkRuleForm from "./AddHybridRuleButton/HybridRuleFormWrapper/HybridRuleFormWrapper"
import DefaultHybridWorkRule from "./DefaultHybridWorkRule/DefaultHybridWorkRule"
import EmptyRulesListMessage from "./EmptyRulesListMessage/EmptyRulesListMessage"
import GroupsHybridRules from "./GroupsHybridRules/GroupsHybridRules"
import ManagersHybridRules from "./ManagersHybridRules/ManagersHybridRules"
import UsersHybridRules from "./UsersHybridRules/UsersHybridRules"

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 100%;
`

export default function HybridWorkPolicy() {
  const t = useTranslate()

  const { companyHybridRules } = useCompanyHybridRules()

  const [selectedUserHybridRule, setSelectedUserHybridRule] = useState<
    UserHybridRule | undefined
  >(undefined)
  const [selectedManagerHybridRule, setSelectedManagerHybridRule] = useState<
    ManagerHybridRule | undefined
  >(undefined)
  const [selectedDefaultHybridRule, setSelectedDefaultHybridRule] = useState<
    DefaultHybridRule | undefined
  >(undefined)
  const [selectedGroupHybridRule, setSelectedGroupHybridRule] = useState<
    GroupHybridRule | undefined
  >(undefined)
  const updateSelectedUserHybridRule = (rule: UserHybridRule) => {
    setSelectedUserHybridRule(rule)
    setSelectedDefaultHybridRule(undefined)
    setSelectedGroupHybridRule(undefined)
    setSelectedManagerHybridRule(undefined)
  }
  const updateSelectedManagerHybridRule = (rule: ManagerHybridRule) => {
    setSelectedManagerHybridRule(rule)
    setSelectedUserHybridRule(undefined)
    setSelectedDefaultHybridRule(undefined)
    setSelectedGroupHybridRule(undefined)
  }
  const updateSelectedGroupHybridRule = (rule: GroupHybridRule) => {
    setSelectedGroupHybridRule(rule)
    setSelectedUserHybridRule(undefined)
    setSelectedDefaultHybridRule(undefined)
    setSelectedManagerHybridRule(undefined)
  }
  const updateSelectedCompanyHybridRule = (rule: DefaultHybridRule) => {
    setSelectedDefaultHybridRule(rule)
    setSelectedUserHybridRule(undefined)
    setSelectedGroupHybridRule(undefined)
    setSelectedManagerHybridRule(undefined)
  }
  const closeForm = () => {
    setSelectedUserHybridRule(undefined)
    setSelectedDefaultHybridRule(undefined)
    setSelectedGroupHybridRule(undefined)
    setSelectedManagerHybridRule(undefined)
  }

  const formOpen =
    selectedDefaultHybridRule !== undefined ||
    selectedUserHybridRule !== undefined ||
    selectedManagerHybridRule !== undefined ||
    selectedGroupHybridRule !== undefined

  const breadcrumbs = [
    {
      name: t("Admin"),
      path: "/admin",
    },
    {
      name: t("Hybrid work policy"),
      path: "/admin/hybrid-policy",
    },
  ]

  return (
    <>
      <PageLayout>
        <PageHeader>
          <AdminPageTitle
            title={t("Hybrid work policy")}
            breadcrumbs={breadcrumbs}
          />
          <AddHybridRuleButton />
        </PageHeader>
        <PageContent>
          <Body>
            <EmptyRulesListMessage companyHybridRules={companyHybridRules} />
            <DefaultHybridWorkRule
              defaultHybridRule={companyHybridRules.default}
              setSelectedDefaultHybridRule={updateSelectedCompanyHybridRule}
            />
            <ManagersHybridRules
              managerHybridRules={companyHybridRules.managers}
              setSelectedManagerHybridRule={updateSelectedManagerHybridRule}
            />
            <GroupsHybridRules
              groupHybridRules={companyHybridRules.groups}
              setSelectedGroupHybridRule={updateSelectedGroupHybridRule}
            />
            <UsersHybridRules
              userHybridRules={companyHybridRules.users}
              setSelectedUserHybridRule={updateSelectedUserHybridRule}
            />
          </Body>
        </PageContent>
      </PageLayout>
      {formOpen && (
        <UpdateHybridWorkRuleForm
          initialUserHybridRule={selectedUserHybridRule}
          initialManagerHybridRule={selectedManagerHybridRule}
          initialGroupHybridRule={selectedGroupHybridRule}
          initialDefaultHybridRule={selectedDefaultHybridRule}
          onCancel={closeForm}
        />
      )}
    </>
  )
}
