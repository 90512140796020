import { ApolloError, gql, useQuery } from "@apollo/client"
import { GroupHybridRuleWithScore } from "types"
import UTCDate from "utils/UTCDate"

import { GroupHybridRuleFragment } from "./fragments/hybridPolicyFragments"

export const GROUP_HYBRID_WORK_RULE_QUERY = gql`
  ${GroupHybridRuleFragment}
  query groupHybridRule($groupId: ID!, $from: String, $to: String) {
    groupHybridRule(groupId: $groupId, from: $from, to: $to) {
      ...GroupHybridRuleFragment
    }
  }
`

export default function useGroupHybridRule(
  groupId: string,
  from?: UTCDate,
  to?: UTCDate
): {
  loading: boolean
  error?: ApolloError
  groupHybridRule: GroupHybridRuleWithScore | undefined
} {
  const { loading, error, data } = useQuery<{
    groupHybridRule: Omit<GroupHybridRuleWithScore, "maxDays"> & {
      maxSlots: number
    }
  }>(GROUP_HYBRID_WORK_RULE_QUERY, {
    variables: {
      groupId,
      from: from ? `${from.getTime()}` : undefined,
      to: to ? `${to.getTime()}` : undefined,
    },
    fetchPolicy: "cache-and-network",
  })

  const groupHybridRule = data?.groupHybridRule
    ? {
        ...data.groupHybridRule,
        maxDays: data.groupHybridRule.maxSlots / 2,
        weeksScores: data.groupHybridRule.weeksScores.map((weekScore) => ({
          ...weekScore,
          days: weekScore.days.map((day) => ({
            ...day,
            date: new UTCDate(Number(day.date)),
          })),
        })),
        monthsScores: data.groupHybridRule.monthsScores.map((monthScore) => ({
          ...monthScore,
          date: new UTCDate(Number(monthScore.date)),
        })),
      }
    : undefined

  return {
    groupHybridRule,
    loading,
    error,
  }
}
