import useMeetingRoomEvents from "graphql/meetingRooms/useMeetingRoomEvents"
import useUser from "graphql/users/useUser"
import MeetingRoomEventForm from "pages/MeetingRoomsPage/MeetingRoomEventForm/MeetingRoomEventForm"
import MeetingRoomEventPopup from "pages/MeetingRoomsPage/MeetingRoomEventPopup/MeetingRoomEventPopup"
import { Row } from "pages/MeetingRoomsPage/SharedLayoutComponents"
import { getTimeTableHours } from "pages/MeetingRoomsPage/utils"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { IntegrationEvent, MeetingRoom, UpsertEventInput } from "types"
import { colors } from "ui"
import { MeetingRoom as MeetingRoomIcon } from "ui/icons"
import { getEmptyEventInput } from "utils/events"
import LocalDate from "utils/LocalDate"

import BookedItem from "./BookedItem/BookedItem"
import { NotBookedItem } from "./NotBookedItem/NotBookedItem"

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  gap: 8px;
`

const Capacity = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 8px;
  background: ${colors.grey4};
  border: solid 1px ${colors.grey3};
  border-radius: 100vh;
  margin-left: auto;
`

const List = styled.ul<{ size: number }>`
  display: grid;
  ${({ size }) => `
    grid-template-columns: repeat(${size}, 1fr);
  `}
`

interface PropsType {
  meetingRoom: MeetingRoom
  meetingRooms: MeetingRoom[]
  from: LocalDate
  to: LocalDate
  minHour: LocalDate
  maxHour: LocalDate
  createLoadedEvent: (id: string) => CustomEvent<{
    loaded: string
  }>
}

export default function MeetingRoomItem({
  meetingRoom,
  meetingRooms,
  from,
  to,
  minHour,
  maxHour,
  createLoadedEvent,
}: PropsType) {
  const { user: me } = useUser()
  const { meetingRoomWithEvents, loading } = useMeetingRoomEvents(
    meetingRoom.id,
    from,
    to
  )

  const [selectedEventToShow, setSelectedEventToShow] = useState<
    IntegrationEvent | undefined
  >(undefined)
  const [selectedEventToMutate, setSelectedEventToMutate] = useState<
    UpsertEventInput | undefined
  >(undefined)

  const hours = getTimeTableHours(minHour, maxHour)

  useEffect(() => {
    if (!loading) {
      document.dispatchEvent(createLoadedEvent(meetingRoom.id))
    }
    // eslint-disable-next-line
  }, [loading])

  return (
    <Row>
      <Header>
        <MeetingRoomIcon />
        {meetingRoom.name}
        <Capacity>{meetingRoom.capacity}</Capacity>
      </Header>
      <List size={hours.length}>
        {hours.map((h) => {
          const allBooked = meetingRoomWithEvents?.events.filter((e) => {
            if (e.isAllDay) return true // NOT WORKING with google maybe force it at back end ?????
            return (
              h.getHours() >= e.from.getHours() &&
              h.getHours() <=
                (e.to.getMinutes() === 0
                  ? e.to.getHours() - 1
                  : e.to.getHours())
            )
          })
          if (!allBooked || allBooked.length === 0)
            return (
              <NotBookedItem
                key={`${meetingRoom.id}-${h.getTime()}`}
                data-content={""}
                duration={60}
                from={0}
                onClick={() =>
                  setSelectedEventToMutate(
                    getEmptyEventInput(h, false, meetingRoom.id, me)
                  )
                }
              >
                <div />
              </NotBookedItem>
            )
          const booked = allBooked[allBooked.length - 1]

          return (
            <BookedItem
              key={`${meetingRoom.id}-${h.getTime()}`}
              booked={booked}
              meetingRoom={meetingRoom}
              slotHour={h}
              setSelectedEventToShow={setSelectedEventToShow}
            />
          )
        })}
      </List>
      {selectedEventToMutate && (
        <MeetingRoomEventForm
          event={selectedEventToMutate}
          meetingRooms={meetingRooms}
          onClose={() => setSelectedEventToMutate(undefined)}
        />
      )}
      {selectedEventToShow && (
        <MeetingRoomEventPopup
          meetingRoom={meetingRoom}
          meetingRoomEvent={selectedEventToShow}
          meetingRooms={meetingRooms}
          onClose={() => setSelectedEventToShow(undefined)}
        />
      )}
    </Row>
  )
}
