import React from "react"
import styled from "styled-components/macro"
import { P16, P16Bold } from "ui"
import { Cancel } from "ui/icons"
import mediaQueries from "ui/mediaQueries"
import getColorFromStatus from "utils/getColorFromStatus"

import colors from "../colors"

interface PrimaryChipButtonProps {
  active?: boolean
  fullWidth?: boolean
  subject?: string
  textColor?: string
}

export const PrimaryChipButton = styled.button<PrimaryChipButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 32px;
  gap: 8px;
  padding: 0 16px;
  width: fit-content;
  ${({ fullWidth }) => {
    if (fullWidth) return `width: 100%;`
  }}
  border-radius: 24px;
  background: ${colors.grey4};
  border: solid 1px ${colors.grey3};

  font-style: normal;
  font-weight: ${({ active }) => (active ? 600 : 400)};
  font-size: 16px;
  line-height: 20px;
  color: ${colors.black};
  white-space: nowrap;
  cursor: pointer;

  @media ${mediaQueries.isTouchable} {
    padding: 3px 12px;
  }

  &:hover {
    ${({ subject }) => {
      if (subject === "services")
        return `
      border-color: ${getColorFromStatus("office")};
      background: ${getColorFromStatus("office")}14;
      `
      if (subject === "off" || subject === "ooc")
        return `
        background: ${colors.greyGradient};
        border-color: ${colors.grey3};
      `
      if (subject)
        return `
      border-color: ${getColorFromStatus(subject)};
      background: ${getColorFromStatus(subject)}14;
      `
      return `
      background: ${colors.greyGradient};
      border-color: ${colors.grey3};
      `
    }}
  }

  ${({ subject, active }) => {
    if (active && subject === "services")
      return `
      border-color: ${getColorFromStatus("office")};
      background: ${getColorFromStatus("office")}14;
      `
    if (active && subject)
      return `
      border-color: ${getColorFromStatus(subject)};
      background: ${getColorFromStatus(subject)}14;
      `
  }}

  &[role=div] {
    background: ${colors.grey4};
    border: solid 1px ${colors.grey3};
    pointer-events: none;

    &:hover {
      border-color: ${colors.grey3};
    }
  }

  &:disabled {
    cursor: auto;
    background: ${colors.grey4};
    border: solid 1px ${colors.grey3};
    color: ${colors.grey2};

    p {
      color: inherit;
    }
  }
`

const ChipButtonIconSpan = styled.span`
  display: flex;
  svg {
    height: 24px;
    width: 24px;
  }
`

export const ChipButtonMoreTextSpan = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  margin-left: auto;

  &:hover {
    text-decoration: underline;
  }
`

const RemovableChipButtonStyled = styled(PrimaryChipButton)`
  justify-content: space-between;
  gap: 8px;

  @media ${mediaQueries.isTouchable} {
    padding: 3px 8px 3px 12px;
  }

  ${({ subject }) => {
    if (subject)
      return `
      &:hover{
        border-color: ${getColorFromStatus(subject)};
        background: ${getColorFromStatus(subject)}14;
      }
      `
  }}

  p {
    white-space: nowrap;
  }

  div {
    display: flex;
    align-items: center;

    svg {
      width: 22px;
      height: 22px;
      stroke: ${colors.grey2};
    }
  }
`

const LocationChipButtonStyled = styled(PrimaryChipButton)`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 3px 16px;

  background: ${colors.grey2}14;
  border-color: ${colors.grey3};

  &:hover {
    border-color: ${getColorFromStatus("office")};
    background: ${getColorFromStatus("office")}14;
  }

  &:focus {
    background: ${colors.grey2}14;
    border-color: ${colors.grey3};
  }

  ${({ subject, active }) => {
    if (active && subject)
      return `
      border-color: ${getColorFromStatus(subject)};
      background: ${getColorFromStatus(subject)}14;

      &:focus {
        border-color: ${getColorFromStatus(subject)};
        background: ${getColorFromStatus(subject)}14;
      }
      `
    if (active)
      return `border-color: ${getColorFromStatus("office")};
    background: ${getColorFromStatus("office")}14;`
  }}

  border-radius: 50px;

  transition: all 200ms ease-in-out;
`

interface PropsType {
  children?: React.ReactNode
  icon?: JSX.Element
  moreText?: string
  active?: boolean
  fullWidth?: boolean
  subject?: string
  textColor?: string
  onMore?: () => void
  id?: string

  // Button props
  disabled?: boolean
  role?: React.AriaRole
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

export function ChipButton({
  children,
  icon,
  moreText,
  active,
  fullWidth,
  subject,
  textColor,
  id,
  role = "button",
  onMore,
  ...rest
}: PropsType) {
  return (
    <PrimaryChipButton
      active={Boolean(active)}
      fullWidth={fullWidth}
      subject={subject}
      id={id}
      role={role}
      {...rest}
    >
      {icon && <ChipButtonIconSpan>{icon}</ChipButtonIconSpan>}
      {active ? (
        <P16Bold style={{ color: textColor }}>{children}</P16Bold>
      ) : (
        <P16>{children}</P16>
      )}
      {moreText && (
        <ChipButtonMoreTextSpan
          onClick={(e) => {
            e.stopPropagation()
            if (onMore) onMore()
          }}
        >
          {moreText}
        </ChipButtonMoreTextSpan>
      )}
    </PrimaryChipButton>
  )
}

interface RemovableChipButtonPropsType extends PropsType {
  onRemove?: () => void
}

export function RemovableChipButton({
  children,
  active,
  textColor,
  onClick,
  onRemove,
  subject,
  id,
  icon,
  ...rest
}: RemovableChipButtonPropsType) {
  return (
    <RemovableChipButtonStyled
      active={Boolean(active)}
      subject={subject}
      onClick={onClick}
      id={id}
      {...rest}
    >
      {icon}
      {active ? (
        <P16Bold style={{ color: textColor }}>{children}</P16Bold>
      ) : (
        <P16>{children}</P16>
      )}
      <div
        onClick={(e) => {
          e.stopPropagation()
          if (onRemove) onRemove()
        }}
      >
        <Cancel />
      </div>
    </RemovableChipButtonStyled>
  )
}

export function LocationChipButton({ active, children, ...rest }: PropsType) {
  return (
    <LocationChipButtonStyled active={active ? true : false} {...rest}>
      {children}
    </LocationChipButtonStyled>
  )
}
