import { gql } from "@apollo/client"

export const SlotFragment = gql`
  fragment SlotFragment on Slot {
    id
    value
    date
    userId
    locationId
    label
    guests
    guestsEmails
  }
`
