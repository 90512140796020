import { makeVar, useReactiveVar } from "@apollo/client"
import { App, URLOpenListenerEvent } from "@capacitor/app"
import { Browser } from "@capacitor/browser"
import { Capacitor } from "@capacitor/core"
import { LinearProgress } from "@mui/material"
import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import useSigninProviderId from "graphql/auth/useSigninProviderId"
import useTranslate from "intl/useTranslate"
import React, { useCallback, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Button } from "ui"
import { loginWithToken } from "utils/firebase"
import { loginWithProvider } from "utils/firebase"
import loginWithOIDC from "utils/loginWithOIDC"

import useSigninWithOIDCCode from "./useSigninWithOIDCCode"

interface SignInState {
  loading: boolean
  error: Error | null
}

/*
 Using reactive vars here instead of local state
 because the component gets unmounted and re-mounted
 as part of the firebase sign-in sequence.
*/
const signInState = makeVar<SignInState>({ loading: false, error: null })

interface SignInProps {
  domain: string
  providerId: string | null
}

export default function SignInWithDomainButton({
  domain,
  providerId: providedProviderId,
}: SignInProps) {
  const isMobileApp = Capacitor.isNativePlatform()
  const history = useHistory()
  useEffect(() => {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      const [, route] = event.url.split("offishall://")
      if (route.split("/")[0] === "ssoNativeLogin") {
        Browser.close()
        const token = route.split("/")[1]
        if (token) {
          loginWithToken(token)
            .then(() => {
              // success
            })
            .catch((e: any) => {
              console.error("Error while login:", e)
              history.replace("/")
            })
        }
      }
    })
    return () => {
      App.removeAllListeners()
    }
  }, [history])
  const t = useTranslate()
  const {
    loading: loadingSG,
    error: errorSG,
    providerId,
  } = useSigninProviderId(domain, providedProviderId)

  useSigninWithOIDCCode(providerId)

  const { loading, error } = useReactiveVar(signInState)

  const onClickSigning = useCallback(async () => {
    if (providerId.startsWith("saml.") && isMobileApp) {
      const isOkta = providerId.startsWith("saml.okta")
      if (isOkta) {
        const res = await fetch(
          process.env.REACT_APP_API + "/auth/saml/ssoUrl",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              providerId,
            }),
          }
        )
        if (!res.ok) {
          throw new Error("Error while fetching SSO URL")
        }
        const { ssoRedirectUrl } = await res.json()
        await Browser.open({
          url: ssoRedirectUrl,
        })
      }
    } else {
      try {
        signInState({ loading: true, error: null })
        if (providerId.startsWith("oidc.")) {
          return await loginWithOIDC(providerId)
        } else {
          await loginWithProvider(providerId)
        }
        signInState({ loading: false, error: null })
      } catch (e) {
        console.error("Error on sign-in: ", e)
        signInState({ loading: false, error: e })
      }
    }
  }, [providerId, isMobileApp])

  if (loadingSG || errorSG || error) {
    return (
      <LoaderOrErrorComponent
        loading={loadingSG}
        error={errorSG || error}
        marginTop={0}
      />
    )
  }

  return (
    <>
      <Button
        disabled={loading || providedProviderId !== null}
        onClick={onClickSigning}
        fullWidth
      >
        {t("Sign in")}
      </Button>
      {(loading || providedProviderId !== null) && (
        <LinearProgress style={{ marginTop: 5 }} />
      )}
    </>
  )
}
