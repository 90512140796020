import useTranslate from "intl/useTranslate"
import React, { useEffect, useState } from "react"
import { CompanySettingEnum } from "types"
import { DropDown } from "ui"

interface PropsType {
  setting: CompanySettingEnum
  onChange: (value: string) => void
}

export default function ManageSettingsEnum({ setting, onChange }: PropsType) {
  const t = useTranslate()
  const [value, setValue] = useState(setting.valueAsEnum)
  const [dropDownTitle, setDropDownTitle] = useState(t(setting.valueAsEnum))

  useEffect(() => {
    if (value !== setting.valueAsEnum) {
      onChange(value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <>
      <DropDown
        title={dropDownTitle}
        options={[
          ...setting.possibleValues.map((value) => ({
            name: t(value),
            value: value,
          })),
        ]}
        onSelect={(e) => {
          setValue(e.value)
          setDropDownTitle(e.name)
        }}
      />
    </>
  )
}
