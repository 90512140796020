import { ActiveLocationChipButtonWithBreadCrumbs } from "components/LocationComponents/Buttons/ActiveLocationChipButtonWithBreadCrumbs/ActiveLocationChipButtonWithBreadCrumbs"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useLocationsAt from "graphql/locations/useLocationsAt"
import useUser from "graphql/users/useUser"
import React, { useLayoutEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { useTeamsAppContext } from "TeamsApp/TeamsAppContext"
import { LocationsAtInput } from "types"
import { mediaQueries } from "ui"
import LocationTree from "utils/LocationTree"

import HiddenActiveLocationsButton from "./HiddenActiveLocationsButton/HiddenActiveLocationsButton"
import UsersAtFilters from "./UsersAtFilters/UsersAtFilters"

const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  min-height: 40px;
  max-width: 100%;
  padding: 2px 16px;
  gap: 4px;

  @media ${mediaQueries.isMobile} {
    padding: 0;
  }
`

const List = styled.ul`
  display: flex;
  align-items: center;
  gap: 8px;
  overflow-x: hidden;

  @media ${mediaQueries.isMobile} {
    max-width: 100%;
    gap: 4px;
  }
`

const Item = styled.li`
  button {
    grid-template-columns: repeat(4, min-content);
    p,
    span {
      text-overflow: unset;
      white-space: normal;
      overflow: unset;
    }
  }

  &.list-item--hidden {
    opacity: 0;
    pointer-events: none;

    &:first-child {
      opacity: 1;
      pointer-events: all;
    }
  }

  @media ${mediaQueries.isTouchable} {
    padding: 0;
    button {
      height: 24px;
    }
  }
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

function ActiveLocationsListContent({
  locations,
  onLocationClick,
}: {
  locations: LocationTree[]
  onLocationClick: (location: LocationTree) => void
}) {
  const listRef = useRef<HTMLUListElement>(null)
  const [listRightEndPosition, setListRightEndPosition] = useState<
    number | undefined
  >(undefined)
  const [hiddenLocations, setHiddenLocations] = useState<LocationTree[]>([])
  const { activeLocation, setActiveLocation } = usePlanningContext()
  const { isTeamsApp } = useTeamsAppContext()

  const filterHiddenItems = (
    el: HTMLLIElement | null,
    location: LocationTree
  ) => {
    if (el === null) return
    if (listRightEndPosition) {
      const position =
        el.getBoundingClientRect().x + el.getBoundingClientRect().width

      if (position < listRightEndPosition) return
      if (hiddenLocations.find((l) => l.id === location.id) !== undefined)
        return
      else setHiddenLocations((prev) => [...prev, location])
    }
  }

  useLayoutEffect(() => {
    if (listRef.current === null) return
    if (listRightEndPosition !== undefined) return
    setListRightEndPosition(
      listRef.current.getBoundingClientRect().x +
        listRef.current.getBoundingClientRect().width
    )
  }, [listRef, listRightEndPosition, hiddenLocations.length])

  return (
    <Layout>
      <List ref={listRef} data-component="planning-active-locations-list">
        {locations.map((location) => {
          const isActive = location.id === activeLocation.location?.id
          return (
            <Item
              ref={(el) => {
                filterHiddenItems(el, location)
              }}
              key={`loc-${location.id}`}
              className={
                hiddenLocations.find((l) => l.id === location.id) !== undefined
                  ? "list-item--hidden"
                  : ""
              }
            >
              <ActiveLocationChipButtonWithBreadCrumbs
                location={location}
                active={isActive}
                showBreadCrumbs
                onClick={() => {
                  if (!isTeamsApp) {
                    onLocationClick(location)
                  }
                }}
                onMouseEnter={() => {
                  if (!activeLocation.persist) {
                    setActiveLocation({
                      location,
                      persist: false,
                      triggeredFrom: activeLocation.triggeredFrom,
                    })
                  }
                }}
                onMouseLeave={() => {
                  if (!activeLocation.persist) {
                    setActiveLocation({
                      location: null,
                      persist: false,
                      triggeredFrom: "planning",
                    })
                  }
                }}
              />
            </Item>
          )
        })}
      </List>
      <Actions>
        <HiddenActiveLocationsButton
          locations={hiddenLocations}
          onSelect={onLocationClick}
        />
        {!location.pathname.startsWith("/planning/location") && (
          <UsersAtFilters />
        )}
      </Actions>
    </Layout>
  )
}

function useGoToLocationPlanning() {
  const history = useHistory()
  return (location: LocationTree) =>
    history.push(`/planning/location/${location.id}`)
}

function LocationsListFromQuery({ query }: { query: LocationsAtInput }) {
  const { activeLocation, setActiveLocation } = usePlanningContext()
  const { locations } = useLocationsAt(query, activeLocation.persist)
  const goToLocationPlanning = useGoToLocationPlanning()
  const onActiveLocationClick = (location: LocationTree) => {
    goToLocationPlanning(location)
    setActiveLocation({
      location,
      persist: false,
      triggeredFrom: "active-locations-list",
    })
  }

  return (
    <ActiveLocationsListContent
      locations={locations}
      onLocationClick={onActiveLocationClick}
    />
  )
}

export function ActiveLocationsList() {
  const { usersAtQuery } = usePlanningContext()
  const { user: me } = useUser()

  const getQueryIncludingMeInUserIds = (): LocationsAtInput =>
    ({
      ...usersAtQuery,
      userIds:
        usersAtQuery.userIds && me
          ? [...usersAtQuery.userIds.filter((id) => id !== me.id), me.id]
          : usersAtQuery.userIds,
    } as any as LocationsAtInput)

  if (usersAtQuery.from && usersAtQuery.to) {
    return <LocationsListFromQuery query={getQueryIncludingMeInUserIds()} />
  }
  // No dates are set, we cannont deduce active locations
  return <Layout />
}

export function LocationsListFromCategory({
  category,
}: {
  category: LocationTree
}) {
  const goToLocationPlanning = useGoToLocationPlanning()
  return (
    <ActiveLocationsListContent
      locations={category.children}
      onLocationClick={(location) => goToLocationPlanning(location)}
    />
  )
}
