import React from "react"
import { CompanyIntegration } from "types"

import GoogleIntegration from "./GoogleIntegration/GoogleIntegration"
import OutlookIntegration from "./OutlookIntegration/OutlookIntegration"

function getUniqueIntegration(companyIntegrations: CompanyIntegration[]) {
  let integration: CompanyIntegration | undefined = undefined

  integration = companyIntegrations.find(({ name }) => name === "google-events")
  if (integration)
    return {
      name: "google-events",
      Component: GoogleIntegration,
      enabledByDefault: true,
      integration,
    }

  integration = companyIntegrations.find(
    ({ name }) => name === "microsoft-outlook"
  )
  if (integration)
    return {
      name: "microsoft-outlook",
      Component: OutlookIntegration,
      enabledByDefault: true,
      integration,
    }

  return
}

interface PropsType {
  companyIntegrations: CompanyIntegration[]
}

export default function CompanyWideIntegrations({
  companyIntegrations,
}: PropsType) {
  const uniqueIntegration = getUniqueIntegration(companyIntegrations)

  if (!uniqueIntegration) return <></>

  const { Component, integration } = uniqueIntegration

  return <Component integration={integration} />
}
