import React from "react"
import styled from "styled-components"
import { Button, Dialog, P16 } from "ui"

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px;
`

interface PropsType {
  onClose: () => void
  open: boolean
  error: string
  isError?: boolean
}

export default function ErrorDialog({
  onClose,
  open,
  error,
  isError = true,
}: PropsType) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={isError ? "Error" : ""}
      rightButton={<Button onClick={onClose}>OK</Button>}
    >
      <Content>
        <P16>{error}</P16>
      </Content>
    </Dialog>
  )
}
