import { gql } from "@apollo/client"

export const AttendeeFragment = gql`
  fragment AttendeeFragment on Attendee {
    email
    status
  }
`

export const IntegrationEventFragment = gql`
  ${AttendeeFragment}

  fragment IntegrationEventFragment on IntegrationEvent {
    integration
    id
    name
    description
    location
    from
    to
    organizer {
      ...AttendeeFragment
    }
    attendees {
      ...AttendeeFragment
    }
    isPrivate
    isAllDay
    flags
    roomId
    initiator
  }
`
