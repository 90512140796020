type TransitionType = "generic" | "toggleActiveLocation" | "fade"

const transitions: Record<TransitionType, string> = {
  generic: "all 150ms ease-in-out",
  // toggleActiveLocation also in App.css
  // we need to keep them in sync
  toggleActiveLocation: `
  transition-property: color, border-color, background-color;
  transition-delay: var(--toggle-active-location-in);
  transition-duration: var(--toggle-active-location-dt);
  transition-timing-function: ease-in-out;
  `,
  fade: `
  transition-property: color, border-color, background-color, stroke;
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;
  `,
}

export default transitions
