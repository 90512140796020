import useTranslate from "intl/useTranslate"
import UpdateCategoryDialog from "pages/AdminPage/AdminLocationsPage/AddCategoryButton/UpdateCategoryDialog/UpdateCategoryDialog"
import React, { useState } from "react"
import { Button } from "ui"

export default function AddCategoryButton() {
  const t = useTranslate()

  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)

  return (
    <>
      <Button secondary onClick={() => setIsEditModalOpen(true)}>
        {t("Add a zone")}
      </Button>
      {isEditModalOpen && (
        <UpdateCategoryDialog
          open={isEditModalOpen}
          categoryToMutate={undefined}
          onClose={() => setIsEditModalOpen(false)}
        />
      )}
    </>
  )
}
