import ManagerSearchControlled from "components/ManagerSearch/ManagerSearchControlled"
import useTranslate from "intl/useTranslate"
import React, { useRef, useState } from "react"
import styled from "styled-components"
import { Button, colors } from "ui"
import isEmailValid from "utils/isEmailValid"

import { CSVRecap } from "./CSVImporter/CSVRecap"
import EmailInputText from "./EmailInputText/EmailInputText"
import UsersToBeAddedList from "./UsersToBeAddedList/UsersToBeAddedList"

const Container = styled.div<{ alreadyOccupiedSpace: number }>`
  width: 500px;
  overflow-y: hidden;
  ul:nth-child(2) {
    ${({ alreadyOccupiedSpace }) => `
      max-height: calc(100vh - ${alreadyOccupiedSpace}px);
      `}
  }
`

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
`

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid ${colors.grey3};
  padding-top: 22px;
  margin-top: 12px;
`

interface UserInputType {
  userEmail: string
  managerEmail?: string
  error?: boolean
}

interface PropsType {
  onClose: () => void
}

export default function FormAddEmployees({ onClose }: PropsType) {
  const t = useTranslate()
  const [showRecap, setShowRecap] = useState(false)
  const [userInput, setUserInput] = useState<UserInputType>({ userEmail: "" })
  const [users, setUsers] = useState<UserInputType[]>([])
  const [value, setValue] = useState("")
  const userEmailInputRef = useRef<HTMLInputElement>(null)
  const [alreadyOccupiedSpace, setAlreadyOccupiedSpace] = useState(0)

  const computeCanAddUser = () => {
    if (userInput.userEmail === "") {
      return false
    }
    if (userInput.error === true) {
      return false
    }
    if (users.find((u) => u.userEmail === userInput.userEmail)) {
      return false
    }
    return true
  }

  const computeCanProceedToNextStep = () => {
    if (users.length > 0) return true
    else if (isEmailValid(userInput.userEmail)) return true
    return false
  }

  const removeUser = (user: UserInputType) => {
    setUsers(users.filter((u) => u.userEmail !== user.userEmail))
  }

  const updateUsers = (user: UserInputType) => {
    // TODO show error message email already used
    setUsers((prev) => [
      ...prev.filter((u) => u.userEmail !== user.userEmail),
      user,
    ])
    setUserInput({ userEmail: "" })
    userEmailInputRef.current?.focus()
  }

  if (showRecap) {
    return (
      <CSVRecap
        onPrevious={() => setShowRecap(false)}
        onClose={onClose}
        users={users.map((u) => [u.userEmail, u.managerEmail ?? ""])}
      />
    )
  }
  return (
    <Container alreadyOccupiedSpace={alreadyOccupiedSpace}>
      <Inputs
        ref={(el) => {
          if (!el) return
          const { y, height } = el.getBoundingClientRect()
          setAlreadyOccupiedSpace(y + height + 32)
        }}
      >
        <EmailInputText
          inputRef={userEmailInputRef}
          label={t("New user email")}
          value={userInput.userEmail}
          initialValue={userInput.userEmail}
          autoFocus
          onChange={(userEmail, error) => {
            setUserInput((prev) => ({ ...prev, userEmail, error }))
          }}
          onAddEmail={() => {
            setUsers((prev) => [
              ...prev.filter(
                ({ userEmail }) => userEmail !== userInput.userEmail
              ),
              userInput,
            ])
          }}
        />

        <ManagerSearchControlled
          placeholder={t("search manager users")}
          value={value}
          setValue={setValue}
          updateManagerEmail={(managerEmail) => {
            setUserInput((prev) => ({ ...prev, managerEmail }))
            setValue(managerEmail)
          }}
        />

        <UsersToBeAddedList users={users} removeUser={removeUser} />

        <Actions>
          <Button
            secondary
            onClick={() => {
              if (!computeCanAddUser()) return
              updateUsers(userInput)
              setValue("")
              setTimeout(() => {
                userEmailInputRef.current?.focus()
              }, 200)
            }}
            disabled={!computeCanAddUser()}
          >
            {t("add new user")}
          </Button>

          <Button
            onClick={() => {
              if (isEmailValid(userInput.userEmail)) {
                if (!users.find((u) => u.userEmail === userInput.userEmail)) {
                  setUsers([...users, userInput])
                }
              }
              setShowRecap(true)
            }}
            disabled={!computeCanProceedToNextStep()}
          >
            {t("Next")}
          </Button>
        </Actions>
      </Inputs>
    </Container>
  )
}
