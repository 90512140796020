import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function Menu({ stroke, strokeWidth, ...rest }: SvgIconProps) {
  return (
    <SvgIcon
      strokeWidth={strokeWidth ?? 1.5}
      stroke={stroke ? stroke : "currentColor"}
      {...rest}
    >
      <path
        d="M3 5H21"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 12H21"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 19H21"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}
