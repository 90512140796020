import FavoriteCheckbox from "components/FavoriteCheckbox"
import DeleteLocationButton from "components/LocationComponents/Buttons/DeleteLocationButton/DeleteLocationButton"
import EditLocationButton from "components/LocationComponents/Buttons/EditLocationButton/EditLocationButton"
import useTranslate from "intl/useTranslate"
import SlotsEditorLocation from "pages/PlanningPage/components/SlotsEditor/SlotsEditorContent/SlotsEditorLocation"
import React, { useState } from "react"
import styled from "styled-components"
import {
  Equipment,
  EquipmentAvailability,
  SlotOccupationForLocation,
  WorkingMode,
} from "types"
import { LU12, P16 } from "ui"
import { ArrowDownFill, FloorPlan } from "ui/icons"
import LocationTree, { findOldestParentWithFloorPlan } from "utils/LocationTree"

import { ContentList } from "./components/ContentList"
import { Header } from "./components/Header"

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`

interface PropsType {
  category: LocationTree
  companyLocations: LocationTree[]
  equipments: Equipment[]
  equipmentsAvailability: EquipmentAvailability[]
  withActions?: boolean
  showFavoriteCheckbox?: boolean
  disabled?: boolean
  occupations: SlotOccupationForLocation[]
  guestIds: string[]
  floorPlan?: {
    selectedFloorPlan?: LocationTree
    selectedArea?: LocationTree
    updateSelectedFloorPlan: (location: LocationTree) => void
    updateSelectedVisualArea: (visualArea: LocationTree) => void
  }

  selectedLocationId?: string
  saveWorkingMode: (workingMode: WorkingMode) => void | Promise<void>
}

export default function SlotsEditorCategoryItem({
  category,
  companyLocations,
  equipments,
  equipmentsAvailability,
  withActions,
  showFavoriteCheckbox,
  disabled,
  occupations,
  floorPlan,
  selectedLocationId,
  guestIds,
  saveWorkingMode,
}: PropsType) {
  const t = useTranslate()

  const olderParentWithFloorPlan = findOldestParentWithFloorPlan(
    companyLocations,
    category
  )
  const showPlan =
    floorPlan &&
    (category.floorPlanImageUrl !== null ||
      olderParentWithFloorPlan?.floorPlanImageUrl)
  const [isCollapseOpen, setIsCollapseOpen] = useState<boolean>(false)

  return (
    <Layout>
      <Header
        canClick={floorPlan ? true : false}
        onClick={(e) => {
          e.preventDefault()
          setIsCollapseOpen((prev) => !prev)
        }}
      >
        <ArrowDownFill
          className={
            isCollapseOpen ? "arrow-btn" : "arrow-btn arrow-btn--turned"
          }
        />

        <LU12 className="title">{category.name}</LU12>

        <div className="actions">
          <>
            {showPlan && (
              <FloorPlan
                onClick={(e) => {
                  e.stopPropagation()
                  if (!floorPlan) return

                  floorPlan.updateSelectedFloorPlan(category)
                  floorPlan.updateSelectedVisualArea(category)
                }}
              />
            )}
            {showFavoriteCheckbox && (
              <FavoriteCheckbox
                type="location"
                itemId={category.id}
                disabled={disabled}
              />
            )}
          </>
          {withActions && (
            <>
              <DeleteLocationButton location={category} disabled={disabled} />
              <EditLocationButton location={category} disabled={disabled} />
            </>
          )}
        </div>
        <P16 className="more-text">{`${category.children.length} ${t(
          category.children.length > 1 ? "Places" : "Place"
        ).toLowerCase()}`}</P16>
      </Header>
      {isCollapseOpen && (
        <ContentList>
          {category.children
            .filter((c) => !c.isCategory)
            .map((location) => (
              <SlotsEditorLocation
                key={`cat-${category.parentId}-loc-${location.id}`}
                location={location}
                companyLocations={companyLocations}
                occupations={occupations}
                equipments={equipments}
                equipmentsAvailability={equipmentsAvailability}
                floorPlan={floorPlan}
                showFavoriteCheckbox={showFavoriteCheckbox}
                saveWorkingMode={saveWorkingMode}
                isSelected={selectedLocationId === location.id}
                guestIds={guestIds}
              />
            ))}
          {category.children
            .filter((c) => c.isCategory)
            .map((child) => (
              <SlotsEditorCategoryItem
                key={`parent-${category.id}-cat-${child.id}`}
                category={child}
                companyLocations={companyLocations}
                occupations={occupations}
                floorPlan={floorPlan}
                equipments={equipments}
                equipmentsAvailability={equipmentsAvailability}
                saveWorkingMode={saveWorkingMode}
                showFavoriteCheckbox={showFavoriteCheckbox}
                selectedLocationId={selectedLocationId}
                guestIds={guestIds}
              />
            ))}
        </ContentList>
      )}
    </Layout>
  )
}
