import useCompanyFlags from "hooks/useCompanyFlags"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { Group, UserWithTeam } from "types"
import { colors, P16 } from "ui"
import { Cancel, HomeUser } from "ui/icons"
import getTeamDisplayName from "utils/getTeamDisplayName"

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  padding: 8px 16px;
`

const Item = styled.li`
  display: flex;
  align-items: center;
  gap: 4px;
  border: solid 1px ${colors.grey3};
  border-radius: 100vh;
  background: ${colors.grey4};
  padding: 4px 8px;
  max-width: 25ch;
  cursor: pointer;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover,
  &.search-group-item--selected {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)),
      #fafafa;
  }

  svg:first-child {
    stroke: ${colors.black};
  }

  svg {
    stroke: ${colors.grey2};
    fill: ${colors.grey2};
    width: 20px;
    height: 20px;

    &:hover {
      stroke: ${colors.grey1};
      fill: ${colors.grey1};
    }
  }
`

interface SearchGroupItemPropsType {
  group: Group
  selected?: boolean
  onSelect: (group: Group) => void
  onDeselect: (group: Group) => void
}

function SearchGroupItem({
  group,
  selected,
  onSelect,
  onDeselect,
}: SearchGroupItemPropsType) {
  const t = useTranslate()
  const { companyFlags } = useCompanyFlags()

  return (
    <Item
      className={selected ? "search-group-item--selected" : ""}
      onClick={() => onSelect(group)}
    >
      {group.id === "myteam" || (group.id === "mymanagerteam" && <HomeUser />)}
      <P16>{getTeamDisplayName(group, companyFlags, t)}</P16>
      {selected && (
        <Cancel
          onClick={(e) => {
            e.stopPropagation()
            onDeselect(group)
          }}
        />
      )}
    </Item>
  )
}

interface PropsType {
  teams: UserWithTeam[]
  groups: Group[]
  selectedGroups: Group[]
  selectedTeams: UserWithTeam[]
  isEveryoneSelected: boolean
  onSelect: (group: Group) => void
  onSelectTeam: (team: UserWithTeam) => void
  onDeselect: (group: Group) => void
  onDeselectTeam: (team: UserWithTeam) => void
  setIsEveryoneSelected: (bool: boolean) => void
}

export default function GroupsTeamsList({
  teams,
  groups,
  selectedGroups,
  selectedTeams,
  isEveryoneSelected,
  onSelect,
  onSelectTeam,
  onDeselect,
  onDeselectTeam,
  setIsEveryoneSelected,
}: PropsType) {
  const t = useTranslate()

  return (
    <List>
      <Item
        className={isEveryoneSelected ? "search-group-item--selected" : ""}
        onClick={() => setIsEveryoneSelected(!isEveryoneSelected)}
      >
        <P16>{t("the whole company")}</P16>
        {isEveryoneSelected && (
          <Cancel onClick={() => setIsEveryoneSelected(!isEveryoneSelected)} />
        )}
      </Item>
      {groups.map((g) => {
        const selected = selectedGroups.find((sg) => sg.id === g.id)
        return (
          <SearchGroupItem
            key={`group-search-item-${g.id}`}
            group={g}
            selected={selected !== undefined}
            onSelect={onSelect}
            onDeselect={onDeselect}
          />
        )
      })}
      {teams.map((team, id) => {
        const selected = selectedTeams.find((st) => st.id === team.id)
        return (
          <Item
            key={id}
            className={selected ? "search-group-item--selected" : ""}
            onClick={() => onSelectTeam(team)}
          >
            <HomeUser />
            <P16>
              {team.teamName.includes("'s team")
                ? t("person's team", {
                    person: team.teamName.replace("'s team", ""),
                  })
                : team.teamName}
            </P16>
            {selected && (
              <Cancel
                onClick={(e) => {
                  e.stopPropagation()
                  onDeselectTeam(team)
                }}
              />
            )}
          </Item>
        )
      })}
    </List>
  )
}
