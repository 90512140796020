import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function VerifiedUser({
  stroke,
  strokeWidth,
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      strokeWidth={strokeWidth ?? 1.5}
      stroke={stroke ? stroke : "currentColor"}
      {...rest}
    >
      <path
        d="M2 20V19C2 15.134 5.13401 12 9 12"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.804 12.3136C16.4458 11.6089 17.5546 11.6089 18.1964 12.3136C18.5208 12.6698 18.987 12.8629 19.4682 12.8404C20.4203 12.7959 21.2044 13.58 21.1599 14.5321C21.1374 15.0133 21.3305 15.4795 21.6867 15.8039C22.3914 16.4457 22.3914 17.5545 21.6867 18.1963C21.3305 18.5207 21.1374 18.9869 21.1599 19.4681C21.2044 20.4202 20.4203 21.2043 19.4682 21.1598C18.987 21.1373 18.5208 21.3304 18.1964 21.6866C17.5546 22.3913 16.4458 22.3913 15.804 21.6866C15.4796 21.3304 15.0134 21.1373 14.5322 21.1598C13.5801 21.2043 12.796 20.4202 12.8405 19.4681C12.863 18.9869 12.6699 18.5207 12.3137 18.1963C11.609 17.5545 11.609 16.4457 12.3137 15.8039C12.6699 15.4795 12.863 15.0133 12.8405 14.5321C12.796 13.58 13.5801 12.7959 14.5322 12.8404C15.0134 12.8629 15.4796 12.6698 15.804 12.3136Z"
        fill="none"
      />
      <path
        d="M15.3633 17L16.4543 18.0909L18.6361 15.9091"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 12C11.2091 12 13 10.2091 13 8C13 5.79086 11.2091 4 9 4C6.79086 4 5 5.79086 5 8C5 10.2091 6.79086 12 9 12Z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}
