import { gql, useMutation } from "@apollo/client"
import { GET_USER_QUERY } from "graphql/users/useUser"

import { UserIntegrationFragment } from "./fragments/UserIntegrationFragments"
import updateCache from "./updateUserIntegrationCache"

const MUTATION = gql`
  ${UserIntegrationFragment}
  mutation setUserIntegrationFlag(
    $name: String!
    $key: String!
    $value: JSON!
  ) {
    setUserIntegrationFlag(name: $name, key: $key, value: $value) {
      ...UserIntegrationFragment
    }
  }
`

interface PropsType {
  name: string
  key: string
  value: any
}

export default function useSetUserIntegrationFlag() {
  const [mutate] = useMutation(MUTATION, {
    update: (cache, { data }) =>
      updateCache({
        cache,
        data,
        mutationName: "setUserIntegrationFlag",
      }),
    refetchQueries: [GET_USER_QUERY],
  })

  return async ({ name, key, value }: PropsType) => {
    try {
      await mutate({
        variables: { name, key, value },
      })
    } catch (e) {
      console.error(e.message)
    }
  }
}
