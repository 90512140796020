import LocationTree, { getChildrenLocations } from "utils/LocationTree"

import findOlderParentsInTree from "./findOlderParentsInTree"

export default function getCsvLocations({
  showAllLocationsBar,
  allLocationsFlatten,
  selectedLocations,
  locationsTree,
}: {
  showAllLocationsBar: boolean
  allLocationsFlatten: LocationTree[]
  selectedLocations: LocationTree[]
  locationsTree: LocationTree[]
}) {
  if (showAllLocationsBar) return allLocationsFlatten
  const orphanLocations = selectedLocations.filter(
    (l) => l.parentId === null && !l.isCategory
  )
  const categories = selectedLocations.filter((l) => l.isCategory)
  if (categories.length > 0) {
    const parentCategories = findOlderParentsInTree(categories, locationsTree)
    const locations: LocationTree[] = []
    parentCategories.forEach((category) => {
      locations.push(...[category, ...getChildrenLocations([category], false)])
    })
    return [...locations, ...orphanLocations]
  }
  return selectedLocations
}
