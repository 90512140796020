import UserSearch from "components/UserSearch/UserSearch"
import useUpdateManager from "graphql/users/useUpdateManager"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { SearchResult, User } from "types"
import { Button, colors, Dialog, P14Bold, RemovableChipButton } from "ui"

const Content = styled.div`
  margin: 24px;

  p {
    color: ${colors.grey1};
    margin-bottom: 4px;
  }

  .users-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 25px;
  }
`

interface PropsType {
  onClose: () => void
  open: boolean
  title: string
  manager: User
}

export default function AddUserToTeamDialog({
  onClose,
  open,
  title,
  manager,
}: PropsType) {
  const t = useTranslate()

  const { updateManager } = useUpdateManager()

  const [selectedUsers, setSelectedUsers] = useState<SearchResult[]>([])

  const addUserToTeam = (user: SearchResult) => {
    setSelectedUsers((oldSelectedUsers) =>
      selectedUsers.some((oldUser) => oldUser.id === user.id)
        ? oldSelectedUsers.filter((oldUser) => user.id !== oldUser.id)
        : [...oldSelectedUsers, user]
    )
  }

  const removeUserToTeam = (user: SearchResult) => {
    setSelectedUsers((oldSelectedUsers) =>
      oldSelectedUsers.filter((oldUser) => user.id !== oldUser.id)
    )
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={title}
      leftButton={
        <Button secondary onClick={onClose}>
          {t("Cancel")}
        </Button>
      }
      rightButton={
        <Button
          onClick={() => {
            selectedUsers.length > 0 &&
              selectedUsers.map((user) => updateManager(manager.email, user.id))
            onClose()
          }}
        >
          {t("Add")}
        </Button>
      }
    >
      <Content>
        <P14Bold>{t("User")}</P14Bold>
        <UserSearch onSelect={(user) => addUserToTeam(user)} />
        <div className="users-list">
          {selectedUsers.length > 0 &&
            selectedUsers.map((user) => (
              <RemovableChipButton
                key={user.id}
                subject={user.name}
                onRemove={() => removeUserToTeam(user)}
              >
                {user.name}
              </RemovableChipButton>
            ))}
        </div>
      </Content>
    </Dialog>
  )
}
