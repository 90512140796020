import useCompanyLabels from "graphql/labels/useCompanyLabels"
import useTranslate from "intl/useTranslate"
import NewCustomLabelButton from "pages/PlanningPage/components/SlotsEditor/SlotsEditorContent/WorkingModeButtons/NewCustomLabelButton/NewCustomLabelButton"
import StatusTitle from "pages/PlanningPage/components/SlotsEditor/SlotsEditorContent/WorkingModeButtons/StatusTitle/StatusTitle"
import { WorkingModeButtonWithLabel } from "pages/PlanningPage/components/SlotsEditor/SlotsEditorContent/WorkingModeButtons/WorkingModeButton/WorkingModeButton"
import React, { useState } from "react"
import styled from "styled-components"
import { BulkSlotRuleInput } from "types"
import { hideScrollBarsCSS } from "ui/utilities"
import UTCDate from "utils/UTCDate"

const WorkingModeContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-top: 8px;
  gap: 10px;
  ${hideScrollBarsCSS}
`

interface PropsType {
  genericSlotValue: "home" | "else" | "off"
  label: string
  rule: BulkSlotRuleInput
  saveStatus: (status: string, label: string) => void
}

export default function SimpleWorkingModeButton({
  genericSlotValue,
  label,
  rule,
  saveStatus,
}: PropsType) {
  const t = useTranslate()
  const { labels } = useCompanyLabels()

  const [state, setState] = useState<"IDLE" | "ADD_LABEL">("IDLE")
  const to = rule.to ?? new UTCDate(rule.from, "DAY")

  return (
    <div className="working-mode-btn">
      <StatusTitle status={genericSlotValue} from={rule.from} to={to} />
      <WorkingModeContainer>
        <WorkingModeButtonWithLabel
          value={genericSlotValue}
          label={label}
          isSelected={genericSlotValue === rule.status && rule.label === ""}
          onClick={() => saveStatus(genericSlotValue, "")}
        />
        {/* FOR "OFF" mode, we also display "OOC" mode*/}
        {genericSlotValue === "off" && (
          <WorkingModeButtonWithLabel
            value="ooc"
            label={t("Out Of Contract")}
            onClick={() => saveStatus("ooc", "")}
            isSelected={"ooc" === rule.status && rule.label === ""}
          />
        )}
        {/* COMPANY LABELS */}
        {labels
          .filter((l) => l.type === genericSlotValue)
          .map((l, n) => (
            <WorkingModeButtonWithLabel
              key={`label-company-${genericSlotValue}-${n}`}
              value={genericSlotValue}
              label={l.label}
              onClick={() => saveStatus(genericSlotValue, l.label)}
              isSelected={l.label === rule.label}
            />
          ))}
        {/* FOR "OFF" mode, we also display "OOC" mode*/}
        {genericSlotValue === "off" &&
          labels
            .filter((l) => l.type === "ooc")
            .map((l, n) => (
              <WorkingModeButtonWithLabel
                key={`label-company-ooc-${n}`}
                value="ooc"
                label={l.label}
                onClick={() => saveStatus(genericSlotValue, label)}
                isSelected={l.label === rule.label}
              />
            ))}
        <NewCustomLabelButton
          target="company"
          type={genericSlotValue}
          onClick={() => setState("ADD_LABEL")}
          onSubmit={() => setState("IDLE")}
          disabled={state === "ADD_LABEL"}
        />
      </WorkingModeContainer>
    </div>
  )
}
