import InteractionsMenu from "components/InteractionsMenu/InteractionsMenu"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import { ActiveLocationsList } from "pages/PlanningPage/components/PlanningHeadersRouter/ActiveLocationsList/ActiveLocationsList"
import PlanningRowLayout from "pages/PlanningPage/components/PlanningRowLayout/PlanningRowLayout"
import React from "react"
import styled from "styled-components"
import { UserBase } from "types"
import { colors, mediaQueries, P16, transitions } from "ui"
import { Group as GroupIcon } from "ui/icons"
import getUserDisplayName from "utils/getUserDisplayName"

const Header = styled.div`
  display: grid;
  grid-template-columns: 24px auto 24px;
  align-items: center;
  width: 100%;
  min-height: 40px;
  background: hsla(${colors.blackReal}, 0);
  cursor: pointer;
  padding: 0 8px 0 32px;

  > p {
    padding-left: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    > em {
      font-style: normal;
      color: ${colors.grey1};
      padding-left: 6px;
    }
  }

  &:hover {
    background: hsla(${colors.blackReal}, 0.04);
  }

  @media ${mediaQueries.isNarrowScreen} {
    padding: 0 8px 0 16px;
  }

  transition: ${transitions.generic};
`

interface PropsType {
  selectedUser: UserBase
  teamUsers: UserBase[]
}

export default function MyTeamUsersListHeader({
  selectedUser,
  teamUsers,
}: PropsType) {
  const t = useTranslate()
  const { user: me } = useUser()

  const { from, to } = usePlanningContext()

  if (me === null) return <></>

  return (
    <PlanningRowLayout>
      <Header>
        <GroupIcon />
        <P16>
          {t("person's team", {
            person: getUserDisplayName(selectedUser, me.company.flags),
          })}
        </P16>
        {teamUsers.length > 0 ? (
          <InteractionsMenu users={teamUsers} from={from} to={to} />
        ) : undefined}
      </Header>
      <div
        style={{
          padding: "0 4px 0 8px",
        }}
      >
        <ActiveLocationsList />
      </div>
    </PlanningRowLayout>
  )
}
