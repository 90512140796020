import { ApolloError, gql, useMutation } from "@apollo/client"
import Divider from "components/Divider"
import ErrorDialog from "components/ErrorDialog"
import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import useTranslate from "intl/useTranslate"
import React, { useCallback, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { Button, colors, Grid, LU12, P14, P14Bold } from "ui"
import { Warning } from "ui/icons"
import * as Yup from "yup"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  margin-top: 20px;

  > ul {
    margin-top: 10px;

    > li {
      display: flex;
      flex-direction: row;
      gap: 8px;
    }
  }
`

const AlertText = styled.div`
  margin-bottom: 8px;
  gap: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    stroke-width: 0.5px;
    height: 24px;
    width: 24px;
    color: ${colors.redAlert};
  }

  p {
    color: ${colors.redAlert};
  }
`

const ADD_NEW_USERS = gql`
  mutation AddNewUsers($users: [[String]]!) {
    addNewUsers(users: $users)
  }
`

function isUserValid(user: string[]) {
  if (user.length === 2) {
    const email = user[0].trim()
    const manager = user[1].trim()
    return (
      Yup.string().email().isValidSync(email) &&
      (manager === "" || Yup.string().email().isValidSync(manager))
    )
  }
  return false
}

interface PropsType {
  users: string[][]
  onPrevious: () => void
  onClose: () => void
}

export function CSVRecap({ users, onClose, onPrevious }: PropsType) {
  const t = useTranslate()
  const history = useHistory()
  const [addNewUsers] = useMutation(ADD_NEW_USERS)
  const [error, setError] = useState<ApolloError | null>(null)
  const [success, setSuccess] = useState("")
  const [loading, setLoading] = useState(false)
  const validUsers = useMemo(() => {
    return users
      .filter(isUserValid)
      .map((user) => [user[0].trim(), user[1].trim()])
  }, [users])
  const invalidUsers = useMemo(() => {
    return users.filter((user) => !isUserValid(user))
  }, [users])
  const withManagers = useMemo(() => {
    return validUsers.filter((user) => user[1] !== "")
  }, [validUsers])

  const addUsersCallback = useCallback(
    async (validUsers: string[][]) => {
      setError(null)
      setLoading(true)
      try {
        await addNewUsers({ variables: { users: validUsers } })
        setSuccess(
          t(
            "Users successfully added. They will soon receive an email with a registration link."
          )
        )
      } catch (e) {
        setError(e)
      } finally {
        setLoading(false)
      }
    },
    [addNewUsers, t]
  )

  return (
    <>
      <ErrorDialog
        onClose={() => {
          history.push("/admin/users")
        }}
        open={success !== ""}
        error={success}
        isError={false}
      />
      <Container>
        {invalidUsers.length > 0 && (
          <>
            <AlertText>
              <Warning />
              <LU12>
                {invalidUsers.length > 1
                  ? t("invalid users", {
                      count: `${invalidUsers.length}`,
                    })
                  : t("invalid user", {
                      count: `${invalidUsers.length}`,
                    })}
              </LU12>
            </AlertText>

            <Divider />

            <P14Bold>{t("Example of invalid user :")}</P14Bold>
            <ul>
              <li>
                <P14Bold>{t("Email address")} : </P14Bold>
                <P14>{invalidUsers[0][0]}</P14>
              </li>
              <li>
                <P14Bold>{t("Manager")} : </P14Bold>
                <P14>{invalidUsers[0][1]}</P14>
              </li>
            </ul>
          </>
        )}
        {invalidUsers.length === 0 && (
          <>
            <P14>
              {validUsers.length > 1
                ? t("You're about to import users", {
                    count: `${validUsers.length}`,
                  })
                : t("You're about to import user", {
                    count: `${validUsers.length}`,
                  })}
            </P14>
            <P14>
              {withManagers.length > 1
                ? t("users have a manager", {
                    managers: `${withManagers.length} `,
                  })
                : t("user have a manager", {
                    managers: `${withManagers.length} `,
                  })}{" "}
              {withManagers.length > 1
                ? t("users have no manager", {
                    nomanager: `${validUsers.length - withManagers.length}`,
                  })
                : t("user have no manager", {
                    nomanager: `${validUsers.length - withManagers.length}`,
                  })}
            </P14>

            <P14>
              {t(
                "If a user with the same adress already exists in our database, it will be skipped."
              )}
            </P14>
          </>
        )}
        <Divider />
        <Grid container spacing={6} justifyContent="center">
          <Grid item>
            <Button secondary onClick={onPrevious}>
              {t("Previous")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={invalidUsers.length > 0}
              onClick={() => {
                addUsersCallback(validUsers)
                onClose()
              }}
            >
              {t("Confirm")}
            </Button>
            {(loading || error) && (
              <>
                <Divider />
                <LoaderOrErrorComponent loading={loading} error={error} />
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
