import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { Button, mediaQueries } from "ui"

import AddUsersModal from "./AddUsersModal"

const ButtonStyled = styled(Button)`
  @media ${mediaQueries.isMobile} {
    width: 100%;
  }
`

export default function AdminAddUsersButton() {
  const t = useTranslate()

  const [openNewUsersModal, setOpenNewUsersModal] = useState<boolean>(false)

  return (
    <>
      <ButtonStyled
        onClick={() => {
          setOpenNewUsersModal(true)
        }}
      >
        {t("Add users")}
      </ButtonStyled>
      <AddUsersModal
        open={openNewUsersModal}
        onClose={() => setOpenNewUsersModal(false)}
      />
    </>
  )
}
