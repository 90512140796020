import React from "react"
import styled from "styled-components"
import { colors, H4, Link, mediaQueries } from "ui"

const Button = styled.button`
  padding: 16px;
  width: 224px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  text-align: left;
  text-decoration: none;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  border: 1px solid ${colors.grey3};
  background: white;

  svg {
    color: ${colors.black};
  }

  @media ${mediaQueries.isTouchable} {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    flex-direction: row;
    height: 60px;
  }

  &:hover {
    background: hsla(${colors.blackHSL}, 0.04);
  }

  &:focus-within {
    background: hsla(${colors.blackHSL}, 0.12);
  }

  &:hover:focus {
    background: linear-gradient(
        0deg,
        hsla(${colors.blackHSL}, 0.04),
        hsla(${colors.blackHSL}, 0.04)
      ),
      hsla(${colors.blackHSL}, 0.12);
  }
`

interface PropsType {
  title: string
  icon: JSX.Element
  path: string
}

export default function AdminButton({ title, icon, path }: PropsType) {
  return (
    <Link naked to={path}>
      <Button>
        {icon}
        <H4>{title}</H4>
      </Button>
    </Link>
  )
}
