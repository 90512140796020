import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { P16 } from "ui"
import colors from "ui/colors"
import transitions from "ui/transitions"

const Button = styled.div.attrs({ role: "button" })`
  display: flex;
  align-items: center;
  border: none;
  border-radius: 50px;
  padding: 0px 16px;
  margin-left: auto;

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }

  > p {
    color: ${colors.grey1};
    white-space: nowrap;
  }

  transition: ${transitions.generic};
`

interface PropsType {
  handleClick: () => void
}

export default function OpenEquipmentsBookingButton({
  handleClick,
}: PropsType) {
  const t = useTranslate()

  return (
    <Button
      className="btn-add-equipment"
      onClick={(e) => {
        e.stopPropagation()
        handleClick()
      }}
    >
      <P16>+ {t("Book a service")}</P16>
    </Button>
  )
}
