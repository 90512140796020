import ErrorDialog from "components/ErrorDialog"
import React from "react"
import { useHistory } from "react-router-dom"

import ResetPasswordAction from "./ResetPasswordAction"
import VerifyEmail from "./VerifyEmail"

export default function EmailActionHandler() {
  const history = useHistory()
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const mode = urlParams.get("mode") || urlParams.get("amp;mode")
  const actionCode = urlParams.get("oobCode") || urlParams.get("amp;oobCode")
  const token = urlParams.get("otoken") || urlParams.get("amp;otoken")
  if (!actionCode || (mode === "verifyEmail" && !token))
    return (
      <ErrorDialog
        onClose={() => {
          history.push("/")
        }}
        open={true}
        error={`Invalid ${!actionCode ? "action code" : "token"}`}
        isError={true}
      />
    )
  switch (mode) {
    case "verifyEmail":
      return (
        <VerifyEmail
          actionCode={actionCode}
          token={token || "<invalid-token>"}
        />
      )
    case "resetPassword":
      return <ResetPasswordAction actionCode={actionCode} />
    default:
      return (
        <ErrorDialog
          onClose={() => {
            history.push("/")
          }}
          open={true}
          error={"Invalid email handler"}
          isError={true}
        />
      )
  }
}
