import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { colors, mediaQueries, P16 } from "ui"

import UsersAtFilters from "../../ActiveLocationsList/UsersAtFilters/UsersAtFilters"

const Message = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;

  > p {
    color: ${colors.grey2};
    text-align: center;
    margin: 0 auto;
  }

  @media ${mediaQueries.isTouchable} {
    justify-content: flex-start;
  }
`

interface PropsType {
  messageToBeTranslated: string
}

export default function PlanningHeaderMessage({
  messageToBeTranslated,
}: PropsType) {
  const t = useTranslate()

  return (
    <Message>
      <P16 style={{ color: colors.grey2 }}>{t(messageToBeTranslated)}</P16>
      <UsersAtFilters />
    </Message>
  )
}
