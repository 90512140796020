import { gql, useMutation } from "@apollo/client"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import { QUERY as LOCATIONS_AT_QUERY } from "graphql/locations/useLocationsAt"
import { CustomErrorFragment } from "graphql/misc/customErrorFragment"
import { SlotFragment } from "graphql/slots/fragments/slotFragment"
import { QUERY as USERS_AT_QUERY } from "graphql/users/useUsersAt"
import useCustomErrorToast from "hooks/useCustomErrorToast"
import { CustomError } from "types"
import UTCDate from "utils/UTCDate"

const SET_SLOT_TEMPLATE_MUTATION = gql`
  ${SlotFragment}
  ${CustomErrorFragment}
  mutation applySlotTemplates($from: String!, $until: String) {
    applySlotTemplates(from: $from, until: $until) {
      slots {
        ...SlotFragment
      }
      errors {
        ...CustomErrorFragment
      }
    }
  }
`

export default function useApplySlotTemplatesUTC() {
  const {
    usersAtQuery: { from, to, userIds, locationIds, groupIds },
  } = usePlanningContext()

  const refetchVariables = {
    from: `${(from || new UTCDate()).getTime()}`,
    to: `${(to || new UTCDate()).getTime()}`,
    userIds: userIds && userIds.length > 0 ? userIds : undefined,
    locationIds,
    groupIds,
  }

  const { addToastError } = useCustomErrorToast()
  const [applySlotTemplates] = useMutation<{
    applySlotTemplates: {
      slots: any
      errors: CustomError[]
    }
  }>(SET_SLOT_TEMPLATE_MUTATION, {
    update(cache, { data }) {
      if (data?.applySlotTemplates) {
        const { applySlotTemplates } = data
        const { slots: slotsFromQuery, errors } = applySlotTemplates
        cache.modify({
          fields: {
            slots(existing = []) {
              const slots = existing.filter(
                (es: any) =>
                  es.__ref !==
                  `${slotsFromQuery.__typename}:${slotsFromQuery.id}`
              )
              const newRefs = slotsFromQuery.map((slot: any) =>
                cache.writeFragment({
                  data: slot,
                  fragment: SlotFragment,
                })
              )
              return [...slots, ...newRefs]
            },
          },
        })
        if (errors.length > 0) {
          addToastError(errors[0])
        }
      }
    },
    refetchQueries: [
      "userEquipments",
      {
        query: USERS_AT_QUERY,
        fetchPolicy: "network-only",
        variables: refetchVariables,
      },
      {
        query: LOCATIONS_AT_QUERY,
        fetchPolicy: "network-only",
        variables: refetchVariables,
      },
    ],
  })
  return (from: UTCDate, until: UTCDate | null) => {
    return applySlotTemplates({
      variables: {
        from: `${from.getTime()}`,
        until: until ? `${until.getTime()}` : null,
      },
    }).catch(({ message }) => {
      addToastError({ message })
    })
  }
}
