import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"
import PickersDay, {
  pickersDayClasses,
  PickersDayProps,
} from "@mui/lab/PickersDay/PickersDay"
import StaticDatePicker from "@mui/lab/StaticDatePicker"
import { Backdrop, SxProps } from "@mui/material"
import React from "react"
import styled from "styled-components"
import { colors, P16Bold } from "ui"
import { ArrowLeft, ArrowRight } from "ui/icons"
import LocalDate from "utils/LocalDate"
import UTCDate from "utils/UTCDate"

const DatePickerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  background: white;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.24), 0px 2px 2px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  outline: none;

  > div {
    border-radius: 8px;
  }
  .MuiCalendarPicker-root {
    // month title
    div[role="presentation"] {
      position: absolute;
      width: 100%;
      left: 0;
      display: flex;
      justify-content: center;
      pointer-events: none;
      cursor: default;
      box-shadow: none;
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      button {
        display: none;
      }
    }
    // month selection arrows
    div[role="presentation"] + div {
      width: 100%;
      display: flex;
      justify-content: space-between;

      svg {
        cursor: pointer;
        &:hover {
          background: none;
        }
      }
    }
    div[role="grid"] {
      // calendar
      padding: 0 20px;
    }
  }
`

interface PropsType {
  label?: string
  open: boolean
  onSelect: (date?: UTCDate | LocalDate) => void
  onClose: () => void
  minDate?: UTCDate | LocalDate
  maxDate?: UTCDate | LocalDate
  defaultDate?: UTCDate | LocalDate
}

export default function DatePicker({
  label,
  minDate,
  maxDate,
  open,
  onSelect,
  onClose,
  defaultDate,
}: PropsType) {
  const onChange = (date: MaterialUiPickersDate) => {
    const utcDate = new UTCDate(date as Date, "DAY")
    onSelect(utcDate)
    onClose()
  }

  const defaultPickersDayStyle: SxProps = {
    borderRadius: "4px",
    background: `${colors.office}00`,
    border: `solid 1px ${colors.office}00`,

    "&:hover": {
      background: `${colors.office}14`,
      borderColor: colors.office,
    },

    transition: "background 350ms ease-in-out, border-color 175ms ease-in-out",
  }

  const selectedPickersDayStyle: SxProps = {
    backgroundColor: colors.office,
  }

  const disabledPickersDayStyle: SxProps = {
    position: "relative",
    backgroundColor: colors.grey4,
    color: colors.grey3,

    "&.invalid-date": {
      cursor: "not-allowed",
      "&::after": {
        // CAN ADD A BADGE HERE FOR EXAMPLE
      },
    },
  }

  return (
    <>
      <Backdrop
        open={open}
        style={{ background: "rgba(0, 0, 0, 0.2)", zIndex: 1200 }}
        onClick={() => {
          onClose()
        }}
      >
        <DatePickerContainer onClick={(e) => e.stopPropagation()}>
          {label && <P16Bold>{label}</P16Bold>}
          <StaticDatePicker
            label={label}
            value={defaultDate}
            onChange={onChange}
            onClose={onClose}
            allowSameDateSelection={true}
            minDate={minDate ? new UTCDate(minDate, "DAY") : undefined}
            maxDate={maxDate ? new UTCDate(maxDate, "DAY") : undefined}
            renderInput={() => <></>}
            showToolbar={false}
            showDaysOutsideCurrentMonth
            renderDay={(
              date: UTCDate | LocalDate,
              selectedDates: Array<UTCDate | LocalDate | null>,
              pickersDayProps: PickersDayProps<UTCDate | LocalDate>
            ) => {
              return (
                <PickersDay
                  {...pickersDayProps}
                  sx={{
                    ...defaultPickersDayStyle,
                    [`&&.${pickersDayClasses.selected}`]:
                      selectedPickersDayStyle,
                    [`&&.${pickersDayClasses.disabled}`]:
                      disabledPickersDayStyle,
                  }}
                  className={pickersDayProps.className}
                  disabled={pickersDayProps.disabled}
                />
              )
            }}
            components={{
              LeftArrowButton: ArrowLeft,
              RightArrowButton: ArrowRight,
            }}
          />
        </DatePickerContainer>
      </Backdrop>
    </>
  )
}
