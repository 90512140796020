import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import useAllGroups from "graphql/groups/useAllGroups"
import useGroupUsers from "graphql/groups/useGroupUsers"
import useUser from "graphql/users/useUser"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { useCallback, useEffect, useRef, useState } from "react"
import styled from "styled-components/macro"
import { Group, UserBase } from "types"
import { Button, colors, mediaQueries, P16Bold, Toggle } from "ui"

import UserGroupList from "./UserGroupList/UserGroupList"
import SearchGroupList from "./UserGroupSearch/SearchGroupsList/SearchGroupsList"
import UserGroupSearch from "./UserGroupSearch/UserGroupSearch"

const Menu = styled.div`
  display: flex;
  background-color: white;
  flex-direction: column;
  border-radius: 4px;
  min-height: 100%;

  footer {
    display: flex;
    justify-content: end;
    padding: 16px;
    border-top: solid 1px ${colors.grey3};
  }

  @media ${mediaQueries.isMobile} {
    footer {
      padding: 8px;
    }
  }
`

const SearcSectionTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 16px;
  background: ${colors.grey4};
  height: 40px;

  p {
    color: ${colors.grey1};
  }
`

interface SearchProps {
  isElementsSelected: boolean
}

const SearchList = styled.div<SearchProps>`
  .search {
    display: flex;
    flex-direction: row;
    align-items: center;
    opacity: 0.75;
    border: 1px solid #afb4b6;

    ${({ isElementsSelected }) => {
      if (isElementsSelected) {
        return `
        border: 1px solid ${colors.grey2};
        cursor: text;
        padding: 8px 0px;`
      } else return `border: 2px solid ${colors.green};padding: 4px 0px;`
    }}
    box-sizing: border-box;
    border-radius: 4px;
    background: white;

    svg {
      margin: 0px 5px 0px 5px;
    }

    p {
      color: ${colors.grey1};
    }

    .selected-list {
      // If you change this value, please change it in ./LocationsTextInput/LocationsTextInput
      // variable : SPACE_BETWEEN_LOCATIONS
      gap: 10px;
      // If you change this value, please change it in ./LocationsTextInput/LocationsTextInput
      // variable : INPUT_MARGIN
      margin: 0 10px 0 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .search-icon {
    display: flex;
  }
`

const SearchSuggestionsList = styled.div`
  > ul {
    max-height: 192px;
    overflow-y: scroll;
    overflow-x: hidden;

    @media ${mediaQueries.isMobile} {
      max-height: 150px;
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }
`

interface PropsType {
  singleSelection?: boolean
  initialUserIds?: string[]
  groupIds: string[]
  userIds: string[]
  hideDefaults?: boolean
  noInteractionWithMyself?: boolean
  onChange: (input: { users: UserBase[]; groups: Group[] }) => void
  onSave: () => void
}

export default function UsersGroupsSelection({
  singleSelection,
  initialUserIds,
  groupIds,
  userIds,
  hideDefaults = false,
  noInteractionWithMyself = false,
  onChange,
  onSave,
}: PropsType) {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()
  const { user: me } = useUser()

  const { groups: allGroups } = useAllGroups()
  const { group: myTeamGroupUsers } = useGroupUsers("myteam")
  const { group: myManagerTeamGroupUsers } = useGroupUsers("mymanagerteam")
  const {
    group: everyoneGroupUsers,
    loading,
    error,
  } = useGroupUsers("everyone")

  const [isResultDisplay, setIsResultDisplay] = useState(true)
  const fakeSearch = useRef<HTMLDivElement>(null)

  const [searchString, setSearchString] = useState<string>("")

  const [selectedUsers, setSelectedUsers] = useState<UserBase[]>(
    everyoneGroupUsers
      ? userIds
          .map((id) => everyoneGroupUsers.users.find((u) => u.id === id))
          .filter((u): u is UserBase => u !== undefined)
      : []
  )
  const [filteredUsers, setFilteredUsers] = useState<UserBase[]>([])

  const addOrRemoveUser = (user: UserBase) => {
    // single selection means:
    // users array is reduced to the currently selected user
    if (singleSelection) {
      setSelectedUsers([user])
      onChange({ groups: selectedGroups, users: [user] })
      return
    }
    // if user already part of the selectino then remove it from the list
    if (selectedUsers.some((u) => u.id === user.id)) {
      const newUsers = selectedUsers.filter((u) => user.id !== u.id)
      setSelectedUsers(newUsers)
      onChange({ groups: selectedGroups, users: newUsers })
      return
    }
    // user is not already part of the selection then add it to the list
    const newUsers = [...selectedUsers.filter((u) => user.id !== u.id), user]
    setSelectedUsers(newUsers)
    onChange({ groups: selectedGroups, users: newUsers })
  }

  const getUsersToDisplay = useCallback(() => {
    if (everyoneGroupUsers === null) return filteredUsers
    return filteredUsers.length > 0 ? filteredUsers : everyoneGroupUsers.users
  }, [filteredUsers, everyoneGroupUsers])

  const [selectedGroups, setSelectedGroups] = useState<Group[]>(
    allGroups.filter((g) => groupIds.find((id) => g.id === id))
  )
  const [filteredGroups, setFilteredGroups] = useState<Group[]>([])
  const [showGroups, setShowGroups] = useState(true)

  const getGroupsToDisplay = useCallback(() => {
    const filterSpecialGroups = (g: Group) => {
      return (
        ["myteam", "mymanagerteam", "everyone"].find((id) => id === g.id) ===
        undefined
      )
    }
    const topOfTheList = []
    if (myTeamGroupUsers) topOfTheList.push(myTeamGroupUsers)
    if (myManagerTeamGroupUsers) topOfTheList.push(myManagerTeamGroupUsers)
    if (everyoneGroupUsers) topOfTheList.push(everyoneGroupUsers)
    if (hideDefaults)
      return filteredGroups.length > 0
        ? [...filteredGroups.filter(filterSpecialGroups)]
        : [...allGroups.filter(filterSpecialGroups)]

    return filteredGroups.length > 0
      ? [...topOfTheList, ...filteredGroups.filter(filterSpecialGroups)]
      : [...topOfTheList, ...allGroups.filter(filterSpecialGroups)]
  }, [
    filteredGroups,
    allGroups,
    myTeamGroupUsers,
    myManagerTeamGroupUsers,
    everyoneGroupUsers,
    hideDefaults,
  ])

  const handleChange = (users: UserBase[], groups: Group[]) => {
    setSelectedUsers(users)
    setSelectedGroups(groups)
    onChange({ users, groups })
  }

  useEffect(() => {
    if (everyoneGroupUsers !== null && initialUserIds) {
      setSelectedUsers(
        initialUserIds
          .map((id) => everyoneGroupUsers.users.find((u) => u.id === id))
          .filter((x): x is UserBase => x !== undefined)
      )
    }
  }, [everyoneGroupUsers, initialUserIds])

  useEffect(() => {
    // if everyone group selected reduce selected to everyone only
    if (
      everyoneGroupUsers &&
      selectedGroups.find((g) => g.id === "everyone") &&
      selectedGroups.length > 1
    )
      setSelectedGroups([everyoneGroupUsers])
  }, [selectedGroups, everyoneGroupUsers])

  if (loading || error) {
    return <LoaderOrErrorComponent loading={loading} error={error} />
  }
  if (me === null) return <></>
  return (
    <SearchList isElementsSelected={selectedUsers.length === 0 ? true : false}>
      <div ref={fakeSearch} onClick={() => setIsResultDisplay(true)}>
        <UserGroupSearch
          users={getUsersToDisplay()}
          groups={getGroupsToDisplay()}
          placeholder={t("search groups or users")}
          searchString={searchString}
          setSearchString={(str) => setSearchString(str)}
          onChange={(str) =>
            setSearchString((prev) => {
              if (prev.length > 0 && str === "") {
                setFilteredUsers([])
                setFilteredGroups([])
              }
              return str
            })
          }
          onNewResults={(results) => {
            setFilteredUsers(results)
          }}
          onNewGroupResults={(results) => {
            setFilteredGroups(results)
          }}
        />
      </div>
      {isResultDisplay && (
        <Menu>
          <SearchSuggestionsList
            style={{
              width:
                fakeSearch !== null && fakeSearch.current !== null
                  ? fakeSearch.current.offsetWidth
                  : "max-content",
            }}
          >
            <SearcSectionTitle>
              <P16Bold>{t("Groups")}</P16Bold>
              <Toggle
                label={t("show groups")}
                labelToTheLeft
                checked={showGroups}
                onChange={(checked) => {
                  setShowGroups(checked)
                }}
              />
            </SearcSectionTitle>
            {showGroups && (
              <SearchGroupList
                groups={getGroupsToDisplay()}
                selectedGroups={selectedGroups}
                onSelect={(group) => {
                  const newGroups = [
                    ...selectedGroups.filter((g) => g.id !== group.id),
                    group,
                  ]
                  setSelectedGroups(newGroups)
                  handleChange(selectedUsers, newGroups)
                }}
                onDeselect={(group) => {
                  const newGroups = selectedGroups.filter(
                    (g) => g.id !== group.id
                  )
                  const newUsers = selectedUsers.filter((su) => {
                    group.users.find((gu) => gu.id === su.id) === undefined
                  })
                  setSelectedGroups(newGroups)
                  handleChange(newUsers, newGroups)
                }}
              />
            )}
            <SearcSectionTitle>
              <P16Bold>{t("Users")}</P16Bold>
            </SearcSectionTitle>
            <UserGroupList
              users={getUsersToDisplay()}
              selectedUsers={selectedUsers}
              noInteractionWithMyself={noInteractionWithMyself}
              onSelect={addOrRemoveUser}
            />
          </SearchSuggestionsList>

          <footer>
            <Button
              fullWidth={isMobile}
              onClick={() => {
                handleChange(selectedUsers, selectedGroups)
                onSave()
              }}
            >
              {t("Save")}
            </Button>
          </footer>
        </Menu>
      )}
    </SearchList>
  )
}
