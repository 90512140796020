import useTranslate from "intl/useTranslate"
import React, { useRef, useState } from "react"
import styled from "styled-components"
import { colors, PopperMenu, transitions } from "ui"
import { Calendar } from "ui/icons"
import UTCDate from "utils/UTCDate"

const ShadowedButton = styled.button`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 7px 16px;
  background: ${colors.white};
  box-shadow: 0px 2px 2px hsla(${colors.blackHSL}, 0.25);
  border: none;
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  cursor: not-pointer;

  svg {
    width: 22px;
  }
`

const Item = styled.li`
  display: flex;
  gap: 16px;
  padding: 8px 16px;
  background: ${colors.grey4}00;
  cursor: pointer;

  &:hover {
    background: ${colors.grey4};
  }

  transition: ${transitions.fade};
`

interface ChildrenPropsType {
  selectedDate: UTCDate
  setSelectedDate: (date: UTCDate) => void
}

function WeeksNavigation({ selectedDate, setSelectedDate }: ChildrenPropsType) {
  const t = useTranslate()
  const [open, setOpen] = useState(false)
  const anchorEl = useRef(null)

  const to = new UTCDate("NEXT-YEAR")
  const weeks: UTCDate[] = []
  for (
    let cursor = new UTCDate("YEAR");
    cursor.getTime() < to.getTime();
    cursor.shift("WEEK", 1)
  ) {
    weeks.push(new UTCDate(cursor))
  }
  return (
    <>
      <ShadowedButton ref={anchorEl} onClick={() => setOpen(true)}>
        <Calendar />
        {t("week")} {selectedDate.getWeekNumber()}
      </ShadowedButton>
      {open && (
        <PopperMenu
          open
          anchorEl={anchorEl.current}
          style={{ zIndex: 4000, maxHeight: "50vh" }}
          onClose={() => setOpen(false)}
        >
          <ul>
            {weeks.map((week, n) => (
              <Item
                key={`week-${n}`}
                onClick={() => {
                  setOpen(false)
                  setSelectedDate(week)
                }}
              >
                <Calendar />
                {t("week")} {week.getWeekNumber()}
              </Item>
            ))}
          </ul>
        </PopperMenu>
      )}
    </>
  )
}

function MonthsNavigation({
  selectedDate,
  setSelectedDate,
}: ChildrenPropsType) {
  const t = useTranslate()
  const [open, setOpen] = useState(false)
  const anchorEl = useRef(null)

  const to = new UTCDate("NEXT-YEAR")
  const months: UTCDate[] = []
  for (
    let cursor = new UTCDate("YEAR");
    cursor.getTime() < to.getTime();
    cursor.shift("MONTH", 1)
  ) {
    months.push(new UTCDate(cursor))
  }
  return (
    <>
      <ShadowedButton ref={anchorEl} onClick={() => setOpen(true)}>
        <Calendar />
        {t(selectedDate.getMonthAsString())}
      </ShadowedButton>
      {open && (
        <PopperMenu
          open
          anchorEl={anchorEl.current}
          style={{ zIndex: 4000, maxHeight: "50vh" }}
          onClose={() => setOpen(false)}
        >
          <ul>
            {months.map((month, n) => (
              <Item
                key={`month-${n}`}
                onClick={() => {
                  setOpen(false)
                  setSelectedDate(month)
                }}
              >
                <Calendar />
                {t(month.getMonthAsString())}
              </Item>
            ))}
          </ul>
        </PopperMenu>
      )}
    </>
  )
}

interface PropsType {
  selectedDate: UTCDate
  setSelectedDate: (date: UTCDate) => void
  type: "week" | "month"
}

export default function NavigationButton({
  selectedDate,
  type,
  setSelectedDate,
}: PropsType) {
  return (
    <div style={{ gridArea: "navigation", padding: "0 8px" }}>
      {type === "week" ? (
        <WeeksNavigation
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      ) : (
        <MonthsNavigation
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      )}
    </div>
  )
}
