import BookmarkCheckbox from "components/BookmarkCheckbox/BookmarkCheckbox"
import FavoriteCheckbox from "components/FavoriteCheckbox"
import MobileNavBack from "components/Layout/PageLayout/PageHeader/PageTitle/MobileNavBack/MobileNavBack"
import EditManager from "components/UpdateManagerButton/EditManager"
import useUser from "graphql/users/useUser"
import React from "react"
import styled from "styled-components"
import { User } from "types"
import { colors, H4 } from "ui"
import { Mail } from "ui/icons"
import getUserDisplayName from "utils/getUserDisplayName"

const FixedLayout = styled.div`
  position: fixed;
  width: 100vw;
  background: ${colors.purple100};
  z-index: 2;
  top: 0;
  padding-top: env(safe-area-inset-top, 0);
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  height: 56px;
  gap: 8px;

  border-bottom: solid 1px ${colors.grey3};

  .left-part {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: auto;
    gap: 14px;

    h4 {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 45vw;
    }

    svg:not(:first-child),
    .favorite-checkbox-icon svg {
      width: 22px;
      height: 22px;
    }
    .subtitle {
      color: ${colors.redAlert};
    }
  }
  .right-part {
    gap: 12px;
    display: flex;
    justify-content: space-between;
    svg {
      color: ${colors.purple};
    }
  }
`

interface PropsType {
  selectedUser: User
}

export default function MobileHeader({ selectedUser }: PropsType) {
  const { user: me } = useUser()

  if (!me) return <></>

  return (
    <>
      <FixedLayout>
        <Container>
          <div className="left-part">
            <MobileNavBack />
            <H4>{getUserDisplayName(selectedUser, me.company.flags)}</H4>
            <FavoriteCheckbox type="user" itemId={selectedUser.id} />
            <BookmarkCheckbox type="user" id={selectedUser.id} />
          </div>
          <div className="right-part">
            <EditManager selectedUser={selectedUser} variant="small" />
            <a
              href={`mailto:${selectedUser.email}`}
              style={{
                color: colors.purple,
                textDecoration: "none",
              }}
            >
              <Mail />
            </a>
          </div>
        </Container>
      </FixedLayout>
      {/*
       * Mandatory spacer to avoid hidden content behind search bar
       */}
      <div
        style={{
          background: "red",
          height: "56px",
          maxHeight: "56px",
        }}
      />
    </>
  )
}
