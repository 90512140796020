import FavoriteCheckbox from "components/FavoriteCheckbox"
import InteractionsMenu from "components/InteractionsMenu/InteractionsMenu"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useLocationsTree from "graphql/locations/useLocationsTree"
import OccupationList from "pages/PlanningPage/components/PlanningHeadersRouter/OccupationList/OccupationList"
import CategoryLocationsList from "pages/PlanningPage/components/PlanningHeadersRouter/PlanningHeaders/LocationPlanningHeader/CategoryLocationsList/CategoryLocationsList"
import UserFloorPlanRouter from "pages/PlanningPage/components/PlanningHeadersRouter/PlanningHeaders/LocationPlanningHeader/UserFloorPlanDrawer/UserFloorPlanDrawer"
import React, { useEffect } from "react"
import { useTeamsAppContext } from "TeamsApp/TeamsAppContext"
import { UserBase } from "types"
import { P14 } from "ui"
import { Building } from "ui/icons"
import LocationTree from "utils/LocationTree"

import { HeaderContainer, HeaderTitle } from "./components"

interface PropsType {
  locationId: string
  users: UserBase[]
}

export default function LocationHeader({ locationId, users }: PropsType) {
  const {
    openDrawer,
    setOpenDrawer,
    setActiveLocation,
    activeLocation,
    from,
    to,
  } = usePlanningContext()
  const { locations } = useLocationsTree()
  const location = LocationTree.getLocationNode(locations, locationId)
  const { isTeamsApp } = useTeamsAppContext()

  useEffect(() => {
    if (location) {
      if (activeLocation.location?.id !== location.id) {
        setActiveLocation({
          location,
          persist: true,
          triggeredFrom: activeLocation.triggeredFrom,
        })
      }
    }
  }, [setActiveLocation, location, activeLocation])

  if (location === undefined) return <></>

  return (
    <>
      <HeaderContainer>
        <HeaderTitle>
          <Building />
          <P14>{location.name}</P14>
          <FavoriteCheckbox itemId={location.id} type="location" />
          {!isTeamsApp && (
            <>
              <InteractionsMenu users={users} from={from} to={to} />
            </>
          )}
        </HeaderTitle>
        <OccupationList location={location} />
        {location.isCategory && <CategoryLocationsList category={location} />}
      </HeaderContainer>
      {openDrawer === "FLOOR_PLAN" && (
        <UserFloorPlanRouter
          from={from}
          to={to}
          drawerWidth={100}
          onClose={() => {
            if (openDrawer === "FLOOR_PLAN") setOpenDrawer(null)
          }}
        />
      )}
    </>
  )
}
