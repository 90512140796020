import styled from "styled-components"
import { colors, mediaQueries } from "ui"

interface ItemProps {
  isSelected?: boolean
  zeroBorder?: boolean
  doubleBorder?: boolean
  onClick?: () => void
}

export const Item = styled.li<ItemProps>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 16px;
  gap: 10px;
  cursor: pointer;
  border: none;
  width: 100%;
  background: none;

  p {
    white-space: nowrap;
    ${({ isSelected }) => {
      if (isSelected) return `font-weight: 600;`
      else return ""
    }}
  }
  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }

  @media ${mediaQueries.isMobile} {
    ${({ zeroBorder, doubleBorder }) => {
      if (zeroBorder) ``
      else if (doubleBorder)
        return `border-top: solid 1px ${colors.grey4}; border-bottom: solid 1px ${colors.grey4};`
      else return `border-bottom: solid 1px ${colors.grey3};`
    }}

    padding: 11px 16px;

    svg {
      color: ${colors.grey2};
      height: 16px;
      width: 16px;
      margin-left: auto;
    }
  }

  ${({ isSelected }) => {
    if (isSelected) return `background: rgba(0, 0, 0, 0.04);`
    else return ""
  }}
`
