import { ConfirmDeleteLocationDialog } from "components/LocationComponents/ConfirmDeleteLocationDialog/ConfirmDeleteLocationDialog"
import React, { useState } from "react"
import { UnstyledButton } from "ui"
import { Trash } from "ui/icons"
import LocationTree from "utils/LocationTree"

interface PropsType {
  location: LocationTree
  disabled?: boolean
}

export default function DeleteLocationButton({
  location,
  disabled,
}: PropsType) {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)

  return (
    <>
      <UnstyledButton
        className="trash-icon"
        onClick={(e) => {
          e.stopPropagation()
          setIsDeleteModalOpen(true)
        }}
        disabled={disabled}
      >
        <Trash />
      </UnstyledButton>
      <ConfirmDeleteLocationDialog
        category={location}
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      />
    </>
  )
}
