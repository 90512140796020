import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function CheckOn({
  stroke,
  strokeWidth,
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      strokeWidth={strokeWidth ?? 1.5}
      stroke={stroke ? stroke : "currentColor"}
      {...rest}
    >
      <path
        d="M6 12.25L9.81818 16L20 6"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M21 11L21 20.4C21 20.7314 20.7314 21 20.4 21L3.6 21C3.26863 21 3 20.7314 3 20.4L3 3.6C3 3.2686 3.26863 3 3.6 3L13 3"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  )
}
