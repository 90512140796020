import useUpdateUser from "graphql/admin/useUpdateUser"
import useUser from "graphql/users/useUser"
import useCompanyFlags from "hooks/useCompanyFlags"
import useTranslate from "intl/useTranslate"
import React, { MouseEvent, useState } from "react"
import styled from "styled-components/macro"
import { UserAdminBase } from "types"
import { colors, P14, P16Bold, Popover, Toggle } from "ui"
import { Cancel, Check, Copy } from "ui/icons"
import getUserDisplayName from "utils/getUserDisplayName"

import EditUserDialog from "./AdminControlSection/EditUserDialog"
interface THProps {
  width?: number
}

const Table = styled.table`
  width: 100%; // available space, 2 * margins
  cursor: pointer;
`

const TH = styled.th<THProps>`
  background: ${colors.grey4};
  padding: 8px;

  &.align-left {
    text-align: left;

    .align-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      ${({ width }) => {
        return `width: ${width}px;`
      }}

      p {
        color: ${colors.grey1};
      }

      svg {
        visibility: hidden;
        opacity: 0;
        color: ${colors.grey2};
      }

      &:hover {
        svg {
          visibility: visible;
          opacity: 1;
          margin-left: 5px;
        }
      }

      &:active {
        svg {
          background-color: ${colors.grey3};
          border-radius: 3px;
        }
      }
    }
  }
`

const TR = styled.tr`
  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
  &:nth-child(even) {
    background: ${colors.grey4};
    &:hover {
      background: rgba(0, 0, 0, 0.04);
    }
  }
`
const TD = styled.td`
  padding: 8px;

  p {
    text-align: left;

    &.disabled {
      text-decoration: overline;
    }

    &.align-left {
      text-align: left;
      padding: 0 0 0 2rem;
    }
    overflow: hidden; // overflow with ellipsis
    white-space: nowrap; // overflow with ellipsis
    text-overflow: ellipsis; // overflow with ellipsis
  }

  max-width: 0; // overflow with ellipsis
  text-align: center;

  &.toggle > div {
    justify-content: center;
  }
`

interface PropsType {
  users: UserAdminBase[]
  heightStats: number
  heightFilters: number
}

export default function AdminUsersTable({ users }: PropsType) {
  const [isAdminModalOpen, setIsAdminModalOpen] = useState<boolean>(false)
  const [selectedUser, setSelectedUser] = useState<UserAdminBase>()

  const { companyFlags } = useCompanyFlags()
  const t = useTranslate()
  const { updateUser } = useUpdateUser()
  const { user: currentUser } = useUser()

  const emailsList = `${users.map((user) => user.email)}`

  const managersList = `${users.map((user) =>
    user.manager ? user.manager.email : "no manager"
  )}`
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

  const handlePopoverOpen = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <Table>
        <thead>
          <TR>
            <TH className="align-left">
              <P16Bold style={{ color: colors.grey1 }}>{t("Name")}</P16Bold>
            </TH>
            <TH className="align-left" width={140}>
              <div className="align-row">
                <P16Bold>{t("Email address")}</P16Bold>
                <div
                  className="copy-icon"
                  onClick={() => navigator.clipboard.writeText(emailsList)}
                >
                  <Copy />
                </div>
              </div>
            </TH>
            <TH className="align-left" width={100}>
              <div className="align-row">
                <P16Bold>{t("Manager")}</P16Bold>
                <div
                  className="copy-icon"
                  onClick={() => navigator.clipboard.writeText(managersList)}
                >
                  <Copy />
                </div>
              </div>
            </TH>
            <TH>
              <P16Bold style={{ color: colors.grey1 }}>{t("Admin")}</P16Bold>
            </TH>
            <TH>
              <P16Bold style={{ color: colors.grey1 }}>{t("State")}</P16Bold>
            </TH>
            <TH>
              <P16Bold style={{ color: colors.grey1 }}>
                {t("Registered")}
              </P16Bold>
            </TH>
          </TR>
        </thead>

        <tbody>
          {users.length > 0 &&
            users.map((user) => {
              return (
                <TR key={user.id}>
                  {/* NAME */}
                  <TD
                    className="align-left"
                    onClick={() => {
                      setIsAdminModalOpen(true)
                      setSelectedUser(user)
                    }}
                  >
                    <P14>{getUserDisplayName(user, companyFlags)}</P14>
                  </TD>
                  {/* EMAIL */}
                  <TD
                    className="align-left"
                    onClick={() => {
                      setIsAdminModalOpen(true)
                      setSelectedUser(user)
                    }}
                  >
                    <P14>{user.email}</P14>
                  </TD>
                  <TD
                    className="align-left"
                    onClick={() => {
                      setIsAdminModalOpen(true)
                      setSelectedUser(user)
                    }}
                  >
                    <P14>
                      {user.manager
                        ? getUserDisplayName(user.manager, companyFlags)
                        : ""}
                    </P14>
                  </TD>

                  {/* ADMIN ? */}
                  <TD
                    className={
                      user.role === "admin" ? "disabled toggle" : "toggle"
                    }
                    onMouseEnter={(e) => {
                      handlePopoverOpen(e)
                      setSelectedUser(user)
                    }}
                    onMouseLeave={() => {
                      handlePopoverClose()
                      setSelectedUser(undefined)
                    }}
                  >
                    {currentUser && (
                      <>
                        <Toggle
                          checked={user.role === "admin" ? true : false}
                          onChange={async (checked) => {
                            await updateUser(
                              user.id,
                              checked ? "admin" : "user",
                              Boolean(user.disabled)
                            )
                          }}
                          disabled={
                            user.id === currentUser.id && user.role === "admin"
                          }
                        />
                        {selectedUser &&
                          selectedUser.id === currentUser.id &&
                          selectedUser.role === "admin" && (
                            <Popover
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handlePopoverClose}
                            >
                              <p>{t("Can't unadmin yourself")}</p>
                            </Popover>
                          )}
                      </>
                    )}
                  </TD>
                  {/* USER ACTIVE ? */}
                  <TD className={user.disabled ? "disabled toggle" : "toggle"}>
                    <Toggle
                      checked={!Boolean(user.disabled)}
                      onChange={async (checked) => {
                        await updateUser(
                          user.id,
                          user.role,
                          checked ? false : true
                        )
                      }}
                    />
                  </TD>
                  {/* USER REGISTERED ? */}
                  <TD
                    onClick={() => {
                      setIsAdminModalOpen(true)
                      setSelectedUser(user)
                    }}
                  >
                    {user.registered ? <Check /> : <Cancel />}
                  </TD>
                </TR>
              )
            })}
        </tbody>
      </Table>
      {isAdminModalOpen && selectedUser && (
        <EditUserDialog
          open={isAdminModalOpen}
          userId={selectedUser.id}
          onClose={() => setIsAdminModalOpen(false)}
          onSuccess={() => {
            //
          }}
        />
      )}
    </>
  )
}
