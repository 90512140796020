import { ApolloError, gql, useQuery } from "@apollo/client"
import { SearchResult } from "types"

import { SearchResultFragment } from "./fragments/searchFragments"

const SEARCH_USERS_BY_NAME_QUERY = gql`
  ${SearchResultFragment}
  query searchUsersByName($search: String!) {
    searchUsersByName(search: $search) {
      ...SearchResultFragment
    }
  }
`

export default function useSearchUsersByName(search: string): {
  loading: boolean
  error?: ApolloError
  results: SearchResult[]
} {
  const { loading, error, data } = useQuery<{
    searchUsersByName: SearchResult[]
  }>(SEARCH_USERS_BY_NAME_QUERY, {
    variables: { search },
    skip: search.length === 0,
  })
  return {
    results: data?.searchUsersByName || [],
    loading,
    error,
  }
}
