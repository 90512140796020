import useCreateGroup from "graphql/groups/useCreateGroup"
import useUser from "graphql/users/useUser"
import useMenu from "hooks/useMenu"
import React from "react"
import { Button } from "ui"

interface PropsType {
  children: React.ReactNode
  disabled?: boolean
  groupName: string
  groupUsers: string[]
  cleanupAfterSaving: () => void
}

export default function SaveGroupButton({
  children,
  groupName,
  groupUsers,
  disabled,
  cleanupAfterSaving,
}: PropsType) {
  const { user: me } = useUser()
  const createGroupMutation = useCreateGroup()
  const { handlePath } = useMenu()

  if (!me) return <></>

  return (
    <Button
      disabled={disabled}
      onClick={async () => {
        const group = await createGroupMutation(
          groupName,
          groupUsers.filter((id) => id !== me.id)
        )
        if (group) {
          handlePath(`/planning/group/${group.id}`)
        }
        cleanupAfterSaving()
      }}
    >
      {children}
    </Button>
  )
}
