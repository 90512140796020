import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { NotificationTriggers } from "types"
import { colors, H4, mediaQueries, P16 } from "ui"

import NotificationTriggerChoices from "./NotificationTriggerChoices/NotificationTriggerChoices"

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  padding-bottom: 15px;
  border-top: solid 1px ${colors.grey3};
`

const TitleLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
  padding: 16px 0;

  @media ${mediaQueries.isMobile} {
    gap: 8px;
  }
`

const Label = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 16px;

  .description {
    color: ${colors.grey1};
  }
`

const Body = styled.div``

function NotificationSettings(props: any, ref: React.Ref<HTMLDivElement>) {
  const t = useTranslate()
  const { user: me } = useUser()
  const notificationTriggers: NotificationTriggers | undefined =
    me?.flags.notificationTriggers

  if (!me) return <></>

  return (
    <Content ref={ref}>
      <TitleLayout>
        <H4>{t("user-setting-group-notifications")}</H4>
      </TitleLayout>

      <Label>
        <P16>{t("user-setting-group-notifications--description")}</P16>
      </Label>
      <Body>
        <NotificationTriggerChoices initialTriggers={notificationTriggers} />
      </Body>
    </Content>
  )
}

export default React.forwardRef(NotificationSettings)
