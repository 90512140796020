import PageHeader from "components/Layout/PageLayout/PageHeader/PageHeader"
import MobileNavBack from "components/Layout/PageLayout/PageHeader/PageTitle/MobileNavBack/MobileNavBack"
import PageTitle from "components/Layout/PageLayout/PageHeader/PageTitle/PageTitle"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import SimpleSearch from "components/SimpleSearch/SimpleSearch"
import useToggleFavorite from "graphql/favorites/useToggleFavorite"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { SearchResult, UserFavorites } from "types"
import {
  Button,
  colors,
  Dialog,
  L12GreyBold,
  mediaQueries,
  P16,
  RemovableChipButton,
} from "ui"

const Content = styled.div`
  padding: 24px;

  .search-container {
    p {
      margin-bottom: 8px;
    }
  }

  @media ${mediaQueries.isMobile} {
    padding: 16px 8px;
  }
`

const Results = styled.div`
  > button {
    margin: 8px 0px;
  }

  .red-text {
    color: ${colors.redAlert};
  }
`

interface PropsType {
  onClose: () => void
  open: boolean
  favorites: UserFavorites
}

export default function CreateFavorite({
  onClose,
  open,
  favorites,
}: PropsType) {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()

  const toggleFavorite = useToggleFavorite()
  const { addToast } = useToasts()

  const [selectedFavorite, setSelectedFavorite] = useState<SearchResult>()

  const reset = () => {
    setSelectedFavorite(undefined)
    onClose()
  }

  const defineFavoriteType = (favorite: SearchResult) => {
    if (favorite && favorite.type === "location") return "location"
    if (favorite && favorite.type === "user") return "user"
    if (favorite && favorite.type === "group") return "group"
    else return "user"
  }

  const isAlreadyFavorite = () => {
    const favoritesUsers = favorites.users
    const favoritesGroups = favorites.groups
    const favoritesLocations = favorites.locations

    if (selectedFavorite && selectedFavorite.type === "user") {
      return favoritesUsers.find((el) => el.itemId === selectedFavorite.id)
    } else if (selectedFavorite && selectedFavorite.type === "group") {
      return favoritesGroups.find((el) => el.itemId === selectedFavorite.id)
    } else if (selectedFavorite && selectedFavorite.type === "location") {
      return favoritesLocations.find((el) => el.itemId === selectedFavorite.id)
    } else return undefined
  }

  return (
    <Dialog
      open={open}
      title={isMobile ? undefined : t("Add one favorite")}
      onClose={reset}
      leftButton={
        !isMobile || !selectedFavorite ? (
          <Button secondary onClick={reset} fullWidth={isMobile}>
            {t("Cancel")}
          </Button>
        ) : undefined
      }
      rightButton={
        !isMobile || selectedFavorite ? (
          <Button
            disabled={!selectedFavorite}
            fullWidth={isMobile}
            onClick={() => {
              if (selectedFavorite)
                toggleFavorite(
                  selectedFavorite?.id,
                  defineFavoriteType(selectedFavorite)
                ).then(() => {
                  reset()
                  addToast(t("favorite added confirmation"), {
                    appearance: "success",
                  })
                })
            }}
          >
            {t("Add")}
          </Button>
        ) : undefined
      }
      fullscreen={isMobile}
    >
      {isMobile && (
        <PageHeader>
          <MobileNavBack onClose={onClose} />
          <PageTitle title={t("Add one favorite")} />
        </PageHeader>
      )}
      <Content>
        {selectedFavorite ? (
          <Results>
            <P16>{t("You are about to add")}</P16>
            <RemovableChipButton
              onRemove={() => setSelectedFavorite(undefined)}
            >
              {selectedFavorite.name}
            </RemovableChipButton>
            <P16 className="red-text">
              {isAlreadyFavorite() !== undefined && t("Already in favorites")}
            </P16>
          </Results>
        ) : (
          <div className="search-container">
            <L12GreyBold>{t("Search")}</L12GreyBold>
            <SimpleSearch
              placeholder={t("Groups, Locations, People")}
              forceBringToTop
              onSelect={(result) => {
                setSelectedFavorite(result)
              }}
            />
          </div>
        )}
      </Content>
    </Dialog>
  )
}
