import { gql, Reference, useMutation } from "@apollo/client"
import { CustomErrorFragment } from "graphql/misc/customErrorFragment"
import { SLOTS_QUERY } from "graphql/slots/useWeeklyUserSlots"
import useUser from "graphql/users/useUser"
import useCustomErrorToast from "hooks/useCustomErrorToast"
import { CustomError, Slot } from "types"

import { SlotTemplateFragment } from "./fragments/slotTemplateFragment"
import { SLOT_TEMPLATES_QUERY } from "./useUserSlotTemplates"

const SET_SLOT_TEMPLATES_MUTATION = gql`
  ${SlotTemplateFragment}
  ${CustomErrorFragment}
  mutation setSlotTemplates(
    $timestamps: [String]!
    $value: String!
    $locationId: ID
    $label: String
  ) {
    setSlotTemplates(
      timestamps: $timestamps
      value: $value
      locationId: $locationId
      label: $label
    ) {
      slots {
        ...SlotTemplateFragment
      }
      errors {
        ...CustomErrorFragment
      }
    }
  }
`

interface SlotWithGraphqlAddons extends Slot {
  __typename: string
}

export default function useSetSlotTemplatesUTC() {
  const { user: me } = useUser()

  const { addToastError } = useCustomErrorToast()
  const [setSlotTemplates] = useMutation<{
    setSlotTemplates: {
      slots: SlotWithGraphqlAddons[]
      errors: CustomError[]
    }
  }>(SET_SLOT_TEMPLATES_MUTATION, {
    update(cache, { data }) {
      if (data?.setSlotTemplates) {
        const { setSlotTemplates } = data
        const { slots: slotsFromQuery, errors } = setSlotTemplates
        cache.modify({
          fields: {
            slotTemplates(existing: Reference[] = []): Reference[] {
              const untouchedRefs = existing.filter((es: Reference) => {
                return !slotsFromQuery.find((s: SlotWithGraphqlAddons) => {
                  return es.__ref === `Slot:${s.id}`
                })
              })
              const newRefs = slotsFromQuery
                .map((slot: SlotWithGraphqlAddons) =>
                  cache.writeFragment({
                    data: slot,
                    fragment: SlotTemplateFragment,
                  })
                )
                .filter((r): r is Reference => r !== undefined)

              return [...untouchedRefs, ...newRefs]
            },
          },
        })
        if (errors.length > 0) {
          const message = errors[0].message
          addToastError({ message })
        }
      }
    },
  })
  return (
    timestamps: number[],
    value: string,
    locationId: string | null,
    label: string | null
  ) => {
    const after = `${timestamps.length === 0 ? undefined : timestamps[0]}`
    const before = `${
      timestamps.length === 0 ? undefined : timestamps[timestamps.length - 1]
    }`

    const refetchUserSlots =
      before && after && me
        ? [
            {
              query: SLOTS_QUERY,
              variables: {
                userId: me.id,
                before,
                after,
              },
            },
          ]
        : []

    const refetchSlotTemplates = me
      ? [
          {
            query: SLOT_TEMPLATES_QUERY,
            variables: {
              userId: me.id,
            },
          },
        ]
      : []

    return setSlotTemplates({
      variables: {
        timestamps: timestamps.map((t) => `${t}`),
        value,
        locationId,
        label,
      },
      refetchQueries: [
        ...refetchSlotTemplates,
        ...refetchUserSlots,
        "getSlots",
      ],
    }).catch(({ message }) => {
      addToastError({ message })
    })
  }
}
