import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useUser from "graphql/users/useUser"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { colors } from "ui"
import {
  Bicycle,
  DashboardSpeed,
  Drawer,
  Event,
  MeetingRoom,
  Settings,
} from "ui/icons"

import { MenuList } from "../components/MenuList"
import { EventItem, SettingsItem } from "./SettingsItem/SettingsItem"

const Layout = styled.div`
  border-bottom: solid 1px ${colors.grey3};
`

export function MenuSettings() {
  const t = useTranslate()
  const { user: me } = useUser()
  const { isMobile } = useMediaQueries()
  const { setViewMode } = usePlanningContext()

  const disableEvents = Boolean(me?.company.flags.disableEvents)

  if (isMobile) return <Layout />
  else
    return (
      <Layout onClick={() => setViewMode("WEEK")}>
        <MenuList>
          {process.env.NODE_ENV === "development" && (
            <SettingsItem
              title="Apollo Sandbox"
              icon={
                <Bicycle
                  strokeWidth={location.pathname === "/apollo" ? 2 : 1.5}
                />
              }
              path="/apollo"
            />
          )}
          {
            <SettingsItem
              title={t("Statistics")}
              icon={
                <DashboardSpeed
                  strokeWidth={location.pathname === "/statistics" ? 2 : 1.5}
                />
              }
              path="/statistics"
            />
          }
          <SettingsItem
            title={t("Directory")}
            icon={
              <Drawer
                strokeWidth={location.pathname === "/directory" ? 2 : 1.5}
              />
            }
            path="/directory"
          />
          <SettingsItem
            title={t("Meeting rooms")}
            icon={
              <MeetingRoom
                strokeWidth={location.pathname === "/meeting-rooms" ? 2 : 1.5}
              />
            }
            path="/meeting-rooms"
          />

          {!disableEvents && (
            <EventItem
              title={t("Events")}
              icon={
                <Event
                  strokeWidth={location.pathname === "/events" ? 1 : 0.5}
                />
              }
              path="/events"
            />
          )}
          {me?.role === "admin" && (
            <SettingsItem
              title={t("Admin")}
              icon={
                <Settings
                  strokeWidth={location.pathname.includes("/admin") ? 2 : 1.5}
                />
              }
              path="/admin"
            />
          )}
        </MenuList>
      </Layout>
    )
}
