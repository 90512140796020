import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useTranslate from "intl/useTranslate"
import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { loginWithToken } from "utils/firebase"

async function loginWithOIDCCode(code: string, providerId: string) {
  const res = await fetch(
    `${process.env.REACT_APP_API}/auth/user/login/oidc/code`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ code, providerId }),
    }
  )
  if (res.status === 200) {
    const { token } = await res.json()
    await loginWithToken(token)
  }
}

export default function useSigninWithOIDCCode(providerId?: string | null) {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const code = urlParams.get("code")
  const { addToast } = useToasts()
  const history = useHistory()
  const t = useTranslate()
  useEffect(() => {
    if (code && providerId) {
      loginWithOIDCCode(code, providerId).catch((e) => {
        addToast(t("Something went wrong"), { appearance: "error" })
        console.error("Error while login with OIDC.", e)
        history.replace("/")
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, providerId])
}
