import React from "react"
import styled from "styled-components"
import { CompanyFile } from "types"
import { Button, ExternalLink } from "ui"
import { Download } from "ui/icons"

const StyledButton = styled(Button)`
  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`

interface PropsType {
  file: CompanyFile
}

export default function FileItem({ file }: PropsType) {
  return (
    <ExternalLink href={file.url} rel="noopener noreferrer" download>
      <StyledButton fullWidth secondary>
        <Download style={{ marginRight: 8 }} />
        <span>{file.name}</span>
      </StyledButton>
    </ExternalLink>
  )
}
