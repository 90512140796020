import { ApolloError, gql, useMutation } from "@apollo/client"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import { SLOTS_QUERY } from "graphql/slots/usePlanningUserSlots"
import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import { useState } from "react"
import UTCDate from "utils/UTCDate"

import { TeamDayFragment } from "./fragments/teamDayFragment"
import updateCache from "./updateCache"

const MUTATION = gql`
  ${TeamDayFragment}
  mutation addTeamDay($timestamp: String!, $userIds: [ID!]!, $locationId: ID!) {
    addTeamDay(
      timestamp: $timestamp
      userIds: $userIds
      locationId: $locationId
    ) {
      ...TeamDayFragment
    }
  }
`

export default function useAddTeamDay() {
  const { addToast } = useToasts()
  const t = useTranslate()
  const { user: me } = useUser()
  const { from, to } = usePlanningContext()

  const team = me?.team ?? []

  const [mutate] = useMutation(MUTATION, {
    update: (cache, { data }) =>
      updateCache({
        cache,
        data,
        mutationName: "addTeamDay",
      }),
    refetchQueries: [
      "getSlots",
      ...team.map(({ id }) => ({
        query: SLOTS_QUERY,
        variables: {
          userId: id,
          after: `${from.getTime()}`,
          before: `${to.getTime()}`,
        },
      })),
    ],
  })
  const [error, setError] = useState<ApolloError | null>(null)
  const [loading, setLoading] = useState(false)
  const addTeamDay = async (
    date: UTCDate,
    userIds: string[],
    locationId: string
  ) => {
    setLoading(true)
    try {
      await mutate({
        variables: { timestamp: `${date.getTime()}`, userIds, locationId },
      })
      addToast(t("creation team day confirmation"), {
        appearance: "success",
      })
    } catch (e) {
      setError(e)
      addToast(e.message, { appearance: "error" })
    } finally {
      setLoading(false)
    }
  }
  return {
    loading,
    error,
    addTeamDay,
  }
}
