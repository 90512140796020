import { gql, useMutation } from "@apollo/client"

import { GroupFragment } from "./fragments/groupFragment"
import updateGroupInCache from "./updateGroupInCache"

const JOIN_GROUP_MUTATION = gql`
  ${GroupFragment}
  mutation joinGroup($groupId: ID!, $userId: ID) {
    joinGroup(groupId: $groupId, userId: $userId) {
      ...GroupFragment
    }
  }
`

const LEAVE_GROUP_MUTATION = gql`
  ${GroupFragment}
  mutation leaveGroup($groupId: ID!, $userId: ID) {
    leaveGroup(groupId: $groupId, userId: $userId) {
      ...GroupFragment
    }
  }
`
export default function useJoinOrLeaveGroup() {
  const [mutateJoin] = useMutation(JOIN_GROUP_MUTATION, {
    update: (cache, { data }) =>
      updateGroupInCache({ cache, data, mutationName: "joinGroup" }),
  })
  const joinGroup = async (groupId: string, userId?: string) => {
    try {
      await mutateJoin({ variables: { groupId, userId } })
    } catch (e) {
      console.error(e.message)
    }
  }

  const [mutateLeave] = useMutation(LEAVE_GROUP_MUTATION)
  const leaveGroup = async (groupId: string, userId?: string) => {
    try {
      await mutateLeave({
        variables: { groupId, userId },

        update: (cache, { data }) =>
          updateGroupInCache({
            cache,
            data,
            mutationName: "leaveGroup",
            removeFromUserGroups: userId === undefined,
          }),
      })
    } catch (e) {
      console.error(e.message)
    }
  }

  return {
    joinGroup,
    leaveGroup,
  }
}
