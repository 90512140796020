import { IndexedDay } from "types"

type Translator = (
  id: string,
  values?: Record<string, string> | undefined
) => string

export const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
]

export function getIndexedDays(
  locale: string,
  excludeWeekends?: boolean
): IndexedDay[] {
  const indexedDays = days.map((day, n) => ({ name: day, index: n }))
  const sunday = indexedDays[0]
  const saturday = indexedDays[indexedDays.length - 1]
  const weekDays = indexedDays.slice(1, indexedDays.length - 1)
  if (locale === "en") return excludeWeekends ? weekDays : indexedDays
  return excludeWeekends ? weekDays : [...weekDays, saturday, sunday]
}

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

export type DateIncrementType =
  | "YEAR"
  | "SEMESTER"
  | "QUARTER"
  | "MONTH"
  | "WEEK"
  | "DAY"
  | "HALF-DAY"
  | "HOUR"
  | "MINUTE"
  | "SECOND"

export type DateClampType =
  | "YEAR"
  | "SEMESTER"
  | "QUARTER"
  | "MONTH"
  | "WEEK-MONDAY"
  | "DAY"
  | "HALF-DAY"
  | "HOUR"
  | "MINUTE"
  | "SECOND"
  | "MILLISECOND"
  | "NEXT-YEAR"
  | "NEXT-SEMESTER"
  | "NEXT-QUARTER"
  | "NEXT-MONTH"
  | "NEXT-MONDAY"
  | "NEXT-FRIDAY"
  | "NEXT-SATURDAY"
  | "NEXT-SUNDAY"
  | "NEXT-DAY"
  | "NEXT-HALF-DAY"
  | "NEXT-HOUR"
  | "NEXT-MINUTE"

function clampDate(date: Date, clamp?: DateClampType): Date {
  const clampedDate = new Date(date)
  switch (clamp) {
    case "YEAR":
      clampedDate.setHours(0, 0, 0, 0)
      clampedDate.setDate(1)
      clampedDate.setMonth(0)
      break
    case "NEXT-YEAR":
      clampedDate.setHours(0, 0, 0, 0)
      clampedDate.setDate(1)
      clampedDate.setMonth(12)
      break
    case "SEMESTER":
      if (clampedDate.getMonth() < 6) {
        clampedDate.setHours(0, 0, 0, 0)
        clampedDate.setMonth(0)
        clampedDate.setDate(1)
      }
      if (clampedDate.getMonth() >= 6) {
        clampedDate.setHours(0, 0, 0, 0)
        clampedDate.setMonth(6)
        clampedDate.setDate(1)
      }
      break
    case "NEXT-SEMESTER":
      if (clampedDate.getMonth() <= 5) {
        clampedDate.setHours(0, 0, 0, 0)
        clampedDate.setMonth(6)
        clampedDate.setDate(1)
      } else if (clampedDate.getMonth() > 5) {
        clampedDate.setHours(0, 0, 0, 0)
        clampedDate.setMonth(11)
        clampedDate.setDate(1)
      }
      break
    case "QUARTER":
      if (clampedDate.getMonth() <= 2) {
        clampedDate.setHours(0, 0, 0, 0)
        clampedDate.setMonth(0)
        clampedDate.setDate(1)
      } else if (clampedDate.getMonth() > 2 && clampedDate.getMonth() <= 5) {
        clampedDate.setHours(0, 0, 0, 0)
        clampedDate.setMonth(3)
        clampedDate.setDate(1)
      } else if (clampedDate.getMonth() > 5 && clampedDate.getMonth() <= 8) {
        clampedDate.setHours(0, 0, 0, 0)
        clampedDate.setMonth(6)
        clampedDate.setDate(1)
      } else if (clampedDate.getMonth() > 8) {
        clampedDate.setHours(0, 0, 0, 0)
        clampedDate.setMonth(9)
        clampedDate.setDate(1)
      }
      break
    case "NEXT-QUARTER":
      if (clampedDate.getMonth() <= 2) {
        clampedDate.setHours(0, 0, 0, 0)
        clampedDate.setMonth(3)
        clampedDate.setDate(1)
      } else if (clampedDate.getMonth() > 2 && clampedDate.getMonth() <= 5) {
        clampedDate.setHours(0, 0, 0, 0)
        clampedDate.setMonth(6)
        clampedDate.setDate(1)
      } else if (clampedDate.getMonth() > 5 && clampedDate.getMonth() <= 8) {
        clampedDate.setHours(0, 0, 0, 0)
        clampedDate.setMonth(9)
        clampedDate.setDate(1)
      } else if (clampedDate.getMonth() > 8) {
        clampedDate.setHours(0, 0, 0, 0)
        clampedDate.setMonth(12)
        clampedDate.setDate(1)
      }
      break
    case "MONTH":
      clampedDate.setHours(0, 0, 0, 0)
      clampedDate.setMonth(clampedDate.getMonth())
      clampedDate.setDate(1)
      break
    case "NEXT-MONTH":
      clampedDate.setHours(0, 0, 0, 0)
      clampedDate.setMonth(clampedDate.getMonth() + 1)
      clampedDate.setDate(1)
      break
    case "WEEK-MONDAY":
      clampedDate.setHours(0, 0, 0, 0)
      if (clampedDate.getDay() > 0) {
        // non sundays
        clampedDate.setDate(clampedDate.getDate() - clampedDate.getDay() + 1)
      } else {
        // sundays
        clampedDate.setDate(clampedDate.getDate() - 6)
      }
      break
    case "NEXT-MONDAY":
      clampedDate.setHours(0, 0, 0, 0)
      if (clampedDate.getDay() > 0) {
        // non sundays
        clampedDate.setDate(clampedDate.getDate() - clampedDate.getDay() + 8)
      } else {
        // sundays
        clampedDate.setDate(clampedDate.getDate() + 1)
      }
      break
    case "NEXT-FRIDAY":
      clampedDate.setHours(0, 0, 0, 0)
      if (clampedDate.getDay() >= 5) {
        clampedDate.setDate(clampedDate.getDate() + 12 - clampedDate.getDay())
      } else {
        clampedDate.setDate(clampedDate.getDate() + 5 - clampedDate.getDay())
      }
      break
    case "NEXT-SATURDAY":
      clampedDate.setHours(0, 0, 0, 0)
      if (clampedDate.getDay() === 6) {
        clampedDate.setDate(clampedDate.getDate() + 7)
      } else {
        clampedDate.setDate(clampedDate.getDate() + 6 - clampedDate.getDay())
      }
      break
    case "NEXT-SUNDAY":
      clampedDate.setHours(0, 0, 0, 0)
      clampedDate.setDate(clampedDate.getDate() + 7 - clampedDate.getDay())
      break
    case "DAY":
      clampedDate.setHours(0, 0, 0, 0)
      break
    case "NEXT-DAY":
      clampedDate.setHours(24, 0, 0, 0)
      break
    case "HALF-DAY":
      clampedDate.setHours(date.getHours() >= 12 ? 12 : 0, 0, 0, 0)
      break
    case "NEXT-HALF-DAY":
      clampedDate.setHours(date.getHours() >= 12 ? 24 : 12, 0, 0, 0)
      break
    case "HOUR":
      clampedDate.setMinutes(0, 0, 0)
      break
    case "NEXT-HOUR":
      clampedDate.setMinutes(60, 0, 0)
      break
    case "MINUTE":
      clampedDate.setSeconds(0, 0)
      break
    case "NEXT-MINUTE":
      clampedDate.setSeconds(60, 0)
      break
    case "SECOND":
      clampedDate.setMilliseconds(0)
      break
    default:
  }
  return clampedDate
}

export default class LocalDate extends Date {
  constructor(
    dateOrClampOrTime?: number | Date | DateClampType,
    clamp?: DateClampType
  ) {
    if (typeof dateOrClampOrTime === "number") {
      super(clampDate(new Date(dateOrClampOrTime), clamp))
    } else if (dateOrClampOrTime instanceof Date) {
      super(clampDate(dateOrClampOrTime, clamp))
    } else {
      super(clampDate(new Date(), dateOrClampOrTime))
    }
  }
  compare(date: Date, clamp?: DateClampType) {
    if (clamp === undefined) {
      return this.getTime() - date.getTime()
    }
    const a = clampDate(this, clamp)
    const b = clampDate(date, clamp)
    return a.getTime() === b.getTime()
  }

  distanceTo(
    date: Date,
    unit: DateIncrementType,
    absoluteValue = true,
    includeWeekends = true
  ) {
    let count = 0
    const copy = new LocalDate(date)
    if (copy.getTime() < this.getTime()) {
      while (copy.getTime() < this.getTime()) {
        copy.shift(unit, 1)
        if (includeWeekends || !copy.isWeekend()) {
          count--
        }
      }
    } else if (copy.getTime() > this.getTime()) {
      while (copy.getTime() > this.getTime()) {
        copy.shift(unit, -1)
        if (includeWeekends || !copy.isWeekend()) {
          count++
        }
      }
    }
    // dates are equal
    return absoluteValue ? Math.abs(count) : count
  }

  unitsTo(date: Date, unit: DateIncrementType, includeWeekends = true) {
    const copy = new LocalDate(date)
    const units: LocalDate[] = []
    if (copy.getTime() < this.getTime()) {
      while (copy.getTime() < this.getTime()) {
        if (includeWeekends || !copy.isWeekend()) {
          units.push(new LocalDate(copy))
        }
        copy.shift(unit, 1)
      }
    } else if (copy.getTime() > this.getTime()) {
      while (copy.getTime() > this.getTime()) {
        if (includeWeekends || !copy.isWeekend()) {
          units.push(new LocalDate(copy))
        }
        copy.shift(unit, -1)
      }
    }
    // dates are equal
    return units
  }

  isEqual(date: Date, clamp?: DateClampType) {
    if (clamp === undefined) {
      return this.getTime() === date.getTime()
    }
    const a = clampDate(this, clamp)
    const b = clampDate(date, clamp)
    return a.getTime() === b.getTime()
  }

  isToday() {
    return this.isEqual(new Date(), "DAY")
  }

  isMorning(): boolean {
    return this.getHours() < 12
  }

  isWeekend(): boolean {
    return this.getDay() === 0 || this.getDay() === 6
  }

  increment(inc: DateIncrementType) {
    this.shift(inc, 1)
  }

  decrement(inc: DateIncrementType) {
    this.shift(inc, -1)
  }

  shift(inc: DateIncrementType, quantity: number) {
    switch (inc) {
      case "YEAR":
        this.setFullYear(this.getFullYear() + quantity)
        break
      case "MONTH":
        this.setMonth(this.getMonth() + quantity)
        break
      case "WEEK":
        this.setDate(this.getDate() + 7 * quantity)
        break
      case "DAY":
        this.setDate(this.getDate() + quantity)
        break
      case "HALF-DAY":
        this.setHours(this.getHours() + 12 * quantity)
        break
      case "HOUR":
        this.setHours(this.getHours() + quantity)
        break
      case "MINUTE":
        this.setMinutes(this.getMinutes() + quantity)
        break
      case "SECOND":
        this.setSeconds(this.getSeconds() + quantity)
        break
    }
  }

  getDayAsString() {
    return days[this.getDay()]
  }

  getMonthAsString() {
    return months[this.getMonth()]
  }

  getWeekNumber(): number {
    // const firstDayOfDateYear = new LocalDate(this, "YEAR")
    // const dateNumber = Math.floor(
    //   (this.getTime() - firstDayOfDateYear.getTime()) / (24 * 60 * 60 * 1000)
    // )
    // return Math.ceil((this.getDay() + 1 + dateNumber) / 7)

    const date = new Date(
      Date.UTC(this.getFullYear(), this.getMonth(), this.getDate())
    )
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7))
    // Get first day of year
    const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1))
    // Calculate full weeks to nearest Thursday
    return Math.ceil(
      ((date.getTime() - yearStart.getTime()) / 86400000 + 1) / 7
    )
  }

  format(format: string, translator?: Translator): string {
    const getNumber = (n: number) => (n < 10 ? `0${n}` : `${n}`)
    const t = translator ? translator : (key: string) => key
    switch (format) {
      case "long":
        return `
        ${t(days[this.getDay()])} ${this.getDate()} ${t(
          months[this.getMonth()]
        )} ${this.getFullYear()}`
      case "short":
        return `${this.getDate()} ${t(
          months[this.getMonth()]
        )} ${this.getFullYear()}`
      case "DD/MM":
        return `${getNumber(this.getDate())}/${getNumber(this.getMonth() + 1)}`
      case "DD/MM/YY":
        return `${getNumber(this.getDate())}/${getNumber(
          this.getMonth() + 1
        )}/${`${this.getFullYear()}`.substring(2)}`
      case "MM/DD/YY":
        return `${getNumber(this.getMonth() + 1)}/${getNumber(
          this.getDate()
        )}/${`${this.getFullYear()}`.substring(2)}`
      case "YYYY-MM-DD":
        return `${`${this.getFullYear()}`}-${getNumber(
          this.getMonth() + 1
        )}-${getNumber(this.getDate())}`
      case "HH:MM":
        return `${String(this.getHours()).padStart(2, "0")}:${String(
          this.getMinutes()
        ).padStart(2, "0")}`
      case "DD/MM/YY HH:MM":
        return `${this.format("DD/MM/YY")} ${this.format("HH:MM")}`
      case "MM/DD/YY HH:MM":
        return `${this.format("MM/DD/YY")} ${this.format("HH:MM")}`
      case "day-DD-MM":
        return `${t(days[this.getDay()])} ${this.getDate()} ${t(
          months[this.getUTCMonth()]
        )}`

      default:
        return this.toString()
    }
  }
}

export function formatLocalDate(
  date: Date,
  format: string,
  translator?: Translator
) {
  return new LocalDate(date).format(format, translator)
}
