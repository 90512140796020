import { gql, useMutation } from "@apollo/client"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import { GET_USER_QUERY } from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"

const MUTATION = gql`
  mutation deleteUserPhoto {
    deleteUserPhoto
  }
`

export default function useDeleteUserPhoto() {
  const { addToast } = useToasts()
  const [mutate] = useMutation(MUTATION)
  const t = useTranslate()

  return async () => {
    try {
      await mutate({
        refetchQueries: [
          {
            query: GET_USER_QUERY,
            variables: { user: null },
          },
        ],
      })
      addToast(t("your profile photo has been deleted"), {
        appearance: "success",
      })
    } catch (e) {
      console.error(e.message)
      addToast(e.message, { appearance: "error" })
    }
  }
}
