import React from "react"
import { Equipment, TeamDay, UserEquipment, UTCSlot, ViewModeType } from "types"
import UTCDate from "utils/UTCDate"

import getUTCSlotProps from "../utils/getUTCSlotProps"
import EditableUserSlotEntry from "./EditableUserSlotEntry/EditableUserSlotEntry"
import TeamDayBadge from "./TeamDayBadge/TeamDayBadge"

interface PropsType {
  index: number
  slot: UTCSlot
  slots: UTCSlot[]
  actualSelectedSlots: UTCSlot[]
  equipments: Equipment[]
  userEquipments: UserEquipment[]
  userTeamDays: TeamDay[]
  editionTimeFrame: {
    from?: UTCDate | undefined
    to?: UTCDate | undefined
  }
  isActive: boolean
  hoverInfo: string
  showHoverInfo: boolean
  viewMode: ViewModeType
  userShowWeekends: boolean
  onMouseEnter: () => void
  onMouseLeave: () => void
}

interface SlotPropsType {
  isFirst: boolean
  isLast: boolean
  isSelected: boolean
  slot: UTCSlot
  equipments: Equipment[]
  userEquipments: UserEquipment[]
  isActive: boolean
  hoverInfo: string
  isTeamDayMorning: boolean
  showHoverInfo: boolean
  onMouseEnter: () => void
  onMouseLeave: () => void
}

export function EditableUserSlotWithLocation({
  slot,
  equipments,
  userEquipments,
  isFirst,
  isLast,
  isSelected,
  isActive,
  hoverInfo,
  isTeamDayMorning,
  showHoverInfo,
  onMouseEnter,
  onMouseLeave,
}: SlotPropsType) {
  const slotEquipments = userEquipments.filter((e) => e.slotId === slot.id)

  return (
    <div style={{ position: "relative" }}>
      <EditableUserSlotEntry
        key={slot.id}
        value={slot.value}
        equipments={slotEquipments
          .map((ue) => equipments.find((e) => e.id === ue.equipmentId))
          .filter((e): e is Equipment => e !== undefined)}
        nbGuests={slot.guests}
        isFirst={isFirst}
        isLast={isLast}
        hoverInfo={hoverInfo}
        isSelected={isSelected}
        isActive={isActive}
        showHoverInfo={showHoverInfo}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      {isTeamDayMorning && <TeamDayBadge />}
    </div>
  )
}

export function EditableUserSlotWithoutLocation({
  slot,
  isFirst,
  isLast,
  isSelected,
  isActive,
  hoverInfo,
  isTeamDayMorning,
  showHoverInfo,
  onMouseEnter,
  onMouseLeave,
}: SlotPropsType) {
  return (
    <div style={{ position: "relative" }}>
      <EditableUserSlotEntry
        key={slot.id}
        value={slot.value}
        equipments={[]}
        nbGuests={0}
        isFirst={isFirst}
        isLast={isLast}
        hoverInfo={hoverInfo}
        isSelected={isSelected}
        isActive={isActive}
        showHoverInfo={showHoverInfo}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      {isTeamDayMorning && <TeamDayBadge />}
    </div>
  )
}

export default function EditableUserSlot({
  index,
  slot,
  slots,
  userTeamDays,
  actualSelectedSlots,
  editionTimeFrame,
  isActive,
  viewMode,
  userShowWeekends,
  ...rest
}: PropsType) {
  const slotProps = getUTCSlotProps(
    index,
    slot,
    slots,
    actualSelectedSlots,
    viewMode,
    userShowWeekends
  )

  const isSelected = () => {
    if (
      editionTimeFrame.from === undefined ||
      editionTimeFrame.to === undefined
    )
      return slotProps.isSelected
    if (
      slot.date >= editionTimeFrame.from &&
      slot.date <= editionTimeFrame.to
    ) {
      return true
    }
    return false
  }

  const currentTeamDay = userTeamDays.find((td) => {
    const teamDayDate = new UTCDate(new Date(td.date), "DAY")
    const slotDate = new UTCDate(slot.date, "DAY")
    return teamDayDate.getTime() === slotDate.getTime()
  })

  const isTeamDayMorning = currentTeamDay !== undefined && slot.date.isMorning()

  const isFirst = isSelected()
    ? slot.date.getTime() === editionTimeFrame.from?.getTime()
    : slotProps.isFirst

  const isLast = isSelected()
    ? slot.date.getTime() === editionTimeFrame.to?.getTime()
    : slotProps.isLast

  if (slot.locationId)
    return (
      <EditableUserSlotWithLocation
        isFirst={isFirst}
        isLast={isLast}
        isSelected={isSelected()}
        isActive={isActive}
        slot={slot}
        isTeamDayMorning={isTeamDayMorning}
        {...rest}
      />
    )

  return (
    <EditableUserSlotWithoutLocation
      isFirst={isFirst}
      isLast={isLast}
      isSelected={isSelected()}
      isActive={isActive}
      slot={slot}
      isTeamDayMorning={isTeamDayMorning}
      {...rest}
    />
  )
}
