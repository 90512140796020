import PageContent from "components/Layout/PageLayout/PageContent/PageContent"
import PageHeader from "components/Layout/PageLayout/PageHeader/PageHeader"
import MobileNavBack from "components/Layout/PageLayout/PageHeader/PageTitle/MobileNavBack/MobileNavBack"
import PageTitle from "components/Layout/PageLayout/PageHeader/PageTitle/PageTitle"
import PageLayout from "components/Layout/PageLayout/PageLayout"
import useUser from "graphql/users/useUser"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { colors, Tabs } from "ui"
import { Lock } from "ui/icons"
import UTCDate, { UTCDateClampType } from "utils/UTCDate"

import AdminGroupsStats from "./AdminGroupsStats/AdminGroupsStats"
import AdminTeamsStats from "./AdminTeamsStats/AdminTeamsStats"
import CompletionRate from "./CompletionRate/CompletionRate"
import OccupancyRate from "./OccupancyRate/OccupancyRate"
import TeamStats from "./TeamStats/TeamStats"
import WeekDays from "./WeekDays/WeekDays"
import WorkingMode from "./WorkingMode/WorkingMode"

export default function StatisticsPage() {
  const t = useTranslate()
  const { user: me } = useUser()
  const { isMobile } = useMediaQueries()

  const [pageHeaderHeight, setPageHeaderHeight] = useState<{
    header?: number
    tabsHeader?: number
  }>({})

  const [selectedPeriod, setSelectedPeriod] = useState<
    UTCDateClampType | "personalized"
  >("WEEK-MONDAY")
  const editSelectedPeriod = (value: UTCDateClampType | "personalized") =>
    setSelectedPeriod(value)

  const [from, setFrom] = useState<UTCDate>(new UTCDate("WEEK-MONDAY"))
  const [to, setTo] = useState<UTCDate>(new UTCDate("NEXT-SUNDAY"))
  const editFromDate = (value: UTCDate) => setFrom(value)
  const editToDate = (value: UTCDate) => setTo(value)

  const [enableWeekends, setEnableWeekends] = useState<boolean>(false)
  const editEnableWeekends = (value: boolean) => setEnableWeekends(value)

  const { search } = useLocation()
  const defaultTab = new URLSearchParams(search).get("defaultTab")

  useEffect(() => {
    if (selectedPeriod === "YEAR") {
      setFrom(new UTCDate("YEAR"))
      const to = new UTCDate("NEXT-YEAR")
      to.shift("DAY", -1)
      setTo(to)
      return
    }
    if (selectedPeriod === "SEMESTER") {
      setFrom(new UTCDate("SEMESTER"))
      const to = new UTCDate("NEXT-SEMESTER")
      to.shift("DAY", -1)
      setTo(to)
      return
    }
    if (selectedPeriod === "QUARTER") {
      setFrom(new UTCDate("QUARTER"))
      const to = new UTCDate("NEXT-QUARTER")
      to.shift("DAY", -1)
      setTo(to)
      return
    }
    if (selectedPeriod === "MONTH") {
      setFrom(new UTCDate("MONTH"))
      const to = new UTCDate("NEXT-MONTH")
      to.shift("DAY", -1)
      setTo(to)
      return
    }
    if (selectedPeriod === "WEEK-MONDAY") {
      setFrom(new UTCDate("WEEK-MONDAY"))
      setTo(new UTCDate("NEXT-SUNDAY"))
      return
    }
    if (selectedPeriod === "personalized") return

    setFrom(new UTCDate("WEEK-MONDAY"))
    setTo(new UTCDate("NEXT-SUNDAY"))
  }, [selectedPeriod])

  const tabs: any[] = [
    {
      label: t("occupation rate"),
      value: 0,
      component: (
        <OccupancyRate
          from={from}
          to={to}
          selectedPeriod={selectedPeriod}
          enableWeekends={enableWeekends}
          pageHeaderHeight={pageHeaderHeight}
          editEnableWeekends={editEnableWeekends}
          editSelectedPeriod={editSelectedPeriod}
          editFromDate={editFromDate}
          editToDate={editToDate}
        />
      ),
    },
    {
      label: t("completion rate"),
      value: 1,
      component: (
        <CompletionRate
          from={from}
          to={to}
          selectedPeriod={selectedPeriod}
          enableWeekends={enableWeekends}
          pageHeaderHeight={pageHeaderHeight}
          editEnableWeekends={editEnableWeekends}
          editSelectedPeriod={editSelectedPeriod}
          editFromDate={editFromDate}
          editToDate={editToDate}
        />
      ),
    },
    {
      label: t("working mode"),
      value: 2,
      component: (
        <WorkingMode
          from={from}
          to={to}
          selectedPeriod={selectedPeriod}
          enableWeekends={enableWeekends}
          pageHeaderHeight={pageHeaderHeight}
          editEnableWeekends={editEnableWeekends}
          editSelectedPeriod={editSelectedPeriod}
          editFromDate={editFromDate}
          editToDate={editToDate}
        />
      ),
    },
    {
      label: t("week days"),
      value: 3,
      component: (
        <WeekDays
          from={from}
          to={to}
          selectedPeriod={selectedPeriod}
          enableWeekends={enableWeekends}
          pageHeaderHeight={pageHeaderHeight}
          editEnableWeekends={editEnableWeekends}
          editSelectedPeriod={editSelectedPeriod}
          editFromDate={editFromDate}
          editToDate={editToDate}
        />
      ),
    },
  ]

  if (me?.team && me.team.length > 0) {
    tabs.push({
      label: t("team stats"),
      value: 4,
      component: (
        <TeamStats
          from={from}
          to={to}
          selectedPeriod={selectedPeriod}
          pageHeaderHeight={pageHeaderHeight}
          editSelectedPeriod={editSelectedPeriod}
          editFromDate={editFromDate}
          editToDate={editToDate}
          variant="team"
        />
      ),
      icon: (
        <Lock
          style={{
            color: colors.grey1,
            height: 16,
            width: 16,
            marginTop: "4px",
          }}
        />
      ),
    })
  }

  if (me?.role === "admin") {
    tabs.push(
      {
        label: t("admin user stats"),
        value: 5,
        component: (
          <TeamStats
            from={from}
            to={to}
            selectedPeriod={selectedPeriod}
            pageHeaderHeight={pageHeaderHeight}
            editSelectedPeriod={editSelectedPeriod}
            editFromDate={editFromDate}
            editToDate={editToDate}
            variant="admin"
          />
        ),
        icon: (
          <Lock
            style={{
              color: colors.grey1,
              height: 16,
              width: 16,
              marginTop: "4px",
            }}
          />
        ),
      },
      {
        label: t("admin teams stats"),
        value: 6,
        component: (
          <AdminTeamsStats
            from={from}
            to={to}
            selectedPeriod={selectedPeriod}
            pageHeaderHeight={pageHeaderHeight}
            editSelectedPeriod={editSelectedPeriod}
            editFromDate={editFromDate}
            editToDate={editToDate}
          />
        ),
        icon: (
          <Lock
            style={{
              color: colors.grey1,
              height: 16,
              width: 16,
              marginTop: "4px",
            }}
          />
        ),
      },
      {
        label: t("admin groups stats"),
        value: 7,
        component: (
          <AdminGroupsStats
            from={from}
            to={to}
            selectedPeriod={selectedPeriod}
            pageHeaderHeight={pageHeaderHeight}
            editSelectedPeriod={editSelectedPeriod}
            editFromDate={editFromDate}
            editToDate={editToDate}
          />
        ),
        icon: (
          <Lock
            style={{
              color: colors.grey1,
              height: 16,
              width: 16,
              marginTop: "4px",
            }}
          />
        ),
      }
    )
  }

  return (
    <PageLayout contentNotScrollable>
      {isMobile ? (
        <PageHeader
          ref={(el) =>
            setPageHeaderHeight((prev) => {
              if (prev.header !== undefined) return prev
              return {
                ...prev,
                header: el?.getBoundingClientRect().height,
              }
            })
          }
        >
          <MobileNavBack />
          <PageTitle title={t("Statistics")} />
        </PageHeader>
      ) : (
        <PageHeader
          ref={(el) =>
            setPageHeaderHeight((prev) => {
              if (prev.header !== undefined) return prev
              return {
                ...prev,
                header: el?.getBoundingClientRect().height,
              }
            })
          }
        >
          <PageTitle title={t("Statistics")} />
        </PageHeader>
      )}
      <PageContent>
        <Tabs
          tabs={tabs}
          defaultValue={defaultTab ? Number(defaultTab) : 0}
          getBoudingRect={(rect) => {
            setPageHeaderHeight((prev) => {
              if (prev.tabsHeader !== undefined) return prev
              return {
                ...prev,
                tabsHeader: rect.height,
              }
            })
          }}
        />
      </PageContent>
    </PageLayout>
  )
}
