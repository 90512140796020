import useTranslate from "intl/useTranslate"
import React from "react"
import { AttendeeStatus } from "types"
import { L12, P16 } from "ui"
import { User as UserIcon } from "ui/icons"

import { Item } from "../Item"

interface PropsType {
  guest: {
    email: string
    attendingStatus: AttendeeStatus
    isOrganizer: boolean
  }
}

export default function GuestEmailItem({
  guest: { email, attendingStatus, isOrganizer },
}: PropsType) {
  const t = useTranslate()

  return (
    <Item>
      <UserIcon />
      <P16>{email}</P16>
      {isOrganizer && <L12 className="organizer-badge">{t("organizer")}</L12>}

      <L12
        className={`attending-status ${
          attendingStatus !== "attending"
            ? "attending-status--error"
            : "attending-status--success"
        }`}
      >
        {t(attendingStatus)}
      </L12>
    </Item>
  )
}
