import { ApolloError, gql, useQuery } from "@apollo/client"
import UTCDate from "utils/UTCDate"

export const QUERY = gql`
  query lastSharedLocation($userIds: [ID]!, $currentTimestamp: String!) {
    lastSharedLocation(userIds: $userIds, currentTimestamp: $currentTimestamp) {
      locationId
      date
    }
  }
`

export default function useLastSharedLocation({
  userIds,
}: {
  userIds: string[]
}): {
  loading: boolean
  error?: ApolloError
  lastSharedLocation: { locationId: string; date: UTCDate } | undefined
} {
  const today = new UTCDate("DAY")
  const { loading, error, data } = useQuery<{
    lastSharedLocation: {
      locationId: string
      date: string
    } | null
  }>(QUERY, {
    variables: {
      userIds,
      currentTimestamp: today.getTime().toString(),
    },
    fetchPolicy: "network-only",
  })

  const lastSharedLocation = data?.lastSharedLocation
    ? {
        ...data.lastSharedLocation,
        date: new UTCDate(
          new Date(Number(data.lastSharedLocation.date)),
          "DAY",
          false
        ),
      }
    : undefined

  return { lastSharedLocation, loading, error }
}
