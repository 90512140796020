import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import React from "react"

import MonthDays from "./MonthDays/MonthDays"
import WeekDays from "./WeekDays/WeekDays"

export default function Wrapper() {
  const { viewMode } = usePlanningContext()

  if (viewMode === "MONTH") return <MonthDays />
  return <WeekDays />
}
