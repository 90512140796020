import { ApolloError, gql, useQuery } from "@apollo/client"
import useMyGroupsSimple from "graphql/groups/useMyGroupsSimple"
import { useMemo } from "react"
import { User } from "types"
import LocationTree from "utils/LocationTree"

import { LocationFragment } from "./fragments/locationFragment"

const LOCATIONS_QUERY = gql`
  ${LocationFragment}
  query getLocations {
    locations {
      ...LocationFragment
    }
  }
`

export default function useLocationsTreeForUser(
  user: User,
  hidePrivateLocationsEvenIfUserIsAuthorized = false
): {
  loading: boolean
  error?: ApolloError
  locations: LocationTree[]
} {
  const { loading: gLoading, groups } = useMyGroupsSimple(user.id)
  const { loading, error, data } = useQuery(LOCATIONS_QUERY)

  const locations = useMemo(() => {
    if (hidePrivateLocationsEvenIfUserIsAuthorized) {
      // no groupIds, not userIds - only public locations
      return LocationTree.make(data?.locations || [], true, [], [])
    }
    const groupIds = groups ? groups.map((group) => group.id) : []
    return LocationTree.make(data?.locations || [], true, groupIds, [user.id])
  }, [data, groups, user.id, hidePrivateLocationsEvenIfUserIsAuthorized])

  return {
    locations,
    loading: loading || gLoading,
    error,
  }
}
