import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function NavArrowDown({
  strokeWidth,
  stroke,
  ...props
}: SvgIconProps) {
  return (
    <SvgIcon
      strokeWidth={strokeWidth ? strokeWidth : 2}
      stroke={stroke ? stroke : "currentColor"}
      {...props}
    >
      <path
        d="M6 9L12 15L18 9"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}
