import { gql, useMutation } from "@apollo/client"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useTranslate from "intl/useTranslate"

import { DefaultHybridRuleFragment } from "./fragments/hybridPolicyFragments"
import updateCache from "./updateCache"

const MUTATION = gql`
  ${DefaultHybridRuleFragment}
  mutation deleteDefaultHybridRule {
    deleteDefaultHybridRule {
      ...DefaultHybridRuleFragment
    }
  }
`
export default function useDeleteDefaultHybridRule() {
  const t = useTranslate()
  const { addToast } = useToasts()
  const [mutate] = useMutation(MUTATION, {
    update: (cache, { data }) =>
      updateCache({
        cache,
        data,
        mutationName: "deleteDefaultHybridRule",
        remove: true,
      }),
  })

  return async () => {
    try {
      await mutate()
      addToast(t("hybrid-rule--delete-success"), { appearance: "success" })
    } catch (error) {
      console.error(error)
      addToast(error.message, { appearance: "error" })
    }
  }
}
