import { SlotValue } from "types"
import UTCDate from "utils/UTCDate"

import useBookingMaxDate from "./useBookingMaxDate"

export default function useSlotsEditorDisabledStatus(
  to: UTCDate | undefined
): SlotValue[] {
  const bookingMaxDate = useBookingMaxDate()
  if (!to) return []
  const canBookOffice = to.getTime() < bookingMaxDate.getTime()
  return canBookOffice ? [] : ["office"]
}
