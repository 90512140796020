import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import UTCDate from "utils/UTCDate"

export default function usePlanningProgressFromTo() {
  const { from, to, userShowWeekends, viewMode } = usePlanningContext()
  if (viewMode !== "DAY") return { from, to }

  if (userShowWeekends)
    return {
      from: new UTCDate(from, "WEEK-MONDAY"),
      to: new UTCDate(from, "NEXT-MONDAY"),
    }

  return {
    from: new UTCDate(from, "WEEK-MONDAY"),
    to: new UTCDate(from, "NEXT-SATURDAY"),
  }
}
