import useRemoveUserSubscription from "graphql/subscriptions/useRemoveUserSubscription"
import useUserSubscriptions from "graphql/subscriptions/useUserSubscriptions"
import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { colors, P16 } from "ui"
import { Trash } from "ui/icons"
import getUserDisplayName from "utils/getUserDisplayName"

const List = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 0;
`

const Item = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 4px 16px;

  &:hover {
    background: ${colors.grey4};
  }

  svg:last-child:hover {
    cursor: pointer;
    stroke: ${colors.error};
  }
`

export default function UserSubscriptionsList() {
  const t = useTranslate()
  const { user: me } = useUser()
  const { subscriptions } = useUserSubscriptions()
  const { removeUserSubscription, loading } = useRemoveUserSubscription()

  if (!me) return null

  return (
    <List>
      {subscriptions.length === 0 && (
        <Item>
          <P16>{t("user-settings-subscriptions-empty-message")}</P16>
        </Item>
      )}
      {subscriptions.map((user, n) => (
        <Item key={`user-subscription-${n}`}>
          {getUserDisplayName(user, me.company.flags)}

          <Trash
            onClick={() => {
              if (!loading) removeUserSubscription(user.id)
            }}
          />
        </Item>
      ))}
    </List>
  )
}
