import { makeVar, useReactiveVar } from "@apollo/client"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useLogEvent from "graphql/misc/useLogEvent"
import { useHistory } from "react-router-dom"
import { Flags } from "types"

import useMediaQueries from "./useMediaQueries"

const menuDrawerOpenVar = makeVar<boolean>(false)

export default function useMenu() {
  const menuDrawerOpen = useReactiveVar(menuDrawerOpenVar)

  const history = useHistory()
  const { isMobile, isNarrowScreen } = useMediaQueries()
  const logEvent = useLogEvent()

  const persistentMenu = !isMobile && !isNarrowScreen

  const {
    openDrawer: openedDrawers,
    setOpenDrawer: setOpenedDrawers,
    activeLocation,
    setActiveLocation,
    focusedDate,
    setFocusedDate,
    usersAtFilters,
    setUsersAtFilters,
  } = usePlanningContext()

  const toggleMenuDrawer = () => {
    const open = menuDrawerOpenVar()
    menuDrawerOpenVar(!open)
  }

  const setMenuDrawerOpen = (e: boolean) => {
    menuDrawerOpenVar(e)
  }

  const cleanUp = () => {
    if (!persistentMenu) setMenuDrawerOpen(false)
    if (focusedDate) setFocusedDate(undefined)
    if (openedDrawers !== null) setOpenedDrawers(null)
    if (activeLocation)
      setActiveLocation({
        location: null,
        persist: false,
        triggeredFrom: "menu",
      })
    if (usersAtFilters.length > 0) setUsersAtFilters([])
  }

  const handlePath = (path: string) => {
    history.push(path)
    cleanUp()
  }

  const handleLogEvent = (event: string, data?: Flags) => {
    logEvent(event, data)
    cleanUp()
  }

  const handlePathAndLogEvent = (path: string, event: string, data?: Flags) => {
    logEvent(event, data)
    history.push(path)
    cleanUp()
  }

  return {
    menuDrawerOpen,
    toggleMenuDrawer,
    setMenuDrawerOpen,
    handlePath,
    handleLogEvent,
    handlePathAndLogEvent,
  }
}
