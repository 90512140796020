import { ApolloClient, gql, useApolloClient, useMutation } from "@apollo/client"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import { MY_GROUPS_SIMPLE_QUERY } from "graphql/groups/useMyGroupsSimple"
import { GET_USER_FROM_ID_QUERY } from "graphql/users/useUserFromId"
import { useState } from "react"
import { User } from "types"

import { UserAdminBaseFragment, UserFragment } from "./fragments/userFragment"

const UPDATE_MANAGER = gql`
  ${UserFragment}
  mutation updateManager($managerEmail: String!, $userId: ID) {
    updateManager(managerEmail: $managerEmail, userId: $userId) {
      ...UserFragment
    }
  }
`

async function fetchUser(
  id: string,
  client: ApolloClient<any>
): Promise<User | null> {
  const { data } = await client.query({
    query: GET_USER_FROM_ID_QUERY,
    variables: { id },
  })
  return data?.userFromId
}

export default function useUpdateManager() {
  const { addToast } = useToasts()

  const client = useApolloClient()
  const [mutate] = useMutation(UPDATE_MANAGER)
  const [loading, setLoading] = useState(false)
  const updateManager = async (managerEmail: string, userId: string) => {
    setLoading(true)
    try {
      const userToUpdate = await fetchUser(userId, client)
      const oldManager = userToUpdate?.manager
      await mutate({
        variables: { managerEmail, userId },
        update(
          cache,
          {
            data: {
              updateManager: { manager },
            },
          }
        ) {
          cache.updateFragment(
            {
              id: `UserAdminBase:${userId}`,
              fragment: UserAdminBaseFragment,
            },
            (data) => ({ ...data, manager })
          )
        },
        refetchQueries: ({ data }) => {
          const updatedUser = data["updateManager"]
          const queriesToRefetch = [
            {
              query: MY_GROUPS_SIMPLE_QUERY,
              variables: { id: updatedUser.id },
            },
            {
              query: GET_USER_FROM_ID_QUERY,
              variables: { id: updatedUser.id },
            },
          ]

          if (oldManager) {
            // refetch the old manager
            queriesToRefetch.push({
              query: GET_USER_FROM_ID_QUERY,
              variables: { id: oldManager.id },
            })
          }
          if (updatedUser.manager) {
            // refetch the new manager
            queriesToRefetch.push({
              query: GET_USER_FROM_ID_QUERY,
              variables: { id: updatedUser.manager.id },
            })
          }
          return queriesToRefetch
        },
      })
    } catch (e) {
      addToast(e.message, { appearance: "error" })
    } finally {
      setLoading(false)
    }
  }
  return {
    loading,
    updateManager,
  }
}
