import { ApolloError, gql, useQuery } from "@apollo/client"
import { UserAdminBase } from "types"

import { UserAdminBaseFragment } from "./fragments/userFragment"

export const ADMIN_SEARCH_USERS_QUERY = gql`
  ${UserAdminBaseFragment}
  query adminSearchUsers(
    $search: String
    $limit: Int
    $offset: Int
    $registered: Boolean
    $enabled: Boolean
    $role: String
  ) {
    adminSearchUsers(
      search: $search
      limit: $limit
      offset: $offset
      registered: $registered
      enabled: $enabled
      role: $role
    ) {
      ...UserAdminBaseFragment
    }
  }
`

export default function useAdminSearchUsers(): {
  loading: boolean
  error?: ApolloError
  users: UserAdminBase[]
} {
  const { loading, error, data } = useQuery<{
    adminSearchUsers: UserAdminBase[]
  }>(ADMIN_SEARCH_USERS_QUERY)

  return {
    users: data?.adminSearchUsers || [],
    loading,
    error,
  }
}
