import React from "react"
import { CircularSpinner } from "ui"

export default function Loader() {
  return (
    <div
      style={{
        position: "absolute",
        inset: "0 0 0 0",
        zIndex: 3000,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        pointerEvents: "none",
      }}
    >
      <CircularSpinner color="purple" />
    </div>
  )
}
