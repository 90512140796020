import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { colors, Dialog, LU14, mediaQueries } from "ui"
import { ArrowRightFill } from "ui/icons"
import LocationTree from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

import ChildLocation from "./ChildLocation/ChildLocation"
import ParentLocation from "./ParentLocation/ParentLocation"

const Layout = styled.ul<{ maxHeight: number }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 0 16px 16px;
  ${({ maxHeight }) => `
  max-height: calc(${maxHeight}px - 40px);
  `}
  overflow-y: scroll;

  @media ${mediaQueries.isNarrowScreen} {
  }
`

const MobileEmptySelectionButton = styled.button`
  margin: 0 auto;
  padding: 1px 16px;
  background: none;
  border: dashed 1px ${colors.grey3};
  border-radius: 100vh;
  width: 100%;
`

const MobileHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-top: solid 1px ${colors.grey3};
  border-bottom: solid 1px ${colors.grey3};
`

const MobileList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 0 16px 16px;
`

interface PropsType {
  from: UTCDate
  to: UTCDate
  locations: LocationTree[]
  selectedLocation: LocationTree | undefined
  selectedFloorPlan: LocationTree | undefined
  maxHeight: number
  onSelect: (location?: LocationTree) => void
}

function MobileFloorPlanLocationsList({
  from,
  to,
  locations,
  selectedLocation,
  selectedFloorPlan,
  onSelect,
}: PropsType) {
  const t = useTranslate()
  const [open, setOpen] = useState(false)

  const handleOpenLocationPicker = () => {
    onSelect(undefined)
    setOpen(true)
  }

  const handleSelect = (location: LocationTree) => {
    onSelect(location)
    setOpen(false)
  }

  return (
    <>
      <MobileHeader onClick={handleOpenLocationPicker}>
        {selectedLocation ? (
          <>
            <ArrowRightFill
              style={{
                rotate: open ? "90deg" : undefined,
                transition: "rotate 250ms ease-in-out",
              }}
            />
            <LU14 color="grey1">{selectedLocation.name}</LU14>
          </>
        ) : (
          <MobileEmptySelectionButton>
            {t("select a location")}
          </MobileEmptySelectionButton>
        )}
      </MobileHeader>
      {open && (
        <Dialog
          open
          title={t("select a location")}
          onClose={() => setOpen(false)}
        >
          <MobileList>
            {locations.map((l) => {
              const key = `root-loc-${l.id}`
              if (l.isCategory)
                return (
                  <ParentLocation
                    key={key}
                    location={l}
                    selectedLocation={selectedLocation}
                    selectedFloorPlan={selectedFloorPlan}
                    from={from}
                    to={to}
                    onSelect={handleSelect}
                  />
                )
              return (
                <ChildLocation
                  key={key}
                  location={l}
                  selectedLocation={selectedLocation}
                  from={from}
                  to={to}
                  onSelect={handleSelect}
                />
              )
            })}
          </MobileList>
        </Dialog>
      )}
    </>
  )
}

export default function FloorPlanLocationsList({
  from,
  to,
  locations,
  selectedLocation,
  selectedFloorPlan,
  maxHeight,
  onSelect,
}: PropsType) {
  const { isMobile } = useMediaQueries()
  const { openDrawer } = usePlanningContext()

  if (isMobile)
    return (
      <MobileFloorPlanLocationsList
        from={from}
        to={to}
        locations={locations}
        selectedLocation={selectedLocation}
        selectedFloorPlan={selectedFloorPlan}
        maxHeight={maxHeight}
        onSelect={onSelect}
      />
    )

  return (
    <Layout
      maxHeight={maxHeight}
      className={
        openDrawer === "SLOTS_EDITOR" ? "within-slots-editor" : undefined
      }
    >
      {locations.map((l) => {
        const key = `root-loc-${l.id}`
        if (l.isCategory)
          return (
            <ParentLocation
              key={key}
              location={l}
              selectedLocation={selectedLocation}
              selectedFloorPlan={selectedFloorPlan}
              from={from}
              to={to}
              onSelect={onSelect}
            />
          )
        return (
          <ChildLocation
            key={key}
            location={l}
            selectedLocation={selectedLocation}
            from={from}
            to={to}
            onSelect={onSelect}
          />
        )
      })}
    </Layout>
  )
}
