import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { WorkingMode } from "types"
import { Button } from "ui"
import LocationTree from "utils/LocationTree"

const StyledButton = styled(Button)`
  z-index: 2;
  position: absolute;
  right: 10px;
  bottom: 10px;
`

interface Props {
  activeSublocation?: LocationTree
  saveWorkingMode?: (workingMode: WorkingMode) => void | Promise<void>
}

export default function SaveOfficeSlotButton({
  activeSublocation,
  saveWorkingMode,
}: Props) {
  const t = useTranslate()

  return (
    <StyledButton
      onClick={() => {
        if (!activeSublocation || activeSublocation?.floorPlanImageUrl) return
        if (saveWorkingMode) {
          saveWorkingMode({
            locationId: activeSublocation?.id,
            genericSlotValue: "office",
            label: null,
            equipments: [],
          })
        }
      }}
      disabled={
        !activeSublocation || activeSublocation?.floorPlanImageUrl !== null
      }
    >
      {t("Save")}
    </StyledButton>
  )
}
