import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components/macro"
import { TeamCompleteStats, UserBase } from "types"
import { colors, P14, P14Bold } from "ui"
import getLabelFromStatus from "utils/getLabelFromStatus"

import TableLabelWithSortingButton, {
  TableLabelWithBadgeAndSortingButton,
} from "./TableLabelWithSortingButton"

const Table = styled.div`
  display: grid;
  grid-template-columns:
    minmax(80px, auto) minmax(140px, auto)
    minmax(130px, 240px)
    repeat(5, minmax(min-content, 140px));
  align-items: center;
`

const TD = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 4px;
  height: 38px;
  width: 100%;
  cursor: default;
  font-size: 1rem;

  &.with-background {
    background: ${colors.grey4};
  }

  &.hovered {
    background: #00000009;
  }

  > p {
    padding-top: 0px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  > svg {
    stroke-width: 2px;
    width: 20px;
    height: 20px;
    margin: 0 auto;
  }

  &.cursor-pointer {
    cursor: pointer;
  }
`

interface PropsType {
  orderBy: string
  setOrderBy: (str: string) => void
  teamsStats: TeamCompleteStats[]
  fetchMore: () => void
  canFetchMore: boolean
  loading: boolean
}

export default function AdminTeamsStatsTable({
  orderBy,
  setOrderBy,
  teamsStats,
}: PropsType) {
  const history = useHistory()
  const onUserClick = (user: UserBase) => {
    history.push(`/user/${user.id}`)
  }
  const t = useTranslate()

  const [hoveredUser, setHoveredUser] = useState<UserBase | undefined>(
    undefined
  )

  const getMouseInteractions = (user: UserBase) => ({
    onMouseEnter: () => {
      setHoveredUser(user)
    },
    onMouseLeave: () => {
      setHoveredUser(undefined)
    },
  })

  return (
    <>
      <Table aria-label="simple table">
        <TD className="with-background">
          <P14Bold color="grey1">{t("Name")}</P14Bold>
        </TD>
        <TD className="with-background">
          <TableLabelWithSortingButton
            field="manager.email"
            orderBy={orderBy}
            setOrderBy={setOrderBy}
          >
            {t("Manager")}
          </TableLabelWithSortingButton>
        </TD>
        <TD className="with-background">
          <TableLabelWithSortingButton
            field="total"
            orderBy={orderBy}
            setOrderBy={setOrderBy}
          >
            {t("Completion rate")}
          </TableLabelWithSortingButton>
        </TD>
        <TD className="with-background">
          <TableLabelWithBadgeAndSortingButton
            field="home"
            orderBy={orderBy}
            setOrderBy={setOrderBy}
          >
            {t(getLabelFromStatus("home"))}
          </TableLabelWithBadgeAndSortingButton>
        </TD>
        <TD className="with-background">
          <TableLabelWithBadgeAndSortingButton
            field="office"
            orderBy={orderBy}
            setOrderBy={setOrderBy}
          >
            {t(getLabelFromStatus("office"))}
          </TableLabelWithBadgeAndSortingButton>
        </TD>
        <TD className="with-background">
          <TableLabelWithBadgeAndSortingButton
            field="else"
            orderBy={orderBy}
            setOrderBy={setOrderBy}
          >
            {t(getLabelFromStatus("else"))}
          </TableLabelWithBadgeAndSortingButton>
        </TD>
        <TD className="with-background">
          <TableLabelWithBadgeAndSortingButton
            field="off"
            orderBy={orderBy}
            setOrderBy={setOrderBy}
          >
            {t(getLabelFromStatus("off"))}
          </TableLabelWithBadgeAndSortingButton>
        </TD>
        <TD className="with-background">
          <TableLabelWithBadgeAndSortingButton
            field="ooc"
            orderBy={orderBy}
            setOrderBy={setOrderBy}
          >
            {t(getLabelFromStatus("ooc"))}
          </TableLabelWithBadgeAndSortingButton>
        </TD>

        {teamsStats.map((user, n) => {
          const getClassName = () => {
            const withBackground = (n + 1) % 2 === 0
            const isHovered = hoveredUser?.id === user.id
            const classList = []
            if (withBackground) classList.push("with-background")
            if (isHovered) classList.push("hovered")
            return classList.join(" ")
          }
          return (
            <React.Fragment key={`stats-user-${n}-${user.id}`}>
              <TD
                className={`${getClassName()} cursor-pointer`}
                onClick={() => onUserClick(user)}
                {...getMouseInteractions(user)}
              >
                <P14>{user.teamName}</P14>
              </TD>
              <TD
                className={`${getClassName()} cursor-pointer`}
                onClick={() => onUserClick(user)}
                {...getMouseInteractions(user)}
              >
                <P14>{user.email}</P14>
              </TD>
              <TD
                className={`${getClassName()}`}
                {...getMouseInteractions(user)}
              >
                <P14>{(user.completion * 100).toFixed(2)} %</P14>
              </TD>
              <TD
                className={`${getClassName()}`}
                {...getMouseInteractions(user)}
              >
                <P14>
                  {user.home / 2} (
                  {((100 * user.home) / (user.teamSize * user.total)).toFixed(
                    0
                  )}
                  %)
                </P14>
              </TD>
              <TD
                className={`${getClassName()}`}
                {...getMouseInteractions(user)}
              >
                <P14>
                  {user.office / 2} (
                  {((100 * user.office) / (user.teamSize * user.total)).toFixed(
                    0
                  )}
                  %)
                </P14>
              </TD>
              <TD
                className={`${getClassName()}`}
                {...getMouseInteractions(user)}
              >
                <P14>
                  {user.else / 2} (
                  {((100 * user.else) / (user.teamSize * user.total)).toFixed(
                    0
                  )}
                  %)
                </P14>
              </TD>
              <TD
                className={`${getClassName()}`}
                {...getMouseInteractions(user)}
              >
                <P14>
                  {user.off / 2} (
                  {((100 * user.off) / (user.teamSize * user.total)).toFixed(0)}
                  %)
                </P14>
              </TD>
              <TD
                className={`${getClassName()}`}
                {...getMouseInteractions(user)}
              >
                <P14>
                  {user.ooc / 2} (
                  {((100 * user.ooc) / (user.teamSize * user.total)).toFixed(0)}
                  %)
                </P14>
              </TD>
            </React.Fragment>
          )
        })}
      </Table>
    </>
  )
}
