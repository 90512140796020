import { gql, useMutation } from "@apollo/client"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useTranslate from "intl/useTranslate"

import { updateLocationCache } from "./updateCache"

const MUTATION = gql`
  mutation deleteFloorPlanImage($locationId: ID!) {
    deleteFloorPlanImage(locationId: $locationId) {
      locationId
    }
  }
`

interface PropsType {
  locationId: string
}

export default function useDeleteFloorPlanImage() {
  const { addToast } = useToasts()
  const [mutate] = useMutation(MUTATION)
  const t = useTranslate()

  return async ({ locationId }: PropsType) => {
    try {
      await mutate({
        variables: {
          locationId,
        },
        update: async (cache, { data }) => {
          updateLocationCache({
            cache,
            data,
            mutationName: "deleteFloorPlanImage",
            floorPlanImageUrl: null,
          })
        },
      })
      addToast(t("your floor plan image has been deleted"), {
        appearance: "success",
      })
    } catch (e) {
      console.error(e.message)
      addToast(e.message, { appearance: "error" })
    }
  }
}
