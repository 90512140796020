import useLink from "hooks/useLink"
import React from "react"
import { loginWithToken } from "utils/firebase"

export default function SignInWithSAMLIdpFlow() {
  const goToPlanning = useLink("/planning")

  React.useEffect(() => {
    if (!goToPlanning) return

    const token = localStorage.getItem("saml_IdP_token")
    if (token) {
      localStorage.removeItem("saml_IdP_token")
      loginWithToken(token).then(() => {
        goToPlanning()
      })
    } else {
      const parentDomain = process.env.REACT_APP_API as string
      window.addEventListener("message", (event) => {
        if (event.data && event.data.type === "SAML_IDP_FLOW_RESPONSE") {
          localStorage.setItem("saml_IdP_token", event.data.token)
          window.parent.postMessage({ type: "SAML_IDP_FLOW_OK" }, parentDomain)
        }
      })
      window.parent.postMessage({ type: "SAML_IDP_FLOW_READY" }, parentDomain)
    }
  }, [goToPlanning])

  return null
}
