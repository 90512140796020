import InteractionsMenu from "components/InteractionsMenu/InteractionsMenu"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useTranslate from "intl/useTranslate"
import { ActiveLocationsList } from "pages/PlanningPage/components/PlanningHeadersRouter/ActiveLocationsList/ActiveLocationsList"
import React from "react"
import { useTeamsAppContext } from "TeamsApp/TeamsAppContext"
import { UserBase } from "types"
import { P14 } from "ui"
import { Star } from "ui/icons"

import { HeaderContainer, HeaderTitle } from "./components"
import HeaderMessage from "./HeaderMessage/HeaderMessage"

interface PropsType {
  users: UserBase[]
}

export default function FavoriteUsersHeader({ users }: PropsType) {
  const t = useTranslate()
  const { showHiddenActiveLocations, from, to } = usePlanningContext()
  const { isTeamsApp } = useTeamsAppContext()

  return (
    <HeaderContainer>
      <HeaderTitle>
        <Star className="star--is-favorite" />
        <P14 className="favorite-title">{t("Favorite users")}</P14>
        {!isTeamsApp && <InteractionsMenu users={users} from={from} to={to} />}
      </HeaderTitle>
      {users.length > 0 || showHiddenActiveLocations ? (
        <ActiveLocationsList />
      ) : (
        <HeaderMessage
          message={t(
            "You have not selected any user to be part of your favorite ones."
          )}
        />
      )}
    </HeaderContainer>
  )
}
