import { MenuItem as MuiMenuItem, Select as MuiSelect } from "@mui/material"
import { styled as muiStyled } from "@mui/material/styles"
import React from "react"
import styled from "styled-components"
import colors from "ui/colors"
import { Circle, Rectangle } from "ui/icons"

const Wrapper = styled.div`
  position: absolute;
  pointer-events: auto;
  padding: 6px;
`

const Select = muiStyled(MuiSelect)(() => ({
  "&": {
    lineHeight: 0,
  },
  "& .MuiSelect-select": {
    padding: 3,
  },
  "& .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-inputSizeSmall":
    {
      paddingRight: 20,
      minHeight: 0,
    },
  "& .MuiSvgIcon-root": {
    top: 1,
    right: 0,
  },
}))

const MenuItem = muiStyled(MuiMenuItem)(() => ({
  "&": {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 4,
    paddingBottom: 4,
  },
}))

export interface ShapeButtonProps {
  locationId: string
  shape: string
  left?: number
  right?: number
  top?: number
  bottom?: number
}

interface Props extends ShapeButtonProps {
  onChangeShape: (locationId: string, shape: string) => void
}

export default function ShapeSelector({
  locationId,
  shape,
  left,
  right,
  top,
  bottom,
  onChangeShape,
}: Props) {
  return (
    <Wrapper
      style={{
        left,
        right,
        top,
        bottom,
      }}
    >
      <Select
        value={shape}
        onChange={(e: any) => onChangeShape(locationId, e.target.value)}
        autoWidth
        size="small"
        sx={{
          background: "white",
          padding: 0,
        }}
      >
        <MenuItem value="rect">
          <Rectangle htmlColor={colors.magenta} fontSize="small" />
        </MenuItem>
        <MenuItem value="circle">
          <Circle htmlColor={colors.magenta} fontSize="small" />
        </MenuItem>
      </Select>
    </Wrapper>
  )
}
