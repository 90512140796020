import { TextField as MuiTextField } from "@mui/material"
import PasswordInput from "components/PasswordInput"
import ResendValidationMail from "components/ResendValidationMail"
import { Formik } from "formik"
import useLoginWithEmailAndPassword from "hooks/auth/useLoginWithEmailAndPassword"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components/macro"
import { Button, colors, H3, Link, mediaQueries, P14, P16 } from "ui"
import { NotVerifiedError } from "utils/firebase"
import * as Yup from "yup"

import SignInWithSSOPage from "./SignInWithSSOPage"
import SSOButtons from "./SSOButtons"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 480px;

  @media ${mediaQueries.isMobile} {
    width: 90%;
    margin-left: 10px;
    margin-right: 10px;
  }

  h3 {
    color: ${colors.purple};
  }

  .forgot-password {
    color: ${colors.purple};
    text-align: flex-end;
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 370px;
    margin-top: 32px;
    gap: 16px;

    .input {
      width: 100%;
    }

    @media ${mediaQueries.isMobile} {
      width: 280px;
    }

    .error-message {
      display: flex;
      flex-direction: row;
      margin-top: 24px;
      p {
        margin-left: 12px;
      }
    }
  }

  .divider {
    height: 20px;
  }
`

const FormikTextField = styled(MuiTextField)`
  width: 100%;
  & .MuiOutlinedInput-root {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: ${colors.black};
    height: 40px;
    border: 1px solid ${colors.grey2};
  }
  & .MuiOutlinedInput-root :hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)),
      ${colors.grey4};
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 10px;
    color: ${colors.black};
    border-radius: 4px;
  }
  & .MuiOutlinedInput-root.Mui-disabled {
    background: ${colors.grey4};
    cursor: not-allowed;
    color: ${colors.grey1};
  }
  & .MuiOutlinedInput-root.Mui-focused {
    background: linear-gradient(0deg, ${colors.white}, ${colors.white}),
      ${colors.grey4};
    border: 2px solid ${colors.green};
    color: ${colors.black};
    height: 40px;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  & .MuiInputAdornment-root {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: ${colors.black};
    height: 40px;
  }
`

const StyledButton = styled(Button)`
  width: 370px;

  @media ${mediaQueries.isMobile} {
    width: 280px;
  }
`

const regularHostnames = ["app.offishall.io", "dev.offishall.io", "localhost"]

function SignIn() {
  const t = useTranslate()
  const { loading, loginWithEmailAndPassword } = useLoginWithEmailAndPassword()
  const [resendEmail, setResendEmail] = useState(false)

  if (
    !regularHostnames.includes(window.location.hostname) &&
    !/^.*\.netlify.app$/.test(window.location.hostname) &&
    !/^.*\.ngrok.io$/.test(window.location.hostname) &&
    !/^\d+\.\d+\.\d+\.\d+$/.test(window.location.hostname)
  ) {
    const domainBase = window.location.hostname.split(".")[0]
    return <SignInWithSSOPage domainInUrl={domainBase} />
  }

  return (
    <Container>
      <Helmet title={t("Sign in")} />
      <H3>{t("welcome")}</H3>

      <Formik
        initialValues={{
          email: "",
          password: "",
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(t("Must be a valid email"))
            .max(255)
            .required(t("Email is required")),
          password: Yup.string().max(255).required(t("Password is required")),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await loginWithEmailAndPassword(values.email, values.password)
          } catch (error) {
            const message = error.message || t("Something went wrong")

            setStatus({ success: false })
            setErrors({ submit: message })
            setResendEmail(error instanceof NotVerifiedError)
            setSubmitting(false)
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit} className="form">
              {errors.submit && (
                <div className="error-message">
                  <P16>{errors.submit}</P16>
                  {resendEmail && <ResendValidationMail email={values.email} />}
                </div>
              )}
              <FormikTextField
                type="email"
                name="email"
                placeholder={t("Email address")}
                value={values.email}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                onBlur={handleBlur}
                onChange={handleChange}
                variant="outlined"
                required
                size="small"
              />
              <PasswordInput
                name="password"
                fullWidth
                isFormik
                useB4BPasswords={false}
                newPassword={true}
                placeholder={t("Password")}
                value={values.password}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                onBlur={handleBlur}
                handleChange={handleChange}
                variant="outlined"
                size="small"
              />
              <Link to="/reset-password">
                <P14 className="forgot-password">{t("Forgot password")}</P14>
              </Link>

              <StyledButton type="submit" disabled={isSubmitting || loading}>
                {t("Sign in")}
              </StyledButton>
            </form>
          )
        }}
      </Formik>
      <div className="divider" />
      <SSOButtons />
    </Container>
  )
}

export default SignIn
