import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { IntegrationEvent, UpsertEventInput } from "types"
import { colors, H4, mediaQueries, transitions } from "ui"
import { ArrowDownFill } from "ui/icons"

import EventItem from "../EventItem/EventItem"

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-bottom: solid 1px ${colors.grey3};
  cursor: default;

  padding: 24px 0;

  :first-child {
    padding: 0 0 24px 0;
  }

  @media ${mediaQueries.isMobile} {
    padding: 24px 0;
  }

  &:last-child {
    border: none;
  }

  .header {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    width: fit-content;

    .arrow-btn {
      &--turned {
        transform: rotate(-90deg);
      }
      transition: ${transitions.generic};
    }
  }
`

interface PropsType {
  events: IntegrationEvent[]
  title: string
  setEventToBeEdited: (event: UpsertEventInput) => void
}

export default function CollapseEvents({
  events,
  title,
  setEventToBeEdited,
}: PropsType) {
  const { user: me } = useUser()
  const t = useTranslate()

  const [collapseMenuContent, setCollapseMenuContent] = useState(false)

  const checkEventEditPermission = (event: IntegrationEvent) => {
    if (me?.role === "admin") return true
    if (event.organizer?.email === me?.email) return true
    return false
  }

  if (events.length === 0) return <></>

  return (
    <List>
      <div
        className="header"
        onClick={(e) => {
          e.stopPropagation()
          setCollapseMenuContent((prev) => !prev)
        }}
      >
        <H4>{title}</H4>
        {title === t("Past") && (
          <ArrowDownFill
            className={
              collapseMenuContent ? "arrow-btn" : "arrow-btn arrow-btn--turned"
            }
          />
        )}
      </div>
      {collapseMenuContent &&
        events.map((e) => (
          <EventItem
            key={`${title}-evt-${e.id}`}
            event={e}
            isEditable={checkEventEditPermission(e)}
            setEventToBeEdited={setEventToBeEdited}
          />
        ))}
    </List>
  )
}
