import useMenu from "hooks/useMenu"
import React from "react"
import styled from "styled-components"
import { colors, mediaQueries } from "ui"
import { Menu } from "ui/icons"

const ToggleButon = styled(Menu)`
  stroke: ${colors.black};
  width: 24px;
  height: 24px;
  cursor: pointer;

  @media ${mediaQueries.isNarrowScreen} {
    margin: 0 8px;
  }
`

export default function BurgerButton() {
  const { toggleMenuDrawer } = useMenu()

  return <ToggleButon onClick={toggleMenuDrawer} />
}
