import SlotsEditorLocationsList from "components/Collapses/LocationsCollapse/SlotsEditorLocationsList"
import useLocationsTree from "graphql/locations/useLocationsTree"
import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { WorkingMode } from "types"
import { P16Bold } from "ui"
import { NavArrowLeft } from "ui/icons"
import { getChildrenLocations } from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

import { TitleContainer } from "../SharedComponents"

const LocationPickerLayout = styled.div`
  > p:first-child {
    padding: 0 16px;
  }
`

interface PropsType {
  from: UTCDate
  to: UTCDate
  setContentType: (
    contentType: "HOW_TO" | "SLOTS_EDITOR" | "LOCATION_PICKER"
  ) => void
  handleSaveSlot: ({ genericSlotValue, label, locationId }: WorkingMode) => void
  onNavback: () => void
}

export default function TypicalWeekLocationPicker({
  from,
  to,
  setContentType,
  handleSaveSlot,
}: PropsType) {
  const t = useTranslate()
  const { user: me } = useUser()

  const { locations } = useLocationsTree()
  const companyHasNoOffice = getChildrenLocations(locations, true).length === 0

  if (!me) return <></>

  if (companyHasNoOffice)
    return (
      <div
        style={{
          margin: "0 auto",
        }}
      >
        {t("user-typical-week-update-slots--no-offices")}
      </div>
    )

  return (
    <LocationPickerLayout>
      <TitleContainer>
        <NavArrowLeft onClick={() => setContentType("SLOTS_EDITOR")} />
        <P16Bold>{t("user-onboarding-planning-choose-location")}</P16Bold>
      </TitleContainer>
      <SlotsEditorLocationsList
        from={from}
        to={to}
        selectedUser={me}
        guestIds={[]} // typical week should not involved guests
        occupations={[]}
        saveWorkingMode={(wm) => {
          handleSaveSlot(wm)
          setContentType("HOW_TO")
        }}
      />
    </LocationPickerLayout>
  )
}
