import { gql, useMutation } from "@apollo/client"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useLogEvent from "graphql/misc/useLogEvent"
import useTranslate from "intl/useTranslate"
import uploadFileToSignedURL from "utils/uploadFileToSignedURL"

import { updateLocationCache } from "./updateCache"

const MUTATION = gql`
  mutation saveFloorPlanImage($locationId: ID!) {
    saveFloorPlanImage(locationId: $locationId) {
      locationId
      imageUrl
      signedUrl
    }
  }
`

interface PropsType {
  locationId: string
  image: File
}

export default function useSaveFloorPlanImage() {
  const { addToast } = useToasts()
  const logEvent = useLogEvent()
  const [mutate] = useMutation(MUTATION)
  const t = useTranslate()

  return async ({ locationId, image }: PropsType) => {
    try {
      await mutate({
        variables: {
          locationId,
        },
        update: async (cache, { data }) => {
          const signedUrl = data?.saveFloorPlanImage?.signedUrl
          const imageUrl = data?.saveFloorPlanImage?.imageUrl
          if (signedUrl) {
            try {
              await uploadFileToSignedURL(image, signedUrl)
              logEvent("floor_plan_image_uploaded", { image })
            } catch (e) {
              console.error(e)
              logEvent("floor_plan_image_upload_failed", {
                image,
                message: e.message,
              })
            }
          }
          updateLocationCache({
            cache,
            data,
            mutationName: "saveFloorPlanImage",
            floorPlanImageUrl: imageUrl,
          })
        },
      })
      addToast(t("your floor plan image has been saved"), {
        appearance: "success",
      })
      return true
    } catch (e) {
      console.error(e.message)
      addToast(e.message, { appearance: "error" })
      return false
    }
  }
}
