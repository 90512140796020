import React from "react"
import styled from "styled-components"
import { colors, mediaQueries } from "ui"

const SearchButton = styled.button.attrs({
  type: "submit",
})`
  border: none;
  background: none;
  padding: 0;
  display: flex;
  color: ${colors.grey1};
  margin-right: 10px;

  svg {
    width: 24px;
    height: 24px;
  }

  @media ${mediaQueries.isTouchable} {
    margin-right: 5px;
  }
`

interface PropsType {
  icon: JSX.Element
}

export default function SearchBarButton({ icon }: PropsType) {
  return <SearchButton>{icon}</SearchButton>
}
