import { ClickAwayListener, Fade, Popper, PopperProps } from "@mui/material"
import React from "react"
import styled from "styled-components"
import { colors } from "ui"

export default Popper

const Layout = styled.div`
  background: ${colors.white};
  border-radius: 8px;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.24), 0px 2px 2px rgba(0, 0, 0, 0.12);
  max-height: inherit;
  overflow-y: scroll;
`

interface PropsType extends PopperProps {
  children: React.ReactNode
  hideBackground?: boolean
  onClose: () => void
}

export function PopperMenu({
  children,
  hideBackground = false,
  onClose,
  anchorEl,
  open,
  disablePortal,
  ...rest
}: PropsType) {
  if (!open || anchorEl === null) return <></>
  return (
    <ClickAwayListener onClickAway={onClose}>
      <Popper
        disablePortal={disablePortal}
        open={open}
        anchorEl={anchorEl}
        {...rest}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={250}>
            {hideBackground ? (
              <>{children}</>
            ) : (
              <>
                <Layout>{children}</Layout>
              </>
            )}
          </Fade>
        )}
      </Popper>
    </ClickAwayListener>
  )
}
