import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import usePlanningUserSlots from "graphql/slots/usePlanningUserSlots"
import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { colors, P14 } from "ui"
import { ArrowLeft } from "ui/icons"
import capitalizeFirstLetter from "utils/texts/capitalizeFirstLetter"
import UTCDate from "utils/UTCDate"

import Days from "./Days"
import SelectedDate from "./SelectedDate"
import WeeklySlots from "./WeeklySlots"

const Layout = styled.div`
  width: 100vw;
  overflow: hidden;
  padding: 8px;
  border-bottom: 1px solid ${colors.grey3};
  .header {
    display: flex;
    gap: 10px;
    align-items: center;
    > svg {
      stroke: ${colors.purple};
    }

    p {
      color: ${colors.purple};
    }
  }
  .selected-date {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8.5px;
    p {
      text-align: center;
    }
    .event {
      display: flex;
      align-items: center;
      gap: 4px;
      background: rgba(0, 0, 0, 0.04);
      border-radius: 16px;
      padding: 0px 8px;
      width: fit-content;
      svg {
        height: 14px;
        width: 14px;
        color: ${colors.grey1};
        stroke-width: 0.3px;
      }
    }
  }
`

interface PropsType {
  onClose: () => void
}

export default function WeeklyDays({ onClose }: PropsType) {
  const t = useTranslate()
  const { user: me } = useUser()
  const { selectedDate, userShowWeekends } = usePlanningContext()

  const from = new UTCDate(selectedDate, "WEEK-MONDAY")
  const to = userShowWeekends
    ? new UTCDate(selectedDate, "NEXT-MONDAY")
    : new UTCDate(selectedDate, "NEXT-SATURDAY")

  const { slots } = usePlanningUserSlots({
    userId: me ? me.id : "",
    from,
    to,
  })

  const todayAM =
    slots.find((s) => s.date.isToday() && s.date.isMorning())?.value ?? null
  const todayPM =
    slots.find((s) => s.date.isToday() && !s.date.isMorning())?.value ?? null

  return (
    <Layout>
      <div className="header" onClick={() => onClose()}>
        <ArrowLeft />
        <P14>
          {capitalizeFirstLetter(t("week"))} {selectedDate.getWeekNumber()}
        </P14>
      </div>
      <Days todayStatus={{ AM: todayAM, PM: todayPM }} from={from} to={to} />
      <WeeklySlots slots={slots} displayWeekends={userShowWeekends} />
      <SelectedDate date={selectedDate} />
    </Layout>
  )
}
