import useUser from "graphql/users/useUser"
import useUserTree from "hooks/useUserTree"
import React, { useState } from "react"
import styled from "styled-components"
import { Flags } from "types"
import { colors, P16 } from "ui"
import { ArrowRightFill } from "ui/icons"
import getUserDisplayName from "utils/getUserDisplayName"
import UserTree from "utils/UserTree"

import { CustomCheckbox, Item, List } from "./SharedLayouts"

const ManagerHeader = styled.div<{ depth: number }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 7px 8px 7px 16px;
  background: rgba(0, 0, 0, 0.04);
  border-bottom: solid 1px ${colors.grey3};
  ${({ depth }) => `padding-left: ${depth * 12}px;`}

  > svg {
    width: 20px;
  }
`

interface ManagerItemPropsType {
  user: UserTree
  selectedManagers: UserTree[]
  companyFlags: Flags
  depth?: number
  disabled: boolean
  disableMySelf?: boolean
  extraAction?: React.ReactNode
  extraActionFilteredTargetIds?: string[]
  onSelectManager: (user: UserTree) => void
}

function ManagerItem({
  user: manager,
  selectedManagers,
  companyFlags,
  depth = 0,
  disabled,
  disableMySelf = false,
  extraAction: ExtraAction,
  extraActionFilteredTargetIds,
  onSelectManager,
}: ManagerItemPropsType) {
  const { user: me } = useUser()
  const [showTeam, setShowTeam] = useState(false)
  const checked =
    selectedManagers.find(({ id }) => id === manager.id) !== undefined

  return (
    <>
      <Item
        onClick={() => {
          if (disabled) return
          onSelectManager(manager)
        }}
      >
        <CustomCheckbox
          checked={checked}
          onChange={() => {
            //
          }}
          disabled={disabled}
        />
        <ManagerHeader depth={depth}>
          <ArrowRightFill
            style={{
              rotate: showTeam ? "90deg" : "0deg",
              transition: "rotate 250ms ease-in-out",
            }}
            onClick={(e) => {
              e.stopPropagation()
              setShowTeam((prev) => !prev)
            }}
          />
          <P16 color={disabled ? "grey2" : undefined}>
            {getUserDisplayName(manager, companyFlags)}
          </P16>
          {extraActionFilteredTargetIds?.find((id) => id === manager.id) && (
            <div className="extra-action">{ExtraAction}</div>
          )}
        </ManagerHeader>
      </Item>
      {showTeam && (
        <li>
          <ul>
            {manager.team.map((u, n) => {
              if (u.isManager)
                return (
                  <ManagerItem
                    key={`${manager.id}-${u.id}-${n}`}
                    user={u}
                    selectedManagers={selectedManagers}
                    companyFlags={companyFlags}
                    depth={depth + 1}
                    onSelectManager={onSelectManager}
                    disabled={disableMySelf && me?.id === u.id}
                    disableMySelf={disableMySelf}
                    extraAction={ExtraAction}
                    extraActionFilteredTargetIds={extraActionFilteredTargetIds}
                  />
                )
            })}
          </ul>
        </li>
      )}
    </>
  )
}

interface PropsType {
  selectedManagers: UserTree[]
  companyFlags: Flags
  disableMySelf?: boolean
  extraAction?: React.ReactNode
  extraActionFilteredTargetIds?: string[]
  onSelectManager: (user: UserTree) => void
}

export default function ManagersTab({
  selectedManagers,
  companyFlags,
  disableMySelf = false,
  extraAction,
  extraActionFilteredTargetIds,
  onSelectManager,
}: PropsType) {
  const { userTree } = useUserTree()
  const { user: me } = useUser()

  return (
    <List>
      {userTree.map((u, n) => {
        if (u.isManager)
          return (
            <ManagerItem
              key={`manager-${u.id}-${n}`}
              user={u}
              selectedManagers={selectedManagers}
              companyFlags={companyFlags}
              onSelectManager={onSelectManager}
              disabled={me?.id === u.id}
              disableMySelf={disableMySelf}
              extraAction={extraAction}
              extraActionFilteredTargetIds={extraActionFilteredTargetIds}
            />
          )
      })}
    </List>
  )
}
