import { ApolloError, gql, useQuery } from "@apollo/client"
import { TeamDay } from "types"
import UTCDate from "utils/UTCDate"

import { TeamDayFragment } from "./fragments/teamDayFragment"

export const QUERY = gql`
  ${TeamDayFragment}
  query teamDays($managerId: ID!) {
    teamDays(managerId: $managerId) {
      ...TeamDayFragment
    }
  }
`

export default function useTeamDays({
  managerId,
  filterPastTeamDays = false,
}: {
  managerId: string
  filterPastTeamDays?: boolean
}): {
  loading: boolean
  error?: ApolloError
  teamDays: TeamDay[]
} {
  const { loading, error, data } = useQuery(QUERY, {
    variables: {
      managerId,
    },
    skip: managerId === "",
  })
  const teamDays = (data?.teamDays || [])
    .map((td: any) => {
      const { date, ...rest } = td
      return {
        ...rest,
        date: new UTCDate(new Date(date), "DAY", false),
      }
    })
    .filter((td: TeamDay) => {
      const yesterday = new UTCDate("DAY")
      yesterday.shift("DAY", -1)
      if (filterPastTeamDays) {
        return td.date.getTime() > yesterday.getTime()
      }
      return true
    })
  return { teamDays, loading, error }
}
