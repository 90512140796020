import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useSetSlotTemplatesUTC from "graphql/typicalWeek/useSetSlotTemplatesUTC"
import useTranslate from "intl/useTranslate"
import WorkingModeButtons from "pages/PlanningPage/components/SlotsEditor/SlotsEditorContent/WorkingModeButtons/WorkingModeButtons"
import React from "react"
import { EditionTimeFrame, User, WorkingMode } from "types"
import UTCDate from "utils/UTCDate"

import OfficeSection from "./OfficeSection"

interface PropsType {
  me: User
  setOpenDrawer: (open: "SLOTS_EDITOR" | "FLOOR_PLAN" | null) => void
  editionTimeFrame: EditionTimeFrame
  setEditionTimeFrame: (el: EditionTimeFrame) => void
  setLoading: (el: boolean) => void
}

export default function SimpleSlotEditor({
  me,
  setOpenDrawer,
  editionTimeFrame,
  setEditionTimeFrame,
  setLoading,
}: PropsType) {
  const t = useTranslate()
  const { addToast } = useToasts()
  const setSlotTemplates = useSetSlotTemplatesUTC()

  const getTimestamps = (from: UTCDate, to: UTCDate) => {
    const timestampsStart = new UTCDate(from)
    const timestampsEnd = new UTCDate(to)
    const timestamps: number[] = []
    for (
      const timestampCursor = timestampsStart;
      timestampsStart.getTime() <= timestampsEnd.getTime();
      timestampCursor.shift("HALF-DAY", 1)
    ) {
      timestamps.push(new UTCDate(timestampCursor).getTime())
    }
    return timestamps
  }

  const handleSaveSlot = ({
    genericSlotValue,
    label,
    locationId,
  }: WorkingMode) => {
    if (editionTimeFrame.from && editionTimeFrame.to) {
      setOpenDrawer(null)
      setLoading(true)
      setSlotTemplates(
        getTimestamps(editionTimeFrame.from, editionTimeFrame.to),
        genericSlotValue,
        locationId,
        label
      )
        .then(() => {
          addToast(t("changes-saved--success"), { appearance: "success" })
          setEditionTimeFrame({})
          setLoading(false)
        })
        .catch(() => {
          addToast(t("changes-saved--failure"), { appearance: "error" })
          setOpenDrawer(null)
          setEditionTimeFrame({})
        })
    } else return
  }

  if (editionTimeFrame.from && editionTimeFrame.to)
    return (
      <div className="locations-list">
        <WorkingModeButtons
          genericSlotValue="home"
          i8nKey="Home"
          label="Remote"
          selectedUser={me}
          saveWorkingMode={handleSaveSlot}
          from={editionTimeFrame.from}
          to={editionTimeFrame.to}
        />
        <WorkingModeButtons
          genericSlotValue="else"
          i8nKey="Other"
          label="Other"
          selectedUser={me}
          saveWorkingMode={handleSaveSlot}
          from={editionTimeFrame.from}
          to={editionTimeFrame.to}
        />
        <WorkingModeButtons
          genericSlotValue="off"
          i8nKey="Unavailable"
          label="Off"
          selectedUser={me}
          saveWorkingMode={handleSaveSlot}
          from={editionTimeFrame.from}
          to={editionTimeFrame.to}
        />
        <OfficeSection
          me={me}
          guestIds={[]} // typical week should not involved guests
          from={editionTimeFrame.from}
          to={editionTimeFrame.to}
          saveWorkingMode={handleSaveSlot}
        />
      </div>
    )
  else return <></>
}
