import useSetUserIntegrationFlag from "graphql/integrations/useSetUserIntegrationFlag"
import useTranslate from "intl/useTranslate"
import React from "react"
import { UserIntegration } from "types"
import { Toggle } from "ui"

interface PropsType {
  integration: UserIntegration
}

export default function WriteEventsToggle({ integration }: PropsType) {
  const t = useTranslate()
  const setFlag = useSetUserIntegrationFlag()

  const writeEvents =
    integration.flags["writeEvents"] !== undefined
      ? Boolean(integration.flags["writeEvents"])
      : true

  return (
    <Toggle
      label={t("writeEvents-toggle")}
      checked={writeEvents}
      onChange={() => {
        setFlag({
          name: integration.name,
          key: "writeEvents",
          value: !writeEvents,
        })
      }}
    />
  )
}
