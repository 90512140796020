import { Checkbox } from "@mui/material"
import React from "react"
import styled from "styled-components"
import { colors, P14 } from "ui"
import { CheckOff, CheckOn } from "ui/icons"

const DropDownItemContainer = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 7px 16px;
  background: hsla(${colors.blackHSL}, 0);
  cursor: pointer;

  > p {
    white-space: nowrap;
    &:first-letter {
      text-transform: capitalize;
    }
  }

  &:hover,
  &:focus {
    background: hsla(${colors.blackHSL}, 0.04);
  }
`

interface PropsType {
  option: { name: string; value: any }
  onClick: (obj: { name: string; value: any }) => void
  tabIndex?: 0 | -1
  checkBox?: boolean
  state?: undefined | string | string[]
}

export function DropDownItem({
  option: { name, value },
  onClick,
  tabIndex,
  checkBox,
  state,
}: PropsType) {
  return (
    <DropDownItemContainer
      onClick={() => {
        onClick({ name, value })
      }}
      onKeyDown={(event) => {
        if (event.key === "Enter") onClick({ name, value })
      }}
      tabIndex={tabIndex}
    >
      {checkBox && (
        <Checkbox
          style={{ color: colors.black, padding: 0 }}
          size="small"
          icon={<CheckOff />}
          checked={name === state}
          checkedIcon={<CheckOn />}
        />
      )}
      <P14>{name}</P14>
    </DropDownItemContainer>
  )
}
