import useMenu from "hooks/useMenu"
import React from "react"
import styled from "styled-components"
import { colors, Link, P14, P14Bold } from "ui"

const ListItem = styled.li`
  display: flex;
  align-items: center;
  min-height: 24px;
  width: 100%;

  list-style: none;
  padding: 2px 0 2px 24px;

  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  &:focus {
    background: hsla(${colors.blackHSL}, 0.08);
  }

  a {
    width: 100%;
  }

  &:hover {
    background: hsla(${colors.blackHSL}, 0.04);
  }

  &:focus-within,
  &.menu-element-item--selected {
    background: hsla(${colors.blackHSL}, 0.12);
    font-weight: 600;
  }

  &:hover:focus {
    background: linear-gradient(
        0deg,
        hsla(${colors.blackHSL}, 0.04),
        hsla(${colors.blackHSL}, 0.04)
      ),
      hsla(${colors.blackHSL}, 0.12);
  }
`

const Icon = styled.span`
  display: flex;
  min-width: 24px;
  svg {
    width: 16px;
    height: 16px;
  }
`

interface PropsType {
  title: string
  icon: JSX.Element
  path: string
}

interface ItemPropsType {
  path: string
  children: React.ReactNode
}

function Item({ path, children }: ItemPropsType) {
  const { handleLogEvent } = useMenu()

  const selected = location.pathname.startsWith(path)

  return (
    <ListItem
      className={selected ? "menu-element-item--selected" : "menu-element-item"}
      onClick={() => {
        handleLogEvent("menu-action", { to: path })
      }}
    >
      <Link naked to={path}>
        {children}
      </Link>
    </ListItem>
  )
}

export function SettingsItem({ title, icon, path }: PropsType) {
  const selected = location.pathname.startsWith(path)

  return (
    <Item path={path}>
      <Icon>{icon}</Icon>
      {selected ? <P14Bold>{title}</P14Bold> : <P14>{title}</P14>}
    </Item>
  )
}

export function EventItem({ title, icon, path }: PropsType) {
  const selected = location.pathname.startsWith(path)

  return (
    <Item path={path}>
      <Icon>{icon}</Icon>
      {selected ? <P14Bold>{title}</P14Bold> : <P14>{title}</P14>}
    </Item>
  )
}
