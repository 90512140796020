import styled from "styled-components/macro"
import { colors, mediaQueries } from "ui"

interface ElemProps {
  disabled?: boolean
}

export const Elem = styled.div<ElemProps>`
  display: flex;
  align-items: center;
  gap: 16px;
  height: 40px;
  border-bottom: 1px solid ${colors.grey3};
  padding: 8px;
  width: 80%;

  @media ${mediaQueries.isMobile} {
    width: 100vw;
    padding: 16px 18px;
  }

  .action {
    cursor: pointer;
    opacity: 0;
    margin-right: 12px;
    margin-left: auto;
    @media ${mediaQueries.isMobile} {
      opacity: 1;
      margin-right: 0px;
      svg {
        color: ${colors.grey2};
        height: 18px;
        width: 18px;
      }
    }
  }

  p {
    color: ${colors.purple};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & :hover {
    background: rgba(0, 0, 0, 0.04);
    .action {
      opacity: 1;
    }
  }

  & :first-child {
    margin-bottom: 24px;
  }
  @media ${mediaQueries.isMobile} {
    & :first-child {
      margin-bottom: 0;
    }
  }
`
