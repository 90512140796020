import LocaleSelector from "components/LocaleSelector"
import useTranslate from "intl/useTranslate"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Transition } from "react-transition-group"
import styled from "styled-components"
import { Button, Grid, P16 } from "ui"

import { Content, Footer, RightContainer, Title } from "./components/Layout"
import StepIndicator from "./components/StepIndicator"

const duration = 600

const defaultStyleSmallPopUp = {
  transition: `right ${duration}ms ease-in-out 400ms`,
  position: "absolute",
  top: "48%",
  width: "30%",
}
const defaultStyleMainPopUp = {
  transition: `right ${duration}ms ease-in-out 300ms`,
  position: "absolute",
  top: "25%",
}

const transitionStylesSmallPopUp: any = {
  entering: { right: 200 },
  entered: { right: 200 },
  exiting: { right: -300 },
  exited: { right: -300 },
}

const transitionStylesMainPop: any = {
  entering: { right: -110 },
  entered: { right: -110 },
  exiting: { right: -500 },
  exited: { right: -500 },
}

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: "100%";
  padding: 50px 70px;
  height: 150px;
`

export default function OnboardingWelcome() {
  const history = useHistory()
  const t = useTranslate()

  const [inProp, setInProp] = useState(false)

  useEffect(() => {
    setTimeout(() => setInProp(true), 1)
  }, [])

  return (
    <Grid container direction="row">
      <Grid
        item
        md={8}
        xl={9}
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header>
          <LocaleSelector />
        </Header>
        <Content>
          <Title>{t("welcome to Offishall")}</Title>
          <P16>{t("configure platform in 2 steps")}</P16>
        </Content>
        <Footer>
          <StepIndicator />
          <Button onClick={() => history.push("/users")}>
            {t("Get started")}
          </Button>
        </Footer>
      </Grid>
      <RightContainer item md={4} xl={3}>
        <Transition in={inProp} timeout={duration}>
          {(state) => (
            <div
              style={{
                ...defaultStyleMainPopUp,
                ...transitionStylesMainPop[state],
              }}
            >
              <img
                alt="Planning page"
                src="/static/img/onboarding/pop_up_1_step1.svg"
              />
            </div>
          )}
        </Transition>
        <Transition in={inProp} timeout={duration}>
          {(state) => (
            <div
              style={{
                ...defaultStyleSmallPopUp,
                ...transitionStylesSmallPopUp[state],
              }}
            >
              <img
                alt="Define slot value"
                src="/static/img/onboarding/pop_up_step1.svg"
              />
            </div>
          )}
        </Transition>
      </RightContainer>
    </Grid>
  )
}
