import { ApolloClient, ApolloError, gql, useApolloClient } from "@apollo/client"
import { GroupCompleteStatsFragment } from "graphql/groups/fragments/groupFragment"
import { useCallback, useState } from "react"
import { GroupCompleteStats } from "types"
import UTCDate from "utils/UTCDate"

const GROUPS_STATS_QUERY = gql`
  ${GroupCompleteStatsFragment}
  query stats_groupsStats(
    $from: Date!
    $to: Date!
    $orderBy: String!
    $limit: Int
    $offset: Int
  ) {
    stats_groupsStats(
      from: $from
      to: $to
      limit: $limit
      offset: $offset
      orderBy: $orderBy
    ) {
      ...GroupCompleteStatsFragment
    }
  }
`

const GROUPS_STATS_CSV_QUERY = gql`
  query stats_groupsStatsCSV($from: Date!, $to: Date!, $orderBy: String!) {
    stats_groupsStatsCSV(from: $from, to: $to, orderBy: $orderBy)
  }
`

export default function useTeamsStats(limit?: number): {
  loading: boolean
  hasSearched: boolean
  error?: ApolloError
  groupsStats: GroupCompleteStats[]
  start: (from: UTCDate, to: UTCDate, orderBy: string) => void
  getCSV: () => Promise<string>
  fetchMore: () => void
  canFetchMore: boolean
} {
  const client = useApolloClient()
  const [loading, setLoading] = useState(false)
  const [canFetchMore, setCanFetchMore] = useState(false)
  const [offset, setOffset] = useState<number | undefined>(
    limit && limit > 0 ? 0 : undefined
  )
  const [orderBy, setOrderBy] = useState<string>("")
  const [from, setFrom] = useState<UTCDate | null>(null)
  const [to, setTo] = useState<UTCDate | null>(null)
  const [hasSearched, setHasSearched] = useState<boolean>(false)
  const [groupsStats, setGroupsStats] = useState<GroupCompleteStats[]>([])
  const [error, setError] = useState<ApolloError | undefined>(undefined)

  const executeQuery = async (
    apolloClient: ApolloClient<any>,
    from: UTCDate,
    to: UTCDate,
    orderBy: string,
    limit?: number,
    offset?: number
  ) => {
    const { data } = await apolloClient.query({
      query: GROUPS_STATS_QUERY,
      variables: { limit, offset, orderBy, from: from, to: to },
    })
    return data.stats_groupsStats || []
  }

  const start = useCallback(
    async (from: UTCDate, to: UTCDate, orderBy: string) => {
      if (limit) setOffset(0)
      try {
        setLoading(true)
        setOrderBy(orderBy)
        setFrom(from)
        setTo(to)
        const newUsers = await executeQuery(
          client,
          from,
          to,
          orderBy,
          limit,
          limit ? 0 : undefined
        )
        setHasSearched(true)
        setLoading(false)
        setGroupsStats(newUsers)
        setCanFetchMore(newUsers.length > 0 && newUsers.length === limit)
      } catch (e) {
        setError(e)
      }
    },
    [client, setGroupsStats, limit]
  )

  const fetchMore = useCallback(async () => {
    if (limit) {
      let newOffset = limit
      if (offset) newOffset += offset
      try {
        if (from && to) {
          setLoading(true)
          const newGroupsStats = await executeQuery(
            client,
            from,
            to,
            orderBy,
            limit,
            newOffset
          )
          setLoading(false)
          setCanFetchMore(
            newGroupsStats.length > 0 && newGroupsStats.length === limit
          )
          setGroupsStats([...groupsStats, ...newGroupsStats])
          setOffset(newOffset)
        }
      } catch (e) {
        setError(e)
      }
    }
  }, [setGroupsStats, from, to, orderBy, limit, offset, client, groupsStats])

  const getCSV = useCallback(async () => {
    if (from && to) {
      const { data } = await client.query({
        query: GROUPS_STATS_CSV_QUERY,
        variables: { from: from, to: to, orderBy },
      })
      if (data?.stats_groupsStatsCSV) {
        return data.stats_groupsStatsCSV
      }
    }
    return ""
  }, [client, from, to, orderBy])

  return {
    groupsStats,
    loading,
    error,
    start,
    fetchMore,
    getCSV,
    canFetchMore,
    hasSearched,
  }
}
