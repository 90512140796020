import { useState } from "react"
import { useCallback } from "react"
// import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

export default function useProtectedRoute(
  name: string,
  path: string,
  method: "GET" | "POST"
): {
  callRoute: (body?: Record<string, string | number | boolean>) => Promise<any>
  loading: boolean
} {
  // const { executeRecaptcha } = useGoogleReCaptcha()
  const [loading, setLoading] = useState(false)
  const callRoute = useCallback(
    async (body?: Record<string, string | number | boolean>) => {
      /*if (!executeRecaptcha) {
        throw new Error(`Google ReCaptcha not ready`)
      }*/
      setLoading(true)
      try {
        // const token = await executeRecaptcha(name)
        const url = `${process.env.REACT_APP_API}${path}`
        const res = await fetch(url, {
          method,
          body: body ? JSON.stringify(body) : undefined,
          headers: {
            "Content-Type": "application/json",
            // "g-recaptcha-response": token,
          },
        })
        if (res.status === 200) {
          const data = await res.json()
          setLoading(false)
          return data
        }
        const error = await res.text()
        setLoading(false)
        throw new Error(error)
      } catch (e) {
        setLoading(false)
        throw new Error(e.message)
      }
    },
    [path, method]
  )

  return {
    loading,
    callRoute,
  }
}
