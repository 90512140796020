import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { useEffect, useState } from "react"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore-next-line
import { useProductFruits } from "react-product-fruits"
import styled from "styled-components"
import { Button } from "ui"
import colors from "ui/colors"

import PFHubspotChat from "./PFHubspotChat"

const Layout = styled.div`
  width: 100%;
  padding: 8px 24px;
  border-bottom: solid 1px ${colors.grey3};
`

const ButtonStyled = styled(Button)`
  height: auto;
  background-color: ${colors.black};
  width: 100%;
  border-radius: 24px;
  text-transform: uppercase;
  &:hover {
    background-color: ${colors.black};
  }

  &:focus {
    background-color: ${colors.black};
  }

  &:active {
    background-color: ${colors.black};
  }

  &:disabled {
    background-color: ${colors.black};
  }
`

export default function PFLifeRingButton() {
  const [isReady, setIsReady] = useState(false)
  const { isMobile, isNarrowScreen } = useMediaQueries()
  const [isOpen, setIsOpen] = useState(false)
  const t = useTranslate()

  useProductFruits((api: any) => {
    setIsReady(true)
    if (!isOpen) {
      api.button.hide()
    }
  }, [])

  useEffect(() => {
    if (isReady === true && !isMobile && !isNarrowScreen) {
      const w = window as any
      const removeListener: null | (() => void) =
        w.productFruits.api.button.listen("closed", () => {
          setIsOpen(false)
        })
      return () => {
        if (removeListener) {
          if (w?.productFruits?.api?.button?.hideModal) {
            w.productFruits.api.button.hideModal()
          }
          removeListener()
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, isMobile, isNarrowScreen])

  if (!isReady || isMobile || isNarrowScreen) return <></>

  return (
    <Layout>
      <ButtonStyled
        data-component="product-fruits-help-center-menu-button"
        fullWidth
        onClick={() => {
          if (isReady) {
            const w = window as any
            if (!isOpen) {
              w.productFruits.api.button.show()
              setIsOpen(true)
            } else {
              w.productFruits.api.button.hideModal()
              setIsOpen(false)
            }
          }
        }}
      >
        {t("help")}
      </ButtonStyled>
      <PFHubspotChat />
    </Layout>
  )
}
