import React, { ButtonHTMLAttributes, MouseEvent } from "react"
import styled from "styled-components"

import colors from "../colors"

interface StyledIcon {
  readonly $iconColor?: string
}

interface StyledButton {
  $width?: number
  fullWidth?: boolean
  gap?: number
}

const BaseButton = styled.button<StyledButton>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ gap }) => (gap ? `gap: ${gap}px;` : undefined)}
  height: 40px;
  padding: 0 16px;
  border-radius: 4px;
  width: ${({ $width }) => ($width ? `${$width}px` : "fit-content")};
  ${({ fullWidth }) => (fullWidth ? "width: 100%;" : "")}

  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;

  &:disabled {
    cursor: not-allowed;
  }
`

const PrimaryButton = styled(BaseButton)`
  background: ${colors.purple};
  border: 1px solid ${colors.purple};
  color: ${colors.white};

  &:hover {
    background: linear-gradient(
        0deg,
        hsla(${colors.whiteHSL}, 0.08),
        hsla(${colors.whiteHSL}, 0.08)
      ),
      ${colors.purple};
  }

  &:focus {
    background: linear-gradient(
        0deg,
        hsla(${colors.whiteHSL}, 0.24),
        hsla(${colors.whiteHSL}, 0.24)
      ),
      ${colors.purple};
  }

  &:active {
    background: linear-gradient(
        0deg,
        hsla(${colors.whiteHSL}, 0.32),
        hsla(${colors.whiteHSL}, 0.32)
      ),
      ${colors.purple};
  }

  &:disabled {
    background: ${colors.grey3};
    border-color: ${colors.grey3};
    color: ${colors.grey2};
  }
`

const SecondaryButton = styled(BaseButton)`
  background: hsla(${colors.blackHSL}, 0);
  border: 1px solid ${colors.grey3};
  color: ${colors.purple};

  &:hover {
    background: hsla(${colors.blackHSL}, 0.04);
    border: solid 1px ${colors.grey3};
  }

  &:focus {
    background: hsla(${colors.blackHSL}, 0.12);
    border: solid 1px ${colors.purple};
  }

  &:active {
    background: hsla(${colors.blackHSL}, 0.24);
    border: solid 1px ${colors.grey3};
  }

  &:disabled {
    background: ${colors.grey4};
    border: solid 1px ${colors.grey4};
    color: ${colors.grey2};
  }
`

const GhostButton = styled(BaseButton)`
  background: hsla(${colors.blackHSL}, 0);
  border: solid 1px hsla(0, 0%, 0%, 0);
  color: ${colors.purple};

  &:hover {
    background: hsla(${colors.blackHSL}, 0.04);
  }

  &:focus {
    background: hsla(${colors.blackHSL}, 0.12);
  }

  &:active {
    background: hsla(${colors.blackHSL}, 0.24);
  }

  &:disabled {
    background: ${colors.grey4};
    color: ${colors.grey2};
  }
`

const DangerButton = styled(BaseButton)`
  background: hsla(${colors.whiteHSL}, 0);
  border: 1px solid ${colors.grey3};
  color: ${colors.redAlert};

  &:hover {
    background: hsla(${colors.blackHSL}, 0.04);
  }

  &:focus {
    background: hsla(${colors.blackHSL}, 0.12);
    border: 1px solid ${colors.redAlert};
  }

  &:active {
    background: hsla(${colors.blackHSL}, 0.24);
    border: 1px solid ${colors.grey3};
  }

  &:disabled {
    background: ${colors.grey4};
    color: ${colors.grey2};
  }
`

const ButtonIconSpan = styled.span<StyledIcon>`
  display: flex;
  padding-right: 16px;
  color: ${({ $iconColor }) => ($iconColor ? $iconColor : "")};

  svg {
    width: 24px;
    height: 24px;
  }
`

interface PropsType {
  secondary?: boolean
  ghost?: boolean
  danger?: boolean

  children?: React.ReactNode
  icon?: JSX.Element
  iconColor?: string
  width?: number

  fullWidth?: boolean
  gap?: number

  // Button props
  disabled?: boolean
  onClick?: () => void

  type?: "button" | "submit" | "reset"
  stopPropagation?: boolean
}

export function Button({
  secondary,
  ghost,
  danger,
  children,
  icon,
  iconColor,
  type = "button",
  width,
  fullWidth,
  onClick,
  stopPropagation,
  ...rest
}: PropsType) {
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (stopPropagation) event.stopPropagation()
    if (onClick) onClick()
  }
  if (secondary)
    return (
      <SecondaryButton
        {...rest}
        type={type}
        $width={width}
        fullWidth={fullWidth}
        onClick={handleClick}
      >
        {icon && <ButtonIconSpan $iconColor={iconColor}>{icon}</ButtonIconSpan>}
        {children}
      </SecondaryButton>
    )
  if (ghost)
    return (
      <GhostButton
        {...rest}
        type={type}
        $width={width}
        fullWidth={fullWidth}
        onClick={handleClick}
      >
        {icon && <ButtonIconSpan>{icon}</ButtonIconSpan>}
        {children}
      </GhostButton>
    )
  if (danger)
    return (
      <DangerButton
        {...rest}
        type={type}
        $width={width}
        fullWidth={fullWidth}
        onClick={handleClick}
      >
        {icon && <ButtonIconSpan>{icon}</ButtonIconSpan>}
        {children}
      </DangerButton>
    )
  return (
    <PrimaryButton
      {...rest}
      type={type}
      $width={width}
      fullWidth={fullWidth}
      onClick={handleClick}
    >
      {icon && <ButtonIconSpan>{icon}</ButtonIconSpan>}
      {children}
    </PrimaryButton>
  )
}

const UnstyledButtonLayout = styled.button`
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background: none;
  display: flex;
`

type UnstyledButtonPropsType = ButtonHTMLAttributes<HTMLButtonElement>

export function UnstyledButton({ ...props }: UnstyledButtonPropsType) {
  return <UnstyledButtonLayout {...props}></UnstyledButtonLayout>
}
