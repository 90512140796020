import SlotsEditorLocationsList from "components/Collapses/LocationsCollapse/SlotsEditorLocationsList"
import SearchBarForm from "components/Search/SearchBarForm/SearchBarForm"
import useLocationsTree from "graphql/locations/useLocationsTree"
import useOccupations from "graphql/slots/useOccupations"
import useCompanyFlag from "hooks/useCompanyFlag"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import StatusTitle from "pages/PlanningPage/components/SlotsEditor/SlotsEditorContent/WorkingModeButtons/StatusTitle/StatusTitle"
import React, { useState } from "react"
import styled from "styled-components"
import { User, WorkingMode } from "types"
import { ChipButton, Dialog, mediaQueries, P14 } from "ui"
import { Search as SearchIcon } from "ui/icons"
import isIphone from "utils/isIphone"
import LocationTree, { getChildrenLocations } from "utils/LocationTree"
import UTCDate, { days } from "utils/UTCDate"

const StyledChipButton = styled(ChipButton)`
  margin-top: 8px;
  margin-left: 16px;
`

const DialogContent = styled.div`
  padding: 8px;
  min-height: 70vh;
  max-height: 70vh;
  height: 70vh;
  display: grid;
  grid-template-rows: auto 1fr;

  > :nth-child(1) {
    // locations search
    padding: 8px;
  }

  .desk-booking-date {
    margin-top: 8px;
  }

  > :nth-child(2) {
    // locations list
    overflow-y: scroll;
    max-height: calc(70vh - 56px);
  }

  @media ${mediaQueries.isMobile} {
    min-height: unset;
    max-height: unset;
    height: unset;

    > :nth-child(2) {
      // locations list
      max-height: calc(
        100vh - 130px - env(safe-area-inset-bottom, 0) -
          env(safe-area-inset-top, 0)
      );
    }
  }
`

interface PropsType {
  me: User
  guestIds: string[]
  from: UTCDate
  to: UTCDate
  saveWorkingMode: (workingMode: WorkingMode) => void | Promise<void>
}

export default function OfficeSection({
  me,
  guestIds,
  from,
  to,
  saveWorkingMode,
}: PropsType) {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()
  const { value: limitSlotInput }: { enabled: boolean; value?: number } =
    useCompanyFlag("limitSlotInput", { enabled: false, value: undefined })
  const { value: reminderDay }: { value: string; enabled: boolean } =
    useCompanyFlag("weeklyReminder", {
      value: "Thursday",
      enabled: false,
    })

  const { occupations } = useOccupations(from.getTime(), to.getTime())
  const [openLocationPicker, setOpenLocationPicker] = useState(false)
  const { locations } = useLocationsTree()
  const [searchString, setSearchString] = useState("")
  const [locationsFromSearch, setLocationsFromSearch] = useState<
    LocationTree[]
  >([])

  const filterLocations = (searchString: string) => {
    if (searchString === "") return setLocationsFromSearch([])
    const filteredLocations = getChildrenLocations(locations, false).filter(
      (l) => l.name.toLowerCase().includes(searchString.toLowerCase())
    )
    setLocationsFromSearch(filteredLocations)
  }

  const resetSearchForm = () => {
    setSearchString("")
    setLocationsFromSearch([])
  }

  const firstBookableMonday = limitSlotInput
    ? new UTCDate("DAY") // NOT TRUE should be in sync with selected slots
    : undefined
  if (firstBookableMonday && limitSlotInput)
    firstBookableMonday.shift("WEEK", limitSlotInput ? 1 * limitSlotInput : 0)

  const reminderDate = new UTCDate(firstBookableMonday)
  const reminderDayWeekIndex = days.indexOf(reminderDay)
  if (reminderDayWeekIndex > 0) {
    const shiftingAmount =
      reminderDayWeekIndex - 1 < 0 ? 6 : reminderDayWeekIndex - 1
    reminderDate.shift("DAY", shiftingAmount)
  }

  return (
    <>
      <StatusTitle status="office" error={undefined} from={from} to={to}>
        {t("at work")}
      </StatusTitle>
      <StyledChipButton onClick={() => setOpenLocationPicker(true)}>
        {t("select a location")}
      </StyledChipButton>

      <Dialog
        open={openLocationPicker}
        title={t("select a location")}
        fullscreen={isMobile}
        onClose={() => {
          setSearchString("")
          setOpenLocationPicker(false)
        }}
      >
        <DialogContent>
          <div>
            <SearchBarForm
              searchString={searchString}
              handleChange={(str) => {
                setSearchString(str)
                filterLocations(str)
              }}
              resetForm={resetSearchForm}
              icon={isIphone ? undefined : <SearchIcon />}
              placeholder={t("search for zone or space")}
              onSubmit={(e) => {
                e.preventDefault()
              }}
              autoFocus
            />
            <P14 className="desk-booking-date">
              {t("Your organisation allows for desk booking until the")}{" "}
              <strong>{reminderDate.format("day-DD-MM", t)}</strong>.
            </P14>
          </div>

          <SlotsEditorLocationsList
            from={from}
            to={to}
            selectedUser={me}
            hidePrivateLocationsEvenIfUserIsAuthorized={false}
            occupations={occupations}
            disableEquipments
            selectedLocationId={undefined}
            externalLocations={
              locationsFromSearch.length > 0 ? locationsFromSearch : undefined
            }
            isTypicalWeek
            guestIds={guestIds}
            saveWorkingMode={(workingMode) => {
              saveWorkingMode(workingMode)
              if (workingMode.locationId) {
                setOpenLocationPicker(false)
                resetSearchForm()
              }
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}
