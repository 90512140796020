import { useApolloClient } from "@apollo/client"
import React, { useEffect, useState } from "react"
import TagManager from "react-gtm-module"
import { Redirect, Route, Switch } from "react-router-dom"
import { useHistory } from "react-router-dom"
import { setHistory } from "utils/firebase"

import AccountValidation from "./AccountValidation"
import AuthLayout from "./AuthLayout"
import EmailActionHandler from "./EmailActionHandler"
import LinkAccount from "./LinkAccount"
import ResetPassword from "./ResetPassword"
import Signin from "./Signin"
// import SigninForOnboarding from "./SigninForOnboarding"
import SignInWithSSOPage from "./SignInWithSSOPage"
import Signup from "./Signup"
import SignupWithMSTransactable from "./SignupWithMSTransactable"
import SignupWithToken from "./SignupWithToken"

export default function PublicApp() {
  const client = useApolloClient()
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  useEffect(() => {
    setLoading(false)
  }, [client])
  useEffect(() => {
    if (process.env.REACT_APP_URL === "https://app.offishall.io") {
      TagManager.initialize({ gtmId: "GTM-TXHJGJX" })
    }
  }, [])
  setHistory(history)
  if (loading) return null
  return (
    <AuthLayout>
      <Switch>
        <Route path="/signup-company" exact>
          <Signup />
        </Route>
        <Route path="/link-account" exact>
          <LinkAccount />
        </Route>
        <Route path="/signup-transactable" exact>
          <SignupWithMSTransactable />
        </Route>
        <Route path="/signup/:token" exact>
          <SignupWithToken />
          {/*<SigninForOnboarding />*/}
        </Route>
        <Route path="/onboarding/signin/:token" exact>
          <SignupWithToken />
          {/*<SigninForOnboarding />*/}
        </Route>
        <Route path="/sign-in">
          <Signin />
        </Route>
        <Route path="/sso">
          <SignInWithSSOPage />
        </Route>
        <Route path="/account-validation">
          <AccountValidation />
        </Route>
        <Route path="/email-action">
          <EmailActionHandler />
        </Route>
        <Route path="/reset-password">
          <ResetPassword />
        </Route>
        <Route path="/" exact>
          <Signin />
        </Route>
        <Redirect to="/" />
      </Switch>
    </AuthLayout>
  )
}
