import React, { useRef } from "react"
import styled from "styled-components"
import { Button } from "ui"

const ButtonStyled = styled(Button)`
  width: 100%;
  gap: 8px;
`

interface PropsType {
  children: React.ReactNode
  accept?: string
  handleChange: (filelist: FileList) => void
}

export function InputFileButton({ children, accept, handleChange }: PropsType) {
  const uploadInputRef = useRef<HTMLInputElement>(null)

  return (
    <>
      <input
        ref={uploadInputRef}
        accept={accept}
        type="file"
        style={{ display: "none" }}
        onChange={(e) => {
          if (e.target.files) handleChange(e.target.files)
        }}
      />
      <label htmlFor="upload-button-file">
        <ButtonStyled
          secondary
          onClick={() =>
            uploadInputRef.current && uploadInputRef.current.click()
          }
        >
          {children}
        </ButtonStyled>
      </label>
    </>
  )
}
