import useUser from "graphql/users/useUser"
import useMediaQueries from "hooks/useMediaQueries"
import React, { useRef } from "react"
import styled from "styled-components"
import { IntegrationEvent } from "types"
import { colors, mediaQueries } from "ui"
import filterEventsOfTheDay, {
  filterMeAsAttendingAttendeeEvents,
} from "utils/events"
import UTCDate from "utils/UTCDate"

const EventIconAsShape = styled.span`
  margin: auto 0;
  width: 6px;
  height: 6px;
  background: ${colors.grey2};
  border-radius: 100vh;
`

const EmptyDiv = styled.div`
  width: 24px;
  height: 20px;

  @media ${mediaQueries.isMobile} {
    display: none;
  }
`

const Counter = styled.div`
  display: flex;
  align-items: center;
  padding: 0 3px;
  .counter {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100vh;
    background: ${colors.purple};
    color: ${colors.white};
    width: 16px;
    height: 16px;
    padding: 2px;
    margin: auto 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }
`

interface PropsType {
  events: IntegrationEvent[]
  day: UTCDate
  setPopupAnchorEl: (el: HTMLElement | null) => void
}

export default function EventCounter({
  events,
  day,
  setPopupAnchorEl,
}: PropsType) {
  const { user: me } = useUser()
  const { isMobile } = useMediaQueries()
  const iconRef = useRef<HTMLDivElement | null>(null)

  const eventsOfTheDayAttendingByMe = events
    .filter(filterEventsOfTheDay(day))
    .filter(filterMeAsAttendingAttendeeEvents(me))
    .filter((e): e is IntegrationEvent => e !== undefined)

  if (eventsOfTheDayAttendingByMe.length === 0) return <EmptyDiv />

  if (isMobile) return <EventIconAsShape />

  return (
    <Counter>
      <div
        className="counter"
        ref={iconRef}
        onMouseOver={() => {
          setPopupAnchorEl(iconRef.current)
        }}
      >
        {eventsOfTheDayAttendingByMe.length}
      </div>
    </Counter>
  )
}
