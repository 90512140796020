import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { UsersAtFilter } from "types"
import { colors, PopperMenu } from "ui"

import { Circle } from "../components/components"

const PopperLayout = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  cursor: pointer;

  li {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;

    span {
      padding: 0;
    }

    &:hover {
      background: ${colors.black}08;
    }

    transition: background-color 250ms ease-in-out;
  }
`

interface PropsType {
  open: boolean
  anchorEl: HTMLElement | null
  setOpen: (value: boolean) => void
  handleFilterUpdate: (filter: UsersAtFilter | undefined) => void
}

export default function UsersAtFiltersContent({
  open,
  anchorEl,
  setOpen,
  handleFilterUpdate,
}: PropsType) {
  const t = useTranslate()

  const availableFilters: UsersAtFilter[] = [
    "available",
    "office",
    "home",
    "else",
    "off",
    "empty-planning",
    "not-empty-planning",
  ]

  return (
    <PopperMenu
      open={open}
      anchorEl={anchorEl}
      placement="bottom-end"
      onClose={() => setOpen(false)}
      style={{ zIndex: 5000, paddingTop: "6px" }}
    >
      <PopperLayout>
        {React.Children.map(availableFilters, (filter) => (
          <li
            onClick={() => {
              handleFilterUpdate(filter)
              setOpen(false)
            }}
          >
            <Circle className={`circle circle--${filter}`} />
            {t(`users_at_filter--${filter}`)}
          </li>
        ))}
      </PopperLayout>
    </PopperMenu>
  )
}
