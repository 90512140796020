import { ApolloError, gql, useQuery } from "@apollo/client"
import { MeetingRoomWithEvents } from "types"
import LocalDate from "utils/LocalDate"

import { MeetingRoomWithEventsFragment } from "./fragments/meetingRoomFragments"

export const MEETING_ROOM_EVENTS_QUERY = gql`
  ${MeetingRoomWithEventsFragment}
  query integrations_meetingRoomEvents(
    $id: String!
    $from: String!
    $to: String!
  ) {
    integrations_meetingRoomEvents(id: $id, from: $from, to: $to) {
      ...MeetingRoomWithEventsFragment
    }
  }
`

export default function useMeetingRoomEvents(
  id: string,
  from: LocalDate,
  to: LocalDate
): {
  loading: boolean
  error: ApolloError | undefined
  meetingRoomWithEvents: MeetingRoomWithEvents | undefined
} {
  const { loading, error, data } = useQuery<{
    integrations_meetingRoomEvents: MeetingRoomWithEvents
  }>(MEETING_ROOM_EVENTS_QUERY, {
    variables: {
      id,
      from: `${from.getTime()}`,
      to: `${to.getTime()}`,
    },
    fetchPolicy: "cache-and-network",
  })

  const events = data
    ? data.integrations_meetingRoomEvents.events.map((e) => ({
        ...e,
        from: new LocalDate(Number(e.from)),
        to: new LocalDate(Number(e.to)),
      }))
    : []

  return {
    loading,
    error,
    meetingRoomWithEvents: data
      ? { ...data.integrations_meetingRoomEvents, events }
      : undefined,
  }
}
