import styled from "styled-components"
import { colors } from "ui"

export const Layout = styled.div`
  display: grid;
  grid-template-columns: 360px auto;
`

interface SampleListPropsType {
  alreadyOccupiedHeight?: number
}

export const SampleList = styled.div<SampleListPropsType>`
  border-right: solid 1px ${colors.grey3};
  overflow-y: scroll;
  ${({ alreadyOccupiedHeight }) => {
    if (alreadyOccupiedHeight !== undefined) {
      const extraBottomPadding = 12
      return `height: calc(100vh - ${
        alreadyOccupiedHeight - extraBottomPadding
      }px);`
    }
  }}
`

export const DataLayout = styled.div`
  display: grid;
  grid-template-rows: min-content auto;
`

interface ChartLayoutPropsType {
  alreadyOccupiedHeight?: number
}

export const ChartLayout = styled.div<ChartLayoutPropsType>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 8px;

  ${({ alreadyOccupiedHeight }) => {
    if (alreadyOccupiedHeight !== undefined) {
      const bottomPaddingInExcess = 200
      return `
      height: calc(100vh - ${alreadyOccupiedHeight + bottomPaddingInExcess}px);
        max-height: calc(100vh - ${
          alreadyOccupiedHeight + bottomPaddingInExcess
        }px);
        `
    }
  }}
  > canvas {
    padding-left: 16px;
  }

  > .stats-occupancy-dropdown {
    margin-left: 24px;
    width: fit-content;
  }

  > :last-child {
    margin-left: auto;
  }
`
