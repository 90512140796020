import { gql } from "@apollo/client"

export const SearchResultFragment = gql`
  fragment SearchResultFragment on SearchResult {
    id
    type
    name
    email
  }
`
