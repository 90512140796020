import Loader from "components/Loader/Loader"
import useDefaultHybridRule from "graphql/hybridPolicy/useDefaultHybridRule"
import useDeleteDefaultHybridRule from "graphql/hybridPolicy/useDeleteDefaultHybridRule"
import { getRuleFromTo } from "hooks/useHybridRuleScore"
import React from "react"
import styled from "styled-components"
import { DefaultHybridRule } from "types"
import { colors } from "ui"
import UTCDate from "utils/UTCDate"

import HyridRuleCard from "../HyridRuleCard/HyridRuleCard"
import CompanyScoreCard from "./CompanyScoreCard/CompanyScoreCard"

const List = styled.ul`
  display: grid;
  grid-template-columns: 386px 1fr;
  gap: 16px 32px;
  width: 100%;

  li.header {
    grid-column-start: 1;
    grid-column-end: -1;
    background: ${colors.grey4};
    border-radius: 16px;
    padding: 16px;
  }

  > :last-child {
    grid-column-start: 2;
    grid-column-end: -1;
  }
`

const EmptyCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  width: 100%;
  min-height: 219px;
  border-top: solid 1px ${colors.grey3}44;
`

function LoadingCard() {
  return (
    <EmptyCard>
      <Loader />
    </EmptyCard>
  )
}

interface PropsType {
  rule: DefaultHybridRule
  setSelectedDefaultHybridRule: (rule: DefaultHybridRule) => void
}

function DefaultHybridRuleCard({
  rule,
  setSelectedDefaultHybridRule,
}: PropsType) {
  const { defaultHybridRule, loading } = useDefaultHybridRule(
    getRuleFromTo(rule, new UTCDate())
  )
  const deleteDefaultHybridRule = useDeleteDefaultHybridRule()

  if (loading) return <LoadingCard />
  if (!defaultHybridRule) return <></>

  return (
    <HyridRuleCard
      rule={defaultHybridRule}
      setSelectedUserOrGroupHybridRule={setSelectedDefaultHybridRule}
      deleteHybridRule={deleteDefaultHybridRule}
    />
  )
}

interface WrapperPropsType {
  defaultHybridRule: DefaultHybridRule | undefined
  setSelectedDefaultHybridRule: (rule: DefaultHybridRule) => void
}

export default function Wrapper({
  defaultHybridRule,
  setSelectedDefaultHybridRule,
}: WrapperPropsType) {
  if (!defaultHybridRule) return <></>

  return (
    <List>
      <DefaultHybridRuleCard
        rule={defaultHybridRule}
        setSelectedDefaultHybridRule={setSelectedDefaultHybridRule}
      />
      <CompanyScoreCard />
    </List>
  )
}
