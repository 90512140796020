import BookmarkCheckbox from "components/BookmarkCheckbox/BookmarkCheckbox"
import FavoriteCheckbox from "components/FavoriteCheckbox"
import MobileNavBack from "components/Layout/PageLayout/PageHeader/PageTitle/MobileNavBack/MobileNavBack"
import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import MobileBottomNavigation from "components/MobileBottomNavigation/MobileBottomNavigation"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import EditManager from "components/UpdateManagerButton/EditManager"
import UserAvatar from "components/UserAvatar/UserAvatar"
import useToggleFavorite from "graphql/favorites/useToggleFavorite"
import useUserFavorites from "graphql/favorites/useUserFavorites"
import useAddUserSubscription from "graphql/subscriptions/useAddUserSubscription"
import useRemoveUserSubscription from "graphql/subscriptions/useRemoveUserSubscription"
import useUserSubscriptions from "graphql/subscriptions/useUserSubscriptions"
import useUser from "graphql/users/useUser"
import useUserFromId from "graphql/users/useUserFromId"
import useMediaQueries from "hooks/useMediaQueries"
import usePacman from "hooks/usePacman"
import useWindowSize from "hooks/useWindowResize"
import useTranslate from "intl/useTranslate"
import CalendarDays from "pages/PlanningPage/components/CalendarDays/CalendarDays"
import EditableUserSlots from "pages/PlanningPage/components/EditableUserSlots/EditableUserSlots"
import PlanningNavigation from "pages/PlanningPage/components/PlanningNavigation/PlanningNavigation"
import PlanningProgress from "pages/PlanningPage/components/PlanningProgress/PlanningProgress"
import PlanningRowLayout from "pages/PlanningPage/components/PlanningRowLayout/PlanningRowLayout"
import UsersPlanningList from "pages/PlanningPage/components/UsersPlanningList/UsersPlanningList"
import React, { useEffect, useRef } from "react"
import { useParams } from "react-router"
import styled from "styled-components"
import { User } from "types"
import { BigToggle, Button, colors, H3, mediaQueries } from "ui"
import { Mail } from "ui/icons"
import getSafeAvailableHeightCSSProperties from "utils/getSafeAvailableHeightCSSProperties"
import getUserDisplayName from "utils/getUserDisplayName"

import CanMutatePlanningMessage from "./components/CanMutatePlanningMessage"
import { CheckboxContainer } from "./components/CheckboxContainer"
import MobileHeader from "./components/MobileHeader"
import MyTeamUsersListHeader from "./MyTeamUsersListHeader/MyTeamUsersListHeader"

const Layout = styled.div<{ maxHeight: number }>`
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;
  height: 100vh;
  max-height: 100vh;

  @media ${mediaQueries.isMobile} {
    ${({ maxHeight }) => getSafeAvailableHeightCSSProperties(maxHeight)}
  }
`

const Body = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
`

const DesktopHeader = styled.section`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px 32px 0 32px;
`

const DesktopTitleWithFavoriteDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  div:last-child {
    margin-left: auto;
  }
`

const DesktopHeaderActions = styled.div`
  display: flex;
  gap: 4px;
`

const EmailButton = styled(Button)`
  a {
    display: flex;
    align-items: center;
    gap: 8px;
    text-decoration: none;

    span {
      color: ${colors.purple};
    }
    svg {
      stroke: ${colors.black};
    }
  }
`

const UserPlanningSection = styled.div`
  padding-top: 16px;
  .date-selection {
    border-top: solid 1px ${colors.grey3};
  }

  .user-planning {
    border-top: solid 1px ${colors.grey3};
    border-bottom: solid 1px ${colors.grey3};
  }

  @media ${mediaQueries.isMobile} {
    padding-top: 0;

    .date-selection {
      border-top: none;

      > div:first-child {
        border-bottom: solid 1px ${colors.grey3};
      }

      > :nth-child(2) {
        padding: 4px 8px;
      }
    }

    .user-planning {
      > div:first-child {
        padding-left: 8px;
      }
    }
  }
`

interface PropsType {
  selectedUser: User
  editionActive: boolean
}

function UserPageContent({ selectedUser, editionActive }: PropsType) {
  const t = useTranslate()

  const { user: me } = useUser()
  const { users: usersFromCtx, viewMode, setViewMode } = usePlanningContext()

  const editableSlotsRef = useRef<HTMLDivElement>(null)

  const { isMobile } = useMediaQueries()
  const [, maxHeight] = useWindowSize()

  const toggleFavorite = useToggleFavorite()
  const { favorites } = useUserFavorites()
  const isFavorite =
    favorites[`users`].find((f) => f.itemId == selectedUser.id) !== undefined

  const { subscriptions } = useUserSubscriptions()
  const { addUserSubscription } = useAddUserSubscription()
  const { removeUserSubscription } = useRemoveUserSubscription()
  const meHasSubscribedToSelectedUser =
    subscriptions.find((s) => s.id === selectedUser.id) !== undefined

  const users =
    viewMode === "MONTH" ? [selectedUser, ...usersFromCtx] : usersFromCtx
  const displayMyTeam = !editionActive && usersFromCtx.length > 0

  const { setPosition } = usePacman()

  useEffect(() => {
    if (editableSlotsRef.current) {
      const position = {
        x: editableSlotsRef.current.getBoundingClientRect().x,
        y: editableSlotsRef.current.getBoundingClientRect().y,
      }
      setPosition(position)
    }
  }, [editableSlotsRef, setPosition])

  if (!me || !selectedUser) return <></>

  return (
    <Layout maxHeight={maxHeight}>
      <div className="user-page-header">
        {isMobile && <MobileHeader selectedUser={selectedUser} />}
        {!isMobile && (
          <DesktopHeader>
            <MobileNavBack />
            <DesktopTitleWithFavoriteDiv>
              <UserAvatar user={selectedUser} size={32} />
              <H3>
                {getUserDisplayName(selectedUser, selectedUser.company.flags)}
              </H3>
              <CheckboxContainer
                active={isFavorite}
                onClick={() => toggleFavorite(selectedUser.id, "user")}
              >
                <FavoriteCheckbox type="user" itemId={selectedUser.id} />
                {t("Favorites")}
              </CheckboxContainer>
              <CheckboxContainer
                active={meHasSubscribedToSelectedUser}
                onClick={() => {
                  if (meHasSubscribedToSelectedUser)
                    removeUserSubscription(selectedUser.id)
                  if (!meHasSubscribedToSelectedUser)
                    addUserSubscription(selectedUser.id)
                }}
              >
                <BookmarkCheckbox type="user" id={selectedUser.id} />
                {t("Subscribe")}
              </CheckboxContainer>
              <BigToggle
                checked={viewMode === "MONTH"}
                checkedLabel={t("month")}
                uncheckedLabel={t("week")}
                onChange={(checked) => {
                  if (checked) return setViewMode("MONTH")
                  setViewMode("WEEK")
                }}
              />
            </DesktopTitleWithFavoriteDiv>

            <DesktopHeaderActions>
              <EmailButton secondary>
                <a href={`mailto:${selectedUser.email}`}>
                  <Mail /> <span>{selectedUser.email}</span>
                </a>
              </EmailButton>
              <EditManager selectedUser={selectedUser} />
            </DesktopHeaderActions>
          </DesktopHeader>
        )}
        {viewMode !== "MONTH" && (
          <CanMutatePlanningMessage selectedUser={selectedUser} />
        )}
        <UserPlanningSection>
          <PlanningRowLayout options={{ topBorder: true }}>
            <PlanningNavigation />
            <CalendarDays />
          </PlanningRowLayout>
          {viewMode !== "MONTH" && (
            <PlanningRowLayout>
              {!isMobile && <PlanningProgress />}
              <div
                ref={editableSlotsRef}
                style={isMobile ? { paddingLeft: "8px" } : undefined}
              >
                <EditableUserSlots />
              </div>
            </PlanningRowLayout>
          )}
        </UserPlanningSection>

        {displayMyTeam && (
          <MyTeamUsersListHeader
            selectedUser={selectedUser}
            teamUsers={users}
          />
        )}
      </div>

      {!editionActive && (
        <>
          <Body>
            <UsersPlanningList users={users} limit={undefined} />
          </Body>
          {isMobile && <MobileBottomNavigation />}
        </>
      )}
    </Layout>
  )
}

export default function UserPage() {
  const { userId } = useParams<{ userId?: string }>()
  const { loading, error, user: selectedUser } = useUserFromId(userId ?? "")
  const { openDrawer, setUserIds } = usePlanningContext()

  const editionActive = openDrawer === "SLOTS_EDITOR"

  useEffect(() => {
    setUserIds(
      selectedUser && selectedUser.team
        ? selectedUser.team.map((u) => u.id)
        : []
    )
    // eslint-disable-next-line
  }, [userId, selectedUser])

  if (loading || error)
    return <LoaderOrErrorComponent loading={loading} error={error} />

  if (selectedUser === null) return <></>

  return (
    <UserPageContent
      selectedUser={selectedUser}
      editionActive={editionActive}
    />
  )
}
