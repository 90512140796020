import useJoinOrLeaveGroup from "graphql/groups/useJoinOrLeaveGroup"
import useUser from "graphql/users/useUser"
import useMenu from "hooks/useMenu"
import React, { MouseEvent, useState } from "react"
import styled from "styled-components"
import { colors, mediaQueries, P14, Tooltip, transitions } from "ui"

const TooltipStyled = styled(Tooltip)`
  &:hover {
    background: none;
  }
`

interface ListItemProps {
  notActive?: boolean
}

const ListItemButtonStyled = styled.button<ListItemProps>`
  display: grid;
  grid-template-columns: 16px auto 16px;
  align-items: center;
  gap: 8px;
  padding: 0 8px 0 24px;
  border: none;
  width: 100%;
  background: none;

  ${({ notActive }) => {
    if (notActive) return `color: ${colors.grey1};`
    return "color: black;"
  }}

  .icon {
    width: 16px;
    height: 24px;
  }

  .name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
    ${({ notActive }) => {
      if (notActive) return `color: ${colors.grey1};`
      return "color: black;"
    }}
  }

  .action {
    width: 20px;
    visibility: hidden;
    opacity: 0;

    @media ${mediaQueries.isMobile} {
      visibility: visible;
      opacity: 1;
    }

    transition: ${transitions.generic};
  }

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }

  &:hover:not(.hide-action) {
    .action {
      visibility: visible;
      opacity: 1;
    }
  }

  &:active,
  &.active {
    background: rgba(0, 0, 0, 0.12);

    &:hover {
      background: rgba(0, 0, 0, 0.04);
    }
    .name {
      font-weight: 600;
    }

    svg {
      stroke-width: 2px;
    }
  }

  transition: ${transitions.generic};
`

interface PropsType {
  icon: JSX.Element
  name: string
  action?: JSX.Element
  path: string
  notActive?: boolean
  groupId?: string
  handleAction?: () => void
}

export default function MenuListItem({
  icon,
  name,
  action,
  path,
  notActive = false,
  groupId,
  handleAction,
}: PropsType) {
  const { handlePath } = useMenu()
  const { joinGroup } = useJoinOrLeaveGroup()
  const { user: me } = useUser()

  const [openTooltip, setOpenTooltip] = useState<boolean>(false)

  const handleClose = () => {
    setOpenTooltip(false)
  }

  const handleOpen = () => {
    setOpenTooltip(true)
  }

  const active = Boolean(location.pathname === path)
  const hasEllipsis = name.length > 17

  const className = [
    active ? "active" : "",
    !action || handleAction === undefined ? "hide-action" : "",
  ].join(" ")

  const onAction = (e: MouseEvent<SVGElement>) => {
    e.stopPropagation()
    if (handleAction) handleAction()
  }

  if (hasEllipsis)
    return (
      <li>
        <TooltipStyled
          title={name}
          open={openTooltip}
          onOpen={handleOpen}
          onClose={handleClose}
        >
          <ListItemButtonStyled
            role="link"
            className={className}
            onClick={() => handlePath(path)}
            notActive={notActive}
          >
            <div
              className="icon"
              onClick={(e) => {
                e.stopPropagation()
                if (groupId && me) joinGroup(groupId, me.id)
              }}
            >
              {React.cloneElement(icon, { className: "icon" })}
            </div>
            <P14 className="name">{name}</P14>
            {action ? (
              React.cloneElement(action, {
                className: "action",
                onClick: onAction,
              })
            ) : (
              <></>
            )}
          </ListItemButtonStyled>
        </TooltipStyled>
      </li>
    )

  return (
    <li>
      <ListItemButtonStyled
        role="link"
        className={className}
        onClick={() => handlePath(path)}
        notActive={notActive}
      >
        <div
          className="icon"
          onClick={(e) => {
            e.stopPropagation()
            if (groupId && me) joinGroup(groupId, me.id)
          }}
        >
          {React.cloneElement(icon, { className: "icon" })}
        </div>
        <P14 className="name">{name}</P14>
        {action ? (
          React.cloneElement(action, {
            className: "action",
            onClick: onAction,
          })
        ) : (
          <></>
        )}
      </ListItemButtonStyled>
    </li>
  )
}
