import LocaleSelector from "components/LocaleSelector"
import ManageSettingsSlider from "components/Settings/ManageSettingsSlider"
import useUserIntegrations from "graphql/integrations/useUserIntegrations"
import useUserSettings from "graphql/settings/useUserSettings"
import useSetAllowedUserFlags from "graphql/users/useSetAllowedUserFlags"
import useUser from "graphql/users/useUser"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import {
  CompanySetting,
  NotificationTriggers,
  NotificationType,
  TriggerType,
} from "types"
import { colors, LU12, P14Bold, P16Bold, Table, TD, TH, Toggle, TR } from "ui"

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;

  > table {
    margin-top: 32px;
    > tbody {
      p {
        color: ${colors.purple};
      }
    }
  }
`

const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px 12px;
`

const ButtonsLine = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 50%;
  margin-top: 25px;
  padding: 0px 12px;

  p {
    color: ${colors.grey1};
  }
`

const ALL_NOTIFICATION_TRIGGERS: TriggerType[] = [
  "email",
  "slack",
  "teams",
  "push",
]

const DISABLED_NOTIFICATION_TRIGGERS: TriggerType[] = ["teams", "push"]

export default function NotificationsDisplay() {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()

  const { user: me } = useUser()
  const notificationTriggers: NotificationTriggers | undefined =
    me?.flags.notificationTriggers

  const { userSettings } = useUserSettings()
  const groups = userSettings.reduce((grouped, setting) => {
    if (grouped[setting.group] !== undefined) {
      grouped[setting.group].push(setting)
    } else {
      grouped[setting.group] = [setting]
    }
    return grouped
  }, {} as Record<string, CompanySetting[]>)

  const { userIntegrations } = useUserIntegrations()
  const { setAllowedUserFlags } = useSetAllowedUserFlags()

  const getUserEnabledTriggers = () => {
    const defaultTriggers: TriggerType[] = ["email"]
    const enabledTriggers: TriggerType[] = [...defaultTriggers]
    for (const integration of userIntegrations) {
      const trigger = ALL_NOTIFICATION_TRIGGERS.find(
        (t) => t === integration.name
      )
      if (trigger) enabledTriggers.push(trigger)
    }
    return enabledTriggers
  }

  const defaultNotificationTriggers: NotificationTriggers = {
    nudge: {
      email: true,
      slack: true,
      teams: true,
      push: true,
    },
    weeklyReminder: {
      email: true,
      slack: true,
      teams: true,
      push: true,
    },
    teamDayInvitation: {
      email: true,
      slack: true,
      teams: true,
      push: true,
    },
    userSubscriptions: {
      email: true,
      slack: true,
      teams: true,
      push: true,
    },
  }

  const triggersInitialState = notificationTriggers
    ? { ...defaultNotificationTriggers, ...notificationTriggers }
    : defaultNotificationTriggers

  const [triggers, setTriggers] =
    useState<NotificationTriggers>(triggersInitialState)

  const updateNotificationTriggers = (
    notificationTriggers: NotificationTriggers
  ) => {
    setAllowedUserFlags({ notificationTriggers })
  }

  const getRowTextOrIcon = (type: NotificationType) => {
    switch (type) {
      case "nudge":
        return <P14Bold>{t("nudge explanation")}</P14Bold>

      case "weeklyReminder":
        return <P14Bold>{t("weekly-reminder-explanation")}</P14Bold>

      case "teamDayInvitation":
        return <P14Bold>{t("teamDayInvitation explanation")}</P14Bold>

      case "userSubscriptions":
        return <P14Bold>{t("userSubscriptions explanation")}</P14Bold>

      default:
        return type
    }
  }

  if (Object.keys(groups).length === 0) {
    return null
  }

  return (
    <Content>
      {!isMobile && <P16Bold>{t("notifications & display")}</P16Bold>}
      <ButtonsLine>
        <P14Bold>{t("App language")}</P14Bold>
        <LocaleSelector />
      </ButtonsLine>
      {Object.keys(groups).map((group) => (
        <SliderContainer key={group}>
          {groups[group].map((setting) => (
            <ManageSettingsSlider
              type="user"
              key={setting.key}
              setting={setting}
            />
          ))}
        </SliderContainer>
      ))}

      <Table>
        <thead>
          <TR>
            <TH>
              <LU12>{t("Notifications")}</LU12>
            </TH>
            <TH>
              <LU12>Email</LU12>
            </TH>
            <TH>
              <LU12>Slack</LU12>
            </TH>
          </TR>
        </thead>
        <tbody>
          {Object.entries(triggers).map(([notificationType, value]) => {
            return (
              <TR key={`trigger-${notificationType}`}>
                <TD>
                  {getRowTextOrIcon(notificationType as NotificationType)}
                </TD>
                {Object.entries(value).map(([notificationTrigger, v]) => {
                  const disabled = !getUserEnabledTriggers().find(
                    (t) => t === notificationTrigger
                  )
                  if (
                    DISABLED_NOTIFICATION_TRIGGERS.includes(
                      notificationTrigger as TriggerType
                    )
                  )
                    return null

                  return (
                    <TD
                      key={`trigger-${notificationType}-${notificationTrigger}`}
                    >
                      <Toggle
                        checked={disabled ? false : (v as boolean)}
                        disabled={disabled}
                        onChange={(checked) => {
                          // object copy hack to avoid object mutation read-only error
                          const newNotificationTriggers = JSON.parse(
                            JSON.stringify(triggers)
                          )
                          newNotificationTriggers[
                            notificationType as NotificationType
                          ][notificationTrigger as TriggerType] = checked

                          setTriggers(newNotificationTriggers)
                          updateNotificationTriggers(newNotificationTriggers)
                        }}
                      />
                    </TD>
                  )
                })}
              </TR>
            )
          })}
        </tbody>
      </Table>
    </Content>
  )
}
