import { gql, useMutation } from "@apollo/client"
import { Flags } from "types"

import { UserHistoryFragment } from "./fragments/userHistoryFragments"

const MUTATION = gql`
  ${UserHistoryFragment}
  mutation addUserHistory($type: String!, $value: JSON!) {
    addUserHistory(type: $type, value: $value) {
      ...UserHistoryFragment
    }
  }
`

interface PropsType {
  type: string
  value: Flags
}

export default function useAddUserHistory() {
  const [mutate] = useMutation(MUTATION, {
    update: (cache, { data }) => {
      if (data && data.addUserHistory) {
        const userHistory = data.addUserHistory
        const fields = {
          userHistory: (existing = []) => {
            const s = existing.filter(
              (es: any) =>
                es.__ref !== `${userHistory.__typename}:${userHistory.id}`
            )
            const newRef = cache.writeFragment({
              data: userHistory,
              fragment: UserHistoryFragment,
            })
            return [...s, newRef]
          },
        }
        cache.modify({ fields })
      }
    },
  })

  return async ({ type, value }: PropsType) => {
    try {
      await mutate({
        variables: { type, value },
      })
    } catch (e) {
      console.error(e.message)
    }
  }
}
