import useCompanyLabels from "graphql/labels/useCompanyLabels"
import useUserLabels from "graphql/labels/useUserLabels"
import useTranslate from "intl/useTranslate"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { ChipButton, colors, P16, transitions } from "ui"
import { Plus } from "ui/icons"

const Form = styled.form`
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 50px;
  border: solid 1px ${colors.grey3};
  height: 32px;

  &.label-name-form {
    &--visible {
      visibility: visible;
      opacity: 1;
    }
    &--hidden {
      visibility: hidden;
      opacity: 0;
      width: 0;
      padding: 0;
      border-width: 0;
      margin-left: -8px;
    }
  }

  label {
    display: none;
  }

  .label-name-input {
    border: none;
    outline: none;
    background: none;
    font-size: 16px;
    line-height: 20px;
  }

  .btn-submit {
    border: none;
    background: none;
    p {
      color: ${colors.blue};
    }
  }

  transition: ${transitions.generic};
`

const ChipButtonStyled = styled(ChipButton)`
  background-color: ${colors.white};
  p {
    color: ${colors.grey1};
  }

  svg {
    stroke: ${colors.grey2};
  }

  &:disabled {
    cursor: not-allowed;
  }
`

interface PropsType {
  type: "home" | "else" | "off" | "ooc"
  target: "user" | "company"
  disabled?: boolean

  onClick?: () => void
  onSubmit?: () => void
}

export default function NewCustomLabelButton({
  type,
  target,
  disabled,
  onClick,
  onSubmit,
}: PropsType) {
  const t = useTranslate()

  const inputRef = useRef<HTMLInputElement>(null)

  const { addUserLabel } = useUserLabels()
  const { addCompanyLabel } = useCompanyLabels()

  const [editorOpen, setEditorOpen] = useState(false)
  const [labelName, setLabelName] = useState<string | undefined>(undefined)

  const reset = () => {
    setEditorOpen(false)
    setLabelName(undefined)
    if (inputRef.current) inputRef.current.value = ""
    if (onSubmit) onSubmit()
  }

  const handleSumbit = () => {
    if (labelName) {
      if (target === "user") addUserLabel(type, labelName)
      if (target === "company") addCompanyLabel(type, labelName)
    }
    if (onSubmit) onSubmit()
    reset()
  }

  useEffect(() => {
    if (editorOpen && inputRef.current) inputRef.current.focus()
  }, [editorOpen, inputRef])

  return (
    <>
      <Form
        onSubmit={(e) => e.preventDefault()}
        className={
          editorOpen ? "label-name-form--visible" : "label-name-form--hidden"
        }
        onKeyDown={(e) => {
          if (e.key === "Escape") reset()
        }}
      >
        <input
          ref={inputRef}
          className={
            editorOpen
              ? "label-name-input label-name-input--visible"
              : "label-name-input label-name-input--hidden"
          }
          placeholder="Nom du label"
          id="custom-label-name"
          name="custom-label-name"
          type="text"
          onChange={(e) => setLabelName(e.target.value)}
        />
        <label htmlFor="custom-label-name">Nom du label</label>
        <button
          className={
            editorOpen
              ? "btn-submit btn-submit--visible"
              : "btn-subnmit btn-submit--hidden"
          }
          type="submit"
          onClick={handleSumbit}
        >
          <P16>Valider</P16>
        </button>
      </Form>
      <ChipButtonStyled
        icon={<Plus />}
        onClick={() => {
          if (onClick) onClick()
          reset()
          setEditorOpen(true)
          if (inputRef.current) inputRef.current.focus()
        }}
        disabled={disabled}
      >
        {t("Add")}
      </ChipButtonStyled>
    </>
  )
}
