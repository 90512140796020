import useTranslate from "intl/useTranslate"
import React from "react"
import LocationTree, { getChildrenLocations } from "utils/LocationTree"

import LocationsList from "../../../../components/Collapses/LocationsCollapse/LocationsList"
import CollapseContainer from "../components/CollapseContainer"

interface PropsType {
  locations: LocationTree[]
  collapseOpen: string | null
  setCollapseOpen: (str: string | null) => void
}

export function LocationsCollapse({
  locations,
  collapseOpen,
  setCollapseOpen,
}: PropsType) {
  const t = useTranslate()

  return (
    <CollapseContainer
      id="places"
      title={t("Places")}
      subtitle={`${getChildrenLocations(locations).length} ${t(
        getChildrenLocations(locations).length <= 1 ? "Place" : "Places"
      ).toLowerCase()}`}
      collapseOpen={collapseOpen}
      setCollapseOpen={setCollapseOpen}
    >
      <LocationsList showFavoriteCheckbox />
    </CollapseContainer>
  )
}
