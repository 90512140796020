import { ApolloError, gql, useQuery } from "@apollo/client"
import { useMemo } from "react"
import { Slot, UTCSlot } from "types"
import UTCDate from "utils/UTCDate"

import { SlotFragment } from "./fragments/slotFragment"

const SLOTS_QUERY = gql`
  ${SlotFragment}
  query getMultiSlots($userIds: [ID!]!, $before: String!, $after: String!) {
    multiSlots(userIds: $userIds, after: $after, before: $before) {
      ...SlotFragment
    }
  }
`

export default function useMultiUserSlots(
  userIds: string[],
  from: UTCDate, // included
  to: UTCDate // excluded
): {
  slots: UTCSlot[]
  loading: boolean
  error?: ApolloError
} {
  const { loading, error, data } = useQuery(SLOTS_QUERY, {
    variables: {
      userIds,
      before: `${to.getTime()}`,
      after: `${from.getTime()}`,
    },
    skip: userIds.length === 0,
    fetchPolicy: "cache-first",
  })
  const slots: UTCSlot[] = useMemo(() => {
    if (userIds.length > 0 && data) {
      const { multiSlots }: { multiSlots: Slot[] } = data

      const d: UTCSlot[] = []
      const currentDate = new UTCDate(from)
      while (currentDate.getTime() < to.getTime()) {
        for (const userId of userIds) {
          const id = `${userId}_${currentDate.getTime()}`
          const foundSlot = multiSlots.find((s) => s.id === id)
          const slot: UTCSlot = {
            id,
            value: foundSlot?.value || null,
            locationId: foundSlot?.locationId || null,
            userId,
            date: new UTCDate(currentDate),
            guests: foundSlot?.guests || 0,
            guestsEmails: foundSlot?.guestsEmails || [],
            label: foundSlot?.label || "",
          }
          d.push(slot)
        }
        currentDate.increment("HALF-DAY")
      }
      return d
    }
    return []
  }, [userIds, data, from, to])

  return { slots, loading, error }
}
