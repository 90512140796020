import React from "react"
import { NavLink as RouterDomLink } from "react-router-dom"
import styled from "styled-components"

interface PrimaryNavLinkProps {
  readonly disabled?: boolean
}

const PrimaryNavLink = styled(RouterDomLink)<PrimaryNavLinkProps>`
  ${({ disabled }) =>
    disabled ? `pointer-events: none; cursor: not-allowed;` : ``}
`

const NakedNavLink = styled(PrimaryNavLink)`
  display: flex;
  text-decoration: none;

  &:hover,
  &:link,
  &:visited,
  &:active {
    color: inherit;
  }
`

interface PropsType {
  children?: React.ReactNode
  to: string
  activeClassName?: string
  disabled?: boolean
  naked?: boolean
}

export function NavLink({
  children,
  to,
  activeClassName,
  disabled,
  naked = false,
  ...rest
}: PropsType) {
  if (naked)
    return (
      <NakedNavLink
        to={to}
        activeClassName={activeClassName}
        onClick={(event) => {
          if (disabled) event.preventDefault()
        }}
        disabled={disabled}
        {...rest}
      >
        {children}
      </NakedNavLink>
    )
  return (
    <PrimaryNavLink
      to={to}
      activeClassName={activeClassName}
      onClick={(event) => {
        if (disabled) event.preventDefault()
      }}
      disabled={disabled}
      {...rest}
    >
      {children}
    </PrimaryNavLink>
  )
}
