import useCompanyFlags from "hooks/useCompanyFlags"
import useTranslate from "intl/useTranslate"
import React from "react"
import { UserBase } from "types"
import { UserListItem } from "ui"
import getUserDisplayName from "utils/getUserDisplayName"

import CollapseContainer from "../components/CollapseContainer"

interface PropsType {
  users: UserBase[]
  collapseOpen: string | null
  setCollapseOpen: (str: string | null) => void
}

export function UsersCollapse({
  users,
  collapseOpen,
  setCollapseOpen,
}: PropsType) {
  const t = useTranslate()
  const { companyFlags } = useCompanyFlags()

  return (
    <CollapseContainer
      id="employees"
      title={t("Employees")}
      subtitle={`${users.length} ${t(
        users.length <= 1 ? "Employee" : "Employees"
      ).toLowerCase()}`}
      collapseOpen={collapseOpen}
      setCollapseOpen={setCollapseOpen}
    >
      {users.map((user) => (
        <UserListItem
          key={user.id}
          user={user}
          showLeaveGroupButton={false}
          showFavoriteCheckbox
        >
          {getUserDisplayName(user, companyFlags)}
        </UserListItem>
      ))}
    </CollapseContainer>
  )
}
