import { Menu } from "@mui/material"
import React from "react"
import { DropDownItem } from "ui"

interface PropsType {
  open: boolean
  options: { name: string; value: any }[]
  onClick?: () => void
  onSelect: (option: { name: string; value: any }) => void
  anchorEl: Element | null
  multiSelection?: boolean
  state?: undefined | string | string[]
  checkBox?: boolean
  parentWidth?: number
}

export function DropDownMenu({
  open,
  options,
  onClick,
  onSelect,
  anchorEl,
  multiSelection,
  state,
  checkBox,
  parentWidth,
}: PropsType) {
  return (
    <Menu
      open={open}
      onClick={onClick}
      onKeyDown={(event) => {
        if (onClick && event.key === "Enter") {
          event.preventDefault()
          onClick()
        }
      }}
      anchorEl={anchorEl}
      elevation={0}
      PaperProps={{
        sx: {
          background: "#FFFFFF",
          boxShadow:
            "0px 2px 16px rgba(0, 0, 0, 0.24), 0px 2px 2px rgba(0, 0, 0, 0.12)",
          borderRadius: "4px",
          width: parentWidth,
          maxHeight: "50vh",
        },
      }}
      autoFocus={true}
      disableScrollLock
    >
      {options.map((option, i) => {
        return (
          <DropDownItem
            key={`opt-${i}`}
            option={option}
            onClick={(option) => onSelect(option)}
            tabIndex={0}
            checkBox={checkBox}
            state={
              multiSelection && state
                ? state.includes(option.value)
                  ? option.name
                  : ""
                : state
            }
          />
        )
      })}
    </Menu>
  )
}
