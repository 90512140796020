import React, { useEffect, useState } from "react"
import checkAppVersion, { UpdatorType } from "utils/checkAppVersion"

import MandatoryUpdatePopup from "./MandatoryUpdatePopup"
import UpdateAvailableButton from "./UpdateAvailableButton"

const FIVE_MINUTES_IN_MS = 1000 * 60 * 5

export default function AppUpdator() {
  const [updateStatus, setUpdateStatus] = useState<UpdatorType>("UNKNOWN")
  useEffect(() => {
    checkAppVersion()
      .then(setUpdateStatus)
      .catch(() => setUpdateStatus("UNKNOWN"))
    const interval = setInterval(async () => {
      try {
        const status = await checkAppVersion()
        setUpdateStatus(status)
      } catch (e) {
        console.error(`Error while setting app status: ${e.message}`)
      }
    }, FIVE_MINUTES_IN_MS)
    return () => {
      clearInterval(interval)
    }
  }, [])
  if (updateStatus === "FORCE-UPDATE") {
    return <MandatoryUpdatePopup />
  }
  if (updateStatus === "UPDATE-AVAILABLE") {
    return <UpdateAvailableButton />
  }
  return null
}
