import UpdateManagerSearchResults from "components/Search/ResultsList/UpdateManagerSearchResults/UpdateManagerSearchResults"
import SearchBarForm from "components/Search/SearchBarForm/SearchBarForm"
import useSearchUsersByName from "graphql/search/useSearchUsersByName"
import useCheckSearchResults from "hooks/useCheckSearchResults"
import useTranslate from "intl/useTranslate"
import React, { useRef, useState } from "react"
import styled from "styled-components"
import { SearchResult } from "types"
import { L12GreyBold } from "ui"

const SearchContainer = styled.div`
  .search-bar--textfield input {
    font-family: inherit;
  }
  p {
    margin-bottom: 8px;
  }
`

interface PropsType {
  variant?: "textfield"
  initialValue?: SearchResult | null
  hideLabel?: boolean
  onSelect: (input: SearchResult) => void
  handleClearForm: () => void
}

export default function ManagerSearch({
  variant,
  initialValue,
  hideLabel,
  onSelect,
  handleClearForm,
}: PropsType) {
  const t = useTranslate()
  const searchType = "user"

  const inputRef = useRef<HTMLInputElement>(null)
  const resultListAnchorElRef = useRef<HTMLDivElement>(null)

  const [searchString, setSearchString] = useState("")

  const { results, loading: isSearching } = useSearchUsersByName(searchString)
  const [newResults, setNewResults] = useState<SearchResult[]>([])
  const [selectedManager, setSelectedManager] = useState<
    SearchResult | null | undefined
  >(initialValue)

  const [displayResults, setDisplayResults] = useState(false)

  const closeResults = () => {
    setNewResults([])
    setDisplayResults(false)
  }

  const reset = () => {
    closeResults()
    handleClearForm()
    setSelectedManager(null)
    setSearchString("")
    inputRef.current?.focus()
  }
  const handleSelect = (input: SearchResult) => {
    onSelect(input)
    setSelectedManager(input)
    setSearchString(input.name)
    closeResults()
  }

  useCheckSearchResults({
    searchString,
    searchType,
    newResults,
    setNewResults,
    results,
    isSearching,
  })

  const onChange = (el: string) => {
    setSearchString(el)
    setDisplayResults(true)
  }

  const hasResult = Boolean(selectedManager || initialValue)

  return (
    <>
      <SearchContainer ref={resultListAnchorElRef}>
        {!hideLabel && <L12GreyBold>{t("Manager")}</L12GreyBold>}
        <SearchBarForm
          searchString={searchString}
          searchInputRef={inputRef}
          placeholder={
            initialValue ? initialValue.name : t("search manager users")
          }
          hasResult={hasResult}
          variant={variant}
          resetForm={reset}
          handleChange={onChange}
          onClick={() => setDisplayResults(true)}
          handleClearForm={() => {
            handleClearForm()
            setSelectedManager(null)
            setDisplayResults(false)
            setSearchString("")
            inputRef.current?.focus()
          }}
          autoFocus
        />
      </SearchContainer>

      {displayResults && (
        <UpdateManagerSearchResults
          searchString={searchString}
          results={newResults}
          anchorEl={resultListAnchorElRef.current}
          onSelect={handleSelect}
          handleReset={reset}
        />
      )}
    </>
  )
}
