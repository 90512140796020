import useTeamDays from "graphql/teamDays/useTeamDays"
import { User } from "types"

interface PropsType {
  selectedUser: User
}

export default function useUserTeamDays({ selectedUser }: PropsType) {
  const { teamDays: selectedUserTeamDays } = useTeamDays({
    managerId: selectedUser.id,
  })
  const { teamDays: selectedUserManagerTeamDays } = useTeamDays({
    managerId: selectedUser.manager ? selectedUser.manager.id : "",
  })

  return [...selectedUserTeamDays, ...selectedUserManagerTeamDays]
}
