import { LinearProgress } from "@mui/material"
import IntegrationLayout from "components/IntegrationsLayout/IntegrationLayout"
import useSetupCompanyIntegration from "graphql/integrations/useSetupCompanyIntegration"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { CompanyIntegration } from "types"
import { Button, Dialog, P16, TextField } from "ui"
import { Workday } from "ui/icons"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
`

const TextContainer = styled.div`
  margin-left: 12px;
  margin-bottom: 18px;
`

interface PropsType {
  integration?: CompanyIntegration
}

export default function WorkdayIntegrationComponent({
  integration,
}: PropsType) {
  const t = useTranslate()
  const setupCompanyIntegration = useSetupCompanyIntegration()

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  // Integration states
  const [clientId, setClientId] = useState(integration?.externalId || "")
  const [clientSecret, setClientSecret] = useState(
    integration ? "********" : ""
  )
  const [refreshToken, setRefreshToken] = useState(
    integration ? "********" : ""
  )
  const [apiEndpoint, setApiEndpoint] = useState(
    integration?.flags.apiEndpoint || ""
  )
  const [tokenEndpoint, setTokenEndpoint] = useState(
    integration?.flags.tokenEndpoint || ""
  )
  const [authorizationEndpoint, setAuthorizationEndpoint] = useState(
    integration?.flags.authorizationEndpoint || ""
  )

  const onClose = () => {
    setOpen(false)
  }

  const onSave = () => {
    onClose()
    setLoading(true)
    setupCompanyIntegration({
      name: "workday",
      externalId: clientId,
      token: "",
      flags: {
        authorizationEndpoint,
        tokenEndpoint,
        apiEndpoint,
        refreshToken,
        clientSecret,
      },
    }).then(() => {
      setLoading(false)
    })
  }
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        title={t("connect to workday")}
        rightButton={
          <Button
            disabled={
              apiEndpoint.length === 0 ||
              clientSecret.length === 0 ||
              clientSecret === "********" ||
              refreshToken.length === 0 ||
              refreshToken === "********" ||
              tokenEndpoint.length === 0 ||
              authorizationEndpoint.length === 0 ||
              !clientId
            }
            onClick={onSave}
          >
            {t("Save")}
          </Button>
        }
      >
        <Container>
          <P16>
            {t("find documentation")}{" "}
            <a
              href="https://offishall.notion.site/Workday-d11dd3b9754e46f5bcf508bb640138fd"
              target="_blank"
              rel="noreferrer"
            >
              {t("here")}
            </a>{" "}
            {t("know how to retrieve the needed information")}{" "}
          </P16>
          <TextField
            label={t("Client ID")}
            initialValue={clientId}
            placeholder={t("Client ID")}
            handleChange={(str: string) => setClientId(str)}
          />

          <TextField
            label={t("Client Secret")}
            initialValue={clientSecret}
            placeholder={t("Client Secret")}
            handleChange={(str: string) => setClientSecret(str)}
          />
          <TextField
            label={t("Refresh Token")}
            initialValue={refreshToken}
            placeholder={t("Refresh Token")}
            handleChange={(str: string) => setRefreshToken(str)}
          />
          <TextField
            label={t("Workday REST API Endpoint")}
            initialValue={apiEndpoint}
            placeholder={t("Workday REST API Endpoint")}
            handleChange={(str: string) => setApiEndpoint(str)}
          />
          <TextField
            label={t("Token Endpoint")}
            initialValue={tokenEndpoint}
            placeholder={t("Token Endpoint")}
            handleChange={(str: string) => setTokenEndpoint(str)}
          />
          <TextField
            label={t("Authorization Endpoint")}
            initialValue={authorizationEndpoint}
            placeholder={t("Authorization Endpoint")}
            handleChange={(str: string) => setAuthorizationEndpoint(str)}
          />
        </Container>
      </Dialog>
      <IntegrationLayout connected={Boolean(integration)} title="Workday">
        {integration && (
          <TextContainer>
            <P16>{t("offishall linked to workday")}.</P16>
            <P16>
              {t("your workday tenant id").replace(
                "{externalId}",
                integration.externalId
              )}
            </P16>
          </TextContainer>
        )}
        {!integration && (
          <TextContainer>
            <P16>{t("offishall not linked to workday")}</P16>
          </TextContainer>
        )}
        <Button
          disabled={loading || open}
          icon={<Workday />}
          secondary
          onClick={() => {
            setOpen(true)
          }}
        >
          {integration ? t("update values") : t("connect to workday")}
        </Button>
        {loading && <LinearProgress />}
      </IntegrationLayout>
    </>
  )
}
