import { useEffect } from "react"
import { SearchResult } from "types"

interface PropsType {
  searchString: string
  searchType: string
  newResults: SearchResult[]
  setNewResults: (arg1: SearchResult[]) => void
  results: SearchResult[]
  isSearching: boolean
}

export default function useCheckSearchResults({
  searchString,
  searchType,
  newResults,
  setNewResults,
  results,
  isSearching,
}: PropsType) {
  useEffect(() => {
    if (searchString === "" && newResults.length > 0) setNewResults([])

    const _results = results.filter((r) => r.type === searchType)

    // checking if results have changed
    // checking both with increasing/decreasing results length
    if (!isSearching && _results.length > 0) {
      if (_results.length > newResults.length) {
        if (
          _results.every((result) =>
            newResults.find(
              (newResult) =>
                newResult.id === result.id && newResult.type === result.type
            )
          )
        )
          return
        else {
          setNewResults(_results)
        }
      }
      if (_results.length < newResults.length) {
        if (
          newResults.every((newResult) =>
            _results.find(
              (result) =>
                newResult.id === result.id && newResult.type === result.type
            )
          )
        )
          return
        else {
          setNewResults(_results)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearching, results, newResults, searchString])
}
