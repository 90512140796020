import useCustomErrorToast from "hooks/useCustomErrorToast"
import { useHistory } from "react-router-dom"
import { loginWithToken } from "utils/firebase"

import useProtectedRoute from "./useProtectedRoute"

export default function useLoginWithEmailAndPassword(): {
  loginWithEmailAndPassword: (
    email: string,
    password: string
  ) => Promise<boolean>
  loading: boolean
} {
  const history = useHistory()
  const { loading, callRoute } = useProtectedRoute(
    "loginWithEmailAndPassword",
    "/auth/user/login",
    "POST"
  )
  const { addToastError } = useCustomErrorToast()
  return {
    loading,
    loginWithEmailAndPassword: async (email: string, password: string) => {
      try {
        const data = await callRoute({ email, password })
        if (data && data?.token) {
          await loginWithToken(data?.token)
          return true
        } else {
          addToastError({ message: "Wrong email or password" })
        }
      } catch (e) {
        if (e.message === "Email not verified") {
          history.push("/account-validation")
        } else {
          addToastError({ message: "Wrong email or password" })
        }
      }
      return false
    },
  }
}
