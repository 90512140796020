import { gql, useMutation } from "@apollo/client"
import useTranslate from "intl/useTranslate"
import React, { useCallback, useState } from "react"
import styled from "styled-components"
import { Button, colors, P16 } from "ui"
import { Check } from "ui/icons"

const P16Styled = styled(P16)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: underline;

  &.disabled {
    color: ${colors.grey2};
  }
`

const RESEND_VALIDATION_MAIL = gql`
  mutation ResendValidationMail($email: String!) {
    resendValidationMail(email: $email)
  }
`

interface PropsType {
  email: string
  variant?: "naked" | "secondary"
}

function ResendValidationMailButton({ email, variant }: PropsType) {
  const t = useTranslate()
  const [sending, setSending] = useState(false)
  const [sent, setSent] = useState(false)
  const [resendValidationMail] = useMutation(RESEND_VALIDATION_MAIL)
  const resendValidationMailCallback = useCallback(async () => {
    if (sending || sent) return
    setSending(true)
    const userId = await resendValidationMail({ variables: { email } })
    setSending(false)
    if (userId) {
      setSent(true)
    }
  }, [resendValidationMail, email, sending, sent])
  if (variant === "naked")
    return (
      <P16Styled
        className={sending || sent ? "disabled" : ""}
        onClick={(e) => {
          e.stopPropagation()
          if (sending || sent) return
          resendValidationMailCallback()
        }}
      >
        {t("Send validation email again")} {sent && <Check />}
      </P16Styled>
    )
  return (
    <Button
      secondary={variant === "secondary"}
      onClick={resendValidationMailCallback}
      disabled={sending || sent}
    >
      {t("Send validation email again")} {sent && <Check />}
    </Button>
  )
}

export default ResendValidationMailButton
