import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import { BulkSlotRuleInput } from "types"
import { BigToggle, L12GreyBold } from "ui"
import TextfieldDatePicker from "ui/TextfieldDatePicker/TextfieldDatePicker"
import capitalizeFirstLetter from "utils/texts/capitalizeFirstLetter"
import UTCDate from "utils/UTCDate"

interface PropsType {
  rule: BulkSlotRuleInput
  setRule: (value: (value: BulkSlotRuleInput) => BulkSlotRuleInput) => void
}

export default function DatePicker({ rule, setRule }: PropsType) {
  const t = useTranslate()

  const [isPeriodSelected, setIsPeriodSelected] = useState<boolean>(false)

  return (
    <div>
      <div className="big-toggle-container">
        <L12GreyBold>{t("Date")}</L12GreyBold>
        <BigToggle
          checked={isPeriodSelected}
          checkedLabel={t("hybrid-rule-label--period")}
          uncheckedLabel={t("day")}
          onChange={(checked) => {
            setIsPeriodSelected(checked)
          }}
          variant="secondary"
        />
      </div>

      <div className="textfields-container">
        <div style={{ marginTop: 8 }}>
          <TextfieldDatePicker
            label={isPeriodSelected ? capitalizeFirstLetter(t("from")) : ""}
            defaultDate={rule.from}
            minDate={new UTCDate("DAY")}
            onSelect={(utcDate) =>
              utcDate &&
              setRule((prev: BulkSlotRuleInput) => ({
                ...prev,
                from: utcDate,
              }))
            }
          />
        </div>

        <div className="last-date-picker">
          {isPeriodSelected && (
            <TextfieldDatePicker
              label={t("to")}
              defaultDate={rule.to || new UTCDate("DAY")}
              minDate={new UTCDate("DAY")}
              onSelect={(utcDate) =>
                utcDate &&
                setRule((prev: BulkSlotRuleInput) => ({
                  ...prev,
                  to: utcDate,
                }))
              }
            />
          )}
        </div>
      </div>
    </div>
  )
}
