import { fabric } from "fabric"
import React from "react"
import colors from "ui/colors"
import LocationTree from "utils/LocationTree"
import PromiseQueue from "utils/PromiseQueue"

import VisualArea from "./VisualArea"
import { VisualEditorMode } from "./VisualAreaEditor"

fabric.Object.prototype.transparentCorners = false
fabric.Object.prototype.cornerColor = "white"
fabric.Object.prototype.cornerSize = 12
fabric.Object.prototype.cornerStrokeColor = colors.magenta

interface SaveAreaProps {
  locationId: string
  area: VisualArea
}

interface Callbacks {
  readonly saveVisualArea: (props: SaveAreaProps) => Promise<void>
  readonly setSelectedLocationId: (locationId: string) => void
}

export default class FabricJSWrapper {
  readonly fabricCanvas: fabric.Canvas

  private readonly canvasElement: HTMLCanvasElement
  private readonly parentRef: React.RefObject<HTMLDivElement>
  private readonly mode: VisualEditorMode
  private location: LocationTree | null = null
  private callbacks: Callbacks | null = null

  private activeSublocation?: LocationTree
  private isDragging = false
  private dragged = false
  private lastPosX = Infinity
  private lastPosY = Infinity
  private loading = false
  private saveQueue = new PromiseQueue()
  private skipSaving = false

  constructor(
    canvasElement: HTMLCanvasElement,
    parentRef: React.RefObject<HTMLDivElement>,
    mode: VisualEditorMode
  ) {
    this.fabricCanvas = new fabric.Canvas(canvasElement)
    this.fabricCanvas.selection = false
    fabric.Object.prototype.borderColor =
      mode === VisualEditorMode.EDITOR ? colors.magenta : "transparent"

    this.canvasElement = canvasElement
    this.parentRef = parentRef
    this.mode = mode

    this.bindFunctions([
      "onKeyDown",
      "onMouseWheel",
      "onMouseDown",
      "onMouseUp",
      "onMouseMove",
      "save",
      "onResizeCanvas",
      "addShape",
    ])

    this.fabricCanvas.on("mouse:wheel", this.onMouseWheel)
    this.fabricCanvas.on("mouse:down", this.onMouseDown)
    this.fabricCanvas.on("mouse:move", this.onMouseMove)
    this.fabricCanvas.on("mouse:up", this.onMouseUp)

    if (this.mode === VisualEditorMode.EDITOR) {
      this.fabricCanvas.on("object:modified", this.save)
    }

    if (this.mode === VisualEditorMode.VIEWER) {
      this.fabricCanvas.hoverCursor = "pointer"
    }

    this.onResizeCanvas()

    document.addEventListener("keydown", this.onKeyDown, false)
    window.addEventListener("resize", this.onResizeCanvas, false)
    window.addEventListener("add-floor-plan-area", this.addShape, false)
  }

  setCallbacks(callbacks: Callbacks) {
    this.callbacks = callbacks
  }

  init(location: LocationTree, area: any, disabledLocationIds: string[] = []) {
    if (
      this.location?.id === location.id &&
      area.objects?.length === this.fabricCanvas.getObjects().length
    ) {
      return
    }

    this.loading = true
    this.location = location
    this.fabricCanvas.loadFromJSON(
      area.objects ? area : { objects: [] },
      () => {
        this.setupEventHandlers()
        this.updateHighlights()
        if (disabledLocationIds) this.updateDisabledAreas(disabledLocationIds)
        this.loadBackgroundImage()
        this.loading = false
      }
    )
  }

  dispose() {
    document.removeEventListener("keydown", this.onKeyDown)
    window.removeEventListener("resize", this.onResizeCanvas)
    window.removeEventListener("add-floor-plan-area", this.addShape)
    this.fabricCanvas.dispose()
  }

  updateActiveSublocation(
    activeSublocation?: LocationTree,
    disabledLocationIds: string[] = []
  ) {
    this.activeSublocation = activeSublocation
    this.updateHighlights()
    if (disabledLocationIds) this.updateDisabledAreas(disabledLocationIds)
    this.fabricCanvas.renderAll()
  }

  changeShape(locationId: string, shape: string) {
    const oldShape = this.fabricCanvas
      .getObjects()
      .find((obj) => obj.data.locationId === locationId)
    if (oldShape) {
      let newShape: fabric.Object | undefined
      const width = (oldShape.width || 0) * (oldShape.scaleX || 0)
      const height = (oldShape.height || 0) * (oldShape.scaleY || 0)
      if (shape === "rect") {
        newShape = new fabric.Rect({
          width,
          height,
        })
      } else if (shape === "circle") {
        newShape = new fabric.Circle({
          radius: Math.min(width, height) / 2,
        })
      } else {
        throw new Error("Unknown shape")
      }

      newShape.data = { locationId }
      newShape.set("fill", oldShape.fill)
      newShape.set("stroke", oldShape.stroke)
      newShape.set("strokeWidth", oldShape.strokeWidth)
      newShape.set("strokeUniform", oldShape.strokeUniform)
      newShape.set("left", oldShape.left)
      newShape.set("top", oldShape.top)
      this.skipSaving = true
      this.fabricCanvas.remove(oldShape)
      this.fabricCanvas.add(newShape)
      this.fabricCanvas.setActiveObject(newShape)
      this.setupObjectEventHandlers(newShape)
      this.fabricCanvas.renderAll()
      this.skipSaving = false
      this.save()
    }
  }

  deleteObject(locationId: string) {
    const objToDelete = this.fabricCanvas
      .getObjects()
      .find((obj) => obj.data.locationId === locationId)
    if (objToDelete) {
      this.fabricCanvas.remove(objToDelete)
      this.fabricCanvas.renderAll()
      this.save()
    }
  }

  zoomIn() {
    const zoom = this.fabricCanvas.getZoom()
    this.zoomAroundCenter(zoom * 1.25)
  }

  zoomOut() {
    const zoom = this.fabricCanvas.getZoom()
    this.zoomAroundCenter(zoom * 0.8)
  }

  private zoomAroundCenter(zoom: number) {
    this.fabricCanvas.zoomToPoint(
      new fabric.Point(
        (this.fabricCanvas.width || 0) * 0.5,
        (this.fabricCanvas.height || 0) * 0.5
      ),
      zoom
    )
  }

  private async save() {
    if (this.mode !== VisualEditorMode.EDITOR) {
      console.warn(
        "FabricJSWrapper.save() should not be called outside of EDITOR mode"
      )
      return
    }

    if (!this.callbacks) {
      console.warn("callbacks not properly initialized")
      return
    }

    if (!this.location || this.loading || this.skipSaving) {
      return
    }

    const data = this.fabricCanvas.toJSON(["data"]) as any
    this.saveQueue.enqueue(async () => {
      if (!this.callbacks || !this.location) {
        console.warn("failed to execute saving job")
        return
      }

      delete data.backgroundImage
      await this.callbacks?.saveVisualArea({
        locationId: this.location.id,
        area: data,
      })
    })
  }

  private setupEventHandlers() {
    for (const obj of this.fabricCanvas.getObjects()) {
      this.setupObjectEventHandlers(obj)
    }
  }

  private setupObjectEventHandlers(obj: fabric.Object) {
    if (this.mode === VisualEditorMode.EDITOR) {
      obj.on("removed", this.save)
    }
    obj.on("mouseup", () => {
      this.callbacks?.setSelectedLocationId(obj.data.locationId)
    })
  }

  private loadBackgroundImage() {
    if (!this.location?.floorPlanImageUrl) {
      return
    }

    this.fabricCanvas.setBackgroundImage(
      this.location?.floorPlanImageUrl,
      () => {
        this.centerContent()
      },
      {
        crossOrigin: "anonymous",
        // filters: [new fabric.Image.filters.Grayscale({ mode: "luminosity" })],
      }
    )
  }

  private centerContent() {
    const backgroundImage: undefined | any = this.fabricCanvas
      .backgroundImage as {
      width: number
      height: number
    }
    if (backgroundImage) {
      let ratio
      const pan = [0, 0]
      const rect = this.canvasElement.getBoundingClientRect()
      const imgRatio = backgroundImage.width / backgroundImage.height
      const canvasRatio = rect.width / rect.height
      if (imgRatio > canvasRatio) {
        ratio = backgroundImage.width / rect.width
        pan[1] = (rect.height - backgroundImage.height / ratio) / 2
      } else {
        ratio = backgroundImage.height / rect.height
        pan[0] = (rect.width - backgroundImage.width / ratio) / 2
      }
      if (ratio === 0 || ratio === Infinity) {
        ratio = 1
      }
      if (this.fabricCanvas.viewportTransform) {
        const vpt = this.fabricCanvas.viewportTransform
        vpt[4] = 0
        vpt[5] = 0
      }
      this.fabricCanvas.setZoom(1 / ratio)
      if (this.fabricCanvas.viewportTransform) {
        const vpt = this.fabricCanvas.viewportTransform
        vpt[4] = pan[0]
        vpt[5] = pan[1]
        this.fabricCanvas.setViewportTransform(vpt)
      }
    }
    if (this.fabricCanvas.getContext()) {
      this.fabricCanvas.renderAll()
    }
  }

  private updateHighlights() {
    const activeObjects = this.fabricCanvas.getActiveObjects()
    const newActiveObjects = []
    for (const obj of this.fabricCanvas.getObjects()) {
      if (obj.data.locationId === this.activeSublocation?.id) {
        obj.hasControls = this.mode === VisualEditorMode.EDITOR
        obj.set("fill", colors.magenta400)
        obj.set("stroke", colors.magenta)
        obj.set("strokeWidth", 2)
        obj.setControlsVisibility({
          mtr: false,
        })
        obj.bringToFront()
        newActiveObjects.push(obj)
      } else {
        obj.hasControls = false
        obj.set("fill", colors.lightCyan)
        obj.set("stroke", colors.cyan)
        obj.set("strokeWidth", 2)
        if (activeObjects.includes(obj)) {
          this.fabricCanvas.discardActiveObject()
        }
      }
    }
    for (const obj of newActiveObjects) {
      this.fabricCanvas.setActiveObject(obj)
    }
  }

  private updateDisabledAreas(disabledLocationIds: string[]) {
    const objects = this.fabricCanvas.getObjects()
    for (const obj of objects) {
      if (disabledLocationIds.find((id) => id === obj.data.locationId)) {
        obj.hasControls = false
        obj.set("fill", `${colors.grey3}96`)
        obj.set("stroke", colors.grey1)
        obj.set("hoverCursor", "not-allowed")
      }
    }
  }

  private addShape(e: Event) {
    const { locationId } = (e as CustomEvent).detail
    if (!this.fabricCanvas.viewportTransform) {
      return
    }

    for (const obj of this.fabricCanvas.getActiveObjects()) {
      if (obj.data.locationId === locationId) {
        return
      }
    }

    /*
     ** We want to center the shape in the viewport and
     ** make it take up half of the width and height available.
     ** To do that, we need to transform the points
     ** from viewport space to canvas space.
     */
    const viewportInverseTransform = fabric.util.invertTransform(
      this.fabricCanvas.viewportTransform
    )
    const center = fabric.util.transformPoint(
      new fabric.Point(
        (this.fabricCanvas.width || 0) * 0.5,
        (this.fabricCanvas.height || 0) * 0.5
      ),
      viewportInverseTransform
    )
    const topLeft = fabric.util.transformPoint(
      new fabric.Point(0, 0),
      viewportInverseTransform
    )
    const width = center.x - topLeft.x /* Half of the viewport width */
    const height = center.y - topLeft.y /* Half of the viewport height */

    const shape = new fabric.Rect({
      width,
      height,
    })

    shape.data = { locationId }
    shape.set("left", center.x - width * 0.5)
    shape.set("top", center.y - height * 0.5)
    shape.set("fill", colors.magenta400)
    shape.set("stroke", colors.magenta)
    shape.set("strokeWidth", 2)
    shape.set("strokeUniform", true)
    this.fabricCanvas.add(shape)
    this.fabricCanvas.setActiveObject(shape)

    this.setupObjectEventHandlers(shape)

    this.save()
  }

  private onResizeCanvas() {
    if (this.parentRef.current) {
      this.fabricCanvas.setWidth(this.parentRef.current.clientWidth - 4)
      this.fabricCanvas.setHeight(this.parentRef.current.clientHeight - 4)
    } else {
      this.fabricCanvas.setWidth(0)
      this.fabricCanvas.setHeight(0)
    }

    this.centerContent()
  }

  private deleteActiveObjects() {
    if (this.mode !== VisualEditorMode.EDITOR) {
      return
    }
    this.fabricCanvas.getActiveObjects().forEach((obj) => {
      this.fabricCanvas.remove(obj)
    })
    this.fabricCanvas.renderAll()
    this.save()
  }

  private onKeyDown(e: KeyboardEvent) {
    if (e.code === "Backspace" || e.code === "Delete") {
      this.deleteActiveObjects()
    }
  }

  private onMouseWheel(opt: fabric.IEvent<WheelEvent>) {
    const delta = opt.e.deltaY
    let zoom = this.fabricCanvas.getZoom()
    zoom *= 0.999 ** delta
    if (zoom > 20) zoom = 20
    if (zoom < 0.01) zoom = 0.01
    this.fabricCanvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom)
    opt.e.preventDefault()
    opt.e.stopPropagation()
  }

  private onMouseDown(opt: fabric.IEvent<MouseEvent>) {
    const evt = opt.e
    if (
      this.mode === VisualEditorMode.VIEWER ||
      !this.fabricCanvas.getActiveObject()
    ) {
      this.isDragging = true
      this.dragged = false
      if (!this.dragged) {
        if (this.fabricCanvas.backgroundImage instanceof fabric.Image) {
          const { x: pointerX, y: pointerY } = opt.pointer ?? { x: 0, y: 0 }

          this.lastPosX = pointerX
          this.lastPosY = pointerY
        }
      }
      evt.stopPropagation()
      evt.preventDefault()
    }
  }

  private onMouseMove(opt: fabric.IEvent<MouseEvent>) {
    if (this.isDragging && this.fabricCanvas.viewportTransform) {
      const { x: pointerX, y: pointerY } = opt.pointer ?? { x: 0, y: 0 }
      const vpt = this.fabricCanvas.viewportTransform
      vpt[4] += pointerX - this.lastPosX
      vpt[5] += pointerY - this.lastPosY

      this.fabricCanvas.requestRenderAll()
      this.lastPosX = pointerX
      this.lastPosY = pointerY
      this.dragged = true
      opt.e.preventDefault()
    }
  }

  private onMouseUp() {
    // on mouse up we want to recalculate new interaction
    // for all objects, so we call setViewportTransform
    if (this.fabricCanvas.viewportTransform) {
      this.fabricCanvas.setViewportTransform(
        this.fabricCanvas.viewportTransform
      )
    }
    if (
      !this.fabricCanvas.getActiveObject() &&
      this.location &&
      !this.dragged
    ) {
      // seems to be never called because mousMove is fired prior to mouseUp
      // and dragged is true even on short clicks
      this.callbacks?.setSelectedLocationId(this.location.id)
    }
    this.isDragging = false
    this.dragged = false
  }

  private bindFunctions(functions: string[]) {
    const that = this as any
    functions.forEach((funcName) => {
      if (typeof that[funcName] !== "function") {
        throw new Error(`Function ${funcName} not found`)
      }
      that[funcName] = that[funcName].bind(this)
    })
  }
}
