import React from "react"
import styled from "styled-components"
import { colors, LU12, mediaQueries } from "ui"

const Item = styled.li`
  height: 24px;
  padding: 4px 12px;
  margin: 4px 0px;
  background: linear-gradient(
      0deg,
      rgba(34, 0, 117, 0.02),
      rgba(34, 0, 117, 0.02)
    ),
    #ffffff;

  @media ${mediaQueries.isMobile} {
    padding: 8px 16px;
    height: 38px;
    margin: 0px;
  }

  p {
    color: ${colors.grey2};
    margin: 4px;
  }
`

interface PropsType {
  title: string
}

export default function MenuTitle({ title }: PropsType) {
  return (
    <Item>
      <LU12>{title}</LU12>
    </Item>
  )
}
