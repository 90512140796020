import DeleteButtonWithConfirmation from "components/ButtonsWithConfirmation/DeleteButtonWithConfirmation/DeleteButtonWithConfirmation"
import useDeleteEquipment from "graphql/equipments/useDeleteEquipment"
import useTranslate from "intl/useTranslate"
import React from "react"
import { Equipment } from "types"
import { P16, P16Bold } from "ui"

interface PropsType {
  equipment: Equipment
  onSucces: () => void
}

export default function DeleteEquipmentButton({
  equipment,
  onSucces,
}: PropsType) {
  const t = useTranslate()

  const deleteEquipment = useDeleteEquipment()

  return (
    <DeleteButtonWithConfirmation
      dialogTitle={t("confirm service deletion title")}
      onConfirm={async () => {
        await deleteEquipment(equipment.id)
        onSucces()
      }}
    >
      <P16>{t("confirm service deletion message")}</P16>
      <P16Bold>{equipment.name}</P16Bold>
    </DeleteButtonWithConfirmation>
  )
}
