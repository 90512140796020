import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useLocationsTree from "graphql/locations/useLocationsTree"
import usePlanningUserSlots from "graphql/slots/usePlanningUserSlots"
import useUser from "graphql/users/useUser"
import useMediaQueries from "hooks/useMediaQueries"
import useUserTree from "hooks/useUserTree"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import { Route, Switch } from "react-router-dom"
import styled from "styled-components"
import { Flags, UserBase, UTCSlot, ViewModeType } from "types"
import { colors, mediaQueries, P16 } from "ui"
import LocationTree, { getParentsFromLocationId } from "utils/LocationTree"
import UserTree from "utils/UserTree"

import PlanningRowLayout from "../PlanningRowLayout/PlanningRowLayout"
import UserPlanningItem from "./UserPlanningItem/UserPlanningItem"
import UserSlots from "./UserSlots/UserSlots"

const ListLayout = styled.ul`
  display: grid;
  grid-template-columns: auto;
  width: 100%;
`

const EmptyPlanningContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  @keyframes fade-in {
    from {
      transform: scale(0.95);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }

  > p {
    color: ${colors.grey1};
    animation-name: fade-in;
    animation-duration: 250ms;
    animation-iteration-count: 1;
  }

  > div:first-child {
    margin-top: 0;
  }

  > img {
    width: 60%;
    @media ${mediaQueries.isMobile} {
      width: 100%;
    }
  }

  @media ${mediaQueries.isMobile} {
    padding: 0px 12px;
  }
`

const MobileItemContent = styled.div`
  position: relative;

  > span {
    position: absolute;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 2px 8px;
    inset: 0 0 auto 0;
    z-index: 1;

    p em {
      font-style: normal;
      padding-left: 4px;
      color: ${colors.grey1};
    }

    svg {
      width: 18px;
      height: 18px;
    }

    > div:last-child {
      display: flex;
      gap: 8px;
      margin-left: auto;
      width: min-content;

      > input {
        pointer-events: all;
      }
    }
  }
`

const DayViewItemContent = styled.div`
  > span {
    display: grid;
    grid-template-columns: auto 1fr auto auto;
    align-items: center;
    padding: 0 8px;
    gap: 8px;

    svg {
      width: 18px;
      height: 18px;
    }

    p  {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    > div:first-child {
      padding: 0;
      max-height: 32px;
    }
  }
`

function getFilteredSlots(
  slots: UTCSlot[],
  userShowWeekends: boolean,
  viewMode: ViewModeType
) {
  if (viewMode === "WEEK" && !userShowWeekends)
    return slots.filter((s) => !s.date.isWeekend())
  if (viewMode === "WEEK") return slots
  if (viewMode === "MONTH" && !userShowWeekends)
    return slots.filter((s) => s.date.getDay() !== 0)
  return slots
}

interface UserListItemPropsType {
  user: UserBase
  userTree: UserTree[]
  companyFlags: Flags
  focusedSlot: UTCSlot | undefined
  "data-component"?: string
  depth: number
  setFocusedSlot: (slot: UTCSlot | undefined) => void
}

function UserLisItem({
  user,
  userTree,
  companyFlags,
  focusedSlot,
  "data-component": dataComponent,
  depth,
  setFocusedSlot,
}: UserListItemPropsType) {
  const { user: me } = useUser()
  const { isMobile } = useMediaQueries()

  const { from, to, userShowWeekends, viewMode, visibleTeams } =
    usePlanningContext()

  const { slots: planningSlots, loading } = usePlanningUserSlots({
    userId: user.id,
    from,
    to,
  })

  const slots = getFilteredSlots(planningSlots, userShowWeekends, viewMode)

  const { locations } = useLocationsTree()

  const isTeamFriendly = location.pathname === "/planning/group/myteam"
  const userAsUserTree = UserTree.findUserById(userTree, user.id)
  const managerWithTeam =
    userAsUserTree?.isManager && userAsUserTree.team.length > 0
      ? userAsUserTree
      : undefined
  const showTeam = visibleTeams.find((id) => id === user.id) !== undefined
  const canShowTeam = isTeamFriendly && showTeam && managerWithTeam

  const userLocationsOfTheWeek = slots.reduce(
    (prev: string[], curr: UTCSlot) => {
      if (curr.locationId === null) return prev
      if (prev.find((p) => p === curr.locationId)) return prev
      return [...prev, curr.locationId]
    },
    []
  )

  let parentsLocation: LocationTree[] = []
  for (const locationId of userLocationsOfTheWeek) {
    const parents = getParentsFromLocationId(locations, locationId)
    if (parents) {
      parentsLocation = [...parentsLocation, ...parents]
    }
  }

  const userIsMe = me?.id === user.id

  if (loading) return <></>

  if (isMobile && viewMode === "DAY")
    return (
      <>
        <PlanningRowLayout
          data-component={userIsMe ? undefined : dataComponent}
        >
          <DayViewItemContent>
            <UserPlanningItem
              user={user}
              slots={slots}
              isMe={userIsMe}
              companyFlags={companyFlags}
              data-component={dataComponent}
              showTeam={false}
              depth={depth}
              focusedSlot={focusedSlot}
              setFocusedSlot={setFocusedSlot}
            />
          </DayViewItemContent>
        </PlanningRowLayout>
      </>
    )
  if (isMobile)
    return (
      <>
        <PlanningRowLayout
          data-component={userIsMe ? undefined : dataComponent}
        >
          <MobileItemContent>
            <UserPlanningItem
              user={user}
              slots={slots}
              isMe={userIsMe}
              companyFlags={companyFlags}
              data-component={dataComponent}
              showTeam={false}
              depth={depth}
              focusedSlot={focusedSlot}
              setFocusedSlot={setFocusedSlot}
            />
            <UserSlots
              slots={slots}
              focusedSlot={focusedSlot}
              viewMode={viewMode}
              setFocusedSlot={setFocusedSlot}
            />
          </MobileItemContent>
        </PlanningRowLayout>
      </>
    )

  return (
    <>
      <PlanningRowLayout>
        <UserPlanningItem
          user={user}
          slots={slots}
          isMe={userIsMe}
          companyFlags={companyFlags}
          data-component={dataComponent}
          showTeam={showTeam}
          depth={depth}
          focusedSlot={focusedSlot}
          setFocusedSlot={setFocusedSlot}
        />
        <UserSlots
          slots={slots}
          focusedSlot={focusedSlot}
          viewMode={viewMode}
          setFocusedSlot={setFocusedSlot}
        />
      </PlanningRowLayout>
      {canShowTeam &&
        managerWithTeam.team.map((u) => (
          <UserLisItem
            key={`team-${managerWithTeam.id}-member-${u.id}`}
            user={u}
            userTree={userTree}
            companyFlags={companyFlags}
            focusedSlot={focusedSlot}
            depth={depth + 1}
            setFocusedSlot={setFocusedSlot}
          />
        ))}
    </>
  )
}

interface PropsType {
  users: UserBase[]
  limit?: number
}

export default function UsersPlanningList({ users, limit }: PropsType) {
  const t = useTranslate()
  const { loading, viewMode } = usePlanningContext()
  const { user: me } = useUser()
  const { userTree } = useUserTree()
  const [focusedSlot, setFocusedSlot] = useState<UTCSlot | undefined>(undefined)

  const canShowMe = () => {
    if (viewMode !== "MONTH") return users.find((u) => u.id === me?.id)
    if (location.pathname.startsWith("/planning/group/myteam")) return true
    if (location.pathname.startsWith("/planning/group/mymanagerteam"))
      return true
    return viewMode === "MONTH" && users.find((u) => u.id === me?.id)
  }

  if (loading)
    return (
      <EmptyPlanningContainer>
        <LoaderOrErrorComponent marginTop={0} loading={loading} error={null} />
      </EmptyPlanningContainer>
    )
  if (users.length === 0)
    return (
      <Switch>
        <Route path="/planning/favorites">
          <EmptyPlanningContainer>
            <img src={"/static/img/add_user_to_favorites.png"} />
            <P16>{t("you have no favorites users yet - add to favorites")}</P16>
          </EmptyPlanningContainer>
        </Route>
        <Route path="/planning">
          <EmptyPlanningContainer>
            <P16>{t("nobody fits filtering")}</P16>
          </EmptyPlanningContainer>
        </Route>
      </Switch>
    )

  if (me === null) return <></>

  return (
    <ListLayout>
      {canShowMe() && (
        <UserLisItem
          user={me}
          userTree={userTree}
          companyFlags={me.company.flags}
          focusedSlot={focusedSlot}
          depth={0}
          setFocusedSlot={setFocusedSlot}
        />
      )}
      {users
        .filter((u) => u.id !== me?.id)
        .map((u, i) => {
          if (limit && i >= limit) return null
          return (
            <UserLisItem
              key={`user-${u.id}`}
              user={u}
              userTree={userTree}
              companyFlags={me.company.flags}
              focusedSlot={focusedSlot}
              data-component={i === 0 ? "first-user-list-item" : undefined}
              depth={0}
              setFocusedSlot={setFocusedSlot}
            />
          )
        })}
    </ListLayout>
  )
}
