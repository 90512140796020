import { Drawer } from "@mui/material"
import React from "react"
import styled from "styled-components"

const Container = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  button {
    width: 100%;
  }
`

interface WrapperProps {
  children: React.ReactNode
  open: boolean
  onClose: (e: React.SyntheticEvent) => void
}

export default function MobileWrapper({
  children,
  open,
  onClose,
}: WrapperProps) {
  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          marginBottom: "env(safe-area-inset-bottom, 0)",
        },
      }}
    >
      <Container>{children}</Container>
    </Drawer>
  )
}
