import useMenu from "hooks/useMenu"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { UserFavorites } from "types"
import { colors, Link } from "ui"

const ListItem = styled.li`
  display: flex;
  align-items: center;
  min-height: 24px;
  width: 100%;

  list-style: none;
  padding: 2px 0 2px 24px;

  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  &:focus {
    background: hsla(${colors.blackHSL}, 0.08);
  }

  a {
    width: 100%;
  }

  &:hover {
    background: hsla(${colors.blackHSL}, 0.04);
  }

  &:focus-within,
  &.menu-element-item--selected {
    background: hsla(${colors.blackHSL}, 0.12);
    font-weight: 600;
  }

  &:hover:focus {
    background: linear-gradient(
        0deg,
        hsla(${colors.blackHSL}, 0.04),
        hsla(${colors.blackHSL}, 0.04)
      ),
      hsla(${colors.blackHSL}, 0.12);
  }
`

const Icon = styled.span`
  display: flex;
  min-width: 24px;
  svg {
    width: 16px;
    height: 16px;
  }
`

interface CounterPropsType {
  count: number
}

function Counter({ count }: CounterPropsType) {
  return <Badge className={count <= 0 ? "disabled" : ""}>{count}</Badge>
}

interface PropsType {
  path: string
  favorites: UserFavorites
}

export default function FavoriteUsersMenuItem({ path, favorites }: PropsType) {
  const t = useTranslate()

  const { handleLogEvent } = useMenu()

  const selected = location.pathname.startsWith(path)
  const count = favorites.users.length
  const disabled = count === 0

  return (
    <ListItem
      className={
        selected
          ? disabled
            ? "menu-element-item--selected menu-element-item--disabled"
            : "menu-element-item--selected"
          : disabled
          ? "menu-element-item menu-element-item--disabled"
          : "menu-element-item"
      }
      onClick={() => handleLogEvent("menu-action", { to: path })}
    >
      <Link naked to={path}>
        <Icon>
          <Counter count={count} />
        </Icon>
        {t("Favorite users")}
      </Link>
    </ListItem>
  )
}

const Badge = styled.span`
  background: ${colors.grey3};
  width: 18px;
  aspect-ratio: 1;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 16px;

  &.disabled {
    color: ${colors.grey2};
  }
`
