import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import UserOnboardingSlotTemplatesPage from "pages/OnboardingUserPage/UserOnboardingPlanningPage/UserOnboardingPlanningPage"
import React, { useEffect } from "react"

export default function UserPlanningTemplatePage() {
  const { setActiveLocation, activeLocation } = usePlanningContext()

  useEffect(() => {
    // reset active location if any
    if (activeLocation.location) {
      setActiveLocation({
        location: null,
        persist: false,
        triggeredFrom: "planning",
      })
    }
    // eslint-disable-next-line
  }, [])

  return <UserOnboardingSlotTemplatesPage />
}
