import en from "./en"
import fr from "./fr"
import { Translation } from "./types"

export default function getTranslation(lang: string): Translation {
  // fr, fr-fr, etc
  if (lang.substr(0, 2).toLowerCase() === "fr") {
    return fr
  }
  return en
}
