import useCompanyFlags from "hooks/useCompanyFlags"
import useTranslate from "intl/useTranslate"
import React from "react"
import { UserBase } from "types"
import { UserListItem } from "ui"
import getUserDisplayName from "utils/getUserDisplayName"
import UserTree from "utils/UserTree"

import CollapseContainer from "../components/CollapseContainer"
import { Manager } from "./components/Manager"

interface PropsType {
  users: UserTree[]
  collapseOpen: string | null
  setCollapseOpen: (str: string | null) => void
  onUserSelect?: (user: UserTree | UserBase) => void
}

export function OrganisationChart({
  users,
  collapseOpen,
  setCollapseOpen,
  onUserSelect,
}: PropsType) {
  const t = useTranslate()
  const { companyFlags } = useCompanyFlags()

  const nbManagers = UserTree.getUserTreeManagers(users).length
  const nbCollaborators = UserTree.getUserTreeCollaborators(users).length
  const handleUserSelect = (user: UserBase | UserTree) => {
    if (!onUserSelect) return
    onUserSelect(user)
    setCollapseOpen(null)
  }

  return (
    <CollapseContainer
      id="organisation chart"
      title={t("Organisation chart")}
      subtitle={`${nbManagers} ${
        nbManagers > 1 ? t("Managers") : t("Manager")
      } - ${nbCollaborators}  ${
        nbCollaborators > 1 ? t("Collaborators") : t("Collaborator")
      }`}
      collapseOpen={collapseOpen}
      setCollapseOpen={setCollapseOpen}
    >
      {users.map((user) => {
        if (user.isManager)
          return (
            <Manager
              key={user.id}
              manager={user}
              onUserSelect={onUserSelect ? handleUserSelect : undefined}
            />
          )
        else
          return (
            <div key={user.id} style={{ marginLeft: 30 }}>
              <UserListItem
                user={user}
                onUserSelect={onUserSelect ? handleUserSelect : undefined}
                showFavoriteCheckbox
                showLeaveGroupButton={false}
              >
                {getUserDisplayName(user, companyFlags)}
              </UserListItem>
            </div>
          )
      })}
    </CollapseContainer>
  )
}
