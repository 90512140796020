enum SortByOrder {
  ASC = 1,
  DESC = -1,
}

export default function sortByKey<T = any>(
  arr: T[],
  key = "name",
  order: SortByOrder = SortByOrder.ASC
) {
  return [...arr].sort(
    (s1: any, s2: any) => order * String(s1[key]).localeCompare(String(s2[key]))
  )
}
