import useIndexedDays from "hooks/useIndexedDays"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { HybridRule } from "types"
import { colors, P16Bold } from "ui"

const List = styled.ul`
  margin-top: auto;
  display: flex;
  justify-content: space-evenly;
  gap: 8px;
  background: ${colors.grey4};
  padding: 10px 16px 14px 16px;
  border-radius: 0 0 16px 16px;
  min-height: 56px;
  .list {
    display: flex;
    gap: 8px;
  }
`

const Day = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 11px;
  border-radius: 100vh;
  background: ${colors.grey3};
  p {
    color: ${colors.grey1};
  }

  &.restricted {
    background: ${colors.green};
    p {
      color: ${colors.white};
    }
  }
`

interface PropsType {
  rule: HybridRule
}

export default function DaysList({ rule }: PropsType) {
  const indexedDays = useIndexedDays()
  const t = useTranslate()

  return (
    <List>
      {rule.period === "week" &&
        indexedDays.map(({ index, name }, n) => {
          const isRestrictedDay =
            rule.restrictedDays.find((id) => id === index) !== undefined
          return (
            <Day
              key={`rule-${rule.id}-${n}`}
              className={isRestrictedDay ? "restricted" : undefined}
            >
              <P16Bold>{t(name)[0]}</P16Bold>
            </Day>
          )
        })}
    </List>
  )
}
