import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useDeleteUser from "graphql/admin/useDeleteUser"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { User } from "types"
import { Button, Dialog, P14 } from "ui"

const Content = styled.div`
  padding: 24px;
`

interface PropsType {
  open: boolean
  onClose: () => void
  user: User
  onUserHasBeenDeleted: () => void
}

export default function DeleteUserConfirmation({
  open,
  onClose,
  user,
  onUserHasBeenDeleted,
}: PropsType) {
  const t = useTranslate()

  const { addToast } = useToasts()
  const { deleteUser } = useDeleteUser()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t("Delete User")}
      leftButton={
        <Button secondary onClick={onClose}>
          {t("Cancel")}
        </Button>
      }
      rightButton={
        <Button
          onClick={() => {
            deleteUser(user.id).then(() => {
              addToast(t("User has been deleted"), { appearance: "success" })
              onUserHasBeenDeleted()
            })
            onClose()
          }}
        >
          {t("Yes, delete user")}
        </Button>
      }
    >
      <Content>
        <P14>{t("delete user confirm")}</P14>
      </Content>
    </Dialog>
  )
}
