import { gql } from "@apollo/client"

export const UserIntegrationFragment = gql`
  fragment UserIntegrationFragment on UserIntegration {
    id
    externalId
    name
    flags
  }
`
