import React, { useEffect, useState } from "react"
import styled from "styled-components"
import LocationTree from "utils/LocationTree"

import FabricJSWrapper from "../FabricJSWrapper"
import { VisualEditorMode } from "../VisualAreaEditor"
import AreaLabel, { LabelProps } from "./InlineToolsComponents/AreaLabel"
import DeleteButton, {
  DeleteButtonProps,
} from "./InlineToolsComponents/DeleteButton"
import ShapeSelector, {
  ShapeButtonProps,
} from "./InlineToolsComponents/ShapeSelector"

const Container = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
`

interface Props {
  loading: boolean
  fabricWrapper: FabricJSWrapper | null
  location: LocationTree
  activeSublocation?: LocationTree
  mode: VisualEditorMode
}

export default function InlineTools({
  loading,
  fabricWrapper,
  location,
  activeSublocation,
  mode,
}: Props) {
  const [hidden, setHidden] = useState(false)
  const [labels, setLabels] = useState<LabelProps[]>([])
  const [shapeButtons, setShapeButtons] = useState<ShapeButtonProps[]>([])
  const [deleteButtons, setDeleteButtons] = useState<DeleteButtonProps[]>([])

  useEffect(() => {
    let skipUpdate = false
    const canvas = fabricWrapper?.fabricCanvas

    const update = () => {
      if (skipUpdate) {
        return
      }

      const labels: LabelProps[] = []
      const shapeButtons: ShapeButtonProps[] = []
      const deleteButtons: DeleteButtonProps[] = []
      const canvasHeight = canvas?.height || 0
      const canvasWidth = canvas?.width || 0
      for (const obj of canvas?.getObjects() ?? []) {
        const { locationId } = obj.data
        const b = obj.getBoundingRect()
        const childLocation = LocationTree.getLocationNode(
          [location],
          locationId
        )
        if (childLocation && b.width > 50 && b.height > 20) {
          const pos =
            obj.type === "rect"
              ? {
                  top: b.top,
                  left: b.left,
                  center: false,
                }
              : {
                  bottom: canvasHeight - (b.top + b.height * 0.5),
                  left: b.left + b.height * 0.5,
                  center: true,
                }
          labels.push({
            text: childLocation.name,
            maxWidth: b.width,
            ...pos,
          })
        }
        if (
          mode === VisualEditorMode.EDITOR &&
          locationId === activeSublocation?.id
        ) {
          if (obj.type && b.width > 90 && b.height > 70) {
            const sbPosition =
              obj.type === "rect"
                ? {
                    left: b.left,
                    bottom: canvasHeight - (b.top + b.height - 4),
                  }
                : {
                    right: canvasWidth - (b.left + b.width * 0.5),
                    top: b.top + b.height * 0.5,
                  }
            shapeButtons.push({
              locationId,
              shape: obj.type,
              ...sbPosition,
            })

            const dbPosition =
              obj.type === "rect"
                ? {
                    right: canvasWidth - (b.left + b.width - 2),
                    bottom: canvasHeight - (b.top + b.height - 4),
                  }
                : {
                    left: b.left + b.width * 0.5,
                    top: b.top + b.height * 0.5,
                  }
            deleteButtons.push({
              locationId,
              ...dbPosition,
            })
          }
        }
      }
      setLabels(labels)
      setShapeButtons(shapeButtons)
      setDeleteButtons(deleteButtons)
    }

    const onMouseDown = () => {
      /*
       * We hide the inline tools between mouse down and mouse up
       * events to prevent their location from being out of sync with
       * the canvas.
       * This is because FabricJS prevents the DOM event loop
       * from running while dragging.
       */
      setHidden(true)
      skipUpdate = true
    }

    const onMouseUp = () => {
      setHidden(false)
      skipUpdate = false
    }

    canvas?.on("before:render", update)
    canvas?.on("mouse:down", onMouseDown)
    canvas?.on("mouse:up", onMouseUp)
    return () => {
      canvas?.off("before:render", update)
      canvas?.off("mouse:down", onMouseDown)
      canvas?.off("mouse:up", onMouseUp)
    }
  }, [fabricWrapper, location, activeSublocation, mode])

  const onChangeShape = (locationId: string, shape: string) => {
    fabricWrapper?.changeShape(locationId, shape)
  }

  const onDelete = (locationId: string) => {
    fabricWrapper?.deleteObject(locationId)
  }

  if (!fabricWrapper || loading || hidden) {
    return null
  }

  return (
    <Container>
      {labels.map((label, idx) => {
        return <AreaLabel key={idx} {...label} />
      })}
      {shapeButtons.map((shapeButton, idx) => {
        return (
          <ShapeSelector
            key={idx}
            onChangeShape={onChangeShape}
            {...shapeButton}
          />
        )
      })}
      {deleteButtons.map((deleteButton, idx) => {
        return <DeleteButton key={idx} onDelete={onDelete} {...deleteButton} />
      })}
    </Container>
  )
}
