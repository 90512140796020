import styled from "styled-components"
import { colors } from "ui"

export const ButtonBase = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 5px 16px;
  border-radius: 100vh;
  border: solid 1px ${colors.grey3};
  background: rgba(0, 0, 0, 0);
  margin-left: auto;

  svg {
    stroke: ${colors.grey1};
    width: 20px;
    height: 20px;
    transition: stroke 250ms ease-in-out;
  }

  &:hover {
    svg {
      stroke: ${colors.black};
    }
  }
`

export const Circle = styled.div`
  &.circle {
    width: 14px;
    height: 14px;
    border-radius: 100vh;
    border-style: solid;
    border-width: 3px;

    background: transparent;
    &--empty-planning {
      border-color: ${colors.grey3};
    }
    &--not-empty-planning {
      border-color: ${colors.home} ${colors.office} ${colors.else} ${colors.off};
    }
    &--available {
      border-color: ${colors.home} ${colors.office};
    }
    &--office {
      border-color: ${colors.office};
    }
    &--home {
      border-color: ${colors.home};
    }
    &--else {
      border-color: ${colors.else};
    }
    &--off {
      border-color: ${colors.off};
    }
  }
`
