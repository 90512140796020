import useTranslate from "intl/useTranslate"
import React, { useRef, useState } from "react"
import styled from "styled-components"
import { MeetingRoom } from "types"
import { colors, FieldsetRow } from "ui"
import { Cancel, Search as SearchIcon } from "ui/icons"

const Form = styled.form`
  display: flex;
  align-items: center;
  padding: 0 16px;
  background: ${colors.grey4};
  fieldset {
    width: 100%;
    > svg:first-child {
      // search icon
      stroke: ${colors.grey2};
    }
    > svg:last-child {
      // cancel search button
      margin-left: auto;
      stroke: ${colors.grey2};
      cursor: pointer;
    }
  }
`

const Input = styled.input`
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  outline: none;
  font-weight: 400;
  font-size: 14px;
  color: ${colors.black};
  width: 100%;

  &::placeholder {
    color: ${colors.grey1};
  }
`

interface PropsType {
  meetingRooms: MeetingRoom[]
  updateFilteredMeetingRooms: (rooms: MeetingRoom[]) => void
}

export default function MeetingRoomsSearch({
  meetingRooms,
  updateFilteredMeetingRooms,
}: PropsType) {
  const t = useTranslate()
  const [input, setInput] = useState("")
  const inputRef = useRef<HTMLInputElement>(null)

  const handleChange = (str: string) => {
    setInput(str)
    if (str === "") return updateFilteredMeetingRooms(meetingRooms)
    const found = meetingRooms.filter(({ name }) =>
      name.toLowerCase().includes(input.toLowerCase())
    )
    if (found.length > 0) updateFilteredMeetingRooms(found)
  }

  const clearSearch = () => {
    setInput("")
    updateFilteredMeetingRooms(meetingRooms)
    if (inputRef.current) inputRef.current.value = ""
  }

  return (
    <Form onSubmit={(e) => e.preventDefault()}>
      <FieldsetRow>
        <SearchIcon />
        <Input
          ref={inputRef}
          type="text"
          placeholder={t("search meeting room")}
          onChange={(e) => handleChange(e.target.value)}
        />
        {input !== "" && <Cancel onClick={() => clearSearch()} />}
      </FieldsetRow>
    </Form>
  )
}
