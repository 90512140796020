import { IntegrationEvent } from "types"
import LocalDate from "utils/LocalDate"

export default function getFilteredEvents(
  events: IntegrationEvent[],
  clamp: "TODAY" | "THIS-WEEK" | "LATER" | "PAST"
): IntegrationEvent[] {
  const today = new LocalDate("DAY")
  const dayAfterToday = new LocalDate("NEXT-DAY")
  const nextMonday = new LocalDate("NEXT-MONDAY")

  switch (clamp) {
    case "PAST": {
      const filter = (ue: IntegrationEvent) => {
        const toDate = new LocalDate(ue.to, "DAY")
        return toDate.getTime() < today.getTime() && ue.initiator !== null
      }
      return events.filter(filter)
    }
    case "TODAY": {
      const filter = (ue: IntegrationEvent) => {
        const fromDate = new LocalDate(ue.from, "DAY")
        return (
          fromDate.getTime() >= today.getTime() &&
          fromDate.getTime() < dayAfterToday.getTime() &&
          ue.initiator !== null
        )
      }
      return events.filter(filter)
    }
    case "THIS-WEEK": {
      const filter = (ue: IntegrationEvent) => {
        const fromDate = new LocalDate(ue.from, "DAY")
        return (
          fromDate.getTime() >= dayAfterToday.getTime() &&
          fromDate.getTime() < nextMonday.getTime() &&
          ue.initiator !== null
        )
      }
      return events.filter(filter)
    }
    case "LATER": {
      const filter = (ue: IntegrationEvent) =>
        new LocalDate(ue.from, "DAY").getTime() >= nextMonday.getTime() &&
        ue.initiator !== null
      return events.filter(filter)
    }
    default:
      return []
  }
}
