import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import useLocationsTree from "graphql/locations/useLocationsTree"
import useMediaQueries from "hooks/useMediaQueries"
import React, { useState } from "react"
import { useParams } from "react-router-dom"
import LocationTree, { findOldestParentWithFloorPlan } from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

import DesktopFloorPlanDrawer from "./DesktopFloorPlanDrawer"
import MobileUserFloorPlanDrawer from "./MobileUserFloorPlanDrawer"

interface PropsType {
  from: UTCDate
  to: UTCDate
  drawerWidth: number
  onClose: () => void
}

function getSelectedFloorPlan(
  roots: LocationTree[],
  selectedLocation: LocationTree | undefined,
  locationFromUrl: LocationTree | undefined
) {
  if (!locationFromUrl && !selectedLocation) return undefined

  if (selectedLocation)
    return findOldestParentWithFloorPlan(roots, selectedLocation)

  if (locationFromUrl)
    return findOldestParentWithFloorPlan(roots, locationFromUrl)

  return undefined
}

export default function UserFloorPlanRouter({
  from,
  to,
  drawerWidth,
  onClose,
}: PropsType) {
  const { isMobile } = useMediaQueries()
  const { locationId } = useParams<{ locationId?: string }>()
  const { locations: locationsTree, loading } = useLocationsTree()

  const locationFromUrl = LocationTree.getLocationNode(
    locationsTree,
    locationId ?? ""
  )
  const [selectedLocation, setSelectedLocation] = useState<
    LocationTree | undefined
  >(locationFromUrl)

  const selectedFloorPlan = getSelectedFloorPlan(
    locationsTree,
    selectedLocation,
    locationFromUrl
  )

  // const floorPlanLocations =
  //   baseLocations.length > 0 ? baseLocations : locationsTree
  const floorPlanLocations = locationsTree

  if (loading) return <LoaderOrErrorComponent loading={loading} error={null} />

  if (isMobile)
    return (
      <MobileUserFloorPlanDrawer
        from={from}
        to={to}
        locations={floorPlanLocations}
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
        selectedFloorPlan={selectedFloorPlan}
        onClose={onClose}
      />
    )

  return (
    <DesktopFloorPlanDrawer
      from={from}
      to={to}
      locations={floorPlanLocations}
      selectedLocation={selectedLocation}
      selectedFloorPlan={selectedFloorPlan}
      drawerWidth={drawerWidth}
      setSelectedLocation={setSelectedLocation}
      onClose={onClose}
    />
  )
}
