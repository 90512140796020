import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import Search from "components/Search/Search"
import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { useTeamsAppContext } from "TeamsApp/TeamsAppContext"
import { Button } from "ui"

import CalendarDays from "../components/CalendarDays/CalendarDays"
import EditableUserSlots from "../components/EditableUserSlots/EditableUserSlots"
import UserFloorPlanRouter from "../components/PlanningHeadersRouter/PlanningHeaders/LocationPlanningHeader/UserFloorPlanDrawer/UserFloorPlanDrawer"
import PlanningHeadersRouter from "../components/PlanningHeadersRouter/PlanningHeadersRouter"
import PlanningNavigation from "../components/PlanningNavigation/PlanningNavigation"
import PlanningProgress from "../components/PlanningProgress/PlanningProgress"
import PlanningRowLayout from "../components/PlanningRowLayout/PlanningRowLayout"
import UsersPlanningList from "../components/UsersPlanningList/UsersPlanningList"

const Layout = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;

  height: 100vh;
  overflow: hidden;
`

const Body = styled.div`
  overflow-y: scroll;
`

const ViewMoreButton = styled(Button)`
  margin: 8px 0 8px 16px;
`

const GROUP_LIST_LIMIT = 20
const GROUP_LIST_INCREMENT = 10
const DEFAULT_LAYOUT_WIDTH = 824

export default function PlanningPageDesktop() {
  const t = useTranslate()
  const { isTeamsApp } = useTeamsAppContext()
  const {
    users,
    openDrawer,
    setOpenDrawer,
    from,
    to,
    viewMode,
    isTypicalWeekOpen,
  } = usePlanningContext()

  const { user: me } = useUser()

  const [limit, setLimit] = useState(GROUP_LIST_LIMIT)

  const [layoutWidth, setLayoutWidth] = useState(DEFAULT_LAYOUT_WIDTH)

  const showUsersPlannings = isTypicalWeekOpen || openDrawer === null
  const showHeader = isTypicalWeekOpen || openDrawer === null

  if (!me) return null

  return (
    <Layout
      ref={(el) => {
        if (!el) return
        const margins = 68
        setLayoutWidth(el.getBoundingClientRect().width - margins)
      }}
    >
      {/*
        First row is the date selector on the left, and the week days on the right
        */}
      <div className="planning-page-header">
        {!isTeamsApp && <Search forceBringToTop />}
        <PlanningRowLayout options={{ topBorder: true }}>
          <PlanningNavigation />
          <CalendarDays />
        </PlanningRowLayout>
        {viewMode === "WEEK" && (
          <PlanningRowLayout>
            <PlanningProgress />
            <EditableUserSlots />
          </PlanningRowLayout>
        )}
        {showHeader && <PlanningHeadersRouter users={users} />}
      </div>
      <Body
        onClick={() => {
          if (openDrawer !== null) setOpenDrawer(null)
        }}
      >
        {showUsersPlannings && (
          <>
            <UsersPlanningList users={users} limit={limit} />
            {limit < users.length && (
              <PlanningRowLayout>
                <ViewMoreButton
                  ghost
                  onClick={() => setLimit(limit + GROUP_LIST_INCREMENT)}
                >
                  {t("view more")}
                </ViewMoreButton>
                <div />
              </PlanningRowLayout>
            )}
          </>
        )}
      </Body>
      {openDrawer === "FLOOR_PLAN" && (
        <UserFloorPlanRouter
          from={from}
          to={to}
          drawerWidth={layoutWidth}
          onClose={() => {
            if (openDrawer === "FLOOR_PLAN") setOpenDrawer(null)
          }}
        />
      )}
    </Layout>
  )
}
