import React from "react"
import styled from "styled-components"
import colors from "ui/colors"

interface PrimaryIconButtonProps {
  readonly ghost?: boolean
  $iconColor?: string
}

const PrimaryIconButton = styled.button<PrimaryIconButtonProps>`
  display: flex;
  padding: 8px;
  background: none;
  ${({ ghost }) =>
    ghost
      ? `border: 1px solid hsla(0, 0%, 0%, 0);`
      : `border: 1px solid ${colors.grey3};`}
  border-radius: 4px;

  &:hover {
    background: linear-gradient(
        0deg,
        hsla(${colors.blackHSL}, 0.04),
        hsla(${colors.blackHSL}, 0.04)
      ),
      ${colors.white};
  }

  &:focus {
    background: linear-gradient(
        0deg,
        hsla(${colors.blackHSL}, 0.12),
        hsla(${colors.blackHSL}, 0.12)
      ),
      ${colors.white};
  }

  &:active {
    background: linear-gradient(
        0deg,
        hsla(${colors.blackHSL}, 0.24),
        hsla(${colors.blackHSL}, 0.24)
      ),
      ${colors.white};
  }

  &:disabled {
    background: #fafafa;

    svg {
      color: ${colors.grey2};
    }
  }

  svg {
    ${({ $iconColor }) =>
      $iconColor ? `color: ${$iconColor}` : `color: ${colors.purple};`}
  }
`

interface PropsType {
  icon: JSX.Element
  iconColor?: string
  onClick?: () => void
  disabled?: boolean
  ghost?: boolean
}

export function IconButton({ icon, iconColor, disabled, ...rest }: PropsType) {
  return (
    <PrimaryIconButton $iconColor={iconColor} disabled={disabled} {...rest}>
      {icon}
    </PrimaryIconButton>
  )
}
