import React from "react"
import styled from "styled-components"
import { EditionTimeFrame } from "types"
import { mediaQueries } from "ui"
import UTCDate from "utils/UTCDate"

import InviteGuests from "../InviteGuests/InviteGuests"
import HybridRule from "./HybridRule/HybridRule"
import TeamDayButton from "./TeamDayButton/TeamDayButton"

const ActionsContainer = styled.div`
  margin-left: auto;
  display: flex;
  gap: 8px;

  @media ${mediaQueries.isMobile} {
    width: 100%;
    margin: 0;
    padding-left: 43px;
    flex-direction: column;
    gap: 4px;
  }
`

interface PropsType {
  from: UTCDate
  inviteGuestsMessage: string
  guestsEmails: string[]
  updateGuestsEmails: (emails: string[]) => void
  updateInviteGuestsMessage: (description: string) => void
  setEditionTimeFrame: React.Dispatch<React.SetStateAction<EditionTimeFrame>>
}

export default function SettingsActions({
  from,
  guestsEmails,
  inviteGuestsMessage,
  updateGuestsEmails,
  updateInviteGuestsMessage,
  setEditionTimeFrame,
}: PropsType) {
  return (
    <ActionsContainer>
      <HybridRule />
      <TeamDayButton from={from} setEditionTimeFrame={setEditionTimeFrame} />
      <InviteGuests
        guestsEmails={guestsEmails}
        inviteGuestsMessage={inviteGuestsMessage}
        updateGuestsEmails={updateGuestsEmails}
        updateInviteGuestsMessage={updateInviteGuestsMessage}
      />
    </ActionsContainer>
  )
}
