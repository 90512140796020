import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { Button, colors, Dialog, transitions } from "ui"
import { Trash } from "ui/icons"
interface ContainerPropsType {
  canEdit?: boolean
}

const Container = styled.div<ContainerPropsType>`
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
  gap: 16px;
  background-color: ${colors.white};

  transition: ${transitions.generic};
`

interface WithConfirmationPropsType {
  dialogTitle: string
  buttonTitle?: string
  children: React.ReactNode
  fullWidth?: boolean
  onClick?: () => void
  onConfirm: () => void
}

export default function DeleteButtonWithConfirmation({
  dialogTitle,
  buttonTitle,
  children,
  fullWidth,
  onClick,
  onConfirm,
}: WithConfirmationPropsType) {
  const [open, setOpen] = useState(false)
  const t = useTranslate()

  return (
    <>
      {buttonTitle ? (
        <Button
          danger
          fullWidth={fullWidth}
          onClick={() => {
            setOpen(true)
            if (onClick) onClick()
          }}
        >
          {buttonTitle}
        </Button>
      ) : (
        <Trash
          className="trash-icon"
          onClick={(e) => {
            e.stopPropagation()
            setOpen(true)
            if (onClick) onClick()
          }}
        />
      )}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        title={dialogTitle}
        rightButton={
          <Button
            danger
            onClick={() => {
              onConfirm()
              setOpen(false)
            }}
          >
            {t("Delete")}
          </Button>
        }
      >
        <Container>{children}</Container>
      </Dialog>
    </>
  )
}
