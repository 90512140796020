import { ButtonBase } from "@mui/material"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import PFLifeRingButton from "components/ProductFruits/PFLifeRingButton"
import { useSettingsContext } from "components/SettingsDialog/components/SettingsContextProvider"
import UserAvatar from "components/UserAvatar/UserAvatar"
import useWeeklyUserSlots from "graphql/slots/useWeeklyUserSlots"
import useUser from "graphql/users/useUser"
import useMediaQueries from "hooks/useMediaQueries"
import useMenu from "hooks/useMenu"
import React, { MouseEvent, useState } from "react"
import styled from "styled-components"
import { colors, Popover } from "ui"
import getUserDisplayName from "utils/getUserDisplayName"
import UTCDate from "utils/UTCDate"

const UserMenuFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 12px;
  margin-top: auto;

  &.menu-footer--with-border-top {
    border-top: solid 1px ${colors.grey3};
  }
`

const AvatarContainer = styled.div`
  padding-right: 10px;

  svg,
  img {
    height: 24px;
    width: 24px;
    stroke-width: 1.5px;
  }
`

const UserInfoContainer = styled.div`
  outline: none;
`

const UserNameContainer = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  width: 15ch;
  min-width: 15ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const UserCompanyContainer = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  width: 15ch;
  min-width: 15ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ELLIPSIS_MIN_CHAR = 14

interface PropsType {
  isProductFruitsReady: boolean
}

export default function MenuFooter({ isProductFruitsReady }: PropsType) {
  const { isMobile, isNarrowScreen } = useMediaQueries()
  const { setViewMode } = usePlanningContext()
  const { user: me } = useUser()

  const today = new UTCDate("DAY")
  const { toggleMenuDrawer } = useMenu()
  const { setShouldDisplaySettings } = useSettingsContext()

  const { slots } = useWeeklyUserSlots(me ? me.id : "", today)

  const todaySlots = slots.filter((slot) => slot.date.isToday())

  const slotColor =
    todaySlots[0] && todaySlots[0].value !== null
      ? todaySlots.length === 1
        ? todaySlots[0].value === "null" || todaySlots[0].value === null
          ? colors.black
          : colors[todaySlots[0].value]
        : todaySlots.length === 2
        ? todaySlots[1].value === "null" || todaySlots[1].value === null
          ? colors.black
          : colors[todaySlots[1].value]
        : colors.black
      : colors.black

  // Display Popover for user name
  const [anchorElUserName, setAnchorElUserName] =
    useState<HTMLDivElement | null>(null)
  const handlePopoverOpenUserName = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorElUserName(event.currentTarget)
  }
  const handlePopoverCloseUserName = () => {
    setAnchorElUserName(null)
  }
  const openUserName = Boolean(anchorElUserName)

  // Display Popover for company name
  const [anchorElCompanyName, setAnchorElCompanyName] =
    useState<HTMLDivElement | null>(null)
  const handlePopoverOpenCompanyName = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorElCompanyName(event.currentTarget)
  }
  const handlePopoverCloseCompanyName = () => {
    setAnchorElCompanyName(null)
  }
  const openCompanyName = Boolean(anchorElCompanyName)

  const showProductFruitsButton =
    isProductFruitsReady && !isMobile && !isNarrowScreen

  if (me === null) return <></>

  return (
    <>
      <UserMenuFooterContainer
        className={
          showProductFruitsButton ? "" : "menu-footer--with-border-top"
        }
      >
        {showProductFruitsButton && <PFLifeRingButton />}

        <ButtonBase
          onClick={() => {
            toggleMenuDrawer()
            setShouldDisplaySettings(true)
            setViewMode("WEEK")
          }}
          style={{ textAlign: "left", paddingTop: 8 }}
        >
          <AvatarContainer>
            <UserAvatar user={me} color={slotColor} size={32} />
          </AvatarContainer>
          {me && (
            <UserInfoContainer>
              <UserNameContainer
                onMouseEnter={handlePopoverOpenUserName}
                onMouseLeave={handlePopoverCloseUserName}
              >
                {getUserDisplayName(me, me.company.flags)}
              </UserNameContainer>
              <Popover
                anchorEl={anchorElUserName}
                open={
                  openUserName &&
                  getUserDisplayName(me, me.company.flags).length >
                    ELLIPSIS_MIN_CHAR
                }
                onClose={handlePopoverCloseUserName}
              >
                {getUserDisplayName(me, me.company.flags)}
              </Popover>
              <UserCompanyContainer
                onMouseEnter={handlePopoverOpenCompanyName}
                onMouseLeave={handlePopoverCloseCompanyName}
              >
                {me.company.name}
              </UserCompanyContainer>
              <Popover
                anchorEl={anchorElCompanyName}
                open={
                  openCompanyName && me.company.name.length > ELLIPSIS_MIN_CHAR
                }
                onClose={handlePopoverCloseCompanyName}
              >
                {me.company.name}
              </Popover>
            </UserInfoContainer>
          )}
        </ButtonBase>
      </UserMenuFooterContainer>
    </>
  )
}
