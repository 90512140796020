import { ApolloError, gql, useMutation } from "@apollo/client"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useTranslate from "intl/useTranslate"
import { useState } from "react"

const DELETE_GROUP_MUTATION = gql`
  mutation deleteGroup($id: ID!) {
    deleteGroup(id: $id)
  }
`

export default function useDeleteGroup() {
  const { addToast } = useToasts()
  const t = useTranslate()
  const [error, setError] = useState<ApolloError | null>(null)
  const [loading, setLoading] = useState(false)
  const [mutate] = useMutation(DELETE_GROUP_MUTATION, {
    update(cache, { data: { deleteGroup } }) {
      cache.modify({
        fields: {
          allGroups(existingGroups = []) {
            return existingGroups.filter(
              (es: any) => es.__ref !== `Group:${deleteGroup}`
            )
          },
          myGroupsSimple(existingGroups = []) {
            return existingGroups.filter(
              (es: any) => es.__ref !== `Group:${deleteGroup}`
            )
          },
        },
      })
    },
  })
  const deleteGroup = async (id: string) => {
    setLoading(true)
    try {
      await mutate({ variables: { id } })
      addToast(t("Group deleted"), {
        appearance: "success",
      })
    } catch (e) {
      setError(e)
      addToast(e.message, { appearance: "error" })
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    error,
    deleteGroup,
  }
}
