import useUserIntegrations from "graphql/integrations/useUserIntegrations"
import useTranslate from "intl/useTranslate"
import React from "react"
import { User } from "types"
import { Grid, H4 } from "ui"

import GoogleIntegrationComponent from "./GoogleIntegrationComponent"
import OutlookUserIntegrationComponent from "./OutlookUserIntegrationComponent"
import SlackIntegrationComponent from "./SlackIntegrationComponent"

const possibleIntegrations = [
  {
    name: "google",
    Component: GoogleIntegrationComponent,
    enabledByDefault: true,
  },
  {
    name: "microsoft-outlook",
    Component: OutlookUserIntegrationComponent,
    enabledByDefault: true,
  },
  {
    name: "slack",
    Component: SlackIntegrationComponent,
    enabledByDefault: true,
  },
]

interface PropsType {
  user: User
}

export default function CompanyLabelsPage({ user }: PropsType) {
  const enabledIntegrations = user?.company.flags.enabledIntegrations || []
  const { userIntegrations, loading } = useUserIntegrations()
  const t = useTranslate()
  return (
    <Grid container spacing={4} style={{ marginBottom: 25 }}>
      <Grid item xs={12}>
        <H4>{t("Integrations")}</H4>
      </Grid>
      {possibleIntegrations
        .filter(
          ({ name, enabledByDefault }) =>
            enabledByDefault || enabledIntegrations.includes(name)
        )
        .map(({ name, Component }) => (
          <Grid item xs={12} key={`integration-${name}`}>
            <Component
              loading={loading}
              integration={userIntegrations.find((i) => i.name === name)}
            />
          </Grid>
        ))}
    </Grid>
  )
}
