import UTCDatePicker from "components/UTCDatePicker/UTCDatePicker"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { Button, DropDown, Toggle } from "ui"
import { ArrowRight } from "ui/icons"
import UTCDate, { days, months, UTCDateClampType } from "utils/UTCDate"

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 16px;
  margin: 32px 0px 16px 32px;

  .buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    svg {
      height: 20px;
      width: 20px;
    }
  }
`

interface PropsType {
  from: UTCDate
  to: UTCDate
  selectedPeriod: UTCDateClampType | "personalized"
  editSelectedPeriod: (string: UTCDateClampType | "personalized") => void
  editFromDate: (date: UTCDate) => void
  editToDate: (date: UTCDate) => void
  enableWeekends: boolean
  editEnableWeekends?: (bool: boolean) => void
}

export default function DatePicker({
  from,
  to,
  selectedPeriod,
  enableWeekends,
  editEnableWeekends,
  editSelectedPeriod,
  editFromDate,
  editToDate,
}: PropsType) {
  const t = useTranslate()

  const [fromPickerOpen, setFromPickerOpen] = useState<boolean>(false)
  const [toPickerOpen, setToPickerOpen] = useState<boolean>(false)

  const getDate = (date: UTCDate) => {
    return (
      <>{`${t(days[date.getUTCDay()])} ${date.getUTCDate()} ${t(
        months[date.getUTCMonth()]
      )} ${
        selectedPeriod === "YEAR" ||
        from.getUTCFullYear() !== to.getUTCFullYear()
          ? date.getUTCFullYear()
          : ""
      }`}</>
    )
  }

  return (
    <Container>
      <DropDown
        options={[
          { name: t("WEEK-MONDAY"), value: "WEEK-MONDAY" },
          { name: t("MONTH"), value: "MONTH" },
          { name: t("QUARTER"), value: "QUARTER" },
          { name: t("SEMESTER"), value: "SEMESTER" },
          { name: t("YEAR"), value: "YEAR" },
          { name: t("personalized"), value: "personalized" },
        ]}
        title={t(`${selectedPeriod}`)}
        onSelect={(option) => {
          editSelectedPeriod(option.value)
        }}
      />

      <div className="buttons-container">
        <Button secondary onClick={() => setFromPickerOpen(true)}>
          {getDate(from)}
        </Button>
        <ArrowRight />
        <Button secondary onClick={() => setToPickerOpen(true)}>
          {getDate(to)}
        </Button>

        {fromPickerOpen && (
          <UTCDatePicker
            label={t("Choose a date")}
            open={fromPickerOpen}
            defaultDate={from || undefined}
            onClose={() => setFromPickerOpen(false)}
            onSelect={(date) => {
              if (!date) return
              if (!to || to.getTime() < date.getTime()) editToDate(date)
              editFromDate(date)
              editSelectedPeriod("personalized")
              setFromPickerOpen(false)
            }}
          />
        )}
        {toPickerOpen && (
          <UTCDatePicker
            label={t("Choose a date")}
            open={toPickerOpen}
            defaultDate={to || undefined}
            onClose={() => setToPickerOpen(false)}
            onSelect={(date) => {
              if (!date) return
              if (!from || from.getTime() > date.getTime()) editFromDate(date)
              editToDate(date)
              editSelectedPeriod("personalized")
              setToPickerOpen(false)
            }}
          />
        )}
      </div>
      {editEnableWeekends && (
        <Toggle
          label={t("includes week-ends")}
          labelToTheLeft
          checked={enableWeekends}
          onChange={() => editEnableWeekends(!enableWeekends)}
        />
      )}
    </Container>
  )
}
