import useToggleFavorite from "graphql/favorites/useToggleFavorite"
import useUserFavorites from "graphql/favorites/useUserFavorites"
import useUser from "graphql/users/useUser"
import React from "react"
import styled from "styled-components"
import { FavoritePossibleType } from "types"
import { colors } from "ui"
import { Star, StarOutline } from "ui/icons"
import transitions from "ui/transitions"

const FavoriteCheckboxContainer = styled.span`
  position: relative;
  .favorite-checkbox-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      height: 16px;
      width: 16px;
      stroke: ${colors.grey1};
      transition: ${transitions.generic};
    }
  }

  .favorite-checkbox-input:checked ~ .favorite-checkbox-icon {
    svg {
      fill: ${colors.yellow} !important;
    }
  }

  .favorite-checkbox-input:disabled {
    cursor: auto;
  }
`

const FavoriteCheckboxInput = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  margin: 0;
  padding: 0;
  width: 16px;
  height: 16px;
  z-index: 10;
  opacity: 0;
  cursor: pointer;
`

interface PropsType {
  type: FavoritePossibleType
  itemId: string
  className?: string
  disabled?: boolean
}

export default function FavoriteCheckbox({
  type,
  itemId,
  className,
  disabled,
}: PropsType) {
  const { user: me } = useUser()
  const { favorites } = useUserFavorites()
  const toggleFavorite = useToggleFavorite()

  const checked =
    favorites[`${type}s`].find((f) => f.itemId == itemId) !== undefined

  if (type === "user" && me !== null && me.id === itemId) return <></>
  if (type === "group" && itemId === "everyone") return <></>

  return (
    <FavoriteCheckboxContainer
      className={className}
      onClick={(e) => {
        if (!disabled) {
          e.stopPropagation()
        }
      }}
    >
      <FavoriteCheckboxInput
        className="favorite-checkbox-input"
        onChange={(e) => {
          if (!disabled) {
            e.stopPropagation()
            toggleFavorite(itemId, type)
          }
        }}
        checked={checked}
        disabled={disabled}
      />
      <span className={`favorite-checkbox-icon`}>
        {checked ? <Star /> : <StarOutline />}
      </span>
    </FavoriteCheckboxContainer>
  )
}
