import { ApolloError, gql, useQuery } from "@apollo/client"
import VisualArea from "components/FloorPlanComponents/VisualAreaEditor/VisualArea"

interface VisualAreaResult {
  area: VisualArea
}

const GET_VISUAL_AREA_QUERY = gql`
  query floorVisualArea($locationId: ID!) {
    floorVisualArea(locationId: $locationId) {
      locationId
      area
    }
  }
`

export default function useFloorVisualArea(locationId?: string): {
  loading: boolean
  error?: ApolloError
  area: VisualArea
} {
  const { loading, error, data } = useQuery<{
    floorVisualArea: VisualAreaResult
  }>(GET_VISUAL_AREA_QUERY, {
    variables: {
      locationId,
    },
    fetchPolicy: "cache-and-network",
    skip: !locationId,
  })
  const area = data?.floorVisualArea.area
    ? data.floorVisualArea.area
    : {
        objects: [],
      }
  return {
    area,
    loading,
    error,
  }
}
