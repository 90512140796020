import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function Map({ stroke, strokeWidth, ...rest }: SvgIconProps) {
  return (
    <SvgIcon
      strokeWidth={strokeWidth ?? 1.5}
      stroke={stroke ? stroke : "currentColor"}
      {...rest}
    >
      <path
        d="M15 5L9 3M9 19L3.78974 20.7368C3.40122 20.8663 3 20.5771 3 20.1675V5.43246C3 5.1742 3.16526 4.94491 3.41026 4.86325L9 3V19ZM9 19L15 21L9 19ZM9 19V3V19ZM15 21L20.5897 19.1368C20.8347 19.0551 21 18.8258 21 18.5675V3.83246C21 3.42292 20.5988 3.13374 20.2103 3.26325L15 5V21ZM15 21V5V21Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}
