import useCompletionRate from "graphql/stats/useCompletionRate"
import React, { useEffect } from "react"
import { GroupSimple, UserBase } from "types"
import UTCDate from "utils/UTCDate"

interface PropsType {
  users: UserBase[]
  groups: GroupSimple[]
  from: UTCDate
  to: UTCDate
  includeWeekends: boolean
  totalCompletionRate: number | undefined
  updateTotalCompletionRate: (completionRate: number) => void
}

export default function TotalCompletionRateBar({
  users,
  groups,
  from,
  to,
  includeWeekends,
  totalCompletionRate,
  updateTotalCompletionRate,
}: PropsType) {
  const { completionRate, loading, error } = useCompletionRate({
    from,
    to,
    userIds: users.map((u) => u.id),
    groupIds: groups.map((g) => g.id),
    includeWeekends,
  })

  useEffect(() => {
    if (loading || error) return

    if (
      totalCompletionRate === undefined ||
      totalCompletionRate !== completionRate
    ) {
      updateTotalCompletionRate(completionRate)
    }
  }, [
    loading,
    error,
    completionRate,
    totalCompletionRate,
    updateTotalCompletionRate,
  ])

  return <></>
}
