import React from "react"
import { SearchResult } from "types"

import { UserResultsGroup } from "../ResultsGroup/ResultsGroup"
import SearchResultsContainer from "../SearchResultsContainer/SearchResultsContainer"
import RawEmailOrExistingUserPicker from "./RawEmailOrExistingUserPicker/RawEmailOrExistingUserPicker"

interface PropsType {
  anchorEl: HTMLElement | null
  searchString: string
  results: SearchResult[]
  handleReset: () => void
  updateManagerEmail: (email: string) => void
}

export default function AddManagerToNewUserSearchResults({
  searchString,
  results,
  anchorEl,
  handleReset,
  updateManagerEmail,
}: PropsType) {
  const users = results.filter((r) => r.type === "user")

  const handleManagerEmailUpdate = (email: string) => {
    updateManagerEmail(email)
  }

  if (users.length === 0) return <></>

  return (
    <SearchResultsContainer
      anchorEl={anchorEl}
      searchString={searchString}
      results={results}
      handleReset={handleReset}
      forceBringToTop
    >
      <RawEmailOrExistingUserPicker
        searchString={searchString}
        searchResults={results}
        updateManagerEmail={handleManagerEmailUpdate}
      />

      <UserResultsGroup
        results={users}
        showEmail
        onSelect={(manager) => {
          if (manager.email) handleManagerEmailUpdate(manager.email)
        }}
      />
    </SearchResultsContainer>
  )
}
