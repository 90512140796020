import FavoriteCheckbox from "components/FavoriteCheckbox"
import InteractionsMenu from "components/InteractionsMenu/InteractionsMenu"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useAllGroups from "graphql/groups/useAllGroups"
import useMyGroupsSimple from "graphql/groups/useMyGroupsSimple"
import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import { ActiveLocationsList } from "pages/PlanningPage/components/PlanningHeadersRouter/ActiveLocationsList/ActiveLocationsList"
import React from "react"
import { useTeamsAppContext } from "TeamsApp/TeamsAppContext"
import { UserBase } from "types"
import { P14 } from "ui"
import { Group as GroupIcon } from "ui/icons"
import getTeamDisplayName from "utils/getTeamDisplayName"

import { HeaderContainer, HeaderTitle } from "./components"
import HeaderMessage from "./HeaderMessage/HeaderMessage"

interface PropsType {
  groupId: string
  users: UserBase[]
}

export default function GoupHeader({ groupId, users }: PropsType) {
  const t = useTranslate()
  const { showHiddenActiveLocations, from, to } = usePlanningContext()
  const { isTeamsApp } = useTeamsAppContext()
  const { user: me } = useUser()
  const { groups: allGroups } = useAllGroups()
  const { groups: myGroups } = useMyGroupsSimple()
  const group = [...allGroups, ...myGroups].find((g) => g.id === groupId)

  const emptyGroup = users.length === 0
  const meAloneInGroup =
    users.length === 1 && users.find((u) => u.id === me?.id)
  const showMessage =
    (emptyGroup || meAloneInGroup) && !showHiddenActiveLocations

  const displayFavoriteCheckbox =
    !isTeamsApp && groupId !== "myteam" && groupId !== "mymanagerteam"

  if (group === undefined || !me) return <></>

  return (
    <HeaderContainer>
      <HeaderTitle>
        <GroupIcon />
        <P14>{getTeamDisplayName(group, me.company.flags, t)}</P14>
        <div className="actions">
          {displayFavoriteCheckbox && (
            <FavoriteCheckbox itemId={group ? group.id : ""} type="group" />
          )}
          {!isTeamsApp && (
            <InteractionsMenu users={users} from={from} to={to} />
          )}
        </div>
      </HeaderTitle>
      {showMessage ? (
        <HeaderMessage
          message={
            emptyGroup
              ? t("No one in this group")
              : t("No one else in this group")
          }
        />
      ) : (
        <ActiveLocationsList />
      )}
    </HeaderContainer>
  )
}
