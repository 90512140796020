import { ApolloError, gql, useQuery } from "@apollo/client"
import UTCDate from "utils/UTCDate"

const QUERY = gql`
  query stats_occupancyRateOverTime(
    $from: String!
    $to: String!
    $locationIds: [String]!
    $includeWeekends: Boolean!
    $absoluteValue: Boolean
    $perDay: Boolean
    $bypassLocationIds: Boolean
  ) {
    stats_occupancyRateOverTime(
      from: $from
      to: $to
      locationIds: $locationIds
      includeWeekends: $includeWeekends
      absoluteValue: $absoluteValue
      perDay: $perDay
      bypassLocationIds: $bypassLocationIds
    ) {
      date
      value
      booked
      total
    }
  }
`

interface PropsType {
  from: UTCDate
  to: UTCDate
  locationIds: string[]
  includeWeekends: boolean
  absoluteValue?: boolean
  perDay?: boolean
  bypassLocationIds?: boolean
}

interface ResponseType {
  date: string
  value: number
  booked: number
  total: number
}

export default function useOccupancyRateOverTime({
  from,
  to,
  locationIds,
  includeWeekends,
  absoluteValue,
  perDay,
  bypassLocationIds,
}: PropsType): {
  loading: boolean
  error?: ApolloError
  occupancyRateOverTime: ResponseType[]
} {
  const { loading, error, data } = useQuery(QUERY, {
    variables: {
      from: `${from.getTime()}`,
      to: `${to.getTime()}`,
      locationIds,
      includeWeekends,
      absoluteValue,
      perDay,
      bypassLocationIds,
    },
  })
  return {
    occupancyRateOverTime: data?.stats_occupancyRateOverTime || [],
    loading,
    error,
  }
}
