import LocationsList from "components/Collapses/LocationsCollapse/LocationsList"
import PageContent from "components/Layout/PageLayout/PageContent/PageContent"
import AdminPageTitle from "components/Layout/PageLayout/PageHeader/AdminPageTitle/AdminPageTitle"
import PageHeader from "components/Layout/PageLayout/PageHeader/PageHeader"
import PageLayout from "components/Layout/PageLayout/PageLayout"
import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import colors from "ui/colors"
import LocationTree from "utils/LocationTree"

import AddCategoryButton from "./AddCategoryButton/AddCategoryButton"
import AddLocationButton from "./AddLocationButton/AddLocationButton"
import AdminLocationsSearch from "./AdminLocationsSearch/AdminLocationsSearch"

const PageHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`

const MainTitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const TitleActions = styled.div`
  display: flex;
  gap: 16px;
  margin-top: auto;
  margin-left: auto;
`

const Body = styled.div`
  padding-bottom: 16px;
`

const ListLayout = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  background-color: ${colors.grey4};
  border: 1px solid ${colors.grey3};
  border-radius: 5px;
`

export default function AdminLocationsPage() {
  const t = useTranslate()

  const { user } = useUser()

  const [filteredLocations, setFilteredLocations] = useState<LocationTree[]>([])

  const breadcrumb = [
    {
      name: t("Admin"),
      path: "/admin",
    },
    {
      name: t("Spaces"),
      path: "/admin/locations",
    },
  ]

  if (user?.role !== "admin") return null

  return (
    <PageLayout>
      <PageHeader>
        <PageHeaderContent>
          <MainTitleSection>
            <AdminPageTitle
              title={t("Your working spaces")}
              breadcrumbs={breadcrumb}
            />
            <TitleActions>
              <AddCategoryButton />
              <AddLocationButton />
            </TitleActions>
          </MainTitleSection>
          <AdminLocationsSearch
            showArchivedLocations
            onNewResults={(results) => {
              setFilteredLocations(results)
            }}
            onSubmit={(results) => {
              setFilteredLocations(results)
            }}
            onClose={() => {
              setFilteredLocations([])
            }}
          />
        </PageHeaderContent>
      </PageHeader>
      <PageContent>
        <Body>
          <ListLayout>
            <LocationsList
              externalLocationsArray={
                filteredLocations.length > 0 ? filteredLocations : undefined
              }
              withActions
              showArchivedLocations
            />
          </ListLayout>
        </Body>
      </PageContent>
    </PageLayout>
  )
}
