import { gql } from "@apollo/client"

export const UserHybridRuleFragment = gql`
  fragment UserHybridRuleFragment on UserHybridRule {
    id
    name
    userId
    period
    maxSlots
    restrictedDays
    weeksScores {
      id
      weekIndex
      months
      homeSlotDelta
      concernedUserIds
      compliantUserIds
      days {
        date
        isRestricted
        isHomeSlot
      }
    }
    monthsScores {
      id
      monthIndex
      date
      homeSlotDelta
      concernedUserIds
      compliantUserIds
    }
  }
`

export const DefaultHybridRuleFragment = gql`
  fragment DefaultHybridRuleFragment on DefaultHybridRule {
    id
    name
    period
    maxSlots
    restrictedDays
    score {
      id
      from
      to
      concernedUserIds
      compliantUserIds
    }
  }
`

export const GroupHybridRuleFragment = gql`
  fragment GroupHybridRuleFragment on GroupHybridRule {
    id
    groupId
    userIds
    name
    period
    maxSlots
    restrictedDays
    weeksScores {
      id
      weekIndex
      months
      days {
        date
        isRestricted
      }
      concernedUserIds
      compliantUserIds
    }
    monthsScores {
      id
      monthIndex
      date
      concernedUserIds
      compliantUserIds
    }
  }
`

export const ManagerHybridRuleFragment = gql`
  fragment ManagerHybridRuleFragment on ManagerHybridRule {
    id
    managerId
    userIds
    name
    period
    maxSlots
    restrictedDays
    appliesToManager
    weeksScores {
      id
      weekIndex
      months
      days {
        date
        isRestricted
      }
      concernedUserIds
      compliantUserIds
    }
    monthsScores {
      id
      monthIndex
      date
      concernedUserIds
      compliantUserIds
    }
  }
`
