import useTranslate from "intl/useTranslate"
import { DropzoneArea } from "material-ui-dropzone"
import React from "react"
import styled from "styled-components"
import { Button, colors, ExternalLink, LU12, P14 } from "ui"
import { Warning } from "ui/icons"
import { Check, Download, MultiplePages } from "ui/icons"

const Container = styled.div`
  font-size: 18px !important;
  gap: 16px;

  .dropzone-area {
    border: 1px dashed ${colors.purple};
    height: 230px;
    background-color: ${colors.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 16px 0;
  }

  .dropzone-text {
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 400;
    max-width: 200px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  svg {
    stroke-width: 0.5px;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid ${colors.grey3};
    padding-top: 22px;
    margin-top: 12px;
  }

  .explanation-text {
    color: ${colors.grey1};
  }
`

const PreviewIcon = styled(MultiplePages)`
  height: 30px;
  width: 30px;
  stroke-width: 2px;
  border-radius: 4px;
  color: ${colors.purple};
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.24), 0px 2px 2px rgba(0, 0, 0, 0.12);
`

const AlertText = styled.div`
  margin-bottom: 8px;
  gap: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    stroke-width: 0.5px;
    height: 24px;
    width: 24px;
    color: ${colors.redAlert};
  }

  p {
    color: ${colors.redAlert};
  }
`

const ConfirmationText = styled.div`
  margin-bottom: 12px;
  gap: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    color: ${colors.green};
    stroke-width: 2px;
  }

  p {
    color: ${colors.green};
  }
`

interface PropsType {
  fileName: string
  handleFile: (files: File[] | undefined | null) => void
  error: string
  onPrevious: () => void
  onNext: () => void
}

export function CSVFileUpload({
  fileName,
  handleFile,
  error,
  onPrevious,
  onNext,
}: PropsType) {
  const t = useTranslate()

  const handlePreviewIcon = () => {
    return <PreviewIcon style={{ height: 40, width: 40 }} />
  }

  return (
    <>
      <Container>
        <P14 className="explanation-text">{t("what the csv must contain")}</P14>

        <DropzoneArea
          acceptedFiles={["text/csv"]}
          filesLimit={1}
          dropzoneText="Déposer un fichier csv ou Parcourez vos documents"
          onChange={handleFile}
          dropzoneParagraphClass="dropzone-text"
          getPreviewIcon={handlePreviewIcon}
          dropzoneClass="dropzone-area"
        />

        {fileName && (
          <ConfirmationText>
            <Check />
            <LU12>{t("Your document has been successfully imported")}</LU12>
          </ConfirmationText>
        )}
        {error && (
          <AlertText>
            <Warning />
            <LU12>{error}</LU12>
          </AlertText>
        )}
        <ExternalLink
          href={"/static/misc/template-import-users.csv"}
          rel="noopener noreferrer"
          download
        >
          <Button secondary icon={<Download />}>
            {t("download a CSV template")}
          </Button>
        </ExternalLink>

        <div className="buttons">
          <Button secondary onClick={onPrevious}>
            {t("Previous")}
          </Button>
          <Button disabled={!fileName || error !== ""} onClick={onNext}>
            {t("Next")}
          </Button>
        </div>
      </Container>
    </>
  )
}
