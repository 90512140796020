import useSetUserLocale from "graphql/settings/useSetUserLocale"
import useUser from "graphql/users/useUser"
import useTranslation from "intl/useTranslation"
import { useEffect } from "react"

/*

This will be removed at some point soon in the future
It just sets the user favorite locale if it's not set already

*/

export default function useTemporaryLocaleSetter() {
  const { user } = useUser()
  const { setUserLocale } = useSetUserLocale()
  const [locale] = useTranslation()
  useEffect(() => {
    if (locale.id && user && !user.flags.locale) {
      setUserLocale(locale.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, locale])
}
