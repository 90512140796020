import CreateGroupModal from "components/CreateGroupModal/CreateGroupModal"
import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { colors, P14 } from "ui"
import { Plus } from "ui/icons"

const Container = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  width: 100%;
  height: 25px;
  background-color: transparent;
  p {
    color: ${colors.grey1};
  }
  svg {
    height: 16px;
    width: 16px;
    color: ${colors.grey1};
    margin-right: 8px;
    margin-left: 17px;
    stroke-width: 2px;
  }
`

export default function CreateNewGroup() {
  const t = useTranslate()
  const { user: me } = useUser()
  const [openCreateGroupModal, setOpenCreateGroupModal] =
    useState<boolean>(false)

  if (!me) return <></>

  return (
    <>
      <Container onClick={() => setOpenCreateGroupModal(true)}>
        <Plus />
        <P14>{t("create a group")}</P14>
      </Container>
      <CreateGroupModal
        open={openCreateGroupModal}
        me={me}
        onClose={() => {
          setOpenCreateGroupModal(false)
        }}
      />
    </>
  )
}
