import EventDialog from "components/Events/EventDialog/EventDialog"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useUserEvents from "graphql/events/useUserEvents"
import useTranslate from "intl/useTranslate"
import EventsOfTheDayPopup from "pages/PlanningPage/components/CalendarDays/WeekDays/Day/EventsOfTheDayPopup/EventsOfTheDayPopup"
import React, { useState } from "react"
import { IntegrationEvent } from "types"
import { P14 } from "ui"
import { Celebration } from "ui/icons"
import filterEventsOfTheDay from "utils/events"
import LocalDate from "utils/LocalDate"
import UTCDate from "utils/UTCDate"

interface PropsType {
  date: UTCDate
}

export default function SelectedDate({ date }: PropsType) {
  const t = useTranslate()
  const { from, to } = usePlanningContext()
  const { userEvents } = useUserEvents({
    from,
    to,
  })

  const eventsOfTheDay = userEvents.filter(
    filterEventsOfTheDay(new LocalDate("DAY"))
  )

  const [openEventsDialog, setOpenEventsDialog] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState<
    IntegrationEvent | undefined
  >(undefined)

  const [popupAnchorEl, setPopupAchorEl] = useState<HTMLElement | null>(null)

  return (
    <div className="selected-date">
      <P14>{date.format("long", t)}</P14>
      {eventsOfTheDay.length > 0 && (
        <div
          className="event"
          onClick={() => setOpenEventsDialog(true)}
          ref={setPopupAchorEl}
        >
          <Celebration />
          <P14 color="grey1">{eventsOfTheDay.length}</P14>
        </div>
      )}
      {openEventsDialog && (
        <EventsOfTheDayPopup
          open={openEventsDialog}
          anchorEl={popupAnchorEl}
          events={eventsOfTheDay}
          onLeave={() => setOpenEventsDialog(false)}
          onSelectEvent={setSelectedEvent}
        />
      )}

      {selectedEvent && (
        <EventDialog
          open
          event={selectedEvent}
          onClose={() => {
            setSelectedEvent(undefined)
          }}
          isEditable={false}
          openEventToBeEditable={() => {
            //
          }}
        />
      )}
    </div>
  )
}
