import { DocumentNode, gql, useMutation } from "@apollo/client"
import { InputLocation } from "types"

import { LocationFragment } from "./fragments/locationFragment"

const ADD_LOCATION_MUTATION = gql`
  ${LocationFragment}
  mutation addLocation($location: LocationInput!, $parentId: ID) {
    addLocation(location: $location, parentId: $parentId) {
      ...LocationFragment
    }
  }
`

const UPDATE_LOCATION_MUTATION = gql`
  ${LocationFragment}
  mutation updateLocation($id: ID!, $location: LocationInput!, $parentId: ID) {
    updateLocation(id: $id, location: $location, parentId: $parentId) {
      ...LocationFragment
    }
  }
`

const REMOVE_LOCATION_MUTATION = gql`
  ${LocationFragment}
  mutation removeLocation($id: ID!) {
    removeLocation(id: $id) {
      ...LocationFragment
    }
  }
`

function useLocationMutation(mutation: DocumentNode, key: string) {
  const [mutate] = useMutation(mutation, {
    update(cache, { data }) {
      cache.modify({
        fields: {
          locations() {
            return data[key]
          },
        },
      })
    },
  })
  return mutate
}

export default function useLocationMutations() {
  const mutateAdd = useLocationMutation(ADD_LOCATION_MUTATION, "addLocation")
  const addLocation = async (
    location: InputLocation,
    parentId: string | null
  ) => {
    try {
      await mutateAdd({ variables: { location, parentId } })
    } catch (e) {
      console.error(e.message)
    }
  }

  const mutateUpdate = useLocationMutation(
    UPDATE_LOCATION_MUTATION,
    "updateLocation"
  )
  const updateLocation = async (
    id: string,
    location: InputLocation,
    parentId: string | null
  ) => {
    try {
      await mutateUpdate({ variables: { id, location, parentId } })
    } catch (e) {
      console.error(e.message)
    }
  }

  const mutateRemove = useLocationMutation(
    REMOVE_LOCATION_MUTATION,
    "removeLocation"
  )
  const removeLocation = async (id: string) => {
    try {
      await mutateRemove({ variables: { id } })
    } catch (e) {
      console.error(e.message)
    }
  }

  return {
    addLocation,
    updateLocation,
    removeLocation,
  }
}
