import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function Cancel({ strokeWidth, stroke, ...rest }: SvgIconProps) {
  return (
    <SvgIcon
      strokeWidth={strokeWidth ?? 1.5}
      stroke={stroke ? stroke : "currentColor"}
      {...rest}
    >
      <path
        d="M12.0004 12L17.243 17.2426M6.75781 17.2426L12.0004 12L6.75781 17.2426ZM17.243 6.75732L12.0004 12L17.243 6.75732ZM12.0004 12L6.75781 6.75732L12.0004 12Z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}
