import useRevokeUserIntegration from "graphql/integrations/useRevokeUserIntegration"
import useToken from "hooks/useToken"
import useTranslate from "intl/useTranslate"
import React from "react"
import { UserIntegration } from "types"
import { L12, P14Bold } from "ui"
import { Slack } from "ui/icons"

interface PropsType {
  integration?: UserIntegration
}

export default function SlackIntegration({ integration }: PropsType) {
  const t = useTranslate()
  const token = useToken()
  const revoke = useRevokeUserIntegration()
  if (!token) return null
  return (
    <li className="item">
      <div className="top-part">
        <Slack />
        <P14Bold className="title">Slack</P14Bold>

        <div className="status">
          <P14Bold color={integration ? "green" : "grey3"}>
            {t("Subscribed")}
          </P14Bold>
        </div>
      </div>

      <L12 className="description">
        {t("slack improve offishall experience")}
      </L12>

      <div className="description">
        {integration ? (
          <button
            className="btn-small"
            onClick={() => {
              revoke({ name: integration.name })
            }}
          >
            {t("revoke access")}
          </button>
        ) : (
          <button
            className="btn-small"
            onClick={() => {
              location.href = `${process.env.REACT_APP_API}/slack/install?token=${token}`
            }}
          >
            {t("Sign in")}
          </button>
        )}
        {integration && (
          <button
            className="btn-small"
            onClick={() => {
              location.href = `${process.env.REACT_APP_API}/slack/install?token=${token}`
            }}
          >
            {t("refresh access")}
          </button>
        )}
      </div>
    </li>
  )
}
