import { ApolloError, gql, useQuery } from "@apollo/client"
import { BulkSlotRule } from "types"
import UTCDate from "utils/UTCDate"

import { BulkSlotRuleFragment } from "./fragment/bulkSlotRuleFragment"

const GET_BULK_SLOT_RULES_QUERY = gql`
  ${BulkSlotRuleFragment}
  query getBulkSlotRules {
    bulkSlotRules {
      ...BulkSlotRuleFragment
    }
  }
`

export default function useBulkSlotRules(): {
  loading: boolean
  error?: ApolloError
  rules: BulkSlotRule[]
} {
  const { loading, error, data } = useQuery(GET_BULK_SLOT_RULES_QUERY)
  const rules =
    data?.bulkSlotRules?.map((rule: any) => ({
      ...rule,
      from: rule.from && new UTCDate(new Date(rule.from), "DAY", false),
      to: rule.to && new UTCDate(new Date(rule.to), "DAY", false),
    })) || []
  return { rules, loading, error }
}
