import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import DownloadCsvButton from "pages/StatisticsPage/components/DownloadCsvButton"
import React, { useCallback, useMemo, useState } from "react"
import { Bar } from "react-chartjs-2"
import { GroupSimple, UserBase } from "types"
import { colors } from "ui"
import getColorFromStatus from "utils/getColorFromStatus"
import getUserDisplayName from "utils/getUserDisplayName"
import UTCDate from "utils/UTCDate"

import WorkingModesPerDay from "./WorkingModesPerDayBar/WorkingModesPerDayBar"

interface PropsType {
  users: UserBase[]
  groups: GroupSimple[]
  from: UTCDate
  to: UTCDate
  includeWeekends: boolean
  showDownloadCSV?: boolean
}

export default function WeekDaysChart({
  users,
  groups,
  from,
  to,
  includeWeekends,
  showDownloadCSV = true,
}: PropsType) {
  const t = useTranslate()
  const { user: me } = useUser()

  const [workingModesPerDay, setWorkingModesPerDay] = useState<
    {
      day: string
      workingMode: string
      value: number
    }[]
  >([])

  const daysAsLabels = useMemo(() => {
    const weekDays =
      workingModesPerDay.length > 0
        ? Array.from(new Set(workingModesPerDay.map((wm) => wm.day)))
        : []
    if (weekDays.length > 5 && weekDays[0] === "Sunday") {
      const [sunday, ...rest] = weekDays
      return [...rest, sunday]
    }
    return weekDays
  }, [workingModesPerDay])

  const workindModes = useMemo(
    () => ["home", "office", "else", "off", "ooc", "null"],
    []
  )

  const getCsv = useCallback(() => {
    if (me === null) return Promise.resolve(undefined)
    const headers = [
      "email",
      "name",
      "type",
      "working_mode",
      ...daysAsLabels,
    ].join(",")
    const rows: string[] = []
    for (const u of users) {
      for (const wm of workindModes) {
        rows.push(
          [
            u.email,
            getUserDisplayName(u, me.company.flags),
            "user",
            wm,
            ...daysAsLabels.map((d) =>
              workingModesPerDay
                .filter((wmpd) => wmpd.day === d && wmpd.workingMode === wm)
                .map((d) => d.value)
            ),
          ].join(",")
        )
      }
    }

    for (const g of groups) {
      for (const wm of workindModes) {
        rows.push(
          [
            "",
            g.name,
            "group",
            wm,
            ...daysAsLabels.map((d) =>
              workingModesPerDay
                .filter((wmpd) => wmpd.day === d && wmpd.workingMode === wm)
                .map((d) => d.value)
            ),
          ].join(",")
        )
      }
    }

    return Promise.resolve(`${headers}\n${rows.join("\n")}`)
  }, [users, groups, me, daysAsLabels, workingModesPerDay, workindModes])

  const datasets = [
    ...workindModes.map((wm) => ({
      label: wm,
      data: daysAsLabels
        .map((d) =>
          workingModesPerDay
            .filter((wmpd) => wmpd.day === d && wmpd.workingMode === wm)
            .map((d) => d.value)
        )
        .reduce((prev, curr) => prev.concat(curr), []),
      backgroundColor:
        wm !== "null" ? getColorFromStatus(wm) : colors.nullStats,
      maxBarThickness: 32,
      stack: 0,
    })),
  ]

  const data = {
    labels: daysAsLabels.map((day) => t(day)),
    datasets: groups.length > 0 || users.length > 0 ? datasets : [],
  }
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          id: "xAxis1",
          type: "category",
          ticks: {
            display: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          gridLines: {
            display: true,
          },
          ticks: {
            callback: function (value: number) {
              return Math.floor(100 * value) + "%"
            },
          },
        },
      ],
    },
    tooltips: {
      mode: "nearest",
      callbacks: {
        label: function (tooltipItem: any) {
          return `${(100 * tooltipItem.yLabel).toFixed(2)}%`
        },
      },
    },
    legend: {
      labels: {
        usePointStyle: true,
        boxWidth: 7,
        filter: (legendItem: any) => {
          legendItem.text = t(legendItem.text)

          return true
        },
      },
    },
    onHover: (event: any, elements: any) => {
      event.target.style.cursor = "default"
      if (elements.length > 0) {
        event.target.style.cursor = "pointer"
      }
    },
  }

  return (
    <>
      {showDownloadCSV && (
        <DownloadCsvButton fileName="week-days.csv" getCsv={getCsv} />
      )}
      <WorkingModesPerDay
        users={users}
        groups={groups}
        from={from}
        to={to}
        includeWeekends={includeWeekends}
        updateWorkingModesPerDay={(wm) => setWorkingModesPerDay(wm)}
      />
      <Bar data={data} options={options} />
    </>
  )
}
