import { gql, useMutation } from "@apollo/client"
import { Button } from "@mui/material"
import useTranslate from "intl/useTranslate"
import React, { useCallback, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components/macro"
import { colors, H3, mediaQueries, P16 } from "ui"
import { Cancel } from "ui/icons"
import { verifyEmail } from "utils/firebase"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 480px;

  @media ${mediaQueries.isMobile} {
    width: 90%;
    margin-left: 10px;
    margin-right: 10px;
  }

  h3 {
    color: ${colors.purple};
  }

  .texts-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    gap: 12px;

    .error-text  {
      display: flex;
      flex-direction: row;
      align-items: center;
      svg {
        color: ${colors.redAlert};
      }
      p {
        margin-left: 12px;
        color: ${colors.redAlert};
      }
    }
  }
`

const VALIDATE_ACCOUNT = gql`
  mutation ValidateAccount(
    $token: String
    $error: String
    $mode: String
    $actionCode: String
  ) {
    validateAccount(
      token: $token
      error: $error
      mode: $mode
      actionCode: $actionCode
    )
  }
`

type ValidateParams = {
  token?: string | null
  error?: string | null
  mode?: string | null
  actionCode?: string | null
}

export default function VerifyEmail({
  actionCode,
  token,
}: {
  actionCode: string
  token: string
}) {
  const t = useTranslate()
  const [verifying, setVerifying] = useState(false)
  const [verified, setVerified] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [validateAccount] = useMutation(VALIDATE_ACCOUNT)
  // "safe" here means that we ignore the errors that could
  // occur when calling the validateAccount() mutation.
  // The mutation is only here to notify the server that
  // the user has verified his email address.
  // It doesn't prevent the user from accessing the app if it fails.
  const safeValidateAccount = useCallback(
    async (params: ValidateParams) => {
      try {
        await validateAccount({ variables: params })
      } catch (err) {
        console.error("Failed call to validateAccount", err)
      }
    },
    [validateAccount]
  )

  useEffect(() => {
    const verify = async () => {
      try {
        setVerifying(true)
        await verifyEmail(actionCode)
        await safeValidateAccount({ token })
        setVerifying(false)
        setVerified(true)
      } catch (err) {
        console.error(err)
        setError(err.message)
        setVerifying(false)
        await safeValidateAccount({
          token,
          error: err.message,
          actionCode,
        })
      }
    }
    verify()
  }, [safeValidateAccount, actionCode, token])
  const continueToNextPage = useCallback(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const continueUrl =
      urlParams.get("continueUrl") ||
      urlParams.get("amp;continueUrl") ||
      window.location.origin
    window.location.href = continueUrl
  }, [])
  return (
    <Container>
      <Helmet title={t("Email validation")} />
      <H3>{t("Email validation")}</H3>
      <div className="texts-container">
        {error && (
          <div className="error-text">
            <Cancel />
            <P16>{t("Failed to verify email.")}</P16>
          </div>
        )}
        {verifying && <P16>{t("Verifying your email now...")}</P16>}
        {verified && (
          <>
            <P16>{t("Your email has been successfully verified.")}</P16>
            <Button onClick={continueToNextPage}>
              {t("Continue to sign-in")}
            </Button>
          </>
        )}
      </div>
    </Container>
  )
}
