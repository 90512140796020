import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { colors, L12, mediaQueries } from "ui"
import { Group as GroupIcon } from "ui/icons"

const Badge = styled.div`
  position: absolute;
  top: -5px;

  height: 16px;
  border-radius: 100vh;
  background: ${colors.purple};
  transform: translateX(50%);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;

  &.short-badge {
    left: 0;
    right: 0;
    transform: translateX(50%);
  }

  &.long-badge {
    left: -20%;
    right: -20%;
    transform: translateX(35%);
  }

  p {
    color: ${colors.white};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0 4px;
  }

  @media ${mediaQueries.isMobile} {
    left: 10%;
    right: 10%;
    transform: translateX(60%);

    svg {
      width: 16px;
      height: 16px;
      stroke: ${colors.white};
    }
  }
`

export default function TeamDayBadge() {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()

  const getClassName = () => {
    if (isMobile) return ""
    const title = t("team day")
    if (title === "team day") return "short-badge"
    return "long-badge"
  }

  return (
    <Badge className={getClassName()}>
      {isMobile ? <GroupIcon /> : <L12>{t("team day")}</L12>}
    </Badge>
  )
}
