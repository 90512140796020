import AdvancedSearchPopUp from "components/AdvancedSearchPopUp/AdvancedSearchPopUp"
import PageContent from "components/Layout/PageLayout/PageContent/PageContent"
import PageHeader from "components/Layout/PageLayout/PageHeader/PageHeader"
import MobileNavBack from "components/Layout/PageLayout/PageHeader/PageTitle/MobileNavBack/MobileNavBack"
import Search from "components/Search/Search"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { ChipButton, Dialog } from "ui"

const StyledChipButton = styled(ChipButton)`
  width: 100%;
  display: flex;
  justify-content: center;
`

interface PropsType {
  open: boolean
  mobileParentContainer: HTMLElement | null
  setMobileParentContainer: (el: HTMLElement | null) => void
  setOpen: (open: boolean) => void
}

export default function SearchDialog({
  open,
  mobileParentContainer,
  setMobileParentContainer,
  setOpen,
}: PropsType) {
  const t = useTranslate()
  const [openSearchResults, setOpenSearchResults] = useState(false)

  return (
    <Dialog open={open} fullscreen>
      <PageHeader ref={setMobileParentContainer}>
        <MobileNavBack onClose={() => setOpen(false)} />
        <Search
          mobileParentContainer={mobileParentContainer}
          onSubmit={() => {
            setOpenSearchResults(false)
            setOpen(false)
          }}
          forceBringToTop
        />
      </PageHeader>
      <PageContent>
        <StyledChipButton onClick={() => setOpenSearchResults(true)}>
          {t("Advanced Search")}
        </StyledChipButton>
        {openSearchResults && (
          <AdvancedSearchPopUp
            open={openSearchResults}
            onClose={() => {
              setOpenSearchResults(false)
            }}
          />
        )}
      </PageContent>
    </Dialog>
  )
}
