import { useGroupHybridRuleScore } from "hooks/useHybridRuleScore"
import React, { useEffect } from "react"
import { GroupHybridRule } from "types"
import UTCDate from "utils/UTCDate"

import { LoadedScoreEventDetail, LoadingScore } from "../../types"

export default function GroupScoreNullComponent({
  score,
  addScoreAsLoaded,
}: {
  score: LoadingScore<GroupHybridRule>
  addScoreAsLoaded: (detail: LoadedScoreEventDetail) => void
}) {
  const {
    score: groupScore,
    loading,
    error,
  } = useGroupHybridRuleScore(new UTCDate(), score.rule, score.rule.groupId)

  useEffect(() => {
    if (error)
      return addScoreAsLoaded({
        id: score.rule.id,
        concernedUserIds: [],
        compliantUserIds: [],
      })
    if (!loading && groupScore)
      addScoreAsLoaded({
        id: groupScore.id,
        concernedUserIds: groupScore.concernedUserIds,
        compliantUserIds: groupScore.compliantUserIds,
      })
  }, [loading, error, addScoreAsLoaded, groupScore, score])
  return <></>
}
