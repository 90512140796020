import DeleteButtonWithConfirmation from "components/ButtonsWithConfirmation/DeleteButtonWithConfirmation/DeleteButtonWithConfirmation"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React from "react"
import { User } from "types"
import { P16 } from "ui"

interface PropsType {
  previousManager: User | null
  newManager: User | null
  onClick: () => void
  onConfirm: () => void
}

export default function DeleteManagerButton({
  previousManager,
  newManager,
  onClick,
  onConfirm,
}: PropsType) {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()

  return (
    <DeleteButtonWithConfirmation
      dialogTitle={t("Remove manager")}
      buttonTitle={t("Remove manager")}
      fullWidth={isMobile}
      onConfirm={onConfirm}
      onClick={onClick}
    >
      <P16>{t("delete manager confirmation")}</P16>

      <P16>
        {t("previous manager")}:{" "}
        <em
          style={{
            textDecoration: "line-through",
          }}
        >
          {previousManager?.name ?? "null"}
        </em>
      </P16>

      <P16>
        {t("new manager")}: <strong>{newManager?.name ?? "null"}</strong>
      </P16>
    </DeleteButtonWithConfirmation>
  )
}
