import React from "react"
import styled from "styled-components"
import { UTCSlot } from "types"
import { Gauge0, Gauge25, Gauge50, Gauge75 } from "ui/icons"

const SharedContainer = styled.div`
  display: flex;
  width: 70px;
  min-width: 70px;
  height: 70px;
`

const GifContainer = styled(SharedContainer)``

const SvgContainer = styled(SharedContainer)`
  svg {
    width: 100%;
    height: 100%;
  }
`

interface PropsType {
  weekSlotsLength: number
  nullSlots: UTCSlot[]
  loading: boolean
}

export default function GaugeIcon({
  weekSlotsLength,
  nullSlots,
  loading,
}: PropsType) {
  const viewBox = "-12 -12 48 48"

  if (loading)
    return (
      <SvgContainer>
        <Gauge0 viewBox={viewBox} />
      </SvgContainer>
    )
  if (nullSlots.length === 0) {
    return (
      <GifContainer>
        <img className="" src="/static/img/misc_gif/week-completed.gif" />
      </GifContainer>
    )
  }
  if (nullSlots.length / weekSlotsLength < 0.4)
    return (
      <SvgContainer>
        <Gauge75 viewBox={viewBox} />
      </SvgContainer>
    )
  if (nullSlots.length / weekSlotsLength < 0.6)
    return (
      <SvgContainer>
        <Gauge50 viewBox={viewBox} />
      </SvgContainer>
    )
  if (nullSlots.length / weekSlotsLength < 0.8)
    return (
      <SvgContainer>
        <Gauge25 viewBox={viewBox} />
      </SvgContainer>
    )
  return (
    <SvgContainer>
      <Gauge0 viewBox={viewBox} />
    </SvgContainer>
  )
}
