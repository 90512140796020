import useTranslate from "intl/useTranslate"
import React, { useRef, useState } from "react"
import styled from "styled-components"
import { colors, IconButton, P14, PopperMenu, transitions } from "ui"
import { MidlineHorizontalEllispsis } from "ui/icons"

const PopperMenuList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  cursor: pointer;
`

const PopperMenuItem = styled(P14)`
  padding: 6px 16px;
  min-width: 136px;
  &:hover {
    background: ${colors.grey4};
  }
  &.error {
    color: ${colors.red};
  }
  transition: ${transitions.fade};
`

interface PropsType {
  hideDelete?: boolean
  setSelectedUserOrGroupHybridRule: () => void
  deleteHybridWordkRule: () => void
}

export default function ActionButton({
  hideDelete = false,
  setSelectedUserOrGroupHybridRule,
  deleteHybridWordkRule,
}: PropsType) {
  const t = useTranslate()
  const [openActions, setOpenActions] = useState(false)
  const actionAnchorEl = useRef<HTMLDivElement>(null)

  return (
    <>
      <div ref={actionAnchorEl}>
        <IconButton
          icon={<MidlineHorizontalEllispsis />}
          onClick={() => setOpenActions(true)}
        />
      </div>
      <PopperMenu
        open={openActions}
        anchorEl={actionAnchorEl.current}
        onClose={() => setOpenActions(false)}
      >
        <PopperMenuList>
          <li>
            <PopperMenuItem
              onClick={() => {
                setSelectedUserOrGroupHybridRule()
                setOpenActions(false)
              }}
            >
              {t("Edit")}
            </PopperMenuItem>
          </li>
          {!hideDelete && (
            <li>
              <PopperMenuItem
                className="error"
                onClick={() => {
                  deleteHybridWordkRule()
                  setOpenActions(false)
                }}
              >
                {t("Delete")}
              </PopperMenuItem>
            </li>
          )}
        </PopperMenuList>
      </PopperMenu>
    </>
  )
}
