import { gql, useMutation } from "@apollo/client"
import { GET_USER_QUERY } from "graphql/users/useUser"
import useCustomErrorToast from "hooks/useCustomErrorToast"
import { useState } from "react"

type OnboardingLocation = {
  name: string
  capacity: number
}

const START_DEMO_MUTATION = gql`
  mutation startDemo(
    $userEmails: [String]!
    $locations: [OnboardingLocationInputType]!
    $locale: String!
  ) {
    startDemo(userEmails: $userEmails, locations: $locations, locale: $locale)
  }
`
export default function useStartDemo() {
  const [loading, setLoading] = useState(false)
  const { addToastError } = useCustomErrorToast()
  const [mutate] = useMutation(START_DEMO_MUTATION, {
    refetchQueries: [
      {
        query: GET_USER_QUERY,
        variables: { user: null },
      },
    ],
  })
  const startDemo = async (
    userEmails: string[],
    locations: OnboardingLocation[],
    locale: string
  ) => {
    setLoading(true)
    try {
      await mutate({
        variables: { userEmails, locations, locale },
      })
    } catch (e) {
      console.error(e.message)
      addToastError({ message: `generic error` })
    } finally {
      setLoading(false)
    }
  }

  return {
    startDemo,
    loading,
  }
}
