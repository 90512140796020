import useTranslate from "intl/useTranslate"
import React from "react"
import { NumberField } from "ui"

interface PropsType {
  value: number
  onChange: (int: number) => void
}

export default function CapacityFieldset({ value, onChange }: PropsType) {
  const t = useTranslate()

  return (
    <NumberField
      label={t("Total capacity")}
      value={value}
      handleChange={onChange}
      min={0}
    />
  )
}
