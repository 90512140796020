import { ApolloError, gql, useQuery } from "@apollo/client"
import { User } from "types"

import { UserFragment } from "./fragments/userFragment"

export const GET_USER_QUERY = gql`
  ${UserFragment}
  query getUser {
    user {
      ...UserFragment
    }
  }
`

export default function useUser(): {
  loading: boolean
  error?: ApolloError
  user: User | null
} {
  const { loading, error, data } = useQuery(GET_USER_QUERY)
  return { user: data?.user || null, loading, error }
}
