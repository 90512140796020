import React from "react"
import styled from "styled-components"
import { SearchResult } from "types"
import { colors, P16, transitions } from "ui"

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Item = styled.li`
  display: flex;
  flex-direction: column;

  > p {
    padding: 0 16px;
  }
`

const ItemContentContainer = styled.div`
  cursor: pointer;
  padding: 2px 16px;
  background: hsla(0, 0%, 0%, 0);

  &:hover {
    background: hsla(0, 0%, 0%, 0.04);
  }

  transition: ${transitions.fade};
`

const Header = styled.h6`
  padding: 0 16px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${colors.grey2};
`

// const UserNameAndEmail = styled.span`
//   display: flex;
//   justify-content: space-between;
//   width: 100%;

//   em {
//     color: ${colors.grey1};
//     font-style: normal;
//     font-size: 0.85em;
//   }
// `

const Separator = styled.hr`
  width: 100%;
  border: none;
  border-bottom: solid 1px ${colors.grey3};
`

interface PropsType {
  searchString: string
  searchResults: SearchResult[]
  updateManagerEmail: (email: string) => void
}

export default function RawEmailOrExistingUserPicker({
  searchString,
  updateManagerEmail,
}: PropsType) {
  function validateEmail(email: string) {
    const re = /\S+@\S+\.\S+/
    return re.test(email)
  }

  // const perfectMatch = searchResults.find((user) => user.email === searchString)

  if (!validateEmail(searchString)) return <></>

  return (
    <li>
      <List>
        <Item>
          <Header>Use this email</Header>
          <ItemContentContainer
            onClick={() => updateManagerEmail(searchString)}
          >
            <P16>{searchString}</P16>
          </ItemContentContainer>
        </Item>
        {/* {perfectMatch !== undefined && (
          <Item>
            <Header>Pick this perfect match</Header>
            <ItemContentContainer
              onClick={() => {
                const email = perfectMatch.email ?? searchString
                updateManagerEmail(email)
              }}
            >
              <IconText
                fullWidth
                icon={<UserAvatarFromId id={perfectMatch.id} />}
              >
                <UserNameAndEmail>
                  <span>{perfectMatch.name}</span>
                  <em>{perfectMatch.email}</em>
                </UserNameAndEmail>
              </IconText>
            </ItemContentContainer>
          </Item>
        )} */}
        <Separator />
      </List>
    </li>
  )
}
