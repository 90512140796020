import React, { useEffect, useState } from "react"
import { CompanySettingNumber } from "types"
import { NumberField } from "ui"

interface PropsType {
  setting: CompanySettingNumber
  onChange: (value: number) => void
}

export default function ManageSettingNumber({ setting, onChange }: PropsType) {
  const [value, setValue] = useState(setting.valueAsNumber)

  useEffect(() => {
    if (value !== setting.valueAsNumber) {
      onChange(value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <NumberField
      value={value}
      handleChange={(int) => {
        if (int >= setting.minValue && int <= setting.maxValue) {
          setValue(int)
        }
      }}
      min={setting.minValue ?? undefined}
      max={setting.maxValue ?? undefined}
    />
  )
}
