import useGroupsStats from "graphql/stats/useGroupsStats"
import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import DownloadCsvButton from "pages/StatisticsPage/components/DownloadCsvButton"
import React, { useEffect, useState } from "react"
import styled from "styled-components/macro"
import { Button } from "ui"
import UTCDate, { UTCDateClampType } from "utils/UTCDate"

import DatePicker from "../components/DatePicker"
import AdminGroupsStatsTable from "./components/AdminGroupsStatsTable"
interface TablePropsType {
  maxHeight?: number
}

const Layout = styled.div<TablePropsType>`
  display: grid;
  grid-template-rows: auto 1fr auto;
  ${({ maxHeight }) => `height: calc(100vh - ${maxHeight}px);`}
`

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

const Footer = styled.div`
  padding-top: 8px;
`

const Table = styled.div<TablePropsType>`
  overflow-y: scroll;
`

const Actions = styled.div`
  padding-top: 32px;
`

const RESULTS_PER_PAGE = 15

interface PropsType {
  from: UTCDate
  to: UTCDate
  selectedPeriod: UTCDateClampType | "personalized"
  pageHeaderHeight: {
    header?: number | undefined
    tabsHeader?: number | undefined
  }
  editSelectedPeriod: (string: UTCDateClampType | "personalized") => void
  editFromDate: (date: UTCDate) => void
  editToDate: (date: UTCDate) => void
}

export default function GroupsStats({
  from,
  to,
  selectedPeriod,
  pageHeaderHeight,
  editSelectedPeriod,
  editFromDate,
  editToDate,
}: PropsType) {
  const t = useTranslate()
  const { user } = useUser()

  const maxHeight =
    pageHeaderHeight.header !== undefined &&
    pageHeaderHeight.tabsHeader !== undefined
      ? pageHeaderHeight.header + pageHeaderHeight.tabsHeader
      : undefined

  const [orderBy, setOrderBy] = useState("order by name")

  const {
    loading,
    groupsStats,
    start,
    fetchMore,
    canFetchMore,
    hasSearched,
    getCSV,
  } = useGroupsStats(RESULTS_PER_PAGE)

  useEffect(() => {
    start(from, to, orderBy)
  }, [start, orderBy, from, to])

  if (user?.role !== "admin") return null
  return (
    <Layout maxHeight={maxHeight}>
      <Header>
        <DatePicker
          from={from}
          to={to}
          selectedPeriod={selectedPeriod}
          enableWeekends={false}
          editSelectedPeriod={editSelectedPeriod}
          editFromDate={editFromDate}
          editToDate={editToDate}
        />
        <Actions>
          <DownloadCsvButton
            fileName={`admin-groups-stats-${
              from ? from.format("YYYY-MM-DD") : ""
            }-${to ? to.format("YYYY-MM-DD") : ""}.csv`}
            getCsv={getCSV}
          />
        </Actions>
      </Header>
      <Table>
        {hasSearched && (
          <AdminGroupsStatsTable
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            groupsStats={groupsStats}
            fetchMore={fetchMore}
            canFetchMore={canFetchMore}
            loading={loading}
          />
        )}
      </Table>
      <Footer>
        {canFetchMore && !loading && (
          <Button
            secondary
            disabled={loading || !canFetchMore}
            onClick={() => {
              if (canFetchMore) fetchMore()
            }}
          >
            {t("View more results")}
          </Button>
        )}
      </Footer>
    </Layout>
  )
}
