import { gql, useApolloClient } from "@apollo/client"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useTranslate from "intl/useTranslate"

const QUERY = gql`
  query nudge($userId: ID!) {
    nudge(userId: $userId)
  }
`

export default function useNudge(): (userId: string) => Promise<void> {
  const client = useApolloClient()
  const { addToast } = useToasts()
  const t = useTranslate()
  return async (userId: string) => {
    try {
      await client.query({
        query: QUERY,
        variables: {
          userId,
        },
        fetchPolicy: "no-cache",
      })
      addToast(t("nudge sent"), { appearance: "success" })
    } catch (error) {
      addToast(error.message, { appearance: "error" })
      console.error(error)
      return
    }
  }
}
