import useMenu from "hooks/useMenu"
import React from "react"
import styled from "styled-components"
import { Link, UnstyledButton } from "ui"
import { OffishallLogo } from "ui/assets"

const Button = styled(UnstyledButton)`
  padding: 24px;

  svg {
    width: 100%;
  }
`

export function MenuLogoButton() {
  const { handleLogEvent } = useMenu()

  const getLogo = () => <OffishallLogo />

  return (
    <Link naked to="/">
      <Button
        onClick={() => {
          handleLogEvent("logo-click")
        }}
      >
        {getLogo()}
      </Button>
    </Link>
  )
}
