import PageHeader from "components/Layout/PageLayout/PageHeader/PageHeader"
import MobileNavBack from "components/Layout/PageLayout/PageHeader/PageTitle/MobileNavBack/MobileNavBack"
import PageTitle from "components/Layout/PageLayout/PageHeader/PageTitle/PageTitle"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import EmailInputText from "pages/AdminPage/UsersPage/NewEmployees/EmailInputText/EmailInputText"
import React, { useRef, useState } from "react"
import styled from "styled-components"
import { Button, Dialog, L12, RemovableChipButton, Textarea } from "ui"
import isEmailValid from "utils/isEmailValid"

const Form = styled.div`
  padding: 24px;
`

const EmailsContainer = styled.div`
  display: flex;
  margin-top: 8px;
  margin-bottom: 12px;
  gap: 8px;
  flex-wrap: wrap;
`

interface PropsType {
  open: boolean
  onClose: () => void
  inviteGuestsMessage: string
  guestsEmails: string[]
  updateGuestsEmails: (emails: string[]) => void
  updateInviteGuestsMessage: (description: string) => void
}

export default function InviteDialog({
  open,
  onClose,
  inviteGuestsMessage,
  guestsEmails,
  updateGuestsEmails,
  updateInviteGuestsMessage,
}: PropsType) {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()

  const userEmailInputRef = useRef<HTMLInputElement>(null)
  const [inputEmail, setInputEmail] = useState<string>("")
  const [disabledAddEmail, setDisabledAddEmail] = useState(false)
  const [duplicatedEmailError, setDuplicatedEmailError] = useState<
    string | undefined
  >(undefined)

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setInputEmail("")
          setDisabledAddEmail(false)
          onClose()
        }}
        title={isMobile ? undefined : t("guests outside of offishall")}
        fullscreen={isMobile}
        rightButton={
          <Button
            disabled={disabledAddEmail}
            onClick={() => {
              if (guestsEmails.length === 0 && inputEmail === "")
                updateInviteGuestsMessage("")

              if (!guestsEmails.find((email) => email === inputEmail)) {
                updateGuestsEmails(
                  isEmailValid(inputEmail)
                    ? [inputEmail, ...guestsEmails]
                    : guestsEmails
                )
              }
              setInputEmail("")
              onClose()
            }}
            fullWidth={isMobile}
          >
            {t("Confirm")}
          </Button>
        }
      >
        {isMobile && (
          <PageHeader>
            <MobileNavBack onClose={onClose} />
            <PageTitle title={t("details")} />
          </PageHeader>
        )}
        <Form>
          <EmailInputText
            inputRef={userEmailInputRef}
            label={t("guest list")}
            value={inputEmail}
            placeholder={t("write here emails from your guests")}
            initialValue={inputEmail}
            autoFocus
            onChange={(str: string, error?: boolean) => {
              setInputEmail(str)
              setDisabledAddEmail(error === true)
            }}
            externalError={duplicatedEmailError}
            resetExternalError={() => setDuplicatedEmailError(undefined)}
            onAddEmail={() => {
              if (guestsEmails.find((email) => email === inputEmail)) {
                return setDuplicatedEmailError(t("email already present"))
              }
              if (disabledAddEmail === true) userEmailInputRef.current?.blur()
              if (!isEmailValid(inputEmail)) return
              updateGuestsEmails([
                ...guestsEmails.filter((e) => e !== inputEmail),
                inputEmail,
              ])
              setInputEmail("")
            }}
          />

          <EmailsContainer>
            {guestsEmails.map((email, index) => (
              <RemovableChipButton
                key={index}
                onRemove={() => {
                  updateGuestsEmails(guestsEmails.filter((e) => e !== email))
                }}
              >
                {email}
              </RemovableChipButton>
            ))}
          </EmailsContainer>
          <Textarea
            label={t("description")}
            value={inviteGuestsMessage}
            handleChange={updateInviteGuestsMessage}
            placeholder={t("guest description placeholder")}
          />
          <L12>{t("sent description in email message")}</L12>
        </Form>
      </Dialog>
    </>
  )
}
