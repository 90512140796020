import { days } from "utils/LocalDate"
import UTCDate from "utils/UTCDate"

import useBookingMaxDate from "./useBookingMaxDate"
import useCompanyFlag from "./useCompanyFlag"

export default function useBookingSlotReminderDate(to: UTCDate) {
  const { value: limitSlotInput }: { enabled: boolean; value?: number } =
    useCompanyFlag("limitSlotInput", { enabled: false, value: undefined })
  const { value: reminderDay }: { value: string; enabled: boolean } =
    useCompanyFlag("weeklyReminder", {
      value: "Thursday",
      enabled: false,
    })
  const lastBookableSunday = useBookingMaxDate()

  // to is bookable (which means from being < to is also valid)
  if (to.getTime() <= lastBookableSunday.getTime()) return

  const nextTimeToIsBookable = new UTCDate(to, "WEEK-MONDAY")
  if (limitSlotInput) nextTimeToIsBookable.shift("WEEK", -1 * limitSlotInput)
  else nextTimeToIsBookable.shift("YEAR", -1)

  const reminderDate = new UTCDate(nextTimeToIsBookable)
  const reminderDayWeekIndex = days.indexOf(reminderDay)
  if (reminderDayWeekIndex > 0) {
    const shiftingAmount =
      reminderDayWeekIndex - 1 < 0 ? 6 : reminderDayWeekIndex - 1
    reminderDate.shift("DAY", shiftingAmount)
  }
  return reminderDate
}
