import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useUser from "graphql/users/useUser"
import useCompanyFlags from "hooks/useCompanyFlags"
import useTranslate from "intl/useTranslate"
import NewTeamDayDialog from "pages/MyTeamPage/TeamDaysList/NewTeamDayButton/NewTeamDayDialog/NewTeamDayDialog"
import React, { useState } from "react"
import { useTeamsAppContext } from "TeamsApp/TeamsAppContext"
import { EditionTimeFrame } from "types"
import { DashedButton } from "ui"
import { Group } from "ui/icons"
import UTCDate from "utils/UTCDate"

interface PropsType {
  from: UTCDate
  setEditionTimeFrame: React.Dispatch<React.SetStateAction<EditionTimeFrame>>
}

export default function TeamDayButton({
  from,
  setEditionTimeFrame,
}: PropsType) {
  const t = useTranslate()
  const { isTeamsApp } = useTeamsAppContext()
  const { user: me } = useUser()
  const { openDrawer, setOpenDrawer } = usePlanningContext()
  const { companyFlags } = useCompanyFlags()
  const shouldHideTeamDays = Boolean(companyFlags.hideTeamDays)

  const [open, setOpen] = useState(false)

  if (shouldHideTeamDays || isTeamsApp || (me && me.team.length === 0))
    return <></>

  return (
    <>
      <DashedButton
        title={t("team day")}
        icon={<Group />}
        onClick={() => {
          setOpen(true)
        }}
      />
      {open && (
        <NewTeamDayDialog
          initialTeamDayDate={from}
          onClose={() => {
            setOpen(false)
            if (openDrawer !== null) setOpenDrawer(null)
            setEditionTimeFrame({})
          }}
        />
      )}
    </>
  )
}
