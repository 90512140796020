import Loader from "components/Loader/Loader"
import useCompanyHybridRules from "graphql/hybridPolicy/useCompanyHybridRules"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { CompanyHybridRules, DefaultHybridRule, HybridRule } from "types"
import { Button, Dialog, DropDown, P16Bold } from "ui"

import HybridRuleScoreTable from "./HybridRuleScoreTable/HybridRuleScoreTable"

const DialogContentLayout = styled.div`
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  grid-template-areas:
    "dropdown navigation empty export"
    "table    table      table table";
  padding-top: 16px;
`

type CompanyRulesType = CompanyHybridRules & {
  default: DefaultHybridRule
}

export interface DropDownOptionType {
  name: string
  value: {
    rule: HybridRule
    type: "default" | "user" | "manager" | "group" | "myteam"
  }
}

interface DialogPropsType {
  companyHybridRules: CompanyRulesType
  options?: {
    myTeamScoresOnly?: boolean
  }
  onClose: () => void
}

function HybridRulesBrowserDialog({
  companyHybridRules,
  options,
  onClose,
}: DialogPropsType) {
  const t = useTranslate()
  const getInitialSelectedRule = (): DropDownOptionType => {
    if (options?.myTeamScoresOnly) {
      return {
        // dummy values but needed to avoir showing default rule ?
        name: t("myteam-scores"),
        value: { rule: companyHybridRules.default, type: "myteam" },
      }
    }
    return {
      name: t("default rule"),
      value: {
        rule: companyHybridRules.default,
        type: "default",
      },
    }
  }
  const [selectedRule, setSelectedRule] = useState<DropDownOptionType>(
    getInitialSelectedRule()
  )
  const getDropDownOptions = (): DropDownOptionType[] => {
    if (options?.myTeamScoresOnly) {
      return [
        {
          // dummy values but needed to avoir showing default rule ?
          name: t("myteam-scores"),
          value: { rule: companyHybridRules.default, type: "myteam" },
        },
      ]
    }
    const userRules: DropDownOptionType[] = companyHybridRules.users.map(
      (rule) => ({
        name: rule.name,
        value: { rule, type: "user" },
      })
    )
    const managerRules: DropDownOptionType[] = companyHybridRules.managers.map(
      (rule) => ({
        name: rule.name,
        value: { rule, type: "manager" },
      })
    )
    const groupRules: DropDownOptionType[] = companyHybridRules.groups.map(
      (rule) => ({
        name: rule.name,
        value: { rule, type: "group" },
      })
    )
    return [
      {
        name: t(companyHybridRules.default.name),
        value: { rule: companyHybridRules.default, type: "default" },
      },
      ...managerRules,
      ...groupRules,
      ...userRules,
    ]
  }

  return (
    <Dialog
      open
      onClose={onClose}
      title={t("Details of hybrid compliance")}
      maxWidth="lg"
    >
      <DialogContentLayout>
        <div style={{ gridArea: "dropdown", padding: "0 8px 0 16px" }}>
          {options?.myTeamScoresOnly ? (
            <P16Bold
              style={{
                padding: "8px 16px",
              }}
              color="purple"
            >
              {t("myteam-scores")}
            </P16Bold>
          ) : (
            <DropDown
              title={selectedRule.name}
              options={getDropDownOptions()}
              onSelect={setSelectedRule}
            />
          )}
        </div>
        <HybridRuleScoreTable
          rule={selectedRule}
          options={{
            showUserRuleType: options?.myTeamScoresOnly !== undefined,
          }}
        />
      </DialogContentLayout>
    </Dialog>
  )
}

function DialogWrapper({
  options,
  onClose,
}: {
  options?: {
    myTeamScoresOnly?: boolean
  }
  onClose: () => void
}) {
  const { companyHybridRules, loading } = useCompanyHybridRules()

  if (companyHybridRules.default === undefined) return <></>

  if (loading) return <Loader />

  return (
    <HybridRulesBrowserDialog
      options={options}
      companyHybridRules={companyHybridRules as CompanyRulesType}
      onClose={onClose}
    />
  )
}

interface PropsType {
  title: string
  options?: {
    myTeamScoresOnly?: boolean
  }
  disabled?: boolean
}

export default function HybridRulesBrowserButton({
  title,
  options,
  disabled,
}: PropsType) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Button
        secondary
        disabled={disabled}
        onClick={() => {
          setOpen(true)
        }}
      >
        {title}
      </Button>
      {open && (
        <DialogWrapper options={options} onClose={() => setOpen(false)} />
      )}
    </>
  )
}
