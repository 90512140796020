import React from "react"
import styled from "styled-components"
import { SearchResult } from "types"
import { colors, PopperMenu } from "ui"

const ResultsListContainer = styled.ul<{ width: string }>`
  padding-top: 12px;

  ${({ width }) => `width: ${width};`}

  &.list {
    &--hide-box-shadow {
      box-shadow: none;
    }
    &--extra-margin-top {
      margin-top: -46px;
    }
  }

  .results-list {
    &__search {
      display: flex;
      align-items: center;
      padding: 0 16px 12px 16px;
      border-bottom: solid 1px ${colors.grey3};
    }
  }

  > li:nth-child(2) {
    padding-top: 12px;
  }
`

interface PropsType {
  anchorEl: HTMLElement | null
  searchString: string
  results: SearchResult[]
  hideBoxShadow?: boolean
  forceBringToTop?: boolean
  onTopOfSearchInput?: boolean
  children: React.ReactNode
  onClick?: () => void
  handleReset: () => void
}

export default function SearchResultsContainer({
  searchString,
  anchorEl,
  forceBringToTop,
  hideBoxShadow,
  onTopOfSearchInput,
  children,
  onClick,
  handleReset,
}: PropsType) {
  const showSearchResults = searchString !== ""

  const getClassName = ({
    hideBoxShadow = false,
    onTopOfSearchInput = false,
  }: {
    hideBoxShadow?: boolean
    onTopOfSearchInput?: boolean
  }) => {
    const classList = ["list"]
    if (hideBoxShadow) classList.push("list--hide-box-shadow")
    if (onTopOfSearchInput) classList.push("list--extra-margin-top")
    return classList.join(" ")
  }

  if (!anchorEl) return <></>

  return (
    <PopperMenu
      open={showSearchResults}
      anchorEl={anchorEl}
      style={
        forceBringToTop
          ? {
              zIndex: 3000,
            }
          : undefined
      }
      onClose={handleReset}
    >
      <ResultsListContainer
        onClick={onClick}
        width={`${anchorEl.getBoundingClientRect().width}px`}
        className={getClassName({ hideBoxShadow, onTopOfSearchInput })}
      >
        {children}
      </ResultsListContainer>
    </PopperMenu>
  )
}
