import useTranslate from "intl/useTranslate"
import React from "react"
import { L12, P16Bold } from "ui"

interface PropsType {
  maxDays: number
  concernedUserIds: string[]
  compliantUserIds: string[]
}

export default function ScoreAsText({
  maxDays,
  concernedUserIds,
  compliantUserIds,
}: PropsType) {
  const t = useTranslate()

  return (
    <div>
      <L12 color="grey1">{t("Maximum in home office")}</L12>
      <span
        style={{
          display: "flex",
          alignItems: "baseline",
          gap: 16,
        }}
      >
        <P16Bold color="purple">
          {maxDays} {t("days")}
        </P16Bold>
        {compliantUserIds.length - concernedUserIds.length < 0 ? (
          <L12 color="redAlert">
            {concernedUserIds.length - compliantUserIds.length}{" "}
            {t("people do not respect")}
          </L12>
        ) : (
          <div />
        )}
      </span>
    </div>
  )
}
