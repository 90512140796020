import { gql, useMutation } from "@apollo/client"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useTranslate from "intl/useTranslate"
import { Flags } from "types"

import { UserIntegrationFragment } from "./fragments/UserIntegrationFragments"
import updateCache from "./updateUserIntegrationCache"
import { QUERY as CHECK_USER_INTEGRATION_QUERY } from "./useCheckUserIntegration"

const MUTATION = gql`
  ${UserIntegrationFragment}
  mutation setupUserIntegration(
    $name: String!
    $externalId: ID
    $token: String
    $flags: JSON
  ) {
    setupUserIntegration(
      name: $name
      externalId: $externalId
      token: $token
      flags: $flags
    ) {
      ...UserIntegrationFragment
    }
  }
`

interface PropsType {
  name: string
  externalId?: string
  token?: string
  flags?: Flags
}

export default function useSetupUserIntegration() {
  const { addToast } = useToasts()
  const t = useTranslate()

  const [mutate] = useMutation(MUTATION, {
    update: (cache, { data }) =>
      updateCache({
        cache,
        data,
        mutationName: "setupUserIntegration",
      }),
  })

  return async ({ name, externalId, token, flags }: PropsType) => {
    try {
      await mutate({
        variables: { name, externalId, token, flags },
        refetchQueries: [
          {
            query: CHECK_USER_INTEGRATION_QUERY,
            variables: { name },
          },
        ],
      })
      addToast(
        t("confirmation connexion to integration").replace("{name}", name),
        { appearance: "success" }
      )
    } catch (e) {
      console.error(e.message)
      addToast(t("cannot setup integration", { name }), {
        appearance: "error",
        autoDismissTimeout: 10000,
      })
    }
  }
}
