import useCompanyFlags from "hooks/useCompanyFlags"
import useMenu from "hooks/useMenu"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { GroupSimple } from "types"
import { colors } from "ui"
import {
  Group as GroupIcon,
  HomeUser,
  MidlineHorizontalEllispsis,
} from "ui/icons"
import getTeamDisplayName from "utils/getTeamDisplayName"

import { MenuList } from "../components/MenuList"
import MenuListItem from "../MenuListItem/MenuListItem"

const Icon = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  svg {
    color: ${colors.grey2};
    width: 16px;
  }
`

interface PropsType {
  myTeam: GroupSimple | undefined
  myManagerTeam: GroupSimple | undefined
  everyoneGroup: GroupSimple | undefined
  hideEveryoneGroup: boolean
}

// only display myTeam - myManagerTeam
export default function TeamMenuGroups({
  myTeam,
  myManagerTeam,
  everyoneGroup,
  hideEveryoneGroup,
}: PropsType) {
  const t = useTranslate()
  const { companyFlags } = useCompanyFlags()
  const { handlePath } = useMenu()

  if (!myTeam && !myManagerTeam && hideEveryoneGroup) return <></>

  return (
    <MenuList>
      {!myTeam && !myManagerTeam && everyoneGroup && (
        <MenuListItem
          icon={<GroupIcon />}
          name={everyoneGroup.name}
          path={`/planning/group/${everyoneGroup.id}`}
        />
      )}
      {myTeam && (
        <MenuListItem
          icon={<HomeUser />}
          name={getTeamDisplayName(myTeam, companyFlags, t)}
          path={`/planning/group/${myTeam.id}`}
          action={
            <Icon>
              <MidlineHorizontalEllispsis />
            </Icon>
          }
          handleAction={() => {
            handlePath("/myteam")
          }}
        />
      )}

      {myManagerTeam && (
        <MenuListItem
          icon={<HomeUser />}
          name={getTeamDisplayName(myManagerTeam, companyFlags, t)}
          path={`/planning/group/${myManagerTeam.id}`}
        />
      )}
    </MenuList>
  )
}
