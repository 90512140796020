import useDeleteGroup from "graphql/groups/useDeleteGroup"
import useLink from "hooks/useLink"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { Button, Dialog, P16 } from "ui"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
  gap: 16px;
`

interface PropsType {
  groupId: string
  open: boolean
  onClose: () => void
}

export default function DeleteGroupDialog({
  groupId,
  open,
  onClose,
}: PropsType) {
  const t = useTranslate()

  const { loading, deleteGroup } = useDeleteGroup()
  const goToGroupsPage = useLink("/groups")

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t("delete group")}
      leftButton={
        <Button
          secondary
          disabled={loading}
          onClick={() => {
            onClose()
          }}
        >
          {t("Cancel")}
        </Button>
      }
      rightButton={
        <Button
          danger
          disabled={loading}
          onClick={async () => {
            await deleteGroup(groupId)
            goToGroupsPage()
          }}
        >
          {t("Confirm")}
        </Button>
      }
    >
      <Container>
        <P16>{t("Are you sure you want to delete this group?")}</P16>
      </Container>
    </Dialog>
  )
}
