import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import TypicalWeekDialog from "components/TypicalWeekDialog/TypicalWeekDialog"
import usePlanningUserSlots from "graphql/slots/usePlanningUserSlots"
import useApplySlotTemplatesUTC from "graphql/typicalWeek/useApplySlotTemplatesUTC"
import useUserSlotTemplates from "graphql/typicalWeek/useUserSlotTemplates"
import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import React, { useRef, useState } from "react"
import styled from "styled-components"
import { useTeamsAppContext } from "TeamsApp/TeamsAppContext"
import { UserBase } from "types"
import { colors, mediaQueries, PopperMenu, transitions } from "ui"
import { Refresh } from "ui/icons"
import { days } from "utils/UTCDate"

const UnstyledButton = styled.button`
  margin: 0;
  padding: 0;
  display: flex;
  background: none;
  border: solid 1px ${colors.grey3};
  border-radius: 4px;
  padding: 8px;

  svg {
    width: 24px;
    height: 24px;
  }

  @media ${mediaQueries.isMobile} {
    border: none;
    padding-right: 16px;

    svg {
      width: 16px;
      height: 16px;
      stroke-width: 2px;
    }
  }
`

const ListLayout = styled.ul`
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  padding: 16px 0;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.24), 0px 2px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  margin-top: 8px;
  cursor: pointer;
`

const Item = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 24px;
  background: rgba(0, 0, 0, 0);

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }

  transition: ${transitions.fade};
`
interface PropsType {
  selectedUser: UserBase
}
export default function TypicalWeekButton({ selectedUser }: PropsType) {
  const t = useTranslate()
  const { from, to, userShowWeekends, setOpenDrawer, setIsTypicalWeekOpen } =
    usePlanningContext()
  const { isTeamsApp } = useTeamsAppContext()
  const [open, setOpen] = useState(false)

  const { slots } = usePlanningUserSlots({
    userId: selectedUser.id,
    from,
    to,
  })
  const hasEmptyWeek =
    slots.filter((s) => s.value !== "null" && s.value !== null).length === 0

  const { user: me } = useUser()
  const selectedUserIsMe = me?.id === selectedUser.id
  const { slots: slotTemplates } = useUserSlotTemplates(me?.id ?? "")
  const applySlotTemplatesUTC = useApplySlotTemplatesUTC()

  const canApplyTemplate =
    slotTemplates.filter((s) => {
      if (!userShowWeekends)
        return (
          s.value !== null &&
          s.date.getDayAsString() !== days[0] &&
          s.date.getDayAsString() !== days[6]
        )
      return s.value !== null
    }).length > 0

  const buttonRef = useRef<HTMLButtonElement>(null)
  const [popperOpen, setPopperOpen] = useState(false)

  if (!selectedUserIsMe) return <></>

  if (isTeamsApp && !canApplyTemplate) return <></>

  return (
    <>
      <UnstyledButton
        ref={buttonRef}
        onClick={(e) => {
          e.stopPropagation()
          setPopperOpen(true)
        }}
      >
        <Refresh stroke={hasEmptyWeek ? colors.office : colors.grey2} />
      </UnstyledButton>
      <PopperMenu
        anchorEl={buttonRef.current}
        placement="bottom-start"
        open={popperOpen}
        onClose={() => setPopperOpen(false)}
        disablePortal
        style={{ zIndex: 3000 }}
      >
        <ListLayout>
          {canApplyTemplate && (
            <Item
              onClick={() => {
                applySlotTemplatesUTC(from, null).then(() => {
                  setPopperOpen(false)
                })
              }}
            >
              {t("apply typical week")}
            </Item>
          )}
          {!isTeamsApp && (
            <>
              <Item
                onClick={() => {
                  setOpen(true)
                  setPopperOpen(false)
                  setOpenDrawer("SLOTS_EDITOR")
                  setIsTypicalWeekOpen(true)
                }}
              >
                {t(
                  canApplyTemplate
                    ? "go edit typical week"
                    : "go create typical week"
                )}
              </Item>
            </>
          )}
        </ListLayout>
      </PopperMenu>
      {open && (
        <TypicalWeekDialog
          open={open}
          onClose={() => {
            setOpen(false)
          }}
        />
      )}
    </>
  )
}
