import { ApolloError, gql, useQuery } from "@apollo/client"
import { CompanyUserStats } from "types"

const GET_COMPANY_USER_STATS = gql`
  query getCompanyUserStats {
    companyUserStats {
      registered
      disabled
      total
    }
  }
`

export default function useCompanyUserStats(): {
  loading: boolean
  error?: ApolloError
  stats: CompanyUserStats | null
} {
  const { loading, error, data } = useQuery(GET_COMPANY_USER_STATS)
  return { stats: data?.companyUserStats || null, loading, error }
}
