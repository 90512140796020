import { ApolloError, gql, useQuery } from "@apollo/client"
import { CompanySetting } from "types"

import { CompanySettingInlineFragment } from "./fragments/settingsFragments"

const GET_COMPANY_SETTINGS_QUERY = gql`
  query getCompanySettings {
    companySettings {
      ${CompanySettingInlineFragment}
    }
  }
`

export default function useCompanySettings(): {
  loading: boolean
  error?: ApolloError
  companySettings: CompanySetting[]
} {
  const { loading, error, data } = useQuery(GET_COMPANY_SETTINGS_QUERY)
  const companySettings: CompanySetting[] = data?.companySettings || []
  return {
    companySettings,
    loading,
    error,
  }
}
