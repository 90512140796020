import React from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { colors } from "ui"
import { ArrowLeft } from "ui/icons"

const Button = styled(ArrowLeft)`
  stroke: ${colors.grey1};
  width: 20px;
  cursor: pointer;
`

interface PropsType {
  path?: string
  onClose?: () => void
}

export default function MobileNavBack({ path, onClose }: PropsType) {
  const history = useHistory()

  const onClick = () => {
    if (onClose) return onClose()
    if (path) return history.push(path)
    history.goBack()
  }

  if (path) return <ArrowLeft onClick={onClick} />
  return <Button onClick={onClick} />
}
