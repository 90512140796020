import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useUserFavorites from "graphql/favorites/useUserFavorites"
import useMyGroupsSimple from "graphql/groups/useMyGroupsSimple"
import useLocationsTree from "graphql/locations/useLocationsTree"
import useUser from "graphql/users/useUser"
import useMediaQueries from "hooks/useMediaQueries"
import React, { useEffect } from "react"
import { Redirect, Route, Switch, useParams } from "react-router-dom"
import { useTeamsAppContext } from "TeamsApp/TeamsAppContext"
import LocationTree from "utils/LocationTree"
import sortByKey from "utils/sortByKey"

import getDefaultGroup from "./components/utils/getDefaultGroup"
import PlanningPageDesktop from "./desktop/PlanningPageDesktop"
import PlanningPageMobile from "./mobile/PlanningPageMobile"

interface PlanningPageGenericPropsType {
  locations: LocationTree[]
}

interface LocationPlanningPagePropsType extends PlanningPageGenericPropsType {
  locationId: string
}

function LocationPlanningPage({ locationId }: LocationPlanningPagePropsType) {
  const { isMobile } = useMediaQueries()
  const { setLocationId, setShowHiddenActiveLocations } = usePlanningContext()

  useEffect(() => {
    setLocationId(locationId)
    setShowHiddenActiveLocations(false)
    // eslint-disable-next-line
  }, [locationId])

  if (isMobile) return <PlanningPageMobile />
  return <PlanningPageDesktop />
}

interface GroupPlanningPagePropsType extends PlanningPageGenericPropsType {
  groupId: string
}

function GroupPlanningPage({ groupId }: GroupPlanningPagePropsType) {
  const { isMobile } = useMediaQueries()
  const { setGroupId, setShowHiddenActiveLocations } = usePlanningContext()

  useEffect(() => {
    setGroupId(groupId)
    setShowHiddenActiveLocations(false)
    // eslint-disable-next-line
  }, [groupId])

  if (isMobile) return <PlanningPageMobile />
  return <PlanningPageDesktop />
}

function FavoritesPlanningPage({}: PlanningPageGenericPropsType) {
  const { isMobile } = useMediaQueries()
  const { setUserIds, userIds, setShowHiddenActiveLocations } =
    usePlanningContext()

  const { favorites } = useUserFavorites()
  useEffect(() => {
    if (userIds?.length !== favorites.users.length) {
      setUserIds(favorites.users.map((u) => u.itemId))
      setShowHiddenActiveLocations(false)
    }
    // eslint-disable-next-line
  }, [favorites, userIds])

  if (isMobile) return <PlanningPageMobile />
  return <PlanningPageDesktop />
}

function DefaultPlanningPage({ locations }: PlanningPageGenericPropsType) {
  const { favorites } = useUserFavorites()
  const { user: me } = useUser()
  const { groups: myGroups, loading } = useMyGroupsSimple()

  if (loading) {
    return <></>
  }

  const defaultGroup = getDefaultGroup(me, myGroups)

  // in first we return the default group
  if (defaultGroup) {
    return <Redirect to={`/planning/group/${defaultGroup.id}`} />
  }

  // if there are favoris, we chose the first fav
  else if (favorites.groups.length > 0 || favorites.locations.length > 0) {
    // right now the first fav is only defined by its name
    const firstFav = sortByKey(
      [...favorites.groups, ...favorites.locations],
      "itemName"
    )[0]
    if (firstFav.type === "group") {
      return <Redirect to={`/planning/group/${firstFav.itemId}`} />
    }

    if (firstFav.type === "location") {
      const selectedLocation = LocationTree.getLocationNode(
        locations,
        firstFav.itemId
      )
      if (selectedLocation === undefined)
        return <Redirect to="/planning/favorites" />
      return <Redirect to={`/planning/location/${selectedLocation.id}`} />
    }
  }
  return <Redirect to="/planning/favorites" />
}

function TeamsPlanningPage({}: PlanningPageGenericPropsType) {
  const { isMobile } = useMediaQueries()
  const { users: teamsUsers } = useTeamsAppContext()
  const { user: me } = useUser()
  const { groups: myGroups } = useMyGroupsSimple()

  const {
    setUserIds,
    setGroupId,
    userIds,
    setUsersAtFilters,
    setShowHiddenActiveLocations,
  } = usePlanningContext()

  useEffect(() => {
    if (teamsUsers) {
      if (teamsUsers.length !== userIds?.length) {
        setUserIds(teamsUsers.map((u) => u.id))
        setUsersAtFilters([])
        setShowHiddenActiveLocations(false)
      } else {
        for (let i = 0; i < teamsUsers.length; i++) {
          if (teamsUsers[i].id !== userIds[i]) {
            setUserIds(teamsUsers.map((u) => u.id))
            setUsersAtFilters([])
            setShowHiddenActiveLocations(false)
            break
          }
        }
      }
    } else {
      const defaultGroup = getDefaultGroup(me, myGroups)
      if (defaultGroup) {
        setGroupId(defaultGroup.id)
        setUsersAtFilters([])
        setShowHiddenActiveLocations(false)
      }
    }
    // eslint-disable-next-line
  }, [teamsUsers, me, myGroups])

  if (isMobile) return <PlanningPageMobile />
  return <PlanningPageDesktop />
}

export default function PlanningPageRouter() {
  const { setActiveLocation, activeLocation } = usePlanningContext()
  const { locationId, groupId } = useParams<{
    locationId?: string
    groupId?: string
  }>()

  const { locations } = useLocationsTree()

  const { isTeamsApp } = useTeamsAppContext()

  useEffect(() => {
    // always reset active location when browsing /planning/... pages
    if (activeLocation.location !== null) {
      setActiveLocation({
        location: null,
        persist: false,
        triggeredFrom: "active-locations-list",
      })
    }
    // eslint-disable-next-line
  }, [])

  if (isTeamsApp) return <TeamsPlanningPage locations={locations} />

  return (
    <Switch>
      <Route path="/planning/location/:locationId">
        {() => {
          if (locationId === undefined) return <></>
          return (
            <LocationPlanningPage
              locationId={locationId}
              locations={locations}
            />
          )
        }}
      </Route>
      <Route path="/planning/group/:groupId">
        {() => {
          if (groupId === undefined) return <></>
          return <GroupPlanningPage groupId={groupId} locations={locations} />
        }}
      </Route>
      <Route path="/planning/favorites">
        <FavoritesPlanningPage locations={locations} />
      </Route>
      <Route path={["/", "/planning"]}>
        <DefaultPlanningPage locations={locations} />
      </Route>
    </Switch>
  )
}
