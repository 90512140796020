import useCompanyFiles from "graphql/company/useCompanyFiles"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components/macro"
import { ExternalLink, P14, P16Bold } from "ui"
import { Download } from "ui/icons"

import { Elem } from "./ListElem"

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Title = styled(P16Bold)`
  margin-bottom: 24px;
`

function CompanyFiles() {
  const t = useTranslate()
  const { companyFiles } = useCompanyFiles()
  const { isMobile } = useMediaQueries()

  return (
    <Content>
      {!isMobile && <Title>{t("corporate documents")}</Title>}
      <div>
        {companyFiles.length > 0 ? (
          companyFiles.map((file) => (
            <ExternalLink
              key={file.id}
              href={file.url}
              rel="noopener noreferrer"
              download
            >
              <Elem>
                <Download />
                <P14>{file.name}</P14>
              </Elem>
            </ExternalLink>
          ))
        ) : (
          <P14>{t("company_file--empty_file_list")}</P14>
        )}
      </div>
    </Content>
  )
}
export default CompanyFiles
