import useMediaQueries from "hooks/useMediaQueries"
import React from "react"
import styled from "styled-components"
import { H2, H4 } from "ui"

const textOverflow = `
white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;
`

const StyledH4 = styled(H4)`
  margin-right: auto;
  ${textOverflow}
`

const StyledH2 = styled(H2)`
  ${textOverflow}
`

interface PropsType {
  title: string
}

export default function PageTitle({ title }: PropsType) {
  const { isMobile } = useMediaQueries()
  if (isMobile) return <StyledH4>{title}</StyledH4>
  return <StyledH2>{title}</StyledH2>
}
