import { gql, useMutation } from "@apollo/client"
import { LinearProgress } from "@mui/material"
import useCompanyFlags from "hooks/useCompanyFlags"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components/macro"
import { Alert, Grid, P16Bold, Toggle } from "ui"

import ConfigureCustomDomain from "./ConfigureCustomDomain"
import ConfigureSAML from "./ConfigureSAML"
import { providerSettings } from "./ProvisionningForm"

const ProvisioningContainer = styled.div`
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 15px;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 480px;

  .toggle-container {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .text {
    margin-top: 20px;
  }
`

const TOGGLE_SAML = gql`
  mutation toggleRawSAMLSSO($enabled: Boolean!) {
    toggleRawSAMLSSO(enabled: $enabled)
  }
`

interface PropsType {
  onClose: () => void
}

export default function RawSAMLSSOForm({ onClose }: PropsType) {
  const t = useTranslate()
  const { companyFlags } = useCompanyFlags()
  const [loading, setLoading] = useState(false)

  const [toggleRawSAMLSSO] = useMutation(TOGGLE_SAML, {
    update(cache) {
      cache.modify({
        fields: {
          user: (_user) => {
            return
          },
        },
      })
    },
  })

  const toggleSAML = async (enabled: boolean) => {
    setLoading(true)
    try {
      await toggleRawSAMLSSO({
        variables: {
          enabled,
        },
      })
    } finally {
      setLoading(false)
    }
  }

  if ("SCIM" in companyFlags) {
    const otherProvider = providerSettings[companyFlags.SCIM.provider]
    return (
      <>
        <Grid item>
          <P16Bold>{t("Configure custom SAML SSO")}</P16Bold>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={12} mt={2}>
            <Alert severity="warning">
              <>
                {t("You can only configure one provider per organization.")}
                <br />
                {t("Please disable provider provisioning to proceed.", {
                  provider: otherProvider.longName,
                })}
              </>
            </Alert>
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <ProvisioningContainer>
      <Grid item>
        <P16Bold>{t("Configure custom SAML SSO")}</P16Bold>
      </Grid>
      <Grid container direction="column" className="toggle-container">
        <Toggle
          labelToTheLeft
          label={t("Enable SAML SSO")}
          checked={companyFlags.useRawSAMLSSO}
          onChange={(e) => toggleSAML(e)}
          disabled={loading}
        />
        {loading && <LinearProgress sx={{ marginTop: 1 }} />}
      </Grid>
      <ConfigureCustomDomain />
      <P16Bold className="text">{t("Single sign-on (SAML):")}</P16Bold>
      <ConfigureSAML provider="RAW" onClose={onClose} />
    </ProvisioningContainer>
  )
}
