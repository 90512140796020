import React from "react"
import styled from "styled-components"
import { colors } from "ui"

const StyledCounter = styled.div`
  display: flex;
  align-items: center;
  height: 18px;
  min-width: 30px;
  gap: 1px;

  &.disabled {
    p {
      color: ${colors.grey2};
    }
  }
`
export function Counter({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) {
  return <StyledCounter className={className}>{children}</StyledCounter>
}
