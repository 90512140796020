import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { UsersAtFilter } from "types"
import { colors, mediaQueries } from "ui"
import { Cancel } from "ui/icons"

import { ButtonBase, Circle } from "../components/components"

const FilterItem = styled(ButtonBase)`
  color: ${colors.black};
  &:hover {
    background: ${colors.black}08;
    transition: background-color 250ms ease-in-out;
  }
  @media ${mediaQueries.isMobile} {
    padding: 2px 4px;
    gap: 0;
  }
`

interface PropsType {
  usersAtFilters: UsersAtFilter[]
  updateFilter: (filter: UsersAtFilter | undefined) => void
  setOpen: (open: boolean) => void
  setAnchorEl: (el: HTMLElement | null) => void
}

export default function UsersAtFiltersContent({
  usersAtFilters,
  updateFilter,
  setOpen,
  setAnchorEl,
}: PropsType) {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()

  return (
    <ul>
      {React.Children.map(usersAtFilters, (filter) => (
        <FilterItem
          ref={(el) => setAnchorEl(el)}
          onClick={() => {
            setOpen(true)
          }}
        >
          <Circle
            className={
              usersAtFilters.length > 0
                ? `circle circle--${usersAtFilters[0]}`
                : ""
            }
          />
          {!isMobile && <>{t(`users_at_filter--${filter}`)}</>}
          <Cancel
            onClick={(e) => {
              updateFilter(undefined)
              setOpen(false)
              e.stopPropagation()
            }}
          />
        </FilterItem>
      ))}
    </ul>
  )
}
