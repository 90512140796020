import ButtonResetSearch from "components/ButtonResetSearch/ButtonResetSearch"
import useLocationsTree from "graphql/locations/useLocationsTree"
import useMediaQueries from "hooks/useMediaQueries"
import React from "react"
import { SearchResult } from "types"
import { IconText } from "ui"
import { Search as SearchIcon } from "ui/icons"

import {
  GroupResultsGroup,
  LocationResultsGroup,
  UserResultsGroup,
} from "../ResultsGroup/ResultsGroup"
import SearchResultsContainer from "../SearchResultsContainer/SearchResultsContainer"

interface PropsType {
  anchorEl: HTMLElement | null
  searchString: string
  results: SearchResult[]
  variant?: "simple"
  forceBringToTop?: boolean
  onSelect: (input: SearchResult) => void
  giveFocusToInput: () => void
  handleReset: () => void
}

export default function MainSearchResults({
  anchorEl,
  searchString,
  results,
  variant,
  forceBringToTop,
  giveFocusToInput,
  onSelect,
  handleReset,
}: PropsType) {
  const { isMobile } = useMediaQueries()

  const users = results.filter((r) => r.type === "user")
  const groups = results.filter((r) => r.type === "group")
  const locations = results.filter((r) => r.type === "location")
  const { locations: locationsTree } = useLocationsTree()

  const hideBoxShadow = location.pathname === "/search" && isMobile

  const onTopOfSearchInput =
    !isMobile &&
    variant !== "simple" &&
    (location.pathname.startsWith("/planning") || location.pathname === "/")

  const handleSelect = (input: SearchResult) => {
    onSelect(input)
  }

  return (
    <SearchResultsContainer
      anchorEl={anchorEl}
      searchString={searchString}
      results={results}
      onClick={giveFocusToInput}
      handleReset={handleReset}
      hideBoxShadow={hideBoxShadow}
      forceBringToTop={forceBringToTop}
      onTopOfSearchInput={onTopOfSearchInput}
    >
      {!isMobile && variant !== "simple" && (
        <li className="results-list__search" onClick={giveFocusToInput}>
          <IconText icon={<SearchIcon />}>{searchString}</IconText>
          {searchString !== "" && (
            <ButtonResetSearch
              resetForm={handleReset}
              handleClearForm={handleReset}
            />
          )}
        </li>
      )}
      {users.length > 0 && (
        <UserResultsGroup results={users} onSelect={handleSelect} />
      )}
      {groups.length > 0 && (
        <GroupResultsGroup results={groups} onSelect={handleSelect} />
      )}
      {locations.length > 0 && (
        <LocationResultsGroup
          results={locations}
          locationsTree={locationsTree}
          onSelect={handleSelect}
        />
      )}
    </SearchResultsContainer>
  )
}
