import uniqBy from "lodash.uniqby"
import { RawLocationEntry, ReservedLocation } from "types"

import isLocationPrivate from "./isLocationPrivate"

export default class LocationTree {
  parentId: string | null
  children: LocationTree[]
  breadCrumbs: string[]

  id: string
  name: string
  shortname: string
  isCategory: boolean
  capacity: number
  bookable: number
  archived: boolean
  floorPlanImageUrl: string | null = null

  isPrivate: boolean
  reservedTo: ReservedLocation

  constructor(
    rawEntry: RawLocationEntry,
    parent: LocationTree | null,
    allRawEntries: RawLocationEntry[],
    filterArchived: boolean,
    filterGroupIds: string[] | null = null,
    filterUserIds: string[] | null = null
  ) {
    this.parentId = parent?.id || null

    this.id = rawEntry.id
    this.name = rawEntry.name
    this.shortname = rawEntry.shortname
    this.archived = rawEntry.archived

    this.reservedTo = {
      users: rawEntry.reservedTo.users,
      groups: rawEntry.reservedTo.groups,
    }
    this.isPrivate = isLocationPrivate(rawEntry)

    this.isCategory = rawEntry.isCategory

    this.floorPlanImageUrl = rawEntry.floorPlanImageUrl

    this.breadCrumbs = parent ? [...parent.breadCrumbs, parent.name] : []

    if (this.isCategory) {
      // make children

      this.children = allRawEntries
        .filter((entry) => entry.parentId === this.id)
        .filter((entry) => (filterArchived ? entry.archived === false : true))
        .filter((entry) => {
          if (filterGroupIds || filterUserIds) {
            if (isLocationPrivate(entry)) {
              const groupIds = filterGroupIds || []
              const userIds = filterUserIds || []
              if (entry.reservedTo.groups.some((id) => groupIds.includes(id))) {
                return true
              }
              if (entry.reservedTo.users.some((id) => userIds.includes(id))) {
                return true
              }
              return false
            }
          }
          return true
        })
        /*.sort((a, b) => {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        })*/
        .map((entry) => {
          const location = new LocationTree(
            entry,
            this,
            allRawEntries,
            filterArchived,
            filterGroupIds,
            filterUserIds
          )
          location.breadCrumbs = [...this.breadCrumbs, this.name]
          return location
        })
      this.capacity = this.children.reduce(
        (acc, child) => acc + child.capacity,
        0
      )
      this.bookable = this.children.reduce(
        (acc, child) => acc + child.bookable,
        0
      )
    } else {
      this.children = []
      this.capacity = rawEntry.capacity
      this.bookable = rawEntry.bookable
    }
  }

  copy(): LocationTree {
    const copied = new LocationTree(
      {
        id: this.id,
        name: this.name,
        shortname: this.shortname,
        isCategory: this.isCategory,
        capacity: this.capacity,
        bookable: this.bookable,
        archived: this.archived,
        floorPlanImageUrl: this.floorPlanImageUrl,
        parentId: null,
        reservedTo: this.reservedTo,
      },
      null,
      [],
      false
    )
    copied.parentId = this.parentId
    copied.children = this.children.map((child) => child.copy())
    copied.breadCrumbs = this.breadCrumbs
    return copied
  }

  // Returns an array, because this tree can have several roots
  static make(
    rawEntries: RawLocationEntry[],
    filterArchived: boolean,
    filterGroupIds: string[] | null = null,
    filterUserIds: string[] | null = null
  ): LocationTree[] {
    const roots = rawEntries
      .filter((entry) => entry.parentId === null)
      .filter((entry) => (filterArchived ? entry.archived === false : true))
      .filter((entry) => {
        if (filterGroupIds || filterUserIds) {
          if (isLocationPrivate(entry)) {
            const groupIds = filterGroupIds || []
            const userIds = filterUserIds || []
            if (entry.reservedTo.groups.some((id) => groupIds.includes(id))) {
              return true
            }
            if (entry.reservedTo.users.some((id) => userIds.includes(id))) {
              return true
            }
            return false
          }
        }
        return true
      })
      /*.sort((a, b) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })*/
      .map(
        (entry) =>
          new LocationTree(
            entry,
            null,
            rawEntries,
            filterArchived,
            filterGroupIds,
            filterUserIds
          )
      )
    return roots
  }

  static getLocationNode(
    roots: LocationTree[],
    id: string
  ): LocationTree | undefined {
    for (const root of roots) {
      if (root.id === id) {
        return root
      }
      if (root.isCategory) {
        for (const child of root.children) {
          const f = LocationTree.getLocationNode([child], id)
          if (f) {
            return f
          }
        }
      }
    }
  }

  getParents(roots: LocationTree[]): LocationTree[] {
    if (this.parentId === null) {
      return []
    }
    const parent = LocationTree.getLocationNode(roots, this.parentId)
    if (parent === undefined) {
      return []
    }
    return [parent, ...parent.getParents(roots)]
  }

  static getCategories(roots: LocationTree[]): LocationTree[] {
    const categories: LocationTree[] = []

    const extractCategories = (
      location: LocationTree,
      categories: LocationTree[]
    ) => {
      if (location.isCategory) categories.push(location)
      for (const child of location.children) {
        extractCategories(child, categories)
      }
    }

    for (const root of roots) {
      extractCategories(root, categories)
    }

    return categories
  }

  getLeaves(): LocationTree[] {
    if (this.isCategory) {
      return this.children.map((child) => child.getLeaves()).flat()
    }
    return [this]
  }
}

export function getChildrenLocations(
  roots: LocationTree[],
  filterCategory = true
) {
  const childrenLocations: LocationTree[] = []

  const getLocations = (location: LocationTree, locations: LocationTree[]) => {
    if (!location.isCategory) locations.push(location)
    if (!filterCategory && location.isCategory) locations.push(location)
    for (const child of location.children) {
      getLocations(child, locations)
    }
  }
  for (const root of roots) {
    getLocations(root, childrenLocations)
  }

  return childrenLocations
}

export function getParentsFromLocationId(
  roots: LocationTree[],
  locationId: string
) {
  const location = LocationTree.getLocationNode(roots, locationId)
  if (location === undefined) return
  if (location.parentId === null) {
    return []
  }
  const parent = LocationTree.getLocationNode(roots, location.parentId)
  if (parent === undefined) {
    return []
  }
  return [parent, ...parent.getParents(roots)]
}

export function hasChildrenWithFloorPlan(location: LocationTree) {
  if (location.floorPlanImageUrl) {
    return true
  }
  if (location.children) {
    return location.children.some(hasChildrenWithFloorPlan)
  }
  return false
}

export function findOldestParentWithFloorPlan(
  roots: LocationTree[],
  location: LocationTree
): LocationTree | undefined {
  if (hasChildrenWithFloorPlan(location)) {
    return location
  }
  for (const parent of location.getParents(roots)) {
    const parentWithFloorPlan = findOldestParentWithFloorPlan(roots, parent)
    if (parentWithFloorPlan) {
      return parentWithFloorPlan
    }
  }
  return undefined
}

export function getFloorPlans(
  roots: LocationTree[],
  floorPlans: LocationTree[] = []
) {
  for (const root of roots) {
    if (root.floorPlanImageUrl) floorPlans.push(root)
    if (root.children.length > 0) {
      for (const child of root.children) {
        getFloorPlans([child], floorPlans)
      }
    }
  }
  return uniqBy(floorPlans, "id")
}
