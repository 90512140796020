import { PaperProps } from "@mui/material"
import useMediaQueries from "hooks/useMediaQueries"
import useWindowSize from "hooks/useWindowResize"
import React from "react"
import { colors, Drawer } from "ui"

interface PropsType {
  children: React.ReactNode
  open: boolean
  desktopWidth?: string
  onClose: () => void
}

export function PlanningDrawer({
  children,
  open,
  desktopWidth,
  onClose,
}: PropsType) {
  const { isMobile, isNarrowScreen } = useMediaQueries()
  const [, availableHeight] = useWindowSize()

  const drawerMarginTop = isMobile ? "8px" : "200px"

  const getDrawerWidth = () => {
    if (isMobile) return "100vw"
    if (isNarrowScreen) return "calc(100vw - 32px)"
    return desktopWidth ?? "824px"
  }

  const desktopPaperProps: PaperProps = {
    style: {
      width: getDrawerWidth(),
      minHeight: `calc(100vh - ${drawerMarginTop})`,
      height: `calc(100vh - ${drawerMarginTop})`,
      marginLeft: "auto",
      marginRight: "14px",
      borderRadius: "32px 32px 0 0",
      boxShadow: colors.boxShadow,
      scrollbarWidth: "none",
    },
  }
  const mobilePaperProps: PaperProps = {
    style: {
      minHeight: `calc(${availableHeight}px - ${drawerMarginTop} - env(safe-area-inset-top, 0))`,
      height: `calc(${availableHeight}px - ${drawerMarginTop} - env(safe-area-inset-top, 0))`,
      width: getDrawerWidth(),
      overflowX: "hidden",
      borderRadius: "16px 16px 0 0",
      scrollbarWidth: "none",
      boxShadow: `0px -2px 32px ${colors.grey1}`,
    },
  }

  return (
    <Drawer
      open={open}
      anchor="bottom"
      onClose={onClose}
      variant={"persistent"}
      style={{
        zIndex: isMobile ? 3000 : undefined,
      }}
      PaperProps={isMobile ? mobilePaperProps : desktopPaperProps}
      transitionDuration={{
        enter: 300,
      }}
    >
      {children}
    </Drawer>
  )
}
