import AdminLocationsPage from "pages/AdminPage/AdminLocationsPage/AdminLocationsPage"
import AdminPage from "pages/AdminPage/AdminPage"
import BulkChangeStatusPage from "pages/AdminPage/BulkChangeStatusPage/BulkChangeStatusPage"
import CompanyLabelsPage from "pages/AdminPage/CompanyLabelsPage/CompanyLabelsPage"
import CompanySettingsPage from "pages/AdminPage/CompanySettingsPage/CompanySettingsPage"
import FloorPlansAdminPage from "pages/AdminPage/FloorPlansAdminPage/FloorPlansAdminPage"
import HybridWorkPolicy from "pages/AdminPage/HybridWorkPolicy/HybridWorkPolicy"
import IntegrationsPage from "pages/AdminPage/IntegrationsPage/IntegrationsPage"
import PWStatsPage from "pages/AdminPage/PWStatsPage/PWStatsPage"
import ServicesPage from "pages/AdminPage/ServicesPage/ServicesPage"
import ManageTeam from "pages/AdminPage/UsersPage/ManageTeam/ManageTeam"
import AddEmployeesFromCSV from "pages/AdminPage/UsersPage/NewEmployees/AddEmployeesFromCSV"
import UsersPage from "pages/AdminPage/UsersPage/UsersPage"
import AdvancedSearchResultsPage from "pages/AdvancedSearchResultsPage/AdvancedSearchResultsPage"
import ApolloSandboxPage from "pages/ApolloSandboxPage/ApolloSandboxPage"
import CurrentUserSettingsPage from "pages/CurrentUserSettingsPage/CurrentUserSettingsPage"
import DirectoryPage from "pages/DirectoryPage/DirectoryPage"
import EventsPage from "pages/EventsPage/EventsPage"
import FavoritesPage from "pages/FavoritesPage/FavoritesPage"
import GroupPage from "pages/GroupPage/GroupPage"
import GroupsPage from "pages/GroupsPage/GroupsPage"
import MeetingRoomsPage from "pages/MeetingRoomsPage/MeetingRoomsPage"
import MyTeamPage from "pages/MyTeamPage/MyTeamPage"
import PlanningPage from "pages/PlanningPage/PlanningPage"
import StatisticsPage from "pages/StatisticsPage/StatisticsPage"
import UserPage from "pages/UserPage/UserPage"
import UserPlanningTemplatePage from "pages/UserPlanningTemplatePage/UserPlanningTemplatePage"
import TransactableRedirectHandler from "PublicApp/TransactableRedirectHandler"

type RouteType = {
  title: string
  path: string
  component: (props: any) => JSX.Element | null
  role?: string
}

const devRoutes: RouteType[] =
  process.env.NODE_ENV === "development"
    ? [
        {
          title: "Apollo Sandbox",
          path: "/apollo",
          component: ApolloSandboxPage,
        },
      ]
    : []

export const notAccessibleOnMobilePaths: string[] = [
  "/statistics",
  "/floor-plans",
  "/meeting-rooms",
  "/admin/messages",
  "/admin",
  "/admin/settings",
  "/admin/labels",
  "/admin/locations",
  "/admin/users",
  "/admin/integrations",
  "/admin/users/add/csv",
  "/admin/manage-team/:userId",
  "/admin/services",
  "/admin/floor-plans",
  "/admin/tw-stats",
  "/admin/hybrid-policy",
  "/admin/special-days",
]
export const notAccessibleOnDesktopPaths: string[] = []

const routes: RouteType[] = [
  // Planning page
  {
    title: "Planning Page",
    path: "/planning",
    component: PlanningPage,
  },
  {
    title: "Planning Page",
    path: "/",
    component: PlanningPage,
  },
  {
    title: "Group",
    path: "/planning/group/:groupId",
    component: PlanningPage,
  },
  {
    title: "Location",
    path: "/planning/location/:locationId",
    component: PlanningPage,
  },
  {
    title: "Location",
    path: "/planning/favorites",
    component: PlanningPage,
  },

  {
    title: "Perfect Week Stats",
    path: "/admin/tw-stats",
    role: "admin",
    component: PWStatsPage,
  },
  {
    title: "Typical Week",
    path: "/typical-week",
    component: UserPlanningTemplatePage,
  },

  // My team page
  {
    title: "Team Page",
    path: "/myteam",
    component: MyTeamPage,
  },

  // Explorer page
  {
    title: "Directory",
    path: "/directory",
    component: DirectoryPage,
  },

  // User page
  {
    title: "User",
    path: "/user/:userId",
    component: UserPage,
  },

  // Settings Current User page
  {
    title: "User",
    path: "/settings",
    component: CurrentUserSettingsPage,
  },

  // Listing groups page
  {
    title: "Groups",
    path: "/groups",
    component: GroupsPage,
  },

  // Individual group page
  {
    title: "Group page",
    path: "/group/:groupId",
    component: GroupPage,
  },

  // Advanced search results
  {
    title: "Advanced Search",
    path: "/advanced-search/results/:locationId/:timestamp",
    component: AdvancedSearchResultsPage,
  },
  // Admin page
  {
    title: "Admin page",
    path: "/admin",
    component: AdminPage,
    role: "admin",
  },
  {
    title: "Admin settings page",
    path: "/admin/settings",
    component: CompanySettingsPage,
    role: "admin",
  },
  {
    title: "Admin labels page",
    path: "/admin/labels",
    component: CompanyLabelsPage,
    role: "admin",
  },
  {
    title: "Admin locations page",
    path: "/admin/locations",
    component: AdminLocationsPage,
    role: "admin",
  },
  {
    title: "Admin users page",
    path: "/admin/users",
    component: UsersPage,
    role: "admin",
  },
  {
    title: "Integrations",
    path: "/admin/integrations",
    component: IntegrationsPage,
    role: "admin",
  },
  {
    title: "Floor plans",
    path: "/admin/floor-plans",
    component: FloorPlansAdminPage,
    role: "admin",
  },
  {
    title: "Add Users From CSV",
    path: "/admin/users/add/csv",
    component: AddEmployeesFromCSV,
    role: "admin",
  },
  {
    title: "Manage Team",
    path: "/admin/manage-team/:userId",
    component: ManageTeam,
    role: "admin",
  },
  {
    title: "Services",
    path: "/admin/services",
    component: ServicesPage,
    role: "admin",
  },
  {
    title: "Hybrid Work Policy",
    path: "/admin/hybrid-policy",
    component: HybridWorkPolicy,
    role: "admin",
  },
  // Events page
  {
    title: "Events",
    path: "/events/:eventId",
    component: EventsPage,
  },
  {
    title: "Events",
    path: "/events/",
    component: EventsPage,
  },
  {
    title: "Meeting Rooms",
    path: "/meeting-rooms",
    component: MeetingRoomsPage,
  },
  {
    title: "Statistics",
    path: "/statistics",
    component: StatisticsPage,
  },
  // Listing favorites page
  {
    title: "Favorites",
    path: "/favorites",
    component: FavoritesPage,
  },
  // Bulk change status
  {
    title: "Special days",
    path: "/admin/special-days",
    component: BulkChangeStatusPage,
    role: "admin",
  },
  {
    title: "Signup Transactable",
    path: "/signup-transactable",
    component: TransactableRedirectHandler,
  },

  ...devRoutes,
]

export default routes
