import ButtonResetSearch from "components/ButtonResetSearch/ButtonResetSearch"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { Button, colors, L12GreyBold, P14Bold, RemovableChipButton } from "ui"

const TextInput = styled.form`
  width: 100%;
  height: 40px;
  border: 1px solid ${colors.grey3};
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 10px 0px 10px 10px;

  &:focus-within {
    border: 2px solid ${colors.green};
  }
`

const StyledGreyBold = styled(L12GreyBold)`
  margin-bottom: 8px;
`

const SearchInputText = styled.input`
  border: none;
  background: inherit;
  outline: none;
  width: 100%;
  color: ${colors.black};
  text-decoration: none;

  font-size: 14px;
  line-height: 18px;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${colors.grey2};
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${colors.grey2};
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${colors.grey2};
  }
`

const ResultsList = styled.div`
  max-width: 770px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  margin-top: 30px;
`

interface PropsType {
  placeholder?: string
  searchString: string
  emails: string[]
  onChange: (str: string) => void
  resetForm: () => void
  onValidation: (email: string) => void
  onRemove: (email: string) => void
  id?: string | undefined
}

export default function InviteByEmail({
  id,
  placeholder,
  searchString,
  emails,
  onChange,
  onValidation,
  resetForm,
  onRemove,
}: PropsType) {
  const t = useTranslate()

  function validateEmail(email: string) {
    const re = /\S+@\S+\.\S+/
    return re.test(email)
  }

  const [isEmail, setIsEmail] = useState<boolean>(true)
  const [isEmailAlreadyPresent, setIsEmailAlreadyPresent] =
    useState<boolean>(false)

  const onConfirm = () => {
    if (validateEmail(searchString)) {
      setIsEmail(true)
      if (emails.includes(searchString)) {
        setIsEmailAlreadyPresent(true)
        resetForm()
      } else {
        setIsEmailAlreadyPresent(false)
        onValidation(searchString)
        resetForm()
      }
    } else setIsEmail(false)
  }
  return (
    <>
      <>
        <StyledGreyBold>{t("invite by email")}</StyledGreyBold>
        <TextInput>
          <SearchInputText
            id={id}
            placeholder={placeholder}
            onChange={(event) => {
              onChange(event.target.value)
            }}
            value={searchString}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                e.preventDefault()
                onConfirm()
              }
            }}
          />
          {searchString !== "" && (
            <ButtonResetSearch
              resetForm={resetForm}
              handleClearForm={resetForm}
            />
          )}
          <Button onClick={onConfirm}>{t("Add")}</Button>
        </TextInput>
        {!isEmail && (
          <P14Bold style={{ color: colors.redAlert }}>
            {t("valid email")}
          </P14Bold>
        )}
        {isEmailAlreadyPresent && (
          <P14Bold style={{ color: colors.redAlert }}>
            {t("email already present")}
          </P14Bold>
        )}
      </>
      <ResultsList>
        {emails.map((email) => (
          <RemovableChipButton key={email} onRemove={() => onRemove(email)}>
            {email}
          </RemovableChipButton>
        ))}
      </ResultsList>
    </>
  )
}
