import PageContent from "components/Layout/PageLayout/PageContent/PageContent"
import AdminPageTitle from "components/Layout/PageLayout/PageHeader/AdminPageTitle/AdminPageTitle"
import PageHeader from "components/Layout/PageLayout/PageHeader/PageHeader"
import PageLayout from "components/Layout/PageLayout/PageLayout"
import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"

import AdminUsersPageContent from "./AdminUsersPageContent"
import AdminAddUsersButton from "./NewEmployees/AdminAddUsersButton"

const TitleAction = styled.div`
  margin-top: auto;
  margin-left: auto;
`

export default function UsersPage() {
  const t = useTranslate()
  const { user } = useUser()
  const breadcrumb = [
    {
      name: t("Admin"),
      path: "/admin",
    },
    {
      name: t("Users"),
      path: "/admin/users",
    },
  ]

  if (user?.role !== "admin") return null

  return (
    <PageLayout contentNotScrollable>
      <PageHeader>
        <AdminPageTitle title={t("Users")} breadcrumbs={breadcrumb} />
        <TitleAction>
          <AdminAddUsersButton />
        </TitleAction>
      </PageHeader>
      <PageContent>
        <AdminUsersPageContent />
      </PageContent>
    </PageLayout>
  )
}
