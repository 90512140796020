import { Divider as MuiDivider } from "@mui/material"
import { spacing } from "@mui/system"
import React from "react"
import styled from "styled-components/macro"

const Divider = styled(MuiDivider)(spacing)

export default function DividerComponent({ spacing = 6 }) {
  return <Divider my={spacing} />
}
