import React, { useContext, useState } from "react"

interface SettingsContextType {
  shouldDisplaySettings: boolean
  setShouldDisplaySettings: (bool: boolean) => void
}

const emptyFunc = () => {
  //
}

const initialSettingsContext: SettingsContextType = {
  // main filters
  shouldDisplaySettings: false,
  setShouldDisplaySettings: emptyFunc,
}

const SettingsContext = React.createContext(initialSettingsContext)

interface PropsType {
  children: React.ReactNode
}

export default function HomeContextProvider({ children }: PropsType) {
  const [shouldDisplaySettings, setShouldDisplaySettings] = useState(false)

  const value: SettingsContextType = {
    shouldDisplaySettings,
    setShouldDisplaySettings,
  }

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  )
}

export function useSettingsContext() {
  return useContext(SettingsContext)
}
