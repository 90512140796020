import useSetUserIntegrationFlag from "graphql/integrations/useSetUserIntegrationFlag"
import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { CompanyIntegration } from "types"
import { P14, Toggle } from "ui"

const Layout = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`

interface WriteSlotsEventsFlagType {
  googleEvents?: boolean
  microsoftOutlook?: boolean
}

interface PropsType {
  integration: CompanyIntegration
}

export default function WriteSlotsEventsToggle({ integration }: PropsType) {
  const t = useTranslate()
  const { user: me } = useUser()
  const setFlag = useSetUserIntegrationFlag()

  const getSelectedIntegrationWriteSlotEvents =
    (): WriteSlotsEventsFlagType => {
      const storedWriteSlotsEvents: WriteSlotsEventsFlagType =
        me?.flags.writeSlotsEvents

      if (!storedWriteSlotsEvents) return {}
      if (integration.name === "google-events")
        return {
          ...storedWriteSlotsEvents,
          googleEvents: Boolean(storedWriteSlotsEvents.googleEvents),
        }
      if (integration.name === "microsoft-outlook")
        return {
          ...storedWriteSlotsEvents,
          microsoftOutlook: Boolean(storedWriteSlotsEvents.microsoftOutlook),
        }
      return { ...storedWriteSlotsEvents }
    }

  const getChecked = () => {
    if (integration.name === "google-events")
      return Boolean(getSelectedIntegrationWriteSlotEvents().googleEvents)
    if (integration.name === "microsoft-outlook")
      return Boolean(getSelectedIntegrationWriteSlotEvents().microsoftOutlook)
    return false
  }

  return (
    <Layout>
      <P14>{t("writeSlotsEvents-toggle")}</P14>
      <Toggle
        checked={getChecked()}
        variant="small"
        onChange={() => {
          setFlag({
            name: integration.name,
            key: "writeSlotsEvents",
            value: !getChecked(),
          })
        }}
      />
    </Layout>
  )
}
