import FavoriteCheckbox from "components/FavoriteCheckbox"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useUser from "graphql/users/useUser"
import React, { useCallback } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { useTeamsAppContext } from "TeamsApp/TeamsAppContext"
import { colors, P16 } from "ui"
import { ArrowLeft } from "ui/icons"
import mediaQueries from "ui/mediaQueries"
import transitions from "ui/transitions"

interface LayoutProps {
  isClickable: boolean
  canGoBack: boolean
}

const Layout = styled.div<LayoutProps>`
  display: grid;
  align-items: center;
  width: 100%;
  min-height: 40px;
  background: hsla(${colors.blackReal}, 0);

  padding: ${({ canGoBack }) => (canGoBack ? "0 6px 0 16px" : "0 6px 0 32px")};

  grid-template-columns: ${({ canGoBack }) =>
    canGoBack ? "24px 24px auto auto" : "24px auto auto"};

  cursor: ${({ isClickable }) => (isClickable ? "pointer" : "auto")};

  .back-button {
    height: 18px;
    width: 18px;
    cursor: pointer;
  }

  > p {
    padding: 0 22px 0 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    > em {
      font-style: normal;
      color: ${colors.grey1};
      padding-left: 6px;
    }
  }

  .actions {
    > * {
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
    }

    > .nudge {
      visibility: visible;
      opacity: 1;
      pointer-events: all;
    }
  }

  &:hover {
    background: hsla(${colors.blackReal}, 0.04);
    .actions {
      > * {
        visibility: visible;
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  @media ${mediaQueries.isNarrowScreen} {
    padding: 0 8px 0 16px;
  }

  transition: ${transitions.generic};
`

const ActionsLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;

  svg {
    width: 16px;
    height: 16px;
  }

  @media ${mediaQueries.isNarrowScreen} {
    gap: 2px;
  }

  transition: ${transitions.generic};
`

interface PropsType {
  children: React.ReactNode
  icon?: JSX.Element
  path: string | undefined
  itemId: string
  type: "group" | "location"
  actions?: JSX.Element[]
}

export function GroupOrLocationHeader({
  children,
  icon,
  path,
  itemId,
  type,
  actions = [],
}: PropsType) {
  const history = useHistory()
  const { user: me } = useUser()
  const { isTeamsApp } = useTeamsAppContext()
  const { activeLocation } = usePlanningContext()

  const isMe = me && me.id === itemId

  const handleClick = useCallback(() => {
    if (isTeamsApp) {
      //
    } else {
      if (path) history.push(path)
    }
  }, [isTeamsApp, history, path])

  const locationPlanningFrom =
    (activeLocation.triggeredFrom === "active-locations-list" &&
      activeLocation.location &&
      type === "location") ??
    false

  const displayFavoriteCheckbox =
    !isTeamsApp && itemId !== "myteam" && itemId !== "mymanagerteam"

  return (
    <Layout
      isClickable={path && !isMe ? true : false}
      role="link"
      canGoBack={locationPlanningFrom}
      onClick={() => {
        if (!isMe) handleClick()
      }}
    >
      {locationPlanningFrom && (
        <ArrowLeft className="back-button" onClick={() => history.goBack()} />
      )}
      {icon}
      <P16>{children}</P16>
      <ActionsLayout className="actions" onClick={(e) => e.stopPropagation()}>
        {displayFavoriteCheckbox && (
          <FavoriteCheckbox className="favorite" itemId={itemId} type={type} />
        )}

        {actions.length > 0 && <>{actions.map((action) => action)}</>}
      </ActionsLayout>
    </Layout>
  )
}
