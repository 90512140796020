import { gql } from "@apollo/client"
import { UserBaseFragment } from "graphql/users/fragments/userFragment"

export const GroupFragment = gql`
  ${UserBaseFragment}
  fragment GroupFragment on Group {
    id
    name
    flags
    owner {
      ...UserBaseFragment
    }
    users {
      ...UserBaseFragment
    }
  }
`

export const SimpleGroupFragment = gql`
  ${UserBaseFragment}
  fragment SimpleGroupFragment on Group {
    id
    name
    flags
    owner {
      ...UserBaseFragment
    }
  }
`

export const GroupCompleteStatsFragment = gql`
  fragment GroupCompleteStatsFragment on GroupCompleteStats {
    groupId
    groupName
    groupSize
    home
    office
    ooc
    else
    off
    null
    total
    completion
  }
`
