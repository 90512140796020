import SlotsEditorLocationsList from "components/Collapses/LocationsCollapse/SlotsEditorLocationsList"
import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import SearchBarForm from "components/Search/SearchBarForm/SearchBarForm"
import useLocationsTree from "graphql/locations/useLocationsTree"
import useOccupations from "graphql/slots/useOccupations"
import useUser from "graphql/users/useUser"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { colors, Dialog, Label, LU12, mediaQueries, TextField } from "ui"
import { Search as SearchIcon } from "ui/icons"
import isIphone from "utils/isIphone"
import LocationTree, { getChildrenLocations } from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

const Fieldset = styled.div`
  display: flex;
  flex-direction: column;
`

const AlertText = styled(LU12)`
  color: ${colors.redAlert};
  margin: 4px 0 8px 0;
`

const DialogContent = styled.div`
  padding: 8px;
  min-height: 70vh;
  max-height: 70vh;
  height: 70vh;
  display: grid;
  grid-template-rows: auto 1fr;

  > :nth-child(1) {
    // locations search
    padding: 8px;
  }

  > :nth-child(2) {
    // locations list
    overflow-y: scroll;
    max-height: calc(70vh - 56px);
  }

  @media ${mediaQueries.isMobile} {
    min-height: unset;
    max-height: unset;
    height: unset;

    > :nth-child(2) {
      // locations list
      max-height: calc(
        100vh - 130px - env(safe-area-inset-bottom, 0) -
          env(safe-area-inset-top, 0)
      );
    }
  }
`

interface PropsTypes {
  AM: UTCDate
  PM: UTCDate
  hasRoom: boolean
  selectedLocation: LocationTree | undefined
  guestIds: string[]
  setSelectedLocation: (location?: LocationTree) => void
}

export default function TeamDayLocations({
  AM,
  PM,
  hasRoom,
  selectedLocation,
  guestIds,
  setSelectedLocation,
}: PropsTypes) {
  const t = useTranslate()
  const { user: me } = useUser()
  const { isMobile } = useMediaQueries()

  const { error, loading, occupations } = useOccupations(
    AM.getTime(),
    PM.getTime()
  )
  const [openLocationPicker, setOpenLocationPicker] = useState(false)
  const { locations } = useLocationsTree()
  const selectedLocationAsLocationtree = selectedLocation
    ? LocationTree.getLocationNode(locations, selectedLocation.id)
    : undefined
  const [searchString, setSearchString] = useState("")
  const [locationsFromSearch, setLocationsFromSearch] = useState<
    LocationTree[]
  >([])

  const filterLocations = (searchString: string) => {
    if (searchString === "") return setLocationsFromSearch([])
    const filteredLocations = getChildrenLocations(locations, false).filter(
      (l) => l.name.toLowerCase().includes(searchString.toLowerCase())
    )
    setLocationsFromSearch(filteredLocations)
  }

  const resetSearchForm = () => {
    setSearchString("")
    setLocationsFromSearch([])
  }

  return (
    <>
      <Fieldset>
        <Label>{t("Places")}</Label>
        <TextField
          handleChange={() => {
            //
          }}
          onClick={() => setOpenLocationPicker(true)}
          placeholder={t("search spaces")}
          value={
            !selectedLocationAsLocationtree
              ? ""
              : selectedLocationAsLocationtree.name
          }
        />
      </Fieldset>
      {selectedLocationAsLocationtree && !hasRoom && (
        <AlertText>{t("No room in the selected location")}</AlertText>
      )}

      {openLocationPicker && (
        <Dialog
          open
          title={t("select a location")}
          fullscreen={isMobile}
          onClose={() => {
            setSearchString("")
            setOpenLocationPicker(false)
          }}
        >
          <DialogContent>
            <div>
              <SearchBarForm
                searchString={searchString}
                handleChange={(str) => {
                  setSearchString(str)
                  filterLocations(str)
                }}
                resetForm={resetSearchForm}
                icon={isIphone ? undefined : <SearchIcon />}
                placeholder={t("search for zone or space")}
                onSubmit={(e) => {
                  e.preventDefault()
                }}
                autoFocus
              />
            </div>
            {loading || error || !me ? (
              <LoaderOrErrorComponent loading={loading} error={error} />
            ) : (
              <SlotsEditorLocationsList
                from={AM}
                to={PM}
                guestIds={guestIds}
                selectedUser={me}
                hidePrivateLocationsEvenIfUserIsAuthorized
                occupations={occupations}
                disableEquipments
                selectedLocationId={selectedLocation?.id}
                externalLocations={
                  locationsFromSearch.length > 0
                    ? locationsFromSearch
                    : undefined
                }
                saveWorkingMode={(workingMode) => {
                  if (workingMode.locationId) {
                    setSelectedLocation(
                      LocationTree.getLocationNode(
                        locations,
                        workingMode.locationId
                      )
                    )
                    setOpenLocationPicker(false)
                    resetSearchForm()
                  }
                }}
              />
            )}
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
