import InteractionsMenu from "components/InteractionsMenu/InteractionsMenu"
import UserChipButton from "components/UserChipButton/UserChipButton"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { UserBase } from "types"
import { colors, LU12, LU14, P14, P16 } from "ui"
import LocationTree from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

const GreyText = styled(P16)`
  color: ${colors.grey2};
`

const SmallGreyText = styled(P14)`
  color: ${colors.grey2};
  margin-left: auto;
`

interface PropsType {
  users: UserBase[]
  date: UTCDate
  location: LocationTree
  title: string
}

export default function HalfDay({ users, title, location, date }: PropsType) {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()

  const to = new UTCDate(date, "NEXT-HALF-DAY")

  if (isMobile) {
    return (
      <>
        <div className="time-container">
          <LU14>{title}</LU14>
          <InteractionsMenu
            users={users}
            location={location}
            from={date}
            to={to}
          />
        </div>
        {users.length > 0 && (
          <div className="location">
            <div className="blue-circle" />
            <LU12>{t("at work").toUpperCase()}</LU12>
            <SmallGreyText>
              {users.length} {t(users.length > 1 ? "persons" : "person")}
            </SmallGreyText>
          </div>
        )}
        <div className="users-list">
          {users.length > 0 ? (
            users.map((user) => <UserChipButton key={user.id} user={user} />)
          ) : (
            <GreyText>{t("no user simple")}</GreyText>
          )}
        </div>
      </>
    )
  } else
    return (
      <>
        <div className="time-container">
          <LU14>{title}</LU14>
          <InteractionsMenu
            users={users}
            location={location}
            from={date}
            to={to}
          />
        </div>
        <div className="location">
          <div className="blue-circle" />
          <LU12>{t("at work").toUpperCase()}</LU12>
          <SmallGreyText>
            {users.length} {t(users.length > 1 ? "persons" : "person")}
          </SmallGreyText>
        </div>
        <div className="users-list">
          {users.length > 0 ? (
            users.map((user) => <UserChipButton key={user.id} user={user} />)
          ) : (
            <P16>{t("no user simple")}</P16>
          )}
        </div>
      </>
    )
}
