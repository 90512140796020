import { ApolloError, gql, useQuery } from "@apollo/client"
import { UserBaseFragment } from "graphql/users/fragments/userFragment"
import { UserBase } from "types"

const QUERY = gql`
  ${UserBaseFragment}
  query getUserSubscriptions {
    userSubscriptions {
      ...UserBaseFragment
    }
  }
`

export default function useUserSubscriptions(): {
  loading: boolean
  error?: ApolloError
  subscriptions: UserBase[]
} {
  const { loading, error, data } =
    useQuery<{ userSubscriptions: UserBase[] }>(QUERY)
  const subscriptions: UserBase[] = data?.userSubscriptions ?? []
  return {
    subscriptions,
    loading,
    error,
  }
}
