import { LinearProgress } from "@mui/material"
import IntegrationLayout from "components/IntegrationsLayout/IntegrationLayout"
import useSetupCompanyIntegration from "graphql/integrations/useSetupCompanyIntegration"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { CompanyIntegration } from "types"
import { Button, Dialog, P16, TextField } from "ui"
import { Lucca } from "ui/icons"

const Container = styled.div`
  // width: 600px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
`

const TextContainer = styled.div`
  margin-left: 12px;
  margin-bottom: 18px;
`
interface PropsType {
  integration?: CompanyIntegration
}

export default function LuccaIntegrationComponent({ integration }: PropsType) {
  const t = useTranslate()
  const setupCompanyIntegration = useSetupCompanyIntegration()

  const [open, setOpen] = useState(false)
  const [instanceName, setInstanceName] = useState(
    integration?.externalId || ""
  )
  const [apiToken, setApiToken] = useState(integration ? "********" : "")
  const [loading, setLoading] = useState(false)

  const onClose = () => {
    setOpen(false)
  }

  const onSave = () => {
    onClose()
    setLoading(true)
    setupCompanyIntegration({
      name: "lucca",
      externalId: instanceName,
      token: apiToken,
    }).then(() => {
      setLoading(false)
    })
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        title={t("connect to lucca")}
        rightButton={
          <Button
            icon={<Lucca />}
            disabled={
              instanceName.length === 0 ||
              apiToken.length === 0 ||
              apiToken === "********"
            }
            onClick={onSave}
          >
            {t("Save")}
          </Button>
        }
      >
        <Container>
          <P16>
            {t("find documentation")}{" "}
            <a
              href="https://support.lucca.fr/hc/fr/articles/204866061-Fonctionnement-des-API-Lucca"
              target="_blank"
              rel="noreferrer"
            >
              {t("here")}
            </a>{" "}
            {t("know how to retrieve the needed information")}
          </P16>
          <TextField
            initialValue={instanceName}
            placeholder={t("company instance name at Lucca")}
            handleChange={(str: string) => setInstanceName(str)}
          />
          <TextField
            initialValue={apiToken}
            placeholder="API Token"
            handleChange={(str: string) => setApiToken(str)}
          />
        </Container>
      </Dialog>
      <IntegrationLayout connected={Boolean(integration)} title="Lucca">
        {integration && (
          <TextContainer>
            <P16>{t("offishall linked to lucca")}</P16>
            <P16>
              {t("lucca instance name").replace(
                "{extranalID}",
                integration.externalId
              )}
            </P16>
          </TextContainer>
        )}
        {!integration && (
          <TextContainer>
            <P16>{t("offishall not linked to lucca")}</P16>
          </TextContainer>
        )}
        <Button
          icon={<Lucca />}
          disabled={loading || open}
          secondary
          onClick={() => {
            setOpen(true)
          }}
        >
          {integration ? t("refresh access") : t("connect to lucca")}
        </Button>
        {loading && <LinearProgress />}
      </IntegrationLayout>
    </>
  )
}
