import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function OffishallLogo(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="133" height="24" viewBox="0 0 133 24">
      <path
        d="M121.721 23.9593C123.256 23.9593 124.5 22.7186 124.5 21.1882C124.5 19.6577 123.256 18.417 121.721 18.417C120.186 18.417 118.942 19.6577 118.942 21.1882C118.942 22.7186 120.186 23.9593 121.721 23.9593Z"
        fill="#00DCA3"
      />
      <path
        d="M44.9976 6.49275C44.9976 4.18384 46.3801 3.28649 48.4381 3.28649C50.1742 3.28649 51.3324 3.83123 52.5857 4.7297L53.9422 2.38802C52.1415 0.918817 50.2762 0.14353 47.7683 0.14353C43.7499 0.14353 41.5355 2.3236 41.5355 6.29949V9.3803H33.97V5.78753C33.97 4.15219 34.8698 3.28649 36.3169 3.28649C37.0007 3.27756 37.6791 3.40833 38.3103 3.67075L39.3064 0.977585C37.9567 0.368431 36.9266 0.14353 35.5769 0.14353C32.2973 0.14353 30.4649 2.35638 30.4649 5.5954V9.3803H23.8717C22.8461 3.81993 18.3086 0 12.0452 0C4.96812 0 0 4.97269 0 11.826C0 18.512 4.96812 23.7197 12.0452 23.7197C18.9954 23.7197 23.8196 18.7945 24.0791 12.3741L30.4649 12.3605V23.6802H33.97V12.3605H41.5253V23.6802H45.0304V12.3605H51.9103V23.6802H55.4154V9.3803H44.9976V6.49275ZM10.155 3.6278C10.6388 3.51341 11.1318 3.44118 11.6282 3.41194L11.7993 3.40516C12.1472 3.39725 12.4985 3.40516 12.8487 3.41985C16.475 3.68431 18.9296 6.08589 19.7229 9.39838H16.3345C11.907 9.39838 8.55596 8.7282 8.16726 6.17856C7.96328 4.83933 8.79508 4.00075 10.155 3.6278ZM12.0452 20.2705C7.21421 20.2705 4.11253 16.428 4.11253 11.826C4.1049 10.5344 4.36574 9.2553 4.8786 8.06932C5.03031 8.37893 5.20533 8.67664 5.40215 8.95988C5.73742 9.43561 6.13796 9.86206 6.59206 10.2268C7.07802 10.612 7.61114 10.9341 8.17859 11.1852C8.83274 11.4734 9.51318 11.6982 10.2105 11.8565C11.04 12.0455 11.8812 12.1795 12.7286 12.2577C13.7405 12.3571 14.7582 12.4001 15.7758 12.4102C16.2847 12.4102 18.1704 12.3989 19.9881 12.3831C19.7512 16.7342 16.7142 20.2705 12.0452 20.2705Z"
        fill="#220075"
      />
      <path
        d="M65.0006 15.1825C63.4571 14.6367 62.3941 14.0919 62.3941 13.1618C62.3941 12.2645 63.3279 11.9435 64.4204 11.9435C65.3848 11.9435 66.5429 12.2328 67.6036 12.7775L68.8253 10.437C67.4089 9.58756 65.7852 9.14375 64.1325 9.15427C61.3992 9.15427 59.0522 10.5331 59.0522 13.1618C59.0522 15.5024 60.8201 16.689 63.1036 17.5547C64.5178 18.1006 65.8687 18.6125 65.8687 19.6715C65.8687 20.57 65.0323 21.1464 63.7461 21.1464C62.3526 21.1292 60.9926 20.7181 59.824 19.9608L58.4414 22.4291C60.1129 23.4869 61.8819 24 64.0034 24C67.1549 24 69.3092 22.333 69.3092 19.6715C69.3092 17.2982 67.5085 16.1115 65.0006 15.1825Z"
        fill="#220075"
      />
      <path
        d="M80.2097 8.99378C78.12 8.99378 76.7057 9.89112 75.8048 11.3027C75.8365 10.437 75.8365 9.79506 75.8365 9.12261V0.592194H72.3325V23.6802H75.8365V16.3048C75.8365 14.0275 77.0264 12.1356 79.3087 12.1356C81.046 12.1356 81.7849 13.2579 81.7849 14.9904V23.6802H85.29V14.7011C85.29 11.1738 83.5856 8.99378 80.2097 8.99378Z"
        fill="#220075"
      />
      <path
        d="M94.9652 9.12262C93.0387 9.12262 91.0748 9.63458 88.9523 10.5918L89.9495 13.2522C91.4284 12.547 93.0093 12.0983 94.419 12.0983C96.2515 12.0983 97.345 12.8363 97.345 14.3112V14.952C96.8306 14.9203 96.2832 14.8875 95.5443 14.8875C91.1712 14.8875 88.2769 16.6834 88.2769 19.9552C88.2769 22.4879 90.1422 23.9627 92.7792 23.9627C95.1262 23.9627 96.8306 23.0326 97.8913 21.525L98.5338 23.6723H100.882V14.4445C100.885 10.9817 98.6347 9.12262 94.9652 9.12262ZM93.8116 21.3385C92.5254 21.3385 91.7536 20.7293 91.7536 19.6071C91.7536 18.0362 93.2325 17.1377 95.7721 17.1377C96.3311 17.1361 96.8897 17.1685 97.4448 17.2349C97.3802 19.832 95.8718 21.3385 93.8116 21.3385Z"
        fill="#220075"
      />
      <path
        d="M104.809 0.592194V23.6802H108.345L108.314 0.592194H104.809Z"
        fill="#220075"
      />
      <path
        d="M115.967 0.592194H112.461V23.6802H115.998L115.967 0.592194Z"
        fill="#220075"
      />
    </SvgIcon>
  )
}
