import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { User } from "types"
import { P16, Toggle } from "ui"

const UserIsDisabledDiv = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;
  gap: 8px;
`

interface PropsType {
  user: User
  updateChanges: (value: { isDisabled?: boolean }) => void
}

export default function UserDisabledStatus({ user, updateChanges }: PropsType) {
  const t = useTranslate()

  const [isActive, setIsActive] = useState<boolean>(!Boolean(user.disabled))

  const getLabel = () => {
    if (user.disabled) return t("user is disabled")
    return t("user is active")
  }

  const handleChange = (isActive: boolean) => {
    setIsActive(isActive)
    updateChanges({
      isDisabled: !isActive,
    })
  }

  return (
    <UserIsDisabledDiv>
      <P16>{getLabel()}</P16>
      <Toggle checked={isActive} onChange={handleChange} />
    </UserIsDisabledDiv>
  )
}
