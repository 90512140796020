import useRevokeCompanyIntegration from "graphql/integrations/useRevokeCompanyIntegration"
import React from "react"
import { CompanyIntegration } from "types"
import { Toggle } from "ui"

interface PropsType {
  integration: CompanyIntegration | undefined
}

export default function RevokeCompanyWideIntegrationToggle({
  integration,
}: PropsType) {
  const revokeCompanyIntegration = useRevokeCompanyIntegration()

  return (
    <Toggle
      checked={integration !== undefined}
      disabled={integration === undefined}
      onChange={() => {
        if (integration !== undefined)
          revokeCompanyIntegration({ name: integration.name })
      }}
    />
  )
}
