import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import Divider from "components/Divider"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components/macro"
import { Button, colors, Grid, P14 } from "ui"

import CSVDisplayer from "./CSVDisplayer"

const Container = styled.div`
  gap: 24px;
  max-width: 100%;
`

const StyledFormControl = styled(FormControl)`
  display: flex;
  flex-direction: column;
  cursor: auto;
  max-width: 100%;
  flex-wrap: wrap;
`

const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
  }
`

const P14Styled = styled(P14)`
  color: ${colors.grey1};
  margin-bottom: 24px;
`

const RadioStyling = {
  color: colors.purple,
  "&.Mui-checked": {
    color: colors.purple,
  },
}

interface PropsType {
  csv: string[][]
  hasColumnNames: boolean
  currentStep: number
  setHasColumnNames: (cn: boolean) => void
  onPrevious: () => void
  onNext: () => void
}

export function CSVFirstLineChecker({
  csv,
  hasColumnNames,
  currentStep,
  setHasColumnNames,
  onPrevious,
  onNext,
}: PropsType) {
  const t = useTranslate()

  const [isColumnName, setIsColumnName] = useState(hasColumnNames)
  return (
    <Container>
      <StyledFormControl>
        <P14Styled className="explanation-text">
          {t("Does the first row of your csv contain the column names?")}
        </P14Styled>
        <RadioGroup
          row
          value={isColumnName}
          onChange={() => setIsColumnName((oldValue) => !oldValue)}
        >
          <StyledFormControlLabel
            value={true}
            label={t("Yes, first line is column names")}
            control={<Radio sx={{ ...RadioStyling }} />}
          />
          <StyledFormControlLabel
            value={false}
            label={t("No, first line is just like the others")}
            control={<Radio sx={{ ...RadioStyling }} />}
          />
        </RadioGroup>
      </StyledFormControl>
      <CSVDisplayer csv={csv} currentStep={currentStep} />
      <Divider />
      <Grid container spacing={6} justifyContent="center">
        <Grid item>
          <Button secondary onClick={onPrevious}>
            {t("Previous")}
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => {
              setHasColumnNames(isColumnName)
              onNext()
            }}
          >
            {t("Next")}
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}
