import { gql, useMutation } from "@apollo/client"

import { GroupFragment } from "./fragments/groupFragment"
import updateGroupInCache from "./updateGroupInCache"

const RENAME_GROUP_MUTATION = gql`
  ${GroupFragment}
  mutation renameGroup($groupId: ID!, $name: String!) {
    renameGroup(groupId: $groupId, name: $name) {
      ...GroupFragment
    }
  }
`

export default function useRenameGroup() {
  const [mutate] = useMutation(RENAME_GROUP_MUTATION, {
    update: (cache, { data }) =>
      updateGroupInCache({ cache, data, mutationName: "renameGroup" }),
  })

  return async (groupId: string, name: string) => {
    try {
      await mutate({ variables: { groupId, name } })
    } catch (e) {
      console.error(e.message)
    }
  }
}
