import { ApolloError, gql, useApolloClient, useQuery } from "@apollo/client"
import UTCDate from "utils/UTCDate"

const QUERY = gql`
  query stats_completionRate(
    $from: String!
    $to: String!
    $groupIds: [String]!
    $userIds: [String]!
    $includeWeekends: Boolean!
  ) {
    stats_completionRate(
      from: $from
      to: $to
      groupIds: $groupIds
      userIds: $userIds
      includeWeekends: $includeWeekends
    )
  }
`

interface PropsType {
  from: UTCDate
  to: UTCDate
  groupIds: string[]
  userIds: string[]
  includeWeekends: boolean
}

export default function useCompletionRate({
  from,
  to,
  groupIds,
  userIds,
  includeWeekends,
}: PropsType): {
  loading: boolean
  error?: ApolloError
  completionRate: number
} {
  const { loading, error, data } = useQuery(QUERY, {
    variables: {
      from: `${from.getTime()}`,
      to: `${to.getTime()}`,
      groupIds,
      userIds,
      includeWeekends,
    },
  })
  return {
    completionRate: data?.stats_completionRate || 0,
    loading,
    error,
  }
}

export function useCompletionRateAsync() {
  const client = useApolloClient()
  return ({ from, to, groupIds, userIds, includeWeekends }: PropsType) => {
    return client.query({
      query: QUERY,
      fetchPolicy: "no-cache",
      variables: {
        from: `${from.getTime()}`,
        to: `${to.getTime()}`,
        groupIds,
        userIds,
        includeWeekends,
      },
    })
  }
}
