import useBookingMaxDate from "hooks/useBookingMaxDate"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { colors, InfoTooltip, LU12, mediaQueries, P14 } from "ui"
import getColorFromStatus from "utils/getColorFromStatus"
import getLabelFromStatus from "utils/getLabelFromStatus"
import UTCDate from "utils/UTCDate"

import RestrictedDays from "../RestrictedDays/RestrictedDays"

interface ContainerProps {
  pathname: string
}

export const TitleContainer = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  ${({ pathname }) => {
    if (pathname.includes("/special-days")) `padding: 0px;`
    else return `padding: 10px 16px 0 16px;`
  }}

  > div:last-child {
    display: flex;
  }

  svg {
    width: 14px;
    aspect-ratio: 1;
    stroke: ${colors.grey1};
  }

  @media ${mediaQueries.isMobile} {
    padding: 10px 8px 0 8px;
  }

  .hybrid-rules-reminder {
    padding: 4px 8px;
    gap: 8px;
    background: rgba(255, 0, 0, 0.04);
    border-radius: 4px;
    display: flex;
    align-items: center;
    svg {
      color: ${colors.redAlert};
      stroke: ${colors.redAlert};
    }
  }
`

export const ColorPoint = styled.div`
  height: 11px;
  width: 11px;
  ${({ color }) => `background: ${color};`};
  border-radius: 50px;

  &.color-point {
    &--home {
      background: ${getColorFromStatus("home")};
    }
    &--office {
      background: ${getColorFromStatus("office")};
    }
    &--else {
      background: ${getColorFromStatus("else")};
    }
    &--off {
      background: ${getColorFromStatus("off")};
    }
    &--ooc {
      background: ${getColorFromStatus("ooc")};
    }
    &--null {
      background: ${getColorFromStatus("null")};
    }
  }
`

const ErrorParagraph = styled(P14)`
  color: ${colors.error};
`

interface PropsType {
  status: string
  error?: string
  children?: string
  from: UTCDate
  to: UTCDate
}

export default function StatusTitle({
  status,
  error,
  children,
  from,
  to,
}: PropsType) {
  const t = useTranslate()
  const bookingMaxDate = useBookingMaxDate()

  const getTooltipTitle = (status: string) => {
    switch (status) {
      case "home":
        return t("home-tooltip-title")
      case "office":
        return t("office-tooltip-title")
      case "else":
        return t("else-tooltip-title")
      case "off":
        return `${t("off-tooltip-title")} \n ${t("ooc-tooltip-title")}`
      case "ooc":
        return t("ooc-tooltip-title")
      case "office-recurrence-warning":
        return t("desk-booking-recurrence-warning--tooltip", {
          date: bookingMaxDate.format("DD/MM/YY"),
        })
      default:
        return ""
    }
  }

  return (
    <TitleContainer pathname={location.pathname}>
      <ColorPoint className={`color-point--${status}`} />
      <LU12>
        {children !== undefined ? children : t(getLabelFromStatus(status))}
      </LU12>

      {error && <ErrorParagraph>{error}</ErrorParagraph>}
      <InfoTooltip
        text={getTooltipTitle(error ? "office-recurrence-warning" : status)}
      />
      {status === "home" && <RestrictedDays from={from} to={to} />}
    </TitleContainer>
  )
}
