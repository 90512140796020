import { LinearProgress } from "@mui/material"
import useStopDemo from "graphql/registration/useStopDemo"
import useUser from "graphql/users/useUser"
import useCompanyFlag from "hooks/useCompanyFlag"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { Button, Button as OButton, Dialog, P16 } from "ui"

const ButtonStyled = styled(OButton)`
  height: auto;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
`

const StyledButton = styled(Button)`
  width: 200px;
`

export default function StopDemoButton() {
  const t = useTranslate()
  const { user } = useUser()
  const [open, setOpen] = useState(false)
  const demoActive = Boolean(useCompanyFlag("demo", { active: false })?.active)
  const { loading, stopDemo } = useStopDemo()
  const handleYes = () => {
    window.open("https://meetings.hubspot.com/pierre-godret", "_blank")
    handleNo()
  }
  const handleNo = () => {
    stopDemo().then(handleClose)
  }
  const handleClose = () => {
    if (!loading) {
      setOpen(false)
    }
  }
  if (user && user.role === "admin" && demoActive === true) {
    return (
      <>
        <Dialog
          open={open}
          onClose={handleClose}
          title={t("demo-leave-popup-title")}
          leftButton={
            <StyledButton danger onClick={handleNo} disabled={loading}>
              {t("demo-leave-popup-no")}
            </StyledButton>
          }
          rightButton={
            <StyledButton onClick={handleYes} disabled={loading}>
              {t("demo-leave-popup-yes")}
            </StyledButton>
          }
        >
          <Content>
            <P16>{t("demo-leave-popup-content")}</P16>
          </Content>
          {loading && <LinearProgress />}
        </Dialog>

        <div style={{ marginLeft: 8, marginRight: 8, marginBottom: 20 }}>
          <ButtonStyled
            onClick={() => {
              setOpen(true)
            }}
          >
            {t("Setup your organization")}
          </ButtonStyled>
        </div>
      </>
    )
  }
  return null
}
