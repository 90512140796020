import { ApolloError, gql, useQuery } from "@apollo/client"
import { UserWithTeam } from "types"

import { UserWithTeamFragment } from "./fragments/userFragment"

export const QUERY = gql`
  ${UserWithTeamFragment}
  query searchUsersWithTeams($search: String, $limit: Int, $offset: Int) {
    searchUsersWithTeams(search: $search, limit: $limit, offset: $offset) {
      ...UserWithTeamFragment
    }
  }
`

export default function useSearchUsersWithTeams(): {
  loading: boolean
  error?: ApolloError
  users: UserWithTeam[]
} {
  const { loading, error, data } = useQuery<{
    searchUsersWithTeams: UserWithTeam[]
  }>(QUERY)

  return {
    users: data?.searchUsersWithTeams || [],
    loading,
    error,
  }
}
