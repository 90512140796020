import EditManager from "components/UpdateManagerButton/EditManager"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components/macro"
import { User } from "types"
import { Button, colors, H2, H4, mediaQueries, P14Bold } from "ui"
import { Lock, Mail } from "ui/icons"
import getUserDisplayName from "utils/getUserDisplayName"

import MeAvatarPicker from "../MeAvatarPicker/MeAvatarPicker"
import UpdateInformationsDialog from "./UpdateInformationsDialog/UpdateInformationsDialog"

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  padding-bottom: 15px;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  padding: 32px 0 0 0;
  margin-bottom: 25px;

  @media ${mediaQueries.isMobile} {
    padding: 16px 0 0 0;
    flex-direction: column;
  }
`

const Email = styled.div`
  display: flex;
  align-items: center;

  p {
    color: ${colors.purple};
  }
`

const PasswordContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`

const CirclesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
  margin-left: 20px;
`

const CirclePassword = styled.div`
  height: 5px;
  width: 5px;
  border-radius: 5px;
  background-color: ${colors.purple};
`

const TitleLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;

  button {
    margin-top: 4px;
  }

  @media ${mediaQueries.isMobile} {
    gap: 8px;
  }
`

interface PropsType {
  user: User
  openUserInfosDialog: boolean
  setOpenUserInfosDialog: (bool: boolean) => void
}

function PersonalInformations(
  { user, openUserInfosDialog, setOpenUserInfosDialog }: PropsType,
  ref: React.Ref<HTMLDivElement>
) {
  const t = useTranslate()
  const { isMobile, isNarrowScreen } = useMediaQueries()
  const [openPhotoDialog, setOpenPhotoDialog] = useState(false)

  return (
    <Content>
      {!isMobile && !isNarrowScreen && (
        <TitleLayout>
          <div
            style={{ display: "flex", alignItems: "center", gap: 16 }}
            ref={ref}
          >
            <MeAvatarPicker
              me={user}
              open={openPhotoDialog}
              onClose={() => setOpenPhotoDialog(false)}
            />
            <H2>{getUserDisplayName(user, user.company.flags)}</H2>
          </div>

          <Button onClick={() => setOpenUserInfosDialog(true)}>
            {t("Edit")}
          </Button>
        </TitleLayout>
      )}
      {isNarrowScreen && !isMobile && (
        <H4>{t("user-setting-group-personal")}</H4>
      )}
      <UpdateInformationsDialog
        open={openUserInfosDialog}
        onClose={() => setOpenUserInfosDialog(false)}
        user={user}
      />
      <ButtonsContainer>
        <Email>
          <Mail style={{ marginRight: 20 }} />
          <P14Bold>{user?.email}</P14Bold>
        </Email>
        <PasswordContainer>
          <Lock style={{ marginLeft: 5 }} />
          <CirclesContainer>
            {Array.from(Array(10), (e, i) => {
              return <CirclePassword key={i} />
            })}
          </CirclesContainer>
        </PasswordContainer>
      </ButtonsContainer>
      <EditManager selectedUser={user} />
    </Content>
  )
}
export default React.forwardRef(PersonalInformations)
