import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { Button as OButton } from "ui"

import updateApp from "./updateApp"

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 20px;
`

const ButtonStyled = styled(OButton)`
  height: auto;
`

export default function UpdateAvailableButton() {
  const t = useTranslate()
  return (
    <ButtonContainer>
      <ButtonStyled onClick={updateApp}>{t("update available")}</ButtonStyled>
    </ButtonContainer>
  )
}
