import { ApolloError, gql, useQuery } from "@apollo/client"
import { useMemo } from "react"
import { Slot, UTCSlot } from "types"
import UTCDate from "utils/UTCDate"

import { SlotFragment } from "./fragments/slotFragment"

export const SLOTS_QUERY = gql`
  ${SlotFragment}
  query getSlots($userId: ID!, $before: String!, $after: String!) {
    slots(userId: $userId, after: $after, before: $before) {
      ...SlotFragment
    }
  }
`

export default function useWeeklyUserSlots(
  userId: string,
  weekDate: Date,
  until: "NEXT-SATURDAY" | "NEXT-MONDAY" = "NEXT-MONDAY"
): {
  slots: UTCSlot[]
  loading: boolean
  error?: ApolloError
} {
  const weekSize = until === "NEXT-MONDAY" ? 7 : 5
  const after = new UTCDate(weekDate, "WEEK-MONDAY")
  const before = new UTCDate(weekDate, until)
  const { loading, error, data } = useQuery(SLOTS_QUERY, {
    variables: {
      userId,
      before: `${before.getTime()}`,
      after: `${after.getTime()}`,
    },
    skip: userId === "",
    fetchPolicy: "cache-first",
  })
  const slots: UTCSlot[] = useMemo(() => {
    if (userId && data) {
      const { slots }: { slots: Slot[] } = data
      const d: UTCSlot[] = []
      const currentDate = new UTCDate(weekDate, "WEEK-MONDAY")
      for (let i = 0; i < weekSize * 2; i++) {
        const id = `${userId}_${currentDate.getTime()}`
        const foundSlot = slots.find((s) => s.id === id)
        const slot: UTCSlot = {
          id,
          value: foundSlot?.value || null,
          locationId: foundSlot?.locationId || null,
          userId,
          date: new UTCDate(currentDate),
          guests: foundSlot?.guests || 0,
          guestsEmails: foundSlot?.guestsEmails || [],
          label: foundSlot?.label || "",
        }
        currentDate.increment("HALF-DAY")
        d.push(slot)
      }
      return d
    }
    return []
  }, [userId, weekDate, weekSize, data])

  return { slots, loading, error }
}
