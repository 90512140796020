import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { SlotValue, WorkingMode } from "types"
import { colors, P16, P16Bold, transitions } from "ui"
import { Cancel } from "ui/icons"

import { TitleContainer } from "../SharedComponents"

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  &.list-slot-values--visible {
    visibility: visible;
    opacity: 1;
    pointer-events: all;
  }
  transition: ${transitions.fade};
`

const Item = styled.li`
  display: flex;
  align-items: center;
  gap: 16px;

  > :first-child {
    min-width: 120px;
  }
`

const CustomButton = styled.button`
  white-space: nowrap;
  padding: 4px 16px;
  margin: 0;
  outline: none;
  border-radius: 100vh;
  border: solid 1px ${colors.grey3};
  background: ${colors.grey4};
  color: ${colors.black};

  &.slot-value {
    &--office {
      border: solid 1px ${colors.office}20;
      background: ${colors.office}14;
      &:hover {
        border: solid 1px ${colors.office};
        background: ${colors.office}10;
      }
    }
    &--home {
      border: solid 1px ${colors.home}20;
      background: ${colors.home}14;
      &:hover {
        border: solid 1px ${colors.home};
        background: ${colors.home}10;
      }
    }
    &--else {
      border: solid 1px ${colors.else}20;
      background: ${colors.else}14;
      &:hover {
        border: solid 1px ${colors.else};
        background: ${colors.else}10;
      }
    }
    &--ooc {
      border: solid 1px ${colors.ooc}20;
      background: ${colors.ooc}14;
      &:hover {
        border: solid 1px ${colors.ooc};
        background: ${colors.ooc}10;
      }
    }
    &--reset {
      border: solid 1px ${colors.null}20;
      background: ${colors.null}14;
      &:hover {
        border: solid 1px ${colors.null};
        background: ${colors.null}10;
      }
    }
  }

  transition: ${transitions.fade};
`

interface PropsType {
  setContentType: (
    contentType: "HOW_TO" | "SLOTS_EDITOR" | "LOCATION_PICKER"
  ) => void
  handleSaveSlot: ({ genericSlotValue, label, locationId }: WorkingMode) => void
  handleResetSlots: () => void
  onNavback: () => void
}

export default function TypicalWeekSlotsEditor({
  setContentType,
  handleSaveSlot,
  handleResetSlots,
  onNavback,
}: PropsType) {
  const t = useTranslate()

  const slotValues: SlotValue[] = ["office", "home", "else", "ooc"]

  return (
    <div>
      <List className={`list-slot-values--visible`}>
        <TitleContainer>
          <Cancel onClick={onNavback} />
          <P16Bold className="title">{t("Select your working mode")}</P16Bold>
        </TitleContainer>
        {slotValues.map((value, n) => {
          return (
            <Item key={`slot-value-${n}-${value}`}>
              <CustomButton
                className={`slot-value--${value}`}
                onClick={() => {
                  if (value === "null") return // not happening since "null" not used here

                  if (value === "office")
                    return setContentType("LOCATION_PICKER")

                  handleSaveSlot({
                    genericSlotValue: value,
                    label: null,
                    locationId: null,
                    equipments: [],
                  })
                }}
              >
                {value !== "home" ? t(value) : t("Remote")}
              </CustomButton>
              <P16>{t(`${value}-tooltip-title`)}</P16>
            </Item>
          )
        })}
        <Item>
          <CustomButton
            className={`slot-value--reset`}
            onClick={() => {
              handleResetSlots()
              setContentType("HOW_TO")
            }}
          >
            {t("Reinitialize")}
          </CustomButton>
          <P16>{t("mistake-tooltip-title")}</P16>
        </Item>
      </List>
    </div>
  )
}
