import React from "react"
import { ServiceIconType } from "types"
import {
  Accessibility,
  AppleImac2021,
  Bicycle,
  Camera,
  HardDrive,
  Parking,
} from "ui/icons"
import Food from "ui/icons/Food"

export const defaultServiceIcon: ServiceIconType = {
  name: "default",
  description: "Computer hardware",
  icon: <HardDrive />,
}

export const serviceIcons: ServiceIconType[] = [
  {
    name: "hardware",
    description: "Computer hardware",
    icon: <HardDrive />,
  },
  {
    name: "screen",
    description: "Second screen",
    icon: <AppleImac2021 />,
  },
  {
    name: "camera",
    description: "A/V",
    icon: <Camera />,
  },
  {
    name: "parking",
    description: "Parking",
    icon: <Parking />,
  },
  {
    name: "accessibility",
    description: "Accessible facilities",
    icon: <Accessibility />,
  },
  {
    name: "bicycle",
    description: "Bike",
    icon: <Bicycle />,
  },

  {
    name: "food",
    description: "Self service",
    icon: <Food />,
  },

  defaultServiceIcon,
]

export const getServiceIcon = (name: string) => {
  return serviceIcons.find((s) => s.name === name) ?? defaultServiceIcon
}
