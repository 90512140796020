import useUser from "graphql/users/useUser"

export default function useCompanyFlags() {
  const { user, error, loading } = useUser()
  return {
    error,
    loading,
    companyFlags: user ? user.company.flags : {},
  }
}
