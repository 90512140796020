import { gql } from "@apollo/client"
import { UserBaseFragment } from "graphql/users/fragments/userFragment"

export const LocationFragment = gql`
  fragment LocationFragment on Location {
    id
    name
    shortname
    capacity
    bookable
    archived
    parentId
    isCategory
    floorPlanImageUrl
    reservedTo {
      users
      groups
    }
  }
`

export const LocationUsersFragment = gql`
  ${UserBaseFragment}
  fragment LocationUsersFragment on LocationUsers {
    id
    location {
      id
      name
      shortname
    }
    users {
      ...UserBaseFragment
    }
  }
`
