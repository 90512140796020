import styled from "styled-components"
import { colors, mediaQueries } from "ui"

interface ContentProps {
  windowHeight: number
  isEventsDisplayed: boolean
}

export const Content = styled.div<ContentProps>`
  --text-color: rgba(255, 255, 255, 0.8);

  @media ${mediaQueries.isMobile} {
    ${({ windowHeight }) => `
    min-height: calc(
    ${windowHeight}px - env(safe-area-inset-top, 0) - env(safe-area-inset-bottom, 0) - 32px
  );
  `}
    max-height: 90%;
  }

  @media ${mediaQueries.isDesktop} {
    height: 700px;
  }

  display: flex;
  flex-direction: column;
  padding: 0px 32px 16px 32px;
  overflow-y: scroll;
  background-color: ${colors.purple};
  padding-bottom: 35px;
  border-radius: 32px;
  margin-top: auto;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  position: relative;

  @media ${mediaQueries.isDesktop} {
    ${({ isEventsDisplayed }) => {
      if (isEventsDisplayed) return `width: 783px;`
    }}
    overflow: hidden;
  }

  .close-btn {
    position: fixed;
    display: flex;
    right: 32px;
    height: 56px;
    margin-top: 18px;

    @media ${mediaQueries.isDesktop} {
      display: flex;
      position: absolute;
      top: 36px;
      right: 0px;
      height: 56px;
      width: 56px;
      cursor: pointer;
    }
    svg {
      height: 24px;
      width: 24px;
      color: ${colors.grey1};
      @media ${mediaQueries.isDesktop} {
        color: ${colors.white};
      }
    }
  }

  .header {
    gap: 8px;
    display: flex;
    flex-direction: column;
    margin-top: 48px;
    > h2 {
      color: ${colors.white};
    }

    p {
      color: ${colors.white};
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    @media ${mediaQueries.isDesktop} {
      flex-direction: row;
      overflow-x: hidden;
    }

    margin-top: 8px;
    margin-bottom: 18px;
    height: 100%;

    > h2 {
      color: ${colors.white};
    }

    .section-title {
      color: var(--text-color);
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
    }

    .bubbles-section {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
      border-right: none;
      min-width: 100%;
      @media ${mediaQueries.isDesktop} {
        min-width: 50%;
      }

      .target-selection {
        display: flex;
        flex-direction: row;
        gap: 16px;
      }
      .bubbles-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: 250px;
        @media ${mediaQueries.isDesktop} {
          height: 300px;
        }

        .bubble {
          display: flex;
          width: 50%;
          padding: 8px;
        }
      }
    }
    .events {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-width: 100%;
      padding-left: 0px;
      padding-top: 24px;
      margin-top: 10px;
      min-height: 200px;
    }
  }
`

interface BubbleProps {
  size: number
  value: string
}

export const Bubble = styled.div<BubbleProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @-webkit-keyframes bubble-anim {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    20% {
      -webkit-transform: scaleY(0.95) scaleX(1.05);
      transform: scaleY(0.95) scaleX(1.05);
    }

    48% {
      -webkit-transform: scaleY(1.1) scaleX(0.9);
      transform: scaleY(1.1) scaleX(0.9);
    }

    68% {
      -webkit-transform: scaleY(0.98) scaleX(1.02);
      transform: scaleY(0.98) scaleX(1.02);
    }

    80% {
      -webkit-transform: scaleY(1.02) scaleX(0.98);
      transform: scaleY(1.02) scaleX(0.98);
    }

    97%,
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  @keyframes bubble-anim {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    20% {
      -webkit-transform: scaleY(0.95) scaleX(1.05);
      transform: scaleY(0.95) scaleX(1.05);
    }

    48% {
      -webkit-transform: scaleY(1.1) scaleX(0.9);
      transform: scaleY(1.1) scaleX(0.9);
    }

    68% {
      -webkit-transform: scaleY(0.98) scaleX(1.02);
      transform: scaleY(0.98) scaleX(1.02);
    }

    80% {
      -webkit-transform: scaleY(1.02) scaleX(0.98);
      transform: scaleY(1.02) scaleX(0.98);
    }

    97%,
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  -webkit-animation: bubble-anim ease-out 1s;
  animation: bubble-anim ease-out 1s;

  ${({ size }) => `
  height: ${size}px;
  width: ${size}px;
  border-radius: ${size}px;
  `}

  ${({ value }) => {
    if (value === "office")
      return `
        background-color: ${colors.office};
        margin-top: auto;
        margin-left: auto;
      `
    else if (value === "else")
      return `
        background-color: ${colors.else};
        margin-top: auto;
        margin-right: auto;
      `
    else if (value === "off")
      return `
        background-color: ${colors.off};
        margin-bottom: auto;
        margin-left: auto;
      `
    else if (value === "home")
      return `
        background-color: ${colors.home};
        margin-bottom: auto;
        margin-right: auto;
      `
  }}

  .old {
    animation: fadeIn 3s;
    -webkit-animation: fadeIn 3s;
    -moz-animation: fadeIn 3s;
    -o-animation: fadeIn 3s;
    -ms-animation: fadeIn 3s;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`
