import { gql, useApolloClient } from "@apollo/client"
import { Flags } from "types"

const QUERY = gql`
  query logEvent($event: String!, $data: JSON) {
    logEvent(event: $event, data: $data)
  }
`

export default function useLogEvent(): (
  event: string,
  data?: Flags
) => Promise<void> {
  const client = useApolloClient()

  const logEvent = async (event: string, data?: Flags) => {
    try {
      await client.query({
        query: QUERY,
        variables: { event, data },
        fetchPolicy: "no-cache",
      })
    } catch (error) {
      console.error(error)
      return
    }
  }

  return logEvent
}
