import { gql, useMutation } from "@apollo/client"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"

import { CompanyIntegrationFragment } from "./fragments/CompanyIntegrationFragments"
import updateCache from "./updateCompanyIntegrationCache"

const MUTATION = gql`
  ${CompanyIntegrationFragment}
  mutation revokeCompanyIntegration($name: String!) {
    revokeCompanyIntegration(name: $name) {
      ...CompanyIntegrationFragment
    }
  }
`

interface PropsType {
  name: string
}

export default function useRevokeCompanyIntegration() {
  const { addToast } = useToasts()
  const [mutate] = useMutation(MUTATION, {
    update: (cache, { data }) =>
      updateCache({
        cache,
        data,
        mutationName: "revokeCompanyIntegration",
        remove: true,
      }),
    refetchQueries: ["companyIntegrations"],
  })

  return async ({ name }: PropsType) => {
    try {
      await mutate({
        variables: { name },
      })
      addToast(`connexion to ${name} revoked`, {
        appearance: "success",
      })
    } catch (e) {
      console.error(e.message)
      addToast(e.message, { appearance: "error" })
    }
  }
}
