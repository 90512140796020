import useOccupations from "graphql/slots/useOccupations"
import useAddTeamDay from "graphql/teamDays/useAddTeamDay"
import useUser from "graphql/users/useUser"
import useUsersAt from "graphql/users/useUsersAt"
import useBookingMaxDate from "hooks/useBookingMaxDate"
import useMediaQueries from "hooks/useMediaQueries"
import useTeamUsersWithDailySlots from "hooks/useTeamUsersWithDailySlots"
import useTranslate from "intl/useTranslate"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { UserWithDailySlots } from "types"
import { AdminPopup, Button, colors, InfoTooltip, Label, P14 } from "ui"
import { Warning } from "ui/icons"
import TextfieldDatePicker from "ui/TextfieldDatePicker/TextfieldDatePicker"
import getUserDisplayName from "utils/getUserDisplayName"
import LocationTree from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

import TeamDayLocations from "./TeamDayLocations/TeamDayLocations"
import UsersAvailabilityList from "./UsersAvailabilityList/UsersAvailabilityList"

const DialogBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;

  .recap {
    margin-top: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    border-top: 1px solid ${colors.grey3};
    padding: 18px 0px;
    p {
      margin-right: auto;
    }
    svg {
      stroke-width: 0.2px;
    }
  }
`

interface WrapperPropsType {
  initialTeamDayDate?: UTCDate
  onClose: () => void
}

interface PropsType {
  AM: UTCDate
  PM: UTCDate
  teamDayUsersWithDailySlots: UserWithDailySlots[]
  teamDayLocation?: LocationTree
  setTeamDayDate: (date: UTCDate) => void
  updateTeamDayLocation: (location?: LocationTree) => void
  onClose: () => void
}

function NewTeamDayDialog({
  AM,
  PM,
  teamDayUsersWithDailySlots,
  teamDayLocation,
  setTeamDayDate,
  onClose,
  updateTeamDayLocation,
}: PropsType) {
  const t = useTranslate()
  const { user: me } = useUser()
  const { isMobile } = useMediaQueries()
  const { addTeamDay } = useAddTeamDay()
  const { occupations } = useOccupations(AM.getTime(), PM.getTime())
  const maxDate = useBookingMaxDate()
  const filterAvailable = (u: UserWithDailySlots) => u.available
  const [teamDayInvitedUserIds, setTeamDayInvitedUserIds] = useState(
    teamDayUsersWithDailySlots.filter(filterAvailable).map((u) => u.id)
  )
  const { users: usersAt } = useUsersAt({
    from: AM,
    to: PM,
    locationIds: teamDayLocation ? [teamDayLocation.id] : [],
    filters: [],
  })
  const previousTeamDayLocation = useRef<LocationTree | undefined>(undefined)

  const canBookLocation = () => {
    if (!teamDayLocation) return false

    if (teamDayInvitedUserIds.length > teamDayLocation.bookable) return false

    const teamDayLocationOccupation = occupations.find(
      (o) => o.locationId === teamDayLocation.id
    )
    if (!teamDayLocationOccupation) {
      if (teamDayInvitedUserIds.length > teamDayLocation.bookable) return false
      return true
    }

    const teamMembersOccupyingTeamDayLocation = usersAt.filter((u) =>
      teamDayInvitedUserIds.find((id) => id === u.id)
    ) // NOT TRUE !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

    if (
      teamDayLocation.bookable + teamMembersOccupyingTeamDayLocation.length >
      teamDayLocationOccupation.occupation
    )
      return true
    return false
  }

  const hasRoom = canBookLocation()
  const valid = teamDayLocation !== undefined && hasRoom

  const saveTeamDay = () => {
    if (teamDayLocation) {
      addTeamDay(AM, teamDayInvitedUserIds, teamDayLocation.id).finally(() => {
        onClose()
      })
    }
  }

  useEffect(() => {
    if (previousTeamDayLocation.current?.id === teamDayLocation?.id) {
      const invited = teamDayUsersWithDailySlots
        .filter(filterAvailable)
        .map((u) => u.id)
      setTeamDayInvitedUserIds(invited)
    } else {
      previousTeamDayLocation.current = teamDayLocation
    }
  }, [teamDayUsersWithDailySlots, teamDayLocation])

  if (!me) return <></>

  return (
    <AdminPopup
      open
      onClose={onClose}
      title={t("create new team day")}
      contentStyle={
        isMobile
          ? {
              padding: "8px",
            }
          : { padding: "12px 20px", width: 550 }
      }
      rightButton={
        <Button disabled={!valid} onClick={saveTeamDay}>
          {t("Confirm")}
        </Button>
      }
    >
      <DialogBody>
        <div>
          <TextfieldDatePicker
            label={t("Date")}
            defaultDate={AM}
            maxDate={maxDate}
            onSelect={(utcDate) => utcDate && setTeamDayDate(utcDate)}
          />
        </div>
        <div>
          <TeamDayLocations
            AM={AM}
            PM={PM}
            setSelectedLocation={updateTeamDayLocation}
            selectedLocation={teamDayLocation}
            guestIds={teamDayInvitedUserIds}
            hasRoom={hasRoom}
          />
        </div>

        <div>
          <Label>{t("Users")}</Label>
          <UsersAvailabilityList
            teamDayUsersWithDailySlots={teamDayUsersWithDailySlots}
            teamDayInvitedUserIds={teamDayInvitedUserIds}
            setTeamDayInvitedUserIds={setTeamDayInvitedUserIds}
          />
        </div>
        {valid && (
          <div className="recap">
            <Warning />
            <P14>
              {t("You are going to modify the planning of")}{" "}
              <b>
                {teamDayInvitedUserIds.length} {t("Collaborators")}
              </b>
            </P14>
            <InfoTooltip
              text={`${teamDayUsersWithDailySlots
                .filter((el) => teamDayInvitedUserIds.includes(el.id))
                .map((el) => getUserDisplayName(el, me.company.flags))
                .join(", ")}`}
            />
          </div>
        )}
      </DialogBody>
    </AdminPopup>
  )
}

export default function Wrapper({
  initialTeamDayDate,
  ...props
}: WrapperPropsType) {
  const [AM, setAM] = useState(initialTeamDayDate ?? new UTCDate("DAY"))
  const PM = new UTCDate(new Date(AM).setUTCDate(new Date(AM).getUTCDate() + 1))
  const teamDayUsersWithDailySlots = useTeamUsersWithDailySlots(AM, PM)

  const [teamDayLocation, setTeamDayLocation] = useState<
    LocationTree | undefined
  >(undefined)

  return (
    <NewTeamDayDialog
      AM={AM}
      PM={PM}
      setTeamDayDate={setAM}
      teamDayUsersWithDailySlots={teamDayUsersWithDailySlots}
      teamDayLocation={teamDayLocation}
      updateTeamDayLocation={setTeamDayLocation}
      {...props}
    />
  )
}
