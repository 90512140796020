import { PageContentWithRef } from "components/Layout/PageLayout/PageContent/PageContent"
import AdminPageTitle from "components/Layout/PageLayout/PageHeader/AdminPageTitle/AdminPageTitle"
import PageHeader from "components/Layout/PageLayout/PageHeader/PageHeader"
import PageLayout from "components/Layout/PageLayout/PageLayout"
import useCompanySettings from "graphql/settings/useCompanySettings"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { CompanySetting } from "types"
import { colors, L12 } from "ui"
import { hideScrollBarsCSS } from "ui/utilities"

import CompanyLocaleSelector from "./CompanyLocaleSelector"
import FileUploader from "./FileUploader/FileUploader"
import SettingItem from "./SettingItem/SettingItem"

const Body = styled.div<{ maxHeight: number }>`
  display: grid;
  grid-template-columns: 180px 1fr;
  ${({ maxHeight }) => {
    return `
  height: ${maxHeight}px; 
  max-height: ${maxHeight}px;
`
  }}
  overflow-y: hidden;
`

const Menu = styled.nav`
  ul {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 120px;
    padding-top: 16px;

    a {
      text-decoration: none;
    }

    p {
      color: ${colors.grey2};
      text-align: right;
      &.active-link {
        color: ${colors.black};
        transition: color 250ms ease-in-out;
        transition-delay: 250ms;
      }
    }
  }
`

const Content = styled.div<{ maxHeight: number; lastChildHeight: number }>`
  overflow-y: scroll;
  scroll-behavior: smooth;
  ${({ maxHeight, lastChildHeight }) => {
    return `
  padding-bottom: calc(${maxHeight}px - ${lastChildHeight}px);
`
  }}
  ${hideScrollBarsCSS}
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const Item = styled.li`
  border-bottom: solid 1px ${colors.grey3};
  padding: 16px 0;

  .title {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &:last-child {
    border: none;
  }
`

type AnchorType = "notifications" | "display" | "default" | "documents"

export default function CompanySettingsPage() {
  const t = useTranslate()
  const { companySettings } = useCompanySettings()
  const breadcrumb = [
    {
      name: t("Admin"),
      path: "/admin",
    },
    {
      name: t("Settings"),
      path: "/admin/settings",
    },
  ]
  const groups = companySettings.reduce((grouped, setting) => {
    if (grouped[setting.group] !== undefined) {
      grouped[setting.group].push(setting)
    } else {
      grouped[setting.group] = [setting]
    }
    return grouped
  }, {} as Record<string, CompanySetting[]>)

  const [pageContentRef, setPageContentRef] = useState<HTMLDivElement | null>(
    null
  )
  const [contentLastChild, setContentLastChild] =
    useState<HTMLLIElement | null>(null)
  const anchors: AnchorType[] = [
    "notifications",
    "display",
    "default",
    "documents",
  ]
  const [selectedAnchor, setSelectedAnchor] =
    useState<AnchorType>("notifications")

  const maxHeight = pageContentRef
    ? pageContentRef.getBoundingClientRect().height - 20 // 20px of parent padding bottom
    : 0

  return (
    <PageLayout>
      <PageHeader>
        <AdminPageTitle
          title={t("Company Settings")}
          breadcrumbs={breadcrumb}
        />
      </PageHeader>
      <PageContentWithRef ref={setPageContentRef}>
        <Body maxHeight={maxHeight}>
          <Menu>
            <ul>
              {React.Children.map(anchors, (anchor) => (
                <li>
                  <a
                    href={`#${anchor}`}
                    onClick={() => setSelectedAnchor(anchor)}
                  >
                    <L12
                      className={selectedAnchor === anchor ? "active-link" : ""}
                    >
                      {t(`company-setting-anchor--${anchor}`)}
                    </L12>
                  </a>
                </li>
              ))}
            </ul>
          </Menu>
          <Content
            maxHeight={maxHeight}
            lastChildHeight={
              contentLastChild
                ? contentLastChild.getBoundingClientRect().height
                : 0
            }
            onScroll={() => {
              if (!pageContentRef) return
              for (const anchor of anchors) {
                const element = document.getElementById(anchor)
                if (!element) continue

                const visible =
                  element.getBoundingClientRect().top >
                  pageContentRef.getBoundingClientRect().top - 10
                if (visible) {
                  return setSelectedAnchor(anchor)
                }
              }
            }}
          >
            <List>
              {Object.keys(groups).map((group) => {
                return (
                  <Item key={group} id={group}>
                    <SettingItem group={group} groups={groups} />
                  </Item>
                )
              })}
              <Item>
                <CompanyLocaleSelector />
              </Item>
              <Item id="documents" ref={setContentLastChild}>
                <FileUploader />
              </Item>
            </List>
          </Content>
        </Body>
      </PageContentWithRef>
    </PageLayout>
  )
}
