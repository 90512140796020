import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function MidlineHorizontalEllipsis({
  fill,
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon fill={fill ? fill : "currentColor"} {...rest}>
      <path
        d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
        stroke="none"
      />
    </SvgIcon>
  )
}
