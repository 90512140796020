import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { ServiceIconType } from "types"
import { HeaderTextField } from "ui"

import IconPicker from "./IconPicker/IconPicker"

const Fieldset = styled.fieldset`
  display: flex;
  gap: 16px;

  padding: 0;
  border: none;
`

interface PropsType {
  placeholder?: string
  initialName?: string
  initialIcon?: string
  onIconChange: (icon: ServiceIconType) => void
  onNameChange: (str: string) => void
}

export default function IconNameFieldset({
  placeholder,
  initialName,
  initialIcon,
  onIconChange,
  onNameChange,
}: PropsType) {
  const t = useTranslate()
  return (
    <Fieldset>
      <IconPicker initialIconName={initialIcon} onChange={onIconChange} />
      <HeaderTextField
        placeholder={placeholder ? t(placeholder) : undefined}
        autoFocus
        initialValue={initialName ? initialName : undefined}
        handleChange={onNameChange}
      />
    </Fieldset>
  )
}
