import { Menu, MenuItem } from "@mui/material"
import MobileWrapper from "components/MobileWrapper/MobileWrapper"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import { usePlanningContext } from "components/PlanningContextProvider/PlanningContextProvider"
import useExportPlanning from "graphql/slots/useExportPlanning"
import useUser from "graphql/users/useUser"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { GroupSimple, UserBase } from "types"
import { Button, colors, P14, P14Bold } from "ui"
import copyTextToClipboard from "utils/copyTextToClipboard"
import downloadTextFile from "utils/downloadTextFile"
import LocationTree from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

import InteractionsButton from "./InteractionsButton/InteractionsButton"

const FakeButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 16px;
  border-radius: 4px;
  border: 1px solid ${colors.grey3};

  a {
    text-decoration: none;
    color: ${colors.purple};
  }
`
interface PropsType {
  users: UserBase[]
  location?: LocationTree
  group?: GroupSimple
  from: UTCDate
  to: UTCDate
}

export default function InteractionsMenu({
  users,
  location: locationFromUrl,
  group,
  from,
  to,
}: PropsType) {
  const t = useTranslate()
  const { addToast } = useToasts()
  const { isMobile } = useMediaQueries()

  const { setOpenDrawer } = usePlanningContext()

  const [anchorMenu, setAnchorMenu] = React.useState<any>(null)
  const { user: me } = useUser()

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget)
    event.stopPropagation()
  }

  const closeMenu = () => {
    setAnchorMenu(null)
  }

  const usersEmail =
    users && me
      ? users
          .filter((u) => u.id !== me.id)
          .map((u) => u.email)
          .join(",")
      : undefined
  const usersId = users ? users.map((user) => user.id) : undefined

  const enabledFeatures = {
    sendEmail: true,
    exportToCsv: !isMobile,
    copyEmails: true,
  }

  const getName = () => {
    if (locationFromUrl) {
      return locationFromUrl.name
    } else if (group) {
      return group.name
    }
    return "offishall-planning-export"
  }

  const csvName = `${getName()}-${from.format("YYYY-MM-DD")}.csv`

  const { getCSV } = useExportPlanning({
    from,
    to,
    userIds:
      locationFromUrl === undefined && group === undefined
        ? usersId
        : undefined,
    locationId: locationFromUrl?.id,
    groupId: group?.id,
  })

  const emailRecipients =
    me && users.some((u) => u.id === me.id)
      ? [...users.filter((u) => u.id !== me.id), me]
      : me
      ? users.filter((u) => u.id !== me.id)
      : []

  if (isMobile)
    return (
      <div className="visible-action">
        <InteractionsButton toggleMenu={toggleMenu} />

        <MobileWrapper open={anchorMenu !== null} onClose={closeMenu}>
          {enabledFeatures.sendEmail && (
            <FakeButton>
              <P14Bold>
                <a
                  href={`mailto:${usersEmail}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("send email")}
                </a>
              </P14Bold>
            </FakeButton>
          )}

          {enabledFeatures.copyEmails && (
            <Button
              secondary
              onClick={() => {
                copyTextToClipboard(
                  emailRecipients.map((u) => u.email).join(",")
                )
                closeMenu()
                addToast(t("Emails copied"), { appearance: "success" })
              }}
            >
              {t("copy emails address")}
            </Button>
          )}

          <Button
            secondary
            onClick={() => {
              setOpenDrawer("FLOOR_PLAN")
              closeMenu()
            }}
          >
            {t("see floor plan")}
          </Button>

          <Button ghost onClick={closeMenu}>
            {t("Cancel")}
          </Button>
        </MobileWrapper>
      </div>
    )

  return (
    <div className="visible-action">
      <InteractionsButton toggleMenu={toggleMenu} />

      <Menu
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
        onClick={(e) => e.stopPropagation()}
        elevation={0}
        PaperProps={{
          sx: {
            background: "#FFFFFF",
            boxShadow:
              "0px 2px 16px rgba(0, 0, 0, 0.24), 0px 2px 2px rgba(0, 0, 0, 0.12)",
            borderRadius: "4px",
          },
        }}
        autoFocus={true}
        disableScrollLock
      >
        {enabledFeatures.sendEmail && (
          <MenuItem>
            <P14>
              <a
                href={`mailto:${usersEmail}`}
                target="_blank"
                rel="noreferrer"
                style={{
                  color: colors.black,
                  textDecoration: "none",
                  marginRight: 20,
                }}
              >
                {t("send email")}
              </a>
            </P14>
          </MenuItem>
        )}
        {enabledFeatures.exportToCsv && (
          <MenuItem
            onClick={async () => {
              const csv = await getCSV()
              if (csv === undefined) return
              downloadTextFile(csvName, csv)
            }}
          >
            <P14>{t("export to csv")}</P14>
          </MenuItem>
        )}
        {enabledFeatures.copyEmails && (
          <MenuItem
            onClick={() => {
              copyTextToClipboard(emailRecipients.map((u) => u.email).join(","))
              closeMenu()
              addToast(t("Emails copied"), { appearance: "success" })
            }}
          >
            <P14>{t("copy emails address")}</P14>
          </MenuItem>
        )}
      </Menu>
    </div>
  )
}
