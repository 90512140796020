import DatePickerButton from "components/Date/DatePickerButton"
import LocalTimePicker from "components/LocalTimePicker/LocalTimePicker"
import useMeetingRoomEvents from "graphql/meetingRooms/useMeetingRoomEvents"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { IntegrationEvent, UpsertEventInput } from "types"
import { colors, FieldsetColumn, Label as UILabel, P16 } from "ui"
import LocalDate from "utils/LocalDate"
import capitalizeFirstLetter from "utils/texts/capitalizeFirstLetter"

const Label = styled(UILabel)`
  margin: 0;
`

const DateTimeContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  ${() => {
    if (location.pathname.startsWith("/meeting-rooms"))
      return `grid-template-columns:  1fr;`
  }}
  gap: 16px;
  button {
    width: 100%;
  }
  .time-input {
    color: ${colors.grey1};
  }
`

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  &.column {
    flex-direction: column;
  }
`

function checkPeriodsIntersect(
  a: { from: LocalDate; to: LocalDate },
  b: { from: LocalDate; to: LocalDate }
) {
  return (
    (a.from.getTime() > b.from.getTime() &&
      a.from.getTime() < b.to.getTime()) ||
    (a.to.getTime() > b.from.getTime() && a.to.getTime() < b.to.getTime())
  )
}

interface WrapperPropsType {
  inputs: UpsertEventInput
  updateTo: (to: LocalDate) => void
  updateFrom: (from: LocalDate) => void
  onError: (error?: string) => void
}

interface PropsType {
  inputs: UpsertEventInput
  meetingRoomEvents: IntegrationEvent[]
  updateFrom: (from: LocalDate) => void
  updateTo: (to: LocalDate) => void
  onError: (error?: string) => void
}

function DateAndTimePicker({
  inputs,
  meetingRoomEvents,
  updateFrom,
  updateTo,
  onError,
}: PropsType) {
  const t = useTranslate()

  const from = inputs.from
  const to = inputs.to

  const areOverlappedDates = (from: LocalDate, to: LocalDate) =>
    meetingRoomEvents
      .filter((e) => e.id !== inputs?.id)
      .map((e) =>
        checkPeriodsIntersect({ from: e.from, to: e.to }, { from, to })
      )
      .some((e) => e)

  const getDateAndTimeValidity = () => {
    const clampedFrom = new LocalDate(from)
    clampedFrom.setHours(0, 0, 0, 0)
    const clampedTo = new LocalDate(to)
    clampedTo.setHours(0, 0, 0, 0)
    return {
      time: from.getHours() < to.getHours(),
      date: clampedFrom.getTime() <= clampedTo.getTime(),
    }
  }

  return (
    <Layout
      className={
        !location.pathname.startsWith("/meeting-rooms") ? "column" : ""
      }
    >
      <FieldsetColumn className="full-width">
        <Label>{capitalizeFirstLetter(t("from"))}</Label>
        <DateTimeContainer>
          {!location.pathname.startsWith("/meeting-rooms") && (
            <DatePickerButton
              date={from}
              defaultDate={from}
              minDate={new LocalDate()}
              error={
                !getDateAndTimeValidity().date ? "invalid date" : undefined
              }
              updateDate={(date) => {
                onError()
                if (!date) return
                const from = new LocalDate(date)
                from.setHours(inputs.from.getHours())
                updateFrom(from)

                if (from.getTime() > to.getTime()) onError("invalid from date")
              }}
            />
          )}

          <LocalTimePicker
            hours={from.getHours()}
            minutes={from.getMinutes()}
            disabled={inputs.isAllDay}
            error={
              !getDateAndTimeValidity().time ? "invalid chronology" : undefined
            }
            onChange={(h, m) => {
              onError()
              const date = new LocalDate(from)
              date.setHours(h, m, 0, 0)
              updateFrom(date)
              if (areOverlappedDates(date, to)) onError("already booked")
              if (date.getTime() >= to.getTime()) onError("invalid from time")
            }}
          />
        </DateTimeContainer>
      </FieldsetColumn>
      <FieldsetColumn className="full-width">
        <Label>{capitalizeFirstLetter(t("to"))}</Label>
        <DateTimeContainer>
          {!location.pathname.startsWith("/meeting-rooms") && (
            <DatePickerButton
              date={to}
              defaultDate={to}
              minDate={new LocalDate()}
              error={
                !getDateAndTimeValidity().date ? "invalid date" : undefined
              }
              updateDate={(date) => {
                onError()
                if (!date) return
                const to = new LocalDate(date)
                to.setHours(inputs.to.getHours())
                updateTo(to)
                if (from.getTime() > to.getTime()) onError("invalid to date")
              }}
            />
          )}
          <LocalTimePicker
            hours={to.getHours()}
            minutes={to.getMinutes()}
            disabled={inputs.isAllDay}
            error={
              !getDateAndTimeValidity().time ? "invalid chronology" : undefined
            }
            onChange={(h, m) => {
              onError()
              const date = new LocalDate(from)
              date.setHours(h, m, 0, 0)
              updateTo(date)
              if (areOverlappedDates(from, date)) onError("already booked")
              if (from.getTime() >= date.getTime()) onError("invalid to time")
            }}
          />
        </DateTimeContainer>
        {areOverlappedDates(from, to) && (
          <P16 color="redAlert">{t("already booked")}</P16>
        )}
      </FieldsetColumn>
    </Layout>
  )
}

function DatePickerWithEvents({ ...props }: WrapperPropsType) {
  const from = new LocalDate(props.inputs.from, "DAY")
  const to = new LocalDate(from, "NEXT-DAY")
  const { meetingRoomWithEvents } = useMeetingRoomEvents(
    props.inputs.roomId ?? "",
    from,
    to
  )
  return (
    <DateAndTimePicker
      {...props}
      meetingRoomEvents={meetingRoomWithEvents?.events ?? []}
    />
  )
}

export default function Wrapper({ ...props }: WrapperPropsType) {
  if (props.inputs.roomId) return <DatePickerWithEvents {...props} />
  return <DateAndTimePicker {...props} meetingRoomEvents={[]} />
}
