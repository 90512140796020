import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { Equipment } from "types"
import { Button, ChipButton, Dialog, P16 } from "ui"
import { User as UserIcon } from "ui/icons"
import { getServiceIcon } from "utils/getServiceIcons"

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
`

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`

function EquipmentItem({ equipment }: { equipment: Equipment }) {
  return (
    <ChipButton role="div" icon={getServiceIcon(equipment.icon).icon}>
      {equipment.name}
    </ChipButton>
  )
}

function GuestItem({ email }: { email: string }) {
  return (
    <ChipButton role="div" icon={<UserIcon />}>
      {email}
    </ChipButton>
  )
}

interface PropsType {
  open: boolean
  equipments: Equipment[]
  guests: string[]
  onClose: () => void
  onCancel: () => void
  onSave: () => void
}

export default function PreviouslyBookedItemsDialog({
  open,
  equipments,
  guests,
  onClose,
  onCancel,
  onSave,
}: PropsType) {
  const t = useTranslate()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t("Modify previous service bookings")}
      leftButton={
        <Button
          secondary
          onClick={() => {
            onClose()
            onCancel()
          }}
        >
          {t("Cancel")}
        </Button>
      }
      rightButton={
        <Button
          onClick={() => {
            onClose()
            onSave()
          }}
        >
          {t("Save")}
        </Button>
      }
    >
      <Layout>
        {equipments.length > 0 && (
          <>
            <P16>{t("Loosing previous service bookings warning")}</P16>
            <List>
              {equipments.map((e) => (
                <li key={`previously-booked-equipment-${e.id}`}>
                  <EquipmentItem equipment={e} />
                </li>
              ))}
            </List>
          </>
        )}
        {guests.length > 0 && (
          <>
            <P16>{t("Loosing previous guests warning")}</P16>
            <List>
              {React.Children.map(guests, (email) => (
                <li>
                  <GuestItem email={email} />
                </li>
              ))}
            </List>
          </>
        )}
      </Layout>
    </Dialog>
  )
}
