import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function Event({
  stroke,
  strokeWidth,
  fill,
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      strokeWidth={strokeWidth ?? 1.5}
      stroke={stroke ? stroke : "currentColor"}
      fill={fill ? fill : "currentColor"}
      {...rest}
    >
      <path
        d="M3.74988 21C3.54988 21 3.35988 20.92 3.21988 20.78C3.01988 20.58 2.94988 20.28 3.03988 20.01L6.53988 9.51C6.61988 9.26 6.82988 9.08 7.07988 9.02C7.32988 8.96 7.59988 9.04 7.77988 9.22L14.7799 16.22C14.9599 16.4 15.0399 16.67 14.9799 16.92C14.9199 17.17 14.7299 17.38 14.4899 17.46L3.98988 20.96C3.90988 20.99 3.82988 21 3.74988 21ZM7.57988 11.14L4.93988 19.07L12.8699 16.43L7.57988 11.14Z"
        stroke="none"
      />
      <path
        d="M13.04 11.04C12.85 11.04 12.66 10.97 12.51 10.82C12.22 10.53 12.22 10.05 12.51 9.76001C13.07 9.20001 13.7 9.23001 14.08 9.24001C14.41 9.26001 14.44 9.24001 14.52 9.16001C14.6 9.08001 14.61 9.05001 14.6 8.72001C14.58 8.34001 14.56 7.71001 15.11 7.16001C15.67 6.60001 16.3 6.63001 16.68 6.64001C17.01 6.65001 17.04 6.64001 17.12 6.56001C17.2 6.48001 17.21 6.44001 17.2 6.12001C17.18 5.74001 17.16 5.11001 17.72 4.55001C18.28 3.99001 18.91 4.02001 19.29 4.03001C19.62 4.05001 19.65 4.03001 19.73 3.95001C20.02 3.66001 20.5 3.66001 20.79 3.95001C21.08 4.24001 21.08 4.72001 20.79 5.01001C20.23 5.57001 19.6 5.54001 19.22 5.53001C18.89 5.52001 18.86 5.53001 18.78 5.61001C18.7 5.69001 18.69 5.73001 18.7 6.05001C18.72 6.43001 18.74 7.06001 18.18 7.62001C17.62 8.18001 16.99 8.15001 16.62 8.14001C16.29 8.13001 16.26 8.14001 16.18 8.22001C16.1 8.30001 16.09 8.33001 16.1 8.66001C16.12 9.04001 16.14 9.67001 15.59 10.22C15.03 10.78 14.41 10.75 14.02 10.74C13.69 10.73 13.66 10.74 13.58 10.82C13.43 10.97 13.24 11.04 13.04 11.04Z"
        stroke="none"
      />
      <path
        d="M10.25 9C9.84 9 9.5 8.66 9.5 8.25V6.25C9.5 5.29 8.71 4.5 7.75 4.5C7.34 4.5 7 4.16 7 3.75C7 3.34 7.34 3 7.75 3C9.54 3 11 4.46 11 6.25V8.25C11 8.66 10.66 9 10.25 9Z"
        stroke="none"
      />
      <path
        d="M20.25 17C19.84 17 19.5 16.66 19.5 16.25C19.5 15.29 18.71 14.5 17.75 14.5H15.75C15.34 14.5 15 14.16 15 13.75C15 13.34 15.34 13 15.75 13H17.75C19.54 13 21 14.46 21 16.25C21 16.66 20.66 17 20.25 17Z"
        stroke="none"
      />
      <path
        d="M12.75 5.49999C12.65 5.49999 12.56 5.47999 12.46 5.43999C12.37 5.39999 12.29 5.34999 12.22 5.27999C12.08 5.13999 12 4.94999 12 4.74999C12 4.54999 12.08 4.35999 12.22 4.21999C12.43 4.00999 12.76 3.93999 13.04 4.05999C13.13 4.09999 13.21 4.14999 13.28 4.21999C13.35 4.28999 13.4 4.36999 13.44 4.45999C13.48 4.55999 13.5 4.64999 13.5 4.74999C13.5 4.94999 13.42 5.13999 13.28 5.27999C13.21 5.34999 13.13 5.39999 13.04 5.43999C12.94 5.47999 12.85 5.49999 12.75 5.49999Z"
        stroke="none"
      />
      <path
        d="M20.25 12C20.05 12 19.86 11.92 19.72 11.78C19.65 11.71 19.6 11.63 19.56 11.54C19.52 11.45 19.5 11.35 19.5 11.25C19.5 11.05 19.58 10.86 19.72 10.72C19.79 10.65 19.87 10.6 19.96 10.56C20.15 10.48 20.35 10.48 20.54 10.56C20.63 10.6 20.71 10.65 20.78 10.72C20.92 10.86 21 11.05 21 11.25C21 11.35 20.98 11.45 20.94 11.54C20.9 11.63 20.85 11.71 20.78 11.78C20.64 11.92 20.45 12 20.25 12Z"
        stroke="none"
      />
    </SvgIcon>
  )
}
