import { gql, useMutation } from "@apollo/client"
import { useToasts } from "components/NotificationContextProvider/NotificationContextProvider"
import useTranslate from "intl/useTranslate"

import { EquipmentFragment } from "./fragments/equipmentFragments"
import updateCache from "./updateCache"

const MUTATION = gql`
  ${EquipmentFragment}
  mutation deleteEquipment($equipmentId: ID!) {
    deleteEquipment(equipmentId: $equipmentId) {
      ...EquipmentFragment
    }
  }
`

export default function useDeleteEquipment() {
  const { addToast } = useToasts()
  const t = useTranslate()

  const [mutate] = useMutation(MUTATION, {
    update: (cache, { data }) =>
      updateCache({
        cache,
        data,
        mutationName: "deleteEquipment",
        remove: true,
      }),
  })

  return async (equipmentId: string) => {
    try {
      await mutate({ variables: { equipmentId } })
      addToast(t("confirmation delete a service"), { appearance: "success" })
    } catch (e) {
      console.error(e.message)
      addToast(e.message, { appearance: "error" })
    }
  }
}
