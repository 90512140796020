import LoaderOrErrorComponent from "components/LoaderOrErrorComponent"
import useAllGroups from "graphql/groups/useAllGroups"
import useMyGroupsSimple from "graphql/groups/useMyGroupsSimple"
import uniqBy from "lodash.uniqby"
import {
  DataLayout,
  Layout,
  SampleList,
} from "pages/StatisticsPage/components/Layout"
import SamplePicker from "pages/StatisticsPage/components/SamplePicker/SamplePicker"
import WeekDaysChart from "pages/StatisticsPage/WeekDays/WeekDaysChart/WeekDaysChart"
import React, { useState } from "react"
import styled from "styled-components"
import { GroupSimple, UserBase } from "types"
import LocationTree from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

const ChartLayout = styled.div<{ alreadyOccupiedHeight?: number }>`
  ${({ alreadyOccupiedHeight }) => {
    if (alreadyOccupiedHeight)
      return `
      height: calc(100vh - ${alreadyOccupiedHeight}px);
      max-height: calc(100vh - ${alreadyOccupiedHeight}px);
    `
  }}
`

interface PropsType {
  from: UTCDate
  to: UTCDate
  enableWeekends: boolean
  pageHeaderHeight: { header?: number; tabsHeader?: number }
}

export default function PWStatsWeekDays({
  from,
  to,
  enableWeekends,
  pageHeaderHeight,
}: PropsType) {
  const maxHeight =
    pageHeaderHeight.header !== undefined &&
    pageHeaderHeight.tabsHeader !== undefined
      ? pageHeaderHeight.header + pageHeaderHeight.tabsHeader
      : undefined

  const extraBottomPadding = 64
  const alreadyOccupiedHeight =
    pageHeaderHeight.header !== undefined &&
    pageHeaderHeight.tabsHeader !== undefined
      ? pageHeaderHeight.header +
        pageHeaderHeight.tabsHeader +
        extraBottomPadding
      : undefined

  const { groups: myGroupsSimple, loading, error } = useMyGroupsSimple()
  const {
    groups: allGroups,
    loading: loadingAllGroups,
    error: errorAllGroups,
  } = useAllGroups()

  const [selectedGroups, setSelectedGroups] = useState<GroupSimple[]>([])
  const [selectedUsers, setSelectedUsers] = useState<UserBase[]>([])

  if (loading || loadingAllGroups || error || errorAllGroups)
    return (
      <LoaderOrErrorComponent
        loading={loading || loadingAllGroups}
        error={error || errorAllGroups}
      />
    )
  return (
    <Layout>
      <SampleList alreadyOccupiedHeight={maxHeight}>
        <SamplePicker
          data={{
            users: [],
            groups: uniqBy([...myGroupsSimple, ...allGroups], "id"),
            locations: [],
          }}
          onChange={(sample: {
            users: UserBase[]
            groups: GroupSimple[]
            locations: LocationTree[]
          }) => {
            setSelectedGroups(sample.groups)
            setSelectedUsers(sample.users)
          }}
        />
      </SampleList>
      <DataLayout>
        <ChartLayout alreadyOccupiedHeight={alreadyOccupiedHeight}>
          <WeekDaysChart
            showDownloadCSV={false}
            users={selectedUsers}
            groups={selectedGroups}
            from={from}
            to={to}
            includeWeekends={enableWeekends}
          />
        </ChartLayout>
      </DataLayout>
    </Layout>
  )
}
