import { InputFileButton } from "components/InputFileButton/InputFileButton"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { AdminPopup, Button, colors, P14Bold, TextField } from "ui"
import { Plus, Trash } from "ui/icons"

const TextfieldStyled = styled(TextField)`
  &.textfield--error {
    > div:last-child > div {
      border-color: ${colors.error}88;
    }
  }
`

const FileItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 10px 8px;
  cursor: pointer;

  p {
    color: ${colors.grey1};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  svg {
    stroke: ${colors.grey1};
    transition: stroke 175ms ease-in-out;
  }

  svg:hover {
    stroke: ${colors.black};
  }
`

interface PropsType {
  open: boolean
  onConfirm: (fileWithFilename: { file: File; filename: string }) => void
  onCancel: () => void
}

export default function UploadDialog({ open, onConfirm, onCancel }: PropsType) {
  const t = useTranslate()

  const [filename, setFilename] = useState<{ value: string; error?: boolean }>({
    value: "",
    error: undefined,
  })
  const [file, setFile] = useState<File | undefined>(undefined)

  const validateFileName = (str: string) => {
    setFilename({
      value: str,
      error: str === "",
    })
  }

  const handleFileChange = (filelist: FileList) => {
    const file = filelist.item(0)
    if (file !== null) setFile(file)
  }

  const discardFile = () => {
    setFile(undefined)
  }

  const isValidForm =
    file !== undefined &&
    filename.error === false &&
    filename.value !== undefined

  const submitForm = () => {
    if (!isValidForm) return
    onConfirm({ file: file!, filename: filename.value! })
  }

  return (
    <AdminPopup
      title={t("company_file--add_file")}
      rightButton={
        <Button onClick={submitForm} disabled={!isValidForm}>
          {t("Confirm")}
        </Button>
      }
      open={open}
      onClose={onCancel}
    >
      <>
        <TextfieldStyled
          label={t("company_file--input_title")}
          placeholder={t("company_file--input_title_placeholder")}
          required
          handleChange={validateFileName}
          className={
            filename.error ? "textfield textfield--error" : "textfield"
          }
          autoFocus
        />
        <InputFileButton
          accept=".doc,.docx,.pdf"
          handleChange={handleFileChange}
        >
          <Plus /> {t("company_file--add_file")}
        </InputFileButton>
        {file !== undefined && (
          <FileItem>
            <P14Bold>{file.name}</P14Bold>
            <Trash onClick={discardFile} />
          </FileItem>
        )}
      </>
    </AdminPopup>
  )
}
