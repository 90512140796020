import React from "react"
import styled from "styled-components"
import { User } from "types"
import { mediaQueries } from "ui"

import UserIntegrations from "./UserIntegrations"

const Integrations = styled.div`
  @media ${mediaQueries.isMobile} {
    margin-top: 10px;
    margin-bottom: 20px;
  }
`

interface PropsType {
  user: User
}

function UserSettings({ user }: PropsType, ref: React.Ref<HTMLDivElement>) {
  return (
    <Integrations ref={ref}>
      <UserIntegrations user={user} />
    </Integrations>
  )
}

export default React.forwardRef(UserSettings)
