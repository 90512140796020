import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function Zone({ stroke, strokeWidth, ...rest }: SvgIconProps) {
  return (
    <SvgIcon
      strokeWidth={strokeWidth ?? 1.5}
      stroke={stroke ? stroke : "currentColor"}
      {...rest}
    >
      <path
        d="M7 9.01L7.01 8.99889M11 9.01L11.01 8.99889M7 13.01L7.01 12.9989M11 13.01L11.01 12.9989M7 17.01L7.01 16.9989M11 17.01L11.01 16.9989M15 21H3.6C3.26863 21 3 20.7314 3 20.4V5.6C3 5.26863 3.26863 5 3.6 5H9V3.6C9 3.26863 9.26863 3 9.6 3H14.4C14.7314 3 15 3.26863 15 3.6V9M15 21V9M15 21H20.4C20.7314 21 21 20.7314 21 20.4V9.6C21 9.26863 20.7314 9 20.4 9H15M15 21V17M15 9V13M15 17V13M15 17H17M15 13H17"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}
