import FavoriteCheckbox from "components/FavoriteCheckbox"
import FloorPlanProps from "components/FloorPlanComponents/FloorPlanProps"
import LocationFloorPlanAction from "components/FloorPlanComponents/LocationFloorPlanAction"
import VisualArea from "components/FloorPlanComponents/VisualAreaEditor/VisualArea"
import DeleteLocationButton from "components/LocationComponents/Buttons/DeleteLocationButton/DeleteLocationButton"
import EditLocationButton from "components/LocationComponents/Buttons/EditLocationButton/EditLocationButton"
import useMediaQueries from "hooks/useMediaQueries"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { useTeamsAppContext } from "TeamsApp/TeamsAppContext"
import { Equipment, EquipmentAvailability, WorkingMode } from "types"
import { colors, L12, mediaQueries, P16, P16Bold, transitions } from "ui"
import { FloorPlan } from "ui/icons"
import getColorFromStatus from "utils/getColorFromStatus"
import LocationTree from "utils/LocationTree"

import BookEquipmentForm from "./BookEquipmentForm/BookEquipmentForm"
import OpenEquipmentsBookingButton from "./OpenEquipmentsBookingButton/OpenEquipmentsBookingButton"

interface LayoutPropsType {
  disabled?: boolean
  active?: boolean
  archived?: boolean
}

const Layout = styled.div<LayoutPropsType>`
  display: grid;
  grid-template-columns: auto auto 1fr 1fr;
  align-items: center;
  width: 100%;
  border-radius: 50px;
  padding: 5px 16px;

  background: ${colors.grey4};
  border: solid 1px ${colors.grey3};
  cursor: pointer;

  &:hover,
  &:focus {
    border-color: ${getColorFromStatus("office")};
    background: ${getColorFromStatus("office")}14;

    ${({ active }) => {
      if (active)
        return `
          border-color: ${colors.purple};
          `
    }}
  }

  ${({ active }) => {
    if (active) {
      return `
        border-color: ${colors.purple};
        `
    }
  }}

  ${({ disabled }) => {
    if (disabled)
      return `
    cursor: auto;
    background: ${colors.grey4};
    border: solid 1px ${colors.grey3};
    color: ${colors.grey2};
    pointer-events: none;

    p {
      color: inherit;
    }
    `
  }}

  .breadcrumbs {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${colors.grey2};
    font-size: 16px;
    line-height: 20px;

    .crumb:after {
      content: "/";
      margin: 4px;
    }
  }

  .content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${({ archived }) => {
      if (archived)
        return `text-decoration: line-through; color: ${colors.grey1}`
    }}

    ${({ active }) => {
      if (active) return `color: ${colors.purple};`
    }}
  }

  &:hover .actions {
    .trash-icon,
    .edit-icon,
    .favorite-checkbox-icon {
      visibility: visible;
      opacity: 1;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 10px;

    .trash-icon,
    .edit-icon,
    .favorite-checkbox-icon {
      visibility: hidden;
      opacity: 0;
    }

    transition: ${transitions.generic};

    svg {
      width: 16px;
      height: 16px;
      stroke: ${colors.grey2};
      transition: ${transitions.generic};
    }

    .trash-icon:hover {
      svg {
        stroke: ${colors.red};
      }
    }

    .edit-icon:hover {
      svg {
        stroke: ${colors.black};
      }
    }
  }

  .more {
    white-space: nowrap;
    margin-left: auto;
    font-size: 14px;
    line-height: 18px;

    p {
      ${({ archived }) => {
        if (archived) return `color: ${colors.grey1}`
      }}
    }

    svg {
      display: flex;
      width: 16px;
      height: 16px;
    }
  }

  @media ${mediaQueries.isMobile} {
    .actions {
      svg {
        stroke: ${colors.grey1};
      }
    }
    .trash-icon {
      svg {
        stroke: ${colors.red};
      }
    }
  }

  transition: ${transitions.generic};
`

const LayoutWithServices = styled(Layout)`
  grid-template-columns: auto auto 1fr min-content;

  &.layout-with-service--selected {
    border-color: ${getColorFromStatus("office")};
    background: ${getColorFromStatus("office")}14;
  }
  .btn-add-equipment {
    visibility: hidden;
    opacity: 0;
  }

  &:hover {
    .btn-add-equipment {
      visibility: visible;
      opacity: 1;
    }
  }
`

const Counter = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100vh;
  background: ${colors.grey3};
  height: 20px;
  min-width: 20px;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`

const DeskAvailable = styled.div`
  display: flex;
  align-items: center;
  gap: 1px;
`

function LocationCapacityAvailability({
  availability: { available, bookable },
}: {
  availability: {
    available?: number
    bookable?: number
  }
}) {
  const t = useTranslate()
  const { isMobile } = useMediaQueries()
  if (isMobile) {
    if (available === undefined && bookable === undefined) return <></>
    if (available === undefined) return <Counter>{bookable}</Counter>
    return <Counter>{available}</Counter>
  }

  if (available === undefined && bookable === undefined) return <></>
  if (available === undefined) return <>{`${t("capacity")} : ${bookable}`}</>
  return (
    <DeskAvailable>
      <L12 color="grey1">
        {t("desks available")} : {available}
      </L12>
    </DeskAvailable>
  )
}

interface PropsType {
  location: LocationTree
  availability: {
    available?: number
    bookable?: number
  }
  active: boolean
  showBreadCrumbs?: boolean
  disabled?: boolean
  showFavoriteCheckbox?: boolean
  withActions?: boolean

  onClick: () => void
  onMoreClick?: () => void

  floorPlan?: FloorPlanProps
  visualArea?: {
    area: VisualArea
    onSelectArea: (area: VisualArea) => void
  }
}

export function LocationChipButton({
  location,
  availability,
  active,
  showBreadCrumbs,
  disabled,
  withActions,
  showFavoriteCheckbox,
  onClick,
  onMoreClick,
  floorPlan,
  visualArea,
}: PropsType) {
  const { isTeamsApp } = useTeamsAppContext()

  return (
    <Layout
      onClick={() => {
        if (disabled) return
        onClick()
      }}
      disabled={disabled}
      active={active}
      role="button"
      archived={location.archived}
    >
      <span className="breadcrumbs">
        {showBreadCrumbs &&
          location.breadCrumbs.map((crumb, n) => (
            <span key={`loc-${location.id}-crumb-${n}`} className="crumb">
              {crumb}
            </span>
          ))}
      </span>
      {active ? (
        <P16Bold className="content">{location.name}</P16Bold>
      ) : (
        <P16 className="content">{location.name}</P16>
      )}
      <div className="actions">
        {showFavoriteCheckbox && !isTeamsApp && (
          <>
            <FavoriteCheckbox
              type="location"
              itemId={location.id}
              disabled={disabled}
            />
            {visualArea && (
              <FloorPlan
                onClick={(e) => {
                  e.stopPropagation()
                  visualArea.onSelectArea(visualArea.area)
                }}
              />
            )}
          </>
        )}
        {withActions && (
          <>
            <DeleteLocationButton location={location} disabled={disabled} />
            <EditLocationButton location={location} disabled={disabled} />
          </>
        )}
      </div>

      {!floorPlan && (
        <div className={`${onMoreClick ? "more more--underline" : "more"}`}>
          <div
            onClick={(e) => {
              if (onMoreClick) {
                e.stopPropagation()
                onMoreClick()
              }
            }}
          >
            <LocationCapacityAvailability availability={availability} />
          </div>
        </div>
      )}

      {floorPlan && (
        <div className="more">
          <p>
            <LocationFloorPlanAction
              location={location}
              disabled={disabled}
              floorPlan={floorPlan}
            />
          </p>
        </div>
      )}
    </Layout>
  )
}

interface WithServicePropsType extends PropsType {
  equipments: Equipment[]
  equipmentsAvailability: EquipmentAvailability[]
  saveWorkingMode: (workingMode: WorkingMode) => void | Promise<void>
}

export function LocationChipButtonWithServices({
  location,
  equipments,
  equipmentsAvailability,
  availability,
  active,
  showBreadCrumbs,
  disabled,
  showFavoriteCheckbox,
  onClick,
  onMoreClick,
  saveWorkingMode,
}: WithServicePropsType) {
  const [isFormOpen, setIsFormOpen] = useState(false)
  const { isTeamsApp } = useTeamsAppContext()
  const { isMobile } = useMediaQueries()

  return (
    <>
      <LayoutWithServices
        onClick={() => {
          if (isMobile) {
            return setIsFormOpen(true)
          }
          onClick()
        }}
        disabled={disabled}
        active={active}
        role="button"
        className={isFormOpen ? "layout-with-service--selected" : ""}
      >
        <span className="breadcrumbs">
          {showBreadCrumbs &&
            location.breadCrumbs.map((crumb, n) => (
              <span key={`loc-${location.id}-crumb-${n}`} className="crumb">
                {crumb}
              </span>
            ))}
        </span>
        {active ? (
          <P16Bold className="content">{location.name}</P16Bold>
        ) : (
          <P16 className="content">{location.name}</P16>
        )}
        <div className="actions">
          {showFavoriteCheckbox && !isTeamsApp && (
            <FavoriteCheckbox
              type="location"
              itemId={location.id}
              disabled={disabled}
            />
          )}
          {!isMobile && (
            <OpenEquipmentsBookingButton
              handleClick={() => setIsFormOpen(true)}
            />
          )}
        </div>

        <div className={`${onMoreClick ? "more more--underline" : "more"}`}>
          <div
            onClick={(e) => {
              if (onMoreClick) {
                e.stopPropagation()
                onMoreClick()
              }
            }}
          >
            <LocationCapacityAvailability availability={availability} />
          </div>
        </div>
      </LayoutWithServices>
      <BookEquipmentForm
        open={isFormOpen}
        location={location}
        equipments={equipments}
        equipmentsAvailability={equipmentsAvailability}
        onCancel={() => setIsFormOpen(false)}
        saveWorkingMode={saveWorkingMode}
      />
    </>
  )
}
