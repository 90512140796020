export const hideScrollBarsCSS = `
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; 
  scrollbar-width: none;
  `

export const overflowWithEllipsisCSS = `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
