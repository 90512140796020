import useUser from "graphql/users/useUser"
import useTranslate from "intl/useTranslate"
import React, { useState } from "react"
import styled from "styled-components"
import { User } from "types"
import { InfoTooltip, P16, Toggle } from "ui"

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .info-tooltip {
    display: flex;
    svg {
      width: 18px;
    }
  }

  div:last-child {
    margin-left: auto;
  }
`

interface PropsType {
  user: User
  updateChanges: (value: { isAdmin?: boolean }) => void
}

export default function UserRole({ user, updateChanges }: PropsType) {
  const t = useTranslate()
  const { user: currentUser } = useUser()

  const [isAdmin, setIsAdmin] = useState<boolean>(user.role === "admin")

  const handleChange = (isAdmin: boolean) => {
    setIsAdmin(isAdmin)
    updateChanges({
      isAdmin: isAdmin,
    })
  }

  return (
    <Container>
      <P16>{t("Admin")}</P16>
      <InfoTooltip text={t("admin explanation")} />

      {currentUser && (
        <Toggle
          checked={isAdmin}
          onChange={handleChange}
          disabled={isAdmin && user.id === currentUser.id}
        />
      )}
    </Container>
  )
}
