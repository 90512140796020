import React from "react"
import styled from "styled-components"
import { colors, P14 } from "ui"

const Label = styled.div`
  position: absolute;
  background: white;
  border: 1px solid ${colors.grey2};
  border-radius: 10px;
  height: 20px;
  line-height: 18px;
  margin: 6px;
  padding: 0px 8px;
  cursor: pointer;

  p {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

export interface LabelProps {
  text: string
  left: number
  top?: number
  bottom?: number
  center: boolean
  maxWidth: number
}

export default function AreaLabel({
  text,
  left,
  top,
  bottom,
  center,
  maxWidth,
}: LabelProps) {
  return (
    <Label
      style={{
        left: left,
        top: top,
        bottom: bottom,
        transform: center ? "translateX(-50%)" : undefined,
        margin: center ? "0 0 6px 0" : undefined,
        maxWidth: maxWidth - 12,
      }}
    >
      <P14>{text}</P14>
    </Label>
  )
}
