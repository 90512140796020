import { ClickAwayListener } from "@mui/material"
import useTranslate from "intl/useTranslate"
import React from "react"
import styled from "styled-components"
import { Button, colors, P14, Popper } from "ui"
import LocationTree from "utils/LocationTree"

import LocationSearch from "../components/LocationSearch"
import LocationsList from "../components/LocationsList"

const Menu = styled.div`
  display: flex;
  background-color: ${colors.white};
  flex-direction: column;
  padding: 16px 0;
  position: absolute;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.24), 0px 2px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  z-index: 1;
`

const ListHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px 8px 15px;

  .locations-lenght {
    color: ${colors.purple};
    margin-right: auto;
  }
`

const LocationsResultsList = styled.div`
  max-height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
`

interface PropsType {
  open: boolean
  anchorEl: HTMLElement | null
  locations: LocationTree[]
  selectedLocations: LocationTree[]
  isEverythingSelected: boolean
  singleSelection?: boolean
  searchString: string
  showArchivedLocations?: boolean
  setSearchString: (str: string) => void
  onSelectEverything: () => void
  onLocationSearch: (locations: LocationTree[]) => void
  onLocationSelection: (location: LocationTree) => void
  onClose: () => void
}

export default function LocationsPicker({
  open,
  anchorEl,
  locations,
  selectedLocations,
  isEverythingSelected,
  singleSelection,
  searchString,
  showArchivedLocations = false,
  setSearchString,
  onSelectEverything,
  onLocationSearch,
  onLocationSelection,
  onClose,
}: PropsType) {
  const t = useTranslate()

  if (!open || anchorEl === null) return <></>

  return (
    <ClickAwayListener
      onClickAway={(e) => {
        e.stopPropagation()
        onClose()
      }}
    >
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        style={{ zIndex: 3000 }}
      >
        <Menu>
          <ListHeader>
            <P14 className="locations-lenght">
              {selectedLocations.length} {t("Places")}
            </P14>
            {!singleSelection && (
              <Button disabled={singleSelection} onClick={onSelectEverything}>
                {isEverythingSelected ? t("Deselect all") : t("Select all")}
              </Button>
            )}
          </ListHeader>

          <LocationSearch
            placeholder={t("search for zone or space")}
            searchString={searchString}
            setSearchString={(e) => setSearchString(e)}
            onChange={(e) => setSearchString(e)}
            onNewResults={onLocationSearch}
            showArchivedLocations={showArchivedLocations}
          />
          <LocationsResultsList
            style={{
              width: anchorEl.offsetWidth,
            }}
          >
            <LocationsList
              locations={locations}
              recursiveRendering={searchString === ""}
              selectedLocations={selectedLocations}
              addLocation={(location) => {
                onLocationSelection(location)
                if (singleSelection) {
                  onClose()
                }
              }}
            />
          </LocationsResultsList>
        </Menu>
      </Popper>
    </ClickAwayListener>
  )
}
