import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

export default function Edit({
  stroke,
  strokeWidth,
  fill,
  ...rest
}: SvgIconProps) {
  return (
    <SvgIcon
      strokeWidth={strokeWidth ?? 1.5}
      stroke={stroke ? stroke : "currentColor"}
      {...rest}
    >
      <path
        d="M13.0206 5.8284L17.9703 10.7781M13.0206 5.8284L15.849 2.99997L20.7987 7.94972L17.9703 10.7781L13.0206 5.8284ZM13.0206 5.8284L3.41398 15.435C3.22645 15.6225 3.12109 15.8769 3.12109 16.1421V20.6776H7.65662C7.92184 20.6776 8.17619 20.5723 8.36373 20.3847L17.9703 10.7781L13.0206 5.8284Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={fill ? fill : "none"}
      />
    </SvgIcon>
  )
}
