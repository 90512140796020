export default async function loginWithOIDC(providerId: string) {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API}/auth/user/login/oidc`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ providerId }),
      }
    )
    if (res.status === 200) {
      const { url } = await res.json()
      window.location.href = url
    }
  } catch (e) {
    console.error(`Error while login with OIDC: ${e.message}`)
  }
}
