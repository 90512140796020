import useUserHybridRule from "graphql/hybridPolicy/useUserHybridRule"
import useUser from "graphql/users/useUser"
import React from "react"
import { Flags } from "types"
import UTCDate from "utils/UTCDate"

import UserHybridRuleScoreRow from "../UserHybridRuleScoreRow/UserHybridRuleScoreRow"

interface PropsType {
  companyFlags: Flags
  selectedDate: UTCDate
}

function FetchUserRule({
  userId,
  companyFlags,
  selectedDate,
}: PropsType & { userId: string }) {
  const { userHybridRule } = useUserHybridRule(userId)
  if (!userHybridRule) return <></>
  return (
    <UserHybridRuleScoreRow
      companyFlags={companyFlags}
      rule={userHybridRule}
      selectedDate={selectedDate}
      userId={userId}
      options={{ showUserRuleType: true }}
    />
  )
}

export default function MyTeamScores({
  companyFlags,
  selectedDate,
}: PropsType) {
  const { user: me } = useUser()
  if (!me || !me.isManager) return <></>
  return (
    <>
      {[me, ...me.team].map(({ id: userId }, n) => (
        <FetchUserRule
          key={`${userId}-${n}`}
          userId={userId}
          companyFlags={companyFlags}
          selectedDate={selectedDate}
        />
      ))}
    </>
  )
}
