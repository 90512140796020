import useTranslate from "intl/useTranslate"
import useTranslation from "intl/useTranslation"
import React from "react"
import styled from "styled-components"
import { colors, P16, P16Bold } from "ui"

const Tuto = styled.div`
  height: 250px;
  background-color: ${colors.grey4};
  border-radius: 20px;
  padding: 16px;

  .step {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    height: 100%;

    .gif {
      display: flex;
      margin-left: auto;
      margin-right: auto;
      width: 215px;
      height: 90px; /* Allows to crop the gif because there is a border at the bottom. */
      overflow: hidden;

      > img {
        display: block;
        width: 100%;
      }
    }
  }

  .footer-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    svg {
      cursor: pointer;
    }
  }
`

export default function TypicalWeekTuto() {
  const t = useTranslate()
  const [translation] = useTranslation()

  return (
    <Tuto>
      <div className="step">
        <P16Bold>{t("how to do it")}</P16Bold>
        <P16>{t("use drag and drop")}</P16>
        <figure className="gif">
          <img
            src={
              translation.id === "fr"
                ? "/static/img/misc_gif/how_to_fill_slot_fr.gif"
                : "/static/img/misc_gif/how_to_fill_slot.gif"
            }
            height={100}
            width={210}
          />
        </figure>
      </div>
    </Tuto>
  )
}
