import styled from "styled-components"
import { colors, transitions } from "ui"

interface CheckboxContainerProps {
  active: boolean
}

export const CheckboxContainer = styled.button<CheckboxContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  border-radius: 4px;
  gap: 8px;

  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;

  background: ${colors.grey4};
  border: 1px solid ${colors.grey3};
  border-radius: 8px;
  color: ${colors.black};
  font-weight: 400;

  padding: 4px 12px 4px 8px;
  transition: ${transitions.fade};

  &:hover {
    background: ${colors.grey3};
    border: 1px solid ${colors.grey2};
  }

  &:focus {
    background: ${colors.grey2};
    border: 1px solid ${colors.grey2};
  }

  &:active {
    background: ${colors.grey2};
    border: 1px solid ${colors.grey2};
  }

  &:disabled {
    background: ${colors.grey4};
    border: solid 1px ${colors.grey4};
    color: ${colors.grey2};
  }
  svg {
    height: 16px;
    width: 16px;
  }

  ${({ active }) => {
    if (active) {
      return `border: 1.5px solid ${colors.purple}; 
      color: ${colors.purple}; 
      font-weight: 600;
      transition: ${transitions.fade};`
    }
  }}
`
