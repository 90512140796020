import useLocationsTree from "graphql/locations/useLocationsTree"
import useTranslate from "intl/useTranslate"
import DownloadCsvButton from "pages/StatisticsPage/components/DownloadCsvButton"
import React, { useCallback, useState } from "react"
import { Bar } from "react-chartjs-2"
import { colors, Toggle } from "ui"
import LocationTree from "utils/LocationTree"
import UTCDate from "utils/UTCDate"

import getCsvLocations from "../utils/getCsvLocations"
import AllLocationsOccupancyBar from "./AllLocationsOccupancyBar/AllLocationsOccupancyBar"
import OccupancyBar from "./OccupancyBar/OccupancyBar"
import TotalOccupancyBar from "./TotalOccupancyBar/TotalOccupancyBar"

interface PropsType {
  selectedLocations: LocationTree[]
  allLocationsFlatten: LocationTree[]
  showTotal: boolean
  from: UTCDate
  to: UTCDate
  includeWeekends: boolean
  showAllLocationsBar: boolean
  setShowTotal: (show: boolean) => void
}

export default function OccupancyRateChart({
  selectedLocations,
  allLocationsFlatten,
  showTotal,
  from,
  to,
  includeWeekends,
  showAllLocationsBar,
  setShowTotal,
}: PropsType) {
  const t = useTranslate()
  const { locations: locationsTree } = useLocationsTree()

  const [occupancies, setOccupancies] = useState<
    {
      location: LocationTree
      occupancyRate: { value: number; booked: number; total: number }
    }[]
  >([])

  const [totalOccupancyRate, setTotalOccupancyRate] = useState<
    { value: number; booked: number; total: number } | undefined
  >(undefined)

  const [allLocationsOccupancyRate, setAllLocationsOccupancyRate] = useState<
    { value: number; booked: number; total: number } | undefined
  >(undefined)

  const totalOccupiedOccupancyRate = totalOccupancyRate

  const totalNotOccupiedOccupancyRate = 1 - (totalOccupancyRate?.value || 0)

  const isTotalValid = () => {
    if (!showAllLocationsBar && selectedLocations.length < 2) return false
    if (showAllLocationsBar && selectedLocations.length === 0) return false
    return true
  }

  // when total become invalid: not enough item selected
  // then hide total toggle and bar in chart
  if (!isTotalValid() && showTotal) setShowTotal(false)

  const getCsv = useCallback(() => {
    const headers = [
      "location_name",
      "from",
      "to",
      "value",
      "booked",
      "total",
    ].join(",")
    const rows: string[] = []
    if (showTotal && totalOccupiedOccupancyRate) {
      rows.push(
        [
          "total",
          from.format("DD/MM/YY HH:MM"),
          to.format("DD/MM/YY HH:MM"),
          Number(totalOccupiedOccupancyRate.value),
          Number(totalOccupiedOccupancyRate.booked),
          Number(totalOccupiedOccupancyRate.total),
        ].join(",")
      )
    }
    if (showAllLocationsBar && allLocationsOccupancyRate) {
      rows.push(
        [
          t("All locations"),
          from.format("DD/MM/YY HH:MM"),
          to.format("DD/MM/YY HH:MM"),
          Number(allLocationsOccupancyRate.value),
          Number(allLocationsOccupancyRate.booked),
          Number(allLocationsOccupancyRate.total),
        ].join(",")
      )
    }
    for (const l of getCsvLocations({
      showAllLocationsBar,
      allLocationsFlatten,
      selectedLocations,
      locationsTree,
    })) {
      const locationOccupancy = occupancies.find((o) => o.location.id === l.id)
      if (locationOccupancy !== undefined) {
        rows.push(
          [
            l.name,
            from.format("DD/MM/YY HH:MM"),
            to.format("DD/MM/YY HH:MM"),
            Number(locationOccupancy.occupancyRate.value),
            Number(locationOccupancy.occupancyRate.booked),
            Number(locationOccupancy.occupancyRate.total),
          ].join(",")
        )
      }
    }
    return Promise.resolve(`${headers}\n${rows.join("\n")}`)
  }, [
    allLocationsOccupancyRate,
    occupancies,
    from,
    to,
    showTotal,
    totalOccupiedOccupancyRate,
    showAllLocationsBar,
    allLocationsFlatten,
    selectedLocations,
    locationsTree,
    t,
  ])

  const getLabels = () => {
    const labels = occupancies.map((o) => o.location.name)
    if (showAllLocationsBar && allLocationsOccupancyRate !== undefined)
      labels.push(t("All locations"))

    if (showTotal && totalOccupancyRate !== undefined) labels.push("Total")
    return labels
  }

  const getOccupiedData = () => {
    const occupiedData = occupancies.map((o) => o.occupancyRate)
    if (showAllLocationsBar && allLocationsOccupancyRate !== undefined)
      occupiedData.push(allLocationsOccupancyRate)
    if (showTotal && totalOccupiedOccupancyRate !== undefined)
      occupiedData.push(totalOccupiedOccupancyRate)
    return occupiedData
  }

  const getNotOccupiedData = () => {
    const notOccupiedData = occupancies.map((o) => ({
      value: 1 - o.occupancyRate.value,
    }))
    if (showAllLocationsBar && allLocationsOccupancyRate !== undefined)
      notOccupiedData.push({
        ...allLocationsOccupancyRate,
        value: 1 - allLocationsOccupancyRate.value,
      })
    if (showTotal)
      notOccupiedData.push({
        ...allLocationsOccupancyRate,
        value: totalNotOccupiedOccupancyRate,
      })
    return notOccupiedData
  }

  const datasets = [
    {
      label: "occupied",
      data: getOccupiedData().map((d) => d.value),
      backgroundColor: colors.purple,
      maxBarThickness: 32,
      stack: 0,
    },
    {
      label: "not occupied",
      data: getNotOccupiedData().map((d) => d.value),
      backgroundColor: colors.grey3,
      maxBarThickness: 32,
      stack: 0,
    },
  ]

  const getTooltipLabel = (tooltipItem: {
    index: number
    datasetIndex: number
    xLabel: string
    yLabel: number
  }) => {
    const type = tooltipItem.datasetIndex === 0 ? "occupied" : "not-occupied"

    // All locations has a special label
    if (
      tooltipItem.xLabel === t("All locations") &&
      allLocationsOccupancyRate
    ) {
      if (type === "occupied") {
        return `${allLocationsOccupancyRate.booked}/${
          allLocationsOccupancyRate.total
        } ${t("occupied seats")} | ${(100 * tooltipItem.yLabel).toFixed(2)}%`
      }
      return `${
        allLocationsOccupancyRate.total - allLocationsOccupancyRate.booked
      }/${allLocationsOccupancyRate.total} ${t("not occupied seats")} | ${(
        100 * tooltipItem.yLabel
      ).toFixed(2)}%`
    }

    // Normal location
    const locationOccupancy = occupancies.find(
      (o) => o.location.name === tooltipItem.xLabel
    )
    if (locationOccupancy !== undefined) {
      if (type === "occupied") {
        return `${locationOccupancy.occupancyRate.booked}/${
          locationOccupancy.occupancyRate.total
        } ${t("occupied seats")} | ${(100 * tooltipItem.yLabel).toFixed(2)}%`
      }
      return `${
        locationOccupancy.occupancyRate.total -
        locationOccupancy.occupancyRate.booked
      }/${locationOccupancy.occupancyRate.total} ${t(
        "not occupied seats"
      )} | ${(100 * tooltipItem.yLabel).toFixed(2)}%`
    }

    // Fall back
    return `${(100 * tooltipItem.yLabel).toFixed(2)}%`
  }

  const data = {
    labels: getLabels(),
    datasets:
      selectedLocations.length > 0 ||
      (showAllLocationsBar && allLocationsOccupancyRate !== undefined)
        ? datasets
        : [],
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          id: "xAxis1",
          type: "category",
          ticks: {
            display: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          gridLines: {
            display: true,
          },
          ticks: {
            callback: function (value: number) {
              return Math.floor(100 * value) + "%"
            },
          },
        },
      ],
    },
    tooltips: {
      mode: "nearest",
      callbacks: {
        label: getTooltipLabel,
      },
    },
    legend: {
      labels: {
        usePointStyle: true,
        boxWidth: 7,
        filter: (legendItem: any) => {
          legendItem.text = t(legendItem.text)

          return true
        },
      },
    },
    onHover: (event: any, elements: any) => {
      event.target.style.cursor = "default"
      if (elements.length > 0) {
        event.target.style.cursor = "pointer"
      }
    },
  }

  const updateData = (
    location: LocationTree,
    occupancyRate: { value: number; booked: number; total: number },
    remove = false
  ) => {
    const occupancy = remove ? [] : [{ location, occupancyRate }]
    setOccupancies((prev) => [
      ...prev.filter((o) => o.location.id !== location.id),
      ...occupancy,
    ])
  }

  return (
    <>
      <DownloadCsvButton
        className="btn--margin-top"
        fileName="occupancy-rate.csv"
        getCsv={getCsv}
      />

      {selectedLocations.map((l) => (
        <OccupancyBar
          key={`occupancy-bar-${l.id}`}
          location={l}
          from={from}
          to={to}
          includeWeekends={includeWeekends}
          occupancies={occupancies}
          updateData={updateData}
        />
      ))}
      {showAllLocationsBar && (
        <AllLocationsOccupancyBar
          locations={allLocationsFlatten}
          from={from}
          to={to}
          includeWeekends={includeWeekends}
          allLocationsOccupancyRate={allLocationsOccupancyRate}
          setAllLocationsOccupancyRate={setAllLocationsOccupancyRate}
        />
      )}
      {isTotalValid() && showTotal && (
        <TotalOccupancyBar
          locations={selectedLocations}
          from={from}
          to={to}
          includeWeekends={includeWeekends}
          totalOccupancyRate={totalOccupancyRate}
          setTotalOccupancyRate={setTotalOccupancyRate}
        />
      )}
      <Bar
        data={data}
        options={options}
        // dataset hover logic starts here
        // getElementAtEvent={(e: any[]) => {
        //   if (e.length === 0) return
        //   const index = e[0]._index
        //   const label = e[0]._model.label
        //   const location = selectedLocations.find(
        //     (l, n) => l.name === label && index === n
        //   )
        // }}
      />
      {isTotalValid() && (
        <Toggle
          checked={showTotal && isTotalValid()}
          onChange={setShowTotal}
          label={"Total"}
          labelToTheLeft
          disabled={selectedLocations.length === 0 && !showAllLocationsBar}
        />
      )}
    </>
  )
}
