import styled from "styled-components"

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-bottom: 12px;

  > svg {
    height: 24px;
    width: 24px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0);
    transition: background 250ms ease-in-out;

    cursor: pointer;
    &:hover {
      background: rgba(0, 0, 0, 0.04);
    }
  }
`
