import { GroupSimple, User } from "types"

export default function getDefaultGroup(
  me: User | null,
  myGroups: GroupSimple[]
): GroupSimple | null {
  if (me) {
    const everyoneGroupLast = me.company.flags.openEveryoneGroup === false
    const myTeam = myGroups.find((group) => group.id === "myteam")
    const myManagerTeam = myGroups.find((group) => group.id === "mymanagerteam")
    const everyone = myGroups.find(
      (group) =>
        group.id === "everyone" && me.company.flags.hideEveryoneGroup !== true
    )
    let groupIdToDisplay: string | null = null
    if (everyone && !everyoneGroupLast) groupIdToDisplay = everyone.id
    else if (myTeam) groupIdToDisplay = myTeam.id
    else if (myManagerTeam) groupIdToDisplay = myManagerTeam.id
    else if (everyone) groupIdToDisplay = everyone.id
    if (groupIdToDisplay) {
      return myGroups.find((g) => g.id === groupIdToDisplay) || null
    }
  }
  return null
}
